import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
// MUI
import Slider, { SliderThumb } from "@mui/material/Slider";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";

// Types
type AirbnbSliderProps = {
  start?: number;
  end?: number;
  max?: number;
  onChange?: (data: { start: number; end?: number }) => any;
};

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  light_grey: "rgba(159, 159, 159, 1)",
};

const useStyles = makeStyles((theme) => ({
  screen: { padding: "0 13px" },
  box_input: {
    display: "flex",
    alignItems: "center",
    margin: "0.75rem -13px 0",
    "& .ui.fluid.input": {
      width: "100%",
      margin: 0,
      opacity: 1,
      "& input": {
        borderRadius: "8px",
        padding: "0.9rem 1em",
      },
    },
    "& .dash": {
      backgroundColor: COLORS.light_grey,
      width: "50px",
      height: "1px",
      margin: "0 20px",
    },
  },
}));

const SliderCustom = styled(Slider)(({ theme }) => ({
  color: COLORS.primary,
  height: 1,
  padding: "13px 0",
  marginTop: "-0.25rem",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(1, 71, 163, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 1,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 2,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

const AirbnbThumbComponent = (props: AirbnbThumbComponentProps) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
};

const AirbnbSlider = (props: AirbnbSliderProps) => {
  const classes = useStyles();

  const [range, setRange] = useState<any>([]);

  useEffect(() => {
    setRange([props.start || 1, props.end || 60]);
  }, [props.start,props.end]);

  const handleChange = (
    event: any,
    value: number | number[],
    activeThumb: number
  ) => {
    if (Array.isArray(value)) {
      setRange(value);

      props.onChange?.({ start: value[0], end: value[1] });
    }
  };

  return (
    <div className={classes.screen}>
      <SliderCustom
        slots={{ thumb: AirbnbThumbComponent }}
        getAriaLabel={(index) =>
          index === 0 ? "Minimum price" : "Maximum price"
        }
        value={range}
        min={1}
        max={props.max || 30}
        onChange={handleChange}
      />

      <div className={classes.box_input}>
        <Input value={`${range[0]} ปี`} fluid={true} disabled={true} />
        <div className="dash"></div>
        <Input value={`${range[1]} ปี`} fluid={true} disabled={true} />
      </div>
    </div>
  );
};

export default React.memo(AirbnbSlider);
