import WasmController from "react-lib/frameworks/WasmController";
import * as MobSmartRegisterI from "./MobSmartRegisterInterface";
import * as FeedInterface from "./native/FeedInterface";
import * as KYCInterface from "../bplus-lib/register/KYCInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobSmartAppointmentI from "./MobSmartAppointmentInterface";

console.log(" MobAppointmentController !!! init ");
if (
  typeof globalThis !== "undefined" &&
  typeof globalThis.mobile === "undefined"
) {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}
export default class MobSmartRegisterController extends WasmController<
  MobSmartRegisterI.State,
  MobSmartRegisterI.Event,
  MobSmartRegisterI.Data
> {
  constructor(
    getState: () => MobSmartRegisterI.State,
    setState: (state: MobSmartRegisterI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobSmartRegisterI.DataInitial);
  }

  handleEvent = (e: MobSmartRegisterI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      case "DidMount":
        MobSmartRegisterI.DidMount(this, e.params);
        return;
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;
      case "HandleSetValueRegisterInfo":
        MobSmartRegisterI.HandleSetValueRegisterInfo(this, e.params);
        return;
      case "HandleSetProfileInfo":
        MobSmartRegisterI.HandleSetProfileInfo(this, e.params);
        return;
      case "HandleRegister":
        MobSmartRegisterI.HandleRegister(this, e.params);
        return;
      case "HandleRequestOTP":
        MobSmartRegisterI.HandleRequestOTP(this, e.params);
        return;
      case "HandleRequestToken":
        MobSmartRegisterI.HandleRequestToken(this, e.params);
        return;
      case "HandleSetPassword":
        MobSmartRegisterI.HandleSetPassword(this, e.params);
        return;
      case "HandleGetUserProfile":
        MobSmartRegisterI.HandleGetUserProfile(this, e.params);
        return;
      case "HandleUpdateProfile":
        MobSmartRegisterI.HandleUpdateProfile(this, e.params);
        return;
      case "HandleLogin":
        MobSmartRegisterI.HandleLogin(this, e.params);
        return;
      case "HandleLogOut":
        MobSmartRegisterI.HandleLogOut(this, e.params);
        return;
      case "HandleGotoMainScreen":
        MobSmartRegisterI.HandleGotoMainScreen(this, e.params);
        return;
      case "HandleUpdateStatuseKYC":
        return MobSmartRegisterI.HandleUpdateStatuseKYC(this, e.params);
      case "HandleGetListOrganizations":
        MobSmartRegisterI.HandleGetListOrganizations(this, e.params);
        return;
      case "HandleRegisterHN":
        MobSmartRegisterI.HandleRegisterHN(this, e.params);
        return;
      case "HandleCheckPatientHasHospitial":
        MobSmartRegisterI.HandleCheckPatientHasHospitial(this, e.params);
        return;
      case "CheckUserProfileRequiredField":
        return MobSmartRegisterI.CheckUserProfileRequiredField(this, e.params);
      case "HandleGetStatuseKYC":
        MobSmartRegisterI.HandleGetStatuseKYC(this, e.params);
        return;
      case "HandleCheckMakeAppointment":
        return MobSmartRegisterI.HandleCheckMakeAppointment(this, e.params);

      case "HandlerPostRetrieveProfile":
        return MobSmartRegisterI.HandlerPostRetrieveProfile(this, e.params);

      case "HandleCheckConsentHospitalForm":
        return MobSmartRegisterI.HandleCheckConsentHospitalForm(this, e.params);

      case "GetMyProfileDetail":
        return MobSmartRegisterI.GetMyProfileDetail(this, e.params);

      // ----- KYCI
      case "HandleCreateTaskIdCard":
        KYCInterface.HandleCreateTaskIdCard(this, e.params);
        return;

      case "HandleFaceResult":
        KYCInterface.HandleFaceResult(this, e.params);
        return;

      case "HandleCanceleKYC":
        KYCInterface.HandleCanceleKYC(this, e.params);
        return;

      case "HandleCompleteScan":
        KYCInterface.HandleCompleteScan(this, e.params);
        return;

      case "HandleThaiIdOCR":
        KYCInterface.HandleThaiIdOCR(this, e.params);
        return;

      // ----- Selected Hospital
      case "HandleSelectedHospital":
        SelectHospitalI.HandleSelectedHospital(this, e.params);
        return;

      case "GetListHospital":
        return SelectHospitalI.GetListHospital(this, e.params);

      case "HandleForgotPassword":
        MobSmartRegisterI.HandleForgotPassword(this, e.params);
        return;

        // ----- SmartAppointmentI
      case "HandleHistoryPushState":
        MobSmartAppointmentI.HandleHistoryPushState(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
