import Cookies from "js-cookie";

import AppController from "./AppController";
import { AsyncDuckDB, AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";

export type WasmHandler<S=any, E=any, D=any, P=any>
  = (controller: WasmController<S, E, D>, params?: P) => any

export default class WasmController<S, E, D> extends AppController<S, E> {

  python: any
  wasm: any
  data: D
  setProp: any
  cookies: typeof Cookies = Cookies
  apiToken: string
  permit: string
  mode: string
  history: any
  bearer?: any
  constructor(
    getState: () => S,
    setState: (state: S, callback?: any) => void,
    window: any,
    dataInitial: D,
    mode: string = "",
    history: any = null,
    bearer: any  = null,
  ) {
    super(getState, setState, window);
    this.data = dataInitial;
    this.apiToken = "";
    this.permit = "";
    this.mode = mode;
    this.history = history;
    this.bearer = bearer;
  }

  setData = (newData: D) => {
    this.data = {...this.data, ...newData};
  }

}