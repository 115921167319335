import React, { memo, useEffect, useState } from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import {
  Button,
  Divider,
  Grid,
  Icon,
  Image,
  Transition,
} from "semantic-ui-react";
import moment from "moment";

const COLOR = {
  darkgrayHos: "#797878",
  primary: "var(--primary-theme-color)",
  font_white: "white",
  orange: "#DE7800",
  super_red: "#DA0000",
  super_darkgray: "#393939",
  lightBlueBDMS: "#0072BC",
  DarkGray_Line: "#9F9F9F",
  super_ligtblue: "#CCDAED",
};

const styles = {
  normaltext: {
    fontSize: "14px",
    marginBottom: "3px",
    color: COLOR.DarkGray_Line,
  } as CSSProperties,
  titletext_bole: {
    width: "90%",
    overflow: "hidden",
    fontSize: "14px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "2px",
    marginBottom: "3px",
    color: COLOR.primary,
  } as CSSProperties,
  display_center: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  border_select: {
    position: "absolute",
    background: "white",
    padding: "0 10px",
    color: COLOR.orange,
    fontWeight: "bold",
    fontSize: "14px",
  } as CSSProperties,
  drug_detail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: COLOR.lightBlueBDMS,
    fontSize: "14px",
    fontWeight: "normal",
  } as CSSProperties,
};

type SeeMoreMedicationProps = {
  medicationItems?: any;
  handleOpenDrugDetail: (item: any) => any;
  onEvent: (e: any) => any;
};

const SeeMoreMedication: React.FunctionComponent<SeeMoreMedicationProps> = (
  props
) => {
  const [medicationItem, setMedicationItem] = useState<any | undefined>(
    undefined
  );

  useEffect(() => {
    if (props.medicationItems !== null && props.medicationItems?.length > 0) {
      setMedicationItem(props.medicationItems);
    }
  }, [props.medicationItems]);

  const handleUpdateState = (current: any, encounter: any, id: any) => {
    props.onEvent({
      message: "handleUpdateCurrentMed",
      params: {
        drug_id: id,
        encounter: encounter,
        state: current,
      },
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {props.medicationItems ? (
        props.medicationItems.map((item: any, index: any) => (
          <div>
            <div
              style={{
                ...styles.titletext_bole,
                display: "inline-block",
              }}
            >
              {item.name}
            </div>
            <div
              style={{
                ...styles.normaltext,
                color: COLOR.super_darkgray,
                fontWeight: "normal",
                marginBottom: "15px",
              }}
            >
              {item.note.medication}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      ...styles.normaltext,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {"Qty : "}
                  </div>
                  <div
                    style={{
                      ...styles.normaltext,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                     { (item.ph_qty_ord || "").length > 0 ? `X ${item.ph_qty_ord} ${item.unit}`: "-"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={styles.normaltext}>{"Exp : "}</div>
                  <div style={{ ...styles.normaltext, paddingLeft: "10px" }}>
                    {item.exp ? moment(item.exp).format("DD MMM YYYY") : "-"}
                  </div>
                </div>
              </div>
              <div
                style={styles.drug_detail}
                onClick={() => props.handleOpenDrugDetail(item)}
              >
                <Image
                  src="/images/drug/icon_no_medication1.png"
                  style={{ width: "35px", height: "35px" }}
                />
                <div>More detail</div>
              </div>
            </div>
            <div style={styles.display_center}>
              <div>
                <div
                  style={{
                    ...styles.display_center,
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      ...styles.border_select,
                      display: item.state === "" ? "" : "none",
                    }}
                  >
                    Please select
                  </div>
                </div>
                <div
                  style={{
                    border:
                      item.state === "" ? `1px solid ${COLOR.orange}` : "",
                    padding: "15px 13px 8px",
                  }}
                >
                  <Button
                    style={{
                      background:
                        item.state === ""
                          ? ""
                          : item.state === "OFF"
                          ? COLOR.super_red
                          : "",
                      color:
                        item.state === ""
                          ? ""
                          : item.state === "OFF"
                          ? COLOR.font_white
                          : "",
                      borderRadius: "10px",
                      padding: "10px 15px",
                      fontSize: "12px",
                    }}
                    onClick={() =>
                      handleUpdateState("OFF", item.encounter_number, item.id)
                    }
                  >
                    OFF
                  </Button>
                  <Button
                    style={{
                      background:
                        item.state === ""
                          ? ""
                          : item.state === "ON"
                          ? COLOR.primary
                          : "",
                      color:
                        item.state === ""
                          ? ""
                          : item.state === "ON"
                          ? COLOR.font_white
                          : "",
                      borderRadius: "10px",
                      padding: "10px 20px",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }}
                    onClick={() =>
                      handleUpdateState("ON", item.encounter_number, item.id)
                    }
                  >
                    ON
                  </Button>
                </div>
              </div>
            </div>
            {props.medicationItems.length > 1 &&
            index !== props.medicationItems.length - 1 ? (
              <Divider style={{ background: COLOR.super_ligtblue }} />
            ) : (
              ""
            )}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(SeeMoreMedication);
