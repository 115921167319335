import WasmController from "react-lib/frameworks/WasmController";
import * as MobCouponI from "./MobCouponInterface";
import * as FeedInterface from "./native/FeedInterface";
import * as MobAppointmentI from "./MobAppointmentInterface";
import * as MakeAppointmentI from "./appointment/MakeAppointmentInterface";

console.log(" MobCouponController !!! init ");

if (typeof globalThis !== "undefined" && typeof globalThis.mobile === "undefined") {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}  

export default class MobCouponController extends WasmController<
  MobCouponI.State,
  MobCouponI.Event,
  MobCouponI.Data
> {
  constructor(
    getState: () => MobCouponI.State,
    setState: (state: MobCouponI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobCouponI.DataInitial);
  }

  handleEvent = async (e: MobCouponI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      // Setup
      case "DidMount":
        MobCouponI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobCouponI.DidUpdate(this, e.params);
        return;

      // MyCoupon
      case "handleGetListMyCoupon":
        MobCouponI.handleGetListMyCoupon(this, e.params);
        return;

      case "handleClickRedeemCoupon":
        MobCouponI.handleClickRedeemCoupon(this, e.params);
        return;

      case "handleSelectedCoupon":
        MobCouponI.handleSelectedCoupon(this, e.params);
        return;

      case "handleFilterListMyCoupon":
        MobCouponI.handleFilterListMyCoupon(this, e.params);
        return;

      case "getCouponDetail":
        return MobCouponI.getCouponDetail(this, e.params);

      case "handleGetEvaluate":
        return MobCouponI.handleGetEvaluate(this, e.params);

      case "getPatientCouponCheck":
        return MobCouponI.getPatientCouponCheck(this, e.params);

      case "handleGetPatientDetail":
        MobCouponI.handleGetPatientDetail(this, e.params);
        return;

      case "handleSaveDiagForm":
        MobCouponI.handleSaveDiagForm(this, e.params);
        return;

      case "GetMasterDataNewPatient":
        MobCouponI.GetMasterDataNewPatient(this, e.params);
        return;

      case "HandleSetValueCreatePatient":
        MobCouponI.HandleSetValueCreatePatient(this, e.params);
        return;

      case "HandleCreateNewPatient":
        MobCouponI.HandleCreateNewPatient(this, e.params);
        return;

      case "HandleCloseModCreateNewPatient":
        MobCouponI.HandleCloseModCreateNewPatient(this, e.params);
        return;

      case "MakeAppointmentDubaiTeleConsult":
        MobCouponI.MakeAppointmentDubaiTeleConsult(this, e.params);
        return;

      // MobAppointment
      case "HandleRouteChange":
        MobAppointmentI.HandleRouteChange(this, e.params);
        return;

      // MakeAppointment
      // case "GetConfigPrxEnableEcoupon":
      //   MakeAppointmentI.GetConfigPrxEnableEcoupon(this as any, e.params);
      //   return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
