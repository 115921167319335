import React, { CSSProperties, useEffect, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import {
  Dimmer,
  Loader,
  Segment,
  Divider,
  Grid,
  Icon,
  Label,
  Modal,
  Checkbox,
  Button,
  Input,
} from "semantic-ui-react";

import moment from "moment";

// Interface
import { Event, State } from "../../ManageInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";

// Common
import { DateTextBox } from "../../../react-lib/apps/common";

// Utils
import { adToBe } from "../../../react-lib/utils";
import {
  BACKEND_DATE_FORMAT,
  BE_DATE_FORMAT,
} from "bplus-lib/MobClinicInterface";

const styles = {
  paddingSpace: {
    width: "28px",
  } as CSSProperties,
  vaccineItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  rowHeaderFont: {
    fontWeight: "bold",
    fontSize: "18px",
  } as CSSProperties,
  vaccineDiv: {
    display: "flex",
    alignItems: "baseline",
  } as CSSProperties,
  labelVaccine: {
    textAlign: "center",
    color: "white",
    width: "100px",
    fontSize: "16px",
    fontWeight: "bold",
  } as CSSProperties,
  modHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2D9BE2",
    padding: "15px 10px 15px 15px",
  } as CSSProperties,
  fontModHeader: {
    fontSize: "26px",
    fontWeight: "bold",
    color: "#FFFFFF",
  } as CSSProperties,
  modDivColumn: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    // alignItems: "center",
  } as CSSProperties,
  modDivRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
  } as CSSProperties,
  fontModName: {
    fontSize: "22px",
    color: "#2399E5",
    fontWeight: "bold",
  } as CSSProperties,
  checkboxDivMod: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    paddingRight: "15px",
  } as CSSProperties,
  checkboxLabelMod: {
    fontSize: "16px",
    color: "#2399E5",
    paddingLeft: "1.3em",
  } as CSSProperties,
  inputDivMod: {
    display: "flex",
    flex: "3",
    alignItems: "center",
  } as CSSProperties,
  fontInputMod: {
    fontSize: "22px",
    color: "#2399E5",
    fontWeight: "bold",
    paddingRight: "15px",
  } as CSSProperties,
};

const COLOR_CODE = {
  lightblue: "#C7F1FF",
  cyan: "#2D9BE2",
};

type WebDMVaccineProps = {
  onEvent: (e: Event) => any;
} & Pick<
  State,
  "successMessage" | "errorMessage" | "loadingStatus" | "vaccineDataTabDetail" | "vaccineSeriesList"
>;

const WebDMVaccine: React.FC<WebDMVaccineProps> = (props) => {
  const [selectedData, setSelectedData] = useState<any>(null);

  useEffect(() => {
    props.onEvent({ message: "handleGetVaccineSummary", params: {} });
  }, []);

  const handleAddExtraVaccine = (data: any) => {
    setSelectedData({ ...data, active: true, action: "add", date: "" });
  };

  const handleSelectVaccine = ({
    item,
    detail,
    index,
  }: {
    item: any;
    detail: any;
    index: number;
  }) => {
    const cloneDetail = { ...detail };
    if (!cloneDetail.date) {
      cloneDetail.active = true;
      cloneDetail.date = moment().format(BE_DATE_FORMAT);
    }

    setSelectedData({
      ...cloneDetail,
      name: item.name,
      id: detail.id,
      action: "edit",
      vaccine: item.id,
      index,
    });
  };

  const handleApprove = (detail: any) => {
    if (selectedData.action === "edit") {
      handleUpdateVaccine(detail);
    } else {
      handleAddVaccine(detail);
    }

    setSelectedData(null);
  };

  const handleUpdateVaccine = (detail: any) => {
    props.onEvent({
      message: "HandleSaveVaccineDetail",
      params: {
        id: selectedData.id,
        proxy_patient: selectedData.proxy_patient,
        vaccine: selectedData.vaccine,
        data: {
          ...selectedData.realData,
          OEORI_Date: detail.date
            ? moment(detail.date, BE_DATE_FORMAT).format(BACKEND_DATE_FORMAT)
            : "",
          OEORI_PrescNo: detail.lot_no,
          By_Staff: true,
          DispenseTypeDesc: "Other hospital",
        },
      },
    });
  };

  const handleAddVaccine = (detail: any) => {
    props.onEvent({
      message: "HandleSaveVaccineDetail",
      params: {
        id: null,
        proxy_patient: null,
        vaccine: selectedData.id,
        data: {
          ...selectedData.realData,
          OEORI_Date: detail.date
            ? moment(detail.data).format(BACKEND_DATE_FORMAT)
            : "",
          OEORI_PrescNo: detail.lot_no,
          Manual: true,
          By_Staff: true,
          DispenseTypeDesc: "Other hospital",
        },
      },
    });
  };

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/detail/vaccine.png"
        />
        <span>
          <FormattedMessage id="vaccine.vaccine" />
        </span>
      </div>

      <ModAddVaccine
        open={!!selectedData}
        detail={selectedData}
        onClose={() => {
          setSelectedData(null);
        }}
        onApprove={handleApprove}
      />

      <div className="sub-layout dm">
        {/* mock data here */}
        {props.vaccineDataTabDetail?.items?.map((item: any, index: number) => {
          return (
            <Grid key={index} style={{ padding: "15px" }}>
              <Grid.Row style={{ padding: "0px" }}>
                <Grid.Column width={3} style={styles.rowHeaderFont}>
                  {item.name}
                </Grid.Column>
                <Grid.Column width={1} style={{ fontWeight: "bold" }}>
                  {item.add_manually && (
                    <Icon
                      name="plus circle"
                      size="big"
                      color="blue"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleAddExtraVaccine(item);
                      }}
                    />
                  )}
                </Grid.Column>

                <Grid.Column
                  width={12}
                  style={{ fontWeight: "bold", padding: 0 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {item?.items?.map((detail: any, sindex: number) => {
                      return (
                        <div
                          style={{
                            margin: "0 4px 4px",
                            display: "flex",
                            width: "152px",
                            textAlign: "center",
                            alignItems: "baseline",
                          }}
                        >
                          <div>
                            {detail.date ? (
                              <Icon
                                name="check circle"
                                color="green"
                                size="large"
                              />
                            ) : (
                              <div style={styles.paddingSpace} />
                            )}
                          </div>
                          <div style={styles.vaccineItems}>
                            <Label
                              style={{
                                ...styles.labelVaccine,
                                backgroundColor: detail.date
                                  ? COLOR_CODE.cyan
                                  : COLOR_CODE.lightblue,
                                cursor: detail.realData?.OEORI_RowId
                                  ? ""
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (detail.realData?.OEORI_RowId) {
                                  return;
                                }
                                handleSelectVaccine({
                                  item,
                                  detail,
                                  index: sindex,
                                });
                              }}
                            >
                              {detail.code}
                            </Label>
                            <label style={{ color: COLOR_CODE.cyan }}>
                              {detail.date}
                            </label>
                          </div>
                          {detail.date &&
                            (!detail.realData?.OEORI_RowId &&
                              !detail.realData?.By_Staff) && (
                              <Icon
                                name="user outline"
                                color="grey"
                                size="large"
                                style={{ fontSize: "1.2rem" }}
                              />
                            )}
                        </div>
                      );
                    })}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Divider />
            </Grid>
          );
        })}
      </div>
    </Segment>
  );
};

type ModAddVaccineProps = {
  // callback
  onEvent?: any;
  onClose?: () => any;
  onApprove?: (detail: any) => any;
  open?: boolean;
  detail?: {
    code: string;
    name: string;
    date: string;
    active?: boolean;
    realData?: any;
  };
};

export const ModAddVaccine: React.FC<ModAddVaccineProps> = (props) => {
  const [lotName, setLotName] = useState<string>("");
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const [active, setActive] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (props.detail) {
      setLotName(props.detail?.realData?.OEORI_PrescNo || "");
      setDate(props.detail?.date || date);
      setActive(props.detail?.date ? true : props.detail?.active ?? false);
    }
  }, [props.detail]);

  const handleSetDate = (date: string) => {
    if (!date) {
      return;
    }
    let dateConvert = date.split("/");
    let year = +dateConvert[2] - 543;
    setDate(`${dateConvert[0]}/${dateConvert[1]}/${year}`);
  };

  const handleOnConfirm = () => {
    // event for remove date and change to inactive vaccine or update vaccine data
    //implement here

    props.onApprove?.({ date, lot_no: lotName, active });
  };

  return (
    <Modal open={props.open} closeOnDimmerClick={false} size="small">
      <div style={styles.modHeader}>
        <div style={styles.fontModHeader}>
          <FormattedMessage id="bplusClinicKey383" />
        </div>
        <Icon
          name="close"
          onClick={props.onClose}
          size="big"
          style={{ color: "white", cursor: "pointer" }}
        />
      </div>
      <div style={styles.modDivColumn}>
        <div style={styles.modDivRow}>
          <div style={{ ...styles.fontModName, flex: "3" }}>
            {props.detail?.name || ""}
          </div>
          <div style={styles.checkboxDivMod}>
            <Checkbox
              style={{ transform: "scale(1.5)" }}
              checked={active}
              label={
                <label style={styles.checkboxLabelMod}>
                  <FormattedMessage id="bplusClinicKey38" />
                </label>
              }
              onClick={(e, { checked }) => {
                if (!checked) {
                  setDate("");
                }
                setActive(checked);
              }}
            />
          </div>
        </div>
        <div style={styles.modDivRow}>
          <div style={{ flex: "1" }} />
          <div style={styles.inputDivMod}>
            <div style={styles.fontInputMod}>
              <FormattedMessage id="bplusClinicKey302" />
            </div>
            <Input
              value={lotName}
              onChange={(e) => {
                setLotName(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={styles.modDivRow}>
          <div style={{ flex: "1" }} />
          <div style={styles.inputDivMod}>
            <div style={styles.fontInputMod}>
              <FormattedMessage id="bplusClinicKey275" />
            </div>
            <DateTextBox
              disabled={!active}
              value={adToBe(date) || ""}
              onChange={handleSetDate}
            />
          </div>
        </div>
        <div style={styles.modDivRow}>
          <Button
            content="OK"
            color="blue"
            size="big"
            onClick={handleOnConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(WebDMVaccine);
