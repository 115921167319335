import { WasmHandler } from 'react-lib/frameworks/WasmController'
import { collection, query, where, getDocs } from "firebase/firestore";
export type State =
  {
    appointmentList?: any[];
    todayVisitList?: any[];
    postList?: any[];
    localEvent?: any[];
    contentList?: any[];
    selectedContentIndex?: number | null;
    selectedHospital?: any;
  }

export const StateInitial =
{
  appointmentList: [],
  todayVisitList: [],
  postList: [],
  localEvent: [],
  contentList: [],
  selectedContentIndex: null,
  selectedHospital: null
}

export type Event = {}

export type Data = {}

export const DataInitial = {}

type Handler = WasmHandler<State, Event>

export const GetPatientInfo: Handler = (controller, params) => {
  GetAppointment(controller, params);
  GetTodayVisit(controller, params);
  GetPostList(controller, params);
}

export const GetAppointment: Handler = async (controller, params) => {

  const q = query(collection(controller.db, "Appointment_apps_APP"), where("patientId", "==", params.patientId));
  const querySnapshot = await getDocs(q);
  console.log("GetAppointment ", querySnapshot.docs.map(doc => doc.data()));
  controller.setState({
    appointmentList: querySnapshot?.docs?.map( doc => doc.data())
  })


  // controller.db.collection("Appointment_apps_APP")
  //   .where("patientId", "==", params.patientId)
  //   .get()
  //   .then(res => {
  //     console.log(res.docs.map(doc => doc.data()));
  //     controller.setState({
  //       appointmentList: res.docs.map(doc => doc.data())
  //     })
  //   })
}

export const GetTodayVisit: Handler = async (controller, params) => {



  const q = query(collection(controller.db, "TodayVisit"), where("patientId", "==", params.patientId));
  const querySnapshot = await getDocs(q);
  console.log("GetTodayVisit", querySnapshot.docs.map(doc => doc.data()));

  controller.setState({
    todayVisitList: querySnapshot?.docs?.map(doc => doc.data())
  })

  if ( querySnapshot?.docs?.length > 0 ) {

    const qL = query(collection(controller.db, "LocalEvent"), where("patientId", "==", params.patientId));
    const queryLSnapshot = await getDocs(qL);
    controller.setState({
      localEvent: queryLSnapshot?.docs?.map(ev => ev.data())
    })
  }

  // controller.db.collection("TodayVisit")
  //   .where("patientId", "==", params.patientId)
  //   .get()
  //   .then(res => {
  //     controller.setState({
  //       todayVisitList: res.docs.map(doc => doc.data())
  //     })
  //     if (res.docs.length > 0) {
  //       controller.db.collection("LocalEvent")
  //         .where("patientId", "==", params.patientId)
  //         .get()
  //         .then(ev => {
  //           controller.setState({
  //             localEvent: ev.docs.map(ev => ev.data())
  //           })
  //         })
  //     }
  //   })
}

export const GetPostList: Handler = async (controller, params) => {

  console.log("GetPostList ", params.patientId)

  const postRes = await getDocs(collection(controller.db, "Post"));
  const patientSegmentsQuery = query(collection(controller.db, "PatientMarketing"), where("patient_id", "==", params.patientId));
  const patientSegments = await getDocs(patientSegmentsQuery);

  // const postRes = await controller.db.collection("Post").get();
  // const patientSegments = await controller.db
  //   .collection("PatientMarketing")
  //   .where("patient_id", "==", params.patientId)
  //   .get();

  let segments = patientSegments.docs.map((doc: any) => doc.data());
  console.log(segments);

  let postList = postRes.docs.map((doc: any, index: number) => {
    const data: any = doc.data();
    console.log(data);
    let match = false;
    for (const segment of segments) {
      if (data.segments.includes(segment.result)) {
        match = true;
        break;
      }
    };
    if (match) {
      return data;
    } else {
      return false;
    }
  })
    .filter((item: any) => item);

  console.log(postList);
  controller.setState({ postList: postList })
}

// controller.storage.refFromURL('gs://mybplus-content')
// .child("preview.json")
// .getDownloadURL()
// .then((url: string) => {
//   axios.get(url)
//   .then(res => {
//     console.log(res.data)
//     controller.setState({
//       previewList: res.data,
//     })
//   })
//   .catch(err => {
//     console.log(err)
//   })
// });
