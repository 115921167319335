import React, { CSSProperties } from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import makeStyles from "@mui/styles/makeStyles";

// Types
type CardVerifyingPaymentProps = {
  // callback
  onRefresh?: () => any;
};

// Images
const IMAGES = {
  consent: "/images/register/consent.png",
  refresh: "/images/payment/tabler-refresh.png",
};

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  grey: "rgba(121, 120, 120, 1)",
  very_light_blue: "rgba(204, 218, 237, 1)",
  bg: "#ECF4FF",
  light_blue: "rgba(0, 139, 245, 1)",
};

const styles = {
  flex_center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    lineHeight: 1.75,
  } as CSSProperties,
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const cardRefreshStyles = {
  borderRadius: "8px !important",
  boxShadow: "none !important",
  border: `1px solid ${COLORS.very_light_blue} !important`,
  backgroundColor: `${COLORS.bg} !important`,
  width: "75%",
  "& .MuiCardActionArea-focusHighlight": {
    backgroundColor: COLORS.very_light_blue,
  },
  "& .MuiCardContent-root": {
    color: COLORS.primary,
    "& img": {
      width: "24px",
      marginBottom: "0.5rem",
    },
  },
};

const useStyles = makeStyles(() => ({
  checking: {
    height: "calc(100vh - 23rem)",
    ...styles.flex_center,
    "& img": {
      width: "12rem",
      marginBottom: "1rem",
    },
    "& > div:nth-of-type(1)": {
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: COLORS.primary,
      marginBottom: "0.5rem",
    },
  },
  card_refresh: cardRefreshStyles,
}));

const CardVerifyingPayment = (props: CardVerifyingPaymentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.checking}>
      <img src={IMAGES.consent} alt="consent" />
      <div>กําลังตรวจสอบรายการที่ต้องชําระ</div>
      <div>
        <div>ท่านจะสามารถเห็นรายการพร้อมชำระ</div>
        <div>เมื่อรพ. ตรวจสอบเสร็จ โดยการกด refresh</div>
        <div>หรือติดต่อเจ้าหน้าที่ ณ จุดล่าสุด</div>
        <div>ที่ท่านใช้บริการ หากรอนานเกิน 10 นาที</div>
      </div>
      <div
        style={{
          ...styles.center,
          width: "100%",
          position: "absolute",
          bottom: "3rem",
        }}
      >
        <Card className={classes.card_refresh} onClick={props.onRefresh}>
          <CardActionArea>
            <CardContent>
              <div>
                <img src={IMAGES.refresh} alt="refresh" />
              </div>
              <div>กดเพื่อ Refresh </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
};

export default React.memo(CardVerifyingPayment);
