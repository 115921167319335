import React, { ReactElement } from "react";
import { Loader } from "semantic-ui-react";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import InfoIcon from "@mui/icons-material/Info";

// I.Styles
import { cardPartialSuccess } from "./ModFaceScanComplete";
import { FormattedMessage } from "react-intl";

// Types
type ModIdentityVerifiedProps = {
  type: "success" | "partial_success";
  buttonText?: string | ReactElement;
  loading?: boolean;
  // callback
  onApprove: () => any;
};

// Images
const IMAGES = {
  success: "/images/register/success-hn.png",
};

// Styles

const useStyles = makeStyles(() => ({
  card: cardPartialSuccess,
}));

const ModIdentityVerified = (props: ModIdentityVerifiedProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} className="smart-dialog-container">
        <DialogContent>
          <div className="content">
            <img src={IMAGES.success} alt="success-hn" />
            <div
              className="title"
              style={{ marginBottom: "0.75rem", lineHeight: 1.35 }}
            >
              <FormattedMessage id="bplusClinicKey1064" />
            </div>
            <div style={{ padding: "0.25rem 1rem 0.15rem" }}>
              {props.type === "partial_success" && (
                <div style={{ whiteSpace: "pre-line" }}>
                  <FormattedMessage id="bplusClinicKey1065" />
                </div>
              )}
            </div>
          </div>

          {props.type === "partial_success" && (
            <div className={classes.card}>
              <div>
                <InfoIcon />
              </div>
              <div>
                <FormattedMessage id="bplusClinicKey1076" />
              </div>
            </div>
          )}

          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!!props.loading}
              style={{ width: "87.5%" }}
              onClick={props.onApprove}
            >
              {props.buttonText || <FormattedMessage id="bplusClinicKey1077" />}

              <Loader
                size="small"
                active={!!props.loading}
                inline={true}
                style={{ marginLeft: "0.5rem" }}
              ></Loader>
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(ModIdentityVerified);
