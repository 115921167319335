import React, { CSSProperties, useEffect, useState } from "react";
import {
  Dimmer,
  Dropdown,
  Icon,
  Loader,
  Segment,
  Checkbox,
  TextArea,
  Modal,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import SnackMessage from "../../clinic/SnackMessage";

import moment from "moment";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  FOOT_STATUS,
  BE_DATE_FORMAT,
} from "../../MobClinicInterface";

import {
  footDetailWordMap,
  styles as MobFootStyle,
  FootDetailKey,
} from "../../clinic/DMFoot";
import { HeaderChart } from "../../clinic/DMEmotionRestChart";

const styles = {
  subHeader: {
    color: "#0072BC",
    fontSize: "24px",
    fontWeight: "bold",
  } as CSSProperties,
  footDetailHeader: {
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  footDetailHeaderFont: {
    fontSize: "16px",
    fontWeight: "bold",
  } as CSSProperties,
  footDateTextBox: {
    display: "flex",
    fontSize: "18px",
    width: "auto",
    justifyContent: "center",
  } as CSSProperties,
  durationFont: {
    fontSize: "15px",
    color: "#2D9BE2",
    paddingRight: "15px",
  } as CSSProperties,
  durationDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  } as CSSProperties,
  durationDropdown: {
    minWidth: "100px",
  } as CSSProperties,
  monofilamentLayout: {
    padding: "15px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  monofilamentSubLayout: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  monoItemsDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignContent: "center",
  } as CSSProperties,
  monoItemsHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingLeft: "20%",
  } as CSSProperties,
  monoItemsCheckboxDiv: {
    display: "flex",
  } as CSSProperties,
  monoItemsChekbox: {
    flex: "1",
    transform: "scale(1.4)",
  } as CSSProperties,
  careProviderDiv: {
    padding: "15px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  careProviderLayout: {
    display: "flex",
    flex: "1",
    padding: "15px",
  } as CSSProperties,
  careProviderCheckLayout: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    padding: "10px",
  } as CSSProperties,
  headerCareProvider: {
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
  } as CSSProperties,
  subHeaderCareProvider: {
    fontSize: "18px",
    padding: "10px 5px 5px 6px",
  } as CSSProperties,
  highlightGreen: {
    color: "#44A110",
  } as CSSProperties,
  highlightRed: {
    color: "#EE1C24",
  } as CSSProperties,
  careProviderCheckbox: {
    transform: "scale(1.1)",
    padding: "4px 0px",
  } as CSSProperties,
  careProviderTextarea: {
    padding: "3px 0px",
    resize: "none",
  } as CSSProperties,
};

const durationOptions = [
  { key: "1", value: "4", text: "5 Day" },
  { key: "2", value: "6", text: "7 Days" },
  { key: "3", value: "9", text: "10 Days" },
];

type WebFootDetailProps = {
  onEvent: any;
  footHistory: any;
  footData: any;
  loadingStatus?: boolean;
  loadingWebFoot?: boolean;
  errorMessage?: any;
  successMessage?: any;
  footEclinicalData?: any;
};

const WebFootDetail: React.FC<WebFootDetailProps> = (props: any) => {
  const intl = useIntl();
  const [dateMonofilament, setDateMonofilament] = useState<string>(
    moment().locale("en").format("D MMM YYYY")
  );

  const [dateCareProvider, setDateCareProvider] = useState<string>(
    moment().locale("en").format("D MMM YYYY")
  );
  const [duration, setDuration] = useState<any>("6");
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [footFilamentIndex, setFootFilamentIndex] = useState<number>(0);
  const [footExamIndex, setFootExamIndex] = useState<number>(0);

  // useEffect(() => {
  //   props.onEvent({
  //     message: "handleGetFootHistoryByDuration",
  //     params: {
  //       start_measure: moment().add("-7", "days").format(BACKEND_DATE_FORMAT),
  //       end_measure: moment().format(BACKEND_DATE_FORMAT),
  //       isNeedExam: true,
  //     },
  //   });
  // }, [props.footData]);

  useEffect(() => {
    props.onEvent({
      message: "handleGetFootHistoryByDuration",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
        isNeedExam: true,
      },
    });
  }, [startDate, props.footData]);

  useEffect(() => {
    if (
      props.footEclinicalData?.foot_monofilament &&
      props.footEclinicalData?.foot_monofilament.length > 0
    ) {
      setFootFilamentIndex(
        props.footEclinicalData?.foot_monofilament.length - 1
      );
    } else {
      setFootFilamentIndex(0);
    }
    if (
      props.footEclinicalData?.foot_examination &&
      props.footEclinicalData?.foot_examination.length > 0
    ) {
      setFootExamIndex(props.footEclinicalData?.foot_examination.length - 1);
    } else {
      setFootExamIndex(0);
    }
  }, [props.footEclinicalData]);

  const handleChangeDuration = (event: any, data: any) => {
    setDuration(data.value);
    setStartDate(moment().add(`-${data.value}`, "days"));
    setEndDate(moment());
  };

  const addDateThenString = (action: string, date: any) => {
    if (action === "next") {
      return moment(date).add(1, "days").locale("en").format("D MMM YYYY");
    }
    if (action === "previous") {
      return moment(date).add(-1, "days").locale("en").format("D MMM YYYY");
    }
    return null;
  };

  const handleChangeDate = (name: string, action: string) => {
    if (name === "monofilament") {
      setDateMonofilament(addDateThenString(action, dateMonofilament) || "");
      // recommend on Event here
    }
    if (name === "careProvider") {
      setDateCareProvider(addDateThenString(action, dateCareProvider) || "");
      // recommend on Event here
    }
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, BE_DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${duration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, BE_DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(duration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleSetFootMonofilamentIndex = (position: "previous" | "next") => {
    if (props.footEclinicalData?.foot_monofilament?.length > 0) {
      if (position === "previous" && footFilamentIndex !== 0) {
        setFootFilamentIndex(footFilamentIndex - 1);
      }
      if (
        position === "next" &&
        props.footEclinicalData?.foot_monofilament?.length > 0 &&
        footFilamentIndex !==
          props.footEclinicalData?.foot_monofilament?.length - 1
      ) {
        setFootFilamentIndex(footFilamentIndex + 1);
      }
    }
  };

  const handleSetFootExaminationIndex = (position: "previous" | "next") => {
    if (props.footEclinicalData?.foot_examination?.length > 0) {
      if (position === "previous" && footExamIndex !== 0) {
        setFootExamIndex(footExamIndex - 1);
      }
      if (
        position === "next" &&
        props.footEclinicalData?.foot_examination?.length > 0 &&
        footExamIndex !== props.footEclinicalData?.foot_examination?.length - 1
      ) {
        setFootExamIndex(footExamIndex + 1);
      }
    }
  };

  console.log("Web foot Detial props:", props);

  return (
    // <div>
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingWebFoot} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src={process.env.PUBLIC_URL + "/images/detail/foot.png"}
        />
        <span>
          <FormattedMessage id="foot.foot_examination" />
        </span>
      </div>

      <div className="sub-layout dm">
        <div style={{ position: "relative" }}>
          <div
            style={{ ...styles.subHeader, position: "absolute", top: "29px" }}
          >
            <FormattedMessage id="bplusClinicKey446" />
          </div>
          <HeaderChart
            startDate={startDate}
            endDate={endDate}
            durationOptions={durationOptions}
            selectedDuration={duration}
            onChangeDuration={handleChangeDuration}
            style={{ position: "relative" }}
            dateRangeStyle={{
              position: "absolute",
              top: "20px",
              left: "calc(50% - 104px)",
            }}
            onChangeDateRange={handleChangeDateRange}
          />

          <WebFootImageList footHistory={props.footHistory} />
        </div>
        <div style={styles.monofilamentLayout}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={styles.subHeader}>
              <FormattedMessage id="bplusClinicKey327" />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Icon
                name="angle double left"
                size="large"
                style={{
                  cursor: "pointer",
                  display: footFilamentIndex !== 0 ? "" : "none",
                }}
                color={footFilamentIndex !== 0 ? "black" : "grey"}
                onClick={() => {
                  handleSetFootMonofilamentIndex("previous");
                }}
              />
              <div style={styles.footDateTextBox}>
                {props.footEclinicalData?.foot_monofilament?.[footFilamentIndex]
                  ?.created
                  ? moment(
                      props.footEclinicalData?.foot_monofilament?.[
                        footFilamentIndex
                      ]?.created
                    )
                      .locale("en")
                      .format("D MMMM YYYY")
                  : moment().locale("en").format("D MMMM YYYY")}
                <Icon name="calendar alternate" />
              </div>
              <Icon
                name="angle double right"
                color={
                  props.footEclinicalData?.foot_monofilament &&
                  props.footEclinicalData?.foot_monofilament?.length > 0 &&
                  footFilamentIndex !==
                    props.footEclinicalData?.foot_monofilament?.length - 1
                    ? "black"
                    : "grey"
                }
                size="large"
                style={{
                  cursor: "pointer",
                  display:
                    props.footEclinicalData?.foot_monofilament &&
                    props.footEclinicalData?.foot_monofilament?.length > 0 &&
                    footFilamentIndex !==
                      props.footEclinicalData?.foot_monofilament?.length - 1
                      ? ""
                      : "none",
                }}
                onClick={() => {
                  handleSetFootMonofilamentIndex("next");
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", padding: "15px" }}>
            <div style={styles.monofilamentSubLayout}>
              <MonofilamentItems
                header="Left"
                monofilamentData={
                  props.footEclinicalData?.foot_monofilament?.[
                    footFilamentIndex
                  ] || {}
                }
              />
              {/* props.footEclinicalData?.[footExamIndex] */}
            </div>
            <div style={styles.monofilamentSubLayout}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/detail/foot_monofilament.png"
                }
                style={{ height: "270px" }}
              />
            </div>
            <div style={styles.monofilamentSubLayout}>
              <MonofilamentItems
                header="Right"
                monofilamentData={
                  props.footEclinicalData?.foot_monofilament?.[
                    footFilamentIndex
                  ] || {}
                }
              />
            </div>
          </div>
        </div>
        <div style={styles.careProviderDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={styles.subHeader}>
              <FormattedMessage id="bplusClinicKey204" />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Icon
                name="angle double left"
                size="large"
                style={{
                  cursor: "pointer",
                  display: footExamIndex !== 0 ? "" : "none",
                }}
                color={footExamIndex !== 0 ? "black" : "grey"}
                onClick={() => {
                  handleSetFootExaminationIndex("previous");
                }}
              />
              <div style={styles.footDateTextBox}>
                {props.footEclinicalData?.foot_examination?.[footExamIndex]
                  ?.created
                  ? moment(
                      props.footEclinicalData?.foot_examination?.[footExamIndex]
                        ?.created
                    )
                      .locale("en")
                      .format("D MMMM YYYY")
                  : moment().locale("en").format("D MMMM YYYY")}
                <Icon name="calendar alternate" />
              </div>
              <Icon
                name="angle double right"
                size="large"
                style={{
                  cursor: "pointer",
                  display:
                    props.footEclinicalData?.foot_examination &&
                    props.footEclinicalData.foot_examination?.length > 0 &&
                    footExamIndex !==
                      props.footEclinicalData.foot_examination.length - 1
                      ? ""
                      : "none",
                }}
                color={
                  props.footEclinicalData?.foot_examination &&
                  props.footEclinicalData.foot_examination?.length > 0 &&
                  footExamIndex !==
                    props.footEclinicalData.foot_examination.length - 1
                    ? "black"
                    : "grey"
                }
                onClick={() => {
                  handleSetFootExaminationIndex("next");
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles.careProviderLayout}>
              <CareProviderCheck
                header="Left"
                careproviderFootData={
                  props.footEclinicalData?.foot_examination?.[footExamIndex] ||
                  {}
                }
              />
            </div>
            <div style={styles.careProviderLayout}>
              <CareProviderCheck
                header="Right"
                careproviderFootData={
                  props.footEclinicalData?.foot_examination?.[footExamIndex] ||
                  {}
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Segment>
    // </div>
  );
};

type WebFootImageListProps = {
  footHistory: any;
  bgColor?: any;
};
const WebFootImageList = (props: WebFootImageListProps) => {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [viewImage, setViewImage] = useState<string>("");

  const handleSetFootDetailMap = (footDetail: any) => {
    // sent left or right detail to convert to text
    let footDetailKeys = Object.keys(footDetail);
    let footDetailText = [];
    for (const foot of footDetailKeys) {
      if (footDetail[foot]) {
        footDetailText.push(footDetailWordMap[foot as FootDetailKey]);
      }
    }
    return footDetailText.length > 0 ? footDetailText.join(", ") : "";
  };

  return (
    <>
      <div style={{ ...MobFootStyle.flexColumn, padding: "5px" }}>
        <div
          style={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            borderTop: "solid rgb(0, 114, 188)",
            borderBottom: "solid rgb(0, 114, 188)",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="mea.icon" height="25px" src="/images/detail/foot.png" />
            <span
              style={{ fontSize: "15px", fontWeight: "bold", padding: "2px" }}
            >
              {intl.formatMessage({ id: "bplusClinicKey626" })}
            </span>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="mea.icon" height="25px" src="/images/detail/foot.png" />
            <span
              style={{ fontSize: "15px", fontWeight: "bold", padding: "2px" }}
            >
              {intl.formatMessage({ id: "bplusClinicKey624" })}
            </span>
          </div>
        </div>
        {props.footHistory?.items?.map((item: any, index: number) => {
          const lastData: any = item?.data[item?.data?.length - 1];
          return (
            <div
              style={
                index % 2 === 0
                  ? MobFootStyle.MainflexColumnWithBackground
                  : MobFootStyle.MainflexColumn
              }
            >
              <div style={{ ...MobFootStyle.flexRow, alignItems: "baseline" }}>
                <div style={{ flex: "1", fontSize: "16px" }}>
                  <Icon
                    name="calendar alternate outline"
                    style={{ color: "#0072BC" }}
                  />
                  {moment(
                    lastData?.time,
                    `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                  ).format("DD MMMM YYYY")}
                </div>
                <div style={{ flex: "1" }}>
                  {lastData?.status === FOOT_STATUS.HIGH && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        name="user md"
                        color={lastData?.color}
                        style={{ fontSize: "22px" }}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: lastData?.color,
                        }}
                      >
                        <FormattedMessage id="bplusClinicKey381" />
                      </span>
                    </div>
                  )}
                  {[FOOT_STATUS.MEDIUM, FOOT_STATUS.LOW].includes(
                    lastData?.status
                  ) && (
                    <div style={{ display: "flex" }}>
                      <Icon
                        name={
                          lastData?.status === FOOT_STATUS.LOW
                            ? "thumbs up"
                            : "thumbs down"
                        }
                        color={lastData?.color}
                        style={{ fontSize: "22px" }}
                      />
                      {lastData?.status === FOOT_STATUS.LOW ? (
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: lastData?.color,
                          }}
                        >
                          {lastData?.status === FOOT_STATUS.LOW && "Normal"}
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#F2A20C",
                          }}
                        >
                          {lastData?.status === FOOT_STATUS.MEDIUM && "Dry"}
                        </span>
                      )}
                    </div>
                  )}
                  {lastData?.status === "" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: lastData?.color,
                          textAlign: "center",
                        }}
                      >
                        {intl.formatMessage({ id: "bplusClinicKey483" })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div style={MobFootStyle.flexRow}>
                <div
                  style={{
                    flex: "1",
                    padding: "0px 10px",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#4F4F4F",
                  }}
                >
                  {handleSetFootDetailMap(lastData?.leftFoot)}
                </div>
                <div
                  style={{
                    flex: "1",
                    padding: "0px 10px",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#4F4F4F",
                  }}
                >
                  {handleSetFootDetailMap(lastData?.rightFoot)}
                </div>
              </div>
              <div style={MobFootStyle.flexRow}>
                <div
                  style={{
                    ...MobFootStyle.borderImg,
                    textAlign: "center",
                    width: "125px",
                    height: "125px",
                  }}
                >
                  {lastData?.leftFoot?.image?.url ? (
                    <img
                      src={lastData.leftFoot.image.url}
                      style={{ height: "inherit", width: "100%" }}
                      onClick={() => {
                        setOpenModal(true);
                        setViewImage(lastData.leftFoot.image.url);
                      }}
                    />
                  ) : (
                    "No photo"
                  )}
                </div>
                <div
                  style={{
                    ...MobFootStyle.borderImg,
                    textAlign: "center",
                    width: "125px",
                    height: "125px",
                  }}
                >
                  {lastData?.rightFoot?.image?.url ? (
                    <img
                      src={lastData.rightFoot.image.url}
                      style={{ height: "inherit", width: "100%" }}
                      onClick={() => {
                        setOpenModal(true);
                        setViewImage(lastData.rightFoot.image.url);
                      }}
                    />
                  ) : (
                    "No photo"
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={openModal}
        dimmer={true}
        onClose={() => {
          setOpenModal(false);
        }}
        closeIcon
      >
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <img src={viewImage} style={{ height: "80vh" }} />
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

type monofilamentItemsProps = {
  onClick?: any;
  header?: string;
  monofilamentData?: any;
};

const MonofilamentItems: React.FC<monofilamentItemsProps> = (props) => {
  const [footData, setFootData] = useState<any>({});

  useEffect(() => {
    if (
      props.monofilamentData &&
      Object.keys(props.monofilamentData).length > 0
    ) {
      let targetKeys = Object.keys(props.monofilamentData).filter((item) =>
        item.includes("monofilament_" + props.header.toLowerCase())
      );
      let data = {
        monofilament_other_detail:
          props.monofilamentData?.monofilament_other_detail || "",
      };

      targetKeys.forEach((key) => {
        data[key] = props.monofilamentData[key];
      });
      setFootData(data);
    } else {
      setFootData({});
    }
  }, [props.monofilamentData]);
  return (
    <div style={styles.monoItemsDiv}>
      <div style={styles.monoItemsHeader}>{props.header || ""}</div>
      {footData?.[`monofilament_${props.header.toLowerCase()}`] &&
      footData?.[`monofilament_${props.header.toLowerCase()}`] === "Normal" ? (
        <div
          style={{
            ...styles.monoItemsHeader,
            color: "#44A110",
            paddingLeft: "0px",
          }}
        >
          {'"Normal"'}
        </div>
      ) : (
        <div style={{ ...styles.monoItemsDiv, height: "80%" }}>
          <div style={styles.monoItemsCheckboxDiv}>
            <Checkbox
              label="1"
              style={styles.monoItemsChekbox}
              // checked={props.monofilamentData?.[props.header || ""]?.["1"]}
              checked={
                footData?.[
                  `monofilament_${props.header.toLowerCase()}_detail`
                ]?.includes(1) || false
              }
              readOnly={true}
            />
            {/* <Checkbox
          label="6"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["6"]}
          readOnly={true}
        /> */}
          </div>
          <div style={styles.monoItemsCheckboxDiv}>
            <Checkbox
              label="2"
              style={styles.monoItemsChekbox}
              // checked={props.monofilamentData?.[props.header || ""]?.["2"]}
              checked={
                footData?.[
                  `monofilament_${props.header.toLowerCase()}_detail`
                ]?.includes(2) || false
              }
              readOnly={true}
            />
            {/* <Checkbox
          label="7"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["7"]}
          readOnly={true}
        /> */}
          </div>
          <div style={styles.monoItemsCheckboxDiv}>
            <Checkbox
              label="3"
              style={styles.monoItemsChekbox}
              // checked={props.monofilamentData?.[props.header || ""]?.["3"]}
              checked={
                footData?.[
                  `monofilament_${props.header.toLowerCase()}_detail`
                ]?.includes(3) || false
              }
              readOnly={true}
            />
            {/* <Checkbox
          label="8"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["8"]}
          readOnly={true}
        /> */}
          </div>
          <div style={styles.monoItemsCheckboxDiv}>
            <Checkbox
              label="4"
              style={styles.monoItemsChekbox}
              // checked={props.monofilamentData?.[props.header || ""]?.["4"]}
              checked={
                footData?.[
                  `monofilament_${props.header.toLowerCase()}_detail`
                ]?.includes(4) || false
              }
              readOnly={true}
            />
            {/* <Checkbox
          label="9"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["9"]}
          readOnly={true}
        /> */}
          </div>
          {/* <div style={styles.monoItemsCheckboxDiv}>
        <Checkbox
          label="5"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["5"]}
          readOnly={true}
        />
        <Checkbox
          label="10"
          style={styles.monoItemsChekbox}
          checked={props.monofilamentData?.[props.header]?.["10"]}
          readOnly={true}
        />
      </div> */}
        </div>
      )}
    </div>
  );
};

type CareProviderProps = {
  careproviderFootData?: any;
  header?: string;
};

const CareProviderCheck: React.FC<CareProviderProps> = (props) => {
  const [footData, setFootData] = useState<any>({});
  const [isNormal, setIsNormal] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.careproviderFootData &&
      Object.keys(props.careproviderFootData).length > 0
    ) {
      let targetKeys = Object.keys(props.careproviderFootData).filter((item) =>
        item.includes("examination_" + props.header.toLowerCase())
      );
      // let data = {
      //   monofilament_other_detail: props.careproviderFootData?.monofilament_other_detail || ""
      // }
      let data = {};

      targetKeys.forEach((key) => {
        data[key] = props.careproviderFootData[key];
      });
      if (
        data?.[`examination_${props.header.toLowerCase()}_detail`] === "Normal"
      ) {
        setIsNormal(true);
      } else {
        setIsNormal(false);
      }
      setFootData({ ...data?.[`examination_${props.header.toLowerCase()}`] });
      // console.log("examination set data: ", data)
    } else {
      setIsNormal(false);
      setFootData({});
    }
  }, [props.careproviderFootData]);

  // console.log("Foot Examination data: ", footData)

  return (
    <div
      style={{
        ...styles.careProviderCheckLayout,
        backgroundColor: props.header === "Left" ? "#FCDDEC" : "#F3F6CA",
      }}
    >
      <div style={styles.headerCareProvider}>{props.header || ""}</div>
      {isNormal && (
        <div
          style={{
            ...styles.headerCareProvider,
            color: "#44A110",
            paddingTop: "30px",
          }}
        >
          {'"Normal"'}
        </div>
      )}
      <div style={{ display: isNormal ? "none" : "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            padding: "0px 15px 15px 15px",
          }}
        >
          <div style={styles.subHeaderCareProvider}>
            <FormattedMessage id="bplusClinicKey457" />
          </div>
          <Checkbox
            style={styles.careProviderCheckbox}
            readOnly={true}
            checked={footData?.dry || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey168" />
              </label>
            }
          />
          <Checkbox
            style={styles.careProviderCheckbox}
            readOnly={true}
            checked={footData?.sweat || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey480" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            checked={footData?.maceration || false}
            style={styles.careProviderCheckbox}
            label="Maceration"
          />
          <Checkbox
            readOnly={true}
            checked={footData?.fissure_cracks || false}
            style={styles.careProviderCheckbox}
            label="Fissure/cracks"
          />
          <Checkbox
            readOnly={true}
            checked={footData?.corn || false}
            style={styles.careProviderCheckbox}
            label="Corn"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.bllister || false}
            label="Blister"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.callus || false}
            label="Callus"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.temp || false}
            label="Temp"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.skin_breakdown || false}
            label={
              <label style={styles.highlightRed}>
                <FormattedMessage id="bplusClinicKey456" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.ulcer || false}
            label={
              <label style={styles.highlightRed}>
                <FormattedMessage id="bplusClinicKey499" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.trickened_nails || false}
            label="Trickened nails"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.discolored_nails || false}
            label="Discolored nails"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.deformed_nails || false}
            label="Deformed nails"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.ingrown_nails || false}
            label="Ingrown nails"
          />
          <div style={styles.subHeaderCareProvider}>
            <FormattedMessage id="bplusClinicKey448" />
          </div>
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.disminished || false}
            label="Disminished"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.absent || false}
            label="Absent"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.painful_neuropathy || false}
            label="Painful neuropathy"
          />
          <div style={styles.subHeaderCareProvider}>
            <FormattedMessage id="bplusClinicKey325" />
          </div>
          <Checkbox
            readOnly={true}
            checked={footData?.rom || false}
            style={styles.careProviderCheckbox}
            label="Rom"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.gait_abnormality || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey208" />
              </label>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            padding: "0px 15px 15px 15px",
          }}
        >
          <div style={styles.subHeaderCareProvider}>
            <FormattedMessage id="bplusClinicKey473" />
          </div>
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.hammer_toes || false}
            label="Hammer toes"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.claw_toes || false}
            label="Claw toes"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.overlapping_digits || false}
            label="Overlapping digits"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.bunion || false}
            label="Bunion"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.arch_deformity || false}
            label="Arch deformity"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.amputation || false}
            label={
              <label style={styles.highlightRed}>
                <FormattedMessage id="bplusClinicKey64" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.other_structure || false}
            label="Other"
          />
          <TextArea
            readOnly={true}
            rows="3"
            value={footData?.other_structure || ""}
            style={styles.careProviderTextarea}
          />
          <div style={styles.subHeaderCareProvider}>
            <FormattedMessage id="bplusClinicKey520" />
          </div>
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.shiny_skin || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey452" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.hair_loss || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey231" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.ederma || false}
            label={
              <label style={styles.highlightGreen}>
                <FormattedMessage id="bplusClinicKey178" />
              </label>
            }
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.ederma_weeping || false}
            label="Edema (weeping)"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.cold_skin || false}
            label="Cold skin"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.pollor_cyanosis || false}
            label="Pollor/Cyanosis"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.cap_refill_sec || false}
            label="Cap.refill > 3-4 sec"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.absent_doralis_pedis || false}
            label="Absent doralis pedis"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.absent_posterior_tibial || false}
            label="Absent posterior tibial"
          />
          <Checkbox
            readOnly={true}
            style={styles.careProviderCheckbox}
            checked={footData?.other_vascular || false}
            label="Other"
          />
          <TextArea
            readOnly={true}
            rows="3"
            value={footData?.other_vascular || ""}
            style={styles.careProviderTextarea}
          />
        </div>
      </div>
    </div>
  );
};

export default WebFootDetail;
