import React, {
  useState,
  useRef,
  useEffect,
  CSSProperties,
  ReactChild,
  forwardRef,
  Ref,
  useImperativeHandle,
  useCallback,
} from "react";
import { InputProps, Icon, Input, Button } from "semantic-ui-react";
// MUI
import { Avatar, Button as MuiButton } from "@material-ui/core";

import { FormattedMessage } from "react-intl";

// Style
import "../../css/MobAppointment.scss";

type SearchBoxProps = {
  // data
  textSearch?: string;
  list?: any[];
  isLoadingSearch?: boolean;
  placeholder?: string;
  className?: string;
  subTitleField?: string;
  // styles
  style?: CSSProperties;
  // config
  rightIcon?: ReactChild;
  rightIconColor?: string;
  iconRightReverse?: boolean;
  hideButtonFilter?: boolean;
  hideSearchList?: boolean;
  hideNotFound?: true;
  showOnBlur?: boolean;
  // callback
  onSearch?: (value: any) => any;
  onSelect?: (data?: any) => any;
  onRightIconClick?: () => any;
  onSeeAllClick?: () => any;
  onInputClick?: (e: any) => any;
  onInputBlur?: (e: any) => any;
  onInputSubmit?: (e: any) => any;
  onClear?: () => any;
};

export const COLOR = {
  blue: "var(--primary-theme-color)",
  light_blue: "var(--light-blue-bdms-color)",
  yellow: "var(--warning)",
  dark_grey: "var(--secondary-font-color)",
  orange: "var(--orange)",
  background: "var(--primary-bg-color)",
  background_card: "var(--card-bg-color-light)",
  dark_blue: "var(--primary-theme-color)",
  super_dark_grey: "var(--super-dark-gray)",
  white: "white",
};

const ICON = {
  profile: "/images/Feed/doctor.png",
  place: "/images/Appointment/neurosurgery.png",
  department: "/images/Appointment/brain-nervous-system.png",
};

const ButtonSort = (props: any) => {
  return (
    <Button
      circular
      style={{
        background: props.rightIconColor,
        padding: "0px 10.98px",
      }}
      onClick={props.onClick}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        {props.rightIcon ? (
          props.rightIcon
        ) : (
          <>
            <svg
              style={{ transform: !props.reverse ? "rotate(180deg)" : "" }}
              width="7"
              height="15"
              viewBox="0 0 7 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="3.5"
                y1="-2.18557e-08"
                x2="3.5"
                y2="13"
                stroke="white"
              />
              <circle cx="3.5" cy="11.5" r="3.5" fill="white" />
            </svg>
            <svg
              style={{
                marginLeft: "4px",
                transform: props.reverse ? "rotate(180deg)" : "",
              }}
              width="7"
              height="15"
              viewBox="0 0 7 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="3.5"
                y1="-2.18557e-08"
                x2="3.5"
                y2="13"
                stroke="white"
              />
              <circle cx="3.5" cy="11.5" r="3.5" fill="white" />
            </svg>
          </>
        )}
      </div>
    </Button>
  );
};

const SearchBox = (props: SearchBoxProps, ref: Ref<any>) => {
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<any>("");
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const segmentHoverRef = useRef<any>(false);
  const inputRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.inputRef?.current?.focus?.();
      setIsFocus(true);
      setOpen(true);
    },
  }));

  // Effect
  useEffect(() => {
    document.addEventListener("click", handleMouseClick);
    return () => {
      document.removeEventListener("click", handleMouseClick);
    };
  }, []);

  useEffect(() => {
    if (!inputSearch && props.textSearch && !typingTimeout) {
      setInputSearch(props.textSearch);
    }
    if (inputSearch && props.textSearch && !typingTimeout) {
      setInputSearch(props.textSearch);
    }
    if (inputSearch && !props.textSearch && !typingTimeout) {
      setInputSearch("");
    }
  }, [inputSearch, props.textSearch, typingTimeout]);

  const getAvatar = useCallback((item: Record<string, any>) => {
    let avatar = "";

    if (item.is_doctor) {
      avatar = item.image || ICON.profile;
    } else if (item.type) {
      avatar = item.image_url || ICON.place;
    } else {
      avatar = item.image_url || ICON.department;
    }

    return avatar;
  }, []);

  const getSubTitle = useCallback(
    (item: Record<string, any>) => {
      let subTitle = "";

      if (props.subTitleField) {
        subTitle = item[props.subTitleField];
      } else if (item.is_doctor) {
        subTitle = "doctor";
      } else if (item.type) {
        subTitle = "Specialty";
      } else {
        subTitle = "Department";
      }

      return subTitle;
    },
    [props.subTitleField]
  );

  // Handler
  const handleMouseClick = (e: any) => {
    if (!segmentHoverRef.current && !props.showOnBlur) {
      handleClose(e);
    }
  };

  const handleSearch = (_e: any, v: InputProps) => {
    const value = v.value;
    setInputSearch(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (!value) {
      setOpen(false);
      props.onSearch?.(value);
      setTypingTimeout(null);
      return;
    }

    setTypingTimeout(
      setTimeout(() => {
        setOpen(true);
        props.onSearch?.(value);
      }, 800)
    );
  };

  const handleClose = (e: any) => {
    setTypingTimeout(null);
    setOpen(false);
    props.onInputBlur?.(e);
    setIsFocus(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.isLoadingSearch && inputSearch) {
      props.onInputSubmit?.(e);

      e.target.querySelector("input").blur();
    }
  };

  const handleClickInput = (e: any) => {
    props.onInputClick?.(e);
    setIsFocus(true);

    if (props.textSearch) {
      setOpen(true);
    }
  };

  return (
    <div
      aria-hidden="true"
      className={props.className}
      // styles
      style={{ position: "relative", ...(props.style || {}) }}
      // callback
      onMouseOver={() => (segmentHoverRef.current = true)}
      onClick={() => (segmentHoverRef.current = true)}
      onMouseLeave={() => (segmentHoverRef.current = false)}
      onFocus={() => (segmentHoverRef.current = true)}
    >
      <form onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          className="location-input-search"
          value={inputSearch}
          icon
          loading={props.isLoadingSearch}
          placeholder={props.placeholder}
          onChange={handleSearch}
          onClick={handleClickInput}
        >
          {!props.hideButtonFilter && (
            <ButtonSort
              reverse={props.iconRightReverse}
              onClick={props.onRightIconClick}
              rightIcon={props.rightIcon}
              rightIconColor={props.rightIconColor}
            />
          )}

          <input style={{ background: COLOR.background_card }} />

          <Icon
            name="search"
            style={{ position: "absolute", left: 0, fontSize: "17px" }}
          />
        </Input>
      </form>

      {isFocus && !props.hideSearchList && !props.hideNotFound && (
        <>
          <div
            aria-hidden="true"
            className="sb-div-floor"
            style={{ top: "-100vh" }}
            onClick={handleClose}
          ></div>
          <div
            aria-hidden="true"
            className="sb-div-floor"
            onClick={handleClose}
          ></div>
        </>
      )}

      {open && !props.hideSearchList && (
        <>
          <Icon
            name="close"
            circular
            style={{
              position: "absolute",
              right: "45px",
              top: "12px",
              fontSize: "10px",
              backgroundColor: COLOR.dark_grey,
              color: COLOR.white,
            }}
            onClick={(e: any) => {
              setInputSearch("");
              props.onSearch?.("");
              handleClose(e);

              props.onClear?.();
            }}
          />

          {!(props.hideNotFound && !props.list?.[0]) && (
            <div className="sb-search-doctor" style={{ position: "absolute" }}>
              {props.list?.[0] ? (
                <ul>
                  {props.list.map((item: any, index: number) => (
                    <MuiButton
                      key={"list-" + item.id || index}
                      variant="text"
                      color="primary"
                      style={{
                        textAlign: "left",
                        textTransform: "unset",
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                      onClick={() => props.onSelect?.(item)}
                    >
                      <li>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Avatar
                            alt={"profile-" + index}
                            src={getAvatar(item)}
                            style={{
                              width: "50px",
                              height: "50px",
                              marginTop: "3px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ lineHeight: "23px" }}>
                            <div
                              style={{
                                fontSize: "16px",
                                color: COLOR.blue,
                                fontWeight: "bold",
                              }}
                            >
                              {item.is_doctor ? item.full_name : item.name}
                            </div>
                            <div style={{ color: COLOR.dark_grey }}>
                              {getSubTitle(item)}
                            </div>
                          </div>
                        </div>
                      </li>
                    </MuiButton>
                  ))}
                </ul>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "15px 0",
                  }}
                >
                  {props.isLoadingSearch ? "กำลังค้นหา..." : "ไม่พบข้อมูล"}
                </div>
              )}
              <div
                aria-hidden="true"
                className="see-all"
                style={{
                  padding: "5px",
                  fontSize: "16px",
                  color: COLOR.blue,
                  display: "flex",
                }}
                onClick={props.onSeeAllClick}
              >
                <div style={{ minWidth: "95px" }}>
                  {" "}
                  <FormattedMessage id="bplusClinicKey442" />
                </div>
                <label
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                    overflowWrap: "anywhere",
                  }}
                >
                  {props.textSearch}
                </label>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ForwardedSearchBox = forwardRef<any, SearchBoxProps>(SearchBox);

ForwardedSearchBox.defaultProps = {
  textSearch: "",
  list: [],
  isLoadingSearch: false,
  onSearch: () => {},
  onSelect: () => {},
  onRightIconClick: () => {},
  style: {},
  hideButtonFilter: false,
  hideSearchList: false,
  onSeeAllClick: () => {},
  placeholder: "",
  rightIconColor: COLOR.blue,
  onInputClick: () => {},
  onInputBlur: () => {},
} as SearchBoxProps;

export default React.memo(ForwardedSearchBox);
