import React from "react"
import AdminController from "./AdminController";
import * as AdminI from "./AdminInterface";
import SetProp from "react-lib/frameworks/SetProp";
import LoginForm from './login/LoginForm';
import TopBarClinic from "./manage/TopBarClinic";
import { defaultAppConfig } from "./appConfig";
import Cookies from 'js-cookie';
import TabPath from 'react-lib/frameworks/TabPath';
import TagManager from 'react-lib/frameworks/TagManager';
import Content from './content/Content';
import { withRouter } from 'react-router-dom'
import axios from "axios";

class Admin extends React.Component<any, AdminI.State> {
  
  controller: AdminController;
  appConfig: any
  
  constructor(props: any) {
    super(props);
    this.state = { ...AdminI.StateInitial  }
    this.controller = new AdminController(
      () => { return this.state },
      (state: AdminI.State, callback: any) => { this.setState(state, callback) },
      window
    )
    this.appConfig = defaultAppConfig;
    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} })
    // axios.get("http://localhost:8000/test-content")
    // .then((res: any) => {
    //   console.log(res.data);
    // })
    // .catch((err: any) => {
    //   console.log(err);
    // })
  }

  render = () => {
    return(
      !this.state.loggedin ?
      <LoginForm 
        appConfig={this.appConfig}
        onEvent={this.controller.handleEvent}
      />: 
        
      <div 
        style={{ 
          display: "flex", 
          flexDirection: "column", 
          width: "100%", 
          height: "100%" 
        }}>
        <div style={{ height: "94vh" }}>
          <TopBarClinic
            appConfig={this.appConfig}
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            history={this.props.history}
            style={{ backgroundColor: "black" }}
            hideSearch={true}
          />
          <div style={{ flex: 1, overflowY: "scroll" }}>
            <TabPath
              level={1}
              tabWidth="100px"
              history={this.props.history}
              data={[
                { name: "Content", key: "content", render: 
                  () => (
                    <Content
                      onEvent={this.controller.handleEvent}
                      setProp={this.controller.setProp}
                      history={this.props.history}
                      contentSummary={this.state.contentSummary}
                      contentOk={this.state.contentOk}
                      contentUnique={this.state.contentUnique}
                      termList={this.state.termList}
                      contentList={this.state.contentList}
                      selectedContentIndex={this.state.selectedContentIndex}
                      tagList={this.state.tagList}
                      domainList={this.state.domainList}
                      domainData={this.state.domainData}
                      contentDomain={this.state.contentDomain}
                    />
                  )
                },
                { name: "Tag", key: "tagmanager", render: 
                  () => (
                    <div style={{ height: "100%"}} >
                      <TagManager
                        tagList={this.state.tagList}
                        selectedTagIndex={this.state.selectedTagIndex}
                        setProp={this.controller.setProp}
                        onEvent={this.controller.handleEvent}
                      />
                    </div>
                  )
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
  
}

export default withRouter(Admin);