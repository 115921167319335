import React from "react";
import { Image } from "semantic-ui-react";

// Use styles
import "./TeleDesktopCSS.scss";

type TeleLoginProps = {};

const TeleLogin = (props: TeleLoginProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div>
        <Image src={"/images/expansion/logo_192.png"} />
      </div>
    </div>
  );
};

export default React.memo(TeleLogin);
