import React, {
  ReactChild,
  useState,
  useEffect,
  useMemo,
  CSSProperties,
} from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { formatDateByLanguage } from "bplus-lib/MobClinicInterface";

type ChildGrowthChartProps = {
  type: "weight" | "height" | "weight_height" | "head";
  gender: "male" | "female" | "";
  selectedPatient: any;
  growthHistoryList: any;
  showBottomArrow?: boolean;
  height?: number;
  hideUnderTitleChart?: boolean;
};

type AgeWeightChartProps = {
  height?: number;
  hideUnderTitle?: boolean;
  hideUnderTitleChart?: boolean;
} & Pick<
  ChildGrowthChartProps,
  "selectedPatient" | "growthHistoryList" | "showBottomArrow"
>;

type LineChartProps = {
  title: string | ReactChild;
  data: { labels: any[]; datasets: any[] };
  options: any;
  underTitle?: string | ReactChild;
  hideUnderTile?: boolean;
} & Pick<
  ChildGrowthChartProps,
  "selectedPatient" | "height" | "hideUnderTitleChart"
>;

export const styles = {
  TextTitle: {
    marginLeft: "3%",
    paddingBottom: "15px",
    fontWeight: "bold",
    color: "#1621A8",
  } as CSSProperties,
  TextTitleUnder: {
    color: "#1621A8",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "-20px",
  } as CSSProperties,
};

const labels = [
  45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
];

const ageLabels = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
];

const datasetKeyProvider = (d: any) => {
  return d.key;
};

// Utils
const getChartData = (params: {
  list: any[];
  patient: any;
  yField: "weight" | "height" | "head";
  type: "age_weight" | "age_length" | "weight_length" | "age_head";
  options: any;
  minMax: number;
  xField?: "height";
  intl: any;
}) => {
  const { list, patient, yField, options, minMax, xField, type, intl } = params;

  const mapList = (list || []).map((item: any) => ({
    ...item,
    month_year: moment(item.measure_date)
      .clone()
      .locale("en")
      .format("MMMM YYYY"),
    months: moment(item.measure_date).diff(
      moment(patient?.birthdate),
      "months"
    ),
  }));

  const key = "month_year";
  const arrayUniqueByKey: any[] = Array.from(
    new Map(mapList.map((item: any) => [item[key], item])).values()
  );

  console.log(arrayUniqueByKey);
  // Data
  let chartData = arrayUniqueByKey.map((item: any) => ({
    x: +(xField ? item[xField] : item.months),
    y: +item[yField],
    measure_date: item.measure_date,
  }));

  // Options
  const cloneOptions = JSON.parse(JSON.stringify(options));
  const lastWeight = arrayUniqueByKey.slice(-1)?.[0]?.[yField];

  if (lastWeight) {
    cloneOptions.scales.yAxes[0].ticks.max =
      +lastWeight > minMax ? +lastWeight : minMax;
  }

  const tooltipLabel = {
    age_weight: {
      xLabel: `${intl.formatMessage({ id: "profile.age" })}`,
      xUnit: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      yLabel: `${intl.formatMessage({ id: "bplusClinicKey529" })}`,
      yUnit: `${intl.formatMessage({ id: "bplusClinicKey572" })}`,
    },
    age_length: {
      xLabel: `${intl.formatMessage({ id: "profile.age" })}`,
      xUnit: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      yLabel: `${intl.formatMessage({ id: "bplusClinicKey795" })}`,
      yUnit: `${intl.formatMessage({ id: "bplusClinicKey558" })}`,
    },
    weight_length: {
      xLabel: `${intl.formatMessage({ id: "bplusClinicKey529" })}`,
      xUnit: `${intl.formatMessage({ id: "bplusClinicKey572" })}`,
      yLabel: `${intl.formatMessage({ id: "bplusClinicKey795" })}`,
      yUnit: `${intl.formatMessage({ id: "bplusClinicKey558" })}`,
    },
    age_head: {
      xLabel: `${intl.formatMessage({ id: "profile.age" })}`,
      xUnit: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      yLabel: `${intl.formatMessage({ id: "bplusClinicKey797" })}`,
      yUnit: `${intl.formatMessage({ id: "bplusClinicKey558" })}`,
    },
  }[type];

  cloneOptions.tooltips = {
    callbacks: {
      title: (tooltipItem: any[], data: any) => {
        const tooltip = tooltipItem[0];
        const item = data.datasets[tooltip.datasetIndex].data[tooltip.index];
        let x = item.x;
        let y = item.y;

        if (typeof item.x === "undefined") {
          return;
        }
        if (type === "weight_length") {
          x = item.y;
          y = item.x;
        }

        return [
          `${formatDateByLanguage(
            moment(item.measure_date).format("DD/MM/YYYY"),
            "DD MMMM YYYY"
          )}`,
          `- ${tooltipLabel.xLabel}: ${x} ${tooltipLabel.xUnit}`,
          `- ${tooltipLabel.yLabel}: ${y} ${tooltipLabel.yUnit}`,
        ];
      },
      label: (tooltipItem: any, data: any) => {
        return null;
      },
    },
  };

  return {
    data: chartData,
    options: cloneOptions,
  };
};

const ChildGrowthChart: React.FunctionComponent<ChildGrowthChartProps> = (
  props
) => {
  return (
    <div>
      {props.type === "weight" && (
        <>
          {props.gender === "male" && (
            <AgeWeightMaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
          {props.gender === "female" && (
            <AgeWeightFemaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
        </>
      )}

      {props.type === "height" && (
        <>
          {props.gender === "male" && (
            <AgeHeightMaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
          {props.gender === "female" && (
            <AgeHeightFemaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
        </>
      )}

      {props.type === "weight_height" && (
        <>
          {props.gender === "male" && (
            <WeightHeightMaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
          {props.gender === "female" && (
            <WeightHeightFemaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
        </>
      )}

      {props.type === "head" && (
        <>
          {props.gender === "male" && (
            <AgeHeadMaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
          {props.gender === "female" && (
            <AgeHeadFemaleChart
              selectedPatient={props.selectedPatient}
              growthHistoryList={props.growthHistoryList}
              showBottomArrow={props.showBottomArrow}
              height={props.height}
              hideUnderTitleChart={props.hideUnderTitleChart}
            />
          )}
        </>
      )}
    </div>
  );
};

/* TODO ---------------------- LineChart --------------------- */
const LineChart: React.FunctionComponent<LineChartProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(!loading);
  }, [props.data, props.options]);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-linechart",
        afterDraw: (chart: any) => {
          if (props.hideUnderTile && props.selectedPatient?.birthdate) {
            const textAt = moment().diff(
              moment(props.selectedPatient.birthdate),
              "months"
            );
            // var textAt = 3;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            let beginX = (textAt / xAxe.max) * xAxe.width + xAxe.left;
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();
            ctxPlugin.moveTo(beginX - 5, yAxe.bottom + 30);
            ctxPlugin.lineTo(beginX, yAxe.bottom + 20);
            ctxPlugin.lineTo(beginX + 5, yAxe.bottom + 30);
            ctxPlugin.closePath();
            ctxPlugin.strokeStyle = "#1c99f3";
            ctxPlugin.stroke();
            ctxPlugin.fillStyle = "#1c99f3";
            ctxPlugin.fill();
            ctxPlugin.font = "bold 14px serif";
            ctxPlugin.fillText("Current Age", beginX - 37.5, yAxe.bottom + 45);
            ctxPlugin.restore();
          }
        },
      },
    ];
  }, []);

  return (
    <div>
      <div style={styles.TextTitle}>{props.title}</div>
      <div>
        {useMemo(
          () =>
            loading ? (
              <Line
                key={"line-true"}
                data={props.data}
                options={props.options}
                height={props.height ?? 160}
                datasetKeyProvider={datasetKeyProvider}
                plugins={plugins}
                redraw={true}
              />
            ) : (
              <Line
                key={"line-false"}
                data={props.data}
                options={props.options}
                height={props.height ?? 160}
                datasetKeyProvider={datasetKeyProvider}
                plugins={plugins}
                redraw={true}
              />
            ),
          [props.data, props.options]
        )}
      </div>
      {!props.hideUnderTile && !props.hideUnderTitleChart && (
        <div style={styles.TextTitleUnder}>
          {props.underTitle || <FormattedMessage id="bplusClinicKey137" />}
        </div>
      )}
    </div>
  );
};

/* TODO ------------------- AgeWeightMaleChart ------------------- */
export const AgeWeightMaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [weightData, setWeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsWeight);
  // Boy
  // boy weight chart

  useEffect(() => {
    const data = getChartData({
      type: "age_weight",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "weight",
      options: optionsWeight,
      minMax: 18,
      intl: intl,
    });

    setWeightData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Orange
          data: [
            2.4, 3.3, 4.2, 4.9, 5.5, 5.9, 6.3, 6.6, 6.8, 7.0, 7.3, 7.5, 7.6,
            7.8, 8.0, 8.2, 8.3, 8.5, 8.7, 8.8, 9.0, 9.1, 9.3, 9.4, 9.6,
          ],
          fill: true,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239,131,24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Lightgreen
          data: [
            2.6, 3.5, 4.5, 5.2, 5.8, 6.2, 6.6, 6.9, 7.2, 7.5, 7.7, 7.9, 8.1,
            8.3, 8.5, 8.6, 8.8, 9.0, 9.2, 9.3, 9.5, 9.7, 9.8, 10.0, 10.1,
          ],
          fill: true,
          backgroundColor: "rgba(160, 242, 151, 1)",
          borderColor: "rgba(160, 242, 151, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            4.1, 5.4, 6.7, 7.6, 8.3, 8.8, 9.3, 9.8, 10.1, 10.5, 10.8, 11.1,
            11.4, 11.6, 11.9, 12.2, 12.4, 12.7, 12.9, 13.2, 13.4, 13.7, 13.9,
            14.2, 14.4,
          ],
          fill: true,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Oldgreen
          data: [
            4.4, 5.8, 7.1, 8.0, 8.7, 9.3, 9.8, 10.3, 10.7, 11.0, 11.4, 11.7,
            12.0, 12.3, 12.6, 12.8, 13.1, 13.4, 13.7, 13.9, 14.2, 14.5, 14.7,
            15.0, 15.3,
          ],
          fill: true,
          backgroundColor: "rgba(67, 119, 61, 1)",
          borderColor: "rgba(67, 119, 61, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey534" />}
      data={weightData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

/* TODO ---------------- AgeWeightFemaleChart; --------------- */
export const AgeWeightFemaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [weightData, setWeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsWeight);
  // SET DATA LINE
  // Girl
  // girl weight chart
  // const dataWeightGirl =
  // const dataWeightGirl =

  useEffect(() => {
    const data = getChartData({
      type: "age_weight",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "weight",
      options: optionsWeight,
      minMax: 18,
      intl: intl,
    });

    setWeightData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Orange
          data: [
            2.3, 3.1, 3.8, 4.4, 4.9, 5.3, 5.6, 5.9, 6.2, 6.4, 6.6, 6.8, 6.9,
            7.1, 7.3, 7.5, 7.6, 7.8, 8.0, 8.1, 8.3, 8.5, 8.6, 8.8, 8.9,
          ],
          fill: true,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239,131,24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Lightgreen
          data: [
            2.5, 3.3, 4.1, 4.7, 5.2, 5.6, 6.0, 6.3, 6.5, 6.8, 7.0, 7.2, 7.3,
            7.6, 7.7, 7.9, 8.1, 8.3, 8.4, 8.6, 8.8, 9.0, 9.1, 9.3, 9.5,
          ],
          fill: true,
          backgroundColor: "rgba(160, 242, 151, 1)",
          borderColor: "rgba(160, 242, 151, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            4.0, 5.1, 6.2, 7.1, 7.7, 8.3, 8.8, 9.2, 9.6, 9.9, 10.2, 10.5, 10.7,
            11.1, 11.3, 11.6, 11.9, 12.1, 12.4, 12.6, 12.9, 13.1, 13.4, 13.6,
            13.9,
          ],
          fill: true,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },

        {
          // Oldgreen
          data: [
            4.2, 5.5, 6.6, 7.5, 8.2, 8.8, 9.3, 9.8, 10.2, 10.5, 10.9, 11.2,
            11.5, 11.8, 12.1, 12.4, 12.6, 12.9, 13.2, 13.5, 13.7, 14.0, 14.3,
            14.6, 14.8,
          ],
          fill: true,
          backgroundColor: "rgba(67, 119, 61, 1)",
          borderColor: "rgba(67, 119, 61, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey534" />}
      data={weightData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

/* TODO ----------------- AgeHeightMaleChart; ---------------- */
export const AgeHeightMaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [heightData, setHeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsHeight);
  // boy height chart

  useEffect(() => {
    const data = getChartData({
      type: "age_length",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "height",
      options: optionsHeight,
      minMax: 95,
      intl: intl,
    });

    setHeightData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Orange
          data: [
            46, 50.7, 54.3, 57.2, 59.6, 61.6, 63.2, 64.7, 66.1, 67.4, 68.6,
            69.8, 70.9, 72.0, 73.0, 74.0, 74.9, 75.9, 76.8, 77.6, 78.5, 79.3,
            80.1, 80.9, 81.6,
          ],
          fill: true,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239,131,24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Lightgreen
          data: [
            46.9, 51.6, 55.3, 58.2, 60.7, 62.6, 64.3, 65.8, 67.2, 68.5, 69.7,
            71.0, 72.1, 73.2, 74.2, 75.2, 76.2, 77.2, 78.1, 79.0, 79.9, 80.7,
            81.5, 82.3, 83.1,
          ],
          fill: true,
          backgroundColor: "rgba(160, 242, 151, 1)",
          borderColor: "rgba(160, 242, 151, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            52.7, 57.6, 61.4, 64.5, 67.0, 69.1, 70.8, 72.4, 73.9, 75.3, 76.7,
            78, 79.3, 80.6, 81.8, 82.9, 84.1, 85.2, 86.3, 87.4, 88.4, 89.4,
            90.4, 91.4, 92.4,
          ],
          fill: true,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Oldgreen
          data: [
            53.7, 58.6, 62.4, 65.5, 68.0, 70.1, 71.9, 73.5, 75.0, 76.5, 77.9,
            79.2, 80.5, 81.8, 83.0, 84.2, 85.4, 86.5, 87.7, 88.8, 89.8, 90.9,
            91.9, 92.9, 93.9,
          ],
          fill: true,
          backgroundColor: "rgba(67, 119, 61, 1)",
          borderColor: "rgba(67, 119, 61, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey237" />}
      data={heightData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

/* TODO ---------------- AgeHeightFemaleChart; --------------- */
export const AgeHeightFemaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [heightData, setHeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsHeight);
  // girl height chart
  useEffect(() => {
    const data = getChartData({
      type: "age_length",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "height",
      options: optionsHeight,
      minMax: 95,
      intl: intl,
    });

    setHeightData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Orange
          data: [
            45.3, 49.7, 52.9, 55.5, 57.7, 59.5, 61.1, 62.6, 63.9, 65.2, 66.4,
            67.6, 68.8, 69.9, 70.9, 71.9, 72.9, 73.9, 74.8, 75.7, 76.6, 77.4,
            78.3, 79.1, 79.9,
          ],
          fill: true,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239,131,24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Lightgreen
          data: [
            46.3, 50.6, 53.9, 56.5, 58.8, 60.6, 62.2, 63.7, 65.1, 66.4, 67.7,
            68.9, 70.0, 71.2, 72.3, 73.3, 74.3, 75.3, 76.3, 77.2, 78.1, 79.0,
            79.8, 80.7, 81.5,
          ],
          fill: true,
          backgroundColor: "rgba(160, 242, 151, 1)",
          borderColor: "rgba(160, 242, 151, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            51.9, 56.6, 60.1, 62.9, 65.4, 67.3, 69.1, 70.8, 72.3, 73.8, 75.2,
            76.6, 77.9, 79.2, 80.4, 81.6, 82.8, 83.9, 85.1, 86.1, 87.2, 88.3,
            89.3, 90.3, 91.2,
          ],
          fill: true,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Oldgreen
          data: [
            52.9, 57.6, 61.1, 64.0, 66.4, 68.5, 70.3, 71.9, 73.5, 75.0, 76.4,
            77.8, 79.2, 80.5, 81.7, 83.0, 84.2, 85.4, 86.5, 87.6, 88.7, 89.8,
            90.8, 91.9, 92.9,
          ],
          fill: true,
          backgroundColor: "rgba(67, 119, 61, 1)",
          borderColor: "rgba(67, 119, 61, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey237" />}
      data={heightData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

/* TODO ---------------- WeightHeightMakeChart --------------- */
export const WeightHeightMaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [weightHeightData, setWeightHeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsWeight);
  // boy Weight_Height chart

  useEffect(() => {
    const data = getChartData({
      type: "weight_length",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "weight",
      xField: "height",
      options: optionsWeight,
      minMax: 28,
      intl: intl,
    });

    const xLabel = data.data.map((item) => item.x);

    const heightLabel = labels
      .map((label) => {
        const find = xLabel.find(
          (x) => Math.floor(x) === label && !Number.isInteger(x)
        );
        return find ? [label, find] : label;
      })
      .flat();

    const prepareDatasets = [
      {
        // point data
        data: data.data,
        fill: false,
        backgroundColor: "#0013FF",
        borderColor: "#2399E5",
        tension: 0.1,
        radius: 3,
        showLine: false,
      },
      {
        // Orange
        data: [
          1.9, 2.1, 2.2, 2.4, 2.5, 2.7, 2.9, 3.1, 3.3, 3.5, 3.7, 4.0, 4.2, 4.5,
          4.7, 5.0, 5.2, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3, 7.5,
          7.6, 7.8, 8.0, 8.2, 8.3, 8.5, 8.6, 8.8, 9.0, 9.1, 9.3, 9.5, 9.7, 9.9,
          10.1, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4, 11.6, 11.8, 12.0, 12.2,
          12.4, 12.6, 12.8, 13.1, 13.3, 13.5, 13.8, 14.0, 14.3, 14.5, 14.8,
          15.0, 15.3,
        ],
        fill: true,
        backgroundColor: "rgba(239, 131, 24, 1)",
        borderColor: "rgba(239,131,24, 1)",
        showLine: true,
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        radius: 0,
      },
      {
        // Lightgreen
        data: [
          2.0, 2.2, 2.3, 2.5, 2.6, 2.8, 3.0, 3.2, 3.4, 3.7, 3.9, 4.2, 4.4, 4.7,
          4.9, 5.2, 5.4, 5.7, 5.9, 6.1, 6.3, 6.6, 6.8, 7.0, 7.2, 7.4, 7.6, 7.8,
          7.9, 8.1, 8.3, 8.5, 8.7, 8.8, 9.0, 9.1, 9.3, 9.5, 9.7, 9.9, 10.1,
          10.3, 10.5, 10.8, 11.0, 11.2, 11.4, 11.6, 11.8, 12.0, 12.2, 12.5,
          12.7, 12.9, 13.1, 13.3, 13.6, 13.8, 14.1, 14.3, 14.6, 14.9, 15.1,
          15.4, 15.7, 16.0,
        ],
        fill: true,
        backgroundColor: "rgba(160, 242, 151, 1)",
        borderColor: "rgba(160, 242, 151, 1)",
        showLine: true,
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        radius: 0,
      },
      {
        // Green
        data: [
          2.8, 3.0, 3.2, 3.4, 3.6, 3.8, 4.1, 4.3, 4.6, 4.9, 5.2, 5.5, 5.8, 6.2,
          6.5, 6.8, 7.1, 7.4, 7.7, 8.0, 8.2, 8.5, 8.8, 9.0, 9.3, 9.6, 9.8, 10.1,
          10.3, 10.6, 10.8, 11.0, 11.2, 11.5, 11.7, 11.9, 12.1, 12.3, 12.5,
          12.8, 13.0, 13.3, 13.6, 13.9, 14.1, 14.4, 14.7, 14.9, 15.2, 15.4,
          15.7, 16.0, 16.3, 16.6, 16.9, 17.2, 17.5, 17.9, 18.2, 18.6, 18.9,
          19.3, 19.7, 20.1, 20.5, 20.9,
        ],
        fill: true,
        backgroundColor: "rgba(127, 194, 120, 1)",
        borderColor: "rgba(127, 194, 120, 1)",
        showLine: true,
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        radius: 0,
      },
      {
        // Oldgreen
        data: [
          3.0, 3.1, 3.3, 3.6, 3.8, 4.0, 4.2, 4.5, 4.8, 5.1, 5.4, 5.8, 6.1, 6.4,
          6.8, 7.1, 7.4, 7.7, 8.0, 8.3, 8.6, 8.9, 9.2, 9.4, 9.7, 10.0, 10.2,
          10.5, 10.8, 11.0, 11.3, 11.5, 11.7, 12.0, 12.2, 12.4, 12.6, 12.8,
          13.1, 13.3, 13.6, 13.9, 14.2, 14.5, 14.7, 15.0, 15.3, 15.6, 15.8,
          16.1, 16.4, 16.7, 17.0, 17.3, 17.6, 18.0, 18.3, 18.7, 19.0, 19.4,
          19.8, 20.2, 20.6, 21.0, 21.4, 21.9,
        ],
        fill: true,
        backgroundColor: "rgba(67, 119, 61, 1)",
        borderColor: "rgba(67, 119, 61, 1)",
        showLine: true,
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        radius: 0,
      },
      {
        // purple
        data: [
          3.3, 3.5, 3.7, 3.9, 4.2, 4.4, 4.7, 5.0, 5.3, 5.6, 6.0, 6.3, 6.7, 7.1,
          7.4, 7.8, 8.1, 8.5, 8.8, 9.1, 9.4, 9.7, 10.0, 10.3, 10.6, 10.9, 11.2,
          11.5, 11.8, 12.1, 12.3, 12.6, 12.8, 13.1, 13.3, 13.6, 13.8, 14.0,
          14.3, 14.6, 14.9, 15.2, 15.5, 15.8, 16.1, 16.4, 16.7, 17.0, 17.3,
          17.6, 17.9, 18.2, 18.5, 18.9, 19.2, 19.6, 20.0, 20.4, 20.8, 21.2,
          21.7, 22.1, 22.6, 23.1, 23.6, 24.1,
        ],
        fill: true,
        backgroundColor: "#E2D0E5",
        borderColor: "#E2D0E5",
        showLine: true,
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        radius: 0,
      },
    ];

    setWeightHeightData({
      labels: heightLabel,
      datasets: prepareDatasets.map((item) => {
        return { ...item, data: item.data.slice(0, heightLabel.length) };
      }),
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  console.log("male", options, weightHeightData);
  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="weight.weight_unit" />}
      data={weightHeightData}
      options={options}
      underTitle={<FormattedMessage id="bplusClinicKey293" />}
    />
  );
};

/* TODO --------------- WeightHeightFemaleChart -------------- */
export const WeightHeightFemaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [weightHeightData, setWeightHeightData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsWeight);
  // girl Weight_Height chart

  useEffect(() => {
    const data = getChartData({
      type: "weight_length",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "weight",
      xField: "height",
      options: optionsWeight,
      minMax: 28,
      intl: intl,
    });

    const xLabel = data.data.map((item) => item.x);

    const heightLabel = labels
      .map((label) => {
        const find = xLabel.find(
          (x) => Math.floor(x) === label && !Number.isInteger(x)
        );
        return find ? [label, find] : label;
      })
      .flat();

    setWeightHeightData({
      labels: heightLabel,
      datasets: [
        {
          // point data
          data: data.data,
          //weightData,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          spanGaps: true,
          showLine: false,
        },
        {
          // Orange
          data: [
            2.0, 2.1, 2.3, 2.4, 2.5, 2.7, 2.9, 3.1, 3.3, 3.5, 3.7, 3.9, 4.2,
            4.4, 4.6, 4.8, 5.0, 5.2, 5.4, 5.6, 5.8, 6.0, 6.2, 6.4, 6.6, 6.8,
            6.9, 7.1, 7.3, 7.4, 7.6, 7.7, 7.9, 8.1, 8.2, 8.4, 8.6, 8.7, 8.9,
            9.1, 9.3, 9.6, 9.8, 10.0, 10.2, 10.4, 10.6, 10.8, 11.0, 11.2, 11.4,
            11.6, 11.9, 12.1, 12.3, 12.5, 12.7, 13.0, 13.2, 13.5, 13.7, 14.0,
            14.3, 14.6, 14.9, 15.2,
          ],
          fill: true,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239,131,24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Lightgreen
          data: [
            2.1, 2.2, 2.4, 2.5, 2.7, 2.8, 3.0, 3.2, 3.4, 3.7, 3.9, 4.1, 4.3,
            4.6, 4.8, 5.0, 5.3, 5.5, 5.7, 5.9, 6.1, 6.3, 6.5, 6.7, 6.9, 7.0,
            7.2, 7.4, 7.6, 7.8, 7.9, 8.1, 8.2, 8.4, 8.6, 8.8, 8.9, 9.1, 9.3,
            9.5, 9.8, 10.0, 10.2, 10.4, 10.6, 10.8, 11.1, 11.3, 11.5, 11.7,
            11.9, 12.1, 12.4, 12.6, 12.8, 13.1, 13.3, 13.6, 13.8, 14.1, 14.4,
            14.6, 14.9, 15.2, 15.6, 15.9,
          ],
          fill: true,
          backgroundColor: "rgba(160, 242, 151, 1)",
          borderColor: "rgba(160, 242, 151, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            2.8, 3.0, 3.2, 3.4, 3.6, 3.9, 4.1, 4.4, 4.6, 4.9, 5.2, 5.5, 5.9,
            6.2, 6.5, 6.8, 7.1, 7.3, 7.6, 7.9, 8.1, 8.4, 8.7, 8.9, 9.2, 9.4,
            9.6, 9.8, 10.1, 10.3, 10.5, 10.7, 10.9, 11.1, 11.4, 11.6, 11.8,
            12.1, 12.3, 12.6, 12.9, 13.2, 13.4, 13.7, 14.0, 14.3, 14.6, 14.9,
            15.2, 15.5, 15.7, 16.0, 16.3, 16.6, 17.0, 17.3, 17.6, 18.0, 18.3,
            18.7, 19.1, 19.5, 19.9, 20.3, 20.7, 21.2,
          ],
          fill: true,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Oldgreen
          data: [
            3.0, 3.2, 3.4, 3.6, 3.8, 4.0, 4.3, 4.6, 4.9, 5.2, 5.5, 5.8, 6.1,
            6.5, 6.8, 7.1, 7.4, 7.7, 8.0, 8.3, 8.6, 8.8, 9.1, 9.4, 9.6, 9.9,
            10.1, 10.3, 10.6, 10.8, 11.0, 11.2, 11.5, 11.7, 11.9, 12.1, 12.4,
            12.6, 12.9, 13.2, 13.5, 13.8, 14.1, 14.4, 14.7, 15.0, 15.3, 15.6,
            15.9, 16.2, 16.5, 16.8, 17.1, 17.5, 17.8, 18.1, 18.5, 18.9, 19.2,
            19.6, 20.0, 20.5, 20.9, 21.3, 21.8, 22.3,
          ],
          fill: true,
          backgroundColor: "rgba(67, 119, 61, 1)",
          borderColor: "rgba(67, 119, 61, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // purple
          data: [
            3.3, 3.5, 3.7, 4.0, 4.2, 4.5, 4.8, 5.1, 5.4, 5.7, 6.1, 6.4, 6.8,
            7.1, 7.5, 7.8, 8.2, 8.5, 8.8, 9.1, 9.5, 9.8, 10.0, 10.3, 10.6, 10.9,
            11.1, 11.4, 11.7, 11.9, 12.2, 12.4, 12.6, 12.9, 13.1, 13.4, 13.7,
            13.9, 14.2, 14.5, 14.9, 15.2, 15.5, 15.9, 16.2, 16.5, 16.9, 17,
            17.5, 17.9, 18.2, 18.6, 18.9, 19.3, 19.6, 20.0, 20.4, 20.8, 21.3,
            21.7, 22.2, 22.6, 23.1, 23.6, 24.2, 24.7,
          ],
          fill: true,
          backgroundColor: "#E2D0E5",
          borderColor: "#E2D0E5",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="weight.weight_unit" />}
      data={weightHeightData}
      options={options}
      underTitle={<FormattedMessage id="bplusClinicKey293" />}
    />
  );
};

/* TODO ------------------ AgeHeadMaleChart ------------------ */
export const AgeHeadMaleChart: React.FunctionComponent<AgeWeightChartProps> = (
  props
) => {
  const intl = useIntl();
  const [headData, setHeadData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsHead);

  // boy head chart

  useEffect(() => {
    const data = getChartData({
      type: "age_head",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "head",
      options: optionsHeight,
      minMax: 52,
      intl: intl,
    });

    setHeadData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Red
          data: [
            32.1, 35.1, 36.9, 38.3, 39.4, 40.3, 41.0, 41.7, 42.2, 42.6, 43.0,
            43.4, 43.6, 43.9, 44.1, 44.3, 44.5, 44.7, 44.9, 45.0, 45.2, 45.3,
            45.4, 45.6, 45.7,
          ],
          fill: false,
          backgroundColor: "#EE1C24",
          borderColor: "#EE1C24",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Orange
          data: [
            33.1, 36.1, 37.9, 39.3, 40.4, 41.3, 42.1, 42.7, 43.2, 43.7, 44.1,
            44.4, 44.7, 45.0, 45.2, 45.5, 45.6, 45.8, 46.0, 46.2, 46.3, 46.4,
            46.6, 46.7, 46.8,
          ],
          fill: false,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239, 131, 24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            34.5, 37.3, 39.1, 40.5, 41.6, 42.6, 43.3, 44.0, 44.5, 45.0, 45.4,
            45.8, 46.1, 46.3, 46.6, 46.8, 47.0, 47.2, 47.4, 47.5, 47.7, 47.8,
            48.0, 48.1, 48.3,
          ],
          fill: false,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Orange
          data: [
            35.8, 38.5, 40.3, 41.7, 42.9, 43.8, 44.6, 45.3, 45.8, 46.3, 46.7,
            47.1, 47.4, 47.7, 47.9, 48.2, 48.4, 48.6, 48.7, 48.9, 49.1, 49.2,
            49.4, 49.5, 49.7,
          ],
          fill: false,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239, 131, 24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Red
          data: [
            36.9, 39.5, 41.3, 42.7, 43.9, 44.8, 45.6, 46.3, 46.9, 47.4, 47.8,
            48.2, 48.5, 48.8, 49.0, 49.3, 49.5, 49.7, 49.9, 50.0, 50.2, 50.4,
            50.5, 50.7, 50.8,
          ],
          fill: false,
          backgroundColor: "#EE1C24",
          borderColor: "#EE1C24",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });

    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey235" />}
      data={headData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

/* TODO ----------------- AgeHeadFemaleChart ---------------- */
export const AgeHeadFemaleChart: React.FunctionComponent<
  AgeWeightChartProps
> = (props) => {
  const intl = useIntl();
  const [headData, setHeadData] = useState<any>({});
  const [options, setOptions] = useState<any>(optionsHead);
  // girl head chart

  useEffect(() => {
    const data = getChartData({
      type: "age_head",
      list: props.growthHistoryList?.items,
      patient: props.selectedPatient,
      yField: "head",
      options: optionsHead,
      minMax: 52,
      intl: intl,
    });
    setHeadData({
      labels: ageLabels,
      datasets: [
        {
          // point data
          data: data.data,
          fill: false,
          backgroundColor: "#0013FF",
          borderColor: "#2399E5",
          tension: 0.1,
          radius: 3,
          showLine: false,
        },
        {
          // Red
          data: [
            31.7, 34.3, 36.0, 37.2, 38.2, 39.0, 39.7, 40.4, 40.9, 41.3, 41.7,
            42.0, 42.3, 42.6, 42.9, 43.1, 43.3, 43.5, 43.6, 43.8, 44.0, 44.1,
            44.3, 44.4, 44.6,
          ],
          fill: false,
          backgroundColor: "#EE1C24",
          borderColor: "#EE1C24",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Orange
          data: [
            32.7, 35.3, 37.0, 38.2, 39.3, 40.1, 40.8, 41.5, 42.0, 42.4, 42.8,
            43.2, 43.5, 43.8, 44.0, 44.2, 44.4, 44.6, 44.8, 45.0, 45.1, 45.3,
            45.4, 45.6, 45.7,
          ],
          fill: false,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239, 131, 24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Green
          data: [
            33.9, 36.5, 38.3, 39.5, 40.6, 41.5, 42.2, 42.8, 43.4, 43.8, 44.2,
            44.6, 44.9, 45.2, 45.4, 45.7, 45.9, 46.1, 46.2, 46.4, 46.6, 46.7,
            46.9, 47.0, 47.2,
          ],
          fill: false,
          backgroundColor: "rgba(127, 194, 120, 1)",
          borderColor: "rgba(127, 194, 120, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Orange
          data: [
            35.1, 37.8, 39.5, 40.8, 41.9, 42.8, 43.5, 44.2, 44.7, 45.2, 45.6,
            46.0, 46.3, 46.6, 46.8, 47.1, 47.3, 47.5, 47.7, 47.8, 48.0, 48.2,
            48.3, 48.5, 48.6,
          ],
          fill: false,
          backgroundColor: "rgba(239, 131, 24, 1)",
          borderColor: "rgba(239, 131, 24, 1)",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
        {
          // Red
          data: [
            36.1, 38.8, 40.5, 41.9, 43.0, 43.9, 44.6, 45.3, 45.9, 46.3, 46.8,
            47.1, 47.5, 47.7, 48.0, 48.2, 48.5, 48.7, 48.8, 49.0, 49.2, 49.4,
            49.5, 49.7, 49.8,
          ],
          fill: false,
          backgroundColor: "#EE1C24",
          borderColor: "#EE1C24",
          showLine: true,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          radius: 0,
        },
      ],
    });
    setOptions(data.options);
  }, [props.growthHistoryList, props.selectedPatient]);

  return (
    <LineChart
      {...props}
      title={<FormattedMessage id="bplusClinicKey235" />}
      data={headData}
      options={options}
      hideUnderTile={props.showBottomArrow}
    />
  );
};

// set head graph
const optionsHead = {
  layout: {
    padding: {
      left: 20,
      right: 25,
      bottom: 10,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    legend: {
      display: false,
    },
    yAxes: [
      {
        ticks: {
          display: true,
          minRotation: 0,
          min: 32,
          max: 52,
          stepSize: 2,
          fontSize: 8,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontSize: 7,
          color: "#747474",
        },
      },
    ],
  },
};

// set height graph
const optionsHeight = {
  layout: {
    padding: {
      left: 20,
      right: 25,
      bottom: 10,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    legend: {
      display: false,
    },
    yAxes: [
      {
        ticks: {
          display: true,
          minRotation: 0,
          min: 40,
          max: 95,
          stepSize: 5,
          fontSize: 8,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontSize: 7,
          color: "#747474",
        },
      },
    ],
  },
};

// set weight graph
const optionsWeight = {
  responsive: true,
  layout: {
    padding: {
      left: 20,
      right: 20,
      bottom: 10,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    legend: {
      display: false,
    },
    yAxes: [
      {
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          max: 16,
          stepSize: 2,
          fontSize: 8,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontSize: 7,
          color: "#747474",
        },
      },
    ],
  },
};

export default ChildGrowthChart;
