import React from 'react'
import {Icon, SemanticICONS } from "semantic-ui-react";

const TopBarStyle = {
  backgroundColor: "#2083C1",
  padding: "5px",
  width: "100%",
  justifyContent: "space-between",
  minHeight: "40px",
  color: "white",
  display: "flex",
  alignItems: "center",
};

type MobHeadProps = {
 leftIconClick?: () => void; 
 leftIcon?: string;
 onEvent?: (arg0: { message: string, params: any;}) => void; 
 title?: any;
 rightIconClick?: () => void; 
 rightIcon?: SemanticICONS | undefined; 
 titleStyle?: any;
}

export const MobHead = (props: MobHeadProps) => { 
  console.log(" ModHead props: ", props)
  
  return (
  <div
    id="MobHead-nomenu"
    style={TopBarStyle}
    // onClick={(e) => { props.onEvent({ message: "DidLogin", params: {} })}}
  >
    <div
      style={{ flex: 1 }}
      onClick={() => {
        props.leftIconClick();
      }}
    >
      {props.leftIcon && <Icon name={props.leftIcon} style={{ marginLeft: "8px" }}/>}
    </div>
    <div
      style={{
        flex: 1,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "big",
        ...props.titleStyle
      }}
      onClick={(e) => {
        // props.onEvent({ message: "LoginFirebase", params: {} });
      }}
    >
      {props.title}
    </div>
    <div
      style={{ flex: 1, textAlign: "right" }}
      onClick={() => {
        props.rightIconClick();
      }}
    >
      {props.rightIcon && <Icon name={props.rightIcon} />}
      {/* <Icon name="bell" style={{ minWidth: "25px", margin: 0 }} />
      <Icon name="log out" style={{ minWidth: "25px", margin: 0 }} /> */}
    </div>
  </div>
)};

export default MobHead
