import React, { CSSProperties, useState } from "react";
import moment from "moment";
import {
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Segment,
} from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import { formatUTCtoMoment } from "react-lib/utils/dateUtils";

import { BACKEND_DATE_FORMAT } from "../MobClinicInterface";

// UI
import DMPersonalLabChart from "../clinic/DMPersonalLabChart";
import DMPersonalScoreChart from "../clinic/DMPersonalScoreChart";
import ChildOverallProgress from "../clinic/ChildOverallProgress";
import MomProfileHealthOverall from "../clinic/MomProfileHealthOverall";
import SnackMessage from "../clinic/SnackMessage";

import "../../css/WebClinic.scss";

export const styles = {
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  gridSummary: {
    width: "93%",
    backgroundColor: "var(--bg-indigo-grey)",
    margin: "20px 0px",
    paddingBottom: "10px",
  } as CSSProperties,
};

// ---------- WebPatientPanel
type WebPatientPanelProps = {
  // function
  onEvent?: any;
  setProp?: any;
  // data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  dmSelectedTab?: null | number;
  scoreSummary?: any;
  labSummary?: any;
  profileHealth?: any[];
  childOverallProgress?: any[];
  // config
  hidePersonalScoreChart?: boolean;
  hidePersonalLabChart?: boolean;
  hideMomOverall?: boolean;
  hideChildOverall?: boolean;
};

const WebPatientPanelInitial: WebPatientPanelProps = {
  // function
  onEvent: () => {},
  setProp: () => {},
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  dmSelectedTab: null,
  scoreSummary: {},
  labSummary: {},
  profileHealth: [],
  childOverallProgress: [],
  // config
  hidePersonalScoreChart: false,
  hidePersonalLabChart: false,
  hideMomOverall: false,
  hideChildOverall: false,
};

const WebPatientPanel: React.FC<WebPatientPanelProps> = (props) => {
  const intl = useIntl();

  const handleSetAge = () => {
    if (props.selectedPatient?.full_age !== "") {
      const [year, month, day] = props.selectedPatient?.full_age?.match(/\d+ ?(?= )/g) || []
      var text_age = `${year} ${intl.formatMessage({ id: "profile.years" })} ${month} ${intl.formatMessage({ id: "profile.months" })} ${day} ${intl.formatMessage({ id: "profile.days" })}`
      return text_age
    } else {
      return ""
    }
  };

  return (
    <>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid verticalAlign="middle">
        <Header
          as="h4"
          style={{ color: "var(--text-grey)", padding: "50px 0px 10px 30px" }}
        >
          <Icon
            name="angle double left"
            style={{
              display: props.dmSelectedTab !== null ? "" : "none",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setProp("showPatientDM", false);
              props.setProp("dmSelectedTab", null);
            }}
          />
          <FormattedMessage id="bplusClinicKey373" />
        </Header>

        <Grid.Row
          centered
          style={{ ...styles.noPadding, ...styles.textCenter }}
        >
          <Grid.Column width={10} style={{ paddingLeft: "0px" }}>
            <Segment
              onClick={() => {
                props.setProp("showPatientDM", true);
              }}
            >
              HN{" "}
              <label
                style={{ color: "var(--text-light-blue)", cursor: "pointer" }}
              >
                {props.selectedPatient?.hn || "-"}
              </label>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={14}>
            <Image
              src={
                props.selectedPatient?.image_url ||
                props.selectedPatient?.image ||
                "/images/Appointment/user-icon.png"
              }
              style={{ height: "100%", width: "100%" }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={16} style={styles.textCenter}>
          <Header as="h3">{props.selectedPatient?.fullname}</Header>
          {props.selectedPatient?.gender_name || "-"}
        </Grid.Column>
      </Grid>

      <Grid verticalAlign="middle" style={{ padding: "20px", color: "black" }}>
        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={7}>
            <Header as="h4" style={{ color: "var(--text-grey)" }}>
              <FormattedMessage id="bplusClinicKey181" />
            </Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Header as="h4">
              {props.selectedPatient?.encounter_number || "-"}
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={7}>
            <Header as="h4" style={{ color: "var(--text-grey)" }}>
              <FormattedMessage id="bplusClinicKey54" />
            </Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Header as="h4">{handleSetAge()}</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={7}>
            <Header as="h4" style={{ color: "var(--text-grey)" }}>
              <FormattedMessage id="bplusClinicKey88" />
            </Header>
          </Grid.Column>
          <Grid.Column width={9}>
            {props.selectedPatient?.birthdate ? (
              <Header as="h4">
                {formatUTCtoMoment(props.selectedPatient?.birthdate).format(
                  "DD-MM-YYYY"
                )}
              </Header>
            ) : (
              <Header as="h4">-</Header>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Column
          width={16}
          style={{ ...styles.noPadding, ...styles.textCenter }}
        >
          <Header as="h3" color="red">
            {props.selectedPatient?.result}
          </Header>
        </Grid.Column>
      </Grid>

      {!props.hidePersonalScoreChart && (
        <DMPersonalScoreChart
          // function
          onEvent={props.onEvent}
          // data
          selectedPatient={props.selectedPatient}
          scoreSummary={props.scoreSummary}
          hideFilter={true}
        />
      )}

      {!props.hidePersonalLabChart && (
        <DMPersonalLabChart
          // function
          onEvent={props.onEvent}
          // data
          selectedPatient={props.selectedPatient}
          labSummary={props.labSummary}
          hideFilter={true}
        />
      )}

      {!props.hideMomOverall && (
        <MomProfileHealthOverall
          onEvent={props.onEvent}
          errorMessage={props.errorMessage}
          loadingStatus={props.loadingStatus}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          profileHealth={props.profileHealth}
          tableWidth="100%"
        />
      )}

      {!props.hideChildOverall && (
        <ChildOverallProgress
          // function
          onEvent={props.onEvent}
          // data
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          childOverallProgress={props.childOverallProgress}
        />
      )}
    </>
  );
};

WebPatientPanel.defaultProps = WebPatientPanelInitial;

export default React.memo(WebPatientPanel);
