import React, { useEffect, ReactChild, useState, CSSProperties } from "react";

import { Icon } from "semantic-ui-react";
import { AppBar, makeStyles, Button as MuiButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

type TopbarModalProps = {
  headerText?: string;
  showUnderline?: boolean;
  showBackIcon?: boolean;
  hideCloseIcon?: boolean;
  screenElement?: HTMLDivElement;
  headerClassName?: string;

  // callback
  onClose?: () => any;
  leftIconClick?: () => any;
};

const COLOR = {
  blue: "var(--blue-bdms-color)",
  background: "var(--primary-bg-color)",
};

const useStyles = makeStyles((theme) => ({
  header: {
    color: COLOR.blue,
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    marginBottom: "-20px",
    height: "70px",
    position: "relative",
    textAlign: "center",
  },
  screen: {
    padding: "15px 10px",
    background: COLOR.background,
    height: "100%",
    overflow: "auto",
  },
  box_topbar: {
    "& .text-header": {
      position: "absolute",
      zIndex: 1100,
    },
    "&.fixed": {
      "& header": {
        boxShadow: "0 1px 4px 0 rgb(0,0,0,0.12)",
      },
      "& .text-header": {
        position: "fixed",
        // top: "14px",
      },
    },
  },
  close_icon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  left_icon: {
    textTransform: "unset",
    minWidth: "fit-content",
    borderRadius: ".28571429rem",
    display: "inline-block",
    color: "rgba(0,0,0,.6)",
    padding: "5.5px 9px",
    background: COLOR.background,
  },
  underline: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    "& span": {
      borderBottom: "3px solid red",
      width: "217px",
      "@media (max-width: 345px)": {
        width: "179px",
      },
    },
  },
  button_close: {
    padding: 0,
    borderRadius: "50rem",
    overflow: "hidden",
    minWidth: "fit-content",
    marginRight: "2px",
  },
}));

const TopbarModal: React.FunctionComponent<TopbarModalProps> = (props) => {
  const classes = useStyles();

  const [topbarFixed, setTopbarFixed] = useState<boolean>(false);

  useEffect(() => {
    const bodyRef = props.screenElement;

    if (bodyRef) {
      const topbar = bodyRef.firstElementChild as HTMLDivElement;
      const textHeader = topbar?.querySelector(
        ".text-header"
      ) as HTMLDivElement;
      const headerTop = textHeader?.getBoundingClientRect()?.top;
      const headerWidth = textHeader?.getBoundingClientRect()?.width;

      const defaultFontSize =
        window.innerWidth <= 345 && headerWidth > 210
          ? 16.2 + (1 - headerWidth / 275)
          : headerWidth > 275
          ? 18
          : 20;
      const fontSize = window.getComputedStyle(textHeader).fontSize;
      const number = +(fontSize?.match(/\d+/g)?.[0] || defaultFontSize);

      const distance = number - defaultFontSize;

      bodyRef.onscroll = async () => {
        const fontResize =
          distance * (bodyRef.scrollTop / ((headerTop || 0) - (15 + distance)));

        if (fontResize <= distance && distance > 0) {
          textHeader.style.fontSize = `${number - fontResize}px`;
        } else {
          textHeader.style.fontSize = `${defaultFontSize}px`;
        }

        if (bodyRef.scrollTop >= (headerTop || 0) - (15 + distance)) {
          topbar.classList.add("fixed");
          textHeader.style.top = props.showUnderline ? "19px" : "14px";
          setTopbarFixed(true);
        } else {
          topbar.classList.remove("fixed");
          textHeader.style.top = "";
          setTopbarFixed(false);
        }
      };
    }
  }, [props.screenElement, props.showUnderline]);

  return (
    <>
      <div className={classes.box_topbar}>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: topbarFixed ? "white" : "transparent",
            padding: "10px",
          }}
          elevation={0.0}
        >
          {!props.hideCloseIcon && (
            <div className={classes.close_icon}>
              <MuiButton
                color="primary"
                variant="text"
                className={classes.button_close}
                onClick={props.onClose}
              >
                <CloseIcon
                  style={{
                    color: COLOR.blue,
                    fontSize: "30px",
                  }}
                />
              </MuiButton>
            </div>
          )}
          {props.showBackIcon && (
            <div
              style={{ display: "flex", justifyContent: "flex-start" }}
              onClick={props.leftIconClick}
            >
              <MuiButton
                variant="text"
                color="primary"
                className={classes.left_icon}
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                <Icon name="angle left" style={{ fontSize: "14px" }} />
              </MuiButton>
            </div>
          )}
        </AppBar>

        <div
          className={classes.header}
          style={props.showUnderline ? { height: "80px" } : {}}
        >
          <div className={`text-header ${props.headerClassName}`}>
            <div>{props.headerText}</div>
            {props.showUnderline && !topbarFixed && (
              <div className={classes.underline}>
                <span></span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "46px" }}></div>
    </>
  );
};

TopbarModal.defaultProps = {
  headerText: "",
  headerClassName: "",
  showUnderline: false,
  showBackIcon: false,
  hideCloseIcon: false,
  onClose: () => {},
  leftIconClick: () => {},
} as TopbarModalProps;

export default TopbarModal;
