import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
} from "../MobClinicInterface";

export type State = {
  kickCountFilterDate?: moment.Moment;
  kickCountFilterDuration?: string;
  kickCountDetail?: any;
  kickCountHistory?: any;
};

export const StateInitial = {
  kickCountFilterDate: moment(),
  kickCountFilterDuration: "6",
  kickCountDetail: {},
  kickCountHistory: {},
};

export type Event =
  // GET
  | { message: "handleGetKickCountDetail"; params: {} }
  | {
      message: "handleGetKickCountHistory";
      params: { start_measure: string; end_measure: string };
    }
  // CHANGE
  | {
      message: "handleChangekickCountFilterDate";
      params: { date: moment.Moment };
    }
  | {
      message: "handleChangeKickCountFilterDuration";
      params: { duration: string };
    }
  // SAVE
  | { message: "handleSaveKickCountDetail"; params: {} }
  | { message: "handleDeleteKickCountDetail"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetKickCountDetail: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.KICK_COUNT,
    data_type: DATA_TYPE.KICK_COUNT,
    start_measure: state.kickCountFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    kickCountDetail: response,
  });
};

export const handleGetKickCountHistory: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.KICK_COUNT,
    data_type: DATA_TYPE.KICK_COUNT,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    kickCountHistory: response,
  });
};

// CHANGE
export const handleChangekickCountFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({
    kickCountFilterDate: moment(params.date, "DD/MM/YYYY"),
  });
};

export const handleChangeKickCountFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ kickCountFilterDuration: params.duration });
};

// SAVE
export const handleSaveKickCountDetail: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const state = controller.getState();

    data = {
      group: GROUP.KICK_COUNT,
      data_type: DATA_TYPE.KICK_COUNT,
      measure_date: state.kickCountFilterDate?.format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: { ...params.data },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save kick count success.";
    handleGetKickCountDetail(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleDeleteKickCountDetail: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete kick count success.";
    }

    handleGetKickCountDetail(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
