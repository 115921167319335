import React, { useEffect, useMemo } from "react";
import {
  Image,
  Label,
  List,
  Input,
  InputProps,
  Button,
} from "semantic-ui-react";

import { History } from "history";

// UX
import ModGettingStarted from "./ModGettingStarted";

//type
import { registerInfo } from "bplus-lib/register/TypeModal";

// Styles
import "./RegisterCSS.scss";
import { FormattedMessage } from "react-intl";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

const icons = {
  passport: "/images/register/create_password.png",
};

type CreatePasswordProps = {
  onEvent: (e: any) => any;
  // data
  history: History;
  registerInfo?: registerInfo;
  // CommonInterface
  loading?: boolean;
  successMessage?: Record<string, any>;
  loadingStatus?: Record<string, any>;
};

const CREATE_PASSWORD = "CreatePassword";

const CreatePassword: React.FunctionComponent<CreatePasswordProps> = (
  props
) => {
  const isFirstApp = useMemo(() => {
    const message = props.successMessage?.[CREATE_PASSWORD];
    return !!message && !message?.status?.is_verified;
  }, [props.successMessage]);

  useEffect(() => {
    if (window.mobile?.getRegisterState) {
      window.mobile?.getRegisterState();
    }
  }, []);

  const handleSetPassword = () => {
    props.onEvent({
      message: "HandleSetPassword",
      params: { history: props.history, card: CREATE_PASSWORD },
    });
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};
    if (filed === "password" && props.registerInfo?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }
    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleCheckFile = () => {
    let disable = false;
    if (!props.registerInfo?.password || !props.registerInfo?.confirmPassword) {
      disable = true;
    }
    return disable;
  };

  const handleTogglePassword = (type: string) => (e: any) => {
    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params:
        type === "confirm_password"
          ? {
              key: "hideConfirmPassword",
              value: !props.registerInfo?.hideConfirmPassword,
            }
          : {
              key: "hidePassword",
              value: !props.registerInfo?.hidePassword,
            },
    });
  };

  console.log("CreatePassword", props);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="title-text-blue">
          {<FormattedMessage id="bplusClinicKey1013" />}
        </div>
        <div className="text-gray">
          {<FormattedMessage id="bplusClinicKey1012" />}
        </div>
        <div className="div-center" style={{ margin: "20px 0px" }}>
          <Image src={icons.passport} />
        </div>

        <div className="content-filed-input">
          <Label>{<FormattedMessage id="bplusClinicKey981" />}</Label>
          <div aria-hidden="true" onClick={() => handleClearError("password")}>
            <Input
              {...(props.registerInfo?.error?.password && {
                className: "error-input",
              })}
              value={props.registerInfo?.password}
              icon={{
                name: !props.registerInfo?.hidePassword ? "eye slash" : "eye",
                link: true,
                onClick: handleTogglePassword("password"),
              }}
              type={!props.registerInfo?.hidePassword ? "password" : "input"}
              onChange={(e: any, v: InputProps) => {

                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "password", value: v.value },
                });
              }}
            />
          </div>
          <div
            className={props.registerInfo?.error?.password ? "error" : "normal"}
          >
            {props.registerInfo?.error?.password}
          </div>
        </div>

        <div className="content-filed-input">
          <Label>{<FormattedMessage id="bplusClinicKey1014" />}</Label>
          <div
            aria-hidden="true"
            onClick={() => handleClearError("confirmPassword")}
          >
            <Input
              {...(props.registerInfo?.error?.confirmPassword && {
                className: "error-input",
              })}
              value={props.registerInfo?.confirmPassword}
              icon={{
                name: !props.registerInfo?.hideConfirmPassword
                  ? "eye slash"
                  : "eye",
                link: true,
                onClick: handleTogglePassword("confirm_password"),
              }}
              type={
                !props.registerInfo?.hideConfirmPassword ? "password" : "input"
              }
              onChange={(e: any, v: InputProps) => {
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "confirmPassword", value: v.value },
                });
              }}
            />
          </div>

          <div
            className={
              props.registerInfo?.error?.confirmPassword ||
              props.registerInfo?.error?.message
                ? "error"
                : "normal"
            }
          >
            {props.registerInfo?.error?.message
              ? `${props.registerInfo?.error?.message}`
              : `${props.registerInfo?.error?.confirmPassword}`}
          </div>
        </div>

        <div className="list-rule-password">
          <List bulleted>
            <List.Item>
              {<FormattedMessage id="bplusClinicKey1008" />}
            </List.Item>
            <List.Item>
              {<FormattedMessage id="bplusClinicKey1009" />}
            </List.Item>
            <List.Item>
              {<FormattedMessage id="bplusClinicKey1010" />}
            </List.Item>
            <List.Item>
              {<FormattedMessage id="bplusClinicKey1011" />}
            </List.Item>
          </List>
        </div>
      </div>

      <div className="div-center-bottom">
        <div className="bplus-buttom">
          <Button
            loading={props.loading}
            disabled={handleCheckFile()}
            onClick={() => {
              if (!props.loading) {
                handleSetPassword();
              }
            }}
          >
            {<FormattedMessage id="common.confirm" />}
          </Button>
        </div>
      </div>

      {isFirstApp && (
        <ModGettingStarted
          onEvent={props.onEvent}
          // data
          history={props.history}
          hospitalList={props.successMessage?.[CREATE_PASSWORD]?.hospitalList}
          patient={props.successMessage?.[CREATE_PASSWORD]?.patient}
          // CommonInterface
          loadingStatus={props.loadingStatus}
        />
      )}
    </>
  );
};

export default React.memo(CreatePassword);
