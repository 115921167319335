import React, {
  ChangeEventHandler,
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import { Input, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Icon, Dropdown, Checkbox, InputOnChangeData } from "semantic-ui-react";
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useIntl } from "react-intl";

const gray = "#eeeeee";

const ClinicContent = (props: any) => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [filterClinicContent, setFilterClinicContent] = useState([]);

  const [isShowAlertDeleteContent, setIsShowAlertDeleteContent] =
    useState(false);
  const [selectedContentIdx, setSelectedContentIdx] = useState(-1);

  useEffect(() => {
    // console.log("props.selectedDivision?.division_code", props.selectedDivision?.division_code)
    if (props.selectedDivision?.division_code) {
      props.onEvent({
        message: "GetClinicContent",
        params: { clinic: props.selectedDivision?.division_code },
      });
    }
  }, [props.selectedDivision?.division_code]);

  console.log("ClinicContent props.clinicContent: ", props.clinicContent);
  useEffect(() => {
    console.log(
      "ClinicContent useEffect props.clinicContent: ",
      props.clinicContent
    );
    if (props.divisionHasUser && props.divisionHasUser?.length > 0) {
      let allowDivision = props.divisionHasUser.map(
        (item: any) => item.division_id
      );
      let clinicContentFiltered = [];

      if (props.clinicContent) {
        clinicContentFiltered = props.clinicContent.filter(
          (item: any) =>
            allowDivision.includes(item.divisionId) ||
            (item.unsaved === true)
        );

        if (props.selectedDivisionId) {
          clinicContentFiltered = clinicContentFiltered.filter(
            (item: any) =>
              item.divisionId === props.selectedDivisionId ||
              (item.unsaved === true)
          );
        }

        if (searchText === "" || searchText?.length <= 2) {
          setFilterClinicContent(clinicContentFiltered);
        } else if (searchText?.length > 2) {
          let filter = clinicContentFiltered.filter((item: any) =>
            item.title.includes(searchText)
          );
          setFilterClinicContent(filter);
        }
      }
    }
  }, [
    props.clinicContent,
    searchText,
    props.divisionHasUser,
    props.selectedDivisionId,
  ]);

  console.log(props.clinicContent);

  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  console.log(
    " ClinicContent RENDER  props.clinicContent: ",
    props.clinicContent
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            height: "95vh",
            borderRight: `solid ${gray} 1px`,
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Input
              style={{ flex: 1 }}
              placeholder="ค้นหา"
              onChange={handleChangeSearchText}
            />
            <Icon
              name="plus"
              onClick={(e: any) => {
                props.onEvent({
                  message: "NewClinicContent",
                  params: {},
                });
              }}
            />
          </div>
          {filterClinicContent.map((content: any, index: number) => (
            <ContentItem

              key={index}
              index={index}
              content={content}
              clinicContentIndex={props.clinicContentIndex} // clinicContentIndex มันคือ index ของ ที่ filter แล้ว ใน state ภายใน Clinic
              setProp={props.setProp}
              onEvent={props.onEvent}
              showDiaglog={() => {
                setIsShowAlertDeleteContent(true);
              }}
              markContent={(idx: any) => {
                setSelectedContentIdx(idx);
              }}
            />
          ))}
        </div>
        <ContentEditor
          content={
            props.clinicContentIndex === null
              ? null
              : filterClinicContent[props.clinicContentIndex]
          }
          clinicContentIndex={props.clinicContentIndex}
          clinicalSegment={props.clinicalSegment}
          onEvent={props.onEvent}
          setProp={props.setPropSeparate}
          divisionPatient={props.divisionPatient}
          clinicContent={props.clinicContent}
        />
        <ContentPreview
          content={
            props.clinicContentIndex === null
              ? null
              : filterClinicContent[props.clinicContentIndex]
          }
        />
        <Dialog
          open={isShowAlertDeleteContent}
          onClose={() => {
            setIsShowAlertDeleteContent(false);
          }}
        >
          <DialogTitle>{"แจ้งเตือน"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({ id: "bplusClinicKey816" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsShowAlertDeleteContent(false);
                setSelectedContentIdx(-1);
              }}
            >
              {intl.formatMessage({ id: "common.cancel" })}
            </Button>
            <Button
              onClick={() => {
                props.onEvent({
                  message: "DeleteClinicContent",
                  params: {
                    index: selectedContentIdx,
                    id: filterClinicContent[selectedContentIdx].id,
                    callback: () => {
                      // setButtonLoading(false);
                    },
                    clinic: props.selectedDivision?.division_code
                  },
                });
                setIsShowAlertDeleteContent(false);
                setSelectedContentIdx(-1);
              }}
              autoFocus
            >
              ตกลง
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </LocalizationProvider>
  );
};

const ContentItem = (props: any) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleChangeActive = () => {
    console.log("handleChangeActive", props.content);

    if (props.content?.unsaved) {
      console.log("Unsaved");
      return;
    }
    setButtonLoading(true);
    console.log("ContentItem   props.index: ", props.index);

    // props.setProp(`clinicContent.${props.index}.active`, !props.content.active);

    props.onEvent({
      message: "SaveClinicContent",
      params: {
        index: props.index,
        id: props.content.id,
        changeActive: true,
        callback: () => {
          setButtonLoading(false);
        },
      },
    });
  };

  const handleDeleteContent = (idx: any) => {
    props.showDiaglog();
    props.markContent(idx);
  };

  if (props.index === 0) {
    console.log("ContentItem props.content", props.content);
    console.log("ContentItem loading", buttonLoading);
  }

  return (
    <div
      style={{
        padding: "5px",
        cursor: "pointer",
        backgroundColor:
          props.index === props.clinicContentIndex ? gray : "white", 
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={(e: any) => {
        props.setProp("clinicContentIndex", props.index);
      }}
    >
      {props.content.name || "ขื่อคอนเทนท์ (ไม่ระบุ)"}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!props.content.active && (
          <DeleteIcon
            color={"secondary"}
            onClick={() => {
              handleDeleteContent(props.index);
            }}
          ></DeleteIcon>
        )}
        <LoadingButton
          {...(buttonLoading && { loading: true, loadingPosition: "start" })}
          color={props.content.active ? "primary" : "secondary"}
          startIcon={<AssignmentTurnedInIcon />}
          onClick={handleChangeActive}
        >
          {props.content.active ? "Active" : "Inactive"}
        </LoadingButton>
      </div>
    </div>
  );
};

const ContentEditor = (props: any) => {
  const intl = useIntl();
  const [isTrashing, setIsTrashing] = useState(false);
  const [isShowAlertSegment, setIsShowAlertSegment] = useState(false);
  const [selectedSegmentIdx, setSelectedSegmentIdx] = useState(-1);

  console.log("ContentEditor", props);

  const handleChangeEffectDate = (value: any) => {
    console.log(" value", value);
    let mapIdx = props.clinicContentIndex
    if (props.content?.id) {
      mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
    }
    props.setProp(
      `clinicContent.${mapIdx}.effect_date`,
      value.startOf("day").toDate()
    );
  };

  return (
    <div
      style={{
        padding: "10px",
        flex: 1,
        height: "95vh",
        backgroundColor: props.content ? "white" : gray,
        borderRight: `solid ${gray} 1px`,
      }}
    >
      {props.content === null ? "No content selected" : null}
      {props.content && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>{intl.formatMessage({ id: "common.detail" })}</h3>

          {/* <div>ชื่อคอนเทนท์</div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Input
                style={{ flex: 1 }}
                value={props.content.name}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  props.setProp(
                    `clinicContent.${props.clinicContentIndex}.name`,
                    e.target.value
                  );
                }}
              />
            </div> */}
          <Stack spacing={3}>
            <TextField
              required
              error={props.content.name === ""}
              id="content-required"
              label="ชื่อคอนเทนท์"
              defaultValue=""
              value={props.content.name}
              onChange={(e: any) => {
                // console.log("value name", e.target.value)
                let mapIdx = props.clinicContentIndex
                if (props.content?.id) {
                  mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                }
                props.setProp(
                  `clinicContent.${mapIdx}.name`,
                  e.target.value
                );
              }}
            />

            <TextField
              required
              id="link-required"
              label="ลิงค์"
              defaultValue=""
              error={props.content.link === ""}
              value={props.content.link}
              onChange={(e: any) => {
                // console.log("value link", value )
                let mapIdx = props.clinicContentIndex
                if (props.content?.id) {
                  mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                }
                props.setProp(
                  `clinicContent.${mapIdx}.link`,
                  e.target.value
                );
              }}
            />

            {/* <div>ลิงค์</div>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Input
                style={{ flex: 1 }}
                value={props.content.link}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  props.setProp(
                    `clinicContent.${props.clinicContentIndex}.link`,
                    e.target.value
                  );
                }}
              />
            </div> */}

            <div style={{ display: "flex", marginBottom: "10px" }}>
              <DesktopDatePicker
                label="Effect Date"
                inputFormat="DD/MM/yyyy"
                value={
                  props.content.effect_date
                    ? moment(props.content.effect_date)
                    : null
                }
                onChange={handleChangeEffectDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              {/* <input
              type="datetime"
              style={{ flex: 1 }}
              value={props.content.effect_date}
              onChange={}
            /> */}
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ paddingLeft: "5px" }}>
                {intl.formatMessage({ id: "bplusClinicKey810" })}
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: "5px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e: any) => {

                      let segments = props.content?.segments || [];
                      segments = [
                        {
                          patient: false,
                          name: props.clinicalSegment[0],
                        },
                      ].concat(segments);
                      let mapIdx = props.clinicContentIndex
                      if (props.content?.id) {
                        mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                      }

                      props.setProp(
                        `clinicContent.${mapIdx}.segments`,
                        segments
                      );


                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    {intl.formatMessage({ id: "common.add" })}
                  </Button>
                </div>

                {/* <Icon
                  name="add circle"
                  style={{ marginLeft: "5px" }}
                  onClick={(e: any) => {
                    let segments = props.content?.segments || [];
                    segments = [
                      {
                        patient: false,
                        name: props.clinicalSegment[0],
                      },
                    ].concat(segments);
                    props.setProp(
                      `clinicContent.${props.clinicContentIndex}.segments`,
                      segments
                    );
                  }}
                /> */}

                <div style={{ paddingRight: "5px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e: any) => {
                      setIsTrashing(!isTrashing);
                    }}
                    startIcon={<DeleteIcon />}
                  >
                    Del
                  </Button>
                </div>
              </div>
            </div>
            {props.content?.segments?.length > 0 &&
              (props.content?.segments).map((item: any, index: number) => {
                return (
                  <div
                    // id={props.content.id + index}
                    key={props.content.id + index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minHeight: "40px",
                    }}
                  >
                    {isTrashing && (
                      <Icon
                        color="red"
                        key={index}
                        name="trash alternate"
                        style={{ marginRight: "5px" }}
                        onClick={(e: any) => {
                          setIsShowAlertSegment(true);
                          setSelectedSegmentIdx(index);
                        }}
                      />
                    )}
                    <FormControl fullWidth>
                      {item?.patient ? (
                        <>
                          <InputLabel>
                            {intl.formatMessage({ id: "bplusClinicKey372" })}
                          </InputLabel>
                          <Select
                            label="Patient"
                            value={item.patient_id}
                            onChange={(e: any) => {
                              let mapIdx = props.clinicContentIndex
                              if (props.content?.id) {
                                mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                              }
                              props.setProp(
                                `clinicContent.${mapIdx}.segments.${index}`,
                                { patient: true, patient_id: e.target.value }
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>
                                {intl.formatMessage({ id: "common.none" })}
                              </em>
                            </MenuItem>
                            {(props.divisionPatient
                              ? Array.from(
                                  new Set(
                                    props.divisionPatient.map(
                                      (item: any) => item.patient_id
                                    )
                                  )
                                ).map((id) =>
                                  props.divisionPatient.find(
                                    (divisionPatient: any) =>
                                      divisionPatient.patient_id === id
                                  )
                                )
                              : []
                            ).map((patient: any) => {
                              return (
                                <MenuItem
                                  value={patient.patient_id}
                                >{`${patient.hn}: ${patient.first_name} ${patient.last_name}`}</MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      ) : (
                        // <Dropdown
                        //   // id={item.patient_id + index}
                        //   style={{ flex: 1 }}
                        //   selection
                        //   // key={item.patient_id + index}
                        //   key="10"
                        //   options={
                        //     props.divisionPatient
                        //       ? Array.from(
                        //           new Set(
                        //             props.divisionPatient.map(
                        //               (item: any) => item.patient_id
                        //             )
                        //           )
                        //         )
                        //           .map((id) =>
                        //             props.divisionPatient.find(
                        //               (divisionPatient: any) =>
                        //                 divisionPatient.patient_id === id
                        //             )
                        //           )
                        //           .map((patient: any) => ({
                        //             key: patient.patient_id,
                        //             value: patient.patient_id,
                        //             text: `${patient.hn}: ${patient.first_name} ${patient.last_name}`,
                        //           }))
                        //       : []
                        //   }
                        //   value={item.patient_id}
                        //   onChange={(e: any, v: any) => {
                        //     console.log(index, v.value);
                        //     props.setProp(
                        //       `clinicContent.${props.clinicContentIndex}.segments.${index}`,
                        //       { patient: true, patient_id: v.value }
                        //     );
                        //   }}
                        // />
                        <>
                          <InputLabel>
                            {intl.formatMessage({ id: "bplusClinicKey820" })}
                          </InputLabel>
                          <Select
                            label="Segment"
                            value={item.name}
                            onChange={(e: any) => {
                              let mapIdx = props.clinicContentIndex
                              if (props.content?.id) {
                                mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                              }
                              props.setProp(
                                `clinicContent.${mapIdx}.segments.${index}`,
                                { patient: false, name: e.target.value }
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>
                                {intl.formatMessage({ id: "common.none" })}
                              </em>
                            </MenuItem>
                            {props.clinicalSegment?.map((segment: any) => (
                              <MenuItem value={segment}>{segment}</MenuItem>
                            ))}
                          </Select>
                        </>

                        // <Dropdown
                        //   style={{ flex: 1 }}
                        //   selection
                        //   key="10"
                        //   // id={props.content.id + index}
                        //   // key={props.content.id + index}
                        //   options={(props.clinicalSegment || []).map(
                        //     (segment: any) => ({
                        //       key: segment,
                        //       value: segment,
                        //       text: segment,
                        //     })
                        //   )}
                        //   value={item.name}
                        //   onChange={(e: any, v: any) => {
                        //     props.setProp(
                        //       `clinicContent.${props.clinicContentIndex}.segments.${index}`,
                        //       { patient: false, name: v.value }
                        //     );
                        //   }}
                        // />
                      )}
                    </FormControl>
                    <Checkbox
                      // id={props.content.id + index}
                      // key={props.content.id + index}
                      key="11"
                      style={{ marginLeft: "10px" }}
                      checked={item?.patient === true}
                      label="ผู้ป่วยรายคน"
                      onClick={(e: any) => {
                        let mapIdx = props.clinicContentIndex
                        if (props.content?.id) {
                          mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                        }
                        props.setProp(
                          `clinicContent.${mapIdx}.segments.${index}.patient`,
                          !item?.patient
                        );
                      }}
                    />
                  </div>
                );
              })}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                disabled={
                  props.content.name === "" || props.content.link === ""
                }
                onClick={(e: any) => {
                  props.onEvent({
                    message: "SaveClinicContent",
                    params: {
                      index: props.clinicContentIndex,
                      id: props.id ,
                    },
                  });
                }}
              >
                Save
              </Button>
            </div>
          </Stack>
        </div>
      )}

      <Dialog
        open={isShowAlertSegment}
        onClose={() => {
          setIsShowAlertSegment(false);
        }}
      >
        <DialogTitle>{"แจ้งเตือน"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: "bplusClinicKey815" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsShowAlertSegment(false);
              setSelectedSegmentIdx(-1);
            }}
          >
            {intl.formatMessage({ id: "common.cancel" })}
          </Button>
          <Button
            onClick={() => {
              let segments = props.content?.segments || [];

              console.log("selectedSegmentIdx: ", selectedSegmentIdx);
              console.log("segments: ", segments);
              if (selectedSegmentIdx >= 0) {
                segments.splice(selectedSegmentIdx, 1);
                let mapIdx = props.clinicContentIndex
                if (props.content?.id) {
                  mapIdx = props.clinicContent.findIndex((item:any) => item.id === props.content?.id )
                }
                props.setProp(
                  `clinicContent.${mapIdx}.segments`,
                  segments
                );
              }
              console.log("segments", segments);
              setIsShowAlertSegment(false);
              setSelectedSegmentIdx(-1);
            }}
            autoFocus
          >
            {intl.formatMessage({ id: "common.ok" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ContentPreview = (props: any) => {
  const intl = useIntl();
  return (
    <div
      style={{
        flex: 1,
        padding: "10px",
        height: "95vh",
        backgroundColor: props.content ? "white" : gray,
      }}
    >
      <h3>{intl.formatMessage({ id: "bplusClinicKey612" })}</h3>
      <div>{props.content?.title}</div>
      {props.content?.image && <img width="100%" src={props.content?.image} />}
      <div>{props.content?.description}</div>
    </div>
  );
};

export default ClinicContent;
