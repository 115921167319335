import React, { useMemo } from "react";

// UX
import CardDoctorSkeleton from "./CardDoctorSkeleton";
import ItemNotFound from "./ItemNotFound";
import CardDoctorContent from "./CardDoctorContent";
import { SCLocationType } from "./SelectCenter";

// Interface
import { State } from "../MobSmartAppointmentInterface";
import { DoctorAppointmentActionType } from "./Types";

// Types
export type CardDoctorListProps = {
  // data
  items: any[];
  loading?: boolean;
  // callback
  onSelect?: (selected: Record<string, any>) => any;
  onButtonAction?: (
    data: Record<string, any>,
    action: DoctorAppointmentActionType,
    loading: string
  ) => any;
} & Pick<State, "loadingStatus" | "textSearch">;

export type DLLocationType = {
  center?: Record<string, any>;
} & SCLocationType;

// Images
const IMAGES = {
  notfound: "/images/Appointment/no-information-search.png",
  notfoundFilter: "/images/Appointment/no-information-filter.png",
};

const CardDoctorList = (props: CardDoctorListProps) => {
  const items = useMemo(() => {
    const skeletons = Array(4)
      .fill("")
      .map((_, index) => ({ id: index } as any));

    return props.loading ? skeletons : props.items;
  }, [props.loading, props.items]);

  if (props.loading) {
    return (
      <>
        {items.map((item) => (
          <CardDoctorSkeleton key={"card-skeleton" + item.id} />
        ))}
      </>
    );
  } else if (items.length) {
    return (
      <>
        {items.map((item) => (
          <CardDoctorContent
            key={"card-doctor" + item.id}
            data={item}
            // CommonInterface
            loadingStatus={props.loadingStatus}
            // callback
            onSelect={props.onSelect}
            onButtonAction={props.onButtonAction}
          />
        ))}
      </>
    );
  } else {
    return (
      <div style={{ height: "100%" }}>
        {props.textSearch ? (
          <ItemNotFound
            img={IMAGES.notfound}
            subTitle={
              <>
                <div>
                  ไม่พบผลการค้นหาสำหรับ <span>"{props.textSearch}"</span>
                </div>
                <div>กรุณาปรับตัวกรองหรือคำค้นหาของคุณ</div>
              </>
            }
          />
        ) : (
          <ItemNotFound
            img={IMAGES.notfoundFilter}
            subTitle={
              <>
                <div style={{font:"18px bold", color:"#393939"}}>
                ไม่พบแพทย์
                </div>
                <div>ดูเหมือนว่าไม่มีแพทย์สำหรับตัวกรองนี้</div>
              </>
            }
          />
        )}
      </div>
    );
  }
};

export default React.memo(CardDoctorList);
