import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import moment from "moment";
import {
  Button,
  Grid,
  Header,
  Image,
  Loader,
  Menu,
  Dimmer,
  Modal,
  Tab,
} from "semantic-ui-react";
import { AppBar, Slide } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

// UI
import MobHead from "./MobHead";
import SnackMessage from "./SnackMessage";
import DMDrugAddDrugManually from "./DMDrugAddDrugManually";
import DMDrugTabDetail from "./DMDrugTabDetail";
import DMDrugTabHistory from "./DMDrugTabHistory";
import DMDrugTabSummary from "./DMDrugTabSummary";
import DMDrugRecordTime from "./DMDrugRecordTime";

export const COLOR_CODE = {
  lightGrey: "#747474",
  darkGrey: "#656565",
  cyan: "#0072BC",
  darkBlue: "#1621A8",
};

export const styles = {
  tabs: {
    width: "33%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  confirmAllButton: {
    backgroundColor: "#B2E8AC",
  } as CSSProperties,
  confirmButton: {
    backgroundColor: "#1AA3AC",
    color: "white",
  } as CSSProperties,
  drugName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    color: COLOR_CODE.cyan,
  } as CSSProperties,
  drugNameSummary: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    paddingRight: "0px",
    margin: "5px 0px",
  } as CSSProperties,
  drugMealSummary: {
    textAlign: "center",
    height: "100%",
    padding: "15px 0px",
    margin: "0px",
  } as CSSProperties,
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
  blueMeal: {
    fontWeight: "bold",
    color: COLOR_CODE.darkBlue,
    paddingRight: "0px",
  } as CSSProperties,
  imageContain: {
    height: "70px",
    width: "85px",
    objectFit: "contain",
    margin: "auto",
    backgroundColor: "white",
  } as CSSProperties,
};

// ---------- DrugDM
type DMDrugProps = {
  onEvent: any;
  onSetBackToApp: any;
  holdDrugReasonOptions: any;
  mealOptions: any;
  medTimeOptions: any;
  unitOptions: any;
  errorMessage: any;
  successMessage: any;
  drugDataTabDetail: any;
  drugManualList: any;
  drugDataTabSummary: any;
  drugDataTabHistory: any;
  drugDataSetting: any;
  selectedPatient: any;
  loadingMedication?: boolean;
};

const DMDrugInitial: DMDrugProps = {
  onEvent: () => null,
  onSetBackToApp: () => null,
  holdDrugReasonOptions: {},
  mealOptions: {},
  medTimeOptions: {},
  unitOptions: {},
  errorMessage: null,
  successMessage: null,
  drugDataTabDetail: {},
  drugManualList: {},
  drugDataTabSummary: {},
  drugDataTabHistory: {},
  drugDataSetting: {},
  selectedPatient: {},
  loadingMedication: false,
};

const DMDrug: React.FC<DMDrugProps> = (props) => {
  const intl = useIntl();
  const [mode, setMode] = useState<string>("default");
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [newManualDrug, setNewManualDrug] = useState<boolean>(false);
  const [modDrugDetail, setModDrugDetail] = useState<any>({
    open: false,
    drug: null,
  });
  const [showDrugDescription, setShowDrugDescription] = useState<any>(null);

  useEffect(() => {
    if (tabIndex === 0) {
      props.onEvent({
        message: "handleGetDrugDetail",
        params: { date: moment().format("DD/MM/YYYY") },
      });
    } else if (tabIndex === 1) {
      props.onEvent({
        message: "handleGetDrugSummary",
        params: { date: moment().format("DD/MM/YYYY") },
      });
    } else if (tabIndex === 2) {
      props.onEvent({
        message: "handleGetDrugHistory",
        params: { duration: "7_DAY" },
      });
    }
  }, [tabIndex]);

  // ----- Tab
  const handleTabChange = (event: object, data: any) => {
    setTabIndex(data.activeIndex);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  // ----- Mode
  const handleShowRecordTime = () => {
    setMode("recordTime");
  };

  const handleShowDrugManually = () => {
    setMode("manually");
  };

  const handleCloseMode = () => {
    if (mode === "manually" && newManualDrug) {
      if (tabIndex === 0) {
        props.onEvent({
          message: "handleGetDrugDetail",
          params: { date: moment().format("DD/MM/YYYY") },
        });
      }
      if (tabIndex === 1) {
        props.onEvent({
          message: "handleGetDrugSummary",
          params: { date: moment().format("DD/MM/YYYY") },
        });
      }
    }
    setNewManualDrug(false);
    setMode("default");
  };

  // ----- Modal Drug Detail
  const handleShowDrugDetail = (drug: any) => {
    setModDrugDetail({ open: true, drug: drug });
  };

  const handleCloseDrugDetail = () => {
    setModDrugDetail({ open: false, drug: null });
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="summary" style={styles.tabs}>
            <FormattedMessage id="bplusClinicKey477" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  const handleShowDrugDescription = (drug: any) => {
    setShowDrugDescription(drug);
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <AppBar position="absolute" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "drug.drug" })}
        />
      </AppBar>

      {mode == "default" ? (
        <Tab
          className="weight-tab-custom dm"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={tabIndex}
          onTabChange={handleTabChange}
        />
      ) : (
        false
      )}

      <Dimmer active={props.loadingMedication} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
      >
        <DMDrugTabDetail
          onEvent={props.onEvent}
          onShowDrugDetail={handleShowDrugDetail}
          onShowDrugManually={handleShowDrugManually}
          onShowRecordTime={handleShowRecordTime}
          onShowDrugDescription={handleShowDrugDescription}
          holdDrugReasonOptions={props.holdDrugReasonOptions}
          selectedPatient={props.selectedPatient}
          drugDataTabDetail={props.drugDataTabDetail}
        />
        <DMDrugTabSummary
          onEvent={props.onEvent}
          onShowDrugManually={handleShowDrugManually}
          onShowDrugDetail={handleShowDrugDetail}
          selectedPatient={props.selectedPatient}
          drugDataTabSummary={props.drugDataTabSummary}
        />
        <DMDrugTabHistory
          onEvent={props.onEvent}
          drugDataTabHistory={props.drugDataTabHistory}
          selectedPatient={props.selectedPatient}
        />
      </SwipeableViews>

      <Slide direction="up" in={mode === "manually"} mountOnEnter unmountOnExit>
        {
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              zIndex: 1101,
              width: "100%",
              height: "100%",
            }}
          >
            <AppBar position="fixed" color="default">
              <MobHead
                leftIcon="arrow left"
                leftIconClick={handleCloseMode}
                title={`${intl.formatMessage({ id: "bplusClinicKey842" })}`}
              />
            </AppBar>
            <DMDrugAddDrugManually
              onEvent={props.onEvent}
              handleAddNewDrug={setNewManualDrug}
              mealOptions={props.mealOptions}
              medTimeOptions={props.medTimeOptions}
              unitOptions={props.unitOptions}
              drugManualList={props.drugManualList}
              selectedPatient={props.selectedPatient}
            />
          </div>
        }
      </Slide>
      <Slide
        direction="left"
        in={mode === "recordTime"}
        mountOnEnter
        unmountOnExit
      >
        {
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              zIndex: 1101,
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <AppBar position="fixed" color="default">
              <MobHead
                leftIcon="arrow left"
                leftIconClick={handleCloseMode}
                title={intl.formatMessage({ id: "drug.drug" })}
              />
            </AppBar>
            <DMDrugRecordTime
              onEvent={props.onEvent}
              // drugDataTabDetail={props.drugDataTabDetail}
              selectedPatient={props.selectedPatient}
              drugDataSetting={props.drugDataSetting}
            />
          </div>
        }
      </Slide>

      <Modal
        closeIcon
        open={modDrugDetail.open}
        closeOnDimmerClick={true}
        onClose={handleCloseDrugDetail}
      >
        <Modal.Header>
          <FormattedMessage id="bplusClinicKey313" />
        </Modal.Header>
        <Modal.Content>
          <Grid style={{ padding: "10px" }}>
            <Grid.Row columns={3}>
              <Grid.Column
                width={3}
                style={{ ...styles.noPadding, ...styles.textCenter }}
              >
                <Image src={modDrugDetail?.drug?.image} />
              </Grid.Column>
              <Grid.Column width={13}>
                <Header as="h4" style={{ color: COLOR_CODE.cyan }}>
                  {modDrugDetail.drug?.drug_name}
                </Header>
                {modDrugDetail.drug?.indication_th}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            fluid
            style={{ ...styles.confirmButton, margin: "0px 0px 15px" }}
            onClick={handleCloseDrugDetail}
          >
            <FormattedMessage id="bplusClinicKey116" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={!!showDrugDescription}
        closeIcon
        closeOnDimmerClick
        className="Modal-descriptionMedicine"
        onClose={() => setShowDrugDescription(null)}
      >
        <Modal.Header>
          <FormattedMessage id="bplusClinicKey808" />
        </Modal.Header>
        <Modal.Content>
          <div style={{ padding: "0 0.5rem" }}>
            <h4 style={{ color: "#0072BC", marginTop: "0.8rem" }}>
              {showDrugDescription?.drug_name || ""}
            </h4>
            <h4
              style={{
                color: "#4F4F4F",
                margin: "0 0 1.3rem 0",
                lineHeight: "24px",
              }}
            >
              <div>{showDrugDescription?.note_medication || "-"}</div>
              <div>{showDrugDescription?.note_label}</div>
            </h4>
          </div>
        </Modal.Content>
        <Modal.Header
          onClick={() => setShowDrugDescription(null)}
          style={{ textAlign: "center" }}
        >
          <FormattedMessage id="common.ok" />
        </Modal.Header>
      </Modal>
    </>
  );
};

DMDrug.defaultProps = DMDrugInitial;

export default React.memo(DMDrug);
