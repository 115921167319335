import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonListPatientDataList,
  commonCreatePatientData,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";
import StaffDirectFootExaminationView from "../../../issara-sdk/apis/StaffDirectFootExaminationView_apps_PRX";

export type State = {
  openFootConfirm?: boolean;
  footFilterDate?: moment.Moment;
  footFilterDuration?: string;
  footDetail?: any;
  footHistory?: any;
  footEclinicalData?: any;
  loadingWebFoot?: any;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus"
>;

export const StateInitial: State = {
  openFootConfirm: false,
  footFilterDate: moment(),
  footFilterDuration: "6",
  footDetail: {},
  footHistory: {},
  footEclinicalData: {},
  loadingWebFoot: false,
};

export type Event =
  // GET
  | {
      message: "handleGetFootDetailByDate";
      params: { openConfirm: boolean };
    }
  | {
      message: "handleGetFootHistoryByDuration";
      params: { start_measure: string; end_measure: string };
    }
  | { message: "handleGetFootExamination"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event, Data>;

// GET
export const handleGetFootDetailByDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const tmpParams: any = {
    group: GROUP.FOOT,
    data_type: DATA_TYPE.FOOT,
    start_measure: state.footFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    footDetail: response,
    openFootConfirm: params.openConfirm,
  });
};

export const handleGetFootHistoryByDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true, loadingWebFoot: true });

  const tmpParams: any = {
    group: GROUP.FOOT,
    data_type: DATA_TYPE.FOOT,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  if (params.isNeedExam) {
    console.log("In isNeed Exam");
    await handleGetFootExamination(controller, params);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    footHistory: response,
    loadingWebFoot: false,
  });
};

export const handleGetFootExamination: Handler = async (controller, params) => {
  const state = controller.getState();
  const [res, err, network] = await StaffDirectFootExaminationView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { patient: state.selectedPatient.proxy_patient },
  });

  if (err) {
    console.log("handleGetFootExamination error: ", err);
  } else {
    console.log("handleGetFootExamination: ", res);
    controller.setState({
      footEclinicalData: res?.data || {},
    });
    return;
  }
  controller.setState({
    footEclinicalData: {},
  });
};
