
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['DirectUtilMixin'] */
/* params: 'department_code', 'loc_code', 'doctor_code', 'start_date', 'end_date' */
/* data:  */
const RealtimeLocationScheduleViewM:
  {
    get: ({ params, apiToken, extra }:
      {
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    post: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any
      }) => any,
    put: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    patch: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  } =
{
  get: async ({ params, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/PRX/v3/doctor-schedule/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/PRX/v3/doctor-schedule/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/PRX/v3/doctor-schedule/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/PRX/v3/realtime_loc_schedule/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default RealtimeLocationScheduleViewM

