import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  MutableRefObject,
} from "react";
// Mui
import { Dialog, TextField, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

// Select
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuList from "@mui/material/MenuList";

// Icons
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

// Types
type MobileTimePickerProps = {
  open: boolean;
  date?: MomentType;
  language?: "en-US" | "th-TH";
  // callback
  onClose?: () => any;
  onCancel?: () => any;
  onOK?: (date: MomentType) => any;
};

type TimeProps = {
  date: MomentType;
  hour?: string;
  minute?: string;
  // callback
  onChangeMode: (mode: ModeType) => any;
  onChangeDate: (date: MomentType, type?: DateType) => any;
  onCancel: () => any;
  onOK: () => any;
} & Pick<MobileTimePickerProps, "language">;

type ModeType = "pick" | "type_in";

type MomentType = moment.Moment | null;

type DateType = "A" | "hh" | "mm";

// Use styles
const COLORS = {
  primary: "var(--primary-theme-color)",
};

const useStyles = makeStyles((theme) => ({
  dialog_custom: {
    "& .MuiPaper-root": {
      margin: 0,
    },
    // Toolbar
    "& .MuiPickersToolbar-root.MuiTimePickerToolbar-root": {
      backgroundColor: COLORS.primary,
      padding: "13px 24px",
      // Select date
      "& > .MuiTypography-overline": {
        display: "none",
      },
      "& > .MuiPickersToolbar-content": {
        justifyContent: "center",
        marginLeft: "1.6rem",
        alignItems: "center",
        "& > .MuiButtonBase-root": {
          display: "none",
        },
        // Time
        "& > .MuiTimePickerToolbar-hourMinuteLabel": {
          alignItems: "baseline",
          "& > .MuiButtonBase-root": {
            padding: "4px 3px",
            minWidth: "auto",
            "& > .MuiTypography-root": {
              fontSize: "3.5rem",
            },
          },
          "& > .MuiTimePickerToolbar-separator": {
            margin: 0,
            fontSize: "3.5erm",
          },
        },
        // AM PM
        "& > .MuiTimePickerToolbar-ampmSelection": {
          margin: 0,
          "& > .MuiButtonBase-root": {
            width: "42px",
            minWidth: "auto",
            height: "25px",
          },
        },
      },
      "& .MuiTypography-root": {
        filter: "invert(1)",
      },
    },
    // Clock Action
    "& .MuiClock-root": {
      marginBottom: "4px",
      "& > .MuiClock-clock": {
        backgroundColor: "#EEEEEE",
        "& .MuiClock-pin,.MuiClockPointer-root,.MuiClockPointer-thumb": {
          backgroundColor: COLORS.primary,
          borderColor: COLORS.primary,
        },
        "& .MuiClockNumber-root:not(.Mui-selected)": {
          color: "#1F1F1F",
        },
      },
    },
    "& .MuiDialogActions-root .MuiButtonBase-root": {
      color: COLORS.primary,
      minWidth: "52px",
      fontSize: "1rem",
      opacity: 0,
    },
  },
  // input underline
  underline: {
    "& > .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #0147A3",
      },
    },
    "& input": {
      caretColor: COLORS.primary,
    },
  },
  // Select
  hide_underline: {
    width: "100%",
    "& > .MuiInputBase-root": {
      color: "rgba(0,0,0,0.87)",
      "&:after,&:before": {
        borderBottom: "none !important",
      },
      "& > .MuiInputBase-input:focus": {
        backgroundColor: "transparent",
      },
      "& > .MuiSvgIcon-root": {
        marginTop: "-2px",
      },
    },
  },
  type_in_time: {
    position: "relative",
    "& .wrapper-content": {
      overflow: "hidden",
      minWidth: 320,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
      "& .header": {
        padding: "16px 24px",
        fontWeight: 400,
        fontSize: "2.5rem",
        lineHeight: 1.167,
        letterSpacing: "0em",
        backgroundColor: COLORS.primary,
        userSelect: "none",
        "& > label": {
          color: "rgba(0, 0, 0, 0.87)",
          filter: "invert(1)",
          marginBottom: "1rem",
        },
      },
      "center-content": {
        overflowX: "hidden",
        width: 320,
        maxHeight: 358,
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        "click-picker": {
          overflowX: "hidden",
          width: 320,
          maxHeight: 358,
          flexDirection: "column",
          margin: "0 auto",
          display: "flex",
        },
      },
      "& .hour-minute-group": {
        display: "grid",
        gridTemplateColumns: "75% 1fr 10px",
        alignItems: "baseline",
        "& > .hour-minute": {
          display: "grid",
          gridTemplateColumns: "40px 10px 40px",
          alignItems: "center",
        },
      },
    },
    "& .dialog-action": {
      display: "flex",
      alignItems: "center",
      padding: 8,
      justifyContent: "flex-end",
      flex: "0 0 auto",
    },
  },
}));

const DATE_FORMAT = "YYYY-MM-DD hh:mm A";

const MobileTimePicker = (props: MobileTimePickerProps) => {
  const [date, setDate] = useState<MomentType>(moment());
  const [mode, setMode] = useState<ModeType>("pick");
  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");

  const classes = useStyles();

  useEffect(() => {
    if (props.open) {
      setDate(props.date || moment());
    }
  }, [props.open]);

  const handleChangeDate = (date: MomentType, type?: DateType) => {
    setDate(date);

    if (["hh", "mm"].includes(type || "")) {
      setHour(date?.format("hh") || "");
      setMinute(date?.format("mm") || "");
    }
  };

  const handleClose = () => {
    handleClear();
    props.onClose?.();
  };

  const handleOK = () => {
    handleClear();
    props.onOK?.(date);
  };

  const handleCancel = () => {
    handleClear();
    props.onCancel?.();
  };

  const handleClear = () => {
    setMode("pick");
    setHour("");
    setMinute("");
  };

  return (
    <Dialog
      open={props.open}
      className={classes.dialog_custom}
      onClose={handleClose}
    >
      {mode === "pick" ? (
        <CardPickTime
          date={date}
          language={props.language}
          // callback
          onChangeMode={setMode}
          onChangeDate={handleChangeDate}
          onCancel={handleCancel}
          onOK={handleOK}
        />
      ) : (
        <CardTypeInTime
          date={date}
          hour={hour}
          minute={minute}
          language={props.language}
          // callback
          onChangeMode={setMode}
          onChangeDate={handleChangeDate}
          onCancel={handleCancel}
          onOK={handleOK}
        />
      )}
    </Dialog>
  );
};

/* ------------------------------------------------------ */

/*                      CardPickTime                      */

/* ------------------------------------------------------ */
const CardPickTime = (props: TimeProps) => {
  const [loadDiv, setLoadDiv] = useState<boolean>(false);

  const intl = useIntl();

  const divRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const actionElm = divRef.current?.querySelector(".MuiDialogActions-root");

    if (actionElm) {
      // Cancel
      const cancelElm = actionElm.children[0] as HTMLButtonElement;
      cancelElm.style.opacity = "1";
      cancelElm.firstChild?.remove();
      cancelElm.insertAdjacentText(
        "afterbegin",
        intl.formatMessage({ id: "bplusClinicKey105" })
      );
      cancelElm.addEventListener("click", props.onCancel);

      // OK
      const okElm = actionElm.children[1] as HTMLButtonElement;
      okElm.style.opacity = "1";
      okElm.firstChild?.remove();
      okElm.insertAdjacentText(
        "afterbegin",
        intl.formatMessage({ id: "common.ok" })
      );
      okElm.addEventListener("click", props.onOK);
    }

    return () => {
      if (actionElm) {
        // Cancel
        actionElm.children[0].removeEventListener("click", props.onCancel);

        // OK
        actionElm.children[1].removeEventListener("click", props.onOK);
      }
    };
  }, [divRef.current, intl, loadDiv, props.language, props.date]);

  const handleLoadDialog = () => {
    if (!loadDiv) {
      setLoadDiv(true);
    }
  };

  const handleClick = () => {
    props.onChangeMode("type_in");
  };

  const handleChange = (date: MomentType) => {
    props.onChangeDate(date);
  };

  return (
    <div
      ref={divRef}
      style={{ position: "relative" }}
      onLoad={handleLoadDialog}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticTimePicker
          ampm={true}
          displayStaticWrapperAs="mobile"
          value={props.date}
          orientation="portrait"
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <div style={{ position: "absolute", bottom: "9px", left: "6px" }}>
        <Button
          variant="text"
          style={{ minWidth: "52px" }}
          onClick={handleClick}
        >
          <KeyboardIcon style={{ color: "#757575" }} />
        </Button>
      </div>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                         CardTypeInTime                        */

/* ------------------------------------------------------ */
const CardTypeInTime = (props: TimeProps) => {
  const [meridiem, setMeridiem] = useState<string>("");
  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");

  const classes = useStyles();

  useEffect(() => {
    setHour(props.hour || "");
    setMinute(props.minute || "");
  }, []);

  const dateData = useMemo(() => {
    const formatDate = (text: string) => {
      return props.date
        ? moment(props.date).clone().locale("en").format(text)
        : "";
    };

    return {
      hour: formatDate("hh"),
      minute: formatDate("mm"),
      am_pm: formatDate("A") as "AM" | "PM",
    };
  }, [props.date]);

  const translate = useMemo(() => {
    return {
      "th-TH": {
        set_time: "ตั้งเวลา",
        type_in: "พิมพ์เวลา",
        hour: "ชั่วโมง",
        minute: "นาที",
      },
      "en-US": {
        set_time: "Set time",
        type_in: "Type in time",
        hour: "hour",
        minute: "minute",
      },
      "": {},
    }[props.language || ""];
  }, [props.language]);

  const getMeridiem = (meridiem: "AM" | "PM") => {
    const locale = moment.locale() as "th" | "en";

    return {
      th: {
        AM: "ก่อนเที่ยง",
        PM: "หลังเที่ยง",
      },
      en: {
        AM: "AM",
        PM: "PM",
      },
    }[locale][meridiem];
  };

  const meridiemString = useMemo(() => {
    return getMeridiem(dateData.am_pm);
  }, [dateData.am_pm]);

  useEffect(() => {
    setMeridiem(dateData.am_pm);
  }, [dateData.am_pm]);

  const handleClick = () => {
    props.onChangeMode("pick");
  };

  const handleChangePeriod = (e: any) => {
    const value = e.target.value as "AM" | "PM";
    const format = `${props.date?.format("YYYY-MM-DD hh:mm")} ${getMeridiem(
      value
    )}`;

    props.onChangeDate(props.date ? moment(format, DATE_FORMAT) : null, "A");
  };

  const handleChangeHour = (e: any) => {
    const value = !Number.isNaN(Number(e.target.value)) ? e.target.value : "0";

    const time =
      Number(value) >= 12
        ? "12"
        : Number(value)
        ? value.padStart(2, "0")
        : "01";

    handleChange(value, `${time}:${dateData.minute}`, "hh", setHour);
  };

  const handleChangeMinute = (e: any) => {
    const value = !Number.isNaN(Number(e.target.value)) ? e.target.value : "0";

    const time =
      Number(value) >= 59
        ? "59"
        : Number(value)
        ? value.padStart(2, "0")
        : "00";

    handleChange(value, `${dateData.hour}:${time}`, "mm", setMinute);
  };

  const handleChange = (
    value: string,
    time: string,
    type: DateType,
    setState: any
  ) => {
    if (value.length > 2) {
      return;
    }

    setState(value);

    const format = `${props.date?.format(
      "YYYY-MM-DD"
    )} ${time} ${meridiemString}`;

    props.onChangeDate(props.date ? moment(format, DATE_FORMAT) : null, type);
  };

  return (
    <div className={classes.type_in_time}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="wrapper-content">
          <div>
            <div className="header">
              <label>{translate.set_time}</label>
            </div>
            <div className="center-content">
              <div className="clock-picker">
                <div style={{ margin: "26px 24px 4px" }}>
                  <div
                    style={{
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      color: "#212121",
                    }}
                  >
                    {translate.type_in}
                  </div>
                  <div className="hour-minute-group">
                    <div className="hour-minute">
                      <TextField
                        className={classes.underline}
                        label=""
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: { fontSize: "1.6rem", paddingBottom: "2px" },
                        }}
                        variant="standard"
                        placeholder={dateData.hour}
                        value={hour}
                        type="number"
                        onChange={handleChangeHour}
                      />

                      <div style={{ fontSize: "1.5rem", color: "#212121" }}>:</div>
                      <TextField
                        className={classes.underline}
                        label=""
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: {
                            fontSize: "1.6rem",
                            paddingBottom: "2px",
                          },
                        }}
                        variant="standard"
                        color="primary"
                        placeholder={dateData.minute}
                        value={minute}
                        type="number"
                        onChange={handleChangeMinute}
                      />
                      <div
                        style={{
                          color: "#757575",
                          marginTop: "10px",
                        }}
                      >
                        {translate.hour}
                      </div>
                      <div></div>
                      <div
                        style={{
                          color: "#757575",
                          marginTop: "10px",
                        }}
                      >
                        {translate.minute}
                      </div>
                    </div>
                    <div>
                      <FormControl className={classes.hide_underline}>
                        <Select
                          autoWidth={true}
                          variant="standard"
                          inputProps={{
                            style: {
                              fontSize: "1.6rem",
                              paddingBottom: "2px",
                            },
                          }}
                          value={meridiem}
                          onChange={handleChangePeriod}
                        >
                          <MenuItem
                            value={"AM"}
                            style={{
                              width: "fit-content",
                              display: "flex",
                              marginTop: "-8px",
                            }}
                          >
                            AM
                          </MenuItem>
                          <MenuItem
                            value={"PM"}
                            style={{
                              width: "fit-content",
                              display: "flex",
                              marginBottom: "-8px",
                            }}
                          >
                            PM
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-action">
          <Button variant="text" onClick={props.onCancel}>
            <FormattedMessage id="bplusClinicKey105" />
          </Button>
          <Button variant="text" onClick={props.onOK}>
            <FormattedMessage id="common.ok" />
          </Button>
        </div>
      </div>
      <div style={{ position: "absolute", bottom: "9px", left: "6px" }}>
        <Button
          variant="text"
          style={{ minWidth: "52px" }}
          onClick={handleClick}
        >
          <AccessTimeIcon style={{ color: "#757575" }} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(MobileTimePicker);
