import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useState,
  Ref,
  useRef,
  MutableRefObject,
  useImperativeHandle,
  ReactElement,
} from "react";
import { useHistory } from "react-router-dom";

import { Icon, Image } from "semantic-ui-react";
// MUI
import Container from "@mui/material/Container";
import { SxProps } from "@mui/material";

type HeaderBarProps = {
  onEvent?: (e: any) => any;
  // data
  hospitalList?: any[];
  selectedHospital?: any;
  setTitle?: string | ReactElement;
  currentMob?: string;
  whiteTheme?: boolean;
  bgGradient?: boolean;
  safeAreaTop?: string;
  className?: string;
  // styles
  titleStyle?: CSSProperties;
  headerSx?: SxProps;
  style?: CSSProperties;
  // config
  hiddenLeft?: boolean;
  hiddenRight?: boolean;
  buttonLeftback?: boolean;
  iconRight?: any;
  // callback
  onGoBack?: Function;
  rightIconClick?: Function;
  // Element
  children?: ReactElement;
};

const HeaderBar = (props: HeaderBarProps, ref: Ref<any>) => {
  const history = useHistory();

  const [hospitalName, setHospitalName] = useState<string>("");
  const [isSort, setIsSort] = useState<boolean>(false);

  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useImperativeHandle(ref, () => headerRef.current);

  useEffect(() => {
    if (props.hospitalList?.length !== 0 && props.hospitalList) {
      if (props.hospitalList?.length > 1) {
        setIsSort(true);
      }
    }
    if (props.selectedHospital) {
      setHospitalName(props.selectedHospital.name);
    }
  }, [props.hospitalList, props.selectedHospital]);

  const handleSelectedHospital = () => {
    console.log("chen sort:");
    if (isSort) {
      history.push(`/Select-Hospital/?app=${props.currentMob}`);
    }
  };

  const handleOpenBurger = () => {
    props.onEvent?.({
      message: "HandleSetOpenBurger",
      params: {},
    });
  };

  const handleBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    } else {
      history.goBack();
    }
  };

  const handleBacktoNative = () => {
    if (props.rightIconClick) {
      return props.rightIconClick?.();
    }
    props.onEvent?.({
      message: "HandleBacktoNative",
      params: {},
    });
  };

  return (
    <div
      ref={headerRef}
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        width: "100vw",
        ...(props.style || {}),
      }}
    >
      <Container
        className={`hb-container ${props.whiteTheme ? "hb-white-theme" : ""} ${
          props.bgGradient ? "hb-box-bg-gradient" : ""
        } ${props.className || ""}`}
        sx={{ padding: 0, ...props.headerSx ,...(props.safeAreaTop && {paddingTop: `${props.safeAreaTop}px`})}}
      >
        {/* Left */}
        {!props.hiddenLeft ? (
          <div style={{ width: "100%", marginLeft: "15px" }}>
            <div
              aria-hidden="true"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
              onClick={handleOpenBurger}
            >
              <Image
                src="/images/drug/icon_menu.png"
                style={{ height: "15px" }}
              />
            </div>
          </div>
        ) : (
          <>
            {props.buttonLeftback ? (
              <div
                aria-hidden="true"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
                onClick={handleBack}
              >
                <Icon
                  name="chevron left"
                  style={{ fontSize: "1.25rem", marginTop: "-3px" }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
        {/* Center */}
        <div
          aria-hidden="true"
          className="hb-title-bar"
          style={props.titleStyle || {}}
          onClick={() => (isSort ? handleSelectedHospital() : "")}
        >
          {props.setTitle ? (
            <div>{props.setTitle}</div>
          ) : (
            <>
              <div>{hospitalName || ""}</div>
              <div style={{ marginBottom: "5px" }}>
                {isSort ? (
                  <Icon
                    size="large"
                    name="sort descending"
                    style={{ verticalAlign: "unset" }}
                  />
                ) : (
                  ""
                )}
              </div>{" "}
            </>
          )}
        </div>
        {/* Right */}
        {!props.hiddenRight ? (
          <div
            aria-hidden="true"
            className="right-icon"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "25px",
            }}
            onClick={handleBacktoNative}
          >
            {props.iconRight ? (
              <>
                {props.iconRight}
              </>
            ) : (
              <Image
                src="/images/drug/icon_cancel_white.png"
                style={{ height: "15px" }}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </Container>
      {props.children}
      {props.bgGradient && <div className="bg-gradient"></div>}
    </div>
  );
};

const ForwardedHeaderBar = forwardRef<any, HeaderBarProps>(HeaderBar);

ForwardedHeaderBar.defaultProps = {
  onEvent: () => null,
  hospitalList: [],
  selectedHospital: null,
  setTitle: "",
  currentMob: "",
  hiddenLeft: false,
  hiddenRight: false,
  buttonLeftback: false,
};

export default React.memo(ForwardedHeaderBar);
