import React, { CSSProperties, useMemo, useEffect, useState } from "react";
import {
  Icon,
  Input,
  Button,
  Tab,
  Select,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { FormattedMessage, useIntl} from "react-intl";
import ReactTable from "react-table-6";

// Interface
import { Event, State } from "../../ManageInterface";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// UI
import { TabAgeRange } from "./WebUMVaccine";
import SnackMessage from "../../clinic/SnackMessage";

const styles = {
  BGheader: {
    width: "95%",
    height: "100%",
    margin: "43px 54px 50px 34px",
  } as CSSProperties,
  TextAgeHeader: {
    width: "90px",
    fontWeight: "bold",
    marginTop: "8px",
  } as CSSProperties,
  Textheader: {
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "20px",
    textDecorationLine: "underline",
  } as CSSProperties,
  DivInput: {
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  ButtonAgeSave: {
    background: "#219653",
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  ButtonAgeClear: {
    background: "#F2C94C",
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  ButtonAddPatient: {
    background: "#2399E5",
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  TextPatientHeader: {
    padding: "30px 50px 0px 50px",
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "16px",
  } as CSSProperties,
  TextAddHeader: {
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    paddingBottom: "30px",
  } as CSSProperties,
  TextRelationHeader: {
    paddingTop: "30px",
    fontWeight: "bold",
  } as CSSProperties,
  TextTableHeader: {
    borderBottom: "none",
    padding: "0px",
    fontWeight: "bold",
  } as CSSProperties,
  TableCell: {
    borderBottom: "none",
    padding: "10px 10px 0px 0px",
  } as CSSProperties,
  InputAbbreviation: {
    border: "#C4C4C4",
    width: "140px",
    padding: "0px",
  } as CSSProperties,
  InputDescription: {
    border: "#C4C4C4",
    width: "180px",
    padding: "0px",
  } as CSSProperties,
  InputAgeRange: {
    border: "1px solid #C4C4C4",
    width: "180px",
  } as CSSProperties,
  DivHeader: {
    display: "flex",
    paddingTop: "10px",
  } as CSSProperties,
};

type DevelopmentProps = {
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "vaccineUMDetail"
  | "vaccineUMList"
  | "orderItemVaccineUM"
  | "loadingStatus"
  | "ageRangeChoice"
  | "ageRangeDetail"
  | "ageRangeList"
  | "errorMessage"
  | "successMessage"
  | "developmentChoice"
  | "developmentUMDetail"
  | "developmentUMList"
>;

export const Development: React.FC<DevelopmentProps> = (props) => {
  const panes = [
    {
      menuItem: "Age range",
      render: () => (
        <Tab.Pane>
          <TabAgeRange {...props} patientType="DEVELOPMENT" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Development",
      render: () => (
        <Tab.Pane>
          <AddDevelopment {...props} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="main-layout web-dm-um">
      <Tab panes={panes} />
    </div>
  );
};

export const AddDevelopment: React.FC<DevelopmentProps> = (props) => {
  const intl = useIntl();
  const [openModDelete, setOpenModDelete] = useState<number | null>(null);

  useEffect(() => {
    props.onEvent({ message: "GetChoiceDevelopment", params: {} });
    props.onEvent({ message: "GetListDevelopment", params: {} });
  }, []);

  const columnsVaccineItem = useMemo(
    () => [
      {
        Header: "Age range",
        accessor: "ageRange",
        style: {
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => (
          <div>
            {props.ageRangeList?.find(
              (item: any) => item?.id === original?.age_range
            )?.name || ""}
          </div>
        ),
      },
      {
        Header: "Skill",
        accessor: "skill",
        style: {
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => (
          <div>
            {props.developmentChoice?.find(
              (item: any) => item?.id === original?.skill
            )?.value || ""}
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        width: 350,
        Cell: ({ original }: any = {}) => (
          <div>
            {original?.data?.method?.map((item: any) => item.name)?.join(", ")}
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "_icon",
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onEvent({
                  message: "HandleSelectedDevelopmentUM",
                  params: { data: original },
                });
              }}
            >
              <Icon name="edit outline" color="green" />
            </div>
          );
        },
      },
      {
        Header: "Delete",
        accessor: "_icon",
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModDelete(original?.id)}
            >
              <Icon name="minus circle" color="red" />
            </div>
          );
        },
      },
    ],
    [props.ageRangeList, props.developmentChoice]
  );

  const CreateOptions = (items: any[]) => {
    const mapOption = (item: any, index: number) => ({
      key: index,
      text: item.label || item.name || item.value,
      value: item.id,
    });
    return items.map(mapOption);
  };

  const ageRangeOptions = useMemo(() => {
    return CreateOptions(props.ageRangeList || []);
  }, [props.ageRangeList]);

  const developmentOptions = useMemo(() => {
    return CreateOptions(props.developmentChoice || []);
  }, [props.developmentChoice]);

  const descriptionList = useMemo(() => {
    return typeof props.developmentUMDetail?.data?.description?.[0] !==
      "undefined"
      ? props.developmentUMDetail?.data?.description
      : [""];
  }, [props.developmentUMDetail?.data?.description]);

  /* ----------------------- Handler ---------------------- */
  const handleSetDevelopment = (
    params: DevelopmentProps["developmentUMDetail"]
  ) => {
    props.onEvent({ message: "HandleChangeDevelopmentUMDetail", params });
  };

  const handleAddDescription = (type: "add" | "remove", index: number) => {
    const { data } = props.developmentUMDetail || {};
    let description: string[] = [];

    if (type === "add") {
      description = [...(descriptionList || []), ""];
    } else {
      description = (descriptionList || []).filter(
        (_: any, idx) => idx !== index
      );
    }

    handleSetDevelopment({
      data: {
        ...data,
        description,
      },
    });
  };

  const handleClear = () => {
    handleSetDevelopment({
      ...(props.developmentUMDetail || {}),
      age_range: null,
      skill: "",
      data: {
        description: null,
      },
    });
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleGetTrProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original &&
          rowInfo?.original?.id === props.developmentUMDetail?.id
            ? "rgb(214, 236, 243)"
            : "",
      },
    };
  };

  const handleDelete = () => {
    props.onEvent({
      message: "HandleDeleteDevelopmentUM",
      params: { id: openModDelete },
    });
    setOpenModDelete(null);
  };

  return (
    <div style={{ padding: "50px 30px 30px 30px" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <div style={styles.TextAgeHeader}>
              <FormattedMessage id="bplusClinicKey61" />
            </div>
            <Select
              value={props.developmentUMDetail?.age_range || ""}
              options={ageRangeOptions}
              style={styles.InputAgeRange}
              onChange={(e, v: any) => {
                handleSetDevelopment({ age_range: v.value });
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "0px 10px 0px 20px" }}>
              <Button style={styles.ButtonAgeClear} onClick={handleClear}>
                <FormattedMessage id="bplusClinicKey113" />
              </Button>
            </div>
            <div style={{ padding: "0px 10px" }}>
              <Button
                style={styles.ButtonAgeSave}
                onClick={() => {
                  props.onEvent({
                    message: "HandleSaveDevelopmentUM",
                    params: {},
                  });
                }}
              >
                <FormattedMessage id="bplusClinicKey431" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={styles.TextAgeHeader}>
          <FormattedMessage id="bplusClinicKey455" />
        </div>
        <Select
          value={props.developmentUMDetail?.skill || ""}
          options={developmentOptions}
          style={styles.InputAgeRange}
          onChange={(e, v: any) => {
            handleSetDevelopment({ skill: v.value });
          }}
        />
      </div>

      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={styles.TextAgeHeader}>
          <FormattedMessage id="bplusClinicKey157" />
        </div>
        <div style={{ display: "grid" }}>
          {descriptionList.map((item, index) => (
            <div
              key={"description-" + index}
              style={{ display: "flex", paddingBottom: "10px" }}
            >
              <Input
                value={item}
                type="text"
                style={{ border: "#C4C4C4", width: "340px" }}
                onChange={(e, v: any) => {
                  handleSetDevelopment({
                    data: {
                      ...(props.developmentUMDetail?.data || {}),
                      description: descriptionList.map((value, idx) =>
                        idx === index ? v.value : value
                      ),
                    },
                  });
                }}
              />
              <Icon
                name={
                  index === descriptionList.length - 1 ? "plus circle" : "trash"
                }
                color={index === descriptionList.length - 1 ? "blue" : "red"}
                style={{ padding: "10px", cursor: "pointer" }}
                onClick={() => {
                  handleAddDescription(
                    index === descriptionList.length - 1 ? "add" : "remove",
                    index
                  );
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "right", paddingTop: "30px" }}>
        <Input
          icon="search"
          type="text"
          placeholder="Search..."
          style={{ border: "#C4C4C4", width: "300px" }}
          onChange={(e, v) => {
            props.onEvent({
              message: "HandleSearchDevelopmentUM",
              params: { search: v.value },
            });
          }}
        />
      </div>

      <div style={{ paddingTop: "20px" }}>
        <ReactTable
          className="weight-table-custom web-mom"
          data={props.developmentUMList}
          columns={columnsVaccineItem}
          style={{ height: "350px" }}
          showPagination={false}
          getTheadThProps={handleGetTheadThProps}
          getTrProps={handleGetTrProps}
          pageSize={
            (props.developmentUMList?.length || 0) > 10
              ? props.developmentUMList?.length
              : 10
          }
        />
      </div>

      <ModConfirm
        titleColor="red"
        openModal={!!openModDelete}
        onDeny={() => setOpenModDelete(null)}
        onApprove={handleDelete}
        onCloseWithDimmeClick={() => setOpenModDelete(null)}
        content={
          <div style={{ textAlign: "center" }}>{intl.formatMessage({id: "bplusClinicKey812"})}</div>
        }
      />
    </div>
  );
};

export default React.memo(Development);
