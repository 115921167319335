import { WasmHandler } from "../../../react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  GROUP,
  DATA_TYPE,
  BACKEND_DATE_FORMAT,
  commonListPatientDataList,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  recieveFormOptions?: any;
  outsideMedicines?: any;
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "successMessage"
>;

export const StateInitial: State = {
  recieveFormOptions: {
    // Todo: remove
    items: [
      { id: "Other hospital", name: "Other hospital" },
      { id: "Clinic", name: "Clinic" },
      { id: "Pharmacy shop", name: "Pharmacy shop" },
    ],
  },
  outsideMedicines: {},
};

export type Event =
  // GET
  { message: "handleGetOutsideMedicineDetail"; params: {} };

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetOutsideMedicineDetail: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.OUTSIDE_MEDICINE,
    data_type: DATA_TYPE.OUTSIDE_MEDICINE,
    start_measure: moment().add(-1, "months").format(BACKEND_DATE_FORMAT),
    end_measure: moment().format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  console.log("response", response);

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    outsideMedicines: response,
  });
};
