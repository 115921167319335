import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Segment,
  Tab,
} from "semantic-ui-react";
import { AppBar } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";

// UI
import { DateTextBox } from "../../react-lib/apps/common";
import MobHead from "../MobHead";
import PatientPanel from "./PatientPanel";
import SnackMessage from "./SnackMessage";
import MomContractionCountChart from "./MomContractionCountChart";
import { DMDatePicker } from "./DMEmotionRestChart";

// Interface
import {
  BACKEND_TIME_FORMAT,
  convertToADDate,
  formatDate,
  formatDateByLanguage,
} from "../MobClinicInterface";

import "../../css/WebClinic.scss";

export const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  textKickCount: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1621A8",
    paddingBottom: "15px",
  } as CSSProperties,
  textDate: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1621A8",
    paddingTop: "10px",
    textAlign: "center",
  } as CSSProperties,
};

export const DATE_FORMAT = "DD/MM/YYYY";

// ---------- Tab Detail
type TabDetailProps = {
  onEvent?: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  contractionCountFilterDate?: moment.Moment;
  contractionCountDetail?: any;
  // swipeableActionRef?: any;
};

const TabDetail: React.FC<TabDetailProps> = (props) => {
  const intl = useIntl();
  const [lastItem, setLastItem] = useState<any>({});

  useEffect(() => {
    props.onEvent({ message: "handleGetContractionCountDetail" });
  }, [props.selectedPatient, props.contractionCountFilterDate]);

  // useEffect(() => {
  //   if (!props.loadingStatus && props.swipeableActionRef?.current?.updateHeight) {
  //     console.log(" props.swipeableActionRef?.current?.updateHeight() ")
  //     props.swipeableActionRef?.current?.updateHeight()
  //   }
  // }, [props.loadingStatus])
  

  useEffect(() => {
    if (props.contractionCountDetail?.total > 0) {
      const result: any = {
        ...props.contractionCountDetail?.items[
          props.contractionCountDetail.total - 1
        ],
      };
      setLastItem(result);
    } else {
      setLastItem({});
    }
  }, [props.contractionCountDetail]);

  // change date
  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleChangeContractionCountFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: any = props.contractionCountFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeContractionCountFilterDate",
      params: { date: targetDate },
    });
  };

  // save
  const handleAddContractionCount = () => {
    let contractionCountData: any = { ...lastItem };
    if (Object.keys(contractionCountData).length === 0) {
      contractionCountData = { data: { items: [], total: 0 } };
    }

    contractionCountData.data.items.push({
      start_time: moment().format(BACKEND_TIME_FORMAT),
      end_time: null,
      period: null,
    });

    contractionCountData.data.total = contractionCountData.data.items.length;

    props.onEvent({
      message: "handleSaveContractionCount",
      params: contractionCountData,
    });
  };

  const handleUpdateEndContractionCount = (index: number) => {
    let contractionCountData: any = { ...lastItem };
    contractionCountData.data.items[index].end_time =
      moment().format(BACKEND_TIME_FORMAT);

    const start = moment(
      contractionCountData.data.items[index].start_time,
      BACKEND_TIME_FORMAT
    );
    const end = moment(
      contractionCountData.data.items[index].end_time,
      BACKEND_TIME_FORMAT
    );
    const duration = moment.duration(end.diff(start));
    const hours = `${duration?._data?.hours}`.padStart(2, "0");
    const minutes = `${duration?._data?.minutes}`.padStart(2, "0");
    const seconds = `${duration?._data?.seconds}`.padStart(2, "0");
    contractionCountData.data.items[
      index
    ].period = `${hours}:${minutes}:${seconds}`;

    props.onEvent({
      message: "handleSaveContractionCount",
      params: contractionCountData,
    });
  };

  const handleDeleteContractionCount = (index: number) => {
    let contractionCountData: any = { ...lastItem };
    contractionCountData.data.items.splice(index, 1);

    contractionCountData.data.total = contractionCountData.data.items.length;
    if (contractionCountData.data.total > 0) {
      props.onEvent({
        message: "handleSaveContractionCount",
        params: contractionCountData,
      });
    } else {
      props.onEvent({
        message: "handleDeleteContractionCount",
        params: contractionCountData,
      });
    }
  };

  return (
    <>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <DMDatePicker
        date={props.contractionCountFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
      />

      <Grid centered columns={2} style={{ paddingTop: "20px", margin: 0 }}>
        <Grid.Column width={10} style={{ textAlign: "center" }}>
          <Button
            fluid
            color="blue"
            size="big"
            onClick={handleAddContractionCount}
          >
            <FormattedMessage id="bplusClinicKey124" />
          </Button>
          <Header
            as="h4"
            style={{ marginTop: "5px", color: "var(--text-dark-blue)" }}
          >
            <FormattedMessage id="bplusClinicKey398" />
          </Header>
        </Grid.Column>
      </Grid>

      <Grid
        verticalAlign="middle"
        style={{
          ...styles.textCenter,
          fontWeight: "bold",
          color: "var(--text-grey)",
          padding: "0px 10px 20px",
        }}
      >
        <Grid.Row
          style={{
            background: "var(--text-light-blue-20)",
            margin: "0px 15px",
          }}
        >
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey467" />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey182" />
          </Grid.Column>
          <Grid.Column width={6}>
            <FormattedMessage id="bplusClinicKey375" />
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
        </Grid.Row>
        {lastItem?.data?.items?.map((item: any, index: number) => {
          return (
            <Grid.Row
              key={index}
              style={{ margin: "0px 10px", padding: "5px" }}
            >
              <Grid.Column width={4}>{item.start_time}</Grid.Column>
              <Grid.Column width={4}>
                {item.end_time ? (
                  <label>{item.end_time}</label>
                ) : (
                  <Button
                    color="orange"
                    size="mini"
                    onClick={() => {
                      handleUpdateEndContractionCount(index);
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey182" />
                  </Button>
                )}
              </Grid.Column>
              <Grid.Column width={6}>{item.period}</Grid.Column>
              <Grid.Column width={1}>
                <Icon
                  name="minus circle"
                  color="red"
                  size="large"
                  onClick={() => {
                    handleDeleteContractionCount(index);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Row style={{ margin: "0px 10px" }}>
          <Grid.Column width={14} style={{ textAlign: "right" }}>
            <Header as="h3" style={{ color: "var(--text-grey)" }}>
              <FormattedMessage id="bplusClinicKey338" />
            </Header>
          </Grid.Column>
          <Grid.Column width={2} style={styles.noPadding}>
            <Header as="h1" style={{ color: "var(--text-dark-blue)" }}>
              {lastItem?.data?.total || 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Segment
        style={{
          backgroundColor: "var(--theme-bg-color-dm)",
          color: "var(--text-dark-blue)",
          margin: "5px",
        }}
      >
        <Header as="h4" style={{ color: "var(--text-dark-blue)" }}>
          <FormattedMessage id="bplusClinicKey413" />
        </Header>
        <Header as="h4" style={{ color: "var(--text-grey)", margin: "0px" }}>
          {`${intl.formatMessage({ id: "bplusClinicKey905" })} >> ${intl.formatMessage({ id: "bplusClinicKey908" })}`}
        </Header>
        <Header as="h4" style={{ color: "var(--text-grey)", margin: "0px" }}>
          {`${intl.formatMessage({ id: "bplusClinicKey906" })} >> ${intl.formatMessage({ id: "bplusClinicKey909" })}`}
        </Header>
        <Header as="h4" style={{ color: "var(--text-grey)", margin: "0px" }}>
          {`${intl.formatMessage({ id: "bplusClinicKey907" })} >> ${intl.formatMessage({ id: "bplusClinicKey832" })}`}
        </Header>
      </Segment>
    </>
  );
};

// ---------- MomContractionCount
type MomContractionCountProps = {
  // function
  onEvent?: any;
  onSetBackToApp?: () => any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  contractionCountFilterDate?: moment.Moment;
  contractionFilterDuration?: string;
  contractionCountDetail?: any;
  contractionCountHistory?: any;
};

const MomContractionCountInitial: MomContractionCountProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  contractionCountFilterDate: moment(),
  contractionFilterDuration: "7",
  contractionCountDetail: {},
  contractionCountHistory: {},
};

const MomContractionCount: React.FC<MomContractionCountProps> = (props) => {
  const intl = useIntl();
  const [tabIndex, setTabIndex] = useState<number>(0);
  // const swipeableActionRef = React.useRef(null)

  useEffect(() => {
    setTabIndex(0);
  }, [props.selectedPatient]);

  // ----- Tab
  const handleTabChange = (event: object, data: any) => {
    setTabIndex(data.activeIndex);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const handleLeftIconClick = () => {
    props.onSetBackToApp?.();
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="counter" style={styles.tabs}>
            <FormattedMessage id="bplusClinicKey133" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  return (
    <div style={{ height: "100%" }}>
      <AppBar position="fixed" color="default">
        <MobHead
          // title={`${props.selectedPatient?.first_name} ${props.selectedPatient?.last_name}`}
          title={intl.formatMessage({ id: "bplusClinicKey125" })}
          leftIcon="arrow left"
          leftIconClick={handleLeftIconClick}
        />
      </AppBar>

      <PatientPanel
        patientInfo={props.selectedPatient}
        useBackgroundGradient
        // titleFooter="Contraction count"
      />

      <Tab
        className="weight-tab-custom"
        style={{ marginTop: "10px" }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={tabIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews 
        index={tabIndex} 
        onChangeIndex={handleChangeIndex} 
        style={{ height: "calc(100% - 142px)"}} 
        containerStyle={{ height: "100%", WebkitOverflowScrolling: 'touch'}}>
      <div style={{width: "100%", overflowX: "hidden", height: "100%"}}> 
        <TabDetail
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          contractionCountFilterDate={props.contractionCountFilterDate}
          contractionCountDetail={props.contractionCountDetail}
        />
      </div>
      <div style={{width: "100%", overflowX: "hidden", height: "100%"}}> 
        <MomContractionCountChart
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          contractionFilterDuration={props.contractionFilterDuration}
          contractionCountHistory={props.contractionCountHistory}
          height={200}
          isMyTab={tabIndex === 1}
        />
        </div>
        
      </SwipeableViews>
    </div>
  );
};

MomContractionCount.defaultProps = MomContractionCountInitial;

export default React.memo(MomContractionCount);
