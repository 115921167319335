import React, { Suspense } from "react";
// import MobFeed from "./MobFeed";

const MobFeed = React.lazy(() => import("bplus-lib/MobFeed"));

const MobFeedSimulator = () => {

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <MobFeed 
          simulator={true} />
      </Suspense>
    </>
  );
};

export default MobFeedSimulator;
