import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";
// interface
import {
  BACKEND_DATE_FORMAT,
  commonListPatientDataList,
  DATA_TYPE,
  GROUP,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
  commonUpdatePatientData,
  commonGetPatientDataLatest,
  commonGetPatientInfo,
  FOOD_MEAL,
  State as MainState,
} from "../MobClinicInterface";
// api
import ChoiceView from "../../issara-sdk/apis/ChoiceView_core";
import NutritionalMenuList from "../../issara-sdk/apis/NutritionalMenuList_apps_PHRM";
import NutritionalMenuDetail from "../../issara-sdk/apis/NutritionalMenuDetail_apps_PHRM";

export type State = {
  foodFilterDate?: moment.Moment;
  foodListBf?: any;
  weightChanges?: {
    difference: number | string;
    mark: "+" | "-" | "0" | "";
    weight: number;
  };
  nutrientRequirement?: any;
  foodCategoryList?: any;
  menuList?: any;
  foodHistory?: any[];
  foodHistoryFilter?: any[];
  todayMealInfo?: any;
  weekBeforeMealInfo?: any;
  firstDayInWeekMealInfo?: any;
  favoriteFoodList?: any[];
  favoriteFoodDetail?: any;
  additionalFoodList?: any[];
  additionalFoodDetail?: any; // for save patientData id
  loadingFood?: boolean;
};

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage" | "selectedPatient">

export const StateInitial: State = {
  foodFilterDate: moment(),
  foodListBf: [],
  foodHistory: [],
  foodHistoryFilter: [],
  weightChanges: {
    difference: "",
    mark: "",
    weight: 0,
  },
  nutrientRequirement: {},
  foodCategoryList: {},
  menuList: {},
  todayMealInfo: {},
  weekBeforeMealInfo: {},
  firstDayInWeekMealInfo: {},
  favoriteFoodList: [],
  favoriteFoodDetail: {},
  additionalFoodList: [],
  additionalFoodDetail: {},
  loadingFood: false,
};

export type Event =
  // MASTER
  | { message: "handleGetNutritionalMenuType"; params: {} }
  | {
    message: "handleFilterNutritionalMenu";
    params: { type: string; keyword: string };
  }
  // GET
  | { message: "handleGetFoodDetail"; params: {} }
  | { message: "handleGetFoodList"; params: {} }
  | {
    message: "handleGetWeightChanges";
    params: { weight: string; foodFilterDate: moment.Moment | undefined };
  }
  | {
    message: "handleGetNutrientRequirement";
    params: { height: any; weight: any; gender: any };
  }
  | { message: "handleGetConfirmInfo"; params: {} }
  | {
    message: "handleGetHistoryFood";
    params: { selectedDate?: any; duration: string };
  }
  // CHANGE
  | { message: "handleChangeFoodFilterDate"; params: { date: moment.Moment } }
  // SAVE
  | {
    message: "handleAddFood";
    params: { meal: string | null; foodDetail: any; qty: number };
  }
  | { message: "handleToggleFavoriteFood"; params: {} }
  | {
    message: "handleAddFoodMenu";
    params: { name: string; file: any; base64: string };
  }
  | {
    message: "handleDeleteSelectedFood";
    params: { id: number; meal: string };
  }
  | {
    message: "handleDeleteAdditionFood";
    params: { id: number };
  };

type Handler = WasmHandler<State & Picked, Event>;

// MASTER
export const handleGetNutritionalMenuType: Handler = async (controller) => {
  controller.setState({ loadingFood: true });

  const [response, error, network] = await ChoiceView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      model: "PHR.NutritionalMenu",
      field: "type",
      name_as_id: true,
    },
  });

  if (error) {
    controller.setState({
      loadingFood: false,
      errorMessage: error,
      successMessage: null,
      foodCategoryList: {},
    });
  } else {
    controller.setState({
      loadingFood: false,
      errorMessage: null,
      successMessage: null,
      foodCategoryList: response,
    });
  }
};

export const handleFilterNutritionalMenu: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingFood: true });

  const [response, error, network] = await NutritionalMenuList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      master_menu: true,
      approved: true,
      ...params, // type, keyword
    },
  });

  if (error) {
    controller.setState({
      loadingFood: false,
      errorMessage: error,
      successMessage: null,
      menuList: {},
    });
  } else {
    controller.setState({
      loadingFood: false,
      errorMessage: null,
      successMessage: null,
      menuList: response,
    });
  }
};

// GET
export const handleGetFoodDetail: Handler = async (controller) => {
  controller.setState({ loadingFood: true });

  const state = controller.getState();
  const date: string = state.foodFilterDate?.format(BACKEND_DATE_FORMAT) || "";
  let errorMessage: any[] = [];
  let favoriteFoodList: any[] = [];
  let favoriteFoodDetail = {};

  let firstDayWeekMealInfo = await handleGetBeforeWeekMeal(controller);

  // Initial todayMealInfo state
  let todayMealInfo: any = {
    date: date,
  };

  todayMealInfo[FOOD_MEAL.BREAKFAST] = [];
  todayMealInfo[FOOD_MEAL.LUNCH] = [];
  todayMealInfo[FOOD_MEAL.DINNER] = [];
  todayMealInfo[FOOD_MEAL.SNACK] = [];

  const foodParams: any = {
    group: GROUP.FOOD,
    data_type: DATA_TYPE.FOOD,
    start_measure: date,
    end_measure: date,
  };

  const [foodError, foodResponse] = await commonListPatientDataList(
    controller as any,
    foodParams
  );

  if (foodError) {
    errorMessage.push(foodError);
  } else {
    if (foodResponse?.items?.length > 0) {
      todayMealInfo = {
        ...todayMealInfo,
        date: formatUTCtoMoment(foodResponse.items[0].measure_date)
          .locale("en")
          .format("D MMMM YYYY"),
        measure_date: formatUTCtoMoment(foodResponse.items[0].measure_date)
          .locale("en")
          .format("D MMMM YYYY"),
        ...foodResponse.items[0].data,
        proxy_patient: foodResponse.items[0].proxy_patient,
        id: foodResponse.items[0].id,
      };

      // check then update approve food
      todayMealInfo = await handleUpdateApproveFoodMenu(controller, {todayMealInfo: todayMealInfo})
    }
  }


  // console.log("today meal info: ", todayMealInfo)
  const [heightError, heightResp] = await commonGetPatientInfo(controller as any);
  if (heightError) {
    errorMessage.push({ weight: heightError });
  } else {
    if (heightResp?.height) {
      todayMealInfo["height"] = parseFloat(
        heightResp?.height.replace("cm", "")
      );
    }
  }

  const favoriteFoodParams = {
    group: GROUP.FOOD,
    data_type: DATA_TYPE.FAVORITE_FOOD,
  };

  const [favError, favResponse] = await commonGetPatientDataLatest(
    controller as any,
    favoriteFoodParams
  );

  if (favError) {
    console.log("Favorite Food get error", favError);
  } else {
    if (favResponse?.data?.items?.length > 0) {
      favoriteFoodList = [...favResponse.data.items];
      favoriteFoodDetail = {
        id: favResponse.id,
        measure_date: favResponse.measure_date,
        proxy_patient: favResponse.proxy_patient,
        ...favoriteFoodParams,
      };
    }
  }

  await handleGetAdditionMenuList(controller);

  // const additionalFoodParams = {
  //   group: GROUP.FOOD,
  //   data_type: DATA_TYPE.ADDITIONAL_FOOD,
  // };

  // const [additionFoodError, additionFoodResponse] =
  //   await commonGetPatientDataLatest(controller, additionalFoodParams);

  // if (additionFoodError) {
  //   // errorMessage.push(favError)
  //   console.log("Additional Food get error", additionFoodError);
  // } else {
  //   if (additionFoodResponse?.data?.items?.length > 0) {
  //     additionalFoodList = [...additionFoodResponse.data.items];
  //     additionalFoodDetail = {
  //       id: additionFoodResponse.id,
  //       measure_date: additionFoodResponse.measure_date,
  //       proxy_patient: additionFoodResponse.proxy_patient,
  //       ...additionalFoodParams,
  //     };
  //   }
  // }

  // console.log("handleGetNutrientRequirement",
  // todayMealInfo.height, firstDayWeekMealInfo.weight,state.selectedPatient?.gender)
  handleGetNutrientRequirement(controller, {
    height: todayMealInfo.height,
    weight: firstDayWeekMealInfo.weight,
    gender: state.selectedPatient?.gender,
  });

  // console.log("All data:", favoriteFoodList, favoriteFoodDetail, additionalFoodList, additionalFoodDetail)
  controller.setState({
    loadingFood: false,
    todayMealInfo: todayMealInfo,
    favoriteFoodList: [...favoriteFoodList],
    favoriteFoodDetail: { ...favoriteFoodDetail },
    // additionalFoodList: [...additionalFoodList],
    // additionalFoodDetail: { ...additionalFoodDetail },
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });
};

const handleGetAdditionMenuList: Handler = async (controller) => {
  const state = controller.getState();

  const [res, err, network] = await NutritionalMenuList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient.proxy_patient,
    },
  });
  if (err) {
    console.log("handleGetAdditionMenuList error: ", err);
  } else {
    console.log("handleGetAdditionMenuList response: ", res);
  }
  controller.setState({
    additionalFoodList: res?.items?.length > 0 ? res.items : [],
    errorMessage: err,
  });
};

export const handleUpdateApproveFoodMenu: Handler = async (controller, params) => {
  let todayMealInfo = {...params.todayMealInfo}
  let foodMeal = [FOOD_MEAL.BREAKFAST, FOOD_MEAL.LUNCH, FOOD_MEAL.DINNER, FOOD_MEAL.SNACK]

  let isUpdateData = false
  for(const meal of foodMeal){
    if(todayMealInfo?.[meal] && todayMealInfo?.[meal].length > 0){
      const allFoodMeal = todayMealInfo[meal].map(async (food) => {
        let targetFood = {...food}
        if(!food?.energy_cal){
          const [response, error, network] = await NutritionalMenuList.list({
            apiToken: controller.apiToken || Cookies.get("apiToken"),
            params: {
              approved: true,
              keyword: food?.name || food?.name_en
            },
          });
          // console.log("today approve data: ", response, error)
          if(error){
            console.log("error food: ", error)
            return targetFood
          }else{
            if(response?.items?.length > 0){
              isUpdateData = true
              let idSameTargetFood = response.items.find((f: any) => f.id === food.id)
              if(idSameTargetFood){
                targetFood = {...targetFood, ...idSameTargetFood}
              }
              // console.log("today after approve data: ", targetFood)
            }
          }
        }

        return targetFood
      })

      todayMealInfo[meal] = await Promise.all(allFoodMeal)
      // console.log("today after await data: ", todayMealInfo[meal])
    }
  }

  if (isUpdateData) {
    let dataFood = {
      id: todayMealInfo.id,
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FOOD,
      proxy_patient: todayMealInfo.proxy_patient,
      measure_date: `${moment(todayMealInfo.measure_date).format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...todayMealInfo,
      },
    };
    delete dataFood.data["id"];
    delete dataFood.data["date"];
    delete dataFood.data["proxy_patient"];
    delete dataFood.data["measure_date"];
    const [error, response] = await commonUpdatePatientData(controller as any, dataFood);
  }

  return {...todayMealInfo}
}

export const handleGetFoodList: Handler = (controller) => {
  ////
};

const handleGetBeforeWeekMeal: Handler = async (controller) => {
  console.log("handleGetBeforeWeekMeal:");
  const state = controller.getState();
  const foodSelectDate: string =
    state.foodFilterDate?.format(BACKEND_DATE_FORMAT) || "";
  console.log("handleGetBeforeWeekMeal date: ", foodSelectDate);
  console.log(
    "handleGetBeforeWeekMeal datetoString : ",
    foodSelectDate.toString()
  );

  /// Get before one week

  let oneWeekbeforeDate = moment(foodSelectDate).add(-1, "weeks");
  let oneWeekBefore_from_date = oneWeekbeforeDate
    .clone()
    .startOf("week")
    .add(1, "days")
    .add(-1, "years")
    .format(BACKEND_DATE_FORMAT);
  let oneWeekBefore_to_date = oneWeekbeforeDate
    .clone()
    .endOf("week")
    .add(1, "days")
    .format(BACKEND_DATE_FORMAT);
  console.log("handleGetBeforeWeekMeal fisrtDay: ", oneWeekbeforeDate);
  console.log("handleGetBeforeWeekMeal to_date: ", oneWeekBefore_to_date);
  console.log("handleGetBeforeWeekMeal from_date: ", oneWeekBefore_from_date);
  console.log(
    "handleGetBeforeWeekMeal fisrtDay.toString(): ",
    oneWeekbeforeDate.toString()
  );
  console.log(
    "handleGetBeforeWeekMeal to_date.toString(): ",
    oneWeekBefore_to_date.toString()
  );
  console.log(
    "handleGetBeforeWeekMeal from_date.toString(): ",
    oneWeekBefore_from_date.toString()
  );

  let weightChanges: any = { ...state.weightChanges };
  let firstDayInWeekMealInfo = {
    weight: "",
    height: "",
    nutrientRequirement: {},
    weightChanges: {},
    show_info: true,
    show_reccommend_fat_info: true,
    date: moment(foodSelectDate).format(BACKEND_DATE_FORMAT),
  };
  let weekBeforeMealInfo = {};

  let foodParams: any = {
    group: GROUP.FOOD,
    data_type: DATA_TYPE.WEEKLY_FOOD_INFO,
    start_measure: oneWeekBefore_from_date,
    end_measure: oneWeekBefore_to_date,
  };

  const [foodError, foodResponse] = await commonListPatientDataList(
    controller as any,
    foodParams
  );

  let errorMessage = [];
  if (foodError) {
    errorMessage.push(foodError);
  } else {
    // One Week before selected food date
    if (foodResponse?.items?.length > 0) {
      weekBeforeMealInfo = {
        ...foodResponse?.items[foodResponse?.items?.length - 1].data,
      };
    } else {
      console.log("handleGetBeforeWeekMeal  no Found 1 week data");
    }
  }

  /// Get Current week
  let currentWeekSelectDate = moment(foodSelectDate, BACKEND_DATE_FORMAT);
  let currentWeek_from_date = currentWeekSelectDate
    .clone()
    .startOf("week")
    .add(1, "days")
    .format(BACKEND_DATE_FORMAT);
  let currentWeek_to_date = currentWeekSelectDate
    .clone()
    .endOf("week")
    .add(1, "days")
    .format(BACKEND_DATE_FORMAT);

  console.log("handleGetBeforeWeekMeal 2fisrtDay: ", currentWeekSelectDate);
  console.log("handleGetBeforeWeekMeal 2to_date: ", currentWeek_to_date);
  console.log("handleGetBeforeWeekMeal 2from_date: ", currentWeek_from_date);
  console.log(
    "handleGetBeforeWeekMeal 2fisrtDay.toString(): ",
    currentWeekSelectDate.toString()
  );
  console.log(
    "handleGetBeforeWeekMeal 2to_date.toString(): ",
    currentWeek_to_date.toString()
  );
  console.log(
    "handleGetBeforeWeekMeal 2from_date.toString(): ",
    currentWeek_from_date.toString()
  );

  foodParams = {
    ...foodParams,
    data_type: DATA_TYPE.WEEKLY_FOOD_INFO,
    start_measure: currentWeek_from_date,
    end_measure: currentWeek_to_date,
  };

  const [firstDayError, firstDayResponse] = await commonListPatientDataList(
    controller as any,
    foodParams
  );

  if (firstDayError) {
    errorMessage.push(firstDayError);
  } else {
    if (firstDayResponse?.items?.length > 0) {
      firstDayInWeekMealInfo = {
        ...firstDayInWeekMealInfo,
        ...firstDayResponse?.items[0].data,
        measure_date: firstDayResponse?.items[0].measure_date,
        proxy_patient: firstDayResponse?.items[0].proxy_patient,
        id: firstDayResponse?.items[0].id,
      };
      weightChanges = { ...firstDayResponse?.items[0].data["weightChanges"] };
    } else {
      console.log(" No Data in this week");
    }
  }

  // expect result firstDayInWeekMealInfo
  // weight height weightChange nutritionrequirement

  console.log("weekBeforeMealInfo:", weekBeforeMealInfo);
  controller.setState({
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
    firstDayInWeekMealInfo,
    weightChanges,
    weekBeforeMealInfo,
  });

  return firstDayInWeekMealInfo;
};

export const handleGetWeightChanges: Handler = async (controller, params) => {
  console.log(" handleGetWeightChanges ");
  const { todayMealInfo, selectedPatient } = controller.getState();
  const { weight } = params;
  const [weightChanges, nutrientRequirement] =
    await handleGetNutrientRequirement(controller, {
      height: todayMealInfo.height,
      weight: weight,
      gender: selectedPatient?.gender,
    });

  console.log(
    " Return of handleGetNutrientRequirement: weightChanges, nutrientRequirement, and params",
    weightChanges,
    nutrientRequirement,
    params
  );

  console.log(" handleGetWeightChanges called handleCreateUpdateWeight");
  await handleCreateUpdateWeight(controller, {
    weight,
    height: todayMealInfo.height,
    weightChanges,
    nutrientRequirement,
    foodFilterDate: params.foodFilterDate,
  });
};

export const handleGetNutrientRequirement: Handler = (controller, params) => {
  const { height, weight } = params;
  const state = controller.getState();
  // let fisrtDay = moment().add(-1, "weeks");
  // let from_date = fisrtDay.startOf("week").format("YYYY-MM-DD");
  // let to_date = fisrtDay.endOf("week").format("YYYY-MM-DD");
  let findFirstDate = { ...state.weekBeforeMealInfo };
  let weightChanges: any = {};
  let nutrientRequirement = {};

  console.log(
    "+state.weekBeforeMealInfo?.weight: ",
    +state.weekBeforeMealInfo?.weight
  );
  console.log("state: ", state);
  //

  if (weight) {
    weightChanges = calculateWeightChanges(
      height,
      +state.weekBeforeMealInfo?.weight || params.weight,
      params.weight
    );

    controller.setState({
      weightChanges: weightChanges,
    });
  }

  let weekBefore = { ...state.weekBeforeMealInfo };
  console.log("weekBeforeMealinfo: ", weekBefore);
  // if (weekBefore) {
  let gender = params.gender;
  if (gender && gender !== "F" && gender !== "M") {
    if (gender.toUpperCase() === "FEMALE") {
      gender = "F";
    } else if (gender.toUpperCase() === "MALE") {
      gender = "M";
    } else {
      gender = "";
    }
  }
  //   let firstDay = { ...state.firstDayInWeekMealInfo };

  //   if (firstDay) {
  //     if (weightChanges.weight != null) {
  //       nutrientRequirement = calculateNutrientRequirement({
  //         ...params,
  //         weight: weightChanges.weight,
  //       });
  //       controller.setState({ nutrientRequirement: nutrientRequirement });
  nutrientRequirement = calculateNutrientRequirement({
    ...params,
    gender: gender,
    week_before_weight: +weekBefore?.weight || null,
    // weight: firstDay.weight,
  });
  controller.setState({ nutrientRequirement: nutrientRequirement });
  // }

  return [weightChanges, nutrientRequirement];
};

export const handleGetConfirmInfo: Handler = async (controller, params) => {
  const state = controller.getState();
  const data = { ...state.firstDayInWeekMealInfo };
  if (state.firstDayInWeekMealInfo.id) {
    let dataFood = {
      id: state.firstDayInWeekMealInfo.id,
      proxy_patient: state.firstDayInWeekMealInfo.proxy_patient,
      measure_date: moment(state.firstDayInWeekMealInfo.measure_date).format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...data,
        show_info:
          params.weight_info !== undefined
            ? params.weight_info
            : data.show_info,
        show_reccommend_fat_info:
          params.fat_info !== undefined
            ? params.fat_info
            : data.show_reccommend_fat_info,
      },
    };

    delete dataFood.data["id"];
    delete dataFood.data["date"];
    delete dataFood.data["proxy_patient"];
    delete dataFood.data["measure_date"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      dataFood
    );
    let errorMessage: any = [];
    if (error) {
      errorMessage.push(error);
    } else {
      controller.setState({
        firstDayInWeekMealInfo: {
          ...state.firstDayInWeekMealInfo,
          show_info:
            params.weight_info !== undefined
              ? params.weight_info
              : data.show_info,
          show_reccommend_fat_info:
            params.fat_info !== undefined
              ? params.fat_info
              : data.show_reccommend_fat_info,
        },
      });
    }
  }
};

export const handleAddFoodMenu: Handler = async (controller, params) => {
  const { name, file, base64 } = params;
  const state = controller.getState();

  let errorMessage: any = [];
  let successMessage: any = null;

  const [res, error] = await NutritionalMenuList.create({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: {
      name: name,
      name_en: name,
      image: file,
      owner: state.selectedPatient.proxy_patient || null,
      active: true,
    },
  });
  if (error) {
    errorMessage.push(error);
  } else {
    successMessage = "Add new menu complete.";
  }

  controller.setState({
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });

  await handleGetAdditionMenuList(controller);
  return true;
};

export const handleGetHistoryFood: Handler = async (controller, params) => {
  controller.setState({ loadingFood: true });
  const state = controller.getState();
  //mock data
  let foodHistory = [];

  let errorMessage = [];
  let endDate = moment();
  if (params.selectedDate) {
    endDate = moment(params.selectedDate);
  }

  let type = params?.duration || "5_DAY";
  const startDate = {
    "5_DAY": endDate.clone().add(-4, "days"),
    "7_DAY": endDate.clone().add(-6, "days"),
    "10_DAY": endDate.clone().add(-9, "days"),
  }[type as "5_DAY"];

  const foodParams = {
    group: GROUP.FOOD,
    data_type: DATA_TYPE.FOOD,
    start_measure: startDate.format(BACKEND_DATE_FORMAT),
    end_measure: endDate.format(BACKEND_DATE_FORMAT),
  };

  const [err, res] = await commonListPatientDataList(controller as any, foodParams);
  if (err) {
    errorMessage.push(err);
  } else {
    if (res.items?.length > 0) {
      foodHistory = res.items.map((food: any) => {
        let breakfast = food.data[FOOD_MEAL.BREAKFAST];
        let lunch = food.data[FOOD_MEAL.LUNCH];
        let dinner = food.data[FOOD_MEAL.DINNER];
        let snack = food.data[FOOD_MEAL.SNACK];

        const concat = [...breakfast, ...lunch, ...dinner, ...snack];
        const sum = concat.reduce(
          (result, item) => {
            result.protein += item.protein_grams * item.qty || 0;
            result.fat += item.fat_grams * item.qty || 0;
            result.carbohydrate += item.cabohydrate_grams * item.qty || 0;
            result.sodium += item.sodium_milligram * item.qty || 0;
            result.sugar += item.sugar_grams * item.qty || 0;
            result.sat_fat += item.sat_fat_milligram * item.qty || 0;
            result.calories += item.energy_cal * item.qty || 0;

            return result;
          },
          {
            protein: 0,
            fat: 0,
            carbohydrate: 0,
            sodium: 0,
            sugar: 0,
            sat_fat: 0,
            calories: 0,
          }
        );
        return {
          ...food.data,
          date: formatUTCtoMoment(food.measure_date).format("YYYY-MM-DD"),
          ...sum,
        };
      });
    }
  }
  let runningDate = startDate.clone();

  let foodDataHistory = [];
  // check isBetween at equal start and end
  startDate.add(-1, "days");
  if (params.selectedDate) {
    endDate.add(1, "days");
  }
  while (
    moment(runningDate.format("YYYY-MM-DD"), "YYYY-MM-DD").isBetween(
      startDate,
      endDate
    )
  ) {
    let dateString = runningDate.format("YYYY-MM-DD");
    let matchData = foodHistory.find((item: any) => item.date === dateString);
    if (matchData) {
      foodDataHistory.push(matchData);
    } else {
      foodDataHistory.push({ ...setInvalidFoodHistory(), date: dateString });
    }
    runningDate.add(1, "days");
  }

  console.log("FoodHistory:", foodDataHistory);

  controller.setState({
    foodHistory: foodDataHistory,
    foodHistoryFilter: foodHistory,
    loadingFood: false,
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });
};

// CHANGE
export const handleChangeFoodFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ foodFilterDate: moment(params.date, "DD/MM/YYYY") });
};

// SAVE
export const handleCreateUpdateWeight: Handler = async (controller, params) => {
  console.log("handleCreateUpdateWeight  start ");
  const state = controller.getState();
  const { weight, date, height, weightChanges, nutrientRequirement } = params;
  let saveData = { ...state.firstDayInWeekMealInfo };
  let firstDayInWeekMealInfo = {};
  let errorMessage: any = null;
  let successMessage: any = null;

  /*****
   *
   *
   *    Update Weight
   *
   *
   *  */

  if (state.firstDayInWeekMealInfo?.id) {
    console.log("handleCreateUpdateWeight  case update  ");
    let dataFood = {
      id: state.firstDayInWeekMealInfo.id,
      proxy_patient: state.firstDayInWeekMealInfo.proxy_patient,
      measure_date: params.foodFilterDate
        ?.clone()
        .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
      data: {
        ...saveData,
        weight: weight,
        height: height,
        show_info: true,
        show_reccommend_fat_info: true,
        weightChanges: { ...weightChanges },
        nutrientRequirement: { ...nutrientRequirement },
      },
    };
    delete dataFood.data["id"];
    delete dataFood.data["date"];
    delete dataFood.data["proxy_patient"];
    delete dataFood.data["measure_date"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      dataFood
    );
    if (error) {
      errorMessage = error;
    } else {
      successMessage = `Save weight success.`;
      firstDayInWeekMealInfo = {
        ...dataFood.data,
        date: state.firstDayInWeekMealInfo.date,
        proxy_patient: dataFood.proxy_patient,
        measure_date: dataFood.measure_date,
        id: state.firstDayInWeekMealInfo.id,
      };
    }
  } else {
    /*****
     *
     *
     *    Create Weight
     *
     *
     ******/
    console.log("handleCreateUpdateWeight  case new   ");
    let foodData = {
      group: GROUP.FOOD,
      data_type: DATA_TYPE.WEEKLY_FOOD_INFO,
      measure_date: params.foodFilterDate
        ?.clone()
        .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
      data: {
        ...saveData,
        weight: weight,
        height: height,
        show_info: true,
        show_reccommend_fat_info: true,
        weightChanges: { ...weightChanges },
        nutrientRequirement: { ...nutrientRequirement },
      },
    };

    delete foodData.data["date"];

    const [error, response] = await commonCreatePatientData(
      controller as any,
      foodData
    );
    if (error) {
      errorMessage = error;
    } else {
      successMessage = `Save weight success.`;
      firstDayInWeekMealInfo = {
        ...foodData.data,
        date: formatUTCtoMoment(response.measure_date).format("YYYY-MM-DD"),
        proxy_patient: response.proxy_patient,
        measure_date: response.measure_date,
        id: response.id,
      };
    }
  }

  let todayFoodData = {...state.todayMealInfo}
  if(state.todayMealInfo?.id){
    let updateFoodData = {
      id: todayFoodData.id,
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FOOD,
      proxy_patient: todayFoodData.proxy_patient,
      measure_date: `${moment(todayFoodData.measure_date).format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...todayFoodData,
        calories_budget: nutrientRequirement.calories_budget || 1,
        nutrientionRequirement: { ...nutrientRequirement },
      },
    };
    delete updateFoodData.data["id"];
    delete updateFoodData.data["date"];
    delete updateFoodData.data["proxy_patient"];
    delete updateFoodData.data["measure_date"];
    const [error, response] = await commonUpdatePatientData(controller as any, updateFoodData);
    todayFoodData = {
      ...todayFoodData,
      calories_budget: nutrientRequirement.calories_budget || 1,
      nutrientionRequirement: { ...nutrientRequirement },
    }

    // console.log("data dm food: ", todayFoodData)
  }

  controller.setState({
    todayMealInfo: todayFoodData,
    firstDayInWeekMealInfo: { ...firstDayInWeekMealInfo },
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleAddFood: Handler = async (controller, params) => {
  const state = controller.getState();

  let data: any = { ...state.todayMealInfo };
  const meal: string = params.meal.toLowerCase();

  // check duplicate menu
  const foodDup: any = data[meal].find(
    (item: any) => params?.foodDetail?.id === item.id
  );
  if (foodDup) {
    data[meal] = data[meal].map((item: any) => {
      return item.id === params?.foodDetail?.id
        ? { ...item, qty: item.qty + params?.qty }
        : item;
    });
  } else {
    data[meal].push({ ...params?.foodDetail, qty: params?.qty });
  }

  let error: any = null;
  let response: any = null;

  // update
  if (data?.id) {
    let dataFood = {
      id: data.id,
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FOOD,
      proxy_patient: data.proxy_patient,
      measure_date: `${moment(data.measure_date).format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...data,
        calories_budget: state.nutrientRequirement.calories_budget || 1,
        nutrientionRequirement: { ...state.nutrientRequirement },
      },
    };
    delete dataFood.data["id"];
    delete dataFood.data["date"];
    delete dataFood.data["proxy_patient"];
    delete dataFood.data["measure_date"];
    [error, response] = await commonUpdatePatientData(controller as any, dataFood);
  }
  // create
  else {
    let foodData = {
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FOOD,
      measure_date: `${state?.foodFilterDate?.format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...data,
        calories_budget: state.nutrientRequirement.calories_budget || 1,
        nutrientionRequirement: { ...state.nutrientRequirement },
      },
    };
    delete foodData.data["date"];

    [error, response] = await commonCreatePatientData(controller as any, foodData);
  }
  let successMessage = null;
  if (error) {
  } else {
    successMessage = `Save Food success.`;
    handleGetFoodDetail(controller);
  }
  controller.setState({
    errorMessage: error ? error : null,
    successMessage: successMessage,
  });
};

export const handleToggleFavoriteFood: Handler = async (controller, params) => {
  const state = controller.getState();

  let favoriteList: any[] = [...(state.favoriteFoodList || [])];

  // check duplicate menu
  const foodDup: any = favoriteList.find((item: any) => params?.id === item.id);
  if (foodDup) {
    favoriteList = favoriteList.filter((item:any)=>item.id !== params?.id)
    
  } else {
    favoriteList.push({ ...params });
  }

  let error: any = null;
  let response: any = null;
  // update
  if (state.favoriteFoodDetail?.id) {
    let data = {
      ...state.favoriteFoodDetail,
      data: { items: [...favoriteList] },
    };
    [error, response] = await commonUpdatePatientData(controller as any, data);
  }
  // create
  else {
    let data = {
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FAVORITE_FOOD,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: { items: [...favoriteList] },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }
  let successMessage = null;
  if (error) {
  } else {
    successMessage = `Save Favorite Food success.`;
    handleGetFoodDetail(controller);
  }
  controller.setState({
    errorMessage: error ? error : null,
    successMessage: successMessage,
  });
};

export const handleDeleteSelectedFood: Handler = async (controller, params) => {
  const state = controller.getState();
  let data: any = { ...state.todayMealInfo };
  const meal: string = params.meal.toLowerCase();

  data[meal] = data[meal].filter((food: any) => food.id !== params.id);
  if (data?.id) {
    let dataFood = {
      id: data.id,
      group: GROUP.FOOD,
      data_type: DATA_TYPE.FOOD,
      proxy_patient: data.proxy_patient,
      measure_date: `${moment(data.measure_date).format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: { ...data },
    };
    delete dataFood.data["id"];
    delete dataFood.data["date"];
    delete dataFood.data["proxy_patient"];
    delete dataFood.data["measure_date"];
    const [error, response] = await commonUpdatePatientData(
      controller as any,
      dataFood
    );
    let successMessage = null;
    if (error) {
    } else {
      successMessage = `Save Food success.`;
      handleGetFoodDetail(controller);
    }
    controller.setState({
      errorMessage: error ? error : null,
      successMessage: successMessage,
    });
  } else {
    controller.setState({ errorMessage: "ID not found." });
  }
};

export const handleDeleteAdditionFood: Handler = async (controller, params) => {
  const { id } = params;
  const { additionalFoodList, additionalFoodDetail } = controller.getState();
  FOOD_DATA.additional = FOOD_DATA.additional.filter(
    (item: any) => item.id !== id
  );
  let errorMessage = [];
  let additional_food = [...(additionalFoodList || [])].filter(
    (food) => food.id !== id
  );

  // let additionalFoodData = {
  //   id: additionalFoodDetail.id,
  //   proxy_patient: additionalFoodDetail.proxy_patient,
  //   measure_date: moment(additionalFoodDetail.measure_date).format(
  //     `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
  //   ),
  //   group: GROUP.FOOD,
  //   data_type: DATA_TYPE.ADDITIONAL_FOOD,
  //   data: {
  //     items: [...additional_food],
  //   },
  // };

  const [error, response] = await NutritionalMenuDetail.delete({
    pk: id,
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });
  if (error) {
    errorMessage.push(error);
  }

  controller.setState({
    additionalFoodList: additional_food,
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });
};

// Utils

const calculateWeightChanges = (
  height: number,
  beforeWeight: number,
  currentWeight: number
): {
  difference: number | string;
  mark: "+" | "-" | "0" | "";
  weight: number;
  bmi: string | number;
} => {
  const value = beforeWeight;
  const diff = currentWeight - value;
  const mark = diff > 0 ? "+" : diff < 0 ? "-" : "0";
  let bmi: string | number = currentWeight / (height / 100) ** 2;

  bmi = bmi.toFixed(1);
  console.log("bmi", height, beforeWeight, currentWeight)
  return { difference: Math.abs(diff), mark, weight: currentWeight, bmi };
};

// load and map
const mapData: Handler = (controller, array: any[] = []) => {
  const { foodCategoryList } = controller.getState();

  return array.map((item: any) => ({
    ...(foodCategoryList
      ?.find((list: any) => list.id === item.category)
      ?.items?.find((list: any) => list.id === item.food_id) || {}),
    ...item,
  }));
};

/**weight: ABW:Actual body weight */
const calculateNutrientRequirement = (params: {
  height: number;
  weight: any;
  week_before_weight: any;
  gender: "F" | "M";
}) => {
  console.log("calculateNutrientRequirement", params);
  const { height, weight: abw, gender, week_before_weight } = params;
  let bmi = abw / (height / 100) ** 2;
  console.log("abw", abw);
  console.log("height", height);
  bmi = +bmi.toFixed(2);
  const status =
    bmi < 18.5
      ? "UNDERWEIGHT"
      : bmi >= 18.5 && bmi <= 22.9
        ? "NORMAL"
        : bmi >= 23 && bmi <= 29.9
          ? "OVERWEIGHT"
          : "OBESE";

  // Calories Budget
  let caloriesBudget = {
    UNDERWEIGHT: abw * 30 + 500,
    NORMAL: abw * 30,
    OVERWEIGHT: abw * 30 - 500,
    OBESE: abw * 14,
  }[status];

  if (status === "OBESE" || status === "OVERWEIGHT" ) {
    if (gender === "F" && caloriesBudget < 1200) {
      caloriesBudget = 1200;
    }
    if (gender === "M" && caloriesBudget < 1500) {
      caloriesBudget = 1500;
    }
  }

  if (status === "UNDERWEIGHT") {
    if (week_before_weight && abw - week_before_weight <= 0) {
      caloriesBudget = caloriesBudget + 500;
    }
  }

  console.log("caloriesBudget: ", status, caloriesBudget);
  // const adjustedIBW = {
  //   F: abw * 0.33 + (height - 105),
  //   M: abw * 0.33 + (height - 110),
  // }[gender];

  const adjustedIBW = {
    adjusted:
      gender === "F"
        ? abw * 0.33 + (height - 105)
        : abw * 0.33 + (height - 110),
  };
  console.log("adjustedIBW.adjusted", adjustedIBW.adjusted);
  // Protein
  const proteinRequirement = {
    UNDERWEIGHT: abw * 1.3,
    NORMAL: abw * 1,
    OVERWEIGHT: adjustedIBW.adjusted * 1.3,
    OBESE: adjustedIBW.adjusted * 1.3,
  }[status];

  const proteinCal = Math.abs(proteinRequirement * 4);
  let protein = {
    gram: +proteinRequirement.toFixed(2),
    cal: +proteinCal.toFixed(2),
    percent: +((proteinCal / caloriesBudget) * 100).toFixed(2),
  };
  // Condition percent > 35
  if (protein.percent > 35) {
    const proteinCal = (35 * caloriesBudget) / 100;
    protein = {
      gram: +(proteinCal / 4).toFixed(2),
      cal: +proteinCal.toFixed(2),
      percent: 35,
    };
  }

  // Carbohydrate Ratio
  let carbohydrateRatio = 0;
  if (protein.percent >= 30 && protein.percent <= 35) {
    carbohydrateRatio = 45;
  } else if (protein.percent >= 20 && protein.percent <= 29.9) {
    carbohydrateRatio = 50;
  } else if (protein.percent <= 19) {
    carbohydrateRatio = 55;
  }

  // Carbohydrate
  const carbohydrateCal = (carbohydrateRatio / 100) * caloriesBudget;
  const carbohydrate = {
    gram: +(carbohydrateCal / 4).toFixed(2),
    cal: +carbohydrateCal.toFixed(2),
    percent: +((carbohydrateCal / caloriesBudget) * 100).toFixed(2),
  };

  // Fat
  const fatPercent = 100 - protein.percent - carbohydrate.percent;
  const fat = {
    gram: +((fatPercent * caloriesBudget) / 100 / 9).toFixed(2),
    cal: +((fatPercent * caloriesBudget) / 100).toFixed(2),
    percent: +fatPercent.toFixed(2),
  };

  // SatFat
  const satFatCal = 0.07 * caloriesBudget;
  const sat_fat = {
    cal: +satFatCal.toFixed(2),
    gram: +(satFatCal / 9).toFixed(2),
    percent: 7, // <=== 7 คืออะไร
  };
  console.log(
    "adjustedIBW.adjusted",
    adjustedIBW.adjusted,
    protein.percent,
    carbohydrate.percent
  );

  if (params?.weight === '') {
    const data = {
      calories_budget: 0,
      protein,
      carbohydrate,
      fat,
      sat_fat,
      bmi,
      status,
      earned: 0,
      first_time: true,
    };

    console.log("Nutrition requirement: ", data);
    return data;
  } else {
    const data = {
      calories_budget: caloriesBudget,
      protein,
      carbohydrate,
      fat,
      sat_fat,
      bmi,
      status,
      earned: 0,
      first_time: false,
    };

    console.log("Nutrition requirement: ", data);
    return data;
  }
  
};

let FOOD_DATA = {
  additional: [
    {
      id: 1,
      name: "ส้มตำ",
      image_url: "/images/pork-noddle-namtok.png",
    },
  ],
  favorites: [
    {
      id: 1,
      food_id: 9,
      category: 3,
    },
  ],
  daily_food: [
    {
      weight: 49,
      date: "2021-09-13",
      breakfast: [],
      lunch: [],
      dinner: [],
    },
    {
      weight: 60,
      date: "2021-09-21",
      breakfast: [
        {
          id: 1,
          food_id: 1,
          category: 1,
          qty: 2,
        },
        {
          id: 2,
          food_id: 2,
          category: 1,
          qty: 1,
        },
      ],
      lunch: [
        {
          id: 3,
          food_id: 3,
          category: 7,
          qty: 1,
        },
      ],
      dinner: [],
    },
    {
      date: "2021-09-22",
      weight: 55,
    },
    // {
    //   date: "2021-09-27",
    //   weight: 57,
    //   breakfast: [
    //     {
    //       id: 1,
    //       name: "ส้มตำ",
    //       image_url: "/images/pork-noddle-namtok.png",
    //       qty: 1,
    //     },
    //   ]
    // },
  ],
};

const setInvalidFoodHistory = () => {
  return {
    carbohydrate: 0,
    protein: 0,
    fat: 0,
    sodium: 0,
    sugar: 0,
    saturated_fat: 0,
    fiber: 0,
  };
};
