import React, { CSSProperties, ReactChild } from "react";

import { makeStyles, Button as MuiButton } from "@material-ui/core";
import { Button, Header, Icon } from "semantic-ui-react";

const COLOR = {
  blue: "var(--blue-bdms-color)",
  background: "var(--primary-bg-color)",
};

const TopBarStyle = {
  padding: "10px 5px",
  width: "100%",
  justifyContent: "space-between",
  minHeight: "40px",
  color: COLOR.blue,
  display: "flex",
  alignItems: "center",
  background: "transparent",
};

type MobHeadProps = {
  title?: string|ReactChild;
  titleColor?: string;
  subTitle?: string;
  leftIconClick?: () => void;
  subTitleComponent?: ReactChild;
  hideBackButton?: boolean;
  subTitleStyle?: CSSProperties;
  barStyle?: CSSProperties;
  titleStyle?: CSSProperties;
};

const useStyles = makeStyles((theme) => ({
  left_icon: {
    textTransform: "unset",
    minWidth: "fit-content",
    borderRadius: ".28571429rem",
    display: "inline-block",
    color: "rgba(0,0,0,.6)",
    padding: "5.5px 9px",
    background: COLOR.background,
  },
}));

export const MobHead = (props: MobHeadProps) => {
  const classes = useStyles();

  return (
    <div style={{ ...TopBarStyle, ...(props.barStyle || {}) }}>
      {!props.hideBackButton ? (
        <div
          style={{ flex: 1, display: "flex", justifyContent: "center" }}
          onClick={props.leftIconClick}
        >
          <MuiButton
            variant="text"
            color="primary"
            className={classes.left_icon}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          >
            <Icon name="angle left" style={{ fontSize: "14px" }} />
          </MuiButton>
        </div>
      ) : null}
      <div
        style={{
          flex: 4.3,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "big",
        }}
      >
        {props.subTitle && (
          <Header
            as="h4"
            style={{
              color: "grey",
              margin: "0px",
              marginBottom: props.title ? "3px" : "0px",
              fontSize: "14px",
              ...(props.subTitleStyle || {}),
            }}
          >
            {props.subTitle}
          </Header>
        )}
        <Header
          as="h4"
          style={{
            color: props.titleColor ? props.titleColor : COLOR.blue,
            marginTop: "0px",
            fontSize: "16px",
            fontWeight: "bold",
            ...(props.titleStyle || {}),
          }}
        >
          {props.title}
        </Header>
        {props.subTitleComponent}
      </div>
      {!props.hideBackButton ? (
        <div style={{ flex: 1, textAlign: "right" }}></div>
      ) : null}
    </div>
  );
};

export default MobHead;
