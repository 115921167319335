import React from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

// I.Styles
import { cardStyles } from "./CardPayment";

const useStyles = makeStyles((theme) => ({
  card: cardStyles,
}));

const CardPaymentSkeleton = () => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      style={{
        boxShadow: "1px 4px 9px 0px rgba(44, 44, 44, 0.025)",
      }}
    >
      <CardActionArea disableRipple={true}>
        <CardContent>
          <div className="header">
            <Skeleton
              animation="pulse"
              variant="text"
              width={"40%"}
              height={18}
            />
          </div>
          <div className="content">
            <Skeleton
              animation="pulse"
              variant="text"
              width={"40%"}
              height={16}
            />
            <Skeleton
              animation="pulse"
              variant="text"
              width={"50%"}
              height={16}
            />

            {[{ id: 1 }].map((item, index) => (
              <div key={item.id} className="doctor">
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={"10%"}
                  height={14}
                />
                <div className="doctor-profile-detail">
                  <div>
                    <Skeleton animation="pulse" variant="circular" />
                  </div>
                  <div>
                    <Skeleton
                      animation="pulse"
                      variant="text"
                      width={"80%"}
                      height={16}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="text"
                      width={"30%"}
                      height={16}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="pay">
              <div>
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={50}
                  height={16}
                />
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={100}
                  height={16}
                />
              </div>
              <div></div>
            </div>
          </div>

          <div style={{ padding: "10px" }}>
            <Skeleton
              animation="pulse"
              variant="text"
              width={"30%"}
              height={16}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardPaymentSkeleton);
