import WasmController, {
  WasmHandler,
} from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// APIs
import ProxyPatientCreateBindHNView from "issara-sdk/apis/ProxyPatientCreateBindHNView_apps_PRX";
import ProxyPatientNoUserListView from "issara-sdk/apis/ProxyPatientNoUserListView_apps_PRX";
import TrakCarePatientInfo from "issara-sdk/apis/TrakCarePatientInfo_users";
import StaffDirectAllergyView from "issara-sdk/apis/StaffDirectAllergyView_apps_PRX";
import ProxyPatientDetail from "issara-sdk/apis/ProxyPatientDetail_apps_PRXM";
// Constant
import {
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonCreatePatientData,
  commonUpdatePatientData,
  calculateDelivery,
  BACKEND_DATE_FORMAT,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

import { apgarScore } from "./WebMomObstreticRecord";
import { deriveryNewbordRecordForm } from "../../../react-lib/utils/deliveryNewbornRecordForm";

// import getPdfMake from "../../../react-lib/appcon/common/pdfMake";

export type State = {
  obstetricHistoryList?: Record<string, any>[];
  obstetricChildList?: Record<string, any>[];
  activeIndexBaby?: number | null;
  activeIndexObstetricHistory?: number;
  openModConfirmCreate?: any;
};
type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export const StateInitial: State = {
  obstetricHistoryList: [],
  obstetricChildList: [],
  activeIndexBaby: 0,
  activeIndexObstetricHistory: 0,
  openModConfirmCreate: null,
};

export type Event =
  // GET
  | {
      message: "GetObstetricHistoryList";
      params: {};
    }
  | { message: "ObstreticGetPatientByHn"; params: { hn: string } }
  // Setter
  | {
      message: "HandleSetObstreticHistoryList";
      params: { key: string; value: any; label: any };
    }
  | { message: "HandleSetActiveIndexBaby"; params: { index: number } }
  | {
      message: "HandleSetactiveIndexObstetricHistory";
      params: { index: number };
    }
  | { message: "HandleSetOpenModConfirmCreate"; params: { value: any } }
  // PUSH
  | { message: "HandleAddBabyObstreticRecord"; params: {} }
  | { message: "HandleAddObstreticHistoryList"; params: {} }
  // ACTION
  | {
      message: "HandleClickButtonMDPOther";
      params: { type: "add" | "delete"; index?: number };
    }
  | {
      message: "HandleClickButtonMedicationDuringLabourOther";
      params: { type: "add" | "delete"; index?: number };
    }
  | {
      message: "HandleClickButtonMedicationPregnancyOther";
      params: { type: "add" | "delete"; index?: number };
    }
  | {
      message: "HandleClickButtonMedicationToBabyOther";
      params: { type: "add" | "delete"; index?: number };
    }
  | {
      message: "HandleClickButtonCordBloodOther";
      params: { type: "add" | "delete"; index?: number };
    }
  | {
      message: "HandleCreateObstreticRecordPDF";
      params: {};
    }
  // Save
  | {
      message: "SaveObstreticRecord";
      params: {
        data: any;
        type: "mom" | "child";
        measure_date: string;
        confirm_create?: boolean;
      };
    };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

/* ------------------------- API ------------------------ */
const PostProxyPatientCreateBindHN: Handler = async (controller, params) => {
  const { dob, first_name, hn, last_name } = params;
  const data: any = {
    birthdate: dob,
    first_name,
    hn,
    last_name,
  };

  if (params.confirm_create) {
    data.confirm_create = params.confirm_create;
  }

  const [res, error] = await ProxyPatientCreateBindHNView.create({
    apiToken: Cookies.get("apiToken"),
    data,
  });
  return [res, error];
};

const TrakCarePatient: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await TrakCarePatientInfo.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      hn: params.hn,
    },
  });

  controller.setState({ loadingStatus: false });
  return [res, error];
};

const StaffDirectAllergy: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await StaffDirectAllergyView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      patient: params.proxy_patient,
    },
  });

  controller.setState({ loadingStatus: false });
  return [res, error];
};

/* ------------------------- GET ------------------------ */
export const GetObstetricHistoryList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.OBSTRETIC_RECORD,
    data_type: DATA_TYPE.OBSTRETIC_RECORD,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  const { activeIndexObstetricHistory, activeIndexBaby } =
    controller.getState();
  const index = activeIndexObstetricHistory || 0;

  let items: any[] = response?.items || [];
  items = items.sort((a: any, b: any) =>
    (a.created_at || "").localeCompare(b.created_at)
  );

  if (items[index]?.data) {
    items[index].data.babies = [getTableNewBornInitial()];
  }

  // get ข้อมูลของลูก
  if (Array.isArray(items[index]?.data?.child)) {
    items[index].data.babies = await GetListChild(controller, {
      child: items[index]?.data?.child,
    });
  }

  controller.setState({
    obstetricHistoryList: items?.[0]
      ? items
      : [
          {
            data: {
              by_lmp_date: true,
              babies: [getTableNewBornInitial()],
            },
          },
        ],
    loadingStatus: false,
  });
};

export const GetListChild: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  return await Promise.all(
    (params.child || []).map(async (item: any) => {
      let filterData = {
        group: GROUP.OBSTRETIC_RECORD,
        data_type: DATA_TYPE.CHILD_OBSTRETIC_RECORD,
        start_measure: moment(item.measure_date).format(BACKEND_DATE_FORMAT),
        end_measure: moment(item.measure_date).format(BACKEND_DATE_FORMAT),
        proxy_patient: item.id,
      };

      const [error, response] = await commonGetPatientDataLatest(
        controller as any,
        filterData
      );

      return response || getTableNewBornInitial();
    })
  );
};

export const ObstreticGetPatientByHn: Handler = async (controller, params) => {
  const { obstetricHistoryList, activeIndexObstetricHistory, activeIndexBaby } =
    controller.getState();
  const index = activeIndexBaby || 0;
  const data = obstetricHistoryList?.[activeIndexObstetricHistory || 0]?.data;
  const babies: any[] = data?.babies || [];
  const proxy_patient: any[] = data?.babies?.[index]?.proxy_patient;

  const defaultDetail = {
    first_name: "",
    last_name: "",
    patient: "NEW_PATIENT",
    birthdate: "",
    hn: params.hn,
  };

  const [resPatient, errorPatient] = await ProxyPatientNoUserListView.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      hn: params.hn,
    },
  });

  if (!resPatient?.items?.[0]) {
    controller.setState({ errorMessage: "ไม่พบข้อมูล" });
    HandleSetChildDetail(controller, {
      detail: defaultDetail,
      index,
    });
    return;
  }

  const detail = resPatient.items[0];
  detail.dob = resPatient.items[0].birthdate;
  detail.hn = params.hn;

  // หาก id ลูกซ้ำ
  if (
    !!babies.find((item) => item.proxy_patient === detail.patient) &&
    proxy_patient !== detail.patient
  ) {
    controller.setState({
      errorMessage: "มีข้อมูลลูกคนนี้อยู่แล้ว",
    });
    HandleSetObstreticHistoryList(controller, {
      key: `babies[${index}].data.prepare_child_id`,
      value: "CHANGE",
    });
    return;
  }

  HandleSetChildDetail(controller, { detail, index });
};

const HandleSetChildDetail: Handler = (controller, params) => {
  const { detail, index } = params;

  [
    { key: `babies[${index}].data.labour.hn`, value: detail.hn },
    {
      key: `babies[${index}].data.labour.first_name`,
      value: detail.first_name || "",
    },
    {
      key: `babies[${index}].data.labour.last_name`,
      value: detail.last_name || "",
    },
    {
      key: `babies[${index}].data.labour.dob`,
      value: detail.dob || "",
    },
    {
      key: `babies[${index}].data.prepare_child_id`,
      value: detail.patient,
    },
    {
      key: `babies[${index}].data.newborn.sex`,
      value: detail.gender,
    },
  ].map((item) => {
    HandleSetObstreticHistoryList(controller, item);
  });
};

/* ----------------------- Setter ----------------------- */
export const HandleSetObstreticHistoryList: Handler = (controller, params) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();
  const { key, value, label } = params;

  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0]?.data || {}),
  };

  const setCheckbox = (
    array: string[],
    prefix?: string,
    val?: any,
    otherLabel?: boolean
  ) => {
    array.forEach((text) => {
      if (prefix) {
        text = `${prefix}.${text}`;
      }

      if (label && text === key) {
        let activeLabel = `${prefix}.${"active_label"}`;
        let otherLebel = `${prefix}.${"other_label"}`;

        if (otherLabel) {
          cloneData = setObject(
            cloneData,
            otherLebel,
            value ? label : undefined
          );
        } else {
          cloneData = setObject(
            cloneData,
            activeLabel,
            value ? label : undefined
          );
          cloneData = setObject(cloneData, otherLebel);
        }
      }

      cloneData = setObject(
        cloneData,
        text,
        val !== undefined ? val : text === key ? value : false
      );
    });
  };

  const setCheckboxOther = ({
    array,
    prefix,
    lastKey,
    descKey,
  }: {
    array?: string[];
    prefix?: string;
    lastKey?: string;
    descKey?: string;
  } = {}) => {
    if (lastKey !== descKey) {
      setCheckbox(array || [], `${prefix}`);
    }

    cloneData = setObject(
      cloneData,
      `${prefix}.${descKey}`,
      key === `${prefix}.${descKey}` ? value : ""
    );
  };

  const setEDDDate = (byUltrasound: boolean, date: string) => {
    const delivery = calculateDelivery(
      byUltrasound,
      moment(),
      moment(date),
      moment(date).add(279, "days")
    );
    console.log(delivery);
    cloneData = setObject(
      cloneData,
      "edc_date",
      delivery.edd_date?.isValid()
        ? delivery.edd_date?.format(BACKEND_DATE_FORMAT)
        : ""
    );
    cloneData = setObject(
      cloneData,
      "start_pregnancy_date",
      delivery.start_pregnancy_date?.isValid()
        ? delivery.start_pregnancy_date?.format(BACKEND_DATE_FORMAT)
        : ""
    );
    cloneData = setObject(
      cloneData,
      "ga_days",
      Number.isNaN(delivery.ga_days) ? null : delivery.ga_days
    );
  };

  if (cloneData) {
    if (key === "lmp_date") {
      setEDDDate(cloneData.by_ultrasound, value);
      cloneData = setObject(cloneData, key, value);
    } else if (["by_lmp_date", "by_ultrasound"].includes(key)) {
      setEDDDate(key === "by_ultrasound" && value, cloneData.lmp_date);
      setCheckbox(["by_lmp_date", "by_ultrasound"]);
    } else if (key.includes("membranes_ruptured")) {
      const [prefix, ...tail]: any = key.split(".");
      const array = ["spontaneous", "artificial"];

      if (array.includes(tail.at(-1))) {
        setCheckbox(array, `${prefix}.${tail.slice(0, -1).join(".")}`);
      } else {
        cloneData = setObject(cloneData, key, value);
      }
    } else if (key.includes("mdp")) {
      if (!key.includes("mdp.description")) {
        setCheckbox(["mdp.no", "mdp.yes"]);
        cloneData = setObject(cloneData, "mdp.description", [""]);
      } else {
        cloneData = setObject(cloneData, key, value);
      }
    } else if ("gravida" === key) {
      [
        "gravida",
        "full_term",
        "premature",
        "abortion",
        "living",
        "stillbirth",
      ].forEach((text) => {
        cloneData = setObject(cloneData, text, text === key ? value : "");
      });
    }
    // labour
    else if (
      ["amniotic_fluid_appearance", "status"].includes(
        key.split(".")?.[3] || ""
      )
    ) {
      const [prefix, ...tail]: any = key.split(".");

      const convert = {
        amniotic_fluid_appearance: ["clear", "thin_meconium", "think_meconium"],
        status: ["alive", "stillbirth", "fresh", "marcerated"],
      }[tail.at(-2) as "amniotic_fluid_appearance" | "status"];

      if (convert.includes(tail.at(-1))) {
        setCheckbox(convert, `${prefix}.${tail.slice(0, -1).join(".")}`);
      }
    } else if (key.includes("delivery_method")) {
      const [prefix, ...tail] = key.split(".");
      const array = ["normal_labour", "forcep_extraction"];
      const firstString = tail.slice(0, -1).join(".");
      const lastString = tail.at(-1);

      if (array.includes(lastString)) {
        setCheckbox(array, `${prefix}.${firstString}`, null);
      }

      const clearValue = () => {
        cloneData = setObject(
          cloneData,
          `${prefix}.${firstString}.${lastString}_description`,
          value
        );
      };

      if (lastString === "normal_labour") {
        cloneData = setObject(
          cloneData,
          `${prefix}.${firstString}.${"forcep_extraction"}_description`,
          ""
        );
      } else if (
        [...array, "vacuum_extraction", "cesarean_section_due_to"].includes(
          lastString
        ) &&
        !value
      ) {
        clearValue();
      }

      if (label) {
        cloneData = setObject(
          cloneData,
          `${prefix}.${firstString}.${"active_label"}`,
          label
        );
      }

      cloneData = setObject(cloneData, key, value);
    } else if (
      [
        "not_in_labour",
        "spontaneous",
        "induced_method",
        "induced_method_description",
      ].includes(key.split(".")?.[3] || "")
    ) {
      const [prefix, ...tail] = key.split(".");

      setCheckboxOther({
        array: [
          "not_in_labour",
          "spontaneous",
          "induced_method",
          "induced_method_description",
        ],
        prefix: `${prefix}.${tail.slice(0, -1).join(".")}`,
        descKey: "induced_method_description",
        lastKey: tail.at(-1),
      });
    }
    // vaginal_delivery
    else if (key.includes("placenta_membranes")) {
      const [prefix, ...tail] = key.split(".");
      const array = ["spontaneous_delivery", "manual_removal"];

      if (array.includes(tail.at(-1))) {
        setCheckbox(array, `${prefix}.${tail.slice(0, -1).join(".")}`);
      } else {
        cloneData = setObject(cloneData, key, value);
      }
    } else if (key.includes("presentation")) {
      const [prefix, ...tail] = key.split(".");

      setCheckboxOther({
        array: ["cephalic", "breech", "other"],
        prefix: `${prefix}.${tail.slice(0, -1).join(".")}`,
        descKey: "other_description",
        lastKey: tail.at(-1),
      });
    } else if (
      ["cord_insertion", "cord_vessels", "post_partum_condition"].includes(
        key.split(".")?.[3] || ""
      )
    ) {
      const [prefix, ...tail] = key.split(".");
      if (tail.at(-1) === "estimated_blood_loss") {
        cloneData = setObject(cloneData, key, value);
      } else {
        setCheckboxOther({
          array: ["normal", "abnormal"],
          prefix: `${prefix}.${tail.slice(0, -1).join(".")}`,
          descKey: "abnormal_description",
          lastKey: tail.at(-1),
        });
      }
    } else if (
      ["episiotomy", "laceration"].includes(key.split(".")?.[3] || "")
    ) {
      const [prefix, ...tail] = key.split(".");
      const firstString = tail.slice(0, -1).join(".");

      const array = ["no", "yes"];
      const radio = firstString.includes("episiotomy")
        ? ["right_mediolateral", "left_mediolateral", "median"]
        : ["first", "second", "third", "fourth_degree_tear"];

      if (array.includes(tail.at(-1))) {
        setCheckbox([...array, ...radio], `${prefix}.${firstString}`);
      } else {
        setCheckbox(radio, `${prefix}.${firstString}`, undefined, true);
      }
    } else if (
      ["gross_appearance", "oxytocin_drug"].includes(key.split(".")?.[3] || "")
    ) {
      const [prefix, ...tail]: any[] = key.split(".");
      const array = ["normal", "abnormal"];
      const lastString = tail.at(-1);
      const firstString = tail.slice(0, -1).join(".");

      const convert = {
        gross_appearance: {
          radio: ["complete", "incomplete"],
          other: "abnormal_description",
        },
        oxytocin_drug: {
          radio: ["oxytocin", "methergin", "duratocin", "other"],
          other: "other_description",
        },
      }[tail.at(-2) as "gross_appearance" | "oxytocin_drug"];

      if (array.includes(lastString)) {
        setCheckboxOther({
          array:
            tail.at(-2) === "oxytocin_drug"
              ? [...array, ...convert.radio]
              : array,
          prefix: `${prefix}.${firstString}`,
          lastKey: "",
          descKey: convert.other,
        });
      } else if (lastString === convert.other) {
        cloneData = setObject(cloneData, key, value);
      } else {
        setCheckbox(convert.radio, `${prefix}.${firstString}`, undefined, true);
      }
    }
    // newborn
    else if (
      ["medication_to_baby", "cord_blood"].includes(key.split(".")?.[3] || "")
    ) {
      const [prefix, ...tail]: any = key.split(".");
      const firstString = tail.slice(0, -1).join(".");
      const lastString = tail.at(-1);

      const convert = {
        medication_to_baby: ["terramycin_eye_ointment", "vit_k1", "other"],
        cord_blood: ["blood_group", "other"],
      }[tail.at(-2) as "medication_to_baby" | "cord_blood"];

      if (!tail.at(-1).includes("other_description")) {
        if (lastString === "other" && !value) {
          cloneData = setObject(
            cloneData,
            `babies[${activeIndexBaby || 0}].${firstString}.other_description`,
            [""]
          );
        } else if (lastString !== "other") {
          let data =
            getObject(cloneData, `${prefix}.${firstString}.active_label`) || [];

          if (!Array.isArray(data)) {
            data = [];
          }

          if (!value) {
            data = data.filter((item: string) => item !== label);
          } else {
            data = [...data, label];
          }

          cloneData = setObject(
            cloneData,
            `${prefix}.${firstString}.active_label`,
            data
          );
        }

        cloneData = setObject(cloneData, key, value);
      } else {
        cloneData = setObject(cloneData, key, value);
      }
    } else if (
      [
        "vdrl",
        "hbsag",
        "hbsab",
        "rubella_igg",
        "group_b_streptococcus",
      ].includes(key.split(".")?.[1] || "")
    ) {
      const prefix = key.split(".")[1] as
        | "vdrl"
        | "hbsag"
        | "hbsab"
        | "rubella_igg"
        | "group_b_streptococcus";
      const lastKey = key.split(".")[2];

      const convert = {
        vdrl: ["non_reactive", "reactive"],
        hbsag: ["negative", "positive"],
        hbsab: ["negative", "positive"],
        rubella_igg: ["non_reactive", "reactive"],
        group_b_streptococcus: ["no_growth", "growth"],
      }[prefix];

      setCheckboxOther({
        array: [...convert, "other"],
        prefix: `lab_result.${prefix}`,
        descKey: "other_description",
        lastKey,
      });
    } else {
      cloneData = setObject(cloneData, key, value);
    }
  }
  if (cloneList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }

  controller.setState({ obstetricHistoryList: cloneList });
};

export const HandleSetOpenModConfirmCreate: Handler = (controller, params) => {
  controller.setState({ openModConfirmCreate: params.value });
};

// PUSH
export const HandleAddBabyObstreticRecord: Handler = (controller, params) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();

  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };

  let active = activeIndexBaby;

  if (cloneData) {
    if (!("babies" in cloneData)) {
      cloneData.babies = [];
      active = 0;
    }
    cloneData.babies.push(getTableNewBornInitial());
  }

  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }

  controller.setState({
    obstetricHistoryList: cloneList,
    activeIndexBaby: active,
  });
};

export const HandleAddObstreticHistoryList: Handler = (controller, params) => {
  const { obstetricHistoryList, activeIndexObstetricHistory } =
    controller.getState();

  const cloneList = [...(obstetricHistoryList || [])];

  let active = (activeIndexObstetricHistory || 0) + 1;

  if (cloneList) {
    cloneList.push({
      data: {
        by_lmp_date: true,
        babies: [getTableNewBornInitial()],
      },
    });
  }

  controller.setState({
    obstetricHistoryList: cloneList,
    activeIndexObstetricHistory: active,
    activeIndexBaby: 0,
  });
};

export const HandleSetActiveIndexBaby: Handler = (controller, params) => {
  controller.setState({ activeIndexBaby: params.index });
};

export const HandleSetactiveIndexObstetricHistory: Handler = async (
  controller,
  params
) => {
  const { index } = params;
  const { obstetricHistoryList } = controller.getState();
  const cloneArray = [...(obstetricHistoryList || [])];

  if (cloneArray[index]?.data) {
    cloneArray[index].data.babies = [getTableNewBornInitial()];
  }

  if (Array.isArray(cloneArray?.[index]?.data?.child)) {
    cloneArray[index].data.babies = await GetListChild(controller, {
      child: cloneArray?.[index]?.data?.child,
    });
  }
  controller.setState({
    activeIndexObstetricHistory: params.index,
    activeIndexBaby: 0,
  });
};

// ACTION
export const HandleClickButtonMDPOther: Handler = (controller, params) => {
  const { obstetricHistoryList, activeIndexObstetricHistory } =
    controller.getState();
  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };

  switch (params.type) {
    case "add":
      if (!Array.isArray(cloneData?.mdp?.description) && cloneData) {
        cloneData.mdp.description = [];
      }
      cloneData.mdp.description.push("");
      break;
    case "delete":
      cloneData.mdp.description = cloneData.mdp.description.filter(
        (_: any, index: number) => index !== params.index
      );
      break;

    default:
      break;
  }

  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }
  controller.setState({ obstetricHistoryList: cloneList });
};

export const HandleClickButtonMedicationDuringLabourOther: Handler = (
  controller,
  params
) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();
  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };
  const index = activeIndexBaby || 0;

  switch (params.type) {
    case "add":
      if (
        !Array.isArray(
          cloneData.babies[index]?.data?.vaginal_delivery
            ?.medication_during_labour
        ) &&
        cloneData
      ) {
        cloneData = setObject(
          cloneData,
          `babies[${index}].data.vaginal_delivery.medication_during_labour`,
          [""]
        );
      }

      cloneData.babies[
        index
      ].data.vaginal_delivery.medication_during_labour.push("");
      break;
    case "delete":
      cloneData.babies[index].data.vaginal_delivery.medication_during_labour =
        cloneData.babies[
          index
        ].data.vaginal_delivery.medication_during_labour.filter(
          (_: any, index: number) => index !== params.index
        );
      break;

    default:
      break;
  }

  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }
  controller.setState({ obstetricHistoryList: cloneList });
};

export const HandleClickButtonMedicationPregnancyOther: Handler = (
  controller,
  params
) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();
  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };

  switch (params.type) {
    case "add":
      if (!Array.isArray(cloneData?.mdp?.description) && cloneData) {
        cloneData = setObject(cloneData, `mdp.description`, [""]);
      }
      cloneData.mdp.description.push("");
      break;
    case "delete":
      cloneData.mdp.description = cloneData.mdp.description.filter(
        (_: any, index: number) => index !== params.index
      );
      break;

    default:
      break;
  }

  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }
  controller.setState({ obstetricHistoryList: cloneList });
};

export const HandleClickButtonMedicationToBabyOther: Handler = (
  controller,
  params
) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();
  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };
  const index = activeIndexBaby || 0;

  switch (params.type) {
    case "add":
      if (
        !Array.isArray(
          cloneData.babies[index]?.data?.newborn?.medication_to_baby
            ?.other_description
        ) &&
        cloneData
      ) {
        cloneData = setObject(
          cloneData,
          `babies[${index}].data.newborn.medication_to_baby.other_description`,
          [""]
        );
      }
      cloneData.babies[
        index
      ].data.newborn.medication_to_baby.other_description.push("");
      break;
    case "delete":
      cloneData.babies[
        index
      ].data.newborn.medication_to_baby.other_description = cloneData.babies[
        index
      ].data.newborn.medication_to_baby.other_description.filter(
        (_: any, index: number) => index !== params.index
      );
      break;

    default:
      break;
  }
  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }

  controller.setState({ obstetricHistoryList: cloneList });
};

export const HandleClickButtonCordBloodOther: Handler = (
  controller,
  params
) => {
  const { obstetricHistoryList, activeIndexBaby, activeIndexObstetricHistory } =
    controller.getState();
  const cloneList = [...(obstetricHistoryList || [])];
  let cloneData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0].data || {}),
  };
  const index = activeIndexBaby || 0;

  switch (params.type) {
    case "add":
      if (
        !Array.isArray(
          cloneData.babies[index].data.newborn.cord_blood?.other_description
        ) &&
        cloneData
      ) {
        cloneData = setObject(
          cloneData,
          `babies[${index}].data.newborn.cord_blood.other_description`,
          [""]
        );
      }
      cloneData.babies[index].data.newborn.cord_blood.other_description.push(
        ""
      );
      break;
    case "delete":
      cloneData.babies[index].data.newborn.cord_blood.other_description =
        cloneData.babies[
          index
        ].data.newborn.cord_blood.other_description.filter(
          (_: any, index: number) => index !== params.index
        );
      break;

    default:
      break;
  }
  if (obstetricHistoryList) {
    cloneList[activeIndexObstetricHistory || 0].data = cloneData;
  }

  controller.setState({ obstetricHistoryList: cloneList });
};

/* ------------------------ Save ------------------------ */

const prepareDataSaveChild: Handler = async (controller, params) => {
  const { selectedPatient } = controller.getState();
  const { childData, obstretricData, tmpParams } = params;
  const babyList = [...(obstretricData.data.babies || [])];

  if (childData.prepare_child_id === "NEW_PATIENT") {
    const labour = { ...childData.labour };

    if (params.confirm_create) {
      labour.confirm_create = params.confirm_create;
    }

    let [res, error] = await PostProxyPatientCreateBindHN(controller, labour);

    if (error) {
      if (error?.confirm_create) {
        controller.setState({ openModConfirmCreate: error });
      } else {
        controller.setState({ errorMessage: error });
      }
      controller.setState({ loadingStatus: false });
      return;
    }

    childData.prepare_child_id = res.id;
  }

  tmpParams.data_type = DATA_TYPE.CHILD_OBSTRETIC_RECORD;
  tmpParams.proxy_patient = childData.prepare_child_id;

  let child: any[] = obstretricData.data.child || [];

  child = child.filter((item: any) =>
    babyList.find((acc: any) => acc.proxy_patient === item.id)
  );

  if (!child?.[0]) {
    obstretricData.data.child = [
      {
        id: childData.prepare_child_id,
        sequence: 1,
        measure_date: tmpParams.measure_date,
      },
    ];
  } else {
    obstretricData.data.child.push({
      id: childData.prepare_child_id,
      sequence: obstretricData.data.child.length + 1,
      measure_date: tmpParams.measure_date,
    });
  }

  tmpParams.data.mom_id = selectedPatient.proxy_patient;
  tmpParams.proxy_patient = childData.prepare_child_id;
  delete obstretricData.data.babies;
  delete tmpParams.data.prepare_child_id;

  return { tmpParams, obstretricData, childData };
};

const HandlePrepareUpdateObstetric: Handler = async (controller, params) => {
  const { obstretricData, data } = params;
  if (data.data.prepare_child_id === "NEW_PATIENT") {
    const [res, error] = await PostProxyPatientCreateBindHN(
      controller,
      data.data.labour
    );

    if (error) {
      controller.setState({ errorMessage: error, loadingStatus: false });
      return;
    }

    data.data.prepare_child_id = res.id;
  }

  obstretricData.data.child = obstretricData.data.child.map((item: any) =>
    item.id === params.data.proxy_patient
      ? {
          ...item,
          id: data.data.prepare_child_id,
          measure_date: data.measure_date,
        }
      : item
  );
  // Update Child
  HandleUpdateObstreticRecord(controller, params);
  // Update Mom
  HandleUpdateObstreticRecord(controller, {
    data: obstretricData,
    hideMessage: true,
  });
};

export const SaveObstreticRecord: Handler = async (
  controller,
  params: Extract<Event, { message: "SaveObstreticRecord" }>["params"]
) => {
  controller.setState({ loadingStatus: true });
  delete params.data.data.babies;

  const { obstetricHistoryList, activeIndexObstetricHistory } =
    controller.getState();

  const cloneList = [...(obstetricHistoryList || [])];
  let obstretricData = {
    ...(cloneList?.[activeIndexObstetricHistory || 0] || {}),
  };

  console.log("params", params)
  if (params.data.id) {
    const data = params.data;
    if (data.data.prepare_child_id) {
      console.log(" obstretricData", obstretricData)
      HandlePrepareUpdateObstetric(controller, { obstretricData, data });
    } else {
      HandleUpdateObstreticRecord(controller, params);
    }
    return;
  }

  let tmpParams: any = {
    group: GROUP.OBSTRETIC_RECORD,
    data_type: "",
    measure_date: params.measure_date,
    data: { ...params.data.data },
  };

  if (params.type === "mom") {
    tmpParams.data_type = DATA_TYPE.OBSTRETIC_RECORD;
  } else if (params.type === "child") {
    const prepareData = await prepareDataSaveChild(controller, {
      childData: params.data.data,
      obstretricData,
      tmpParams,
      confirm_create: params.confirm_create,
    });

    if (!prepareData) {
      return;
    } else {
      obstretricData = prepareData.obstretricData;
      tmpParams = prepareData.tmpParams;
      params.data.data = prepareData.childData;
    }
  }

  const [error, response] = await commonCreatePatientData(
    controller as any,
    tmpParams
  );

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add Obstretic record success.";
    if (params.type === "child") {
      HandleUpdateObstreticRecord(controller, {
        data: obstretricData,
        hideMessage: true,
        type: "child",
      });
    } else {
      GetObstetricHistoryList(controller);
    }
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const HandleUpdateObstreticRecord: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  if (params.data.data.prepare_child_id) {
    params.data.proxy_patient = params.data.data.prepare_child_id;
    delete params.data.data.prepare_child_id;
  }

  console.log("HandleUpdateObstreticRecord params ", params)
  if (
    params.type === "child" &&
    params.data.proxy_patient &&
    params.data.data?.newborn?.sex
  ) {
    const [res, error] = await HandleUpdatePatientDetail(controller, {
      id: params.data.proxy_patient,
      gender: params.data.data?.newborn?.sex,
    });
    if (error) {
      controller.setState({ loadingStatus: false, errorMessage: error });
      return;
    }
  }

  const [error, response] = await commonUpdatePatientData(
    controller as any,
    params.data
  );

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    if (!params.hideMessage) {
      successMessage = "Update Obstretic record success.";
    }
    GetObstetricHistoryList(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const HandleUpdatePatientDetail: Handler = async (
  controller,
  params
) => {
  const [res, error] = await ProxyPatientDetail.patch({
    apiToken: Cookies.get("apiToken"),
    pk: params.id,
    data: {
      gender: params.gender,
    },
  });

  return [res, error];
};

export const HandleCreateObstreticRecordPDF: Handler = async (
  controller,
  params
) => {
  const { selectedPatient, obstetricHistoryList, obstetricChildList } =
    controller.getState();

  const dataOH = obstetricHistoryList?.[0]?.data;
  console.log(obstetricChildList);
  console.log(obstetricHistoryList);

  //split_age
  const years = selectedPatient.full_age.split("ปี")[0];
  const month = selectedPatient.full_age.split("ปี")[1].split("เดือน")[0];
  const days = selectedPatient.full_age
    .split("ปี")[1]
    .split("เดือน")[1]
    .split("วัน")[0];

  //cheack count babies and pushdata
  var babies = [];
  for (var i = 0; i < dataOH?.babies?.length; i++) {
    babies.push({
      data: dataOH?.babies?.[i]?.data,
    });
  }
  console.log("babies",babies)
  console.log("dataOH",dataOH)
  var [resTCP, errorTCP] = await TrakCarePatient(controller, selectedPatient);
  var [resSDA, errorSDA] = await StaffDirectAllergy(
    controller,
    selectedPatient
  );
  const dataSDA = resSDA?.items?.[0]?.note_dict;

  const generateData = {
    //header
    hn: selectedPatient.hn,
    name: selectedPatient.fullname,
    sex: selectedPatient.gender_name,
    birthdate: selectedPatient.birthdate
      ? moment(selectedPatient.birthdate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
          "DD/MM/YYYY"
        )
      : "",
    age_years: years,
    age_month: month,
    age_days: days,
    nationality: resTCP?.nationality,
    bloodType: resTCP?.blood_type,
    allergies: dataSDA === undefined ? "" : dataSDA?.allergy,
    sideEffect: dataSDA === undefined ? "" : dataSDA?.side_effect,
    image: selectedPatient.image === null ? "" : selectedPatient.image,
    //obstetricHistoryList
    lmp: dataOH.lmp_date
      ? moment(dataOH.lmp_date, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
          "DD/MM/YYYY"
        )
      : "",
    edc: dataOH.edc_date
      ? moment(dataOH.edc_date, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
          "DD/MM/YYYY"
        )
      : "",
    by_lmp_date: dataOH.by_lmp_date,
    by_ultrasound: dataOH.by_ultrasound,
    gestational_age: dataOH.ga_weeks,
    past_delivery_last: dataOH.past_delivery_last,
    gravida: dataOH.gravida,
    full_term: dataOH.full_term,
    abortion: dataOH.abortion,
    pdp_yes_specify: dataOH.pdp?.yes_specify,
    pdp_yes_specify_description: dataOH.pdp?.yes_specify_description,
    mdp_no: dataOH.mdp?.no,
    mdp_yes: dataOH.mdp?.yes,
    mdp_description: dataOH.mdp?.description,
    lab_result_blood_group: dataOH.lab_result?.blood_group,
    lab_result_rh: dataOH.lab_result?.rh,
    lab_result_hct: dataOH.lab_result?.hct,
    lab_result_mcv: dataOH.lab_result?.mcv,
    lab_result_vdrl_non_reactive: dataOH.lab_result?.vdrl.non_reactive,
    lab_result_vdrl_reactive: dataOH.lab_result?.vdrl.reactive,
    lab_result_vdrl_other: dataOH.lab_result?.vdrl.other,
    lab_result_vdrl_other_description:
      dataOH.lab_result?.vdrl.other_description,
    lab_result_hbsag_negative: dataOH.lab_result?.hbsag.negative,
    lab_result_hbsag_positive: dataOH.lab_result?.hbsag.positive,
    lab_result_hbsag_other: dataOH.lab_result?.hbsag.other,
    lab_result_hbsag_other_description:
      dataOH.lab_result?.hbsag.other_description,
    lab_result_hbsab_negative: dataOH.lab_result?.hbsab.negative,
    lab_result_hbsab_positive: dataOH.lab_result?.hbsab.positive,
    lab_result_hbsab_other: dataOH.lab_result?.hbsab.other,
    lab_result_hbsab_other_description:
      dataOH.lab_result?.hbsab.other_description,
    lab_result_rubella_igg_non_reactive:
      dataOH.lab_result?.rubella_igg.non_reactive,
    lab_result_rubella_igg_reactive: dataOH.lab_result?.rubella_igg.reactive,
    lab_result_rubella_igg_other: dataOH.lab_result?.rubella_igg.other,
    lab_result_rubella_igg_other_description:
      dataOH.lab_result?.rubella_igg.other_description,
    lab_result_group_b_streptococcus_no_growth:
      dataOH.lab_result?.group_b_streptococcus.no_growth,
    lab_result_group_b_streptococcus_growth:
      dataOH.lab_result?.group_b_streptococcus.growth,
    lab_result_group_b_streptococcus_other:
      dataOH.lab_result?.group_b_streptococcus.other,
    lab_result_group_b_streptococcus_other_description:
      dataOH.lab_result?.group_b_streptococcus.other_description,
    lab_result_hemoglobin_typing: dataOH.lab_result?.hemoglobin_typing,
    obstetric_history_nurse_name: dataOH.obstetric_history?.nurse_name,
    obstetric_history_staff_id: dataOH.obstetric_history?.staff_id,
    obstetric_history_date: dataOH.obstetric_history?.date,
    obstetric_history_time: dataOH.obstetric_history?.time,

    //LaborDetail
    babies: babies,
  };
  console.log("PDF print");
  console.log("generateData", generateData);
  await deriveryNewbordRecordForm(generateData);
};

const getTableNewBornInitial = () => {
  return {
    data: {
      newborn: {
        apgar_score: apgarScore,
      },
    },
  };
};

// Utils
const getObject = (obj: any, path: string) => {
  return objectManage(obj, path, "", true);
};

// path => "a.b.c", "a.b[0].c"
const setObject = (obj: any, path: string, value?: any) => {
  return objectManage(obj, path, value);
};

const objectManage = (
  obj: any,
  path: string,
  value?: any,
  isReadonly?: boolean
) => {
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot

  const paths = path.split(".");
  const keys = path.split(".");
  const lastKey = keys.pop();

  const lastObj = keys.reduce(
    (obj, key, index) =>
      (obj[key] = obj[key] || (Number.isInteger(+paths[index + 1]) ? [] : {})),
    obj
  );

  if (isReadonly) {
    return lastObj[lastKey || ""];
  }

  lastObj[lastKey || ""] = value;

  if (typeof value === "undefined") {
    delete lastObj[lastKey || ""];
  }

  return obj;
};
