import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import moment from "moment";
import {
  Grid,
  Image,
  Button,
  Icon,
  Modal,
  Header,
  Label,
  Segment,
} from "semantic-ui-react";
import { Card, CardContent } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Webcam from "react-webcam";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  FOOT_TYPE,
  FootTypeKey,
  FOOT_STATUS,
  convertToADDate,
  formatDateByLanguage,
} from "../MobClinicInterface";
import { DMDatePicker } from "./DMEmotionRestChart";

import { useIntl } from "react-intl";

// Styles
import "./DMFoot.scss";

const DATE_FORMAT = "DD/MM/YYYY";

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  lightBlueBG: {
    background: "#B4ECF9",
  } as CSSProperties,
  selectedFeeling: {
    border: "2px solid #1C99F3",
    padding: "0px",
    marginBottom: "2px",
  } as CSSProperties,
  allFeeling: {
    padding: "0px",
    marginTop: "3px",
    marginBottom: "3px",
  } as CSSProperties,
  imageContain: {
    height: "70px",
    width: "85px",
    objectFit: "contain",
    margin: "auto",
    backgroundColor: "white",
  } as CSSProperties,
  imageWord: {
    color: "#3d9ad7",
    textAlign: "center",
    display: "flex",
  } as CSSProperties,
  labelMenu: {
    fontSize: "11px",
    color: "#4F4F4F",
    textAlign: "center",
  } as CSSProperties,
  paddingGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as CSSProperties,
  columnsItem: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  spanCenterBlue: {
    textAlign: "center",
    color: "#0072BC",
  } as CSSProperties,
  divPicture: {
    display: "flex",
    justifyContent: "space-around",
    padding: "2px 0px",
  } as CSSProperties,
};

// ---------- DMFootMain
type DMFootMainProps = {
  onEvent: any;
  selectedPatient: any;
  footFilterDate?: moment.Moment;
  footDetail: any;
  openFootConfirm?: boolean;
};

const DMFootMainInitial: DMFootMainProps = {
  onEvent: () => null,
  selectedPatient: {},
  footFilterDate: moment(),
  footDetail: {},
  openFootConfirm: false,
};

const DMFootMain: React.FC<DMFootMainProps> = (props) => {
  const intl = useIntl();
  const leftFootRef = useRef<any>();
  const rightFootRef = useRef<any>();

  const [lastData, setLastData] = useState<any>(null);

  useEffect(() => {
    props.onEvent({
      message: "handleGetFootDetailByDate",
      params: { openConfirm: false },
    });
  }, [props.footFilterDate]);

  useEffect(() => {
    if (
      props.footDetail?.items?.length > 0 &&
      props.footDetail.items[0].data?.length > 0
    ) {
      setLastData(
        props.footDetail?.items[0]?.data[
          props.footDetail.items[0].data.length - 1
        ]
      );
    } else {
      setLastData(null);
    }
  }, [props.footDetail?.items]);

  // change
  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: moment.Moment | undefined = props.footFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate?.add(-1, "days");
    } else {
      targetDate = targetDate?.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeFootFilterDate",
      params: { date: targetDate },
    });
  };

  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleChangeFootFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  // save
  const handleSaveFootDetail = () => {
    const result: any = {
      time: `${props.footFilterDate?.format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      leftFoot: leftFootRef.current.getData(),
      rightFoot: rightFootRef.current.getData(),
    };

    props.onEvent({ message: "handleSaveFootDetail", params: result });
  };

  return (
    <div style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
      <DMDatePicker
        date={props.footFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
        style={{ marginTop: "10px" }}
      />

      <div style={{ padding: "10px ​7px 25px" }}>
        <span style={{ fontWeight: "bold" }}>
          <FormattedMessage id="bplusClinicKey625" />
        </span>
        <span>{" "}{intl.formatMessage({id: "bplusClinicKey12"})}{" "}</span>
      </div>

      <Grid columns="equal" style={{ padding: "10px" }}>
        <Grid.Column style={{ backgroundColor: "rgba(223, 245, 252, 0.8)" }}>
          <FootItem
            ref={leftFootRef}
            footDetail={lastData?.leftFoot}
            positionFoot="leftFoot"
          />
        </Grid.Column>
        <Grid.Column style={{ backgroundColor: "rgba(242, 201, 76, 0.2)" }}>
          <FootItem
            ref={rightFootRef}
            footDetail={lastData?.rightFoot}
            positionFoot="rightFoot"
          />
        </Grid.Column>
      </Grid>

      <div
        style={{ textAlign: "center", marginTop: "18px", marginBottom: "80px" }}
      >
        <Button
          style={{ backgroundColor: "#2399E5", color: "white" }}
          onClick={handleSaveFootDetail}
        >
          {intl.formatMessage({id: "common.save"})}
        </Button>
      </div>

      <Modal open={props.openFootConfirm}>
        <Header
          content={formatDateByLanguage(moment(
            lastData?.time,
            `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
          ).format("DD/MM/YYYY"),"DD MMMM YYYY")}
          style={{
            textAlign: "center",
            backgroundColor: "#2399E5",
            color: "white",
          }}
        />
        <Modal.Content>
          <div style={{ ...styles.columnsItem, alignItems: "center" }}>
            {lastData?.status === FOOT_STATUS.HIGH && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon name="user md" color={lastData?.color} size="big" />
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: lastData?.color,
                  }}
                >
                  <FormattedMessage id="bplusClinicKey381" />
                </span>
              </div>
            )}
            {[FOOT_STATUS.MEDIUM, FOOT_STATUS.LOW].includes(
              lastData?.status
            ) && (
              <div style={{ ...styles.columnsItem, alignItems: "center" }}>
                <div style={{ fontSize: "20px", padding: "5px" }}>
                  <FormattedMessage id="bplusClinicKey550" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    name={
                      lastData?.status === FOOT_STATUS.LOW
                        ? "thumbs up"
                        : "thumbs down"
                    }
                    color={lastData?.color}
                    size="big"
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color:
                        lastData?.color === "yellow"
                          ? "#fbbd08"
                          : lastData?.color,
                    }}
                  >
                    {lastData?.status === FOOT_STATUS.MEDIUM && "Dry"}
                    {lastData?.status === FOOT_STATUS.LOW && "Normal"}
                  </span>
                </div>
              </div>
            )}
            {lastData?.status === "" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: lastData?.color,
                    textAlign: "center",
                    padding: "0px 10px",
                  }}
                >
                  {intl.formatMessage({id: "bplusClinicKey483"})}
                </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                padding: "5px 0px",
                alignItems: "center",
              }}
            >
              <Icon name="info circle" color="blue" size="large" />
              <span
                style={{
                  color: "#0072BC",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <FormattedMessage id="bplusClinicKey488" />
              </span>
            </div>
            <Grid style={{ width: "100%", paddingBottom: "20px" }}>
              <Grid.Column width={4}>
                <Image
                  src="/images/foot_wear_clean.png"
                  style={styles.imageContain}
                />
                <span style={styles.imageWord}><FormattedMessage id="bplusClinicKey744" /></span>
                {/* {"Wear clean & dry sock"} */}
              </Grid.Column>
              <Grid.Column width={4}>
                <Image
                  src="/images/foot_do_not_walk.png"
                  style={styles.imageContain}
                />
                <span style={styles.imageWord}><FormattedMessage id="bplusClinicKey745" /></span>
                {/* {"Don't work bare foot"} */}
              </Grid.Column>
              <Grid.Column width={4}>
                <Image
                  src="/images/foot_comfortable.png"
                  style={styles.imageContain}
                />
                <span style={styles.imageWord}><FormattedMessage id="bplusClinicKey746" /></span>
                {/* {"Comfortable shoes"} */}
              </Grid.Column>
              <Grid.Column width={4}>
                <Image
                  src="/images/foot_moisturizer.png"
                  style={styles.imageContain}
                />
                <span style={styles.imageWord}><FormattedMessage id="bplusClinicKey747" /></span>
                {/* {"Mosturizer application"} */}
              </Grid.Column>
              {/* {Object.keys(FOOT_TYPE).map((key: string) => {
                if (
                  lastData?.leftFoot[FOOT_TYPE[key]?.key] ||
                  lastData?.rightFoot[FOOT_TYPE[key]?.key]
                ) {
                  return (
                    <Grid.Column width={4} style={{ padding: "5px" }}>
                      <Image
                        src={`/images/foot/${FOOT_TYPE[key]?.key}.png`}
                        style={styles.imageContain}
                      />
                      <span style={styles.spanCenterBlue}>
                        {`${FOOT_TYPE[key]?.label1} ${FOOT_TYPE[key]?.label2}`}
                      </span>
                    </Grid.Column>
                  );
                } else {
                  return "";
                }
              })} */}
            </Grid>
            <Button
              content="close"
              color="blue"
              onClick={(event: any) => {
                props.onEvent({
                  message: "handleCloseFootModal",
                });
              }}
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

// ---------- FootItem
type FootItemProps = {
  positionFoot?: "leftFoot" | "rightFoot" | "";
  footDetail?: any;
};

const FootItem = forwardRef<any, FootItemProps>((props, ref) => {
  const webcamRef = useRef<any>(null);
  const inputRef = useRef<any>();
  const intl = useIntl();

  const [data, setData] = useState<any>({});
  const [previewImage, setPreviewImage] = useState<any>();
  const [openModPreview, setOpenModPreview] = useState<boolean>(false);
  const [openModWebcam, setOpenModWebcam] = useState<boolean>(false);
  const [previewSource, setPreviewSource] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<string | Blob>("");

  useImperativeHandle(ref, () => ({
    getData: () => {
      return data;
    },
  }));

  useEffect(() => {
    if (props.footDetail === undefined) {
      setData({
        normal_foot: false,
        paralyze_foot: false,
        dry_skin_foot: false,
        wound_foot: false,
        dark_skin_foot: false,
        pale_skin_foot: false,
        red_skin_foot: false,
        cut_toenail_foot: false,
        mycosis_foot: false,
        infected_foot: false,
        abnormal_foot: false,
        foot_picture: null,
        image: {
          id: null,
          url: null,
        },
      });
      setPreviewImage(null);
    } else {
      setData({ ...props.footDetail });
      if (props.footDetail.image?.url) {
        setPreviewImage(props.footDetail.image.url);
      } else {
        setPreviewImage(null);
      }
    }
  }, [props.footDetail]);

  const handleSelectedFoot = (name: string, value: boolean) => {
    setData({ ...data, [name]: value });
  };

  // const previewFile = (file: Blob) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onloadend = () => {
  //     setPreviewImage(reader.result);
  //     setData({ ...data, image: { ...data["image"], data: reader.result } });
  //   };
  // };

  // const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e?.target?.files?.[0];
  //   if (file) {
  //     previewFile(file);
  //   }
  // };

  const handleImageCancel = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    if (data?.image?.id && data?.image?.data) {
      setData({
        ...data,
        image: { id: data["image"].id, url: data["image"].url },
      });
    } else {
      setData({ ...data, image: { id: data["image"].id, url: null } });
    }
    if (data?.image?.url) {
      setPreviewImage(data.image.url);
    } else {
      setPreviewImage("");
    }
  };

  const handleCapture = useCallback(() => {
    console.log("----- webcamRef", webcamRef);
    console.log("----- webcamRef.current", webcamRef?.current);
    console.log(
      "----- webcamRef.current.getScreenshot()",
      webcamRef?.current?.getScreenshot()
    );
    const imageSrc = webcamRef?.current?.getScreenshot({}); // Base 64
    
    if (imageSrc) {
      setPreviewImage(imageSrc);
      console.log('handleCapture imageSrc: ', imageSrc);
      console.log('handleCapture data: ', data);
      let prepareData = { ...data, image: { ...data["image"], data: imageSrc } };
      console.log('handleCapture prepareData: ', prepareData);
      setData(prepareData);
      setOpenModWebcam(false);
    } else {
      alert("No Image");
    }
  }, []);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    console.log("handleFileInputChange  file: ", file)
    if (file) {
      previewFile(file);
      setSelectedFile(file);
      // setFileInputState(e.target.value);
    }
  };

  const previewFile = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      console.log("previewFile reader.result: ", reader.result)
      let imageSrc = reader.result
      // setPreviewSource(reader.result);
      let prepareData = { ...data, image: { ...data["image"], data: imageSrc } };
      console.log('handleCapture prepareData: ', prepareData);
      setData(prepareData);
      setPreviewImage(imageSrc)
    };
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Label
          color={props.positionFoot === "leftFoot" ? "blue" : "orange"}
          content={props.positionFoot === "leftFoot" ? `${intl.formatMessage({ id: "bplusClinicKey626" })}` : `${intl.formatMessage({ id: "bplusClinicKey624" })}`}
        />
      </div>

      <Grid verticalAlign="middle">
        <Grid.Column
          width={8}
          style={{ padding: "0px 0px 10px 5px", textAlign: "center" }}
        >
          <Card>
            <CardContent
              style={{
                height: "100px",
                padding: 0,
              }}
            >
              {!previewImage ? (
                <div>
                  <label 
                    htmlFor={`file-${props.positionFoot}`}
                    style={{
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      // setOpenModWebcam(true);
                    }}
                  >
                    <Icon name="camera" size="big" />
                    
                    <div style={{ lineHeight: "15px" }}>
                      {intl.formatMessage({id: "bplusClinicKey606"})}
                    </div>

                  </label>
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    id={`file-${props.positionFoot}`}
                    onChange={handleFileInputChange}
                  />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    <img
                      style={styles.imageContain}
                      id={`fileField-${props.positionFoot}`}
                      src={previewImage}
                      alt="chosen"
                      onClick={() => {
                        setOpenModPreview(true);
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "-6px",
                        right: "-4px",
                        color: "#EB5757",
                        borderRadius: "35px",
                        height: "35px",
                      }}
                    >
                      <CancelIcon
                        style={{ fontSize: "35px" }}
                        onClick={handleImageCancel}
                      />
                    </div>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </Grid.Column>
        {(Object.keys(FOOT_TYPE) as FootTypeKey[]).map((key, index: number) => {
          return (
            <Grid.Column
              key={index}
              width={8}
              style={
                data[FOOT_TYPE[key].key]
                  ? styles.selectedFeeling
                  : styles.allFeeling
              }
            >
              <Image
                src={`/images/foot/${FOOT_TYPE[key].key}.png`}
                style={styles.imageContain}
                onClick={() => {
                  handleSelectedFoot(
                    FOOT_TYPE[key].key,
                    !data[FOOT_TYPE[key].key] || false
                  );
                }}
              />
              <div style={styles.labelMenu}>{
              FOOT_TYPE[key].label1? <FormattedMessage id={FOOT_TYPE[key].label1} />  : ""
            }</div>
              <div style={styles.labelMenu}>{FOOT_TYPE[key].label2? 
              <FormattedMessage id={FOOT_TYPE[key].label2} />  : ""
              }</div>
            </Grid.Column>
          );
        })}
      </Grid>

      <Modal
        open={openModWebcam}
        closeIcon
        onClose={() => setOpenModWebcam(false)}
      >
        <Modal.Header>
          <FormattedMessage id="bplusClinicKey482" />
        </Modal.Header>
        <Modal.Content>
          <Segment align="center">
            <Webcam
              videoConstraints={{
                width: 800,
                height: 600,
                facingMode: { exact: "environment" },
              }}
              audio={false}
              height={250}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={300}
              screenshotQuality={0.99}
            />
            {/* <Webcam
              audio={false}
              height={200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={220}
              videoConstraints={{
                width: 220,
                height: 200,
                facingMode: "user",
              }}
            /> */}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={(event: any) => {
              event.preventDefault();
              handleCapture();
            }}
          >
            <FormattedMessage id="bplusClinicKey482" />
          </Button>
          <Button color="red" onClick={() => setOpenModWebcam(false)}>
            <FormattedMessage id="common.cancel"/>
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={openModPreview}
        closeIcon
        onClose={() => setOpenModPreview(false)}
      >
        <Modal.Header>{intl.formatMessage({id: "bplusClinicKey612"})}</Modal.Header>
        <Modal.Content>
          <Image
            style={{ minHeight: "200px", minWidth: "200px" }}
            src={previewImage}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenModPreview(false)}>
          <FormattedMessage id="bplusClinicKey116"/>
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
});

DMFootMain.defaultProps = DMFootMainInitial;

export default React.memo(DMFootMain);
