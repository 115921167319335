import React, {
  useState,
  useEffect,
  CSSProperties,
  useMemo,
  ReactChild,
  MutableRefObject,
  useRef,
} from "react";
import {
  Icon,
  Input,
  Button,
  Card,
  ButtonProps,
  Modal,
  Dimmer,
  Loader,
  InputProps,
} from "semantic-ui-react";
import { Skeleton } from "@mui/material";
import { AppBar } from "@material-ui/core";

import moment from "moment";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
  useIntl,
} from "react-intl";
// Css
import "./MyCoupon.scss";
import { Event as MobEvent } from "../MobCouponInterface";
import { SetPropSeparate } from "react-lib/frameworks/SetProp";
import ErrorMessage  from "react-lib/apps/common/ErrorMessage";
import ModInfo  from "react-lib/apps/common/ModInfo";
import MobHeadApp from "../appointment/MobHeadApp";
import ModalChooseMakeAppointment from "./ModalChooseMakeAppointment";
import ModRetryTimeout from "../appointment/ModRetryTimeout";
import SnackMessage from "../clinic/SnackMessage";

type MyCouponProps = {
  onEvent: (e: MobEvent) => any;
  myCouponList?: any[];
  firstLoadingSuccess?: boolean;
  loadingSearchCoupon?: boolean;
  selectedCoupon?: any;
  loadingModalRedeem?: boolean;
  patientDetail?: any;
  userId?: number | string;
  language?: "th-TH" | "en-US";
  showTopbar?: boolean;
  // config_PRX_ENABLE_ECOUPON?: boolean;
} & WrappedComponentProps;

type MenuTabType = "ALL" | "AVAILABLE";

type CardTitleProps = {
  name: string;
  hospital: string;
  id?: string;
  hideId?: boolean;
  skeleton?: boolean;
};

type NotFoundProps = {
  style?: CSSProperties;
  text?: string;
  type?: "not_found_grey" | "not_found" | "has_used";
  content?: () => ReactChild;
};

type CardCouponListProps = {
  list?: any[];
  onDownloadCoupon?: (item: any) => any;
  onSelectCoupon?: (item: any) => any;
  skeleton?: boolean;
} & Pick<MyCouponProps, "language" | "intl" >;

// Constant
export const COLOR = {
  blue: "#18a0fb",
  medium_blue: "#0b4ea6",
  dark_blue: "#0147A3",
  opacity: "rgba(133,133,133,0.025)",
};

const IMAGE = {
  coupon_not_found_grey: "/images/coupon-not-found-2.png",
  coupon_not_found: "/images/coupon-not-found.png",
  coupon_has_used: "/images/coupon-has-used.png",
};

const params = new URLSearchParams(window.location.search);
const app = params.get("app");
const show_coupon = params.get("show_coupon");

// Utils
const formatDate = (date: string, language?: string) => {
  if (!moment(date).isValid()) {
    return "";
  }
  const format = moment(date)
    .locale(language === "th-TH" ? "th" : "en")
    .format("DD MMM YYYY");

  return format;
};

const CardTitle: React.FunctionComponent<CardTitleProps> = (props) => {
  const intl = useIntl();

  return (
    <div style={{ display: "grid", gridTemplateColumns: "52px auto" }}>
      {!props.skeleton ? (
        <>
          <div>
            <div className="teal card-image">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.546 2.41315L6.23437 3.72477L4.711 2.20052L5.978 0.933524L5.04437 -0.000976562L0 5.0434L0.933625 5.97702L2.20062 4.71002L3.72487 6.23427L2.41325 7.5459L13.7532 18.8859L18.4774 18.9944L20.4802 20.9973L21.4025 20.8975L18.9892 18.4843L18.8807 13.7601L7.546 2.41315ZM7.546 3.90765L13.307 9.66865L9.6775 13.2982L8.211 11.8334L10.031 10.0134L9.261 9.2434L7.441 11.0634L6.08475 9.70715L7.90475 7.88715L7.13475 7.11715L5.31475 8.93715L3.92 7.5424L7.546 3.90765Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>

          <div style={{ lineHeight: "24px", marginTop: "-4px" }}>
            <div className="font-bold" style={{ fontSize: "1.05rem" }}>
              {props.name}
            </div>
            <div className="grey">{props.hospital}</div>
            {!props.hideId && (
              <div className="grey">
                {intl.formatMessage({ id: "bplusClinicKey261" })} {props.id}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Skeleton
            animation="pulse"
            variant="circular"
            width={42}
            height={42}
            style={{ margin: "2px 0 0 -5px" }}
          />
          <div style={{ lineHeight: "24px", marginTop: "-4px" }}>
            {[90, 60, 75, 50].map((width, index: number) => (
              <Skeleton
                key={"skeleton" + index}
                animation="pulse"
                height={20}
                width={`${width}%`}
                style={{ marginBottom: "3px" }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export const NotFound: React.FunctionComponent<NotFoundProps> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh)",
        ...props.style,
      }}
    >
      <img
        src={IMAGE[`coupon_${props.type || "not_found_grey"}`]}
        alt="coupon-not-found"
        style={{ marginLeft: "15px" }}
      />
      {props.content ? (
        props.content()
      ) : (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginTop: "15px",
            color: "#797878",
          }}
        >
          {props.text}
        </div>
      )}
    </div>
  );
};

const CardCouponList: React.FunctionComponent<CardCouponListProps> = (
  props
) => {
  return (
    <>
      {props.list?.[0] || props.skeleton ? (
        (props.skeleton ? new Array(3).fill("") : props.list)?.map(
          (item: any, index: number) => (
            <Card
              key={"coupon-" + index}
              className={`card-frame card-group${
                item.coupon_status === "not use" && item.book_status
                  ? ""
                  : " inactive"
              }`}
              style={
                props.skeleton
                  ? { background: COLOR.opacity, boxShadow: "none" }
                  : {}
              }
            >
              {!props.skeleton ? (
                <>
                  {/* Tag */}
                  <div className="card-tag-name teal">
                    {/* <label>{item.vaccine_name?.split(" ")?.[0]}</label> */}
                  </div>

                  <div style={{ padding: "15px 10px" }}>
                    {/* Header */}
                    <CardTitle
                      name={item.product_name}
                      hospital={"Bangkok Hospital (BHQ)"}
                      id={item.coupon_id}
                    />
                    {/* Ribbon */}
                    {!(
                      item.coupon_status === "not use" && item.book_status
                    ) && (
                      <div className="ribbon">
                        {item.coupon_status === "not use" && !item.book_status
                          ? "can't booking"
                          : item.coupon_status}
                      </div>
                    )}
                    {/* Footer */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "14px",
                      }}
                    >
                      <div style={{ marginLeft: "18px" }}>
                        <div className="grey">
                          <FormattedMessage id="common.expire" />
                        </div>
                        <div className="font-bold">
                          {formatDate(item.expired_date, props.language)}
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="rounded"
                          style={{
                            color: "white",
                            backgroundColor: COLOR.blue,
                            padding: "6px 10px",
                          }}
                          onClick={() => props.onDownloadCoupon?.(item)}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.625 17.1875L19.6529 16.2154L17.875 17.9933V12.375H16.5V17.9933L14.7221 16.2154L13.75 17.1875L17.1875 20.625L20.625 17.1875Z"
                              fill="white"
                            />
                            <path
                              d="M12.375 19.2502H5.5V2.75023H11V6.87523C11.0011 7.23957 11.1463 7.58868 11.4039 7.8463C11.6616 8.10393 12.0107 8.24915 12.375 8.25023H16.5V10.3127H17.875V6.87523C17.8774 6.78488 17.8602 6.69507 17.8246 6.612C17.789 6.52892 17.7359 6.45454 17.6688 6.39398L12.8563 1.58148C12.7957 1.51435 12.7214 1.46114 12.6383 1.42553C12.5552 1.38992 12.4654 1.37276 12.375 1.37523H5.5C5.13566 1.37632 4.78656 1.52154 4.52893 1.77916C4.2713 2.03679 4.12609 2.3859 4.125 2.75023V19.2502C4.12609 19.6146 4.2713 19.9637 4.52893 20.2213C4.78656 20.4789 5.13566 20.6241 5.5 20.6252H12.375V19.2502ZM12.375 3.02523L16.225 6.87523H12.375V3.02523Z"
                              fill="white"
                            />
                          </svg>
                        </Button>
                        <Button
                          className="rounded redeem"
                          style={{
                            color: "white",
                            // backgroundColor: !props.config_PRX_ENABLE_ECOUPON
                            //   ? "rgba(0, 0, 0, 0.12)"
                            //   : COLOR.blue,
                            backgroundColor: COLOR.blue,
                            textTransform: "uppercase",
                          }}
                          onClick={() => props.onSelectCoupon?.(item)}
                          // disabled={!props.config_PRX_ENABLE_ECOUPON}
                        >
                          <FormattedMessage id="e_coupon.redeem" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Tag */}
                  <div
                    className="card-tag-name teal"
                    style={props.skeleton ? { background: "transparent" } : {}}
                  ></div>
                  <div style={{ padding: "15px 10px" }}>
                    {/* Header */}
                    <CardTitle
                      name={item.product_name}
                      hospital={"Bangkok Hospital (BHQ)"}
                      id={item.coupon_id}
                      skeleton={true}
                    />
                    {/* Footer */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "14px",
                      }}
                    >
                      <div style={{ marginLeft: "18px" }}>
                        <Skeleton animation="pulse" height={20} width={40} />
                        <Skeleton animation="pulse" height={20} width={80} />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton
                          animation="pulse"
                          variant="circular"
                          width={42}
                          height={35}
                          style={{ borderRadius: "8px", marginRight: "5px" }}
                        />
                        <Skeleton
                          animation="pulse"
                          variant="circular"
                          width={98}
                          height={35}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
          )
        )
      ) : (
        <NotFound
          text={props.intl.formatMessage({
            id: "e_coupon.coupon_not_found",
          })}
          style={{ height: "calc(100vh - 180px)" }}
        />
      )}
    </>
  );
};

const MyCoupon: React.FunctionComponent<MyCouponProps> = (props) => {
  let history = useHistory();
  // Loading

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [locationKeys, setLocationKeys] = useState<string[]>([]);
  const [reverseOrder, setReverseOrder] = useState<boolean>(false);
  const [menuTab, setMenuTab] = useState<MenuTabType>("AVAILABLE");
  const [textSearch, setTextSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  const [openModInfo, setOpenModInfo] = useState<any>({});
  const [openModChooseAppointment, setOpenModChooseAppointment] = useState<{
    open?: boolean;
    data?: any;
  }>({});

  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
  const [isFetchError, setIsFetchError] = useState<boolean>(false);

  const screenRef = useRef() as MutableRefObject<HTMLDivElement>;
  const appBarRef = useRef() as MutableRefObject<HTMLDivElement>;
  const diagFormId = useRef<any>();

  // Effect
  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }

        if (props.selectedCoupon) {
          props.onEvent({
            message: "handleSelectedCoupon",
            params: { data: null },
          });
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (show_coupon) {
      history.replace(`?app=${app}`);
    }
  }, []);

  useEffect(() => {
    if (typeof props.userId === "undefined") {
      return;
    }

    props.onEvent({
      message: "handleGetListMyCoupon",
      params: { ...paramsSearch, userId: props.userId },
    });
  }, [props.userId]);

  useEffect(() => {
    getFilterListMyCoupon();
  }, [reverseOrder, textSearch]);

  useEffect(() => {
    const bodyRef = screenRef.current;

    bodyRef.onscroll = async () => {
      // set topbar boxShadow on scroll
      if (appBarRef.current && props.showTopbar) {
        appBarRef.current.style.boxShadow =
          bodyRef.scrollTop > 0 ? "0 1px 4px 0 rgb(0,0,0,0.12)" : "none";
      }
    };
  }, []);

  // Memo
  const paramsSearch = useMemo(() => {
    return {
      reverse: reverseOrder,
      search: textSearch,
      type: menuTab === "ALL" ? "" : menuTab,
    };
  }, [reverseOrder, textSearch, menuTab]);

  //---------- API GET
  const getFilterListMyCoupon = () => {
    props.onEvent({
      message: "handleFilterListMyCoupon",
      params: paramsSearch,
    });
  };

  // Handle
  const handleToggleSort = () => {
    setReverseOrder(!reverseOrder);
  };

  const handleSelectCoupon = async (item: any) => {
    if (!(item.coupon_status === "not use" && item.book_status)) {
      return;
    }

    setSelectedCoupon(item);

    diagFormId.current = null;

    setIsLoading(true);
    // console.log("Selected coupon:", item)
    const [res, error] = await props.onEvent({
      message: "getPatientCouponCheck",
      params: { hospital: 1, couponId: item.coupon_id },
    });

    if (error) {
      setIsFetchError(true);
      setIsLoading(false);
      return;
    }

    if (res?.result_code !== "Y") {
      setIsLoading(false);
      setOpenModInfo({ open: true, error: res.result_details });
      setIsLoading(false);
      return;
    }

    // implement here for diag form coupon
    const response = await props.onEvent({
      message: "handleGetEvaluate",
      params: { productCode: item.product_code },
    }); // set fix
    setIsLoading(false);

    const findModerna = response?.medProgram?.items?.find(
      (item: any) => item.booking_service_code === "24A2767"
    );

    diagFormId.current = response?.diagFormId;

    if (findModerna) {
      setOpenModChooseAppointment({ open: true, data: item });
    } else {
      goToPageEvaluate(item);
    }

    // implement soon for non-diag form coupon

    // history.push({
    //   pathname: `/?app=${"MobAppointment"}&product_code=${item.product_code}`,
    //   state: { product_name: item.product_name, coupon_number: item.coupon_id, patientDetail: props.patientDetail, coupon_code: `${item.coupon_id}${item.site}` }
    // });

    // globalThis.location.href = `/?app=${"MobAppointment"}&product_code=${item.product_code}`;
  };

  const goToPageEvaluate = (item: any, page: string = "evaluate") => {
    history.push({
      pathname: `/${page}/?app=${"MobCoupon"}`,
      // pathname: `/?app=${"MobAppointment"}&product_code=${product_code}`,
      state: {
        product_code: item.product_code,
        product_name: item.product_name,
        coupon_number: item.coupon_id,
        patientDetail: props.patientDetail,
        coupon_code: `${item.coupon_id}${item.site}`,
        coupon_expired_date: item.expired_date,
        is_redeem: true,
        diag_form: diagFormId.current,
      },
    });
  };

  const handleModalSelect = (select: "self" | "others") => {
    if (select === "self") {
      goToPageEvaluate(openModChooseAppointment.data);
    } else if (select === "others") {
      goToPageEvaluate(openModChooseAppointment.data, "new-patient");
    }

    setOpenModChooseAppointment({});
  };

  const handleCloseModal = () => {
    history.goBack();
    props.onEvent({ message: "handleSelectedCoupon", params: { data: null } });
  };

  const handleClickRedeem = () => {
    if (props.selectedCoupon?.is_used) {
      return;
    }

    globalThis.location.href = `?app=${"MobAppointment"}`;
  };

  const handleClickTab = (e: any, v: ButtonProps) => {
    setMenuTab(v.name);
    props.onEvent({
      message: "handleFilterListMyCoupon",
      params: { ...paramsSearch, type: v.name === "ALL" ? "" : v.name },
    });
  };

  const handleSearch = (e: any, v: InputProps) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setTextSearch(v.value);
      }, 800)
    );
  };

  const handleClickAddCoupon = () => {
    history.push(`/add-coupon?app=${app}`);
  };

  const handleDownloadCoupon = (item: any) => {
    var a = document.createElement("a");
    a.setAttribute("download", "5599");
    a.setAttribute("href", item.link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // globalThis.open(item.link, '_blank');
  };
  const handleCloseModInfo = () => {
    setOpenModInfo({});
  };

  return (
    <div>
      {/* <div style={{ width: "100%", height: "80px", backgroundColor: "#18a0fb", position: "sticky", zIndex: 99, top: 0 }}></div> */}
      <Dimmer active={isLoading} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      {props.showTopbar && (
        <AppBar
          ref={appBarRef}
          position="relative"
          style={{ backgroundColor: "transparent", zIndex: 1000 }}
          elevation={0.0}
        >
          <MobHeadApp
            title={"E-coupon"}
            leftIconClick={() => {
              history.goBack();
            }}
          />
        </AppBar>
      )}

      <div
        ref={screenRef}
        className="my-coupon"
        style={{
          height: props.showTopbar
            ? `calc(100vh - ${appBarRef.current?.offsetHeight || 0}px)`
            : "100vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "relative" }}>
          {props.myCouponList?.[0] || props.firstLoadingSuccess ? (
            <>
              <div style={{ padding: "10px 12px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "85% 15%",
                    rowGap: "15px",
                  }}
                >
                  {/* Filter */}
                  <div>
                    <Input
                      icon={
                        <Icon
                          name="search"
                          style={{ color: COLOR.medium_blue, fontSize: "17px" }}
                        />
                      }
                      placeholder={props.intl.formatMessage({
                        id: "common.search",
                      })}
                      iconPosition="left"
                      fluid
                      style={{
                        marginRight: "10px",
                        fontSize: "16px",
                      }}
                      onChange={handleSearch}
                    />
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      circular
                      style={{
                        backgroundColor: COLOR.blue,
                        width: "42px",
                        height: "42px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleToggleSort}
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          style={{
                            transform: !reverseOrder ? "rotate(180deg)" : "",
                          }}
                          width="7"
                          height="15"
                          viewBox="0 0 7 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="3.5"
                            y1="-2.18557e-08"
                            x2="3.5"
                            y2="13"
                            stroke="white"
                          />
                          <circle cx="3.5" cy="11.5" r="3.5" fill="white" />
                        </svg>
                        <svg
                          style={{
                            marginLeft: "4px",
                            transform: reverseOrder ? "rotate(180deg)" : "",
                          }}
                          width="7"
                          height="15"
                          viewBox="0 0 7 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="3.5"
                            y1="-2.18557e-08"
                            x2="3.5"
                            y2="13"
                            stroke="white"
                          />
                          <circle cx="3.5" cy="11.5" r="3.5" fill="white" />
                        </svg>
                      </div>
                    </Button>
                  </div>

                  {/* Tab */}
                  <div>
                    <div className="tab-coupon" style={{ display: "flex" }}>
                      <Button
                        className={`${"rounded"}${
                          menuTab === "ALL" ? " active" : ""
                        }`}
                        name="ALL"
                        onClick={handleClickTab}
                      >
                        <FormattedMessage id="common.all" />
                      </Button>
                      <Button
                        className={`${"rounded"}${
                          menuTab === "AVAILABLE" ? " active" : ""
                        }`}
                        name="AVAILABLE"
                        onClick={handleClickTab}
                      >
                        <FormattedMessage id="common.available" />
                      </Button>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {props.config_PRX_ENABLE_ECOUPON && (
                      <Button
                        circular
                        onClick={handleClickAddCoupon}
                        icon="add"
                        style={{
                          backgroundColor: COLOR.blue,
                          padding: "11px 10.6px 11px 11px",
                          color: "white",
                        }}
                      />
                    )}
                  </div>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    marginTop: "15px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                <Button onClick={() => { history.push(`/camera?app=${app}`) }}>
                  TestCamera
                </Button>
              </div>
                </div> */}

                {/* List */}
                <div style={{ marginTop: "15px", position: "relative" }}>
                  <Dimmer active={props.loadingSearchCoupon} inverted>
                    <Loader inverted>
                      <FormattedMessage id="bplusClinicKey297" />
                    </Loader>
                  </Dimmer>
                  <CardCouponList
                    intl={props.intl}
                    list={props.myCouponList}
                    onSelectCoupon={handleSelectCoupon}
                    onDownloadCoupon={handleDownloadCoupon}
                    language={props.language}
                    // config_PRX_ENABLE_ECOUPON={props.config_PRX_ENABLE_ECOUPON}
                  />
                </div>
              </div>
            </>
          ) : props.firstLoadingSuccess ? (
            <NotFound
              text={props.intl.formatMessage({
                id: "e_coupon.sorry_you_not_have_coupon",
              })}
            />
          ) : (
            <div
              style={{
                height: "100vh",
                width: "100%",
                padding: "0 15px",
              }}
            >
              <Skeleton
                animation="pulse"
                height={60}
                style={{ marginTop: "-4px" }}
                width={"100%"}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "-5px",
                }}
              >
                <Skeleton
                  animation="pulse"
                  height={55}
                  style={{ marginRight: "15px" }}
                  width={105}
                />
                <Skeleton animation="pulse" height={55} width={105} />
              </div>
              <div style={{ marginTop: "10px" }}>
                <CardCouponList
                  intl={props.intl}
                  language={props.language}
                  skeleton={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        className="modal-card-group my-coupon"
        basic
        // open={Boolean(props.selectedCoupon)}
        onClose={handleCloseModal}
      >
        <Card className={props.selectedCoupon?.is_used ? "inactive" : ""}>
          <Dimmer active={props.loadingModalRedeem} inverted>
            <Loader inverted>
              <FormattedMessage id="bplusClinicKey297" />
            </Loader>
          </Dimmer>
          <div className="header teal">
            <div>{props.selectedCoupon?.vaccine_name?.split(" ")?.[0]}</div>
            <div style={{ marginRight: "30px" }}>
              {props.selectedCoupon?.coupon_id}
            </div>
            <div className="close-icon" onClick={handleCloseModal}>
              <Icon name="remove" />
            </div>
          </div>
          <div style={{ padding: "25px 18px 35px 18px" }}>
            {/* Header */}
            <CardTitle
              name={props.selectedCoupon?.vaccine_name}
              hospital={props.selectedCoupon?.division_name}
              hideId={true}
            />
            {/* Description */}
            <div
              className="grey"
              style={{ display: "flex", marginTop: "20px" }}
            >
              <div style={{ width: "80px" }}>
                <FormattedMessage id="bplusClinicKey194" />
              </div>
              :
              <div className="font-bold" style={{ paddingLeft: "5px" }}>
                {formatDate(props.selectedCoupon?.expired, props.language)}
              </div>
            </div>
            <div
              className="grey"
              style={{ display: "flex", marginTop: "13px" }}
            >
              <div style={{ width: "80px" }}>
                <FormattedMessage id="bplusClinicKey507" />
              </div>
              :
              <div className="font-bold" style={{ paddingLeft: "5px" }}>
                {formatDate(props.selectedCoupon?.is_used, props.language) ||
                  "-"}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "90px 15px",
              }}
            >
              <QRCodeImage />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="rounded redeem"
                size="large"
                style={{ backgroundColor: COLOR.blue, color: "white" }}
                onClick={handleClickRedeem}
                disabled={props.selectedCoupon?.is_used}
              >
                <FormattedMessage id="bplusClinicKey406" />
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <ModInfo
        open={openModInfo?.open}
        titleColor={"red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
        className="mod-error-custom"
      >
        <ErrorMessage error={openModInfo?.error} />
      </ModInfo>
      {/* <SnackMessage
        onEvent={props.onEvent}
        error={openModInfo?.error}
        success={null}
        onClose={handleCloseModInfo}
      /> */}

      <ModRetryTimeout
        open={isFetchError}
        language={props.language}
        onClick={() => {
          setIsFetchError(false);
          handleSelectCoupon(selectedCoupon);
        }}
        onCloseIconClick={() => setIsFetchError(false)}
        closeIcon={true}
      />

      <ModalChooseMakeAppointment
        open={openModChooseAppointment.open || false}
        onClose={() => setOpenModChooseAppointment({})}
        onSelect={handleModalSelect}
      />
    </div>
  );
};

export const QRCodeImage = (props: any) => (
  <svg
    style={props.style}
    className="qr-code-icon"
    width="317"
    height="105"
    viewBox="0 0 317 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 105V0H11.1445V105H0ZM16.6283 104.925V0H22.2891V104.925H16.6283ZM33.4336 104.925V0H38.9173V104.925H33.4336ZM61.2064 104.925V0H66.6901V104.925H61.2064ZM83.4954 104.925V0H94.4629V104.925H83.4954ZM111.268 104.925V0H116.752V104.925H111.268ZM122.413 104.925V0H127.896V104.925H122.413ZM133.557 104.925V0H139.041V104.925H133.557ZM155.67 104.925V0H166.814V104.925H155.67ZM183.443 104.925V0H194.587V104.925H183.443ZM205.731 104.925V0H216.876V104.925H205.731ZM228.021 104.925V0H239.166V104.925H228.021ZM244.649 104.925V0H255.794V104.925H244.649ZM272.599 104.925V0H289.227V104.925H272.599ZM294.711 104.925V0H300.372V104.925H294.711ZM305.855 105V0H317V105H305.855Z"
      fill="#393939"
    />
  </svg>
);

MyCoupon.defaultProps = {
  onEvent: () => null,
  myCouponList: [],
  firstLoadingSuccess: false,
  loadingSearchCoupon: false,
  selectedCoupon: null,
  loadingModalRedeem: false,
  patientDetail: {},
  showTopbar: false,
} as Omit<MyCouponProps, "intl">;

export default React.memo(injectIntl(MyCoupon));
