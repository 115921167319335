export default class ChatController {
  constructor(props) {
    this.chatManager = props.chatManager;
  }

  getChatChanel = async (apiToken, userId) => {
    var params = {
      apiToken: apiToken,
      userId: userId,
    };
    const [data, error] = await this.chatManager.getChatChannel(params);
    return [data, error];
  };

  searchChatChannel = async (apiToken, userId, search) => {
    var params = {
      apiToken: apiToken,
      userId: userId,
      search: search,
    };
    const [data, error] = await this.chatManager.getChatChannel(params);
    return [data, error];
  };

  getOfficialChannel = async (apiToken, userId, isFollow = false) => {
    var params = {
      apiToken: apiToken,
      userId: userId,
      ...( isFollow && {followed: true}) ,
      ...( !isFollow && { not_followed: true})
    };
    const [data, error] = await this.chatManager.getOfficialChannel(params);
    return [data, error];
  };

  postFollowDivisionProfile = async (apiToken, division_profile_id) => {
    var params = {
      apiToken: apiToken,
      division_profile_id: division_profile_id,
    };
    const [data, error] = await this.chatManager.postFollowDivisionProfile(params);
    return [data, error];
  };
  postunFollowDivisionProfile = async (apiToken, division_profile_id) => {
    var params = {
      apiToken: apiToken,
      division_profile_id: division_profile_id,
    };
    const [data, error] = await this.chatManager.postunFollowDivisionProfile(params);
    return [data, error];
  };

  getChatChannelFromPatientHasDivision = async (apiToken, feature_only) => {
    var params = {
      apiToken: apiToken,
      feature_only: feature_only
    };
    const [data, error] = await this.chatManager.getProxyPatientHasDivisionList(params);
    return [data, error];
  }

  

}
