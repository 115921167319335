import WasmController from "../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
// Interface

import moment from "moment";

// Api
import ProxyUserProfile from "../issara-sdk/apis/ProxyUserProfileAPI_users";
import OauthHomeViewToken from "../issara-sdk/apis/oauth_home_view_usersM";
import OauthUsersTokenSSO from "../issara-sdk/apis/oauth_users_token_ssoM";
import UserWithPatientDetail from "issara-sdk/apis/UserWithPatientDetail_users";
import SingOutAPI from "issara-sdk/apis/MobileDeviceSignOutAPI_users";
import ReceiptDownloadView from "issara-sdk/apis/ReceiptDownloadView_apps_PRX";
import * as serviceWorker from "serviceWorker";
import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";
import Fingerprint2 from "fingerprintjs2";
import CONFIG from "config/config";
import { BurstModeSharp } from "@material-ui/icons";
import { formdata } from "patient-lib/TestData";
import {
  browserName,
  browserVersion,
  isBrowser,
  deviceType,
} from "react-device-detect";

export type State = {
  loggedin?: boolean;
  platform?: string;
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  language?: "en-US" | "th-TH";
  // message
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  chatLoading?: boolean;
  patientDetail?: any;
  channelList?: any[];
  patientInfo?: any;
  unfollowChannelList?: any[];
  openVideoCallModal?: boolean;
  videoCallRoom?: string;
  focusSelectMenu?: any;
  readCaht?: any[];
  incomingCalling?: boolean;
  incomingMessage?: any;
  incomingDetail?: any;
  askPermission?: boolean;
  loadingStatusPayment?: Record<string, any>;
};

// const language = ;  // language without region code
export const StateInitial: State = {
  userId: "",
  loggedin: false,
  platform: "",
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",

  loadingStatus: false,
  patientDetail: {},
  errorMessage: null,
  successMessage: null,

  channelList: [],
  chatLoading: false,
  unfollowChannelList: [],
  patientInfo: null,
  openVideoCallModal: false,
  videoCallRoom: "",
  focusSelectMenu: "",
  readCaht: [],
  incomingCalling: false,
  incomingMessage: "",
  incomingDetail: {},
  askPermission: false,
  loadingStatusPayment: {}
};

export type Event =
  | { message: "GetLoginInfo"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "HandleGetPatient"; params: {} }
  | { message: "HandleGetUserWithPatientDetail"; params: {} }
  | { message: "HandleGetOAuthToken"; params: { code: any } }
  | { message: "HandleGetPaymentData"; params: {} }
  | { message: "logout"; params: {} }
  | { message: "HandleDownloadReceipt"; params: {receiptId: string}}
  | { message: "DidUpdate"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const DidUpdate: Handler = async (controller, params) => {};

export const HandleGetPatientDetail: Handler = async (controller, params) => {
  const [patientData, patientError] = await GetUserWithPatientDetail(
    controller,
    params
  );

  controller.setState({ patientDetail: patientData });
};

export const GetUserWithPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await UserWithPatientDetail.retrieve({
    pk: params.userId || Cookies.get("userId"),
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  return [res, error];
};

export const HandleGetUserWithPatientDetail: Handler = async (
  controller,
  params
) => {
  const { patientDetail } = controller.getState();

  if (Object.keys(patientDetail || {})?.[0]) {
    return;
  }

  const [patientData, patientError] = await GetUserWithPatientDetail(
    controller as any,
    {}
  );

  controller.setState({ patientDetail: patientData || {} });
};

export const getFingerPrint: Handler = async (controller) => {
  var deviceId: string | number | undefined;
  var options = {};
  await Fingerprint2.getPromise(options).then((components) => {
    var values = components.map(function (component) {
      return component.value;
    });
    var device = Fingerprint2.x64hash128(values.join(""), 31);
    deviceId = device;
    controller.setState({ device_id: deviceId });
  });

  return deviceId;
};

export const HandleGetOAuthToken: Handler = async (controller, params) => {
  getFingerPrint(controller);

  const HostURL = CONFIG.favor;
  const Hosttest = "localhost:3000";
  const HostURLFull = `https://${Hosttest}/?app=TeleDesktop`;

  const form_data = new FormData();
  form_data.append("client_id", CONFIG.OAUTH_CLIENT_ID); //staging
  form_data.append("client_secret", CONFIG.OAUTH_CLIENT_SECRET); //staging
  form_data.append("grant_type", "authorization_code");
  form_data.append("code", params.code);
  form_data.append("redirect_uri", CONFIG.OAUTH_REDIRECT_URL);

  const [res, error] = await OauthHomeViewToken.post({
    vendor: "bdms",
    data: form_data,
  });
  // console.log("saika ;) handleGetOAuthToken res ", res, " error :", error);

  if (error) {
    console.log("saika ;) error : ", error);
  } else {
    {
      if (res.access_token) {
        const { device_id } = controller.getState();
        const form_data = new FormData();
        form_data.append("device_id", `${device_id}` || "");
        form_data.append("device_type", `${deviceType}` || "webpush");
        form_data.append("device_name", browserName || "");
        form_data.append("device_token", "");
        form_data.append("device_api_version", browserVersion || "");
        form_data.append("apn_token", "");
        form_data.append("apn_sandbox", "");
        form_data.append("pushkit_token", "");
        form_data.append("comname", "");
        form_data.append("application", "TeleDesktop");

        const [response, error] = await OauthUsersTokenSSO.post({
          apiToken: res.access_token,
          data: form_data,
        });

        // console.log(
        //   "saika ;) OauthUsersTokenSSO response ",
        //   response,
        //   " error : ",
        //   error
        // );
        if (error) {
          console.log("saika ;) ", "error : ", error);
        } else {
          Cookies.set("apiToken", response.token || "", { path: "/" });
          HandleGetPatient(controller);
          controller.setState({
            apiToken: response.token,
            loggedin: true,
          });
        }
      }
    }
  }
};

export const logout: Handler = async (controller) => {
  // *******************  path link login  *************************
  const clientId = CONFIG.OAUTH_CLIENT_ID;
  // const Host = CONFIG.API_HOST;
  const Host = CONFIG.SSO_HOST;
  const HostURL = CONFIG.OAUTH_REDIRECT_URL; //ต้องตรงกับ Redirect url ใน staging
  const HostURLtest = `https://localhost:3000/?app=TeleDesktop`;
  const urlRedirect = encodeURIComponent(HostURL);
  const urlLogin = `${Host}/users/oauth/bdms/login/?next=%2Fusers%2Foauth%2Fbdms%2Fauthorize%2F%3Fclient_id%3D${clientId}%26response_type%3Dcode%26scope%3Dread%26lang%3Dth%26redirect_uri%3D${urlRedirect}`;
  const url = `${Host}/users/oauth/bdms/logout/?lang=th&next=${urlRedirect}`;

  const [singOutResp, singOutError] = await SingOutAPI.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  if (singOutError) {
    Cookies.set("apiToken", "", { path: "/" });
    Cookies.set("userId", "", { path: "/" });
    controller.setState({ loggedin: false, patientInfo: "" });
    localStorage.removeItem("isTeleDeskTop")
    controller.app
      .auth()
      .signOut()
      .then(() => {
        clearData(controller);
        refreshUI(controller);
      })
      .catch((error: any) => {
        console.log("signOut error");
        console.log(error);
      });

    globalThis.location.href = url;
  } else {
    Cookies.set("apiToken", "", { path: "/" });
    Cookies.set("userId", "", { path: "/" });
    controller.setState({ loggedin: false, patientInfo: "" });
    localStorage.removeItem("isTeleDeskTop")
    controller.app
      .auth()
      .signOut()
      .then(() => {
        clearData(controller);
        refreshUI(controller);
      })
      .catch((error: any) => {
        console.log("signOut error");
        console.log(error);
      });

    globalThis.location.href = url;
  }
};

const clearData: Handler = (controller) => {
  controller.user = null;
  controller.setState({
    loggedin: false,
  });
};

const setLoggedinData: Handler = (controller) => {
  controller.user = controller.app.auth().currentUser;
  // controller.setState({loggedin: true})
};

const refreshUI: Handler = (controller) => {
  if (controller.app.auth().currentUser) {
    setLoggedinData(controller);
  }
};

export const HandleGetPatient: Handler = async (controller) => {
  const [profileResp, profileError] = await ProxyUserProfile.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  let currentPatient: any = {};

  // console.log(
  //   "saika12 ;) . profileResp ",
  //   profileResp,
  //   " profileError ",
  //   profileError
  // );

  if (profileError) {
    controller.setState({
      patientInfo: {},
    });
    return;
  } else {
    currentPatient = {
      user: profileResp?.user,
      proxy_patient: profileResp?.patient,
      // episode
      pre_name: profileResp?.pre_name,
      first_name: profileResp?.first_name,
      last_name: profileResp?.last_name,
      image: profileResp?.image,
      image_url: profileResp?.image_url,
      age: profileResp?.age,
      age_dict: profileResp?.age_dict,
      birthdate: profileResp?.birthdate,
      gender: profileResp?.gender_value,
      gender_name: profileResp?.gender,
      chat_channel: "",
      patient: profileResp?.patient,
    };
    Cookies.set("userId", currentPatient.user || "", { path: "/" });

    controller.setState({
      patientInfo: currentPatient,
      userId: currentPatient.user,
    });

    if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
      const apiToken = controller.apiToken || Cookies.get("apiToken");
      const imageURl = await handleGetImage(currentPatient, apiToken);

      currentPatient = {
        ...currentPatient,
        image: imageURl,
      };
    }
    controller.setState({
      patientInfo: currentPatient,
      userId: currentPatient.user,
    });
  }
};

const handleGetImage = async (currentPatient: any, apiToken: any) => {
  const id = currentPatient.user;
  const time = Math.floor(Date.now() / 1000);
  const token = apiToken;
  const msg = `id=${id}&time=${time}&token=${token}`;
  const HOST = `${CONFIG.API_HOST}`;
  let imageURl = "";

  let hashText: any;
  await crypto.subtle
    .digest("SHA-1", new TextEncoder().encode(msg))
    .then((arrayBuffer) => {
      hashText = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      imageURl = `${HOST}/apis/PRX/v3/patient-image/?id=${id}&time=${time}&hash=${hashText
        .replaceAll("/", "_")
        .replaceAll("+", "-")}`;
    })
    .catch((err) => {
      imageURl = "";
    });
  return imageURl;
};


export const HandleDownloadReceipt: Handler = async (controller, params ) => {
  let state = controller.getState();

  controller.setState({
    loadingStatusPayment: { ...state.loadingStatusPayment, [params.card]: true },
  });

  const [arrayBuffer] = await ReceiptDownloadView.get({
    apiToken: state.apiToken || controller.cookies.get("apiToken"),
    receipt_id: params.receiptId,
    params: {
      hospital: params.hospital,
    },
    extra: {
      responseType: "arraybuffer",
    },
  });

  if (arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);

    const dataArray = Array.from(uint8Array);
    const base64String = btoa(String.fromCharCode.apply(null, dataArray));
    const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${params.linkcode}.pdf`;

  // Trigger the download
  link.click();

  }
  state = controller.getState();
  controller.setState({
    loadingStatusPayment: { ...state.loadingStatusPayment, [params.card]: false },
  });
};

