import React from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

import { cardStyles, descriptionStyles } from "./CardDoctorContent";

// Styles
const useStyles = makeStyles((theme) => ({
  card: cardStyles,
  description: descriptionStyles,
  bottom: { display: "flex", "& > div": { width: "50%" } },
}));

const CardDoctorSkeleton = () => {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.card}`}
      style={{ boxShadow: "none", backgroundColor: "transparent" }}
    >
      <CardActionArea>
        <CardContent>
          <div className="doctor-profile-detail">
            <div
              style={{
                boxShadow: "none",
                background: "transparent",
                border: "2px solid transparent",
              }}
            >
              <Skeleton
                animation="pulse"
                variant="circular"
                width={60}
                height={60}
              />
            </div>
            <div>
              <Skeleton
                animation="pulse"
                variant="text"
                width={"60%"}
                height={16}
              />
              <Skeleton
                animation="pulse"
                variant="text"
                width={"90%"}
                height={16}
              />
              <Skeleton
                animation="pulse"
                variant="text"
                width={"20%"}
                height={16}
              />
            </div>
          </div>

          <div className={classes.description}>
            <div>
              <div className="label">
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={"50%"}
                  height={16}
                />
              </div>

              <div className="label">
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={"30%"}
                  height={16}
                />
              </div>
            </div>
          </div>

          <div className={classes.bottom} style={{ marginTop: "1rem" }}>
            <div>
              <Skeleton
                animation="pulse"
                variant="text"
                width={"80%"}
                height={16}
              />
            </div>
            <div>
              <Skeleton
                animation="pulse"
                variant="text"
                width={"80%"}
                height={16}
              />
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardDoctorSkeleton);
