import WasmController from 'react-lib/frameworks/WasmController';
import * as LoginI from "./login/LoginInterface";
import axios from "axios";

export type State = 
  {
    start_date?: string,
    end_date?: string,
    downloading?: boolean,
    downloadingCovid?: boolean,
  } 
  & LoginI.State

export const StateInitial: State = 
  {
    start_date: "",
    end_date: "",
    downloading: false,
    downloadingCovid: false,
    ...LoginI.StateInitial,
  }

export type Event = 
    { message: "DidMount", params: {} } 
  | { message: "DidUpdate", params: {} }
  | { message: "FetchReport", params: {} }
  | { message: "FetchCovid", params: {} }
  | LoginI.Event

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const DidMount: Handler = async (controller, params) => {
  const apiToken = controller.cookies.get("apiToken") || "";
  if (apiToken !== "") {
    controller.apiToken = apiToken;
    controller.setState({ loggedin: true });
  }
}

export const DidUpdate: Handler = async (controller, params) => {
}

// const host = "http://localhost:8000"
const host = "https://analytics-5amzj6c6ga-de.a.run.app"

export const FetchReport: Handler = async (controller, params) => {
  controller.setState({
    downloading: true
  })
  const start_date = controller.getState().start_date; 
  const end_date = controller.getState().end_date;
  console.log(start_date, end_date);
  Promise.all([
    axios.get(`${host}/api/analytics/dailyReportMappingHN/`, 
      { 
        params: { start_date: start_date, end_date: end_date },
        headers: { "Authorization": `Token ${controller.apiToken}`}
      },
    ),
    axios.get(`${host}/api/analytics/accessKPI/`, 
      { 
        params: { start_date: start_date, end_date: end_date },
        headers: { "Authorization": `Token ${controller.apiToken}`}
      }
    )
  ])
  .then((values: any[]) => {
      console.log(values[0]);
      console.log(values[1]);
      controller.setState({
        downloading: false
      })
      downloadCsv(values[0].data.csv, "mappingHN");
      downloadCsv(values[1].data.csv, "accessKPI");
    }
  ).catch((e: any) => {
      console.log(e);
      alert("Some errors occur");
      controller.setState({
        downloading: false
      })
    }
  );
}

export const FetchCovid: Handler = async (controller, params) => {
  controller.setState({
    downloadingCovid: true
  })
  const start_date = controller.getState().start_date; 
  const end_date = controller.getState().end_date;
  console.log(start_date, end_date);
  Promise.all([
    axios.get(`${host}/api/analytics/modernaForm/`, 
      { 
        params: { start_date: start_date, end_date: end_date },
        headers: { "Authorization": `Token ${controller.apiToken}`}
      },
    ),
  ])
  .then((values: any[]) => {
      console.log(values[0]);
      controller.setState({
        downloadingCovid: false
      })
      downloadCsv(values[0].data.csv, "covidForm");
    }
  ).catch((e: any) => {
      console.log(e);
      alert("Some errors occur");
      controller.setState({
        downloadingCovid: false
      })
    }
  );
}

const downloadCsv = (csv: string, name: string) => {
  const blob = new Blob(
    ["\uFEFF"+ csv], 
    {type:"text/csv;charset=utf-8"}
  );
  const download_url = URL.createObjectURL(blob);
  var download_link = document.createElement("a");
  download_link.download = name + ".csv"
  download_link.href = download_url;
  document.body.appendChild(download_link);
  download_link.click();
  document.body.removeChild(download_link);
  download_link.remove();
}