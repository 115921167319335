import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
} from "../MobClinicInterface";

export type State = {
  breastFeedingFilterDate?: moment.Moment;
  breastFeedingDetail?: any;
};

export const StateInitial = {
  breastFeedingFilterDate: moment(),
  breastFeedingDetail: {},
};

export type Event =
  // GET
  | { message: "handleGetBreastFeedingDetail" }
  // CHANGE
  | {
      message: "handleChangeBreastFeedingFilterDate";
      params: { date: moment.Moment };
    }
  // SAVE
  | { message: "handleSaveBreastFeeding"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetBreastFeedingDetail: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.BREAST_FEEDING,
    data_type: DATA_TYPE.BREAST_FEEDING,
    start_measure: state.breastFeedingFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    breastFeedingDetail: response,
  });
};

// CHANGE
export const handleChangeBreastFeedingFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({
    breastFeedingFilterDate: moment(params.date, "DD/MM/YYYY"),
  });
};

// SAVE
export const handleSaveBreastFeeding: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const state = controller.getState();

    data = {
      group: GROUP.BREAST_FEEDING,
      data_type: DATA_TYPE.BREAST_FEEDING,
      measure_date: state.breastFeedingFilterDate?.format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: { ...params.data },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save breast feeding success.";
    handleGetBreastFeedingDetail(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
