import React, { useRef, useEffect, ReactElement } from "react";
import "./PullToRefreshCSS.scss";
import { Icon } from "semantic-ui-react";

interface PullToRefreshProps {
  isPullable?: boolean;
  onRefresh: () => any;
  children: any;
  pullDownThreshold?: number;
  maxPullDownDistance?: number;
  resistance?: number;
  backgroundColor?: string;
  className?: string;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  isPullable = true,
  onRefresh,
  children,
  pullDownThreshold = 70,
  maxPullDownDistance = 80, // max distance to scroll to trigger refresh
  resistance = 1,
  backgroundColor,
  className = "",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const pullDownRef = useRef<HTMLDivElement>(null);
  const fetchMoreRef = useRef<HTMLDivElement>(null);
  let pullToRefreshThresholdBreached: boolean = false;
  let fetchMoreTresholdBreached: boolean = false; // if true, fetchMore loader is displayed
  let isDragging: boolean = false;
  let startY: number = 0;
  let currentY: number = 0;

  useEffect(() => {
    if (!isPullable || !childrenRef || !childrenRef.current) return;
    const childrenEl = childrenRef.current;
    childrenEl.addEventListener("touchstart", onTouchStart, { passive: true });
    childrenEl.addEventListener("mousedown", onTouchStart);
    childrenEl.addEventListener("touchmove", onTouchMove, { passive: false });
    childrenEl.addEventListener("mousemove", onTouchMove);
    childrenEl.addEventListener("touchend", onEnd);
    childrenEl.addEventListener("mouseup", onEnd);
    document.body.addEventListener("mouseleave", onEnd);

    return () => {
      childrenEl.removeEventListener("touchstart", onTouchStart);
      childrenEl.removeEventListener("mousedown", onTouchStart);
      childrenEl.removeEventListener("touchmove", onTouchMove);
      childrenEl.removeEventListener("mousemove", onTouchMove);
      childrenEl.removeEventListener("touchend", onEnd);
      childrenEl.removeEventListener("mouseup", onEnd);
      document.body.removeEventListener("mouseleave", onEnd);
    };
  }, [children, isPullable, onRefresh, pullDownThreshold, maxPullDownDistance]);

  const initContainer = (): void => {
    requestAnimationFrame(() => {
      if (childrenRef.current) {
        childrenRef.current.style.overflowX = "hidden";
        childrenRef.current.style.overflowY = "auto";
        childrenRef.current.style.transform = `unset`;
      }
      if (pullDownRef.current) {
        pullDownRef.current.style.opacity = "0";
      }
      if (containerRef.current) {
        containerRef.current.classList.remove(
          "ptr--pull-down-treshold-breached"
        );
        containerRef.current.classList.remove("ptr--dragging");
      }

      if (pullToRefreshThresholdBreached)
        pullToRefreshThresholdBreached = false;
      if (fetchMoreTresholdBreached) fetchMoreTresholdBreached = false;
    });
  };

  const onTouchStart = (e: MouseEvent | TouchEvent): void => {
    isDragging = false;
    if (e instanceof MouseEvent) {
      startY = e.pageY;
    }
    if (window.TouchEvent && e instanceof TouchEvent) {
      startY = e.touches[0].pageY;
    }
    currentY = startY;
    if (childrenRef.current!.getBoundingClientRect().top < 0) {
      return;
    }
    if ((childrenRef.current?.scrollTop || 0) > 0) {
      return;
    }

    isDragging = true;
  };

  const onTouchMove = (e: MouseEvent | TouchEvent): void => {
    if (!isDragging) {
      return;
    }

    if (window.TouchEvent && e instanceof TouchEvent) {
      currentY = e.touches[0].pageY;
    } else {
      currentY = (e as MouseEvent).pageY;
    }

    containerRef.current!.classList.add("ptr--dragging");

    if (currentY < startY) {
      isDragging = false;
      return;
    }

    if (e.cancelable) {
      e.preventDefault();
    }

    const yDistanceMoved = Math.min(
      (currentY - startY) / resistance,
      maxPullDownDistance
    );

    // Limit to trigger refresh has been breached
    if (yDistanceMoved >= pullDownThreshold) {
      isDragging = true;
      pullToRefreshThresholdBreached = true;
      containerRef.current!.classList.remove("ptr--dragging");
      containerRef.current!.classList.add("ptr--pull-down-treshold-breached");
    }

    // maxPullDownDistance breached, stop the animation
    if (yDistanceMoved >= maxPullDownDistance) {
      return;
    }
    pullDownRef.current!.style.opacity = (yDistanceMoved / 65).toString();
    childrenRef.current!.style.overflow = "visible";
    childrenRef.current!.style.transform = `translate(0px, ${yDistanceMoved}px)`;
    pullDownRef.current!.style.visibility = "visible";
  };

  const onEnd = (): void => {
    const isRefresh = currentY - startY >= maxPullDownDistance / 2;

    isDragging = false;
    startY = 0;
    currentY = 0;

    // Container has not been dragged enough, put it back to it's initial state
    if (!pullToRefreshThresholdBreached) {
      if (pullDownRef.current) pullDownRef.current.style.visibility = "hidden";
      initContainer();
      return;
    }

    if (childrenRef.current) {
      childrenRef.current.style.overflow = "visible";
      childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`;
    }

    initContainer();

    if (isRefresh) {
      onRefresh();
    }
  };

  return (
    <div
      className={`ptr ${className}`}
      style={{ backgroundColor }}
      ref={containerRef}
    >
      <div className="ptr__pull-down" ref={pullDownRef}>
        <Icon loading name="circle notch" size="big" disabled />
      </div>
      <div className="ptr__children" ref={childrenRef}>
        {children}
      </div>
    </div>
  );
};
// fullcode github: react-simple-pull-to-refresh
export default PullToRefresh;
