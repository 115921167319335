import React from "react";

const MenuIcon = (props: any) => {
  return (
    <div
      className="MainDm-menuIcon"
      style={{
        borderBottom: props.underline ? "1px solid black" : "",
      }}
    >
      <img src={props.icon} alt="user-activity.icon" />
      <label>{props.label}</label>
    </div>
  );
};

export default React.memo(MenuIcon);
