import WasmController from "react-lib/frameworks/WasmController";
import * as WebI from "./WebClinicInterface";
import * as WebMomChildClassificationI from "./clinic/WebMomChildClassificationInterface";

export default class WebClinicController extends WasmController<
  WebI.State,
  WebI.Event,
  WebI.Data
> {
  constructor(
    getState: () => WebI.State,
    setState: (state: WebI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, WebI.DataInitial);
  }

  handleEvent = (e: WebI.Event) => {
    console.log(e);
    switch (e.message) {
      case "":
        console.log("test");
        return;

      // ----- WebMomChildClassification
      case "handleGetPatientList":
        WebMomChildClassificationI.handleGetPatientList(this, e.params);
        return;
      case "handleSeletedPatient":
        WebMomChildClassificationI.handleSeletedPatient(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
