import WasmController from "../../react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  GROUP,
  DATA_TYPE,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonDeletePatientData,
  commonGetPatientInfo,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonUpdatePatientData,
} from "../MobClinicInterface";

export type State = {
  lastestHeight?: any;
  weightFilterDate?: moment.Moment;
  weightFilterDuration?: string;
  weightRecordList?: any;
  weightSettingData?: any;
  weightChartList?: any;
  setWeight?: string | number | null;
  inputWeightDetail?: Partial<
    {
      weight: string | number;
      note: string;
    }>;
};

export const StateInitial: State = {
  lastestHeight: {},
  weightFilterDate: moment(),
  weightFilterDuration: "6",
  weightRecordList: {},
  weightSettingData: {},
  weightChartList: {},
  setWeight: null,
  inputWeightDetail: {
    weight: "",
    note: ""
  }
};

export type Event =
  // GET
  | { message: "GetLatestHeight", params: {} }
  | { message: "GetWeightRecord", params: {} }
  | { message: "GetWeightSetting", params: {} }
  | {
    message: "GetWeightChart";
    params: { start_measure: string; end_measure: string };
  }
  // CHANGE
  | { message: "handleChangeWeightFilterDate"; params: { date: moment.Moment } }
  | {
    message: "handleChangeWeightFilterDuration";
    params: { duration: string };
  }
  // SAVE
  | {
    message: "SaveWeightRecord";
    params: { measure_date: string; data: any };
  }
  | { message: "SaveWeightSetting"; params: {} }
  // DELETE
  | { message: "DeleteWeightRecord"; params: { id: number } }
  // Setter
  | {
    message: "HandleSetInputWeightDetail";
    params: Record<string, any>;
  };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// GET
export const GetLatestHeight: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const [error, response] = await commonGetPatientInfo(controller as any);

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    lastestHeight: parseFloat(response?.height.replace("cm", "")),
  });
};

export const GetWeightRecord: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const weightParams: any = {
    group: GROUP.WEIGHT,
    data_type: DATA_TYPE.WEIGHT,
    start_measure: state.weightFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    weightParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    weightRecordList: response,
  });
};

export const GetWeightSetting: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.WEIGHT,
    data_type: DATA_TYPE.SETTING,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      weightSettingData: null,
    });
  } else if (response === null) {
    await SaveWeightSetting(controller, {
      data: { isReminder: false, remindBefore: "", times: ["08:00", "18:00"] },
    });
    GetWeightSetting(controller);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: null,
      weightSettingData: response,
    });
  }
};

export const GetWeightChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.WEIGHT,
    data_type: DATA_TYPE.WEIGHT,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    weightChartList: response,
  });
};

// CHANGE
export const handleChangeWeightFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ 
    weightFilterDate: moment(params.date, "DD/MM/YYYY"),
    inputWeightDetail: {
      weight: "",
      note: ""
    } 
  });
};

export const handleChangeWeightFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ weightFilterDuration: params.duration });
};

// SAVE
export const SaveWeightRecord: Handler = async (controller, params) => {
  // params = {measure_date: __, data: { value: __, note: __ }}
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const data: any = {
    group: GROUP.WEIGHT,
    data_type: DATA_TYPE.WEIGHT,
    measure_date: params.measure_date,
    data: { ...params.data, height: state.lastestHeight },
  };

  const [error, response] = await commonCreatePatientData(
    controller as any,
    data
  );
  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add weight success.";
    GetWeightRecord(controller);
    
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
    inputWeightDetail: {
      weight: "",
      note: ""
    }
  });
};

export const SaveWeightSetting: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller as any, data);
  } else {
    data = {
      group: GROUP.WEIGHT,
      data_type: DATA_TYPE.SETTING,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }

  console.log("SaveWeightSetting data: ", data)

  console.log("globalThis.iosNative", globalThis.iosNative)
  if (globalThis.iosNative?.setiOSLocalAlarm) {
    // IOS 
    console.log("Call globalThis.iosNative.setiOSLocalAlarm")
    globalThis.iosNative?.setiOSLocalAlarm(data)
  }

  if (globalThis.MobNative) {
    // Android 
    console.log("call globalThis.MobNative.setLocalAlarm")
    console.log(data)
    globalThis.MobNative.setLocalAlarm(JSON.stringify(data))
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save settings success.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

// DELETE
export const DeleteWeightRecord: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete weight success.";
    }

    GetWeightRecord(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

/* ----------------------- Setter ----------------------- */
export const HandleSetInputWeightDetail: Handler = (controller, params) => {
  const { inputWeightDetail } = controller.getState()
  const { key, value } = params

  controller.setState({
    inputWeightDetail: {
      ...inputWeightDetail,
      ...params
    },
    setWeight: ""
  })
}