import React, { useState, useMemo, useEffect } from "react";
import "./MedicationCSS.scss";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Modal, Icon } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// Use styles
const COLOR = {
  primary: "var(--primary-theme-color)",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  red: "#DA0000",
  darkgray: "#9F9F9F",
  font_white: "white",
  darkgray_hos: "#797878",
};

const styles = {
  parent_card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    width: "100%",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
    background: COLOR.primary,
    color: COLOR.font_white,
    fontWeight: "bold",
    fontSize: "16px",
    justifyContent: "center",
  } as CSSProperties,
  normal_text: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: COLOR.darkgray_hos,
    justifyContent: "center",
  } as CSSProperties,
  decline_button: {
    border: `1px solid ${COLOR.primary}`,
    padding: "5px 30px",
    borderRadius: "5px",
    color: COLOR.primary,
    fontSize: "16px",
  } as CSSProperties,
  accept_button: {
    border: `1px solid ${COLOR.primary}`,
    padding: "5px 30px",
    borderRadius: "5px",
    background: COLOR.primary,
    color: COLOR.font_white,
    fontSize: "16px",
    marginLeft: "8px",
  } as CSSProperties,
};

type ModalOffMedicationAllProps = {
  decline: () => any;
  accept: () => any;
  visit?: boolean;
  typeON?: boolean;
};

const ModalOffMedicationAll: React.FunctionComponent<
  ModalOffMedicationAllProps
> = (props) => {
  return (
    <div style={styles.parent_card}>
      {props.visit ? (
        <div style={styles.title}>{`${
          props.typeON ? "On" : "Off"
        } all medicines in selected visit`}</div>
      ) : (
        <div style={styles.title}>{`${
          props.typeON ? "On" : "Off"
        } all medicines in My Medication`}</div>
      )}
      <div style={{ width: "100%", padding: "23px 10px 15px 10px" }}>
        {props.visit ? (
          <>
            <div
              style={styles.normal_text}
            >{`All medicines in this visit will be ${
              props.typeON ? "on" : "off"
            }.`}</div>
          </>
        ) : (
          <>
            <div
              style={styles.normal_text}
            >{`All medicines in My Medication`}</div>
            <div style={styles.normal_text}>{`will be ${
              props.typeON ? "on" : "off"
            }.`}</div>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "23px",
          }}
        >
          <div style={styles.decline_button} onClick={() => props.decline()}>
            Decline
          </div>
          <div style={styles.accept_button} onClick={() => props.accept()}>
            Accept
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModalOffMedicationAll);
