import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import { Avatar } from "@mui/material";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "../../clinic/SnackMessage";
import PureReactTable from "../../../react-lib/component-js/common/PureReactTable";
import { formatUTCtoMoment } from "../../../react-lib/utils/dateUtils";

import "../../../css/WebClinic.scss";

const styles = {
  ButtonAdd: {
    background: "var(--text-green)",
    color: "white",
  } as CSSProperties,
  ButtonClear: {
    background: "#F2C94C",
    color: "white",
  } as CSSProperties,
  ButtonAddPatient: {
    background: "var(--text-light-blue)",
    color: "white",
  } as CSSProperties,
};

// Modal
type ModalBabyRelationshipProps = {
  // function
  onEvent?: any;
  handleCloseModal?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  babyList?: any;
};

const ModalBabyRelationship: React.FC<ModalBabyRelationshipProps> = (props) => {
  const [keyword, setKeyword] = useState<string>("");
  const [addBabySelected, setAddBabySelected] = useState<any[]>([]);
  const [babyOptions, setBabyOptions] = useState<any[]>([]);
  // Pagination
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const columnsAdd = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 200 },
      { Header: "Phone No.", accessor: "phone_no", width: 200 },
      { Header: "Patient’s name", accessor: "patient_name", width: 350 },
      {
        Header: "Birthdate",
        accessor: "birthdate",
        width: 200,
        Cell: ({ row }: any = {}) => {
          if (row?.birthdate) {
            return (
              <>{formatUTCtoMoment(row?.birthdate).format("DD MMM YYYY")}</>
            );
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    handleGetBabyPatientList();
  }, [page, limit]);

  const handleSearchPatient = (event: any) => {
    if (event.key === "Enter") {
      handleGetBabyPatientList();
    }
  };

  const handleGetBabyPatientList = () => {
    props.onEvent({
      message: "handleGetBabyPatientList",
      params: { keyword: keyword, limit: limit, offset: page * limit },
    });
  };

  const handleSelectedBaby = (row?: any) => {
    setBabyOptions([
      ...babyOptions,
      { ...row, text: row?.patient_name, value: row.id },
    ]);
    setAddBabySelected([...addBabySelected, row.id]);
  };

  const handleUnSelectedBaby = (_event: any, data: any) => {
    let options: any[] = [];
    babyOptions?.forEach((item: any) => {
      if (data.value.includes(item.id)) {
        options.push(item);
      }
    });

    setBabyOptions(options);
    setAddBabySelected([...data.value]);
  };

  const handleBabyCreatePatientSegment = () => {
    props.onEvent({
      message: "handleBabyCreatePatientSegment",
      params: babyOptions,
    });
    setBabyOptions([]);
    setAddBabySelected([]);
    setKeyword("");
    props.handleCloseModal();
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    setPage(pageNo);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setLimit(pageSize);
  };

  return (
    <Modal
      closeIcon
      size="large"
      open={true}
      closeOnDimmerClick={true}
      onClose={props.handleCloseModal}
    >
      <Modal.Header>
        <FormattedMessage id="bplusClinicKey41" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Dimmer active={props.loadingStatus} inverted>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>

        <SnackMessage
          onEvent={props.onEvent}
          error={props.errorMessage}
          success={props.successMessage}
        />

        <Grid style={{ marginBottom: "0px" }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Input
                fluid
                icon="search"
                value={keyword}
                placeholder="HN/ชื่อ/นามสกุล/เบอร์โทรศัพท์"
                onChange={(event: any) => {
                  setKeyword(event.target.value);
                }}
                onKeyPress={handleSearchPatient}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Column width={14}>
            <Dropdown
              fluid
              multiple
              selection
              options={babyOptions}
              value={addBabySelected}
              onChange={handleUnSelectedBaby}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              fluid
              style={styles.ButtonAddPatient}
              onClick={handleBabyCreatePatientSegment}
            >
              <FormattedMessage id="bplusClinicKey39" />
            </Button>
          </Grid.Column>
        </Grid>

        <PureReactTable
          // @ts-ignore
          className="weight-table-custom web-mom"
          onSelect={handleSelectedBaby}
          data={props.babyList?.items}
          columns={columnsAdd}
          showPagination={true}
          pageSize={limit}
          pages={Math.ceil(props.babyList?.total / limit)}
          page={page}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          manual
        />
      </Modal.Content>
    </Modal>
  );
};

// WebUMBabyRelationship
type WebUMBabyRelationshipProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  loadingBabyRelationship?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedDivision?: any;
  babyRelationFilter?: any;
  familyOptions?: any[];
  relationPatient?: any;
  relationProfiles?: any[];
  selectedBabySegment?: any;
  babySegmentList?: any;
  babyList?: any;
};

const WebUMBabyRelationshipInitial: WebUMBabyRelationshipProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  loadingBabyRelationship: false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  babyRelationFilter: {},
  familyOptions: [],
  relationPatient: {},
  relationProfiles: [],
  selectedBabySegment: {},
  babySegmentList: {},
  babyList: {},
};

const WebUMBabyRelationship: React.FC<WebUMBabyRelationshipProps> = (props) => {
  const [openModAdd, setOpenModAdd] = useState<boolean>(false);
  const [openModDelete, setOpenModDelete] = useState<any>({
    open: false,
    row: {},
  });
  // Pagination
  const [clear, setClear] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const columnsPatientSegment = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 150 },
      { Header: "Patient’s name", accessor: "fullname", width: 300 },
      {
        Header: "Birthdate",
        accessor: "birthdate",
        width: 100,
        Cell: ({ row }: any = {}) => {
          if (row?.birthdate) {
            return (
              <>{formatUTCtoMoment(row?.birthdate).format("DD-MM-YYYY")}</>
            );
          }
        },
      },
      { Header: "Mom’s name", accessor: "mom_name", width: 200 },
      {
        Header: "member of relation",
        accessor: "member_of_relation",
        width: 150,
      },
      {
        Header: "Edit",
        accessor: "_edit",
        width: 80,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                color="green"
                name="edit"
                onClick={() => {
                  handleSelectedPatientSegment(row.original);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Delete",
        accessor: "_remove",
        width: 80,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                color="red"
                name="minus circle"
                onClick={() => {
                  handleDeleteBabyPatientSegment(row.original);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    props.onEvent({ message: "handleGetOptions" });
  }, []);

  useEffect(() => {
    handleGetBabySegmentList();
  }, [props.selectedDivision]);

  const handleGetBabySegmentList = () => {
    if (props.selectedDivision?.division_code !== undefined) {
      props.onEvent({ message: "handleGetBabySegmentList" });
    }
  };

  const handleKeyEnter = (event: any) => {
    if (event.key === "Enter") {
      handleGetBabySegmentList();
    }
  };

  const handleSelectedPatientSegment = (row: any) => {
    props.onEvent({ message: "handleGetFamilyRelation", params: { ...row } });
  };

  const handleGetProfileByUsername = async (index: number) => {
    props.onEvent({
      message: "handleGetProfileByUsername",
      params: { index: index },
    });
  };

  const handleAddRelationProfiles = () => {
    props.onEvent({ message: "handleAddRelationProfiles" });
  };

  const handleSaveFamily = () => {
    props.onEvent({ message: "handleCreateFamilyRelation" });
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    props.onEvent({
      message: "handleChangeBabyRelationFilter",
      params: { name: "offset", value: pageNo * props.babyRelationFilter.limit },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.onEvent({
      message: "handleChangeBabyRelationFilter",
      params: { name: "limit", value: pageSize },
    });
  };

  // Modal
  const handleOpenModal = () => {
    setOpenModAdd(true);
  };

  const handleCloseModal = () => {
    setOpenModAdd(false);
  };

  // Model Clear
  const handleClearPatientSegment = () => {
    props.onEvent({
      message: "handleChangeBabyRelationFilter",
      params: { name: "hn", value: "" },
    });
  };

  // Modal Delete
  const handleDeleteBabyPatientSegment = (row: any) => {
    setOpenModDelete({ open: true, row: row });
  };

  const handleConfirmDeletePatientSegment = () => {
    props.onEvent({
      message: "handleDeleteBabyPatientSegment",
      params: openModDelete?.row,
    });
    handleCloseModDelete();
  };

  const handleCloseModDelete = () => {
    setOpenModDelete({ open: false, row: {} });
  };

  return (
    <div className="main-layout web-dm-um">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div style={{ background: "white", padding: "30px 15px" }}>
        <Header
          as="h2"
          style={{
            color: "var(--text-dark-blue)",
            textDecorationLine: "underline",
          }}
        >
          <FormattedMessage id="bplusClinicKey76" />
        </Header>
        <Grid style={{ paddingBottom: "20px" }}>
          <Grid.Column width={8}>
            <Input
              fluid
              icon="search"
              placeholder="Search HN."
              value={props.babyRelationFilter.hn}
              onChange={(event: any) => {
                props.onEvent({
                  message: "handleChangeBabyRelationFilter",
                  params: { name: "hn", value: event.target.value },
                });
              }}
              onKeyPress={handleKeyEnter}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <div style={{ display: "flex" }}>
              <Button style={styles.ButtonAdd} onClick={handleOpenModal}>
                <FormattedMessage id="bplusClinicKey41" />
              </Button>
              <Button
                style={styles.ButtonClear}
                onClick={handleClearPatientSegment}
              >
                <FormattedMessage id="bplusClinicKey113" />
              </Button>
            </div>
          </Grid.Column>
        </Grid>
        <PureReactTable
          // @ts-ignore
          className="weight-table-custom web-mom"
          selectedRow={props.selectedBabySegment}
          data={props.babySegmentList?.items}
          columns={columnsPatientSegment}
          showPagination={true}
          pageSize={props.babyRelationFilter?.limit}
          pages={Math.ceil(
            props.babySegmentList?.total / props.babyRelationFilter?.limit
          )}
          page={
            props.babyRelationFilter?.offset / props.babyRelationFilter?.limit
          }
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          // onSelect={handleSelectedPatientSegment}
          loading={props.loadingBabyRelationship}
          manual
        />
        {props.selectedBabySegment.hasOwnProperty("proxy_patient") && (
          <>
            <Grid
              columns={2}
              verticalAlign="bottom"
              style={{ paddingTop: "30px" }}
            >
              <Grid.Column width={2}>
                <Avatar
                  src={
                    props.relationPatient?.image_url ||
                    props.relationPatient?.image ||
                    "/images/Appointment/user-icon.png"
                  }
                  style={{ height: "130px", width: "120px", margin: "auto" }}
                />
              </Grid.Column>
              <Grid.Column width={14} verticalAlign="middle">
                <Grid
                  verticalAlign="bottom"
                  style={{ color: "var(--text-grey)" }}
                >
                  <Grid.Row>
                    <Grid.Column width={1}>
                      <Header as="h4">
                        <FormattedMessage id="bplusClinicKey229" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {props.relationPatient?.hn}
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Header as="h4">
                        <FormattedMessage id="bplusClinicKey598" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      {props.relationPatient?.fullname}
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Header as="h4">
                        <FormattedMessage id="bplusClinicKey615" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {props.relationPatient?.birthdate}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={3}>
                      <Header as="h4">
                        <FormattedMessage id="bplusClinicKey597" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={13}>
                      {props.relationPatient?.mom_name}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid>

            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={2}>
                  <Header as="h4">
                    <FormattedMessage id="bplusClinicKey611" />
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h5">
                    <FormattedMessage id="bplusClinicKey509" />
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h5">
                    <FormattedMessage id="bplusClinicKey227" />
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header as="h5">
                    <FormattedMessage id="bplusClinicKey596" />
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h5">
                    <FormattedMessage id="bplusClinicKey595" />
                  </Header>
                </Grid.Column>
              </Grid.Row>

              {props.relationProfiles?.map((item: any, index: number) => {
                return (
                  <Grid.Row key={index}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={3}>
                      <Input
                        fluid
                        icon="search"
                        placeholder="Username"
                        value={item?.username}
                        onChange={(_event: any, data: any) => {
                          props.onEvent({
                            message: "handleChangeRelationProfiles",
                            params: {
                              index: index,
                              name: "username",
                              value: data.value,
                            },
                          });
                        }}
                        onKeyPress={(event: any) => {
                          if (event.key === "Enter") {
                            handleGetProfileByUsername(index);
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {/* {item?.hn} */}
                      <Input
                        fluid
                        value={item?.hn}
                        onChange={(_event: any, data: any) => {
                          props.onEvent({
                            message: "handleChangeRelationProfiles",
                            params: {
                              index: index,
                              name: "hn",
                              value: data.value,
                            },
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      {/* {item?.profile_name} */}
                      <Input
                        fluid
                        value={item?.profile_name}
                        onChange={(_event: any, data: any) => {
                          props.onEvent({
                            message: "handleChangeRelationProfiles",
                            params: {
                              index: index,
                              name: "profile_name",
                              value: data.value,
                            },
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Dropdown
                        fluid
                        selection
                        placeholder="ความสัมพันธ์"
                        options={props.familyOptions}
                        value={item?.family}
                        onChange={(_event: any, data: any) => {
                          props.onEvent({
                            message: "handleChangeRelationProfiles",
                            params: {
                              index: index,
                              name: "family",
                              value: data.value,
                            },
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <Icon
                        name="minus circle"
                        color="red"
                        size="large"
                        onClick={() => {
                          props.onEvent({
                            message: "handleRemoveRelationProfiles",
                            params: { index: index },
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={1}>
                      {index + 1 === props.relationProfiles?.length && (
                        <Icon
                          name="plus circle"
                          color="blue"
                          size="large"
                          onClick={handleAddRelationProfiles}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                );
              })}

              <Grid.Column width={14}></Grid.Column>
              <Grid.Column width={2}>
                <Button
                  fluid
                  style={styles.ButtonAdd}
                  onClick={handleSaveFamily}
                >
                  <FormattedMessage id="bplusClinicKey431" />
                </Button>
              </Grid.Column>
            </Grid>
          </>
        )}
      </div>

      {openModAdd && (
        <ModalBabyRelationship
          // function
          onEvent={props.onEvent}
          handleCloseModal={handleCloseModal}
          // data
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          babyList={props.babyList}
        />
      )}

      <ModConfirm
        titleColor={"yellow"}
        openModal={openModDelete.open}
        onDeny={handleCloseModDelete}
        onApprove={handleConfirmDeletePatientSegment}
        onCloseWithDimmeClick={handleCloseModDelete}
        titleName={`ต้องการลบ [${openModDelete?.row?.hn}] ${openModDelete?.row?.fullname} หรือไม่`}
      />
    </div>
  );
};

WebUMBabyRelationship.defaultProps = WebUMBabyRelationshipInitial;

export default React.memo(WebUMBabyRelationship);
