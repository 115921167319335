/* Generated apis ---------------------- */
import { to } from "react-lib/utils";
import BaseService from "react-lib/apis/services/BaseService";
import config from "../../config/config";
import PatientDataSerializer from "../types/PatientDataSerializer_apps_PHR";

const HOST = `${config.API_HOST}`;
/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
const PatientDataDetail: {
  retrieve: ({
    pk,
    params,
    apiToken,
    extra,
  }: {
    pk: any;
    params?: any;
    apiToken?: any;
    extra?: any;
  }) => any;
  update: ({
    pk,
    params,
    data,
    apiToken,
    extra,
  }: {
    pk: any;
    params?: any;
    data?: PatientDataSerializer;
    apiToken?: any;
    extra?: any;
  }) => any;
  patch: ({
    pk,
    params,
    data,
    apiToken,
    extra,
  }: {
    pk: any;
    params?: any;
    data?: PatientDataSerializer;
    apiToken?: any;
    extra?: any;
  }) => any;
  delete: ({
    pk,
    params,
    apiToken,
    extra,
  }: {
    pk: any;
    params?: any;
    apiToken?: any;
    extra?: any;
  }) => any;
} = {
  retrieve: async ({ pk, params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/apis/PHR/patient-data/${pk}/`, {
          params: params ? { ...params } : {},
          ...extra,
        })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  update: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .put(
          `${HOST}/apis/PHR/patient-data/${pk}/`,
          { ...data },
          { params: params ? { ...params } : {}, ...extra }
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  patch: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .patch(
          `${HOST}/apis/PHR/patient-data/${pk}/`,
          { ...data },
          { params: params ? { ...params } : {}, ...extra }
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  delete: async ({ pk, params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .delete(`${HOST}/apis/PHR/patient-data/${pk}/`, {
          params: params ? { ...params } : {},
          ...extra,
        })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
};

export default PatientDataDetail;
