// Autogenerated by utils/python/genmain.py
import React, { useEffect, useState } from "react";
import TabPath from "../../react-lib/frameworks/TabPath";
import MarketingSegment from "../post/MarketingSegment";
import { Post } from "../post/Post";
import ClinicContent from "./ClinicContent";
import { useHistory } from "react-router-dom";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

// Interface
import {
  State,
  Event,
  CHILD_RESULT,
  CHILD_SUB_RESULT,
  MOM_RESULT,
  MOM_SUB_RESULT,
} from "../ManageInterface";

// ui
import PatientDashBoard from "./PatientDashboard";
import MomDetail from "./MomDetail";
import ChildDetail from "./ChildDetail";
import UserManagementMom from "./UserManagementMom";
import MenuIcon from "./MenuIcon";

type MainMomProps = {
  // function
  onEvent: (e: Event) => any;
  setProp: any;
  setPropSeparate: any;
  history: any;
  // data PatientDashBoard
  patientSegmentList?: any;
} & Pick<
  State,
  // common
  | "loadingStatus"
  | "loadingPatientSelection"
  | "loadingBabyRelationship"
  | "loadingPatientSegment"
  | "errorMessage"
  | "successMessage"
  | "selectedPatient"
  | "selectedDivision"
  | "clinic"
  | "leftMenuSelected"
  | "dmSelectedTab"
  | "showSegment"
  // Options
  | "routeOptions"
  | "unitOptions"
  | "frequencyOptions"
  | "methodOptions"
  | "administrationTimeOptions"
  // data PatientDetail
  | "showPatientDM"
  | "menuName"
  // WebPatientPanel
  | "scoreSummary"
  | "labSummary"
  // WebMomOverall
  | "profileHealth"
  | "profileObstretic"
  // Weight gain
  | "weightGainDetail"
  | "weightGainList"
  | "obstreticRecord"
  | "weightPrePregnancy"
  | "weightFilterStartDate"
  | "weightFilterEndDate"
  // Baby kickcount
  | "kickCountHistory"
  | "kickCountFilterDuration"
  // Contraction Count
  | "contractionCountHistory"
  | "contractionFilterDuration"
  // Breast Feeding
  | "breastfeedingList"
  // Obstretic record
  | "obstetricHistoryList"
  | "activeIndexBaby"
  | "activeIndexObstetricHistory"
  | "obstetricChildList"
  | "openModConfirmCreate"
  // Ultrasound
  | "ultrasoundList"
  | "ultrasoundUploadDetail"
  | "selectedUltrasoundImage"
  // Post
  | "tagList"
  | "postList"
  | "selectedPostIndex"
  // PrenatalAndANCRecord
  | "prenatalAndANCRecordList"
  | "prenatalAndANCRecordFilter"
  // OutsideMedicine
  | "outsideMedicines"
  // Development
  | "developmentHistory"
  | "developmentLastEdited"
  | "developmentAgeRange"
  // Growth
  | "growthHistoryList"
  // Overall Progress
  | "childOverallProgress"
  // Vaccination
  | "ageRangeVaccineChild"
  | "essentialVaccineList"
  | "otherVaccineList"
  | "lastUpdateVaccineChild"
  // Usermanager Patient Selection
  | "filterKeyword"
  | "dmMomSegmentList"
  | "dmMomPatientList"
  // WebUMBabyRelationship
  | "babyRelationFilter"
  | "familyOptions"
  | "relationPatient"
  | "relationProfiles"
  | "selectedBabySegment"
  | "babySegmentList"
  | "babyList"
  // User manager vaccine
  | "vaccineUMDetail"
  | "vaccineUMList"
  | "vaccineSeriesChoice"
  | "orderItemVaccineUM"
  | "ageRangeChoice"
  | "ageRangeDetail"
  | "ageRangeList"
  // User manager development
  | "developmentChoice"
  | "developmentUMDetail"
  | "developmentUMList"
  // ClinicContent
  | "clinicContent"
  | "clinicContentIndex"
  | "clinicalSegment"
  | "divisionPatient"
  | "divisionHasUser"
  | "selectedDivisionId"
  // Segmentation
  | "loadingMarketingSegment"
  | "loadingMarketingPatient"
  | "filterMarketingPatient"
  | "marketingSegmentList"
  | "marketingPatientList"
  | "marketingSegmentAgeGender"
  | "marketingSegmentTopPost"
  | "marketingSegmentTopPromotion"
  | "marketingCreatePatient"
  | "tabPathActiveIndex"
>;

export const TAB_MOM = [
  { name: MOM_SUB_RESULT.PREGNANCY_0_13_WEEK, key: "pregnancy-0-13" },
  { name: MOM_SUB_RESULT.PREGNANCY_14_27_WEEK, key: "pregnancy-14-27" },
  { name: MOM_SUB_RESULT.PREGNANCY_28_40_WEEK, key: "pregnancy-28-40" },
  { name: MOM_SUB_RESULT.PREGNANCY_UNKNOW, key: "pregnancy-unknow" },
];

const MOM_LEFT_MENU = [
  {
    result: MOM_RESULT,
    subResults: [
      MOM_SUB_RESULT.PREGNANCY_0_13_WEEK,
      MOM_SUB_RESULT.PREGNANCY_14_27_WEEK,
      MOM_SUB_RESULT.PREGNANCY_28_40_WEEK,
      MOM_SUB_RESULT.PREGNANCY_UNKNOW,
    ],
  },
  {
    result: CHILD_RESULT,
    subResults: [
      CHILD_SUB_RESULT.CHILD_0_2_MONTH,
      CHILD_SUB_RESULT.CHILD_2_4_MONTH,
      CHILD_SUB_RESULT.CHILD_4_6_MONTH,
      CHILD_SUB_RESULT.CHILD_6_12_MONTH,
      CHILD_SUB_RESULT.CHILD_1_3_YEAR,
      CHILD_SUB_RESULT.CHILD_3_6_YEAR,
      CHILD_SUB_RESULT.CHILD_6_10_YEAR,
      CHILD_SUB_RESULT.CHILD_MORE_10_YEAR,
    ],
  },
];

const MainMomInitial: MainMomProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  setPropSeparate: () => null,
  history: {},
  // common data
  loadingStatus: false,
  loadingPatientSelection: false,
  loadingBabyRelationship: false,
  loadingPatientSegment: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  selectedDivision: {},
  clinic: "",
  // Options
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // data PatientDashBoard
  patientSegmentList: {},
  // data WebPatientPanel
  scoreSummary: [],
  labSummary: {},
  // data PatientDetail
  showPatientDM: false,
  leftMenuSelected: "patient_segment",
  dmSelectedTab: 0,
  menuName: "",
  // WebMomOverall
  profileHealth: [],
  profileObstretic: {},
  // Weight gain
  weightGainDetail: {},
  weightGainList: [],
  // baby kickcount
  kickCountHistory: {},
  kickCountFilterDuration: "7",
  // Contraction Count
  contractionCountHistory: {},
  contractionFilterDuration: "7",
  // Breast Feeding
  breastfeedingList: [],
  // Obstretic record
  obstetricHistoryList: [],
  activeIndexBaby: null,
  activeIndexObstetricHistory: 0,
  obstetricChildList: [],
  openModConfirmCreate: false,
  // Ultrasound
  ultrasoundList: [],
  ultrasoundUploadDetail: {},
  selectedUltrasoundImage: null,
  // PrenatalAndANCRecord
  prenatalAndANCRecordList: [],
  prenatalAndANCRecordFilter: [],
  // OutsideMedicine
  outsideMedicines: {},
  // Development
  developmentHistory: [],
  developmentLastEdited: null,
  // Growth
  growthHistoryList: {},
  // Overall Progress
  childOverallProgress: [],
  // Vaccination
  ageRangeVaccineChild: [],
  essentialVaccineList: [],
  otherVaccineList: [],
  lastUpdateVaccineChild: "",
  // Usermanager Patient Selection
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
  // WebUMBabyRelationship
  babyRelationFilter: "",
  familyOptions: [],
  relationPatient: {},
  relationProfiles: [],
  selectedBabySegment: {},
  babySegmentList: {},
  babyList: {},
  // Segmentation
  loadingMarketingSegment: false,
  loadingMarketingPatient: false,
  filterMarketingPatient: {},
  marketingSegmentList: {},
  marketingPatientList: {},
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  marketingCreatePatient: null,
};

const MainMom: React.FC<MainMomProps> = (props: MainMomProps) => {
  let history = useHistory();

  const [locationKeys, setLocationKeys] = useState<string[]>([]);

  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
  }, [locationKeys]);

  const handleTabChange = (index: number) => {
    if (index === 0) {
      props.setProp("leftMenuSelected", "patient_segment");
    } else if (index === 4) {
      props.setProp("leftMenuSelected", "user_management");
    }
  };

  return (
    <div className="MainMom-tabPath" style={{ height: "100%" }}>
      {/* 94vh */}
      <TabPath
        level={1}
        history={props.history}
        tabWidth={props.showPatientDM ? "0" : "5.7vw"}
        onTabChange={handleTabChange}
        data={[
          {
            name: (
              <MenuIcon
                label="Mom and Child Pathway"
                icon="/images/expansion/mom-icon.png"
              />
            ),
            key: "mompathway",
            render: () => (
              <div style={{ height: "100%" }}>
                {props.showPatientDM ? (
                  <div>
                    {props.menuName === CHILD_RESULT ? (
                      <ChildDetail
                        // function
                        onEvent={props.onEvent}
                        setProp={props.setProp}
                        // Options
                        routeOptions={props.routeOptions}
                        unitOptions={props.unitOptions}
                        frequencyOptions={props.frequencyOptions}
                        methodOptions={props.methodOptions}
                        administrationTimeOptions={
                          props.administrationTimeOptions
                        }
                        // data
                        loadingStatus={props.loadingStatus}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        selectedPatient={props.selectedPatient}
                        dmSelectedTab={props.dmSelectedTab}
                        profileHealth={props.profileHealth}
                        // OutsideMedicine
                        outsideMedicines={props.outsideMedicines}
                        // Development
                        developmentHistory={props.developmentHistory}
                        developmentLastEdited={props.developmentLastEdited}
                        developmentAgeRange={props.developmentAgeRange}
                        // Growth
                        growthHistoryList={props.growthHistoryList}
                        // Overall Progress
                        childOverallProgress={props.childOverallProgress}
                        // Vaccination
                        ageRangeVaccineChild={props.ageRangeVaccineChild}
                        essentialVaccineList={props.essentialVaccineList}
                        otherVaccineList={props.otherVaccineList}
                        lastUpdateVaccineChild={props.lastUpdateVaccineChild}
                      />
                    ) : (
                      <MomDetail
                        // function
                        onEvent={props.onEvent}
                        setProp={props.setProp}
                        // Options
                        routeOptions={props.routeOptions}
                        unitOptions={props.unitOptions}
                        frequencyOptions={props.frequencyOptions}
                        methodOptions={props.methodOptions}
                        administrationTimeOptions={
                          props.administrationTimeOptions
                        }
                        // data
                        loadingStatus={props.loadingStatus}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        selectedPatient={props.selectedPatient}
                        dmSelectedTab={props.dmSelectedTab}
                        // overall
                        scoreSummary={props.scoreSummary}
                        labSummary={props.labSummary}
                        profileHealth={props.profileHealth}
                        profileObstretic={props.profileObstretic}
                        // Weight gain
                        weightGainDetail={props.weightGainDetail}
                        weightGainList={props.weightGainList}
                        obstreticRecord={props.obstreticRecord}
                        weightPrePregnancy={props.weightPrePregnancy}
                        weightFilterStartDate={props.weightFilterStartDate}
                        weightFilterEndDate={props.weightFilterEndDate}
                        // kickcount
                        kickCountHistory={props.kickCountHistory}
                        kickCountFilterDuration={props.kickCountFilterDuration}
                        // Contractioncount
                        contractionCountHistory={props.contractionCountHistory}
                        contractionFilterDuration={
                          props.contractionFilterDuration
                        }
                        // Breast Feeding
                        breastfeedingList={props.breastfeedingList}
                        // Obstretic record
                        obstetricHistoryList={props.obstetricHistoryList}
                        activeIndexBaby={props.activeIndexBaby}
                        activeIndexObstetricHistory={
                          props.activeIndexObstetricHistory
                        }
                        obstetricChildList={props.obstetricChildList}
                        openModConfirmCreate={props.openModConfirmCreate}
                        // Ultrasound
                        ultrasoundUploadDetail={props.ultrasoundUploadDetail}
                        ultrasoundList={props.ultrasoundList}
                        selectedUltrasoundImage={props.selectedUltrasoundImage}
                        // PrenatalAndANCRecord
                        prenatalAndANCRecordList={
                          props.prenatalAndANCRecordList
                        }
                        prenatalAndANCRecordFilter={
                          props.prenatalAndANCRecordFilter
                        }
                        // Overall Progress
                        childOverallProgress={props.childOverallProgress}
                        // Vaccination
                        ageRangeVaccineChild={props.ageRangeVaccineChild}
                      />
                    )}
                  </div>
                ) : (
                  <PatientDashBoard
                    // function
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // data
                    loadingStatus={props.loadingStatus}
                    loadingPatientSegment={props.loadingPatientSegment}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    patientSegmentList={props.patientSegmentList}
                    selectedPatient={props.selectedPatient}
                    selectedDivision={props.selectedDivision}
                    leftMenuSelected={props.leftMenuSelected}
                    dmSelectedTab={props.dmSelectedTab}
                    // WebPatientPanel
                    scoreSummary={props.scoreSummary}
                    labSummary={props.labSummary}
                    profileHealth={props.profileHealth}
                    hidePersonalScoreChart={true}
                    hidePersonalLabChart={true}
                    hideMomOverall={
                      props.menuName === CHILD_RESULT ? true : false
                    }
                    hideChildOverall={
                      props.menuName === CHILD_RESULT ? false : true
                    }
                    // config
                    leftMenu={MOM_LEFT_MENU}
                    // Child Overall Progress
                    childOverallProgress={props.childOverallProgress}
                  />
                )}
              </div>
            ),
          },
          {
            name: (
              <MenuIcon
                label="Clinical Content"
                icon="/images/expansion/youtube.png"
                underline={true}
              />
            ),
            key: "Content",
            render: () => (
              <ClinicContent
                onEvent={props.onEvent}
                setProp={props.setProp}
                setPropSeparate={props.setPropSeparate}
                clinicContent={props.clinicContent}
                clinicContentIndex={props.clinicContentIndex}
                clinicalSegment={props.clinicalSegment}
                divisionPatient={props.divisionPatient}
                divisionHasUser={props.divisionHasUser}
                selectedDivisionId={props.selectedDivisionId}
                selectedDivision={props.selectedDivision}
                clinic={props.clinic}
              />
            ),
          },
          {
            name: (
              <MenuIcon
                label="Segmentation"
                icon="/images/expansion/carbon-user-activity.png"
              />
            ),
            key: "segmentation",
            render: () => (
              <div style={{ height: "100%" }}>
                <MarketingSegment
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // data
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  loadingStatus={props.loadingStatus}
                  loadingMarketingSegment={props.loadingMarketingSegment}
                  loadingMarketingPatient={props.loadingMarketingPatient}
                  selectedDivision={props.selectedDivision}
                  filterMarketingPatient={props.filterMarketingPatient}
                  marketingSegmentList={props.marketingSegmentList}
                  marketingPatientList={props.marketingPatientList}
                  marketingSegmentAgeGender={props.marketingSegmentAgeGender}
                  marketingSegmentTopPost={props.marketingSegmentTopPost}
                  marketingSegmentTopPromotion={
                    props.marketingSegmentTopPromotion
                  }
                  marketingCreatePatient={props.marketingCreatePatient}
                  showSegment={props.showSegment}
                />
              </div>
            ),
          },
          {
            name: (
              <MenuIcon
                label="Promotion"
                icon="/images/expansion/fontisto_shopping-sale.png"
              />
            ),
            key: "promotion",
            render: () => (
              <div>
                <FormattedMessage id="bplusClinicKey401" />
              </div>
            ),
          },
          {
            name: (
              <MenuIcon label="Post" icon="/images/expansion/mdi_post.png" />
            ),
            key: "post",
            render: () => (
              <Post
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // data
                tagList={props.tagList}
                postList={props.postList}
                selectedPostIndex={props.selectedPostIndex}
              />
            ),
          },
          {
            name: (
              <MenuIcon
                label="User Management"
                icon="/images/expansion/user-plus.png"
              />
            ),
            key: "User Management",
            render: () => (
              <UserManagementMom
                // function
                onEvent={props.onEvent}
                // data
                loadingStatus={props.loadingStatus}
                loadingPatientSelection={props.loadingPatientSelection}
                loadingBabyRelationship={props.loadingBabyRelationship}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                selectedDivision={props.selectedDivision}
                clinic={props.clinic}
                // Usermanager Patient Selection
                filterKeyword={props.filterKeyword}
                dmMomSegmentList={props.dmMomSegmentList}
                dmMomPatientList={props.dmMomPatientList}
                // Usermanager WebUMBabyRelationship
                babyRelationFilter={props.babyRelationFilter}
                familyOptions={props.familyOptions}
                relationPatient={props.relationPatient}
                relationProfiles={props.relationProfiles}
                selectedBabySegment={props.selectedBabySegment}
                babySegmentList={props.babySegmentList}
                babyList={props.babyList}
                // User manager vaccine
                vaccineUMDetail={props.vaccineUMDetail}
                vaccineUMList={props.vaccineUMList}
                vaccineSeriesChoice={props.vaccineSeriesChoice}
                orderItemVaccineUM={props.orderItemVaccineUM}
                ageRangeChoice={props.ageRangeChoice}
                ageRangeDetail={props.ageRangeDetail}
                ageRangeList={props.ageRangeList}
                // User manager development
                developmentChoice={props.developmentChoice}
                developmentUMDetail={props.developmentUMDetail}
                developmentUMList={props.developmentUMList}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

MainMom.defaultProps = MainMomInitial;

export default React.memo(MainMom);
