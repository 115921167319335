import React, { CSSProperties, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import StepButton from "@mui/material/StepButton";
import Step from "@mui/material/Step";
import { Button, Grid, Header, Image } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const styles = {
  textMain: {
    color: "var(--text-dark-blue)",
    textDecoration: "underline #eb000e",
  } as CSSProperties,
  textSubMain: {
    marginTop: "0px",
    color: "var(--text-grey)",
  } as CSSProperties,
  textStep: {
    margin: "30px",
    padding: "20px",
    textAlign: "center",
    background: "#fbfcfd",
  } as CSSProperties,
};

// ----- Sub <Step>
type KYCStepByStepProps = {
  imageSrc: any;
  stepText: string;
  headerText: string;
  subText: string;
};

const KYCStepByStep: React.FC<KYCStepByStepProps> = (props) => {
  return (
    <>
      <Header as="h2" textAlign="center" style={styles.textMain}>
        <FormattedMessage id="bplusClinicKey522" />
      </Header>
      <Header sub textAlign="center" style={{ color: "var(--text-grey)" }}>
        <FormattedMessage id="bplusClinicKey543" />
      </Header>
      <Header sub textAlign="center" style={styles.textSubMain}>
        <FormattedMessage id="bplusClinicKey581" />
      </Header>
      <div style={styles.textStep}>
        <Image centered src={props.imageSrc} />
        <Header
          as="h4"
          textAlign="center"
          style={{ color: "var(--text-dark-grey)" }}
        >
          {props.stepText}
        </Header>
        <Header
          as="h4"
          textAlign="center"
          style={{ marginTop: "20px", color: "var(--text-dark-grey)" }}
        >
          {props.headerText}
        </Header>
        <Header as="h2" content="" subheader={props.subText} />
      </div>
    </>
  );
};

// ----- Main
const params = new URLSearchParams(window.location.search);
const app = params.get("app");

type KYCStepProps = {
  history: any;
};

const KYCStepInitial: KYCStepProps = {
  history: {},
};

const KYCStep: React.FC<KYCStepProps> = (props) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeStep === 3) {
      handleComplete();
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    props.history.push(`/verify-identification/?app=${app}`);
  };

  return (
    <div style={{ padding: "30px 20px" }}>
      {activeStep === 0 && (
        <KYCStepByStep
          imageSrc="/images/expansion/idcard.png"
          stepText="STEP 1"
          headerText="Taking a photo of your ID card"
          subText="Position the front and turn your ID Card in the frame. Press button to take a photo"
        />
      )}
      {activeStep === 1 && (
        <KYCStepByStep
          imageSrc="/images/expansion/selfie.png"
          stepText="STEP 2"
          headerText="Take a picture of straight face"
          subText="Position your face within the frame"
        />
      )}
      {activeStep === 2 && (
        <KYCStepByStep
          imageSrc="/images/expansion/journal_check.png"
          stepText="STEP 3"
          headerText="Check information"
          subText="Please review your information before submit"
        />
      )}

      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {Array(3)
            .fill("")
            .map((item: any, index: number) => {
              return (
                <Step key={index}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                  ></StepButton>
                </Step>
              );
            })}
        </Stepper>

        <Grid style={{ padding: "40px 20px" }}>
          <Grid.Row centered columns={2} style={{ padding: "0px" }}>
            <Grid.Column>
              <Button fluid color="blue" onClick={handleNext}>
                <FormattedMessage id="bplusClinicKey335" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={2}>
            <Grid.Column>
              <Button fluid inverted color="blue" onClick={handleComplete}>
                <FormattedMessage id="bplusClinicKey458" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Box>
    </div>
  );
};

KYCStep.defaultProps = KYCStepInitial;

export default React.memo(KYCStep);
