import React, { useState, useMemo, useEffect } from "react";
// import { createMuiTheme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import MobHeadApp from "bplus-lib/appointment/MobHeadApp";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Button as ButtonMui } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import lightBlue from "@material-ui/core/colors/lightBlue";
import {
  Card,
  Modal,
  Icon,
  Dimmer,
  Loader,
  Button,
  Form,
} from "semantic-ui-react";
import { prepareForm, appendValid } from "react-lib/apps/IsHealth/Common/Diag";
import { COLOR } from "./MyCoupon";
import MobCouponQuestion from "./MobCouponQuestion";
import { evaluateNextAppointment, SuggestInject } from "./EvaluateAppointment";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

/***********************************************************************************************
 *
 * Hard code logic
 *
 * *********************************************************************************************/

export const combineQuestion = [
  "คุณเคยได้รับวัคซีน COVID-19 มาแล้วกี่เข็ม",
  "How many doses of COVID-19 Vaccine have you got?",
];

export const answerInjectDate = ["ระบุวันที่ฉีด", "injection date"];
export const answervaccineBrand = ["เข็มที่ ", "Dose "];
export const answerCovidInfact = [
  "ระบุวันที่รู้ผลครั้งแรก",
  "First result date",
];
export const questionPregnantWeekText = [
  "กรุณาระบุอายุครรภ์",
  "Please specify gestational age",
];
export const questionCovidText = [
  "คุณเคยป่วยเป็น COVID-19 ใช่หรือไม่",
  "Have you ever been infected with COVID-19?",
];
export const questionVaccineCountText = [
  "คุณเคยได้รับวัคซีน COVID-19 มาแล้วกี่เข็ม",
  "How many doses of COVID-19 Vaccine have you got?",
];
export const weekText = ["สัปดาห์", "Week"];
export const yesText = ["ใช่", "Yes"];

/***********************************************************************************************/
// Utility
/***********************************************************************************************/

export const filterVaccineExtraAnswer = (answer: any, subCount: any): any[] => {
  let currentLabelIsContainDose =
    [...answerInjectDate, ...answervaccineBrand].find((item: any) =>
      answer[subCount].label?.includes(item)
    ) || false;
  // console.log('currentLabelIsContainDose: ', currentLabelIsContainDose);
  let injectAnswerList = [];
  if (!!currentLabelIsContainDose) {
    injectAnswerList = answer
      .map((item: any, idx: any) => {
        item.arrayIdx = idx;
        return item;
      })
      .filter((item: any) => {
        let find =
          [...answerInjectDate, ...answervaccineBrand].find((ans: any) =>
            item.label?.includes(ans)
          ) || [];
        if (find?.length > 0) {
          return true;
        } else {
          return false;
        }
      });
  }

  return injectAnswerList;
};

export const isTextInside = (text: string, list: string[]) => {
  let found = list.find((item) => item.includes(text));
  if (found) {
    return true;
  } else {
    return false;
  }
};

/***********************************************************************************************
 *
 *
 * MobCouponEvaluate
 *
 *
 ***********************************************************************************************/

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.dark_blue,
    },
    secondary: {
      main: COLOR.dark_blue,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px 0 18px 0",
  },
}));

const MobCouponEvaluate = (props: any) => {
  const [answer, setAnswer] = useState(null);

  const [countLevel, setCountLevel] = useState(0);
  const [totalLevel, setTotalLevel] = useState(1);
  const [subCountLevel, setSubCountLevel] = useState(0);
  const [injectCovidCount, setInjectCovidCount] = useState(0); // NaN เลือก ยังไม่ได้ฉีด , 0 ยังไม่ถึงคำถาม , 1;2;3 จำนวนเข็มที่ฉีด
  const [modInjection, setModInjection] = useState<any>({
    open: false,
    text: "",
    buttonName: "",
  });
  const [isEvaluated, setIsEvaluated] = useState(false);

  const [displaySuggest, setDisplaySuggest] = useState<SuggestInject | null>(
    null
  );
  const [genderUser, setGenderUser] = useState<string>("");

  const classes = useStyles();

  console.log("History ModCouponEvaluate:", props.history);
  console.log(" MobCouponEvaluate render ", props);

  useEffect(() => {
    if (props.evaluateForm) {
      //console.log(" props.evaluateForm ", props.evaluateForm);
      let prepareAnswer = prepareForm(props.evaluateForm);
      setAnswer(prepareAnswer);
      //console.log("prepareAnswer", prepareAnswer);

      let prepare = [];
      if ("[object Object]" === Object.prototype.toString.call(prepareAnswer)) {
        for (const [key, value] of Object.entries(prepareAnswer)) {
          prepare.push(value);
        }
      }
      //console.log("prepare: ", prepare);
      let levelList = prepare.map((item: any) => parseInt(item.level));
      //console.log("levelList: ", levelList);
      let max = Math.max(...levelList);
      //console.log("max: ", max);
      setTotalLevel(max);
    }
  }, [props.evaluateForm]);

  useEffect(() => {
    if (
      props.history?.location?.state &&
      Object.keys(props.history.location.state).length > 0
    ) {
      // console.log("history location has state")
      if (props.history.location.state.patientDetail?.gender !== undefined) {
        // เลือกนัดให้ผู้อื่นให้ใช้ gender ของ patientForSomeElse, นัดให้ตัวเองใช้ patientDetail
        const patient = props.history.location.state.book_for_some_else
          ? props.history.location.state.patientForSomeElse
          : props.history.location.state.patientDetail;

        if (patient.gender === 1) {
          setGenderUser("M");
        } else if (patient.gender === 2) {
          setGenderUser("F");
        } else {
          setGenderUser("U");
        }
        // let extraPatient = JSON.parse(
        //   props.history.location.state.patientDetail.extra
        // );
        // // console.log("Extra Patient", extraPatient)
        // if (extraPatient && extraPatient["gender"]) {
        //   const genderType: any = {
        //     ชาย: "Male",
        //     หญิง: "Female",
        //     Male: "Male",
        //     Female: "Female",
        //   };
        //   let gender =
        //     genderType[extraPatient["gender"]] ||
        //     extraPatient["gender"].split("(")?.[1]?.replace(")", "")?.trim();

        //   console.log("Find gender", gender);
        //   if (gender === "Male") {
        //     setGenderUser("M");
        //   } else if (gender === "Female") {
        //     setGenderUser("F");
        //   } else {
        //     setGenderUser("U");
        //   }
        // }
      } else {
        setGenderUser("U");
      }
    }
  }, [props.history]);
  // //console.log("evaluateContent ", evaluateContent)
  // console.log("Gender user", genderUser)

  const handleSaveDiagForm = () => {
    props.onEvent({
      message: "handleSaveDiagForm",
      params: {
        answers: answer,
        patientId: props.history.location.state.patientDetail.patient || null,
        publishedId: props.evaluateForm.id,
      },
    });
  };

  const handleFinishEvaluate = (id: any, lang: string) => {
    let prepare = Object.assign({}, answer);
    prepare[id].extra_finished = true;
    setAnswer(prepare);

    // save diag form here
    handleSaveDiagForm();

    // console.log("handleFinishEvaluate answer: " ,answer)
    let nextAppointmentDate = evaluateNextAppointment(answer, lang);
    setDisplaySuggest(nextAppointmentDate);
    setIsEvaluated(true);

    // const [res, error] = await props.onEvent({ message: "getPatientCouponCheck", params: { hospital: 1, couponId: item.coupon_id } })
    // console.log(" nextAppointmentDate ", nextAppointmentDate)
  };

  const handleNavigateToAppointment = (nextAppointmentDate: moment.Moment) => {
    const stateHistory = props.history?.location?.state;
    if (
      props.history?.location?.state &&
      Object.keys(props.history?.location?.state).length > 0 &&
      nextAppointmentDate !== null
    ) {
      // const stateHistory = props.history?.location?.state;
      props.history.push({
        pathname: `/?app=${"MobAppointment"}&product_code=${
          stateHistory.product_code
        }`,
        state: {
          product_code: stateHistory.product_code,
          product_name: stateHistory.product_name,
          coupon_number: stateHistory.coupon_number,
          patientDetail: stateHistory.patientDetail,
          coupon_code: stateHistory.coupon_code,
          start_appointment_date: nextAppointmentDate
            ?.locale("en")
            .format("DD/MM/YYYY"),
          start_inject_date: displaySuggest?.canInjectDate?.locale("en").format('DD/MM/YYYY'),
          showTopbar: props.showTopbar,
          add_coupon: stateHistory.add_coupon,
          infection_date: displaySuggest?.infection_date,
          injected_dose: displaySuggest?.doseCount,
          coupon_expired_date: stateHistory.coupon_expired_date,
          book_for_some_else: stateHistory.book_for_some_else,
          diag_form: stateHistory.diag_form,
          patientForSomeElse: stateHistory.patientForSomeElse,
        },
      });

      globalThis.history.go();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {props.evaluateForm ? (
        <>
          <MobHeadApp
            hideBackButton={!props.showTopbar}
            subTitle={"แบบคัดกรองก่อนการฉีดวัคซีน"}
            title={"Covid-19 (Moderna)"}
            titleColor={"#0147A3"}
            leftIconClick={() => props.history.goBack()}
          />
          <MobCouponQuestion
            isEvaluated={isEvaluated}
            displaySuggest={displaySuggest}
            onToAppointment={handleNavigateToAppointment}
            answer={answer}
            countLevel={countLevel}
            subCountLevel={subCountLevel}
            max={totalLevel}
            injectCovidCount={injectCovidCount}
            selectedChoice={({ choiceId }) => {
              let countPlus = 0;
              let prepare = Object.assign({}, answer);
              let parentId = prepare[choiceId].parent_id;
              let condition = prepare[choiceId].condition;
              let nodeAnswerThree = null;
              // console.log("answer: ", prepare[parentId]);
              // console.log("choiceId: ", choiceId);

              // Unselected All
              let clearLevel = prepare[choiceId].level;
              for (const [key, value] of Object.entries(prepare)) {
                if (value.level === clearLevel) {
                  prepare[value.id].selected = false;
                }
              }

              // Then Select
              prepare[parentId].answer = condition;
              prepare[choiceId].selected = true;

              // pass diag form question 3 change it when diag form change
              if (countLevel + 1 === 2 && prepare[choiceId].type !== "end") {
                countPlus = 1;
                let childNodeTarget = {};
                let childNode = Object.entries(prepare).map((item) => {
                  if (item[1]?.parent_id === choiceId) {
                    // ======= this is hard code no way to detect now !!! ======
                    if (
                      [
                        "no",
                        "not",
                        "not specified",
                        "ไม่ใช่",
                        "ไม่ระบุ",
                      ].includes(item[1]?.condition.toLowerCase().trim())
                    ) {
                      console.log("Target item:", item[1]);
                      childNodeTarget = item[1];
                    }
                    return item[1].id;
                  }
                });

                for (const [key, value] of Object.entries(prepare)) {
                  if (value.level === childNodeTarget.level) {
                    prepare[value.id].selected = false;
                  }
                }
                if (
                  childNodeTarget &&
                  Object.keys(childNodeTarget).length > 0
                ) {
                  //parent answer
                  prepare[choiceId].answer = condition;
                  // selected choice
                  prepare[childNodeTarget.id].selected = true;

                  // set child node for answer four
                  nodeAnswerThree = childNodeTarget;
                }
              }

              // change this implement when diag form change
              if (
                genderUser === "M" &&
                countLevel + 1 + countPlus === 3 &&
                prepare[choiceId].type !== "end"
              ) {
                console.log("next Data:", choiceId);
                countPlus = countPlus + 1;
                let childNodeTarget = {};
                let childNode = Object.entries(prepare).map((item) => {
                  if (item[1]?.parent_id === nodeAnswerThree.id) {
                    // ======= this is hard code no way to detect now !!! ======
                    if (
                      ["no", "ไม่ใช่", "ไม่"].includes(
                        item[1]?.condition.toLowerCase().trim()
                      )
                    ) {
                      console.log("Target item:", item[1]);
                      childNodeTarget = item[1];
                    }
                    return item[1].id;
                  }
                });

                for (const [key, value] of Object.entries(prepare)) {
                  if (value.level === childNodeTarget.level) {
                    prepare[value.id].selected = false;
                  }
                }
                if (
                  childNodeTarget &&
                  Object.keys(childNodeTarget).length > 0
                ) {
                  //parent answer
                  prepare[nodeAnswerThree.id].answer = condition;
                  // selected choice
                  prepare[childNodeTarget.id].selected = true;
                }
              }

              setTimeout(() => {
                setCountLevel(countLevel + 1 + countPlus);
              }, 500);
              setAnswer(prepare);
            }}
            selectedExtra={({ id, arrayIdx, label, value, injectCount }) => {
              //console.log(" selectedExtar !!", id, arrayIdx, label, value);
              let prepare = Object.assign({}, answer);
              console.log("prepare: ", prepare);
              if (arrayIdx !== null) {
                // Normal selected Extra
                if (prepare[id].extra_items[arrayIdx].label === label) {
                  console.log("Replace ");
                  // Just reject it's same
                  console.log("A ", prepare[id].extra_items[arrayIdx].value);
                  prepare[id].extra_items[arrayIdx].value = value;
                  console.log(" ", prepare[id].extra_items[arrayIdx].value);
                }
                if (injectCount !== undefined) {
                  setInjectCovidCount(injectCount);
                }
              } else {
                // Inject Date (no arrayIdx need to find from label)
                let targetIdx = prepare[id].extra_items.findIndex(
                  (item) => item.label === label
                );
                console.log("targetIdx: ", targetIdx);
                prepare[id].extra_items[targetIdx].value = value;
                console.log("prepare[id]: ", prepare[id]);
                console.log("prepare: ", prepare);
              }
              // ถ้าไม่ใช่ อันสุดท้าย จะไม่ขยับ
              // console.log("userChoose !!!!  ", userChoose)
              // if (userChoose) {

              // หา ว่ามัน เติมอันสุดท้าย หรือไม่ ถ้าไม่ Auto Next
              // ถ้าอันสุดท้าย รอ ให้ผู้ใช้กด Finish

              let firstIdxOfDose = prepare[id].extra_items.findIndex(
                (item: any) =>
                  isTextInside(
                    item.label.trim().split(" ")[0],
                    answervaccineBrand
                  )
              );
              let idxOfCovidQues = prepare[id].extra_items.findIndex(
                (item: any) => isTextInside(item.label, questionCovidText)
              );
              console.log("idxOfCovidQues: ", idxOfCovidQues);
              // console.log('injectCount: ', injectCount);
              // console.log('firstIdxOfDose: ', firstIdxOfDose);
              // console.log('arrayIdx: ', arrayIdx);

              if (
                arrayIdx >= firstIdxOfDose ||
                (isNaN(injectCount) && arrayIdx + 1 === firstIdxOfDose)
              ) {
                // คำถามสุดท้ายของวัคซีน หรือ คำถามก่อน คำถามสุดท้ายตอบว่าไม่ได้ฉีดวัคซีน คือ จบไม่ต้อง autoNext
                console.log("do nothing");
              } else if (
                arrayIdx === idxOfCovidQues &&
                !yesText.includes(value)
              ) {
                // ตอบ ว่าไม่ใช่ ข้าม 2 step
                console.log("do two steps");
                setTimeout(() => {
                  setSubCountLevel(subCountLevel + 2);
                }, 500);
              } else {
                setTimeout(() => {
                  setSubCountLevel(subCountLevel + 1);
                }, 500);
              }

              setAnswer(prepare);
            }}
            nextQuestion={() => {
              //console.log(" nextQuestion ");
              setCountLevel(countLevel + 1);
            }}
            nextExtraQuestion={() => {
              //console.log(" nextExtraQuestion ");
              setSubCountLevel(subCountLevel + 1);
            }}
            backQuestion={() => {
              //console.log(" nextQuestion ");
              if (countLevel > 0) {
                if (genderUser === "M" && countLevel === 4) {
                  setCountLevel(countLevel - 3);
                } else if (countLevel === 3) {
                  setCountLevel(countLevel - 2);
                } else {
                  setCountLevel(countLevel - 1);
                }
              }
            }}
            backExtraQuestion={({ id }: { id: number }) => {
              //console.log(" nextExtraQuestion ");
              if (subCountLevel > 0) {
                let idxOfCovidQues = answer[id].extra_items.findIndex(
                  (item: any) => isTextInside(item.label, questionCovidText)
                );
                let answerOfCovidQues =
                  answer[id].extra_items[idxOfCovidQues].value;
                if (
                  !yesText.includes(answerOfCovidQues) &&
                  subCountLevel === idxOfCovidQues + 2
                ) {
                  setSubCountLevel(subCountLevel - 2);
                } else {
                  setSubCountLevel(subCountLevel - 1);
                }
                setDisplaySuggest(null);
              }
            }}
            setModInjectionCancel={setModInjection}
            finishEvaluate={handleFinishEvaluate}
          />
          <Modal
            size="tiny"
            open={modInjection.open}
            style={{ padding: "0px 10px 15px 5px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginRight: "-30px",
                }}
              >
                <Icon
                  name="cancel"
                  size="big"
                  onClick={() => {
                    setModInjection({ open: false, text: "", buttonName: "" });
                    handleSaveDiagForm();
                    props.history.push("/?app=MobCoupon");
                  }}
                />
              </div>
              <div>
                <img src="/images/chat_alert.png" height="150px" />
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: "20px",
                  textAlign: "center",
                  padding: "15px 5px",
                  lineHeight: "1.3em",
                }}
              >
                {modInjection.text}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "0.5px solid red",
                  }}
                  content={modInjection.buttonName}
                  onClick={() => {
                    setModInjection({ open: false, text: "", buttonName: "" });
                    handleSaveDiagForm();
                    props.history.push("/?app=MobCoupon");
                  }}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <Dimmer active={true} inverted>
          <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
        </Dimmer>
      )}
    </ThemeProvider>
  );
};

export default MobCouponEvaluate;
