import React, { CSSProperties, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";

import moment from "moment";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  Grid,
  Icon,
  Image,
  Divider,
  Button,
  Item,
  Dimmer,
  Modal,
} from "semantic-ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./MedicationCSS.scss";
import { Global } from "@emotion/react";
import { Medication } from "@mui/icons-material";
import { TramRounded } from "@material-ui/icons";

//type
import { medicationType, historyType } from "../MobMedicationInterface";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  lightBlueBDMS: "#0072BC",
  lightBlue: "lightBlue",
  super_darkgray: "#393939",
  darkgrayHos: "#797878",
  orange: "#DE7800",
  super_red: "#DA0000",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  card_shadow: {
    borderRadius: "10px",
    boxShadow: "0px 2px 3px #c4c4c4",
    background: COLOR.font_white,
    margin: "5px",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    margin: "0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  text_lightBlue: {
    margin: "5px 0 0 0 ",
    fontSize: "14px",
    color: COLOR.lightBlueBDMS,
  } as CSSProperties,
  text_lightBlueBDMS_bold: {
    margin: "5px 0 0 0 ",
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.lightBlueBDMS,
  } as CSSProperties,
  titletext_bole: {
    width: "90%",
    overflow: "hidden",
    fontSize: "16px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "2px",
    color: COLOR.primary,
    fontWeight: "bold",
  } as CSSProperties,
  text_bluebdms_bold: {
    margin: "0 0 0 10px ",
    fontSize: "14px",
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    color: COLOR.primary,
  } as CSSProperties,
  text_bluebdms_16: {
    margin: "5px 0 0 0 ",
    fontSize: "16px",
    alignItems: "center",
    display: "flex",
    color: COLOR.primary,
  } as CSSProperties,
  text_DarkGray_Line: {
    margin: "5px 0 10px 0 ",
    fontSize: "14px",
    alignItems: "center",
    display: "flex",
    color: COLOR.DarkGray_Line,
    textDecorationLine: "underline",
  } as CSSProperties,
  normaltext: {
    fontSize: "14px",
    marginBottom: "3px",
    color: COLOR.darkgrayHos,
  } as CSSProperties,
  divIcon: {
    display: "flex",
    justifyContent: "center",
    margin: "25px 0 10px 0",
    alignItems: "center",
  } as CSSProperties,
  division_detail: {
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  checkbox: {
    margin: " 5px 0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: COLOR.primary,
  } as CSSProperties,
  display_center: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  border_select: {
    position: "absolute",
    background: "white",
    padding: "0 10px",
    color: COLOR.orange,
    fontWeight: "bold",
    fontSize: "14px",
  } as CSSProperties,
};

type SelectDoseProps = {
  onClose: Function;
  onEvent: (e: any) => any;
  medicationItem: medicationType;
  selectedHospital: any;
};

const SelectDose: React.FC<SelectDoseProps> = (props) => {
  const [openYoutube, setOpenYoutube] = useState<boolean>(false);
  const [accordion, setAccordion] = useState<boolean>(false);
  const [includeOff, setIncludeOff] = useState<boolean>(true);
  const [medication, setMedication] = useState<any>({});

  useEffect(() => {
    if (props.medicationItem !== undefined) {
      setMedication(props.medicationItem);
    }
  }, [props.medicationItem]);

  const handleChange = (accordion: boolean) => {
    console.log("saika ;) handleChange ");
    setAccordion(!accordion);
  };

  const handleOpenLink = (url: string, type: string) => {
    props.onEvent({
      message: "handleOpenLink",
      params: { url: url, type: type },
    });
  };

  const handleDownloadFile = () => {
    // const url = "apis/PRX/v3/lab_report_file/?en="+latestVisitNumber+"&hospital="+props.selectedHospital?.code;

    console.log("globalThis", globalThis);
    console.log("----- typeof globalThis.mobile", typeof globalThis.mobile);

    // props.onEvent({
    //   message: "handleDownloadLabResult",
    //   params: { url: url, filename: `.pdf` },
    // });

    // handleCloseModConfirm();
  };

  const handleUpdateState = (current: any, index: any) => {
    var medicationTmp = medication;
    // console.log(
    //   "saika ;) encounter:",
    //   medicationTmp.history[index].encounter,
    //   ",drug_id:",
    //   medicationTmp.id,
    //   ",state:",
    //   current
    // );
    if (current === "ON") {
      medicationTmp.history[index].state = "ON";

      props.onEvent({
        message: "handleUpdateCurrentMed",
        params: {
          encounter: medicationTmp.history[index].encounter,
          drug_id: medicationTmp.id,
          state: current,
        },
      });
    } else {
      medicationTmp.history[index].state = "OFF";
      props.onEvent({
        message: "handleUpdateCurrentMed",
        params: {
          encounter: medicationTmp.history[index].encounter,
          drug_id: medicationTmp.id,
          state: current,
        },
      });
    }
    console.log("saika ;) medicationTmp ", medicationTmp);
    setMedication(medicationTmp);
  };

  return (
    <div style={{ padding: "10px 10px 30px 10px" }}>
      <div style={{ position: "absolute" }}>
        <Button
          icon
          size="large"
          onClick={props.onClose}
          style={styles.card_shadow}
        >
          <Icon name="angle left" />
        </Button>
      </div>
      <div style={{ ...styles.parentbox }}>
        <div style={{ margin: " 25px 0 10px" }}>
          <div style={{ ...styles.title, margin: "0px 10px" }}>
            {"Select Dose"}
            {/* <FormattedMessage id="bplusClinicKey937" /> */}
          </div>
          <Divider
            style={{
              background: "red",
              marginTop: "5px",
              marginBottom: "5px",
            }}
            variant="middle"
          />
        </div>
      </div>

      <div className="medication-selectDose">
        <Accordion
          style={{
            borderRadius: "10px",
            boxShadow: "none",
            padding: "0px 25px",
          }}
          expanded={accordion}
          onChange={() => handleChange(accordion)}
        >
          <AccordionSummary
            aria-controls={`panela-content`}
            id={`panela-header`}
          >
            {/* medication.nodata field field ที่ยังไม่ได้แอดเข้ามาแต่ต้องมี */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ ...styles.text_bluebdms_16, fontWeight: "bold" }}>
                {medication.name || "-"}
              </div>
              <div style={styles.text_bluebdms_16}>
                {medication.nodata || "-"}
              </div>
              <div style={styles.text_lightBlueBDMS_bold}>Indication : </div>
              <div style={styles.text_lightBlue}>
                {medication.indication || "-"}
              </div>
              <div
                style={{
                  ...styles.text_DarkGray_Line,
                  display: accordion ? "none" : "flex",
                }}
              >
                <div>{"More Detail"}</div>
                <div>
                  <Icon name="angle right" />
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div onClick={() => handleChange(accordion)}>
                <div style={styles.text_lightBlueBDMS_bold}>Side effect : </div>
                <div style={styles.text_lightBlue}>
                  {medication.med_side_effect || "-"}
                </div>
                <div style={styles.text_lightBlueBDMS_bold}> Precaution : </div>
                <div style={styles.text_lightBlue}>
                  {medication.med_need_before || "-"}
                </div>
                <div style={styles.text_lightBlueBDMS_bold}>Storage :</div>
                <div style={styles.text_lightBlue}>
                  {medication.med_store || "-"}
                </div>
                <div style={styles.text_lightBlueBDMS_bold}>Description : </div>
                <div style={styles.text_lightBlue}>
                  {medication.med_detail || "-"}
                </div>
              </div>
              <div style={styles.divIcon}>
                <div
                  style={{ width: "100%", display: "flex" }}
                  onClick={() =>
                    handleOpenLink(
                      props.medicationItem?.media_link || "",
                      "video"
                    )
                  }
                >
                  <div style={{ width: "48.75px" }}>
                    <Image src="/images/icon_youtube.png" />
                  </div>
                  <div style={styles.text_bluebdms_bold}>How to use</div>
                </div>
                <div
                  style={{ width: "100%", display: "flex" }}
                  onClick={() => {
                    handleOpenLink(
                      props.medicationItem?.pi_my_med_url || "",
                      "link"
                    );
                  }}
                >
                  <div style={{ width: "34px", marginLeft: "20px" }}>
                    <Image src="/images/icon_document.png" />
                  </div>
                  <div style={styles.text_bluebdms_bold}>Drug Fact</div>
                </div>
              </div>
            </div>
            <div
              style={{
                ...styles.text_DarkGray_Line,
                justifyContent: "flex-end",
                display: accordion ? "flex" : "none",
              }}
              onClick={() => handleChange(accordion)}
            >
              <div>{"Hide "}</div>
              <div>
                <Icon name="angle up" />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={styles.checkbox} onClick={() => setIncludeOff(!includeOff)}>
        <Checkbox
          defaultChecked
          checked={includeOff}
          sx={{
            color: COLOR.primary,
            "&.Mui-checked": {
              color: COLOR.primary,
            },
          }}
        />
        <label>{"Included OFF"}</label>
      </div>
      {medication.history ? (
        medication.history.map((item: historyType, index: number) => (
          <>
            {(item.state === "" ? true : item.state === "ON" ? true : false) ||
            includeOff ? (
              <div style={styles.card_shadow} key={index}>
                <Grid
                  style={{
                    margin: "0",
                    padding: "10px 10px 0px 10px",
                  }}
                >
                  <Grid.Column
                    width={3}
                    style={{
                      padding: "0",
                    }}
                  >
                    <div style={styles.display_center}>
                      <Image
                        src={
                          item.doctor_image
                            ? item.doctor_image
                            : "/images/Feed/doctor.png"
                        }
                        style={{
                          width: "58px",
                          height: "58px",
                          borderRadius: "50px",
                          marginTop: "16px",
                        }}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    width={13}
                    style={{
                      padding: "0 0 0 10px",
                    }}
                  >
                    <div
                      style={{
                        ...styles.titletext_bole,
                        display: "inline-block",
                      }}
                    >
                      {item.doctor || "-"}
                    </div>
                    <div style={styles.normaltext}>
                      {medication?.hospital_name ||
                        props.selectedHospital?.name ||
                        ""}
                    </div>
                    <div style={styles.normaltext}>
                      {item.order_date
                        ? moment(item.order_date).format("DD MMM YYYY hh:mm")
                        : "-"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          ...styles.normaltext,
                          color: "black",
                        }}
                      >
                        {item?.note?.medication || "-"}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          ...styles.normaltext,
                          color: COLOR.super_darkgray,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {"Qty : "}
                      </div>
                      <div
                        style={{
                          ...styles.normaltext,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        { (item.ph_qty_ord || "").length > 0 ? `X ${item.ph_qty_ord} ${item.unit}`: "-"}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          ...styles.normaltext,
                          color: COLOR.super_darkgray,
                        }}
                      >
                        {"Exp : "}
                      </div>
                      <div style={styles.normaltext}>
                        {item?.exp
                          ? moment(item?.exp).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
                <div style={styles.display_center}>
                  <div style={{ marginBottom: "15px" }}>
                    <div
                      style={{
                        ...styles.display_center,
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          ...styles.border_select,
                          display: item.state === "" ? "" : "none",
                        }}
                      >
                        Please select
                      </div>
                    </div>
                    <div
                      style={{
                        border:
                          item.state === "" ? `1px solid ${COLOR.orange}` : "",
                        padding: "15px 13px 8px",
                      }}
                    >
                      <Button
                        style={{
                          background:
                            item.state === ""
                              ? ""
                              : item.state === "OFF"
                              ? COLOR.super_red
                              : "",
                          color:
                            item.state === ""
                              ? ""
                              : item.state === "OFF"
                              ? COLOR.font_white
                              : "",
                          borderRadius: "10px",
                          padding: "10px 15px",
                          fontSize: "12px",
                        }}
                        onClick={() => handleUpdateState("OFF", index)}
                      >
                        OFF
                      </Button>
                      <Button
                        style={{
                          background:
                            item.state === ""
                              ? ""
                              : item.state === "ON"
                              ? COLOR.primary
                              : "",
                          color:
                            item.state === ""
                              ? ""
                              : item.state === "ON"
                              ? COLOR.font_white
                              : "",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          marginLeft: "15px",
                          fontSize: "12px",
                        }}
                        onClick={() => handleUpdateState("ON", index)}
                      >
                        ON
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(SelectDose);

const dataItem = [
  {
    name: "Dr. PAT PHATRAPAKDEE",
    department: "(BHQ) Diabetes and Endocrine...",
    date: "09/11/2022/10:30",
    detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
    status: undefined,
    qty: "10 Tablet",
    exp: "10/12/2025",
  },
  {
    name: "Dr. PAT PHATRAPAKDEE",
    department: "(BHQ) Diabetes and Endocrine...",
    date: "10/11/2022/10:30",
    detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
    status: true,
    qty: "10 Tablet",
    exp: "10/12/2025",
  },
  {
    name: "Dr. PAT PHATRAPAKDEE",
    department: "(BHQ) Diabetes and Endocrine...",
    date: "11/11/2022/10:30",
    detail: "Take 1 Tablet 2 Times Daliy AfterBreakfast and Dinner ",
    status: false,
    qty: "10 Tablet",
    exp: "10/12/2025",
  },
];
