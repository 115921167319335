import { BE_DATE_FORMAT } from './../MobClinicInterface';
import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  MEDICATION_PERIOD,
  MEDICATION_STATUS,
  commonCreatePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
  commonGetPatientDataLatest,
  commonCreateUpdatePatientDocumentFile,
  MEDICATION_TIMES,
  State as MainState,
} from "../MobClinicInterface";
import StaffDirectMedicationView from "../../issara-sdk/apis/StaffDirectMedicationView_apps_PRX";
import DirectMedicationView from "../../issara-sdk/apis/DirectMedicationView_apps_PRX";
import DirectEncounterView from "../../issara-sdk/apis/DirectEncounterView_apps_PRX";
import Cookies from "js-cookie";
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";
import StaffDirectEncounterView from "../../issara-sdk/apis/StaffDirectEncounterView_apps_PRX";

export type State = {
  loadingMedication?: boolean;
  drugDataSetting?: any;
  drugDataTabDetail?: any;
  drugManualList?: any;
  drugDataTabSummary?: any;
  drugDataTabHistory?: any;
  medicationTrackcare?: any;
  medicationTrackcareFilter?: any[]; // in here there was medicaiton only
  medicationCurrentList?: any;
  medicationCurrentListFilter?: any;
  medicationShowFilter?: any;
  medicationDoctorManualList?: any;
};

type Picked = Pick<MainState, "loadingMedication" | "loadingStatus" | "errorMessage" | "successMessage" | "selectedPatient" | "language">

export const StateInitial = {
  loadingMedication: false,
  drugDataSetting: {
    is_reminder: false,
    remind_before: null,
    is_morning_reminder: false,
    is_afternoon_reminder: false,
    is_evening_reminder: false,
    is_bedtime_reminder: false,
    is_others_reminder: false,
    morning_before_meal: "",
    morning_after_meal: "",
    afternoon_before_meal: "",
    afternoon_after_meal: "",
    evening_before_meal: "",
    evening_after_meal: "",
    bed_meal: "",
    other_meals: [],
  },
  drugDataTabDetail: {
    // Todo: Remove It
    date: moment().format("DD/MM/YYYY"),
    // is_reminder: false,
    // remind_before: 5,
    // is_morning_reminder: false,
    // is_afternoon_reminder: false,
    // is_evening_reminder: false,
    // is_bedtime_reminder: false,
    // is_others_reminder: false,
    // morning_before_meal: "07:30",
    // morning_after_meal: "09:08",
    // afternoon_before_meal: "11:00",
    // afternoon_after_meal: "13:00",
    // evening_before_meal: "17:30",
    // evening_after_meal: "19:30",
    // bed_meal: "21:30",
    // other_meals: ["01:00", "05:00"],
    sideEffectNote: "",
    morning: {
      warning: false,
      items: [],
    },
    afternoon: {
      warning: false,
      items: [],
    },
    evening: {
      warning: false,
      items: [],
    },
    bedtime: {
      warning: false,
      items: [],
    },
    others: {
      warning: false,
      items: [],
    },
  },
  drugManualList: {
    items: [],
  },
  drugDataTabSummary: {
    // Todo: Remove It
    date: moment().format("DD/MM/YYYY"),
    items: [],
  },
  drugDataTabHistory: {
    summary: {
      title: "",
      detail: "",
      color: "",
    },
    sideEffectNote: "",
    items: [],
  },
  medicationTrackcare: { items: [] },
  medicationTrackcareFilter: [],
  medicationCurrentList: {},
  medicationCurrentListFilter: [],
  medicationShowFilter: {},
  medicationDoctorManualList: {},
};

export type Event =
  | { message: "handleGetDrugDetail"; params: { date: string } }
  | { message: "handleGetDrugSummary"; params: { date: string } }
  | {
    message: "handleGetDrugHistory";
    params: { date?: string; duration: "5_DAY" | "7_DAY" | "10_DAY"; drugShow?: any };
  }
  | { message: "handleGetDrugSetting"; params: {} }
  | { message: "handleGetDrugManual"; params: {} }
  | {
    message: "handleChangeDrugDetail";
    params: { meal: string; index: number; drug: any };
  }
  | { message: "handleSaveRecordTime"; params: { name: string; value: string } }
  | { message: "handleSaveSideEffectNote"; params: { sideEffectNote: string } }
  | { message: "handleConfirmDrugMeal"; params: { meal: string } }
  | {
    message: "handleConfirmNormalDrug";
    params: { meal: string; index: number; drug: any };
  }
  | {
    message: "handleAddManualDrug";
    params: {
      image: string;
      drug_name: string;
      dosage: number;
      unit: any;
      administration_time: [{ time: any; meal: any }];
    };
  }
  | {
    message: "handleActionManualDrug";
    params: { index: number; status: string };
  }
  | {
    message: "handleHoldDrug";
    params: {
      meal: string;
      index: number;
      drug: any;
      holdDateType: string;
      holdEndDate: string;
      holdReason: string;
    };
  }
  | {
    message: "handleContinueDrug";
    params: { meal: string; index: number; drug: any };
  }
  | {
    message: "handleDeleteDrug";
    params: { meal: string; index: number; drug: any };
  }
  | {
    message: "handleSaveAllRecordTime";
    params: {};
  }
  | { message: "getAllTrackCareDrug"; params: {} }
  | {
    message: "updateTrackcareMedication";
    params: { action: string; medId?: any; isActive?: boolean };
  }
  | { message: "saveMedicationTrackCare"; params: { manualDrug?: any } }
  | {
    message: "filterMedicationTrackCare";
    params: {
      start?: string;
      end?: string;
      drugName?: string;
      clinic?: string;
      doctor?: string;
      showType?:
      | "medicationTrackcareFilter"
      | "medicationCurrentListFilter"
      | null;
    };
  }
  | { message: "getCurrentDrugList"; params: {} };

type Handler = WasmHandler<State & Picked, Event>;

// ----- Get
export const handleGetDrugDetail: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const state = controller.getState();

  // get data from trackcare
  let result: any = {};
  let directDrugResult: any = {};
  let drugTC: any = [];
  let manualDrugResult: any = {};
  let errorMessage: any = [];
  let encounterTrackcare: any = {};
  let currentMedResult: any = {};
  let drugList: any = [];

  const [resEncounter, errEncounter] = await DirectEncounterView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
    },
  });
  if (errEncounter) {
    errorMessage.push(errEncounter);
  } else {
    // set patientEpisode Data
    if (resEncounter?.results && resEncounter.results.length > 0) {
      resEncounter.results.forEach((item: any) => {
        encounterTrackcare[item.number] = {
          doctor: item.doctor_name,
          clinic: item.division_name,
        };
      });
    }
  }

  // Medication on TrackCare
  const [resTC, errTC] = await DirectMedicationView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      // start_date, end_date
    },
  });

  // console.log("resTC", resTC)
  if (errTC) {
    errorMessage.push(errTC);
  } else {
    if (resTC?.items.length > 0) {
      drugTC = [...resTC.items].map((item) => {
        if (encounterTrackcare[item.encounter_number]) {
          if (!item.doctor) {
            return {
              ...item,
              clinic: encounterTrackcare[item.encounter_number].clinic,
              doctor: encounterTrackcare[item.encounter_number].doctor,
              active: false,
            };
          }
          return {
            ...item,
            clinic: encounterTrackcare[item.encounter_number].clinic,
            active: false,
          };
        }

        return { ...item, clinic: "", active: false };
      });
    }
  }

  const [errCurrent, resCurrent] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    }
  );

  if (errCurrent && errCurrent.length > 1) {
    errorMessage.push(errCurrent);
  } else {
    if (resCurrent?.measure_date) {
      currentMedResult = {
        ...resCurrent.data,
        id: resCurrent.id,
        proxy_patient: resCurrent.proxy_patient,
        measure_date: resCurrent.measure_date,
      };
    }
  }

  // result merge drug
  let currentTCMedId = currentMedResult?.add_ids || [];
  let newTCMed: any[] = [];
  let expireMed: any[] = [];
  let currentMedIds = currentMedResult?.items?.map((item: any) => item.id) || [];
  drugList = drugTC?.map((item: any) => {
    let dateNow = +new Date();
    let dateOrder = +new Date(item.order_date);
    let checkExpireDate = Math.floor(
      Math.abs(dateNow - dateOrder) / (1000 * 60 * 60 * 24)
    );
    console.log("checkExpireDate", checkExpireDate)
    let drugIndex = currentMedIds.indexOf(item.id);
    // console.log("Index checking:", drugIndex, currentMedIds);

    if (checkExpireDate > 90) {
      expireMed.push(item.id);
      if (drugIndex !== -1) {
        return { ...currentMedResult?.items[drugIndex], active: false };
      }else{
        return {...item, active: false}
      }
    }

    if (drugIndex !== -1) {
      return { ...currentMedResult?.items[drugIndex], active: true };
    }
    if (!currentTCMedId.includes(item.id)) {
      newTCMed.push({ ...item, active: true, compliance: !item.extra?.is_prn });
      return { ...item, active: true, compliance: !item.extra?.is_prn };
    }
    return { ...item };
  });

  // Math.floor(Math.abs(b - a ) / (1000*60*60*24))

  if (newTCMed.length > 0 || expireMed.length > 0) {
    let updateCurrentDrug = {
      newMed: newTCMed,
      expireMed: expireMed,
      currentDrug: currentMedResult,
    };

    // console.log("updateCurrentDrug", updateCurrentDrug)
    currentMedResult = await updateCurrentMedicationFromTrackcare(
      controller,
      updateCurrentDrug
    );
  }

  let language: "TH" | "EN" = state.language?.includes("TH") ? "TH" : "EN"
  directDrugResult = mapDirectDrugData(currentMedResult?.items || [], language, resEncounter?.results.map((item: any) => item.drug_order).flat() || []);

  // patient drug add manually
  const [errManual, resManual] = await commonGetPatientDataLatest(controller as any, {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.DRUG_MANUAL,
  });
  if (errManual) {
    errorMessage.push(errManual);
  } else {
    manualDrugResult = mapManualDrugData(resManual?.data?.items || [], language);
  }

  const tmpParams: any = {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.DRUG,
    start_measure: moment(params.date, "DD/MM/YYYY").format(
      BACKEND_DATE_FORMAT
    ),
    end_measure: moment(params.date, "DD/MM/YYYY").format(BACKEND_DATE_FORMAT),
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  if (error) {
    errorMessage.push(error);
  } else {
    if (response?.items?.length > 0) {
      result = {
        ...response.items[0].data,
        id: response.items[0].id,
        proxy_patient: response.items[0].proxy_patient,
        measure_date: response.items[0].measure_date,
      };
    }
    result = { ...mapDrugData(result, directDrugResult) };
    result = { ...mapDrugData(result, manualDrugResult) };
  }
  // Todo: Remove It
  // const result =
  // console.log("handleGetDrug:", result);
  controller.setState({
    loadingMedication: false,
    drugDataTabDetail: {
      ...result,
      date: params.date,
    },
  });
};

export const handleGetDrugSetting: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.SETTING,
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  let errorMessage: any = null;
  let result: any = {};
  if (error) {
    errorMessage = error;
  } else {
    if (response?.items?.length > 0) {
      result = {
        ...response.items[0].data,
        id: response.items[0].id,
        proxy_patient: response.items[0].proxy_patient,
        measure_date: response.items[0].measure_date,
        date: moment(response.items[0].measure_date).format("DD/MM/YYYY"),
      };
    }
  }

  controller.setState({
    loadingMedication: false,
    drugDataSetting: { ...result },
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });
};

export const handleGetDrugSummary: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const state = controller.getState();

  // Todo: Remove It

  let errorMessage: any = null;
  let currentDrug: any = {};
  let result: any = { items: [] };
  let confirmDrug: any = {};

  const [errCurrent, resCurrent] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    }
  );

  if (errCurrent) {
    errorMessage = errCurrent;
  } else {
    currentDrug = mapDirectDrugData(resCurrent?.data?.items || []);
    result = { ...result, ...currentDrug };
    console.log("----- r currentDrug", result);
  }

  const tmpParams: any = {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.DRUG,
    start_measure: moment(params.date, "DD/MM/YYYY").format(
      BACKEND_DATE_FORMAT
    ),
    end_measure: moment(params.date, "DD/MM/YYYY").format(BACKEND_DATE_FORMAT),
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );
  if (error) {
    errorMessage = error;
  } else {
    if (response?.items?.length > 0) {
      confirmDrug = {
        ...response.items[0].data,
        id: response.items[0].id,
        proxy_patient: response.items[0].proxy_patient,
        measure_date: response.items[0].measure_date,
      };
      result = { ...mapDrugData(confirmDrug, currentDrug) };
      console.log("----- r currentDrug", result);
    }

    let mealSet: Record<string, any[]> = {};

    mealSet[MEDICATION_PERIOD.MORNING] = [
      // ...response.items[0].data.morning.items,
      ...result.morning.items,
    ];
    mealSet[MEDICATION_PERIOD.AFTERNOON] = [
      // ...response.items[0].data.afternoon.items,
      ...result.afternoon.items,
    ];
    mealSet[MEDICATION_PERIOD.EVENING] = [
      // ...response.items[0].data.evening.items,
      ...result.evening.items,
    ];
    mealSet[MEDICATION_PERIOD.BEDTIME] = [
      // ...response.items[0].data.bedtime.items,
      ...result.bedtime.items,
    ];
    mealSet[MEDICATION_PERIOD.OTHERS] = [
      // ...response.items[0].data.others.items,
      ...result.others.items,
    ];

    let drugItems: any[] = [];
    for (const [key, value] of Object.entries(mealSet)) {
      let data: any = {};

      if (value.length > 0) {
        value.forEach((item) => {
          let drugItem = drugItems.find(
            (drug) =>
              drug.id.split("-")?.[0] === item.id.split("-")?.[0] &&
              drug.dose === item.dose
          );
          if (drugItem) {
            data = { ...drugItem };
            data[key] = { meal: item.meal, dose: item.dose };

            drugItems = drugItems.map((drug) => {
              return drug.id.split("-")?.[0] === item.id.split("-")?.[0]
                ? { ...data }
                : { ...drug };
            });
          } else {
            data = {
              id: item.id,
              drug_name: item.drug_name,
              image: item.image,
              indication_th: item.indication_th,
              dose: item.dose,
              // morning: {},
              // afternoon: {},
              // evening: {},
              // bedtime: {},
              // others: {},
            };

            data[MEDICATION_PERIOD.MORNING] = {};
            data[MEDICATION_PERIOD.AFTERNOON] = {};
            data[MEDICATION_PERIOD.EVENING] = {};
            data[MEDICATION_PERIOD.BEDTIME] = {};
            data[MEDICATION_PERIOD.OTHERS] = {};

            data[key] = { meal: item.meal, dose: item.dose };
            drugItems.push(data);
          }
        });
      }
    }

    result = { ...result, items: [...drugItems] };
    // }
  }

  result.date = params.date;

  console.log("dataSummary:", result);
  // const result = { ...state.drugDataTabSummary, date: params.date };
  controller.setState({
    loadingMedication: false,
    drugDataTabSummary: result,
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
  });
};

export const handleGetDrugManual: Handler = async (controller, params) => {
  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.DRUG_MANUAL,
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  let errorMessage: any = null;
  let result: any = { items: [] };
  if (error) {
    errorMessage = error;
  } else {
    if (response?.items?.length > 0) {
      result = {
        id: response.items[0].id,
        items: [...response.items[0].data.items],
        proxy_patient: response.items[0].proxy_patient,
        measure_date: response.items[0].measure_date,
      };
    }
  }
  // Todo: Remove It
  // const result =

  controller.setState({
    drugManualList: {
      ...result,
    },
    errorMessage,
  });

  return result;
};

export const handleGetDrugHistory: Handler = async (controller, params: Extract<Event, { message: "handleGetDrugHistory" }>["params"]) => {
  controller.setState({
    loadingMedication: true,
  });
  const state = controller.getState();
  let dateSelected = moment(new Date());
  let medicationFilter: any = {};
  if (params?.drugShow && Object.keys(params?.drugShow || {}).length > 0) {
    medicationFilter = { ...(params?.drugShow || {}) };
  }
  if (params?.date) {
    dateSelected = moment(params.date);
  }

  const range_map = {
    "5_DAY": dateSelected.clone().add(-4, "days"),
    "7_DAY": dateSelected.clone().add(-6, "days"),
    "10_DAY": dateSelected.clone().add(-9, "days"),
  };

  let start_range = range_map["5_DAY"];
  if (params?.duration) {
    start_range = range_map[params.duration];
  }


  console.log("handle: ", start_range, dateSelected)
  const tmpParams: any = {
    group: GROUP.DRUG,
    data_type: DATA_TYPE.DRUG,
    start_measure: start_range.format(BACKEND_DATE_FORMAT),
    end_measure: dateSelected.format(BACKEND_DATE_FORMAT),
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  let result: any = {};
  let errorMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    if (response?.items?.length > 0) {
      let itemsData: any[] = [];
      response.items.forEach((item: any) => {
        // console.log("ietms:", item);
        let dateHistory = formatUTCtoMoment(item.measure_date).format(
          "YYYY-MM-DD"
        );
        let data = { ...item };
        let dataSet = {
          summary: {
            title: "", // Diabetic patient should
            detail: "", // continuously take drug  & observe yourself everyday.
            color: "", // red
          },
          date: formatUTCtoMoment(item.measure_date).format(
            "DD/MM/YYYY"
          ),
          sideEffectNote: data.data.sideEffectNote,
        };

        let itemMeal: Record<string, Record<string, any>> = {};
        console.log("history data: ", data, dataSet);

        itemMeal[MEDICATION_PERIOD.MORNING] = {
          ...data.data[MEDICATION_PERIOD.MORNING],
        };
        itemMeal[MEDICATION_PERIOD.AFTERNOON] = {
          ...data.data[MEDICATION_PERIOD.AFTERNOON],
        };
        itemMeal[MEDICATION_PERIOD.EVENING] = {
          ...data.data[MEDICATION_PERIOD.EVENING],
        };
        itemMeal[MEDICATION_PERIOD.BEDTIME] = {
          ...data.data[MEDICATION_PERIOD.BEDTIME],
        };
        itemMeal[MEDICATION_PERIOD.OTHERS] = {
          ...data.data[MEDICATION_PERIOD.OTHERS],
        };

        // console.log("itemMeal:", itemMeal);
        // calculate compliance
        for (let [keyString, value] of Object.entries(itemMeal)) {
          let key = keyString as keyof (typeof dataSet)

          let v = value.items;
          let complianceCount = 0;
          let maxCount = 0;
          if (v.length > 0) {
            v.forEach((drug: any) => {
              // add when not exists in medicationFilter
              if (!Object.keys(medicationFilter).includes(drug.drug_name)) {
                medicationFilter[drug.drug_name] = true;
              }
              if (medicationFilter[drug.drug_name] && drug.compliance) {
                if (
                  [MEDICATION_STATUS.CONFIRM, MEDICATION_STATUS.HOLD].includes(
                    drug.status
                  )
                ) {
                  complianceCount += 1;
                }
                maxCount += 1;
              }
            });
          }

          let drugItem = value.items
            .filter((drug: any) => medicationFilter[drug.drug_name])
            .map((d: any) => {
              let drugConfirmTime = Date.parse(`${dateHistory} ${d.confirmTime}`);
              if (d.status === "confirm") {
                if (key === MEDICATION_PERIOD.MORNING) {
                  if (
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.MORNING_START}`
                    ) < drugConfirmTime &&
                    drugConfirmTime <
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.MORNING_END}`
                    )
                  ) {
                    return { ...d, over_time: false };
                  } else {
                    return { ...d, over_time: true };
                  }
                } else if (key === MEDICATION_PERIOD.AFTERNOON) {
                  if (
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.AFTERNOON_START}`
                    ) < drugConfirmTime &&
                    drugConfirmTime <
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.AFTERNOON_END}`
                    )
                  ) {
                    return { ...d, over_time: false };
                  } else {
                    return { ...d, over_time: true };
                  }
                } else if (key === MEDICATION_PERIOD.EVENING) {
                  if (
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.EVENING_START}`
                    ) < drugConfirmTime &&
                    drugConfirmTime <
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.EVENING_END}`
                    )
                  ) {
                    return { ...d, over_time: false };
                  } else {
                    return { ...d, over_time: true };
                  }
                } else if (key === MEDICATION_PERIOD.BEDTIME) {
                  if (
                    Date.parse(
                      `${dateHistory} ${MEDICATION_TIMES.BEDTIME_START}`
                    ) < drugConfirmTime
                  ) {
                    return { ...d, over_time: false };
                  } else {
                    return { ...d, over_time: true };
                  }
                }
              }
              return { ...d, over_time: false };
            });

          console.log(
            "medicationFilter", drugItem
          );
          let complianceSum =
            maxCount > 0
              ? ((complianceCount / maxCount) * 100).toFixed(2).toString()
              : "";

          dataSet[key] = {
            compliance: complianceSum,
            ...value,
            items: [...drugItem],
          };
        }

        itemsData.push(dataSet);
      });

      result["items"] = itemsData;
      // console.log("Result with data:", result);
    }
  }

  controller.setState({
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
    loadingMedication: false,
    drugDataTabHistory: result,
    medicationShowFilter: { ...medicationFilter },
  });
};

// ----- Change
export const handleChangeDrugDetail: Handler = (controller, params) => {
  const state = controller.getState();

  let result = { ...state.drugDataTabDetail };
  result[params.meal.toLowerCase().replace(" ", "")].items[params.index] =
    params.drug;
  controller.setState({ drugDataTabDetail: result });
};

// ----- Save
export const handleSaveRecordTime: Handler = (controller, params) => {
  const state = controller.getState();

  let result = { ...state.drugDataSetting };
  result[params.name] = params.value;
  controller.setState({
    drugDataSetting: result,
    // errorMessage: null,
    // successMessage: `Record Medication time success.`,
  });
};

export const handleSaveAllRecordTime: Handler = async (controller, params) => {
  const state = controller.getState();
  let result = { ...state.drugDataSetting };
  console.log('handleSaveAllRecordTime result: ', result);

  let errorMessage: any = null;
  let successMessage: any = null;

  if (state.drugDataSetting?.id) {
    let drugData = {
      id: state.drugDataSetting.id,
      proxy_patient: state.drugDataSetting.proxy_patient,
      measure_date: state.drugDataSetting.measure_date,
      data: {
        ...result,
      },
      group: GROUP.DRUG,
    };
    delete drugData.data["date"];
    delete drugData.data["proxy_patient"];
    delete drugData.data["measure_date"];
    delete drugData.data["id"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      successMessage = `Save drug setting time success.`;
    }

    if (globalThis.iosNative?.setiOSLocalAlarmDrug) {
      // IOS Issue 53384 ตั้งแจ้งเตือน iOS
      console.log("call globalThis.iosNative.setiOSdrugDataLocalAlarm", drugData);
      globalThis.iosNative?.setiOSLocalAlarmDrug(drugData);
    }

    if (globalThis.MobNative) {
      // Android 
      console.log("call globalThis.MobNative.setLocalAlarm")
      console.log(drugData)
      globalThis.MobNative.setLocalAlarm(JSON.stringify(drugData))
    }

  } else {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.SETTING,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...result,
      },
    };

    delete drugData.data["date"];

    if (globalThis.iosNative?.setiOSLocalAlarmDrug) {
      // IOS Issue 53384 ตั้งแจ้งเตือน iOS
      console.log("call globalThis.iosNative.setiOSLocalAlarm", drugData);
      globalThis.iosNative?.setiOSLocalAlarmDrug(drugData);
    }

    if (globalThis.MobNative) {
      // Android 
      console.log("call globalThis.MobNative.setLocalAlarm")
      console.log(drugData)
      globalThis.MobNative.setLocalAlarm(JSON.stringify(drugData))
    }

    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      result = {
        ...result,
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
      };
      successMessage = `Save drug setting time success.`;
    }
  }


  controller.setState({
    drugDataSetting: result,
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
    successMessage: successMessage,
  });
};

export const handleSaveSideEffectNote: Handler = (controller, params) => {
  const state = controller.getState();

  let result = { ...state.drugDataTabDetail };
  result.sideEffectNote = params.sideEffectNote;
  // controller.setState({ drugDataTabDetail: result });
  saveDrugDetailData(controller, { ...params, result: { ...result } });
};

// ----- Confirm
export const handleConfirmDrugMeal: Handler = async (controller, params) => {
  const state = controller.getState();

  // Todo: Remove It
  let result = { ...state.drugDataTabDetail };
  const confirmTime: string = moment().format("HH:mm");
  if (params.meal === "ALL" || params.meal === "morning") {
    result[MEDICATION_PERIOD.MORNING].items.forEach((item: any) => {
      if (item.status !== MEDICATION_STATUS.HOLD || !item.confirmTime) {
        item.status = MEDICATION_STATUS.CONFIRM;
        item.confirmTime = confirmTime;
      }
    });
  }
  if (params.meal === "ALL" || params.meal === "afternoon") {
    result[MEDICATION_PERIOD.AFTERNOON].items.forEach((item: any) => {
      if (item.status !== MEDICATION_STATUS.HOLD || !item.confirmTime) {
        item.status = MEDICATION_STATUS.CONFIRM;
        item.confirmTime = confirmTime;
      }
    });
  }
  if (params.meal === "ALL" || params.meal === "evening") {
    result[MEDICATION_PERIOD.EVENING].items.forEach((item: any) => {
      if (item.status !== MEDICATION_STATUS.HOLD || !item.confirmTime) {
        item.status = MEDICATION_STATUS.CONFIRM;
        item.confirmTime = confirmTime;
      }
    });
  }
  if (params.meal === "ALL" || params.meal === "bedtime") {
    result[MEDICATION_PERIOD.BEDTIME].items.forEach((item: any) => {
      if (item.status !== MEDICATION_STATUS.HOLD || !item.confirmTime) {
        item.status = MEDICATION_STATUS.CONFIRM;
        item.confirmTime = confirmTime;
      }
    });
  }
  if (params.meal === "ALL" || params.meal === "others") {
    result[MEDICATION_PERIOD.OTHERS].items.forEach((item: any) => {
      if (item.status !== MEDICATION_STATUS.HOLD) {
        item.confirmTime.forEach((cftime: any, index: number) => {
          if (!item.confirmTime[index]) {
            item.confirmTime[index] = confirmTime;
          }
        });
        item.status = MEDICATION_STATUS.CONFIRM;
      }
    });
  }

  saveDrugDetailData(controller, { ...params, result: { ...result } });
};

export const handleConfirmNormalDrug: Handler = (controller, params) => {
  const state = controller.getState();

  // Todo: remove params.index, params.meal
  // Todo: Remove It
  let result = { ...state.drugDataTabDetail };
  let drugMeal = params.meal.toLowerCase().replace(" ", "");
  result[drugMeal].items[params.index].status = MEDICATION_STATUS.CONFIRM;

  if (
    !result[drugMeal].items[params.index].confirmTime &&
    drugMeal !== MEDICATION_PERIOD.OTHERS
  ) {
    result[drugMeal].items[params.index].confirmTime = moment().format("HH:mm");
  }

  saveDrugDetailData(controller, { ...params, result: { ...result } });
  // controller.setState({
  //   drugDataTabDetail: result,
  //   errorMessage: null,
  //   successMessage: `Confirm ${params.drug.drug_name} success.`,
  // });

  // Todo: update params.drug status
};

// Manual
export const handleAddManualDrug: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const state = controller.getState();
  console.log("drug manual lust items: ", state.drugManualList)
  let result = [...state.drugManualList.items];
  params.status = MEDICATION_STATUS.START;
  let errorMessage: any = [];
  let successMessage: any = null;

  if (
    !params.drug_name ||
    !params.dosage ||
    !params.unit ||
    !params.administration_time[0].time ||
    !params.administration_time[0].meal
  ) {
    controller.setState({
      errorMessage: "No drug manually data for save.",
      loadingMedication: false,
    });
  }

  if (state.drugManualList?.id) {
    if (params.image) {
      const [imgErr, imgRes] = await commonCreateUpdatePatientDocumentFile(
        controller as any,
        {
          phr: (+state.drugManualList.id),
          image: params.image,
        }
      );
      if (imgErr) {
        errorMessage.push(imgErr);
      } else {
        params.image_id = imgRes.id;
        params.image = imgRes.url;
      }
    }

    result.push({ ...params });
    await saveDrugManualData(controller, { result: [...result] });
  } else {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_MANUAL,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        items: [...result],
      },
    };
    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    let dataSet: any = {};
    if (error) {
      errorMessage = error;
      dataSet = { ...state.drugManualList };
    } else {
      dataSet = {
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
        items: [...response.data.items],
      };
      successMessage = `Save drug manual success.`;
    }

    if (params.image) {
      const [imgErr, imgRes] = await commonCreateUpdatePatientDocumentFile(
        controller as any,
        {
          phr: (+response.id),
          image: params.image,
        }
      );
      if (imgErr) {
        errorMessage.push(imgErr);
      } else {
        params.image_id = imgRes.id;
        params.image = imgRes.url;
      }
    }

    await controller.setState({
      drugManualList: dataSet,
      errorMessage: errorMessage?.length > 0 ? errorMessage : null,
      successMessage: successMessage,
    });

    result.push({ ...params });
    await saveDrugManualData(controller, { result: [...result] });
  }
  // controller.setState({ drugManualList: {...state.drugManualList, params} });
  controller.setState({ loadingMedication: false });
};

export const handleActionManualDrug: Handler = (controller, params) => {
  const state = controller.getState();
  let result = [...state.drugManualList.items];
  if (params.status === MEDICATION_STATUS.DELETE) {
    result.splice(params.index, 1);
  } else {
    result[params.index].status = params.status;
  }
  if (params.status === MEDICATION_STATUS.DELETE) {
    checkDeleteDrugManualData(controller, { result: [...result] });
  } else {
    saveDrugManualData(controller, { result: [...result] });
  }
  // controller.setState({ drugManualList: result });
};

// ----- Hold - Continue
export const handleHoldDrug: Handler = (controller, params) => {
  const state = controller.getState();

  // Todo: remove params.index, params.meal
  // Todo: Remove It
  let result = { ...state.drugDataTabDetail };

  let target =
    result[params.meal.toLowerCase().replace(" ", "")].items[params.index];
  target.status = MEDICATION_STATUS.HOLD;
  target.holdDateType = params.holdDateType;
  target.holdStartDate = moment().format("DD/MM/YYYY");
  target.holdEndDate = params.holdEndDate;
  target.holdReason = params.holdReason;

  // controller.setState({
  //   drugDataTabDetail: result,
  //   errorMessage: null,
  //   successMessage: `Hold ${params.drug.drug_name} success.`,
  // });

  saveDrugDetailData(controller, { ...params, result: { ...result } });
  // Todo: update params.drug status
};

export const handleContinueDrug: Handler = (controller, params) => {
  const state = controller.getState();

  // Todo: remove params.index, params.meal
  // Todo: Remove It
  let result = { ...state.drugDataTabDetail };

  let target =
    result[params.meal.toLowerCase().replace(" ", "")].items[params.index];
  target.status = MEDICATION_STATUS.NONE;
  target.holdDateType = "";
  target.holdStartDate = "";
  target.holdEndDate = "";
  target.holdReason = "";

  saveDrugDetailData(controller, { ...params, result: { ...result } });
  // controller.setState({
  //   drugDataTabDetail: result,
  //   errorMessage: null,
  //   successMessage: `Continue ${params.drug.drug_name} success.`,
  // });

  // Todo: update params.drug status
};

// ----- Delete
export const handleDeleteDrug: Handler = (controller, params) => {
  const state = controller.getState();

  // Todo: remove params.index, params.meal
  // Todo: Remove It
  let result = { ...state.drugDataTabDetail };

  result[params.meal.toLowerCase().replace(" ", "")].items.splice(
    params.index,
    1
  );
  let removeId = result.removeItem || [];
  removeId.push(params.drug.id);
  result.removeItem = [...removeId];


  saveDrugDetailData(controller, { ...params, result: { ...result }, isDelete: true });
  // controller.setState({
  //   drugDataTabDetail: result,
  //   errorMessage: null,
  //   successMessage: `Delete ${params.drug.drug_name} success.`,
  // });

  // Todo: update params.drug status
};

// ------------- Utility -------------

const saveDrugDetailData: Handler = async (controller, params) => {
  const state = controller.getState();

  let result = { ...params.result };

  let errorMessage: any = null;
  let successMessage: any = null;
  const date = typeof state.drugDataTabDetail?.date === "string" ?
    moment(state.drugDataTabDetail?.date, BE_DATE_FORMAT) : state.drugDataTabDetail?.date

  if (state.drugDataTabDetail?.id) {
    let drugData = {
      id: state.drugDataTabDetail.id,
      proxy_patient: state.drugDataTabDetail.proxy_patient,
      measure_date: `${moment(state.drugDataTabDetail?.date, "DD/MM/YYYY").format(BACKEND_DATE_FORMAT)} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...result,
      },
    };
    delete drugData.data["date"];
    delete drugData.data["proxy_patient"];
    delete drugData.data["measure_date"];
    delete drugData.data["id"];
    const [error, response] = await commonUpdatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      successMessage = `${params.isDelete ? "Delete drug manual success" : "Confirm drug success."}`;
    }
  } else {
    const date = typeof state.drugDataTabDetail?.date === "string" ?
      moment(state.drugDataTabDetail?.date, BE_DATE_FORMAT) : state.drugDataTabDetail?.date

    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG,
      measure_date: `${date.format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...result,
      },
    };

    delete drugData.data["date"];

    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      result = {
        ...result,
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
      };
      successMessage = `Confirm  drug success.`;
    }
  }

  controller.setState({
    drugDataTabDetail: result,
    errorMessage: errorMessage?.length > 0 ? errorMessage : null,
    successMessage: successMessage,
  });
};

const checkDeleteDrugManualData: Handler = async (controller, params) => {
  const state = controller.getState();
  let result = [...params.result];
  console.log("params.status", params.status)
  let errorMessage: any = null;
  let successMessage: any = null;

  if (state.drugManualList?.id) {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_MANUAL,
      id: state.drugManualList.id,
      proxy_patient: state.drugManualList.proxy_patient,
      measure_date: state.drugManualList.measure_date,
      data: {
        items: [...result],
      },
    };

    // delete drugData.data["proxy_patient"];
    // delete drugData.data["measure_date"];
    // delete drugData.data["id"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
      result = [...state.drugManualList.items];
    } else {
      successMessage = `Delete drug manual success.`;
    }

    controller.setState({
      drugManualList: { ...state.drugManualList, items: result },
      errorMessage: errorMessage?.length > 0 ? errorMessage : null,
      successMessage: successMessage,
    });
  } else {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_MANUAL,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        items: [...result],
      },
    };

    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    let dataSet: any = {};
    if (error) {
      errorMessage = error;
      dataSet = { ...state.drugManualList };
    } else {
      dataSet = {
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
        items: [...response.data.items],
      };
      successMessage = `Confirm drug manual success.`;
    }

    controller.setState({
      drugManualList: dataSet,
      errorMessage: errorMessage?.length > 0 ? errorMessage : null,
      successMessage: successMessage,
    });
  }
};

const saveDrugManualData: Handler = async (controller, params) => {
  console.log("params", params)
  const state = controller.getState();
  let result = [...params.result];
  console.log("params.status", params.status)
  let errorMessage: any = null;
  let successMessage: any = null;

  if (state.drugManualList?.id) {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_MANUAL,
      id: state.drugManualList.id,
      proxy_patient: state.drugManualList.proxy_patient,
      measure_date: state.drugManualList.measure_date,
      data: {
        items: [...result],
      },
    };

    // delete drugData.data["proxy_patient"];
    // delete drugData.data["measure_date"];
    // delete drugData.data["id"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
      result = [...state.drugManualList.items];
    } else {
      successMessage = `Confirm drug manual success.`;
    }

    controller.setState({
      drugManualList: { ...state.drugManualList, items: result },
      errorMessage: errorMessage?.length > 0 ? errorMessage : null,
      successMessage: successMessage,
    });
  } else {
    let drugData = {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_MANUAL,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        items: [...result],
      },
    };

    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    let dataSet: any = {};
    if (error) {
      errorMessage = error;
      dataSet = { ...state.drugManualList };
    } else {
      dataSet = {
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
        items: [...response.data.items],
      };
      successMessage = `Confirm drug manual success.`;
    }

    controller.setState({
      drugManualList: dataSet,
      errorMessage: errorMessage?.length > 0 ? errorMessage : null,
      successMessage: successMessage,
    });
  }
};

export const getAllTrackCareDrug: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const state = controller.getState();

  let errorMessage: any = [];
  let encounterTrackcare: any = {};
  let directDrugResult: any = [];
  let currentMedResult: any = { items: [] };
  let drugList: any = [];

  const [resEncounter, errEncounter] = await StaffDirectEncounterView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
    },
  });
  if (errEncounter) {
    // console.log("Error:", errEncounter);
    errorMessage.push(errEncounter);
  } else {
    // console.log("getAllTrackCareDrug", resEncounter);
    // set patientEpisode Data
    if (resEncounter?.results && resEncounter.results.length > 0) {
      resEncounter.results.forEach((item: any) => {
        encounterTrackcare[item.number] = {
          doctor: item.doctor_name,
          clinic: item.division_name,
        };
      });
    }
  }

  // console.log("encounter Medication trakcare: ", encounterTrackcare);
  // Medication on TrackCare
  const [resTC, errTC] = await StaffDirectMedicationView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      // start_date, end_date
    },
  });
  if (errTC) {
    errorMessage.push(errTC);
  } else {
    if (resTC?.items.length > 0) {
      directDrugResult = [...resTC.items].map((item) => {
        // console.log("show Item: ", item);
        if (encounterTrackcare[item.encounter_number]) {
          if (!item.doctor) {
            return {
              ...item,
              clinic: encounterTrackcare[item.encounter_number].clinic,
              doctor: encounterTrackcare[item.encounter_number].doctor,
              active: false,
            };
          }
          return {
            ...item,
            clinic: encounterTrackcare[item.encounter_number].clinic,
            active: false,
          };
        }

        return { ...item, clinic: "", active: false };
      });
    }
  }

  // console.log("direct drug result: ", directDrugResult);
  // Medication Currect
  const [errCurrent, resCurrent] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    }
  );

  if (errCurrent && errCurrent.length > 1) {
    errorMessage.push(errCurrent);
  } else {
    if (resCurrent?.measure_date) {
      currentMedResult = {
        ...resCurrent.data,
        id: resCurrent.id,
        proxy_patient: resCurrent.proxy_patient,
        measure_date: resCurrent.measure_date,
      };
    }
    // currentMedResult = { ...mapDrugData(currentMedResult, directDrugResult) };
  }

  // result merge drug
  // console.log("drug data", directDrugResult);
  let currentTCMedId = currentMedResult?.add_ids || [];
  let newTCMed: any[] = [];
  let expireMed: any[] = [];
  let currentMedIds = currentMedResult?.items?.map((item: any) => item.id) || [];
  drugList = directDrugResult?.map((item: any) => {
    let dateNow = +new Date();
    let dateOrder = +new Date(item.order_date);
    let checkExpireDate = Math.floor(
      Math.abs(dateNow - dateOrder) / (1000 * 60 * 60 * 24)
    );
    let drugIndex = currentMedIds.indexOf(item.id);
    // console.log("Index checking:", drugIndex, currentMedIds);
    if (checkExpireDate > 90) {
      expireMed.push(item.id);
      if (drugIndex !== -1) {
        return { ...currentMedResult?.items[drugIndex], active: false, is_expired: true };
      }else{
        return { ...item, active: false, is_expired: true };
      }
    }

    if (drugIndex !== -1) {
      return { ...currentMedResult?.items[drugIndex], active: true };
    }
    if (!currentTCMedId.includes(item.id)) {
      newTCMed.push({ ...item, active: true, compliance: !item.extra?.is_prn });
      return { ...item, active: true, compliance: !item.extra?.is_prn };
    }
    return { ...item };
  });

  // Math.floor(Math.abs(b - a ) / (1000*60*60*24))
  console.log("checkExpireDate med: ", newTCMed, expireMed)

  if (newTCMed.length > 0 || expireMed.length > 0) {
    let updateCurrentDrug = {
      newMed: newTCMed,
      expireMed: expireMed,
      currentDrug: currentMedResult,
    };

    currentMedResult = await updateCurrentMedicationFromTrackcare(
      controller,
      updateCurrentDrug
    );
  }

  // console.log("Res:", drugList);
  controller.setState({
    medicationTrackcare: {
      measure_date:
        currentMedResult?.measure_date || moment().format(BACKEND_DATE_FORMAT),
      proxy_patient: state.selectedPatient.proxy_patient,
      items: [...drugList],
    },
    medicationTrackcareFilter: [...drugList],
    medicationCurrentList: { ...currentMedResult },
    loadingMedication: false,
  });
};

// this method will work truly on save trackcare
export const updateTrackcareMedication: Handler = (controller, params) => {
  const state = controller.getState();
  let currentDrug = [...state.medicationCurrentList?.items] || [];
  let currentDrugFilter = [...state.medicationCurrentListFilter];
  let trackCareDrug = [...state.medicationTrackcare?.items] || [];
  let filterDrug = [...(state.medicationTrackcareFilter || [])];

  if (params?.action === "REMOVE_TRACKCARE" && params.medId) {
    currentDrug = currentDrug.filter((item) => item.id !== params.medId);
    trackCareDrug = trackCareDrug.map((item) => {
      return item.id === params.medId
        ? { ...item, active: false }
        : { ...item };
    });
    filterDrug = filterDrug.map((item) => {
      return item.id === params.medId
        ? { ...item, active: false }
        : { ...item };
    });
  }

  if (params?.action === "ADD_TRACKCARE" && params.medId) {
    let drugFind = trackCareDrug.find((item) => item.id === params.medId);
    if (drugFind && Object.keys(drugFind).length > 0) {
      currentDrug.push({ ...drugFind, active: true });
    }
    trackCareDrug = trackCareDrug.map((item) => {
      return item.id === params.medId ? { ...item, active: true } : { ...item };
    });

    filterDrug = filterDrug.map((item) => {
      return item.id === params.medId ? { ...item, active: true } : { ...item };
    });
  }

  if (params?.action === "REMOVE_ALL") {
    currentDrug = [];
    trackCareDrug = trackCareDrug.map((item) => {
      return { ...item, active: false };
    });

    filterDrug = filterDrug.map((item) => {
      return { ...item, active: false };
    });
  }

  // current list manage
  if (params?.action === "DELETE_CURRENT") {
    currentDrug = currentDrug.filter((item) => item.active);
    currentDrugFilter = currentDrugFilter.filter((item) => item.active);
  }

  if (params?.action === "ADD_COMPLIANCE" && params.medId && params.isActive) {
    currentDrug = currentDrug.map((item) => {
      return item.id === params.medId
        ? { ...item, compliance: params.isActive }
        : { ...item };
    });
    currentDrugFilter = currentDrugFilter.map((item) => {
      return item.id === params.medId
        ? { ...item, compliance: params.isActive }
        : { ...item };
    });
  }

  if (
    params?.action === "ACTIVE_CURRENT" &&
    params.medId &&
    typeof params.isActive === "boolean"
  ) {
    currentDrug = currentDrug.map((item) => {
      return item.id === params.medId
        ? { ...item, active: params.isActive }
        : { ...item };
    });
    currentDrugFilter = currentDrugFilter.map((item) => {
      return item.id === params.medId
        ? { ...item, active: params.isActive }
        : { ...item };
    });
  }

  controller.setState({
    medicationCurrentList: {
      ...state.medicationCurrentList,
      items: [...currentDrug],
    },
    medicationCurrentListFilter: [...currentDrugFilter],
    medicationTrackcare: {
      ...state.medicationTrackcare,
      items: [...trackCareDrug],
    },
    medicationTrackcareFilter: [...filterDrug],
  });
};

export const saveMedicationTrackCare: Handler = async (controller, params) => {
  const state = controller.getState();
  let data = { ...state.medicationCurrentList };
  if (params.manualDrug) {
    data["items"].push(doctorDrugManualAdd(params.manualDrug));
  }
  let paramsData = {
    data: data,
    groupData: {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    },
    isUpdateDate: true,
  };

  const [res, success, error] = await createUpdateDrugRequest(
    controller,
    paramsData
  );

  if (params.page === "trakcareList") {
    await getAllTrackCareDrug(controller, params);
  } else {
    await getCurrentDrugList(controller, params);
  }
  controller.setState({
    // medicationCurrentList: { ...res, ...data },
    errorMessage: error.length > 0 ? error : null,
    successMessage: success,
  });
};

export const updateCurrentMedicationFromTrackcare: Handler = async (
  controller,
  params
) => {
  let data = { ...params.currentDrug };

  if (params.newMed && params.newMed.length > 0) {
    // newMed: newTCMed,
    //   expireMed: expireMed,
    //   currentDrug: currentMedResult
    let newIds = params.newMed.map((item: any) => item.id);
    let drugData = data.items?.concat(params.newMed) || [...params.newMed];
    let currentId = data?.add_ids?.concat(newIds) || [...newIds];
    data["items"] = [...drugData];
    data["add_ids"] = [...currentId];
  }

  if (params.expireMed && params.expireMed.length > 0) {
    data["items"] = data?.items?.filter((drug: any) => !params.expireMed.includes(drug.id)) || [];
    data["add_ids"] = data["add_ids"].concat(params.expireMed.filter(i => !data["add_ids"].includes(i)))
  }

  let paramsData = {
    data: data,
    groupData: {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    },
    isUpdateDate: true,
  };

  const [res, success, error] = await createUpdateDrugRequest(
    controller,
    paramsData
  );

  return data;
};

export const filterMedicationTrackCare: Handler = (controller, params) => {
  if (!params.showType) {
    return;
  }

  const state = controller.getState();
  let filterDrug: any = [];
  if (params.showType === "medicationTrackcareFilter") {
    filterDrug = [...state.medicationTrackcare?.items] || [];
  }
  if (params.showType === "medicationCurrentListFilter") {
    filterDrug = [...state.medicationCurrentList?.items] || [];
  }

  console.log("filterDrug: ", filterDrug);
  if (filterDrug.length > 0) {
    if (params.start) {
      filterDrug = filterDrug.filter((item: any) =>
        moment(item.order_date, "YYYY-MM-DD").isSameOrAfter(params.start)
      );
    }
    if (params.end) {
      filterDrug = filterDrug.filter((item: any) =>
        moment(item.order_date, "YYYY-MM-DD").isSameOrBefore(params.end)
      );
    }
    if (params.drugName) {
      filterDrug = filterDrug.filter((item: any) =>
        item.name.toLowerCase().includes(params.drugName.toLowerCase())
      );
    }
    if (params.clinic) {
      filterDrug = filterDrug.filter((item: any) =>
        item.clinic.toLowerCase().includes(params.clinic.toLowerCase())
      );
    }
    if (params.doctor) {
      filterDrug = filterDrug.filter((item: any) =>
        item.doctor?.toLowerCase()?.includes(params.doctor.toLowerCase())
      );
    }
  }
  console.log("filterDrug2 :", filterDrug);
  controller.setState({
    [params.showType]: [...filterDrug],
  });
};

export const getCurrentDrugList: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  let errorMessage: any = [];
  let currentMedResult: any = { items: [] };

  const [errCurrent, resCurrent] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.DRUG,
      data_type: DATA_TYPE.DRUG_CURRENT,
    }
  );
  if (errCurrent && errCurrent.length > 1) {
    errorMessage.push(errCurrent);
  } else {
    if (resCurrent?.measure_date) {
      currentMedResult = {
        ...resCurrent.data,
        id: resCurrent.id,
        proxy_patient: resCurrent.proxy_patient,
        measure_date: resCurrent.measure_date,
      };
    }
    // currentMedResult = { ...mapDrugData(currentMedResult, directDrugResult) };
  }

  let expireMed = []
  let directDrugResult = currentMedResult?.items || []
  expireMed = [...directDrugResult]?.filter((item: any) => {
    let dateNow = +new Date();
    let dateOrder = +new Date(item.order_date);
    let checkExpireDate = Math.floor(
      Math.abs(dateNow - dateOrder) / (1000 * 60 * 60 * 24)
    );
      return checkExpireDate > 90 
  })
  .map((item: any) => item.id);

  // Math.floor(Math.abs(b - a ) / (1000*60*60*24))

  if (expireMed?.length > 0) {
    let updateCurrentDrug = {
      expireMed: expireMed,
      currentDrug: currentMedResult,
    };

    currentMedResult = await updateCurrentMedicationFromTrackcare(
      controller,
      updateCurrentDrug
    );
  }

  // console.log("Current drug:", currentMedResult);
  controller.setState({
    medicationCurrentList: { ...currentMedResult },
    medicationCurrentListFilter: [...currentMedResult.items],
    loadingMedication: false,
  });
};

const mapDirectDrugData = (drugData: any[], language = "EN", tkList: any[] = []) => {
  let drugDataDict: Record<string, any> = { ...getDrugDefaultDict() };
  // console.log("check direct data:", drugData)
  if (!drugData || drugData.length === 0) {
    return drugDataDict
  }
  drugData.forEach((drug, index) => {
    let meal = "";
    let frequency = "";
    let frequencySuffix = "";

    if (drug.extra?.before) {
      meal = "before"
      frequencySuffix = language === "EN" ? "before meal" : "ก่อนอาหาร";
    } else if (
      drug.extra?.with
    ) {
      meal = "with"
      frequencySuffix = language === "EN" ? "within meal" : "พร้อมอาหาร";
    } else if (drug.extra?.after) {
      meal = "after"
      frequencySuffix = language === "EN" ? "after meal" : "หลังอาหาร";
    }

    // if (drug.extra?.bedtime) {
    //   frequencySuffix = language === "EN" ? "before bedtime" : "ก่อนนอน";
    // }

    if (language === "EN") {
      frequency = (drug.route_tc || drug.route_description || "") + " " + frequencySuffix
    } else {
      frequency = (drug.route_tc_th?.trim() || drug.route_description_th || "") + " " + frequencySuffix
      if (drug.route_display_seq === 2) {
        frequency = (drug.route_tc_th?.trim().replace("ครั้งละ", "") || drug.route_description_th?.replace("ครั้งละ", "") || "") + " " + frequencySuffix
      }
    }

    if (drug && drug.route_display_seq === undefined) {
      drug.route_display_seq = 2
      frequency = (language === "EN" ? "Take" : "รับประทาน") + " " + frequencySuffix
    }

    console.log("DM drug: ", drug, frequency, language)

    let drugIndexData = 1

    const findDrug = tkList.find((item: any) => item.id === drug.id)

    let data = {
      // id: drug.id,
      drug_name: drug.name,
      dose: drug.dose,
      unit: drug.unit || "",
      frequency: frequency,
      meal: meal,
      image: drug.image,
      indication_th: drug.medication,
      note_medication: drug.note?.medication || "",
      note_label: drug.note?.label || "",
      confirmTime: "",
      status: MEDICATION_STATUS.NONE,
      compliance: drug.compliance || false,
      isCurrectMed: false,
      isActive: false,
      prn: drug.extra?.is_prn || false,
      display_seq: drug.route_display_seq || 999,
      ...(findDrug ? {
        note_medication: findDrug?.note?.medication || "",
        note_label: findDrug?.note?.label || "",
      } : {})
    };

    let isOther = true;
    if (drug.extra?.morning) {
      isOther = false;
      drugDataDict[MEDICATION_PERIOD.MORNING]["items"].push({ ...data, id: drug.id + "-" + drugIndexData });
      drugIndexData += 1
    }

    if (drug.extra?.afternoon) {
      isOther = false;
      drugDataDict[MEDICATION_PERIOD.AFTERNOON]["items"].push({ ...data, id: drug.id + "-" + drugIndexData });
      drugIndexData += 1
    }

    if (drug.extra?.evening) {
      isOther = false;
      drugDataDict[MEDICATION_PERIOD.EVENING]["items"].push({ ...data, id: drug.id + "-" + drugIndexData });
      drugIndexData += 1
    }

    if (drug.extra?.bedtime) {
      data.frequency = data.frequency.replace(/ before meal$| ก่อนอาหาร$| within meal$| พร้อมอาหาร$| after meal$| หลังอาหาร$/g, "")
      data.frequency = `${data.frequency} ${language === "EN" ? "before bedtime" : "ก่อนนอน"}`

      isOther = false;
      drugDataDict[MEDICATION_PERIOD.BEDTIME]["items"].push({ ...data, id: drug.id + "-" + drugIndexData });
      drugIndexData += 1
    }

    if (isOther) {
      drugDataDict[MEDICATION_PERIOD.OTHERS]["items"].push({
        ...data,
        id: drug.id,
        confirmTime: [],
      });
    }
  });
  return drugDataDict;
};

const mapManualDrugData = (drugData: any[], language: "TH" | "EN") => {
  let drugDataDict: Record<string, any> = { ...getDrugDefaultDict() };

  if (!drugData || drugData.length === 0) {
    return drugDataDict
  }

  drugData.forEach((drug, index) => {
    // some data may be get back from all drug data
    if (drug.status === MEDICATION_STATUS.START) {
      let drugIndexData = 1
      let data = {
        // id: drug.drug_name,
        drug_name: drug.drug_name,
        dose: drug.dosage,
        unit: drug.unit,
        frequency: "",
        meal: "",
        image: drug.image,
        indication_th: "",
        confirmTime: "",
        status: MEDICATION_STATUS.START,
        compliance: false,
        isCurrectMed: false,
        isActive: false,
        prn: false,
        display_seq: 2,
      };

      // console.log(
      //   "administration_time:",
      //   drug.administration_time,
      //   drug.administration_time["time"]
      // );
      drug.administration_time.map((item: any) => {
        let meal = "";
        let frequency = "";
        let isOther = true;

        if (item.meal === "Before meal") {
          meal = "before";
          frequency = language === "EN" ? "Take before food meal" : "รับประทาน ก่อนอาหาร";
        } else if (item.meal === "After meal") {
          meal = "after";
          frequency = language === "EN" ? "Take after food meal" : "รับประทาน หลังอาหาร";
        } else if (item.time === "Bed time") {
          meal = "";
          frequency = language === "EN" ? "Take" : "รับประทาน"
        }

        if (item.time === "Morning") {
          // console.log(drug.administration_time?.time);
          isOther = false;
          drugDataDict["morning"]["items"].push({
            ...data,
            id: drug.drug_name + "-" + drugIndexData,
            meal: meal,
            frequency: frequency,
          });
          drugIndexData += 1
        }

        if (item.time === "Afternoon") {
          isOther = false;
          drugDataDict["afternoon"]["items"].push({
            ...data,
            id: drug.drug_name + "-" + drugIndexData,
            meal: meal,
            frequency: frequency,
          });
          drugIndexData += 1
        }

        if (item.time === "Evening") {
          isOther = false;
          drugDataDict["evening"]["items"].push({
            ...data,
            id: drug.drug_name + "-" + drugIndexData,
            meal: meal,
            frequency: frequency,
          });
          drugIndexData += 1
        }

        if (item.time === "Bedtime") {
          isOther = false;
          drugDataDict["bedtime"]["items"].push({
            ...data,
            id: drug.drug_name + "-" + drugIndexData,
            meal: meal,
            frequency: frequency,
          });
          drugIndexData += 1
        }

        if (isOther) {
          drugDataDict["others"]["items"].push({
            ...data,
            id: drug.drug_name,
            confirmTime: [],
            meal: meal,
            frequency: frequency,
          });
        }
      });
    }
  });

  return drugDataDict;
};

const mapDrugData = (drugData: Record<string, any>, drugDirectData: Record<string, any>) => {
  let drugDataDict: Record<string, any> = {
    date: moment().format("DD MMMM YYYY"),
    sideEffectNote: "",
    // morning: {
    //   warning: false,
    //   items: [],
    // },
    // afternoon: {
    //   warning: false,
    //   items: [],
    // },
    // evening: {
    //   warning: false,
    //   items: [],
    // },
    // bedtime: {
    //   warning: false,
    //   items: [],
    // },
    // others: {
    //   warning: false,
    //   items: [],
    // },
  };

  drugDataDict = { ...drugDataDict, ...getDrugDefaultDict() };

  drugDataDict = { ...drugDataDict, ...drugData };
  drugDataDict = { ...mergeData(drugDataDict, drugDirectData) };
  // drugDataDict = { ...mergeData(drugDataDict, drugManualData) };

  let today = moment().format("YYYY-MM-DD");
  let dateTimeNow = moment().format("YYYY-MM-DD HH:mm:ss");
  if (drugData?.measure_date) {
    today = formatUTCtoMoment(drugData.measure_date).format("YYYY-MM-DD");
    dateTimeNow = formatUTCtoMoment(drugData.measure_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }

  let morningTimeStart = MEDICATION_TIMES.MORNING_START;
  let morningTimeEnd = MEDICATION_TIMES.MORNING_END;
  let afternoonTimeStart = MEDICATION_TIMES.AFTERNOON_START;
  let afternoonTimeEnd = MEDICATION_TIMES.AFTERNOON_END;
  let eveningTimeStart = MEDICATION_TIMES.EVENING_START;
  let eveningTimeEnd = MEDICATION_TIMES.EVENING_END;
  let bedTimeStart = MEDICATION_TIMES.BEDTIME_START;
  let bedTimeEnd = MEDICATION_TIMES.BEDTIME_END;

  // console.log(
  //   "check in bedtime:",
  //   timeNow.isBetween(bedTimeStart, bedTimeEnd, undefined, "()")
  // );
  if (
    Date.parse(`${today} ${morningTimeStart}`) < Date.parse(dateTimeNow) &&
    Date.parse(dateTimeNow) < Date.parse(`${today} ${morningTimeEnd}`)
  ) {
    drugDataDict["morning"].warning = true;
  }

  if (
    Date.parse(`${today} ${afternoonTimeStart}`) < Date.parse(dateTimeNow) &&
    Date.parse(dateTimeNow) < Date.parse(`${today} ${afternoonTimeEnd}`)
  ) {
    drugDataDict["afternoon"].warning = true;
    drugDataDict["morning"].warning = checkConfirmTimeDrug(
      drugDataDict["morning"].items,
      Date.parse(`${today} ${morningTimeEnd}`)
    );
  }

  if (
    Date.parse(`${today} ${eveningTimeStart}`) < Date.parse(dateTimeNow) &&
    Date.parse(dateTimeNow) < Date.parse(`${today} ${eveningTimeEnd}`)
  ) {
    drugDataDict["evening"].warning = true;
    drugDataDict["afternoon"].warning = checkConfirmTimeDrug(
      drugDataDict["afternoon"].items,
      Date.parse(`${today} ${afternoonTimeEnd}`)
    );
    drugDataDict["morning"].warning = checkConfirmTimeDrug(
      drugDataDict["morning"].items,
      Date.parse(`${today} ${morningTimeEnd}`)
    );
  }
  // console.log(
  //   "Time logs",
  //   timeNow.format("YYYY-MM-DDThh:mm"),
  //   bedTimeStart.format("YYYY-MM-DDThh:mm"),
  //   bedTimeEnd.format("YYYY-MM-DDThh:mm")
  // );
  if (Date.parse(`${today} ${bedTimeStart}`) < Date.parse(today)) {
    drugDataDict["bedtime"].warning = true;
    drugDataDict["evening"].warning = checkConfirmTimeDrug(
      drugDataDict["evening"].items,
      Date.parse(`${today} ${eveningTimeEnd}`)
    );
    drugDataDict["afternoon"].warning = checkConfirmTimeDrug(
      drugDataDict["afternoon"].items,
      Date.parse(`${today} ${afternoonTimeEnd}`)
    );
    drugDataDict["morning"].warning = checkConfirmTimeDrug(
      drugDataDict["morning"].items,
      Date.parse(`${today} ${morningTimeEnd}`)
    );
  }

  return drugDataDict;
};

// only simple merge meal drug
const mergeData = (sourceDrugData: Record<string, any>, mergeDrugData: Record<string, any>) => {
  let drugData = { ...sourceDrugData };
  // console.log(" check merge data: ", sourceDrugData, mergeDrugData)
  for (const [key, value] of Object.entries(mergeDrugData)) {
    let sourceDrug = [...drugData[key].items];
    let mergeDrug = [...mergeDrugData[key].items];
    mergeDrug.forEach((item) => {
      console.log("merge key: ", key)
      if (
        !sourceDrugData.removeItem ||
        !sourceDrugData.removeItem.includes(item.id)
      ) {
        let index = -1;
        sourceDrug.forEach((sd: any, idx: number) => {
          if (sd.id === item.id && sd.dose === item.dose) {
            index = idx;
            return;
          }
        });
        if (index > -1) {
          sourceDrug[index] = {
            ...item,
            ...sourceDrug[index],
            // confirmTime: sourceDrug[index].confirmTime,
          };
        } else {
          sourceDrug.push(item);
        }

        // let sourceDrugIds = sourceDrug.map((d) => d.id);
        // if (sourceDrugIds.includes(item.id)) {
        //   let index = sourceDrugIds.indexOf(item.id);
        //   sourceDrug[index] = {
        //     ...item,
        //     ...sourceDrug[index],
        //     // confirmTime: sourceDrug[index].confirmTime,
        //   };
        // } else {
        //   sourceDrug.push(item);
        // }
      }
    });
    sourceDrug = sortDrugData(sourceDrug)
    drugData[key].items = [...sourceDrug];
  }
  console.log("merge data: ", drugData)
  return drugData;
};

const sortDrugData = (data: any[]) => {
  let allDrug: any[] = []
  let beforeDrug = data.filter(item => item.meal?.toLowerCase() === "before")
    .sort((x: any, y: any) => x.display_seq < y.display_seq ? x : y)
  let withDrug = data.filter(item => item.meal?.toLowerCase() === "with")
    .sort((x: any, y: any) => x.display_seq < y.display_seq ? x : y)
  let afterDrug = data.filter(item => item.meal?.toLowerCase() === "after")
    .sort((x: any, y: any) => x.display_seq < y.display_seq ? x : y)
  let anotherDrug = data.filter(item => !["before", "with", "after"].includes(item.meal?.toLowerCase()))
    .sort((x: any, y: any) => x.display_seq < y.display_seq ? x : y)

  allDrug = allDrug.concat(beforeDrug)
  allDrug = allDrug.concat(withDrug)
  allDrug = allDrug.concat(afterDrug)
  allDrug = allDrug.concat(anotherDrug)
  // let morningDrug = [...data[MEDICATION_PERIOD.MORNING]]
  // let afternoonDrug = [...data[MEDICATION_PERIOD.AFTERNOON]]
  // let eveningDrug = [...data[MEDICATION_PERIOD.EVENING]]
  // let bedtimeDrug = [...data[MEDICATION_PERIOD.BEDTIME]]
  // let otherDrug = [...data[MEDICATION_PERIOD.OTHERS]]
  return allDrug
}

const checkConfirmTimeDrug = (drugData: any[], timeEnd: number) => {
  let confirmTimeMap = drugData.map((item) => item.confirmTime);
  let checkStatus = false;
  let today = moment().format("YYYY-MM-DD");
  for (const time of confirmTimeMap) {
    // console.log("show time:", time);
    let timeConfirm = Date.parse(`${today} ${time}`);
    if (!time || timeConfirm > timeEnd) {
      checkStatus = true;
      break;
    }
  }
  return checkStatus;
};

const getDrugDefaultDict = () => {
  let drug: Record<string, any> = {};
  drug[MEDICATION_PERIOD.MORNING] = {
    warning: false,
    items: [],
  };
  drug[MEDICATION_PERIOD.AFTERNOON] = {
    warning: false,
    items: [],
  };
  drug[MEDICATION_PERIOD.EVENING] = {
    warning: false,
    items: [],
  };
  drug[MEDICATION_PERIOD.BEDTIME] = {
    warning: false,
    items: [],
  };
  drug[MEDICATION_PERIOD.OTHERS] = {
    warning: false,
    items: [],
  };

  return drug;
};

/* params
 * data - any drug data *** set before set to this params
 * groupData - set group and data type to save drug data
 * isUpdateDate = use only update method usually use on save current medication data
 */
const createUpdateDrugRequest: Handler = async (controller, params) => {
  let sentDrugData = { ...params.data };
  let groupData = { ...params.groupData };
  let errorMessage: any = [];
  let successMessage = "";
  let resData = {};
  if (sentDrugData?.id) {
    let measureDate = params.isUpdateDate
      ? moment().format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`)
      : sentDrugData.measure_date;

    let drugData = {
      id: sentDrugData.id,
      proxy_patient: sentDrugData.proxy_patient,
      measure_date: measureDate,
      ...groupData,
      data: {
        ...sentDrugData,
      },
    };
    delete drugData.data["date"];
    delete drugData.data["proxy_patient"];
    delete drugData.data["measure_date"];
    delete drugData.data["id"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      successMessage = `Conplete update data`;
    }
  } else {
    let drugData = {
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      ...groupData,
      data: {
        ...sentDrugData,
      },
    };

    delete drugData.data["date"];

    const [error, response] = await commonCreatePatientData(
      controller as any,
      drugData
    );
    if (error) {
      errorMessage = error;
    } else {
      resData = {
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
      };
      successMessage = `Conplete save data`;
    }
  }
  return [resData, successMessage, errorMessage];
};

const doctorDrugManualAdd = (sourceData: Record<string, any>) => {
  let before = sourceData.method === "before meal";
  let after = sourceData.method === "after meal";

  let data = {
    id: moment().format("DDMMYYYYHHmm"),
    name: "",
    unit: "",
    dose: "",
    extra: {
      before: before,
      with: false,
      after: after,
      morning: sourceData.administrationTime.includes(MEDICATION_PERIOD.MORNING),
      afternoon: sourceData.administrationTime.includes(MEDICATION_PERIOD.AFTERNOON),
      evening: sourceData.administrationTime.includes(MEDICATION_PERIOD.EVENING),
      bedtime: sourceData.administrationTime.includes(MEDICATION_PERIOD.BEDTIME),
      other: (!sourceData.administrationTime.includes(MEDICATION_PERIOD.MORNING) &&
        !sourceData.administrationTime.includes(MEDICATION_PERIOD.AFTERNOON) &&
        !sourceData.administrationTime.includes(MEDICATION_PERIOD.EVENING) &&
        !sourceData.administrationTime.includes(MEDICATION_PERIOD.BEDTIME))
    },
    clinic: "",
    order_date: moment().format("YYYY-MM-DD"),
    active: true,
    compliance: false,
  };

  data = { ...data, ...sourceData };
  data["dose"] = sourceData.dosage;
  data["name"] = sourceData.drug_name;
  // if(sourceData.method = "before meal"){

  // }
  return data;
};
