import React, { useMemo, useState } from "react";
// Semantic
import { Tab, TabProps, Menu } from "semantic-ui-react";
// Mui
import { makeStyles, Avatar, Button as MuiButton } from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import SwipeableViews from "react-swipeable-views";

// UI
import HeaderBar from "../medication/HeaderBar";

// utils
import { WARNING_DRUG } from "./CardWarningTab";

// Types
type CardG6PDInfoProps = {
  // function
  onEvent: any;
  onClose: Function;
  // data
  history: any;
};

// Use styles
const COLORS = {
  blue_bdms: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    "&.active": {
      color: `${COLORS.blue_bdms} !important`,
    },
  },
  accordion: {
    borderRadius: "5px !important",
    marginBottom: "10px",
    boxShadow: "0px 0px 8px 1px rgba(131, 133, 135, 0.1) !important",
    overflow: "hidden",
    "&::before": {
      opacity: "0 !important",
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      padding: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      },
      "& .UnsafeDrug-header": {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "50px 1fr 85px",
        alignItems: "center",
        padding: "12px 0 0 12px",
      },
    },
    "&.Mui-expanded": {
      margin: "0 0 10px",
    },
  },
}));

// Images
const ICONS = {
  checkbox: "/images/History/checkbox-icon.png",
  pills_green: "/images/History/pills-green-icon.png",
  pills_purple: "/images/History/pills-purple-icon.png",
};

const DRUG_STATUS = {
  ANTIBIOTICS: "Antibiotics",
  OTHER: "Other",
} as const;

const DRUG_COLOR = {
  [DRUG_STATUS.ANTIBIOTICS]: "#27AE60",
  [DRUG_STATUS.OTHER]: "#9B51E0",
};

const CardG6PDInfo: React.FunctionComponent<CardG6PDInfoProps> = (props) => {
  const [viewIndex, setViewIndex] = useState<number>(0);

  const classes = useStyles();

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="doctor" className={classes.tabs}>
            {/* <FormattedMessage id="bplusClinicKey161" /> */}
            Information
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="specialty" className={classes.tabs}>
            {/* <FormattedMessage id="bplusClinicKey463" /> */}
            Unsafe Drugs
          </Menu.Item>
        ),
      },
    ],
    []
  );

  // Handle
  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    if (typeof data.activeIndex === "number") {
      setViewIndex(data.activeIndex);
    }
  };

  return (
    <div
      style={{ backgroundColor: "white", height: "100%", overflow: "hidden" }}
    >
      <HeaderBar
        // function
        onEvent={props.onEvent}
        // data
        setTitle={WARNING_DRUG.G_6_PD}
        // config
        currentMob={"MobHistory"}
        hiddenLeft={true}
        // callback
        rightIconClick={props.onClose}
      />
      <Tab
        className="appointment-tab-custom --small"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
      />
      <SwipeableViews
        index={viewIndex}
        slideClassName="app-view --h90"
        onChangeIndex={handleChangeIndex}
        containerStyle={{ height: "100%", WebkitOverflowScrolling: "touch" }}
        animateHeight={true}
      >
        <InformationView />
        <UnsafeDrugView />
      </SwipeableViews>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    InformationView;                    */

/* ------------------------------------------------------ */
const InformationView = React.memo((props: any) => {
  return (
    <div style={{ padding: "15px" }}>
      {INFORMATION.map((item) => (
        <>
          <div
            style={{
              fontWeight: "bold",
              color: COLORS.blue_bdms,
              margin: "0 0 0.25rem",
            }}
          >
            {item.title}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            {item.contents.map((content) => (
              <p
                style={{
                  textIndent: item.checked ? "" : "2rem",
                  margin: "0 0 0.5rem",
                }}
              >
                {item.checked && (
                  <img
                    src={ICONS.checkbox}
                    alt="checkbox.icon"
                    style={{
                      width: "16px",
                      height: "16px",
                      margin: "0 5px -3px 0",
                    }}
                  />
                )}{" "}
                {content}
              </p>
            ))}
          </div>
        </>
      ))}
    </div>
  );
});

/* ------------------------------------------------------ */

/*                     UnsafeDrugView;                    */

/* ------------------------------------------------------ */
const UnsafeDrugView = React.memo((props: any) => {
  const classes = useStyles();

  return (
    <div style={{ padding: "15px" }}>
      {UNSAFE_DRUGS.map((item, index) => (
        <Accordion
          key={"allergy-accordion-" + index}
          className={classes.accordion}
        >
          <AccordionSummary
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div style={{ display: "grid", width: "100%" }}>
              <div className="UnsafeDrug-header">
                <div>
                  <img
                    src={
                      item.status === DRUG_STATUS.ANTIBIOTICS
                        ? ICONS.pills_green
                        : ICONS.pills_purple
                    }
                    alt={`pills-${index}.icon`}
                    style={{ width: "35px" }}
                  />
                </div>
                <div
                  style={{
                    color: (DRUG_COLOR as any)[item.status],
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  {item.name}
                </div>
                <div style={{ position: "relative", height: "100%" }}>
                  <div
                    style={{
                      width: "85px",
                      padding: "5px 10px",
                      backgroundColor: "#F4F6F7",
                      color: "#828282",
                      position: "absolute",
                      top: 0,
                    }}
                  >
                    {item.status}
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "8px 12px 12px",
                  color: "rgba(130, 130, 130, 1)",
                }}
              >
                {item.description}
              </div>
            </div>
          </AccordionSummary>
        </Accordion>
      ))}
    </div>
  );
});

// MOCK DATA
const INFORMATION = [
  {
    contents: [
      "ภาวะพร่องเอนไซม์ G-6-PD คือ ภาวะความผิดปกติทางพันธุกรรมของร่างกาย ที่ทำให้เม็ดเลือดแดงไม่แข็งแรงเมื่อร่างกายได้รับสิ่งกระตุ้น เช่น ยา สารเคมี อาหาร การติดเชื้อ ก็อาจทำให้เม็ดเลือดแดงแตกได้",
    ],
  },
  {
    title: "อาการแสดง",
    contents: [
      "ทารกแรกเกิด จะมีตัวเหลืองร่วมกับซีดหรือไม่ซีดก็ได้ ภายใน 2-3 วันแรก หรือสัปดาห์แรกของชีวิต",
      "เด็กโตและผู้ใหญ่ จะมีอาการซีด ตัวเหลือง เวียนศีรษะ ปวดศีรษะ หายใจเร็ว ชีพจรเต้นเร็วและเบา ปัสสาวะสีชา เป็นต้น",
    ],
  },
  {
    title: "คำแนะนำในการปฏิบัติตัว",
    contents: [
      "พกสมุดหรือบัตรประจำตัว จี-6-พีดี และแจ้งให้แพทย์ ทราบเสมอว่ามีภาวะนี้",
      "เมื่อเกิดอาการไม่สบายควรปรึกษาแพทย์ ไม่ควรซื้อยา รับประทานเอง",
      "หลีกเลี่ยงยา อาหาร หรือสารเคมีที่อาจกระตุ้นให้เกิด อาการเม็ดเลือดแดงแตก",
      "เมื่อเกิดอาการของภาวะเม็ดเลือดแดงแตก ควรพบแพทย์ ที่โรงพยาบาล เพื่อรับการรักษาทันที",
      "หากต้องการมีบุตร ควรได้รับคำปรึกษาและคำแนะนำ จากแพทย์เพื่อให้ทราบถึงอัตราเสี่ยงของการที่บุตรจะมี ภาวะนี้",
      "ผู้ปกครองควรแจ้งให้เด็กทราบเมื่อเด็กโตขึ้นถึง ภาวะโรค และสิ่งกระตุ้นที่ควรหลีกเลี่ยง",
    ],
    checked: true,
  },
];

const UNSAFE_DRUGS = [
  {
    name: "Quinolones",
    description:
      "Ciprofloxacin, Nalidixic acid, Norfloxacin Ofloxacin, Moxifloxacin",
    status: "Antibiotics",
  },
  {
    name: "Sulfonamide & Sulfones",
    description:
      "Sulfamethoxazole & Trimethoprim (Co-trimoxazole), Sulfacetamide, Sulfadimidine Sulfanilamide , Sulfapyridine , Sulfasalazine Sulfisoxazole",
    status: "Antibiotics",
  },
  {
    name: "Nitrofurans",
    description: "Nitrofurantoin, Nitrofurazone",
    status: "Antibiotics",
  },
  {
    name: "Chloramphenicol",
    description: "Chloramphenicol (ยาทา ยาหยอดตา ยาหยอดหู สามารถใช้ได้)",
    status: "Antibiotics",
  },
  {
    name: "Antileprosy",
    description: "Dapsone",
    status: "Antibiotics",
  },
  {
    name: "Antimalarials",
    description:
      "Primaquine - สำหรับ Primaquine ในผู้ป่วยที่เป็นมาลาเรียชนิดไวแวกซ์ (P. vivax) อาจใช้ยานี้ได้ด้วยความระมัดระวังเป็นพิเศษ",
    status: "Antibiotics",
  },
  {
    name: "Antineoplastic Agent",
    description: "Doxorubicin",
    status: "Other",
  },
  {
    name: "Urinary Analgesic",
    description: "Phenazopyridine (Pyridium)",
    status: "Other",
  },
  {
    name: "Antimethemoglo-binemic agent",
    description: "Methylene blue",
    status: "Other",
  },
];

(CardG6PDInfo.defaultProps as CardG6PDInfoProps) = {
  // function
  onEvent: null,
  // data
  history: null,
};

export default React.memo(CardG6PDInfo);
