import React, { useState, useMemo } from "react";
// Mui
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import { FormattedMessage, useIntl } from "react-intl";

// UI
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Interface
import { Event } from "../MobSmartRegisterInterface";

// Styles
import { stepeKYC } from "./CardKYCStep";

// Types
type StartFaceScanProps = {
  onEvent: (e: Event) => any;
  // data
  history: History<{
    idCardBase64?: string;

    appointment?: {
      pathname: string;
      search: string;
      state: string;
    };
  }>;
};

// Images
const IMAGES = {
  face_recognition: "/images/register/face-recognition.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  red: "rgb(218, 0, 0)",
  light_blue: "rgb(204 218 237)",
  grey: "#2c2c2c",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const useStyles = makeStyles((theme) => ({
  description: {
    padding: "1.55rem 20px 3.5rem",
    marginBottom: "3.5rem",
    lineHeight: 1.5,
    "& > div:nth-child(1)": {
      fontWeight: "bold",
      fontSize: "1.3rem",
      marginBottom: "-0.25rem",
    },
    "& ui": {
      paddingLeft: "1.5rem",
    },
  },
  ...stepeKYC,
}));

const StartFaceScan = (props: StartFaceScanProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const [openModRefuse, setOpenModRefuse] = useState<boolean>(false);

  // Memo
  const diglogTitle = useMemo(() => {
    return `${intl.formatMessage({
      id: "bplusClinicKey1097",
    })}\n${intl.formatMessage({ id: "bplusClinicKey1043" })}`;
  }, []);

  const dialogDescription = useMemo(() => {
    return `${intl.formatMessage({
      id: "bplusClinicKey1098",
    })}\n${intl.formatMessage({ id: "bplusClinicKey1099" })}`;
  }, []);

  // Handler
  const handleOpenModRefuse = () => {
    setOpenModRefuse(true);
  };

  const handleCloseModRefuse = () => {
    setOpenModRefuse(false);
  };

  const handleRefuse = () => {
    handleCloseModRefuse();

    props.onEvent({
      message: "HandleCanceleKYC",
      params: { history: props.history },
    });
  };

  const handleFaceScan = () => {
    // -const params = new URLSearchParams(window.location.search);
    // -const t = params.get("t");

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history: props.history,
        pathname: `/kyc-step/face-scan/`,
        replace: false,
      },
    });
  };

  return (
    <div>
      <div className={classes.step_ekyc}>
        <div style={styles.center}>
          <div className="number">{2}</div>
          <div className="title">
            <FormattedMessage id="bplusClinicKey1028" />
          </div>
        </div>
        <div style={styles.center}>
          <div className="divider">
            <div></div>
            <div className="step-active"></div>
            <div></div>
          </div>
        </div>
      </div>

      <div style={styles.center}>
        <img
          src={IMAGES.face_recognition}
          style={{ width: "13rem", marginTop: "1.55rem" }}
          alt="face-recognition"
        />
      </div>

      <div className={classes.description}>
        <div>
          <FormattedMessage id="bplusClinicKey1043" />
        </div>
        <div>
          <ul>
            <li style={{ whiteSpace: "pre-line" }}>
              <FormattedMessage id="bplusClinicKey1035" />
            </li>
            <li style={{ marginTop: "1rem", whiteSpace: "pre-line" }}>
              <FormattedMessage id="bplusClinicKey1042" />
            </li>
          </ul>
        </div>
      </div>

      <div className="button-submit-bottom">
        <MuiButton variant="contained" color="primary" onClick={handleFaceScan}>
          <FormattedMessage id="bplusClinicKey1056" />
        </MuiButton>
      </div>

      <div
        aria-hidden="true"
        style={{
          textAlign: "center",
          color: COLORS.blue,
          marginBottom: "1rem",
        }}
        onClick={handleOpenModRefuse}
      >
        <FormattedMessage id="bplusClinicKey1057" />
      </div>

      <DialogConfirm
        open={openModRefuse}
        title={diglogTitle}
        description={dialogDescription}
        img={IMAGES.face_recognition}
        imgStyle={{ width: "150px", marginTop: 0 }}
        denyBasic={true}
        // style
        onApprove={handleRefuse}
        onDeny={handleCloseModRefuse}
      />
    </div>
  );
};

export default React.memo(StartFaceScan);
