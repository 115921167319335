import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// APIs
import DirectLabResultView from "../../issara-sdk/apis/DirectLabResultView_apps_PRX";
import DirectChildLabResultView from "../../issara-sdk/apis/DirectChildLabResultView_apps_PRX";

// Interface
import { HandleSelectHospitalInitial, GetListHospital as handleGetListHospital } from "../appointment/MakeAppointmentInterface"

export type State = {
  parentLabResult?: any;
  childLabResult?: any[];
  hospitalList?: any[];
  firstLoadingHospital?: boolean;
  selectedHospital?: any;
  loadingSearchHostipalFilter: boolean;
  textSearchHostipalFilter?: string;
};

export const StateInitial = {
  parentLabResult: {},
  childLabResult: [],
  hospitalList: [],
  firstLoadingHospital: false,
  selectedHospital: null,
  loadingSearchHostipalFilter: false,
  textSearchHostipalFilter: ""
};

export type Event =
  // GET
  | { message: "handleGetParentLabResult" }
  | { message: "handleGetChildLabResult"; params: { code: string } }
  | { message: "GetListHospital", params: {} }
  | { message: "HandleSelectedHospital", params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetParentLabResult: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const hospitalDetail = await HandleSelectHospitalInitial(controller as any, {});

  GetParentLabResult(controller, { code: hospitalDetail?.code })
};

export const GetParentLabResult: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
    });
    return;
  }

  let appName: string = "";
  if (state?.clinic === "mom") {
    appName = "MOM";
  } else if (state?.clinic === "child") {
    appName = "CHILD";
  }
  const [response, error, network] = await DirectLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      app_name: appName,
      hospital: params.code
    },
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    parentLabResult: response,
  });
};

export const handleGetChildLabResult: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
    });
    return;
  }
  
  let appName: string = "";
  if (state?.clinic === "mom") {
    appName = "MOM";
  } else if (state?.clinic === "child") {
    appName = "CHILD";
  }  
  const [response, error, network] = await DirectChildLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      parent_lab: params.code,
      app_name: appName,
    },
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    childLabResult: response,
  });
};

export const GetListHospital: Handler = (controller, params) => {
  console.log("should not see me GetListHospital MCLRI.tsx")
  handleGetListHospital(controller as any, params)
}

export const HandleSelectedHospital: Handler = (controller, params) => {
  console.log("should not see me GetListHospital MCLRI.tsx")
  controller.setState({ loadingStatus: true });
  const data = JSON.parse(JSON.stringify(params.data));
  console.log("data",data)
  GetParentLabResult(controller, { code: +data.code })
}

