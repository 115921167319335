import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  useMemo,
} from "react";
// Mui
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";

// UX
import ModFaceScanComplete from "./ModFaceScanComplete";
import SubUnderVerification from "./SubUnderVerification";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Interface
import { Event } from "../MobSmartRegisterInterface";

// Styles
import { stepeKYC } from "./CardKYCStep";

// Types
type FaceRecognitionProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  // data
  rotateType?:
    | "Portrait"
    | "LandscapeLeft"
    | "LandscapeRight"
    | "PortraitUpsideDown";
  history: History<{ idCardBase64?: string }>;
  loadingStatus?: Record<string, any>;
  errorMessage?: Record<string, any>;
};

declare global {
  interface Window {
    facemeAsyncInit: any;
  }
}

declare const FaceMe: any;

// Images
const IMAGES = {
  dismiss: "/images/register/dismiss.png",
  lock_shield: "/images/register/lock-shield.png",
};

// Styles
const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const SIZE = 340;

const useStyles = makeStyles((theme) => ({
  faceme_box: {
    width: "fit-content",
    height: "fit-content",
    borderRadius: "500rem",
    transform: "scale(0.8)",
    background:
      "linear-gradient(243.88deg, #0147A3 51.35%, rgba(1, 71, 163, 0) 94.22%)",
    // marginTop: "1.55rem",
    margin: "-1rem 0 -3rem",
    padding: "5px",
    "& > div": {
      width: `${SIZE}px`,
      height: `${SIZE}px`,
      borderRadius: "500rem",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "white",
      "& #faceme-module": {
        width: `${SIZE}px`,
        height: `${SIZE}px`,
      },
    },
  },
  description: {
    position: "relative",
    marginTop: "2.5rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& > div": {
      opacity: "1 !important",
    },
    "& .bottom-block": {
      position: "absolute",
      zIndex: "3",
      bottom: "40px",
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
      padding: "0 8px",
      boxSizing: "border-box",
      top: "0 !important",
    },
    "& .processing": {
      color: "#494649",
      zIndex: "999",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    "& .processing_bar ": {
      width: "40%",
      height: "8px",
      borderRadius: "8px",
      backgroundColor: "#e4e4e4",
      margin: "4px 0 8px 0",
    },
    "& .processing_blue": {
      height: "8px",
      backgroundColor: "#007aff",
      borderRadius: "8px",
      transitionProperty: "width",
      transitionTimingFunction: "ease-in-out",
    },
    "& .turn-face": {
      display: "none",
    },
    "& .turn-face img": {
      position: "absolute",
      animationDuration: "1.5s",
      animationIterationCount: "infinite",
    },
  },
  loading: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    zIndex: 9999,
  },
  ...stepeKYC,
}));

const FACEME_URL = "/sdk/faceme-web-sdk.js";
const FACE_RECOGNITION = "FaceRecognition";

const FaceRecognition = (props: FaceRecognitionProps) => {
  const classes = useStyles();
  const intl = useIntl();

  // Mod
  const [image, setImage] = useState<string>("");

  const descRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Memo Effect
  const isMobileDevice = useMemo(() => {
    try {
      return (navigator as any).userAgentData.mobile;
    } catch (error) {
      return isMobile;
    }
  }, []);

  // Callback Effect
  const handleFaceResult = useCallback(
    async (detail, spoofingcheckImages, clearImage) => {
      if (descRef.current) {
        descRef.current.innerHTML = "";
      }

      setImage(URL.createObjectURL(clearImage[0]));

      props.onEvent({
        message: "HandleFaceResult",
        params: {
          detail,
          spoofingcheckImages,
          clearImage,
          card: FACE_RECOGNITION,
        },
      });
    },
    []
  );

  const failCallback = useCallback((response) => {
    // -if (response?.code) {
    //   props.setProp(`errorMessage`, {
    //     ...(props.errorMessage || {}),
    //     [FACE_RECOGNITION]: {
    //       status: "error",
    //       message: `${response.message}\n${JSON.stringify(response.detail)}`,
    //     },
    //   });
    // }
    console.log("[html] getFail:", response);
  }, []);

  const handleDetectBottomBlock = useCallback((block: HTMLElement) => {
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };
    // Create an observer instance linked to the callback function
    const handleChange = (e: any) => {
      const target = e[0].target.closest("#bottom-block");

      const clone = target.cloneNode(true);

      Array.from(
        target.querySelectorAll("span,.processing-value,.processing_bar")
      ).forEach((elm: any) => {
        elm.style.opacity = "0";
      });
      Array.from(
        clone.querySelectorAll("span,.processing-value,.processing_bar")
      ).forEach((elm: any) => {
        elm.style.opacity = "1";
      });

      target.parentElement.querySelector(".watermark").style.display = "none";
      target.parentElement.querySelector(".hint-center").style.minWidth =
        "max-content";

      if (!clone.textContent.includes("0%") && descRef.current) {
        descRef.current.innerHTML = clone.outerHTML;
      }
    };

    const observer = new MutationObserver(handleChange);

    observer.observe(block, config);
  }, []);

  const handleStartAntiSpoofing = useCallback(() => {
    console.log("FaceMe", FaceMe);

    window.facemeAsyncInit = function () {
      FaceMe.init(
        () => {
          // The callback function for successful initialization.
          FaceMe.startAntiSpoofing(
            {
              sdkBlockId: "faceme-module", // must
              frontSetting: {},
              rightSetting: {},
              // preferCameraList: ["C920"],
              enableStrictMode: false, // true: remove blob image type: "image/jpeg"
              // actionList: ["LEFT", "RIGHT", "NOD", "SMILE"],
              hintStyle: { fontSize: 28, color: "white" },
              debug: false,
              fps: 60,
            },
            {
              // always2Stages: false,
              antiSpoofingType: "SINGLE_STAGE", //"SINGLE_STAGE", //,
              precisionLevel: "fast", //standard,
              raNum: 1,
              raNodEnable: false,
              raSmileEnable: false,
            },
            handleFaceResult,
            failCallback
          );

          const intervalIframe = setInterval(() => {
            const iframe = document.getElementById(
              "faceme-module-iframe"
            ) as HTMLIFrameElement;
            const block =
              iframe?.contentWindow?.document?.getElementById("bottom-block");

            const facemeDisplay =
              iframe?.contentWindow?.document?.querySelector(
                "#faceme-display-canvas"
              ) as HTMLCanvasElement;
              if (facemeDisplay) {
                facemeDisplay.style.transform = handleCheckRotate(props.rotateType);
              }

            if (block) {
              clearInterval(intervalIframe);
              handleDetectBottomBlock(block);
            }
          }, 100);

          setTimeout(() => clearInterval(intervalIframe), 5000);
        },
        (errorType: any) => {
          // The callback function for failed initialization.
          console.log("Some error happened : ", errorType.message);
        }
      );
    };
    handleRotate();
    window.facemeAsyncInit();
  }, []);

  const loadScript = useCallback(() => {
    if (typeof FaceMe !== "undefined") {
      return;
    }

    const script = document.createElement("script") as HTMLScriptElement;
    script.type = "application/javascript";
    script.src = FACEME_URL;
    script.async = true;
    document.body.appendChild(script);

    return new Promise((resolve) => {
      script.onload = () => resolve("!!!");
    });
  }, []);

  const handleSetSize = useCallback(async () => {
    try {
      let features = {
        video: { width: { ideal: 720 }, height: { ideal: 1280 } },
      };

      let display = await navigator.mediaDevices.getUserMedia(features);
      // Returns a sequence of MediaStreamTrack objects
      // representing the video tracks in the stream
      let tracks = display.getVideoTracks();
      let settings = tracks[0].getSettings();

      let width = settings.width;
      let height = settings.height;

      if (width && height) {
        const percent = (height / width) * 100;
        // const facemeDisplay = document.querySelector(
        //   "canvas#faceme-display-canvas"
        // ) as HTMLCanvasElement;
        // facemeDisplay.style.transform = handleCheckRotate(props.rotateType)
        if (percent < 100) {
          if (!isMobileDevice) {
            const faceme = document.querySelector(
              "#faceme-module"
            ) as HTMLDivElement;
            const img = faceme.parentElement?.querySelector(
              "& > img"
            ) as HTMLDivElement;

            const canvasWidth = SIZE * (100 / percent);
            const left = `-${((canvasWidth / 2) * (100 - percent)) / 100}px`;
            faceme.style.width = `${canvasWidth}px`;
            faceme.style.marginLeft = left;

            img.style.width = `${canvasWidth}px`;
            img.style.marginLeft = left;
          }
        }
      }

      tracks.forEach((track) => {
        if (track.readyState == "live") {
          track.stop();
        }
      });
    } catch (error) {}
  }, []);

  // Effect
  useEffect(() => {
    const init = async () => {
      const idCardBase64 = props.history.location.state?.idCardBase64;

      if (!idCardBase64) {
        return props.history.replace("/kyc-step/id-card/?app=MobRegister");
      }

      props.onEvent({
        message: "HandleGetStatuseKYC",
        params: { card: FACE_RECOGNITION },
      });

      await Promise.all([handleSetSize(), loadScript()]);

      handleStartAntiSpoofing();
    };

    init();
  }, []);

  useEffect(() => {
    console.log("saika ~ props.rotateType:", props.rotateType);
    handleRotate();
  }, [props.rotateType]);

  const handleRotate = () => {
    const iframe = document.getElementById(
      "faceme-module-iframe"
    ) as HTMLIFrameElement;

    if (iframe) {
      const facemeDisplay = iframe?.contentWindow?.document?.querySelector(
        "#faceme-display-canvas"
      ) as HTMLCanvasElement;

      if (facemeDisplay) {
        facemeDisplay.style.transition = "transform 0.5s";
        facemeDisplay.style.transform = handleCheckRotate(props.rotateType);
      }
    }
  };

  const handleRescan = () => {
    handleCloseModError();

    setImage("");
    handleStartAntiSpoofing();
  };

  const handleCloseModError = () => {
    const { [FACE_RECOGNITION]: key, ...error } = props.errorMessage || {};

    props.setProp(`errorMessage`, { ...error });
  };

  const handleCompleteScan = () => {
    props.onEvent({
      message: "HandleCompleteScan",
      params: { history: props.history, card: FACE_RECOGNITION },
    });
  };

  const handleCheckRotate = (rotateType?: string) => {
    switch (rotateType) {
      case `Portrait`:
        return "rotate(0deg)";
      case `PortraitUpsideDown`:
        return "rotate(180deg)";
      case `LandscapeLeft`:
        return "rotate(-90deg)";
      case `LandscapeRight`:
        return "rotate(90deg)";
      default:
        return "rotate(0deg)";
    }
  };

  const handleGotoFeed = () => {
    props.onEvent({
      message: "HandleCanceleKYC",
      params: { history: props.history },
    });
  };

  console.log("Face", props);

  return (
    <div>
      <div className={classes.step_ekyc}>
        <div style={styles.center}>
          <div className="number">{2}</div>
          <div className="title">
            <FormattedMessage id="bplusClinicKey1028" />
          </div>
        </div>
        <div style={styles.center}>
          <div className="divider">
            <div></div>
            <div className="step-active"></div>
            <div></div>
          </div>
        </div>
      </div>

      <div style={styles.center}>
        <div className={classes.faceme_box}>
          <div>
            <div id="faceme-module"></div>
            <img
              src={image}
              alt=""
              style={isMobileDevice ? { top: "-25%", width: `${SIZE}px` } : {}}
            />
          </div>
        </div>
      </div>

      <div style={styles.center}>
        <div ref={descRef} className={classes.description}></div>
      </div>

      {props.loadingStatus?.[FACE_RECOGNITION] && (
        <div className={classes.loading}>
          <SubUnderVerification />
        </div>
      )}

      {props.errorMessage?.[FACE_RECOGNITION]?.status?.includes("success") && (
        <ModFaceScanComplete
          type={props.errorMessage?.[FACE_RECOGNITION]?.status}
          // description={props.errorMessage?.[FACE_RECOGNITION]?.message}
          loading={props.loadingStatus?.[`${FACE_RECOGNITION}_SUCCESS`]}
          onRescan={handleRescan}
          onSuccess={handleCompleteScan}
        />
      )}

      <DialogConfirm
        open={props.errorMessage?.[FACE_RECOGNITION]?.status === "failed"}
        title={<FormattedMessage id="bplusClinicKey1044" />}
        description={`${intl.formatMessage({
          id: "bplusClinicKey1114",
        })}`}
        img={IMAGES.dismiss}
        imgStyle={{ width: "4rem", margin: "-1rem 0px 1.5rem" }}
        denyBasic={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1045" />}
        // callback
        onApprove={handleRescan}
        onDeny={handleCloseModError}
      />

      <DialogConfirm
        open={props.errorMessage?.[FACE_RECOGNITION]?.status === "error"}
        title={"ไม่สามารถสแกนหน้าได้"}
        description={props.errorMessage?.[FACE_RECOGNITION]?.message}
        img={IMAGES.dismiss}
        imgStyle={{ width: "4rem", margin: "-1rem 0px 1.5rem" }}
        denyBasic={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1045" />}
        // callback
        onApprove={handleRescan}
        onDeny={handleCloseModError}
      />

      <DialogConfirm
        open={props.errorMessage?.[FACE_RECOGNITION]?.status === "exceeded"}
        title={
          <div style={{ whiteSpace: "pre-line" }}>
            <FormattedMessage id="bplusClinicKey1079" />
          </div>
        }
        description={intl.formatMessage({ id: "bplusClinicKey1046" })}
        img={IMAGES.lock_shield}
        imgStyle={{ width: "5rem", margin: "-1.5rem 0px 0.75rem" }}
        hideDeny={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1078" />}
        // callback
        onApprove={handleGotoFeed}
      />
    </div>
  );
};

export default React.memo(FaceRecognition);
