import _ from 'lodash';
import config from '../config/config';

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

// console.log("urls.js hostname: ",window.location.hostname);
// console.log("urls.js host: ",window.location.host);
// console.log("urls.js port: ",window.location.port);

// const  PENTA_CENTER = "https://penta.center";
// const HOST = `${PENTA_CENTER}`

var HOST = `${config.API_HOST}`

// console.log("urls.js API HOST: ",HOST);

export const CHAT = {
    CHAT_CHANNEL: (
        HOST + "/apis/MSG/chat-channel/"
    ),
    LATEST_CHAT_CHANNEL: (
      HOST + "/apis/MSG/latest-chat-channel/"
    ),
    CHAT_CHANNEL_MESSAGE_LIST: _.template(
        HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/list/"
    ),
    CHAT_CHANNEL_MESSAGE_POST: _.template(
        HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/post/"
    ),
    USER_CHAT_SUBSCRIPTION: (
        HOST + "/apis/MSG/user-chat-subscription/"
    ),
    CHAT_CHANNEL_MESSAGE_READ_COUNT: _.template(
        HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/read-count/"
    ),
  }

export const PRX = {
    DIAG_FORM_DETAIL: _.template(
        HOST + "/apis/PRX/diag-form/{{diagFormId}}/"
    ),
    PUBLISH_DIAG_RULE_CHECK: (
        HOST + "/apis/PRX/published-diag-rule/check/"
    ),
    PROXY_PATIENT_HAS_DIVISION: (
        HOST + "/apis/PRX/proxy-patient-has-division/"
    ),
    PUBLISHED_DIAG_RULE_LIST: (
        HOST + "/apis/PRX/published-diag-rule/list/"
    ),
    PUBLISHED_DIAG_RULE_DETAIL: _.template(
        HOST + "/apis/PRX/published-diag-rule/{{publishDiagRuleId}}/"
    ),
    PATIENT: (
        HOST + "/apis/PRX/patient/"
    ),
    DIAG_FORM: (
        HOST + "/apis/PRX/diag-form/"
    ),
    MY_DIAG_FORM: (
        HOST + "/apis/PRX/my-diag-form/"
    ),
    DIAG_FORM_ID: _.template(
        HOST + "/apis/PRX/diag-form/{{id}}/"
    ),
    CHAT_CHANNEL: (
        HOST + "/apis/PRX/chat-channel/"
    ),
    OFFICAL_ACCOUNT: (
        HOST + "/apis/PRX/official-account/"
    ),
    OFFICAL_ACCOUNT_FOLLOW: _.template(
        HOST + "/apis/PRX/official-account/{{division_profile_id}}/follow/"
    ),
    OFFICAL_ACCOUNT_UNFOLLOW: _.template(
        HOST + "/apis/PRX/official-account/{{division_profile_id}}/unfollow/"
    ),
    TRIAGE_LEVEL_DIVISION_ID: _.template(
        HOST + "/apis/PRX/triage-level/division/{{division_id}}/"
    ),
    CREATE_ENCOUNTER_FROM_DIAG_FORM: (
        HOST + "/apis/PRX/create-encounter-from-diag-form/"
    ),
    DIVISION_ID_PROFILE: _.template(
        HOST + "/apis/PRX/division/{{division_id}}/profile/"
    ),
    DIAG_RULE_DETAIL: _.template(
        HOST + "/apis/PRX/diag-rule/{{diagRuleId}}/"
      ),
    LIST_CONSENTS: HOST + "/apis/PRX/list-consents/",
    V3_PATIENT_HOSPITAL: HOST + "/apis/PRX/v3/patient_hospital/",
    V3_PATIENT_GET_HN: HOST + "/apis/PRX/v3/patient_get_hn/"
}

export const USER = {
     USER_PROFILE: (
         HOST + "/users/apis/users/profile/"
     ),
     USER_MY_PROFILE: (
        HOST + "/users/api/my_profile/"
    )
}
