import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { makeStyles, Typography } from "@material-ui/core";
import { PREFERRED_MODE } from "./Preferred";

type PreferredTransferListProps = {
  type: string;
  medProgramList?: any[];
  subspecialtiesList?: any[];
  // selectedMedProgram: any[];
  // selectedSubSpecialties: any[];
  selectedServices: any;
  onChange: any;
  typeOptions: any[];
};

const not = (a: readonly number[], b: readonly number[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a: readonly number[], b: readonly number[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a: readonly number[], b: readonly number[]) => {
  return [...a, ...not(b, a)];
};

const useStyles = makeStyles(() => ({
  title: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.0375em",
    fontWeight: "bold",
  },
}));

const PreferredTransferList = (props: PreferredTransferListProps) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<number[]>([]);
  const [left, setLeft] = React.useState<number[]>([]);
  const [right, setRight] = React.useState<number[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly number[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    let r = right.concat(leftChecked)
    console.log('r: ', r);
    setRight(r);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    if (props.type === "med_program") {
      props.onChange?.({
        med_programs: [...r]
      })
    } else { 
      props.onChange?.({
        subspecialties: [...r]
      })
    }

  };

  const handleCheckedLeft = () => {
    let r = not(right, rightChecked)
    setLeft(left.concat(rightChecked));
    setRight(r);
    setChecked(not(checked, rightChecked));
    if (props.type === "med_program") {
      props.onChange?.({
        med_programs: [...r]
      })
    } else { 
      props.onChange?.({
        subspecialties: [...r]
      })
    }
  };

  useEffect(() => {
    if (props.type === "med_program") {
      // med_programs
      let unselected = (props.medProgramList || [])
        ?.filter((item: any) => !props.selectedServices?.med_programs?.includes(item.id))
        ?.map((item: any) => item.id);
      console.log("useEffect props.medProgramList: ", props.medProgramList);
      console.log("useEffect unselected: ", unselected);
      console.log(
        "useEffect props.selectedServices.med_programs: ",
        props.selectedServices?.med_programs
      );
      
      // console.log('props.selectedServices?.med_programs: ', props.selectedServices?.med_programs);
      setLeft([...unselected]);
      setRight([...props.selectedServices?.med_programs]);
    } else if (props.type === "subspecialty") {
      // subspecialties
      let unselected = (props.subspecialtiesList || [])
        ?.filter(
          (item: any) => !props.selectedServices?.subspecialties?.includes(item.id)
        )
        ?.map((item: any) => item.id);
      console.log(
        "useEffect props.subspecialtiesList: ",
        props.subspecialtiesList
      );
      console.log("useEffect unselected: ", unselected);
      console.log(
        "useEffect props.selectedServices.subspecialties: ",
        props.selectedServices?.subspecialties
      );

      setLeft([...unselected]);
      setRight([...props.selectedServices?.subspecialties]);
    }
  }, [
    props.medProgramList,
    props.subspecialtiesList,
    props.type,
    props.selectedServices?.med_programs,
    props.selectedServices?.subspecialties,
  ]);

  const customList = (title: React.ReactNode, items: readonly number[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          let name =
            props.type === "med_program"
              ? props.medProgramList?.find((item: any) => item.id === value)
                  ?.name
              : props.subspecialtiesList?.find((item: any) => item.id === value)
                  ?.name;
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <Typography className={classes.title}>{ props?.typeOptions?.find((item: any) => item.key === props.type)?.label } </Typography>

      <Grid container spacing={0.5} justifyContent="center" alignItems="center">
        <Grid item xs={5}>
          {customList(`${props?.typeOptions?.find((item: any) => item.key === props.type)?.label} ที่มีอยู่`, left)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {customList(`${props?.typeOptions?.find((item: any) => item.key === props.type)?.label} ที่ถูกเลือก`, right)}
        </Grid>
      </Grid>
    </>
  );
};

export default PreferredTransferList;
