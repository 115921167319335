import React, {
  CSSProperties,
  useMemo,
  useEffect,
  useState,
  MutableRefObject,
} from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
// CSS
import {
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Loader,
  Input,
  Button,
} from "semantic-ui-react";
import moment from "moment";
import ReactTable from "react-table-6";
import { State, Event } from "../../ManageInterface";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
} from "../../MobClinicInterface";
// COMMON
import { DateTextBox } from "../../../react-lib/apps/common";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import { Filter } from "@material-ui/icons";

const COLOR_CODE = {
  light_blue: "var(--text-light-blue)",
};

const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  } as CSSProperties,
  divDetail: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 20px",
  } as CSSProperties,
  alignItems: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  } as CSSProperties,
  label: {
    color: COLOR_CODE.light_blue,
    marginRight: "15px",
    marginLeft: "15px",
    fontWeight: "bold",
  } as CSSProperties,
  list_file: {
    paddingBottom: "5px",
    borderBottom: "1px solid #C4C4C4",
    width: "250px",
    textAlign: "center",
    marginBottom: "10px",
  } as CSSProperties,
  buttonDowload: {
    background: "rgba(146, 212, 255, 0.9)",
    borderRadius: "5px",
    color: "#656565",
    width: "100px",
    height: "27px",
    lineHeight: "4px",
  } as CSSProperties,
  buttonDelete: {
    background: "#EB5757",
    borderRadius: "5px",
    color: "#FFFFFF",
    width: "100px",
    height: "27px",
    lineHeight: "4px",
  } as CSSProperties,
};

type WebMomPrenatalAndANCRecordProps = {
  onEvent: (e: Event) => any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
} & Pick<State, "prenatalAndANCRecordList" | "prenatalAndANCRecordFilter">;

const WebMomPrenatalAndANCRecordInitial: WebMomPrenatalAndANCRecordProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  prenatalAndANCRecordList: [],
};

const fileTypesOptions = [
  { key: 1, value: "บันทึกฝากครรภ์", text: "บันทึกฝากครรภ์" },
  { key: 2, value: "Prenatal record", text: "Prenatal record" },
  { key: 3, value: "Other", text: "Other" },
];

const WebMomPrenatalAndANCRecord: React.FC<WebMomPrenatalAndANCRecordProps> = (
  props
) => {
  const [uploadFile, setUploadFile] = useState<any>();
  const [fileName, setFileName] = useState<any>("");
  const [fileType, setFileType] = useState<any>("");
  const [specity, setSpecity] = useState<any>("");
  const [obstetricName, setObstetricName] = useState<any>("");
  const [outputfileName, setOutputfileName] = useState<any>(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [searchDateStart, setSearchDateStart] = useState<any>();
  const [searchDateEnd, setSearchDateEnd] = useState<any>();
  const [searchFileType, setSearchFileType] = useState<any>();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-7", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    props.onEvent({
      message: "GetPrenatalAndANCRecordList",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.prenatalAndANCRecordList != null) {
      let mapDate = props.prenatalAndANCRecordList.map((item) => item);
      console.log("data", mapDate);
    }
  }, [props.prenatalAndANCRecordList]);

  const handleRemovePrenatalAndANCRecordList = (row: any) => {
    props.onEvent({ message: "RemovePrenatalAndANCRecordList", params: row });
  };

  const handleDownloadPrenatalAndANCRecordList = (data: any) => {
    let a = document.createElement("a");
    a.setAttribute("download", "5599");
    a.setAttribute("href", data.data.uploadFile);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    console.log(data.data.uploadFile);
  };

  const columnsRecord = useMemo(
    () => [
      {
        Header: "Visit Date",
        accessor: "data",
        width: 160,
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {moment(row._original.measure_date)
                .locale("en")
                .format("ddd, D MMMM YYYY")}
            </div>
          );
        },
      },
      {
        Header: "File type",
        accessor: "data",
        width: 160,
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {row._original.data.fileType == "Other" ? (
                <div>{row._original.data.specity}</div>
              ) : (
                <div>{row._original.data.fileType}</div>
              )}
            </div>
          );
        },
      },
      {
        Header: "File name",
        accessor: "data",
        width: 160,
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {row._original.data.fileName}
            </div>
          );
        },
      },
      {
        Header: "Obstertric’s name",
        accessor: "data",
        width: 180,
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {row._original.data.obstetricName}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "_icon",
        width: 70,
        Cell: ({ row }: any = {}) => {
          return (
            <div>
              <Icon
                name="file pdf outline"
                color="red"
                style={{ marginRight: "20px" }}
              />
            </div>
          );
        },
      },
      {
        Header: "",
        Cell: ({ row }: any = {}) => {
          return (
            <div>
              <Button
                style={styles.buttonDowload}
                onClick={() =>
                  handleDownloadPrenatalAndANCRecordList(row._original)
                }
              >
                <FormattedMessage id="bplusClinicKey163" />
              </Button>
            </div>
          );
        },
      },
      {
        Header: "",
        Cell: ({ row }: any = {}) => {
          return (
            <div>
              <Button
                style={styles.buttonDelete}
                onClick={() => {
                  handleRemovePrenatalAndANCRecordList(row._original);
                }}
              >
                <FormattedMessage id="bplusClinicKey153" />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const hiddenFileInput = React.useRef() as MutableRefObject<HTMLInputElement>;

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    console.log("fileUploaded", fileUploaded, fileUploaded.name);
    setFileName(fileUploaded.name);
    setUploadFile(fileUploaded);
    setOutputfileName(true);
  };

  const handleChangeDate = (date: any) => {
    let dateConverter = date.split("/");
    let year = dateConverter[2] - 543;
    let dateSelected = moment(
      `${dateConverter[0]}/${dateConverter[1]}/${year}`,
      "DD/MM/YYYY"
    )
      .locale("en")
      .format("ddd, D MMMM YYYY");
    setSelectedDate(dateSelected);
  };

  const handleChangeDateStart = (date: any) => {
    console.log("date", date);
    let dateConverter = date.split("/");
    let year = dateConverter[2] - 543;
    let showDateStart = moment(
      `${dateConverter[0]}/${dateConverter[1]}/${year}`,
      "DD/MM/YYYY"
    )
      .locale("en")
      .format("ddd, D MMMM YYYY");
    setSearchDateStart(showDateStart);
    let startDate = moment(
      `${year}-${dateConverter[0]}-${dateConverter[1]}`,
      "YYYY-DD-MM"
    ).format("YYYY-DD-MM");
    console.log("year", year);
    console.log("dateConverter", dateConverter[0]);
    console.log("dateConverter", dateConverter[1]);
    console.log("startDate", startDate);
    props.onEvent({
      message: "HandleChangePrenatalAndANCRecordFilter",
      params: { key: "startDate", value: startDate },
    });
  };

  const handleChangeDateEnd = (date: any) => {
    let dateConverter = date.split("/");
    let year = dateConverter[2] - 543;
    let showDateEnd = moment(
      `${dateConverter[0]}/${dateConverter[1]}/${year}`,
      "DD/MM/YYYY"
    )
      .locale("en")
      .format("ddd, D MMMM YYYY");
    setSearchDateEnd(showDateEnd);
    let endDate = moment(
      `${year}-${dateConverter[0]}-${dateConverter[1]}`,
      "YYYY-DD-MM"
    ).format("YYYY-DD-MM");
    props.onEvent({
      message: "HandleChangePrenatalAndANCRecordFilter",
      params: { key: "endDate", value: endDate },
    });
  };

  const handleChangeFileType = (value: any) => {
    console.log("value", value);
    setSearchFileType(value);
    props.onEvent({
      message: "HandleChangePrenatalAndANCRecordFilter",
      params: { key: "fileType", value: value },
    });
  };

  const handleGetTheadThProps = () => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  // Save
  const handleSavePrenatalAndANCRecordList = () => {
    console.log("uploadFile", uploadFile);
    const result: any = {
      measure_date: `${moment(selectedDate).format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      )}`,
      data: {
        fileType: fileType,
        fileName: fileName,
        obstetricName: obstetricName,
        specity: specity,
        uploadFile: uploadFile,
      },
    };
    console.log("result", result);
    props.onEvent({ message: "SavePrenatalAndANCRecordList", params: result });
    setFileType("");
    setFileName("");
    setObstetricName("");
    setSpecity("");
    setUploadFile(null);
  };

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="antenatal.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/mom/antenatal.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey396" />
        </span>
      </div>

      <div className="sub-layout">
        <div style={styles.alignItems}>
          <label style={styles.label}>
            <FormattedMessage id="bplusClinicKey526" />
          </label>
          <DateTextBox
            value={selectedDate}
            onChange={(date: any) => {
              handleChangeDate(date);
            }}
          />
        </div>
        <div style={styles.alignItems}>
          <label style={styles.label}>
            <FormattedMessage id="bplusClinicKey200" />
          </label>
          <Dropdown
            selection
            options={fileTypesOptions}
            value={fileType}
            onChange={(_e, { value }) => setFileType(value)}
          />
          {fileType == "Other" ? (
            <div>
              <label style={styles.label}>
                <FormattedMessage id="bplusClinicKey465" />
              </label>
              <Input
                value={specity}
                onChange={(_e, { value }) => setSpecity(value)}
              />
            </div>
          ) : (
            false
          )}
        </div>
        <div style={styles.alignItems}>
          <label style={styles.label}>
            <FormattedMessage id="bplusClinicKey350" />
          </label>
          <Input
            value={obstetricName}
            onChange={(_e, { value }) => setObstetricName(value)}
          />
        </div>

        <div style={styles.alignItems}>
          <Button
            style={{ backgroundColor: COLOR_CODE.light_blue, color: "white" }}
            onClick={handleClick}
          >
            <FormattedMessage id="bplusClinicKey444" />
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <Button
            style={{ backgroundColor: COLOR_CODE.light_blue, color: "white" }}
            onClick={handleSavePrenatalAndANCRecordList}
          >
            <FormattedMessage id="bplusClinicKey505" />
          </Button>
        </div>

        <div style={{ marginTop: "20px" }}>
          {outputfileName ? (
            <div style={styles.list_file}>
              <Icon
                name="file pdf outline"
                color="red"
                style={{ marginRight: "20px" }}
              />
              {fileName}
              <Icon name="check" color="green" style={{ marginLeft: "20px" }} />
            </div>
          ) : (
            <div style={styles.list_file}>
              <FormattedMessage id="bplusClinicKey577" />
            </div>
          )}
        </div>

        <div
          style={{
            ...styles.alignItems,
            marginTop: "40px",
            marginBottom: "20px",
            justifyContent: "flex-end",
          }}
        >
          <label style={styles.label}>
            <FormattedMessage id="bplusClinicKey441" />
          </label>
          <label style={{ ...styles.label, fontWeight: "normal" }}>
            <FormattedMessage id="bplusClinicKey526" />
          </label>
          <DateTextBox
            value={searchDateStart}
            onChange={(date: any) => {
              handleChangeDateStart(date);
            }}
          />
          <label style={{ ...styles.label, fontWeight: "normal" }}>
            <FormattedMessage id="bplusClinicKey586" />
          </label>
          <DateTextBox
            value={searchDateEnd}
            onChange={(date: any) => {
              handleChangeDateEnd(date);
            }}
          />
          <label style={{ ...styles.label, fontWeight: "normal" }}>
            <FormattedMessage id="bplusClinicKey201" />
          </label>
          <Dropdown
            selection
            options={fileTypesOptions}
            value={searchFileType}
            onChange={(_e, { value }) => {
              handleChangeFileType(value);
            }}
          />
        </div>

        <ReactTable
          className="weight-table-custom web-mom"
          data={props.prenatalAndANCRecordList}
          pageSize={props.prenatalAndANCRecordList?.length}
          columns={columnsRecord}
          style={{ height: "300px" }}
          showPagination={false}
          resizable={false}
          getTheadThProps={handleGetTheadThProps}
        />
      </div>
    </div>
  );
};

WebMomPrenatalAndANCRecord.defaultProps = WebMomPrenatalAndANCRecordInitial;

export default React.memo(WebMomPrenatalAndANCRecord);
