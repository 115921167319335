import { FormattedMessage } from "react-intl";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Image,
  Modal,
  Grid,
  Button,
  Divider,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import {convertToBEDate} from "react-lib/utils/tsUtils";

//component

// Use styles
import "./TeleDesktopCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  gary: "#797878",
  lightGary: "#9D9C9C",
};

const styles = {
  cardPayment: {
    padding: "10px 20px",
  } as CSSProperties,
  button_pay: {
    color: "white",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: "22px",
    borderRadius: "5px",
    background: COLOR.blue,
  } as CSSProperties,
  styleCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  } as CSSProperties,
  styleBetween: {
    padding: "10px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  } as CSSProperties,
  textGray_30px: {
    fontSize: "30px",
    color: COLOR.gary,
  } as CSSProperties,
  textGray_18px_bold: {
    fontSize: "18px",
    color: COLOR.gary,
    fontWeight: "bold",
  } as CSSProperties,
  textlightGray_18px: {
    fontSize: "18px",
    color: COLOR.lightGary,
  } as CSSProperties,
};

type ShowPaymentDetailProps = {
  paymentID?: any;
  close: () => any;
  open: boolean;
  paymentController?: any;
  apiToken: any;
};

const ShowPaymentDetail = (props: ShowPaymentDetailProps) => {
  const [open, setOpen] = useState<boolean | undefined>(false);
  const [invoiceDetail, setInvoiceDetail] = useState<{
    price?: string | number;
    edited?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentRedirect, setPaymentRedirect] = useState({});
  const [invoiceItem, setInvoiceItem] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {}, [paymentRedirect, invoiceItem]);

  useEffect(() => {
    if (props.paymentID) {
      setInvoiceDetail({price:"",edited:""})
      setPaymentRedirect({});
      setInvoiceItem([]);
      handleGetInvoiceDetail();
      handleGetInvoiceItem();
      handlePostPaymentParameters();
    }
  }, [props.paymentID]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handlePostPaymentParameters = async () => {
    setIsLoading(true);
    const [res, err, network] =
      await props.paymentController.postPaymentParameters({
        apiToken: props.apiToken,
        invoiceId: props.paymentID,
      });

    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setPaymentRedirect(res);
      } else {
        setPaymentRedirect({});
      }
    }
  };

  const handleGetInvoiceDetail = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.paymentController.getInvoiceDetail({
      apiToken: props.apiToken,
      invoiceId: props.paymentID,
    });

    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setInvoiceDetail(res);
      } else {
        setInvoiceDetail({});
      }
    }
  };

  const date = invoiceDetail.edited
    ? convertToBEDate({
        date: moment(invoiceDetail.edited).format("DD/MM/YYYY")
      })
    : "";
  const time = invoiceDetail.edited
    ? moment(invoiceDetail.edited).format("HH:mm")
    : "";

  const handleGetInvoiceItem = async () => {
    setIsLoading(true);
    const [res, err, network] =
      await props.paymentController.getInvoiceItemByItem({
        apiToken: props.apiToken,
        invoiceId: props.paymentID,
      });

    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setInvoiceItem(res.items);
      } else {
        setInvoiceItem([]);
      }
    }
  };

  // const genPaymentForm = () => {
  //   return Object.keys(paymentRedirect).map((key) => {
  //     if (key === "endpoint" || key === "method") {
  //       return null;
  //     }
  //     return (
  //       <input
  //         key={key}
  //         type="hidden"
  //         name={key}
  //         value={paymentRedirect[key] ? paymentRedirect[key] : ""}
  //       />
  //     );
  //   });
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal
        onClose={() => props.close()}
        open={open}
        style={{ width: "350px", borderRadius: "5px" }}
      >
        <div style={styles.cardPayment}>
          <Dimmer.Dimmable>
            <Dimmer data-testid="chat-list-loader" active={isLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <Grid style={{ margin: "0" }}>
              <Grid.Row>
                <div style={styles.textlightGray_18px}>
                  {<FormattedMessage id="payment.total_balance" />}
                </div>
              </Grid.Row>
              <Grid.Row>
                <div style={styles.styleCenter}>
                  <div style={styles.textGray_30px}>
                    {invoiceDetail.price ? invoiceDetail.price : ""}
                  </div>
                  <div style={{ ...styles.textGray_30px, paddingLeft: "5px" }}>
                    {<FormattedMessage id="payment.baht" />}
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row>
                <div style={styles.styleBetween}>
                  <div style={styles.textlightGray_18px}>
                    {<FormattedMessage id="common.date" />}
                  </div>
                  <div style={styles.textlightGray_18px}>
                    {date}
                  </div>
                </div>
                <div style={styles.styleBetween}>
                  <div style={styles.textlightGray_18px}>
                    {<FormattedMessage id="common.time" />}
                  </div>
                  <div style={styles.textlightGray_18px}>
                    {time}
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row style={{ margin: "0", padding: "0" }}>
                <Divider style={{ width: "100%", margin: "0" }} />
              </Grid.Row>
              <Grid.Row>
                <div style={{ width: "inherit" }}>
                  {invoiceItem &&
                    invoiceItem.map((item: any) => {
                      return (
                        <div style={styles.styleBetween}>
                          <div style={styles.textlightGray_18px}>
                            {item.pricing_name ? item.pricing_name : ""}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div style={styles.textlightGray_18px}>
                              {item.price ? item.price : ""}
                            </div>
                            <div
                              style={{
                                ...styles.textlightGray_18px,
                                paddingLeft: "5px",
                              }}
                            >
                              {<FormattedMessage id="payment.baht" />}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div style={styles.styleBetween}>
                  <div style={styles.textGray_18px_bold}>
                    {<FormattedMessage id="common.total" />}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={styles.textGray_18px_bold}>
                      {invoiceDetail.price ? invoiceDetail.price : ""}
                    </div>
                    <div
                      style={{
                        ...styles.textGray_18px_bold,
                        paddingLeft: "5px",
                      }}
                    >
                      {<FormattedMessage id="payment.baht" />}
                    </div>
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row
                style={{ justifyContent: "center", padding: "3rem 0 5px" }}
              >
                {/* <form
                  method={paymentRedirect["method"]}
                  action={paymentRedirect["endpoint"]}
                >
                  {genPaymentForm()}
                  
                </form> */}
                {invoiceDetail.status_name !== "CANCELED" &&
                    invoiceDetail.price && (
                      <Button
                        style={styles.button_pay}
                        content={
                          invoiceDetail.status_name === "PAID" ? (
                            <FormattedMessage id="payment.view_receipt" />
                          ) : (
                            <FormattedMessage id="payment.pay" />
                          )
                        }
                        disabled={isLoading}
                        onClick={()=>window.open(`${paymentRedirect["endpoint"]}/?linkcode=${paymentRedirect["linkcode"]}`)}
                      />
                    )}
              </Grid.Row>
            </Grid>
          </Dimmer.Dimmable>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(ShowPaymentDetail);
