import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { Button, Grid, Header, Image } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

import * as KYCVerifyConst from "./KYCVerify";

type KYCPhotoProps = {
  onEvent: any;
  onNext: any;
  patientIdentification: any;
  imageName: string;
  headerText: string;
  videoConstraints: any;
};

const KYCPhotoInitial: KYCPhotoProps = {
  onEvent: () => null,
  onNext: () => null,
  patientIdentification: {},
  imageName: "",
  headerText: "",
};

// const videoConstraints = {
//   width: 220,
//   height: 200,
//   facingMode: "user",
// };

const KYCPhoto: React.FC<KYCPhotoProps> = (props) => {
  const webcamRef = useRef<any>(null);

  const handleCapture = useCallback(() => {
    console.log("----- webcamRef", webcamRef);
    console.log("----- webcamRef.current", webcamRef?.current);
    console.log(
      "----- webcamRef.current.getScreenshot()",
      webcamRef?.current?.getScreenshot()
    );
    const imageSrc = webcamRef?.current?.getScreenshot({});
    if (imageSrc) {
      props.onEvent({
        message: "handleChangePhoto",
        params: { name: props.imageName, value: imageSrc },
      });
      props.onNext();
    } else {
      props.onEvent({
        message: "handleSetMessage",
        params: { errorMessage: "Can't take photo. Please try again." },
      });
    }
  }, []);

  const handleClear = () => {
    props.onEvent({
      message: "handleChangePhoto",
      params: { name: props.imageName, value: null },
    });
  };

  return (
    <>
      <Header
        as="h4"
        textAlign="center"
        style={KYCVerifyConst.styles.textHeader}
      >
        {props.headerText}
      </Header>
      {props.patientIdentification.hasOwnProperty(props.imageName) &&
      props.patientIdentification[props.imageName] !== null ? (
        <>
          <Image
            centered
            src={props.patientIdentification[props.imageName]}
            style={{ minHeight: "200px", minWidth: "200px" }}
          />
          <Grid centered columns={2} style={{ padding: "40px 20px" }}>
            <Grid.Column>
              <Button
                fluid
                color="blue"
                onClick={(event: any) => {
                  event.preventDefault();
                  handleClear();
                }}
              >
                <FormattedMessage id="bplusClinicKey423" />
              </Button>
            </Grid.Column>
          </Grid>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Webcam
            videoConstraints={props.videoConstraints}
            audio={false}
            height={250}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={300}
            screenshotQuality={0.99}
          />
          <Grid centered columns={2} style={{ padding: "40px 20px" }}>
            <Grid.Column>
              <Button
                fluid
                color="blue"
                onClick={(event: any) => {
                  event.preventDefault();
                  handleCapture();
                }}
              >
                <FormattedMessage id="bplusClinicKey482" />
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      )}
    </>
  );
};

KYCPhoto.defaultProps = KYCPhotoInitial;

export default React.memo(KYCPhoto);
