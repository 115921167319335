import { WasmHandler } from 'react-lib/frameworks/WasmController'
import { RequestToken } from 'react-lib/apis/issara/manual';
import axios from "axios";
import config from '../../config/config';
const FAST_API = `${config.FAST_API}`;


export type State = 
  {
    loggedin?: boolean,
    showAlertLoginError?: boolean,
  }

export const StateInitial = 
  {
    loggedin: false,
    showAlertLoginError:false,
  }

export type Event = 
  { message: "LoginIssara", params: {} } |
  { message: "Logout", params: {} }

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = WasmHandler<State, Event, Data>

export const LoginIssara: Handler = async (controller, params) => {
  console.log(params);
  const [r, e, n] = await RequestToken.post({ data: { 
      username: params.username, 
      password: params.password,
      device_type: params.device_type, // "webpush",
      // comname: params.comname
  }});
  if (e) {
      console.log(e, n);
      controller.setState({ showAlertLoginError: true })
      return
  }
  // console.log(r);
  controller.cookies.set("userId", params.username);
  controller.cookies.set("apiToken", r.token);
  controller.cookies.set("division_id", r.division);
  controller.apiToken = r.token
  controller.setState({ loggedin: true })
  // LoginFirebase(controller, { username: params.username })
  console.log('LoginIssara call LoginFirebase1 ');
  LoginFirebase1(controller, { apiToken: r.token })

}

export const LoginFirebase: Handler = (controller, params) => {
  controller.functions.httpsCallable("createTokenFromUser")(params.username)
    .then((result: any) => {
      // console.log("Token", result.data);
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .catch(function (error: any) {
            console.log(error);
        });
    })
    .catch((error: any) => {
        console.log(error);
    });
}

export const LoginFirebase1: Handler = (controller, params) => {
  console.log('LoginFirebase1: ');
  axios.post(
    `${FAST_API}/api/login/get-firebase-token`, {},
    { headers: { "Authorization": `Token ${params.apiToken}`}}
  ).then((result: any) => {
      controller.app
        .auth()
        .signInWithCustomToken(result.data.token)
        .then(async (res: any) => {
          console.log('LoginFirebase1 signInWithCustomToken res: ', res);
          console.log('LoginFirebase1 signInWithCustomToken res.user.getIdToken();: ', res.user.getIdToken());
          // Test login with firebase id token
          // const firebase_id_token = await res.user.getIdToken();
          // axios.post(
          //   `${FAST_API}/api/login/get-firebase-user`, {},
          //   { headers: { "Authorization": `Token ${firebase_id_token}`}}
          // ).then((res: any) => {
          //     console.log("uid", res.data);
          // })
          // .catch((err: any) => {
          //   console.log(err);
          // });
        })
        .catch(function (error: any) {
            // console.log(error);
            console.warn("LoginFirebase1 error: ",error)
        });
    })
    .catch((error: any) => {
        console.warn("LoginFirebase1 error: ",error)
        // console.log(error);
    });
}

export const Logout: Handler = (controller, params) => {
  controller.cookies.set("userId", "");
  controller.cookies.set("apiToken", "");
  controller.cookies.set("division_id", "");
  controller.apiToken = ""
  controller.app.auth().signOut();
  controller.setState({ loggedin: false })
  controller.setState({ showAlertLoginError: false })
}