import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// APIs
import VaccineSeriesList from "issara-sdk/apis/VaccineSeriesList_apps_PHR";

// Serializer
import VaccineSeriesSerializer from "issara-sdk/types/VaccineSeriesSerializer_apps_PHR";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  BE_DATE_FORMAT,
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
  State as MainState,
} from "../MobClinicInterface";

export type State = {
  vaccineDataTabDetail?: any;
  vaccineDataTabHistory?: any;
  vaccineDetail?: any;
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "successMessage"
>;

export const StateInitial = {
  vaccineDataTabDetail: {},
  vaccineDataTabHistory: {},
  vaccineDetail: {},
};

export type Event =
  // GET
  | { message: "handleGetVaccineSummary"; params: {} }
  | { message: "handleGetVaccineHistory"; params: {} }
  | { message: "handleGetVaccineDetail"; params: { id: number } }
  // Save
  | {
      message: "handleSaveSchedule";
      params: { vaccine: any; place: number; date: string };
    }
  | {
      message: "handleCheckedVaccineDetail";
      params: {
        data: any;
        id: number;
        vaccine: number;
        index: number;
        action_type?: "add";
      };
    };

type Handler = WasmHandler<State & Picked, Event>;

const getVaccineSeriesDetail: Handler = async (controller) => {
  const getVaccineSeriesList = VaccineSeriesList.list({
    apiToken: Cookies.get("apiToken"),
    params: { patient_type: "DM" },
  });

  const tmpParams: any = {
    group: GROUP.VACCINE_DM,
    // data_type: DATA_TYPE.VACCINE_DM,
  };

  const getVaccinePatient = commonListPatientDataList(
    controller as any,
    tmpParams
  );

  const [vaccineSeries, vaccinePatient] = await Promise.all([
    getVaccineSeriesList,
    getVaccinePatient,
  ]);

  const vSeriesItems: VaccineSeriesSerializer[] = vaccineSeries[0]?.items || [];
  const vPatientItems: any[] = vaccinePatient[1]?.items || [];

  return { vSeriesItems, vPatientItems, vaccineSeries, vaccinePatient };
};

export const mapVaccineItems = (vSeriesItems: any[], vPatientItems: any[]) => {
  console.log("mapVaccineItems vPatientItems: ", vPatientItems);
  console.log("mapVaccineItems vSeriesItems: ", vSeriesItems);
  const mapVaccine = (item: VaccineSeriesSerializer) => {
    const filterItem: any[] = vPatientItems.filter(
      (acc: any) => acc.data_type === `vaccine_${item.id}` && !acc.data.Manual
    );

    console.log("mapVaccineItems filterItem", filterItem);
    let prepare = new Array(
      item.qty > filterItem.length ? item.qty : filterItem.length
    )
      .fill("")
      .map((acc, cIndex) => {
        const code: string = `${item.abbreviation}${
          item.qty > 1 ? cIndex + 1 : ""
        }`;
        return formatVaccineDMData(filterItem[cIndex] || {}, code);
      });
    console.log("mapVaccineItems prepare", prepare);
    return prepare;
  };

  const manualVaccine = (item: VaccineSeriesSerializer) => {
    const filterItems = vPatientItems
      .filter(
        (acc: any) => acc.data_type === `vaccine_${item.id}` && acc.data.Manual
      )
      .map((item: any) => formatVaccineDMData(item, ""));

    return filterItems;
  };

  const getItems = (item: VaccineSeriesSerializer) => {
    let items = mapVaccine(item);
    let manual = manualVaccine(item);

    const sortItems = [...items, ...manual].sort(sortVaccineByDate);

    items = sortItems.map((acc: any, index: number) => ({
      ...acc,
      code: `${item.abbreviation}${item.qty > 1 ? index + 1 : ""}`,
    }));

    return items;
  };

  let prepareReturn = vSeriesItems.map(
    (item: VaccineSeriesSerializer & { age_range: any[] }, pIndex: number) => {
      return {
        id: item.id,
        name: item.name,
        vaccine_type: item.vaccine_type,
        abbreviation: item.abbreviation,
        add_manually: item.add_manually,
        description: item.description,
        ...(item.age_range && { age_range: item.age_range }),
        qty: item.qty,
        items: [...getItems(item)],
      };
    }
  );
  console.log("mapVaccineItems prepareReturn", prepareReturn);

  return prepareReturn;
};

export const handleGetVaccineSummary: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const { vSeriesItems, vPatientItems, vaccineSeries, vaccinePatient } =
    await getVaccineSeriesDetail(controller, {});

  let result: any = {};
  let errorMessage: any = null;
  if (vaccineSeries?.[1] || vaccinePatient?.[0]) {
    errorMessage = vaccineSeries?.[1] || vaccinePatient?.[0];
  } else {
    result = {
      items: mapVaccineItems(vSeriesItems, vPatientItems),
    };
  }

  console.log(result);
  controller.setState({
    vaccineDataTabDetail: result,
    errorMessage,
    loadingStatus: false,
  });
};

export const handleGetVaccineHistory: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });
  const { vSeriesItems, vPatientItems, vaccineSeries, vaccinePatient } =
    await getVaccineSeriesDetail(controller, {});
  let result: any = {};
  let errorMessage: any = null;
  if (vaccineSeries?.[1] || vaccinePatient?.[0]) {
    errorMessage = vaccineSeries?.[1] || vaccinePatient?.[0];
  } else {
    const mapVaccine = (item: VaccineSeriesSerializer) => {
      const filterItem = vPatientItems
        .filter(
          (acc: any) =>
            acc.data_type === `vaccine_${item.id}` && !acc.data.Manual
        )
        .sort(sortVaccineByDate);

      return new Array(item.qty).fill("").map((acc, cIndex) => {
        const code: string = `${item.abbreviation}${
          item.qty > 1 ? cIndex + 1 : ""
        }`;
        return formatVaccineDMData(filterItem[cIndex] || {}, code);
      });
    };
    const manualVaccine = (item: VaccineSeriesSerializer) => {
      const filterItems = vPatientItems
        .filter(
          (acc: any) =>
            acc.data_type === `vaccine_${item.id}` && acc.data.Manual
        )
        .map((item: any) => formatVaccineDMData(item, ""));
      return filterItems;
    };
    const getItems = (item: VaccineSeriesSerializer) => {
      let items = mapVaccine(item);
      let manual = manualVaccine(item);
      items = [...items.filter((item) => item.date), ...manual].map(
        (acc: any, index: number) => ({
          ...acc,
          code: `${item.abbreviation}${item.qty > 1 ? index + 1 : ""}`,
        })
      );
      return items.filter((item) => item.realData.OEORI_Date);
    };

    result = {
      items: vSeriesItems
        .map((item: any, pIndex: number) => ({
          id: item.id,
          name: item.name,
          abbreviation: item.abbreviation,
          add_manually: item.add_manually,
          items: [...getItems(item).sort(sortVaccineByDate)],
        }))
        .filter((item: any) => item.items.length > 0),
    };
  }

  console.log(result);
  controller.setState({
    vaccineDataTabHistory: result,
    errorMessage,
    loadingStatus: false,
  });
};

export const handleGetVaccineDetail: Handler = (controller, params) => {
  const state = controller.getState();

  // Todo: Remove It
  // let result = {
  //   id: params.id,
  //   name: "Hepatitis B Vaccine (HBV)",
  //   items: [
  //     {
  //       dose: "HBV1 (Hepatitis B เข็มที่ 1)",
  //       date: "04/10/2016",
  //       place: "Bangkok hospital",
  //       inject: true,
  //     },
  //     {
  //       dose: "HBV2(Hepatitis B เข็มที่ 2)",
  //       date: null,
  //       place: null,
  //       inject: false,
  //     },
  //   ],
  let result = {
    ...state.vaccineDataTabDetail.items.find(
      (item: any) => params.id === item.id
    ),
  };

  controller.setState({ vaccineDetail: result });

  // if (params.id) {
  //   // Todo: Get
  // }
};

export const handleSaveSchedule: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("handleSaveSchedule params: ", params);

  let vaccineList = [...state.vaccineDataTabDetail.items];
  let update: any = {};
  if (params.vaccine) {
    vaccineList = vaccineList.map((vaccine) => {
      let vaccineDetail = { ...vaccine };
      if (vaccine.id === state.vaccineDetail.id) {
        vaccineDetail.items = vaccineDetail.items.map((item: any) => {
          return item.code === params.vaccine.code
            ? {
                ...item,
                place_name: params.place_name,
                date: params.date,
                place: params.place,
              }
            : item;
        });
        update = vaccineDetail;
      }
      return vaccineDetail;
    });
  }

  if (state.vaccineDataTabDetail.id) {
    let saveData = {
      id: state.vaccineDataTabDetail.id,
      data: {
        items: vaccineList,
      },
      proxy_patient: state.vaccineDataTabDetail.proxy_patient,
      measure_date: state.vaccineDataTabDetail.measure_date,
    };
    const [error, response] = await commonUpdatePatientData(
      controller as any,
      saveData
    );
    let errorMessage: any = null;
    let successMessage: any = null;
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Update vaccine data success.";
    }

    controller.setState({
      errorMessage: null,
      successMessage: successMessage,
      vaccineDataTabDetail: {
        ...state.vaccineDataTabDetail,
        items: vaccineList,
      },
      vaccineDetail: update,
    });
  } else {
    let saveData = {
      group: GROUP.VACCINE_DM,
      data_type: DATA_TYPE.VACCINE_DM,
      measure_date: moment()
        .locale("en")
        .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
      data: { items: vaccineList },
    };

    const [error, response] = await commonCreatePatientData(
      controller as any,
      saveData
    );
    let errorMessage: any = null;
    let successMessage: any = null;
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Create vaccine data success.";
    }

    controller.setState({
      errorMessage: null,
      successMessage: successMessage,
      vaccineDataTabDetail: {
        id: response.id,
        proxy_patient: response.proxy_patient,
        measure_date: response.measure_date,
        items: vaccineList,
      },
    });
  }
};

export const handleCheckedVaccineDetail: Handler = async (
  controller,
  params: Extract<Event, { message: "handleCheckedVaccineDetail" }>["params"]
) => {
  let { vaccineDetail } = controller.getState();

  controller.setState({ loadingStatus: true });

  let detail = vaccineDetail.items[params.index] || {};

  const data = {
    ...detail.realData,
    ...params.data,
  };

  let saveData: any = {
    id: params.id,
    data,
    proxy_patient: detail.proxy_patient,
    measure_date: (data.OEORI_Date ? moment(data.OEORI_Date) : moment())
      .locale("en")
      .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
  };

  if (!saveData.id) {
    saveData = {
      ...saveData,
      group: GROUP.VACCINE_DM,
      data_type: `${DATA_TYPE.VACCINE}_${params.vaccine}`,
    };

    delete saveData.id;
    delete saveData.proxy_patient;
  }

  let [error, response]: [any, any] = [null, null];

  if (vaccineDetail.qty === 1 && !saveData.data.OEORI_Date) {
    [error, response] = await commonDeletePatientData(controller as any, {
      id: saveData.id,
    });
  } else if (saveData.id) {
    [error, response] = await commonUpdatePatientData(
      controller as any,
      saveData
    );
  } else {
    [error, response] = await commonCreatePatientData(
      controller as any,
      saveData
    );
  }

  if (params.action_type === "add") {
    vaccineDetail.items.push(formatVaccineDMData(response, ""));
  } else {
    if (!response) {
      vaccineDetail.items = vaccineDetail.items.filter(
        (_: any, index: number) => params.index !== index
      );
    } else {
      vaccineDetail.items[params.index] = formatVaccineDMData(
        response,
        detail.code
      );
    }
  }

  vaccineDetail.items = vaccineDetail.items
    .sort(sortVaccineByDate)
    .map((item: any, index: number) => ({
      ...item,
      code: `${vaccineDetail.abbreviation}${
        vaccineDetail.items.length > 1 ? index + 1 : ""
      }`,
    }));

  controller.setState({
    vaccineDetail: { ...vaccineDetail },
    loadingStatus: false,
  });
};

// Utils
export const formatVaccineDMData = (item: any, code: string) => {
  console.log("formatVaccineDMData: item ", item, "code", code);
  return {
    realData: item.data || {},
    data: item.data || {},
    date: item.data?.OEORI_Date
      ? moment(item.data?.OEORI_Date).format(BE_DATE_FORMAT)
      : "",
    place: item.data?.DispenseTypeDesc || "",
    place_name: item.data?.DispenseTypeCode || "",
    code,
    id: item.id,
    measure_date: item.measure_date,
    proxy_patient: item.proxy_patient,
  };
};

export const sortVaccineByDate = (a: any, b: any) => {
  return !a.data?.OEORI_Date
    ? 1
    : !b.data?.OEORI_Date
    ? -1
    : new Intl.Collator().compare(a.data?.OEORI_Date, b.data?.OEORI_Date);
};
