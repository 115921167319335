import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Menu,
  Segment,
  Tab,
} from "semantic-ui-react";
import { AppBar, Slider } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

// UI
import { DateTextBox } from "../../react-lib/apps/common";
import MobHead from "../MobHead";
import PatientPanel from "./PatientPanel";
import SnackMessage from "./SnackMessage";
import MomKickCountChart from "./MomKickCountChart";
import { DMDatePicker } from "./DMEmotionRestChart";

// Interface
import {
  BACKEND_TIME_FORMAT,
  convertToADDate,
  formatDate,
} from "bplus-lib/MobClinicInterface";

import "../../css/WebClinic.scss";

export const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  textKickCount: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1621A8",
    paddingBottom: "15px",
  } as CSSProperties,
  textDate: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1621A8",
    paddingTop: "10px",
    textAlign: "center",
  } as CSSProperties,
  textTotal:{
    justifyContent:"space-between",
    display:"flex",
    padding:"15px"
  } as CSSProperties,
};

export const DATE_FORMAT = "DD/MM/YYYY";

// ---------- Tab Detail
type TabDetailProps = {
  onEvent?: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  kickCountFilterDate?: moment.Moment;
  kickCountDetail?: any;
};

const TabDetail: React.FC<TabDetailProps> = (props) => {
  const intl = useIntl();
  const [lastItem, setLastItem] = useState<any>({});
  const [kickCount, setKickCount] = useState<number>(0);

  useEffect(() => {
    props.onEvent({ message: "handleGetKickCountDetail" });
  }, [props.selectedPatient, props.kickCountFilterDate]);

  useEffect(() => {
    if (props.kickCountDetail?.total > 0) {
      const result: any = {
        ...props.kickCountDetail?.items[props.kickCountDetail.total - 1],
      };
      setLastItem(result);
    } else {
      setLastItem({});
    }
  }, [props.kickCountDetail]);

  // change date
  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleChangekickCountFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: any = props.kickCountFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangekickCountFilterDate",
      params: { date: targetDate },
    });
  };

  // Kick count
  const handleChangeKickCount = (event: object, value: number) => {
    setKickCount(value);
  };

  const handleIncreaseKickCount = () => {
    setKickCount(kickCount + 1);
  };

  const handleDecreaseKickCount = () => {
    if (kickCount <= 0) {
      setKickCount(0);
    } else {
      setKickCount(kickCount - 1);
    }
  };

  // save
  const calculateTotal = (items: any[]) => {
    let result: number = 0;
    items?.forEach((item: any) => {
      result += item.kick_count;
    });
    return result;
  };

  const handleSaveKickCountDetail = () => {
    let kickCountData: any = { ...lastItem };
    if (Object.keys(kickCountData).length === 0) {
      kickCountData = { data: { items: [], total: 0 } };
    }

    kickCountData.data.items.push({
      time: moment().format(BACKEND_TIME_FORMAT),
      kick_count: kickCount,
    });

    kickCountData.data.total = calculateTotal(kickCountData.data.items);

    props.onEvent({
      message: "handleSaveKickCountDetail",
      params: kickCountData,
    });
    setKickCount(0);
  };

  const handleDeleteKickCountItem = (index: number) => {
    let kickCountData: any = { ...lastItem };
    kickCountData.data.items.splice(index, 1);

    kickCountData.data.total = calculateTotal(kickCountData.data.items);
    if (kickCountData.data.total > 0) {
      props.onEvent({
        message: "handleSaveKickCountDetail",
        params: kickCountData,
      });
    } else {
      props.onEvent({
        message: "handleDeleteKickCountDetail",
        params: kickCountData,
      });
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <DMDatePicker
        date={props.kickCountFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
      />

      <Segment
        style={{
          backgroundColor: "var(--theme-bg-color-dm)",
          color: "var(--text-dark-blue)",
          fontWeight: "bold",
        }}
      >
        <Grid centered verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={14} style={{ paddingTop: "30px" }}>
              <Slider
                defaultValue={kickCount}
                value={kickCount}
                min={0}
                step={1}
                max={50}
                scale={(x) => x ** 1}
                marks={[
                  { value: 0, label: "0" },
                  { value: 50, label: "50" },
                ]}
                valueLabelDisplay="on"
                valueLabelFormat={`${kickCount}`}
                onChange={handleChangeKickCount}
              />
            </Grid.Column>
            <Grid.Column
              width={1}
              style={styles.noPadding}
              onClick={handleDecreaseKickCount}
            >
              <Icon.Group size="large">
                <Icon name="trash alternate" />
                <Icon name="reply" corner="bottom left" color="red" />
              </Icon.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column
            width={16}
            style={{
              ...styles.textCenter,
              marginTop: "-50px",
              marginBottom: "4px",
            }}
          >
            <Button
              circular
              icon="child"
              size="massive"
              style={{
                padding: "20px",
                background: "var(--text-light-blue-20)",
              }}
              onClick={handleIncreaseKickCount}
            >
              <Image
                src={"/images/mom/kickcount.png"}
                style={{ width: "50px" }}
              />
            </Button>
          </Grid.Column>

          <Grid.Row style={{ marginTop: "-40px" }}>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={11} style={{ ...styles.textCenter }}>
              <FormattedMessage id="bplusClinicKey17" />
            </Grid.Column>{" "}
            <Grid.Column
              width={2}
              style={{ ...styles.textCenter }}
              onClick={handleSaveKickCountDetail}
            >
              <Icon name="save" size="large" />
              <FormattedMessage id="bplusClinicKey583" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Grid
        verticalAlign="middle"
        style={{ padding: "20px", display: "inline-block", width: "100%" }}
      >
        <Grid.Row
          style={{
            ...styles.textCenter,
            background: "#9fdbf8",
            color: "var(--text-grey)",
            fontWeight: "bold",
            margin: "0px 15px",
          }}
        >
          <Grid.Column width={7}></Grid.Column>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey405" />
          </Grid.Column>
          <Grid.Column width={5}>
            <FormattedMessage id="bplusClinicKey153" />
          </Grid.Column>
        </Grid.Row>
        {lastItem?.data?.items?.map((item: any, index: number) => {
          return (
            <Grid.Row
              key={index}
              style={{
                margin: "0px 10px",
                padding: "5px",
              }}
            >
              <Grid.Column width={8} style={{ fontWeight: "bold" }}>
                <FormattedMessage id="bplusClinicKey78" />
              </Grid.Column>
              <Grid.Column
                width={4}
                style={{ ...styles.textCenter, color: "var(--text-grey)" }}
              >
                {item.kick_count}
              </Grid.Column>
              <Grid.Column width={4} style={{ ...styles.textCenter }}>
                <Icon
                  name="minus circle"
                  color="red"
                  size="large"
                  onClick={() => {
                    handleDeleteKickCountItem(index);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <div style={{...styles.textTotal}}>
          <div style={{ textAlign: "right" }}>
          <Header as="h3" style={{ color: "var(--text-grey)" }}>
              <FormattedMessage id="bplusClinicKey497" />
            </Header>
          </div>
          <div>
          <Header as="h2" style={{ color: "var(--text-dark-blue)",marginBottom:"1px" }}>
              {lastItem?.data?.total || 0}
            </Header>
          </div>
          <div>
          <Header
              as="h3"
              style={{ color: "var(--text-grey)" }}
            >
              <FormattedMessage id="bplusClinicKey814" />
            </Header>
          </div>
        </div>
      </Grid>

      <Segment
        style={{
          backgroundColor: "var(--theme-bg-color-dm)",
          color: "var(--text-dark-blue)",
          margin: "5px",
        }}
      >
        <Header as="h4" style={{ color: "var(--text-dark-blue)" }}>
          <FormattedMessage id="bplusClinicKey413" />
        </Header>
        <Header as="h4" style={{ color: "var(--text-grey)", margin: "0px" }}>
          {`${intl.formatMessage({ id: "bplusClinicKey923" })}`}
        </Header>
        <Header as="h4" style={{ color: "var(--text-grey)", margin: "0px" }}>
          {`>> ${intl.formatMessage({ id: "bplusClinicKey908" })}`}
        </Header>
      </Segment>
    </div>
  );
};
// ---------- MomKickCount
type MomKickCountProps = {
  // function
  onEvent?: any;
  onSetBackToApp?: () => any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  kickCountFilterDate?: moment.Moment;
  kickCountFilterDuration?: string;
  kickCountDetail?: any;
  kickCountHistory?: any;
};

const MomKickCountInitial: MomKickCountProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  kickCountFilterDate: moment(),
  kickCountFilterDuration: "6",
  kickCountDetail: {},
  kickCountHistory: {},
};

const MomKickCount: React.FC<MomKickCountProps> = (props) => {
  const intl = useIntl();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    setTabIndex(0);
  }, [props.selectedPatient]);

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="counter" style={styles.tabs}>
            <FormattedMessage id="bplusClinicKey133" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  // ----- Tab
  const handleTabChange = (event: object, data: any) => {
    setTabIndex(data.activeIndex);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const handleLeftIconClick = () => {
    props.onSetBackToApp?.();
  };

  return (
    <>
      <AppBar position="fixed" color="default">
        <MobHead
          // title={`${props.selectedPatient?.first_name} ${props.selectedPatient?.last_name}`}
          title={intl.formatMessage({ id: "bplusClinicKey78" })}
          leftIcon="arrow left"
          leftIconClick={handleLeftIconClick}
        />
      </AppBar>

      <PatientPanel
        patientInfo={props.selectedPatient}
        useBackgroundGradient
        // titleFooter="Baby kick count"
      />

      <Tab
        className="weight-tab-custom"
        style={{ marginTop: "10px" }}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={tabIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews index={tabIndex} onChangeIndex={handleChangeIndex}>
        <TabDetail
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          kickCountFilterDate={props.kickCountFilterDate}
          kickCountDetail={props.kickCountDetail}
        />
        <MomKickCountChart
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          kickCountFilterDuration={props.kickCountFilterDuration}
          kickCountHistory={props.kickCountHistory}
          height={200}
          isMyTab={tabIndex === 1}
        />
      </SwipeableViews>
    </>
  );
};

MomKickCount.defaultProps = MomKickCountInitial;

export default React.memo(MomKickCount);
