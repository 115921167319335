import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useState, useMemo, useEffect, useRef, Ref } from "react";
import {
  Sidebar,
  Menu,
  Icon,
  Segment,
  Header,
  Image,
  Button,
  Search,
  Table,
  Grid,
  Modal,
} from "semantic-ui-react";

//component
import "./TeleDesktopCSS.scss";
import ChatList from "./ChatListDesktop";
import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import ShowPaymentDetail from "./ShowPaymentDetail";
import Payment from "./Payment";
import * as MainVideoCall from "../MainVideoCall";
import IncomingDetail from "./IncomingDetail";
import CONFIG from "config/config";
import AskPermission from "./AskNotiPermission";
import ChatTeleDeskTop from "./ChatTeleDeskTop";
import DiagBubble from "./DiagBubble";
import Cookies from "js-cookie";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
};

const styles = {
  profile_image: {
    width: "80px",
    padding: "5px",
    margin: "20px 10px",
    borderRadius: "10px",
    background: "white",
  } as CSSProperties,
  title_logo: {
    fontSize: "20px",
    color: COLOR.primary,
    alignItems: "center",
    fontWeight: "bold",
    display: "flex",
    marginLeft: "20px",
  } as CSSProperties,
  payment_titlebar: {
    background: COLOR.blue,
    padding: "5px 0px",
    color: "white",
    margin: "10px 0px",
  } as CSSProperties,
  button_pay: {
    padding: "10px",
    color: "white",
    background: COLOR.blue,
  } as CSSProperties,
};

type MainTeleDesktopProps = {
  chatListRef: Ref<unknown> | undefined;
  chatBoxRef: Ref<unknown> | undefined;
  onEvent: (e: any) => any;
  apiToken?: any;
  chatController: any;
  diagFormController: any;
  userId?: any;
  channelList?: any;
  loadChannelList: () => any;
  followDivisionProfile: (item: any) => any;
  unfollowChannelList: any;
  isLoading?: any;
  patientInfo?: any;
  paymentController?: any;
  setProp: any;
  openVideoCallModal?: boolean;
  videoCallRoom?: any;
  focusSelectMenu?: any;
  handleSetFocusSelect: () => any;
  incomingCalling: boolean;
  handleOpenVideoCall: () => any;
  handleCloseVideoCall: () => any;
  incomingDetail: any;
  askPermission?: boolean;
  handlePermission: Function;
  loadingStatusPayment?: Record<string, any>;
};

const MainTeleDesktop = (props: MainTeleDesktopProps) => {
  const [activeItem, setActiveItem] = useState<string>("chat");
  const [selectChat, setSelectChat] = useState<any>({
    id: "",
    division: "",
    channel_Name: "",
    icon: "",
  });
  const [isDiag, setIsDiag] = useState<any>({
    id: 0,
    isOpen: false,
  });
  const [searchBox, setSearchBox] = useState<string>("");
  const [keyWord, setkeyWord] = useState<string>("");
  const [chatListSelect, setChatListSelect] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [paymentID, setPaymentID] = useState<any>(null);
  const [lockChatList, setLockChatList] = useState<boolean>(true);
  const [screenLandscape, setScreenLandscape] = useState<boolean>(true);
  var chatBoxRef = useRef(null);
  // let audio = new Audio("ringtone.mp3")
  const [audio, setaudio] = useState<string>("");
  const state = {
    // Get audio file in a variable
    audio: new Audio("ringtone.mp3"),
    // Set initial state of song
    isPlaying: false,
  };

  const mobpayment = [
    {
      date: "19/08/2021 10:15",
      pay: "500",
      paid: false,
    },
    {
      date: "19/15/2021 10:15",
      pay: "10000",
      paid: false,
    },
    {
      date: "8/08/2022 10:15",
      pay: "15000",
      paid: false,
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      checkScreenPosition();
    });
    checkScreenPosition();
  }, []);

  useEffect(()=> {
    const userId = Cookies.get("userId") || "";
    localStorage.setItem(
      "isTeleDeskTop",
      JSON.stringify({[userId]: true })
    );
  }, []);

  useEffect(() => {
    if (props.focusSelectMenu && props.channelList) {
      setChatListSelect(props.focusSelectMenu);
      const filterList = props.channelList.filter(
        (item: any) => item.id === props.focusSelectMenu
      );
      setActiveItem("chat");
      var iconName = "/logo512.png";

      props.handleSetFocusSelect();
      if (filterList.length !== 0) {
        if (
          filterList.division_feature === CONFIG.DM_FEATURE ||
          filterList.division_feature === CONFIG.MOM_FEATURE ||
          filterList.division_feature === CONFIG.CHILD_FEATURE
        ) {
          iconName = getIcon(filterList.division_feature);
        }

        handleSelectChat(
          filterList[0].id,
          filterList[0].division,
          filterList[0].name,
          iconName
        );
      }
    }
  }, [props.focusSelectMenu]);

  const checkScreenPosition = () => {
    if (window.innerWidth < window.innerHeight) {
      setScreenLandscape(false);
    } else {
      setScreenLandscape(true);
    }

    if (window.innerWidth > 992) {
      setLockChatList(true);
    } else {
      setLockChatList(false);
    }
  };

  const getIcon = (feature: any) => {
    let iconName = "/logo512.png";
    // console.log(" getIcon: ", feature);
    switch (feature) {
      case CONFIG.DM_FEATURE:
        iconName = "/dm.png";
        break;
      case CONFIG.MOM_FEATURE:
        iconName = "/mom.png";
        break;
      case CONFIG.CHILD_FEATURE:
        iconName = "/child.png";
        break;
      default:
        break;
    }
    return iconName;
  };

  const handleSelectChat = (
    id: string,
    division: string,
    channel_Name: string,
    icon: string
  ) => {
    setChatListSelect(id);
    setSelectChat({
      id: id,
      division: division,
      channel_Name: channel_Name,
      icon: icon,
    });
    setIsDiag({});
  };

  const handleSearchChat = (value: any) => {
    setSearchBox(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setkeyWord(value);
      }, 800)
    );
  };

  const handleOpenPayment = (item: any) => {
    setOpenPayment(true);
    setPaymentID(item);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  const handleNavigationMessage = ({
    content = "",
    contentPayload = "",
    contentType,
    channelId,
  } = {}) => {
    if (content.includes("{หน้าโปรไฟล์}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(/{หน้าโปรไฟล์}/g, "หน้าโปรไฟล์"),
          }}
        />
      );
    } else if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ตารางออกตรวจผู้ให้คำปรึกษา}/g,
              "ตารางออกตรวจผู้ให้คำปรึกษา"
              // "<a href='/Appointment'>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
            ),
          }}
        />
      );
    } else if (content.includes("{กดเพื่อยืนยันการจัดส่งยา}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{กดเพื่อยืนยันการจัดส่งยา}/g,
              "กรุณาระบุที่อยู่จัดส่ง"
            ),
          }}
        />
      );
    } else if (content.includes("{ดูประวัติการนัดหมาย}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ดูประวัติการนัดหมาย}/g,
              "ดูประวัติการนัดหมาย"
              // "<a href='/VCAppointmentView'>ดูประวัติการนัดหมาย</a>"
            ),
          }}
        />
      );
    } else if (contentType === "bill") {
      return (
        <div
          onClick={() => {
            setPaymentID(contentPayload.invoice_id);
            setOpenPayment(true);
          }}
        >
          <a nohref>{content}</a>
        </div>
      );
    } else if (contentType === "diag_rule") {
      return (
        <DiagBubble
          apiToken={props.apiToken}
          diagFormController={props.diagFormController}
          contentPayload={contentPayload}
          diagRuleId={contentPayload.diag_rule_id}
          onclick={(publishDiag:number) =>
            setIsDiag({ id: publishDiag, isOpen: true })
          }
        />
      );
    } else {
      return content;
    }
  };

  /*---------------------- MainTeleDesktop section ------------------------ */

  /*------ chat ----------------------------------------------------------- */
  /*------------| Main menu |  chat list |  chat box  | ------------------- */

  /*------ payment -------------------------------------------------------- */
  /*------------| Main menu |      payment content    |  ------------------ */

  /*----------------------------------------------------------------------- */

  return (
    <div>
      <Sidebar.Pushable
        as={Segment}
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: screenLandscape ? "150px auto" : "70px auto",
            height: "100vh",
            width: "100%",
            overflowY: "hidden",
          }}
        >
          <div
            style={{
              gridColumn: 1,
            }}
          >
            {/*----------------- Sidebar Mainmenu & Profile icon --------------------- */}

            <Sidebar
              as={Menu}
              animation={"push"}
              direction={"left"}
              vertical
              visible={true}
              style={{
                background: COLOR.primary,
                width: screenLandscape ? "150px" : "70px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginTop: screenLandscape ? "30%" : "40%" }}>
                  {/*----------------- menu  --------------------- */}

                  <Menu.Menu className="tele_menu">
                    <Menu.Item
                      name="chat"
                      active={activeItem === "chat"}
                      onClick={() => setActiveItem("chat")}
                    >
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0",
                          fontSize: screenLandscape ? "16px" : "20px",
                        }}
                      >
                        <Icon name="chat" inverted />
                        <div
                          style={{
                            color: "white",
                            paddingLeft: "10px",
                            ...(!screenLandscape && { display: "none" }),
                          }}
                        >
                          {"chat"}
                        </div>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      name="payment"
                      active={activeItem === "payment"}
                      onClick={() => {
                        setActiveItem("payment");
                        state.audio.pause();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          margin: "5px 0",
                          fontSize: screenLandscape ? "16px" : "20px",
                        }}
                      >
                        <Icon name="payment" inverted />
                        <div
                          style={{
                            color: "white",
                            paddingLeft: "10px",
                            ...(!screenLandscape && { display: "none" }),
                          }}
                        >
                          {"payment"}
                        </div>
                      </div>
                    </Menu.Item>
                  </Menu.Menu>
                </div>

                <div style={{ marginTop: "30%" }}>
                  <Menu.Item
                    name="logout"
                    onClick={(e) => {
                      props.onEvent({
                        message: "logout",
                        params: {},
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        fontSize: screenLandscape ? "16px" : "25px",
                        ...(!screenLandscape && { marginBottom: "20%" }),
                      }}
                    >
                      <Image
                        src={"/images/icons_exit.png"}
                        style={{ width: screenLandscape ? "19px" : "33px" }}
                      />
                      <div
                        style={{
                          color: "white",
                          paddingLeft: "10px",
                          ...(!screenLandscape && { display: "none" }),
                        }}
                      >
                        {"sign-out"}
                      </div>
                    </div>
                  </Menu.Item>

                  {/*----------------- Profile icon --------------------- */}

                  <div
                    style={{
                      ...styles.profile_image,
                      ...(!screenLandscape && { display: "none" }),
                    }}
                  >
                    <div>
                      <Image
                        src={
                          props.patientInfo?.image
                            ? props.patientInfo?.image
                            : "/images/Feed/doctor.png"
                        }
                        style={{ width: "70px", height: "70px" }}
                        circular
                        onError={(error: any) => {
                          error.currentTarget.src = "/images/Feed/doctor.png";
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Sidebar>
          </div>

          {/*----------------- Menu Chat list & icon profile --------------------- */}
          <div
            style={{
              gridColumn: 2,
            }}
          >
            {/*----------------- content after selected menu chat  --------------------- */}

            {activeItem === "chat" ? (
              <ChatTeleDeskTop
                {...props}
                channelList={props.channelList}
                lockChatList={lockChatList}
                handleSelectChat={handleSelectChat}
                selectChat={selectChat}
                isDiag={isDiag}
                onCloseDiagForm={() => setIsDiag({})}
                handleNavigationMessage={handleNavigationMessage}
                chatListSelect={chatListSelect}
              />
            ) : (
              <>{/* section payment is not show */}</>
            )}

            {/*----------------- content payment after selected menu  --------------------- */}

            {activeItem === "payment" ? (
              <Sidebar.Pusher>
                <div>
                  {/*----------------- Payment --------------------- */}

                  <Payment
                    onEvent={props.onEvent}
                    mobpayment={mobpayment}
                    clickButton={handleOpenPayment}
                    paymentController={props.paymentController}
                    apiToken={props.apiToken}
                    patientInfo={props.patientInfo}
                    open={openPayment}
                    loadingStatusPayment={props.loadingStatusPayment}
                  />
                </div>
              </Sidebar.Pusher>
            ) : (
              ""
            )}

            {/********************** section Modal popup ************************/}

            {/*----------------- ShowPaymentDetail ----------------- */}

            <div>
              <ShowPaymentDetail
                paymentID={paymentID}
                close={handleClosePayment}
                open={openPayment}
                paymentController={props.paymentController}
                apiToken={props.apiToken}
              />
            </div>

            {/*----------------- MainVideoCall --------------------- */}

            <MainVideoCall.Main
              onEvent={props.onEvent}
              setProp={props.setProp}
              openVideoCallModal={props.openVideoCallModal}
              videoCallRoom={props.videoCallRoom}
              isPatient={true}
              username={props.patientInfo?.first_name}
              incomingDetail={props.incomingDetail}
              userId={props.userId}
              controller={props.chatController}
              apitoken={props.apiToken}
            />

            {/*----------------- IncomingDetail --------------------- */}

            <IncomingDetail
              open={props.incomingCalling}
              handleOpenVideoCall={props.handleOpenVideoCall}
              handleCloseVideoCall={props.handleCloseVideoCall}
              incomingDetail={props.incomingDetail}
              userId={props.userId}
              controller={props.chatController}
              apitoken={props.apiToken}
            />

            {/*----------------- AskPermission Modal ----------------- */}

            <AskPermission
              open={props.askPermission}
              handlePermission={props.handlePermission}
            />
          </div>
        </div>
      </Sidebar.Pushable>
    </div>
  );
};

export default React.memo(MainTeleDesktop);
