import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

import {
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonCreatePatientData,
  commonUpdatePatientData,
  BACKEND_TIME_FORMAT,
  BACKEND_DATE_FORMAT,
  calculateDelivery,
} from "../MobClinicInterface";
import PatientSegmentDetail from "../../issara-sdk/apis/PatientSegmentDetail_apps_PHRM";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";

export type State = {
  profileHealth?: any[];
  profileObstretic?: any;
};

export const StateInitial = {
  profileHealth: [],
  profileObstretic: {},
};

export type Event =
  // GET
  | { message: "handleGetProfileHealth"; params: {} }
  | { message: "handleGetProfileObstretic"; params: {} }
  // SAVE
  | { message: "handleSaveProfileObstretic"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetProfileHealth: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let promiseArr: any[] = [];
  promiseArr.push(
    commonGetPatientDataLatest(controller, {
      group: GROUP.PREGNANCY_WEIGHT,
      data_type: DATA_TYPE.PREGNANCY_WEIGHT,
    })
  );
  promiseArr.push(
    commonGetPatientDataLatest(controller, {
      group: GROUP.BREAST_FEEDING,
      data_type: DATA_TYPE.BREAST_FEEDING,
    })
  );
  promiseArr.push(
    commonGetPatientDataLatest(controller, {
      group: GROUP.CONTRACTION_COUNT,
      data_type: DATA_TYPE.CONTRACTION_COUNT,
    })
  );
  promiseArr.push(
    commonGetPatientDataLatest(controller, {
      group: GROUP.KICK_COUNT,
      data_type: DATA_TYPE.KICK_COUNT,
    })
  );

  // Get
  const failResponse = await Promise.all(promiseArr);

  let errorMessage: any[] = [];
  let result: any[] = [];

  failResponse.forEach((resp: any) => {
    if (resp[0]) {
      errorMessage.push(resp[0]);
    } else {
      if (
        resp[1]?.group === GROUP.PREGNANCY_WEIGHT &&
        resp[1]?.data_type === DATA_TYPE.PREGNANCY_WEIGHT
      ) {
        result.push({ name: params.intl.formatMessage({ id: "bplusClinicKey537" }), value: resp[1]?.data?.status });
      } else if (
        resp[1]?.group === GROUP.BREAST_FEEDING &&
        resp[1]?.data_type === DATA_TYPE.BREAST_FEEDING
      ) {
        result.push({ name: params.intl.formatMessage({ id: "bplusClinicKey788" }), value: resp[1]?.data?.text });
      } else if (
        resp[1]?.group === GROUP.CONTRACTION_COUNT &&
        resp[1]?.data_type === DATA_TYPE.CONTRACTION_COUNT
      ) {
        result.push({ name: params.intl.formatMessage({ id: "bplusClinicKey124" }), value: resp[1]?.data?.status });
      } else if (
        resp[1]?.group === GROUP.KICK_COUNT &&
        resp[1]?.data_type === DATA_TYPE.KICK_COUNT
      ) {
        result.push({ name: params.intl.formatMessage({ id: "bplusClinicKey77" }), value: resp[1]?.data?.status });
      }
    }
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage.length > 0 ? errorMessage : null,
    successMessage: null,
    profileHealth: result,
  });
};

export const handleGetProfileObstretic: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });
  const { selectedPatient } = controller.getState();
  const tmpParams: any = {
    group: GROUP.OBSTRETIC_RECORD,
    data_type: DATA_TYPE.OBSTRETIC_RECORD,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller,
    tmpParams
  );
  if (error) {

  } else {
    if (response) {
  let data = calculateDelivery(
    response.data.by_ultrasound,
    moment(),
    moment(response.data.lmp_date, BACKEND_DATE_FORMAT),
    response.data.edc_date
      ? moment(response.data.edc_date, BACKEND_DATE_FORMAT)
      : null
  );

  let tempData = { ...response, data: { ...response.data, ...data } };

  const [isUpdateSegment, calculateResult] = await handleUpdateMomSegment(
    controller,
    { ...tempData }
  );
  if (isUpdateSegment) {
    controller.setState({
      selectedPatient: { ...selectedPatient, result: calculateResult },
    });
  }
  }
  }
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: null,
      profileObstretic: response,
    });  
};

// SAVE
export const handleSaveProfileObstretic: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });
  const { selectedPatient } = controller.getState();
  let error: any = null;
  let response: any = null;

  let data = { ...params };
  data.measure_date = `${data?.data?.edc_date ? data?.data?.edc_date : moment().format("YYYY-MM-DD") } ${moment().format(
    BACKEND_TIME_FORMAT
  )}`;
  if (params?.id) {
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    data.group = GROUP.OBSTRETIC_RECORD;
    data.data_type = DATA_TYPE.OBSTRETIC_RECORD;
    [error, response] = await commonCreatePatientData(controller, data);
  }

  const [isUpdateSegment, calculateResult] = await handleUpdateMomSegment(
    controller,
    { ...params }
  );

  let successMessage: any = null;
  if (error) {
  } else {
    successMessage = "Save LMP date success.";
    await handleGetProfileObstretic(controller);
  }

  if (isUpdateSegment) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
    });
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
      selectedPatient: { ...selectedPatient, result: calculateResult },
    });
  }
};

// utility
const handleUpdateMomSegment: Handler = async (controller, params) => {
  const { selectedPatient } = controller.getState();
  let patientResult = selectedPatient.result;

  let latestResult = "";
  if (params?.data?.trimester) {
    // console.log("params?.data?.trimester",params?.data?.trimester)

    if (params?.data?.trimester?.label === "1st") {
      console.log("ผู้ป่วย Mom Pregnancy: 0-13 weeks");
      latestResult = "ผู้ป่วย Mom Pregnancy: 0-13 weeks";
    }
    if (params?.data?.trimester?.label === "2nd") {
      console.log("ผู้ป่วย Mom Pregnancy: 14-27 weeks");
      latestResult = "ผู้ป่วย Mom Pregnancy: 14-27 weeks";
    }
    if (params?.data?.trimester?.label === "3rd") {
      console.log("ผู้ป่วย Mom Pregnancy: 28-40 weeks");
      latestResult = "ผู้ป่วย Mom Pregnancy: 28-40 weeks";
    }
  }

  if (patientResult && latestResult && patientResult !== latestResult) {
    const [res, err, net] = await PatientSegmentDetail.patch({
      pk: selectedPatient.id,
      apiToken: controller.apiToken || Cookies.get("apiToken "),
      data: {
        result: latestResult,
      },
    });
    if (err) {
      console.log("err: ", err);
    }
    return [true, latestResult];
  }
  return [false, patientResult];
};
