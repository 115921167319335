import ChatService from "./ChatService";
import { to, setStateAsync } from "../react-lib/utils";

export default class ChatManager {

  getChatChannel = async ({ apiToken, userId, search }) => {
    let headers = {};
    let params = { user_type: "PATIENT" };
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    if (userId) {
      params.user = userId;
    }
    if (search) {
      params.search = search;
    }
    const chatService = new ChatService();
    const [error, response] = await to(
      chatService.getChatChannel({ headers, params })
    );
    return [response, error];
  };

  getLasterChatChannel = async ({ apiToken, userId }) => {
    let headers = {};
    let params = {};
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    if (userId) {
      params.user_id = userId;
    }
    const chatService = new ChatService();
    const [error, response] = await to(
      chatService.getLasterChatChannel({ headers, params })
    );
    return [response, error];
  };

  
  getOfficialChannel = async ({ apiToken, userId, search, followed, not_followed }) => {
    let headers = {};
    let params = { user_type: "PATIENT" };
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    if (userId) {
      params.user = userId;
    }
    if (search) {
      params.search = search;
    }

    if (followed) {
      params.followed = followed;
    }


    if (not_followed) {
      params.not_followed = not_followed;
    }
    const chatService = new ChatService();
    const [error, response] = await to(
      chatService.getOfficialChannel({ headers, params })
    );
    return [response, error];
  };


  postFollowDivisionProfile = async ({apiToken, division_profile_id}) => {
    let headers = {}
    if (apiToken) {
        headers.Authorization = `Token ${apiToken}`;
    }
    let data = { }
    // console.log("updateDiagForm Request: ",JSON.stringify(data));
    const chatService = new ChatService();
    const [error, response] = await to(chatService.postFollowDivisionProfile({division_profile_id, headers, data }));
    // console.log("updateDiagForm response: ",JSON.stringify(response));
    // console.log("updateDiagForm response: ",JSON.stringify(error));
    return [response, error];

  }

  postunFollowDivisionProfile = async ({apiToken, division_profile_id}) => {
    let headers = {}
    if (apiToken) {
        headers.Authorization = `Token ${apiToken}`;
    }
    let data = { }
    // console.log("updateDiagForm Request: ",JSON.stringify(data));
    const chatService = new ChatService();
    const [error, response] = await to(chatService.postunFollowDivisionProfile({division_profile_id, headers, data }));
    // console.log("updateDiagForm response: ",JSON.stringify(response));
    // console.log("updateDiagForm response: ",JSON.stringify(error));
    return [response, error];

  }

  getProxyPatientHasDivisionList = async ({ apiToken, feature_only}) => {
    let headers = {}
    let params = { };
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }

    if (feature_only) {
      params.feature_only = feature_only;
    }

    const chatService = new ChatService();
    const [error, response] = await to(chatService.getProxyPatientHasDivisionList({ headers, params}));
    // console.log("updateDiagForm response: ",JSON.stringify(response));
    // console.log("updateDiagForm response: ",JSON.stringify(error));
    return [response, error];
  }
}
