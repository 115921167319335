import React, { ReactElement } from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import CloseIcon from "@mui/icons-material/Close";

// I.Styles
import { cardModStyles } from "./CardKYC";
import { FormattedMessage } from "react-intl";

// Types
type ModAccessVerifyProps = {
  // config
  firstApp?: boolean;
  buttonText?: string | ReactElement;
  // callback
  onApprove: () => any;
  onDeny: () => any;
  onClose: () => any;
};

// Images
const IMAGES = {
  profile: "/images/register/profile.png",
  button_account: "/images/register/button-account.png",
  hospital: "/images/register/button-hospital.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  very_light_blue: "#d6e1f1",
  very_light_grey: "rgba(233, 233, 233, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
  grey: "rgba(159, 159, 159, 1)",
};

const useStyles = makeStyles((theme) => ({
  card: cardModStyles,
  first_app: {
    "& img": {
      width: "35px",
    },
    "& .connector": {
      borderLeft: `2px solid ${COLORS.very_light_grey}`,
      height: "15px",
      margin: "-7px 0 0px 17px",
    },
    "& .grid": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "base-line",
      "& .title": {
        fontWeight: "bold",
        fontSize: "1.125rem",
        marginLeft: "1rem",
        marginTop: "0.35rem",
        "&.--blue": {
          color: COLORS.blue,
        },
        "&.--grey": {
          color: COLORS.light_grey,
        },
      },
      "& ul": {
        paddingLeft: "1rem",
        fontSize: "1rem",
        color: COLORS.grey,
        lineHeight: 0.9,
        margin: "1rem 0 0.25rem",
        fontWeight: "normal",
      },
    },
  },
}));

const ModAccessVerify = (props: ModAccessVerifyProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} className="smart-dialog-container">
        <div className="close-icon-right">
          <MuiButton
            color="primary"
            variant="text"
            style={{ position: "absolute", top: "20px", right: "20px" }}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiButton>
        </div>
        <DialogContent>
          <div className="content">
            <img src={IMAGES.profile} alt="profile" />
            <div className="title" style={{ margin: "0.5rem 0 1rem" }}>
              <FormattedMessage id="bplusClinicKey1069" />
            </div>
            <div>
              <FormattedMessage id="bplusClinicKey1070" />
            </div>
          </div>

          <div className={classes.card}>
            <div>
              {props.firstApp ? (
                <div className={classes.first_app}>
                  <div className="grid">
                    <div>
                      <img src={IMAGES.button_account} alt="button account" />
                    </div>
                    <div className="title --blue">
                      <FormattedMessage id="bplusClinicKey969" />
                    </div>
                  </div>
                  <div className="connector"></div>
                  <div className="grid">
                    <div>
                      <img src={IMAGES.hospital} alt="button hospital" />
                    </div>
                    <div className="title --grey">
                      <div>
                        <FormattedMessage id="bplusClinicKey1023" />
                      </div>
                      <div>
                        <ul>
                          <li>
                            <FormattedMessage id="bplusClinicKey1095" />
                          </li>
                          <li style={{ marginTop: "1rem" }}>
                            <FormattedMessage id="bplusClinicKey1096" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <FormattedMessage id="bplusClinicKey1092" />
                  </div>
                  <div>{"\u00a0"}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      whiteSpace: "pre-line",
                      flexWrap: "wrap",
                    }}
                  >
                    {<FormattedMessage id="bplusClinicKey1093" />}{" "}
                    {<FormattedMessage id="bplusClinicKey1094" />}{" "}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              style={{ width: "87.5%" }}
              onClick={props.onApprove}
            >
              {props.buttonText || <FormattedMessage id="bplusClinicKey1024" />}
            </MuiButton>
          </div>

          <div
            aria-hidden="true"
            style={{
              textAlign: "center",
              color: COLORS.blue,
              marginBottom: "1rem",
            }}
            onClick={props.onDeny}
          >
            <FormattedMessage id="bplusClinicKey970" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(ModAccessVerify);
