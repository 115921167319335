import React, {
  CSSProperties,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import moment from "moment";

//cssF
import { Grid, Image, Item } from "semantic-ui-react";

// interface
import { State, Event } from "../MobFeedInterface";
import { PostData } from "../post/PostExInterface";
import { PromotionData } from "../post/PromotionExInterface";

import { BACKEND_DATE_FORMAT } from "bplus-lib/MobClinicInterface";
import { MobContentSkeleton } from "./MobContentWithLazy";

declare global {
  var onYouTubeIframeAPIReady: any;
}

type MobContentDetailProps = {
  onEvent?: (e: Event) => any;
  myProfileDetail?: any;
  item: PostData & PromotionData;
  isNextVisible?: number;
  index?: number;
  firebaseIsAnonymous?: boolean;
  // callback
  handleSeeMoreClick?: (item?: any, player?: any) => any;
  onButtonClick?: OnClickType;
  followOnly?: boolean;
  preferredOnly?: boolean;
  saveOnly?: boolean;
  selectedHospital?: any;
  stopAllVideo?: () => void;
} & Pick<State, "language" | "shareContentTo" | "apiToken">;

export type ActionType =
  | "like"
  | "click"
  | "save"
  | "follow"
  | "share"
  | "reach";

export type OnClickParams = {
  id: string | null;
  active: boolean;
  type: ActionType;
  content_type: PostData["type"];
  onSuccess?: () => any;
};

export type OnClickType = (params: any) => any;

type ActionButtonProps = {
  // data
  id: string;
  active: boolean;
  icon: string;
  iconActive: string;
  style: CSSProperties;
  textStyle: CSSProperties;
  label: string;
  type: ActionType;
  contentType: PostData["type"];
  disabled?: boolean;
  // callback
  onClick?: OnClickType;
  onSuccess?: () => any;
};

type CardArticleProps = {
  image: string | null;
  title: string;
  description: string;
  // callback
  onSeeMoreClick: () => any;
};

export const IMAGES = {
  ribbon: "/images/expansion/Save.png",
  my_bplus: "/images/expansion/logo_48.png",
  like: "/images/expansion/Like.png",
  follow: "/images/expansion/Follow.png",
  share: "/images/expansion/Share.png",
  like_active: "/images/expansion/like-active.png",
  ribbon_active: "/images/expansion/save-outline-active.png",
  follow_active: "/images/expansion/follow-active.png",
};

const styles = {
  gridMiniPost: {
    background: "#FFFFFF",
    // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
    // boxShadow: "0px 0px 6px 1px rgba(227,239,255,0.5)",
    boxShadow: "0px 3px 15px 1px rgba(0, 102, 255, 0.08)",
    borderRadius: "20px",
    margin: "8px 8px 15px 8px",
    padding: "15px 3px 0 3px",
    position: "relative",
  } as CSSProperties,
  logoHospital: {
    width: "25px",
    height: "25px",
    padding: "5px",
  } as CSSProperties,
  imgDescription: {
    width: "100%",
    borderRadius: "10px",
    float: "right",
  } as CSSProperties,
  gridColumnTopLike: {
    // height: "35px",
    borderTop: "1px solid #E9E9E9",
    textAlign: "center",
  } as CSSProperties,
  gridColumnLike: {
    // borderRight: "1px solid #E9E9E9",
    height: "100%",
    padding: "8px 0",
  } as CSSProperties,
  textHeaderHospitalMini: {
    color: "#0147A3",
    fontWeight: 700,
    fontSize: "1.125rem",
    paddingLeft: "5px",
    paddingTop: "6px",
  } as CSSProperties,
  textHeader: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "1rem",
  } as CSSProperties,
  textContent: {
    color: "#9F9F9F",
    fontSize: "1rem",
    whiteSpace: "break-spaces",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    // WebkitLineClamp: 4 /* number of lines to show */,
    WebkitBoxOrient: "vertical",
    lineHeight: "23px",
  } as CSSProperties,
  textSeeMore: {
    marginTop: "5px",
    color: "#0147A3",
    fontSize: "14px",
  } as CSSProperties,
  textContentImg: {
    color: "#000000",
    // fontSize: "1rem",
  } as CSSProperties,
  textSeeMoreImg: {
    color: "#0147A3",
    fontSize: "14px",
    // paddingLeft: "5px",
  } as CSSProperties,
  imagIcon: {
    width: "1.4375rem",
    height: "1.4375rem",
    display: "inline",
    marginRight: "8px",
    paddingBottom: "2px",
  } as CSSProperties,
  imagIconSave: {
    // width: "17.5px",
    // height: "24.5px",
    display: "inline",
    marginRight: "8px",
    paddingBottom: "2px",
    width: "1.0313rem",
    height: "1.4375rem",
  } as CSSProperties,
  imagPromotion: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
    objectPosition: "100% 5%",
    borderRadius: "20px",
  },
};

const YOUTUBE_IFRAME_API = "https://www.youtube.com/iframe_api";
const IFRAME_API = "iframe_api";

export const getStatusActive = (
  data: PostData & PromotionData,
  patient: number
) => {
  const findActive = (list: any[]) => {
    return !!list?.find((item) => item.patient === patient);
  };

  return {
    like: findActive(data?.likes || []),
    saved: findActive(data?.saved || []),
    follow: findActive(data?.follows || []) || Boolean(data?.fake_follow),
  };
};

export const getYoutubeSrc = (url: string, isAutoplay?: boolean) => {
  if (url) {
    url = `${url.replace(/watch\?v=/g, "embed/").replace(/&.*$/g, "")}`;
  }
  return `${url}?enablejsapi=1&html5=1&rel=0&modestbranding=1${
    isAutoplay ? "&autoplay=1" : ""
  }`;
};

const descriptionSubStr = (description: string) => {
  const maxLength = 170;
  const matchAll = Array.from(description?.matchAll(/>.*?(?=<)/gm) || []);

  let stringLength = 0;
  let subIndex = 0;
  for (const match of matchAll) {
    stringLength += match[0].replace(/^>/g, "").length;
    if (stringLength > maxLength) {
      subIndex =
        (match.index || 0) + match[0].length - (stringLength - maxLength);
      break;
    }
  }

  const isEllipsis = matchAll?.[0]
    ? stringLength > maxLength
    : description.length > maxLength;

  return (
    `${description?.substring(0, subIndex || maxLength)}${
      isEllipsis ? "..." : ""
    }` || ""
  );
};

// const removeIframeApi = async (callback: any) => {
//   let frameApis = document.querySelectorAll(`#${IFRAME_API}`);

//   if (!!frameApis.length) {
//     for (const element of Array.from(frameApis)) {
//       element.remove();
//     }
//     removeIframeApi(callback);
//   } else {
//     return callback();
//   }
// };

export const loadPlayer = async (params: {
  id: string;
  onYouTubePlayerAPIReady: (id: string) => any;
  isTimeout?: boolean;
}) => {
  const tag = document.createElement("script");
  tag.src = YOUTUBE_IFRAME_API;
  tag.id = IFRAME_API;

  const firstScriptTag = document.getElementsByTagName("script")[0];

  console.log("loadPlayer firstScriptTag: ", firstScriptTag);
  if (firstScriptTag?.parentNode && window.YT?.loaded !== 1) {
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    console.log(
      "IS Callback Callced ? loadPlayer YT.loaded: ",
      window.YT?.loaded
    );

    window.onYouTubeIframeAPIReady = () => {
      console.log("Callback Callced  to onYouTubeIframeAPIReady after load ");
      params.onYouTubePlayerAPIReady(params.id);
    };
  }

  if (window.YT?.loaded === 1) {
    params.onYouTubePlayerAPIReady(params.id);
  }

  // params.onYouTubePlayerAPIReady(params.id);

  // let isInterval = false;
  // let isLoad = false;

  // var playerInterVal = setInterval(() => {
  //   if (typeof YT !== "undefined" && !isLoad) {
  //     isInterval = true;
  //     params.onYouTubePlayerAPIReady(params.id);
  //     clearInterval(playerInterVal);
  //   }
  // });
};

// https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
const isScrolledIntoView = (el: any) => {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};

// https://codepen.io/bfintal/pen/Ejvgrp
const cardIsInViewHalf = (el: HTMLIFrameElement) => {
  // console.log("cardIsInViewHalf el", el);
  if (el) {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el?.getBoundingClientRect().top + scroll;

    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    };

    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el?.clientHeight / 2, // half
    };

    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
  } else {
    return false;
  }
};

const cardIsInView = (el: HTMLIFrameElement) => {
  if (el) {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el?.getBoundingClientRect().top + scroll;

    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    };

    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el?.clientHeight,
    };

    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top > viewport.bottom && bounds.top <= viewport.top)
    );
  } else {
    return false;
  }
};

// const CURRENT_DATE = moment().format(BACKEND_DATE_FORMAT);

const MobContentDetail: React.FC<MobContentDetailProps> = forwardRef(
  (props, ref) => {
    const [isShareMode, setIsShareMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const iframeRef = useRef() as MutableRefObject<HTMLIFrameElement>;
    const playerRef = useRef() as MutableRefObject<any>;
    const cardRef = useRef() as MutableRefObject<HTMLIFrameElement>;
    const pause = useRef(false);
    // const guardScroll = useRef(false);

    // useEffect(() => {
    //   console.log(
    //     "MobContentDetail useEffect iframeRef.current: ",
    //     iframeRef.current
    //   );
    //   console.log("MobContentDetail useEffect props.item?.id : ", props.item?.id);
    //   console.log(
    //     "MobContentDetail useEffect props.item?.youtube_url: ",
    //     props.item?.youtube_url
    //   );
    //   if (iframeRef.current && props.item?.id && props.item?.youtube_url) {
    //     window.addEventListener("scroll", handleOnScroll);
    //     console.log("MobContentDetail useEffect called handleLoadIframe");
    //     handleLoadIframe();
    //   }

    //   return () => {
    //     window.removeEventListener("scroll", handleOnScroll);
    //   };
    // }, []);

    useImperativeHandle(ref, () => ({
      playerRef: () => {
        return playerRef.current;
      },
    }));

    useEffect(() => {
      if (cardRef.current && props.item?.id) {
        window.addEventListener("scroll", debounce(handleOnCardScroll));
        handleOnCardScroll();
      }
      return () => {
        window.removeEventListener("scroll", handleOnCardScroll);
      };
    }, [props.item.reach, props.isNextVisible]);

    useEffect(() => {
      if (props.item.active) {
        console.log("Need Reach active = true ", props.item?.id);
        console.log("Need Reach props.item: ", props.item);
        handleOnCardScroll();
      } else {
        console.log("Need Reach active = false ", props.item?.id);
      }
    }, [props.item.active]);

    useEffect(() => {
      if (props.shareContentTo && isShareMode && props.item?.id) {
        setIsShareMode(false);

        handleButtonClick({
          id: props.item.id || null,
          active: false,
          type: "share",
          content_type: props.item.type,
        });
      }
    }, [props.shareContentTo]);

    const handleLoadIframe = () => {
      // console.log('handleLoadIframe iframeRef.current: ', iframeRef.current);
      // console.log('handleLoadIframe props.item?.id: ', props.item?.id);
      // console.log('handleLoadIframe props.item?.youtube_url: ', props.item?.youtube_url);
      if (iframeRef.current && props.item?.id && props.item?.youtube_url) {
        loadPlayer({
          id: props.item?.id,
          onYouTubePlayerAPIReady: handleYouTubePlayer,
        });
      }
    };

    const statusActive = useCallback(() => {
      return getStatusActive(props.item, props.myProfileDetail?.patient);
    }, [props.item, props.myProfileDetail?.patient]);

    const youtubeSrc = useMemo(() => {
      return getYoutubeSrc(props.item?.youtube_url || "");
    }, [props.item?.youtube_url]);

    const shortDescription = useMemo(() => {
      return descriptionSubStr(props.item?.short_description || "");
    }, [props.item?.short_description]);

    const handleYouTubePlayer = async (id: string) => {
      console.log("handleYouTubePlayer: ", id);
      if (playerRef.current) {
        console.log("already have player (YT.player) ");
        return;
      }

      window.YT.ready(function () {
        console.log("window YT ready !!! ");
        try {
          let player = new YT.Player(`iframe-${id}`, {
            events: {
              // call this function when player is ready to use
              onReady: (e: any) => {
                console.log("onReady ", e);
                playerRef.current = player;
              },
              onStateChange: () => {
                // -1 – unstarted
                // 0 – ended
                // 1 – playing
                // 2 – paused
                // 3 – buffering
                // 5 – video cued
                let playerState = player?.getPlayerState?.();
                console.log("onStateChange ", playerState);
                if (playerState === -1) {
                  player.seekTo(0);
                }
              },
            },
          });
          console.log(player);
        } catch (error) {
          console.error(error);
        }
      });
    };

    const handleOnScroll = () => {
      console.log("handleOnScroll iframeRef: ", iframeRef);
      // console.log("handleOnScroll iframeRef.current: ", iframeRef.current);

      const isInViewport = cardIsInViewHalf(iframeRef.current);
      console.log("id: ", props.item.id, " playerRef: ", playerRef);
      if (!isInViewport && playerRef.current?.getPlayerState?.() === 1) {
        console.log(" Video Pause ");
        playerRef.current.pauseVideo();
      }
    };

    const debounce = useCallback((func: any, timeout = 100) => {
      let timer: NodeJS.Timeout;
      return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }, []);

    const handleOnCardScroll = () => {
      console.log("handleOnCardScroll called ", props.item.reach);
      console.log(
        "handleOnCardScroll props.myProfileDetail?.patient: ",
        props.myProfileDetail?.patient
      );

      const isCardInViewport = cardIsInView(cardRef.current);

      if (!isCardInViewport) {
        return;
      }

      const today = moment().format(BACKEND_DATE_FORMAT);
      const findReach = props.item.reach?.find(
        (item: any) =>
          item.patient === props.myProfileDetail?.patient &&
          moment(item.date?.toDate?.()).format(BACKEND_DATE_FORMAT) === today
      );

      console.log(
        "handleOnCardScroll findReach: (id) (active) (props.item) ",
        findReach,
        props.item?.id,
        props.item?.active,
        props.item
      );
      console.log("handleOnCardScroll props.item", props.item);
      // const isNextDate =true
      console.log("handleOnCardScroll today: ", today);
      // console.log('handleOnCardScroll CURRENT_DATE: ', CURRENT_DATE);
      // console.log('handleOnCardScroll isNextDate: ', isNextDate);

      if (isCardInViewport && props.isNextVisible) {
        console.log("handleOnCardScroll !!!  props.index: ", props.index);
        console.log(
          "handleOnCardScroll Capture props.index: ",
          props.index,
          "item.active (have content)props.isNextVisible :  ",
          props.isNextVisible,
          " active : ",
          props.item?.active,
          " props.item: ",
          props.item?.id
        );
        console.log(
          "handleOnCardScroll !!!  props.item props.index: ",
          props.item?.active
        );

        // if (props.item?.active && props?.index !== 1) {
        //   return
        // }
        // guardScroll.current = true
        props.onEvent?.({
          message: "HandleVisibleConsent",
          params: { index: props.index },
        });
      }

      console.log("handleOnCardScroll isCardInViewport: ", isCardInViewport);
      console.log("handleOnCardScroll pause: ", pause);
      if (isCardInViewport && !findReach) {
        if (!pause.current) {
          pause.current = true;
        }

        console.log(
          "handleOnCardScroll !!!! cardRef.current.getAttribute('class')",
          cardRef.current.getAttribute("class")
        );

        if (cardRef.current.getAttribute("class") === "content") {
          console.log("handleOnCardScroll: Send Reach ", props.item?.id);
          handleButtonClick({
            id: props.item?.id || null,
            active: false,
            type: "reach",
            content_type: props.item.type,
            onSuccess: () => {},
          });
        }
      }
    };

    const handleButtonClick = (params: OnClickParams) => {
      if (params.type === "share" && params.id && !props.shareContentTo) {
        setIsShareMode(true);
        return props.onEvent?.({
          message: "HandleShareContent",
          params: { data: props.item },
        });
      }

      console.log("handleButtonClick params: ", params);
      setIsShareMode(false);
      return props.onButtonClick?.(params);
    };

    const handleButtonSave = (params: OnClickParams) => {
      setIsShareMode(false);
      props.onButtonClick?.(params);
    };

    const handleSeeMoreClick = (item: any) => {
      console.log(
        "handleSeeMoreClick item: ",
        item,
        "playerRef.current",
        playerRef.current
      );
      props.handleSeeMoreClick?.(item, playerRef.current);
    };

    const handleSeeMoreContentUrl = () => {
      props.stopAllVideo?.();
      // Stop All Video

      if (window.mobile.handleEvent) {
        if (props.item.url) {
          props.onEvent?.({
            message: "seeMoreContent",
            params: {
              contentUrl: props.item.url,
            },
          });
        }
      } else {
        window.open(props.item.url || "", "_blank");
      }
    };

    // console.log(props.item?.id, playerRef.current, isLoadingIframe);
    // console.log("MobContentWithLazy props: ", props);

    if (props.followOnly && !props.item.fake_follow) {
      return (
        <div
          ref={cardRef}
          className={props.item?.active ? "content" : "skeleton"}
        >
          {props.item?.active ? (
            <></>
          ) : (
            <>
              <MobContentSkeleton id="skeleton" type="article" />
            </>
          )}
        </div>
      );
    }

    if (
      props.preferredOnly &&
      props.selectedHospital?.code !== props.item.hospital?.code
    ) {
      return (
        <div
          ref={cardRef}
          className={props.item?.active ? "content" : "skeleton"}
        >
          {props.item?.active ? (
            <></>
          ) : (
            <>
              <MobContentSkeleton id="skeleton" type="article" />
            </>
          )}
        </div>
      );
    }

    if (props.saveOnly) {
      let patientSaveList = props.item.saved?.map((item) => item.patient); // See All patient

      console.log("SaveTab props.item", props.item);
      console.log("SaveTab patientSaveList: ", patientSaveList);
      console.log("SaveTab myProfileDetail: ", props.myProfileDetail?.patient);
      console.log(
        "SaveTab myProfileDetail typeof : ",
        typeof props.myProfileDetail?.patient
      );

      if (
        !(
          Array.isArray(patientSaveList) &&
          patientSaveList?.length > 0 &&
          patientSaveList.includes(props.myProfileDetail?.patient)
        )
      ) {
        return (
          <div
            ref={cardRef}
            className={props.item?.active ? "content" : "skeleton"}
          >
            {props.item?.active ? (
              <></>
            ) : (
              <>
                <MobContentSkeleton id="skeleton" type="article" />
              </>
            )}
          </div>
        );
      }
    }

    return (
      <div
        ref={cardRef}
        className={props.item?.active ? "content" : "skeleton"}
      >
        {props.item?.active ? (
          <div style={{ margin: "2px" }}>
            <Grid
              className="CardContent-detail"
              style={{
                ...styles.gridMiniPost,
                ...((!props.item?.type as any) && { padding: 0 }),
              }}
            >
              {props.item?.type && (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      alignItems: "right",
                      right: "28px",
                      top: 0,
                    }}
                  >
                    {!props.firebaseIsAnonymous ? (
                      <Image
                        src={
                          statusActive().saved
                            ? IMAGES.ribbon_active
                            : IMAGES.ribbon
                        }
                        style={styles.imagIconSave}
                        onClick={() => {
                          if (!loading) {
                            setLoading(true);
                            handleButtonSave?.({
                              id: props.item.id || null,
                              active: statusActive().saved,
                              type: "save",
                              content_type: props.item.type,
                              onSuccess: () => {
                                setLoading(false);
                              },
                            });
                          }
                        }}
                      />
                    ) : null}
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "35px 1fr" }}
                  >
                    <div
                      style={{
                        borderRadius: "50px",
                        // border: "1px solid #0147A3",
                        width: "30px",
                        background: "rgba(236, 244, 255, 1)",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={IMAGES.my_bplus}
                        style={styles.logoHospital}
                      />
                    </div>
                    <div style={styles.textHeaderHospitalMini}>
                      {props.item?.hospital?.[
                        props.language?.includes("th") ? "name" : "name_en"
                      ] || ""}
                    </div>
                  </div>
                </div>
              )}
              {props.item?.type === "article" ? (
                <CardArticle
                  image={props.item?.banners}
                  title={props.item.title}
                  description={props.item.description}
                  onSeeMoreClick={() => {
                    console.log(
                      "called article handleSeeMoreClick props.item: ",
                      props.item
                    );
                    handleSeeMoreClick(props.item);
                  }}
                />
              ) : props.item?.type === "picture" ? (
                <div
                  onClick={(e: any) => {
                    console.log(
                      "called picture 1 handleSeeMoreClick props.item: ",
                      props.item
                    );
                    handleSeeMoreClick(props.item);
                  }}
                >
                  <Grid style={{ margin: "10px 0 10px 0", padding: "0px" }}>
                    <Grid.Row style={{ padding: "0px 0px 5px 0px" }}>
                      <span
                        style={{
                          ...styles.textContent,
                          ...styles.textContentImg,
                        }}
                      >
                        {shortDescription}
                        <span
                          style={{
                            ...styles.textSeeMoreImg,
                            paddingLeft: "10px",
                          }}
                          onClick={(e: any) => {
                            console.log(
                              "called picture 2 handleSeeMoreClick props.item: ",
                              props.item
                            );
                            handleSeeMoreClick(props.item);
                          }}
                        >
                          see more
                        </span>
                      </span>
                    </Grid.Row>
                    {props.item?.images?.length >= 3 ? (
                      <Grid.Row style={{ padding: "0px" }}>
                        <Grid.Column width={11} style={{ padding: "0px" }}>
                          <Image
                            src={props.item?.images?.[0]}
                            size="medium"
                            style={{ width: "98%", height: "96%" }}
                          />
                        </Grid.Column>
                        <Grid.Column width={5} style={{ padding: "0px" }}>
                          <Grid.Row>
                            <Image
                              src={props.item?.images?.[1]}
                              size="medium"
                              style={{ height: "100%", marginBottom: "4%" }}
                            />
                          </Grid.Row>
                          <Grid.Row>
                            <Image
                              src={props.item?.images?.[2]}
                              size="medium"
                              style={{ height: "100%", marginTop: "4%" }}
                            />
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                    ) : props.item?.images?.length === 2 ? (
                      <Grid.Row style={{ padding: "0px" }}>
                        <Grid.Column width={8} style={{ padding: "0px" }}>
                          <Image
                            src={props.item?.images?.[0]}
                            size="medium"
                            style={{ width: "98%", marginRight: "4%" }}
                          />
                        </Grid.Column>
                        <Grid.Column width={8} style={{ padding: "0px" }}>
                          <Grid.Row>
                            <Image
                              src={props.item?.images?.[1]}
                              size="medium"
                              style={{ height: "98%" }}
                            />
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      <Grid.Row style={{ padding: "0px" }}>
                        <Grid.Column width={16} style={{ padding: "0px" }}>
                          <Image
                            src={props.item?.images?.[0]}
                            size="medium"
                            style={{ width: "100%" }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </div>
              ) : props.item?.type === "url" ? (
                <CardArticle
                  image={props.item?.banners}
                  title={props.item.title}
                  description={props.item.description}
                  onSeeMoreClick={handleSeeMoreContentUrl}
                />
              ) : props.item?.type === "video" ? (
                <div
                  onClick={(e: any) => {
                    console.log(
                      "called video 1 handleSeeMoreClick props.item: ",
                      props.item
                    );
                    handleSeeMoreClick(props.item);
                  }}
                >
                  <Grid style={{ margin: "10px 0", padding: "0px" }}>
                    <Grid.Row style={{ padding: "0px 0px 5px 0px" }}>
                      <span
                        style={{
                          ...styles.textContent,
                          ...styles.textContentImg,
                        }}
                      >
                        {shortDescription}
                      </span>
                    </Grid.Row>
                    <Grid.Row style={{ padding: "0px" }}>
                      <Grid.Column width={16} style={{ padding: "0px" }}>
                        <div
                          style={{ ...styles.textSeeMore, margin: "0 0 5px 0" }}
                          onClick={(e: any) => {
                            console.log(
                              "called video 2 handleSeeMoreClick props.item: ",
                              props.item
                            );
                            handleSeeMoreClick(props.item);
                          }}
                        >
                          see more
                        </div>
                        <iframe
                          onLoad={() => {
                            window.addEventListener(
                              "scroll",
                              debounce(handleOnScroll)
                            );
                            console.log(
                              "MobContentDetail onLoad called handleLoadIframe props.item?.id: ",
                              props.item?.id,
                              "iframeRef",
                              iframeRef
                            );
                            handleLoadIframe();
                          }}
                          ref={iframeRef}
                          id={"iframe-" + props.item?.id || ""}
                          title={`YouTube video player`}
                          loading="lazy"
                          width="100%"
                          height="200"
                          src={youtubeSrc}
                          frameBorder="0"
                          allowFullScreen={true}
                          allow=""
                        ></iframe>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              ) : null}

              {props.item?.type === undefined ? (
                <div
                  style={{ padding: "0px", width: "100%" }}
                  onClick={(e: any) => {
                    console.log(
                      "called undefined/promotion handleSeeMoreClick props.item: ",
                      props.item
                    );
                    handleSeeMoreClick(props.item);
                  }}
                >
                  <Image
                    src={props.item?.banners}
                    size="medium"
                    style={styles.imagPromotion}
                  />
                </div>
              ) : ["article", "picture", "video", "url"].includes(
                  props.item.type
                ) && !props?.firebaseIsAnonymous ? (
                <div style={{ width: "100%" }}>
                  <Grid
                    columns="equal"
                    divided
                    inverted
                    padded
                    style={styles.gridColumnTopLike}
                  >
                    <Grid.Row style={{ padding: 0 }}>
                      <Grid.Column style={styles.gridColumnLike}>
                        <ActionButton
                          id={props.item.id || ""}
                          active={statusActive().like}
                          onClick={handleButtonClick}
                          type="like"
                          style={styles.imagIcon}
                          textStyle={styles.textContent}
                          label="Like"
                          icon={IMAGES.like}
                          iconActive={IMAGES.like_active}
                          contentType={props.item.type}
                          disabled={!props?.apiToken}
                          onSuccess={() => {
                            setLoading(false);
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column style={styles.gridColumnLike}>
                        <ActionButton
                          id={props.item.id || ""}
                          active={statusActive().follow}
                          onClick={handleButtonClick}
                          type="follow"
                          style={{ ...styles.imagIcon, padding: "2px 0 0 0" }}
                          textStyle={styles.textContent}
                          label="Follow"
                          icon={IMAGES.follow}
                          iconActive={IMAGES.follow_active}
                          contentType={props.item.type}
                          disabled={!props?.apiToken}
                          onSuccess={() => {
                            setLoading(false);
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column style={{ padding: "8px 0" }}>
                        <ActionButton
                          id={props.item.id || ""}
                          active={true}
                          onClick={handleButtonClick}
                          type="share"
                          style={styles.imagIcon}
                          textStyle={styles.textContent}
                          label="Share"
                          icon={IMAGES.share}
                          iconActive={IMAGES.share}
                          contentType={props.item.type}
                          // disabled={!props?.apiToken}
                          onSuccess={() => {}}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              ) : null}
            </Grid>
          </div>
        ) : (
          <>
            <MobContentSkeleton id="skeleton" type="article" />
          </>
        )}
      </div>
    );
  }
);

/* TODO --------------------- CardArticle -------------------- */
const CardArticle: React.FunctionComponent<CardArticleProps> = (props) => {
  const description = useMemo(() => {
    return descriptionSubStr(props.description || "");
  }, [props.description]);

  return (
    <div onClick={props.onSeeMoreClick}>
      <Grid style={{ margin: "10px 0 10px 0", padding: "0px" }}>
        <Grid.Row style={{ padding: "0px 0px 10px 0px" }}>
          {props.image && (
            <Grid.Column width={6} style={{ padding: "0px 5px" }}>
              <Image
                src={props.image}
                size="medium"
                style={styles.imgDescription}
              />
            </Grid.Column>
          )}
          <Grid.Column
            width={props.image ? 10 : 16}
            style={{ padding: props.image ? "0 0 0 10px" : 0 }}
          >
            <div style={styles.textHeader}>{props.title}</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid>
            {props.description && (
              <div
                className="ArticleDesc-container"
                style={{ ...styles.textContent }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            <div style={styles.textSeeMore} onClick={props.onSeeMoreClick}>
              see more
            </div>
          </Grid>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export const ActionButton: React.FunctionComponent<ActionButtonProps> = (
  props
) => {
  const loadingRef = useRef(false);

  const handleClick = () => {
    if (props?.disabled || loadingRef.current) {
      return;
    }

    console.log("handleClick", loadingRef.current);
    if (props.type !== "share") {
      loadingRef.current = true;
    }

    props.onClick?.({
      id: props.id || null,
      active: props.active,
      type: props.type,
      content_type: props.contentType,
      onSuccess: () => {
        loadingRef.current = false;
      },
    });
  };

  return (
    <div
      style={{
        ...props.textStyle,

        ...(props.active &&
          props.type !== "share" && {
            color: "var(--blue-bdms-color)",
          }),
      }}
      onClick={handleClick}
    >
      <Image
        src={props.active ? props.iconActive : props.icon}
        style={props.style}
      />

      <label>{props.label}</label>
    </div>
  );
};

MobContentDetail.displayName = "MobContentDetail";
export default React.memo(MobContentDetail);
