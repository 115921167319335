import React, { Component } from "react";
import SetProp from "../react-lib/frameworks/SetProp";
import { withRouter, Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";
import CONFIG from "../config/config";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
// Controller
import MobLABController from "./MobLABController";
// Interface
import * as MobLABI from "./MobLABInterface";
// UI
import MainLabResult from "./lab/MainLabResult";

import { IntlProvider } from "react-intl";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "../react-lib/localization/bplusClinic/th.json";
import enMessage from "../react-lib/localization/bplusClinic/en.json";
import HeaderBar from "../bplus-lib/medication/HeaderBar";
import SelectHospital from "./appointment/SelectHospital";

// CSS
import "../css/Default.css";

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};
class MobLAB extends Component<any, MobLABI.State> {
  controller: MobLABController;

  constructor(props: any) {
    super(props);
    this.state = { ...MobLABI.StateInitial };
    this.controller = new MobLABController(
      () => {
        return this.state;
      },
      (state: MobLABI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({ message: "GetLoginInfo", params: {} });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    // Get apiToken and userId from mobNative
    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      console.log("did update check......");
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
    }
  }

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  render = () => {

    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div className="default-background" style={{ background: "white" }}>
          <Switch>
            <Route
              path="/Select-Hospital"
              render={(props) => {
                return (
                  <div>
                    <SelectHospital
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      onClose={() => this.props.history.goBack()}
                      firstLoadingHospital={!this.state.loadingHospital}
                      history={this.props.history}
                      selectedHospital={this.state.selectedHospital}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/"
              render={(props) => {
                return (
                  <div>
                    <HeaderBar
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      selectedHospital={this.state.selectedHospital}
                      currentMob={"MobLAB"}
                    />
                    <MainLabResult
                      onEvent={this.controller.handleEvent}
                      setProp={this.controller.setProp}
                      loadingLabResult={this.state.loadingStatus}
                      loadingLabByEn={this.state.loadingLabByEn}
                      getLabParent={this.state.getLabParent}
                      labItems={this.state.labParentItems}
                      selectedParent={this.state.selectedParent}
                      childLabItems={this.state.childLabItems}
                      loadingHospital={this.state.loadingHospital}
                      selectedHospital={this.state.selectedHospital}
                      visitDateItems={this.state.visitDateItems}
                      pdfDownloadStatus={this.state.pdfDownloadStatus}
                    />
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobLAB);
