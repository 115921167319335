import WasmController from "../../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
// api
import ProxyMyProfileAPI from "../../issara-sdk/apis/ProxyMyProfile_profileM";

export type State = {
  patientIdentification?: any;
};

export const StateInitial: State = {
  patientIdentification: {},
};

export type Event =
  | { message: "handleGetPatientIdentification" }
  | { message: "handleChangePhoto"; params: { name: string; value: any } }
  | { message: "handleConfirmPatientIdentification"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const handleGetPatientIdentification: Handler = async (controller) => {
  const [response, error, network] = await ProxyMyProfileAPI.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });
  if (error) {
    controller.setState({
      patientIdentification: {},
      errorMessage: error,
      successMessage: null,
    });
  } else {
    controller.setState({
      patientIdentification: response,
      errorMessage: null,
      successMessage: null,
    });
  }
};

export const handleChangePhoto: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    patientIdentification: {
      ...state.patientIdentification,
      [params.name]: params.value,
    },
    errorMessage: null,
    successMessage: null,
  });
};

export const handleConfirmPatientIdentification: Handler = async (
  controller,
  params
) => {
  const [response, error, network] = await ProxyMyProfileAPI.patch({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: { ...params },
  });
  if (error) {
    controller.setState({
      patientIdentification: {},
      errorMessage: error,
      successMessage: null,
    });
  } else {
    controller.handleEvent({
      message: "handleMobileEvent",
      params: { name: "completeKYC" },
    });
    controller.setState({
      patientIdentification: response,
      errorMessage: null,
      successMessage: null,
    });
  }
};
