import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
// Interface
import {
  BACKEND_DATE_FORMAT,
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  calculateChildDevelopmentTotalV2,
  commonGetChildVaccine,
} from "../MobClinicInterface";
import { State as MainState } from "../ManageInterface";

export type State = {
  childOverallProgress?: any[];
};

export const StateInitial = {
  childOverallProgress: [],
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export type Event =
  // GET
  { message: "handleGetChildOverallProgress"; params: {} };

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetChildOverallProgress: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true } as any);

  let errorMessage: any[] = [];
  let result: any[] = [];

  // GROWTH
  const [errGrowth, respGrowth] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.GROWTH,
      data_type: DATA_TYPE.GROWTH,
    }
  );
  if (errGrowth) {
    errorMessage.push(errGrowth);
  } else {
    result.push({
      name: params.intl.formatMessage({ id: "bplusClinicKey529" }),
      value: respGrowth?.data?.weight_status,
      score: respGrowth?.data?.weight_score,
    });
    result.push({
      name: params.intl.formatMessage({ id: "bplusClinicKey292" }),
      value: respGrowth?.data?.height_status,
      score: respGrowth?.data?.height_score,
    });
    result.push({
      name: params.intl.formatMessage({ id: "bplusClinicKey233" }),
      value: respGrowth?.data?.head_status,
      score: respGrowth?.data?.head_score,
    });
  }

  // DEVELOPMENT
  const [errDevel, respDevel] = await commonListPatientDataList(
    controller as any,
    {
      group: GROUP.DEVELOPMENT,
    }
  );
  if (errDevel) {
    errorMessage.push(errDevel);
  } else {
    const flatItems =
      respDevel?.items?.map((item: any) => item?.data?.items)?.flat() || [];
    const score: number = calculateChildDevelopmentTotalV2(flatItems);
    let status: string = "";
    if (80 <= score && score <= 100) {
      status = "ดีเยี่ยม";
    } else if (60 <= score && score <= 79) {
      status = "ดี";
    } else if (40 <= score && score <= 59) {
      status = "พอใช้";
    } else if (0 <= score && score <= 39) {
      status = "ควรปรึกษาแพทย์";
    }
    result.push({ name: params.intl.formatMessage({ id: "bplusClinicKey159" }), value: status, score: score });
  }

  // VACCINE
  const [essentialList, otherList] = await commonGetChildVaccine(
    controller as any
  );

  // result.push({ name: params.intl.formatMessage({ id: "vaccine.vaccine" }), value: "-", score: 0 });
  const { selectedPatient } = controller.getState();
  const today: moment.Moment = moment();
  const birthdate: moment.Moment = moment(
    selectedPatient?.birthdate,
    BACKEND_DATE_FORMAT
  );
  const diffDate = today.diff(birthdate);
  const duration = moment.duration(diffDate);
  const ageYear: number = duration.years();
  const ageMonth: number = duration.months();
  const ageDay: number = duration.days();

  let total: number = 0;
  let injected: number = 0;
  essentialList.forEach((ar: any) => {
    if (
      ar.to_year <= ageYear &&
      ar.to_month <= ageMonth &&
      ar.to_day >= ageDay
    ) {
      ar.items.forEach((item: any) => {
        total += 1;

        if (item?.data && item?.data?.OEORI_Date !== "") {
          injected += 1;
        }
      });
    }
  });
  const score: number = (injected * 100) / total;

  result.push({
    name: params.intl.formatMessage({ id: "vaccine.vaccine" }),
    value: score === 100 ? "ครบตามกำหนด" : "ควรรับวัคซีนให้ครบตามเกณฑ์",
    score: score,
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage.length > 0 ? errorMessage : null,
    successMessage: null,
    childOverallProgress: result,
  });
};
