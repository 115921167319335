import { HandleSaveVaccineDetail } from "./WebDMVaccineInterface";
import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import AgeRangeList from "issara-sdk/apis/AgeRangeList_apps_PHR";
import VaccineSeriesList from "issara-sdk/apis/VaccineSeriesList_apps_PHR";
import TrakCarePatientInfo from "issara-sdk/apis/TrakCarePatientInfo_users";

// Types
import AgeRangeSerializer from "../../../issara-sdk/types/AgeRangeSerializer_apps_PHR";

// Interface
import {
  commonListPatientDataList,
  GROUP,
  BE_DATE_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  DATA_TYPE,
  commonUpdatePatientData,
  commonCreatePatientData,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";
import { getAgeAtVaccination } from "../../clinic/ChildVaccineInterface";
import { mapVaccineItems, sortVaccineByDate } from "../../clinic/DMVaccineInterface";

import { recordOfImmunization } from "../../../react-lib/utils/recordOfImmunization";

export type State = {
  ageRangeVaccineChild?: AgeRangeSerializer[];
  essentialVaccineList?: {
    abbreviation: string;
    items: ({ abbreviation: string } | null)[];
  }[];
  otherVaccineList?: any[];
  lastUpdateVaccineChild?: string;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export const StateInitial: State = {
  ageRangeVaccineChild: [],
  essentialVaccineList: [],
  otherVaccineList: [],
  lastUpdateVaccineChild: "",
};

export type Event =
  // GET
  | { message: "GetAgeRangeVaccineChild"; params: {} }
  // ACTION
  | {
      message: "HandleCreateVaccinePDF";
      params: {};
    }
  // Save
  | {
    message: "HandleSaveVaccineChildDetail";
    params: {
      data: any;
      vaccine: string;
      id?: number | null;
      proxy_patient?: number | null;
      measure_date?: string | null;
    };
  };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

export const GetAgeRangeVaccineChild: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  const { selectedPatient } = controller.getState();

  const getAgeRangeList = AgeRangeList.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      type: "VACCINE_CHILD",
    },
  });

  const getVaccineSeriesList = VaccineSeriesList.list({
    apiToken: Cookies.get("apiToken"),
    params: { patient_type: "CHILD" },
  });

  const getVaccinePatient = commonListPatientDataList(controller as any, {
    group: GROUP.VACCINE_CHILD,
  });

  const [resAgeRange, resVaccineSeries, resVaccinePatient] = await Promise.all([
    getAgeRangeList,
    getVaccineSeriesList,
    getVaccinePatient,
  ]);

  const ageRangeItems: any[] = resAgeRange?.[0]?.items || [];
  const vaccineItems: any[] = resVaccineSeries?.[0]?.items || [];
  const vPatientItems: any[] = resVaccinePatient?.[1]?.items || [];

  const filterPatientVaccine = (id: number) => {
    return vPatientItems
      .filter((acc: any) => acc.data_type === `vaccine_${id}`)
      .sort(sortVaccineByDate);
  };

  const essentialList = vaccineItems
    .filter((item) => item.vaccine_type === "ESSENTIAL")
    .map((item) => ({
      abbreviation: item.abbreviation,
      name: item.name,
      id: item.id,
      items: ageRangeItems.reduce((result, acc) => {
        const include = item.age_range.includes(acc.id);
        const length = result.filter((o: any) => o?.abbreviation).length;
        const filter = filterPatientVaccine(item.id);
        const detail = filter[length] || {};

        return [
          ...result,
          include
            ? {
              abbreviation: `${item.abbreviation}${item.qty > 1 ? length + 1 : ""
                }`,
              date: detail.data?.OEORI_Date
                ? moment(detail.data?.OEORI_Date).format(BE_DATE_FORMAT)
                : "",
              age_at_vaccination: getAgeAtVaccination(
                detail.data?.OEORI_Date,
                selectedPatient.birthdate
              ),
              by_patient: detail.data?.By_Patient,
              data: detail.data || null,
              realData: detail.data || null,
              id: detail.id,
              measure_date: detail.measure_date,
              proxy_patient: detail.proxy_patient,
            }
            : null,
        ];
      }, []),
    }));

  const otherItems = vaccineItems.filter(
    (item) => item.vaccine_type === "OTHERS"
  );
  const otherList = mapVaccineItems(otherItems, vPatientItems);

  const lastUpdate = vPatientItems
    .sort((a: any, b: any) => (a.edited_at || "").localeCompare(b.edited_at))
    .slice(-1)?.[0]?.edited_at;

  controller.setState({
    ageRangeVaccineChild: ageRangeItems,
    essentialVaccineList: essentialList,
    loadingStatus: false,
    otherVaccineList: [...otherList],
    lastUpdateVaccineChild: lastUpdate,
  });
};

/* ------------------------ SAVE ------------------------ */
export const HandleSaveVaccineChildDetail: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleSaveVaccineChildDetail" }>["params"]
) => {
  controller.setState({ loadingStatus: true });

  const { data, id, vaccine, proxy_patient, measure_date } = params;
  let saveData: any = {
    id,
    data,
    proxy_patient: proxy_patient,
    measure_date: (data.OEORI_Date ? moment(data.OEORI_Date) : moment())
      .locale("en")
      .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
  };

  if (!id) {
    saveData = {
      ...saveData,
      group: GROUP.VACCINE_CHILD,
      data_type: `${DATA_TYPE.VACCINE}_${vaccine}`,
    };

    delete saveData.id;
    delete saveData.proxy_patient;
  }

  let [error, response]: [any, any] = [null, null];

  if (saveData.id) {
    [error, response] = await commonUpdatePatientData(
      controller as any,
      saveData
    );
  } else {
    [error, response] = await commonCreatePatientData(
      controller as any,
      saveData
    );
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = `Update vaccine data success.`;
    GetAgeRangeVaccineChild(controller as any, {});
  }

  controller.setState({
    loadingStatus: false,
    errorMessage,
    successMessage,
  });
};

/* ------------------------- API ------------------------ */
const TrakCarePatient: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await TrakCarePatientInfo.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      hn: params.hn
    },
  });

  controller.setState({ loadingStatus: false });
  return [res, error];

};

export const HandleCreateVaccinePDF: Handler = async (
  controller,
  params
) => {
  const { selectedPatient, essentialVaccineList, otherVaccineList } = controller.getState();

  var [resTCP, errorTCP] = await TrakCarePatient(controller, selectedPatient);

  console.log("selectedPatient",selectedPatient);
  console.log("essentialVaccineList",essentialVaccineList);
  console.log("otherVaccineList",otherVaccineList);
  console.log("PDF print");

  //split_age
  const years = selectedPatient.full_age.split("ปี")[0];
  const month = selectedPatient.full_age.split("ปี")[1].split("เดือน")[0];
  const days = selectedPatient.full_age.split("ปี")[1].split("เดือน")[1].split("วัน")[0];

  const generateData = {
    selectedPatient: selectedPatient,
    age_years: years,
    age_month: month,
    age_days: days,
    essentialVaccineList: essentialVaccineList,
    otherVaccineList: otherVaccineList,
    bloodType: resTCP.blood_type,
  }

  await recordOfImmunization(generateData);
};
