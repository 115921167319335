import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  MutableRefObject,
  ChangeEvent,
} from "react";
// CSS
import {
  Tab,
  Button,
  Icon,
  Checkbox,
  Grid,
  Header,
  Input,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { makeStyles } from "@material-ui/core";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
// Icon
import CancelIcon from "@material-ui/icons/Cancel";

import PureReactTable from "../../../react-lib/component-js/common/PureReactTable";
import moment from "moment";

// Interface
import { State, Event } from "../../ManageInterface";
import { convertToADDate, formatDate } from "bplus-lib/MobClinicInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import PatientPanel from "../../clinic/PatientPanel";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

type WebDMUMNutritionProps = {
  onEvent: (e: Event) => any;
  setProp: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
} & Pick<
  State,
  | "nutritionAditionalMenuList"
  | "selectedNutritionAditionalMenu"
  | "nutritionNutritionistMenuList"
  | "selectedNutritionNutritionistMenu"
  | "loadingStatusAditionalMenu"
  | "nutritionistUMFilter"
  | "nutritionUMFilter"
  | "consumptionUnitList"
  | "foodTypeList"
  | "openModNutrition"
  | "patientSearch"
>;

type AdditionalMenuProps = {
  foodTypeOptions?: any;
  consumptionUnitOptions?: any;
} & WebDMUMNutritionProps;

type NutritionistMenuProps = {
  foodTypeOptions?: any;
  consumptionUnitOptions?: any;
} & WebDMUMNutritionProps;

type NutrientFormProps = {
  // Data
  type?: "additional-menu";
  data?: any;
  foodTypeOptions?: any;
  consumptionUnitOptions?: any;
  // config
  disabledForm?: boolean;
  // Callback
  onClear?: () => any;
  onSave?: () => any;
  onChangeValue?: (key: string, value: any) => any;
} & Pick<WebDMUMNutritionProps, "onEvent">;

const COLOR = {
  red: "#EB5757",
  grey: "#4F4F4F",
  light_grey: "#C4C4C4",
};

const IMAGE = {
  juice: "/images/OrangeJuice.jpg",
  upload: "/images/upload-icon.png",
};

const DATE_FORMAT = "DD/MM/YYYY";

const useStyles = makeStyles((theme) => ({
  box_top_content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    "& > div:nth-child(1)": {
      display: "flex",
      alignItems: "center",
      "& .ui.icon.input": {
        width: "170px",
      },
    },
    "& > div:nth-child(2)": {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "50px",
    },
  },
  photo: {
    width: "60px",
    objectFit: "contain",
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box_bottom_content: {
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "35% 65%",
    "& .sub-bottom-left-content": {
      padding: "10px 5px 5px",
      "& .img-menu": {
        width: "100%",
        objectFit: "contain",
        borderRadius: "30px",
      },
      "& .input-description": {
        marginTop: "9px",
        "& label": {
          color: COLOR.red,
          paddingBottom: "5px",
        },
        "& > div": {
          marginBottom: "5px",
        },
      },
    },
    "& .sub-bottom-right-content": {
      padding: "5px",
      "& > div": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
        "& > div": {
          padding: "5px",
        },
        "& label": {
          color: COLOR.red,
          paddingBottom: "5px",
        },
      },
      "& > div:nth-child(2)": {
        gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
        "& label": {
          color: COLOR.grey,
        },
      },
    },
  },
  box_footer: {
    display: "flex",
    justifyContent: "flex-end",
    "& .group-button": {
      padding: "10px 30px 20px",
      display: "flex",
      alignItems: "center",
      "& button": {
        margin: "0 10px",
      },
    },
  },
  approved: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginLeft: "25px",
      color: COLOR.red,
      fontWeight: "bold",
    },
  },
  btn_delete: {
    width: "25px",
    height: "25px",
    padding: "0 !important",
    "& i": {
      margin: "0 !important",
    },
  },
  box_upload: {
    width: "100%",
    height: "250px",
    border: `1px solid ${COLOR.light_grey}`,
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer",
    "& .icon-upload": {
      height: "72px",
      objectFit: "contain",
    },
    "& label": {
      marginTop: "5px",
    },
  },
  icon_close: {
    position: "absolute",
    top: "0",
    right: "0",
    color: COLOR.red,
    cursor: "pointer",
  },
  additional_menu: {
    "& .ui.checkbox label:before": {
      border: "3px solid #000000",
    },
    "& .ui.checkbox input:checked~label:before": {
      border: "3px solid #000000",
    },
  },
  mod_content: {
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "0.3in",
    margin: "-10px 0 -20px 0",
  },
}));

const INPUT_MAIN_NUTRIENT = [
  { name: "Energy (cal)", key: "energy_cal" },
  { name: "Cabohydrate (g)", key: "cabohydrate_grams" },
  { name: "Protein (g)", key: "protein_grams" },
  { name: "Fat (g)", key: "fat_grams" },
  { name: "Fiber (g)", key: "fiber_grams" },
  { name: "Sugar (g)", key: "sugar_grams" },
  { name: "Sodium (mg)", key: "sodium_milligram" },
  { name: "Sat. Fat (mg)", key: "sat_fat_milligram" },
];

const INPUT_MICRONUTRIENT = [
  { name: "PROA (g)", key: "proa_grams" },
  { name: "PROV (g)", key: "prov_grams" },
  { name: "CHOLE (mg)", key: "chole_milligram" },
  { name: "CA (mg)", key: "ca_milligram" },
  { name: "P (mg)", key: "p_milligram" },
  { name: "FE (mg)", key: "fe_milligram" },
  { name: "FEA (mg)", key: "fea_milligram" },
  { name: "FEV (mg)", key: "fev_milligram" },
  { name: "K (mg)", key: "k_milligram" },
  { name: "CU (mg)", key: "cu_milligram" },
  { name: "MG (mg)", key: "mg_milligram" },
  { name: "SE (mcg)", key: "se_microgram" },
  { name: "ZN (mg)", key: "zn_milligram" },
  { name: "VITA  (RAE)", key: "vita_rae" },
  { name: "RETOL (mcg)", key: "retol_microgram" },
  { name: "CARTB (mcg)", key: "cartb_microgram" },
  { name: "VITB1 (mg)", key: "vitB1_milligram" },
  { name: "VITB2 (mg)", key: "vitB2_milligram" },
  { name: "VITB6 (mg)", key: "vitB6_milligram" },
  { name: "VITB12 (mcg)", key: "vitB12_microgram" },
  { name: "VITC (mcg)", key: "vitC_microgram" },
  { name: "NIA (mg)", key: "nia_milligram" },
  { name: "VITE (mg)", key: "vitE_milligram" },
  { name: "FBC (g)", key: "fbc_grams" },
  { name: "PHYT (mg)", key: "phyt_milligram" },
  { name: "ASH (g)", key: "ash_grams" },
  { name: "WATER (g)", key: "water_grams" },
];

const NutrientForm: React.FunctionComponent<NutrientFormProps> = (props) => {
  const classes = useStyles();

  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  /* ----------------------- Handle ----------------------- */
  const handleChangeValue = (key: string, value: any) => {
    props.onChangeValue?.(key, value);
  };

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const handleChangeUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      handleChangeValue("file", file);
      inputRef.current.value = "";
    }
  };

  const handleImageCancel = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    handleChangeValue("file", null);
  };

  return (
    <>
      <div
        className={classes.box_bottom_content}
        style={props.disabledForm ? { pointerEvents: "none" } : {}}
      >
        <div className="sub-bottom-left-content">
          <h4 style={{ marginBottom: "9px" }}>
            <FormattedMessage id="bplusClinicKey47" />
          </h4>
          {
            props.data?.image ? (
              <div style={{ position: "relative" }}>
                <div className={classes.icon_close}>
                  <CancelIcon
                    style={{ fontSize: "35px" }}
                    onClick={handleImageCancel}
                  />
                </div>
                <img
                  src={props.data?.image}
                  alt="image.menu"
                  className="img-menu"
                />
              </div>
            ) : (
              // props.type !== "additional-menu" ?
              <div className={classes.box_upload} onClick={handleClickUpload}>
                <img src={IMAGE.upload} className="icon-upload" />
                <label>
                  <FormattedMessage id="bplusClinicKey506" />
                </label>
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onChange={handleChangeUpload}
                />
              </div>
            )
            // : (
            //   <div className={classes.box_upload}></div>
            // )
          }
          <div className="input-description">
            <label>
              <FormattedMessage id="bplusClinicKey320" />
            </label>
            <Input
              value={props.data?.name || ""}
              fluid
              onChange={(e, { value }) => handleChangeValue("name", value)}
            />

            <label>
              <FormattedMessage id="bplusClinicKey610" />
            </label>
            <Dropdown
              selection
              value={props.data?.type || ""}
              options={props.foodTypeOptions}
              fluid
              onChange={(e, { value }) => handleChangeValue("type", value)}
            />

            <label>
              <FormattedMessage id="bplusClinicKey622" />
            </label>
            <Dropdown
              selection
              value={props.data?.consumption_unit || ""}
              options={props.consumptionUnitOptions}
              fluid
              onChange={(e, { value }) =>
                handleChangeValue("consumption_unit", value)
              }
            />

            <label>
              <FormattedMessage id="bplusClinicKey604" />
            </label>
            <Input
              value={props.data?.weight_grams || ""}
              fluid
              onChange={(e, { value }) =>
                handleChangeValue(
                  "weight_grams",
                  value === "" ? "" : +value < 0 ? 0 : +value
                )
              }
              type="number"
            />
          </div>
        </div>
        <div className="sub-bottom-right-content">
          {/* First */}
          <div>
            {INPUT_MAIN_NUTRIENT.map((item) => (
              <div>
                <label>{item.name}</label>
                <Input
                  value={props.data?.[item.key] ?? ""}
                  fluid
                  onChange={(e, { value }) =>
                    handleChangeValue(
                      item.key,
                      value === "" ? "" : +value < 0 ? 0 : +value
                    )
                  }
                  type="number"
                />
              </div>
            ))}
          </div>
          {/* Second */}
          <div>
            {INPUT_MICRONUTRIENT.map((item) => (
              <div>
                <label>{item.name}</label>
                <Input
                  value={props.data?.[item.key] ?? ""}
                  fluid
                  onChange={(e, { value }) =>
                    handleChangeValue(
                      item.key,
                      value === "" ? "" : +value < 0 ? 0 : +value
                    )
                  }
                  type="number"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.box_footer}>
        <div className="group-button">
          <Button
            color="yellow"
            onClick={props.onClear}
            disabled={!Object.keys(props.data || {})?.[0]}
          >
            <FormattedMessage id="bplusClinicKey113" />
          </Button>
          <Button
            color="green"
            onClick={props.onSave}
            disabled={!Object.keys(props.data || {})?.[0]}
          >
            <FormattedMessage id="bplusClinicKey437" />
          </Button>
        </div>
      </div>
    </>
  );
};

const AdditionalMenu: React.FunctionComponent<AdditionalMenuProps> = (
  props
) => {
  const classes = useStyles();

  const [aditionalMenuList, setAditionalMenuList] = useState<any[]>([]);
  const [addToMasterMenuList, setAddToMasterMenuList] = useState<any[]>([]);
  const [removeFromMasterMenuList, setRemoveFromMasterMenuList] = useState<
    any[]
  >([]);

  /* ----------------------- Effect ----------------------- */
  useEffect(() => {
    props.onEvent({ message: "GetNutritionAditionalMenuList", params: {} });
  }, [props.patientSearch?.patient]);

  useEffect(() => {
    const result = props.nutritionAditionalMenuList.items?.map((item: any) => {
      return { ...item, tmpMasterMenu: item.master_menu };
    });
    setAditionalMenuList(result);
  }, [props.nutritionAditionalMenuList]);

  /* ------------------------ Memo ------------------------ */
  const columnsRecord = [
    {
      Header: "No.",
      width: 70,
      accessor: "_no",
      Cell: (row: any = {}) => {
        return <div style={{ paddingLeft: "8px" }}>{row.index + 1}</div>;
      },
    },
    {
      Header: "Menu",
      minWidth: 75,
      accessor: "_name",
      Cell: (row: any = {}) => {
        return <div style={{ paddingLeft: "8px" }}>{row?.original?.name}</div>;
      },
    },
    {
      Header: "Photo",
      minWidth: 75,
      accessor: "_photo",
      Cell: (row: any = {}) => {
        return (
          <div className={classes.flex_center}>
            {row?.original?.image && (
              <img
                src={row?.original?.image}
                alt="photo.img"
                className={classes.photo}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Nutrient composition",
      minWidth: 75,
      accessor: "_nutrient",
      Cell: (row: any = {}) => {
        return (
          <div className={classes.approved}>
            <Icon
              name="edit outline"
              color="green"
              size="large"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onEvent({
                  message: "HandleSelectedNutritionAditionalMenu",
                  params: { data: row.original },
                });
              }}
            />
            <div>{row?.original?.approved && "Approved"}</div>
          </div>
        );
      },
    },
    {
      Header: "Add to master menu",
      minWidth: 75,
      accessor: "tmpMasterMenu",
      Cell: (row: any = {}) => {
        return (
          <div className={classes.flex_center}>
            <Checkbox
              checked={row?.original?.tmpMasterMenu}
              disabled={!row?.original?.approved}
              onChange={(_event: any, value: any) => {
                changeMe(row.index, value.checked);
              }}
            />
          </div>
        );
      },
    },
  ];

  const changeMe = (index: number, checked: boolean) => {
    let data = [...aditionalMenuList];
    data[index]["tmpMasterMenu"] = checked;
    setAditionalMenuList(data);

    let addToMasterMenu: any[] = [];
    let removeFromMasterMenu: any[] = [];
    data?.forEach((item: any) => {
      if (item.approved && item.master_menu !== item.tmpMasterMenu) {
        if (item.tmpMasterMenu) {
          addToMasterMenu.push(item.id);
        } else {
          removeFromMasterMenu.push(item.id);
        }
      }
    });
    setAddToMasterMenuList(addToMasterMenu);
    setRemoveFromMasterMenuList(removeFromMasterMenu);
  };

  /* ----------------------- Handle ----------------------- */
  const handleChangeValue = (key: string, value: any) => {
    props.onEvent({
      message: "HandleChangeValueSelectedNutritionAditionalMenu",
      params: { key, value },
    });
  };

  const handleClear = () => {
    const {
      id,
      created,
      edited,
      image,
      master_menu,
      approved,
      active,
      owner,
      file,
    } = props.selectedNutritionAditionalMenu || {};

    props.onEvent({
      message: "HandleSelectedNutritionAditionalMenu",
      params: {
        data: {
          id,
          created,
          edited,
          image,
          master_menu,
          approved,
          active,
          owner,
          file,
        },
      },
    });
  };

  const handleChangeDateRecord = (key: string, value: any) => {
    props.onEvent({
      message: "HandleChangeNutritionUMFilter",
      params: { key, value: moment(convertToADDate(value), DATE_FORMAT) },
    });
  };

  const handleSave = () => {
    props.onEvent({
      message: "SaveNutritionAditionalMenu",
      params: {
        addToMasterMenu: addToMasterMenuList,
        removeFromMasterMenu: removeFromMasterMenuList,
      },
    });
  };

  const handleUpdate = () => {
    props.onEvent({
      message: "UpdateNutritionAditionalMenu",
      params: {
        id: props.selectedNutritionAditionalMenu?.id,
        data: props.selectedNutritionAditionalMenu,
        type: "additional",
      },
    });
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    props.onEvent({
      message: "HandleChangeNutritionUMFilter",
      params: { key: "offset", value: pageNo * props.nutritionUMFilter.limit },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.onEvent({
      message: "HandleChangeNutritionUMFilter",
      params: { key: "limit", value: pageSize },
    });
  };

  return (
    <div className={classes.additional_menu}>
      <div style={{ position: "absolute", right: "20px", top: "-40px" }}>
        <Button
          color="blue"
          onClick={handleSave}
          disabled={
            addToMasterMenuList?.length + removeFromMasterMenuList.length === 0
          }
        >
          <FormattedMessage id="bplusClinicKey431" />
        </Button>
      </div>
      <div className={classes.box_top_content}>
        <div>
          <label style={{ marginRight: "8px" }}>
            <FormattedMessage id="bplusClinicKey150" />{" "}
          </label>
          <DateTextBox
            value={
              props.nutritionUMFilter?.startDate
                ? formatDate(
                    props.nutritionUMFilter?.startDate?.format(DATE_FORMAT)
                  )
                : ""
            }
            maxDate={formatDate(moment().format(DATE_FORMAT))}
            onChange={(date: string) => {
              handleChangeDateRecord("startDate", date);
            }}
          />
          <label style={{ margin: "0 8px" }}>-</label>
          <DateTextBox
            value={
              props.nutritionUMFilter?.endDate
                ? formatDate(
                    props.nutritionUMFilter?.endDate?.format(DATE_FORMAT)
                  )
                : ""
            }
            maxDate={formatDate(moment().format(DATE_FORMAT))}
            onChange={(date: string) => {
              handleChangeDateRecord("endDate", date);
            }}
            formatDate="DD MMMM YYYY"
          />
        </div>
        <div>
          {props.patientSearch?.id && (
            <PatientPanel
              patientInfo={props.patientSearch}
              haveBackground={false}
              style={{ padding: 0 }}
            />
          )}
        </div>
      </div>

      <PureReactTable
        // @ts-ignore
        className="weight-table-custom web-mom"
        data={aditionalMenuList}
        columns={columnsRecord}
        showPagination={true}
        pageSize={props.nutritionUMFilter?.limit}
        pages={Math.ceil(
          props.nutritionAditionalMenuList?.total /
            props.nutritionUMFilter?.limit
        )}
        page={props.nutritionUMFilter?.offset / props.nutritionUMFilter?.limit}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        loading={props.loadingStatusAditionalMenu}
        manual
      />

      <NutrientForm
        type="additional-menu"
        data={props.selectedNutritionAditionalMenu}
        onEvent={props.onEvent}
        onClear={handleClear}
        onChangeValue={handleChangeValue}
        consumptionUnitOptions={props.consumptionUnitOptions}
        foodTypeOptions={props.foodTypeOptions}
        onSave={handleUpdate}
        disabledForm={!props.selectedNutritionAditionalMenu}
      />
    </div>
  );
};

const NutritionistMenu: React.FunctionComponent<NutritionistMenuProps> = (
  props
) => {
  const intl = useIntl();
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const [filterName, setFilterName] = useState<string>("");

  useEffect(() => {
    handleGetNutritionistMenuList();
  }, [
    props.nutritionistUMFilter?.name,
    props.nutritionistUMFilter?.type,
    props.nutritionistUMFilter?.limit,
    props.nutritionistUMFilter?.offset,
  ]);

  /* ------------------------ Memo ------------------------ */
  const columnsRecord = useMemo(
    () => [
      {
        Header: "No.",
        width: 70,
        accessor: "_no",
        Cell: (row: any = {}) => {
          return <div style={{ paddingLeft: "8px" }}>{row.index + 1}</div>;
        },
      },
      { Header: "Menu", minWidth: 75, accessor: "name" },
      {
        Header: "Photo",
        minWidth: 75,
        accessor: "_photo",
        Cell: (row: any = {}) => {
          return (
            <div className={classes.flex_center}>
              {row?.original?.image && (
                <img
                  src={row?.original?.image}
                  alt={row?.original?.name}
                  className={classes.photo}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Energy (cal)",
        minWidth: 75,
        accessor: "energy",
        Cell: (row: any = {}) => {
          return (
            <div className={classes.flex_center}>
              {row?.original?.energy_cal}
            </div>
          );
        },
      },
      {
        Header: "Edit",
        minWidth: 75,
        accessor: "_edit",
        Cell: (row: any = {}) => {
          return (
            <div className={classes.flex_center}>
              <Icon
                name="edit outline"
                color="green"
                size="large"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedRow(row.original);
                  props.onEvent({
                    message: "HandleSelectedNutritionNutritionistMenu",
                    params: { data: row.original },
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Delete",
        minWidth: 75,
        accessor: "_delete",
        Cell: (row: any = {}) => {
          return (
            <div className={classes.flex_center}>
              <Button
                color="red"
                circular
                className={classes.btn_delete}
                onClick={() => {
                  setSelectedRow(row.original);
                  props.onEvent({
                    message: "HandleSetOpenModNutrition",
                    params: {
                      open: true,
                      type: "delete",
                      color: "red",
                      prepareData: row.original,
                    },
                  });
                }}
              >
                <Icon name="minus" size="small" />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const allFoodTypeOptions = useMemo(() => {
    let result: any[] = [...props.foodTypeOptions];
    result.unshift({ key: 0, text: "All", value: null });
    return result;
  }, [props.foodTypeList]);

  /* ----------------------- Handle ----------------------- */
  const handleClear = () => {
    const {
      id,
      created,
      edited,
      image,
      master_menu,
      approved,
      active,
      owner,
      file,
    } = props.selectedNutritionNutritionistMenu || {};
    let data: any = null;

    if (id) {
      data = {
        id,
        created,
        edited,
        image,
        master_menu,
        approved,
        active,
        owner,
        file,
      };
    }

    props.onEvent({
      message: "HandleSelectedNutritionNutritionistMenu",
      params: {
        data,
        allowEdit: true,
      },
    });
  };

  const handleChangeValue = (key: string, value: any) => {
    props.onEvent({
      message: "HandleChangeValueSelectedNutritionNutritionistMenu",
      params: { key, value },
    });
  };

  const handleUpdate = () => {
    props.onEvent({
      message: "UpdateNutritionAditionalMenu",
      params: {
        id: props.selectedNutritionNutritionistMenu?.id,
        data: props.selectedNutritionNutritionistMenu,
        type: "nutritionist",
      },
    });
  };

  const handleCloseMod = () => {
    props.onEvent({
      message: "HandleSetOpenModNutrition",
      params: {},
    });
  };

  const handleConfirm = () => {
    if (props.openModNutrition?.type === "edit") {
      props.onEvent({
        message: "HandleSelectedNutritionNutritionistMenu",
        params: { data: props.openModNutrition?.prepareData, allowEdit: true },
      });
    } else if (props.openModNutrition?.type === "delete") {
      props.onEvent({
        message: "DeleteNutritionMenu",
        params: {
          id: props.openModNutrition?.prepareData?.id,
          type: "nutritionist",
        },
      });
    }
    handleCloseMod();
  };

  /* ----------------------- Search ----------------------- */
  const handleGetNutritionistMenuList = () => {
    setSelectedRow({});
    props.onEvent({ message: "GetNutritionNutritionistMenuList" });
  };

  const handleChangeInputName = (_e: any, data: any) => {
    setFilterName(data.value);
  };

  const handleSetDefaultLimitOffset = () => {
    setPage(0);
    props.onEvent({
      message: "HandleSetNutritionistUMFilter",
      params: {
        params: [
          { name: "name", value: filterName },
          { name: "limit", value: "10" },
          { name: "offset", value: "0" },
        ],
      },
    });
  };

  const handleEnterSearchByName = (event: any) => {
    if (event.key === "Enter") {
      handleSetDefaultLimitOffset();
    }
  };

  const handleSearchByType = (_e: any, data: any) => {
    setPage(0);
    setFilterName(props.nutritionistUMFilter?.name || "");
    props.onEvent({
      message: "HandleSetNutritionistUMFilter",
      params: {
        params: [
          { name: "type", value: data.value },
          { name: "limit", value: "10" },
          { name: "offset", value: "0" },
        ],
      },
    });
  };

  /* ----------------------- Pagination ----------------------- */
  const handlePageChange = (pageNo: number) => {
    setPage(pageNo);
    props.onEvent({
      message: "HandleSetNutritionistUMFilter",
      params: {
        params: [
          {
            name: "offset",
            value: (
              pageNo * (props.nutritionistUMFilter?.limit || 0)
            ).toString(),
          },
        ],
      },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.onEvent({
      message: "HandleSetNutritionistUMFilter",
      params: { params: [{ name: "limit", value: pageSize.toString() }] },
    });
  };

  return (
    <div>
      <Grid verticalAlign="middle" style={{ paddingBottom: "20px" }}>
        <Grid.Column width={5}>
          <Input
            fluid
            icon="search"
            placeholder="เมนูอาหาร"
            value={filterName}
            onChange={handleChangeInputName}
            onKeyPress={handleEnterSearchByName}
          />
        </Grid.Column>
        <Grid.Column width={1}>
          <Button color="blue" onClick={handleSetDefaultLimitOffset}>
            {intl.formatMessage({ id: "common.search" })}
          </Button>
        </Grid.Column>
        <Grid.Column
          width={5}
          style={{ textAlign: "right", paddingRight: "0px" }}
        >
          <Header as="h4">
            {intl.formatMessage({ id: "bplusClinicKey610" })}
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Dropdown
            fluid
            selection
            value={props.nutritionistUMFilter?.type || ""}
            options={allFoodTypeOptions}
            onChange={handleSearchByType}
          />
        </Grid.Column>
      </Grid>

      <PureReactTable
        // @ts-ignore
        className="weight-table-custom web-mom"
        selectedRow={selectedRow}
        data={props.nutritionNutritionistMenuList?.items}
        columns={columnsRecord}
        showPagination={true}
        pageSize={props.nutritionistUMFilter?.limit}
        pages={Math.ceil(
          props.nutritionNutritionistMenuList?.total /
            (props.nutritionistUMFilter?.limit || 0)
        )}
        page={page}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        manual
      />

      <NutrientForm
        data={props.selectedNutritionNutritionistMenu}
        onEvent={props.onEvent}
        onClear={handleClear}
        onSave={handleUpdate}
        onChangeValue={handleChangeValue}
        consumptionUnitOptions={props.consumptionUnitOptions}
        foodTypeOptions={props.foodTypeOptions}
      />

      <ModConfirm
        titleColor={props.openModNutrition?.color || "yellow"}
        openModal={props.openModNutrition?.open || false}
        onDeny={handleCloseMod}
        onApprove={handleConfirm}
        onCloseWithDimmeClick={handleCloseMod}
        content={
          <div className={classes.mod_content}>
            {props.openModNutrition?.type === "edit" ? (
              <>
                <div>
                  <FormattedMessage id="bplusClinicKey613" />
                </div>
                <div>
                  <FormattedMessage id="bplusClinicKey601" />
                </div>
              </>
            ) : (
              <>
                <div>
                  <FormattedMessage id="bplusClinicKey600" />
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

const WebDMUMNutrition: React.FunctionComponent<WebDMUMNutritionProps> = (
  props
) => {
  const panes = [
    {
      menuItem: "Additional menu",
      render: () => (
        <Tab.Pane>
          <AdditionalMenu
            {...props}
            foodTypeOptions={foodTypeOptions}
            consumptionUnitOptions={consumptionUnitOptions}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Menu item by nutritionist",
      render: () => (
        <Tab.Pane>
          <NutritionistMenu
            {...props}
            foodTypeOptions={foodTypeOptions}
            consumptionUnitOptions={consumptionUnitOptions}
          />
        </Tab.Pane>
      ),
    },
  ];

  useEffect(() => {
    props.onEvent({ message: "GetNutritionMaster", params: {} });
  }, []);

  const CreateOptions = (items: any[]) => {
    const mapOption = (item: any, index: number) => ({
      key: index + 1,
      text: item.label || item.name,
      value: item.id,
    });
    return items.map(mapOption);
  };

  const foodTypeOptions = useMemo(() => {
    return CreateOptions(props.foodTypeList || []);
  }, [props.foodTypeList]);

  const consumptionUnitOptions = useMemo(() => {
    return CreateOptions(props.consumptionUnitList || []);
  }, [props.consumptionUnitList]);

  return (
    <div className="main-layout web-dm-um">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <Tab panes={panes} />
    </div>
  );
};

WebDMUMNutrition.defaultProps = {
  onEvent: () => null,
  setProp: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  loadingStatusAditionalMenu: false,
  nutritionAditionalMenuList: {},
  selectedNutritionAditionalMenu: null,
  nutritionNutritionistMenuList: {},
  selectedNutritionNutritionistMenu: null,
  nutritionistUMFilter: {
    limit: 10,
    offset: 0,
    name: "",
    type: null,
  },
} as WebDMUMNutritionProps;

export default React.memo(WebDMUMNutrition);
