import React, { useMemo } from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { SxProps } from "@mui/material";

// Icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import moment from "moment";

// Interface
import {
  EpisodeDetailType,
  MedicineReceivingOptionType,
  CheckoutStatusType,
} from "bplus-lib/telephar/Types";
import * as Types from "./Types";

// Types
type CardPaymentProps = {
  // data
  keyId: number;
  data?: PaymentDataType;
  dataDeposit?: Types.DepositPaymentType;
  detailLoading?: boolean;
  paymentLoading?: boolean;
  checkoutStatus?: CheckoutStatusType;
  drugStatus?: MedicineReceivingOptionType;
  type: PaymentType;
  // payment
  totalAmount?: string | number;
  // styles
  sx?: SxProps;
  // config
  hidePayment?: boolean;
  hideDescription?: boolean;
  // isReceipt?: boolean; // ดูใบเสร็จ
  // callback
  onSeeDetail?: (data: any, id: number, deposit?: any) => any;
  onPayment?: (
    data: any,
    type: PaymentType,
    index: number,
    link?: string
  ) => any;
};

export type PaymentType = "pay" | "deposit" | "receipt";

export type PaymentDataType = Pick<
  EpisodeDetailType,
  | "started"
  | "consult_doctors"
  | "doctor"
  | "division"
  | "number"
  | "hospital_name"
  | "id"
  | "invoice"
  | "receipt"
  | "receipts"
  | "checkout_status"
  | "drug_status"
  | "delivery_fee"
  | "cardSelected"
>;

// Images
const IMAGES = {
  calendar: "/images/payment/calendar-outline.png",
  pin: "/images/payment/pin-location.png",
  doctor: "/images/Feed/doctor.png",
  note: "/images/payment/note-outline.png",
  medication_orange: "/images/payment/medication-orange.png",
  bg_intersect: "/images/payment/bg-intersect.png",
  receive_medicine: "/images/payment/receive-medicine.png",
  drug_delivery: "/images/payment/drug-delivery.png",
  doctor_orange: "/images/payment/doctor-outline-orange.png",
};

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  card_shadow: "1px 8px 18px 0px rgba(44, 44, 44, 0.1)",
  divider: "#E9E9E9",
  light_label: "rgba(121, 120, 120, 1)",
  bg_shadow: "linear-gradient(317.15deg, #F9F8F8 -14.59%, #EFEFEF 87.87%)",
  label: "rgba(0,0,0,0.87)",
  light_blue: "rgba(245, 249, 255, 1)",
  orange: " rgba(243, 156, 18, 1)",
  bg_medicine: "rgba(253, 244, 236, 1)",
};

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const doctorStyles = {
  margin: "10px 0 0",
  "& > label": {
    fontSize: ".9375rem",
    color: COLORS.label,
    paddingBottom: "0.25rem",
  },
  "& > div": {
    marginTop: "0.25rem",
    "& > div:nth-child(1)": {
      width: "59.29px",
      height: "59.29px",
    },
    "& > div:nth-child(2) .title": {
      fontWeight: "bold",
      marginBottom: "0.55rem",
    },
  },
};

const payStyles = {
  gridTemplateColumns: "auto 1fr",
  display: "grid",
  marginTop: "1.25rem",
  "& > div:nth-child(1)": {
    "& > label": {
      fontSize: "1.125rem",
    },
    "& > div": {
      color: COLORS.primary,
      fontWeight: "bold",
      fontSize: "1.25rem",
      marginTop: "0.3rem",
    },
  },
  "& > div:nth-child(2)": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .button-submit-bottom ": {
    paddingBottom: "0",
    marginTop: "0.2rem",
    "& .MuiButton-root": {
      fontSize: "1rem",
      padding: "6px",
    },
  },
  "& .receipt": {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "1rem",
    alignItems: "flex-end",
    color: COLORS.primary,
    paddingBottom: "0.25rem",
    "& img": {
      width: "19px",
      height: "fit-content",
      marginRight: "0.5rem",
    },
  },
};

export const cardStyles = {
  marginTop: "1rem",
  "&.MuiCard-root": {
    boxShadow: COLORS.card_shadow,
    "& .MuiCardContent-root": { padding: 0, width: "100%" },
  },
  "& .header": {
    padding: "15px 17px 12px",
    color: COLORS.primary,
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    "& > div:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      fontWeight: "normal",
      "& img": {
        width: "22px",
        marginRight: "0.5rem",
      },
      color: COLORS.orange,
    },
  },
  "& .divider": {
    border: `1.5px solid ${COLORS.divider}`,
    borderStyle: "dashed",
  },
  "& .content": {
    padding: "15px",
    "& img.icon": {
      width: "16px",
      marginRight: "0.55rem",
    },
    "& .label": {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.75rem",
      color: COLORS.light_label,
    },
    "& .doctor": doctorStyles,
    "& .pay": payStyles,
  },
  "& .see-detail": {
    backgroundColor: COLORS.light_blue,
    color: COLORS.primary,
    padding: "11px 20px 11px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      fontSize: "18px",
    },
  },
};

const useStyles = makeStyles(() => ({
  card: cardStyles,
  medicine_status: {
    backgroundColor: COLORS.bg_medicine,
    borderRadius: "4px",
    height: "54px",
    width: "100%",
    margin: "1rem 0 -0.3rem",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    "& img": {
      height: "54px",
    },
    "& > div:nth-child(1)": {
      ...styles.center,
      fontWeight: "bold",
      paddingLeft: "16px",
    },
    "& .overlay": {
      position: "absolute",
      height: "54px",
      right: 0,
    },
  },
  btn_content: {
    display: "flex",
    alignItems: "center",
    fontSize: ".9375rem",
    color: COLORS.primary,
    marginTop: "1.05rem",
    "& img": {
      width: "18px",
      height: "auto",
      marginRight: "0.75rem",
    },
  },
}));

// Const
const DRUG_STATUS = {
  pharmacy_room: {
    image: IMAGES.receive_medicine,
    title: "รับยาที่ห้องจ่ายยา",
  },
  delivery: {
    image: IMAGES.drug_delivery,
    title: "จัดส่งยา",
  },
};

const CHECKOUT_STATUS = {
  medication_fee: {
    image: IMAGES.medication_orange,
    title: "ค่ายา",
  },
  doctor_fee: { image: IMAGES.doctor_orange, title: "ค่าแพทย์" },
  have_medication: { image: IMAGES.medication_orange, title: "มียา" },
};

export const PAYMENT_TYPE = {
  pay: {
    outline: false,
    title: "ชำระเงิน",
  },
  deposit: {
    outline: false,
    title: "ชำระเงิน",
  },
  receipt: { outline: true, title: "ดูใบเสร็จ" },
};

const CardPayment = (props: CardPaymentProps) => {
  const classes = useStyles();

  const isDeposit = useMemo(() => {
    switch (props.type) {
      case "pay": {
        return false;
      }
      case "deposit": {
        return true;
      }
      case "receipt": {
        const date = (props.data?.receipts || []).filter(
          (item: any) => item.id === props.keyId
        )?.[0];
        if (date.code.includes("DEPOSITTELE")) {
          return true;
        } else {
          return false;
        }
      }
      default: {
        return false;
      }
    }
  }, [props.type, props.data]);
  // Memo
  const visitDate = useMemo(() => {
    const date = props.dataDeposit?.encounter_started || props?.data?.started;

    const momentDate = moment(date).clone().locale("th");
    const dm = momentDate.format("DD MMM");
    const year = Number(momentDate.format("YYYY")) + 543;

    return `${dm} ${year} ${momentDate.format("HH:mm")}`;
  }, []);

  const doctorItems = useMemo(() => {
    const data = props.data || props.dataDeposit;
    const fullName =
      props.data?.doctor?.full_name || props.dataDeposit?.doctor?.fullname;
    return [
      {
        id: 1,
        name: fullName,
        image: data?.doctor?.image_url,
        division: data?.division?.name,
      },
      ...(data?.consult_doctors || []).map((item, index) => ({
        id: index + 2,
        name: fullName,
        image: item.image_url,
        division: item.division_name,
      })),
      // { id: 2, name: "พล.อ.ท.นพ. กัมปนาท วีรกุล" },
    ].filter((item) => item.name);
  }, [props.data, props.dataDeposit]);

  const totalAmount = useMemo(() => {
    return props.totalAmount
      ? Number(props.totalAmount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "";
  }, [props.totalAmount]);

  // Handler
  const handleSeeDetail = () => {
    if (!props.detailLoading) {
      if (props.type === "deposit") {
        props.onSeeDetail?.(
          {
            ...(props.data && { ...props.data }),
            ...(props.dataDeposit && { ...props.dataDeposit }),
            type: props.type,
            checkoutStatus: props.checkoutStatus,
            drugStatus: props.drugStatus,
          },
          props.keyId,
          props.dataDeposit
        );
      } else {
        props.onSeeDetail?.(
          {
            ...(props.data && { ...props.data }),
            ...(props.dataDeposit && { ...props.dataDeposit }),
            type: props.type,
            checkoutStatus: props.checkoutStatus,
            drugStatus: props.drugStatus,
          },
          props.keyId
        );
      }
    }
  };

  const handlePayment = () => {
    let index = props.data?.receipts?.findIndex(
      (item) => item.id === props.keyId
    );
    if (index === undefined) {
      index = -1;
    }

    if (props.type === "deposit") {
      props.onPayment?.(
        props.data,
        props.type,
        index,
        props.dataDeposit?.override_payment_link
      );
    } else {
      props.onPayment?.(props.data, props.type, index);
    }
  };

  return (
    <Card className={classes.card} sx={props.sx}>
      <CardActionArea disableRipple={true}>
        <CardContent>
          <div className="header">
            <div>{isDeposit && props.type !== "receipt" ? "" : `EN : ${props?.data?.number}`}</div>
            {props.checkoutStatus && (
              <div>
                <img src={CHECKOUT_STATUS[props.checkoutStatus].image} />
                <div>{CHECKOUT_STATUS[props.checkoutStatus].title}</div>
              </div>
            )}
          </div>
          <div className="divider"></div>

          <div className="content">
            <div className="label">
              <img className="icon" src={IMAGES.calendar}></img>
              <label>{visitDate}</label>
            </div>

            <div className="label" style={{ marginBottom: "12px" }}>
              <img className="icon" src={IMAGES.pin}></img>
              <label>
                {isDeposit && props.type !== "receipt"
                  ? props?.dataDeposit?.hospital_name ||
                    "โรงพยาบาลกรุงเทพ สำนักงานใหญ่"
                  : props?.data?.hospital_name ||
                    "โรงพยาบาลกรุงเทพ สำนักงานใหญ่"}
              </label>
            </div>

            {doctorItems.map((item, index) => (
              <div key={item.id} className="doctor">
                {doctorItems.length > 1 ? (
                  <label>แผนกที่ {index + 1}</label>
                ) : (
                  <div style={{ marginTop: "0.25rem" }}></div>
                )}
                <div className="doctor-profile-detail">
                  <div>
                    <img
                      src={item.image || IMAGES.doctor}
                      onError={(e: any) => (e.target.src = IMAGES.doctor)}
                    />
                  </div>
                  <div>
                    <label className="title">{item.name}</label>
                    <label>{item.division}</label>
                  </div>
                </div>
              </div>
            ))}

            {props.checkoutStatus === "have_medication" && props.drugStatus && (
              <div className={classes.medicine_status}>
                <div>{DRUG_STATUS[props.drugStatus].title}</div>
                <div>
                  <img src={IMAGES.bg_intersect} />
                  <img
                    className="overlay"
                    src={DRUG_STATUS[props.drugStatus].image}
                  />
                </div>
              </div>
            )}

            {!props.hidePayment && (
              <div className="pay">
                <div>
                  <label>ยอดชำระ</label>
                  <div>{totalAmount} บาท</div>
                </div>

                <div
                  className={`button-submit-bottom${
                    PAYMENT_TYPE[props.type].outline ? " outline" : ""
                  }`}
                >
                  {PAYMENT_TYPE[props.type].outline ? (
                    <div
                      aria-hidden="true"
                      className={classes.btn_content}
                      onClick={handlePayment}
                    >
                      <img src={IMAGES.note} />{" "}
                      <span>
                        {isDeposit
                          ? "ดูใบมัดจำ"
                          : PAYMENT_TYPE[props.type].title}
                      </span>
                      {props.paymentLoading && (
                        <CircularProgress
                          style={{
                            marginLeft: "0.5rem",
                          }}
                          size={18}
                        />
                      )}
                    </div>
                  ) : (
                    <MuiButton
                      variant="contained"
                      color="primary"
                      disabled={props.paymentLoading}
                      style={{ width: "50%" }}
                      onClick={handlePayment}
                    >
                      {PAYMENT_TYPE[props.type].title}
                      {props.paymentLoading && (
                        <CircularProgress
                          style={{
                            marginLeft: "0.5rem",
                          }}
                          size={18}
                        />
                      )}
                    </MuiButton>
                  )}
                </div>
              </div>
            )}
          </div>

          {!props.hidePayment && (
            <div
              aria-hidden="true"
              className="see-detail"
              onClick={handleSeeDetail}
            >
              <label>ดูรายละเอียด</label>
              <div>
                {props.detailLoading && (
                  <CircularProgress
                    size={15}
                    style={{
                      margin: "0rem 0.25rem 0.05rem",
                    }}
                  />
                )}
                <ArrowForwardIosIcon />
              </div>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardPayment);
