import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import { SemanticCOLORS } from "semantic-ui-react";
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";
import {
  GROUP,
  DATA_TYPE,
  commonCreatePatientData,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonUpdatePatientData,
  commonDeletePatientData,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  EXERCISE_TYPE,
  State as MainState
} from "../MobClinicInterface";

export type ModExerciseType = {
  data?: any;
  open?: boolean;
  error?: any;
  title?: string;
  color?: SemanticCOLORS;
  loading?: boolean;
};

export type State = {
  exerciseFilterDate?: moment.Moment;

  // List
  exerciseTypeList?: any[];
  exerciseRecordList?: any[];
  exerciseChartList?: any[];

  // setting
  exerciseSettingData?: any;

  // Open
  openModRemoveExercise?: ModExerciseType;
  openModInfoExercise?: ModExerciseType;
};

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage">

export const ModInitial = {
  data: null,
  open: false,
  error: null,
  title: "",
  color: undefined,
  loading: false,
};

export const StateInitial = {
  exerciseFilterDate: moment(),

  // List
  exerciseTypeList: [],
  exerciseRecordList: [],
  exerciseChartList: [],

  // setting
  exerciseSettingData: {},

  // Open
  openModRemoveExercise: ModInitial,
  openModInfoExercise: ModInitial,
};

export type Event =
  // GET
  | { message: "GetExerciseType"; params: {} }
  | { message: "GetExerciseRecord", params: {} }
  | {
    message: "GetExerciseChart";
    params: { start_measure: string; end_measure?: string };
  }
  | { message: "GetExerciseSetting", params: {} }
  // CHANGE
  | {
    message: "handleChangeExerciseFilterDate";
    params: { date: moment.Moment };
  }
  // SAVE
  | { message: "SaveExerciseRecord"; params: { data: any } }
  | { message: "DeleteExerciseRecord"; params: {} }
  | { message: "SaveExerciseSetting"; params: {} }
  //  SET
  | {
    message: "SetModRemoveExercise";
    params: ModExerciseType & { clear?: boolean };
  }
  | {
    message: "SetModInfoExercise";
    params: ModExerciseType & { clear?: boolean };
  };

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const GetExerciseType: Handler = (controller) => {
  // Connect Api
  controller.setState({
    exerciseTypeList: [
      { id: 1, name: EXERCISE_TYPE.AEROBIC.text },
      { id: 2, name: EXERCISE_TYPE.WEIGHT_RESISTANCE.text },
      { id: 3, name: EXERCISE_TYPE.FLEXIBILITY.text },
    ],
  });
};

export const GetExerciseRecord: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const tmpParams: any = {
    group: GROUP.EXERCISE,
    data_type: DATA_TYPE.EXERCISE,
    start_measure: state.exerciseFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    exerciseRecordList: response,
  });
};

export const GetExerciseChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true })

  const typeList = [
    { id: 1, name: EXERCISE_TYPE.AEROBIC.text },
    { id: 2, name: EXERCISE_TYPE.WEIGHT_RESISTANCE.text },
    { id: 3, name: EXERCISE_TYPE.FLEXIBILITY.text },
  ]

  const tmpParams: any = {
    group: GROUP.EXERCISE,
    data_type: DATA_TYPE.EXERCISE,
    ...params, // start_measure, end_measure
  };

  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  let errorMessage: any = null;
  let successMessage: any = null;
  let exerciseChartResponse: any[] = [];
  if (error) {
    console.log(error, response);
    errorMessage = error;
  } else {
    if (response.items?.length > 0) {
      exerciseChartResponse = response.items.map((item: { data: any; id: any; measure_date: any; }) => {
        return {
          ...item.data,
          id: item.id,
          date: formatUTCtoMoment(item.measure_date)
            .locale("en")
            .format("YYYY/MM/DD"),
          time: formatUTCtoMoment(item.measure_date).format("HH:mm"),
          type_name: typeList.find((acc) => acc.id === item.data.type)?.name || ""
        };
      });
    }
  }

  console.log("Get response:", exerciseChartResponse);

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
    exerciseChartList: exerciseChartResponse,
  });
};

export const GetExerciseSetting: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.EXERCISE,
    data_type: DATA_TYPE.SETTING,
  };
  let [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  if (!!response?.data?.times?.length) {
    response.data.times = response.data.times.filter((item: any) => typeof item === "string")
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    exerciseSettingData: response,
  });
};

// CHANGE
export const handleChangeExerciseFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({
    exerciseFilterDate: moment(params.date, "DD/MM/YYYY"),
  });
};

// SAVE
export const SaveExerciseRecord: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const dataInsert = {
    group: GROUP.EXERCISE,
    data_type: DATA_TYPE.EXERCISE,
    measure_date: `${state.exerciseFilterDate?.format(
      BACKEND_DATE_FORMAT
    )} ${moment().format(BACKEND_TIME_FORMAT)}`,
    data: { ...params.data },
  };

  const [error, response] = await commonCreatePatientData(
    controller as any,
    dataInsert
  );

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add exercise data success.";
    GetExerciseRecord(controller);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
  return [response, error];
};

export const DeleteExerciseRecord: Handler = async (controller) => {
  const { openModRemoveExercise } = controller.getState();

  controller.setState({
    openModRemoveExercise: { ...openModRemoveExercise, loading: true },
  });


  if (openModRemoveExercise?.data?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: openModRemoveExercise?.data?.id,
    });
    if (error) {
      controller.setState({
        openModRemoveExercise: {
          ...openModRemoveExercise,
          loading: false,
          error: error,
        },
      });
      return;
    } else {
      SetModRemoveExercise(controller, { clear: true });
      GetExerciseRecord(controller);
    }
  } else {
    controller.setState({
      openModRemoveExercise: {
        ...openModRemoveExercise,
        loading: false,
        error: "ID not found.",
      },
    });
  }
};

export const SaveExerciseSetting: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data: any = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };

    if ('reminder' in data.data) {
      data.data.isReminder = data?.data?.reminder
      delete data.data.reminder
    }

    if ('time_setting' in data.data) {
      data.data.times = data?.data?.time_setting
      delete data.data.time_setting
    }
    // console.log("SaveExerciseSetting data")

    [error, response] = await commonUpdatePatientData(controller as any, data);
  } else {
    data = {
      group: GROUP.EXERCISE,
      data_type: DATA_TYPE.SETTING,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }

  if (typeof globalThis !== "undefined" ? globalThis.iosNative?.setiOSLocalAlarm : window.iosNative?.setiOSLocalAlarm) {
    // IOS Issue 53385 ตั้งแจ้งเตือน iOS
    console.log("call globalThis.iosNative.setiOSLocalAlarm");
    if (typeof globalThis !== "undefined") {
      globalThis.iosNative?.setiOSLocalAlarm(data)
    } else {
      window.iosNative?.setiOSLocalAlarm(data)
    } 
    // globalThis.iosNative?.setiOSLocalAlarm(data);
  }

  if (typeof globalThis !== "undefined" ? globalThis.MobNative : window.MobNative) {
    // Android 
    console.log("call globalThis.MobNative.setLocalAlarm")
    console.log(data)
    if ( typeof globalThis !== "undefined") {
      globalThis.MobNative.setLocalAlarm(JSON.stringify(data))
    } else {
      console.log("JSON.stringify(data)", JSON.stringify(data))
      window.MobNative.setLocalAlarm(JSON.stringify(data))
    } 
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save settings success.";
    GetExerciseSetting(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

// SET
export const SetModRemoveExercise: Handler = (controller, params) => {
  if (!params.clear) {
    controller.setState({
      openModRemoveExercise: { ...ModInitial, ...params },
    });
  } else {
    controller.setState({ openModRemoveExercise: ModInitial });
  }
};

export const SetModInfoExercise: Handler = (controller, params) => {
  if (!params.clear) {
    controller.setState({ openModInfoExercise: { ...ModInitial, ...params } });
  } else {
    controller.setState({ openModInfoExercise: ModInitial });
  }
};


