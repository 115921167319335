import { Padding } from "@mui/icons-material";
import moment from "moment";
import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Dimmer, Divider, Grid, Icon, Image, Loader, Modal } from "semantic-ui-react";
import CloseIcon from "@mui/icons-material/Close";
import { set } from "js-cookie";
import { FormattedMessage } from "react-intl";

// Controller
import QueueController from "../react-lib/apps/QUE/QueueController";

// Manager
import QUEManager from "../react-lib/apis/manager/QUEManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";

type MessageTrackingProps = {
  apiToken: any;
  tracking: any;
  contentPayload: any;
  // Controller
  queueController: QueueController & {
    queManager: QUEManager;
    prxManager: PRXManager;
  };
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  white: "white",
  lightBlue2: "#ECF4FF",
  lighterBlue: "#EBF1F8",
  lightBlue: "#A3D2F8",
  lighter: "#DBE5F3",
  normalGrey: "#393939",
};

const ICON = {
  medical: "/images/telephar/icon_medical.png",
  information: "/images/telephar/icon_information.png",
};

const styles = {
  boxWarning: {
    marginTop: "24px",
    background: COLOR.lighterBlue,
    border: `1px solid ${COLOR.lightBlue}`,
    borderRadius: "5px",
  } as CSSProperties,
  setCenter: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  boxTacking: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "15px 14px",
    borderRadius: "8px",
    background: COLOR.lightBlue2,
    padding: "17px",
  } as CSSProperties,
  buttonTacking: {
    background: "#0147A3",
    borderRadius: "10",
    color: "white",
    fontSize: "16px",
    padding: "16px 0",
    width: "75%",
  },
};

const MessageTracking = (props: MessageTrackingProps) => {
  const [openTracking, setOpenTracking] = useState<boolean>(false);
  const [confirmTracking, setConfirmTracking] = useState<boolean>(false);
  const [loadingTracking, setLoadingTracking] = useState<boolean>(false);

  const handleClickMessage = () => {
    setOpenTracking(true);
  };

  const handleCloseTracking = () => {
    setOpenTracking(false);
    setConfirmTracking(false);
  };

  const handleClickTracking = async() => {
    setLoadingTracking(true);
    const [resProvider, errorProvider] =
        await props.queueController.postPatientShippingOrderReceived({
          apiToken: props.apiToken,
          pk: props.contentPayload.id,
        });
      if (errorProvider ) {
        setLoadingTracking(false);
      } else {
        setConfirmTracking(true);
        setLoadingTracking(false); 
      }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}
    >
      <div style={{ minWidth: "max-content", whiteSpace: "pre-line" }}>
        {`ขณะนี้การจัดส่งยาของท่านอยู่ใน\nสถานะ “กำลังจัดส่งยา”
            \nหากท่านได้รับยาเรียนร้อยแล้ว\nกรุณากด “ได้รับยาแล้ว”\nเพื่อรับคำปรึกษายาจากเภสัชกรต่อไป`}
      </div>
      <div
        style={{
          margin: "5px 0",
          ...styles.setCenter,
        }}
      >
        <Button
          style={{
            background: COLOR.primary,
            borderRadius: "10",
            color: "white",
          }}
          onClick={() => handleClickMessage()}
        >
          ได้รับยาแล้ว
        </Button>
      </div>

      <Modal
        open={openTracking}
        onClose={() => {}}
        style={{ width: "90%" }}
      >
        <Dimmer
          active={loadingTracking}
          inverted
        >
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>
        <div style={{ padding: "20px" }}>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            onClick={handleCloseTracking}
          >
            <CloseIcon fontSize="large" />
          </div>
          <div style={styles.setCenter}>
            <Image src={ICON.medical} />
          </div>
          <div
            style={{
              fontSize: "20px",
              color: COLOR.primary,
              ...styles.setCenter,
              marginTop: "30px",
              fontWeight: "bold",
            }}
          >
            {confirmTracking
              ? "ท่านได้รับยาเรียบร้อยแล้ว"
              : "ท่านได้รับยาเรียบร้อยแล้วหรือไม่?"}
          </div>
          <div style={styles.boxTacking}>
            <div
              style={{
                fontSize: "16px",
                color: COLOR.primary,
                ...styles.setCenter,
              }}
            >
              Tracking No.
            </div>
            <div
              style={{
                marginTop: "13px",
                fontSize: "25px",
                color: COLOR.primary,
                fontWeight: "bold",
                ...styles.setCenter,
              }}
            >
              {props.contentPayload.tracking_number? props.contentPayload.tracking_number:" " }
            </div>
          </div>
          <div
            style={{
              ...styles.setCenter,
              marginTop: "9px",
              whiteSpace: "pre-line",
              lineHeight: "24px",
              fontSize: "15px",
              textAlign: "center",
              ...(confirmTracking && { display: "none" }),
            }}
          >
            {`หากท่านได้รับยาเรียบร้อยแล้ว \nกรุณากดยืนยัน เพื่อยืนยันสถานะจัดส่ง\nเพื่อนัดหมายกับเภสัชกรเพื่อให้คำปรึกษายาต่อไป`}
          </div>
          <div
            style={{
              ...styles.setCenter,
              marginTop: "14px",
              ...(confirmTracking && { display: "none" }),
            }}
          >
            <Button
              style={styles.buttonTacking}
              onClick={() => handleClickTracking()}
            >
              ใช่ ฉันได้รับยาแล้ว
            </Button>
          </div>

          <div
            style={{
              ...styles.boxWarning,
              ...(confirmTracking && { display: "none" }),
            }}
          >
            <Grid style={{ margin: "0px" }}>
              <Grid.Row style={{ padding: "1rem" }}>
                <Grid.Column
                  width={2}
                  style={{
                    ...styles.setCenter,
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Image
                    src={ICON.information}
                    style={{ width: "24px", height: "24px" }}
                  />
                </Grid.Column>
                <Grid.Column width={14} style={{ padding: "0px" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#008BF5",
                      whiteSpace: "pre-line",
                      marginLeft: "5px",
                      lineHeight: "22px",
                    }}
                  >{`หากท่านยังไม่ได้รับยา กรุณากด “ปิด”\nและรอยืนยันอีกครั้งหลังท่านได้ยาแล้ว`}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(MessageTracking);
