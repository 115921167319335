import React, { useState, useMemo } from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import { FormattedMessage, useIntl } from "react-intl";

// Icon
import CloseIcon from "@mui/icons-material/Close";

// UX
import ModAccessVerify from "./ModAccessVerify";

// I.Styles
import { cardModStyles } from "./CardKYC";

// Types
type ModGettingStartedProps = {
  onEvent: (e: any) => any;
  // data
  history: History;
  hospitalList?: HospitalDetailType[];
  patient?: Record<string, any>;
  // CommonInterface
  loadingStatus?: Record<string, any>;
};

type HospitalDetailType = {
  hospital_id: number;
  hospital_code: string;
  hn: string;
  phone: string;
  email: string;
  organization: {
    id: number;
    code: string;
    name: string;
    name_en: string;
  };
  patient_id: number;
};

// Images
const IMAGES = {
  profile: "/images/register/profile.png",
  alert: "/images/register/alert.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  very_light_grey: "rgba(196, 196, 196, 0.5)",
  red: "rgba(218, 0, 0, 1)",
};

const useStyles = makeStyles((theme) => ({
  card: cardModStyles,
}));

// Const
const MOD_GETTING_STARTED = "ModGettingStarted";

const ModGettingStarted = (props: ModGettingStartedProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const hospitals = useMemo(() => {
    const list = props.hospitalList || [];
    const email = props.patient?.email;

    const [match, notMatch] = list.reduce(
      (result, item) => {
        result[
          (email.includes(item.phone.slice(1)) && item.phone) ||
          (email === item.email && item.email)
            ? 0
            : 1
        ].push(item);

        return result;
      },
      [[], []] as HospitalDetailType[][]
    );

    return { match, notMatch };
  }, [props.patient, props.hospitalList]);

  const hospitalsMatch = useMemo(() => {
    const list = hospitals.match;
    return isSeeAll ? list : list.slice(0, 3);
  }, [hospitals.match, isSeeAll]);

  // Handler
  const handleClickButton = (isKyc?: boolean, closeType?: string) => {
    if (hospitals.notMatch.length) {
      handleSomeOrNotMatch(isKyc, closeType);
    } else if (hospitals.match.length === 1) {
      handleGotoFeed(closeType);
    } else {
      handleSelectHospital(isKyc);
    }
  };

  const handleSomeOrNotMatch = (isKyc?: boolean, closeType?: string) => {
    // some match = 1
    if (hospitals.match.length === 1) {
      handleGotoFeed(closeType);
    } else if (hospitals.match.length > 1) {
      // some match หลายโรงพยาบาล
      handleSelectHospital(isKyc);
    } else {
      // สมัครใหม่
      props.history.push("/kyc/?app=MobRegister");
    }
  };

  const handleSelectHospital = (isKyc?: boolean) => {
    props.history.push({
      pathname: `/select-hospital/`,
      search: `?app=MobRegister${isKyc ? "&actiontype=verify-identity" : ""}`,
      state: {
        hospitalsMatch: hospitals.match,
      },
    });
  };

  const handleToggle = () => {
    setIsSeeAll(!isSeeAll);
  };

  const handleKYC = () => {
    if (!props.hospitalList?.length) {
      props.history.push("/kyc/?app=MobRegister&actiontype=edit-profile");
    } else {
      props.history.push("/kyc/?app=MobRegister&actiontype=verify-identity");
    }
  };

  // สมัครใหม่, all match = 1, some macth = 1
  const handleGotoFeed = async (closeType?: string) => {
    const data = hospitals.match[0];

    // some macth = 1
    let someMatch = false;

    // all match = 1, some macth = 1
    if (data) {
      await props.onEvent({
        message: "HandlerPostRetrieveProfile",
        params: {
          card: MOD_GETTING_STARTED,
          btnAction: closeType ? `_CLOSE_${closeType}` : "_START",
          code: data.organization.code,
          hn: data.hn,
          name: data.organization.name,
          initHospital: true,
        },
      });

      someMatch =
        !!hospitals.notMatch.length && !!hospitals.match.length && !closeType;
      // some macth = 1
      if (someMatch) {
        handleKYC();
      }
    }

    // all match = 1, some macth = 1
    if (!someMatch) {
      props.onEvent({
        message: "HandleCanceleKYC",
        params: { history: props.history },
      });
    }
  };

  const handleClose = (closeType: string) => {
    handleClickButton(false, closeType);
  };

  return (
    <div>
      {!hospitals.match.length ? (
        <ModAccessVerify
          buttonText={
            !props.hospitalList?.length ? (
              <FormattedMessage id="bplusClinicKey1023" />
            ) : (
              <FormattedMessage id="bplusClinicKey1024" />
            )
          }
          firstApp={!props.hospitalList?.length}
          // callback
          onApprove={handleKYC}
          onDeny={() => handleGotoFeed("K")}
          onClose={() => handleGotoFeed("C")}
        />
      ) : (
        <Dialog open={true} className="smart-dialog-container">
          <div
            className="close-icon-right"
            style={{ position: "absolute", top: "20px", right: "20px" }}
          >
            <MuiButton color="primary" variant="text">
              {props.loadingStatus?.[`${MOD_GETTING_STARTED}_CLOSE_C`] ? (
                <CircularProgress
                  size={20}
                  style={{ margin: "0.75re 1rem 0 0" }}
                />
              ) : (
                <CloseIcon onClick={() => handleClose("C")} />
              )}
            </MuiButton>
          </div>
          <DialogContent>
            <div className="content">
              <img src={IMAGES.profile} alt="profile" />
              <div className="title" style={{ margin: "0rem 0 1rem" }}>
                <div>
                  <FormattedMessage id="bplusClinicKey1069" />
                </div>
                {!!props.patient?.first_name && (
                  <div style={{ marginTop: "0.75rem" }}>
                    <FormattedMessage id="bplusClinicKey1073" />{" "}
                    {props.patient?.first_name} {props.patient?.last_name}
                  </div>
                )}
              </div>
              <div>
                <div>
                  <FormattedMessage id="bplusClinicKey1070" />
                </div>
                <div>
                  {intl.locale === "th" ? (
                    <>
                      <FormattedMessage id="bplusClinicKey1071" />{" "}
                      {hospitalsMatch.length}
                      <FormattedMessage id="bplusClinicKey1072" />
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="bplusClinicKey1071" />{" "}
                      <FormattedMessage id="bplusClinicKey1072" />
                      {hospitalsMatch.length}{" "}
                      {hospitalsMatch.length === 1 ? "hospital" : "hospitals"}
                    </>
                  )}
                </div>
              </div>
            </div>

            {hospitalsMatch.map((item, index) => (
              <div
                key={`card-${item.hn}`}
                className={classes.card}
                style={{
                  padding: "10px 0 5px",
                  margin: index === 0 ? "" : "-1.15rem 0 2rem",
                }}
              >
                <div>
                  <div className="center --small">{item.organization.name}</div>
                  <div className="center --bold --large">{item.hn}</div>
                </div>
              </div>
            ))}

            {!!hospitals.notMatch.length && (
              <>
                <div
                  style={{
                    border: `0.5px solid ${COLORS.very_light_grey}`,
                    margin: "-0.5rem 0 1.15rem",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-0.5rem",
                  }}
                >
                  <div>
                    <img
                      src={IMAGES.alert}
                      alt="alert"
                      style={{ width: "24px" }}
                    />
                  </div>
                  <div
                    style={{
                      color: COLORS.red,
                      fontWeight: "bold",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey1074" />
                  </div>
                </div>

                {hospitals.notMatch.map((item, index) => (
                  <div
                    key={`card-${item}`}
                    className={classes.card}
                    style={{
                      padding: "19px 0px",
                      margin: index === 0 ? "" : "-1.15rem 0 2rem",
                    }}
                  >
                    <div>
                      <div className="center --small">
                        {item.organization.name}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {hospitals.match.length > 3 && !hospitals.notMatch.length && (
              <div
                aria-hidden="true"
                style={{
                  textAlign: "center",
                  color: COLORS.blue,
                  margin: "-0.75rem 0 2rem",
                  fontSize: ".875rem",
                }}
                onClick={handleToggle}
              >
                {isSeeAll ? (
                  <FormattedMessage id="bplusClinicKey1068" />
                ) : (
                  <FormattedMessage id="bplusClinicKey599" />
                )}
              </div>
            )}

            <div className="button-submit-bottom">
              <MuiButton
                variant="contained"
                color="primary"
                disabled={props.loadingStatus?.[`${MOD_GETTING_STARTED}_START`]}
                style={{ width: "87.5%" }}
                onClick={() => handleClickButton(!!hospitals.notMatch.length)}
              >
                {hospitals.notMatch.length ? (
                  <FormattedMessage id="bplusClinicKey1024" />
                ) : (
                  <FormattedMessage id="bplusClinicKey1075" />
                )}
                {props.loadingStatus?.[`${MOD_GETTING_STARTED}_START`] && (
                  <CircularProgress
                    style={{
                      marginLeft: "0.5rem",
                    }}
                    size={25}
                  />
                )}
              </MuiButton>
            </div>

            {!!hospitals.notMatch.length && (
              <div
                aria-hidden="true"
                style={{
                  textAlign: "center",
                  color: COLORS.blue,
                  marginBottom: "1rem",
                }}
                onClick={() => handleClose("K")}
              >
                <FormattedMessage id="bplusClinicKey970" />
                {props.loadingStatus?.[`${MOD_GETTING_STARTED}_CLOSE_K`] && (
                  <CircularProgress
                    size={15}
                    style={{
                      margin: "0 0 -0.1rem 0.5rem",
                    }}
                  />
                )}
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default React.memo(ModGettingStarted);
