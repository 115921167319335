import React from "react";

import { Modal } from "semantic-ui-react";

import { makeStyles, Button as MuiButton } from "@material-ui/core";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

type ModalChooseMakeAppointmentProps = {
  open: boolean;
  onClose?: () => any;
  onSelect?: (select: "self" | "others") => any;
} & WrappedComponentProps;

export const COLOR = {
  blue: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  mod_confirm_content: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  button: {
    width: "75%",
    margin: "15px 0",
    fontSize: "18px",
    fontWeight: "bold",
    color: COLOR.blue,
    border: `2px solid ${COLOR.blue}`,
    "&:hover": {
      border: `2px solid ${COLOR.blue}`,
    },
    borderRadius: "10px",
    padding: "10px 0",
    textTransform: "unset",
    height: "47.2px",
  },
}));

const ModalChooseMakeAppointment: React.FunctionComponent<ModalChooseMakeAppointmentProps> =
  (props) => {
    const classes = useStyles();

    return (
      <div>
        <Modal
          open={props.open}
          closeOnDimmerClick
          onClose={props.onClose}
          style={{ borderRadius: "20px", width: "87.5%" }}
        >
          <div style={{ padding: "30px 25px" }}>
            <div
              className={classes.mod_confirm_content}
              style={{
                color: COLOR.blue,
                paddingBottom: "8px",
              }}
            >
              <FormattedMessage id="e_coupon.would_you_like_to_book_moderna_vaccine" />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MuiButton
                className={classes.button}
                variant="outlined"
                onClick={() => props.onSelect?.("self")}
              >
                <FormattedMessage id="e_coupon.book_for_yourself" />
              </MuiButton>
              <MuiButton
                className={classes.button}
                variant="outlined"
                onClick={() => props.onSelect?.("others")}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <FormattedMessage id="e_coupon.book_for_some_else" />
              </MuiButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

ModalChooseMakeAppointment.defaultProps = {
  open: false,
  onClose: () => {},
} as Omit<ModalChooseMakeAppointmentProps, "intl">;

export default React.memo(injectIntl(ModalChooseMakeAppointment));
