import getPdfMake from "react-lib/appcon/common/pdfMake";
import moment from "moment";

export type DeriveryNewbordRecordForm = {
  hn?: string;
  name?: string;
  sex?: string;
  birthdate?: string;
  age_years?: string;
  age_month?: string;
  age_days?: string;
  nationality?: string;
  bloodType?: string;
  allergies?: string;
  sideEffect?: string;
  lmp?: string;
  edc?: string;
  by_lmp_date?: boolean;
  by_ultrasound?: boolean;
  gestational_age?: string;
  past_delivery_last?: string;
  gravida?: string;
  full_term?: string;
  abortion?: string;
  pdp_yes_specify?: boolean;
  pdp_yes_specify_description?: string;
  mdp_no?: boolean;
  mdp_yes?: boolean;
  mdp_description?: string;
  lab_result_blood_group?: string;
  lab_result_rh?: string;
  lab_result_hct?: string;
  lab_result_mcv?: string;
  lab_result_vdrl_non_reactive?: boolean;
  lab_result_vdrl_reactive?: boolean;
  lab_result_vdrl_other?: boolean;
  lab_result_vdrl_other_description?: string;
  lab_result_hbsag_negative?: boolean;
  lab_result_hbsag_positive?: boolean;
  lab_result_hbsag_other?: boolean;
  lab_result_hbsag_other_description?: string;
  lab_result_hbsab_negative?: boolean;
  lab_result_hbsab_positive?: boolean;
  lab_result_hbsab_other?: boolean;
  lab_result_hbsab_other_description?: string;
  lab_result_rubella_igg_non_reactive?: boolean;
  lab_result_rubella_igg_reactive?: boolean;
  lab_result_rubella_igg_other?: boolean;
  lab_result_rubella_igg_other_description?: string;
  lab_result_group_b_streptococcus_no_growth?: boolean;
  lab_result_group_b_streptococcus_growth?: boolean;
  lab_result_group_b_streptococcus_other?: boolean;
  lab_result_group_b_streptococcus_other_description?: string;
  lab_result_hemoglobin_typing?: string;
  obstetric_history_nurse_name?: string;
  obstetric_history_staff_id?: string;
  obstetric_history_date?: string;
  obstetric_history_time?: string;
  babies?: string;
  image?: string;
};

export const DeriveryNewbordRecordFormInitail = {
  hn: "",
  name: "",
  sex: "",
  birthdate: "",
  age_years: "",
  age_month: "",
  age_days: "",
  nationality: "",
  bloodType: "",
  allergies: "",
  sideEffect: "",
  lmp: "",
  edc: "",
  by_lmp_date: false,
  by_ultrasound: false,
  gestational_age: "",
  past_delivery_last: "",
  gravida: "",
  full_term: "",
  abortion: "",
  pdp_yes_specify: false,
  pdp_yes_specify_description: "",
  mdp_no: false,
  mdp_yes: false,
  mdp_description: "",
  lab_result_blood_group: "",
  lab_result_rh: "",
  lab_result_hct: "",
  lab_result_mcv: "",
  lab_result_vdrl_non_reactive: false,
  lab_result_vdrl_reactive: false,
  lab_result_vdrl_other: false,
  lab_result_vdrl_other_description: "",
  lab_result_hbsag_negative: false,
  lab_result_hbsag_positive: false,
  lab_result_hbsag_other: false,
  lab_result_hbsag_other_description: "",
  lab_result_hbsab_negative: false,
  lab_result_hbsab_positive: false,
  lab_result_hbsab_other: false,
  lab_result_hbsab_other_description: "",
  lab_result_rubella_igg_non_reactive: false,
  lab_result_rubella_igg_reactive: false,
  lab_result_rubella_igg_other: false,
  lab_result_rubella_igg_other_description: "",
  lab_result_group_b_streptococcus_no_growth: false,
  lab_result_group_b_streptococcus_growth: false,
  lab_result_group_b_streptococcus_other: false,
  lab_result_group_b_streptococcus_other_description: "",
  lab_result_hemoglobin_typing: "",
  obstetric_history_nurse_name: "",
  obstetric_history_staff_id: "",
  obstetric_history_date: "",
  obstetric_history_time: "",
  babies: "",
  image: "",
};

export const deriveryNewbordRecordForm = async (props:any) => {
  let pdfMake = await getPdfMake();

  props = { ...DeriveryNewbordRecordFormInitail, ...props };
  // let make = await import("pdfmake/build/pdfmake");
  // let font = await import("../assets/fonts/pdfFonts")
  // let pdfMake = make.default
  // pdfMake.vfs = font.default
  // pdfMake.fonts = {
  //     THSarabunNew: {
  //         normal: 'THSarabunNew.ttf',
  //         bold: 'THSarabunNew-Bold.ttf',
  //         italics: 'THSarabunNew-Italic.ttf',
  //         bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //     },
  //     Roboto: {
  //         normal: 'Roboto-Regular.ttf',
  //         bold: 'Roboto-Medium.ttf',
  //         italics: 'Roboto-Italic.ttf',
  //         bolditalics: 'Roboto-MediumItalic.ttf'
  //     }
  // }
  // pdfMake.vfs = pdfFonts;
  // pdfMake.fonts = {
  //   THSarabunNew: {
  //     normal: "THSarabunNew.ttf",
  //     bold: "THSarabunNew-Bold.ttf",
  //     italics: "THSarabunNew-Italic.ttf",
  //     bolditalics: "THSarabunNew-BoldItalic.ttf",
  //   },
  //   Roboto: {
  //     normal: "Roboto-Regular.ttf",
  //     bold: "Roboto-Medium.ttf",
  //     italics: "Roboto-Italic.ttf",
  //     bolditalics: "Roboto-MediumItalic.ttf",
  //   },
  // };

  var origin = window?.location?.origin;

  // playground requires you to assign document definition to a variable called dd

  var mdpDescription: Record<string,any>[] = [];
  for (var i = 0; i < props.mdp_description?.length; i++) {		
    mdpDescription.push(
      {
        text: (i+1) + ". " + props.mdp_description[i],
        style: "fieldValue",
      },
    );
	}
  console.log(mdpDescription);
  console.log(props.babies);
  console.log(props.babies.length);
  var babiesData: Record<string, any>[] = [];
  for (let i = 0; i < props.babies.length; i++) {

    var medicationDuringLabour: Record<string, any>[] = [];
    for (let j = 0; j < props.babies?.[i]?.data?.vaginal_delivery?.medication_during_labour?.length; j++) {		
      medicationDuringLabour.push(
        {
          text: (j+1) + ". " + props.babies?.[i]?.data?.vaginal_delivery?.medication_during_labour[j],
          style: "fieldValue",
        },
      );
    }
    console.log(medicationDuringLabour);

    var medicationToBaby: Record<string, any>[] = [];
    for (let j = 0; j < props.babies?.[i]?.data?.newborn?.medication_to_baby?.other_description?.length; j++) {		
      medicationToBaby.push(
        {
          text: (j+1) + ". " + props.babies?.[i]?.data?.newborn?.medication_to_baby?.other_description[j],
          style: "fieldValue",
        },
      );
    }
    console.log(medicationToBaby);

    var cordBlood: Record<string, any>[] = [];
    for (let j = 0; j < props.babies?.[i]?.data?.newborn?.cord_blood?.other_description?.length; j++) {		
      cordBlood.push(
        {
          text: (j+1) + ". " + props.babies?.[i]?.data?.newborn?.cord_blood?.other_description[j],
          style: "fieldValue",
        },
      );
    }
    console.log(cordBlood);
    
    babiesData.push(
      {
        //Labor detail
        table: {
          widths: ['*'],
          body: [[
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      text: [
                        { text: "Labor detail: ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.hn, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 160,
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.first_name + " " +
                          props.babies?.[i]?.data?.labour?.last_name, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "DOB: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.dob, [
                          "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: props.babies?.[i]?.data?.labour?.not_in_labour === true ? "Not in labour: " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.spontaneous === true ? "Spontaneous: " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.induced_method === true ? "Induced, method: " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.induced_method_description, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 230,
                      text: [
                        { text: "Membranes ruptured Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.membranes_ruptured?.date, [
                          "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                    {
                      width: 80,
                      text: [
                        { text: "Time: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.membranes_ruptured?.time, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: props.babies?.[i]?.data?.labour?.membranes_ruptured?.spontaneous  === true ? "Spontaneous" : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.membranes_ruptured?.artificial  === true ? "Artificial" : "", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Amniotic fluid appearance: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.amniotic_fluid_appearance?.clear  === true ? "Clear" : "", style: "fieldValue" },
                        { text: props.babies?.[i]?.data?.labour?.amniotic_fluid_appearance?.thin_meconium  === true ? "Thin meconium" : "", style: "fieldValue" },
                        { text: props.babies?.[i]?.data?.labour?.amniotic_fluid_appearance?.think_meconium  === true ? "Think meconium" : "", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Onset of labour: ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.labour_onset?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                    {
                      width: 80,
                      text: [
                        { text: "Time: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.labour_onset?.time, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Duration of first stage: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.labour_onset?.duration_hour + " hr. " + 
                        props.babies?.[i]?.data?.labour?.labour_onset?.duration_min + " min. ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Full dilatation: ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.full_dilatation?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                    {
                      width: 80,
                      text: [
                        { text: "Time: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.full_dilatation?.time, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Duration of second stage: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.full_dilatation?.duration_hour + " hr. " + 
                        props.babies?.[i]?.data?.labour?.full_dilatation?.duration_min + " min. ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Birth of child: ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.child_birth?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                    {
                      width: 80,
                      text: [
                        { text: "Time: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.child_birth?.time, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Duration of third stage: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.child_birth?.duration_hour + " hr. " + 
                        props.babies?.[i]?.data?.labour?.child_birth?.duration_min + " min. ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Birth of plancenta: ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.placenta_birth?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                    {
                      width: 80,
                      text: [
                        { text: "Time: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.placenta_birth?.time, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Duration of labour: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.placenta_birth?.duration_hour + " hr. " + 
                        props.babies?.[i]?.data?.labour?.placenta_birth?.duration_min + " min. ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Method of delivery: ", style: "fieldKey" },
                        { 
                          text: 
                          props.babies?.[i]?.data?.labour?.delivery_method?.normal_labour === true ? "Nornal Labour " :
                          props.babies?.[i]?.data?.labour?.delivery_method?.forcep_extraction === true ? 
                          "Forcep Extraction, indication: " + props.babies?.[i]?.data?.labour?.delivery_method?.forcep_extraction_description + " "
                          : "", style: "fieldValue" 
                        },
                        { 
                          text: 
                          props.babies?.[i]?.data?.labour?.delivery_method?.vacuum_extraction === true ? 
                          "Vacuum Extraction, indication: " + props.babies?.[i]?.data?.labour?.delivery_method?.vacuum_extraction_description + " " 
                          : "", style: "fieldValue" 
                        },
                        { 
                          text: 
                          props.babies?.[i]?.data?.labour?.delivery_method?.cesarean_section_due_to === true ? 
                          "Cesarean section due to: " + props.babies?.[i]?.data?.labour?.delivery_method?.cesarean_section_due_to_description + " " 
                          : "", style: "fieldValue" 
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
          [
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      width: 240,
                      text: [
                        { text: "Nurse’s name: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.nurse_name, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Staff ID: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.staff_id, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                        { text: " [" + props.babies?.[i]?.data?.labour?.time + "]", style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
        ]
        },
        layout: {
          hLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
          vLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
        },
        marginTop: 2,
        pageBreak: 'before'
      },
      {
        //Vaginal Delivery Only
        table: {
          widths: ['*'],
          body: [[
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      text: [
                        { text: "Vaginal Delivery Only: ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Presentation: ", style: "fieldKey" },
                        { text: 
                          props.babies?.[i]?.data?.vaginal_delivery?.presentation?.cephalic === true ? "Cephalic " :
                          props.babies?.[i]?.data?.vaginal_delivery?.presentation?.breech === true ? "Breech " :
                          props.babies?.[i]?.data?.vaginal_delivery?.presentation?.other === true ? "Other " : "", style: "fieldKey" 
                        },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.presentation?.other_description, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Episiotomy: ", style: "fieldKey" },
                        { text: 
                          props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.no === true ? "No " :
                          props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.yes && props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.right_mediolateral
                          === true ? "Yes, Right mediolateral" : 
                          props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.yes && props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.left_mediolateral
                          === true ? "Yes, Left mediolateral" :
                          props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.yes && props.babies?.[i]?.data?.vaginal_delivery?.episiotomy?.median
                          === true ? "Yes, Median" : "", style: "fieldKey" 
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Laceration: ", style: "fieldKey" },
                        { text: 
                          props.babies?.[i]?.data?.vaginal_delivery?.laceration?.no === true ? "No " :
                          props.babies?.[i]?.data?.vaginal_delivery?.laceration?.yes && props.babies?.[i]?.data?.vaginal_delivery?.laceration?.first
                          === true ? "Yes, First" : 
                          props.babies?.[i]?.data?.vaginal_delivery?.laceration?.yes && props.babies?.[i]?.data?.vaginal_delivery?.laceration?.second
                          === true ? "Yes, Second" :
                          props.babies?.[i]?.data?.vaginal_delivery?.laceration?.yes && props.babies?.[i]?.data?.vaginal_delivery?.laceration?.third
                          === true ? "Yes, Third" :
                          props.babies?.[i]?.data?.vaginal_delivery?.laceration?.yes && props.babies?.[i]?.data?.vaginal_delivery?.laceration?.fourth_degree_tear
                          === true ? "Yes, Fourth degree tear" : "", style: "fieldKey" 
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Problem during labour: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.problem_during_labour, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 150,
                      text: [
                        { text: "Medication during labour: ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[
                          medicationDuringLabour,
                        ]],
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Placenta and membranes    ", style: "fieldKey" },
                        { text: "Weight: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.placenta_membranes?.weight, style: "fieldValue" },
                        { text: " gm.   ", style: "fieldKey" },
                        { text: 
                          props.babies?.[i]?.data?.vaginal_delivery?.placenta_membranes?.spontaneous_delivery === true ? "Spontaneous delivery " :
                          props.babies?.[i]?.data?.vaginal_delivery?.placenta_membranes?.manual_removal === true ? "Manual removal " : "", style: "fieldKey" 
                        },                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Gross appearance:  ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.gross_appearance?.normal === true ?  "Normal " :
                          props.babies?.[i]?.data?.vaginal_delivery?.gross_appearance?.abnormal === true ? "Abnormal: " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.gross_appearance?.abnormal_description + "   ", style: "fieldValue" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.gross_appearance?.complete === true ?  "Complete " :
                          props.babies?.[i]?.data?.vaginal_delivery?.gross_appearance?.incomplete === true ? "Incomplete: " : "" , style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Cord length: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.cord_length, style: "fieldValue" },
                        { text: " cm.  ", style: "fieldKey" },
                        { text: "Cord Insertion: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.cord_insertion?.normal === true ?  "Normal " :
                          props.babies?.[i]?.data?.vaginal_delivery?.cord_insertion?.abnormal === true ? "Abnormal, " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.cord_insertion?.abnormal_description + "   ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Cord Vessels: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.cord_vessels?.normal === true ?  "Normal " :
                          props.babies?.[i]?.data?.vaginal_delivery?.cord_vessels?.abnormal === true ? "Abnormal, " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.cord_vessels?.abnormal_description + "   ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Oxytocin drug: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.normal === true ?  "Normal " :
                          props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.abnormal === true ? "Abnormal, " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.oxytocin === true ?  "Oxytocin " :
                        props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.methergin === true ?  "Methergin " :
                        props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.duratocin === true ?  "Duratocin " :
                          props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.other === true ? "Other, " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.oxytocin_drug?.other_description + "   ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Post-partum condition: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.post_partum_condition?.normal === true ?  "Normal " :
                          props.babies?.[i]?.data.vaginal_delivery?.post_partum_condition?.abnormal === true ? "Abnormal, " : "" , style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.post_partum_condition?.abnormal_description + "   ", style: "fieldValue" },
                        { text: "Estimated blood loss: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.post_partum_condition?.estimated_blood_loss, style: "fieldValue" },
                        { text: " ml. ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
          [
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      width: 240,
                      text: [
                        { text: "Obstertric’s name: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.obstertric_name, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "License: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.vaginal_delivery?.license, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.vaginal_delivery?.date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                        { text: " [" + props.babies?.[i]?.data?.vaginal_delivery?.time + "]", style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
        ]
        },
        layout: {
          hLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
          vLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
        },
        marginTop: 2,
      },
      {
        //Newborn detail
        table: {
          widths: ['*'],
          heights: [640],
          body: [[
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      text: [
                        { text: "Newborn detail: ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.hn, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 160,
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.labour?.first_name + " " +
                          props.babies?.[i]?.data?.labour?.last_name, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "DOB: ", style: "fieldKey" },
                        { text: moment(props.babies?.[i]?.data?.labour?.dob, [
                          "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 80,
                      text: [
                        { text: "Sex: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.sex === "F" ? "Female" :
                          props.babies?.[i]?.data?.newborn?.sex === "M" ? "Male" : "", style: "fieldValue" },
                      ],
                    },
                    {
                      width: 100,
                      text: [
                        { text: "Weight: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.weight, style: "fieldValue" },
                        { text: " gm. ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 90,
                      text: [
                        { text: "Length: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.length, style: "fieldValue" },
                        { text: " cm. ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Body temperature: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.body_temperature, style: "fieldValue" },
                        { text: " C. ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Status: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.status?.alive === true ? "Alive" :
                          props.babies?.[i]?.data?.newborn?.status?.stillbirth === true ? "Stillbirth" : 
                          props.babies?.[i]?.data?.newborn?.status?.fresh === true ? "Fresh" : 
                          props.babies?.[i]?.data?.newborn?.status?.marcerated === true ? "Marcerated" : "", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Medication to baby: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.medication_to_baby?.terramycin_eye_ointment === true ? 
                          "Terramycin eye ointment, " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.medication_to_baby?.vit_k1 === true ? 
                          "Vit K1, " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.medication_to_baby?.other === true ? 
                          "Other, " : "", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                medicationToBaby,
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Cord blood for: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.cord_blood?.blood_group === true ? 
                          "Blood group, " : "", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.cord_blood?.other === true ? 
                          "Other, " : "", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                cordBlood,
                {
                  margin: [0,20,0,15],
                  table: {
                    widths: [180,'*','*','*'],
                    body: [
                      [
                        {
                          fillColor: '#0A2C74',
                          columns: [
                            { text: "Apgar Score", style: "fontHeaderTable" },
                          ]
                        },
                        {
                          fillColor: '#0A2C74',
                          columns: [
                            { text: "1 minute", style: "fontHeaderTable" },
                          ]
                        },
                        {
                          fillColor: '#0A2C74',
                          columns: [
                            { text: "5 minute", style: "fontHeaderTable" },
                          ]
                        },
                        {
                          fillColor: '#0A2C74',
                          columns: [
                            { text: "10 minute", style: "fontHeaderTable" },
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Heart rate", style: "fieldTable" }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.heart_rate?.["1_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.heart_rate?.["5_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.heart_rate?.["10_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Respiration", style: "fieldTable" }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.respiration?.["1_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.respiration?.["5_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.respiration?.["10_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Muscle tone", style: "fieldTable" }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.muscle_tone?.["1_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.muscle_tone?.["5_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.muscle_tone?.["10_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Color", style: "fieldTable" }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.color?.["1_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.color?.["5_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.color?.["10_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Reflex", style: "fieldTable" }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.reflex?.["1_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.reflex?.["5_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.reflex?.["10_min"] ,style: "fieldTableValue"  }
                          ]
                        },
                      ],
                      [
                        {
                          columns: [
                            { text: "Total", style: "fieldTable", bold: true, }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.total?.["1_min"] ,style: "fieldTableValue", bold: true, }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.total?.["5_min"] ,style: "fieldTableValue", bold: true, }
                          ]
                        },
                        {
                          columns: [
                            { text: props.babies?.[i]?.data?.newborn?.apgar_score?.total?.["10_min"] ,style: "fieldTableValue", bold: true, }
                          ]
                        },
                      ],
                    ]
                  },
                  layout: {
                    hLineColor: function (i:any, node:any) {
                      return "#000000";
                    },
                    vLineColor: function (i:any, node:any) {
                      return "#000000";
                    },
                  },
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Remark: ", style: "fieldKey" },
                        { text: props.babies?.[i]?.data?.newborn?.remark, style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
        ]
        },
        layout: {
          hLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
          vLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
        },
        marginTop: 2,
        pageBreak: 'before'
      },
    )
  }

  var docDefinition = {

    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 14,
    },

    pageMargins: [8, 180, 8, 10],
    //A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      fontHeader: {
        fontSize: 22,
        bold: true,
      },
      fontHeaderTable: {
        alignment: "center",
        fontSize: 15,
        bold: true,
        color: '#FFFFFF',
        marginTop: 5,
        marginBottom: 5,
      },
      fieldTable: {
        fontSize: 15,
      },
      fieldTableValue: {
        fontSize: 15,
        alignment: "center",
      },
      fieldKey: {
        bold: true,
        fontSize: 17,
      },
      fieldValue: {
        fontSize: 17,
      },
    },

    header: {
      margin: [15, 10, 15, 0],
      table: {
        widths: ['*',83],
        body: [
          [
            {
              stack: [
                {
                  table: {
                    widths: [155,280,'*'],
                    body: [[
                      {
                        stack: [
                          {
                            image: "logobkhpt",
                            width: 155,
                            marginRight: 10,
                          },
                        ],
                        
                      },
                      {
                        table: {
                          widths: ['*'],
                          body: [[{
                            fillColor: '#0A2C74',
                            color: '#FFFFFF',
                            alignment: "left",
                            columns: [
                              {
                                text: "Delivery Newborn Record",
                                style: "fontHeader",
                                alignment: "center",
                                margin: 1,
                              },
                            ]
                        }]]
                        },
                        layout: 'noBorders',
                        width: 270,
                        height: 35,
                        marginTop: 2,
                      },
                      { text: ""},
                    ]]
                  },
                  layout: 'noBorders',
                },
                {
                  marginTop: 5,
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.hn, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        { text: props.name, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Sex: ", style: "fieldKey" },
                        { text: props.sex, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 140,
                      text: [
                        { text: "Birthdate: ", style: "fieldKey" },
                        { text: props.birthdate, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Age: ", style: "fieldKey" },
                        { text: props.age_years + "years ", style: "fieldValue" },
                        { text: props.age_month + "month ", style: "fieldValue" },
                        { text: props.age_days + "days ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 255,
                      text: [
                        { text: "Nationality: ", style: "fieldKey" },
                        { text: props.nationality , style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Blood type: ", style: "fieldKey" },
                        { text: props.bloodType, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Allergies: ", style: "fieldKey" },
                        { text: props.allergies, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Side Effect: ", style: "fieldKey" },
                        { text: props.sideEffect, style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  image: props.image === "" ? "unprofile" : "profile",
                  width: 83,
                  height: 83,
                  alignment: "right",
                },
              ],
            },
          ],
        ]
      },
      layout: 'noBorders',
    },
    
    content: [
      {
        //Obstetric History
        table: {
          widths: ['*'],
          body: [[
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      text: [
                        { text: "Obstetric History: ", style: "fieldKey" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 125,
                      text: [
                        { text: "LMP: ", style: "fieldKey" },
                        { text: props.lmp, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 200,
                      text: [
                        { text: "EDC: ", style: "fieldKey" },
                        { text: props.edc, style: "fieldValue" },
                        { text: props.by_lmp_date === true ? " by lmp date " : "", style: "fieldValue" },
                        { text: props.by_ultrasound === true ? " by ultrasound "  : "", style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Gestational Age at birth: ", style: "fieldKey" },
                        { text: props.gestational_age + " weeks", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 175,
                      text: [
                        { text: "Past delivery last: ", style: "fieldKey" },
                        { text: props.past_delivery_last + " years", style: "fieldValue" },
                      ],
                    },
                    {
                      width: 100,
                      text: [
                        { text: "Gravida: ", style: "fieldKey" },
                        { text: props.gravida, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 100,
                      text: [
                        { text: "Full term: ", style: "fieldKey" },
                        { text: props.full_term, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Abortion: ", style: "fieldKey" },
                        { text: props.abortion, style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 180,
                      text: [
                        { text: "Problems during pregnancy: ", style: "fieldKey" },
                        { text: props.pdp_yes_specify === true ? "Yes "  : "", style: "fieldValue" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            {
                              text: props.pdp_yes_specify_description,
                              style: "fieldValue",
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 190,
                      text: [
                        { text: "Medication during pregnancy: ", style: "fieldKey" },
                        { text: props.mdp_no === true ? "No "  : "", style: "fieldValue" },
                        { text: props.mdp_yes === true ? "Yes "  : "", style: "fieldValue" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[
                          mdpDescription,
                        ]],
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "Lab result: ", style: "fieldKey" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Blood group ", style: "fieldKey" },
                        { text: props.lab_result_blood_group === "a" ? "A" :
                          props.lab_result_blood_group === "b" ? "B" :
                          props.lab_result_blood_group === "o" ? "O" : 
                          props.lab_result_blood_group === "ab" ? "AB" : "" , style: "fieldValue" },
                        { text: " Rh ", style: "fieldValue" },
                        { text: props.lab_result_rh, style: "fieldValue" },
                      ],
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Hematocrit (Hct) ", style: "fieldKey" },
                        { text: props.lab_result_hct + " %    ", style: "fieldValue" },
                        { text: "Mean Corpuscular Volume (MCV) ", style: "fieldKey" },
                        { text: props.lab_result_mcv + " fL ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 250,
                      text: [
                        { text: "Venereal Disease Research Laboratory (VDRL) ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            { 
                              text: 
                                props.lab_result_vdrl_non_reactive === true ? "Non - reactive" :
                                props.lab_result_vdrl_reactive === true ? "Rreactive" :
                                props.lab_result_vdrl_other === true ? "Other " + 
                                props.lab_result_vdrl_other_description : "", style: "fieldValue" 
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Anti-HIV ", style: "fieldKey" },
                        { text: "Confidential (Please view in HIS system)", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 200,
                      text: [
                        { text: "Hepatitis B surface antigen (HBsAg) ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            { 
                              text: 
                                props.lab_result_hbsag_negative === true ? "Negative" :
                                props.lab_result_hbsag_positive === true ? "Positive" :
                                props.lab_result_hbsag_other === true ? "Other " + 
                                props.lab_result_hbsag_other_description : "", style: "fieldValue" 
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 210,
                      text: [
                        { text: "Hepatitis B surface Antibody (HBsAb) ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            { 
                              text: 
                                props.lab_result_hbsab_negative === true ? "Negative" :
                                props.lab_result_hbsab_positive === true ? "Positive" :
                                props.lab_result_hbsab_other === true ? "Other " + 
                                props.lab_result_hbsab_other_description : "", style: "fieldValue" 
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 70,
                      text: [
                        { text: "Rubella IgG ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            { 
                              text: 
                                props.lab_result_rubella_igg_non_reactive === true ? "Non - reactive" :
                                props.lab_result_rubella_igg_reactive === true ? "Reactive" :
                                props.lab_result_rubella_igg_other === true ? "Other " + 
                                props.lab_result_rubella_igg_other_description : "", style: "fieldValue" 
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: 130,
                      text: [
                        { text: "Group B Streptococcus ", style: "fieldKey" },
                      ],
                    },
                    {
                      table: {
                        widths: ['*'],
                        body: [[{
                          columns: [
                            { 
                              text: 
                                props.lab_result_group_b_streptococcus_no_growth === true ? "No growth" :
                                props.lab_result_group_b_streptococcus_growth === true ? "Growth" :
                                props.lab_result_group_b_streptococcus_other === true ? "Other " + 
                                props.lab_result_group_b_streptococcus_other_description : "", style: "fieldValue" 
                            },
                          ]
                        }]]
                      },
                      layout: 'noBorders',
                    }
                  ],
                },
                {
                  marginBottom: 5,
                  columns: [
                    {
                      width: 70,
                      text: [
                        { text: "", style: "fieldKey" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Hemoglobin typing ", style: "fieldKey" },
                        { text: props.lab_result_hemoglobin_typing, style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
          [
            {
              margin: 2,
              stack: [
                {
                  columns: [
                    {
                      width: 250,
                      text: [
                        { text: "Nurse’s name: ", style: "fieldKey" },
                        { text: props.obstetric_history_nurse_name, style: "fieldValue" },
                      ],
                    },
                    {
                      width: 110,
                      text: [
                        { text: "Staff ID: ", style: "fieldKey" },
                        { text: props.obstetric_history_staff_id, style: "fieldValue" },
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Date: ", style: "fieldKey" },
                        { text: moment(props.obstetric_history_date, [
                            "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" },
                        { text: " [" + props.obstetric_history_time + "]", style: "fieldValue" },
                      ],
                    },
                  ],
                },
              ],
            }
          ],
        ]
        },
        layout: {
          hLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
          vLineColor: function (i:any, node:any) {
            return "#0A2C74";
          },
        },
        marginTop: 2,
      },
        //Labor detail
        babiesData,
    ],

    

    images: {
      logobkhpt: origin + "/images/pdfForm/logobkhpt.png",
      profile: props.image,
      unprofile: origin + "/images/Appointment/user-icon.png",
      // profile: origin + "/images/Appointment/user-icon.png"
    },
  };

  var win = window.open("", "_blank");
  pdfMake.createPdf(docDefinition).open({}, win);

};