import React, { Component, useRef } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
// CSS

// Library
import Cookies from "js-cookie";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import Fingerprint2 from "fingerprintjs2";
import {
  IntlProvider,
  // createIntlCache,
  // createIntl,
} from "react-intl";

// Frameworks
import SetProp from "../react-lib/frameworks/SetProp";

// IsHealth
import { createNotificationSubscription } from "../react-lib/apps/IsHealth/Common/push-notifications";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "../react-lib/localization/bplusClinic/th.json";
import enMessage from "../react-lib/localization/bplusClinic/en.json";
import { ChatController } from "react-lib/apps/IsHealth/Chat";

// Manager
import ChatManager from "../apis/ChatManager";
import MSGManager from "react-lib/apis/manager/MSGManager";
import PRXManager from "react-lib/apis/manager/PRXManager";
import REGManager from "react-lib/apis/manager/REGManager";
import DiagManager from "../apis/DiagManager";
import CoreManager from "react-lib/apis/manager/CoreManager";
import ChatListController from "patient-lib/ChatController";
import BILManager from "react-lib/apis/manager/BILManager";
import DiagFormController from "patient-lib/DiagFormController";

// Controller
import TeleDesktopController from "./TeleDesktopController";
import * as MainVideoCall from "./MainVideoCall";
import { deviceType } from "react-device-detect";

// Interface
import * as TeleDesktopI from "./TeleDesktopInterface";

// UI
import CONFIG from "../config/config";
import { isMobile, browserName } from "react-device-detect";

// Style
import "../css/MobAppointment.scss";
import MainTeleDesktop from "./TeleDesktop/MainTeleDesktop";
import TeleLogin from "./TeleDesktop/TeleLogin";
import PaymentController from "patient-lib/PaymentController";
import config from "../config/config";

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

class TeleDesktop extends Component<any, TeleDesktopI.State> {
  controller: TeleDesktopController;

  ChatController: any;
  chatManager: any;
  msgManager: any;
  regManager: any;
  prxManager: any;
  coreManager: any;
  diagManager: DiagManager | undefined;
  chatListController: any;
  chatListRef: any;
  chatBoxRef: any;
  lastReadMessage: null | undefined;
  paymentController: any;
  bilManager: any;
  DiagFormController: DiagFormController | undefined;


  notificationChat: any;
  notificationVDO: any;
  constructor(props: any) {
    super(props);
    this.state = { ...TeleDesktopI.StateInitial };

    this.showNotification = this.showNotification.bind(this);

    this.controller = new TeleDesktopController(
      () => {
        return this.state;
      },
      (state: TeleDesktopI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    this.chatBoxRef = React.createRef();
    this.chatListRef = React.createRef();

    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");

    // Manager legacy
    this.chatManager = new ChatManager();
    this.msgManager = new MSGManager();
    this.regManager = new REGManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.bilManager = new BILManager();
    this.diagManager = new DiagManager();

    this.chatListController = new ChatListController({
      chatManager: this.chatManager,
    });

    this.paymentController = new PaymentController({
      bilManager: this.bilManager,
    });

    this.DiagFormController = new DiagFormController({
      diagManager: this.diagManager,
    });

    // Controller legacy
    this.ChatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
      chatManager: this.chatManager,
    });
    // url = `${config.WEB_RTC}/${props.match?.params.chatChannelId}/?name=${name}`;

    vcMessenger.onMessage((message: any) => {
      // console.log("saika ;) message ----> ", message);
      if (message["data_message_type"] === "MESSAGE") {
        if (!isMobile) {
          this.showNotification(message);
        }
        if (message["content_type"] === "vid_call") {
          this.setState({
            incomingCalling: true,
            incomingMessage: message,
            incomingDetail: {
              channelName: message["channel_name"],
              channelId: message["channel_id"],
              divisionId: message["division_id"],
            },
          });
        } else if (message["content_type"] === "end_call") {
          this.notificationVDO.close();
          this.setState({
            incomingCalling: false,
            incomingMessage: "",
            openVideoCallModal: false,
            videoCallRoom: "",
          });
        }
        if (this.chatBoxRef && this.chatBoxRef.receivedMessage) {
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }

        if (this.chatListRef) {
          this.updateUnreadMessageCount(message);
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.chatBoxRef && this.chatBoxRef.receivedMessageRead) {
          this.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"]),
          });
        }

        if (this.chatBoxRef && this.chatBoxRef.receivedMessage) {
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }

        // if (this.chatListRef) {
        //   this.updateUnreadMessageCount(message);
        // }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.chatBoxRef && this.chatBoxRef.loadPatientEncounter) {
          this.chatBoxRef.loadPatientEncounter();
        }
      }
    });
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });
    // Get login info from Mobile App
    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({
        message: "GetLoginInfo",
        params: {},
      });
    }

    if (!("Notification" in window)) {
      console.log(
        "saika :) This browser does not support desktop notification"
      );
    } else {
      if (
        ["Safari", "Firefox", "Edge", "Chrome", "Opera"].includes(browserName)
      ) {
        if (Notification.permission === "default") {
          this.setState({ askPermission: true });
        }
      } else {
        Notification.requestPermission();
      }
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    document.querySelector("body")?.classList.add("body-mob-appointment");

    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");
    this.loadChannelList();

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }

    this.controller.handleEvent({ message: "HandleGetPatient", params: {} });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
      this.controller.handleEvent({
        message: "HandleGetUserWithPatientDetail",
        params: {},
      });
    }

    if (prevState.apiToken !== this.state.apiToken && this.state.apiToken) {
      this.setWebsocket({ apiToken: this.state.apiToken });
    }
  }

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    var apiToken;
    var userId;
    const search = new URLSearchParams(window.location.search);
    const code = search.get("code") || "";

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      apiToken = Cookies.get("apiToken");
      userId = Cookies.get("userId");
      if (apiToken) {
        this.setWebsocket({ apiToken: apiToken });
      }
      if (apiToken && userId) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");
        // console.log("saika ;) apiToken: ", apiToken, " userId:", userId);
        this.setState({ apiToken: apiToken, userId: userId });
      } else if (code && !Cookies.get("apiToken")) {
        this.controller.handleEvent({
          message: "HandleGetOAuthToken",
          params: { code: code },
        });
      } else if (!apiToken && !Cookies.get("apiToken")) {
        // *******************  path link login  *************************
        const clientId = CONFIG.OAUTH_CLIENT_ID; //staging
        // const Host = CONFIG.API_HOST;
        const Host = CONFIG.SSO_HOST;
        const HostURL = CONFIG.OAUTH_REDIRECT_URL; //ต้องตรงกับ Redirect url ใน staging
        const HostURLtest = `https://localhost:3000/?app=TeleDesktop`;
        console.log("saika ;) HostURLFull ---> ", HostURL);
        const urlRedirect = encodeURIComponent(HostURL);
        const url = `${Host}/users/oauth/bdms/login/?next=%2Fusers%2Foauth%2Fbdms%2Fauthorize%2F%3Fclient_id%3D${clientId}%26response_type%3Dcode%26scope%3Dread%26lang%3Dth%26redirect_uri%3D${urlRedirect}`;
        globalThis.location.href = url;
      }
    }
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  showBadge = (sum_unread: any) => {
    try {
      console.log("sum_unread = " + sum_unread);
      if (typeof window.iosNative !== "undefined") {
        window.iosNative.showBadge(sum_unread.toString());
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative.showBadge(sum_unread.toString());
      }
    } catch (e) {
      console.error("showBadge is not function...");
    }
  };

  setMenuChat = (chat_id: any) => {
    this.setState({ focusSelectMenu: chat_id });
    this.notificationChat.close();
  };

  setOpenCalling = (chat_id: any) => {
    this.setState({ focusSelectMenu: chat_id });
    this.handleOpenVideoCall();
    this.notificationVDO.close();
  };

  showNotification(message: any) {
    const title = "My B+";
    var options = {
      body: `You have a new message from ${message.channel_name}`,
      icon: "/images/expansion/logo_192.png",
      badge: "/images/expansion/logo_192.png",
      tag: "renotify",
      renotify: true,
    };
    if (message["data_message_type"] === "MESSAGE") {
      if (message["content_type"] === "vid_call") {
        this.notificationVDO = new Notification(title, {
          ...options,
          body: `Incominng call from ${message.channel_name}`,
        });
        this.notificationVDO.onclick = () => {
          window.focus();
          this.setOpenCalling(message.channel_id);
        };
      } else if (message["content_type"] === "end_call") {
        console.log("saika ;) not show noti");
      } else {
        this.notificationChat = new Notification(title, options);
        this.notificationChat.onclick = () => {
          window.focus();
          this.setMenuChat(message.channel_id);
        };
      }
    }
  }

  loadChannelList = async () => {
    this.controller.setState({ loadingStatus: true });
    let apiToken = Cookies.get("apiToken");
    let userId = Cookies.get("userId");
    if ((this.state.channelList?.length || 0) === 0) {
      this.setState({ chatLoading: true });
    }
    const [data, error] = await this.chatListController.getChatChanel(
      apiToken,
      userId
    );
    let sum_unread = 0;
    if (data) {
      if (
        data &&
        data.items &&
        Array.isArray(data.items) &&
        data.items.length > 0
      ) {
        console.log("data ", data);
        data.items.forEach((item: any) => {
          sum_unread += item.unread_message_count;
        });
      }
      this.showBadge(sum_unread);
      // console.log(" getIcon data.items", data.items);
      let listCodeClinic = [
        CONFIG.DM_FEATURE,
        CONFIG.MOM_FEATURE,
        CONFIG.CHILD_FEATURE,
      ];
      let nofeature = data.items.filter(
        (item: any) => !listCodeClinic.includes(item.division_feature)
      );
      let feature = data.items.filter((item: any) =>
        listCodeClinic.includes(item.division_feature)
      );
      // if (feature?.length > 0 ) {
      //   const [data3, error3] = await this.chatListController.getChatChannelFromPatientHasDivision(apiToken, true);
      //   if (data3) {
      //     console.log(" getchatChannelFromPatientHasDivision data", data3)
      //   }
      // }
      this.setState({ channelList: [...feature, ...nofeature] });
    } else {
      this.showBadge(sum_unread);
      this.setState({ channelList: [] });
    }

    const [data2, error2] = await this.chatListController.getOfficialChannel(
      apiToken,
      userId
    );
    // console.log("saika ;) data2 ->", data2);
    if (data2) {
      let items = [];
      if (
        data2 &&
        data2.items &&
        Array.isArray(data2.items) &&
        data2.items.length > 0
      ) {
        items = data2.items.filter((item: any) => item.feature);
        items.forEach((item: any) => {
          sum_unread += item.unread_message_count;
        });
      }
      this.showBadge(sum_unread);
      this.setState({ unfollowChannelList: items, chatLoading: false });
    } else {
      this.showBadge(sum_unread);
      this.setState({ unfollowChannelList: [], chatLoading: false });
    }
    this.controller.setState({ loadingStatus: false });
  };

  updateUnreadMessageCount = (message: any) => {
    const update = (this.state.channelList || []).map((item: any) => ({
      ...item,
      unread_message_count:
        item.id === message.channel_id
          ? (item.unread_message_count || 0) + 1
          : item.unread_message_count,
    }));

    this.setState({ channelList: [...update] });
  };

  handleSetFocusSelect = () => {
    this.setState({ focusSelectMenu: "" });
  };

  handleOnFeatureChannelSelected = (item: any) => {
    let division_code = item.division_code;
    let division_feature = item.division_feature;

    // console.log(" handleOnFeatureChannelSelected", item)

    switch (division_feature) {
      case CONFIG.DM_FEATURE:
        // console.log(" my ChatChannel from DMMainPatient to DM", item.id)
        // // let pathDM = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.DM_BPLUS_URL + pathDM)
        // window.location.assign(CONFIG.DM_BPLUS_URL)
        break;
      case CONFIG.MOM_FEATURE:
        // console.log(" my ChatChannel from MainPatient to Mom", item.id)
        // // let pathMom = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.MOM_BPLUS_URL + pathMom)
        // window.location.assign(CONFIG.MOM_BPLUS_URL)
        break;
      case CONFIG.CHILD_FEATURE:
        // console.log(" my ChatChannel from MainPatient to child", item.id)
        // // let pathChild = item.id ? `&chatChannel=${item.id}` : ""
        // // window.location.assign(CONFIG.CHILD_BPLUS_URL + pathChild)
        // window.location.assign(CONFIG.CHILD_BPLUS_URL)

        break;
      default:
        break;
    }
  };

  followDivisionProfile = async (divisionProfile: any) => {
    console.log("followDivisionProfile  divisionProfile", divisionProfile);
    let apiToken = this.state.apiToken
      ? this.state.apiToken
      : this.props.cookies.get("apiToken");
    this.setState({ chatLoading: true });
    const [data, error] =
      await this.chatListController.postFollowDivisionProfile(
        apiToken,
        divisionProfile
      );
    if (data) {
      // แทน ที่จะ reload เปิดไปเลย
      await this.loadChannelList();
      let channel = this.state.channelList?.find(
        (item) => item.division_profile_id === divisionProfile
      );
      console.log("followDivisionProfiledivisionProfile: ", divisionProfile);
      console.log("followDivisionProfile channel?.id: ", channel?.id);

      if (channel?.id) {
        let gotoItem = {
          id: channel?.id,
          division_code: channel?.division_code,
        };
        this.handleOnFeatureChannelSelected(gotoItem);
      }

      // this.handleOnFeatureChannelSelected(item)

      // let items = []
      // if ( data && data.items && Array.isArray(data.items) && data.items.length > 0) {
      //   console.log("data ", data)
      //   items = data.items.filter(item => item.feature)
      //   items.forEach((item) => {
      //     sum_unread += item.unread_message_count
      //   })
      // }
      // this.setState({ unfollowChannelList: items, chatLoading: false })
    } else {
      console.log("test2 ");

      // this.setState({ unfollowChannelList: [],chatLoading: false })
    }
    // this.setState({chatLoading: false})
  };

  handleOpenVideoCall = () => {
    const message = this.state.incomingMessage;
    // console.log("saika ;)  WEB_RTCmessage ", message);
    const patient = this.state.patientDetail;
    const url = `${config.WEB_RTC}/${message["content"]}/?name=${
      patient.first_name || "patient"
    }`;
    // console.log("saika ;)  WEB_RTC url ----> ", url);
    this.notificationVDO.close();
    this.setState({
      openVideoCallModal: true,
      videoCallRoom: url,
      incomingCalling: false,
    });
  };

  handleCloseVideoCall = () => {
    this.notificationVDO.close();
    this.setState({
      incomingCalling: false,
      incomingMessage: "",
    });
  };

  handlePermission = () => {
    this.setState({ askPermission: false });
    Notification.requestPermission((status) => {
      console.log("saika ;) ", status);
    });
  };

  render = () => {
    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        {!this.state.loggedin || !Cookies.get("apiToken") ? (
          <>
            <TeleLogin />
          </>
        ) : (
          <div style={{ height: "100vh" }}>
            <Switch>
              <Route
                path="/"
                render={(props) => {
                  return (
                    <div>
                      <MainTeleDesktop
                        chatBoxRef={(ref) => (this.chatBoxRef = ref)}
                        chatListRef={(ref) => (this.chatListRef = ref)}
                        onEvent={this.controller.handleEvent}
                        askPermission={this.state.askPermission}
                        handlePermission={this.handlePermission}
                        // controller
                        setProp={this.controller.setProp}
                        chatController={this.ChatController}
                        diagFormController={this.DiagFormController}
                        paymentController={this.paymentController}
                        apiToken={this.state.apiToken}
                        userId={this.state.userId}
                        channelList={this.state.channelList}
                        loadChannelList={this.loadChannelList}
                        followDivisionProfile={this.followDivisionProfile}
                        unfollowChannelList={this.state.unfollowChannelList}
                        isLoading={this.state.loadingStatus}
                        patientInfo={this.state.patientInfo}
                        openVideoCallModal={this.state.openVideoCallModal}
                        videoCallRoom={this.state.videoCallRoom}
                        focusSelectMenu={this.state.focusSelectMenu}
                        handleSetFocusSelect={this.handleSetFocusSelect}
                        // incoming
                        incomingDetail={this.state.incomingDetail}
                        incomingCalling={this.state.incomingCalling}
                        handleOpenVideoCall={this.handleOpenVideoCall}
                        handleCloseVideoCall={this.handleCloseVideoCall}
                        loadingStatusPayment={this.state.loadingStatusPayment}
                      />
                    </div>
                  );
                }}
              />
            </Switch>
          </div>
        )}
      </IntlProvider>
    );
  };
}

export default withRouter(TeleDesktop);
