import React, { useState, useEffect, CSSProperties } from "react";
import {
  Header,
  Form,
  Checkbox,
  Input,
  Button,
  Divider,
  CheckboxProps,
  Dropdown,
  DropdownProps,
  Grid,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import moment from "moment";

//Common
import TimeFreeTextBox24 from "../../react-lib/apps/common/TimeFreeTextBox24";

// Event
import { Event as MobEvent, formatDate, formatDateByLanguage } from "../MobClinicInterface";

import { COLOR } from "./DMExercise";

// Css
import "../../react-lib/css/Common.css";

type ExerciseDMSettingProps = {
  onEvent: (e: MobEvent) => any;
  exerciseSettingData: any;
  selectedPatient: any;
};

type TextLabelProps = {
  style: CSSProperties | undefined;
  label: string;
};

// const FREQUENCY_OPTIONS = [
//   {
//     key: 1,
//     value: 1,
//     text: "Everyday",
//   },
//   // TODO: ไว้ต่อย implement แบบอื่น
//   // {
//   //   key: 2,
//   //   value: 2,
//   //   text: "Twice a week",
//   // },
//   // {
//   //   key: 3,
//   //   value: 3,
//   //   text: "3 time/ week",
//   // },
// ];

const TextLabel = (props: TextLabelProps) => {
  return (
    <>
      <label
        style={{
          fontWeight: "bold",
          paddingTop: "13px",
          paddingBottom: "13px",
          color: COLOR.medium_grey,
          ...props.style,
        }}
      >
        {props.label}
      </label>
    </>
  );
};

export const ExerciseDMSetting = React.memo((props: ExerciseDMSettingProps) => {
  const intl = useIntl();
  const [settingData, setSettingData] = useState<any>({
    id: null,
    data: { isReminder: false, calorie: "", frequency: "", times: [""] },
  });

  const FREQUENCY_OPTIONS = [
    {
      key: 1,
      value: 1,
      text: `${intl.formatMessage({ id: "bplusClinicKey723" })}`,
    },
    // TODO: ไว้ต่อย implement แบบอื่น
    // {
    //   key: 2,
    //   value: 2,
    //   text: "Twice a week",
    // },
    // {
    //   key: 3,
    //   value: 3,
    //   text: "3 time/ week",
    // },
  ];

  useEffect(() => {
    props.onEvent({ message: "GetExerciseSetting", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    if (
      props.exerciseSettingData &&
      props.exerciseSettingData.hasOwnProperty("id")
    ) {
      setSettingData(props.exerciseSettingData);
    } else {
      setSettingData({
        id: null,
        data: {
          isReminder: false,
          calorie: "",
          frequency: "",
          times: [""],
        },
      });
    }
  }, [props.exerciseSettingData]);

  const handleChangeSetting = (event: any, data: any) => {
    let result = { ...settingData };
    if (data.type === "checkbox") {
      result.data[data.name] = data.checked;
      setSettingData({ ...settingData, ...result });
    } else {
      result.data[data.name] = data.value;
      setSettingData({ ...settingData, ...result });
    }
  };

  const SaveWeightSetting = () => {
    props.onEvent({
      message: "SaveExerciseSetting",
      params: { ...settingData },
    });
  };

  console.log("----- settingData", settingData);
  return (
    <div
      style={{
        height: "calc(100vh - 53px)",
        overflow: "auto",
        paddingTop: "42px",
      }}
    >
      <Header
        as="h4"
        style={{
          textAlign: "center",
          marginTop: "15px",
          color: COLOR.medium_grey,
        }}
      >
        {formatDateByLanguage(moment(new Date()).format("DD/MM/YYYY"),"DD MMMM YYYY")}
        {/* {formatDate(new Date())} */}
      </Header>

      <Form style={{ padding: "0px 15px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextLabel
            label={intl.formatMessage({ id: "bplusClinicKey722" })}
            style={{ paddingBottom: 0 }}
          />
        </div>
      </Form>
      <Divider />

      <Form style={{ padding: "0px 15px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "75% 25%" }}>
          <div style={styles.flexBaseline}>
            <label style={styles.labelTimeSetting}>
              <FormattedMessage id="bplusClinicKey101" />
            </label>
            <Input
              style={{ transform: "scale(0.8)" }}
              name="calorie"
              value={settingData?.data?.calorie}
              onChange={handleChangeSetting}
            />

            <label style={styles.labelTimeSetting}>
              <FormattedMessage id="bplusClinicKey554" />
            </label>
          </div>
        </div>
      </Form>

      <Divider />

      <Grid verticalAlign="top" style={{ margin: "0px" }}>
        <Grid.Column width={6}>
          <Header as="h5" style={{ color: COLOR.medium_grey }}>
            <FormattedMessage id="weight.time_reminder" />
          </Header>
        </Grid.Column>
        <Grid.Column width={3} style={{ paddingLeft: "0px" }}>
          <Checkbox
            toggle
            style={{ transform: "scale(0.7)", marginLeft: "4px" }}
            name="isReminder"
            checked={settingData?.data?.isReminder}
            onChange={handleChangeSetting}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          {settingData?.data?.times?.map((item: any, index: number) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "-5px",
              }}
            >
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                  transform: "scale(0.8)",
                }}
                className="time-custom-react-picker padding-divider"
                value={item}
                onChange={(time: string) => {
                  let result: any[] = [...(settingData?.data?.times || [])];
                  result[index] = time;
                  handleChangeSetting({}, { name: "times", value: result });
                }}
                autoFocus={false}
                checkTimeOverflow={true}
              />
              <Button
                color="red"
                icon="minus"
                circular
                size="mini"
                style={{ transform: "scale(0.6)", marginTop: "-1px" }}
                onClick={(event: any) => {
                  let result: any[] = [...settingData?.data?.times];
                  result.splice(index, 1);

                  handleChangeSetting(event, {
                    name: "times",
                    value: result,
                  });
                }}
              />
            </div>
          ))}
        </Grid.Column>
      </Grid>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "54% 46%",
          padding: "0px 15px 65px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextLabel label={intl.formatMessage({ id: "bplusClinicKey205" })} style={{ padding: 0 }} />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            options={FREQUENCY_OPTIONS}
            name="frequency"
            value={settingData?.data?.frequency}
            selection
            style={{
              transform: "scale(0.8)",
              marginLeft: "-12px",
              minWidth: "100%",
            }}
            onChange={handleChangeSetting}
          />
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          bottom: "65px",
          justifyContent: "center",
        }}
      >
        <div style={{ backgroundColor: "white" }}>
          <Button
            content={`${intl.formatMessage({ id: "bplusClinicKey724" })}`}
            icon="plus"
            color="blue"
            labelPosition="left"
            basic
            onClick={(event: any) => {
              let result: any[] = [
                ...(settingData?.data?.times ? settingData?.data?.times : []),
              ];
              result.push("");
              handleChangeSetting(event, {
                name: "times",
                value: result,
              });
            }}
          />
        </div>
        <div>
          <Button icon="save" color="green" onClick={SaveWeightSetting} />
        </div>
      </div>
    </div>
  );
});

const styles = {
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    margin: "5px 0",
  } as CSSProperties,
  labelTimeSetting: {
    color: "#299be6",
    fontWeight: "bold",
    width: "70px",
  } as CSSProperties,
};
