import WasmController from 'react-lib/frameworks/WasmController';
import * as AnalyticsI from './AnalyticsInterface';
import * as LoginI from "./login/LoginInterface";

export default class AnalyticsController extends 
  WasmController<AnalyticsI.State, AnalyticsI.Event, AnalyticsI.Data> {

  constructor(
    getState: () => AnalyticsI.State, 
    setState: (state: AnalyticsI.State, callback?: any) => void, 
    window: any) {
    super(getState, setState, window, AnalyticsI.DataInitial)
  }

  handleEvent = async (e: AnalyticsI.Event) => {
    console.log(e.message, e.params)
    switch (e.message) {
      // Setup
      case "DidMount":
        AnalyticsI.DidMount(this, e.params);
        return

      case "DidUpdate":
        AnalyticsI.DidUpdate(this, e.params);
        return

      case "LoginIssara":
        LoginI.LoginIssara(this, e.params);
        return

      case "Logout":
        LoginI.Logout(this, e.params);
        return

      case "FetchReport":
        AnalyticsI.FetchReport(this, e.params);
        return

      case "FetchCovid":
        AnalyticsI.FetchCovid(this, e.params);
        return

      default:
          let testExhausitve: never = e
    }
    console.log("-- Unhandled case")
  }
}