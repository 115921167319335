import { Icon, Input, Grid, Header } from "semantic-ui-react";


// ตัวนี้ เป็น MobHead ใน Submenu 
const MobHead = (props: any) => (
  <div
    id="MobHead"
    style={TopBarStyle}
    // onClick={(e) => { props.onEvent({ message: "DidLogin", params: {} })}}
  >
    <div
      style={{ flex: 1 }}
      onClick={() => {
        props.leftIconClick();
      }}
    >
      {props.leftIcon && (
        <Icon name={props.leftIcon} style={{ marginLeft: "8px" }} />
      )}
    </div>
    <div
      style={{
        flex: "auto",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "big",
      }}
      onClick={(e) => {
        props.onEvent({ message: "LoginFirebase", params: {} });
      }}
    >
      {props.title}
    </div>
    <div
      style={{ flex: 1, textAlign: "right" }}
      onClick={() => {
        props.rightIconClick();
      }}
    >
      {props.rightIcon && <Icon name={props.rightIcon} />}
      {/* <Icon name="bell" style={{ minWidth: "25px", margin: 0 }} />
      <Icon name="log out" style={{ minWidth: "25px", margin: 0 }} /> */}
    </div>
  </div>
);

const TopBarStyle = {
  backgroundColor: "#2083C1",
  padding: "5px",
  width: "100%",
  justifyContent: "space-between",
  minHeight: "40px",
  color: "white",
  display: "flex",
  alignItems: "center",
};

export default MobHead;
