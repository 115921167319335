import React, { createRef } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import MobCouponController from "./MobCouponController";
import * as MobCouponI from "./MobCouponInterface";
import SetProp from "react-lib/frameworks/SetProp";

import Cookies from "js-cookie";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import Fingerprint2 from "fingerprintjs2";
import CONFIG from "../config/config";
import {
  askUserPermission,
  createNotificationSubscription,
} from "../react-lib/apps/IsHealth/Common/push-notifications";
import { IntlProvider, FormattedMessage } from "react-intl";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";

import MyCoupon from "./coupon/MyCoupon";
import AddCoupon from "./coupon/AddCoupon";
import CameraTest from "./coupon/CameraTest";
import MobCouponEvaluate from "./coupon/MobCouponEvaluate";
import CreateNewPatient from "./coupon/CreateNewPatient";
import AddCouponTeleConsult from "./coupon/AddCouponTeleConsult";

// CSS
import "../css/MobAppointment.scss";
declare global {
  var iosNative: any;
  var MobNative: any;
}

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  DARK: "dark",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
};

const PROJECT_NAME = {
  M_B_PLUS_TELEMED: "My B+ Telemed",
};

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

class MobCoupon extends React.Component<any, MobCouponI.State> {
  controller: MobCouponController;
  showTopbar: any;
  currentPathname: any;
  unlisten: any;

  constructor(props: any) {
    super(props);
    this.state = {
      ...MobCouponI.StateInitial,
    };
    this.controller = new MobCouponController(
      () => {
        return this.state;
      },
      (state: MobCouponI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
    this.showTopbar = createRef();
    this.currentPathname = createRef();
    this.unlisten = createRef();
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({
        message: "GetLoginInfo",
        params: {},
      });
    }

    let company = CONFIG.COMPANY;
    // let currentURL = window.location.href;
    if (company === "BDMS") {
      this.setState({
        theme: THEME.PENGUIN,
        projectName: PROJECT_NAME.M_B_PLUS_TELEMED,
      });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      Cookies.set("theme", THEME.PENGUIN, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.M_B_PLUS_TELEMED, {
        path: "/",
      });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      Cookies.set("theme", THEME.PENTA, { path: "/" });
    }

    //
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      // set dark-theme
    } else {
      // remove dark-theme
    }

    document.querySelector("body")?.classList.add("body-mob-appointment");

    // Get apiToken and userId from mobNative
    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentWillMount() {
    this.currentPathname.current = this.props.history.location.pathname;
    this.unlisten = this.props.history.listen(
      (location: any, action: string) => {
        this.controller.handleEvent({
          message: "HandleRouteChange",
          params: {
            currentPathname: this.currentPathname.current,
            nextPathname: location.pathname,
            action,
          },
        });
        this.currentPathname.current = location.pathname;
      }
    );
  }
  componentWillUnmount() {
    this.unlisten();
  }

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
    // this.controller.handleEvent({
    //   message: "GetConfigPrxEnableEcoupon",
    //   params: {},
    // });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      console.log("did update check......");
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
    }
  }

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  render = () => {
    console.log(" MobCoupon render", this);
    console.log(
      "apiToken",
      this.state.apiToken,
      this.controller.apiToken,
      "userId",
      this.state.userId
    );

    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div className="default-background" style={{ paddingBottom: "1px" }}>
          <Switch>
            <Route
              path="/add-coupon"
              render={(props) => {
                console.log("location: ", props?.location?.pathname);
                const state = props?.location?.state as any;
                if (props?.location?.pathname === "/add-coupon") {
                  this.showTopbar.current = true;
                } else {
                  this.showTopbar.current = false;
                }
                return (
                  <AddCoupon
                    onEvent={this.controller.handleEvent}
                    successMessage={this.state.successMessage}
                    errorMessage={this.state.errorMessage}
                    loadingSearchCoupon={this.state.loadingSearchCoupon}
                    language={this.state.language}
                    patientDetail={this.state.patientDetail}
                    userId={this.state.userId}
                    showTopbar={this.showTopbar.current}
                    loadingMainCoupon={this.state.loadingMainCoupon}
                    stackRememberScroll={this.state.stackRememberScroll}
                  />
                );
              }}
            ></Route>

            {CONFIG.DUBAI_TELE_CONSULT_ENABLE && (
              <Route
                path="/add-coupon-tele-consult"
                render={(props) => {
                  return (
                    <AddCouponTeleConsult
                      onEvent={this.controller.handleEvent}
                      patientDetail={this.state.patientDetail}
                      history={this.props.history}
                      userId={this.state.userId}
                      loadingSearchCoupon={this.state.loadingSearchCoupon}
                      openModCreateNewPatient={
                        this.state.openModCreateNewPatient
                      }
                    />
                  );
                }}
              ></Route>
            )}

            <Route
              path="/evaluate"
              render={(props) => {
                const state = props?.location?.state as any;
                if (props?.location?.pathname === "/evaluate") {
                  this.showTopbar.current = true;
                } else {
                  this.showTopbar.current = false;
                }
                return (
                  <MobCouponEvaluate
                    history={props.history}
                    evaluateForm={this.state.evaluateForm}
                    showTopbar={this.showTopbar.current}
                    onEvent={this.controller.handleEvent}
                    // successMessage={this.state.successMessage}
                    // errorMessage={this.state.errorMessage}
                    // loadingSearchCoupon={this.state.loadingSearchCoupon}
                    // language={this.state.language}
                    // patientDetail={this.state.patientDetail}
                    // userId={this.state.userId}
                  />
                );
              }}
            ></Route>
            <Route
              path="/camera"
              render={(props) => {
                return <CameraTest />;
              }}
            ></Route>
            <Route
              path="/new-patient"
              render={(props) => {
                return (
                  <CreateNewPatient
                    onEvent={this.controller.handleEvent}
                    prenameList={this.state.prenameList}
                    nationalityList={this.state.nationalityList}
                    loadingNewPatient={this.state.loadingNewPatient}
                    createPatientInfo={this.state.createPatientInfo}
                    history={this.props.history}
                    language={this.state.language}
                    openModCreateNewPatient={this.state.openModCreateNewPatient}
                    showTopbar={this.showTopbar.current}
                  />
                );
              }}
            ></Route>
            <Route
              path="/:ecoupon?"
              render={(props) => {
                const state = props?.location?.state as any;

                console.log("location: ", props?.location?.pathname);
                if (props?.location?.pathname === "/ecoupon") {
                  this.showTopbar.current = true;
                } else {
                  this.showTopbar.current = false;
                }
                return (
                  <>
                    {!("add_coupon" in (state || {})) ? (
                      <>
                        <MyCoupon
                          onEvent={this.controller.handleEvent}
                          myCouponList={this.state.myCouponList}
                          firstLoadingSuccess={this.state.firstLoadingSuccess}
                          loadingSearchCoupon={this.state.loadingSearchCoupon}
                          selectedCoupon={this.state.selectedCoupon}
                          loadingModalRedeem={this.state.loadingModalRedeem}
                          patientDetail={this.state.patientDetail}
                          userId={this.state.userId}
                          language={this.state.language}
                          showTopbar={this.showTopbar.current}
                          // config_PRX_ENABLE_ECOUPON={
                          //   this.state.config_PRX_ENABLE_ECOUPON
                          // }
                        />
                      </>
                    ) : null}
                  </>
                );
              }}
            ></Route>
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobCoupon);
