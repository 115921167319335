import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  BACKEND_DATE_FORMAT,
  GROUP,
  DATA_TYPE,
  commonCreatePatientData,
  commonDeletePatientData,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonUpdatePatientData,
  BACKEND_TIME_FORMAT,
  State as MainState
} from "../MobClinicInterface";

export type State = {
  bgFilterDate?: moment.Moment;
  bgFilterDuration?: string;
  bgRecordList?: any;
  bgSettingData?: any;
  bgChartList?: any;
  setBloodSugar?: string | number | null;
  inputBgDetail?: {
    smbg?: string | number;
    note?: string
    meal?: string;
  };
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "successMessage"
>;

export const StateInitial: State = {
  bgFilterDate: moment(),
  bgFilterDuration: "6",
  bgRecordList: {},
  bgSettingData: {},
  bgChartList: {},
  inputBgDetail: {
    smbg: "",
    note: "",
    meal: "before",
  }
};

export type Event =
  // GET
  | { message: "GetBloodGlucoseRecord" }
  | { message: "GetBloodGlucoseSetting", params: {} }
  | {
    message: "GetBloodGlucoseChart";
    params: { start_measure: string; end_measure: string };
  }
  // CHANGE
  | { message: "handleChangeBgFilterDate"; params: { date: moment.Moment } }
  | { message: "handleChangeBgFilterDuration"; params: { duration: string } }
  | { message: "HandleSetInputBgDetail"; params: State["inputBgDetail"] }
  // SAVE
  | {
    message: "SaveBloodGlucoseRecord";
    params: { measure_date: string; data: any };
  }
  | { message: "SaveBloodGlucoseSetting"; params: {} }
  // DELETE
  | { message: "RemoveBloodGlucoseRecord"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const GetBloodGlucoseRecord: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const tmpParams: any = {
    group: GROUP.BLOOD_GLUCOSE,
    data_type: DATA_TYPE.BLOOD_GLUCOSE,
    start_measure: state.bgFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bgRecordList: response,
  });
};

export const GetBloodGlucoseSetting: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.BLOOD_GLUCOSE,
    data_type: DATA_TYPE.SETTING,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bgSettingData: response,
  });
};

export const GetBloodGlucoseChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.BLOOD_GLUCOSE,
    data_type: DATA_TYPE.BLOOD_GLUCOSE,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bgChartList: response,
  });
};

// CHANGE
export const handleChangeBgFilterDate: Handler = async (controller, params) => {
  controller.setState({ 
    bgFilterDate: moment(params.date, "DD/MM/YYYY"), 
    inputBgDetail: {
      smbg: "",
      note: "",
      meal: "before",
    }
  });
};

export const handleChangeBgFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ bgFilterDuration: params.duration });
};


export const HandleSetInputBgDetail: Handler = (controller, params) => {
  const { inputBgDetail } = controller.getState();

  controller.setState({
    inputBgDetail: {
      ...inputBgDetail,
      ...params,
    },
    setBloodSugar: "",
  });
};

// SAVE
export const SaveBloodGlucoseRecord: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const data: any = {
    group: GROUP.BLOOD_GLUCOSE,
    data_type: DATA_TYPE.BLOOD_GLUCOSE,
    measure_date: params.measure_date,
    data: { ...params.data },
  };
  const [error, response] = await commonCreatePatientData(controller as any, data);
  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add blood glucose success.";
    GetBloodGlucoseRecord(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
    inputBgDetail: {
      smbg: "",
      note: "",
      meal: "before",
    }
  });
};

export const SaveBloodGlucoseSetting: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller as any, data);
  } else {
    data = {
      group: GROUP.BLOOD_GLUCOSE,
      data_type: DATA_TYPE.SETTING,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }

  console.log("globalThis.iosNative", globalThis.iosNative)
  if (globalThis.iosNative?.setiOSLocalAlarm) {
    // IOS 
    console.log("Call globalThis.iosNative.setiOSLocalAlarm")
    globalThis.iosNative?.setiOSLocalAlarm(data)
  }

  if (globalThis.MobNative) {
    // Android 
    console.log("call globalThis.MobNative.setLocalAlarm")
    console.log(data)
    globalThis.MobNative.setLocalAlarm(JSON.stringify(data))
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    GetBloodGlucoseSetting(controller);
    successMessage = "Save settings success.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

// DELETE
export const RemoveBloodGlucoseRecord: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete blood glucose success.";
    }

    GetBloodGlucoseRecord(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
