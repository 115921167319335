import React, {
  useState,
  CSSProperties,
  forwardRef,
  useEffect,
  useMemo,
  SyntheticEvent,
} from "react";
// Semantic

// Mui
import { makeStyles, Dialog, Slide } from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Skeleton } from "@mui/material";

// Mui Icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// UI
import CardG6PDInfo from "./CardG6PDInfo";
import CardAllergyTab from "./CardAllergyTab";
import CardWarningTab from "./CardWarningTab";
import TabMenu from "./TabMenu";

// Interface
import { HospitalType } from "bplus-lib/appointment/SelectHospitalInterface";
import { State } from "bplus-lib/MobHistoryinterface";

// Styles
import "./HistoryCSS.scss";

// Type
type AllergyDetailProps = {
  // function
  onEvent: any;
  // data
  history: any;

  hospitalList?: any[];
  selectedHospital?: HospitalType | null;
} & Pick<
  State,
  "medAllergyList" | "firstLoadingAllergy" | "medAllergyGroup" | "allergyTab"
>;

export type MedAllergyType = {
  comment?: string;
  detail: string;
  last_used: string;
  name_generic: string;
  type: string;
  testing: string;
  probability?: any;
  order: number;
};

// Use Styles
export const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  super_dark_gray: "#393939",
  blue_bdms: "var(--blue-bdms-color)",
  super_light_blue: "#CCDAED",
  background_button: "#F5F5F5",
  dark_grey: "#797878",
  super_light: "rgba(233, 233, 233, 1)",
};

export const useStyles = makeStyles((theme) => ({
  accordion: {
    borderRadius: "5px !important",
    marginBottom: "15px",
    boxShadow: "0px 0px 8px 1px rgba(131, 133, 135, 0.1) !important",
    overflow: "hidden",
    "&::before": {
      opacity: "0 !important",
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      padding: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      "&.Mui-expanded": {
        margin: "0",
      },
    },
    "&.Mui-expanded": {
      margin: "0 0 15px !important",
    },
    "&.--skeleton": {
      boxShadow: "0px 0px 8px 1px rgba(131, 133, 135, 0.025) !important",
    },
  },
  backdrop_root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
}));

export const styles = {
  box_tabs: {
    width: "100%",
    background: "#F5F5F5",
    display: "flex",
    marginBottom: "20px",
    borderRadius: "8px",
    overflow: "hidden",
  } as CSSProperties,
  btn_tab: {
    width: "100%",
    borderRadius: 0,
    background: "#F5F5F5",
    color: COLORS.dark_grey,
    fontWeight: "bold",
    boxShadow: "none",
    textTransform: "unset",
  } as CSSProperties,
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: COLORS.super_light,
  } as CSSProperties,
};

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const AllergyDetail: React.FC<AllergyDetailProps> = (props) => {
  // const [cardTab, setCardTab] = useState<"Allergy" | "warning">();
  const [expanded, setExpanded] = useState<string>("");
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    const hospital = props.selectedHospital;

    if (hospital) {
      props.onEvent({
        message: "GetMedAllergy",
        params: { hospital: hospital.code, name: hospital.name },
      });
    }
  }, [props.selectedHospital]);

  const handleChangeCardTab = (cardTab: any) => {
    setExpanded("");
    props.onEvent({
      message: "HandleChangeAllergyTab",
      params: { tab: cardTab },
    });
  };

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  const handleOpen = () => {
    setOpenModInfo(true);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleSelectedHospital = (e: SyntheticEvent) => {
    props.history.push(`/Select-Hospital/?app=MobHistory`);
  };

  return (
    <div style={{ padding: "15px" }}>
      {/* Header */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 0 15px",
          fontSize: "1.1rem",
        }}
      >
        <span style={{ display: "flex" }} onClick={handleSelectedHospital}>
          <div
            style={{
              color: COLORS.blue_bdms,
              fontWeight: "bold",
              marginRight: "5px",
            }}
          >
            {props.selectedHospital?.name}
          </div>
          <KeyboardArrowDownIcon
            style={{
              color: COLORS.blue_bdms,
              fontSize: "1.85rem",
              marginTop: -3,
            }}
          />
        </span>
      </div>
      {/* Tab */}
      <TabMenu
        tab={props.allergyTab}
        leftTitle="Allergy"
        rightTitle="Warning"
        // config
        leftDisabled={!props.medAllergyGroup?.allergy?.length}
        rightDisabled={!props.medAllergyGroup?.warning?.length}
        // callback
        onChange={handleChangeCardTab}
      />

      {props.firstLoadingAllergy ? (
        <>
          {/* Card */}
          {props.allergyTab === "Allergy" && (
            <CardAllergyTab
              data={props.medAllergyGroup?.allergy || []}
              expanded={expanded}
              onToggle={handleChange}
            />
          )}
          {props.allergyTab === "Warning" && (
            <CardWarningTab
              data={props.medAllergyGroup?.warning || []}
              expanded={expanded}
              onToggle={handleChange}
              onOpen={handleOpen}
            />
          )}
        </>
      ) : (
        <AllergySkeleton />
      )}

      <Dialog
        fullScreen
        open={openModInfo}
        onClose={() => setOpenModInfo(false)}
        // @ts-ignore
        TransitionComponent={Transition}
        className={classes.backdrop_root}
      >
        <CardG6PDInfo
          onEvent={props.onEvent}
          history={props.history}
          onClose={handleCloseModInfo}
        />
      </Dialog>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                     AllergySkeleton                    */

/* ------------------------------------------------------ */
const AllergySkeleton = () => {
  const classes = useStyles();

  return (
    <>
      {[
        [50, 20],
        [65, 20],
        [45, 30],
        [60, 20],
        [50, 50],
      ].map((width, index) => (
        <Accordion
          key={"allergy-accordion-" + index}
          className={`${classes.accordion} --skeleton`}
          style={{
            backgroundColor: "var(--skeleton-bg-color)",
          }}
        >
          <AccordionSummary
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
              }}
            >
              <div style={{ padding: "12px 10px 18px 18px" }}>
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    height={18}
                    width={`${width[0]}%`}
                  />
                </div>
                <div
                  style={{
                    color: COLORS.blue_bdms,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Skeleton animation="pulse" height={18} width="20%" />
                </div>
                <div style={styles.divider}></div>{" "}
                <Skeleton
                  animation="pulse"
                  height={18}
                  width={`${width[1]}%`}
                />
              </div>
            </div>
          </AccordionSummary>
        </Accordion>
      ))}
    </>
  );
};

(AllergyDetail.defaultProps as AllergyDetailProps) = {
  // function
  onEvent: null,
  // data
  history: null,
};

export default React.memo(AllergyDetail);
