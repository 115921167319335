import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  State as MainState
} from "../MobClinicInterface";

export type State = {
  childBirthRecord?: any;
};

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage">

export const StateInitial = {
  childBirthRecord: {},
};

export type Event =
  // GET
  { message: "handleGetChildBirthRecord" };

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetChildBirthRecord: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.OBSTRETIC_RECORD,
    data_type: DATA_TYPE.CHILD_OBSTRETIC_RECORD,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    childBirthRecord: response,
  });
};
