import React, {
  useMemo,
  useState,
  useEffect,
  CSSProperties,
  useRef,
  MutableRefObject,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
// CSS
import {
  Dimmer,
  Form,
  Icon,
  Header,
  Input,
  Button,
  Loader,
  Tab,
  Menu,
  TabProps,
  Modal,
  SemanticICONS,
} from "semantic-ui-react";

import moment from "moment";
import { useTable } from "react-table";
import { Line } from "react-chartjs-2";
import SwipeableViews from "react-swipeable-views";
import { AppBar } from "@material-ui/core";

// Interface
import {
  Event as MobEvent,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  convertToADDate,
  State,
  formatDateByLanguage,
} from "../MobClinicInterface";

// UI
import PatientPanel from "./PatientPanel";
import MobHead from "../MobHead";
import SnackMessage from "./SnackMessage";
import { DMBloodPressureSetting } from "./DMBloodPressureDialog";
import {
  HeaderChart,
  FooterChart,
  spliceChartData,
  labelNewLine,
  DMDatePicker,
} from "./DMEmotionRestChart";

// Utils
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";
import { dateToStringWithoutTimeBE } from "../../react-lib/utils/dateUtils";

// Styles
import "./DMBloodPressure.scss";

const BP_DETAIL = {
  NORMAL: {
    text: "Normal",
    color: "#44A210",
    pointColor: "#ffd00d",
  },
  HIGH: {
    text: "High blood pressure",
    color: "#EE1C24",
    pointColor: "#ff9100",
  },
};

const BP_STATUS = [
  {
    detail: "SYS < 140, DIA < 90",
    status: BP_DETAIL.NORMAL.text,
    color: BP_DETAIL.NORMAL.color,
  },
  {
    detail: "SYS ≥ 140 or DIA ≥ 90",
    status: BP_DETAIL.HIGH.text,
    color: BP_DETAIL.HIGH.color,
  },
];

type DMBloodPressureProps = {
  // function
  onEvent: (e: MobEvent) => any;
  onSetBackToApp: () => void;
} & Pick<
  State,
  | "inputBpDetail"
  | "bpChartList"
  | "bpSettingData"
  | "bpRecordList"
  | "bpFilterDuration"
  | "bpFilterDate"
  | "loadingStatus"
  | "selectedPatient"
  | "errorMessage"
  | "successMessage"
>;

type HistoryTabProps = {
  // function
  onEvent: (e: MobEvent) => any;
  // data
  bpFilterDuration?: string;
  selectedPatient?: any;
  bpChartList?: any;
  isMyTab?: boolean;
};

type DetailsTabProps = {
  onSettingClick: () => any;
  onCameraClick: () => any;
} & DMBloodPressureProps;

const DMBloodPressureInitial: DMBloodPressureProps = {
  // function
  onEvent: () => null,
  onSetBackToApp: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  bpFilterDate: moment(),
  bpFilterDuration: "6",
  bpRecordList: {},
  bpSettingData: {},
};

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
  } as CSSProperties,
  flexColumns: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    justifyContent: "center",
  } as CSSProperties,
  bmiLeftBold: {
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: "5px",
  } as CSSProperties,
};

const DATE_FORMAT = "DD/MM/YYYY";

export const prepareDataDraw = ({
  chart,
  allowIndex,
  allowStack,
}: {
  chart: any;
  allowIndex?: number[];
  allowStack?: (string | number)[];
}) => {
  const metaDataList: any[] = [];
  let stacks: any = {};
  const barList: any[] = [];

  for (const [i, dataset] of chart.config.data.datasets.entries()) {
    const meta = chart.getDatasetMeta(i);
    if (allowIndex?.includes(i)) {
      metaDataList.push(meta?.data || []);
    }
    if (allowStack?.includes(dataset.stack)) {
      stacks[`${dataset.stack}`] = [
        ...(stacks[`${dataset.stack}`] || []),
        meta.data.map((bar: any, index: number) => ({
          bar,
          value:
            bar._chart.config.data.datasets[bar._datasetIndex].data[bar._index],
        })),
      ];
    }
  }

  stacks = Object.keys(stacks).map((key) => {
    return stacks[key].reduce((result: any, item: any) => {
      item.map((acc: any, index: number) => {
        result[index] = acc;
        // result?.[index]?.value > acc.value ? result?.[index] : acc;
      });
      return result;
    }, []);
  });

  for (const list of [...metaDataList, ...stacks]) {
    list.forEach((bar: any, index: number) => {
      barList.push("bar" in bar ? bar.bar : bar);
    });
  }

  return { barList };
};

export const drawStarChart = ({
  chart,
  top = 20,
  radius = 8,
  allowIndex,
  allowStack,
  noteField,
  hideFillText,
}: {
  chart: any;
  top?: number;
  radius?: number;
  allowIndex?: number[];
  allowStack?: (string | number)[];
  noteField: string; //"x","x.y"
  hideFillText?: boolean;
}) => {
  const ctx = chart.ctx;

  ctx.textAlign = "center";
  ctx.textBaseline = "bottom";

  const barDrawStar = (bar: any) => {
    const [first, second] = noteField.split(".");
    let target =
      bar._chart.config.data.datasets?.[bar._datasetIndex]?.[first]?.[
        bar._index
      ];

    if (second) {
      target = target?.[second];
    }

    const isNoteArray = Array.isArray(target);
    let isDraw = false;
    if (isNoteArray && target?.find((item: any) => item?.note)) {
      isDraw = true;
    } else if (target?.note) {
      isDraw = true;
    }

    if (!isDraw) {
      return;
    }

    const ctx = chart.ctx;
    const y_axis = bar._yScale;
    const _model = bar._model;
    const centerX = _model.x;
    const topY = y_axis.top;
    const centerY = topY + _model.y - top;

    const drawStar = (
      cx: number,
      cy: number,
      spikes: number,
      outerRadius: number,
      innerRadius: number
    ) => {
      let rot = (Math.PI / 2) * 3;
      let x = cx;
      let y = cy;
      let step = Math.PI / spikes;

      ctx.beginPath();
      ctx.moveTo(cx, cy - outerRadius);
      for (let i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * outerRadius;
        y = cy + Math.sin(rot) * outerRadius;
        ctx.lineTo(x, y);
        rot += step;

        x = cx + Math.cos(rot) * innerRadius;
        y = cy + Math.sin(rot) * innerRadius;
        ctx.lineTo(x, y);
        rot += step;
      }
      ctx.lineTo(cx, cy - outerRadius);
      ctx.closePath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#EE1C24";
      ctx.stroke();
      ctx.fillStyle = "#EE1C24";
      ctx.fill();
      ctx.restore();
    };

    drawStar(centerX, centerY, 5, radius, radius / 2 + 0.5);
    if (!hideFillText) {
      ctx.beginPath();
      ctx.font = `${radius}px serif`;
      ctx.fillStyle = "white";
      ctx.fillText("N", centerX, centerY + (radius / 2 + 0.5));
    }
  };

  const { barList } = prepareDataDraw({ chart, allowIndex, allowStack });
  for (const bar of barList) {
    barDrawStar(bar);
  }
};

export const displayNoteChart = ({
  chart,
  event,
  top = 20,
  radius = 8,
  tooltipRef,
  allowIndex,
  noteField,
  allowStack,
}: {
  chart: any;
  event: any;
  top?: number;
  radius?: number;
  tooltipRef?: HTMLDivElement;
  allowIndex?: number[];
  allowStack?: (string | number)[];
  noteField: string; //"x","x.y"
}) => {
  const { barList } = prepareDataDraw({ chart, allowIndex, allowStack });

  const elements = barList?.map((bar: any) => {
    const y_axis = bar._yScale;
    const _model = bar._model;
    const topY = y_axis.top;
    const centerY = topY + _model.y - top;
    return { x: _model.x, y: centerY, chart: bar };
  });

  const star = elements?.find((elm: any) => {
    return (
      event.x >= elm.x - radius &&
      event.x <= elm.x + radius &&
      event.y >= elm.y - radius &&
      event.y <= elm.y + radius
    );
  });

  const tooltip = tooltipRef;
  if (star?.chart && !chart.tooltip?._active?.[0]) {
    const [first, second] = noteField.split(".");
    const data = star.chart?._chart?.config?.data;
    let target =
      data?.datasets?.[star.chart._datasetIndex]?.[first]?.[star.chart._index];

    if (second) {
      target = target?.[second];
    }

    const isNoteArray = Array.isArray(target);
    let isDraw = false;
    if (isNoteArray && target?.find((item: any) => item?.note)) {
      target = target.map((item: any) => item.note).filter(Boolean);
      isDraw = true;
    } else if (target?.note) {
      isDraw = true;
    }

    const starX = chart.canvas.getAttribute("star_x");
    const starY = chart.canvas.getAttribute("star_y");

    chart.canvas.setAttribute("star_y", star.y);
    if ((starX === star.x && starY === star.y) || !isDraw) {
      return;
    }

    chart.canvas.style.cursor = "pointer";
    const innerHTML =
      !isNoteArray || target?.length === 1
        ? target?.[0] || target.note
        : `<ul>
            ${target.map((note: any) => `<li>${note}</li>`).join("")}
            </ul>`;
    let textAlign = "";
    if (target?.length > 1) {
      textAlign = `unset`;
    }
    tooltipCustom({
      chart,
      innerHTML,
      tooltip,
      x: star.x,
      y: star.y,
      textAlign,
      bar: star.chart,
    });
  } else {
    chart.canvas.style.cursor = "";
    tooltipClose(chart, tooltip);
  }
};

const getPopupPosition = ({
  x,
  y,
  area,
  height,
  width,
}: {
  x: number;
  y: number;
  area: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
  height: number;
  width: number;
}) => {
  let type:
    | "top right"
    | "top left"
    | "top center"
    | "bottom right"
    | "bottom left"
    | "bottom center"
    | "right center"
    | "left center" = "bottom center";
  let xAxes: number = x;
  let yAxes: number = y;

  const right = Math.abs(area.right - x);
  const left = Math.abs(area.left - x);
  const isRight = right < left;
  const position = isRight ? "right" : "left";
  const xString = isRight ? `${x}` : `-${x}`;
  const arrow = 5.5;
  const triage = 12;

  if (height + y > area.bottom) {
    yAxes -= height + arrow;
    if (width + Number.parseInt(xString) > area[position]) {
      type = `top ${position}`;
      xAxes -= isRight ? width - triage : triage;
    } else {
      type = "top center";
      xAxes -= width / 2;
    }
  } else if (y - height < area.top) {
    yAxes += arrow;
    if (width + Number.parseInt(xString) > area[position]) {
      type = `bottom ${position}`;
      xAxes -= isRight ? width - triage : triage;
    } else {
      type = "bottom center";
      xAxes = x - width / 2;
    }
  } else {
    type = `${isRight ? "left" : "right"} center`;
    yAxes = y - height / 2;
    xAxes = isRight ? xAxes - width : xAxes + arrow;
  }
  return { type, xAxes, yAxes };
};

export const tooltipClose = (chart: any, tooltip?: HTMLDivElement) => {
  chart.canvas.removeAttribute("star_x");
  chart.canvas.removeAttribute("star_y");
  if (tooltip) {
    tooltip.classList.remove("active");
    tooltip.style.textAlign = ``;
  }
};

export const tooltipCustom = ({
  chart,
  innerHTML,
  tooltip,
  x,
  y,
  textAlign,
  bar,
}: {
  chart: any;
  innerHTML: string;
  tooltip?: HTMLDivElement;
  x: number;
  y: number;
  textAlign?: string;
  bar: any;
}) => {
  chart.canvas.setAttribute("star_x", x);
  chart.canvas.setAttribute("star_y", y);
  const y_axis = bar._yScale;

  if (tooltip) {
    // tooltip.style.top = `${y + 20}px`;
    if (textAlign) {
      tooltip.style.textAlign = `unset`;
    }
    tooltip.innerHTML = innerHTML;

    const { width, height } = tooltip.getBoundingClientRect();
    const position = getPopupPosition({
      x: x,
      y: y,
      area: y_axis.chart.chartArea,
      height,
      width,
    });
    const type = position.type.split(" ");
    tooltip.className = "tooltip-blood-pressure";
    tooltip.classList.add(type[0], type[1]);
    tooltip.style.left = `${position.xAxes}px`;
    tooltip.style.top = `${position.yAxes}px`;
    tooltip.classList.add("active");
  }
};

const DMBloodPressure: React.FC<DMBloodPressureProps> = (props) => {
  const intl = useIntl();
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  //Open
  const [openScreenSetting, setOpenScreenSetting] = useState(false);
  const [locationKeys, setLocationKeys] = useState<string[]>([]);

  let history = useHistory();

  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }

        if (openScreenSetting) {
          setOpenScreenSetting(false);
        }
      }
    });
  }, [locationKeys]);

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleSettingClick = () => {
    history.push(`${history.location.search}&setting=true`);
    setOpenScreenSetting(true);
  };

  const handleCameraClick = () => {
    // history.push("?camera_scan=true");
    // setOpenScreenCameraScan(true);
    if (typeof window.iosNative !== "undefined") {
      window.iosNative.scanBP?.();
    } else if (typeof window.MobNative !== "undefined") {
      window.MobNative.scanBP?.();
    }
  };

  const handleLeftIconClick = () => {
    if (!openScreenSetting) {
      if (props.onSetBackToApp) {
        props.onSetBackToApp();
      }
    } else {
      history.goBack();
      setOpenScreenSetting(false);
    }
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  return (
    <>
      <AppBar position="fixed" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={handleLeftIconClick}
          title={intl.formatMessage({ id: "bplusClinicKey89" })}
        />
      </AppBar>
      <div
        style={{
          backgroundColor: "white",
          transition: "all 0.3s ease",
          position: "fixed",
          top: 0,
          zIndex: 10,
          width: "100%",
          height: "100%",
          right: openScreenSetting ? "0" : "-100%",
        }}
      >
        {openScreenSetting && (
          <DMBloodPressureSetting
            onEvent={props.onEvent}
            successMessage={props.successMessage}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            bpSettingData={props.bpSettingData}
            selectedPatient={props.selectedPatient}
          />
        )}
      </div>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Tab
        className="weight-tab-custom dm"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
      />
      <SwipeableViews
        index={+viewIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
      >
        <div style={{ paddingBottom: "35px" }}>
          <DetailsTab
            onCameraClick={handleCameraClick}
            onSettingClick={handleSettingClick}
            {...props}
          />
        </div>
        <div style={{ paddingBottom: "35px" }}>
          <HistoryTab
            // function
            onEvent={props.onEvent}
            // data
            bpFilterDuration={props.bpFilterDuration}
            selectedPatient={props.selectedPatient}
            bpChartList={props.bpChartList}
            isMyTab={viewIndex === 1}
          />
        </div>
      </SwipeableViews>
    </>
  );
};

const DetailsTab: React.FC<DetailsTabProps> = (props) => {
  const intl = useIntl();
  // Modal state
  const [openMod, setOpenMod] = useState<boolean>(false);
  const [noteInfo, setNoteInfo] = useState<string>("");

  useEffect(() => {
    props.onEvent({ message: "GetBloodPressureRecordByStart" });
  }, [props.selectedPatient, props.bpFilterDate]);

  // change
  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: any = props.bpFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeBpFilterDate",
      params: { date: targetDate },
    });
  };

  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleChangeBpFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  // save
  const handleRemoveRecord = (row: any) => {
    props.onEvent({ message: "RemoveBloodPressureRecord", params: row });
  };

  const handleOnSaveBloodPressure = () => {
    const { systolic, diastolic, pulse, note } = props.inputBpDetail || {};
    props.onEvent({
      message: "SaveTodayBloodPressureRecord",
      params: {
        measure_date: `${props.bpFilterDate?.format(
          BACKEND_DATE_FORMAT
        )} ${moment().format(BACKEND_TIME_FORMAT)}`,
        data: {
          systolic,
          diastolic,
          pulse,
          note,
        },
      },
    });

    if (typeof window.iosNative !== "undefined") {
      window.iosNative.saveBP(`${systolic},${diastolic},${pulse}`);
    } else if (typeof window.MobNative !== "undefined") {
      window.MobNative.saveBP(`${systolic},${diastolic},${pulse}`);
    }

    props.onEvent({
      message: "HandleSetInputBpDetail",
      params: { systolic: "", diastolic: "", pulse: "", note: "" },
    });
  };

  const columnsRecord = useMemo(
    () => [
      {
        Header: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="bplusClinicKey484" />
            </span>
          </div>
        ),
        accessor: "time",
        width: 60,
        Cell: ({ row }: any) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {formatUTCtoMoment(row.original?.measure_date).format("HH:mm")}
          </div>
        ),
      },
      {
        Header: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="bplusClinicKey433" />
            </span>
            <span>
              <FormattedMessage id="bplusClinicKey11" />
            </span>
          </div>
        ),
        accessor: "sys",
        width: 60,
        Cell: ({ row }: any = {}) => (
          <div style={{ textAlign: "center" }}>
            {row.original?.data?.systolic}
          </div>
        ),
      },
      {
        Header: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="bplusClinicKey140" />
            </span>
            <span>
              <FormattedMessage id="bplusClinicKey11" />
            </span>
          </div>
        ),
        accessor: "dia",
        width: 60,
        Cell: ({ row }: any = {}) => (
          <div style={{ textAlign: "center" }}>
            {row.original?.data?.diastolic}
          </div>
        ),
      },
      {
        Header: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="bplusClinicKey404" />
            </span>
          </div>
        ),
        accessor: "pulse",
        width: 60,
        Cell: ({ row }: any = {}) => {
          return (
            <div style={{ textAlign: "center" }}>
              {row.original?.data?.pulse}
            </div>
          );
        },
      },
      {
        Header: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="bplusClinicKey675" />
            </span>
          </div>
        ),
        accessor: "note",
        minWidth: 60,
        Cell: ({ row }: any = {}) => {
          if (row.original?.data?.note) {
            return (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  marginTop: "-5px",
                }}
              >
                <Icon
                  color="blue"
                  name="sticky note outline"
                  onClick={() => {
                    setOpenMod(true);
                    setNoteInfo(row.original?.data?.note);
                  }}
                />
              </div>
            );
          }
          return <div>{row.original?.data?.note}</div>;
        },
      },
      {
        Header: "",
        accessor: "_remove",
        width: 40,
        Cell: ({ row }: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                color="red"
                size="tiny"
                name="minus"
                circular
                inverted
                // style={{ fontSize: "6px" }}
                onClick={() => {
                  handleRemoveRecord(row.original);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <PatientPanel patientInfo={props.selectedPatient} />

      <DMDatePicker
        date={props.bpFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
      />

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <Form style={{ padding: "0px 10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "80% 20%",
            marginBottom: "1.5em",
          }}
        >
          <div style={{ display: "flex", alignItems: "end", color: "#2083C1" }}>
            <Header as="h3" style={{ margin: "0 10px 0 0", color: "#2083C1" }}>
              <FormattedMessage id="bplusClinicKey94" />
            </Header>
            <Icon
              name="camera retro"
              size="large"
              onClick={() => {
                props.onCameraClick?.();
              }}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <Icon
              name="cog"
              color="blue"
              size="large"
              onClick={() => {
                props.onSettingClick?.();
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "0 3em",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "20% 70%",
              marginTop: "10px",
              minWidth: "10%"
            }}
          >
            <div
              className="grey-label"
              style={{ alignSelf: "center", paddingTop: "5px" }}
            >
              <Header as="h5">
                <FormattedMessage id="bplusClinicKey433" />
              </Header>
            </div>
            <Input
              className="grey-label"
              label={{
                basic: true,
                content: `${intl.formatMessage({ id: "bplusClinicKey673" })}`,
              }}
              labelPosition="right"
              value={props.inputBpDetail?.systolic || ""}
              style={{ marginRight: "43px" }}
              onChange={(event, data) => {
                props.onEvent({
                  message: "HandleSetInputBpDetail",
                  params: { systolic: data.value },
                });
              }}
              placeholder={intl.formatMessage({ id: "bplusClinicKey676" })}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "20% 70%",
              marginTop: "10px",
              minWidth: "10%"
            }}
          >
            <div
              className="grey-label"
              style={{ alignSelf: "center", paddingTop: "5px" }}
            >
              <Header as="h5">
                <FormattedMessage id="bplusClinicKey140" />
              </Header>
            </div>
            <Input
              className="grey-label"
              label={{
                basic: true,
                content: `${intl.formatMessage({ id: "bplusClinicKey673" })}`,
              }}
              labelPosition="right"
              value={props.inputBpDetail?.diastolic || ""}
              style={{ marginRight: "43px" }}
              onChange={(event, data) => {
                props.onEvent({
                  message: "HandleSetInputBpDetail",
                  params: { diastolic: data.value },
                });
              }}
              placeholder={intl.formatMessage({ id: "bplusClinicKey677" })}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "20% 70%",
              marginTop: "10px",
              minWidth: "10%"
            }}
          >
            <div
              className="grey-label"
              style={{ alignSelf: "center", paddingTop: "5px" }}
            >
              <Header as="h5">
                <FormattedMessage id="bplusClinicKey369" />
              </Header>
            </div>
            <Input
              className="grey-label"
              label={{
                basic: true,
                content: `${"\u00a0\u00a0\u00a0"}${intl.formatMessage({
                  id: "bplusClinicKey674",
                })}${"\u00a0\u00a0"}`,
              }}
              labelPosition="right"
              value={props.inputBpDetail?.pulse || ""}
              style={{ marginRight: "43px" }}
              onChange={(event, data) => {
                props.onEvent({
                  message: "HandleSetInputBpDetail",
                  params: { pulse: data.value },
                });
              }}
              placeholder={intl.formatMessage({ id: "bplusClinicKey678" })}
            />
          </div>
          <div style={{ marginTop: "10px", display: "flex", width: "100%" }}>
            <Input
              icon="edit"
              iconPosition="left"
              fluid
              placeholder={intl.formatMessage({ id: "bplusClinicKey826" })}
              value={props.inputBpDetail?.note || ""}
              style={{ width: "100%" }}
              onChange={(event, data) => {
                props.onEvent({
                  message: "HandleSetInputBpDetail",
                  params: { note: data.value },
                });
              }}
            />
            <Button
              icon="save"
              color="blue"
              disabled={
                !props.inputBpDetail?.systolic &&
                !props.inputBpDetail?.diastolic &&
                !props.inputBpDetail?.pulse &&
                !props.inputBpDetail?.note
              }
              style={{ marginLeft: "5px", marginRight: "-43px" }}
              onClick={handleOnSaveBloodPressure}
            />
          </div>
        </div>

        <div style={{ marginTop: "3em" }}>
          <Header as="h3" style={{ marginRight: "10px", color: "#2083C1" }}>
            <FormattedMessage id="bplusClinicKey492" />
          </Header>

          {props.bpRecordList?.items?.length > 0 ? (
            <Table
              data={props.bpRecordList?.items || []}
              columns={columnsRecord}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <FormattedMessage id="common.no_data" />
            </div>
          )}
        </div>
      </Form>
      <Modal
        open={openMod}
        size="mini"
        content={noteInfo}
        onClose={() => {
          setOpenMod(false);
          setNoteInfo("");
        }}
      />
    </>
  );
};

const HistoryTab: React.FC<HistoryTabProps> = (props) => {
  const intl = useIntl();
  const [detail, setDetail] = useState<any>({});

  const columnsBP = [
    {
      Header: (
        <div style={{ textAlign: "left", paddingLeft: "5px" }}>
          <label>
            <FormattedMessage id="bplusClinicKey93" />
          </label>
          <label style={{ fontWeight: "normal" }}>
            <FormattedMessage id="bplusClinicKey10" />
          </label>
        </div>
      ),
      accessor: "detail",
      width: 160,
      Cell: ({ row }: any = {}) => (
        <div style={{ color: row.original.color }}>{row.original?.detail}</div>
      ),
    },
    {
      Header: (
        <div style={{}}>
          <FormattedMessage id="bplusClinicKey468" />
        </div>
      ),
      accessor: "status",
      minWidth: 80,
      Cell: ({ row }: any = {}) => (
        <div style={{ color: row.original.color }}>
          {row.original?.status === "Normal"
            ? `${intl.formatMessage({ id: "bplusClinicKey832" })}`
            : row.original?.status === "High blood pressure"
            ? `${intl.formatMessage({ id: "bplusClinicKey835" })}`
            : ""}
        </div>
      ),
    },
  ];

  return (
    <>
      <DMBloodPressureLine
        onEvent={props.onEvent}
        setDetail={setDetail}
        lineData={props.bpChartList}
        bpFilterDuration={props.bpFilterDuration}
        selectedPatient={props.selectedPatient}
        isMyTab={props.isMyTab}
        detail={detail}
      />

      <div style={{ marginTop: "10px", ...styles.flexBaseline }}>
        <IconLabel
          icon="calendar alternate outline"
          label={
            detail?.measure_date
              // ? formatUTCtoMoment(detail?.measure_date).format("DD MMMM YYYY")
              ? formatDateByLanguage(moment(detail?.measure_date).format(DATE_FORMAT),"DD MMMM YYYY")
              : "-"
          }
        />
        <IconLabel
          icon="time"
          label={
            detail?.measure_date
              ? formatUTCtoMoment(detail?.measure_date).format("HH:mm")
              : "-"
          }
        />
        <IconLabel icon="edit" label={detail?.data?.note || "-"} />
      </div>

      <div
        style={{
          marginTop: "15px",
          padding: "0px 45px",
          ...styles.flexColumns,
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <label style={{ fontWeight: "bold", width: "30%" }}>
            <FormattedMessage id="bplusClinicKey434" />
          </label>
          <label style={{ width: "50px" }}>
            {detail?.data?.systolic || "-"}
          </label>
          <label>{`${intl.formatMessage({ id: "bplusClinicKey673" })}`}</label>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <label style={{ fontWeight: "bold", width: "30%" }}>
            <FormattedMessage id="bplusClinicKey141" />
          </label>
          <label style={{ width: "50px" }}>
            {detail?.data?.diastolic || "-"}
          </label>
          <label>{`${intl.formatMessage({ id: "bplusClinicKey673" })}`}</label>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <label style={{ fontWeight: "bold", width: "30%" }}>
            <FormattedMessage id="bplusClinicKey370" />
          </label>
          <label style={{ width: "50px" }}>{detail?.data?.pulse || "-"}</label>
          <label>{`${intl.formatMessage({ id: "bplusClinicKey674" })}`}</label>
        </div>
      </div>

      <div style={{ textAlign: "center", margin: "20px 0 15px" }}>
        <Header as="h2" style={{ color: detail?.data?.color || "black" }}>
          {detail?.data?.status === "Normal"
            ? `${intl.formatMessage({ id: "bplusClinicKey832" })}`
            : detail?.data?.status === "High blood pressure"
            ? `${intl.formatMessage({ id: "bplusClinicKey835" })}`
            : ""}
        </Header>
        {!detail?.data?.pass && (
          <div style={{ padding: "0 40px 20px", color: "#972323" }}>
            <FormattedMessage id="bplusClinicKey386" />
          </div>
        )}
      </div>

      <Form style={{ padding: "0 10px" }}>
        <Table data={BP_STATUS} columns={columnsBP} />
      </Form>
    </>
  );
};

type IconLabelProps = {
  icon: SemanticICONS | undefined;
  label: string;
};

const IconLabel = (props: IconLabelProps) => {
  return (
    <>
      <Icon name={props.icon} style={{ color: "#239cf3" }} />
      <label style={{ color: "#6f6f6f", margin: "0 15px 0 5px" }}>
        {props.label}
      </label>
    </>
  );
};

type TableProps = {
  data: any[];
  columns: any[];
};

const Table: React.FC<TableProps> = (props) => {
  const { columns, data } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({
    columns,
    data,
    // defaultPageSize: 5,
  });

  return (
    <table
      style={{
        borderRadius: "3px",
        borderSpacing: "0px",
        width: "100%",
        border: "1px solid #2b98e1",
      }}
      {...getTableProps()}
    >
      <thead
        style={{
          backgroundColor: "#a6d5f8",
          color: "#1d252b",
          boxShadow: "none",
        }}
      >
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th style={{ padding: "5px" }} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    style={{
                      padding: "7px 5px",
                      // fontWeight: "bold",
                      textAlign: "center",
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

type DMBloodPressureLineProps = {
  onEvent?: any;
  lineData?: any;
  setDetail: any;
  isMyTab?: boolean;
  bpFilterDuration?: string;
  detail: any;
  // config
  hideFooterChart?: boolean;
  height?: number;
  showTooltipChart?: boolean;
} & Pick<HistoryTabProps, "selectedPatient">;

const DMBloodPressureLineInitial: DMBloodPressureLineProps = {
  onEvent: () => {},
  lineData: [],
  setDetail: () => {},
  bpFilterDuration: "6",
  detail: {},
};

export const DMBloodPressureLine: React.FC<DMBloodPressureLineProps> = (
  props
) => {
  const intl = useIntl();
  const [bloodPressureLine, setBloodPressureLine] = useState<{
    data?: any;
    options?: any;
    getElementAtEvent?: any;
  }>({});
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>;
  const lineRef = useRef() as MutableRefObject<any>;

  const labelLimit = 7;

  /* ----------------------- Effect ----------------------- */
  useEffect(() => {
    setStartDate(moment().add(`-${props.bpFilterDuration}`, "days"));
    setEndDate(moment());
  }, [props.bpFilterDuration]);

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({
        message: "GetBloodPressureChart",
        params: {
          start_measure: startDate.format(BACKEND_DATE_FORMAT),
          end_measure: endDate.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, props.isMyTab, startDate]);

  useEffect(() => {
    setSkipIndex(0);
  }, [startDate]);

  // console.log("BloodPressureLine: ", bloodPressureLine);

  useEffect(() => {
    let labels: any[] = [];
    const sysDatas: any[] = [];
    const diaDatas: any[] = [];
    const pulseDatas: any[] = [];
    // let hosSysDatas: any[] = [];
    // let hosDiaDatas: any[] = [];
    // let hosPulseDatas: any[] = [];
    const realDatas: any[] = [];
    const pointStyle: any[] = [];
    const pointRadius: any[] = [];

    props.lineData?.items?.forEach((item: any) => {
      let i = 1;
      let label = `${formatDateByLanguage(moment(item?.measure_date, BACKEND_DATE_FORMAT)
        .format("DD/MM/YYYY"),"DD MMM")} (${i})`;
      while (labels.includes(label)) {
        i = i + 1;
        label = `${formatDateByLanguage(moment(item?.measure_date, BACKEND_DATE_FORMAT)
          // .locale("en")
          .format("DD/MM/YYYY"),"DD MMM")} (${i})`;
      }
      labels.push(label);
      const { systolic, diastolic, pulse } = item.data;
      if ([systolic, diastolic, pulse].some((item) => item)) {
        sysDatas.push(systolic ?? null);
        diaDatas.push(diastolic ?? null);
        pulseDatas.push(pulse ?? null);
        pointStyle.push("circle");
        pointRadius.push(props.showTooltipChart ? 5 : 4);
      } else {
        sysDatas.push(item.data?.hospital_systolic ?? null);
        diaDatas.push(item.data?.hospital_diastolic ?? null);
        pulseDatas.push(item.data?.hospital_pulse ?? null);
        pointStyle.push("triangle");
        pointRadius.push(6.5);
      }
      // hosSysDatas.push(item.data?.hospital_systolic ?? null);
      // hosDiaDatas.push(item.data?.hospital_diastolic ?? null);
      // hosPulseDatas.push(item.data?.hospital_pulse ?? null);
      realDatas.push({
        ...item,
      });
    });

    const concatDatas = [
      ...sysDatas,
      ...diaDatas,
      ...pulseDatas,
      // ...hosSysDatas,
      // ...hosDiaDatas,
      // ...hosPulseDatas,
    ].map(Number);

    setBloodPressureLine({
      data: {
        labels: labels,
        datasets: [
          {
            label: "Systolic",
            data: sysDatas,
            fill: false,
            backgroundColor: "#87502A",
            borderColor: "#87502A",
            yAxisID: "measured",
            pointRadius,
            realData: realDatas,
            spanGaps: true,
            borderWidth: 1,
            pointStyle,
            pointBorderColor: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? "rgba(0,0,0,.2)" : "";
            },
            pointBorderWidth: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? 3 : 1;
            },
            hoverBorderColor: "rgba(0,0,0,.2)",
            hoverBorderWidth: 3,
          },
          {
            label: "Diastolic",
            data: diaDatas,
            fill: false,
            backgroundColor: "#EF9614",
            borderColor: "#EF9614",
            yAxisID: "measured",
            pointRadius,
            spanGaps: true,
            borderWidth: 1,
            pointStyle,
            pointBorderColor: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? "rgba(0,0,0,.2)" : "";
            },
            pointBorderWidth: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? 3 : 1;
            },
            hoverBorderColor: "rgba(0,0,0,.2)",
            hoverBorderWidth: 3,
          },
          {
            label: "Pulse",
            data: pulseDatas,
            fill: false,
            backgroundColor: "#1C99F3",
            borderColor: "#1C99F3",
            yAxisID: "measured",
            pointRadius,
            spanGaps: true,
            borderWidth: 1,
            pointStyle,
            pointBorderColor: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? "rgba(0,0,0,.2)" : "";
            },
            pointBorderWidth: (context: any) => {
              const index = context.dataIndex;
              const raw = realDatas[index];
              return raw?.id === props.detail?.id ? 3 : 1;
            },
            hoverBorderColor: "rgba(0,0,0,.2)",
            hoverBorderWidth: 3,
          },
          // {
          //   label: "Hospital systolic",
          //   data: hosSysDatas,
          //   fill: false,
          //   backgroundColor: "#87502A",
          //   borderColor: "#87502A",
          //   yAxisID: "measured",
          //   pointRadius: 7,
          //   pointStyle: "triangle",
          //   spanGaps: true,
          //   realData: realDatas,
          //   borderWidth: 2,
          // },
          // {
          //   label: "Hospital diastolic",
          //   data: hosDiaDatas,
          //   fill: false,
          //   backgroundColor: "#EF9614",
          //   borderColor: "#EF9614",
          //   yAxisID: "measured",
          //   pointRadius: 7,
          //   pointStyle: "triangle",
          //   spanGaps: true,
          //   borderWidth: 2,
          // },
          // {
          //   label: "Hospital pulse",
          //   data: hosPulseDatas,
          //   fill: false,
          //   backgroundColor: "#1C99F3",
          //   borderColor: "#1C99F3",
          //   yAxisID: "measured",
          //   pointRadius: 7,
          //   pointStyle: "triangle",
          //   spanGaps: true,
          //   borderWidth: 2,
          // },
        ], //.filter((item) => (!props.hideFooterChart ? !item.pointStyle : true)),
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        interaction: {
          mode: "x",
        },
        tooltips: {
          ...(props.showTooltipChart
            ? {}
            : { enabled: false, mode: "x", intersect: false }),
          callbacks: {
            title: (tooltipItem: any[], data: any) => {
              const item = tooltipItem[0];
              const target = data.datasets[0].realData[item.index];

              return `${dateToStringWithoutTimeBE(
                target.measure_date
              )} ${target.time.slice(0, 5)}`;
            },
            label: (tooltipItem: any, data: any) => {
              const value: string[] =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              return [`- ${value}`];
            },
            labelColor(tooltipItem: any, chart: any) {
              const borderColor =
                chart.chart.config.data.datasets[tooltipItem.datasetIndex]
                  .borderColor;

              return {
                borderColor: borderColor,
                backgroundColor: borderColor,
              };
            },
            afterLabel: (tooltipItem: any, data: any) => {
              const target = data.datasets[0].realData[tooltipItem.index];

              return [`- ${target.data.status}`];
            },
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                max: Number.isFinite(Math.max(...concatDatas))
                  ? Math.max(...concatDatas) + 15
                  : 200,
                min: Number.isFinite(Math.min(...concatDatas) - 10)
                  ? Math.min(...concatDatas) - 10
                  : 0,
                stepSize: 40,
                fontColor: "#b1b3b2",
                fontSize: 10,
              },
              type: "linear",
              display: true,
              position: "left",
              id: "measured",
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                callback: labelNewLine,
                fontColor: "#404040",
                fontStyle: "bold",
                fontSize: 10,
              },
            },
          ],
        },
        onClick: function (event: any, element: any) {
          if (element.length > 0) {
            const index = element[0]._index;
            props.setDetail(realDatas[index]);
          }
        },
      },
    });

    const findDetail = realDatas.find((item) => item.id === props.detail?.id);
    if (!findDetail) {
      props.setDetail(realDatas.length > 0 ? realDatas.slice(-1)[0] : null);
    }
  }, [props.lineData, props.detail]);

  /* ------------------------ Memo ------------------------ */
  const durationOptions = useMemo(
    () => [
      {
        key: "4",
        value: "4",
        text: `5 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "6",
        value: "6",
        text: `7 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "9",
        value: "9",
        text: `10 ${intl.formatMessage({ id: "profile.days" })}`,
      },
    ],
    []
  );

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;
        },
        afterDatasetsDraw: (chart: any) => {
          if (props.hideFooterChart) {
            drawStarChart({
              chart,
              allowIndex: [0],
              noteField: "realData.data",
            });
          }
        },
        beforeEvent: (chart: any, e: any) => {
          if (props.hideFooterChart) {
            // displayNote(chart, e);
            displayNoteChart({
              chart,
              event: e,
              allowIndex: [0],
              noteField: "realData.data",
              tooltipRef: tooltipRef.current,
            });
          }
        },
      },
    ];
  }, []);

  const subChartData = useMemo(() => {
    if (props.hideFooterChart) {
      return bloodPressureLine.data || {};
    }
    return spliceChartData({
      data: bloodPressureLine.data || {},
      index: skipIndex,
      limit: labelLimit,
    });
  }, [bloodPressureLine.data, skipIndex]);

  /* ----------------------- Handle ----------------------- */
  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    props.onEvent({
      message: "handleChangeBpFilterDuration",
      params: { duration: value },
    });
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${props.bpFilterDuration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(props.bpFilterDuration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <div>
      <HeaderChart
        yAxesLabelLeft="mmHg"
        yAxesLabelRight=""
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={props.bpFilterDuration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        labelStyle={{ padding: "10px 8px 5px 8px" }}
        legendStyle={
          props.hideFooterChart
            ? { gridTemplateColumns: "repeat(2,130px)" }
            : { gridTemplateColumns: "unset" }
        }
        legendItem={{
          type: "line",
          items: [
            {
              name: "SYS (mmHg)",
              color: "#87502A",
            },
            {
              name: "Hospital monitor",
              color: "#A3A3A3",
              type: "triangle" as "triangle",
            },
            {
              name: "DIA (mmHg)",
              color: "#EF9614",
            },
            {
              name: "Self monitor",
              color: "#A3A3A3",
              type: "ellipse" as "ellipse",
            },
            {
              name: "PULSE (BPM)",
              color: "#1C99F3",
            },
            {
              name: "Patient’s note",
              color: "#EE1C24",
              type: "star" as "star",
            },
          ].filter((item) => (!props.hideFooterChart ? !item.type : true)),
        }}
      />

      <div style={{ position: "relative" }}>
        <Line
          ref={lineRef}
          data={isSeeAll ? bloodPressureLine.data || {} : subChartData}
          options={bloodPressureLine.options}
          height={props.height ?? 180}
          plugins={plugins}
        />
        <div ref={tooltipRef} className="tooltip-blood-pressure"></div>
      </div>

      {!props.hideFooterChart && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            bloodPressureLine.data?.labels?.slice(labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
          style={{ marginBottom: "20px" }}
        />
      )}
    </div>
  );
};

DMBloodPressure.defaultProps = DMBloodPressureInitial;

export default React.memo(DMBloodPressure);
