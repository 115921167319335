import { WasmHandler } from "react-lib/frameworks/WasmController";
import { listPatientDataList } from "../ManageInterface";

export type State = {
  // Weight
  patientWeightList?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  hospitalWeightList?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  weightData?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  }
  // Medication
  medicationCurrentList?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  medicationTrackcare?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  // Blood
  patientData?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  bloodPresusreData?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  // Glucose
  glucoseData?:{
    items: any[];
    next: string;
    previous: string;
    total: number;
  };
  // Foot
  footData?: {
    items: any[];
    next: string;
    previous: string;
    total: number;
  }
  // medicationCurrentList?: {
  //   items: any[];
  //   next: string;
  //   previous: string;
  //   total: number;
  // };
  // medicationTrackcare?: {
  //   items: any[];
  //   next: string;
  //   previous: string;
  //   total: number;
  // };
};

export const StateInitial = {
  // Weight
  patientWeightList: { items: [], next: null, previous: null, total: 0 },
  hospitalWeightList: { items: [], next: null, previous: null, total: 0 },
  // Medication
  // medicationCurrentList: {
  //   //Todo: remove
  //   next: null,
  //   previous: null,
  //   total: 2,
  //   items: [
  //     {
  //       id: 1,
  //       order_date: "21/01/2021",
  //       drug_name:
  //         "Tylenol 500 mg Tablet Take 1 tablet prn Every 4 Hours When have  Fever or Pain",
  //       clinic: "อายุรกรรมทั่วไป",
  //       doctor: "นพ. สามารถ เก่งรักษา",
  //       active: true,
  //     },
  //     {
  //       id: 2,
  //       order_date: "21/01/2021",
  //       drug_name:
  //         "Merislon 8 mg Tablet Take 1 tablet 3 Times Daily After Breakfast Lunch and Dinner",
  //       clinic: "อายุรกรรมทั่วไป",
  //       doctor: "นพ. สามารถ เก่งรักษา",
  //       active: true,
  //     },
  //   ],
  // },
  // medicationTrackcare: {
  //   //Todo: remove
  //   next: null,
  //   previous: null,
  //   total: 2,
  //   items: [
  //     {
  //       id: 1,
  //       order_date: "21/01/2021",
  //       drug_name:
  //         "Tylenol 500 mg Tablet Take 1 tablet prn Every 4 Hours When have  Fever or Pain",
  //       clinic: "อายุรกรรมทั่วไป",
  //       doctor: "นพ. สามารถ เก่งรักษา",
  //       active: true,
  //     },
  //     {
  //       id: 2,
  //       order_date: "21/01/2021",
  //       drug_name:
  //         "Merislon 8 mg Tablet Take 1 tablet 3 Times Daily After Breakfast Lunch and Dinner",
  //       clinic: "อายุรกรรมทั่วไป",
  //       doctor: "นพ. สามารถ เก่งรักษา",
  //       active: false,
  //     },
  //   ],
  // },
};

export type Event =
  // weight
  { message: "handleGetDMTabWeight"; params: { start: string; end: string } }
  // mediction
  | { message: "handleAddMedicationCurrent"; params: {} }
  | { message: "handleDeleteMedicationCurrent"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State, Event, Data>;

export const handleGetDMTabWeight: Handler = async (controller, params) => {
  const [error, response] = await listPatientDataList(controller, {
    data_type: "weight",
    start: params.start,
    end: params.end,
  });
  if (error) {
    controller.setState({
      patientWeightList: {},
      errorMessage: error,
      successMessage: null,
    });
  } else if (response.items.length > 0) {
    controller.setState({
      patientWeightList: response,
      errorMessage: null,
      successMessage: null,
    });
  }
};

export const handleAddMedicationCurrent: Handler = async (
  controller,
  params
) => {
  //Todo: remove
  const state = controller.getState();
  let medicationCurrentList: any = { ...state.medicationCurrentList };
  medicationCurrentList.items.push({
    ...params,
    id: medicationCurrentList.items.length + 1,
    active: true,
  });
  controller.setState({ medicationCurrentList: medicationCurrentList });
};

export const handleDeleteMedicationCurrent: Handler = async (
  controller,
  params
) => {
  //Todo: remove
  const state = controller.getState();

  let result: any[] = [...params];
  result?.forEach((item: any, index: number) => {
    if (!item.active) {
      result.splice(index, 1);
    }
  });
  controller.setState({
    medicationCurrentList: { ...state.medicationCurrentList, items: result },
  });
};
