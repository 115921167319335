import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Segment,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Card, CardContent } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import * as DrugDMConst from "./DMDrug";

type DMDrugAddDrugManuallyProps = {
  onEvent: any;
  handleAddNewDrug: any;
  mealOptions: any;
  medTimeOptions: any;
  unitOptions: any;
  drugManualList: any;
  selectedPatient: any;
};

const DMDrugAddDrugManuallyInitial: DMDrugAddDrugManuallyProps = {
  onEvent: () => null,
  handleAddNewDrug: null,
  mealOptions: { items: [] },
  medTimeOptions: { items: [] },
  unitOptions: { items: [] },
  drugManualList: {},
  selectedPatient: {},
};

const DMDrugAddDrugManually: React.FC<DMDrugAddDrugManuallyProps> = (props) => {
  const intl = useIntl();
  const [mealOptions, setMealOptions] = useState([]);
  const [medTimeOptions, setMedTimeOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [previewImage, setPreviewImage] = useState<any>();
  const inputRef = useRef<any>();
  const [drugManual, setDrugManual] = useState({
    image: null as ArrayBuffer | string | null,
    drug_name: "",
    dosage: "",
    unit: "",
    administration_time: [{ time: "", meal: "" }],
    status: "",
  });

  useEffect(() => {
    let options: any = [];
    props.mealOptions?.items?.forEach((item: any, index: number) => {
      options.push({
        key: index,
        text: 
          item.name === "Before meal" ? `${intl.formatMessage({ id: "bplusClinicKey84" })}` : 
          item.name === "After meal" ? `${intl.formatMessage({ id: "bplusClinicKey53" })}` : "",
        value: item.id,
      });
    });
    setMealOptions(options);
  }, [props.mealOptions]);

  useEffect(() => {
    let options: any = [];
    props.medTimeOptions?.items?.forEach((item: any, index: number) => {
      options.push({
        key: index,
        text:
          item.name === "Morning" ? `${intl.formatMessage({ id: "bplusClinicKey329" })}` : 
          item.name === "Afternoon" ? `${intl.formatMessage({ id: "bplusClinicKey708" })}` : 
          item.name === "Evening" ? `${intl.formatMessage({ id: "bplusClinicKey190" })}` : 
          item.name === "Bed time" ? `${intl.formatMessage({ id: "bplusClinicKey81" })}` : "",
        value: item.id,
      });
    });
    setMedTimeOptions(options);
  }, [props.medTimeOptions]);

  useEffect(() => {
    let options: any = [];
    props.unitOptions?.items?.forEach((item: any, index: number) => {
      options.push({
        key: index,
        text: 
          item.name === "Tablet" ? `${intl.formatMessage({ id: "bplusClinicKey715" })}` : 
          item.name === "Unit" ? `${intl.formatMessage({ id: "bplusClinicKey503" })}` : 
          item.name === "mg" ? `${intl.formatMessage({ id: "bplusClinicKey573" })}` : "",
        value: item.id,
      });
    });
    setUnitOptions(options);
  }, [props.unitOptions]);

  useEffect(() => {
    props.onEvent({ message: "handleGetDrugManual", params: {} });
  }, [props.selectedPatient]);

  const handleChangeDrugManual = (event: any, data: any) => {
    setDrugManual({ ...drugManual, [data.name]: data.value });
  };

  const handleChangeAdministrationTime = (name: string, value: any) => {
    setDrugManual({ ...drugManual, [name]: value });
  };

  const handleAddManualDrug = () => {
    props.onEvent({ message: "handleAddManualDrug", params: drugManual });
    props.handleAddNewDrug(true);
    handleImageCancel();
    setDrugManual({
      image: null,
      drug_name: "",
      dosage: "",
      unit: "",
      administration_time: [{ time: "", meal: "" }],
      status: "",
    });
  };

  const previewFile = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setDrugManual({ ...drugManual, image: reader.result });
    };
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      previewFile(file);
    }
  };

  const handleImageCancel = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setDrugManual({
      ...drugManual,
      image: "",
    });
    setPreviewImage("");
  };

  console.log("dm drug add drug manually: ", drugManual);

  return (
    <Form size="small" style={{ padding: "60px 10px 35px" }}>
      <Header as="h4">
        <FormattedMessage id="bplusClinicKey167" />
      </Header>
      <Input
        fluid
        name="drug_name"
        value={drugManual.drug_name}
        onChange={handleChangeDrugManual}
      />

      <Grid columns={3} style={{ paddingTop: "20px" }}>
        <Grid.Column width={4} style={{ paddingRight: "0px" }}>
          <Header as="h5">
            <FormattedMessage id="bplusClinicKey162" />
          </Header>
          <Input
            type="number"
            fluid
            name="dosage"
            value={drugManual.dosage}
            onChange={handleChangeDrugManual}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Header as="h5">
            <FormattedMessage id="bplusClinicKey503" />
          </Header>
          <Dropdown
            style={{ width: "120px" }}
            selection
            // search
            compact
            options={unitOptions}
            name="unit"
            value={drugManual.unit}
            onChange={handleChangeDrugManual}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment style={{ height: "100%" }}>
            <Card>
              <CardContent
                style={{
                  height: "100px",
                  padding: 0,
                }}
              >
                {!previewImage ? (
                  <>
                    <label
                      htmlFor={`file-drug`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "inherit",
                      }}
                    >
                      <Icon name="camera" size="big" />{" "}
                      <FormattedMessage id="bplusClinicKey376" />
                      <input
                        ref={inputRef}
                        type="file"
                        id={`file-drug`}
                        onChange={handleFileInputChange}
                      />
                    </label>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        display: "flex",
                      }}
                    >
                      <img
                        style={DrugDMConst.styles.imageContain}
                        id={`fileField`}
                        src={previewImage}
                        alt="chosen"
                        // onClick={() => {
                        //   setOpenModal(true);
                        // }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "-6px",
                          right: "-4px",
                          color: "#EB5757",
                          borderRadius: "35px",
                          height: "35px",
                        }}
                      >
                        <CancelIcon
                          style={{ fontSize: "35px" }}
                          onClick={handleImageCancel}
                        />
                      </div>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </Segment>
        </Grid.Column>
      </Grid>

      <Header as="h5">
        <FormattedMessage id="bplusClinicKey51" />
      </Header>
      <Grid verticalAlign="middle">
        <Grid.Row columns={2}>
          {drugManual.administration_time.map((item: any, aIndex: number) => {
            return (
              <>
                <Grid.Column width={6} style={{ paddingBottom: "0px" }}>
                  <Dropdown
                    style={{ width: "140px" }}
                    selection
                    // search
                    compact
                    options={mealOptions}
                    name="meal"
                    value={item.meal}
                    onChange={(event: any, data: any) => {
                      let result: any[] = [...drugManual.administration_time];
                      result[aIndex][data.name] = data.value;
                      handleChangeAdministrationTime(
                        "administration_time",
                        result
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={6} style={{ paddingBottom: "0px" }}>
                  <Dropdown
                    style={{ width: "140px" }}
                    selection
                    // search
                    compact
                    options={medTimeOptions}
                    name="time"
                    value={item.time}
                    onChange={(event: any, data: any) => {
                      let result: any[] = [...drugManual.administration_time];
                      result[aIndex][data.name] = data.value;
                      handleChangeAdministrationTime(
                        "administration_time",
                        result
                      );
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Button
                    icon="minus"
                    size="mini"
                    color="red"
                    onClick={() => {
                      let result: any[] = [...drugManual.administration_time];
                      result.splice(aIndex, 1);
                      handleChangeAdministrationTime(
                        "administration_time",
                        result
                      );
                    }}
                  />
                </Grid.Column>
              </>
            );
          })}
          <Grid.Column width={2} style={DrugDMConst.styles.noPadding}>
            <Button
              icon="plus"
              size="mini"
              color="orange"
              onClick={() => {
                handleChangeAdministrationTime("administration_time", [
                  ...drugManual.administration_time,
                  { time: "", meal: "" },
                ]);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Column width={4}></Grid.Column>
        <Grid.Column width={8}>
          <Button fluid color="blue" onClick={handleAddManualDrug}>
            <FormattedMessage id="bplusClinicKey119" />
          </Button>
        </Grid.Column>
        <Grid.Column width={4}></Grid.Column>
      </Grid>

      <Header as="h5" style={{ color: DrugDMConst.COLOR_CODE.cyan }}>
        <FormattedMessage id="bplusClinicKey316" />
      </Header>
      <div style={{ marginBottom: "50px" }}>
        {props.drugManualList?.items?.map((item: any, dIndex: number) => {
          return (
            <Grid columns={3}>
              <Grid.Column width={3} style={{ padding: "5px 0px 0px 10px" }}>
                <Image src={item.image} />
              </Grid.Column>
              <Grid.Column
                width={7}
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                {item.drug_name}
              </Grid.Column>
              <Grid.Column width={6} style={{ padding: "15px 0px" }}>
                {item.status === "start" ? (
                  <Button
                    color="orange"
                    size="mini"
                    style={{ width: "60px" }}
                    onClick={() => {
                      props.onEvent({
                        message: "handleActionManualDrug",
                        params: { index: dIndex, status: "stop" },
                      });
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey471" />
                  </Button>
                ) : (
                  <Button
                    color="green"
                    size="mini"
                    style={{ width: "60px" }}
                    onClick={() => {
                      props.onEvent({
                        message: "handleActionManualDrug",
                        params: { index: dIndex, status: "start" },
                      });
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey467" />
                  </Button>
                )}
                <Button
                  color="red"
                  size="mini"
                  style={{ width: "70px" }}
                  onClick={() => {
                    props.onEvent({
                      message: "handleActionManualDrug",
                      params: { index: dIndex, status: "delete" },
                    });
                    props.handleAddNewDrug(true);
                  }}
                >
                  <FormattedMessage id="bplusClinicKey153" />
                </Button>
              </Grid.Column>
            </Grid>
          );
        })}
      </div>
    </Form>
  );
};

DMDrugAddDrugManually.defaultProps = DMDrugAddDrugManuallyInitial;

export default React.memo(DMDrugAddDrugManually);
