import React, {
  useMemo,
  CSSProperties,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  MutableRefObject,
} from "react";
import moment from "moment";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";

// CSS
import { AppBar, Button as MuiButton, makeStyles } from "@material-ui/core";
import {
  Dimmer,
  Dropdown,
  Loader,
  Menu,
  Tab,
  TabProps,
} from "semantic-ui-react";

import CancelIcon from "@material-ui/icons/Cancel";
import SwipeableViews from "react-swipeable-views";
import ChildDevelopmentHistoryTab from "./ChildDevelopmentHistoryTab";

// Common
import MobHead from "../MobHead";
import SnackMessage from "./SnackMessage";
import { DateTextBox } from "react-lib/apps/common";

// Interface
import {
  BACKEND_DATE_FORMAT,
  Event as MobEvent,
  convertToADDate,
  formatDate,
  formatDateByLanguage,
} from "../MobClinicInterface";

// UI
import PatientPanel from "./PatientPanel";

import "../../css/WebClinic.scss";

const DATE_FORMAT = "DD/MM/YYYY";

type ChildDevelopmentProps = {
  // function
  onEvent: (e: MobEvent) => any;
  onSetBackToApp?: any;
  // data
  errorMessage?: any;
  successMessage?: any;
  loadingStatus?: boolean;
  selectedPatient?: any;
  developmentAgeRangeOptions: any[];
  developmentAgeRange: any[];
  developmentDetail?: any;
  developmentHistory?: any[];
};

type DetailsTabProps = {
  onSetViewHeight: (height: number) => any;
} & ChildDevelopmentProps;

type SkillMenuProps = {
  image_url: string;
  title: string;
  active?: boolean;
  onClick?: () => any;
  titleStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  style?: CSSProperties;
  disabled?: boolean;
};

export const COLOR = {
  blue: "#33a0e7",
};

export const useStyles = makeStyles((theme) => ({
  skill_menu: {
    padding: "20px 0 10px 0",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    rowGap: "7px",
  },
  skill_menu_item: {
    display: "flex",
    margin: "0 4px",
    backgroundColor: "var(--theme-bg-color-dm)",
    alignItems: "center",
    padding: "3px",
    height: "81px",
  },
  box_image: {
    border: `1px solid grey`,
    width: "100%",
    height: "117px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  card_skill: {
    borderRadius: "5px",
    backgroundColor: "var(--theme-bg-color-dm)",
    marginBottom: "15px",
    padding: "10px 15px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  button_check: {
    color: "var(--text-dark-blue)",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid var(--text-dark-blue)",
  },
  box_date: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "5px",
  },
  card_summary_header: {
    backgroundColor: "var(--theme-bg-color-dm)",
    display: "flex",
    color: "var(--text-dark-blue)",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
  },
  badge: {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  custom_date: {
    "& .ui.input": {
      opacity: 1,
    },
  },
}));

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
};

const SkillMenu: React.FunctionComponent<SkillMenuProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant="text"
      color="primary"
      style={{
        textAlign: "left",
        textTransform: "unset",
        width: "100%",
        padding: "0",
        ...(props.style || {}),
      }}
      onClick={() => props.onClick?.()}
      disabled={props.disabled}
    >
      <div
        className={classes.skill_menu_item}
        style={
          !!props.active
            ? {
                border: "4px solid var(--text-dark-blue)",
                background: "#92D4FF",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }
            : props.boxStyle
        }
      >
        <img
          style={{
            width: "75px",
            height: "79px",
            objectFit: "none",
            ...(props.imageStyle || {}),
          }}
          src={props.image_url}
          alt={props.image_url}
        />
        <div
          style={{
            fontSize: "17px",
            fontWeight: "bold",
            color: "var(--text-grey)",
            ...(props.titleStyle || {}),
          }}
        >
          {props.title}
        </div>
      </div>
    </MuiButton>
  );
};

// export const BarChart: React.FunctionComponent<BarChartProps> = (props) => {
//   const [labelList, setLabelList] = useState([2, 4, 6]);

//   // Chart options
//   const options = useMemo(() => {
//     const defaults = defaultOptions;

//     defaults.tooltips = {
//       // mode: "single",
//       callbacks: {
//         // title: (tooltipItem: any[], data: any) => {
//         //   return data["labels"][tooltipItem[0]["index"]];
//         // },
//         // label: (tooltipItem: any, data: any) => {
//         //   props.onClickLegends?.(tooltipItem, data);
//         //   const type: keyof ExerciseTypeDetail =
//         //     data["datasets"][tooltipItem.datasetIndex].rawData[
//         //       tooltipItem.index
//         //     ].type;
//         //   // return [`- 19/02/2564, ${EXERCISE_TYPE_DETAIL[type].text}, 08:30`]
//         //   return [`${EXERCISE_TYPE_DETAIL[type].text}`];
//         // },
//       },
//     };
//     return defaults;
//   }, []);

//   const chartData = useMemo(() => {
//     let labelList: any[] = [];
//     let dataSkills: any = {
//       [DEVELOPMENT_SKILL.LANGUAGE.key]: [],
//       [DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key]: [],
//       [DEVELOPMENT_SKILL.FINE_MOTOR.key]: [],
//       [DEVELOPMENT_SKILL.GROSS_MOTOR.key]: [],
//     };
//     let dataTotal: any[] = [];

//     props.developmentHistory?.forEach((history: any) => {
//       labelList.push(history?.age_range_name);
//       let dataLang: number = 0;
//       let dataEmo: number = 0;
//       let dataFine: number = 0;
//       let dataGross: number = 0;
//       history?.items?.forEach((item: any) => {
//         if (item?.skill === DEVELOPMENT_SKILL.LANGUAGE.key) {
//           dataLang = item?.skill_score;
//         } else if (item?.skill === DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key) {
//           dataEmo = item?.skill_score;
//         } else if (item?.skill === DEVELOPMENT_SKILL.FINE_MOTOR.key) {
//           dataFine = item?.skill_score;
//         } else if (item?.skill === DEVELOPMENT_SKILL.GROSS_MOTOR.key) {
//           dataGross = item?.skill_score;
//         }
//       });
//       dataSkills[DEVELOPMENT_SKILL.LANGUAGE.key].push(dataLang);
//       dataSkills[DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key].push(dataEmo);
//       dataSkills[DEVELOPMENT_SKILL.FINE_MOTOR.key].push(dataFine);
//       dataSkills[DEVELOPMENT_SKILL.GROSS_MOTOR.key].push(dataGross);
//       dataTotal.push(calculateChildDevelopmentTotalV2(history?.items));
//     });
//     setLabelList(labelList);

//     return {
//       labels: labelList,
//       datasets: [
//         {
//           label: DEVELOPMENT_SKILL.LANGUAGE.label,
//           data: dataSkills[DEVELOPMENT_SKILL.LANGUAGE.key],
//           backgroundColor: DEVELOPMENT_SKILL.LANGUAGE.color,
//           barThickness: 18,
//         },
//         {
//           label: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.label,
//           data: dataSkills[DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key],
//           backgroundColor: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.color,
//           barThickness: 18,
//         },
//         {
//           label: DEVELOPMENT_SKILL.FINE_MOTOR.label,
//           data: dataSkills[DEVELOPMENT_SKILL.FINE_MOTOR.key],
//           backgroundColor: DEVELOPMENT_SKILL.FINE_MOTOR.color,
//           barThickness: 18,
//         },
//         {
//           label: DEVELOPMENT_SKILL.GROSS_MOTOR.label,
//           data: dataSkills[DEVELOPMENT_SKILL.GROSS_MOTOR.key],
//           backgroundColor: DEVELOPMENT_SKILL.GROSS_MOTOR.color,
//           barThickness: 18,
//         },
//         {
//           label: "สรุปรวม",
//           data: dataTotal,
//           backgroundColor: COLOR.blue,
//           barThickness: 18,
//         },
//       ],
//     };
//   }, [props.developmentHistory]);

//   const handleClick = (type: "next" | "prev") => {
//     let cloneArray = [...labelList];
//     if (type === "next") {
//       cloneArray = cloneArray.map((value) => value + 2);
//     } else if (cloneArray[0] !== 2) {
//       cloneArray = cloneArray.map((value) => value - 2);
//     }
//     setLabelList(cloneArray);
//   };

//   return (
//     <>
//       <HeaderChart
//         hideDateRange
//         legendStyle={{
//           gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
//         }}
//         legendItem={{
//           type: "rect",
//           items: [
//             {
//               name: DEVELOPMENT_SKILL.LANGUAGE.label,
//               color: DEVELOPMENT_SKILL.LANGUAGE.color,
//             },
//             {
//               name: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.label,
//               color: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.color,
//             },
//             {
//               name: DEVELOPMENT_SKILL.FINE_MOTOR.label,
//               color: DEVELOPMENT_SKILL.FINE_MOTOR.color,
//             },
//             {
//               name: DEVELOPMENT_SKILL.GROSS_MOTOR.label,
//               color: DEVELOPMENT_SKILL.GROSS_MOTOR.color,
//             },
//             { name: "สรุปผล", color: COLOR.blue },
//           ],
//         }}
//       />
//       <div style={{ marginLeft: "-20px" }}>
//         <Bar data={chartData} options={options} height={props.height} />
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           margin: "-5px 0 30px",
//         }}
//       >
//         <div>
//           <MuiButton
//             variant="outlined"
//             style={{ padding: "8px", borderRadius: "4px 0 0 4px" }}
//             onClick={() => handleClick("prev")}
//           >
//             <svg
//               width="12"
//               height="7"
//               viewBox="0 0 12 7"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M2.21094 3.30273L5.66797 4.61523V6.03906L0.763672 3.85352V2.72266L5.66797 0.542969V1.9668L2.21094 3.30273ZM8.31641 3.30273L11.7734 4.61523V6.03906L6.86914 3.85352V2.72266L11.7734 0.542969V1.9668L8.31641 3.30273Z"
//                 fill="#A3A3A3"
//               />
//             </svg>
//           </MuiButton>
//         </div>
//         <div>
//           <MuiButton
//             variant="outlined"
//             style={{ padding: "8px", borderRadius: "0 4px 4px 0" }}
//             onClick={() => handleClick("next")}
//           >
//             <svg
//               width="12"
//               height="7"
//               viewBox="0 0 12 7"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M3.625 3.27344L0.00390625 1.94336V0.542969L5.06641 2.72266V3.85352L0.00390625 6.0332V4.63281L3.625 3.27344ZM9.87109 3.27344L6.25 1.94336V0.542969L11.3125 2.72266V3.85352L6.25 6.0332V4.63281L9.87109 3.27344Z"
//                 fill="#A3A3A3"
//               />
//             </svg>
//           </MuiButton>
//         </div>
//       </div>
//     </>
//   );
// };

const DetailsTab: React.FunctionComponent<DetailsTabProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const inputRef = useRef<any>();
  const [ageRange, setAgeRange] = useState<number>(0);
  const [menuIndex, setMenuIndex] = useState<number>(0);
  const [activeUpload, setActiveUpload] = useState<number | null>(null);
  const [selectedSkill, setSelectedSkill] = useState<any>({});

  useEffect(() => {
    props.onEvent({ message: "HandleGetDevelopmenAgeRange" });
  }, []);

  useEffect(() => {
    if (props.developmentAgeRangeOptions.length > 0) {
      setAgeRange(props.developmentAgeRangeOptions[0]?.value);
    }
  }, [props.developmentAgeRangeOptions]);

  const ageRangeOptions = useMemo(() => {
    let items: any[] = [];
    props.developmentAgeRangeOptions?.forEach((history: any, index: number) => {
      items.push({
        key: index,
        text:
            props.developmentAgeRangeOptions[index]?.text === "2 month"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "4 month"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text=== "6 month"
            ? `6 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "9 month"
            ? `9 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "12 month"
            ? `12 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "15 month"
            ? `15 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "18 month"
            ? `18 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : props.developmentAgeRangeOptions[index]?.text === "2 year"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : props.developmentAgeRangeOptions[index]?.text === "2.5 year"
            ? `2.5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : props.developmentAgeRangeOptions[index]?.text === "3 year"
            ? `3 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : props.developmentAgeRangeOptions[index]?.text === "4 year"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : props.developmentAgeRangeOptions[index]?.text === "5 year"
            ? `5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : "",
        value: props.developmentAgeRangeOptions[index]?.value,
      });
    });
    return items;
  }, [props.developmentAgeRangeOptions]);

  useEffect(() => {
    if (ageRange) {
      props.onEvent({
        message: "HandleGetDevelopmenDetail",
        params: { age_range: ageRange },
      });
    }
  }, [ageRange]);

  useEffect(() => {
    setSelectedSkill(props.developmentDetail?.data?.items?.[menuIndex] || {});
  }, [props.developmentDetail, menuIndex]);

  const handleChangeAgeRange = (_e: any, data: any) => {
    setAgeRange(data.value);
    setMenuIndex(0);
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    console.log("handleChange file: ", file);
    if (file) {
      props.onEvent({
        message: "HandleCreateUpdateImage",
        params: {
          developmentId: selectedSkill.developmentId,
          index: activeUpload || 0,
          file,
        },
      });
      inputRef.current.value = "";
      setActiveUpload(null);
    }
  };

  const handleOnRemoveImage = (index: number) => {
    props.onEvent({
      message: "HandleDeleteImage",
      params: {
        developmentId: selectedSkill.developmentId,
        index: index,
      },
    });
    inputRef.current.value = "";
    setActiveUpload(null);
  };

  const handleAddFile = (index: number) => {
    inputRef.current.click();
    console.log("add file 555");
    setActiveUpload(index);
  };

  return (
    <div
      style={{ padding: "15px" }}
      ref={(instance: any) => {
        if (instance) {
          props.onSetViewHeight(instance.offsetHeight);
        }
      }}
    >
      <Dropdown
        value={ageRange}
        options={ageRangeOptions}
        selection
        style={{
          backgroundColor: "var(--theme-bg-color-dm)",
          color: "var(--text-dark-blue)",
        }}
        onChange={handleChangeAgeRange}
      />

      {/* Skill Menu */}
      <div className={classes.skill_menu}>
        {props.developmentDetail?.data?.items?.map(
          (item: any, index: number) => (
            <SkillMenu
              key={index}
              image_url={`/images/child/development_${item?.skill}.png`}
              title={
                item?.skill_label === "การสื่อสาร" ? `${intl.formatMessage({ id: "bplusClinicKey799" })}` :
                item?.skill_label === "อารมณ์ และสังคม" ? `${intl.formatMessage({ id: "bplusClinicKey800" })}` :
                item?.skill_label === "กล้ามเนื้อมัดเล็ก" ? `${intl.formatMessage({ id: "bplusClinicKey801" })}` :
                item?.skill_label === "กล้ามเนื้อมัดใหญ่" ? `${intl.formatMessage({ id: "bplusClinicKey802" })}` : ""
              }
              active={item?.developmentId === selectedSkill?.developmentId}
              onClick={() => setMenuIndex(index)}
            />
          )
        )}
      </div>

      {/* Content */}
      {props.developmentDetail ? (
        <div
          style={{
            border: `2px solid ${COLOR.blue}`,
            borderRadius: "30px",
            padding: "5px 10px 15px 10px",
            marginTop: "5px",
          }}
        >
          <SkillMenu
            image_url={`/images/child/development_${selectedSkill?.skill}.png`}
            title={
              selectedSkill?.skill_label === "การสื่อสาร" ? `${intl.formatMessage({ id: "bplusClinicKey799" })}` :
              selectedSkill?.skill_label === "อารมณ์ และสังคม" ? `${intl.formatMessage({ id: "bplusClinicKey800" })}` :
              selectedSkill?.skill_label === "กล้ามเนื้อมัดเล็ก" ? `${intl.formatMessage({ id: "bplusClinicKey801" })}` :
              selectedSkill?.skill_label === "กล้ามเนื้อมัดใหญ่" ? `${intl.formatMessage({ id: "bplusClinicKey802" })}` : ""
            }
            titleStyle={{ color: "var(--text-dark-blue)", fontSize: "22px" }}
            boxStyle={{
              justifyContent: "center",
              height: "99px",
              background: "unset",
            }}
            imageStyle={{ objectFit: "contain" }}
            style={{ justifyContent: "center" }}
            disabled
          />

          {/* Card */}
          {selectedSkill?.data?.method?.map((method: any, index: number) => (
            <div key={index} className={classes.card_skill}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "var(--text-dark-blue)",
                  marginBottom: "10px",
                }}
              >
                {method?.name}
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "60% 40%" }}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <MuiButton
                      className={classes.button_check}
                      variant="contained"
                      size="large"
                      onClick={() => {
                        let result: any = { ...props.developmentDetail };
                        result?.data?.items?.forEach((item: any) => {
                          if (
                            item?.developmentId === selectedSkill?.developmentId
                          ) {
                            item.data.method[index].checked = true;
                            item.data.method[index].date =
                              moment().format(BACKEND_DATE_FORMAT);
                          }
                        });
                        result.getHistory = false;

                        props.onEvent({
                          message: "HandleCreateUpdateDevelopment",
                          params: result,
                        });
                      }}
                    >
                      <FormattedMessage id="bplusClinicKey110" />
                    </MuiButton>
                  </div>

                  <div className={classes.box_date}>
                    <div style={{ color: "var(--text-dark-blue)" }}>
                      <FormattedMessage id="common.date" />
                    </div>
                    <DateTextBox
                      inputClassNames={classes.custom_date}
                      style={{
                        transform: "scale(0.8)",
                        width: "83%",
                        zIndex: selectedSkill.data.method.length - index,
                      }}
                      inputStyle={{ width: "100%" }}
                      value={
                        method?.date
                          ? formatDateByLanguage(
                              moment(method?.date, BACKEND_DATE_FORMAT)?.format(
                                DATE_FORMAT
                              )
                            )
                          : ""
                      }
                      maxDate={formatDateByLanguage(moment().format(DATE_FORMAT))}
                      onChange={(date: string) => {
                        let result: any = { ...props.developmentDetail };
                        result?.data?.items?.forEach((item: any) => {
                          if (
                            item?.developmentId === selectedSkill?.developmentId
                          ) {
                            item.data.method[index].checked = true;
                            item.data.method[index].date = moment(
                              convertToADDate(date),
                              DATE_FORMAT
                            ).format(BACKEND_DATE_FORMAT);
                            result.getHistory = false;

                            props.onEvent({
                              message: "HandleCreateUpdateDevelopment",
                              params: result,
                            });
                            return;
                          }
                        });
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <label
                    className={classes.box_image}
                    onClick={() => handleAddFile(index)}
                  >
                    {method?.image ? (
                      <img
                        src={method?.image}
                        alt={"image-" + index}
                        style={{ height: "inherit", maxWidth: "100%" }}
                      />
                    ) : (
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.5 25V4C35.5 2.075 33.925 0.5 32 0.5H11C9.075 0.5 7.5 2.075 7.5 4V25C7.5 26.925 9.075 28.5 11 28.5H32C33.925 28.5 35.5 26.925 35.5 25ZM16.25 18L19.8025 22.7425L25 16.25L32 25H11L16.25 18ZM0.5 7.5V32C0.5 33.925 2.075 35.5 4 35.5H28.5V32H4V7.5H0.5Z"
                          fill="#A3A3A3"
                        />
                      </svg>
                    )}
                  </label>
                  {method?.image && (
                    <CancelIcon
                      style={{
                        fontSize: "35px",
                        color: "#EB5757",
                        position: "absolute",
                        right: "40",
                      }}
                      onClick={() => {
                        handleOnRemoveImage(index);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

          <input
            ref={inputRef}
            type="file"
            id="file"
            hidden
            onChange={handleFileInputChange}
          />
        </div>
      ) : (
        "No Data"
      )}
    </div>
  );
};

const ChildDevelopment: React.FunctionComponent<ChildDevelopmentProps> = (
  props
) => {
  const intl = useIntl();
  const [viewIndex, setViewIndex] = useState<number | string>(0);

  const swipeableRef = useRef<any>(null);
  const bodyRef = useRef() as MutableRefObject<HTMLDivElement>;

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  // Handle

  const handleTabChange = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleSetViewHeight = (height: number) => {
    if (swipeableRef.current?.containerNode) {
      swipeableRef.current.containerNode.style.height = `${height + 25}px`;
      // swipeableRef.current.containerNode.style.overflow = `hidden`;
      [0, 1].map((view) => {
        if (swipeableRef.current.containerNode.childNodes[view]) {
          swipeableRef.current.containerNode.childNodes[view].style.height = ``;
        }
      });

      swipeableRef.current.containerNode.childNodes[
        +viewIndex
      ].style.height = `${height + 25}px`;
    }
  };

  return (
    <div ref={bodyRef} style={{ height: "100%", width: "100%" }}>
      <AppBar position="fixed" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "bplusClinicKey159" })}
        />
      </AppBar>

      <PatientPanel
        patientInfo={props.selectedPatient}
        useBackgroundGradient={true}
        // titleFooter="Development"
        showFullAge={true}
      />

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Tab
        className="weight-tab-custom"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
        style={{ marginTop: "5px" }}
      />

      <SwipeableViews
        ref={swipeableRef}
        index={+viewIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="child-view"
        style={{ height: "calc(100% - 135px)", overflow: "hidden" }}
        containerStyle={{ height: "100%", WebkitOverflowScrolling: "touch" }}
      >
        <div>
          <DetailsTab
            // Callback
            {...props}
            onSetViewHeight={(height) => {
              return;
              if (+viewIndex === 0) {
                handleSetViewHeight(height);
              }
            }}
          />
        </div>
        <div>
          <ChildDevelopmentHistoryTab
            // function
            onEvent={props.onEvent}
            onSetViewHeight={(height) => {
              return;
              if (+viewIndex === 1) {
                handleSetViewHeight(height);
              }
            }}
            // data
            selectedPatient={props.selectedPatient}
            developmentHistory={props.developmentHistory}
            developmentAgeRange={props.developmentAgeRange}
            isMyTab={viewIndex === 1}
            isMobile={true}
            height={200}
          />
        </div>
      </SwipeableViews>
    </div>
  );
};

ChildDevelopment.defaultProps = {
  // function
  onEvent: () => {},
  // data
  errorMessage: null,
  successMessage: null,
  loadingStatus: false,
  selectedPatient: {},
  developmentAgeRangeOptions: [],
  developmentAgeRange: [],
  developmentDetail: {},
  developmentHistory: [],
} as ChildDevelopmentProps;

export default ChildDevelopment;
