import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Button as ButtonMui } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { appendValid } from "react-lib/apps/IsHealth/Common/Diag";
import {
  filterVaccineExtraAnswer,
  answerInjectDate,
  combineQuestion,
  answervaccineBrand,
} from "./MobCouponEvaluate";

const MobCouponChoiceExtra = (props: any) => {
  console.log("Extra props", props);

  const useStyles = makeStyles(() => ({
    paper: {
      padding: "10px",
      margin: "10px",
    },
    label: {
      color: "#0147A3",
      width: "100%",
    },
    radioGroup: {
      width: "100%",
    },
    questionItem: {
      margin: "20px 0px",
    },
    formControl: {
      minWidth: 120,
    },
    selecttype: {
      margin: "8px 0px 8px 0px",
    },
    dateselecttype: {
      margin: "8px 0px 30px 0px",
    },
    normalDate: {
      margin: "8px 0px 30px 0px",
    },
  }));

  const classes = useStyles();

  const ex = props.extraItems[props.subCountLevel];
  const index = props.subCountLevel;

  let [validated, invalidField] = appendValid(props.extraItems);

  // คำถามของการฉีดยา ที่สอดคล้องกับ คำตอบก่อนหน้า
  let injectAnswerList = filterVaccineExtraAnswer(
    props.extraItems,
    props.subCountLevel
  );

  //console.log("Extra props.extraItems", props.extraItems, ex);
  //console.log( "Extra answerInjectDate.includes(ex.label): ", answerInjectDate.includes(ex.label) );

  // ดูว่า คำถามปัจจุบันถึงจุดที่ถามเกี่ยวกับ วันที่ของการฉีดหรือไม่ โดยใน find ถ้า > 0 แสดงว่าใช่
  let currentLabelIsContainDose =
    [...answerInjectDate, ...answervaccineBrand].find((item: any) =>
      ex.label?.includes(item)
    ) || false;

  // console.log("injectAnswerList: ", injectAnswerList);
  // console.log("currentLabelIsContainDose: ", currentLabelIsContainDose);

  // ลิมิตตัว จำนวนคำถามตาม จำนวนคำตอบที่ว่าฉีดไปกี่เข็ม (คูณ 2 เพราะว่า คำถามมีเพิ่มมา ยี่ห้อกับวันที่)
  injectAnswerList = injectAnswerList.slice(0, props.injectCovidCount * 2);
  // console.log('injectAnswerList: ', injectAnswerList);
  return (
    <>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={props.language.toLowerCase()}
      >
        {/* { props.extraItems.map((ex, index, extra_array) => { */}
        {
          props.extraItems[props.subCountLevel] && ex.type === "Modal" ? (
            <> </>
          ) : props.injectCovidCount !== 0 && !!currentLabelIsContainDose ? (
            <>
              {injectAnswerList.map((injAns: any, idx: any) => {
                return (
                  <>
                    {["Text", "Integer", "Decimal", "DateSelect"].includes(
                      injAns.type
                    ) && injAns.page === "9" ? (
                      <div className={classes.dateselecttype}>
                        <div key={injAns.label}> {injAns.label} </div>
                        <div>
                          <DatePicker
                            format="DD MMM yyyy"
                            emptyLabel={
                              props.language === "EN"
                                ? "Please specify"
                                : "กรุณาระบุ"
                            }
                            maxDate={moment()}
                            value={
                              injAns.value && moment(injAns.value)
                                ? moment(injAns.value)
                                : null
                            }
                            onChange={(mo) => {
                              //console.log("")
                              props.selectedExtra({
                                arrayIdx: injAns.arrayIdx,
                                label: injAns.label,
                                value: mo?.toISOString(),
                              });
                              //console.log("e", mo?.toISOString());
                            }}
                          />
                          {!ex.validated ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                fontSize: "12px",
                              }}
                            >
                              {ex.validated_text}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : injAns.type === "Select" && injAns.page === "9" ? (
                      <>
                        <div className={classes.selecttype}>
                          {!ex.validated ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginBottom: "10px",
                                fontSize: "12px",
                              }}
                            >
                              {ex.validated_text}
                            </div>
                          ) : (
                            <></>
                          )}

                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              {injAns.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={injAns.value}
                              onChange={(e) => {
                                props.selectedExtra({
                                  arrayIdx: injAns.arrayIdx,
                                  label: injAns.label,
                                  value: e.target.value,
                                });
                                // console.log("Chooise");
                              }}
                              label={injAns.label}
                            >
                              {Array.isArray(injAns.options) &&
                                injAns.options.length > 0 &&
                                injAns.options.map((op: any, op_index: any) => (
                                  <MenuItem key={op} value={op}>
                                    {op}
                                  </MenuItem>
                                ))}
                              {/* <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                          </FormControl>

                          {/* {Array.isArray(injAns.options) && injAns.options.length > 0 && injAns.options.map((op, op_index) => (
                          <div key={op_index}>
                            <Radio style={{ marginTop: '3px', marginBottom: '3px' }} label={op} value={op} checked={injAns.value === op}
                            />
                          </div>
                        ))} */}
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            // return (
            // <Paper className={classes.questionItem} elevation={2}>
            <>
              <div>
                <div dangerouslySetInnerHTML={{ __html: ex.label }} />
              </div>
              {["Text", "Integer", "Decimal", "DateSelect"].includes(
                ex.type
              ) ? (
                <div className={classes.normalDate}>
                  <DatePicker
                    format="DD MMM yyyy"
                    emptyLabel={
                      props.language === "EN" ? "Please specify" : "กรุณาระบุ"
                    }
                    maxDate={moment()}
                    value={
                      ex.value && moment(ex.value) ? moment(ex.value) : null
                    }
                    onChange={(mo) => {
                      props.selectedExtra({
                        arrayIdx: index,
                        label: ex.label,
                        value: mo?.toISOString(),
                      });
                      //console.log("e", mo?.toISOString());
                    }}
                  />
                  {!ex.validated ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {ex.validated_text}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : ex.type === "Select" ? (
                <div>
                  {!ex.validated ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "5px",
                        marginBottom: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {ex.validated_text}
                    </div>
                  ) : (
                    <></>
                  )}
                  {Array.isArray(ex.options) && ex.options.length > 0 && (
                    <RadioGroup
                      aria-label="gender"
                      defaultValue="female"
                      name="radio-buttons-group"
                      className={classes.radioGroup}
                      value={ex.value}
                    >
                      {ex.options
                        ? ex.options.map((item: any) => {
                            // console.log("item", item);

                            return (
                              <Paper
                                key={item}
                                className={classes.paper}
                                elevation={2}
                              >
                                <FormControlLabel
                                  className={classes.label}
                                  value={item}
                                  control={
                                    <Radio
                                      onClick={(e) => {
                                        //console.log(" Checked", e.target.value);
                                        props.selectedExtra({
                                          arrayIdx: index,
                                          label: ex.label,
                                          value: e.target.value,
                                          ...(combineQuestion.includes(
                                            ex.label
                                          ) && {
                                            injectCount: parseInt(
                                              e.target.value
                                            ),
                                          }),
                                        });

                                        // props.selectedChoice({ choiceId: item?.id });
                                        // setSelectedchoice(e.target.value);
                                      }}
                                    />
                                  }
                                  label={item}
                                />
                              </Paper>
                            );
                          })
                        : null}
                    </RadioGroup>
                  )}
                </div>
              ) : null}
            </>
          )

          // </Paper>
          // );
        }
      </MuiPickersUtilsProvider>
    </>
  );
};

export default MobCouponChoiceExtra;
