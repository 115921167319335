import React, { useState } from 'react';
import TagSet from "react-lib/frameworks/TagSet";

const PostTagEditor = (props: any) => {

  return(<div style={{ position: "relative", top: 0, left: 0}}>
    {props.selectedPost?.tags.map((tagSet: any, index: number) => 
    <TagSet
      key={index}
      tagSet={tagSet}
      tagIndex={index}
      tagList={props.tagList}
      onEvent={props.onEvent}
      dataType="post"
    />
    )}
  </div>)
}

export default PostTagEditor