import BaseService from  '../services/BaseService'
import { to } from '../../utils'
import config from '../../../config/config'
// import config from '../../../config/config';
import * as serviceWorker  from '../../../serviceWorker'

const HOST = `${config.API_HOST}`

export async function registerServiceWorker() {
  console.log("registerServiceWorker  sw.js");
  return await navigator.serviceWorker.register("/sw.js");
}

export async function getRegisterServiceWorker() {
  let registrations = null;
  try {
    registrations = await navigator.serviceWorker.getRegistrations();
    console.log("check service worker", registrations);
    if (registrations.length <= 0) {
      console.log("Force register service worker again !!!");
      serviceWorker.register();
      registerServiceWorker();
    }
  } catch (e) {
    if (navigator.serviceWorker) {
      console.log("Force register service worker again !!!");
      serviceWorker.register();
      registerServiceWorker();
    }
  }
}

export async function askUserPermission() {
  if (typeof(Notification) === "undefined") {
      return "denied"
  } else {
      return await Notification.requestPermission();
  }
}

export async function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  if (!navigator || !navigator.serviceWorker) {
    return null;
  }
  return navigator.serviceWorker.ready
    .then(async function (serviceWorker) {
      if (!serviceWorker || !serviceWorker.pushManager) {
        return null;
      }
      return await serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  // const serviceWorker = navigator.serviceWorker.ready
  console.log(serviceWorker);
  if (!navigator || !navigator.serviceWorker) {
    return null;
  }
  return await navigator.serviceWorker?.ready
    ?.then(async (serviceWorker) => {
      // subscribe and return the subscription

      if (!serviceWorker || !serviceWorker.pushManager) {
        return null;
      }
      return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BNsqzQm1wcdK-ne8sDG8V-ZKzYoyn8PMYd6ehca2lt0XKl2a4SXgEypIaFPQDGVckKKQY79Ug2yEo_0BZAYumTM",
      });
    })
    .catch((err) => {
      return err;
    });
}

export const SetPassword : 
{
  post:   (arg0:
           { data?: any,
             params?: any,
             apiToken?: any,
             extra?: any,
           }) => any,
} = 
{
  post: async ({data, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/users/api/set_password/`,
      { ...data
      },
      { params: { ...params },
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  }
}

export const RequestToken : 
{
  post:   (arg0:
           { data?: any,
             params?: any,
             apiToken?: any,
             extra?: any,
           }) => any,
} = 
{
  post: async ({data, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/users/api/request_token/`,
      { ...data
      },
      { params: { ...params },
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  }
}

