import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Segment,
  Image,
  Form,
  Button,
  Input,
  Dropdown,
  Dimmer,
  Loader,
  Icon,
  TextArea,
  Grid,
  Checkbox,
} from "semantic-ui-react";

import personImg from "react-lib/apps/common/person.png";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import moment from "moment";
import * as Util from "../react-lib/utils";
import Cookies from "js-cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import { bloodOptions, rhOptions, diseaseList, foodList } from "./ProfileWrap";

const ProfileInfo = (props) => {
  const isMounted = useRef(true);

  const [birthday, setBirthday] = useState("");
  // const [chatChannel ,setChatChannel] = useState([])
  const [selectedChatChannel, setSelectedChatChannel] = useState(null);
  const [hospitalNumber, setHospitalNumber] = useState("");
  const [lockHN, setLockHN] = useState(false);
  const [proxyId, setProxyId] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const [gender, setGender] = useState(null);
  const [personWeight, setPersonWeight] = useState("");
  const [personHeight, setPersonHeight] = useState("");
  const [address, setAddress] = useState("");

  const [congenital, setCongenital] = useState("");
  const [foodAllergy, setFoodAllergy] = useState("");
  const [drugAllergy, setDrugAllergy] = useState("");
  const [allergyText, setAllergyText] = useState("");
  const [noDrugAllergy, setNoDrugAllergy] = useState(false);
  const [noFoodAllergy, setNoFoodAllergy] = useState(false);
  const [noCongenital, setNoCongenital] = useState(false);
  const [bloodType, setBloodType] = useState("");
  const [rhType, setRhType] = useState("");
  const [age, setAge] = useState("");
  const [bmi, setBMI] = useState("-");

  // Donot update email
  const [email, setEmail] = useState("");
  // Ref for input
  // const firstNameRef = useRef();
  // const lastNameRef = useRef();
  // const phoneRef = useRef();
  const intl = useIntl();

  const apiToken = Cookies.get("apiToken");

  useEffect(() => {
    if (props.profile) {
      // console.log(" useEffect props.profile: ", props.profile)
      setFirstname(props.profile?.first_name);
      setLastname(props.profile?.last_name);
      setPhoneNo(props.profile?.phone_no);
      // firstNameRef.current.inputRef.current.value = props.profile?.first_name;
      // lastNameRef.current.inputRef.current.value = props.profile?.last_name;
      let dob = "";
      if (props.profile?.dob) {
        dob = formatADtoBEString(props.profile?.dob);
      }
      setBirthday(dob);
      // phoneRef.current.inputRef.current.value = props.profile?.phone_no;
      setEmail(props.profile?.email);
      setAddress(props.profile?.address);
      setUserId(props.profile?.user);
      if (props.profile?.extra) {
        let extra = JSON.parse(props.profile?.extra);
        setGender(
          extra.gender === 1 ? "male" : extra.gender === 2 ? "female" : ""
        );
        setPersonWeight(extra.personWeight ? extra.personWeight : "");
        setPersonHeight(extra.personHeight ? extra.personHeight : "");
        setBloodType(extra.bloodType ? extra.bloodType : "");
        setRhType(extra.rhType ? extra.rhType : "");
        setCongenital(extra.congenital ? extra.congenital : "");
        setFoodAllergy(extra.foodAllergy ? extra.foodAllergy : "");
        setDrugAllergy(extra.drugAllergy ? extra.drugAllergy : "");
        setNoCongenital(!extra.congenital);
        setNoFoodAllergy(!extra.foodAllergy);
        setNoDrugAllergy(!extra.drugAllergy);

        if (
          extra.personHeight &&
          extra.personWeight &&
          !isNaN(extra.personHeight) &&
          !isNaN(extra.personWeight)
        ) {
          let weight = Math.abs(Number(extra.personWeight));
          let height = Math.abs(Number(extra.personHeight)) / 100;
          if (height > 0) {
            let bmiNumber = weight / (height * height);
            let bmiText = getBMIText(bmiNumber);
            console.log("bmiText", bmiText, bmiNumber);
            setBMI(bmiText);
          } else {
            setBMI("-");
          }
        } else {
          setBMI("-");
        }
      }

      if (props.profile?.age_dict) {
        let { years, months, days } = props.profile?.age_dict;
        let age_text =
          years +
          " " +
          intl.formatMessage({ id: "profile.years" }) +
          " " +
          months +
          " " +
          intl.formatMessage({ id: "profile.months" }) +
          " " +
          days +
          " " +
          intl.formatMessage({ id: "profile.days" });
        setAge(age_text);
      }
    }
  }, [props.profile]);

  const loadProxyHasDivision = async (needLoading = true) => {
    let apiToken = props.apiToken ? props.apiToken : apiToken;
    if (needLoading) {
      setProfileLoading(true);
    }
    const [data, error, network] =
      await props.controller.getProxyPatientHasDivision({ apiToken: apiToken });

    // console.log(' loadProxyHasDivision', data)
    if (isMounted.current) {
      if (needLoading) {
        setProfileLoading(false);
      }
      if (data) {
        let items = data.items;

        console.log("data loadProxyHasDivision", data);
        if (items && items.length > 0) {
          let prepare = items.find((item) => {
            return item.division.name === selectedChatChannel;
          });

          if (prepare) {
            console.log("loadProxyHasDivision prepare", prepare);
            setProxyId(prepare && prepare.id ? prepare.id : null);
            setHospitalNumber(prepare.hn);
            setLockHN(prepare.lock_hn);
          }
        }
      }
    }
  };

  // WHO criteria
  const getBMIText = (data) => {
    let bmi = Number(data.toFixed(2));
    switch (true) {
      case bmi < 18.5:
        return bmi + " " + intl.formatMessage({ id: "bmi.underweight" });
      case bmi >= 18.5 && bmi < 24.9:
        return bmi + " " + intl.formatMessage({ id: "bmi.healthy" });
      case bmi >= 25 && bmi < 29.9:
        return bmi + " " + intl.formatMessage({ id: "bmi.overweight" });
      case bmi >= 30:
        return bmi + " " + intl.formatMessage({ id: "bmi.obese" });
      default:
        return bmi + " ";
    }
  };

  const handleDateChange = (date) => {
    setBirthday(date);
  };

  const handleOnHNChange = (e, data) => {
    if (!lockHN) {
      setHospitalNumber(data.value);
    }
  };

  const handleSelectHospitalList = (e, data) => {
    setSelectedChatChannel(data.value);
    loadProxyHasDivision();
  };

  // useEffect(()=>{
  //   if (chatChannel && Array.isArray(chatChannel) && chatChannel.length > 0 ) {
  //     setSelectedChatChannel(chatChannel[0].value)
  //   }
  // },[chatChannel])

  useEffect(() => {
    loadProxyHasDivision(false);
  }, [selectedChatChannel]);

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  console.log("props profileInfo", props);

  // We need refence inside this UX
  const prepareSave = () => {
    let profile = {
      firstname: firstname,
      lastname: lastname,
      phone: phoneNo,
      birthday: birthday,
      gender: gender,
      // email:email,
      personWeight: personWeight,
      personHeight: personHeight,
      address: address,
      bloodType: bloodType,
      rhType: rhType,
      congenital: congenital,
      foodAllergy: foodAllergy,
      drugAllergy: drugAllergy,
    };
    props.onSave(profile);
    // let hospital = {
    //   hospitalNumber: hospitalNumber,
    //   proxyId: proxyId,
    // };
    // props.onSave(profile, hospital);
  };

  const chatChannelToDropdownOption = (data) => {
    console.log("Compute chatChannelToDropdownOption");
    let dataOption = [];
    if (data && data.length > 0) {
      dataOption = data.map((item) => {
        return { key: item.id, text: item.name, value: item.name };
      });
    }
    return dataOption;
  };

  const chatChannelOptions = useMemo(
    () => chatChannelToDropdownOption(props.chatChannelList),
    [props.chatChannelList]
  );

  useEffect(() => {
    if (
      chatChannelOptions &&
      Array.isArray(chatChannelOptions) &&
      chatChannelOptions.length > 0 &&
      !selectedChatChannel
    ) {
      console.log("loadProxyHasDivision  SelectedDefault ");
      setSelectedChatChannel(chatChannelOptions[0].value);
    }
  }, [props.chatChannelList, chatChannelOptions]);

  const CreateAllergy = (allergyId) => {
    let list = [];
    let otherValue = null;
    let allValue = null;
    let optionsValue = null;
    let setAllValue = null;

    //profile.foodallergy", profile.congenitaldisease
    if (allergyText.includes("disease")) {
      // โรคประจำตัว
      list = diseaseList;

      let congenitalList = congenital
        ? congenital.split(",")?.map((item) => item.trim())
        : [];
      otherValue = congenitalList.filter((item) => !list.includes(item));
      optionsValue = congenitalList.filter((item) => list.includes(item));

      // allergyOtherValue = congenital;
      setAllValue = setCongenital;
      allValue = congenital;
    } else {
      // แพ้อาหาร
      list = foodList;

      let foodAllergylList = foodAllergy
        ? foodAllergy.split(",")?.map((item) => item.trim())
        : [];
      otherValue = foodAllergylList.filter((item) => !list.includes(item));
      optionsValue = foodAllergylList.filter((item) => list.includes(item));

      // allergyOtherValue = congenital;
      setAllValue = setFoodAllergy;
      allValue = foodAllergy;
    }

    return (
      <div className="profile">
        <Segment basic className="profile-segment-noimage">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <div className="allergy-result">
              <label> {intl.formatMessage({ id: allergyId })} </label>
              <Button
                color="teal"
                onClick={() => {
                  props.onSetOpenAllergy(false);
                }}
              >
                {intl.formatMessage({ id: "common.done" })}
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Form>
                <TextArea rows={7} value={allValue} />
              </Form>
            </div>

            <div className="allergy-result">
              <label> Suggestions </label>
            </div>

            <div className="allergy-checkbox">
              {list.map((item, idx) => {
                if (list?.length === idx + 1) {
                  return (
                    // Other Options
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={idx}
                    >
                      <Checkbox
                        checked={otherValue?.length > 0}
                        onClick={(e, data) => {
                          if (data.checked) {
                            // console.log("Checked", otherValue)
                            if (otherValue?.length === 0) {
                              otherValue = [" "];
                            }
                          } else {
                            // console.log("Un Checked")
                            otherValue = [];
                          }

                          let all = optionsValue.concat(otherValue);
                          let total = all.join(", ");
                          // console.log("total: ", total);
                          setAllValue(total);
                        }}
                      />{" "}
                      <span
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          if (otherValue?.length === 0) {
                            // console.log("Checked", otherValue)
                            if (otherValue?.length === 0) {
                              otherValue = [" "];
                            }
                          } else {
                            // console.log("Un Checked")
                            otherValue = [];
                          }

                          let all = optionsValue.concat(otherValue);
                          let total = all.join(", ");
                          // console.log("total: ", total);
                          setAllValue(total);
                        }}
                      >
                        {item}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    // Options
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={idx}
                    >
                      <Checkbox
                        id={item}
                        checked={optionsValue.includes(item)}
                        onClick={(e, data) => {
                          let id = e.target.id;
                          if (optionsValue.includes(id)) {
                            optionsValue = optionsValue.filter(
                              (opt) => opt !== id
                            );
                          } else {
                            optionsValue.push(id);
                          }
                          let total = optionsValue.concat(
                            otherValue ? otherValue : []
                          );
                          setAllValue(total.join(", "));
                        }}
                      />{" "}
                      <span
                        id={item}
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        onClick={(e) => {
                          let id = e.target.id;
                          if (optionsValue.includes(id)) {
                            optionsValue = optionsValue.filter(
                              (opt) => opt !== id
                            );
                          } else {
                            optionsValue.push(id);
                          }
                          let total = optionsValue.concat(
                            otherValue ? otherValue : []
                          );
                          setAllValue(total.join(", "));
                        }}
                      >
                        {item}{" "}
                      </span>{" "}
                    </div>
                  );
                }
              })}
            </div>
            <div style={{ marginTop: "10px" }}>
              <Form>
                <TextArea
                  disabled={otherValue?.length === 0}
                  {...(otherValue?.length === 0 && {
                    style: { backgroundColor: "#E0E0E0" },
                  })}
                  rows={7}
                  value={otherValue.join(",")}
                  onChange={(e, data) => {
                    let val = [data.value];
                    let all = optionsValue.concat(val);
                    let total = all.join(", ");
                    // console.log("total: ", total);
                    setAllValue(total);
                  }}
                />
              </Form>
            </div>
          </Dimmer.Dimmable>
        </Segment>
      </div>
    );
  };

  return (
    <>
      {props.openAllergyProfile && CreateAllergy(allergyText)}

      {!props.openAllergyProfile && (
        <div className="profile">
          <Image
            className={"profile-image border"}
            fluid
            circular
            centered
            src={
              props.profile && props.profile?.image
                ? props.profile.image
                : props.profile?.image_url
                ? props.profile.image_url
                : personImg
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = { personImg };
            }}
          />
          <Segment basic className="profile-segment">
            <Dimmer.Dimmable>
              <Dimmer active={profileLoading} inverted>
                <Loader inverted>Loading...</Loader>
              </Dimmer>
              <p>
                <FormattedMessage id="profile.profile" />
              </p>
              <Form className="profile-segment-form">
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.ihid" />
                  </label>
                  <Input fluid value={userId} />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.first_name" />
                  </label>
                  <Input
                    fluid
                    value={firstname}
                    onChange={(e, data) => {
                      setFirstname(data.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.last_name" />
                  </label>
                  <Input
                    fluid
                    value={lastname}
                    onChange={(e, data) => {
                      setLastname(data.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.day_month_year_birth" />
                  </label>
                  <DateTextBox
                    value={birthday}
                    onChange={handleDateChange}
                    keepFocus={false}
                    maxDate={Util.formatDate(moment())}
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.age" />
                  </label>
                  <Input fluid value={age} />
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.gender" />
                  </label>
                  <div style={{ marginTop: "10px" }}>
                    {gender === "male" && (
                      <>
                        {" "}
                        <Icon name="male" color="teal" size="big" />{" "}
                        <FormattedMessage id="common.male" />{" "}
                      </>
                    )}
                    {gender === "female" && (
                      <>
                        {" "}
                        <Icon name="female" color="teal" size="big" />
                        <FormattedMessage id="common.female" />{" "}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      {...(gender === "male" && { color: "teal" })}
                      onClick={() => {
                        setGender("male");
                      }}
                    >
                      <FormattedMessage id="common.male" />
                    </Button>
                    <Button
                      {...(gender === "female" && { color: "teal" })}
                      onClick={() => {
                        setGender("female");
                      }}
                    >
                      <FormattedMessage id="common.female" />
                    </Button>
                  </div>
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.weight" />
                  </label>
                  <Input
                    fluid
                    value={personWeight}
                    onChange={(e, data) => {
                      setPersonWeight(data.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.height" />
                  </label>
                  <Input
                    fluid
                    value={personHeight}
                    onChange={(e, data) => {
                      setPersonHeight(data.value);
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.bmi" />
                  </label>
                  <Input fluid value={bmi} />
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.tel_no" />
                  </label>
                  <Input
                    fluid
                    value={phoneNo}
                    onChange={(e, data) => {
                      setPhoneNo(data.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.email" />
                  </label>
                  <Input
                    fluid
                    value={email}
                    onChange={(e, data) => {
                      setEmail(data.value);
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    <FormattedMessage id="profile.address" />
                  </label>
                  <TextArea
                    rows="7"
                    value={address}
                    onChange={(e, data) => {
                      setAddress(data.value);
                    }}
                  />
                </Form.Field>

                <div>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Field>
                          <label>
                            {intl.formatMessage({ id: "profile.bloodtype" })}
                          </label>
                          <Dropdown
                            fluid
                            value={bloodType}
                            placeholder={intl.formatMessage({
                              id: "profile.bloodtype",
                            })}
                            selection
                            onChange={(e, { value }) => setBloodType(value)}
                            options={bloodOptions}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>
                            {intl.formatMessage({ id: "profile.rh" })}
                          </label>
                          <Dropdown
                            fluid
                            value={rhType}
                            placeholder={intl.formatMessage({
                              id: "profile.rh",
                            })}
                            selection
                            onChange={(e, { value }) => setRhType(value)}
                            options={rhOptions}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>

                <Form.Field>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <label style={{ marginRight: "10px" }}>
                      <FormattedMessage id="profile.congenitaldisease" />
                    </label>
                    <Checkbox
                      label={intl.formatMessage({ id: "common.none" })}
                      onChange={() => {
                        console.log(" enabledCongenital", noCongenital);
                        setNoCongenital(!noCongenital);
                        setCongenital("");
                      }}
                      checked={noCongenital}
                    />
                  </div>
                  <TextArea
                    value={congenital}
                    disabled={noCongenital}
                    onClick={(e) => {
                      e.target.blur();
                      setAllergyText("profile.congenitaldisease");
                      props.onSetOpenAllergy(true);
                    }}
                    // onChange={(e, data) => {
                    //   setCongenital(data.value);
                    // }}
                  />
                </Form.Field>
                <Form.Field>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <label style={{ marginRight: "10px" }}>
                      <FormattedMessage id="profile.foodallergy" />
                    </label>
                    <Checkbox
                      label={intl.formatMessage({ id: "common.none" })}
                      onChange={() => {
                        setNoFoodAllergy(!noFoodAllergy);
                        setFoodAllergy("");
                      }}
                      checked={noFoodAllergy}
                    />
                  </div>
                  <TextArea
                    value={foodAllergy}
                    // onChange={(e, data) => {
                    //   setFoodAllergy(data.value);
                    // }}
                    disabled={noFoodAllergy}
                    onClick={(e) => {
                      e.target.blur();
                      setAllergyText("profile.foodallergy");
                      props.onSetOpenAllergy(true);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <div style={{ display: "flex", margin: "10px 0px" }}>
                    <label style={{ marginRight: "10px" }}>
                      <FormattedMessage id="profile.drugallergy" />
                    </label>
                    <Checkbox
                      label={intl.formatMessage({ id: "common.none" })}
                      onChange={() => {
                        setNoDrugAllergy(!noDrugAllergy);
                        setDrugAllergy("");
                      }}
                      checked={noDrugAllergy}
                    />
                  </div>
                  <TextArea
                    disabled={noDrugAllergy}
                    value={drugAllergy}
                    onChange={(e, data) => {
                      setDrugAllergy(data.value);
                    }}
                  />
                </Form.Field>

                {/* <Form.Field>
                <Form.Field required>
                  <label>
                    <FormattedMessage id="profile.hospital" />
                  </label>
                  <Dropdown
                    selection
                    fluid
                    placeholder="โปรดเลือกโรงพยาcreateSaveStateาล เพื่อดูข้อมูล"
                    value={selectedChatChannel}
                    options={chatChannelOptions}
                    onChange={handleSelectHospitalList}
                  />
                </Form.Field>
                <Form.Field>
                  <label>HN.</label>
                  <Input
                    fluid
                    readOnly={lockHN ? "1" : null}
                    value={hospitalNumber}
                    onChange={handleOnHNChange}
                  />
                </Form.Field>
              </Form.Field> */}
              </Form>
              <div className="save-div">
                <Button
                  onClick={prepareSave}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  <FormattedMessage id="common.save" />
                </Button>
              </div>
            </Dimmer.Dimmable>
          </Segment>

          {!props.hideLogout && (
            <div className="logout-div">
              <Button
                size={"large"}
                onClick={props.onLogout}
                loading={isLoading}
                disabled={isLoading}
              >
                <FormattedMessage id="auth.logout" />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ProfileInfo.defaultProps = {
  controller: {},
  onLogout: () => {},
  noApiToken: () => {},
  hideLogout: false,
  apiToken: "",
  handleGoBack: () => {},
  profile: null,
  onSave: () => {},
  chatChannelList: [],
};

export default ProfileInfo;
