import React, { useMemo, useState, useEffect, CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import {
  Form,
  Icon,
  Header,
  Input,
  Dropdown,
  Button,
  Tab,
  Menu,
  TabProps,
  Modal,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import moment from "moment";
import ReactTable from "react-table-6";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Slide } from "@material-ui/core";

// Interface
import {
  Event as MobEvent,
  EXERCISE_TYPE,
  convertToADDate,
  formatDate,
} from "../MobClinicInterface";
import { ModExerciseType } from "./DMExerciseInterface";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";


// UI
import PatientPanel from "./PatientPanel";
import { ExerciseDMSetting } from "./DMExerciseDialog";
import MobHead from "../MobHead";
import SnackMessage from "./SnackMessage";
import DMExerciseChart from "./DMExerciseChart";
import { DMDatePicker } from "./DMEmotionRestChart";

// Utils
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";

// Styles
import "./DMWeight.scss";

export const COLOR = {
  yellow: "#ffd00d",
  light_grey: " #A3A3A3",
  grey: "#747474",
  medium_grey: "#656565",
  blue: "#239cf3",
  medium_blue: "#2083C1",
  medium_green: "#27AE60",
  lemon_green: "#A0F297",
  pink: "#FCA5C4",
  cyan_blue: "#ACE8FC",
  tea_green: "#365233",
  baby_blue: "#A2D7E8",
};

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
  } as CSSProperties,
  bmiLeftBold: {
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: "5px",
  } as CSSProperties,
};

export const DATE_FORMAT = "DD/MM/YYYY";

export const EXERCISE_TYPE_DETAIL = {
  AEROBIC: {
    color: EXERCISE_TYPE.AEROBIC.color,
    text: EXERCISE_TYPE.AEROBIC.text,
  },
  WEIGHT: {
    color: EXERCISE_TYPE.WEIGHT_RESISTANCE.color,
    text: EXERCISE_TYPE.WEIGHT_RESISTANCE.text,
  },
  FLEXIBILITY: {
    color: EXERCISE_TYPE.FLEXIBILITY.color,
    text: EXERCISE_TYPE.FLEXIBILITY.text,
  },
  CALORIES: {
    content: () => null,
    color: COLOR.tea_green,
    text: "Calories",
  },
};

export type ExerciseTypeDetail = typeof EXERCISE_TYPE_DETAIL;

const DisplayCalorie = (props: { value: string }) => {
  return (
    <div
      style={{
        width: "115px",
        backgroundColor: COLOR.baby_blue,
        color: COLOR.medium_grey,
        textAlign: "center",
        lineHeight: "25px",
      }}
    >
      <div style={{ fontSize: "15px" }}>
        <FormattedMessage id="bplusClinicKey100" />
      </div>
      <div style={{ fontWeight: 800, fontSize: "20px" }}>{props.value}</div>
      <div>
        <FormattedMessage id="bplusClinicKey99" />
      </div>
    </div>
  );
};

// ------------------------------ Tab Detail
type DetailsTabProps = {
  //Callback
  onSettingClick: () => any;
  exerciseFilterDate?: moment.Moment;
} & ExerciseDMProps;

const DetailsTab: React.FC<DetailsTabProps> = (props) => {
  // Open
  const [openModExcerciseType, setOpenModExcerciseType] = useState(false);

  // Options

  // Input
  const [exerciseType, setExerciseType] = useState<any>("");
  const [duration, setDuration] = useState<string | number>("");
  const [calorie, setCalorie] = useState<string | number>("");

  const intl = useIntl();

  useEffect(() => {
    const getExercise = async () => {
      const getExerciseType = props.onEvent({
        message: "GetExerciseType",
        params: {},
      });
      const getExerciseRecord = props.onEvent({
        message: "GetExerciseRecord",
        params: {},
      });
      await Promise.all([getExerciseRecord]);
    };
    getExercise();
  }, [props.selectedPatient]);

  useEffect(() => {
    props.onEvent({ message: "GetExerciseRecord", params: {} });
  }, [props.exerciseFilterDate]);

  const columnsRecord = useMemo(
    () => [
      {
        Header: "Time",
        accessor: " ",
        width: 60,
        Cell: (row: any) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {formatUTCtoMoment(row.original?.measure_date).format("HH:mm")}
          </div>
        ),
      },
      {
        Header: "Type",
        accessor: " ",
        width: 140,
        Cell: (row: any) => (
          <div style={{ textAlign: "center" }}>
            {props.exerciseTypeList?.find(
              (item: any) => item.id === row.original?.data?.type
            )?.name || ""}
          </div>
        ),
      },
      {
        Header: "Duration (min)",
        accessor: " ",
        minWidth: 90,
        Cell: (row: any) => (
          <div style={{ textAlign: "center" }}>
            {row.original?.data?.duration}
          </div>
        ),
      },
      {
        Header: "Calories",
        accessor: " ",
        width: 60,
        Cell: (row: any) => <div>{row.original?.data?.calorie}</div>,
      },
      {
        Header: "",
        accessor: "_remove",
        width: 30,
        Cell: (row: any) => (
          <div style={{ marginTop: "-4px" }}>
            <Button
              color="red"
              icon="minus"
              circular
              style={{ fontSize: "6px" }}
              onClick={() => {
                props.onEvent({
                  message: "SetModRemoveExercise",
                  params: { open: true, data: row.original },
                });
              }}
            />
          </div>
        ),
      },
    ],
    [props.exerciseTypeList]
  );

  const exerciseTypeOptions = useMemo(() => {
    return (props.exerciseTypeList || []).map((list: any, index) => ({
      key: index,
      value: list.id,
      text: list.name,
    }));
  }, [props.exerciseTypeList]);

  const sumCalories: number = useMemo(() => {
    let result: number = 0;
    props.exerciseRecordList?.items?.forEach((item: any) => {
      result += parseFloat(item?.data?.calorie) || 0;
    });
    return result || 0;
  }, [props.exerciseRecordList]);

  const EXERCISE_TYPE_DETAIL = {
    AEROBIC: {
      content: () => (
        <div>
          <strong>{EXERCISE_TYPE.AEROBIC.text}: </strong>
          {intl.formatMessage({ id: "bplusClinicKey652" })}
        </div>
      ),
    },
    WEIGHT: {
      content: () => (
        <div>
          <strong>{EXERCISE_TYPE.WEIGHT_RESISTANCE.text}: </strong>
          {intl.formatMessage({ id: "bplusClinicKey653" })}
        </div>
      ),
    },
    FLEXIBILITY: {
      content: () => (
        <div>
          <strong>{EXERCISE_TYPE.FLEXIBILITY.text}: </strong>
          {intl.formatMessage({ id: "bplusClinicKey654" })}
        </div>
      ),
    },
    CALORIES: {
      content: () => null,
    },
  };

  const handleGetRowProps = () => {
    return {
      style: {
        borderBottom: 0,
      },
    };
  };

  const handleClearInput = () => {
    setExerciseType("");
    setDuration("");
    setCalorie("");
  };

  const handleSaveExerciseRecord = async () => {
    const [res, error] = await props.onEvent({
      message: "SaveExerciseRecord",
      params: {
        data: {
          target_calories: props.exerciseSettingData?.data?.calorie || 0,
          type: exerciseType,
          type_name: props.exerciseTypeList?.find(
            (list) => list.id === exerciseType
          )?.name,
          duration,
          calorie,
        },
      },
    });

    if (!error) {
      handleClearInput();
    }
  };

  const handleRemoveRecord = () => {
    props.onEvent({ message: "DeleteExerciseRecord", params: {} });
  };

  const handleCloseModRemove = () => {
    props.onEvent({ message: "SetModRemoveExercise", params: { clear: true } });
  };

  const handleChangeSelectedDate = (date: string) => {
    props.onEvent({
      message: "handleChangeExerciseFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  const handleGetDataByDate = (event: any, data: any) => {
    let targetDate: any = props.exerciseFilterDate?.clone();
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeExerciseFilterDate",
      params: { date: targetDate },
    });
  };

  return (
    <>
      <PatientPanel patientInfo={props.selectedPatient} />

      <DMDatePicker
        date={props.exerciseFilterDate}
        onChangeStepDateRecord={handleGetDataByDate}
        onChangeDateRecord={handleChangeSelectedDate}
      />

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <Form style={{ padding: "0px 10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "10% 80% 10%",
            marginBottom: "15px",
          }}
        >
          <div>&nbsp;</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DisplayCalorie
              value={`${sumCalories}/${
                props.exerciseSettingData?.data?.calorie || 0
              }`}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <Icon
              name="cog"
              color="blue"
              size="large"
              onClick={() => {
                props.onSettingClick?.();
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "200px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Dropdown
              selection
              options={exerciseTypeOptions}
              value={exerciseType}
              placeholder={intl.formatMessage({ id: "bplusClinicKey718" })}
              onChange={(_e, { value }) => setExerciseType(value)}
            />
          </div>
          <div>
            <Button
              icon="info"
              color="blue"
              style={{ marginLeft: "5px", marginRight: "-43px" }}
              onClick={() => setOpenModExcerciseType(true)}
              circular
              size="mini"
              type="button"
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "190px auto",
            padding: "12px 0",
            justifyContent: "center",
            paddingRight: "10px",
          }}
        >
          <div>
            <Input
              ref={(instance: any) => {
                if (instance) {
                  instance.inputRef.current.style.textAlign = "right";
                }
              }}
              label={{
                basic: true,
                content: `${intl.formatMessage({ id: "bplusClinicKey721" })}`,
              }}
              labelPosition="right"
              value={duration}
              type="number"
              style={{ marginRight: "43px" }}
              onChange={(_e, { value }) => setDuration(value)}
              placeholder={intl.formatMessage({ id: "bplusClinicKey719" })}
            />
          </div>
          <div></div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "190px auto",
            justifyContent: "center",
            paddingRight: "5px",
          }}
        >
          <div>
            <Input
              ref={(instance: any) => {
                if (instance) {
                  instance.inputRef.current.style.textAlign = "right";
                }
              }}
              label={{
                basic: true,
                content: `${intl.formatMessage({ id: "bplusClinicKey99" })}`,
              }}
              labelPosition="right"
              value={calorie}
              type="number"
              style={{
                marginRight: props.language === "th-TH" ? "63px" : "43px",
              }}
              onChange={(_e, { value }) => setCalorie(value)}
              placeholder={intl.formatMessage({ id: "bplusClinicKey720" })}
            />
          </div>
          <div>
            <Button
              icon="save"
              color="blue"
              style={{ marginLeft: "8px", marginRight: "-43px" }}
              onClick={handleSaveExerciseRecord}
              disabled={!exerciseType || !duration}
            />
          </div>
        </div>

        {/* Table Today record*/}
        <div style={{ marginTop: "3em" }}>
          <Header
            as="h3"
            style={{ marginRight: "10px", color: COLOR.medium_blue }}
          >
            <FormattedMessage id="bplusClinicKey492" />
          </Header>

          {props.exerciseRecordList?.items?.length > 0 ? (
            <ReactTable
              className="weight-table-custom"
              data={props.exerciseRecordList?.items}
              columns={columnsRecord}
              pageSize={
                props.exerciseRecordList?.items?.length > 3
                  ? props.exerciseRecordList?.items?.length
                  : 3
              }
              style={{ height: "135px" }}
              showPagination={false}
              resizable={false}
              getTrGroupProps={(state: any, rowInfo: any) => {
                return handleGetRowProps();
              }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <FormattedMessage id="common.no_data" />
            </div>
          )}
        </div>

        <Modal
          open={openModExcerciseType}
          size={"mini"}
          closeOnDimmerClick={true}
          onClose={() => setOpenModExcerciseType(false)}
        >
          <Segment
            inverted
            className={"modHeader " + "blue"}
            style={{ margin: 0 }}
          >
            {intl.formatMessage({ id: "bplusClinicKey651" })}
          </Segment>
          <Segment
            padded
            className="modContent"
            style={{ padding: "0 0 10px", color: "rgba(0,0,0,0.65)" }}
          >
            {(
              [
                "AEROBIC",
                "WEIGHT",
                "FLEXIBILITY",
              ] as (keyof ExerciseTypeDetail)[]
            ).map((key, index) => (
              <div
                key={"exercise-detail" + index}
                style={{
                  padding: "10px",
                  backgroundColor: index === 1 ? "rgba(25,153,243,0.22)" : "",
                }}
              >
                {EXERCISE_TYPE_DETAIL[key]?.content?.()}
              </div>
            ))}
          </Segment>
        </Modal>

        <ModConfirm
          openModal={props.openModRemoveExercise?.open}
          onDeny={handleCloseModRemove}
          onApprove={handleRemoveRecord}
          onCloseWithDimmeClick={handleCloseModRemove}
          titleColor={"blue"}
          titleName="ต้องการลบหรือไม่"
          loading={props.openModRemoveExercise?.loading}
          content={<ErrorMessage error={props.openModRemoveExercise?.error} />}
        />

        {/* <ModInfo
          open={props.openModInfoExercise?.open}
          titleColor={props.openModInfoExercise?.color || "red"}
          titleName={props.openModInfoExercise?.title}
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          {
            props.openModInfoExercise?.error === "string" ?
              <div>{props.openModInfoExercise?.error}</div> :
              <ErrorMessage error={props.openModInfoExercise?.error} />
          }
        </ModInfo> */}
      </Form>
    </>
  );
};

// ------------------------------ DMExercise
type ExerciseDMProps = {
  // Callback
  onEvent: (e: MobEvent) => any;
  onSetBackToApp?: () => void;

  // Data
  loadingStatus?: boolean;
  successMessage: any;
  errorMessage: any;
  selectedPatient: any;
  exerciseFilterDate?: moment.Moment;
  exerciseSettingData: any;
  language?: "en-US" | "th-TH";

  // List
  exerciseTypeList?: any[];
  exerciseRecordList?: any;
  exerciseChartList?: any[];

  // Open
  openModRemoveExercise?: ModExerciseType;
  openModInfoExercise?: ModExerciseType;
};

const ExerciseDMInitial: ExerciseDMProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  successMessage: null,
  errorMessage: null,
  selectedPatient: {},
  exerciseFilterDate: moment(),
  exerciseTypeList: [],
  exerciseRecordList: {},
  exerciseSettingData: {},
};

const DMExercise: React.FC<ExerciseDMProps> = (props) => {
  const intl = useIntl();
  let history = useHistory();

  const [viewIndex, setViewIndex] = useState<number>(0);
  //Open
  const [openScreenSetting, setOpenScreenSetting] = useState(false);
  const [openScreenCameraScan, setOpenScreenCameraScan] = useState(false);
  const [locationKeys, setLocationKeys] = useState<string[]>([]);

  useEffect(() => {
    props.onEvent({
      message: "SetTopBar",
      params: { title: "Exercise", leftIcon: "arrow left" },
    });
  }, []);

  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }

        if (openScreenSetting) {
          setOpenScreenSetting(false);
        }
        if (openScreenCameraScan) {
          setOpenScreenCameraScan(false);
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    props.onEvent({ message: "GetExerciseSetting", params: {} });
  }, [props.selectedPatient]);

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleTabChange = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    if (typeof data.activeIndex === "number") {
      setViewIndex(data.activeIndex);
    }
  };

  const handleSettingClick = () => {
    let address = typeof globalThis !== "undefined" ? `${globalThis.location.search}&setting=true` : `${window.location.search}&setting=true`
    history.push(address);
    setOpenScreenSetting(true);
  };

  const handleLeftIconClick = () => {
    history.goBack();
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Setting */}
      <Slide direction="left" in={openScreenSetting} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: "white",
            position: "fixed",
            top: 0,
            zIndex: 1101,
            width: "100%",
            height: "100%",
            right: "0",
          }}
        >
          <>
            <AppBar position="fixed" color="default">
              <MobHead
                leftIcon="arrow left"
                leftIconClick={handleLeftIconClick}
                title={
                  openScreenSetting
                    ? `${intl.formatMessage({ id: "bplusClinicKey192" })}`
                    : ""
                }
              />
            </AppBar>

            <ExerciseDMSetting
              onEvent={props.onEvent}
              exerciseSettingData={props.exerciseSettingData}
              selectedPatient={props.selectedPatient}
            />
          </>
        </div>
      </Slide>

      <AppBar position="fixed" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "bplusClinicKey192" })}
        />
      </AppBar>

      <Tab
        className="weight-tab-custom dm"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews
        index={viewIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
      >
        <div className="exercise-dm-custom">
          <DetailsTab
            // Callback
            onSettingClick={handleSettingClick}
            exerciseFilterDate={props.exerciseFilterDate}
            {...props}
          />
          {/* <HistoryTab /> */}
        </div>
        <div className="exercise-dm-custom">
          <DMExerciseChart
            onEvent={props.onEvent}
            loadingStatus={props.loadingStatus}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            selectedPatient={props.selectedPatient}
            height={200}
            exerciseChartList={props.exerciseChartList}
            exerciseSettingData={props.exerciseSettingData}
            showCurrentBarDetail={true}
            isMyTab={viewIndex === 1}
            exerciseFilterDate={props.exerciseFilterDate}
          />
        </div>
      </SwipeableViews>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
    </>
  );
};

DMExercise.defaultProps = ExerciseDMInitial;

export default React.memo(DMExercise);
