
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import DivisionSerializer from '../types/DivisionSerializer_core'


const HOST = `${config.API_HOST}`
/* ['generics.ListAPIView'] */
/* params: 'code', 'code_contains', 'name', 'name_code_contains', 'type', 'exclude_self', 'filter_clinic', 'for_opd_encounter', 'for_ipd_encounter', 'for_order_div', 'exclude_type', 'is_telemed', 'only_have_dsb', 'organization', 'same_org_as_div', 'also_inactive', 'set_storage', 'get_encounter_div' */
/* data:  */
const DivisionList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/core/division/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default DivisionList

