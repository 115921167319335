import React from "react";
import { Menu } from "semantic-ui-react";

const HeaderConsentForm = (props: any) => {
  return (
    <div className="consent-header">
      {!props.hideLeftItem ? (
        <div className="consent-header-left"> {props.leftItem} </div>
      ) : (
        <div className="consent-header-left"></div>
      )}
      <div className="consent-header-title">
        {" "}
        {
          props.middleItem
        } <div className="consent-header-title-redline"></div>{" "}
      </div>
      {!props.hideRightItem ? (
        <div className="consent-header-right"> {props.rightItem} </div>
      ) : !props.hideRightClose ? (
        <div className="consent-header-right-close"> {props.rightClose} </div>
      ) : (
        <div className="consent-header-right"></div>
      )}
    </div>
    // <Menu size="large">
    //   {!props.hideLeftItem && <Menu.Item>{props.leftItem}</Menu.Item>}

    //   <Menu.Item className="chat-channel-name">{props.middleItem}</Menu.Item>

    //   {!props.hideRightItem && <Menu.Item position={"right"} className="right-item">
    //     {props.rightItem}
    //   </Menu.Item>}
    // </Menu>
  );
};

HeaderConsentForm.defaultProps = {
  leftItem: null,
  middleItem: null,
  rightItem: null,
  rightClose: null,
  menuBarClassName: "",
  hideLeftItem: false,
  hideRightItem: false,
  hideRightClose: false,
};

export default HeaderConsentForm;
