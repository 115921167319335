import React, { CSSProperties, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import icon_collapse from "./image/icon_collapse.png";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Grid, Icon, Image, Divider, Button } from "semantic-ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./MainLabResultCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  button_back: {
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: COLOR.font_white
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin:"0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_DarkGray_14_normal: {
    fontSize: "14px",
    color: COLOR.DarkGray_Line,
  } as CSSProperties,
  text_white_17: {
    fontSize: "17px",
    fontWeight: "bold",
    color: COLOR.font_white,
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_14: {
    minHeight: "20px",
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
  } as CSSProperties,
  division_detail: {
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  headerSummary: {
    width: "100%",
    minHeight: "30px",
    maxHeight: "30px",
    background: COLOR.superligtblue,
    padding: "10px 10px",
    borderRadius: "5px",
  } as CSSProperties,
};

type CardDetailProps = {
  onEvent: any;
  onClose?: () => any;
  labData?: any;
  selectedHospital?: any;
  pdfDownloadStatus?: boolean;
} & WrappedComponentProps;

const CardDetailInitial: Omit<CardDetailProps, "intl"> = {
  onEvent: () => null,
  onClose: () => null,
  labData: null,
  selectedHospital: null,
  pdfDownloadStatus: false,
};

type labOrders = {
  division_name: string | null;
  timelab: string | null;
  items: labOderData[];
};

type labOderData = {
  name: string | null;
  value: string | null;
  raf_value: string | null;
  unit: string | null;
  labStatus: string | null;
};

const CardDetail: React.FC<CardDetailProps> = (props) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [labOderItem, setLabOderItem] = useState<labOrders[]>([]);
  const [allLabParent, setAllLabParent] = useState<any[]>([]);

  const [latestVisitNumber, setLatestVisitNumber] = useState("");
  const [latestVisitDate, setLatestVisitDate] = useState("");

  useEffect(() => {
    setExpanded([]);
    if (props.labData !== null) {
      const labOder = props.labData.lab_order;
      console.log("saika props.labData: ", labOder);
      const filterResultsNotNull = labOder.filter(
        (item: any) => item.children !== null
      );
      let defaultExpanded: any[] = [];
      let labOrder: labOrders[] = [];
      let allLabParent: any[] = [];
      filterResultsNotNull.map((labItem: any, index: any) => {
        let labdata: labOderData[] = [];
        labdata.length = 0;
        let timeDivision: string = "";
        // console.log("saika division: ", division);
        const filterResultsNotNull = labItem.children.filter(
          (item: any) => item.history_results !== null
        );
        filterResultsNotNull.map((item: any) => {
          console.log(item)
          let raf: string = "";
          let value: string = "";
          let status: string = "normal";
          const lastArray =
            item.history_results[item.history_results.length - 1];
          value = lastArray.value;
          timeDivision = lastArray.reported_datetime;
          console.log("history_result", item.history_results)
          setLatestVisitNumber(lastArray.visit_number);
          setLatestVisitDate(lastArray.visit_date)
          if (lastArray !== null && !isNaN(+lastArray.value)) {
            raf = lastArray.ref_value_txt;
            let tmpMin: number = 0;
            let tmpMax: number = 0;
            let tmpRef: string = lastArray.ref_value_txt;
            tmpRef = tmpRef.replace("(", "");
            tmpRef = tmpRef.replace(")", "");
            tmpRef = tmpRef.replace("=", "");
            if (tmpRef.search("<") > -1) {
              tmpMin = lastArray.value;
              tmpMax = parseFloat(tmpRef.replace("<", ""));
              if (tmpMin >= tmpMax) {
                status = "high";
              }
            } else if (tmpRef.search(">") > -1) {
              tmpMin = parseFloat(tmpRef.replace(">", ""));
              tmpMax = lastArray.value;
              if (tmpMin >= tmpMax) {
                status = "low";
              }
            } else if (tmpRef.search("-") > -1) {
              const tmpArr = tmpRef.split("-");
              tmpMin = parseFloat(tmpArr[0]);
              tmpMax = parseFloat(tmpArr[1]);
              if (tmpMin <= parseFloat(value) && parseFloat(value) <= tmpMax) {
                status = "normal";
              } else if (tmpMin > parseFloat(value)) {
                status = "low";
              } else if (parseFloat(value) > tmpMax) {
                status = "high";
              }
            }
          } else {
            if (value === "DETECTED") {
              status = "DETECTED";
            } else {
              status = "normal";
            }
          }

          let criticalFlag = lastArray.critical_flag;
          if (criticalFlag !== "") {
            switch(criticalFlag) {
              case "H":
                status = "high";
                break;
              case "L":
                status = "low";
                break;
              default:
                break;
            }
            
          }
          
          var filteredUnit = item.unit
          if (item.lab_code.startsWith("M438")) {
            filteredUnit = ""
          }
          labdata.push({
            name: item.name,
            value: lastArray.value,
            raf_value: raf,
            unit: filteredUnit,
            labStatus: status,
          });
          // console.log("saika labdata: ", labdata);
        });
        defaultExpanded.push(`panel${index}`);
        labOrder.push({
          division_name: labItem.name,
          timelab: timeDivision,
          items: labdata,
        });
        allLabParent.push(labItem.name);
      });
      // console.log("saika labOrder: ", labOrder);
      // console.log("saika defaultExpanded", defaultExpanded);
      setExpanded(defaultExpanded);
      setLabOderItem(labOrder);
      setAllLabParent(allLabParent);
    }
  }, [props.labData]);

  const handleChange = (panel: any) => {
    const tmpExpanded = expanded;
    if (!expanded.includes(panel)) {
      tmpExpanded.push(panel);
    } else {
      const indexPanel = tmpExpanded.findIndex((item) => item === panel);
      tmpExpanded.splice(indexPanel, 1);
    }
    setExpanded([...tmpExpanded]);
  };

  const handleChangeAll = () => {
    if (expanded.length > 0) {
      setExpanded([]);
    } else {
      const tmpExpanded: any[] = [];
      allLabParent.map((item, index) => {
        if (!expanded.includes(`panel${index}`)) {
          tmpExpanded.push(`panel${index}`);
        }
      });
      setExpanded([...tmpExpanded]);
    }
  };

  const handleDownloadFile = () => {

    const url = "apis/PRX/v3/lab_report_file/?en="+latestVisitNumber+"&hospital="+props.selectedHospital?.code;

    console.log("globalThis", globalThis);
    console.log("----- typeof globalThis.mobile", typeof globalThis.mobile);

    props.onEvent({
      message: "handleDownloadLabResult",
      params: { url: url, filename: `${latestVisitDate}.pdf` },
    });

    // handleCloseModConfirm();
  };

  return (
    <div className="CardDetail" style={{ padding: "10px 10px 30px 10px" }}>
      <div style={{ position: "absolute" }}>
        <Button
          icon
          size="large"
          onClick={props.onClose}
          style={styles.button_back}
        >
          <Icon name="angle left" />
        </Button>
      </div>
      <div style={{ ...styles.parentbox }}>
        <div style={{ margin: " 35px 0 10px" }}>
          <div style={{ ...styles.title, margin: "0px 10px" }}> <FormattedMessage id="bplusClinicKey937" /></div>
          <Divider
            style={{ background: "red", marginTop: "5px", marginBottom: "5px" }}
            variant="middle"
          />
        </div>
      </div>

      {/* Title detail */}

      <div style={{ margin: "0px" }}>
        <Grid style={{ margin: "0" }}>
          <Grid.Row style={{ padding: "2px 0" }}>
            <Grid.Column width={5}>
              <div style={styles.text_bluebdms_14}><FormattedMessage id="bplusClinicKey944" /></div>
            </Grid.Column>
            <Grid.Column width={11}>
              <div style={styles.text_DarkGray_14_normal}>
                {moment(props.labData.started).format("DD MMM YYYY")}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ padding: "2px 0" }}>
            <Grid.Column width={5}>
              <div style={styles.text_bluebdms_14}><FormattedMessage id="bplusClinicKey927" /></div>
            </Grid.Column>
            <Grid.Column width={11}>
              <div style={styles.text_DarkGray_14_normal}>
                {props.labData.division_name || ""}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ padding: "2px 0" }}>
            <Grid.Column width={5}>
              <div style={styles.text_bluebdms_14}><FormattedMessage id="bplusClinicKey943" /></div>
            </Grid.Column>
            <Grid.Column width={11}>
              <div style={styles.text_DarkGray_14_normal}>
                {/* {props.labData.hospital_name || ""} */}
                {props.labData.hospital_name  || props.selectedHospital?.name || ""}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {/* Button Download & Collapse */}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 0",
        }}
      >
        <Button
          style={{
            background: `linear-gradient(180deg, #0147A3 0%, #0B87CD 100%)`,
            padding: "10px",
          }}
          loading={props.pdfDownloadStatus}
          onClick={handleDownloadFile}
        >
          <div style={styles.text_white_17}><FormattedMessage id="bplusClinicKey163" /></div>
        </Button>
        <Button
          style={{
            display: "flex",
            background: `linear-gradient(180deg, #0147A3 0%, #0B87CD 100%)`,
            padding: "10px",
            width: "140px",
          }}
          onClick={handleChangeAll}
        >
          {expanded.length > 0 ? (
            <>
              <div style={{ width: "15px", marginRight: "5px" }}>
                <Image src={icon_collapse} />
              </div>
              <div style={styles.text_white_17}><FormattedMessage id="bplusClinicKey945" /></div>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "15px",
                  marginRight: "5px",
                  transform: "rotate(180deg)",
                }}
              >
                <Image src={icon_collapse} />
              </div>
              <div style={styles.text_white_17}><FormattedMessage id="bplusClinicKey946" /></div>
            </>
          )}
        </Button>
      </div>

      {/* All Lab detail */}

      <div>
        {labOderItem &&
          labOderItem.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded.includes(`panel${index}`)}
              onChange={() => handleChange(`panel${index}`)}
              style={{
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
                marginBottom: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                style={styles.headerSummary}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "10px",
                  }}
                >
                  <div
                    style={{
                      ...styles.text_DarkGray_14,
                      color: COLOR.superdarkgray,
                    }}
                  >
                    {item.division_name}
                  </div>
                  <div
                    style={{
                      ...styles.text_DarkGray_14_normal,
                      color: COLOR.superdarkgray,
                    }}
                  >
                    {item.timelab
                      ? `[${moment(item.timelab).format("HH:mm")}]`
                      : ""}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {item.items
                  ? item.items.map((item: any, index: any) => (
                      <div>
                        {/* Lab details */}
                        <div style={styles.division_detail}>
                          <div style={{ width: "40%" }}>
                            {item.name ? item.name : "-"}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "60%",
                            }}
                          >
                            <div>
                              {item.labStatus === "high" ? (
                                <Icon name="arrow up" color="red" />
                              ) : item.labStatus === "low" ? (
                                <Icon name="arrow down" color="red" />
                              ) : item.labStatus === "normal" ? (
                                ""
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              style={{
                                ...styles.text_bluebdms_14,
                                color:
                                  item.labStatus === "normal"
                                    ? COLOR.BlueBDMS
                                    : "red",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              {item.value}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            ...styles.division_detail,
                            color: COLOR.DarkGray_Line,
                          }}
                        >
                          <div>{item.raf_value || ""}</div>
                          <div>{item.unit || ""}</div>
                        </div>
                        <Divider style={{ margin: "5px 0" }} />
                      </div>
                    ))
                  : ""}
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div>
  );
};

CardDetail.defaultProps = CardDetailInitial;

export default React.memo(injectIntl(CardDetail));
