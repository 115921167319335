import { WasmHandler } from "react-lib/frameworks/WasmController";

import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import CONFIG from "config/config";
import firebaseConfig from "../../_manager/firebase-config";

// APIS

// Interface
import { GetClinicalSegment } from "../manage/ClinicContentInterface";
import { State as MainState } from "../ManageInterface";
import { SEGMENT_TYPE, commonListPatientSegmentList } from "../ManageInterface";
import {
  HandleSetMarketingIndex,
  EngagementData,
  CommonData,
  GetMarketing,
  CreateEngagement,
} from "./PostExInterface";
import PatientSegmentList from "issara-sdk/apis/PatientSegmentList_apps_PHR";

export type State = Partial<{
  marketingAnalytics?: MarketingAnalyticsType;
  marketingAnalyticsSegment: string[];
  loadingMarketingAnalytics: boolean;
  loadingAnalyticsSegment: boolean;
  loadingPatientAnalytics: boolean;
  patientAnalyticsDetail: any;
  analyticPatientList: any;
  patientSegmentCancelToken?: any;
}>;

export type MarketingAnalyticsType = {
  // Record1 date YYYYMMDD
  all_content_relevant_daily?: Record<string, ActionCountType>;
  daily_active_user?: string;
  // Record1 age ranges
  graph_detail?: Record<string, GraphDetailType>;
  last_updated?: string;
  // Record1 id firebase
  post_engagement_lifetime?: Record<string, LifetimeType>;
  // Record1 id firebase , Record 2 date YYYYMMDD
  post_relevant_daily?: Record<string, Record<string, ActionCountType>>;
  promotion_engagement_lifetime?: Record<string, LifetimeType>;
  segment_name?: string;
  total_female?: string;
  total_male?: string;
  total_member?: string;
  user_relevant_lifetime?: Record<string, UserRelevantLifetimeType>;
};

type GraphDetailType = {
  gender?: string;
  count?: number;
};

export type LifetimeType = {
  content_type?: string;
  action_counts?: number;
  current_segment_counts?: number;
  title?: string;
};

export type ActionCountType = {
  engagement_counts?: number;
  reach_counts?: number;
  view_counts?: number;
  impression_counts?: number;
  click_counts?: number;
  like_counts?: number;
  share_counts?: number;
  save_counts?: number;
  appointment_counts?: number;
  child_counts?: number;
  adult_counts?: number;
  elderly_counts?: number;
};

export type UserRelevantLifetimeType = {
  patient_name?: string;
  gender?: string;
  age_range?: string;
  engagement_counts?: number;
  reach_counts?: number;
  view_counts?: number;
  impression_counts?: number;
  click_counts?: number;
  like_counts?: number;
  share_counts?: number;
  save_counts?: number;
  appointment_counts?: number;
  buy_counts?: number;
};

type Picked = Pick<
  MainState,
  | "selectedDivisionId"
  | "errorMessage"
  | "successMessage"
  | "divisionHasUser"
  | "selectedDivision"
  // | "organizationIndexList"
>;

export const StateInitial: Required<State> = {
  marketingAnalytics: {},
  marketingAnalyticsSegment: [],
  loadingMarketingAnalytics: false,
  loadingAnalyticsSegment: false,
  loadingPatientAnalytics: false,
  patientAnalyticsDetail: {},
  analyticPatientList: [],
  patientSegmentCancelToken: null,
};

const POST_DIVISION = "issara-content";
const GS_BUCKET_NAME = `gs://${CONFIG.PROMOTION_STORAGE_NAME}`;
const DIVISION_INDEX = "divisionIndex";
const ORGANIZATION_INDEX = "organizationIndex";
const POST_ENGAGEMENT = "PostEngagement";
const issara = false;

export type Event =
  // GET
  | { message: "handleGetMarketingSegmentStorage"; params: {} }
  | {
      message: "handleGetMarketingPatientListByHospital";
      params: {
        result: any;
        organization: number;
        limit: number;
        offset: number;
      };
    }
  | { message: "GetPatientAnalytics"; params: { patientId: string } }
  | { message: "GetMarketingAnalytics"; params: { name: string } };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event, Data>;

export const handleGetMarketingSegmentStorage: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingAnalyticsSegment: true });
  try {
    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json");
    }
    let config = firebaseConfig.find(
      (item: any) => item.projectId === firebase_project
    );

    // let result: any = await controller.storage
    //   .refFromURL(`${config?.gsBucketName}`)
    //   .child(`/AnalyticsDatasource/segment`)
    //   .listAll();

    let result: any = await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      .child(`/AnalyticsDatasource/segment/index.json`)
      .getDownloadURL()
      .then((url) => {
        return axios.get(url);
      });
    // console.log("saika res list ", result.data );   
    controller.setState({
      loadingAnalyticsSegment: false,
      marketingAnalyticsSegment: result?.data || [],
    });
  } catch (error) {
    console.log(
      "Error getting post content from firebase : handleGetMarketingSegmentStorage"
    );
    controller.setState({
      marketingAnalyticsSegment: [],
      loadingAnalyticsSegment: false,
    });
  }
};

export const GetMarketingAnalytics: Handler = async (controller, params) => {
  controller.setState({ loadingMarketingAnalytics: true });
  try {
    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json");
    }
    let config = firebaseConfig.find(
      (item: any) => item.projectId === firebase_project
    );

    let result: any = await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      .child(`/AnalyticsDatasource/segment/${params.name}`)
      .getDownloadURL()
      .then((url) => {
        return axios.get(url);
      });

    // console.log("saika GetListMarketingList ", result?.data || []);

    controller.setState({
      marketingAnalytics: result?.data || {},
      loadingMarketingAnalytics: false,
    });
  } catch (error) {
    console.log(
      "Error getting post content from firebase : GetMarketingAnalytics"
    );
    controller.setState({
      marketingAnalytics: {},
      loadingMarketingAnalytics: false,
    });
  }
};

export const GetPatientAnalytics: Handler = async (controller, params) => {
  controller.setState({ loadingPatientAnalytics: true });
  try {
    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json");
    }
    let config = firebaseConfig.find(
      (item: any) => item.projectId === firebase_project
    );

    let result: any = await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      .child(
        `/AnalyticsDatasource/individual_engagement/${params.patientId}.json`
      )
      .getDownloadURL()
      .then((url) => {
        return axios.get(url);
      });

    // console.log("saika GetPatientAnalytics ", result?.data || []);

    controller.setState({
      patientAnalyticsDetail: result?.data || {},
      loadingPatientAnalytics: false,
    });
  } catch (error) {
    console.log(
      "Error getting post content from firebase : GetPatientAnalytics"
    );
    controller.setState({
      patientAnalyticsDetail: {},
      loadingPatientAnalytics: false,
    });
  }
};

export const handleGetMarketingPatientListByHospital: Handler = async (
  controller,
  params
) => {
  controller.setState({
    loadingMarketingPatient: true,
    marketingCreatePatient: null,
  } as any);

  // const source :CancelTokenSource = axios.CancelToken.source();
  const state = controller.getState();

  if (state.patientSegmentCancelToken) {
    state.patientSegmentCancelToken.abort();
  }

  const abortController = new AbortController()
  controller.setState({
    patientSegmentCancelToken: abortController,
  });

  const [response, error] = await PatientSegmentList.list({
    apiToken: controller.apiToken,
    params: params,
    extra: {
      signal: abortController.signal,
    },
  });

  if (error) {
    controller.setState({
      loadingMarketingPatient: false,
      errorMessage: error,
      successMessage: null,
      analyticPatientList: { next: null, previous: null, total: 0, items: [] },
      patientSegmentCancelToken: null,
    } as any);
  } else if (response) {
    controller.setState({
      loadingMarketingPatient: false,
      errorMessage: null,
      successMessage: null,
      analyticPatientList: response,
      patientSegmentCancelToken: null,
    } as any);
  } else if (response === null && !error) {
    // case cancel do noting
  }
};
