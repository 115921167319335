import React from "react";
import { Loader } from "semantic-ui-react";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import InfoIcon from "@mui/icons-material/Info";
import { FormattedMessage } from "react-intl";

// Types
type ModFaceScanCompleteProps = {
  type: "success" | "partial_success";
  description?: string;
  loading?: boolean;
  // callback
  onSuccess: () => any;
  onRescan: () => any;
};

// Images
const IMAGES = {
  check: "/images/register/success-check.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  grey: "#2c2c2c",
  very_light_blue: "#d6e1f1",
  yellow: "rgba(255, 221, 122, 1)",
  light_yellow: "rgba(255, 248, 227, 1)",
  heavy_yellow: "rgba(248, 187, 7, 1)",
};

export const cardPartialSuccess = {
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  borderRadius: "8px",
  backgroundColor: COLORS.light_yellow,
  width: "100%",
  height: "100%",
  padding: "10px",
  border: `1px solid ${COLORS.yellow} !important`,
  margin: "0.75rem 0 2rem",
  lineHeight: 1.5,
  color: COLORS.heavy_yellow,
  "& > div:nth-child(1)": {
    alignItems: "center",
    display: "flex",
    height: "100%",
    paddingRight: "0.55rem",
    "& svg": {
      fontSize: "2rem !important",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  card: cardPartialSuccess,
}));

const ModFaceScanComplete = (props: ModFaceScanCompleteProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} className="smart-dialog-container">
        <DialogContent>
          <div className="content">
            <img
              src={IMAGES.check}
              alt="check"
              style={{ width: "4rem", margin: "-1rem 0 1rem" }}
            />
            <div
              className="title"
              style={{ marginBottom: "0.25rem", lineHeight: 1.35 }}
            >
              {props.type === "success" ? (
                <FormattedMessage id="bplusClinicKey1047" />
              ) : (
                <FormattedMessage id="bplusClinicKey1048" />
              )}
            </div>
            <div style={{ padding: "0.25rem 1rem 0" }}>
              {props.type === "success" ? (
                <FormattedMessage id="bplusClinicKey1049" />
              ) : (
                <FormattedMessage id="bplusClinicKey1050" />
              )}
              {props.description?.split("\n").map((text) => (
                <div key={text}>{text}</div>
              ))}
            </div>
          </div>

          {props.type === "partial_success" && (
            <div className={classes.card}>
              <div>
                <InfoIcon />
              </div>
              <div style={{ whiteSpace: "pre-line" }}>
                <FormattedMessage id="bplusClinicKey1051" />
              </div>
            </div>
          )}

          <div className="button-submit-bottom" style={{ marginTop: "1.5rem" }}>
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!!props.loading}
              style={{ width: "87.5%" }}
              onClick={props.onSuccess}
            >
              <FormattedMessage id="common.completed" />
              <Loader
                size="small"
                active={!!props.loading}
                inline={true}
                style={{ marginLeft: "0.5rem" }}
              ></Loader>
            </MuiButton>
          </div>

          {props.type === "partial_success" && (
            <div
              aria-hidden="true"
              style={{
                textAlign: "center",
                color: COLORS.blue,
                marginBottom: "1rem",
              }}
              onClick={props.onRescan}
            >
              <FormattedMessage id="bplusClinicKey1045" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(ModFaceScanComplete);
