import { WasmHandler } from "react-lib/frameworks/WasmController";

import Cookies from "js-cookie";

// Interface
import * as FeedI from "./feed/FeedI";
import * as NearbyI from "./feed/NearbyI";
import * as PostI from "./post/PostI";
import * as FeedInterface from "./native/FeedInterface";
import * as LoginI from "./login/LoginInterface";
import * as MobContentI from "./feed/MobContentInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobSmartRegisterI from "./MobSmartRegisterInterface";
import * as MobPaymentI from "./MobPaymentInterface";

// APIs
import V3TodayEvent from "../issara-sdk/apis/V3TodayEvent_apps_PRX";
import UserWithPatientDetail from "issara-sdk/apis/UserWithPatientDetail_users";
import V3AppointmentView from "../issara-sdk/apis/V3AppointmentView_apps_PRX";
import UserNotificationListView from "../issara-sdk/apis/UserNotificationListView_apps_NTF";
import ProxyMyProfile from "../issara-sdk/apis/ProxyMyProfile_profileM";
import V3SaveDrugPrescriptionListView from "../issara-sdk/apis/V3SaveDrugPrescriptionListView_apps_PRX";
import { LoginFirebase1 } from "./login/LoginInterface";
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";

import {
  GetProgramSpecialty,
  goToCheckupData,
} from "./appointment/MakeAppointmentInterface";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const params = new URLSearchParams(window.location.search);
// const patientId = params.get('patientId').toString();

export type State = {
  message?: string;
  device_id?: string;
  subscription?: string;
  query_params?: string;
  platform?: string;
  firebaseToken?: string;
  language?: "en-US" | "th-TH";
  patientId?: string;
  firebaseUID?: string;
  patientInfo?: any;
  mobTitle?: string;
  leftIcon?: string;
  rightIcon?: string;
  isAgreeConcent?: boolean;
  apiToken?: string;
  userId?: string;
  loggedinSuccess?: boolean;
  userNotificationList?: [];
  showBadgeNotification?: boolean;
  myProfileDetail?: Record<string, any> | null;
  isLoadingApiToken?: boolean;
  saveDrugList?: any;
  saveDrugListLoading?: boolean;

  todayEventList?: any[];
  patientDetail?: any;
  upcomingAppointment?: any;
  loadingMainFeed?: boolean;
  firstLoadingNotification?: boolean;
  servicesOfHospitalFB?: any[];
  eKYCStatus?: Record<string, any>;
  bilNumber?: string;

  // config_PRX_ENABLE_ECOUPON?: boolean;
  // loadingConfigEnableEcouponSuccess?: boolean;
  selectedMenuBar?: MenuBarType;
  loadingHospital?: boolean;
} & LoginI.State &
  FeedI.State &
  NearbyI.State &
  PostI.State &
  MobContentI.State &
  SelectHospitalI.State &
  MobSmartRegisterI.State &
  MobPaymentI.State;

export type MenuBarType = "feed" | "preferred" | "favorite" | "saved";

export const StateInitial: State = {
  message: "ทดสอบ",
  device_id: "",
  subscription: "",
  query_params: params.toString(),
  platform: "",
  firebaseToken: "",
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  patientId: "",
  firebaseUID: "",
  patientInfo: {},
  mobTitle: "",
  leftIcon: "",
  rightIcon: "logout",
  isAgreeConcent: false,
  userId: "",
  loggedinSuccess: false,
  userNotificationList: [],
  showBadgeNotification: false,
  myProfileDetail: {},
  isLoadingApiToken: false,

  todayEventList: [],
  patientDetail: {},
  upcomingAppointment: [],
  loadingMainFeed: false,
  firstLoadingNotification: false,
  servicesOfHospitalFB: [],
  selectedMenuBar: "feed",
  hospitalList: [],
  loadingHospital: false,

  ...LoginI.StateInitial,
  ...FeedI.StateInitial,
  ...NearbyI.StateInitial,
  ...PostI.StateInitial,
  ...MobContentI.StateInitial,
  ...SelectHospitalI.StateInitial,
  ...MobSmartRegisterI.StateInitial,
  ...MobPaymentI.StateInitial,
};

export type Event =
  | { message: "FirebaseSetupLoginHandler"; params: {} }
  | { message: "HandelCloseForceSelect"; params: {} }
  | { message: "FirebaseLoginWithUserID"; params: { userId?: string } }
  | { message: "SavePHR"; params: { measure: string; value: number } }
  | { message: "AgreeConcent"; params: {} }
  | {
      message: "SetTopBar";
      params: { title: string; leftIcon?: string; rightIcon?: string };
    }
  | { message: "HandleEvent"; params: { name: string } }
  | { message: "goToAppointment"; params: { appId: string } }
  | { message: "GetListTodayEvent"; params: {} }
  | { message: "GetUserWithPatientDetail"; params: {} }
  | {
      message: "GetListDirectAppointment";
      params: { next_appointment?: boolean };
    }
  | {
      message: "HandleClickMenuCheckup";
      params: { med_program: number; history: any };
    }
  | { message: "SetLoggedinSuccess"; params: {} }
  | { message: "GetUserNotificationList"; params: {} }
  | { message: "GetMyProfileDetail"; params: {} }
  // { message: "GetConfigPrxEnableEcoupon", params: {} } |
  | { message: "GetSaveDrugList"; params: {} }
  | {
      message: "HandleSelectedMenuBar";
      params: { menu: "feed" | "preferred" | "favorite" | "saved" };
    }
  | { message: "shareContent"; params: { contentUrl: string } }
  | { message: "seeMoreContent"; params: { contentUrl: string } }
  | { message: "GetCurrentServiceFromFirestore"; params: { code: string } | {} }
  | { message: "DidMount"; params: {} }
  | LoginI.Event
  | NearbyI.Event
  | PostI.Event
  | FeedInterface.Event
  | MobContentI.Event
  | SelectHospitalI.Event
  | MobSmartRegisterI.Event
  | MobPaymentI.Event;

export type Data = {} & LoginI.Data;

export const DataInitial = {
  ...LoginI.DataInitial,
};

export type Handler = WasmHandler<State, Event>;

export const DidMount: Handler = async (controller, params) => {
  console.log("MobFeed DidMount: ");
  SelectHospitalI.SelectedInitialHospital(controller, params);
};

export const SavePHR: Handler = async (controller, params) => {
  let user = controller.app.auth().currentUser.email;
  let mapping: { [ke: string]: string } = {
    Lab: "CentralLabResult_apps_LAB",
    VitalSign: "VitalSignValue_apps_PTM",
    Nutrition: "Nutrition",
    Exercise: "Exercise",
    Emotion: "Emotion",
  };
  if (user) {
    if (
      ["Lab", "VitalSign", "Nutrition", "Exercise", "Emotion"].includes(
        params.type
      )
    ) {
      let collectionName = mapping[params.type];

      const docRef = await addDoc(collection(controller.db, collectionName), {
        date: new Date(),
        patientId: user,
        fromPatient: true,
        ...params.data,
        // measure: params.measure,
        // value: params.value,
      });

      params?.callback(docRef);
      
      // controller.db
      //   .collection(collectionName)
      //   .add({
      //     date: new Date(),
      //     patientId: user,
      //     fromPatient: true,
      //     ...params.data,
      //     // measure: params.measure,
      //     // value: params.value,
      //   })
      // .then((res) => {
      //   params?.callback(res);
      // })
      // .catch((res) => {
      //   params?.callback(res);
      // });
    }
  }
};

export const AgreeConcent: Handler = (controller, params) => {
  controller.setState({ isAgreeConcent: true });
  console.log(" controller.history", controller.history);
  controller.history.push("/clinicdm");
};

export const SetTopBar: Handler = (controller, params) => {
  let title = params.title;
  let left = params.leftIcon;
  let right = params.rightIcon;
  console.log("SetTopBar title", title, left, right);
  controller.setState({
    mobTitle: title,
    ...(left && { leftIcon: left }),
    ...(right && { rightIcon: right }),
  });
};

export const GetPatientProfile: Handler = async (controller, params) => {
  const patientId = controller.getState().patientId;
  console.log(patientId);
  if (patientId) {

    const docRef = doc(controller.db, "Patient_core", patientId);
    const docSnap = await getDoc(docRef);

    const patientInfo = docSnap?.data();
    console.log(patientInfo);
    controller.setState(
      { patientInfo: patientInfo },
      params?.callback || (() => {})
    );

    // controller.db
    //   .collection("Patient_core")
    //   .doc(patientId)
    //   .get()
    //   .then((res: any) => {
    //     const patientInfo = res.data();
    //     console.log(patientInfo);
    //     controller.setState(
    //       { patientInfo: patientInfo },
    //       params?.callback || (() => {})
    //     );
    //   });
  }
};

// Firebase -- For prototyping -----------------------------------------------------
export const FirebaseSetupLoginHandler: Handler = (controller, params) => {
  // For prototyping -- set up callback for firebase login
  const onFirebaseAuthStateChanged = async (user: any) => {
    console.log("onFirebaseAuthStateChanged user: ", user);
    console.log("onFirebaseAuthStateChanged user?.isAnonymous: ", user?.isAnonymous);

    let userId = controller.cookies.get("userId");
    let apiToken = controller.cookies.get("apiToken");

    console.log('onFirebaseAuthStateChanged userId: ', userId);
    console.log('onFirebaseAuthStateChanged apiToken: ', apiToken);

    // Switch
    if (user?.isAnonymous === false && !userId && !apiToken) {
      //
      // controller.app.auth().signOut().then(() =>{

      // })
      console.log("onFirebaseAuthStateChanged Need User ->  anonymous");
      controller.app
        .auth()
        .signOut()
        .then((res: any) => {
          controller.setState({ selectedHospital: null });
          console.log("onFirebaseAuthStateChanged res", res);
          controller.setState({ firebaseUID: "" });
        });
      return;
    } else if (user?.isAnonymous === true && userId && apiToken) {
      console.log("onFirebaseAuthStateChanged Need anonymous -> User ");
      controller.app
        .auth()
        .signOut()
        .then((res: any) => {
          controller.setState({ selectedHospital: null });
          console.log("onFirebaseAuthStateChanged res", res);
          controller.setState({ firebaseUID: "" });
        });
      return;
    }

    // normal case

    if (user) {
      console.log("user: ", user.uid);
      controller.setState({ loggedin: true });

      if (!user.isAnonymous) {
        // save Patient_core เมื่อ เป็น user จริง

        const docRef = doc(controller.db, "Patient_core", user.uid);
        const docSnap = await getDoc(docRef);

        // const patient = await controller.db
        //   .collection("Patient_core")
        //   .doc(user.uid)
        //   .get();


        if (!docSnap?.data()) {
          const patientCoreCollectionRef = doc(controller.db, "Patient_core", user.uid);
          await setDoc(patientCoreCollectionRef, {
            name: user.uid,
          }).catch((e) => {
            console.error("setDoc : error ->", e)
             // Throw an actual error. 
          });
          // await controller.db.collection("Patient_core").doc(user.uid).set({
          //   name: user.uid,
          // });
        }
        FeedI.GetPatientInfo(controller as any, { patientId: user.uid });
      }

      controller.setState({ firebaseUID: user.uid });
    } else {
      console.log("no user");
      const apiToken = controller.cookies.get("apiToken");
      const userId = controller.cookies.get("userId");

      if (apiToken && userId) {
        console.log(" LoginFirebase1 from MobFeed");
        LoginFirebase1(controller, { apiToken: apiToken });
      } else {
        console.log("signInAnonymously");
        controller.app
          .auth()
          .signInAnonymously()
          .then((credential: any) => {
            console.log("Login Anonymous success credential: ", credential);
            controller.setState({ firebaseUID: credential.user.uid });
            // const {language} = controller.getState()
            // console.log('language: ', language);
            // controller.setState({selectedHospital: {
            //   code: "1",
            //   name:
            //     language === "th-TH"
            //       ? "โรงพยาบาลกรุงเทพ สำนักงานใหญ่"
            //       : "Bangkok Hospital Headquarters",
            // }})
          })
          .catch((error: any) => {
            console.warn("Login Anonymous error", error);
          });
      }
    }
  };
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
};

export const FirebaseLoginWithUserID: Handler = (controller, params) => {
  console.log("FirebaseLoginWithUserID: ");
  if (controller.app.auth().currentUser) {
    console.log(controller.app.auth().currentUser);
    return;
  }
  controller.functions
    .httpsCallable("createTokenFromUser")(params.userId)
    .then((result: any) => {
      controller.setState({ firebaseToken: result.data.toString() });
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .then(async (res: any) => {
          console.log("login firebase");
          // GetAppointment(controller, {})

          const docRef = doc(controller.db, "Patient_core", params.userId);
          const docSnap = await getDoc(docRef);

          // const docs = await controller.db
          //   .collection("Patient_core")
          //   .doc(params.userId)
          //   .get();
            
          console.log(docSnap?.data);
        })
        .catch(function (error: any) {
          console.log(error);
        });
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const GetListTodayEvent: Handler = async (controller, params) => {
  const [res, error] = await V3TodayEvent.get({
    apiToken: controller.cookies.get("apiToken"),
  });
  controller.setState({ todayEventList: res });
};

export const GetUserWithPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await UserWithPatientDetail.retrieve({
    pk: controller.cookies.get("userId"),
    apiToken: controller.cookies.get("apiToken"),
  });

  controller.setState({ patientDetail: res || {} });
};

export const GetListDirectAppointment: Handler = async (controller, params) => {
  const [res, error] = await V3AppointmentView.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      ...params,
      hospital: "all",
    },
  });

  controller.setState({ upcomingAppointment: res?.items || [] });
};

export const HandleClickMenuCheckup: Handler = async (controller, params) => {
  const { myProfileDetail } = controller.getState();
  controller.setState({ loadingMainFeed: true });

  const [resProgram, errorProgram] = await GetProgramSpecialty(
    controller as any,
    params
  );

  if (resProgram?.items?.length === 1) {
    const data: any = resProgram.items[0];

    const pushHistory = await goToCheckupData({
      medProgramId: data?.id,
      history: params.history,
      patientDetail: myProfileDetail,
      selectedHospital: null,
    });
    pushHistory.state.mob = "MobFeed";
    params.history.push(pushHistory);
    globalThis.history.go();
  } else if (resProgram?.items?.length > 1) {
    globalThis.location.href = `/select-specialty/${params.med_program}/?app=MobAppointment`;
  }
  controller.setState({ loadingMainFeed: false });
};

export const SetLoggedinSuccess: Handler = async (controller, params) => {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve("done!");
    }, 400);
  });

  let userId = controller.cookies.get("userId");
  controller.analytics.setUserId(
    userId !== undefined || userId !== "" ? userId : "0"
  );
  controller.setState({ loggedinSuccess: true });
};

export const GetUserNotification: Handler = async (controller, params) => {
  const [res, error, net] = await UserNotificationListView.list({
    apiToken: controller.cookies.get("apiToken"),
  });

  return [res, error, net];
};

export const GetMyProfileDetail: Handler = async (controller, params) => {
  console.log("GetMyProfileDetail: ");


  const [res, error, net] = await ProxyMyProfile.get({
    apiToken: controller.cookies.get("apiToken"),
  });

  console.log("GetMyProfileDetail es: ", res);
  let user_state = null;

  if (params.is_user_state && res) {
    [user_state] = await SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    });


    const state = controller.getState();
    console.log(
      "MobPayment GetMyProfileDetail state.selectedHospital?.code: ",
      state.selectedHospital?.code
    );

    const tooMany =
      user_state.ekyc_too_many && user_state.ekyc_status !== "success";

    // Get Selected Hospital Code 
    let selectedHospitalCode: number = state.selectedHospital?.code;

    // -if (globalThis.mobile?.saveiOSSelectedHospital) {
    //   // iOS 
    //   selectedHospitalCode = await globalThis.mobile.storedHospitalCode;
    // } else if (
    //   globalThis.mobile.storedPreferredHospital &&
    //   globalThis.mobile.storedHospitalList
    // ) {
    //   // Android 
    //   selectedHospitalCode = await globalThis.mobile.storedPreferredHospital();
    // } else {
    //   // Web only 
    //   selectedHospitalCode = state.selectedHospital?.code;
    // }

    const hasHn = user_state.hospital_profiles.find(
      (profile: any) => profile.hospital_code === selectedHospitalCode
    )?.has_hn;

    let userStatus: string;

    if (hasHn) {
      userStatus = "success";
    } else if (tooMany) {
      userStatus = "kyc_failed";
    } else if (
      ["partial_success", "failed", "none"].includes(user_state.ekyc_status)
    ) {
      userStatus = "kyc";
    } else if (!user_state.has_profile) {
      userStatus = "edit_profile";
    } else {
      userStatus = "register_hn";
    }

    res.user_status = userStatus;
  }

  let userId = controller.cookies.get("userId");

  controller.analytics.setUserId(
    userId !== undefined || userId !== "" ? userId : "0"
  );

  controller.setState({
    myProfileDetail: {
      ...(res || {}),
      user_state,
    },
  });
};

export const GetSaveDrugList: Handler = async (controller, params) => {
  controller.setState({ saveDrugListLoading: true });
  const [res, error, net] = await V3SaveDrugPrescriptionListView.get({
    apiToken: controller.cookies.get("apiToken"),
  });
  controller.setState({ saveDrugList: res || {} });
  controller.setState({ saveDrugListLoading: false });
};

const replaceInString = (
  fullString: string,
  search: string,
  replacement: string
) => {
  return fullString.split(search).join(replacement);
};

export const GetUserNotificationList: Handler = async (controller, params) => {
  controller.setState({ firstLoadingNotification: false });

  const [res, error] = await GetUserNotification(controller, params);

  if (!error) {
    HandleCheckBadgeNotification(controller, { notiList: res?.items || [] });
  }
  // console.log('res?.items: ', res?.items);

  const notificationList =  (res?.items || []).map((item: any, idx: number) => {
    if (typeof item.data === "string") {
      // ต้อง parse
      const str: string = item.data
        .replace(/'/g, '"')
        .replace(/: None, /g, ": null, ");
      return { ...item, parse_data: JSON.parse(str) };
    } else if (typeof item.data === "object") {
      // ไม่ต้อง parse
      return { ...item, parse_data: item.data };
    }

    return { ...item };
  })

  controller.setState({
    userNotificationList: notificationList.filter((item:any) => item.parse_data) || [],
    firstLoadingNotification: true,
  });
};

export const HandleCheckBadgeNotification: Handler = (controller, params) => {
  const { notiList }: { notiList: any[] } = params;
  const notiLength = +controller.cookies.get("notiLength");
  const apiToken = controller.cookies.get("apiToken");

  controller.setState({ showBadgeNotification: false });

  if (apiToken) {
    if (notiLength >= 0 && notiList.length > notiLength) {
      controller.setState({ showBadgeNotification: true });
    }
    controller.cookies.set("notiLength", notiList.length);
  } else {
    controller.cookies.set("notiLength", "");
  }
};

const SERVICE_HOSPTIAL = "ServicesHospital";

export const GetCurrentServiceFromFirestore: Handler = async (
  controller,
  params
) => {
  console.log("GetCurrentServiceFromFirestore params: ", params);

  let divisionCode = "";
  if (params?.code) {
    divisionCode = params?.code;
  } else {
    // กรณี ไม่มี divisionCode issue 57230
    divisionCode = "1";
  }

  if (divisionCode) {

    const docRef = doc(controller.db, SERVICE_HOSPTIAL, divisionCode.toString());
    const docSnap = await getDoc(docRef);

    console.log("docSnap: ", docSnap);
    let listOfService = docSnap?.data()?.ServiceItems;
    console.log("collection listOfService: ", listOfService);
    // Optimize Cost
    controller.setState({
      servicesOfHospitalFB: listOfService,
    });

    // controller.db
    //   .collection(SERVICE_HOSPTIAL)
    //   .doc(divisionCode.toString())
    //   .get()

    //   .then((data) => {
    //     console.log("data: ", data);
    //     let listOfService = data?.data()?.ServiceItems;
    //     console.log("collection listOfService: ", listOfService);
    //     // Optimize Cost
    //     controller.setState({
    //       servicesOfHospitalFB: listOfService,
    //     });
    //   })
    //   .catch((error) => console.error("collection  error ", error));
    return;
  }
};

export const HandleSelectedMenuBar: Handler = (controller, params) => {
  controller.setState({ selectedMenuBar: params.menu });
};

const MOCK = [
  {
    doctor: {
      id: 360,
      code: "14784",
      pre_name: "พญ.",
      first_name: "จิรภัทร",
      last_name: "สุริยะชัยสวัสดิ์",
      full_name: "พญ.จิรภัทร สุริยะชัยสวัสดิ์",
      is_doctor: true,
      employee_code: "0114784",
      image:
        "https://penguin.sgp1.cdn.digitaloceanspaces.com/media/doctors/images/25541128_091532.JPG",
      specialty: [
        {
          id: 61,
          name: "เวชศาสตร์ป้องกัน แขนงเวชศาสตร์ป้องกันคลินิก",
          image: null,
        },
      ],
      subspecialty: [
        {
          id: 264,
          name: "Staff Clinic",
        },
      ],
      is_telemed: null,
      is_onsite: true,
      score: 4.583333333333333,
    },
    encounter: "O01-21-0096532",
    is_lab_available: false,
    lab_datetime: null,
    is_radio_result_available: false,
    is_payment_completed: true,
    payment_datetime: null,
    is_med_received: true,
    med_datetime: "2021-10-20 10:20:20",
  },
];
