import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
// Interface
import {
  GROUP,
  DATA_TYPE,
  BACKEND_DATE_FORMAT,
  EMOTION_TYPE,
  EXERCISE_TYPE,
  GLUCOSE_MEAL,
  FOOT_STATUS,
  MEDICATION_PERIOD,
  MEDICATION_STATUS,
  checkFoodStatus,
  checkWeightStatus,
  commonGetPatientDataLatest,
  commonListPatientDataGroupList,
  checkFootStatus,
  calculateLabWeight,
  calculateLabBloodPressureScore,
  calculateDMLabScore,
  State as MainState,
} from "../MobClinicInterface";
// APIs
import DirectChildDMLabResultView from "../../issara-sdk/apis/DirectChildDMLabResultView_apps_PRX";
import DirectDMLabResultView from "../../issara-sdk/apis/DirectDMLabResultView_apps_PRX";

export type State = {
  latestWeight?: any;
  scoreSummary?: any[];
  labSummary?: any;
  labResultHeader?: any[];
  labResultDetail?: any[];
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "successMessage" | "selectedPatient"
>;

export const StateInitial = {
  latestWeight: {},
  scoreSummary: [],
  labSummary: {},
  labResultHeader: [],
  labResultDetail: [],
};

export type Event =
  | { message: "handleGetLatestWeight"; params: {} }
  | {
      message: "handleGetScoreSummary";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "handleGetLabSummary";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "handleGetLabResult";
      params: { lab_codes: any[]; start_measure: string; end_measure: string };
    };

type Handler = WasmHandler<State & Picked, Event>;

export const handleGetLatestWeight: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.WEIGHT,
    data_type: DATA_TYPE.WEIGHT,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    latestWeight: response,
  });
};

export const handleGetScoreSummary: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    questions: [
      { group: GROUP.WEIGHT, data_type: DATA_TYPE.WEIGHT },
      { group: GROUP.BLOOD_PRESSURE, data_type: DATA_TYPE.BLOOD_PRESSURE },
      { group: GROUP.BLOOD_GLUCOSE, data_type: DATA_TYPE.BLOOD_GLUCOSE },
      { group: GROUP.EXERCISE, data_type: DATA_TYPE.EXERCISE },
      { group: GROUP.FOOD, data_type: DATA_TYPE.FOOD },
      { group: GROUP.EMOTION, data_type: DATA_TYPE.EMOTION },
      { group: GROUP.EMOTION, data_type: DATA_TYPE.REST },
      { group: GROUP.FOOT, data_type: DATA_TYPE.FOOT },
      { group: GROUP.DRUG, data_type: DATA_TYPE.DRUG },
    ],
    ...params, // start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataGroupList(
    controller as any,
    tmpParams
  );

  let weightScore: any = {};
  let bpScore: any = {};
  let bgScore: any = {};
  let exerciseScore: any = {};
  let foodScore: any = {};
  let emotionScore: any = {};
  let stressScore: any = {};
  let restScore: any = {};
  let footScore: any = {};
  let drugScore: any = {};
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      scoreSummary: [],
    });
  } else {
    response?.forEach((item: any) => {
      if (item.group === GROUP.WEIGHT && item.data_type === DATA_TYPE.WEIGHT) {
        weightScore = calculateWeightScore(item?.items); // bmi, status, pass, color, score
        console.log("----- weightScore ", weightScore?.score);
      } else if (
        item.group === GROUP.BLOOD_PRESSURE &&
        item.data_type === DATA_TYPE.BLOOD_PRESSURE
      ) {
        bpScore = calculateBloodPressureScore(item?.items); // status, color, score
        console.log("----- bpScore ", bpScore?.score);
      } else if (
        item.group === GROUP.BLOOD_GLUCOSE &&
        item.data_type === DATA_TYPE.BLOOD_GLUCOSE
      ) {
        bgScore = calculateBloodGlucoseScore(item?.items); // status, color, score
        console.log("----- bgScore ", bgScore?.score);
      } else if (
        item.group === GROUP.EXERCISE &&
        item.data_type === DATA_TYPE.EXERCISE
      ) {
        exerciseScore = calculateExcerciseScore(item?.items); // status, color, score
        console.log("----- exerciseScore ", exerciseScore?.score);
      } else if (
        item.group === GROUP.FOOD &&
        item.data_type === DATA_TYPE.FOOD
      ) {
        foodScore = calculateFoodScore(item?.items); // status, color, score
        console.log("----- foodScore ", foodScore?.score);
      } else if (
        item.group === GROUP.EMOTION &&
        item.data_type === DATA_TYPE.EMOTION
      ) {
        [emotionScore, stressScore] = calculateEmotionStressScore(item?.items); // status, color, score
        console.log("----- emotionScore ", emotionScore?.score);
        console.log("----- stressScore ", stressScore?.score);
      } else if (
        item.group === GROUP.EMOTION &&
        item.data_type === DATA_TYPE.REST
      ) {
        restScore = calculateRestScore(item?.items); // status, color, score
        console.log("----- restScore ", restScore?.score);
      } else if (
        item.group === GROUP.FOOT &&
        item.data_type === DATA_TYPE.FOOT
      ) {
        footScore = calculateFootScore(item?.items); // status, color, score
        console.log("----- footScore ", footScore?.score);
      } else if (
        item.group === GROUP.DRUG &&
        item.data_type === DATA_TYPE.DRUG
      ) {
        drugScore = calculateDrugScore(item?.items); // status, color, score
        console.log("----- drugScore ", drugScore?.score);
      }
    });
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      scoreSummary: [
        { label: "น้ำหนัก (WT)", code: "WT", ...weightScore },
        { label: "ความดันโลหิต (BP)", code: "BP", ...bpScore },
        { label: "ระดับน้าตาลปลายนิ้ว (SMBG)", code: "SMBG", ...bgScore },
        { label: "การออกกำลังกาย (EX)", code: "EX", ...exerciseScore },
        { label: "การรับประทานอาหาร (NU)", code: "NU", ...foodScore },
        { label: "อารมณ์/ ความรู้สึก (EM)", code: "EM", ...emotionScore },
        { label: "การพักผ่อน (RE)", code: "RE", ...restScore },
        { label: "ความเครียด (ST)", code: "ST", ...stressScore },
        { label: "สุขภาพเท้า (FT)", code: "FT", ...footScore },
        { label: "การรับประทานยา (MD)", code: "MD", ...drugScore },
      ],
    });
  }
};

export const handleGetLabSummary: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
    });
    return;
  }

  let [response, error, network] = await DirectDMLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      ...params, // start_measure, end_measure
    },
  });

  // response = RESS
  let weightScore: any = {};
  let systolicScore: any = {};
  let labHeaders: any[] = [];
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
    });
  } else {
    weightScore = calculateLabWeight(response?.vital_signs?.bmi);
    systolicScore = calculateLabBloodPressureScore(
      response?.vital_signs?.systolic
    );

    let labSummary: any = {
      items: [
        { label: "น้ำหนัก (WT)", code: "WT", ...weightScore },
        { label: "ความดันโลหิต (BP)", code: "BP", ...systolicScore },
      ],
    };

    response?.labs?.forEach((lab: any) => {
      labHeaders.push({
        name: lab.name,
        code: lab.code,
        ref_value_txt: lab?.items[0]?.children[0]?.NML || "",
      });
      lab?.items?.forEach((item: any) => {
        const score = calculateDMLabScore(item);
        labSummary.items.push({ label: item.name, code: item.code, ...score });
      });
    });
    console.log("----- calculateDMLabScore", labSummary);
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      labResultHeader: labHeaders,
      labSummary: labSummary,
    });
  }
};

export const handleGetLabResult: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
    });
    return;
  }

  const [response, error, network] = await DirectChildDMLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      ...params, // parent_lab, start_measure, end_measure
    },
  });

  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      labResultDetail: [],
    });
  } else {
    // let results: any[] = [];
    // response?.labs[0].results?.forEach((item: any) => {
    //   let score;
    //   if (response?.labs[0].lab_code === LAB_CODE.FPG.code) {
    //     score = calculateFastingPlasmaGlucoseStatus(item.result);
    //   } else if (response?.labs[0].lab_code === LAB_CODE.HbA1c.code) {
    //     score = calculateHemoglobinA1cStatus(item.result);
    //   } else if (response?.labs[0].lab_code === LAB_CODE.HDL.code) {
    //     score = calculateHDLCholesterolStatus(item.result);
    //   } else if (response?.labs[0].lab_code === LAB_CODE.LDL.code) {
    //     score = calculateLDLCholesterolStatus(item.result);
    //   }
    //   results.push({ ...item, ...score });
    // });
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: null,
      labResultDetail: response,

      // {
      //   lab_code: response?.labs[0].lab_code,
      //   lab_name: response?.labs[0].lab_name,
      //   results: results,
      // },
    });
  }
};

// -------------------- CALCULATION
const calculateWeightScore = (items: any[]) => {
  // Math.round((bmi1 + bmi2 + ... + bmi length) / length)
  console.log("----- [WT] calculateWeightScore");
  let result: number = 0;
  items?.forEach((item: any) => {
    console.log(`----- [WT] ${result} + ${item?.data?.bmi}`);
    result = result + parseFloat(item?.data?.bmi);
  });
  const bmiAvg: number = Math.round(result / items.length);
  console.log(
    `----- [WT] AVG Math.round( ${result}/${items.length} ) = ${bmiAvg}`
  );
  const wResult: any = checkWeightStatus(bmiAvg); // { bmi: bmi, status: status, pass: pass, color: color }
  if (bmiAvg === 18.5) {
    return { ...wResult, score: 100 };
  } else if (bmiAvg > 30) {
    return { ...wResult, score: 0 };
  } else {
    return { ...wResult, score: 100 - Math.abs(bmiAvg - 18.5) * 10 };
  }
};

const calculateBloodPressureScore = (items: any[]) => {
  // Math.round((sys1 + sys2 + ... + sys length) / length)
  console.log("----- [BP] calculateBloodPressureScore");
  let result: number = 0;
  items?.forEach((item: any) => {
    console.log(`----- [BP] ${result} + ${item?.data?.systolic}`);
    result = result + parseFloat(item?.data?.systolic);
  });
  const bpAvg: number = Math.round(result / items.length);
  console.log(
    `----- [BP] AVG Math.round( ${result}/${items.length} ) = ${bpAvg}`
  );
  if (bpAvg < 140) {
    return { score: 100, status: "Normal", color: "black" };
  } else {
    return { score: 0, status: "Elevated blood pressure", color: "red" };
  }
};

const calculateBloodGlucoseScore = (items: any[]) => {
  // Math.round((after1 + after2 + ... + after length) / length)
  console.log("----- [BG] calculateBloodGlucoseScore");
  let total: number = 0;
  let result: number = 0;
  items?.forEach((item: any) => {
    if (item?.data?.meal === GLUCOSE_MEAL.AFTER) {
      console.log(`----- [BG] ${result} + ${item?.data?.value}`);
      result = result + parseFloat(item?.data?.value);
      total = total + 1;
    }
  });
  const bpAvg: number = Math.round(result / total);
  console.log(`----- [BG] AVG Math.round( ${result}/${total} ) = ${bpAvg}`);
  if (bpAvg < 180 || Number.isNaN(bpAvg)) {
    return { score: 100, status: "Well controlled", color: "black" };
  } else {
    return { score: 0, status: "Poorly controlled", color: "red" };
  }
};

const calculateExcerciseScore = (items: any[]) => {
  // Math.round((aerobic_flexibility1 + ... + aerobic_flexibility length) / length)
  console.log("----- [EX] calculateExcerciseScore");
  let total: number = 0;
  let result: number = 0;
  items?.forEach((item: any) => {
    if (
      item.data?.type_name === EXERCISE_TYPE.AEROBIC.text ||
      item.data?.type_name === EXERCISE_TYPE.FLEXIBILITY.text
    ) {
      total = total + 1;
      console.log(`----- [EX] ${result} + ${item?.data?.duration}`);
      result = result + parseFloat(item?.data?.duration);
    }
  });

  const exAvg = Math.round(result / total);
  console.log(`----- [EX] AVG Math.round( ${result}/${total} ) = ${exAvg}`);

  // >=150 = 100
  if (result >= 150) {
    return { score: 100, status: "Very good", color: "green" };
  } else {
    // (A/150)*100
    const score: number = (result / 150) * 100;
    let status: string = "";
    let color: string = "black";

    if (score >= 80) {
      status = "Very good";
    } else if (50 <= score && score < 80) {
      status = "Good";
    } else if (25 <= score && score < 50) {
      status = "Poor";
    } else {
      status = "Very poor";
      color = "red";
    }
    return { score: score.toFixed(2), status: status, color: color };
  }
};

const calculateFoodScore = (items: any[]) => {
  // score1 + score2 + ... + score length) / length
  console.log("----- [NU] calculateFoodScore");
  let result: number = 0;
  items?.forEach((item: any) => {
    console.log(`----- [NU] ${result} + ${item?.data?.score}`);
    result = result + item?.data?.score;
  });
  const foodAvg: number = Math.round(result / items.length);
  console.log(
    `----- [NU] AVG Math.round( ${result}/${items.length} ) = ${foodAvg}`
  );
  return checkFoodStatus(foodAvg); // { status: status, score: score, color: color }
};

const calculateEmotionStressScore = (items: any[]) => {
  // ----- Emotion
  // 1. count very good, good, normal, bad, very bad
  // 2. find MAX
  // 3. Math.round((100 / total) * Max)

  // ----- Stress
  // (stress1 + stress2 + ... + stress99) / 99

  let emTotal: number = 0;
  let emVeryGood: number = 0;
  let emGood: number = 0;
  let emNormal: number = 0;
  let emBad: number = 0;
  let emVeryBad: number = 0;

  let resultStress: number = 0;

  items?.forEach((item: any) => {
    item?.data?.feeling?.forEach((em: any) => {
      if (em.value === EMOTION_TYPE.VERY_GOOD.value) {
        emVeryGood = emVeryGood + 1;
      } else if (em.value === EMOTION_TYPE.GOOD.value) {
        emGood = emGood + 1;
      } else if (em.value === EMOTION_TYPE.NORMAL.value) {
        emNormal = emNormal + 1;
      } else if (em.value === EMOTION_TYPE.BAD.value) {
        emBad = emBad + 1;
      } else if (em.value === EMOTION_TYPE.VERY_BAD.value) {
        emVeryBad = emVeryBad + 1;
      }
      emTotal = emTotal + 1;
    });
    resultStress = resultStress + item?.data?.stress;
  });

  // Emotion
  console.log("----- [EM] calculateEmotionScore");
  console.log(
    `----- [EM] VeryGood:${emVeryGood} Good:${emGood} Normal:${emNormal} Bad:${emBad} VeryBad:${emVeryBad} Total:${emTotal}`
  );
  let emObjects = [
    { label: EMOTION_TYPE.VERY_GOOD.label, result: emVeryGood },
    { label: EMOTION_TYPE.GOOD.label, result: emGood },
    { label: EMOTION_TYPE.NORMAL.label, result: emNormal },
    { label: EMOTION_TYPE.BAD.label, result: emBad },
    { label: EMOTION_TYPE.VERY_BAD.label, result: emVeryBad },
  ];
  let emMax = emObjects.reduce((max, obj) =>
    max.result > obj.result ? max : obj
  );
  const emScore = Math.round((100 / emTotal) * emMax.result);
  console.log(
    `----- [EM] Score = Math.round((100 / ${emTotal}) * ${emMax.result})`
  );

  // Stress
  console.log("----- [ST] calculateStressScore");
  console.log("----- [ST] ", resultStress);
  let stressStatus: string = "";
  let stressColor: string = "black";
  const stressAvg = Math.round(resultStress / items.length);
  console.log(
    `----- [ST] AVG Math.round( ${resultStress}/${items.length} ) = ${stressAvg}`
  );

  if (80 < stressAvg && stressAvg <= 100) {
    stressStatus = "Severe stress";
    stressColor = "red";
  } else if (60 < stressAvg && stressAvg <= 80) {
    stressStatus = "High stress";
    stressColor = "red";
  } else if (40 < stressAvg && stressAvg <= 60) {
    stressStatus = "Moderate stress";
  } else if (25 < stressAvg && stressAvg <= 40) {
    stressStatus = "Moderate stress";
  } else if (0 <= stressAvg && stressAvg <= 25) {
    stressStatus = "No stress";
  }

  return [
    {
      score: emScore,
      status: emMax.label,
      color: [EMOTION_TYPE.BAD.label, EMOTION_TYPE.VERY_BAD.label].includes(
        emMax.label
      )
        ? "red"
        : "balck",
    },
    { score: stressAvg, status: stressStatus, color: stressColor },
  ];
};

const calculateRestScore = (items: any[]) => {
  // Math.round((rest1 + rest2 + ... + rest length) / length)
  console.log("----- [RE] calculateRestScore");
  let total: number = 0;
  let result: number = 0;
  items?.forEach((item: any) => {
    if (item?.data?.hours) {
      total = total + 1;
      console.log(`----- [RE] ${result} + ${item?.data?.hours}`);
      result = result + parseFloat(item?.data?.hours);
    }
  });
  const restAvg: number = Math.round(result / total);
  console.log(`----- [RE] AVG Math.round( ${result}/${total} ) = ${restAvg}`);

  if (restAvg > 7) {
    return { score: 100, status: "Perfect", color: "black" };
  } else if (restAvg < 4) {
    return { score: 0, status: "Poor", color: "red" };
  } else {
    const score: number = 100 - (7.1 - restAvg) * 10;
    console.log(`----- [RE] cal score 100-((7.1-${restAvg})*10)`);
    return {
      score: score,
      status: "Good",
      color: "black",
    };
  }
};

const calculateFootScore = (items: any[]) => {
  /*
   * items = [
   *   {
   *     data: [
   *       { leftFoot, rightFoot, ... },
   *       { leftFoot, rightFoot, ... },
   *     ]
   *   },
   *   {
   *     data: [
   *       { leftFoot, rightFoot, ... },
   *       { leftFoot, rightFoot, ... }
   *     ]
   *   }
   * ]
   */
  /*
   *   high 2 ข้าง = 0  ; high ข้างเดียว = 25
   * medium 2 ข้าง = 50 ; medium ข้างเดียว = 75
   *    low 2 ข้าง = 100
   */
  // data1_avg = Math.round((left_right1 + ... + left_right length) / data1 length)
  // data2_avg = Math.round((left_right1 + ... + left_right length) / data2 length)
  // Math.round((data1_avg + data2_avg) / items length)
  console.log("----- [FT] calculateFootScore");
  let avgFoot: number = 0;
  items?.forEach((item: any, index: number) => {
    let dataScore: number = 0;
    console.log(`----- [FT] data ${index}`);
    item?.data?.forEach((data: any) => {
      let tmpData: number = 0;
      const leftStatus = checkFootStatus(data.leftFoot);
      const rightStatus = checkFootStatus(data.rightFoot);
      if (leftStatus === FOOT_STATUS.HIGH && rightStatus === FOOT_STATUS.HIGH) {
        tmpData = 0;
      } else if (
        leftStatus === FOOT_STATUS.HIGH ||
        rightStatus === FOOT_STATUS.HIGH
      ) {
        tmpData = 25;
      } else if (
        leftStatus === FOOT_STATUS.MEDIUM &&
        rightStatus === FOOT_STATUS.MEDIUM
      ) {
        tmpData = 50;
      } else if (
        leftStatus === FOOT_STATUS.MEDIUM ||
        rightStatus === FOOT_STATUS.MEDIUM
      ) {
        tmpData = 75;
      } else if (
        leftStatus === FOOT_STATUS.LOW &&
        rightStatus === FOOT_STATUS.LOW
      ) {
        tmpData = 100;
      }
      console.log(`----- [FT] ${dataScore} + ${tmpData}`);
      dataScore = dataScore + tmpData;
    });
    console.log(
      `----- [FT] AVG data ${avgFoot} + Math.round( ${dataScore}/${item?.data?.length} )`
    );
    avgFoot = avgFoot + Math.round(dataScore / item?.data?.length);
  });

  const score = Math.round(avgFoot / items.length);
  console.log(
    `----- [FT] AVG items Math.round( ${avgFoot}/${items.length} ) = ${score}`
  );

  let status: string = "";
  let color: string = "black";
  if (81 >= score && score <= 100) {
    status = "Excellent caring";
  } else if (61 >= score && score <= 80) {
    status = "Good caring";
  } else if (41 >= score && score <= 60) {
    status = "Fairly good caring";
  } else if (21 >= score && score <= 40) {
    status = "Fairly caring";
  } else if (0 >= score && score <= 20) {
    status = "Poor caring";
  }
  return {
    score: score,
    status: status,
    color: color,
  };
};

const calculateDrugMealAvg = (items: any[]) => {
  let resultDay: number = 0;
  let totalDay: number = 0;
  items?.forEach((item: any) => {
    if (!item?.prn) {
      console.log(`----- [MD] total ${totalDay} + 1`);
      totalDay = totalDay + 1;
      if (
        [MEDICATION_STATUS.CONFIRM, MEDICATION_STATUS.HOLD].includes(
          item.status
        )
      ) {
        console.log(`----- [MD] sum ${resultDay} + 1`);
        resultDay = resultDay + 1;
      }
    }
  });
  if (totalDay !== 0) {
    const mealAvg: number = Math.round((resultDay / totalDay) * 100);
    console.log(
      `----- [MD] AVG 1 MEAL Math.round( (${resultDay}/${totalDay}) * 100 ) = ${mealAvg}`
    );
    return mealAvg;
  }
  return null;
};

const calculateDrugScore = (items: any[]) => {
  /*
   * หาค่าเฉลี่ยสำหรับ 1 วัน
   * Sum(
   *    Math.round((morning1 + morning2 + ... + morning length) / length)
   *    Math.round((afternoon1 + afternoon2 + ... + afternoon length) / length)
   *    Math.round((bedtime1 + bedtime2 + ... + bedtime length) / length)
   *    Math.round((others1 + others2 + ... + others length) / length)
   * ) / meal
   *
   * Math.round((avgDay1 + avgDay2 + ... + avgDay n) / n)
   */
  console.log("----- [MD] calculateDrugScore");
  let result: number = 0;
  let total: number = 0;
  items?.forEach((item) => {
    let resultDay: number = 0;
    let totalDay: number = 0;
    let avg: number | null = null;

    console.log("----- [MD] morning");
    avg = calculateDrugMealAvg(item?.data[MEDICATION_PERIOD.MORNING]?.items);
    if (avg !== null) {
      resultDay = resultDay + avg;
      totalDay = totalDay + 1;
    }

    console.log("----- [MD] afternoon");
    avg = calculateDrugMealAvg(item?.data[MEDICATION_PERIOD.AFTERNOON]?.items);
    if (avg !== null) {
      resultDay = resultDay + avg;
      totalDay = totalDay + 1;
    }

    console.log("----- [MD] bedtime");
    avg = calculateDrugMealAvg(item?.data[MEDICATION_PERIOD.BEDTIME]?.items);
    if (avg !== null) {
      resultDay = resultDay + avg;
      totalDay = totalDay + 1;
    }

    console.log("----- [MD] others");
    avg = calculateDrugMealAvg(item?.data[MEDICATION_PERIOD.OTHERS]?.items);
    if (avg !== null) {
      resultDay = resultDay + avg;
      totalDay = totalDay + 1;
    }

    if (totalDay > 0) {
      const oneDayAvg: number = Math.round(resultDay / totalDay);
      console.log(
        `----- [MD] AVG 1 DAY Math.round( ${resultDay}/${totalDay} ) = ${oneDayAvg}`
      );
      result = result + oneDayAvg;
      total = total + 1;
    }
  });
  const drugAvg: number = Math.round(result / total);
  console.log(`----- [MD] AVG Math.round( ${result}/${total} ) = ${drugAvg}`);

  if (drugAvg === 100) {
    return { score: drugAvg, status: "Perfect", color: "black" };
  } else if (80 <= drugAvg && drugAvg < 100) {
    return { score: drugAvg, status: "Good", color: "black" };
  } else if (40 < drugAvg && drugAvg < 80) {
    return { score: drugAvg, status: "Fair", color: "black" };
  } else if (40 <= drugAvg) {
    return { score: drugAvg, status: "Poor", color: "red" };
  }
};

const calculateFastingPlasmaGlucoseStatus = (value: any) => {
  if (70 < value && value <= 130) {
    return {
      score: 100,
      status: "Normal blood glucose for DM",
      color: "black",
    };
  } else if (70 >= value) {
    return { score: 0, status: "Hypoglycemia", color: "red" };
  } else if (130 < value) {
    return { score: 0, status: "High blood glucose", color: "red" };
  }
};

const calculateFastingPlasmaGlucose = (item: any) => {
  // Math.round((fpg1 + fpg2 + ... + fpg length) / length)
  console.log("----- [FPG] calculateFastingPlasmaGlucose");
  let result: number = 0;
  item?.results?.forEach((item: any) => {
    console.log(`----- [FPG] ${result} + ${item?.result}`);
    result = result + Math.round(item?.result);
  });

  const fpgAvg: number = Math.round(result / item.results.length);
  console.log(
    `----- [FPG] AVG Math.round( ${result}/${item.results.length} ) = ${fpgAvg}`
  );

  return calculateFastingPlasmaGlucoseStatus(fpgAvg);
};

const calculateHemoglobinA1cStatus = (value: any) => {
  if (7 > value) {
    return { score: 100, status: "Well controlled", color: "black" };
  } else if (7 >= value && value <= 8) {
    return { score: 50, status: "Fairly controlled", color: "black" };
  } else if (8 < value) {
    return { score: 0, status: "Poorly controlled", color: "red" };
  }
};

const calculateHemoglobinA1c = (item: any) => {
  // Math.round((hba1c1 + hba1c2 + ... + hba1c length) / length)
  console.log("----- [HbA1c] calculateHemoglobinA1c");
  let result: number = 0;
  item?.results?.forEach((item: any) => {
    console.log(`----- [HbA1c] ${result} + ${item?.result}`);
    result = result + Math.round(item?.result);
  });

  const hba1cAvg: number = Math.round(result / item.results.length);
  console.log(
    `----- [HbA1c] AVG Math.round( ${result}/${item.results.length} ) = ${hba1cAvg}`
  );

  return calculateHemoglobinA1cStatus(hba1cAvg);
};

const calculateHDLCholesterolStatus = (value: any) => {
  if (value >= 60) {
    return { score: 100, status: "Desirable level", color: "black" };
  } else if (50 <= value && value < 59) {
    return { score: 50, status: "Normal level", color: "black" };
  } else {
    return { score: 0, status: "At risk level", color: "red" };
  }
};

const calculateHDLCholesterol = (item: any) => {
  // Math.round((hdl1 + hdl2 + ... + hdl length) / length)
  console.log("----- [HDL] calculateHDLCholesterol");
  let result: number = 0;
  item?.results?.forEach((item: any) => {
    console.log(`----- [HDL] ${result} + ${item?.result}`);
    result = result + Math.round(item?.result);
  });

  const hdlAvg: number = Math.round(result / item.results.length);
  console.log(
    `----- [HDL] AVG Math.round( ${result}/${item.results.length} ) = ${hdlAvg}`
  );

  return calculateHDLCholesterolStatus(hdlAvg);
};

const calculateLDLCholesterolStatus = (value: any) => {
  if (value < 100) {
    return { score: 100, status: "Optimal level", color: "black" };
  } else if (100 <= value && value < 130) {
    return { score: 75, status: "Near optimal level", color: "black" };
  } else if (130 <= value && value <= 150) {
    return { score: 50, status: "Borderline high level", color: "black" };
  } else if (150 < value && value < 190) {
    return { score: 25, status: "High level", color: "red" };
  } else if (190 >= value) {
    return { score: 0, status: "Very high level", color: "red" };
  }
};

const calculateLDLCholesterol = (item: any) => {
  // Math.round((ldl1 + ldl2 + ... + ldl length) / length)
  console.log("----- [LDL] calculateLDLCholesterol");
  let result: number = 0;
  item?.results?.forEach((item: any) => {
    console.log(`----- [LDL] ${result} + ${item?.result}`);
    result = result + Math.round(item?.result);
  });

  const ldlAvg: number = Math.round(result / item.results.length);
  console.log(
    `----- [LDL] AVG Math.round( ${result}/${item.results.length} ) = ${ldlAvg}`
  );

  return calculateLDLCholesterolStatus(ldlAvg);
};


const RESS = {
  "labs": [
    {
      "code": "C040",
      "name": "Glucose (Fasting)",
      "items": [
        {
          "code": "C0180",
          "name": "Glucose (Fasting)",
          "children": [
            {
              "EPVISVisitNumber": "018959009",
              "DBT_CDE": "01-17-051095",
              "EPI_NO": "O01-20-0654731",
              "EPVIS_AGE": 33,
              "EPVIS_SEX": "Male",
              "CTHOS_CDE": "01HPC",
              "CTHOSNAME": "(BHQ) Health Design Center ",
              "EPIVISDateOfCollection": "2020-10-15",
              "DTE_OF_RCV": "2020-10-15",
              "EPIVISTimeOfCollection": "562",
              "CTTS_NME": "Glucose (Fasting)",
              "CTTS_CDE": "C040",
              "VISTSTTimeOfCollection": "",
              "VISTS_TME_OF_AUT": "10:09:00",
              "CTTS_SYN": "GLUF",
              "CTTScompletionTime": "0.041",
              "VISTSHospitalRefNumber": "",
              "CTTC_CDE": "C0180",
              "CTTC_DES": "Glucose (Fasting)",
              "TST_DTA": "78",
              "VISTD_CMM": "",
              "CTTC_UNT": "mg/dL",
              "CTTC_RSL_FMT": "N0",
              "VISTDResultStatus": "",
              "CTTCActiveFlag": "Y",
              "CTDEP_CDE": "C",
              "CTDEP_NME": "Biochemistry",
              "NML": "(70-99)",
              "RSL": "78",
              "HL": "",
              "LabName": "Glucose(Fasting)..............:",
              "Sequen": "2",
              "Status": "",
              "VISTSDateOfReceive": "2020-10-15",
              "VISTSTimeOfReceive": "34200",
              "VISTSDateTimeOfReceive": "2020-10-15T02:30:00Z",
              "TimeRequest": "09:22:00",
              "ResultTime": "10:09:00"
            }
          ]
        }
      ]
    }
  ],
  "vital_signs": {
    "bmi": [
      "20.447",
      "19.832"
    ],
    "systolic": [
      "115",
      "141"
    ]
  }
}