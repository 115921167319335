import React, { useState, useEffect, ReactChild } from "react";

import IconButton from "@mui/material/IconButton";
import { List } from "semantic-ui-react";

type PostTab = {
  initialActive: number;
  disableMenu: boolean;
  activeSegment?: any;
  list?: any;
  smallPadding?: any;
  onClickTab?: (item: any, index: number) => any;
  panes: {
    key: string;
    image: string;
    render: () => ReactChild;
  }[];
};

const COLORS = {
  primary: "var(--text-light-blue)",
  light_blue: "#0072BC",
  dark_grey: "#797878",
};

/* TODO ---------------------- PostMenu ---------------------- */
const PostTab: React.FunctionComponent<PostTab> = (props) => {
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    setActive(props.initialActive);
  }, [props.initialActive]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "40px auto" }}>
      <div
        style={{
          gridColumn: 1,
          backgroundColor: COLORS.primary,
          borderRadius: "0px 5px 5px 0px",
          paddingTop: "10px",
        }}
      >
        {props.panes.map((item, index) => { 
          return (
            <IconButton
              key={"icon-button-" + index}
              aria-label="search"
              style={{
                borderRadius: "0px",
                backgroundColor: index === active ? "#0072BC" : "",
              }}
              disabled={props.disableMenu}
              onClick={() => {
                setActive(index);
                props.onClickTab?.(item, index);
              }}
            >
              <img
                src={item.image}
                alt={`menu-${index}.icon`}
                style={{ width: "23px" }}
              />
            </IconButton>
          )
        })}
      </div>

      <div
        style={{
          gridColumn: 2,
          height: "calc(100vh - 45.5px)",
          padding: props.smallPadding? "15px 20px" : "35px 20px",
          overflowY: "auto",
        }}
      >
        {props.panes[active]?.render?.()}
      </div>
    </div>
  );
};

export default React.memo(PostTab);
