import React, { CSSProperties, useEffect, useMemo, useState } from "react";
// CSS
import { Dimmer, Loader } from "semantic-ui-react";

import { Button as MuiButton } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// Dialog
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

// Icon
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";

import PureReactTable from "react-lib/apps/common/PureReactTable";
import { FormattedMessage } from "react-intl";

// Interface
import { Event, State } from "../ManageInterface";

type PostSegmentProps = {
  // callback
  onEvent: (e: Event) => any;
  onChange?: (data: Record<string, any>) => any;
  onSave: (params: { id: any; callback?: () => any }) => any;
  // data
  id?: string | null;
  manageSegmentList: string[];
} & Pick<State, "marketingSegmentList">;

type DialogSegmentType = {
  result: string;
  count_patient: number;
  checked: boolean;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root.MuiDialog-paper": {
    width: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/* TODO -------------------- PostSegment -------------------- */
const PostSegment: React.FC<PostSegmentProps> = React.memo<PostSegmentProps>(
  (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [dialogSegment, setDialogSegment] = useState<DialogSegmentType[]>([]);
    const [isSave, setIsSave] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // useEffect(() => {

    // }, []);

    useEffect(() => {
      if (isSave) {
        handleCallSave();
      }
    }, [props.manageSegmentList]);

    const columnsSegment = useMemo(
      () => [
        {
          Header: "Segment",
          accessor: "result",
          minWidth: 150,
        },
        {
          Header: "Total members",
          accessor: "total",
          minWidth: 80,
          style: {
            textAlign: "center",
          },
          Cell: ({ original }: any = {}) => <div>{original.count_patient}</div>,
        },
        {
          Header: "",
          accessor: "_delete",
          minWidth: 30,
          Cell: ({ original }: any = {}) => (
            <div>
              <MuiButton
                variant="contained"
                className="PostButton-remove"
                onClick={() => {
                  handleDeleteRow(original);
                }}
              >
                <RemoveIcon />
              </MuiButton>
            </div>
          ),
        },
      ],
      [props.manageSegmentList]
    );

    const columnsDialogSegment = useMemo(
      () => [
        {
          Header: "",
          accessor: "_delete",
          minWidth: 30,
          Cell: ({ original }: any = {}) => (
            <div>
              <Checkbox
                inputProps={{ "aria-label": "Checkbox" }}
                style={{ margin: "-5px" }}
                checked={original?.checked || false}
                onClick={() => {
                  handleClickCheckbox(original);
                }}
              />
            </div>
          ),
        },
        {
          Header: "Segment",
          accessor: "result",
          minWidth: 150,
        },
        {
          Header: "Total members",
          accessor: "count_patient",
          minWidth: 70,
          style: {
            textAlign: "center",
          },
        },
      ],
      [dialogSegment]
    );

    const manageSegmentList = useMemo(() => {
      return (props.manageSegmentList || []).map((value, index) => ({
        result: value,
        count_patient:
          props.marketingSegmentList?.items?.find(
            (item: any) => item.result === value
          )?.count_patient || "",
        id: index + 1,
      }));
    }, [props.manageSegmentList]);

    const isAllSegment = useMemo(() => {
      return (
        props.manageSegmentList?.length ===
        props.marketingSegmentList?.items?.length
      );
    }, [props.manageSegmentList, props.marketingSegmentList]);

    const handleGetTrGroupProps = (
      _state: any,
      rowInfo: any,
      _column: any,
      _instance: any
    ) => {
      return {
        style: {
          borderBottom: rowInfo ? "" : 0,
        } as CSSProperties,
      };
    };

    const handleClose = () => {
      if (isLoading) {
        return;
      }
      setOpen(false);
    };

    const getIntersectionSegment = () => {
      const segment = (props.manageSegmentList || []).map((value) => value);

      const filter = (props.marketingSegmentList?.items || []).filter(
        (item: any) => !segment.includes(item.result)
      );

      return filter;
    };

    const handleAddSegment = () => {
      setDialogSegment(getIntersectionSegment());
      setOpen(true);
    };

    const handleClickCheckbox = (data: DialogSegmentType) => {
      const checked = dialogSegment.map((item) =>
        item.result === data.result ? { ...item, checked: !item.checked } : item
      );

      setDialogSegment(checked);
    };

    const handleDeleteRow = (data: {
      result: string;
      count_patient: number[];
    }) => {
      const filter = manageSegmentList
        .filter((item) => item.result !== data.result)
        .map((item) => item.result);

      setIsSave(true);
      props.onChange?.({
        segment: filter,
      });
    };

    const handleCallSave = () => {
      props.onSave({ id: props?.id });
      setOpen(false);
      setIsSave(false);
      setIsLoading(false);
    };

    const handleSave = () => {
      setIsLoading(true);

      const filter = dialogSegment.filter((item) => item.checked);

      handleConcatSegment(filter);
    };

    const handleConcatSegment = async (list: DialogSegmentType[]) => {
      // const promiseArray = list.map((item) => {
      //   return props.onEvent({
      //     message: "GetMarketingSegmentByResult",
      //     params: { result: item.result },
      //   });
      // });

      // const response = await Promise.all(promiseArray);

      // const segment = response.map((res) => ({
      //   result: res[0].result,
      //   patient: res[0].items.map((item: any) => item.proxy_patient),
      // }));

      setIsSave(true);
      props.onChange?.({
        segment: [
          ...(props.manageSegmentList || []),
          ...list.map((item) => item.result),
        ],
      });
    };

    const handleSelectSegment = (row: any) => {
      setSelectedRow(row);
    };

    const handleClickAllSegment = () => {
      setIsSave(true);
      setIsLoading(true);
      if (isAllSegment) {
        props.onChange?.({ segment: [] });
      } else {
        handleConcatSegment(getIntersectionSegment());
      }
    };

    return (
      <div style={{ position: "relative", height: "100%" }}>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
          }}
        >
          <strong>
            <label
              style={{
                fontSize: "1.125rem",
                color: "#797878",
                paddingLeft: "15px",
              }}
            >
              Segment
            </label>
          </strong>
          <div style={{ margin: "0 0 0 auto" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="All Segment"
              checked={isAllSegment}
              onClick={handleClickAllSegment}
            />

            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleAddSegment}
            >
              Add Segment
            </MuiButton>
          </div>
        </div>

        <PureReactTable
          className="SegmentTable-custom"
          columns={columnsSegment}
          defaultPageSize={10}
          style={{ height: "400px", marginTop: "5px" }}
          showPagination={false}
          data={manageSegmentList}
          resizable={false}
          getTrGroupProps={handleGetTrGroupProps}
          onSelect={handleSelectSegment}
          selectedRow={selectedRow}
        />

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <Dimmer active={isLoading} inverted>
            <Loader inverted>
              <FormattedMessage id="bplusClinicKey297" />
            </Loader>
          </Dimmer>

          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ color: "var(--text-light-blue)" }}
          >
            Segment
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <PureReactTable
              className="SegmentTable-custom"
              columns={columnsDialogSegment}
              defaultPageSize={10}
              style={{ height: "400px", marginTop: "5px" }}
              showPagination={false}
              data={dialogSegment}
              resizable={false}
              getTrGroupProps={handleGetTrGroupProps}
            />
          </DialogContent>
          <DialogActions>
            <MuiButton autoFocus onClick={handleSave}>
              Save changes
            </MuiButton>
          </DialogActions>
        </BootstrapDialog>
      </div>
    );
  }
);

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default PostSegment;
