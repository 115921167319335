import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

type DiagBubbleProps = {
  diagFormController: any;
  contentPayload?: any;
  diagRuleId?: number;
  onclick?: (diagRule:number) => any;
  useLinkOpen?: boolean;
  apiToken: any;
};

const DiagBubble = (props: DiagBubbleProps) => {
  const [publishDiag, setPublishDiag] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
       console.log("saika ~ props.apiToken:", props.apiToken)
      console.log("saika ~ props.diagRuleId:", props.diagRuleId)
    if (props.diagRuleId && props.apiToken) {
      setIsLoading(true);
      getPublishDiagRuleCheck(props.diagRuleId);
    }
  }, [props.diagRuleId, props.apiToken]);

  const getPublishDiagRuleCheck = async (diagRuleId: number) => {
    const [response, error, network] =
      await props.diagFormController.getPublishDiagRuleCheck({
        apiToken: props.apiToken,
        diagRule: diagRuleId,
      });

    if (response) {
      setPublishDiag(response.publish_diag_rule);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const host = window.location.origin;
  const lang = navigator.language;
  const urlShow = `${host}/form/${publishDiag}`;
  const urlHide = `/form/${publishDiag}/?app=IH`;

  return (
    <div>
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted></Loader>
        </Dimmer>
        <div>
          {lang.toLowerCase().includes("th")
            ? props.contentPayload?.text_th
            : props.contentPayload?.text_en}
        </div>
        <div
          onClick={() => {
            props.onclick?.(publishDiag);
          }}
        >
          {!props.useLinkOpen ? (
            <a nohref>{urlShow}</a>
          ) : (
            <a href={urlHide}>{urlShow}</a>
          )}
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(DiagBubble);
