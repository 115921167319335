import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Image,
  Modal,
  Grid,
  Button,
  Divider,
  Dimmer,
  Loader,
} from "semantic-ui-react";


//component

// Use styles
import "./TeleDesktopCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  gary: "#797878",
  lightGary: "#9D9C9C",
};

const styles = {
  cardPayment: {
    padding: "10px 20px",
  } as CSSProperties,
  header: {
    background: COLOR.primary,
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  textNormal: {
    width: "100%",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  button_style: {
    background: COLOR.primary,
    padding: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
  } as CSSProperties,
};

type AskNotiPermissionProps = {
  open?: boolean;
  handlePermission: Function;
};

const AskNotiPermission = (props: AskNotiPermissionProps) => {
  const [open, setOpen] = useState<boolean | undefined>(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal dimmer={'inverted'} open={props.open} onClose={() => {console.log("click ok")}} style={{ width: "350px", borderRadius: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={styles.header}>{"Notification Permission"}</div>
          <div style={{ ...styles.textNormal, padding: "25px 30px 5px" }}>
            {"Check your Notification Permission to"}
          </div>
          <div style={{ ...styles.textNormal, padding: "5px 30px 20px" }}>
            {"Subscribe our Notifications"}
          </div>
          <div
            style={{
              padding: "10px 40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={styles.button_style}
              onClick={() => props.handlePermission()}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(AskNotiPermission);
