import React, { memo, useEffect, useState } from 'react'
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import {
    Grid,
    Icon,
    Image
  } from "semantic-ui-react";

  const COLOR = {
    darkgrayHos: "#797878",
    superdarkgray: "#393939",
  };
  
  const styles = {
    text_superdarkgray_14: {
        fontSize: "14px",
        color: COLOR.superdarkgray,
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      } as CSSProperties,
      text_DarkGray_14: {
        fontSize: "14px",
        color: COLOR.darkgrayHos,
        textOverflow: "ellipsis",
        overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      } as CSSProperties,
      text_DarkGray_14_line3: {
        fontSize: "14px",
        color: COLOR.darkgrayHos,
        textOverflow: "ellipsis",
        overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "3",
      "-webkit-box-orient": "vertical",
      } as CSSProperties,
  };

type SeeMoreMedicationProps = {
    medicationItems?: any;
}

const SeeMoreMedication : React.FunctionComponent<SeeMoreMedicationProps> = (props) => {
    const [medicationItem,setMedicationItem] = useState<any | undefined>(undefined)

    useEffect(()=> {
        if(props.medicationItems !== null && props.medicationItems?.length > 0){
            setMedicationItem(props.medicationItems)
        }
    },[props.medicationItems])

  return (
    <div style={{padding: "10px"}}>
        {medicationItem? medicationItem.map((item:any, index:any) => (
        <Grid columns={2} key={index}>
            <Grid.Column width={2} style={{padding: "5px 0" ,display: "flex" ,justifyContent: "flex-end"}}>
            <div style={{marginRight:"10px"}}>
                <Image
                  style={{ width: "25px", height: "25px" }}
                  src={
                    item.route_image || "/images/drug/icon_no_medication1.png"
                  }
                  onClick={() => {}}
                />
            </div>
            </Grid.Column>
            <Grid.Column width={14} style={{padding: "5px 0"}}>
            <Grid.Row style={styles.text_superdarkgray_14}>
                {item.name ? item.name : " "}
              </Grid.Row>
              <Grid.Row style={styles.text_DarkGray_14}>
                {item.count ? item.count : " "}
              </Grid.Row>
              <Grid.Row style={styles.text_DarkGray_14_line3}>
                {item.note?.medication}
              </Grid.Row>
            </Grid.Column>
        </Grid>
        )) : "-" }
    </div>
  )
}

(SeeMoreMedication.defaultProps as SeeMoreMedicationProps) = {
    medicationItems: [],
}

export default React.memo(SeeMoreMedication);