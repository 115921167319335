import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import SetProp from "../react-lib/frameworks/SetProp";
import {
  IntlProvider,
  FormattedMessage,
  // createIntlCache,
  // createIntl,
} from "react-intl";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";
import Cookies from "js-cookie";
import CONFIG from "../config/config";
import { vcMessenger } from "react-lib/compat/vc-websocket";
// Controller
import MobKYCController from "./MobKYCController";
// Interface
import * as MobKYCI from "./MobKYCInterface";
// UI
import KYCStep from "./kyc/KYCStep";
import KYCVerify from "./kyc/KYCVerify";
// CSS
import "../css/MobAppointment.scss";

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};


class MobKYC extends Component<any, MobKYCI.State> {
  controller: MobKYCController;

  constructor(props: any) {
    super(props);
    this.state = { ...MobKYCI.StateInitial };
    this.controller = new MobKYCController(
      () => {
        return this.state;
      },
      (state: MobKYCI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({ message: "GetLoginInfo", params: {} });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }


    // ทำไม ถึงใช้ createIntl 
    // https://formatjs.io/docs/react-intl/api
    // 
    // useIntl hook: Once you've declared your IntlProvider, 
    //    you can get access to the intl object via calling this hook in your functional React component
    // createIntl: In a non-React environment (Node, vue, angular, testing... you name it), 
    //    you can directly create a intl object by calling this function with the same configuration as the IntlProvider.

    // const cache = createIntlCache();

    // const intl = createIntl(
    //   {
    //     locale: this.state.language || LANGUAGE.EN_US,
    //     // messages: flattenMessages(
    //     //   messages[this.state.language || LANGUAGE.EN_US]
    //     // ),
    //     messages: this.state.language?.includes("TH") ? thMessage : enMessage
    //   },
    //   cache
    // );

    // ทำไม ถึงเก็บใน เอาไปใส่ state เรามี intlObject ที่เป็น cache of all อยู่อีกตัวนึง 
    // 1 The intl object should be reused as much as possible for performance.
    // แล้ว ตรงตำแหน่งนี้ ก็เก็บแค่ภาษาเดียว ตอน Didmount เปลี่ยนภาษา ตัว
    // 2 มันต้องทำ cached 2 ตัว คือ eng และ thai พร้อมกันไปเลย เช่น 
    //
    //const globalIntlCache = createIntlCache()
    // const enIntl = createIntl({ locale: 'en' }, globalIntlCache)
    // const frIntl = createIntl({ locale: 'fr' }, globalIntlCache)
    //  ไม่งั้น ก็ไม่ต่างกับ react ปกติ 

    // this.setState({ intl });

    // Get apiToken and userId from mobNative
    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      console.log("did update check......");
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
    }
  }

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;
    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile);
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user.token;
      userId = user.profile.userId;
      Cookies.set("apiToken", user.token, { path: "/" });
      Cookies.set("userId", user.profile.userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: user.token });
      this.setState({ apiToken: user.token, userId: user.profile.userId });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  render = () => {
    console.log("MobKYC ", this);
    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div className="default-background">
          <Switch>
            <Route
              path="/step-verify-identification"
              render={(props) => {
                return <KYCStep history={this.props.history} />;
              }}
            ></Route>
            <Route
              path="/verify-identification"
              render={(props) => {
                return (
                  <KYCVerify
                    onEvent={this.controller.handleEvent}
                    errorMessage={this.state.errorMessage}
                    successMessage={this.state.successMessage}
                    patientIdentification={this.state.patientIdentification}
                  />
                );
              }}
            ></Route>
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobKYC);
