import React, { useMemo, useEffect, useState } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";

import { useLocation, useHistory } from "react-router";
import moment from "moment";
import { FormattedMessage } from "react-intl";
// UX
import DoctorBanner from "./DoctorBanner";
import BoxTitle from "./BoxTitle";
import { HPLocationType } from "./HealthProblem";
import ModConsentSuccess from "bplus-lib/register/ModConsentSuccess";
// telephar
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Interface
import {
  State,
  Event,
  ACTION_CONFIRM,
  ACTION_REQUEST,
} from "bplus-lib/MobSmartAppointmentInterface";
import ModBookingUnSuccess from "bplus-lib/appointment/ModBookingUnSuccess";
import { momentADToLocalFormatBE } from "react-lib/utils/dateUtils";

// Types
type ConfirmAppointmentProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
} & Pick<
  State,
  | "myProfileDetail"
  | "loadingStatus"
  | "successMessage"
  | "errorMessage"
  | "appointmentParams"
  | "medServiceLists"
  | "language"
>;

type CALocationType = HPLocationType &
  HPLocationType["storedState"] & {
    routes?: string[];
  } & {
    storedState?: {
      errorMessage: any;
    };
  };

// Images
const IMAGES = {
  request_app: "/images/Appointment/request-appointment.png",
  check: "/images/register/check-green.png",
  patient_history: "/images/register/patient-history.png",
  outoftime: "/images/Appointment/out-of-time.png",
  error: " /images/Appointment/error.png",
};

// Styles
const COLORS = {
  bg: "rgba(249, 249, 249, 1)",
  primary: "var(--blue-bdms-color)",
  divider: "rgba(204, 218, 237, 1)",
  grey: "rgba(57, 57, 57, 1)",
  normal: "rgba(121, 120, 120, 1)",
  very_light_grey: "rgba(233, 233, 233, 1)",
  light_grey: "rgba(159, 159, 159, 1)",
  light_blue: "rgba(236, 244, 255, 1)",
};

const styles = {
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
  },
};

const bottomStyles = {
  "& .content": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    color: COLORS.light_grey,
    paddingLeft: "1.5rem",
    paddingBottom: "25px",
  },
  "& .button-submit-bottom": {
    paddingBottom: 0,
    "& button": {
      width: "80%",
      minHeight: "48px",
      alignItems: "center",
      ...styles.ellipsis,
      display: "inline-flex",
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  },
  "& .price": {
    fontSize: "1.375rem",
    color: COLORS.primary,
    fontWeight: "bold",
    textAlign: "left",
    marginTop: "0.5rem",
  },
};

const summaryStyles = {
  color: COLORS.normal,
  marginTop: "2rem",
  "& .title": {
    color: COLORS.primary,
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  "& .total": {
    fontWeight: "bold",
    color: COLORS.grey,
  },
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {},
  summary: summaryStyles,
  divider: {
    borderBottom: `1px solid ${COLORS.very_light_grey}`,
    margin: "1.25rem 0",
  },
  bottom: bottomStyles,
  message: {
    backgroundColor: COLORS.light_blue,
    borderRadius: "8px",
    padding: "8px",
  },
}));

const conditions = {
  "true-true": {
    type: "PAYMENT",
    text: "ชำระเงิน",
  },
  "true-false": {
    type: "REQUEST",
    text: "ส่งคำขอนัดหมาย",
  },
  "false-true": {
    type: "CONFIRM",
    text: "ยืนยันนัดหมาย",
  },
  "false-false": {
    type: "REQUEST",
    text: "ส่งคำขอนัดหมาย",
  },
} as any;

const searchParams = new URLSearchParams(window.location.search);
const app = searchParams.get("app");

const MOB_APP = `app=${app}`;

// const BUTTON_ACTIONS = {
//   confirm: "_CONFIRM",
//   request: "_REQUEST",
// };

// const CONFIRM_APPOINTMENT = "ConfirmAppointment";
// const ACTION_CONFIRM = `${CONFIRM_APPOINTMENT}${BUTTON_ACTIONS.confirm}`;
// const ACTION_REQUEST = `${CONFIRM_APPOINTMENT}${BUTTON_ACTIONS.request}`;

const ConfirmAppointment = (props: ConfirmAppointmentProps) => {
  const classes = useStyles();
  const location = useLocation<CALocationType>();
  const history = useHistory();
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [organizationNameState, setOrganizationNameState] =
    useState<string>("");

  // Memo Effect
  const isStringState = useMemo(() => {
    return typeof location.state === "string";
  }, [location.state]);

  const locState = useMemo(() => {
    // เนื่องจาก make appointment unsign state เป็น JSON stringify
    // จริงทำให้สามารถใช่งานร่วมกับของเดิมได้
    return isStringState
      ? JSON.parse(`${location.state}`).locationState
      : location.state || {};
  }, [location.state, isStringState]);

  const isCompleteAppointment = useMemo(() => {
    return (
      (isStringState &&
        JSON.parse(location.state as any)?.complete_appointment) ||
      locState?.complete_appointment
    );
  }, [isStringState, location.state]);

  useEffect(() => {
    setOpenSuccessModal(false);
    setOrganizationNameState("");
    // เมื่อ make appointment แล้ว จะเกิด complatete_appointment ใน loc state
    if (isCompleteAppointment) {
      setTimeout(() => {
        handleSuccessAppointment(
          locState.actionType !== "CONFIRM" ? "requested" : ""
        )();
      }, 100);
    }
  }, []);

  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      props.setProp("errorMessage", storedState.errorMessage);
    }
  }, [locState.storedState]);

  useEffect(() => {
    const searchParamsURL = new URLSearchParams(window.location.search);
    const complete = searchParamsURL.get("complete");
    const organizationName = searchParamsURL.get("organizationName");

    if (complete === "true") {
      props.onEvent({
        message: "HandleMakeAppointmentUnsign",
        params: { init: true, locState: location.state },
      });
    } else if (!!organizationName) {
      setOpenSuccessModal(true);
      setOrganizationNameState(organizationName);
    }
  }, [locState]);

  useEffect(() => {
    if (props.appointmentParams && props.myProfileDetail) {
      if (props.myProfileDetail && "patient" in props.myProfileDetail) {
        handleMakeAppointment();
      }
    }
  }, [props.myProfileDetail, props.appointmentParams]);

  const medServiceName = useMemo(() => {
    return (
      (props?.medServiceLists || []).find(
        (item) => item?.id === locState?.reason?.id
      )?.name || ""
    );
  }, [locState, props.medServiceLists]);

  // Memo
  const appointmentDatetime = useMemo(() => {
    const slot = locState.slot;
    const times = locState.slot?.times || [];
    const startTime = times[0]?.start_time || "";
    const endTime = times.slice(-1)[0]?.end_time || "";

    let date = slot?.date;

    if (props.language === "th-TH" && date) {
      date = momentADToLocalFormatBE(date);
    }

    return date
      ? `${moment(date).format("DD MMM YYYY")}|${startTime}-${endTime}`
      : "";
  }, [locState.slot]);

  const visitDuration = useMemo(() => {
    const [startTime, endTime] =
      appointmentDatetime.split("|")?.[1]?.split("-") || [];

    return moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"), "minutes");
  }, [appointmentDatetime]);

  const isCheckup = useMemo(() => {
    return locState.centergroup?.appointment_type === "check_up";
  }, [locState.centergroup]);

  const specialtyName = useMemo(() => {
    return locState.doctor?.specialties;
  }, [locState.doctor]);

  const hospitalName = useMemo(() => {
    return locState.doctor?.hospital_name || locState.hospital?.name || "";
  }, [locState.doctor, locState.hospital]);

  const isPayment = useMemo(() => {
    return locState.appointmentLocation === "online";
  }, [locState]);

  const headerImage = useMemo(() => {
    return isCheckup ? locState.checkup?.image : locState.doctor?.image;
  }, [isCheckup, locState]);

  const errorMessageKYC = useMemo(() => {
    return (
      props.errorMessage?.[`${ACTION_CONFIRM}_KYC`] ||
      props.errorMessage?.[`${ACTION_REQUEST}_KYC`]
    );
  }, [props.errorMessage]);

  const errorMessageFull = useMemo(() => {
    return (
      props.errorMessage?.[`${ACTION_CONFIRM}_FULL`] ||
      props.errorMessage?.[`${ACTION_REQUEST}_FULL`]
    );
  }, [props.errorMessage]);

  const errorMessage = useMemo(() => {
    return (
      props.errorMessage?.[ACTION_CONFIRM] ||
      props.errorMessage?.[ACTION_REQUEST]
    );
  }, [props.errorMessage]);

  const isLoading = useMemo(() => {
    return (
      props.loadingStatus?.[ACTION_CONFIRM] ||
      props.loadingStatus?.[ACTION_REQUEST]
    );
  }, [props.loadingStatus]);

  // Handler
  const handleMakeAppointment = async () => {
    const card =
      props.appointmentParams.actionType === "CONFIRM"
        ? ACTION_CONFIRM
        : ACTION_REQUEST;

    props.onEvent({
      message: "HandleMakeAppointmentUnsign",
      params: { init: false, card, history },
    });
  };

  const handleCheckType = () => {
    const isRealtime = locState?.requestType === "REALTIME";
    const isTelemed = locState?.appointmentLocation === "online";

    const conditionKey = `${isTelemed}-${isRealtime}`;

    handleConfirm(conditions[conditionKey].type, true);
  };

  const handleConfirm = (type: string, skipPopup?: boolean) => {
    props.onEvent({
      message: "HandlePrepareMakeAppointment",
      params: {
        history,
        card: type === "CONFIRM" ? ACTION_CONFIRM : ACTION_REQUEST,
        type,
        skipPopup,
      },
    });
  };

  useEffect(() => {
    if (
      props.successMessage[ACTION_CONFIRM] ||
      props.successMessage[ACTION_REQUEST]
    ) {
      if (openSuccessModal) {
        setOpenSuccessModal(false);
      }
    }
  }, [props.successMessage]);

  const handleSuccessAppointment =
    (tab: string = "") =>
    async () => {
      const {
        [ACTION_CONFIRM]: c,
        [ACTION_REQUEST]: r,
        ...success
      } = props.successMessage || {};

      await props.setProp(`successMessage`, { ...success });
      await props.setProp(`myAppointmentList`, null);
      await props.setProp(`requestedAppointmentList`, null);
      await props.setProp(`appointmentParams`, null);

      const routes = locState.routes || [];

      history.go(-routes.length);

      tab = tab ? `&tab=${tab}` : "";

      setTimeout(() => history.replace(`/?${MOB_APP}${tab}`), 10);
    };

  const handleCloseModeKYC = () => {
    handleClearErrorMessage();
  };

  const handleKYC = () => {
    const location = errorMessageKYC;

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: location.pathname,
        search: location.search,
        replaceState: location.state,
        reload: true,
        storedState: {
          errorMessage: props.errorMessage,
        },
      },
    });
  };

  const handleSelectNewTime = async () => {
    await handleClearErrorMessage();

    const {
      [ACTION_CONFIRM]: ac,
      [ACTION_REQUEST]: ar,
      ...locState
    } = props.loadingStatus || {};

    await props.setProp(`loadingStatus`, { ...locState });

    await props.setProp(`appointmentParams`, null);

    const index = (locState.routes || [])
      .reverse()
      .findIndex((route: string) => route === "/select-datetime");

    history.go(-(index + 1));
  };

  const handleRetryAppointmentSlot = async () => {
    handleClearErrorMessage();
  };

  const handleClearErrorMessage = async () => {
    const {
      [`${ACTION_CONFIRM}_FULL`]: acf,
      [`${ACTION_REQUEST}_FULL`]: arf,
      [`${ACTION_CONFIRM}_KYC`]: ackyc,
      [`${ACTION_REQUEST}_KYC`]: arkyc,
      [ACTION_CONFIRM]: ac,
      [ACTION_REQUEST]: ar,
      ...error
    } = props.errorMessage || {};

    await props.setProp(`errorMessage`, { ...error });
  };

  const handlePriceFormat = (prince?: string) => {
    return prince
      ? Number(prince).toLocaleString("en-US", {
          style: "decimal",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "-";
  };

  return (
    <DoctorBanner
      headerName="ยืนยันการนัดหมาย"
      titleName={
        isCheckup ? locState.checkup?.name : locState.doctor?.full_name
      }
      specialtyName={specialtyName}
      appLoc={locState.appointmentLocation}
      appointmentType={isCheckup ? "check_up" : "doctor"}
      hospitalName={hospitalName}
      headerImage={headerImage}
      bottom={
        <BottomContent
          isCheckup={isCheckup}
          isRealtime={locState.requestType === "REALTIME"}
          isTelemed={locState.appointmentLocation === "online"}
          loading={isLoading}
          disabled={!location.state}
          price={locState.division?.price}
          // callback
          onClick={handleConfirm}
          handlePriceFormat={handlePriceFormat}
        />
      }
    >
      <div className={classes.screen}>
        {!isCheckup && (
          <BoxTitle
            style={{ gridTemplateColumns: "1fr auto", marginBottom: "1rem" }}
          >
            <>
              <div>{locState.division?.name || "-"}</div>
              {isPayment && (
                <div className="price">
                  {locState.division?.price && (
                    <>฿ {handlePriceFormat(locState.division?.price)}</>
                  )}
                </div>
              )}
            </>
          </BoxTitle>
        )}

        <BoxTitle
          type="datetime"
          datetime={appointmentDatetime}
          style={{ marginBottom: "1rem" }}
        />

        <BoxTitle
          style={{
            gridTemplateColumns: "1fr 1fr",
            margin: "12px 0 16px",
          }}
        >
          <>
            {medServiceName && (
              <div className="divider">
                <div className="normal">รูปแบบการนัดหมาย</div>
                <div>{medServiceName}</div>
              </div>
            )}
            <div>
              <div className="normal">ชื่อคนไข้ </div>
              {locState.member?.first_name ||
                props.myProfileDetail?.first_name}{" "}
              {locState.member?.last_name || props.myProfileDetail?.last_name}
            </div>
          </>
        </BoxTitle>

        {isCheckup && (
          <BoxTitle
            style={{ gridTemplateColumns: "1fr auto", marginBottom: "1rem" }}
          >
            <div>
              <div className="normal">ประเภทการตรวจสุขภาพ</div>
              <div>{locState.checkup?.name}</div>
            </div>
          </BoxTitle>
        )}

        {isPayment && (
          <div className={classes.summary}>
            <div className="title">สรุปรายการชำระ</div>
            <div className={classes.divider}></div>

            <div>
              <div>พบแพทย์ ({visitDuration} นาที)</div>{" "}
              <div>{`${handlePriceFormat(locState.division?.price)} บาท`}</div>
            </div>
            <div className={classes.divider}></div>

            <div className="total">
              <div>ยอดรวม</div>{" "}
              <div>{`${handlePriceFormat(locState.division?.price)} บาท`}</div>
            </div>
          </div>
        )}

        {locState.requestType !== "REALTIME" && (
          <PaymentAlertMessage
            type="info-yellow"
            style={{ marginTop: "1.5rem" }}
          >
            การส่งคำขอนัดหมายต้องรอการยืนยันนัดหมาย จากโรงพยาบาล ภายใน 24
            ชั่วโมง
          </PaymentAlertMessage>
        )}

        <DialogConfirm
          open={props.successMessage?.[ACTION_REQUEST]}
          title={"กรุณารอการยืนยัน\nนัดหมายจากโรงพยาบาล"}
          subTitle="นัดหมายของท่านจะได้รับการยืนยันภายใน 24 ชั่วโมง"
          description="หากไม่ได้รับการยืนยันนัดหมายจากโรงพยาบาลภายใน 24 ชั่วโมงกรุณาตรวจสอบนัดหมายทางอีเมล/SMS หรือทำการนัดหมายเข้ามาใหม่"
          approveText="ดูคำขอนัดหมาย"
          img={IMAGES.request_app}
          imgSize={"7.5rem"}
          // config
          hideDeny={true}
          onApprove={handleSuccessAppointment("requested")}
        />

        <DialogConfirm
          open={errorMessageFull}
          title={"เสียใจด้วยเวลาที่ท่าน\nเลือกถูกจองไปก่อนหน้าแล้ว"}
          description="เวลาที่ท่านเลือกถูกจองไปก่อนหน้าแล้วกรุณาเลือกเวลานัดหมายใหม่"
          approveText="เลือกเวลาใหม่"
          img={IMAGES.outoftime}
          // config
          hideDeny={true}
          onApprove={handleSelectNewTime}
        />

        <DialogConfirm
          open={props.successMessage?.[ACTION_CONFIRM]}
          title={"ทำการนัดหมายสำเร็จ"}
          description="ท่านทำการนัดหมายแพทย์แบบใช้บริการที่โรงพยาบาล เสร็จเรียบร้อยแล้ว"
          approveText="เสร็จสิ้น"
          img={IMAGES.check}
          imgStyle={{ width: "4rem", margin: "-0.75rem 0 1.25rem" }}
          // config
          hideDeny={true}
          onApprove={handleSuccessAppointment()}
        />

        <DialogConfirm
          open={errorMessageKYC}
          title={<FormattedMessage id="bplusClinicKey1080" />}
          description={
            <div style={{ whiteSpace: "pre-line" }}>
              <FormattedMessage id="bplusClinicKey1081" />
            </div>
          }
          img={IMAGES.patient_history}
          imgStyle={{ width: "150px", marginTop: 0 }}
          hideDeny={true}
          closeIcon={true}
          // element
          approveText={<FormattedMessage id="bplusClinicKey1023" />}
          // callback
          onApprove={handleKYC}
          onDeny={handleCloseModeKYC}
        />

        <DialogConfirm
          open={!!errorMessage}
          title={"เกิดข้อผิดพลาด"}
          img={IMAGES.error}
          imgStyle={{ width: "4rem", margin: "-0.75rem 0 1.25rem" }}
          hideDeny={true}
          closeIcon={true}
          // element
          // callback
          onApprove={handleRetryAppointmentSlot}
          onDeny={handleRetryAppointmentSlot}
        />

        {openSuccessModal && (
          <ModConsentSuccess
            organizationName={organizationNameState || ""}
            loading={isLoading}
            onApprove={handleCheckType}
            onDeny={() => setOpenSuccessModal(false)}
          />
        )}
      </div>
    </DoctorBanner>
  );
};

/* ------------------------------------------------------ */

/*                      BottomContent                     */

/* ------------------------------------------------------ */
type BottomContentProps = {
  isCheckup: boolean;
  isTelemed: boolean;
  isRealtime: boolean;
  loading: boolean;
  price?: string;
  // config
  disabled?: boolean;
  // callback
  onClick?: (type: string) => any;
  handlePriceFormat?: (price: string) => any;
};

const BottomContent = (props: BottomContentProps) => {
  const classes = useStyles();

  // Memo
  const buttonAction = useMemo(() => {
    const isRealtime = props.isRealtime;
    const isTelemed = props.isTelemed;

    const conditionKey = `${isTelemed}-${isRealtime}`;

    return conditions[conditionKey];
  }, [props.isRealtime, props.isTelemed]);

  const buttonText = useMemo(() => {
    return (
      <>
        {buttonAction?.text}
        {props.loading && (
          <CircularProgress
            style={{
              marginLeft: "0.2rem",
            }}
            size={16}
          />
        )}
      </>
    );
  }, [buttonAction, props.loading]);

  // Handler
  const handleClick = () => {
    props.onClick?.(buttonAction.type);
  };

  return (
    <div className={classes.bottom}>
      {props.isCheckup ? (
        <div
          className="button-submit-bottom"
          style={{ marginBottom: "2.75rem" }}
        >
          <MuiButton
            variant="contained"
            color="primary"
            disabled={props.loading || props.disabled}
            onClick={handleClick}
          >
            {buttonText}
          </MuiButton>
        </div>
      ) : (
        <>
          <div className={classes.divider}></div>
          <div className="content">
            <div>
              {!props.isTelemed ? (
                <div className={classes.message}>
                  **ค่าแพทย์ขึ้นอยู่กับโรคและ คำวินิจฉัยของแพทย์
                </div>
              ) : (
                <>
                  <div>รวมยอดชำระ</div>
                  <div className="price">{`${props.handlePriceFormat?.(
                    props?.price || ""
                  )}`}</div>
                </>
              )}
            </div>
            <div className="button-submit-bottom">
              <MuiButton
                variant="contained"
                color="primary"
                disabled={props.loading || props.disabled}
                onClick={handleClick}
              >
                {buttonText}
              </MuiButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ConfirmAppointment);
