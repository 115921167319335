import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import moment from "moment";
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Loader,
  Input,
  SemanticICONS,
} from "semantic-ui-react";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { Bar } from "react-chartjs-2";

// interface
import {
  BACKEND_DATE_FORMAT,
  LANGUAGE,
  formatDateByLanguage,
} from "bplus-lib/MobClinicInterface";

// UI
import SnackMessage from "./SnackMessage";
import { DateTextBox } from "../../react-lib/apps/common/";
import { drawStarChart, displayNoteChart } from "./DMBloodPressure";

// Utils
import { formatADtoBEString } from "../../react-lib/utils/dateUtils";

import { DATE_FORMAT } from "./DMEmotionRest";

// Styles
import "../../css/WebClinic.scss";

// ---------- Charge
type DMEmotionRestChartProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  height?: number;
  emotionRestHistoryList?: any[];
  // Config
  hideFooterChart?: boolean;
  starRadius?: number;
  starTop?: number;
  showPatientNote?: boolean;
};

const DMEmotionRestChartInitial: DMEmotionRestChartProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  height: 130,
  emotionRestHistoryList: [],
};

const COLOR = {
  light_grey: " #A3A3A3",
};

type HeaderChartProps = {
  // Data
  yAxesLabelLeft?: string;
  yAxesLabelRight?: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  durationOptions?: Record<string, any>[];
  labelOptions?: React.ReactChild | string;
  legendItem?: {
    type: "rect" | "line";
    items: {
      color: string;
      name: string;
      type?: "triangle" | "ellipse" | "star";
      style?: CSSProperties;
      image?: string;
    }[];
  };
  selectedDuration?: any;
  labelStyle?: CSSProperties;
  legendStyle?: CSSProperties;
  dateRangeStyle?: CSSProperties;
  style?: CSSProperties;
  dropdownStyle?: CSSProperties;
  // Callback
  onChangeDuration?: (event: any, data: any) => any;
  onChangeDateRange?: (event: any, data: any) => any;
  onChangeDateRecord?: (date: string) => any;
  // Config
  noPadding?: boolean;
  hideDateRange?: boolean;
  formatType?: "day" | "range" | "month" | "year";
  allowPicker?: boolean;
  widthDatePicker?: CSSProperties["width"];
};

type FooterChartProps = {
  graphIndex?: number;
  lastGraph?: boolean;
  onMoveGraph?: (move: "left" | "right") => any;
  isSeeAll?: boolean;
  onClickSeeAll?: () => any;
  // Config
  hideMoveIcon?: boolean;
  style?: CSSProperties;
  iconNameLeft?: SemanticICONS;
  iconNameRight?: SemanticICONS;
};

type FooterChartCustomProps = {
  chartData: any;
  startDate?: moment.Moment;
  onChangeSkipIndex: (index: number) => any;
  onChangeIsSeeAll: (see: boolean) => any;
  labelLimit: number;
  alwaysDisplay?: boolean;
  style?: CSSProperties;
};

type DMDatePickerProps = {
  // Data
  date?: moment.Moment;
  style?: CSSProperties;
  beginDate?: moment.Moment;
  hideRangeIcon?: boolean;
  disabledPickerStyle?: boolean;
  // Callback
  onChangeStepDateRecord?: (event: any, data: any) => any;
  onChangeDateRecord?: (date: string) => any;
  // config
  width?: CSSProperties["width"];
} & Pick<HeaderChartProps, "formatType">;

export const labelNewLine = (value: string) => {
  const splitSpace = value.split(" ");
  const tick = [];
  if (splitSpace.length === 2) {
    tick[0] = `${splitSpace[0]}`;
    tick[1] = splitSpace[1];
  } else {
    tick[0] = `${splitSpace[0]} ${splitSpace[1]}`;
    tick[1] = splitSpace[2];
  }
  return tick;
};

export const spliceChartData = ({
  data,
  index,
  limit,
}: {
  data: any;
  index: number;
  limit: number;
}) => {
  const cloneData = { ...data };
  let sliceArray = (list: any[]) => list.slice(index, limit + index);

  const labels = sliceArray(cloneData.labels || []);

  let datasets = (cloneData?.datasets || []).map((item: any) => {
    let data: any[] = [];
    let realData: any[] = [];
    if (["string", "number"].includes(typeof item.data?.[0]?.x)) {
      const [result, indexList] = item.data.reduce(
        (result: any[][], acc: any, index: number) => {
          return labels.includes(acc.x)
            ? [
                [...result[0], acc],
                [...result[1], index],
              ]
            : result;
        },
        [[], []]
      );
      data = result;
      realData = item.realData.filter((_: any, index: number) =>
        indexList.includes(index)
      );
    } else {
      data = sliceArray(item.data || []);
      realData = sliceArray(item.realData || []);
    }

    return {
      ...item,
      data,
      realData,
    };
  });

  return {
    ...cloneData,
    labels,
    datasets,
  };
};

const getStepChange = (data: {
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  name: "backward" | "forward";
}) => {
  const { endDate, startDate, name } = data;
  const diff = endDate?.diff(startDate, "days") || 0;
  let unit: any = "";
  if (diff > 31) {
    unit = "years";
  } else if (diff > 7) {
    unit = "months";
  } else if (diff > 1) {
    unit = "weeks";
  } else if (diff === 1) {
    unit = "days";
  }

  let fromDate: any = null;
  let toDate: any = null;

  if (name === "backward") {
    const targetDate = startDate;
    toDate = targetDate;
    fromDate = targetDate?.clone()?.add("-1", unit || "days");

    if (unit === "weeks") {
      fromDate.add(1, "days");
    } else if (unit === "" && startDate) {
      toDate.add(-1, "days");
    }
  } else if (name === "forward") {
    const targetDate = endDate;
    fromDate = targetDate;
    toDate = targetDate?.clone()?.add("1", unit || "days");

    if (unit === "weeks") {
      toDate.add(-1, "days");
    } else if (unit === "" && startDate) {
      fromDate.add(1, "days");
    }
  }

  return {
    fromDate,
    fromDateString: fromDate?.format(BACKEND_DATE_FORMAT),
    toDate,
    toDateString: fromDate?.format(BACKEND_DATE_FORMAT),
    unit,
  };
};

const ColorLabel = (props: {
  color: any;
  label: string;
  type?: "rect" | "line" | "triangle" | "ellipse" | "star";
  image?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      className={`box-label ${props.image ? "image" : props.type}`}
      style={props.style}
    >
      {props.type === "line" ? (
        <div className="div-line">
          <div className="point" style={{ backgroundColor: props.color }}></div>
          <div className="line" style={{ backgroundColor: props.color }}></div>
          <div className="point" style={{ backgroundColor: props.color }}></div>
        </div>
      ) : props.type === "triangle" ? (
        <div
          className="div-triangle"
          style={{ backgroundColor: props.color }}
        ></div>
      ) : props.type === "ellipse" ? (
        <div
          className="div-ellipse"
          style={{ backgroundColor: props.color }}
        ></div>
      ) : props.type === "star" ? (
        <div
          className="div-star"
          style={{ backgroundColor: props.color }}
        ></div>
      ) : props.image ? (
        <img className="div-image" src={props.image} alt="image.icon" />
      ) : (
        <div
          className="div-rect"
          style={{ backgroundColor: props.color }}
        ></div>
      )}
      <label
        style={{
          color: COLOR.light_grey,
        }}
      >
        {props.label}
      </label>
    </div>
  );
};

export const HeaderChart: React.FunctionComponent<HeaderChartProps> = (
  props
) => {
  const handleChangeDateRange = (e: any, data: any) => {
    const stepData = getStepChange({
      startDate: props.startDate,
      endDate: props.endDate,
      name: data.name,
    });

    props.onChangeDateRange?.(e, { ...data, ...stepData });
  };

  return (
    <div className="box-header-chart-dm" style={props.style}>
      <div className={`header-top${props.durationOptions?.[0] ? "" : " full"}`}>
        <div
          style={
            props.noPadding
              ? { padding: "0 0 0 0", ...(props.legendStyle || {}) }
              : props.legendStyle || {}
          }
        >
          {props.legendItem?.items?.map((item, index) => (
            <ColorLabel
              key={`color-label-${index}`}
              label={item.name}
              color={item.color}
              type={item.type || props.legendItem?.type}
              style={item.style}
              image={item.image}
            />
          ))}
        </div>
        {props.durationOptions?.[0] && (
          <div
            className="duration"
            style={props.noPadding ? { padding: "0 0 10px" } : {}}
          >
            <label>
              {props.labelOptions ? (
                props.labelOptions
              ) : (
                <FormattedMessage id="bplusClinicKey174" />
              )}
            </label>
            <Dropdown
              fluid
              selection
              style={{ width: "100px", ...(props.dropdownStyle || {}) }}
              options={props.durationOptions}
              value={props.selectedDuration}
              onChange={props.onChangeDuration}
            />
          </div>
        )}
      </div>

      {props.allowPicker ? (
        <DMDatePicker
          date={props.endDate}
          formatType={props.formatType}
          beginDate={props.startDate}
          onChangeStepDateRecord={props.onChangeDateRange}
          onChangeDateRecord={props.onChangeDateRecord}
          width={props.widthDatePicker}
        />
      ) : (
        <div className="box-date" style={props.dateRangeStyle}>
          {!props.hideDateRange && (
            <>
              <Icon
                name="backward"
                onClick={(e: any, d: any) => {
                  handleChangeDateRange(e, d);
                }}
              />

              <div>
                {`${formatDateByLanguage(
                  props.startDate?.format(DATE_FORMAT),
                  "DD/MM/YYYY"
                )} - 
                  ${formatDateByLanguage(
                    props.endDate?.format(DATE_FORMAT),
                    "DD/MM/YYYY"
                  )}`}
              </div>
              <Icon
                name="forward"
                disabled={
                  props.endDate?.format(DATE_FORMAT) ===
                  moment().format(DATE_FORMAT)
                }
                onClick={(e: any, d: any) => {
                  handleChangeDateRange(e, d);
                }}
              />
            </>
          )}
        </div>
      )}

      <div className="header-chart-label" style={props.labelStyle || {}}>
        <div>{props.yAxesLabelLeft || ""}</div>
        <div>{props.yAxesLabelRight || ""}</div>
      </div>
    </div>
  );
};

export const FooterChart: React.FunctionComponent<FooterChartProps> = (
  props
) => {
  const intl = useIntl();
  return (
    <div className="box-footer-chart-dm" style={props.style || {}}>
      {!props.hideMoveIcon ? (
        <Icon
          name={props.iconNameLeft? props.iconNameLeft : "caret square left"}
          color={!props.graphIndex ? "grey" : "blue"}
          size="big"
          style={{ fontSize: "2rem" }}
          onClick={() => {
            props.onMoveGraph?.("left");
          }}
          disabled={!props.graphIndex}
        />
      ) : (
        <div></div>
      )}

      <Button
        className="btn-see-all"
        color={"blue"}
        onClick={props.onClickSeeAll}
      >
        {props.isSeeAll
          ? `${intl.formatMessage({ id: "bplusClinicKey672" })}`
          : `${intl.formatMessage({ id: "bplusClinicKey824" })}`}
      </Button>
      {!props.hideMoveIcon ? (
        <Icon
          name={props.iconNameRight? props.iconNameRight : "caret square right"}
          color={!props.lastGraph ? "grey" : "blue"}
          size="big"
          style={{ fontSize: "2rem" }}
          onClick={() => {
            props.onMoveGraph?.("right");
          }}
          disabled={!props.lastGraph}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export const FooterChartCustom: React.FunctionComponent<
  FooterChartCustomProps
> = (props) => {
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  useEffect(() => {
    setSkipIndex(0);
    props.onChangeSkipIndex(0);
  }, [props.startDate]);

  const handleMoveGraph = (move: "left" | "right") => {
    let index = skipIndex;

    if (move === "left") {
      index -= 1;
    } else if (move === "right") {
      index += 1;
    }

    setSkipIndex(index);
    props.onChangeSkipIndex(index);
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
    props.onChangeIsSeeAll(!isSeeAll);
  };

  return (
    <>
      {(props.chartData?.labels?.length > props.labelLimit ||
        props.alwaysDisplay) && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            props.chartData.labels?.slice(props.labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
          style={props.style}
        />
      )}
    </>
  );
};

export const DMDatePicker: React.FunctionComponent<DMDatePickerProps> = (
  props
) => {
  const formatWeek = useMemo(() => {
    const isCompareMonth =
      props.beginDate?.format("MMM") === props.date?.format("MMM");
    const isCompareYear =
      props.beginDate?.format("YYYY") === props.date?.format("YYYY");

    return `${formatDateByLanguage(
      props.beginDate?.format(DATE_FORMAT),
      `DD${!isCompareMonth ? " MMM" : ""}${!isCompareYear ? " YYYY" : ""}`
    )} - ${formatDateByLanguage(
      props.date?.format(DATE_FORMAT),
      "DD MMM YYYY"
    )}`;
  }, [props.beginDate, props.date]);

  const formatMonth = useMemo(() => {
    const isCompare =
      props.beginDate?.format("YYYY") === props.date?.format("YYYY");

    return `${formatDateByLanguage(
      props.beginDate?.format(DATE_FORMAT),
      `DD MMM${!isCompare ? " YYYY" : ""}`
    )} - ${formatDateByLanguage(props.date?.format(DATE_FORMAT), "MMM YYYY")}`;
  }, [props.beginDate, props.date]);

  const handleChangeStepDateRecord = (e: any, data: any) => {
    const stepData = getStepChange({
      startDate: props.beginDate,
      endDate: props.date,
      name: data.name,
    });

    props.onChangeStepDateRecord?.(e, { ...data, ...stepData });
  };

  const handleChangeDateRecord = (date: string) => {
    if (!/\d{2}\/\d{2}\/\d{4}/g.test(date)) {
      return;
    }
    props.onChangeDateRecord?.(date);
  };

  return (
    <div
      className={`dm-date-picker${
        props.disabledPickerStyle ? "" : " picker-input"
      }`}
      style={props.style}
    >
      {!props.hideRangeIcon && (
        <Icon
          name="backward"
          onClick={(e: any, d: any) => {
            handleChangeStepDateRecord(e, d);
          }}
        />
      )}
      <div className="groupDate" style={{ width: props.width ?? "" }}>
        <DateTextBox
          value={formatADtoBEString(props.date?.format(BACKEND_DATE_FORMAT))}
          style={{ width: props.width ?? "194px" }}
          inputStyle={{
            width: props.width ?? "194px",
            boxShadow: "-1px 2px 3px rgb(37 150 190 / 15%)",
          }}
          maxDate={formatADtoBEString(moment().format(BACKEND_DATE_FORMAT))}
          onChange={handleChangeDateRecord}
          useAdDate={LANGUAGE.includes("en") ? true : false}
          setLanguage={LANGUAGE === "th-TH" ? "th" : "en"}
        />
        <Input
          className="input-overlay"
          icon="calendar"
          placeholder=""
          style={{ width: props.width ?? "" }}
          value={
            props.formatType === "range"
              ? formatWeek
              : props.formatType === "month"
              ? formatMonth
              : props.formatType === "year"
              ? `${formatDateByLanguage(
                  props.beginDate?.format(DATE_FORMAT),
                  "DD MMM YYYY"
                )} - ${formatDateByLanguage(
                  props.date?.format(DATE_FORMAT),
                  "YYYY"
                )}`
              : formatDateByLanguage(
                  props.date?.format(DATE_FORMAT),
                  "DD MMMM YYYY"
                )
          }
        />
      </div>
      {!props.hideRangeIcon && (
        <Icon
          name="forward"
          disabled={
            formatDateByLanguage(props.date?.format(DATE_FORMAT)) ===
            formatDateByLanguage(moment().format(DATE_FORMAT))
          }
          onClick={(e: any, d: any) => {
            handleChangeStepDateRecord(e, d);
          }}
        />
      )}
    </div>
  );
};

export const DMEmotionRestChart: React.FC<DMEmotionRestChartProps> = (
  props
) => {
  const intl = useIntl();
  const [selectedDuration, setSelectedDuration] = useState<string>("w");
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [selectedData, setSelectedData] = useState<any>({});
  const [history, setHistory] = useState<any>({ labels: [], datasets: [] });
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>;

  const labelLimit = 4;

  const durationOptions = useMemo(
    () => [
      {
        key: 1,
        value: "w",
        text: `${intl.formatMessage({ id: "bplusClinicKey763" })}`,
      },
      {
        key: 2,
        value: "M",
        text: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      },
    ],
    []
  );

  useEffect(() => {
    let labels: any[] = [];
    let sleepList: any[] = [];
    let stressList: any[] = [];
    let veryHappyList: any[] = [];
    let happyList: any[] = [];
    let normalList: any[] = [];
    let badList: any[] = [];
    let veryBadList: any[] = [];
    const realData: any[] = [];
    if (props.emotionRestHistoryList) {
      props.emotionRestHistoryList?.forEach((item: any) => {
        labels.push(
          formatDateByLanguage(
            item?.measure_date?.format(DATE_FORMAT),
            "DD MMM"
          )
        );
        sleepList.push(item?.sleep?.hours);
        stressList.push(item?.stress);

        const feelingLength = item?.feeling?.length;
        let feeling: any = {
          veryHappy: 0,
          happy: 0,
          normal: 0,
          bad: 0,
          veryBad: 0,
        };
        item?.feeling?.forEach((feel: any) => {
          if (feel?.value === "5") {
            feeling.veryHappy = feeling?.veryHappy + 1;
          } else if (feel?.value === "4") {
            feeling.happy = feeling?.happy + 1;
          } else if (feel?.value === "3") {
            feeling.normal = feeling?.normal + 1;
          } else if (feel?.value === "2") {
            feeling.bad = feeling?.bad + 1;
          } else if (feel?.value === "1") {
            feeling.veryBad = feeling?.veryBad + 1;
          }
        });

        realData.push(item);
        veryHappyList.push(
          ((100 / feelingLength) * feeling.veryHappy).toFixed(2)
        );
        happyList.push(((100 / feelingLength) * feeling.happy).toFixed(2));
        normalList.push(((100 / feelingLength) * feeling.normal).toFixed(2));
        badList.push(((100 / feelingLength) * feeling.bad).toFixed(2));
        veryBadList.push(((100 / feelingLength) * feeling.veryBad).toFixed(2));
      });

      setSelectedData(
        props.emotionRestHistoryList[props.emotionRestHistoryList.length - 1]
      );
    }
    const datasets = [
      {
        label: "Very happy",
        data: veryHappyList,
        backgroundColor: "#97F2E0",
        yAxisID: "score",
        stack: 5,
        realData,
      },
      {
        label: "Happy",
        data: happyList,
        backgroundColor: "#A0F297",
        yAxisID: "score",
        stack: 5,
        realData,
      },
      {
        label: "Normal",
        data: normalList,
        backgroundColor: "#F9DD8D",
        yAxisID: "score",
        stack: 5,
        realData,
      },
      {
        label: "Bad",
        data: badList,
        backgroundColor: "#FFBA7B",
        yAxisID: "score",
        stack: 5,
        realData,
      },
      {
        label: "Very bad",
        data: veryBadList,
        backgroundColor: "#EE9191",
        yAxisID: "score",
        stack: 5,
        realData,
      },
      {
        label: "Stress level",
        data: stressList,
        backgroundColor: "#3399DB",
        yAxisID: "score",
        stack: 1,
      },
      {
        label: "Sleep time",
        data: sleepList,
        backgroundColor: "#A0A4B5",
        yAxisID: "hours",
      },
    ];

    setHistory({ labels: labels, datasets: datasets });
  }, [props.emotionRestHistoryList]);

  useEffect(() => {
    props.onEvent({
      message: "handleGetEmotionRestHistory",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.selectedPatient, startDate, endDate]);

  useEffect(() => {
    setSkipIndex(0);
  }, [startDate]);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;
        },
        afterDatasetsDraw: (chart: any) => {
          drawStarChart({
            chart,
            allowStack: [5],
            top: props.starTop ?? 15,
            radius: props.starRadius ?? 6.5,
            noteField: "realData.feeling",
            hideFillText: !props.showPatientNote,
          });
        },
        beforeEvent: (chart: any, e: any) => {
          if (props.showPatientNote) {
            displayNoteChart({
              chart,
              event: e,
              allowStack: [5],
              top: props.starTop ?? 20,
              radius: props.starRadius ?? 6.5,
              noteField: "realData.feeling",
              tooltipRef: tooltipRef.current,
            });
          }
        },
      },
    ];
  }, []);

  const subChartData = useMemo(() => {
    if (props.hideFooterChart) {
      return history;
    }
    return spliceChartData({
      data: history,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [history, skipIndex]);

  const handleChangeDuration = (_e: any, { value }: any) => {
    setSelectedDuration(value);
    const start = moment().add("-1", value);
    if (value === "w") {
      start.add(1, "days");
    }
    setStartDate(start);
    setEndDate(moment());
  };

  const handleChangeDateRange = (event: any, data: any) => {
    setStartDate(data.fromDate);
    setEndDate(data.toDate);
  };

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const options = {
    onClick: (event: any, element: any) => {
      if (element.length > 0) {
        console.log(element);
        setSelectedData(
          props.emotionRestHistoryList?.[element[0]._index + skipIndex]
        );
      }
    },
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      ...(props.showPatientNote ? {} : { enabled: false }),
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          stacked: true,
          ticks: {
            beginAtZero: true,
            max: 110,
            min: 0,
            stepSize: 20,
            fontColor: "#b1b3b2",
          },
          position: "left",
          id: "score",
        },
        {
          gridLines: {
            drawBorder: false,
          },
          stacked: false,
          ticks: {
            beginAtZero: true,
            max: 10,
            min: 0,
            stepSize: 2,
            fontColor: "#b1b3b2",
          },
          position: "right",
          id: "hours",
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            fontColor: "#404040",
            fontStyle: "bold",
          },
          maxBarThickness: 70,
        },
      ],
    },
  };

  return (
    <>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <HeaderChart
        yAxesLabelLeft="%"
        yAxesLabelRight="hr."
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={selectedDuration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        legendItem={{
          type: "rect",
          items: [
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey725" })}`,
              color: "#97F2E0",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey726" })}`,
              color: "#A0F297",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey832" })}`,
              color: "#F9DD8D",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey727" })}`,
              color: "#FFBA7B",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey728" })}`,
              color: "#EE9191",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey844" })}`,
              color: "#3399DB",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey459" })}`,
              color: "#A0A4B5",
            },
            {
              name: "Patient’s note",
              color: "#EE1C24",
              type: "star" as "star",
            },
          ].filter((item) => (!props.showPatientNote ? !item.type : true)),
        }}
      />

      <div style={{ position: "relative" }}>
        <Bar
          height={props.height}
          data={isSeeAll ? history : subChartData}
          options={options}
          plugins={plugins}
        />
        <div ref={tooltipRef} className="tooltip-blood-pressure"></div>
      </div>

      {!props.hideFooterChart && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            history.labels?.slice(labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
        />
      )}

      {selectedData && (
        <Grid style={{ padding: "10px 10px 35px" }}>
          <Grid.Row verticalAlign="middle" style={{ padding: "30px 0px 0px" }}>
            <Grid.Column
              width={1}
              style={{ color: "var(--theme-icon-color-dm)" }}
            >
              <Icon size="large" name="calendar alternate outline" />
            </Grid.Column>
            <Grid.Column width={14} style={{ color: "var(--text-grey)" }}>
              {formatDateByLanguage(
                moment(selectedData?.measure_date, "YYYY-MM-DD").format(
                  DATE_FORMAT
                ),
                "DD MMMM YYYY"
              )}
            </Grid.Column>
          </Grid.Row>

          {selectedData?.feeling?.map((detail: any, index: number) => {
            return (
              <Grid.Row
                key={"grid-row-" + index}
                verticalAlign="top"
                style={{ color: "var(--text-grey)", padding: "10px 10px 0px" }}
              >
                <Grid.Column
                  width={1}
                  style={{ color: "var(--theme-icon-color-dm)" }}
                >
                  <Icon name="clock outline" />
                </Grid.Column>
                <Grid.Column width={3}>{detail?.time}</Grid.Column>
                <Grid.Column width={5}>
                  {detail?.label === "Very happy"
                    ? `${intl.formatMessage({ id: "bplusClinicKey725" })}`
                    : detail?.label === "Happy"
                    ? `${intl.formatMessage({ id: "bplusClinicKey726" })}`
                    : detail?.label === "Normal"
                    ? `${intl.formatMessage({ id: "bplusClinicKey832" })}`
                    : detail?.label === "Bad"
                    ? `${intl.formatMessage({ id: "bplusClinicKey727" })}`
                    : detail?.label === "Very bad"
                    ? `${intl.formatMessage({ id: "bplusClinicKey728" })}`
                    : ""}
                </Grid.Column>
                <Grid.Column
                  width={1}
                  style={{ color: "var(--theme-icon-color-dm)" }}
                >
                  <Icon name="edit outline" />
                </Grid.Column>
                <Grid.Column width={6}>{detail?.note}</Grid.Column>
              </Grid.Row>
            );
          })}

          <Grid.Row verticalAlign="middle">
            <Grid.Column
              width={1}
              style={{ color: "var(--theme-icon-color-dm)" }}
            >
              <Icon size="large" name="fire extinguisher" />
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as="h4" style={{ color: "var(--text-grey)" }}>
                <FormattedMessage id="bplusClinicKey472" />
              </Header>
            </Grid.Column>
            <Grid.Column width={8} style={{ color: "var(--text-grey)" }}>
              {selectedData?.stress}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row verticalAlign="middle">
            <Grid.Column
              width={1}
              style={{ color: "var(--theme-icon-color-dm)" }}
            >
              <Icon.Group size="large">
                <Icon name="moon outline" />
                <Icon corner="top right" name="star" />
              </Icon.Group>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as="h4" style={{ color: "var(--text-grey)" }}>
                <FormattedMessage id="bplusClinicKey460" />
              </Header>
            </Grid.Column>
            <Grid.Column width={8} style={{ color: "var(--text-grey)" }}>
              {selectedData?.sleep?.hours}{" "}
              {selectedData?.sleep?.hours && (
                <>
                  {" "}
                  <FormattedMessage id="bplusClinicKey255" />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

DMEmotionRestChart.defaultProps = DMEmotionRestChartInitial;

export default React.memo(DMEmotionRestChart);
