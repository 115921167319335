
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data: 'patient', 'image' */
const UserUpdateImageProfile : 
{
  get: ({params, apiToken, extra}:
             {
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  post:   ({params, data, apiToken, extra}:
            { 
            params?: any,
            data?: any,
            apiToken?: any,
            extra?: any
        }) => any,
  put:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: {[key: string]: any},
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  get: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/users/apis/user-update-image/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/users/apis/user-update-image/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/users/apis/user-update-image/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
        base.client.defaults.headers["content-type"] = "multipart/form-data"
    }
    const formData = new FormData();
    for(const [key, value] of Object.entries(data || {})){
      formData.append(key, value)
    }

    const result = await to(base.client.patch(`${HOST}/users/apis/user-update-image/`,
      formData,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default UserUpdateImageProfile

