import React, { forwardRef } from "react";
import { Icon } from "semantic-ui-react";

// UI
import Welcomeback from "./Welcomeback";
import NormalLogin from "./NormalLogin";
import LoginWithSMS from "./LoginWithSMS";

// Type
import { LastLoginType, registerInfo } from "bplus-lib/register/TypeModal";

// Styles
import "./RegisterCSS.scss";
import { State } from "bplus-lib/MobSmartRegisterInterface";

//type
type MainLoginProps = {
  onEvent: (e: any) => any;
  registerInfo?: registerInfo;
  history: any;
  setProp: any;
  stepType: any;
  lastLogin?: LastLoginType;
  loading?: boolean;
} & Pick< State,"safeAreaTop">;

const MainLogin: React.FunctionComponent<MainLoginProps> = (props) => {
  const handleGoBack = () => {
    // props.history.push("/");
    props.history.push("/?app=MobRegister");
  };

  return (
    <div className="main-screen" style={{ background: "white",...(props.safeAreaTop && {paddingTop: `${props.safeAreaTop}px`}) }}>
      <div>
        <Icon
          name="chevron left"
          style={{ fontSize: "1.25rem", marginLeft: "-0.25rem" }}
          onClick={() => handleGoBack()}
        />
      </div>
      <StepContent {...props} />
    </div>
  );
};

const StepContent = (props: MainLoginProps) => {
  if (props.stepType === "welcome-back") {
    return (
      <Welcomeback
        lastLogin={props.lastLogin}
        onEvent={props.onEvent}
        history={props.history}
        loading={props.loading}
      />
    );
  } else if (props.stepType === "sms") {
    return (
      <LoginWithSMS
        onEvent={props.onEvent}
        history={props.history}
        loading={props.loading}
        registerInfo={props.registerInfo}
      />
    );
  } else {
    return (
      <NormalLogin
        onEvent={props.onEvent}
        history={props.history}
        loading={props.loading}
        registerInfo={props.registerInfo}
      />
    );
  }
};

export default React.memo(MainLogin);
