
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import PatientDataSerializer from '../types/PatientDataSerializer_apps_PHR'


const HOST = `${config.API_HOST}`
/* ['generics.ListCreateAPIView'] */
/* params:  */
/* data: 'img_type' */
const PatientDocumentDataList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: PatientDataSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/PHR/patient-document-data/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
        base.client.defaults.headers["content-type"] = "multipart/form-data"
    }
    const formData = new FormData();
    for(const [key, value] of Object.entries(data || {})){
      formData.append(key, value)
    }
    const result = await to(base.client.post(`${HOST}/apis/PHR/patient-document-data/`,
      formData,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default PatientDocumentDataList

