import React, { CSSProperties, useEffect, useState } from "react";
import { Dimmer, Button, Loader, Segment } from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

// UI
import WebMedicationCompliance from "./WebDMMedicationCompliance";
import WebMedicationCurrent from "./WebMedicationCurrent";
import WebDMMedicationTrackcare from "./WebDMMedicationTrackcare";
import SnackMessage from "../../clinic/SnackMessage";

export const styles = {
  mainLayout: {
    backgroundColor: "var(--theme-bg-color-dm, #DFF5FC)",
    height: "100%",
    padding: "25px",
  } as CSSProperties,
  divHeader: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  headerColor: {
    fontSize: "36px",
    color: "4F4F4F",
    fontWeight: "bold",
    padding: "0px 15px",
  } as CSSProperties,
  subLayout: {
    backgroundColor: "white",
    padding: "15px",
  } as CSSProperties,
  durationFont: {
    fontSize: "15px",
    color: "var(--text-light-blue)",
    paddingRight: "15px",
  } as CSSProperties,
  durationDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  } as CSSProperties,
  durationDropdown: {
    minWidth: "100px",
  } as CSSProperties,
  noPadding: {
    padding: "0px",
  } as CSSProperties,
};

type WebDMMedicationProps = {
  // function
  onEvent: any;
  // Options
  medDivisionTKOptions?: any[];
  medDivisionCROptions?: any[];
  medDoctorTKOptions?: any[];
  medDoctorCROptions?:any[];
  routeOptions: any;
  unitOptions: any;
  frequencyOptions: any;
  methodOptions: any;
  administrationTimeOptions: any;
  // data
  loadingMedication: boolean;
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medicationCurrentList: any;
  medicationCurrentListFilter: any[];
  medicationTrackcare: any;
  medicationTrackcareFilter: any[];
  drugDataTabHistory: any;
  selectedPatient: any;
  medicationShowFilter: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
};

const WebDMMedicationInitial: WebDMMedicationProps = {
  // function
  onEvent: () => null,
  // Options
  medDivisionTKOptions: [],
  medDivisionCROptions: [],
  medDoctorTKOptions: [],
  medDoctorCROptions:[],
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // data
  loadingMedication: false,
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medicationCurrentList: {},
  medicationCurrentListFilter: [],
  medicationTrackcare: {},
  medicationTrackcareFilter: [],
  drugDataTabHistory: {},
  selectedPatient: {},
  medicationShowFilter: {},
};

const WebDMMedication: React.FC<WebDMMedicationProps> = (props) => {
  const [tabMedication, setTabMedication] = useState<string>("compliance");

  useEffect(() => {
    handleSearchDivision("", "");
    handleSearchDoctor('',"");
  }, []);

  const handleSearchDivision = (stateKey:string, searchQuery: string) => {
    props.onEvent({
      message: "handleGetMedicationDivisionOptions",
      params: { limit: 100, offset: 0,stateKey:stateKey, name: searchQuery },
    });
  };

  const handleSearchDoctor = (stateKey:string,searchQuery: string) => {
    props.onEvent({
      message: "handleGetMedicationDoctorOptions",
      params: { limit: 100, offset: 0,stateKey:stateKey, name_code: searchQuery },
    });
  };

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          style={{ color: "var(--theme-icon-color-dm)", height: "36px" }}
          src="/images/detail/medicine.png"
          alt="medicine.icon"
        />
        <span style={styles.headerColor}>
          <FormattedMessage id="bplusClinicKey312" />
        </span>
        <Button
          className={`button-dm-medication${
            tabMedication === "compliance" ? " active" : ""
          }`}
          content="Compliance monitoring"
          color="blue"
          style={{
            marginLeft: "30px",
          }}
          inverted={tabMedication !== "compliance"}
          onClick={() => {
            setTabMedication("compliance");
          }}
        />
        <Button
          className={`button-dm-medication${
            tabMedication === "current" ? " active" : ""
          }`}
          content="Current Medication list"
          color="blue"
          inverted={tabMedication !== "current"}
          onClick={() => {
            setTabMedication("current");
          }}
        />
        <Button
          className={`button-dm-medication${
            tabMedication === "trackcare" ? " active" : ""
          }`}
          content="All list in Trakcare"
          color="blue"
          inverted={tabMedication !== "trackcare"}
          onClick={() => {
            setTabMedication("trackcare");
          }}
        />
      </div>
      {tabMedication === "compliance" && (
        <WebMedicationCompliance
          loadingMedication={props.loadingMedication}
          drugDataTabHistory={props.drugDataTabHistory}
          onEvent={props.onEvent}
          medicationShowFilter={props.medicationShowFilter}
          selectedPatient={props.selectedPatient}
        />
      )}
      {tabMedication === "current" && (
        <WebMedicationCurrent
          // function
          onEvent={props.onEvent}
          onSearchDivision={handleSearchDivision}
          onSearchDoctor={handleSearchDoctor}
          // Options
          medDivisionCROptions={props.medDivisionCROptions}
          medDoctorCROptions={props.medDoctorCROptions}
          routeOptions={props.routeOptions?.items}
          unitOptions={props.unitOptions?.items}
          frequencyOptions={props.frequencyOptions?.items}
          methodOptions={props.methodOptions?.items}
          administrationTimeOptions={props.administrationTimeOptions?.items}
          // data
          loadingMedication={props.loadingMedication}
          loadingDivisionOptions={props.loadingDivisionOptions}
          loadingDoctorOptions={props.loadingDoctorOptions}
          selectedPatient={props.selectedPatient}
          medicationCurrentList={props.medicationCurrentList}
          medicationCurrentListFilter={props.medicationCurrentListFilter}
        />
      )}
      {tabMedication === "trackcare" && (
        <WebDMMedicationTrackcare
          // Options
          medDivisionTKOptions={props.medDivisionTKOptions}
          medDoctorTKOptions={props.medDoctorTKOptions}
          // data
          loadingMedication={props.loadingMedication}
          loadingDivisionOptions={props.loadingDivisionOptions}
          loadingDoctorOptions={props.loadingDoctorOptions}
          selectedPatient={props.selectedPatient}
          medicationTrackcare={props.medicationTrackcare}
          medicationTrackcareFilter={props.medicationTrackcareFilter}
          // function
          onEvent={props.onEvent}
          onSearchDivision={handleSearchDivision}
          onSearchDoctor={handleSearchDoctor}
        />
      )}
    </Segment>
  );
};

WebDMMedication.defaultProps = WebDMMedicationInitial;

export default React.memo(WebDMMedication);
