import WasmController from "../react-lib/frameworks/WasmController";
import * as MobHistoryI from "./MobHistoryinterface";
import * as FeedInterface from "./native/FeedInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobMedicationI from "./MobMedicationInterface";
import * as MobLABI from "./MobLABInterface";

console.log(" MobHistoryController !!! init ");
if (
  typeof globalThis !== "undefined" &&
  typeof globalThis.mobile === "undefined"
) {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobMedicationController extends WasmController<
  MobHistoryI.State,
  MobHistoryI.Event,
  MobHistoryI.Data
> {
  constructor(
    getState: () => MobHistoryI.State,
    setState: (state: MobHistoryI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobHistoryI.DataInitial);
  }

  handleEvent = async (e: MobHistoryI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      case "HandleSetOpenBurger":
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative":
        globalThis.mobile.setBackToNative();
        return;

      // Setup
      case "DidMount":
        MobHistoryI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobHistoryI.DidUpdate(this, e.params);
        return;

      // case "GetListHospital":
      //   MobHistoryI.GetListHospital(this, e.params);
      //   return;
      
      case "GetListHospital":
        SelectHospitalI.GetListHospital(this, e.params);
        return;

      case "HandleSelectedHospital":
        SelectHospitalI.HandleSelectedHospital(this, e.params);
        return;

      case "HandleMedicalRecord":
        MobHistoryI.HandleMedicalRecord(this, e.params);
        return;

      case "HandleAllergy":
        MobHistoryI.HandleAllergy(this);
        return;

      case "GetMedAllergy":
        MobHistoryI.GetMedAllergy(this, e.params);
        return;

      case "HandleChangeAllergyTab":
        MobHistoryI.HandleChangeAllergyTab(this, e.params);
        return;

      case "closeSnackMessage":
        MobHistoryI.closeSnackMessage(this);
        return;

      case "handleDownloadLabResult":
        MobLABI.handleDownloadLabResult(this, e.params);
        return;

      case "handleOpenLink":
        MobMedicationI.handleOpenLink(this, e.params);
        return;

      case "handleUpdateCurrentMed":
        MobHistoryI.handleUpdateCurrentMed(this, e.params);
        return;

      case "GetImplantData":
        MobHistoryI.getImplantData(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
