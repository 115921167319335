import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  GROUP,
  DATA_TYPE,
  BACKEND_DATE_FORMAT,
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  commonCreatePatientDocumentFile,
} from "../MobClinicInterface";

export type State = {
  recieveFormOptions?: any;
  outsideMedicines?: any;
};

export const StateInitial = {
  recieveFormOptions: {
    // Todo: remove
    items: [
      { id: "Other hospital", name: "Other hospital" },
      { id: "Clinic", name: "Clinic" },
      { id: "Pharmacy shop", name: "Pharmacy shop" },
    ],
  },
  outsideMedicines: {},
};

export type Event =
  // GET
  | { message: "handleGetOutsideMedicineDetail" }
  // SAVE
  | {
      message: "handleAddOutsideMedicineItem";
      params: { measure_date: string; data: any };
    }
  | { message: "handleUpdateOutsideMedicineItem"; params: {} }
  | { message: "handleDeleteOutsideMedicineItem"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetOutsideMedicineDetail: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.OUTSIDE_MEDICINE,
    data_type: DATA_TYPE.OUTSIDE_MEDICINE,
    start_measure: moment().add(-1, "months").format(BACKEND_DATE_FORMAT),
    end_measure: moment().format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    outsideMedicines: response,
  });
};

// SAVE
export const handleAddOutsideMedicineItem: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let outSideMedImage = params.data.image;
  params.data.image = null;

  let tmpData: any = {
    group: GROUP.OUTSIDE_MEDICINE,
    data_type: DATA_TYPE.OUTSIDE_MEDICINE,
    measure_date: params.measure_date,
    data: { ...params.data },
    documents: [],
  };

  const [error, response] = await commonCreatePatientData(controller, tmpData);
  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    if (outSideMedImage) {
      const [errorImage, responseImage] = await commonCreatePatientDocumentFile(
        controller,
        {
          phr: response.id,
          image: outSideMedImage,
        }
      );

      if (errorImage) {
        errorMessage = errorImage;
      } else {
        tmpData = {
          id: response.id,
          proxy_patient: response.proxy_patient,
          ...tmpData,
          data: {
            ...tmpData.data,
            image: responseImage.image,
            image_id: responseImage.id,
          },
        };

        const [errorUpdate, responseUpdate] = await commonUpdatePatientData(
          controller,
          tmpData
        );
        if (errorUpdate) {
          errorMessage = errorUpdate;
        }
      }
      successMessage = "Add Outside medicine success.";
      handleGetOutsideMedicineDetail(controller);
    }
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleUpdateOutsideMedicineItem: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const data = { ...params };
    const [error, response] = await commonUpdatePatientData(controller, data);
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Save Outside medicine date success.";
      handleGetOutsideMedicineDetail(controller);
    }
  } else {
    errorMessage = "Outside medicine not found";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleDeleteOutsideMedicineItem: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete Outside medicine success.";
    }

    handleGetOutsideMedicineDetail(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
