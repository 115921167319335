import React, { useEffect } from "react";
import moment from "moment";

// CSS
import { Tab } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core";

// Interface
import { State, Event } from "../ManageInterface";

// UI
import WebMomWeightGain from "./detail/WebMomWeightGain";
import WebMomKickCount from "./detail/WebMomKickCount";
import WebMomContractionCount from "./detail/WebMomContractionCount";
import WebMomUltrasound from "./detail/WebMomUltrasound";
import WebMomOverall from "./detail/WebMomOverall";
import WebMomBreastFeeding from "./detail/WebMomBreastFeeding";
import WebMomPrenatalAndANCRecord from "./detail/WebMomPrenatalAndANCRecord";
import WebMomObstreticRecord from "./detail/WebMomObstreticRecord";

import WebPatientPanel from "./WebPatientPanel";

type MomDetailProps = {
  // function
  onEvent: (e: Event) => any;
  setProp: any;
} & Pick<
  State,
  // common
  | "loadingStatus"
  | "errorMessage"
  | "successMessage"
  | "selectedPatient"
  | "dmSelectedTab"
  // Options
  | "routeOptions"
  | "unitOptions"
  | "frequencyOptions"
  | "methodOptions"
  | "administrationTimeOptions"
  // WebPatientPanel
  | "scoreSummary"
  | "labSummary"
  // WebMomOverall
  | "profileHealth"
  | "profileObstretic"
  // Weight gain
  | "weightGainDetail"
  | "weightGainList"
  | "obstreticRecord"
  | "weightPrePregnancy"
  | "weightFilterStartDate"
  | "weightFilterEndDate"
  // Baby kickcount
  | "kickCountHistory"
  | "kickCountFilterDuration"
  // Contraction Count
  | "contractionCountHistory"
  | "contractionFilterDuration"
  // Breast Feeding
  | "breastfeedingList"
  // Obstretic record
  | "obstetricHistoryList"
  | "activeIndexBaby"
  | "activeIndexObstetricHistory"
  | "obstetricChildList"
  | "openModConfirmCreate"
  // Ultrasound
  | "ultrasoundList"
  | "ultrasoundUploadDetail"
  | "selectedUltrasoundImage"
  // PrenatalAndANCRecord
  | "prenatalAndANCRecordList"
  | "prenatalAndANCRecordFilter"
  //
  | "childOverallProgress"
>;

const MomDetailInitial: MomDetailProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  // common
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  dmSelectedTab: null,
  // Options
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // WebPatientPanel
  scoreSummary: [],
  labSummary: {},
  // WebMomOverall
  profileHealth: [],
  profileObstretic: {},
  // Weight gain
  weightGainDetail: {},
  weightGainList: [],
  // baby kickcount
  kickCountHistory: {},
  kickCountFilterDuration: "7",
  // Contraction Count
  contractionCountHistory: {},
  contractionFilterDuration: "7",
  // Breast Feeding
  breastfeedingList: [],
  // Obstretic record
  obstetricHistoryList: [],
  activeIndexBaby: null,
  activeIndexObstetricHistory: 0,
  obstetricChildList: [],
  openModConfirmCreate: false,
  // Ultrasound
  ultrasoundList: [],
  ultrasoundUploadDetail: {},
  selectedUltrasoundImage: null,
  // PrenatalAndANCRecord
  prenatalAndANCRecordList: [],
  prenatalAndANCRecordFilter: [],
  //
  childOverallProgress: [],
};

const useStyles = makeStyles((theme) => ({
  tab_bar: {
    "& .ui.bottom.attached.segment.active.tab": {
      padding: 0,
    },
  },
}));

const MomDetail: React.FC<MomDetailProps> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.setProp("dmSelectedTab", 0);
  }, [props.selectedPatient]);

  const handleChangeTab = (event: any, data: any) => {
    props.setProp("dmSelectedTab", data.activeIndex);

    if (data.panes[data.activeIndex].menuItem.toLowerCase() === "weight") {
      props.onEvent({
        message: "handleGetDMTabWeight",
        params: {
          start: moment().add(-1, "w").format("DD/MM/YYYY"),
          end: moment().format("DD/MM/YYYY"),
        },
      });
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div style={{ width: "300px" }}>
        <WebPatientPanel
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // common data
          selectedPatient={props.selectedPatient}
          dmSelectedTab={props.dmSelectedTab}
          // loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          scoreSummary={props.scoreSummary}
          labSummary={props.labSummary}
          profileHealth={props.profileHealth}
          childOverallProgress={props.childOverallProgress}
          hidePersonalScoreChart={true}
          hidePersonalLabChart={true}
          hideMomOverall={false}
          hideChildOverall={true}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Tab
          className={classes.tab_bar}
          menu={{
            vertical: true,
            color: "blue",
            pointing: true,
            tabular: true,
          }}
          grid={{ paneWidth: 14, tabWidth: 2 }}
          defaultActiveIndex={props.dmSelectedTab || 0}
          onTabChange={handleChangeTab}
          panes={[
            {
              menuItem: "Overall",
              render: () => (
                <Tab.Pane>
                  <WebMomOverall
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    profileHealth={props.profileHealth}
                    profileObstretic={props.profileObstretic}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Prenatal & ANC",
              render: () => (
                <Tab.Pane>
                  <WebMomPrenatalAndANCRecord
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    prenatalAndANCRecordList={props.prenatalAndANCRecordList}
                    prenatalAndANCRecordFilter={
                      props.prenatalAndANCRecordFilter
                    }
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Weight gain",
              render: () => (
                <Tab.Pane>
                  <WebMomWeightGain
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    weightGainDetail={props.weightGainDetail}
                    weightGainList={props.weightGainList}
                    profileObstretic={props.profileObstretic}
                    obstreticRecord={props.obstreticRecord}
                    weightPrePregnancy={props.weightPrePregnancy}
                    weightFilterStartDate={props.weightFilterStartDate}
                    weightFilterEndDate={props.weightFilterEndDate}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Breast Feeding",
              render: () => (
                <Tab.Pane>
                  <WebMomBreastFeeding
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    breastfeedingList={props.breastfeedingList}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Baby kick",
              render: () => (
                <Tab.Pane>
                  <WebMomKickCount
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    kickCountHistory={props.kickCountHistory}
                    kickCountFilterDuration={props.kickCountFilterDuration}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Contract count",
              render: () => (
                <Tab.Pane>
                  <WebMomContractionCount
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    contractionCountHistory={props.contractionCountHistory}
                    contractionFilterDuration={props.contractionFilterDuration}
                  />
                </Tab.Pane>
              ),
            },
            // {
            //   menuItem: "Ultrasound",
            //   render: () => (
            //     <Tab.Pane>
            //       <WebMomUltrasound
            //         onEvent={props.onEvent}
            //         loadingStatus={props.loadingStatus}
            //         errorMessage={props.errorMessage}
            //         successMessage={props.successMessage}
            //         selectedPatient={props.selectedPatient}
            //         ultrasoundUploadDetail={props.ultrasoundUploadDetail}
            //         ultrasoundList={props.ultrasoundList}
            //         selectedUltrasoundImage={props.selectedUltrasoundImage}
            //       />
            //     </Tab.Pane>
            //   ),
            // },
            {
              menuItem: "Obstetric record",
              render: () => (
                <Tab.Pane>
                  <WebMomObstreticRecord
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    obstetricHistoryList={props.obstetricHistoryList}
                    activeIndexBaby={props.activeIndexBaby}
                    activeIndexObstetricHistory={
                      props.activeIndexObstetricHistory
                    }
                    obstetricChildList={props.obstetricChildList}
                    openModConfirmCreate={props.openModConfirmCreate}
                  />
                </Tab.Pane>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

MomDetail.defaultProps = MomDetailInitial;

export default React.memo(MomDetail);
