import React, {
  useState,
  useEffect,
} from "react";
import { DropdownItemProps, DropdownProps, Form } from "semantic-ui-react";
// MUI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";

// Types
type BottomSheetOptionProps = {
  value?: string | number;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  type?: "phone-number";
  open?: boolean;
  // options
  options?: DropdownItemProps[];
  // callback
  onChange?: (
    event: React.SyntheticEvent<HTMLElement, Event> | null,
    data: DropdownProps
  ) => void;
};

// Styles
const COLORS = {
  backdrop: "rgba(57, 57, 57, 0.45)",
  grey: "#2c2c2c",
  title: "rgba(121, 120, 120, 1)",
  divider: "#ECECEC",
};

export const diaglogStyles = {
  "& .MuiPaper-root": {
    width: 0,
    padding: 0,
  },
  "& .MuiBackdrop-root": {
    backdropFilter: "none",
    backgroundColor: COLORS.backdrop,
  },
  "& .ReactSwipeableBottomSheet--open": {
    maxHeight: "none",
  },
};

const useStyles = makeStyles(() => ({
  dialog: diaglogStyles,
  container: {
    padding: "15px 0 0",
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    "& .title": {
      color: COLORS.title,
      fontSize: "1rem",
      padding: "0 .85rem",
      marginBottom: "0.5rem",
    },
    "& .items": {
      maxHeight: "85vh",
      overflowY: "scroll",
      "& .MuiButton-root": {
        borderBottom: `1px solid ${COLORS.divider}`,
        width: "100%",
        margin: 0,
        boxShadow: "none",
        borderRadius: 0,
        justifyContent: "flex-start",
        color: COLORS.grey,
        padding: "11px 16px",
        fontSize: "1.0625rem",
        fontWeight: "normal",
        textTransform: "unset",
      },
      "&.country-list": {
        boxShadow: "none",
        position: "unset",
        border: "none",
        width: "100%",
        margin: "0",
        "& .flag": {
          transform: "scale(1.15) !important",
          margin: "-0.05rem 0.75rem 0 0.25rem",
        },
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BottomSheetOption = (props: BottomSheetOptionProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  // Effect
  useEffect(() => {
    setOptions(props.options || []);
  }, [props.options]);

  // Handler
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (option: any) => (e: any) => {
    props.onChange?.(e, { ...option, name: props.name });

    handleClose();
  };

  return (
    <>
      {props.type !== "phone-number" && (
        <Form.Select
          required={props.required}
          value={props.value}
          label={props.label}
          placeholder=""
          open={false}
          selection={true}
          disabled={props.disabled}
          options={props.options || []}
          icon="chevron down"
          onClick={handleOpen}
        />
      )}

      <Dialog
        open={props.open ?? open}
        className={`smart-dialog-container ${classes.dialog}`}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogContent>
          <div className={`${classes.container} react-tel-input`}>
            <div className="title">{props.label}</div>
            <div className="items country-list">
              {options.map((option) => (
                <div key={option.key}>
                  <MuiButton
                    variant="text"
                    color="primary"
                    onClick={handleClick(option)}
                  >
                    {props.type === "phone-number" && (
                      <div className={`flag ${option.key}`}></div>
                    )}
                    <div>{option.text}</div>
                    {props.type === "phone-number" && (
                      <div style={{ marginLeft: "0.5rem" }}>
                        (+{option.value})
                      </div>
                    )}
                  </MuiButton>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(BottomSheetOption);
