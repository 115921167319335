import { WasmHandler } from "../../../react-lib/frameworks/WasmController";

import moment from "moment";
import { beToAd } from "../../../react-lib/utils";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
  commonGetPatientDataLatest,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
} from "../../MobClinicInterface";

export type State = {
  developmentInfo?: any;
  developmentMenuList?: any[];
  ageRangeOptions?: any[];
};

export const StateInitial = {
  developmentInfo: {},
  developmentMenuList: [
    {
      id: 1,
      image_url: "/images/child/communication-emotion.png",
      title: "การสื่อสาร",
      method: [
        { id: 1, name: "ทำเสียงในลำคอ (อู, อา, อือ)" },
        { id: 2, name: "ทำเสียงสูงๆต่ำๆ เพื่อแสดงความรู้สึก" },
      ],
    },
    {
      id: 2,
      image_url: "/images/child/emotional-and-social-emotion.png",
      title: "อารมณ์และสังคม",
      method: [
        { id: 3, name: "มองหน้าพูดคุยได้นาน 5 วินาที" },
        { id: 4, name: "ยิ้มตอบเมื่อพ่อแม่ยิ้มและคุยด้วย" },
      ],
    },
    {
      id: 3,
      image_url: "/images/child/fine-motor-emotion.png",
      title: "กล้ามเนื้อมัดเล็ก",
      method: [
        { id: 5, name: "มองตามผ่านกึ่งกลางลำตัว" },
        { id: 6, name: "เด็กกำนิ้วของพ่อแม่ เมื่อเอานิ้วไปแตะฝ่ามือของเด็ก" },
      ],
    },
    {
      id: 4,
      image_url: "/images/child/gross-motor-emotion.png",
      title: "กล้ามเนื้อมัดใหญ่",
      method: [
        { id: 7, name: "ขยับแขนและขาทั้งสองข้างไปด้วยกัน" },
        { id: 8, name: "ท่านอนคว่ำ ยกศีรษะตั้งขึ้นได้ 45 องศา นาน 3 วินาที" },
        { id: 9, name: "ตั้งศีรษะได้ เมื่อพ่อแม่อุ้ม" },
      ],
    },
  ],
  ageRangeOptions: [],
};

export type Event =
  // Get
  | { message: "HandleGetDevelopmentList"; params: {} }
  // Update
  | { message: "HandleUpdateAgeRangeOptions"; params: { birthdate: string } };

type Handler = WasmHandler<State, Event>;

export const HandleGetDevelopmentList: Handler = async (controller, params) => {
  const state = controller.getState();
  let info = {};
  let developList = [...StateInitial.developmentMenuList];
  let errorMessage: any = null;

  let tmpParams = {
    group: GROUP.DEVELOPMENT,
    data_type: DATA_TYPE.DEVELOPMENT,
  };

  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  if (error && error.length > 1) {
    errorMessage = error;
  } else {
    if (response?.id) {
      info = {
        id: response.id,
        measure_date: response.measure_date,
        group: GROUP.DEVELOPMENT,
        data_type: DATA_TYPE.DEVELOPMENT,
        proxy_patient: response.proxy_patient,
        last_update: response.edited_at,
      };
      developList = [...response.data.items];
    }
  }

  console.log("response", response);

  controller.setState({
    developmentMenuList: [...developList],
    developmentInfo: { ...info },
  });
};

export const HandleUpdateAgeRangeOptions: Handler = (controller, params) => {
  const { birthdate } = params;

  if (!birthdate) {
    return;
  }

  let adDate = beToAd(birthdate);
  let months = moment().diff(adDate, "months");

  controller.setState({
    ageRangeOptions: new Array(Math.ceil(months / 2))
      .fill("")
      .map((_: any, index: number) => ({
        key: index + 1,
        value: `${2 * (index + 1)}_month`,
        text: `Age range ${2 * (index + 1)} months`,
      })),
  });
};
