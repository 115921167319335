// To use as a replacement for JavascriptInterface for iOS and Android in Dev mode
// Define the following parameters in .env file at the root of the project
// REACT_APP_USERID=XXX
// REACT_APP_APITOKEN=YYYYYYYYYYYYYY
import Cookies from "js-cookie";
import axios from "axios";

export type Event =
  | { message: "GetLoginInfo"; params: {} }
  | { message: "ScanPHR"; params: {} }
  | { message: "UsageLog"; params: { type: string; content: any } };

// Mock up functions for development.
// Has to be functions with simple arguments (string, int, etc)

export const log = (text: any) => {
  console.log("mobileLog: ", text);
};

export const getLoginInfo = () => {
  const apiToken = Cookies.get("apiToken")
  const userId = Cookies.get("userId")
  if (userId && userId !== "" && apiToken && apiToken !== "") {

      window.setState({
        platform: "WEB",
        loggedin: true,
        login: true, // For IH backward compatibility
        apiToken: apiToken,
        userId: userId,
        // apiToken: process.env.REACT_APP_APITOKEN,
        // userId: process.env.REACT_APP_USERID,
      }) 
    
  } else {
    // Case Anonymous ทำให้เหมือนกับ Native Mobile ที่จะส่งมาแบบนี้ 
    // เปิดเฉพาะตอน Dev 
    if (typeof globalThis !== "undefined" && window.mobile?.isPlatform === undefined && process.env.NODE_ENV === 'development') {
      console.log(" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      console.log(" !!! Attention !!!  MyBplus !!!! Found Dev mode !!!")
      console.log(" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      window.setState({
        platform: "WEB",
        loggedin: true,
        login: true, // For IH backward compatibility
        userId: "",
        apiToken: "",
      })
    } 
  }

  // Without setting loggedin to true, React will go to Login page
};

export const scanPHR = () => {
  console.log("scan PHR");
  alert("Open Scan PHR in iOS/Android");
};

export const usageLog = (params: { url: string; userId: string }) => {
  console.log(params.url, params.userId);
  axios
    .post(
      "http://localhost:8000/api/usagelog/usage-log",
      {
        url: params.url,
        userId: params.userId,
      },
      {
        headers: {
          Authorization: `Token ${process.env.REACT_APP_USAGE_LOG_TOKEN}`,
        },
      }
    )
    .then((res: any) => {
      console.log(res);
    });
};
