import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import { Grid, Image, Button, Divider, Modal } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// UI
import HospitalSearchBox from "./HospitalSearchBox";
import MobSkeletonPHR from "../phr/MobSkeletonPHR";

const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  gray: "#E9E9E9",
  darkgrayHos: "#797878",
  light_grey: "#EEEEEE",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  boxcard: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    margin: "8px",
  } as CSSProperties,
  titletext: {
    width: "75%",
    overflow: "hidden",
    fontSize: "16px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: "2px",
    marginBottom: "3px",
    color: COLORS.primary,
  } as CSSProperties,
  normaltext: {
    fontSize: "14px",
    marginBottom: "3px",
    color: COLORS.darkgrayHos,
  } as CSSProperties,
  boxButtonOPD: {
    padding: "1px",
    background: COLORS.light_grey,
    borderRadius: "10px",
    marginRight: "1px",
  } as CSSProperties,
  buttonOPD: {
    margin: "0px",
    padding: "8px 25px",
    borderRadius: "10px",
    height: "30px",
    background: COLORS.gray,
  } as CSSProperties,
  imageCircular: {
    display: "flex",
    width: "45px",
    height: "45px",
    background: COLORS.superligtblue,
    borderRadius: "23px",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  skeleton_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  } as CSSProperties,
};

type MyMedicationProp = {
  onEvent: (e: any) => any;
  medicationItem: any;
  loadingMedication?: boolean;
  medicationAll: string;
  selectedHospital: any;
  hospitalList: any;
};

type MedicationDataType = {
  name: string;
  image: string | null;
  dose: string | null;
  count: string | null;
  dosage: string | null;
  dosage_image: string | null;
  unit: string | null;
  unit_image: string | null;
  route_image: string | null;
  hospital_name: string | null;
  history: HistoryType;
  is_save_drug: boolean;
};

type HistoryType = {
  count: string;
  doctor: string;
  dosage: string;
  dosage_image: string;
  dose: string;
  encounter: string;
  extra: {
    dispense_type?: string;
  };
  is_save_drug: boolean;
  note: {
    medication: string;
    label: string;
    remark_en: string;
    side_effect: string;
  };
};

const MyMedication: React.FunctionComponent<MyMedicationProp> = (props) => {
  const [medicationData, setMedicationData] = useState<MedicationDataType[]>(
    []
  );
  const [allMedicationList, setAllMedicationList] = useState<
    MedicationDataType[]
  >([]);
  const [encounter, setEncounter] = useState<string>("");
  const [opdFilter, setOpdFilter] = useState<string>("all");
  const [imageFull, setImageFull] = useState<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [searchHospital, setSearchHospital] = useState<string>("");

  useEffect(() => {
    const medFilter = props.medicationItem?.filter((items: any) => {
      if (opdFilter !== "all") {
        return items.history[0].extra.dispense_type === opdFilter;
      } else {
        return items;
      }
    });

    const medFilterDump = medFilter?.map((item: any) => {
      if (item.history.length > 0) {
        return{ ...item, history: item.history[0] };
      }
    });

    setEncounter(medFilterDump?.[0]?.history?.encounter || "");
    setMedicationData(medFilterDump || []);
    setAllMedicationList(medFilterDump || []);
  }, [props.medicationItem, props.loadingMedication, opdFilter]);

  useEffect(() => {
    const list = allMedicationList.filter((item) =>
      item.hospital_name?.toLowerCase()?.includes(searchHospital.toLowerCase())
    );

    setMedicationData(list);
  }, [searchHospital]);

  const hospitalList = useMemo(() => {
    if (props.hospitalList !== undefined) {
      return Array.from(
        new Map(
          props.hospitalList.map((item: any) => [item["name"], item])
        ).values()
      );
    } else {
      const arrayFlat = allMedicationList.map((item, index) => {
        const name = item.hospital_name || props.selectedHospital?.name;
        return { name, code: name };
      });

      return Array.from(
        new Map(arrayFlat.map((item) => [item["name"], item])).values()
      );
    }
  }, [allMedicationList, props.selectedHospital, props.hospitalList]);

  const handleChangeFilter = (type: string) => {
    setOpdFilter(type);
  };

  const handleShowImage = (item: any) => {
    setImageFull(item.image);
    setOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchHospital(value);
  };

  console.log(medicationData, encounter);

  return (
    <div style={styles.parentbox}>
      {/* Search OPD medication */}
      {props.loadingMedication ? (
        <>
          <div style={styles.skeleton_div}>
            <MobSkeletonPHR />
          </div>
        </>
      ) : (
        <>
          {props.medicationAll === "All" && (
            <HospitalSearchBox
              hospitalList={hospitalList}
              onSearchChange={handleSearch}
            />
          )}
          {/* filter OPD medication */}
          {props.medicationItem.length > 0 && (
            <div
              style={{
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "35px", minHeight: "35px" , marginRight: "5px" }}>
                {encounter?.charAt(0) === "O" ? (
                  <Image src="images/drug/icon_opd.png" />
                ) : encounter?.charAt(0) === "E" ? (
                  <Image src="images/drug/icon_er.png" />
                ) : encounter?.charAt(0) === "I" ? (
                  <Image src="images/drug/icon_ipd.png" />
                ) : ""}
              </div>
              <div>
                <Grid columns={3} divided style={styles.boxButtonOPD}>
                  <Grid.Column style={{ padding: "0px 5px 0px 0px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        padding: "8px 22px",
                        fontsize: "16px",
                        background:
                          opdFilter === "all"
                            ? COLORS.font_white
                            : COLORS.light_grey,
                      }}
                      onClick={() => {
                        handleChangeFilter("all");
                      }}
                    >
                      ALL
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "0px 5px 0px 5px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        background:
                          opdFilter === "HOSP"
                            ? COLORS.font_white
                            : COLORS.light_grey,
                      }}
                      onClick={() => {
                        handleChangeFilter("HOSP");
                      }}
                    >
                      <Image
                        src="images/drug/hospital_gray.png"
                        style={{ height: "14px" }}
                      />
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "0px 0px 0px 5px" }}>
                    <Button
                      style={{
                        ...styles.buttonOPD,
                        padding: "8px 26px",
                        background:
                          opdFilter === "OM" ? COLORS.font_white : COLORS.gray,
                      }}
                      onClick={() => {
                        handleChangeFilter("OM");
                      }}
                    >
                      <Image
                        src="images/drug/home_gray.png"
                        style={{ height: "14px" }}
                      />
                    </Button>
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          )}

          {/* card medication */}
          {(medicationData || [])?.length > 0 ? (
            (medicationData || []).map((item, index) => (
              <Grid
                columns={2}
                style={{
                  ...styles.boxcard,
                  display: "grid",
                  gridTemplateColumns: "76.5px 1fr",
                }}
                key={index}
              >
                <Grid.Column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={styles.imageCircular}
                    onClick={() => handleShowImage(item)}
                  >
                    <Image
                      style={{ width: "27px", height: "27px" }}
                      src={
                        item.route_image ||
                        "/images/drug/icon_no_medication1.png"
                      }
                      onClick={() => {}}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column style={{ width: "100%", paddingLeft: 0 }}>
                  <Grid.Row style={{ position: "relative" }}>
                    <div style={{ display: "inline-grid", width: "100%" }}>
                      <div
                        style={{
                          ...styles.titletext,
                          display: "inline-block",
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          width: "40px",
                          position: "absolute",
                          right: "30px",
                        }}
                      >
                        {item.is_save_drug && (
                          <Image src="images/drug/icon_savedrug.png" />
                        )}
                      </div>
                      <div
                        style={{
                          width: "16px",
                          position: "absolute",
                          right: "0px",
                        }}
                      >
                        {item.history?.extra?.dispense_type === "HOSP" ? (
                          <Image src="images/drug/delivery_clinic.png" />
                        ) : item.history?.extra?.dispense_type === "OM" ? (
                          <Image src="images/drug/fontawesome_home.png" />
                        ) : null}
                      </div>
                    </div>
                  </Grid.Row>
                  <Grid.Row style={{ ...styles.titletext, minHeight: "20px" }}>
                    {item.history?.count ? item.history?.count : " "}
                  </Grid.Row>
                  <Grid.Row style={styles.normaltext}>
                    {item.hospital_name || props.selectedHospital?.name || ""}
                  </Grid.Row>
                  <Divider style={{ margin: "0px 0px" }} />
                  <Grid.Row style={styles.normaltext}>
                    {item.history?.note?.medication || ""}
                  </Grid.Row>
                  <Grid.Row style={{...styles.normaltext,margin: "10px 0 0 0 "}}>
                    {item.history?.note?.remark_en || ""}
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            ))
          ) : (
            <div>
              {/** show no data **/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "35%",
                }}
              >
                <Image
                  src="images/drug/icon_no_medication.png"
                  style={{ width: "90px", alignSelf: "flex-start" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  textAlign: "center",
                  whiteSpace: "pre-wrap",
                }}
              >
                <FormattedMessage id={"bplusClinicKey928"} />
              </div>
            </div>
          )}

          {/* Popup image medication */}
          <Modal
            onClose={() => setOpen(false)}
            open={open}
            style={{
              justifyContent: "center",
              height: "50%",
              padding: "60px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Image
                src={imageFull || "images/drug/icon_no_medication1.png"}
                wrapped
              />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

(MyMedication.defaultProps as MyMedicationProp) = {
  onEvent: () => null,
  medicationItem: null,
  loadingMedication: true,
  medicationAll: "Preferred",
  selectedHospital: null,
  hospitalList: null,
};

export default React.memo(MyMedication);
