import React, {
  useMemo,
  useRef,
  MutableRefObject,
  useState,
  CSSProperties,
  useEffect,
} from "react";
// CSS

// Icon

import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";

// Interface
import { Event } from "../ManageInterface";
import { SaveData } from "../post/PostExInterface";
import { prepareDataDraw } from "../clinic/DMBloodPressure";

// UI

type PostChartProps = {
  onEvent: (e: Event) => any;
  onGetChart: () => any;
  // data
  data: any;
  id: string | null;
};

const COLORS = {
  light_blue: "#0072BC",
  dark_grey: "#797878",
  super_dark_grey: "#393939",
  red: "#DA0000",
};

const ICONS = {
  mask: "/images/expansion/mask-icon.png",
  gender: "/images/expansion/gender-icon.png",
};

const defaultOptions = {
  responsive: true,
  legend: {
    display: false,
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          // drawBorder: false,
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontColor: COLORS.dark_grey,
        },
        maxBarThickness: 40,
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "white",
          display: false,
        },
        scaleLabel: {
          // display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          stepSize: 100,
          beginAtZero: true,
          fontColor: COLORS.dark_grey,
        },
      },
    ],
  },
};

const defaultDoughnutOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  layout: {
    padding: 40,
  },
  cutoutPercentage: 75,
};

const engagementField = ["clicks", "likes", "saved", "share"];
const reachField = ["reach"];

const PostChart: React.FunctionComponent<PostChartProps> = (props) => {
  const barRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.onGetChart();
  }, [props.id]);

  useEffect(() => {
    console.log(barRef.current);
  }, [barRef.current]);

  const options = useMemo(() => {
    const defaults: any = defaultOptions;
    return defaults;
  }, []);

  const allList = useMemo(() => {
    return engagementField.flatMap(
      (item) => props.data?.[item] || []
    ) as SaveData[];
  }, [props.data]);

  const reachList = useMemo(() => {
    return reachField.flatMap((item) => props.data?.[item] || []) as SaveData[];
  }, [props.data]);

  const chartData = useMemo(() => {
    const monthList = [4, 3, 2, 1, 0].map((prevIndex) =>
      moment()
        .clone()
        .add(prevIndex * -1, "months")
        .locale("en")
        .format("MMMM YYYY")
    );
    console.log("monthList: ", monthList);

    console.log("allList: ", allList);
    const engagementData = monthList.map((month) => {
      console.log("month: ", month);
      return allList.filter(
        (item) =>
          (typeof item.date === "string"
            ? moment(new Date(item?.date))
            : moment(
                new Date(
                  item?.date?._seconds * 1000 +
                    item?.date?._nanoseconds / 1000000
                )
              )
          )
            .clone()
            .locale("en")
            .format("MMMM YYYY") === month
      ).length;
    });

    console.log("engagementData: ", engagementData);

    const reachData = monthList.map((month) => {
      return reachList.filter(
        (item) =>
          (typeof item.date === "string"
            ? moment(new Date(item?.date))
            : moment(
                new Date(
                  item?.date?._seconds * 1000 +
                    item?.date?._nanoseconds / 1000000
                )
              )
          )
            .clone()
            .locale("en")
            .format("MMMM YYYY") === month
      ).length;
    });

    const datasets = [
      {
        label: "Engagement",
        backgroundColor: "#8191F8",
        stack: "Stack 0",
        data: engagementData,
      },
      {
        label: "Reach",
        backgroundColor: "#B6A3F5",
        stack: "Stack 0",
        data: reachData,
      },
    ];

    return {
      labels: monthList,
      datasets: datasets,
    };
  }, [props.data]);

  const plugins = useMemo(() => {
    const top = 0;
    const radius = 15;
    const allowIndex = [0, 1];
    const allowStack = [2];
    const hideFillText = false;
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          const ctx = chart.ctx;
          const x = chart.chartArea.left;
          const topY = 0;
          const bottomY = chart.chartArea.bottom;
          const rightY = chart.chartArea.right;

          // draw line
          if (ctx) {
            ctx.save();
            //  bottom line
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#C4C4C4";
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, bottomY);
            ctx.lineTo(rightY, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#C4C4C4";
            ctx.stroke();
            ctx.restore();
          }
        },

        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";

          const barDrawText = (bar: any, barlist: any, index: number) => {
            const bottomY = chart.chartArea.bottom;
            const ctx = chart.ctx;
            const y_axis = bar._yScale;
            const _model = bar._model;
            const centerX = _model.x;
            const topY = y_axis.top;
            var centerY = _model.y;
            const centerbarlist = barlist.length / 2;
            var Valuetext = "";

            if (
              bar._chart.config.data.datasets[bar._datasetIndex]?.data[
                bar._index
              ] !== 0
            ) {
              Valuetext = bar._chart.config.data.datasets[
                bar._datasetIndex
              ]?.data[bar._index]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            if (centerbarlist <= index) {
              var bottombar = barlist[index - centerbarlist]._model.y;
              centerY = _model.y + (bottombar - _model.y) / 2;
            } else {
              centerY = _model.y + (bottomY - _model.y) / 2;
            }

            if (!hideFillText) {
              ctx.beginPath();
              ctx.font = `${radius}px Arial`;
              ctx.fillStyle = "white";
              ctx.fillText(Valuetext, centerX, centerY + (radius / 2 + 0.5));
            }
          };

          const { barList } = prepareDataDraw({
            chart,
            allowIndex,
            allowStack,
          });
          // for (const bar of barList) {
          for (var i = 0; i < barList.length; i++) {
            barDrawText(barList[i], barList, i);
          }
        },
      },
    ];
  }, []);

  const totalEngagement = useMemo(() => {
    return engagementField.reduce(
      (result, item) => (result += props.data?.[item]?.length || 0),
      0
    );
  }, [props.data]);

  const patientByGender = useMemo(() => {
    const filterGender = (gender: SaveData["gender"]) =>
      allList.filter((item) => item.gender === gender);

    const maleNumber = filterGender("MALE").length;
    const femaleNumber = filterGender("FEMALE").length;

    return [maleNumber, femaleNumber];
  }, [props.data]);

  const patientByGroup = useMemo(() => {
    const elderlyNumber = allList.filter((item) => item.age >= 60).length;
    const adultNumber = allList.filter(
      (item) => item.age >= 18 && item.age < 60
    ).length;
    const childNumber = allList.filter((item) => item.age < 18).length;

    return [elderlyNumber, adultNumber, childNumber];
  }, [props.data]);

  console.log(props, patientByGender);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "130px auto",
          rowGap: "15px",
        }}
      >
        <label style={{ color: COLORS.dark_grey }}>Total Reach</label>
        <label style={{ fontSize: "1.25rem", color: "#000000" }}>
          <strong>{props.data?.reach?.length || 0}</strong>
        </label>
        <label style={{ color: COLORS.dark_grey }}>Total Engagement</label>
        <label style={{ fontSize: "1.25rem", color: "#000000" }}>
          <strong>{totalEngagement}</strong>
        </label>
      </div>

      <div className="PostBox-summary">
        <div className="PostBox-summary-items">
          {[
            { text: "Clicks", value: props.data?.clicks?.length || 0 },
            { text: "Likes", value: props.data?.likes?.length || 0 },
            { text: "Shares", value: props.data?.shares?.length || 0 },
            { text: "Saved", value: props.data?.saved?.length || 0 },
            { text: "Paid", value: 0 },
          ].map((item, index) => (
            <div key={"summary" + index} className="PostBox-summary-item">
              <label>
                <strong>{item.value}</strong>
              </label>
              <label>{item.text}</label>
            </div>
          ))}
        </div>
      </div>

      <h4 style={{ marginTop: "15px" }}>Post Relevant</h4>

      <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <label
            style={{
              color: COLORS.super_dark_grey,
              marginRight: "10px",
              fontSize: "1.125rem",
            }}
          >
            Tag
          </label>
          <Search style={{ width: "auto" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{
                "aria-label": "search",
                style: { paddingRight: "14px" },
              }}
            />
          </Search> */}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0 0 0 auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#8191F8",
              width: "15px",
              height: "15px",
            }}
          ></div>
          <label style={{ color: COLORS.dark_grey, margin: "0 20px 0 8px" }}>
            Engagement
          </label>

          <div
            style={{
              backgroundColor: "#B6A3F5",
              width: "15px",
              height: "15px",
            }}
          ></div>
          <label style={{ color: COLORS.dark_grey, margin: "0 12px 0 8px" }}>
            Reach
          </label>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Bar
          ref={barRef}
          data={chartData}
          options={options}
          height={160}
          // getElementAtEvent={handleGetElementAtEvent}
          plugins={plugins}
          // redraw={true}
        />
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginTop: "15px" }}>Patient By Gender</h4>
            {/* <MuiButton variant="outlined" className="PostButton-more">
              <MoreHorizIcon />
            </MuiButton> */}
          </div>

          <DoughnutChart
            data={{
              labels: ["Female", "Male"],
              datasets: [
                {
                  label: "",
                  data: patientByGender,
                  backgroundColor: [
                    "rgba(2,110,255,0.85)",
                    "rgba(227,238,255,0.85)",
                  ],
                  hoverBackgroundColor: [
                    "rgba(2,110,255)",
                    "rgba(227,238,255)",
                  ],
                  borderWidth: 0,
                },
              ],
            }}
            icon={ICONS.gender}
            totalvalue={props.data?.reach?.length || 0}
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginTop: "15px" }}>Top Patient By Group</h4>
            {/* <MuiButton variant="outlined" className="PostButton-more">
              <MoreHorizIcon />
            </MuiButton> */}
          </div>

          <DoughnutChart
            data={{
              labels: ["Elderly", "Adult", "Child"],
              datasets: [
                {
                  label: "",
                  data: patientByGroup,
                  backgroundColor: [
                    "rgba(2,110,255,0.85)",
                    "rgba(227,238,255,0.85)",
                    "rgba(95,225,225,0.85)",
                  ],
                  hoverBackgroundColor: [
                    "rgb(2 110 255)",
                    "rgb(227 238 255)",
                    "rgb(95 225 225)",
                  ],
                  borderWidth: 0,
                },
              ],
            }}
            icon={ICONS.mask}
            totalvalue={totalEngagement}
          />
        </div>
      </div>
    </>
  );
};

/* TODO -------------------- LegendLabel -------------------- */
const LegendLabel: React.FunctionComponent<any> = (props) => {
  return (
    <div className="PostLabel-doughnutChart">
      <div style={{ backgroundColor: props.color }}></div>
      <label style={{ color: COLORS.dark_grey, margin: "0 12px 0 8px" }}>
        {props.label}
      </label>
    </div>
  );
};

/* TODO ------------------- DoughnutChart ------------------- */
const DoughnutChart: React.FC<any> = (props) => {
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const divRef = useRef() as MutableRefObject<HTMLDivElement>;

  const outsideRadius = 15;
  //console.log("chen totalvalue: ", props.totalvalue);
  const plugins = useMemo(() => {
    const radius = 15;
    return [
      {
        id: "plugins-chart",
        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          chart.data.datasets.forEach((dataset: any, i: any) => {
            var totalvalue = 0;
            props.data.datasets[0].data.map((item: any) => {
              totalvalue = totalvalue + item;
            });
            chart
              .getDatasetMeta(i)
              .data.forEach((datapoint: any, index: any) => {
                const { x, y } = datapoint.tooltipPosition();
                ctx.beginPath();
                ctx.font = `${radius}px Arial`;
                const halfheight = chart.height / 2;
                const halfwidth = chart.width / 2;
                const dataValue: number = chart.data.datasets[0].data[index];
                const sumValue =
                  totalvalue != 0 ? (dataValue / totalvalue) * 100 : 0;
                // console.log('chen totalvalue: ',totalvalue);
                // console.log('chen dataValue: ',dataValue);
                // console.log('chen sumValue: ',sumValue);
                const positiony = y >= halfheight ? y + 20 : y - 20;
                const positionx = x >= halfwidth ? x + 20 : x - 20;
                const extraPositionx = x >= halfwidth ? 20 : -20;
                const textPosition = x >= halfwidth ? "left" : "right";
                ctx.textAlign = textPosition;
                ctx.textBaseline = "middle";
                ctx.fillStyle = "black";
                ctx.fillText(
                  sumValue != 0 ? `${sumValue.toFixed(0)}%` : " ",
                  positionx + extraPositionx,
                  positiony
                );
                ctx.fillStyle = "#ACACAC";
                ctx.fillText(
                  sumValue != 0 ? `(${dataValue.toLocaleString()})` : " ",
                  positionx + extraPositionx,
                  positiony + 15
                );
              });
          });
        },
      },
    ];
  }, []);

  const getCircleStyle = (radius: number) => {
    return {
      backgroundColor: "rgb(244 250 255)",
      width: `${divRef.current?.offsetHeight + radius}px`,
      height: `${divRef.current?.offsetHeight + radius}px`,
      top: 0,
      position: "absolute",
      left: `calc(50% - ${(divRef.current?.offsetHeight + radius) / 2}px)`,
      borderRadius: "50rem",
    } as CSSProperties;
  };

  return (
    <>
      <div
        ref={divRef}
        style={{ position: "relative", marginTop: "10px" }}
        onLoad={() => {
          setLoadingChart(!loadingChart);
        }}
      >
        <div style={{ paddingTop: `${outsideRadius}px` }}>
          <Doughnut
            data={props.data}
            // redraw={true}
            options={defaultDoughnutOptions}
            plugins={plugins}
          />
        </div>

        {/* background in doughnut นอกสุด */}
        <div
          style={{
            ...getCircleStyle(outsideRadius * -4.5),
            zIndex: -3,
            top: `calc(50% - ${
              (divRef.current?.offsetHeight - outsideRadius * 4.5) / 2 -
              outsideRadius / 2
            }px)`,
          }}
        ></div>

        {/* background in doughnut กลาง */}
        <div
          style={{
            ...getCircleStyle(outsideRadius * -6.5),
            top: `calc(50% - ${
              (divRef.current?.offsetHeight - outsideRadius * 6.5) / 2 -
              outsideRadius / 2
            }px)`,
            zIndex: -2,
            backgroundColor: "white",
          }}
        ></div>

        {/* background in doughnut&icon ด้านในสุด */}
        <div
          style={{
            ...getCircleStyle(outsideRadius * -10.5),
            zIndex: -1,
            backgroundColor: "white",
            top: `calc(50% - ${
              (divRef.current?.offsetHeight - outsideRadius * 10.5) / 2 -
              outsideRadius / 2
            }px)`,
            boxShadow: "0 1px 4px rgb(0 0 0 / 5%)",
            padding: props.icon.includes("gender") ? "12px" : "9px",
          }}
        >
          <img src={props.icon} alt="doughnut.icon" style={{ width: "100%" }} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "40px",
          paddingLeft: "10px",
        }}
      >
        {props.data.labels.map((label: any, index: number) => (
          <LegendLabel
            key={"label" + index}
            label={label}
            color={props.data.datasets[0].backgroundColor[index]}
          />
        ))}
      </div>
    </>
  );
};

export default React.memo(PostChart);
