import React, { createRef, Ref, Component, CSSProperties } from "react";
import { Button, Icon } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const styles = {
  userSection: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
  } as CSSProperties,
  userBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 15px",
  } as CSSProperties,
  manageSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: "1",
  } as CSSProperties,
  buttonPadding: {
    padding: "0px 3px",
  } as CSSProperties,
  borderLeftPointer: {
    borderLeft: "2px solid #E0E0E0",
    padding: "0px 20px",
    cursor: "pointer",
    height: "100%",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  borderLeft: {
    borderLeft: "2px solid #E0E0E0",
    padding: "0px 15px",
  } as CSSProperties,
  fontShow: {
    fontSize: "16px",
    color: "#4F4F4F",
    fontWeight: "bold"
  } as CSSProperties,
};

type WebMomChildTopBarProps = {
  user?: any;
  webSub?: string;
  onEvent?: any;
  setProp?: any;
};

const WebMomChildTopBarInitial = {
  user: {},
  webSub: "",
  onEvent: () => {},
  setProp: null,
};

const WebMomChildTopBar: React.FC<WebMomChildTopBarProps> = (props) => {
  return (
    <div className="TopBar-Main">
      <div style={styles.userSection}>
        <div style={styles.userBox}>
          {props.user.userImage ? (
            <img src={props.user.userImage} />
          ) : (
            <Icon name="user circle" size="big" />
          )}
          {props.user.full_name ? (
            <div style={styles.fontShow}>{props.user.full_name}</div>
          ) : (
            <div><FormattedMessage id="bplusClinicKey65" /></div>
          )}
        </div>
        <div style={styles.buttonPadding}>
          <Button
            color={props.webSub === "patient" ? "blue" : ""}
            onClick={() => {
              props.setProp("webSub", "patient");
            }}
          >
            <Icon name="users" /> <FormattedMessage id="bplusClinicKey372" />
          </Button>
        </div>
        <div style={styles.buttonPadding}>
          <Button
            color={props.webSub === "userManagement" ? "blue" : ""}
            onClick={() => {
              props.setProp("webSub", "userManagement");
            }}
          >
            <Icon name="user plus" /> <FormattedMessage id="bplusClinicKey508" />
          </Button>
        </div>
        <div style={styles.buttonPadding}>
          <Button
            color={props.webSub === "content" ? "blue" : ""}
            onClick={() => {
              props.setProp("webSub", "content");
            }}
          >
            <Icon name="youtube" /> <FormattedMessage id="bplusClinicKey123" />
          </Button>
        </div>
      </div>
      <div style={styles.manageSection}>
        <div style={styles.borderLeftPointer}>
          {" "}
          <Icon name="setting" size="large" />
          <span style={styles.fontShow}><FormattedMessage id="bplusClinicKey309" /></span>
        </div>
        <div style={styles.borderLeft}>
          <Button content="Logout" color="red" />
        </div>
      </div>
    </div>
  );
};

WebMomChildTopBar.defaultProps = WebMomChildTopBarInitial;

export default React.memo(WebMomChildTopBar);
