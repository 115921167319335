
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* [] */
/* params:  */
/* data:  */
const oauth_users_token_sso : 
{
  post:   ({params, data, apiToken, extra}:
            {params?: any,
            data?: any,
            apiToken?: any,
            extra?: any
        }) => any,
} = 
{
  post: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
      
    console.log(`saika ;) ${HOST}/o/api-token/`)

    const result = await to(base.client.post(`${HOST}/o/api-token/`,
      data,
      {
        headers: {
        "Authorization": "Bearer " + apiToken
        }
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default oauth_users_token_sso

