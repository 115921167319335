import WasmController from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// Interface
import * as SelectLocationI from "./appointment/SelectLocationInterface";
import * as SelectDateI from "./appointment/SelectDateInterface";
import * as FilterAppointmentI from "./appointment/FilterAppointmentInterface";
import * as SelectDoctorI from "./appointment/SelectDoctorInterface";
import * as MakeAppointmentI from "./appointment/MakeAppointmentInterface";
import { GetStatuseKYC } from "./MobSmartRegisterInterface";

import V3DoctorScheduleView from "issara-sdk/apis/V3DoctorScheduleView_apps_PRX";
import V3MedProgramsListView from "issara-sdk/apis/V3MedProgramsListView_apps_PRX";
import V3RequestAppointment from "issara-sdk/apis/V3RequestAppointment_apps_PRX";
import UserFamilyAPI from "issara-sdk/apis/UserFamilyAPI_users";
import MyProxyPatientView from "issara-sdk/apis/MyProxyPatientView_apps_PRX";
import ProxyRequestedAppointmentM from "issara-sdk/apis/ProxyRequestedAppointmentM";
import { IntlShape } from "react-intl";
import V3PatientCouponCheckM from "issara-sdk/apis/V3PatientCouponCheckM";
import V3MedServicesListView from "issara-sdk/apis/V3MedServicesListView_apps_PRX";
import SendDoctorReview from "issara-sdk/apis/SendDoctorReview_apps_PRX";
import V3AvailableDoctorListView from "issara-sdk/apis/V3AvailableDoctorListView_apps_PRX";
import DoctorReviews from "issara-sdk/apis/DoctorReviews_apps_PRX";
import V3RegisterHNView from "issara-sdk/apis/V3RegisterHNView_apps_PRX";
import V4RegisterHNView from "issara-sdk/apis/V4RegisterHNView_apps_PRX";
import CheckRequireDiagRuleView from "issara-sdk/apis/CheckRequireDiagRuleView_apps_PRX";
import ProxyPatientHasDivisionList from "issara-sdk/apis/ProxyPatientHasDivisionList_apps_PRX";
import DiagFormDetail from "issara-sdk/apis/DiagFormDetail_apps_PRX";
import PublishedDiagRuleDetail from "issara-sdk/apis/PublishedDiagRuleDetail_apps_PRX";
import OrganizationListView from "issara-sdk/apis/OrganizationListView_users";
import { GetUserWithPatientDetail } from "./MobCouponInterface";

import moment from "moment";

// Config
import CONFIG from "../config/config";
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";

export type State = {
  loggedin?: boolean;
  platform?: string;
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  language?: "en-US" | "th-TH";

  appointmentSlotList?: {
    time_slots?: any[];
    [key: string]: any;
    morning: Record<string, any>[];
    evening: Record<string, any>[];
  };
  familyList?: { results?: any[] };
  medServiceList?: { items?: any[] };
  loadingMainAppointment?: boolean;
  loadingLocation?: boolean;
  loadingSkeleton?: boolean;
  locationList?: any;
  menuTabAppointmentSlot?: "morning" | "evening" | "";
  firstLoadingLocation?: boolean;
  firstLoadingMedService?: boolean;
  stackRememberScroll?: any[];
  openModDoctorReview?: {
    open: boolean;
    error: any;
  };
  isLoadingDoctorReview?: boolean;
  firstLoadingDoctorReview?: boolean;
  doctorDetail?: any;
  doctorReview?: any;
  isFetchAppointmentSlotError?: boolean;
  targetForm?: any;
  targetDiagRule?: any;
  loadingDiagform?: boolean;
  intl?: IntlShape;
  appointmentParams?: any;
  myProfileDetail?: any;
  loadingStatus?: any;
  successMessage?: any;
  errorMessage?: any;
} & SelectLocationI.State &
  SelectDateI.State &
  FilterAppointmentI.State &
  SelectDoctorI.State &
  MakeAppointmentI.State;
// const language = ;  // language without region code
export const StateInitial: State = {
  userId: "",
  loggedin: false,
  platform: "",

  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",

  appointmentSlotList: {},
  familyList: {},
  medServiceList: {},
  loadingMainAppointment: false,
  locationList: {},
  loadingLocation: false,
  menuTabAppointmentSlot: "",
  loadingSkeleton: false,
  firstLoadingLocation: false,
  stackRememberScroll: [],
  openModDoctorReview: {
    open: false,
    error: null,
  },
  isLoadingDoctorReview: false,
  firstLoadingDoctorReview: false,
  doctorDetail: {},
  doctorReview: {},
  isFetchAppointmentSlotError: false,
  targetForm: {},
  targetDiagRule: null,
  loadingDiagform: false,
  // {
  //   total: 2,
  //   next: null,
  //   previous: null,
  //   items: [
  //     {
  //       id: 1,
  //       title: "ศูนย์ฉีดวัคซีนพารากอน",
  //       sub_title: "",
  //       img: "https://assets.brandinside.asia/uploads/2018/08/paragon-group.jpg",
  //     },
  //     {
  //       id: 2,
  //       title: "ศูนย์ฉีดวัคซีน รพ.กรุงเทพ",
  //       sub_title: "(ซ.ศูนย์วิจัย)",
  //       img: "https://ww2.bangkokhospital.com/assets/theme/bangkok/assets/images/corparate/thumb_xs.jpg",
  //     },
  //   ],
  // }
  // ,
  ...SelectLocationI.StateInitial,
  ...SelectDateI.StateInitial,
  ...FilterAppointmentI.StateInitial,
  ...SelectDoctorI.StateInitial,
  ...MakeAppointmentI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "GetLocations"; params: {} }
  | { message: "GetFamilyList"; params: {} }
  | {
      message: "GetMedServiceList";
      params: {
        subspecialty?: number | string;
        is_telemed?: boolean;
        request_type?: "CRM" | "REALTIME";
        hospital?: number;
      };
    }
  | {
      message: "GetAppointmentSlot";
      params: {
        start_date: string | null;
        end_date: string | null;
        loc_code: any;
        doctor_code?: string | null;
        appointment_type?: "specialty" | "check_up";
        is_telemed?: boolean;
      };
    }
  | {
      message: "MakeAppointment";
      params: {
        data: {
          patient?: string | number;
          hn?: string | number;
          doctor_code: string;
          hospital: string | number;
          location_code: string;
          slot_id: string;
          date: string;
          time: string;
          note: string;
        };
        history: any;
        dataCRM: any;
        paramsCheckCoupon: any;
        is_telemed?: boolean;
        request_type?: "CRM" | "REALTIME";
        is_moderna?: boolean;
        book_for_some_else?: boolean;
        locationState?: any;
        appointment_type?: "specialty" | "check_up";
        reasonError?: any;
        hospital?: string | number;
        doctor_id?: number;
        published_diag_rule?: number;
        diag_rule?: number;
        skipConsent?: boolean;
        actionType?: string;
      };
    }
  | { message: "handleGoBackAppointmentSlot"; params: { history: any } }
  | { message: "handleSetMenuTabAppointmentSlot"; params: { value: any } }
  | { message: "GetLoginInfo"; params: {} }
  | {
      message: "HandleRouteChange";
      params: { currentPathname: string; nextPathname: string; action: string };
    }
  | {
      message: "HandleSubmitReview";
      params: {
        encounter_number?: string;
        doctor_code: string;
        score: number | null;
        comment: string;
      };
    }
  | { message: "SetModDoctorReview"; params: { open: boolean; error: any } }
  | {
      message: "GetDoctorDetail";
      params: { doctorCode: string; hospital?: string };
    }
  | {
      message: "HandleSetIsFetchAppointmentSlotError";
      params: { value: boolean };
    }
  | { message: "goToAppointment"; params: { appId: string } }
  | { message: "HandleSelectDiagRule"; params: { history: any; data: any } }
  | { message: "HandleGoBackDiagRuleSearch"; params: { history: any } }
  | { message: "handleGoBackForm"; params: { history: any } }
  | { message: "HandleDiagFormFinished"; params: { history: any } }
  | { message: "HandleGetUserWithPatientDetail"; params: {} }
  | { message: "GetAppointmentObject"; params: {} }
  | {
      message: "HandleMakeAppointmentUnsign";
      params: {
        card?: string;
        init: boolean;
        locState?: any;
        status?: boolean;
        history?: any;
      };
    }
  | SelectLocationI.Event
  | SelectDateI.Event
  | FilterAppointmentI.Event
  | SelectDoctorI.Event
  | MakeAppointmentI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const DidUpdate: Handler = async (controller, params) => {};

export const GetLocations: Handler = async (controller, params) => {
  controller.setState({ loadingLocation: true });
  const [res, error] = await V3MedProgramsListView.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params,
  });

  controller.setState({
    locationList: res || {},
    loadingLocation: false,
    firstLoadingLocation: true,
  });
};

export const GetAppointmentSlot: Handler = async (controller, params) => {
  controller.setState({
    loadingSkeleton: true,
    isFetchAppointmentSlotError: false,
  });

  const { is_telemed, is_specialty, ...paramsUrl } = params;

  const [res, error] = await V3DoctorScheduleView.get({
    params: paramsUrl,
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  console.log(res);
  console.log(error);

  if (error) {
    controller.setState({
      loadingMainAppointment: false,
      loadingSkeleton: false,
      appointmentSlotList: {},
      isFetchAppointmentSlotError: true,
    });
    return;
  }

  const data: any = res || {};
  const isRealTime = res?.time_slots?.[0]?.online;

  const filterTime = (date: moment.Moment) => {
    console.log(date.format("YYYY-MM-DD HH:mm"));
    return (data?.time_slots || []).filter(
      (item: any) =>
        date.diff(moment(`${item.raw_date} ${item.start_time}`)) <= 0
    );
  };

  // filter เวลานัดหมายล่วงหน้า
  // if (params.appointment_type === "check_up") {
  //   data.time_slots = filterTime(moment().add(24, "hours"));
  // }
  // if (params.appointment_type === "specialty") {
  if (isRealTime) {
    if (res?.calendar?.[0].date === moment().format("YYYY-MM-DD")) {
      data.time_slots = filterTime(moment().add(4, "hours"));
    }
  } else {
    data.time_slots = filterTime(moment().add(24, "hours"));
  }
  // }

  const reduceTime = (data?.time_slots || []).reduce(
    (result: { evening: any[]; morning: any[] }, item: any) => {
      const key =
        ("" + item.end_time).localeCompare("12:00") === 1
          ? "evening"
          : "morning";
      if (["Avaliable", "Available"].includes(item.status)) {
        result[key] = [...result[key], item];
      }
      return result;
    },
    { evening: [], morning: [] }
  );

  let menuTab: "" | "morning" | "evening" = "";

  if (!reduceTime.morning[0] && reduceTime.evening[0]) {
    menuTab = "evening";
  } else {
    menuTab = "morning";
  }

  controller.setState({
    loadingMainAppointment: false,
    appointmentSlotList: { ...data, ...reduceTime },
    menuTabAppointmentSlot: menuTab,
    loadingSkeleton: false,
  });
};

/****************************************************************
 *
 * MakeAppointment ตัวนี้ logic ซับซ้อน โปรดระวัง
 *
 ****************************************************************/

export const MakeAppointment: Handler = async (
  controller,
  params: Extract<
    Event,
    {
      message: "MakeAppointment";
    }
  >["params"]
) => {
  const { medServiceList } = controller.getState();
  controller.setState({ loadingMainAppointment: true });
  console.log("MakeAppointment... ", params);

  let apiTokenCheck = Cookies.get("apiToken");
  let userIdCheck = Cookies.get("userId");
  let isAutoHN = false;

  if (!apiTokenCheck || !userIdCheck) {
    // ยังไม่ได้ Login ส่ง params กลับ ไป Native

    if (globalThis.window?.webkit?.messageHandlers?.passObject) {
      console.log("Call Native to iOS");
      globalThis.window?.webkit?.messageHandlers?.passObject.postMessage(
        JSON.stringify(params)
      );
      // globalThis.mobile.unsignMakeAppointmentIOS(JSON.parse(JSON.stringify(params)))
      return Promise.resolve(["pending"]);
    } else if (globalThis.mobile.unsignMakeAppointmentAndroid) {
      console.log("Call Native to Android");
      globalThis.mobile.unsignMakeAppointmentAndroid(JSON.stringify(params));
      return Promise.resolve(["pending"]);
    }
  }

  const locationState = { ...params.locationState };

  delete params.locationState;

  let [resCheck, errCheck]: any = [null, null, {}];

  if (params.paramsCheckCoupon.coupon) {
    [resCheck, errCheck] = await V3PatientCouponCheckM.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: params.paramsCheckCoupon,
    });
  }

  // check ว่าจะไป booking ได้มั้ย
  if (resCheck?.result_code !== "Y" && params.paramsCheckCoupon.coupon) {
    controller.setState({ loadingMainAppointment: false });
    return [resCheck, errCheck || resCheck?.result_details || {}];
  }

  // get profile ดู hn
  const [patientRes, patientErr] = await MyProxyPatientView.retrieve({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  if (params.history?.push) {
    let [user_state] = await SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    });

    const state = controller.getState();

    const tooMany =
      user_state.ekyc_too_many && user_state.ekyc_status !== "success";

    let selectedHospitalCode: number = state.selectedHospital?.code;

    const hasHn = user_state.hospital_profiles.find(
      (profile: any) => profile.hospital_code === selectedHospitalCode
    )?.has_hn;

    // const [status] = await GetStatuseKYC(controller as any, {});

    // เมื่อไม่มี hn และยังไม่ได้ยืนยันตัวตนผ่าน ekyc
    // if (!status.is_verified && !patientErr && !patientRes.hn) {
    let userStatus: string;
    let pathKYC: string = "/kyc/";

    if (hasHn) {
      userStatus = "success";
    } else if (tooMany) {
      userStatus = "kyc_failed";
      const kyc = {
        kyc: { tooMany: true },
      };
      return [null, kyc];
    } else if (
      ["partial_success", "failed", "none"].includes(user_state.ekyc_status)
    ) {
      userStatus = "kyc";
      pathKYC = "/kyc/";
    } else if (!user_state.has_profile) {
      userStatus = "edit_profile";
      pathKYC = "/kyc-step/edit-profile/";
    } else {
      userStatus = "register_hn";
    }

    if (!["register_hn", "success"].includes(userStatus)) {
      const { pathname, search } = params.history.location;
      controller.setState({ loadingMainAppointment: false });

      const kyc = {
        kyc: {
          pathname: pathKYC,
          state: {
            appointment: {
              pathname,
              search,
              state: JSON.stringify({ ...params, locationState }),
            },
          },
          search: `?app=MobRegister`,
        },
      };

      return [null, kyc];
    }
  }

  const response = await RegisterHNAndCheckConsent(controller, {
    params,
    patientErr,
    patientRes,
    medServiceList,
    locationState,
    skipConsent: params.skipConsent,
  });

  if (response) {
    return response;
  }

  // CRM ของเก่า V2 Online == false ซึ่งได้จาก slot ในปฏิทิน ที่เราเลือก
  if (params.request_type === "CRM") {
    controller.setState({ loadingMainAppointment: false });
    return await PostProxyRequestedAppointmentM(controller, params); // CRM Way
  }

  // มี HN ปกติ ของตัวเอง และ ของคนอื่นที่มี HN
  const [res, error] = await V3RequestAppointment.post({
    data: params.data,
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });

  controller.setState({ loadingMainAppointment: false });

  return [res, error];
};

export const RegisterHNAndCheckConsent: Handler = async (
  controller,
  params
) => {
  const { patientErr, patientRes, medServiceList, locationState } = params;
  let isAutoHN = false;

  params = params.params;

  const routes = [
    ...(locationState.routes || []),
    params.history.location.pathname,
  ];

  // Realtime Appointment V3 Online == true ซึ่งได้จาก slot ในปฏิทิน ที่เราเลือก
  // ถ้าเป็น book_for_some_else ให้ เข้า flow register hn
  if ((patientErr && !patientRes?.hn) || params.book_for_some_else) {
    // auto appointment
    const registerHnData: any = {};
    // ถ้านัดให้กับคนอื่น
    if (params.book_for_some_else) {
      registerHnData.patient = params.data.patient;
    }
    if (params.hospital) {
      registerHnData.hospital = params.hospital.toString();
    }
    if (params.doctor_id && registerHnData.patient) {
      registerHnData.doctor = params.doctor_id;
    }

    // RegisterHNView โดยปกติ จะ autoHN ด้วย apiToken ซึ่งถ้าจะ autoHN ให้คนอื่นให้ส่ง data ตามเข้าไป
    // ยิง api ของตัวเอง ไป register ให้คนอื่น
    const api = registerHnData.patient ? V3RegisterHNView : V4RegisterHNView;

    let [resRegist, errRegist] = await api.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: registerHnData,
    });

    if (resRegist?.status) {
      resRegist = resRegist.patient;
    }
    // จะได้ HN ปลาย ทั้ง some_else หรือ ของ ตัวเอง ก็ตาม

    // หากเป็น vaccine moderna และ ยังสร้าง auto appointment ไม่ได้ให้ throw exception
    if (params.is_moderna && !resRegist?.hn) {
      controller.setState({ loadingMainAppointment: false });
      return [null, errRegist || {}];
    }
    // ถ้านัดให้คนอื่นและ register_hn สำเร็จ
    if (params.book_for_some_else && resRegist?.hn) {
      params.data.patient = resRegist.id;
    }

    // check ว่าสร้าง auto hn สำเร็จ
    if (resRegist?.hn) {
      isAutoHN = true;
    }

    // หากยังสร้าง auto appointment ไม่ได้ไปนัดแบบ CRM
    if (!resRegist?.hn) {
      controller.setState({ loadingMainAppointment: false });
      return await PostProxyRequestedAppointmentM(controller, params); // CRM Way
    }
  }

  // หากเป็นแพทย์ออนไลน์ และ make appointment แบบ Realtime และยังไม่ได้ระบุเหตุผล
  if (
    params.appointment_type === "specialty" &&
    !params.data.note &&
    medServiceList?.items?.[0] &&
    params.request_type === "REALTIME"
  ) {
    controller.setState({ loadingMainAppointment: false });

    return [null, params.reasonError];
  }

  // การทำ consent form 2.1.2 สร้าง auto hn และ เป็น Realtime

  if (
    isAutoHN &&
    params.request_type === "REALTIME" &&
    params.history?.push &&
    !params.skipConsent
  ) {
    const hospital = locationState?.hospitalDetail || locationState?.hospital;

    delete hospital.id;

    if (globalThis.mobile?.getNewHN) {
      // iOS Android
      globalThis.mobile.getNewHN();
    }

    const [organization] = await OrganizationListView.list({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: { code: params.hospital },
    });

    const division =
      organization?.items?.[0]?.main_division ||
      organization?.[0]?.items?.[0]?.main_division;

    const promiseArray = [
      CheckRequireDiagRuleView.get({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: { division: division, code: "TM" },
      }),
      CheckRequireDiagRuleView.get({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: { division: "1", code: "CS" },
      }),
    ];

    const [diagRuleTele, diagRule] = await Promise.all(promiseArray);

    const publishDiagRule =
      diagRule?.[0]?.publish_diag_rule || diagRule?.publish_diag_rule;
    const publishDiagRuleTele =
      diagRuleTele?.[0]?.publish_diag_rule || diagRuleTele?.publish_diag_rule;

    // const [diagRule] = await CheckRequireDiagRuleView.get({
    //   apiToken: controller.apiToken || Cookies.get("apiToken"),
    //   params: { division: "1", code: "CS" },
    // });

    params.history.push({
      pathname: `/consent-hospital-form/${publishDiagRule}`,
      state: JSON.stringify({ ...params, locationState, routes }),
      search: `?app=${"IH"}&published_diag_rule=${publishDiagRule}&telemed=${
        params.is_telemed
      }&telemed_diag_rule=${publishDiagRuleTele}`,
    });
    globalThis.history.go();
    return Promise.resolve(["pending"]);
  }

  // Disclaimer
  if (params.history?.push && !params.skipConsent) {
    const promiseArray = [
      // ProxyPatientHasDivisionList.list({
      //   apiToken: controller.apiToken || Cookies.get("apiToken"),
      // }),
      OrganizationListView.list({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: { code: params.hospital },
      }),
      // PublishedDiagRuleDetail.retrieve({
      //   apiToken: controller.apiToken || Cookies.get("apiToken"),
      //   pk: CONFIG.TELEMED_CONSENT_DIAG_RULE,
      // }),
    ];

    if (!params.diag_rule) {
      promiseArray.push(
        MakeAppointmentI.HandleGetHospitalConsent(controller, params)
      );
    }

    const [organization, consents] = await Promise.all(promiseArray);

    if (consents) {
      const consent =
        consents.find(
          (item: any) => String(params.hospital) === item.organization_code
        ) || null;

      params.published_diag_rule = consent?.published_diag_rule;
      params.diag_rule = consent?.diag_rule;
    }

    const division = organization?.[0]?.items?.[0]?.main_division;

    // check ว่าทำ consent telemed แล้วหรือไม่
    const [diagRuleTele] = await CheckRequireDiagRuleView.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: { division: division, code: "TM" },
    });

    let finished = false;

    // check ว่าทำ consent form แล้วหรือไม่
    const [diagRule] = await CheckRequireDiagRuleView.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: { division, diag_rule: params.diag_rule, code: "CS" },
    });

    const [detail] = await DiagFormDetail.retrieve({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      pk: diagRule?.diag_form,
    });

    const answers: any[] = Object.values(JSON.parse(detail?.answers || "{}"));

    const [mainDiagRule] = await CheckRequireDiagRuleView.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: { division: "1", code: "CS" },
    });

    let checkPublished_diag_rule = false;

    if (mainDiagRule?.diag_form == null) {
      checkPublished_diag_rule = true;
    }

    const selectedItems = answers
      .find((item: any) => item.extra_finished)
      ?.extra_items?.filter((item: any) => item.type === "Select");

    selectedItems?.pop();

    // check ว่ายินยอมทั้งหมดหรือไม่ โดยยกเว้นข้อ 2.3
    finished = selectedItems?.every((item: any) =>
      ["ยอมรับ", "ยินยอม", "consent", "รับทราบ", "acknowledged"].includes(
        item.value.toLowerCase()
      )
    );

    if ((!diagRuleTele?.diag_form || !finished) && params.is_telemed) {
      params.history.push({
        pathname: `/consent-hospital-form/${
          finished
            ? diagRuleTele?.publish_diag_rule ||
              CONFIG.TELEMED_CONSENT_DIAG_RULE
            : params.published_diag_rule
        }`,
        state: JSON.stringify({ ...params, locationState, routes }),
        search: `?app=${"IH"}&telemed=true${
          finished ? "&go_to_telemed=true" : ""
        }&telemed_finished=${diagRuleTele?.diag_form ? "true" : "false"}${
          checkPublished_diag_rule
            ? `&published_diag_rule=${mainDiagRule.publish_diag_rule || ""}`
            : ""
        }&telemed_diag_rule=${diagRuleTele?.publish_diag_rule}`,
      });
      globalThis.history.go();
      return Promise.resolve(["pending"]);
    } else if (!finished) {
      params.history.push({
        pathname: `/consent-hospital-form/${
          finished
            ? diagRuleTele?.publish_diag_rule ||
              CONFIG.TELEMED_CONSENT_DIAG_RULE
            : params.published_diag_rule
        }`,
        state: JSON.stringify({ ...params, locationState, routes }),
        search: `?app=${"IH"}&telemed=false${
          finished ? "&go_to_telemed=false" : ""
        }&${
          checkPublished_diag_rule
            ? `&published_diag_rule=${mainDiagRule.publish_diag_rule || ""}`
            : ""
        }`,
      });
      globalThis.history.go();
      return Promise.resolve(["pending"]);
    }
  }
};

export const PostProxyRequestedAppointmentM: Handler = async (
  controller,
  params
) => {
  const [resAppointment, errAppointment] =
    await ProxyRequestedAppointmentM.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: params.dataCRM,
    });
  controller.setState({ loadingMainAppointment: false });
  return [resAppointment, errAppointment];
};

export const GetFamilyList: Handler = async (controller, params) => {
  // controller.setState({ loadingMainAppointment: true })

  // // mock
  // let results = [
  //   {"patient":1, "first_name":"name 1", "last_name":"last 1"},
  //   {"patient":2, "first_name":"name 2", "last_name":"last 2"}
  // ]
  // controller.setState({familyList: {results:results}})
  // controller.setState({ loadingMainAppointment: false})
  // return

  const [res, error] = await UserFamilyAPI.list({
    params: params,
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });
  console.log(res);
  console.log(error);

  if (error) {
    controller.setState({ loadingMainAppointment: false, familyList: {} });
    return;
  }

  controller.setState({ loadingMainAppointment: false, familyList: res || {} });
};

export const GetMedServiceList: Handler = async (controller, params) => {
  // let mock = {
  //   "total": 1,
  //   "next": null,
  //   "previous": null,
  //   "items": [
  //       {
  //         "id": 1,
  //         "name": "test",
  //         "name_en": "test",
  //         "booking_code": "test1234",
  //         "has_free_text": false,
  //         "is_free_text_required": false,
  //         "show_by_default": false
  //       },
  //       {
  //         "id": 2,
  //         "name": "test2",
  //         "name_en": "test",
  //         "booking_code": "test1234",
  //         "has_free_text": true,
  //         "is_free_text_required": false,
  //         "show_by_default": false
  //     }
  //   ]
  // }

  // เมื่อเป็น telemed และ เป็น REALTIME ไม่ต้อง getMedSrvice
  if (params.is_telemed && params.request_type === "REALTIME") {
    setTimeout(() => {
      controller.setState({ firstLoadingMedService: true });
    });
    return;
  }

  const [res, error] = await V3MedServicesListView.list({
    params: params,
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });
  console.log(res);
  console.log(error);

  setTimeout(() => {
    controller.setState({ firstLoadingMedService: true });
  });

  if (error) {
    controller.setState({ loadingMainAppointment: false, medServiceList: {} });
    return;
  }

  controller.setState({
    loadingMainAppointment: false,
    medServiceList: res || {},
  });
};

export const handleGoBackAppointmentSlot: Handler = (controller, params) => {
  params.history.goBack();
  controller.setState({
    appointmentSlotList: {},
    familyList: {},
    medServiceList: {},
    menuTabAppointmentSlot: "",
    firstLoadingMedService: false,
  });
};

export const handleSetMenuTabAppointmentSlot: Handler = (
  controller,
  params
) => {
  controller.setState({ menuTabAppointmentSlot: params.value });
};

export const HandleRouteChange: Handler = (controller, params) => {
  const { stackRememberScroll } = controller.getState();
  const { currentPathname, nextPathname, action } = params;
  const reg = /\/(.*?)($|\/)/i;
  const current: string = currentPathname.match(reg)?.[1];
  const next: string = nextPathname.match(reg)?.[1];
  const scrollElm = Array.from(document.querySelectorAll(".remember-scroll"));

  if (["REPLACE", "PUSH"].includes(action)) {
    const target = stackRememberScroll?.find((item) => item.key === current);

    if (!target) {
      controller.setState({
        stackRememberScroll: [
          ...(stackRememberScroll || []),
          { key: current, scroll_top: scrollElm?.map((elm) => elm?.scrollTop) },
        ],
      });
    }
  } else if (action === "POP") {
    const handleSetScrollTop = (data: any, scrollElm: any) => {
      if (!scrollElm?.length) {
        return;
      }

      setTimeout(() => {
        const scrollElm = Array.from(
          document.querySelectorAll(".remember-scroll")
        );
        if (scrollElm.length > 1) {
          scrollElm.map(
            (elm, index) => (elm.scrollTop = data?.scroll_top?.[index])
          );
        }
      });

      scrollElm[0].scrollTop =
        data?.scroll_top?.[
          +(scrollElm[0]?.className?.match(/sec-(\d+)/i)?.[1] || 1) - 1
        ];
    };

    const target = stackRememberScroll?.find((item) => item.key === next);

    handleSetScrollTop(target, scrollElm);

    const intervalScroll = setInterval(() => {
      const scrollElm = Array.from(
        document.querySelectorAll(".remember-scroll")
      );

      if (scrollElm.length) {
        clearInterval(intervalScroll);

        handleSetScrollTop(target, scrollElm);
      }
    }, 10);

    setTimeout(() => clearInterval(intervalScroll), 100);

    const filter = stackRememberScroll?.filter((item: any) => {
      return item.key !== next;
    });

    controller.setState({
      stackRememberScroll: filter,
    });
  }
};

export const HandleSubmitReview: Handler = async (controller, params) => {
  controller.setState({ isLoadingDoctorReview: true });
  const [res, error] = await SendDoctorReview.create({
    apiToken: Cookies.get("apiToken"),
    data: params,
  });
  controller.setState({ isLoadingDoctorReview: false });

  if (error) {
    controller.setState({ openModDoctorReview: { open: true, error } });
    return;
  }
  // controller.handleEvent({
  //   message: "HandleEvent",
  //   params: { name: "backToNative" },
  // });
};

export const SetModDoctorReview: Handler = (controller, params) => {
  controller.setState({ openModDoctorReview: params });
};

export const GetDoctorDetail: Handler = async (controller, params) => {
  // controller.setState({firstLoadingDoctorReview:false})
  const [res, error, network] = await V3AvailableDoctorListView.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      code: params.doctorCode,
      hospital: params.hospital,
    },
  });

  const [resReview, errorReview] = await DoctorReviews.retrieve({
    apiToken: Cookies.get("apiToken"),
    doctor_id: res?.items?.[0]?.id,
  });

  controller.setState({
    doctorDetail: res?.items?.[0] || {},
    doctorReview: resReview,
    firstLoadingDoctorReview: true,
  });
};

export const HandleSetIsFetchAppointmentSlotError: Handler = (
  controller,
  params
) => {
  controller.setState({ isFetchAppointmentSlotError: params.value });
};

export const HandleGetUserWithPatientDetail: Handler = async (
  controller,
  params
) => {
  const { patientDetail } = controller.getState();

  if (Object.keys(patientDetail || {})?.[0]) {
    return;
  }

  const [patientData, patientError] = await GetUserWithPatientDetail(
    controller as any,
    {}
  );
  controller.setState({ patientDetail: patientData || {} });
};

export const handleGoBackForm: Handler = (controller, params) => {
  if (params.history?.location?.state?.mob === "MobFeed") {
    params.history.go(-1);
    setTimeout(() => {
      params.history.go(0);
    }, 10);
  } else {
    params.history.goBack();
  }
};

export const HandleSelectDiagRule: Handler = (controller, params) => {
  const search = new URLSearchParams(window.location.search);
  const med_program = search.get("med_program");
  var obj = JSON.parse(params.data.content);
  controller.setState({
    targetForm: obj,
    targetDiagRule: params.data,
  });
  params.history.push(
    `/form/${params.data.id}/?app=MobAppointment&med_program=${med_program}`
  );
};

export const HandleDiagFormFinished: Handler = async (controller, params) => {
  const search = new URLSearchParams(window.location.search);
  const locCode = search.get("locCode");
  const stateHistory = params.history?.location?.state || {};

  params.history.push({
    pathname: `/select-date/${locCode}/?app=MobAppointment`,
    state: {
      ...stateHistory,
    },
  });
};

export const HandleMakeAppointmentUnsign: Handler = async (
  controller,
  params
) => {
  let state = controller.getState();

  if (params.init) {
    if (typeof params.locState === "string") {
      const data = JSON.parse(params.locState);

      if (!data.complete_appointment) {
        setTimeout(() => {
          controller.setState({ appointmentParams: data });
        }, 100);
      }

      return;
    }

    controller.handleEvent({
      message: "GetAppointmentObject",
      params: {},
    });
  } else {
    let appointmentParams = state.appointmentParams;
    const card = params.card || "";
    let status = params.status ? "LOADING" : true;

    await controller.setState({
      loadingStatus: { ...state.loadingStatus, [card]: status },
    });

    if (appointmentParams?.request_type === "CRM") {
      appointmentParams.dataCRM.patient = state?.myProfileDetail?.patient; // อันนี้ จะ override state
    } else {
      appointmentParams.data.patient = state?.myProfileDetail?.patient; // อันนี้ จะ override state
    }

    let response: any = [];

    delete appointmentParams.locationState;

    // เกิดจาก auto hn ไม่ได้และเข้า CRM redirect มาจากหน้า ekyc
    if (appointmentParams.response?.length) {
      response = appointmentParams.response;
    } else {
      response = await controller.handleEvent({
        message: "MakeAppointment",
        params: appointmentParams,
      });
    }

    state = controller.getState();
    status = params.status ? "SUCCESS" : false;

    await controller.setState({
      loadingStatus: { ...state.loadingStatus, [card]: status },
    });

    if (response?.[1]) {
      if (response?.[1]?.ResponseStatus?.Description?.includes("full")) {
        await controller.setState({
          errorMessage: { ...state.successMessage, [`${card}_FULL`]: true },
        });
      }
    } else {
      const location = params.history.location;
      let locState = location.state;
      const isString = typeof locState === "string";

      if (isString) {
        locState = JSON.parse(locState);
        locState.complete_appointment = true;
        locState = JSON.stringify(locState);
      } else {
        locState.complete_appointment = true;
      }

      params.history.replace({
        pathname: location.pathname,
        search: location.search,
        state: locState,
      });

      await controller.setState({
        successMessage: { ...state.successMessage, [card]: true },
      });
    }
  }
};
