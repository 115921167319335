export default [
  { 
    apiKey: "AIzaSyD3-V5j5bAty2eEL-QkKNXm4WyhqpEmu7w",
    authDomain: "penguin-dev-e0943.firebaseapp.com",
    databaseURL: "https://penguin-dev-e0943.firebaseio.com",
    projectId: "penguin-dev-e0943", // ห้ามซ้ำ
    storageBucket: "penguin-dev-e0943.appspot.com",
    messagingSenderId: "614639654931",
    appId: "1:614639654931:web:e478ca06d3cf634ea287e6",
    measurementId: "G-B46QQ4H7YL",
    gsBucketName: "gs://mybplus-content",
    serviceGsBucketName: "gs://mybplus-services",
    functionRegion: "asia-east1"
  }, 
  {
    apiKey: "AIzaSyBCd9NzXfdhFROKNaRTfU794XWndD_vnVo",
    authDomain: "my-b-plus-staging.firebaseapp.com",
    databaseURL: "https://my-b-plus-staging.firebaseio.com",
    projectId: "my-b-plus-staging",
    storageBucket: "my-b-plus-staging.appspot.com",
    messagingSenderId: "289561767544",
    appId: "1:289561767544:web:9b8d407aa5aa45656fc78d",
    measurementId: "G-RRX11V87RF",
    gsBucketName: "gs://mybplus-content-staging",
    serviceGsBucketName: "gs://mybplus-services-staging",
    functionRegion: "asia-east1"
  },
  {
    apiKey: "AIzaSyC_vTy6RsJUBTIuygNGwjxHlcNisiFVISU",
    authDomain: "issara-platform.firebaseapp.com",
    projectId: "issara-platform",
    storageBucket: "issara-platform.appspot.com",
    messagingSenderId: "103198969033",
    appId: "1:103198969033:web:71d2643693a1fbe1af9118",
    measurementId: "G-VKZXBS0NHM",
    gsBucketName: "gs://issara-content",
    serviceGsBucketName: "gs://issara-services",
    functionRegion: "asia-east1"
  }
]

