import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
// UI
import SnackMessage from "../../clinic/SnackMessage";
import MomContractionCountChart from "../../clinic/MomContractionCountChart";

type WebMomContractionCountProps = {
  onEvent: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  contractionCountHistory: any;
  contractionFilterDuration: any;
};

const WebMomContractionCountInitial: WebMomContractionCountProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  contractionCountHistory: {},
  contractionFilterDuration: "7",
};

const WebMomContractionCount: React.FC<WebMomContractionCountProps> = (
  props
) => {
  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="contraction.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/mom/contraction.png"
        />
        <span><FormattedMessage id="bplusClinicKey125" /></span>
      </div>
      <div className="sub-layout">
        <MomContractionCountChart
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          contractionFilterDuration={props.contractionFilterDuration}
          contractionCountHistory={props.contractionCountHistory}
          height={100}
          isMyTab={true}
          hideWebMomContractionCount={true}
        />
      </div>
    </div>
  );
};

WebMomContractionCount.defaultProps = WebMomContractionCountInitial;

export default React.memo(WebMomContractionCount);
