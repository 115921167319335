import WasmController from "../../../react-lib/frameworks/WasmController";

// Interface
import { State as MainState } from "../../ManageInterface";
// APIs
import DivisionList from "../../../issara-sdk/apis/DivisionList_core";
import DoctorList from "../../../issara-sdk/apis/DoctorList_core";

export type State = {
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medDivisionTKOptions?: any[];
  medDivisionCROptions?: any[];
  medDoctorTKOptions?: any[];
  medDoctorCROptions?: any[];
};

export const StateInitial: State = {
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medDivisionTKOptions: [],
  medDivisionCROptions: [],
  medDoctorTKOptions: [],
  medDoctorCROptions: [],
};

export type Event =
  // GET
  | {
      message: "handleGetMedicationDivisionOptions";
      params: { limit: number; offset: number; stateKey: string; name: string };
    }
  | {
      message: "handleGetMedicationDoctorOptions";
      params: {
        limit: number;
        offset: number;
        stateKey: string;
        name_code: string;
      };
    };

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "patientSearch"
>;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

// GET
export const handleGetMedicationDivisionOptions: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingDivisionOptions: true });

  const [response, error, network] = await DivisionList.list({
    apiToken: controller.apiToken,
    params: params, // limit, offset, name
  });
  let result: any[] = [];
  if (error) {
  } else {
    result = CreateOptions(response?.items, "name");
  }
  // stateKey = TK, CR
  if (params.stateKey === "") {
    controller.setState({
      loadingDivisionOptions: false,
      medDivisionTKOptions: result,
      medDivisionCROptions: result,
    });
  } else {
    controller.setState({
      loadingDivisionOptions: false,
      [`medDivision${params.stateKey}Options`]: result,
    });
  }
};

export const handleGetMedicationDoctorOptions: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingDoctorOptions: true });

  const [response, error, network] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: params, // limit, offset, name_code
  });
  let result: any[] = [];
  if (error) {
  } else {
    result = CreateOptions(response?.items, "name_code");
  }
  // stateKey = TK, CR
  if (params.stateKey === "") {
    controller.setState({
      loadingDoctorOptions: false,
      medDoctorTKOptions: result,
      medDoctorCROptions: result,
    });
  } else {
    controller.setState({
      loadingDoctorOptions: false,
      [`medDoctor${params.stateKey}Options`]: result,
    });
  }
};

// UTIL
const CreateOptions = (items: any[], textKey: string) => {
  const mapOption = (item: any, index: number) => ({
    key: index,
    text: item?.[textKey],
    value: item.id,
  });
  return items.map(mapOption);
};
