import React, { useEffect, useState } from "react";
import moment from "moment";
import {IntlProvider, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import { Button, Checkbox, Form, Grid, Header, Input } from "semantic-ui-react";

import * as DrugDMConst from "./DMDrug";
import { TimeTextBox } from "../../react-lib/apps/common";

// ---------- SubReminder
type SubReminderProps = {
  onEvent: any;
  title: string;
  is_reminder: boolean;
  before_meal: string;
  after_meal: string;
  name: string
};

const SubReminder: React.FC<SubReminderProps> = (props) => {
  const handleChangeReminder = (event: any, data: any) => {
    if (data.type === "checkbox") {
      const key = `is_${props.title.toLocaleLowerCase()}_reminder`;
      console.log("props.title",props.title)
      props.onEvent({
        message: "handleSaveRecordTime",
        params: { name: key, value: data.checked },
      });
    } else {
      const key = `${props.title.toLocaleLowerCase()}_${data.name}`;
      props.onEvent({
        message: "handleSaveRecordTime",
        params: { name: key, value: data.value },
      });
    }
  };

  return (
    <Grid.Row style={{ paddingTop: "0px" }}>
      <Grid.Column width={4}>
        <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
          {props.name}
        </Header>
      </Grid.Column>
      <Grid.Column width={12}>
        <Checkbox
          toggle
          checked={props.is_reminder ? true : false}
          onChange={handleChangeReminder}
        />
      </Grid.Column>
      <Grid.Column width={4} style={DrugDMConst.styles.blueMeal}>
        <FormattedMessage id="bplusClinicKey84" />
      </Grid.Column>
      <Grid.Column width={4} style={{ padding: "0px", margin: "-5px" }}>
        <TimeTextBox
          style={{ transform: "scale(0.8)", minWidth: "100%" }}
          interval={10}
          minHour={0}
          maxHour={23}
          value={props.before_meal || moment().format("HH:00")}
          onTimeChange={(event: any, data: string) => {
            handleChangeReminder(event, { name: "before_meal", value: data });
          }}
        />
      </Grid.Column>
      <Grid.Column width={4} style={DrugDMConst.styles.blueMeal}>
        <FormattedMessage id="bplusClinicKey53" />
      </Grid.Column>
      <Grid.Column width={4} style={{ padding: "0px", margin: "-5px" }}>
        <TimeTextBox
          style={{ transform: "scale(0.8)", minWidth: "100%" }}
          interval={10}
          minHour={0}
          maxHour={23}
          value={props.after_meal || moment().format("HH:00")}
          onTimeChange={(event: any, data: string) => {
            handleChangeReminder(event, { name: "after_meal", value: data });
          }}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

// ---------- DrugDMRecordTime
type DMDrugRecordTimeProps = {
  onEvent: any;
  selectedPatient: any;
  drugDataSetting: {
    date: any;
    is_reminder: boolean;
    is_morning_reminder: boolean;
    is_afternoon_reminder: boolean;
    is_evening_reminder: boolean;
    is_bedtime_reminder: boolean;
    is_others_reminder: boolean;
    morning_before_meal: string;
    morning_after_meal: string;
    afternoon_before_meal: string;
    afternoon_after_meal: string;
    evening_before_meal: string;
    evening_after_meal: string;
    bed_meal: string;
    other_meals: any[];
    remind_before: number | null;
  };
};

const DMDrugRecordTimeInitial: DMDrugRecordTimeProps = {
  onEvent: () => null,
  selectedPatient: {},
  drugDataSetting: {
    date: null,
    is_reminder: false,
    is_morning_reminder: false,
    is_afternoon_reminder: false,
    is_evening_reminder: false,
    is_bedtime_reminder: false,
    is_others_reminder: false,
    morning_before_meal: "",
    morning_after_meal: "",
    afternoon_before_meal: "",
    afternoon_after_meal: "",
    evening_before_meal: "",
    evening_after_meal: "",
    bed_meal: "",
    other_meals: [],
    remind_before: null,
  },
};

const DMDrugRecordTime: React.FC<DMDrugRecordTimeProps> = (props) => {
  const intl = useIntl();
  const [recordTime, setRecordTime] = useState({
    date: null,
    is_reminder: false,
    is_morning_reminder: false,
    is_afternoon_reminder: false,
    is_evening_reminder: false,
    is_bedtime_reminder: false,
    is_others_reminder: false,
    morning_before_meal: "",
    morning_after_meal: "",
    afternoon_before_meal: "",
    afternoon_after_meal: "",
    evening_before_meal: "",
    evening_after_meal: "",
    bed_meal: "",
    other_meals: [],
    remind_before: "",
  });

  useEffect(() => {
    props.onEvent({message: "handleGetDrugSetting", params: {}})
  }, [props.selectedPatient])

  useEffect(() => {
    let data: DMDrugRecordTimeInitial.drugDataSetting = {};
    Object.keys(DMDrugRecordTimeInitial.drugDataSetting).map((key) => {
      data[key] = props.drugDataSetting[key];
    });
    setRecordTime(data);
  }, [props.drugDataSetting]);

  const handleChangeRecordTime = (event: any, data: any) => {
    if (data.type === "checkbox") {
      props.onEvent({
        message: "handleSaveRecordTime",
        params: { name: data.name, value: data.checked },
      });
    } else {
      props.onEvent({
        message: "handleSaveRecordTime",
        params: { name: data.name, value: data.value },
      });
    }
  };

  return (
    <Form size="small" style={{ padding: "70px 10px 30px 20px" }}>
      <Grid verticalAlign="middle" divided="vertically">
         {/* Time reminder */}
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={6}>
            <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
              <FormattedMessage id="weight.time_reminder" />
            </Header>
          </Grid.Column>
          <Grid.Column width={10}>
            <Checkbox
              toggle
              name="is_reminder"
              checked={recordTime.is_reminder ? true : false}
              onChange={handleChangeRecordTime}
            />
          </Grid.Column>

          <Grid.Column width={16}>
            <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
              <FormattedMessage id="bplusClinicKey486" />
            </Header>
          </Grid.Column>
        </Grid.Row>

        {/* Morning */}
        <SubReminder
          onEvent={props.onEvent}
          name={`${intl.formatMessage({ id: "bplusClinicKey329" })}`}
          title={`Morning`}
          is_reminder={recordTime.is_morning_reminder}
          before_meal={recordTime.morning_before_meal}
          after_meal={recordTime.morning_after_meal}
        />

        {/* Afternoon */}
        <SubReminder
          onEvent={props.onEvent}
          name={`${intl.formatMessage({ id: "bplusClinicKey708" })}`}
          title={`Afternoon`}
          is_reminder={recordTime.is_afternoon_reminder}
          before_meal={recordTime.afternoon_before_meal}
          after_meal={recordTime.afternoon_after_meal}
        />

        {/* Evening */}
        <SubReminder
          onEvent={props.onEvent}
          name={`${intl.formatMessage({ id: "bplusClinicKey190" })}`}
          title={`Evening`}
          is_reminder={recordTime.is_evening_reminder}
          before_meal={recordTime.evening_before_meal}
          after_meal={recordTime.evening_after_meal}
        />

        {/* Bed time */}
        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={4}>
            <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
              <FormattedMessage id="bplusClinicKey81" />
            </Header>
          </Grid.Column>
          <Grid.Column width={12}>
            <Checkbox
              toggle
              name="is_bedtime_reminder"
              checked={recordTime.is_bedtime_reminder ? true : false}
              onChange={handleChangeRecordTime}
            />
          </Grid.Column>
          <Grid.Column width={4} style={DrugDMConst.styles.blueMeal}>
            <FormattedMessage id="bplusClinicKey81" />
          </Grid.Column>
          <Grid.Column width={4} style={{ padding: "0px", margin: "-5px" }}>
            <TimeTextBox
              style={{ transform: "scale(0.8)", minWidth: "100%" }}
              interval={10}
              minHour={0}
              maxHour={23}
              value={recordTime.bed_meal || moment().format("HH:00")}
              onTimeChange={(event: any, data: string) => {
                handleChangeRecordTime(event, {
                  name: "bed_meal",
                  value: data,
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>

        {/* Manual */}
        <Grid.Row style={{ paddingTop: "0px", paddingBottom: "10px" }}>
          <Grid.Column width={9}>
            <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
              <FormattedMessage id="bplusClinicKey45" />
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Checkbox
              toggle
              name="is_others_reminder"
              checked={recordTime.is_others_reminder ? true : false}
              onChange={handleChangeRecordTime}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid
        verticalAlign="middle"
        divided="vertically"
        style={{ padding: "0px 10px 0px 10px", marginTop: "0px" }}
      >
        <Grid.Column width={11} style={{ paddingBottom: "15px" }}>
          <Grid verticalAlign="middle">
            {recordTime?.other_meals?.map((item: any, index: number) => {
              return (
                <>
                  <Grid.Column width={6} style={DrugDMConst.styles.blueMeal}>
                    <FormattedMessage id="common.time" />
                  </Grid.Column>
                  <Grid.Column
                    width={6}
                    style={{ padding: "0px", margin: "-5px" }}
                  >
                    <TimeTextBox
                      style={{ transform: "scale(0.8)", minWidth: "100%" }}
                      interval={10}
                      minHour={0}
                      maxHour={23}
                      value={item}
                      onTimeChange={(event: any, data: string) => {
                        let result: any[] = [...recordTime.other_meals];
                        result[index] = data;
                        handleChangeRecordTime(event, {
                          name: "other_meals",
                          value: result,
                        });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={3}
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <Button
                      icon="minus"
                      size="mini"
                      color="red"
                      onClick={(event: any) => {
                        let result: any[] = [...recordTime.other_meals];
                        result.splice(index, 1);
                        handleChangeRecordTime(event, {
                          name: "other_meals",
                          value: result,
                        });
                      }}
                    />
                  </Grid.Column>
                </>
              );
            })}
            <Grid.Column width={1} style={DrugDMConst.styles.noPadding}>
              <Button
                icon="plus"
                size="mini"
                color="orange"
                onClick={(event: any) => {
                  handleChangeRecordTime(event, {
                    name: "other_meals",
                    value: [...(recordTime?.other_meals || []), "00:00"],
                  });
                }}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={5}></Grid.Column>

         {/* Remind before */}
        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={9}>
            <Header
              as="h4"
              style={{
                color: DrugDMConst.COLOR_CODE.darkGrey,
                textAlign: "right",
              }}
            >
              <FormattedMessage id="bplusClinicKey419" />
            </Header>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: "0px" }}>
            <Input
              fluid
              type="number"
              name="remind_before"
              value={recordTime.remind_before}
              onChange={handleChangeRecordTime}
            />
          </Grid.Column>
          <Grid.Column width={4}><FormattedMessage id="bplusClinicKey323" /></Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: "60px" }}>
          <Grid.Column style={{textAlign: "center"}}>
            <Button content={`${intl.formatMessage({ id: "bplusClinicKey431" })}`} color="blue" onClick={()=> {props.onEvent({message: "handleSaveAllRecordTime", params: {}})}}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

DMDrugRecordTime.defaultProps = DMDrugRecordTimeInitial;

export default React.memo(DMDrugRecordTime);
