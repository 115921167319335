import React, { CSSProperties, useEffect } from "react";
import moment from "moment";
import { Button, Dimmer, Grid, Icon, Image, Loader } from "semantic-ui-react";
import { AppBar } from "@material-ui/core";
import {
  BACKEND_DATE_FORMAT,
  BE_DATE_FORMAT,
  formatDateByLanguage,
} from "../MobClinicInterface";
// UI
import SnackMessage from "./SnackMessage";
import MobHead from "../MobHead";
import DMPersonalLabChart from "./DMPersonalLabChart";
import DMPersonalScoreChart from "./DMPersonalScoreChart";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import "../../css/WebClinic.scss";

export const styles = {
  topBG: {
    backgroundColor: "var(--bg-indigo-grey)",
    paddingBottom: "15px",
  } as CSSProperties,
  gridLab: {
    width: "90%",
    backgroundColor: "var(--bg-indigo-grey)",
    margin: "20px 0px",
    paddingBottom: "10px",
  } as CSSProperties,
  noPadding: {
    padding: "0px 1px 0px 1px",
  } as CSSProperties,
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
};

export const DATE_FORMAT = "DD/MM/YYYY";

// ---------- DMPersonalScore
type DMPersonalScoreProps = {
  onEvent: any;
  onSetBackToApp: any;
  onShowLab: any;
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  latestWeight: any;
  scoreSummary: any[];
  labSummary: any;
  language?: any;
};

const DMPersonalScoreInitial: DMPersonalScoreProps = {
  onEvent: () => null,
  onSetBackToApp: () => null,
  onShowLab: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  latestWeight: {},
  scoreSummary: [],
  labSummary: {},
};

const DMPersonalScore: React.FC<DMPersonalScoreProps> = (props) => {
  useEffect(() => {
    props.onEvent({ message: "handleGetLatestWeight" });
  }, [props.selectedPatient]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <AppBar position="fixed" color="default" style={{ marginTop: "0px" }}>
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={`${props.selectedPatient?.first_name} ${props.selectedPatient?.last_name}`}
        />
      </AppBar>

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid verticalAlign="middle" style={styles.topBG}>
        <Grid.Column
          width={16}
          style={{
            padding: "30px 0px 0px",
            background: "var(--bg-indigo-grey)",
          }}
        >
          <Image
            centered
            size="small"
            src={
              props.selectedPatient?.image_url ||
              props.selectedPatient?.image ||
              "/images/Appointment/user-icon.png"
            }
          />
        </Grid.Column>

        <Grid.Row centered>
          <Grid.Column width={12}>
            <Button
              circular
              fluid
              size="large"
              style={{ backgroundColor: "var(--bg-medium-blue-50)" }}
              onClick={props.onShowLab}
            >
              <Icon name="lab" />
              <FormattedMessage id="bplusClinicKey547" />
            </Button>
          </Grid.Column>
        </Grid.Row>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            width: "100%",
            fontSize: props.language === "th-TH" ? "13px" : "",
            padding: "0px 0px 5px 0px",
          }}
        >
          <div style={{ color: "var(--text-mauve)" }}>
            <Icon name="calendar alternate outline" />
          </div>
          <div>
            <label style={{ color: "var(--text-light-blue)" }}>
              {props.selectedPatient?.birthdate
                ? formatDateByLanguage(
                    moment(
                      props.selectedPatient?.birthdate,
                      BACKEND_DATE_FORMAT
                    ).format(BE_DATE_FORMAT)
                  )
                : ""}
            </label>
            <label style={{ color: "var(--text-grey)", fontWeight: "bold" }}>
              {props.selectedPatient?.age
                ? `  (${props.selectedPatient?.age})`
                : ""}
            </label>
          </div>
          <div
            style={{
              color: "var(--text-light-blue)",
              padding: "0 15px 0 15px",
            }}
          >
            {props.selectedPatient?.gender_name}
          </div>
          <Icon name="tint" style={{ color: "var(--text-mauve)" }} />
          <div style={{ color: "var(--text-mauve)" }}>
            {props.selectedPatient?.blood || "-"}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            width: "100%",
            fontSize: props.language === "th-TH" ? "13px" : "",
          }}
        >
          <Icon name="child" style={{ color: "var(--text-mauve)" }} />
          <div
            style={{
              paddingLeft: "0px",
              paddingRight: "3px",
              color: "var(--text-dark-grey)",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="common.bmi" />
          </div>
          <div
            style={{
              color: "var(--text-light-blue)",
              paddingRight: "3px",
              paddingLeft: "0px",
            }}
          >
            {props.latestWeight?.data?.bmi || "-"}
          </div>
          <div
            style={{
              color: "var(--text-dark-grey)",
              padding: "0px 3px 0 8px",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="weight.weight" />
          </div>
          <div
            style={{
              color: "var(--text-light-blue)",
              padding: "0px 5px 0 2px",
            }}
          >
            {props.latestWeight?.data?.value || "-"}
          </div>
          <div style={{ color: "var(--text-dark-grey)", fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey279" />
          </div>
          <div
            style={{
              ...styles.noPadding,
              padding: "0px 0px 0px 4px",
              color: "var(--text-mauve)",
            }}
          >
            <Icon name="venus" />
          </div>
          <div style={{ ...styles.noPadding, fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey236" />
          </div>
          <div
            style={{
              color: "var(--text-light-blue)",
              padding: "0px 5px 0px 3px",
            }}
          >
            {!Number.isNaN(+props.latestWeight?.data?.height) &&
              props.latestWeight?.data?.height}
          </div>
          <div
            style={{
              color: "var(--text-dark-grey)",
              paddingRight: "1px",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="bplusClinicKey117" />
          </div>
        </div>
      </Grid>

      <DMPersonalScoreChart
        onEvent={props.onEvent}
        selectedPatient={props.selectedPatient}
        scoreSummary={props.scoreSummary}
        hideFilter={false}
      />

      <DMPersonalLabChart
        onEvent={props.onEvent}
        selectedPatient={props.selectedPatient}
        labSummary={props.labSummary}
        hideFilter={false}
      />
    </div>
  );
};

DMPersonalScore.defaultProps = DMPersonalScoreInitial;

export default React.memo(DMPersonalScore);
