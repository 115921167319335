import React, { CSSProperties, useCallback, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import { BACKEND_DATE_FORMAT, formatDateByLanguage, LANGUAGE } from "../MobClinicInterface";

type PatientPanelProps = {
  patientInfo?: any;
  haveBackground?: boolean;
  showDetail?: boolean;
  useBackgroundGradient?: boolean;
  titleFooter?: string;
  showFullAge?: boolean;
  style?: CSSProperties;
};

const patientPanelInitial: PatientPanelProps = {
  patientInfo: {},
  haveBackground: true,
  showDetail: true,
  useBackgroundGradient: false,
  titleFooter: "",
  showFullAge: false,
  style: {},
};

export const DATE_FORMAT = "DD/MM/YYYY";

const avatarStyle = {
  width: "60px",
  height: "60px",
  background: "white",
  color: "#c8cbd0",
};

const BG_GRADIENT =
  "linear-gradient(rgb(132 206 255) 0%, rgba(255, 255, 255, 0) 100%)";

export const ageFromDateOfBirth = (patientInfo: any) => {
  if (patientInfo?.age_dict) {
    const age = LANGUAGE === "th-TH" ? 
      `${patientInfo?.age_dict?.years} ปี ${patientInfo?.age_dict?.months} เดือน ${patientInfo?.age_dict?.days} วัน` : 
      `${patientInfo?.age_dict?.years} years ${patientInfo?.age_dict?.months} months ${patientInfo?.age_dict?.days} days`;
    return age;
  } else if (patientInfo?.birthdate) {
    let startDate: moment.Moment = moment(
      patientInfo?.birthdate,
      BACKEND_DATE_FORMAT
    );
    let today: moment.Moment = moment();

    const diffDate = today.diff(startDate);
    const duration = moment.duration(diffDate);

    // const age = `${duration.years()} ปี ${duration.months()} เดือน ${duration.days()} วัน`;
    const age = LANGUAGE === "th-TH" ? 
      `${duration?.years()} ปี ${duration?.months()} เดือน ${duration?.days()} วัน` : 
      `${duration?.years()} years ${duration?.months()} months ${duration?.days()} days`;
    return age;
  } else if (patientInfo?.age) {
    const age = LANGUAGE === "th-TH" ? 
      `${patientInfo?.age} ปี` : 
      `${patientInfo?.age} years`;
    return age;
  }
  return "";
};

const PatientPanel: React.FC<PatientPanelProps> = (props) => {
  const fullAge = useMemo(() => {
    return ageFromDateOfBirth(props.patientInfo);
  }, [props.patientInfo]);

  return (
    <>
      <div
        style={{
          padding: "15px 15px",
          color: "#4f4f4f",
          background: props.useBackgroundGradient
            ? BG_GRADIENT
            : props.haveBackground
            ? "#abd9fb"
            : "",
          ...props.style,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={
              props.patientInfo?.image_url ||
              props.patientInfo?.image ||
              "/images/Appointment/user-icon.png"
            }
            style={avatarStyle}
            alt="profile"
          />

          <div style={{ marginLeft: "15px" }}>
            <label
              style={{ fontWeight: "bold", fontSize: "16px", color: "#53525a" }}
            >
              {props.patientInfo?.first_name} {props.patientInfo?.last_name}
            </label>
            {props.showDetail && (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginTop: "5px",
                  color: "#7c7e9f",
                }}
              >
                <Icon name="calendar alternate outline" />
                <div
                  style={{
                    display: "grid",
                    alignItems: "center",
                    gridTemplateColumns: "repeat(2, minmax(110px, 1fr))",
                  }}
                >
                  {props.patientInfo?.birthdate && (
                    <label style={{ marginRight: "5px" }}>
                      {formatDateByLanguage(moment(
                        props.patientInfo?.birthdate,
                        BACKEND_DATE_FORMAT
                      ).format(DATE_FORMAT),"DD MMMM YYYY")}
                    </label>
                  )}
                  {fullAge && <label style={{}}>({fullAge})</label>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.titleFooter && (
        <div
          style={{
            color: "#0072BC",
            fontWeight: "bold",
            fontSize: "20px",
            width: "100%",
            textAlign: "center",
          }}
        >
          {props.titleFooter}
        </div>
      )}
    </> 
  );
};

PatientPanel.defaultProps = patientPanelInitial;

export default React.memo(PatientPanel);
