import React, { useState, useEffect } from "react";
import { Divider, Image, Modal, Icon } from "semantic-ui-react";
// Mui
import MuiRadio from "@mui/material/Radio";
import makeStyles from "@mui/styles/makeStyles";

import { FormattedMessage } from "react-intl";

// UX
import DialogReadMore from "./DialogReadMore";

// Types
import { devModeType, registerInfo } from "./TypeModal";

// Styles
import "./RegisterCSS.scss";
import { State } from "bplus-lib/MobSmartRegisterInterface";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

const IMAGES = {
  bg: "/images/register/lobby_background.png",
  bPlusLogo: "/images/expansion/logo_48.png",
  flagThai: "/images/register/flag_thai.png",
  flagUS: "/images/register/flag_english_us.png",
};

const Langue = [
  { flag: IMAGES.flagThai, langue: "ภาษาไทย", key: "th" },
  { flag: IMAGES.flagUS, langue: "English", key: "en" },
];

type MainLobbyProps = {
  onEvent: (e: any) => any;
  history: any;
  loading?: boolean;
  setProp?: any;
  devMode?: devModeType;
  registerInfo?: registerInfo;
} & Pick< State,"safeAreaTop">;

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogContent-root": {
      overflowY: "unset",
    },
  },
}));

export const POLICY = [
  {
    title: "ข้อกำหนดและเงื่อนไขการใช้งานแพลตฟอร์ม",
    header: "หมวดที่ 1 บททั่วไป",
    dtailList: `บริษัทกรุงเทพดุสิตเวชการ จำกัด (มหาชน) และบริษัทในเครือโรงพยาบาลกรุงเทพ ให้บริการแพลตฟอร์มแอปพลิเคชันรูปแบบโทรศัพท์เคลื่อนที่ ชื่อว่า มาย บี พลัส  ( “แพลตฟอร์ม” ) ซึ่งอยู่ภายใต้ข้อกำหนดและเงื่อนไข การเข้าใช้บริการแอพพลิเคชั่น มาย บี พลัส นี้ มีข้อกำหนดและเงื่อนไขฉบับนี้ เป็นเงื่อนไขการใช้งานแพลตฟอร์มสำหรับผู้ใช้บริการ ผู้ใช้บริการโปรดอ่านข้อกำหนดและเงื่อนไขฉบับนี้ อย่างละเอียดก่อนลงทะเบียนใช้งานแพลตฟอร์ม หากผู้ใช้บริการไม่เห็นด้วยหรือไม่ตกลงด้วยกับข้อกำหนดและเงื่อนไขฉบับนี้ โปรดระงับการลงทะเบียนการใช้งานแพลตฟอร์ม  หากผู้ใช้บริการ ลงทะเบียนใช้งานแพลตฟอร์มแล้ว จะถือว่าผู้ใช้บริการยอมรับและผูกพันตามข้อกำหนดและเงื่อนไขฉบับนี้ `,
  },
  {
    title: "",
    header: "1.คำจำกัดความ",
    dtailList: `1.1 \“บริการ\” หมายถึง บริการต่าง ๆ ที่ระบุไว้ในข้อ 2 ที่ผู้ใช้บริการสามารถเข้าถึงได้โดยผ่านทางแอพพลิเคชันมาย บี พลัส 
    1.2 \“บัญชีผู้รับบริการทางการแพทย์\” หมายถึง บัญชีที่เปิดขึ้นเพื่อประโยชน์ในการรับบริการทางการแพทย์หรือบริการอำนวยความสะดวกอื่น ๆ ซึ่งจะเชื่อมโยงเข้ากับระบบฐานข้อมูลของสถานพยาบาลต่าง ๆ ในกลุ่มโรงพยาบาลกรุงเทพ รวมถึงเวชระเบียนของผู้รับบริการที่มีอยู่กับสถาพยาบาลนั้น ๆ 1.3 \“ผู้ใช้บริการ\” หมายถึง บุคคลที่ลงทะเบียนเป็นผู้ใช้แอพพลิเคชั่น มาย บี พลัส ในนามของตนเอง ทั้งนี้ ภายใต้ข้อกำหนดและเงื่อนไขการใช้บริการนี้ 
    1.4 \“ผู้ให้บริการ\” หมายถึง บริษัท กรุงเทพดุสิตเวชการ จำกัด (มหาชน) หรือบริษัทในเครือกลุ่มโรงพยาบาลกรุงเทพ 
    1.5 \“ผู้รับบริการทางการแพทย์\” หมายถึง บุคคลที่ลงทะเบียนเป็นผู้รับบริการทางการแพทย์จากสถานพยาบาลต่าง ๆ ในกลุ่มโรงพยาบาลกรุงเทพ
    1.6 \“รหัสเข้าใช้บริการ\” หมายความถึง Login Name หรือ Username, รหัสผ่าน (Password), PIN –Person Identification Number, รหัส OTP – One Time Password รวมถึงรหัสอื่นใด หรือวิธีการยืนยันตัวบุคคลอื่นใดที่เกี่ยวข้องกับการเข้าใช้แอพพลิเคชันมาย บี พลัส ไม่ว่าจะเรียกด้วยชื่อใด ๆ และไม่ว่าจะเป็นรหัสที่ผู้ให้บริการเป็นผู้กำหนดให้หรือเป็นรหัสที่ผู้ใช้บริการเป็นผู้กำหนดเองก็ตาม 
    1.7 \“ ข้อมูลส่วนบุคคล\” หมายความว่า ข้อมูลเกี่ยวกับสิ่งเฉพาะตัวบุคคลของผู้ใช้บริการ เช่น ชื่อ นามสกุล เพศ หมายเลขประจำตัวประชาชน หมายเลขพาสปอร์ต เลขโรงพยาบาล (HN)  หมายเลขโทรศัพท์ จดหมายอิเลคโทรนิค (E-mail) ข้อมูลประวัติส่วนตัว และข้อมูลอื่น ๆ ที่มีชื่อของบุคคล เลขหมาย รหัส หรือสิ่งอื่นใดที่ทำให้รู้ตัวบุคคลนั้นได้ หรือรูปถ่ายที่มีอยู่ในระบบฐานข้อมูลของผู้ให้บริการ และ/หรือที่ผู้ให้บริการเก็บรวบรวมมาจากผู้ใช้บริการ บุคคลหรือหน่วยงานอื่นใดเพื่อประโยชน์ในการจัดการของผู้ให้บริการ ซึ่งได้รับความคุ้มครองภายใต้กฎหมายว่าด้วยการกระทำใด ๆบนเครือข่ายคอมพิวเตอร์ และกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลใด ๆ
    1.8 \“อุปกรณ์\” หมายความว่า อุปกรณ์ใด ๆ ที่นำมาใช้งานแอพพลิเคชัน มาย บี พลัสเช่น โทรศัพท์เคลื่อนที่   แท็บเล็ต คอมพิวเตอร์ หรืออุปกรณ์และเครื่องมือชนิดอื่นใดที่ผู้ให้บริการกำหนดสำหรับการใช้งานแอพพลิเคชัน มาย บี พลัส`,
    extra: {
      preLine: true,
    },
  },
  {
    title: "",
    header: "2. บริการแอพพลิเคชัน แอพพลิเคชัน มาย บี พลัส",
    dtailList: `2.1 บริการ แอพพลิเคชันแอพพลิเคชัน มาย บี พลัส ครอบคลุมการให้บริการต่าง ๆ ดังต่อไปนี้ 
    •	บริการค้นหารายชื่อแพทย์
    •	บริการนัดหมายแพทย์
    •	บริการนัดหมายตรวจสุขภาพ
    •	บริการนัดหมายฉีดวัคซีน
    •	บริการข้อมูลการรักษาผลแล็ปของผู้รับบริการทางการแพทย์
    •	บริการเอกสารใบรับรองแพทย์อิเลคโทรนิค
    •	บริการเอกสารรายงานการตรวจสุขภาพอิเลคโทรนิค
    •	บริการข้อมูลประวัติการรักษาของผู้รับบริการทางการแพทย์ย้อนหลัง 1 ปี
    •	บริการข้อมูลประวัติการรับยาผู้รับบริการทางการแพทย์
    •	บริการโทรเวชกรรม
    •	บริการชำระค่ารักษาพยาบาลและค่ายา
    •	บริการช่วยเหลือต่าง ๆ ผ่านทางแชทและการโทรศัพท์
    •	บริการบัญชีผู้รับบริการทางการแพทย์
    •	บริการบทความเชิงสุขภาพอันเกี่ยวข้องกับบริการของเครือโรงพยาบาลกรุงเทพ และสามารถส่งออกบทความดังกล่าวไปยังสื่อออนไลน์ที่ผู้ใช้บริการเลือก    
    •	บริการโทรศัพท์เรียกรถพยาบาลฉุกเฉิน หรือติดต่อกรณีฉุกเฉินอื่น ๆ
    •	บริการบันทึกการนัดหมาย
    •	บริการเชื่อมโยงผู้ใช้บริการไปยังร้านค้าหรือบริษัทที่เข้าร่วม ( Save Drug)
    •	บริการอื่น ๆ ที่อาจเพิ่มเติมในภายหลัง
    2.3 ในการให้บริการบางประเภทจำเป็นที่ผู้ใช้บริการจะต้องลงทะเบียนและล็อกอินก่อน และการใช้บริการบางประเภทอาจอยู่ภายใต้ข้อกำหนดและเงื่อนไขเพิ่มเติมเกี่ยวกับการใช้บริการนั้น ๆ ด้วย`,
  },
  {
    title: "",
    header: "3.การลงทะเบียนเป็นผู้ใช้บริการ",
    dtailList: `3.1 ท่านลงทะเบียนเป็นผู้ใช้แอพลิเคชัน มาย บี พลัส และผู้รับบริการทางการแพทย์ด้วย โดยท่านจะต้องกรอกข้อมูลส่วนตัวและตั้งรหัสประจำตัวเพื่อระบุยืนยันตัวตนของผู้ใช้ แอพลิเคชัน มาย บี พลัส
    ประเภทข้อมูลส่วนตัวประกอบด้วย
    · ข้อมูลการติดต่อ (เช่น หมายเลขประจำตัวประชาชน หมายเลขพาสปอร์ต ชื่อ-นามสกุล อีเมล ที่อยู่ปัจจุบัน และเบอร์ติดต่อ เป็นต้น)
    · ข้อมูลในการเข้าสู่ระบบเพื่อสร้างทะเบียนผู้ใช้งาน (เช่น เบอร์โทรศัพท์, อีเมล)
    3.2 ท่านจะเก็บรหัสประจำตัวไว้เป็นความลับและจะไม่เปิดเผยหรือกระทำการใด ๆ ที่อาจทำให้ผู้อื่นทราบรหัสประจำตัวดังกล่าว หากมีบุคคลอื่นล่วงรู้รหัสประจำตัวของผู้ใช้ แอพลิเคชัน มาย บี พลัส ท่านจะแจ้งให้กลุ่ม บริษัทกรุงเทพดุสิตเวชการ จำกัด (มหาชน) และบริษัทในเครือโรงพยาบาลกรุงเทพ ทราบโดยเร็ว
    3.3 ท่านสามารถเปลี่ยนแปลง Login Name หรือ Usernameb และ/หรือ รหัสผ่าน (Password) และ/หรือ PIN-Person Identification Number และ/หรือรหัสที่เกี่ยวข้องกับการเข้าใช้งาน แอพลิเคชัน มาย บี พลัส เมื่อใดก็ได้ โดยดำเนินการตามวิธีการและเงื่อนไขที่บริษัทกรุงเทพดุสิตเวชการ จำกัด (มหาชน) และบริษัทในเครือโรงพยาบาลกรุงเทพ กำหนด
    3.4 ท่านตกลงรับผิดชอบการใด ๆ ในการใช้งานแอพลิเคชัน มาย บี พลัส ที่ได้ทำผ่านรหัสประจำตัวของท่านทุกประการ`,
  },
  {
    title: "หมวดที่ 4 เงื่อนไขการใช้บริการแพลตฟอร์ม",
    header: "4.การลงทะเบียนขอเปิดบัญชีแทนบุคคลอื่นเป็นผู้รับบริการทางการแพทย์",
    dtailList: `ไม่มีการการลงทะเบียนขอเปิดบัญชีแทนบุคคลอื่น`,
  },
  {
    title: "",
    header: "5.การใช้บริการ",
    dtailList: `ผู้ใช้บริการรับทราบ ยอมรับและตกลงกับผู้ให้บริการ ดังนี้
    5.1 การใช้บริการครั้งใด ๆ ด้วยวิธีที่ใช้รหัสเข้าใช้บริการของผู้ใช้บริการผ่านแอปพลิเคชัน มาย บี พลัสให้ถือว่าเป็นการกระทำของผู้ใช้บริการเอง และมีผลผูกพันต่อผู้ใช้บริการ 
    5.2 ผู้ใช้บริการรับทราบว่าคำศัพท์เทคนิคทางการแพทย์ที่เกี่ยวกับการวินิจฉัยโรคและหัตถการนั้น เป็นคำศัพท์ภาษาอังกฤษและอาจยากต่อการทำความเข้าใจของผู้ใช้บริการ ผู้ให้บริการจะมีคำแปลคำศัพท์เทคนิคทางการแพทย์ดังกล่าว เป็นภาษาไทยและเป็นคำที่ผู้ใช้บริการสามารถเข้าใจการวินิจฉัยโรคของแพทย์และหัตถการได้ง่ายขึ้นเท่านั้น ซึ่งความหมายอาจไม่ตรงตามตัวอักษรของคำศัพท์ทางเทคนิคทางการแพทย์ หากผู้ใช้บริการมีข้อสงสัยเกี่ยวกับข้อมูลการวินิจฉัยโรค การรักษา หรือหัตถการ โปรดติดต่อสอบถามผู้ให้บริการ 
    5.3 ผู้ใช้บริการรับทราบว่าบทความเชิงสุขภาพจัดทำขึ้นเพื่อวัตถุประสงค์ในการให้ข้อมูลความรู้เป็นการทั่วไปเท่านั้น ไม่ถือเป็นคำแนะนำทางการแพทย์หรือทดแทนคำแนะนำทางการแพทย์แต่ประการใด ผู้ใช้บริการจะไม่ยึดถือข้อมูลดังกล่าว หรือเปลี่ยนแปลงหรือหยุดการรักษาพยาบาลโดยปราศจากการหารือกับแพทย์ของผู้ใช้บริการก่อน ผู้ให้บริการจะไม่รับผิดชอบในการที่ผู้ใช้บริการนำข้อมูลดังกล่าวไปใช้ในประการใด ๆ 
    5.4 ผู้ใช้บริการสามารถชำระค่ารักษาพยาบาลและค่ายาผ่าน แอปพลิเคชัน มาย บี พลัสนี้โดยผู้ใช้บริการจะต้องกรอกข้อมูลทางการเงินที่ถูกต้องครบถ้วนตามที่กำหนด ทั้งนี้ ผู้ใช้บริการในฐานะผู้ทำรายการจะต้องตรวจสอบความถูกต้องครบถ้วนของเลขที่บัญชี จำนวนเงิน และข้อมูลอื่น ๆ ด้วยตนเองก่อนเข้าทำรายการ โดยผู้ให้บริการจะไม่รับผิดชอบใด ๆ หากผู้ให้บริการได้ดำเนินการถูกต้องตามคำสั่งทำรายการของผู้ใช้บริการ 
    5.5 หากมีความผิดพลาดเกิดขึ้นเกี่ยวกับการใช้บริการ หรือมีข้อสงสัยเกี่ยวกับข้อมูลสุขภาพและอื่น ๆ ผู้ใช้บริการจะต้องแจ้งรายละเอียดต่าง ๆ ที่เกี่ยวข้องให้แก่ผู้ให้บริการ เพื่อให้ผู้ให้บริการใช้เป็นข้อมูลในการตรวจสอบข้อผิดพลาด `,
  },
  {
    title: "",
    header: "6.การจัดการและปกป้องคุ้มครองข้อมูลส่วนบุคคล",
    dtailList: `6.1 ก่อนตัดสินใจเข้ารับบริการนี้ โปรดศึกษานโยบายความเป็นส่วนตัว  (Privacy Notice) https://www.bangkokhospital.com/page/privacy-policy  อย่างไรก็ตาม ผู้ใช้บริการรับทราบว่า การส่งข้อมูลทางอินเทอร์เน็ตนั้นอาจไม่ปลอดภัย แม้ว่าผู้ให้บริการจะใช้ระบบสารสนเทศที่มีมาตรฐานและความปลอดภัยด้านสารสนเทศที่เป็นสากลก็ตาม แต่ผู้ให้บริการไม่สามารถรับรองในความปลอดภัยในข้อมูลส่วนบุคคลซึ่งผู้ใช้บริการส่งมาให้ผู้ให้บริการผ่านการใช้งานอินเทอร์เน็ต และผู้ใช้บริการจะต้องรับความเสี่ยงในการส่งข้อมูลดังกล่าวเอง 
    6.3 ถ้าผู้ใช้บริการยินยอมให้ผู้ให้บริการเปิดเผยข้อมูลของผู้ใช้บริการภายใต้เงื่อนไขและข้อกำหนดนี้ ผู้ให้บริการจะเปิดเผยข้อมูลเพียงเท่าที่จำเป็นเท่านั้น 
    6.4 โดยการใช้แอพลิเคชัน มาย บี พลัส ผู้ใช้บริการได้ยอมรับและตกลงตามนโยบายความเป็นส่วนตัว (Privacy Notice) ทุกประการ `,
  },
  {
    title: "",
    header: "7. ความรับผิดชอบของผู้ใช้บริการ",
    dtailList: `7.1 ผู้ใช้บริการจะต้องไม่อัพโหลดหรือส่งข้อมูลที่มีไวรัส โทรจัน หรือชุดคำสั่งที่มุ่งประสงค์จะรบกวน แทรกแซง ขัดขวาง หรือทำลายระบบคอมพิวเตอร์ เข้าสู่ระบบเครือข่ายคอมพิวเตอร์ของผู้ให้บริการ หรือของสถานพยาบาลต่าง ๆ ในกลุ่มโรงพยาบาลกรุงเทพ
    7.2 ผู้ใช้บริการจะไม่ทำการดังนี้ 
    (ก) คัดลอก จำแนกส่วนประกอบ ทำวิศวกรรมย้อนกลับ หรือกระทำการอื่นใดอันเป็นการเปลี่ยนแปลงทั้งหมดหรือแต่บางส่วนของแอพลิเคชัน มาย บี พลัส 
    (ข) แทรกแซงหรือขัดขวางการทำงานของซอฟต์แวร์ ระบบ อุปกรณ์ เครื่องมือ หรือเครือข่ายใด ๆ ซึ่งใช้ในบริการ หรือฝ่าฝืนกฎ ระเบียบ ข้อบังคับ นโยบายของการบริการ 
    (ค) หลีกเลี่ยงกระบวนการตรวจสอบความน่าเชื่อถือและความปลอดภัยของบริการ เครือข่าย หรือระบบอื่นใดที่เกี่ยวข้อง
     (ง) เข้าถึงและ/หรือใช้บริการโดยวิธีการอื่นใดนอกเหนือจากที่ระบุไว้ในข้อกำหนดและเงื่อนไขนี้
     (จ) ใช้บริการแอพลิเคชัน มาย บี พลัส ในลักษณะที่ขัดต่อกฎหมาย 
    (ฉ) ยินยอมให้บุคคลอื่นที่ไม่ได้รับอนุญาตจากผู้ใช้บริการใช้ ชื่อ รหัสผ่าน และกระบวนการใด ๆ เพื่อให้บุคคลดังกล่าวสามารถเข้าถึงและใช้บริการ `,
  },
  {
    title: "",
    header: "8. ข้อจำกัดความรับผิดของผู้ให้บริการ",
    dtailList: `8.1 ผู้ให้บริการจะรับผิดชอบสำหรับความเสียหายที่เกิดขึ้นในกรณีที่ผู้ให้บริการกระทำโดยจงใจ หรือประมาทเลินเล่ออย่างร้ายแรง ซึ่งเป็นความเสียหายที่ตามปกติย่อมเกิดขึ้นเท่านั้น แต่ผู้ให้บริการไม่ต้องรับผิดชอบในความเสียหายที่เกิดขึ้นจากพฤติการณ์พิเศษ ทั้งนี้ ผู้ใช้บริการตกลงว่า ไม่ว่าในกรณีใด ๆ ผู้ให้บริการไม่ต้องรับผิดชอบในความเสียหายที่เกิดขึ้นจากสาเหตุต่าง ๆ ดังต่อไปนี้ 
    (1) มีการใช้รหัสเข้าใช้บริการควบคู่กับการยืนยันการเปิดการใช้บริการของผู้ใช้บริการ หรือวิธีการอื่นใด ตามที่ผู้ให้บริการกำหนด ไม่ว่าจะเป็นการกระทำของผู้ใดก็ตาม 
    (2) เหตุสุดวิสัยต่าง ๆ หรือเหตุการณ์ที่ผู้ให้บริการไม่สามารถควบคุมได้ ซึ่งรวมถึงการที่อุปกรณ์ ระบบสื่อสาร หรือการส่งสัญญาณเชื่อมต่อไม่ทำงาน หรือถูกรบกวนจากไวรัสคอมพิวเตอร์ หรือจากการกระทำที่ไม่ชอบด้วยกฎหมาย หรือในกรณีที่ผู้ให้บริการปิดการให้บริการ เพื่อปรับปรุงหรือบำรุงรักษาระบบ อันเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการได้ 
    (3) ความล่าช้า ความผิดพลาด หรือความบกพร่องของระบบคอมพิวเตอร์ หรืออุปกรณ์ต่าง ๆ ของผู้ใช้บริการ ผู้ให้บริการ หรือผู้ให้บริการระบบเครือข่ายโทรคมาคมหรือเครือข่ายการสื่อสารอื่น เว้นแต่ในกรณีที่ความล่าช้า ความผิดพลาด หรือความบกพร่องดังกล่าว เกิดจากความจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของผู้ให้บริการ 
    (4) ความล่าช้าในขั้นตอนการดำเนินการของบริการ แอพลิเคชัน มาย บี พลัส
    (5) การที่ผู้ใช้บริการไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขของบริการ   `,
  },
  {
    title: "",
    header: "9. การชดใช้ค่าเสียหาย",
    dtailList: `ผู้ใช้บริการตกลงว่าจะปกป้องและชดใช้ค่าเสียหายให้แก่ผู้ให้บริการ และบริษัทในเครือ พนักงาน คู่สัญญาทางธุรกิจ และลูกจ้าง จากการกล่าวอ้าง เรียกร้อง การกระทำ กระบวนการทางกฎหมาย ความรับผิด บทลงโทษ และค่าใช้จ่ายต่าง ๆ (รวมถึงค่าใช้จ่ายทางด้านกฎหมาย)อันเนื่องจาก 
    (ก) การที่ผู้ใช้บริการเข้าถึงบริการนี้
    (ข) การที่ผู้ใช้บริการละเมิดสิทธิของบุคคลหรือนิติบุคคลอื่น ไม่ว่าจะเกิดขึ้นด้วยความจงใจหรือประมาทเลินเล่อของผู้ใช้บริการหรือ 
    (ค) จากการทำผิดข้อกำหนดและเงื่อนไขนี้ หรือเหตุอื่นใด  `,
  },
  {
    title: "",
    header: "10. ข้อมูลการติดต่อ และการส่งคำบอกกล่าว",
    dtailList: `10.1 ผู้ใช้บริการตกลงให้ผู้ให้บริการติดต่อสอบถาม เก็บ รวบรวม หรือ ใช้ข้อมูลบางประการหรือทั้งหมดเกี่ยวกับผู้ใช้บริการ และที่เกี่ยวกับการให้บริการได้ ตามความจำเป็นและเหมาะสม  
    10.2 หนังสือ จดหมาย คำบอกกล่าวใด ๆ ของผู้ให้บริการที่จัดส่งให้แก่ผู้ใช้บริการทางไปรษณีย์ หรือไปรษณีย์อิเล็กทรอนิกส์ (E-mail) ตามที่อยู่ซึ่งผู้ใช้บริการได้แจ้งให้ผู้ให้บริการทราบ ให้ถือว่า เป็นการจัดส่งให้แก่ผู้ขอใช้บริการโดยชอบแล้ว และในกรณีที่ผู้ใช้บริการเปลี่ยนแปลงที่อยู่ดังกล่าว ผู้ใช้บริการจะแจ้งให้ผู้ให้บริการทราบเป็นลายลักษณ์อักษร  
    10.3 ผู้ใช้บริการสามารถติดต่อสอบถาม หรือแจ้งปัญหาเกี่ยวกับการใช้บริการนี้ โดยติดต่อได้ที่ 
    บริการ แอปพลิเคชัน มาย บี พลัส
    บริษัท กรุงเทพดุสิตเวชการ
    เลขที่ 2 ซอยศูนย์วิจัย 7 ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร10310 
    เบอร์โทรศัพท์ 02-310-3000  เบอร์โทรสาร 02-318-1546 
    อีเมล  info@bangkokhospital.co.th `,
  },
  {
    title: "",
    header: "12. ทรัพย์สินทางปัญญา",
    dtailList: `12.1 การใช้บริการของผู้ใช้บริการ ไม่เป็นการให้ความเป็นเจ้าของในทรัพย์สินทางปัญญาใด ที่เกี่ยวกับบริการหรือเนื้อหาที่ผู้ใช้บริการเข้าถึง 
    12.2 ผู้ให้บริการและ/หรือคู่สัญญาทางธุรกิจ เป็นเจ้าของในสิทธิ และประโยชน์ใด ๆ ที่เกี่ยวข้องกับลิขสิทธิ์ เครื่องหมายการค้า สิทธิบัตร และทรัพย์สินทางปัญญาอื่น ๆ หรือ สิทธิใด ๆ ของบริการนี้ รวมถึงงานผลิตภัณฑ์ หรือการปรับปรุงแก้ไข การออกแบบ หรืองานอื่นใดที่เกิดขึ้น หรือสร้างขึ้นโดยผู้ให้บริการ `,
  },
  {
    title: "",
    header: "13. ความสมบูรณ์ของข้อกำหนดและเงื่อนไข",
    dtailList: `ในกรณีที่ปรากฏว่ามีข้อกำหนดหรือเงื่อนไขใดในเอกสารนี้ไม่สมบูรณ์ ตกเป็นโมฆะ หรือใช้บังคับไม่ได้ ไม่ว่าด้วยประการใด ๆ ให้ถือว่าข้อกำหนดหรือเงื่อนไขที่ไม่สมบูรณ์ ตกเป็นโมฆะ หรือใช้บังคับไม่ได้ดังกล่าวนั้น ไม่มีผลกระทบต่อความสมบูรณ์หรือการบังคับใช้ข้อกำหนดและเงื่อนไขอื่น ๆ ในเอกสารนี้ `,
  },
  {
    title: "",
    header: "14. กฎหมายที่ใช้บังคับ",
    dtailList: `หากมีข้อพิพาทใดเกิดขึ้นจากการให้บริการของผู้ให้บริการ อันเนื่องมาจากบริการนี้ ผู้ใช้บริการและผู้ให้บริการตกลงใช้กฎหมายไทยและให้เฉพาะศาลไทยเป็นศาลที่มีเขตอำนาจในการพิจารณาข้อพิพาทที่เกิดขึ้นเกี่ยวกับการใช้บริการตามข้อกำหนดและเงื่อนไขนี้`,
  },
];

const MainLobby: React.FunctionComponent<MainLobbyProps> = (props) => {
  const classes = useStyles();

  // open
  const [openSelectLangue, setOpenSelectLangue] = useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = useState<boolean>(false);

  const [selectLangue, setSelectLangue] = useState<string>("th");
  const [pattern, setPattern] = useState<string>("");

  useEffect(() => {
    if (window.mobile?.getDevMode) {
      window.mobile?.getDevMode();
    }

    if (window.mobile?.getLocaleLanguage) {
      if (typeof window.mobile.getLocaleLanguage === "object") {
        // iOS
        window.mobile?.getLocaleLanguage.then((lang: any) => {
          setSelectLangue(lang);
        });
      } else {
        // android
        const localeLanguage = window.mobile?.getLocaleLanguage();
        setSelectLangue(localeLanguage);
      }
    }
    props.setProp("loading", false);
    props.setProp("registerInfo", {});
  }, []);

  useEffect(() => {
    if (pattern.includes("121212121212")) {
      console.log("devMode 1:");
      setDevModeNative(true, 1);
    } else if (pattern.includes("122222222222")) {
      console.log("devMode 2:");
      setDevModeNative(true, 2);
    } else if (pattern.includes("111111111111")) {
      console.log("devMode 3:");
      setDevModeNative(true, 3);
    }
  }, [pattern]);

  const setDevModeNative = (active: boolean, version: number) => {
    if (window.mobile?.saveDevMode) {
      window.mobile?.saveDevMode(`${active}`, `${version}`);
      setPattern("");
    }
  };

  const handleHistoryGoTo = (action: string) => {
    switch (action) {
      case "login":
        props.history.push("/login/?app=MobRegister");
        break;
      case "register":
        props.history.push("/register/?app=MobRegister");
        break;
      case "skip":
        if (window.mobile?.skipApp) {
          window.mobile?.skipApp();
        }
        break;
      case "policy":
        break;
      case "selectLangue":
        {
          if (typeof window.mobile.getLocaleLanguage === "object") {
            // iOS
            window.mobile?.getLocaleLanguage.then((lang: any) => {
              setSelectLangue(lang);
            });
          } else {
            // android
            const localeLanguage = window.mobile?.getLocaleLanguage();
            setSelectLangue(localeLanguage);
          }
          setOpenSelectLangue(true);
        }

        break;
      case "conditions":
        break;
    }
  };

  const checkDevMode = (patternInput: string) => {
    if (!props.devMode?.devMode) {
      let patternInputFull = pattern + patternInput;
      patternInputFull.substring(patternInputFull.length - 12);
      setPattern(patternInputFull);
    }
  };

  const handleChangeLangue = (langue: any) => {
    console.log("saika ~ langue:", langue);
    if (window.mobile?.setNativeLanguage) {
      // setSelectLangue(langue);
      window.mobile?.setNativeLanguage(langue);
    }
    setOpenSelectLangue(false);
  };

  console.log("saika props", props);

  return (
    <div
      className="main-login"
      style={{ backgroundImage: `url(${IMAGES.bg})`, backgroundSize: "cover" }}
    >
      <div
        className="devMode"
        style={{ ...(!props.devMode?.devMode && { display: "none" }), ...(props.safeAreaTop && {marginTop: "30px"}) }}
        onClick={() => {
          setDevModeNative(false, 3);
        }}
      >
        {`devMode ${props.devMode?.devVersion}`}
      </div>
      <div className="login-head" style={{...(props.safeAreaTop && {paddingTop: `${props.safeAreaTop}px`})}}>
        <div className="logo">
          <Image
            src={IMAGES.bPlusLogo}
            style={{ width: "37px" }}
            onClick={() => {
              checkDevMode("1");
            }}
          />
          <div
            onClick={() => {
              checkDevMode("2");
            }}
          >
            {"My B+"}
          </div>
        </div>
        <div className="skip" onClick={() => handleHistoryGoTo("skip")}>
          {<FormattedMessage id="bplusClinicKey970" />}
          <Icon name="arrow right" />
        </div>
      </div>
      <div className="login-image"></div>
      <div className="login-selected">
        <div className="login-selected-head">
          <div>{<FormattedMessage id="bplusClinicKey971" />}</div>
          <div
            className="select-language"
            onClick={() => handleHistoryGoTo("selectLangue")}
          >
            <Image
              src={selectLangue === "en" ? IMAGES.flagUS : IMAGES.flagThai}
              style={{ width: "27px" }}
            />
            <div>{selectLangue === "en" ? "English" : "ภาษาไทย"}</div>
            <div>
              <Icon name="chevron down" />
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="bplus-buttom">
            <div
              className="backward"
              style={{
                background: "transparent",
                color: "var(--primary-theme-color)",
                border: `1px solid  var(--primary-theme-color)`,
              }}
              onClick={() => handleHistoryGoTo("login")}
            >
              {<FormattedMessage id="bplusClinicKey958" />}
            </div>
          </div>
          <Divider
            horizontal
            inverted
            style={{ color: "#A2BFC9", fontSize: "14px" }}
          >
            <FormattedMessage id="common.or" />
          </Divider>
          <div className="bplus-buttom">
            <div onClick={() => handleHistoryGoTo("register")}>
              {<FormattedMessage id="bplusClinicKey957" />}
            </div>
          </div>
        </div>

        <div style={{ margin: "10px 0px" }} onClick={() => setOpenPolicy(true)}>
          <div
            style={{
              display: "flex",
              color: "#797878",
              whiteSpace: "nowrap",
              justifyContent: "center",
            }}
          >
            {<FormattedMessage id="bplusClinicKey972" />}
            {
              <span
                className="highlight"
                onClick={() => handleHistoryGoTo("policy")}
              >
                {<FormattedMessage id="bplusClinicKey973" />}
              </span>
            }
          </div>
          <div
            style={{
              display: "flex",
              color: "#797878",
              whiteSpace: "nowrap",
              justifyContent: "center",
            }}
          >
            {<FormattedMessage id="common.and" />}
            {
              <span
                className="highlight"
                onClick={() => handleHistoryGoTo("policy")}
              >
                {<FormattedMessage id="bplusClinicKey974" />}
              </span>
            }
            {<FormattedMessage id="bplusClinicKey975" />}
          </div>
        </div>
      </div>

      <Modal
        open={openSelectLangue}
        dimmer={"blurring"}
        onClose={() => setOpenSelectLangue(false)}
      >
        <div className="modal-langue">
          <div className="modal-title">
            {<FormattedMessage id="settings.change_language" />}
            <div className="icon">
              <Icon
                name="close"
                onClick={() => {
                  setOpenSelectLangue(false);
                }}
              />
            </div>
          </div>
          {Langue.map((item: any, index: any) => (
            <div
              key={item.key}
              className="modal-content"
              onClick={() => {
                handleChangeLangue(item.key);
              }}
            >
              <div className="list-item-langue">
                <div>
                  <Image src={item.flag} />
                </div>
                <div>{item.langue}</div>
              </div>
              <div>
                {/* <Radio value={item.key} checked={selectLangue === item.key} /> */}
                <MuiRadio
                  className="smart-radio-button"
                  name="radio-buttons"
                  checked={selectLangue === item.key}
                />
              </div>
              {index !== Langue.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </Modal>

      <DialogReadMore
        open={openPolicy}
        className={classes.dialog}
        onChange={(state) => setOpenPolicy(state)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {POLICY.map((item) => (
            <>
              {item.title && (
                <div
                  className="text-black-bold"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "15px 0 0 0",
                  }}
                >
                  {item.title}
                </div>
              )}
              <div
                className="text-black-bold"
                style={{ fontSize: "16px", margin: "10px 0 0 0" }}
              >
                {item.header}
              </div>
              <div
                className="text-gray"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {item.dtailList}
              </div>
            </>
          ))}
        </div>
      </DialogReadMore>
    </div>
  );
};

export default React.memo(MainLobby);
