import React, {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FormattedMessage } from "react-intl";
// import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { Dimmer, Header, Image, Input, Loader, Modal } from "semantic-ui-react";
import {
  Button as MuiButton,
  Chip,
  Grid,
  IconButton,
  OutlinedInput,
  InputAdornment,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";

// UI
import PureReactTable from "../../react-lib/component-js/common/PureReactTable";

import "../../css/WebClinic.scss";

// ------------------------------ Tab Dashboard Patient
type TabDashboardPatientProps = {
  selectedPatient?: any;
};

const TabDashboardPatient: React.FC<TabDashboardPatientProps> = (
  props: any
) => {
  return (
    <div className="SegmentPreview">
      <Header as="h4" className="centerText">
        {props.selectedPatient?.fullname || ""}
      </Header>
      {props.selectedPatient?.id && (
        <Image
          centered
          size="small"
          src={
            props.selectedPatient?.image_url ||
            props.selectedPatient?.image ||
            "/images/Appointment/user-icon.png"
          }
        />
      )}
      <Header as="h4">HN : {props.selectedPatient?.hn || "-"}</Header>
      <Header as="h4">อายุ : {props.selectedPatient?.full_age || "-"}</Header>
      <Header as="h4">ที่อยู่ : {props.selectedPatient?.address || "-"}</Header>
      <Header as="h4">
        วันเกิด : {props.selectedPatient?.birthdate || "-"}
      </Header>
      <Header as="h4">โทร : {props.selectedPatient?.phone_no || "-"}</Header>
    </div>
  );
};

// ------------------------------ Tab Dashboard
type MarketingSegmentDashboardProps = {
  // function
  onEvent: any;
  setProp: any;
  onSetModeAddSegment: any;
  // data
  loadingStatus?: boolean;
  loadingMarketingPatient?: boolean;
  filterMarketingPatient?: any;
  marketingPatientList?: any;
  marketingCreatePatient?: any;
  modeAddSegment?: boolean;
  isMyTab?: boolean;
};

const MarketingSegmentDashboardInitial: MarketingSegmentDashboardProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  onSetModeAddSegment: () => null,
  // data
  loadingStatus: false,
  loadingMarketingPatient: false,
  filterMarketingPatient: {},
  marketingPatientList: {},
  marketingCreatePatient: null,
  modeAddSegment: false,
  isMyTab: false,
};

const MarketingSegmentDashboard: React.FC<MarketingSegmentDashboardProps> = (
  props: any
) => {
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [createPatient, setCreatePatient] = useState<any>({ hn: "" });
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const [segmentName, setSegmentName] = useState<string>("");
  const [patientFile, setPatientFile] = useState<any>(null);
  const [modConfirmDetail, setModConfirmDetail] = useState<any>({
    open: false,
    data: {},
    type: "",
    title: "",
    content: "",
  });

  const EDIT_SEGMENT = "EDIT_SEGMENT";
  const DELETE_PATIENT = "DELETE_PATIENT";
  const DELETE_SEGMENT = "DELETE_SEGMENT";

  useEffect(() => {
    fileRef.current.value = null;
    setPatientFile(null);
  }, [props.filterMarketingPatient]);

  useEffect(() => {
    setSelectedPatient({});
  }, [props.marketingPatientList]);

  useEffect(() => {
    setSegmentName(props.filterMarketingPatient?.result || "");
  }, [props.filterMarketingPatient?.result]);

  useEffect(() => {
    if (props.modeAddSegment) {
      setSegmentName("");
    } else {
      setSegmentName(props.filterMarketingPatient?.result || "");
    }
  }, [props.modeAddSegment]);

  useEffect(() => {
    if (props.marketingCreatePatient) {
      setCreatePatient(props.marketingCreatePatient);
    } else {
      setCreatePatient({ patient_name: "" });
    }
  }, [props.marketingCreatePatient]);

  const patientColumn = useMemo(
    () => [
      { Header: "ชื่อ", accessor: "fullname", minWidth: 250 },
      { Header: "อายุ", accessor: "full_age", minWidth: 130 },
      { Header: "base location", accessor: "base_location", minWidth: 220 },
      {
        Header: "Delete",
        accessor: "_remove",
        minWidth: 60,
        Cell: (row: any) => {
          return (
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                setModConfirmDetail({
                  open: true,
                  data: row.original,
                  type: DELETE_PATIENT,
                  title: `ลบผู้ป่วย [${row.original?.hn}] ${row.original?.fullname}`,
                  content: `ต้องการลบผู้ป่วย [${row.original?.hn}] ${row.original?.fullname} ออกจาก Segment ${row.original?.result} หรือไม่`,
                });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            // <MuiButton
            //   variant="contained"
            //   color="error"
            //   size="small"
            //   style={{ width: "70%", minWidth: "70%", height: "30px" }}
            //   onClick={() => {
            //     setModConfirmDetail({
            //       open: true,
            //       data: row.original,
            //       type: DELETE_PATIENT,
            //       title: `ลบผู้ป่วย [${row.original?.hn}] ${row.original?.fullname}`,
            //       content: `ต้องการลบผู้ป่วย [${row.original?.hn}] ${row.original?.fullname} ออกจาก Segment ${row.original?.result} หรือไม่`,
            //     });
            //   }}
            // >
            //   <IconButton style={{ color: "white" }} size="small">
            //     <RemoveIcon fontSize="small" />
            //   </IconButton>
            // </MuiButton>
          );
        },
      },
    ],
    []
  );

  // Patient
  const handleSearchMarketingPatient = (event: any) => {
    if (event.key === "Enter") {
      props.onEvent({
        message: "handleSearchMarketingPatient",
        params: { hn: createPatient?.hn },
      });
    }
  };

  const handleSelectedPatient = (row: any) => {
    setSelectedPatient(row);
  };

  // Import File
  const handleClickBrowse = () => {
    fileRef.current?.click?.();
  };

  const handleChangeFile = (event: any) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setPatientFile(file);
    } else {
      fileRef.current.value = null;
      setPatientFile(null);
    }
  };

  const handleImportFile = () => {
    if (segmentName.trim() === "") {
      props.setProp("errorMessage", "Segment name not found.");
      return;
    }
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(patientFile);
    fileReader.onload = (event) => {
      import("xlsx").then((XLSX) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[workbook.SheetNames[0]]
        );

        let hn_list: any[] = rowObject.map((item: any) => {
          return item.HN;
        });
        if (createPatient?.hn) {
          hn_list.push(createPatient?.hn);
        }
        if (hn_list.length === 0) {
          props.setProp("errorMessage", "Patient not found.");
          return;
        }
        props.onEvent({
          message: "handleCreateMarketingSegmentPatient",
          params: {
            result: segmentName,
            hn_list: hn_list,
          },
        });
      })
    };
    fileRef.current.value = null;
    setPatientFile(null);
    setCreatePatient({ hn: "", patient_name: "" });
    props.onSetModeAddSegment(false);
  };

  // Download File
  const s2ab = (s: any) => {
    let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  const handleDownloadFile = () => {

    import("xlsx").then((XLSX) => {
      let workbook = XLSX.utils.book_new();
      workbook.SheetNames.push("Segment");
      const sheet_data = [["HN"]];
      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);
      workbook.Sheets["Segment"] = sheet;
  
      const output = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([s2ab(output)], { type: "application/octet-stream" }),
        "segment.xlsx"
      );
    })
  };

  // Save
  const handleEditMarketingSegmentName = () => {
    props.onEvent({
      message: "handleEditMarketingSegmentName",
      params: modConfirmDetail.data, // result, old_result
    });
  };

  const handleConfirmDeleteMarketingSegment = () => {
    props.onEvent({
      message: "handleDeleteMarketingSegment",
      params: modConfirmDetail?.data, // result
    });
  };

  const handleConfirmDeleteMarketingPatient = () => {
    props.onEvent({
      message: "handleDeleteMarketingPatient",
      params: modConfirmDetail?.data,
    });
  };

  // Modal
  const handleApprove = () => {
    if (modConfirmDetail?.type === EDIT_SEGMENT) {
      handleEditMarketingSegmentName();
    } else if (modConfirmDetail?.type === DELETE_SEGMENT) {
      handleConfirmDeleteMarketingSegment();
    } else if (modConfirmDetail?.type === DELETE_PATIENT) {
      handleConfirmDeleteMarketingPatient();
    } else {
      return;
    }
    handleCloseModConfirmDetail();
  };

  const handleCloseModConfirmDetail = () => {
    setModConfirmDetail({
      open: false,
      data: {},
      type: "",
      title: "",
      content: "",
    });
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    props.onEvent({
      message: "handleSetMarketingPatientParams",
      params: { offset: pageNo * props.filterMarketingPatient.limit },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.onEvent({
      message: "handleSetMarketingPatientParams",
      params: { limit: pageSize },
    });
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: selectedPatient?.id ? "1fr 350px" : "1fr",
        margin: "-20px -10px",
      }}
    >
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <div>
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ paddingBottom: "10px" }}
        >
          <Grid item lg={2} md={4} sm={5}>
            <Header
              as="h4"
              style={{ color: "var(--text-grey)", textAlign: "right" }}
            >
              Segment Name
            </Header>
          </Grid>
          <Grid item lg={10} md={8} sm={7}>
            <OutlinedInput
              required
              fullWidth
              disabled={!props.modeAddSegment}
              value={segmentName || ""}
              onChange={(event: any) => {
                setSegmentName(event.target.value);
              }}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       color="success"
              //       edge="end"
              //       disabled={
              //         props.modeAddSegment
              //           ? true
              //           : props.filterMarketingPatient?.result === segmentName
              //           ? true
              //           : false
              //       }
              //       onClick={() => {
              //         setModConfirmDetail({
              //           open: true,
              //           data: {
              //             result: segmentName,
              //             old_result: props.filterMarketingPatient?.result,
              //           },
              //           type: EDIT_SEGMENT,
              //           title: "แก้ไข Segment",
              //           content: `ต้องการแก้ไข Segment ${props.filterMarketingPatient?.result} เป็น ${segmentName} หรือไม่`,
              //         });
              //       }}
              //       onMouseDown={(event: any) => event.preventDefault()}
              //     >
              //       <SaveIcon />
              //     </IconButton>
              //   </InputAdornment>
              // }
            />
          </Grid>

          <Grid item lg={2} md={2} sm={5}>
            <Header
              as="h4"
              style={{ color: "var(--text-grey)", textAlign: "right" }}
            >
              HN
            </Header>
          </Grid>
          <Grid item lg={3} md={3} sm={7}>
            <Input
              fluid
              icon="search"
              placeholder="HN"
              value={createPatient?.patient_name || createPatient?.hn || ""}
              onChange={(event: any) => {
                setCreatePatient({ hn: event.target.value, patient_name: "" });
              }}
              onKeyPress={handleSearchMarketingPatient}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5}>
            <Header
              as="h4"
              style={{ color: "var(--text-grey)", textAlign: "center" }}
            >
              or Import file
            </Header>
          </Grid>
          <Grid item lg={5} md={5} sm={7}>
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClickBrowse}
            >
              Browse
            </MuiButton>
            <input
              ref={fileRef}
              type="file"
              accept=".xlsx"
              onChange={handleChangeFile}
              hidden
            />
            {patientFile?.name && (
              <Chip
                label={patientFile?.name || ""}
                variant="outlined"
                onDelete={() => {
                  fileRef.current.value = null;
                  setPatientFile(null);
                }}
              />
            )}
          </Grid>

          <Grid item lg={6} md={3} sm={3}></Grid>
          <Grid item lg={4} md={5} sm={5}>
            <MuiButton
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={handleDownloadFile}
            >
              Download Template
            </MuiButton>
          </Grid>
          <Grid item lg={2} md={4} sm={4}>
            <MuiButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={!(segmentName && (createPatient?.id || patientFile))}
              onClick={handleImportFile}
            >
              Import
            </MuiButton>
          </Grid>
        </Grid>

        <PureReactTable
          // @ts-ignore
          selectedRow={selectedPatient}
          onSelect={handleSelectedPatient}
          data={props.marketingPatientList?.items}
          columns={patientColumn}
          showPagination={true}
          pageSize={props.filterMarketingPatient?.limit}
          pages={Math.ceil(
            props.marketingPatientList?.total /
              props.filterMarketingPatient?.limit
          )}
          page={
            props.filterMarketingPatient?.offset /
            props.filterMarketingPatient.limit
          }
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          loading={props.loadingMarketingPatient}
          manual
        />

        <Grid container style={{ paddingTop: "10px" }}>
          <Grid item lg={10}></Grid>
          <Grid item lg={2}>
            <MuiButton
              variant="contained"
              color="error"
              size="large"
              style={{ width: "100%" }}
              onClick={() => {
                const result: string = props.filterMarketingPatient?.result;
                setModConfirmDetail({
                  open: true,
                  data: { result: result },
                  type: DELETE_SEGMENT,
                  title: `ลบ Segment ${result}`,
                  content: `ต้องการลบ Segment ${result} หรือไม่`,
                });
              }}
            >
              DELETE
            </MuiButton>
          </Grid>
        </Grid>
      </div>

      {selectedPatient?.id && (
        <TabDashboardPatient selectedPatient={selectedPatient} />
      )}

      <Modal
        closeIcon
        size="tiny"
        closeOnDimmerClick={true}
        onClose={handleCloseModConfirmDetail}
        open={modConfirmDetail.open}
      >
        <Header icon>{modConfirmDetail?.title}</Header>
        <Modal.Content>{modConfirmDetail?.content}</Modal.Content>
        <Modal.Actions>
          <MuiButton
            variant="contained"
            color="success"
            style={{ margin: "5px 10px" }}
            startIcon={<CheckIcon />}
            onClick={handleApprove}
          >
            Yes
          </MuiButton>
          <MuiButton
            variant="outlined"
            color="error"
            startIcon={<CloseIcon />}
            onClick={handleCloseModConfirmDetail}
          >
            No
          </MuiButton>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

MarketingSegmentDashboard.defaultProps = MarketingSegmentDashboardInitial;

export default React.memo(MarketingSegmentDashboard);
