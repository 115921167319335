import { CHAT, PRX } from "./url";
import BaseService from "../react-lib/apis/services/BaseService";

class ChatService extends BaseService {
  getChatChannel({ headers, params } = {}) {
    return this.client
      .get(PRX.CHAT_CHANNEL, { headers, params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getLasterChatChannel({ headers, params } = {}) {
    return this.client
      .get(CHAT.LATEST_CHAT_CHANNEL, { headers, params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getOfficialChannel({ headers, params } = {}) {
    return this.client
      .get(PRX.OFFICAL_ACCOUNT, { headers, params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postFollowDivisionProfile({ headers, data, division_profile_id} = {}) {
    const url = PRX.OFFICAL_ACCOUNT_FOLLOW({ division_profile_id: division_profile_id });
    return this.client.post(url, data, {headers})
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }
  postunFollowDivisionProfile({ headers, data, division_profile_id} = {}) {
    const url = PRX.OFFICAL_ACCOUNT_UNFOLLOW({ division_profile_id: division_profile_id });
    return this.client.post(url, data, {headers})
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }
  getProxyPatientHasDivisionList({  headers, params}) {
    return this.client
    .get(PRX.PROXY_PATIENT_HAS_DIVISION, { headers, params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }

}

export default ChatService;
