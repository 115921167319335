import WasmController from 'react-lib/frameworks/WasmController';
import * as AdminI from './AdminInterface';
import * as LoginI from './login/LoginInterface';
import * as TagI from 'react-lib/frameworks/TagI';
import * as ContentI from "./content/ContentInterface"

export default class AdminController extends 
  WasmController<AdminI.State, AdminI.Event, AdminI.Data> {

  constructor(
    getState: () => AdminI.State, 
    setState: (state: AdminI.State, callback?: any) => void, 
    window: any) {
    super(getState, setState, window, AdminI.DataInitial)
  }

  handleEvent = async (e: AdminI.Event) => {
    console.log(e.message, e.params)
    switch (e.message) {
      // Setup
      case "DidMount":
        AdminI.DidMount(this, e.params);
        return
      
      case "LoginIssara":
        LoginI.LoginIssara(this, e.params);
        return

      case "Logout":
        LoginI.Logout(this, e.params);
        return

      // Tag Manager
      case "GetTagList":
        TagI.GetTagList(this, e.params);
        return
      
      case "AddTag":
        TagI.AddTag(this, e.params);
        return
      
      case "SaveTag":
        TagI.SaveTag(this, e.params);
        return
      
      case "DeleteTag":
        TagI.DeleteTag(this, e.params);
        return

      // Content
      case "SearchContent":
        ContentI.SearchContent(this, e.params);
        return

      // case "GetFetchedContent":
      //   ContentI.GetFetchedContent(this, e.params);
      //   return
      
      // case "GetOkContent":
      //   ContentI.GetOkContent(this, e.params);
      //   return

      // case "GetUniqueContent":
      //   ContentI.GetUniqueContent(this, e.params);
      //   return

      case "GetDomainList":
        ContentI.GetDomainList(this, e.params);
        return

      case "GetDomainContent":
        ContentI.GetDomainContent(this, e.params);
        return

      case "GetContent":
        ContentI.GetContent(this, e.params);
        return

      case "ContentOk":
        ContentI.ContentOk(this, e.params);
        return

      case "SaveSelectedContent":
        ContentI.SaveSelectedContent(this, e.params);
        return

      case "GetLinkTerm":
        ContentI.GetLinkTerm(this, e.params);
        return
      
      case "AddSkipTerm":
        ContentI.AddSkipTerm(this, e.params);
        return
      
      // Tag
      case "AddTagSet":
        TagI.AddTagSet(this, e.params);
        return

      case "AddTagToTagSet":
        TagI.AddTagToTagSet(this, e.params);
        return

      case "RemoveTagFromTagSet":
        TagI.RemoveTagFromTagSet(this, e.params);
        return
        
      default:
          let testExhausitve: never = e
    }
    console.log("-- Unhandled case")
  }
}