import React, { useState, useEffect } from 'react'
import { CSSProperties } from 'react'

// export const Tab = (props) => {

//   const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  
//   return(
//   <div style={{ display: "flex", height: "100%", width: "100%" }}>
//     <div 
//       style={{ 
//         width: props.tabWidth || "250px",
//         display: "flex", 
//         flexDirection: "column",
//         border: "solid #cccccc 1px" 
//       }}>
//       {props?.data.map((item, index) => (
//       <div 
//         key={index}
//         style={{ 
//           backgroundColor: props.data[selectedTabIndex]?.key === item.key ? "#d6ecf3" : "white",
//           padding: "5px",
//           cursor: "pointer"
//         }}
//         onClick={(e) => { 
//           setSelectedTabIndex(index) 
//           props?.onTabChange?.()
//         }}>
//         {item.name}
//       </div>
//       ))}
//     </div>
//     <div style={{ flex: 1 }}>
//       {props?.data[selectedTabIndex]?.render() || ""}
//     </div>
//   </div> 
//   )
// }

export const HeaderStyle: CSSProperties = { 
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
  width: "100%", 
  fontWeight: "bold",
  fontSize: "1.2rem",
  padding: "5px",
  backgroundColor: "#2083C1",
  minHeight: "25px" 
}
