import React, { CSSProperties, useEffect, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
// import TimeChart from "./TimeChart";
// import { extractData } from "./ExtractData";
import {
  BarChart as NutritionBar,
  NutrientStatusList,
  COLOR,
  NutrientsType,
  useStyles,
  NutrientDetail,
  DURATION_OPTIONS,
  DATE_DURATION,
  DateDurationKey,
  calculateWhole,
} from "../../clinic/HistoryFood";
import { calculatePercent } from "../../clinic/DMFoodMain";
import {
  Dimmer,
  Icon,
  Loader,
  Segment,
  Button,
  Progress,
  SemanticCOLORS,
  Dropdown,
} from "semantic-ui-react";

import SnackMessage from "../../clinic/SnackMessage";

import { DateTextBox } from "../../../react-lib/apps/common";
import moment from "moment";
import { convertToADDate, FOOD_MEAL } from "../../MobClinicInterface";
import { HeaderChart } from "../../clinic/DMEmotionRestChart";
import { DATE_FORMAT } from "../../clinic/DMEmotionRest";

const styles = {
  subHeader: {
    color: "#0072BC",
    fontSize: "24px",
    fontWeight: "bold",
  } as CSSProperties,
  btnWidth120px: {
    width: "115px",
    background: COLOR.light_grey,
    fontSize: "12px",
    border: `1px solid ${COLOR.border_grey}`,
    margin: "1px",
    textTransform: "capitalize",
  } as CSSProperties,
  flexRow: {
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  foodImage: {
    objectFit: "cover",
    width: "100px",
    height: "100px",
    borderRadius: "50px",
  } as CSSProperties,
  foodItemFont: {
    fontSize: "12px",
    color: "#828282",
  },
};

type NutrientsTypeListKey = keyof typeof NutrientsTypeList;

const NutrientsTypeList: {
  calories: { name: NutrientsType; color: string }[];
  other: { name: NutrientsType; color: string }[];
} = {
  calories: [
    { name: "CARBOHYDRATE", color: COLOR.pink },
    { name: "PROTEIN", color: COLOR.yellow },
    { name: "FAT", color: COLOR.green },
  ],
  other: [
    { name: "SODIUM", color: COLOR.orange },
    { name: "SUGAR", color: COLOR.teal },
    { name: "SAT_FAT", color: COLOR.violet },
  ],
};

type WebDMFoodProps = {
  onEvent?: any;
  foodHistory?: any[];
  loadingFood?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
};

const WebDMFoodInitial = {
  onEvent: () => {},
  foodHistory: [],
};

const WebDMFood: React.FC<WebDMFoodProps> = (props) => {
  const classes = useStyles();
  const [nutritionTab, setNutritionTab] =
    useState<NutrientsTypeListKey>("calories");
  const [foodMealInfo, setFoodMealInfo] = useState<any>(null);
  const [duration, setDuration] = useState<DateDurationKey>("5_DAY");
  const [nutrientsType, setNutrientsType] = useState({
    name: "CARBOHYDRATE",
    color: COLOR.pink,
  });
  // const [selectedDate, setSelectedDate] = useState(
  //   moment().locale("en").format("DD/MM/YYYY")
  // );

  const [startDate, setStartDate] = useState(moment().add(-4, "days"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    props.onEvent({ message: "handleGetHistoryFood", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    const length = props.foodHistory?.length || 0;

    if (length > 0) {
      let latestFoodData = null;
      for (let i = 1; length - i >= 0; i++) {
        let foodData = props.foodHistory?.[length - i];
        // get final that have exist food data
        if (foodData?.[FOOD_MEAL.BREAKFAST]) {
          latestFoodData = foodData;
          break;
        }
      }
      setFoodMealInfo(latestFoodData);
      // setFoodMealInfo(props.foodHistory[props.foodHistory.length - 1])
    }
  }, [props.foodHistory]);

  const handleChangeNutrientsType = (item: {
    name: NutrientsType;
    color: string;
  }) => {
    setNutrientsType(item);
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    let dateRange = DATE_DURATION[duration];

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(dateRange, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(Math.abs(dateRange), "days");
    }

    props.onEvent({
      message: "handleGetHistoryFood",
      params: {
        selectedDate: todate.locale("en").format("D MMMM YYYY"),
        duration: duration,
      },
    });

    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    let dateRange = DATE_DURATION[value as DateDurationKey];
    let stDate = endDate.clone().add(dateRange, "days");

    props.onEvent({
      message: "handleGetHistoryFood",
      params: {
        selectedDate: endDate.locale("en").format("D MMMM YYYY"),
        duration: value,
      },
    });
    console.log("handleChangeDuration: ", stDate, dateRange);
    setStartDate(stDate);
    setDuration(value);
  };

  console.log("Food info: ", foodMealInfo);
  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingFood} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/detail/nutrition.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey345" />
        </span>
        <Button
          content="Calories"
          color="blue"
          style={{
            marginLeft: "30px",
            backgroundColor:
              nutritionTab === "calories" ? "#2185d0" : "#FFFFFF",
          }}
          inverted={nutritionTab !== "calories"}
          onClick={() => {
            setNutritionTab("calories");
          }}
        />
        <Button
          content="Other nutrients"
          color="blue"
          inverted={nutritionTab !== "other"}
          style={{
            backgroundColor: nutritionTab === "other" ? "#2185d0" : "#FFFFFF",
          }}
          onClick={() => {
            setNutritionTab("other");
          }}
        />
      </div>
      <div className="sub-layout dm">
        <div
          style={{
            display: "flex",
            width: "500px",
            justifyContent: "space-evenly",
          }}
        >
          {NutrientsTypeList[nutritionTab].map((item: any, index: number) => (
            <div
              key={"type-" + index}
              // style={{display: "flex"}}
              // className={classes.alignCenter}
            >
              <Button
                key={"button-" + index}
                onClick={() => handleChangeNutrientsType(item)}
                name={item.name}
                style={{
                  ...styles.btnWidth120px,
                  ...(item.name === nutrientsType.name
                    ? {
                        background: COLOR.light_bue,
                        border: `1px solid ${COLOR.blue}`,
                        color: COLOR.blue,
                      }
                    : {}),
                }}
              >
                {item.name.replace(/_/g, " ").toLowerCase()}
              </Button>
            </div>
          ))}
        </div>

        <HeaderChart
          yAxesLabelLeft=""
          yAxesLabelRight=""
          startDate={startDate}
          endDate={endDate}
          durationOptions={DURATION_OPTIONS}
          selectedDuration={duration}
          onChangeDuration={handleChangeDuration}
          onChangeDateRange={handleChangeDateRange}
          legendItem={{
            type: "rect",
            items: [
              {
                name: "Carbohydrate",
                color: "#f4badb",
              },
              {
                name: "Protein",
                color: "#fee8a9",
              },
              {
                name: "Fat",
                color: "#b2e7be",
              },
              {
                name: "Sodium",
                color: "#facdb0",
              },
              {
                name: "Sugar",
                color: "#a6e5e2",
              },
              {
                name: "Sat. Fat",
                color: "#c8b7ec",
              },
            ],
          }}
        />

        <div
          className={classes.caltext}
          style={{ display: "flex", margin: "15px 8px 3px" }}
        >
          <div style={{ padding: "0 3px", textTransform: "capitalize" }}>
            {nutrientsType.name.replace(/_/g, " ").toLowerCase()}{" "}
          </div>
          {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sodium"
            ? "(g)"
            : "(mg)"}
        </div>
        <div className={classes.plot}>
          {/* <Plots /> */}
          <NutritionBar
            color={nutrientsType.color}
            foodData={props.foodHistory}
            graphType="food"
            setMealInfo={setFoodMealInfo}
            hideFooterChart={true}
            nutrient={nutrientsType.name.trim().toLowerCase()}
          />
        </div>
        {/* </div> */}
        {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sodium" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className={classes.caltext}
              style={{ display: "flex", margin: "20px 8px 3px" }}
            >
              <div style={{ padding: "0 3px", textTransform: "capitalize" }}>
                {nutrientsType.name.replace(/_/g, " ").toLowerCase()}{" "}
              </div>
              {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sugar"
                ? "(kcal)"
                : "(teaspoon)"}
            </div>
            <div className={classes.plot}>
              {/* <Plots /> */}
              <NutritionBar
                color={nutrientsType.color}
                foodData={props.foodHistory}
                graphType="energy"
                setMealInfo={setFoodMealInfo}
                nutrient={nutrientsType.name.trim().toLowerCase()}
                hideFooterChart={true}
              />
            </div>
          </div>
        )}

        <div style={{ display: "flex" }} className={classes.alignCenter}>
          {(Object.keys(NutrientStatusList) as (keyof NutrientDetail)[])
            ?.slice(0, 3)
            .map((key, index: number) => {
              console.log(
                "NutrientList: ",
                key,
                Number(foodMealInfo?.[key] || 0).toFixed(2)
              );
              return (
                <div
                  key={"status-" + index}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 15px",
                  }}
                >
                  <div className={classes.ProgressBoxLabel}>
                    <div
                      className={classes.ProgressLabelLeft}
                      style={{ flex: 2, fontSize: "18px" }}
                    >
                      {NutrientStatusList[key].name}
                    </div>
                    <div
                      className={classes.ProgressLabelRight}
                      style={{ flex: 5, fontSize: "18px" }}
                    >
                      {Number(foodMealInfo?.[key] || 0.0).toFixed(2)}/
                      {foodMealInfo?.nutrientionRequirement?.[key]?.[
                        NutrientStatusList[key].apiUnit
                      ]?.toFixed(2) || NutrientStatusList[key].whole}{" "}
                      {NutrientStatusList[key].unit}
                    </div>
                  </div>
                  <Progress
                    percent={
                      key === "sugar"
                        ? calculatePercent(
                            +(
                              (foodMealInfo?.sugar?.toFixed(2) || 0) / 4
                            ).toFixed(2),
                            calculateWhole(foodMealInfo, key)
                          ) || 0
                        : calculatePercent(
                            (foodMealInfo?.[key] || 0.0).toFixed(2),
                            calculateWhole(foodMealInfo, key)
                          )
                    }
                    style={{ marginBottom: "5px" }}
                    color={NutrientStatusList[key].color as SemanticCOLORS}
                    size="large"
                    disabled
                  />
                </div>
              );
            })}
        </div>

        <div style={{ display: "flex" }} className={classes.alignCenter}>
          {(Object.keys(NutrientStatusList) as (keyof NutrientDetail)[])
            ?.slice(3, 6)
            .map((key, index: number) => (
              <div
                key={"status-" + index}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 15px",
                }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 2, fontSize: "18px" }}
                  >
                    {NutrientStatusList[key].name}
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 5, fontSize: "18px" }}
                  >
                    {key === "sugar" ? (
                      <>
                        {((foodMealInfo?.sugar?.toFixed(2) || 0) / 4).toFixed(
                          2
                        )}
                        /
                        {foodMealInfo?.nutrientionRequirement?.[key]?.[
                          NutrientStatusList[key].apiUnit
                        ]?.toFixed(2) || NutrientStatusList[key].whole}{" "}
                        {NutrientStatusList[key].unit}
                      </>
                    ) : (
                      <>
                        {Number(foodMealInfo?.[key] || 0.0).toFixed(2)}/
                        {foodMealInfo?.nutrientionRequirement?.[key]?.[
                          NutrientStatusList[key].apiUnit
                        ]?.toFixed(2) || NutrientStatusList[key].whole}{" "}
                        {NutrientStatusList[key].unit}
                      </>
                    )}
                  </div>
                </div>
                <Progress
                  percent={
                    key === "sugar"
                      ? calculatePercent(
                          +((foodMealInfo?.sugar?.toFixed(2) || 0) / 4).toFixed(
                            2
                          ),
                          calculateWhole(foodMealInfo, key)
                        ) || 0
                      : calculatePercent(
                          (foodMealInfo?.[key] || 0.0).toFixed(2),
                          calculateWhole(foodMealInfo, key)
                        )
                  }
                  style={{ marginBottom: "5px" }}
                  color={NutrientStatusList[key].color as SemanticCOLORS}
                  size="large"
                  disabled
                />
              </div>
            ))}
        </div>
        <div style={{ paddingTop: "20px" }}>
          <span style={styles.subHeader}>
            <FormattedMessage id="bplusClinicKey524" />
          </span>
          <NutritionFoodTable
            onEvent={props.onEvent}
            foodMealInfo={foodMealInfo}
          />
        </div>
      </div>
    </Segment>
  );
};

type NutritionFoodTableProps = {
  foodMealInfo?: any;
  onEvent?: any;
};

type foodMealItems = {
  foods: any[];
  total_calories: number;
};

const foodMealItemsInitial: foodMealItems = {
  foods: [],
  total_calories: 0,
};

const NutritionFoodTable: React.FC<NutritionFoodTableProps> = (props) => {
  const [mealDetail, setMealDetail] = useState({
    [FOOD_MEAL.BREAKFAST]: { ...foodMealItemsInitial },
    [FOOD_MEAL.LUNCH]: { ...foodMealItemsInitial },
    [FOOD_MEAL.DINNER]: { ...foodMealItemsInitial },
    [FOOD_MEAL.SNACK]: { ...foodMealItemsInitial },
  });

  useEffect(() => {
    console.log("mealDetail: ", mealDetail);
    let mealAll = {
      [FOOD_MEAL.BREAKFAST]: { ...foodMealItemsInitial },
      [FOOD_MEAL.LUNCH]: { ...foodMealItemsInitial },
      [FOOD_MEAL.DINNER]: { ...foodMealItemsInitial },
      [FOOD_MEAL.SNACK]: { ...foodMealItemsInitial },
    };
    if (props.foodMealInfo && Object.keys(props.foodMealInfo).length > 0) {
      for (const [key, value] of Object.entries(mealAll)) {
        let calories = 0;
        props.foodMealInfo[key].forEach((item: any) => {
          calories += item.energy_cal * item.qty;
        });
        mealAll[key].foods = [...props.foodMealInfo[key]];
        mealAll[key].total_calories = +calories.toFixed(2);
      }
    }
    setMealDetail(mealAll);
  }, [props.foodMealInfo]);

  console.log("Meal info:", mealDetail);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        {/* <Icon name="angle double left" size="large" onClick={() => {}} /> */}
        {/* <DateTextBox
          inputClassNames="date-center"
          style={{ padding: "0px 5px" }}
          value={props}
          onChange={(date) => {
            handleChangeDate(date);
          }}
        /> */}
        {/* <Icon name="angle double right" size="large" onClick={() => {}} /> */}
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          {moment(props.foodMealInfo?.date, "YYYY-MM-DD")
            .locale("en")
            .format("DD MMMM YYYY") || ""}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid #2399E5",
          borderRadius: "10px",
        }}
      >
        <NutritionFoodColumn
          title="Breakfast"
          MealDetail={mealDetail[FOOD_MEAL.BREAKFAST]}
          headerStyle={{ borderTopLeftRadius: "10px" }}
        />
        <NutritionFoodColumn
          title="Lunch"
          MealDetail={mealDetail[FOOD_MEAL.LUNCH]}
          headerStyle={{}}
        />
        <NutritionFoodColumn
          title="Dinner"
          MealDetail={mealDetail[FOOD_MEAL.DINNER]}
          headerStyle={{}}
        />
        <NutritionFoodColumn
          title="Snack"
          MealDetail={mealDetail[FOOD_MEAL.SNACK]}
          headerStyle={{ borderTopRightRadius: "10px" }}
        />
      </div>
    </div>
  );
};

type NutritionFoodColumnProps = {
  title?: string;
  headerStyle?: any;
  MealDetail?: any;
};

const NutritionFoodColumn: React.FC<NutritionFoodColumnProps> = (props) => {
  console.log("props NutritionFoodColumn: ", props.MealDetail);
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          background: "#C5D9FF",
          ...props.headerStyle,
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          alignItems: "center",
        }}
      >
        <img
          alt="mea.icon"
          height="15px"
          style={{ color: "var(--theme-icon-color-dm)", paddingRight: "5px" }}
          src="/images/detail/nutrition.png"
        />
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.title}
        </span>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px" }}
      >
        <span
          style={{ fontSize: "22px", fontWeight: "bold", color: "#A2D7E8" }}
        >
          {props.MealDetail?.total_calories || 0}
        </span>
        <span
          style={{ paddingLeft: "10px", color: "#A3A3A3", fontSize: "16px" }}
        >
          <FormattedMessage id="bplusClinicKey553" />
        </span>
      </div>
      {props.MealDetail?.foods?.map((item: any, index: number) => (
        <div key={"foodItem" + index}>
          <NutritionFoodItem detailFood={item} />
        </div>
      )) || ""}
    </div>
  );
};

type NutritionItemProps = {
  detailFood?: any;
};

const NutritionFoodItem: React.FC<NutritionItemProps> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0px 10px 10px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "10px",
        }}
      >
        {props.detailFood?.image ? (
          <img
            width={200}
            src={props.detailFood?.image}
            style={styles.foodImage}
          />
        ) : (
          <div />
        )}
      </div>
      <div>
        <div
          style={{
            color: "#1621A8",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {props.detailFood?.name || props.detailFood?.name_en || ""}
        </div>
        <div style={styles.foodItemFont}>
          {"Service size: " + props.detailFood?.qty}{" "}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ ...styles.flexColumn, flex: "1" }}>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Calories: " +
                (
                  props.detailFood?.energy_cal * props.detailFood?.qty || 0
                ).toFixed(2)}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Fat: " +
                (
                  props.detailFood?.fat_grams * props.detailFood?.qty || 0
                ).toFixed(2) +
                " g"}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Sat. Fat: " +
                (
                  props.detailFood?.sat_fat_milligram * props.detailFood?.qty ||
                  0
                ).toFixed(2) +
                " mg"}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Sodium:" +
                (
                  props.detailFood?.sodium_milligram * props.detailFood?.qty ||
                  0
                ).toFixed(2) +
                " g"}
            </div>
          </div>
          <div style={{ ...styles.flexColumn, flex: "1" }}>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Carbohydrate: " +
                (
                  props.detailFood?.cabohydrate_grams * props.detailFood?.qty ||
                  0
                ).toFixed(2) +
                " g"}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Protein: " +
                (
                  props.detailFood?.protein_grams * props.detailFood?.qty || 0
                ).toFixed(2) +
                " g"}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Sugar: " +
                (
                  props.detailFood?.sugar_grams * props.detailFood?.qty || 0
                ).toFixed(2) +
                " g"}
            </div>
            <div style={{ ...styles.flexRow, ...styles.foodItemFont }}>
              {"Fiber: " +
                (
                  props.detailFood?.fiber_grams * props.detailFood?.qty || 0
                ).toFixed(2) +
                " g"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WebDMFood);

const MOCK_FOOD_DATA = [
  {
    date: "2021-02-10",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-02-20",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-03-23",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-03-29",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-04-17",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-04-25",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-05-10",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-05-24",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-06-07",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-07-11",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-08-12",
    carbohydrate: 50,
    protein: 12,
    fat: 20,
    sodium: 1300,
    sugar: 40,
    saturated_fat: 12,
  },
  {
    date: "2021-09-08",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-09-24",
    carbohydrate: 80,
    protein: 15,
    fat: 15,
    sodium: 1500,
    sugar: 50,
    saturated_fat: 16,
  },
  {
    date: "2021-09-20",
    carbohydrate: 120,
    protein: 5,
    fat: 150,
    sodium: 1200,
    sugar: 20,
    saturated_fat: 10,
  },
  {
    date: "2021-09-27",
    carbohydrate: 150,
    protein: 6,
    fat: 17,
    sodium: 1000,
    sugar: 30,
    saturated_fat: 1,
  },
];

const MOCK_FOOD_ITEM = {
  breakfast: {
    total_calories: 410,
    foods: [
      {
        name: "Boiled chicken rice",
        calories: 350,
        carbohydrate: 72,
        protein: 12,
        fat: 20,
        sodium: 20,
        sugar: 40,
        saturated_fat: 12,
        fiber: 5,
        image:
          "https://img.wongnai.com/p/1920x0/2017/06/22/bbf899f7ab4341dea4aec6330c2afafd.jpg",
      },
      {
        name: "Jusmin tea",
        calories: 60,
        carbohydrate: 5,
        protein: 0,
        fat: 3,
        sodium: 0,
        sugar: 10,
        saturated_fat: 12,
        fiber: 1,
        image: "https://food.mthai.com/app/uploads/2018/08/jasmine-tea.jpg",
      },
    ],
  },
  lunch: {
    total_calories: 450,
    foods: [
      {
        name: "Fried chicken rice",
        calories: 450,
        carbohydrate: 150,
        protein: 15,
        fat: 50,
        sodium: 20,
        sugar: 20,
        saturated_fat: 2,
        fiber: 10,
        image:
          "https://cpfmshop.com//uploads/283/product/f8a6817059ec6277992c08c6b5196903_full.jpg",
      },
    ],
  },
  dinner: {
    total_calories: 0,
    foods: [],
  },
  snack: {
    total_calories: 300,
    foods: [
      {
        name: "Doritos",
        calories: 300,
        carbohydrate: 150,
        protein: 0,
        fat: 60,
        sodium: 40,
        sugar: 10,
        saturated_fat: 0,
        fiber: 0,
        image:
          "https://media.allonline.7eleven.co.th/pdmain/473659-01-extruded-doritos.jpg",
      },
    ],
  },
};
// const [matched, statDict] = extractData(
//   {
//     carb: "Carb",
//     fat: "Fat",
//     protein: "Protein"
//   },
//   props.statDict
// )
// if (!matched)
//   return(<div>No data</div>)

// return(
// <div style={{ width: "100%", height: "100%", padding: "10px"}}>
//   <TimeChart
//     type="stack"
//     data={statDict}
//   />)
// </div>
// )

// const Nutrition1 = (props) => {
//   console.log(props.nutritionList)

//   let dateList = []
//   for (const item of props.nutritionList) {
//     if (!dateList.includes(item.dateStr)) {
//       dateList.push(item.dateStr)
//     }
//   }
//   let carbDict = dateList.reduce((acc, cur) => { acc[cur] = 0; return acc }, {})
//   let fatDict = dateList.reduce((acc, cur) => { acc[cur] = 0; return acc }, {})
//   let proteinDict = dateList.reduce((acc, cur) => { acc[cur] = 0; return acc }, {})

//   for (const item of props.nutritionList) {
//     carbDict[item.dateStr] += item.carb
//     fatDict[item.dateStr] += item.fat
//     proteinDict[item.dateStr] += item.protein
//   }

//   return(
//   <div style={{ width: "100%", height: "100%", padding: "10px"}}>
//     <Bar
//       height={80}
//       width={100}
//       options={{
//         maintainAspectRatio: false,
//         scales: {
//             yAxes: [
//               {
//                 stacked: true,
//                 ticks: {
//                     suggestedMin: 0,
//                 }
//               }
//             ]
//         }
//        }}
//       data={{
//         labels: dateList,
//         datasets: [
//           {
//             label: "Carb",
//             stack: "nutrition",
//             data: Object.values(carbDict),
//             backgroundColor: "green",
//           },
//           {
//             label: "Fat",
//             stack: "nutrition",
//             data: Object.values(fatDict),
//             backgroundColor: "blue",
//           },
//           {
//             label: "Protein",
//             stack: "nutrition",
//             data: Object.values(proteinDict),
//             backgroundColor: "yellow",
//           }
//         ]
//       }}
//     />
//   </div>
//   )
// }
