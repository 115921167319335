import React, { createRef, Ref, Component, CSSProperties } from "react";
import { Input, Icon } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  headerSection: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
  } as CSSProperties,
  patientSearchSection: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    justifyContent: "flex-end",
    alignItems: "center"
  } as CSSProperties,
  headerFont: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "0px 15px",
  } as CSSProperties,
};

type WebMomChildTopBarLocationProps = {
  user?: any;
  webSub?: string;
  patientSub?: string;
  userManagerSub?: string;
  onEvent?: any;
  setProp?: any;
};

const WebMomChildTopBarLocationInitial = {
  user: {},
  webSub: "",
  patientSub: "",
  userManagerSub: "",
  onEvent: () => {},
  setProp: null,
};

const WebMomChildTopBarLocation: React.FC<WebMomChildTopBarLocationProps> = (
  props
) => {
  return (
    <div className="TopBar-Location">
      <div style={styles.headerSection}>
        <div style={styles.headerFont}><FormattedMessage id="bplusClinicKey331" /></div>
        {props.webSub && <div style={styles.headerFont}>-</div>}
        {props.webSub === "patient" && (
          <div style={styles.flexRow}>
            <div style={styles.headerFont}>
              <Icon name="users" /> <FormattedMessage id="bplusClinicKey372" />
            </div>
            {props.patientSub && (
              <div style={styles.flexRow}>
                <div style={styles.headerFont}>-</div>
              </div>
            )}
          </div>
        )}
        {props.webSub === "userManagement" && (
          <div style={styles.flexRow}>
            <div style={styles.headerFont}>
              <Icon name="user plus" /> <FormattedMessage id="bplusClinicKey508" />
            </div>
            {props.userManagerSub && (
              <div style={styles.flexRow}>
                <div style={styles.headerFont}>-</div>
              </div>
            )}
          </div>
        )}
        {props.webSub === "content" && (
          <div style={styles.headerFont}>
            <Icon name="youtube" /> <FormattedMessage id="bplusClinicKey123" />
          </div>
        )}
      </div>
      <div style={styles.patientSearchSection}>
          <Input style={{height: "35px", width: "400px"}} icon='search' placeholder='ค้นหารายชื่อคนไข้ (HN, ชื่อ, นามสกุล)'/>
      </div>
    </div>
  );
};

WebMomChildTopBarLocation.defaultProps = WebMomChildTopBarLocationInitial;

export default React.memo(WebMomChildTopBarLocation);
