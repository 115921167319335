import React, { CSSProperties } from "react";
import { Grid, Divider } from "semantic-ui-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Skeleton from "@mui/material/Skeleton";

type MobSkeletonPHRProps = {
  margin?: CSSProperties["margin"];
  divider?: boolean;
  hideFixedSkeleton?: boolean;
  lineHeight?: string;
} & WrappedComponentProps;

const MobSkeletonPHRInitial: Omit<MobSkeletonPHRProps, "intl"> = {};

const MobSkeletonPHR: React.FC<MobSkeletonPHRProps> = (props) => {
  return (
    <>
      {[
        [85, 60, 80],
        [70, 90, 60],
        [80, 75, 90],
      ].map((width) => (
        <Grid
          columns={2}
          style={{
            borderRadius: "10px",
            margin: props.margin ?? "10px",
            width: "95%",
            boxShadow: "0px 0px 8px 1px rgba(131, 133, 135, 0.025)",
            display: "grid",
            gridTemplateColumns: "76.5px 1fr",
            backgroundColor: "var(--skeleton-bg-color)",
          }}
        >
          <Grid.Column
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Skeleton
              animation="pulse"
              variant="circular"
              width={45}
              height={45}
            />
          </Grid.Column>
          <Grid.Column style={{ width: "100%", paddingLeft: 0 }}>
            <Grid.Row>
              <div style={{}}>
                <Skeleton
                  animation="pulse"
                  height={20}
                  width={`${width[0]}%`}
                  style={{ marginBottom: props.lineHeight ?? "" }}
                />

                {!props.hideFixedSkeleton && (
                  <Skeleton
                    animation="pulse"
                    height={21}
                    width={"15%"}
                    style={{ marginBottom: props.lineHeight ?? "" }}
                  />
                )}
              </div>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: props.lineHeight ?? "3px" }}>
              <Skeleton animation="pulse" height={21} width={`${width[1]}%`} />
            </Grid.Row>
            {(typeof props.divider === "undefined" || props.divider) && (
              <Divider style={{ margin: "0px 0px" }} />
            )}
            <Skeleton
              animation="pulse"
              height={21}
              width={`${width[2]}%`}
              style={{ marginBottom: "3px" }}
            />
          </Grid.Column>
        </Grid>
      ))}
    </>
  );
};

MobSkeletonPHR.defaultProps = MobSkeletonPHRInitial;

export default React.memo(injectIntl(MobSkeletonPHR));
