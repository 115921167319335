import React, { useState } from "react";
import TabPath from "react-lib/frameworks/TabPath";
import { Button, Input } from "semantic-ui-react";
import ContentTagEditor from "./ContentTagEditor";
import Domain from "./Domain";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const Classify = (props: any) => {

  const [searchTerm, setSearchTerm] = useState<string>("");

  const selectedContent = Number.isInteger(props.selectedContentIndex) ? 
      props.contentList[props.selectedContentIndex] : null;

  // console.log(selectedContent);
  // console.log(props.tagList)

  return(
    <div style={{ height: "94vh"}}>
      <TabPath
        // tabWidth="400px"
        // topTab={
        //   <div style={{ display: "flex" }}>
        //     <div style={{ flex: 1 }}>
        //       <Input 
        //         fluid
        //         onChange={(e: any) => setSearchTerm(e.target.value)}
        //         value={searchTerm}
        //         placeholder="พิมพ์คำเพื่อฟิลเตอร์ หรือ url ที่จะเพิ่มก่อนกดปุ่ม"
        //       />
        //     </div>
        //     <Button color="green">+</Button>
        //   </div>
        // }
        onTabChange={(index: number) => {
          props.setProp("selectedContentIndex", index);
        }}
        data={props?.domainList?.map((domain: string, index: number) => (
          {
            key: index,
            name: domain,
            render: () =>
              <Domain
                domain={domain}
                onEvent={props.onEvent}
                domainData={props.domainData}
                contentDomain={props.contentDomain}
              />
          }
        ))}
        // to deprecate
        data1={false && props.contentList
          ?.filter((content: any) => searchTerm === "" ? 
                   true: content.title.includes(searchTerm))
          .map((content: any, index: number) => (
          {
            key: index,
            name: content.title,
            render: () => (
              <div style={{ display: "flex", height: "94vh" }}>
                <div 
                  style={{ 
                    flex: 1, 
                    padding: "5px", 
                    borderRight: "solid #cccccc 1px",
                    borderBottom: "solid #cccccc 1px" 
                  }}>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "5px" }}>
                    <div style={{ minWidth: "100px", display: "flex" }}>
                      <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <div><FormattedMessage id="bplusClinicKey481" /> </div>
                        <div
                          style={{ 
                            cursor: "pointer", 
                            display: "flex", 
                            borderRadius: "1rem", 
                            padding: "0 0.3rem 0 0.3rem", 
                            background: "lightgreen" 
                          }}
                          onClick={(e: any) => {
                            props.onEvent({
                              message: "AddTagSet",
                              params: { dataType: "content" }
                            })
                          }}>
                          +
                        </div>
                      </div>  
                    </div>
                    <div style={{ flex: 1 }}>
                      <ContentTagEditor 
                        tagList={props.tagList}
                        selectedContent={selectedContent} 
                        setProp={props.setProp}
                        onEvent={props.onEvent}
                      />
                    </div>
                  </div>
                  <Button
                    color="green"
                    onClick={(e: any) => {
                      props.onEvent({
                        message: "SaveSelectedContent",
                        params: {}
                      });
                    }}>
                    <FormattedMessage id="bplusClinicKey437" />
                  </Button>
                </div>
                <div  
                  style={{ 
                    flex: 2, 
                    padding: "5px", 
                    borderBottom: "solid #cccccc 1px"  
                  }}>
                  <div>{content.url}</div>
                  <img src={content.image} />
                </div>
              </div>
            )
          }
        )) || []}
      />
    </div>
  )
}

export default Classify
