import { WasmHandler } from "../../../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import CONFIG from "../../../config/config";
// Interface
import {
  commonCreatePatientSegmentChild,
  commonDeletePatientSegment,
  commonListPatientSegmentList,
  commonListProxyPatientNoUser,
  SEGMENT_TYPE,
  CHILD_RESULT,
} from "../../ManageInterface";

// Apis
import ChoiceView from "../../../issara-sdk/apis/ChoiceView_core";
import UserProfileListView from "../../../issara-sdk/apis/UserProfileListView_users";
import V3FamilyRelationListView from "../../../issara-sdk/apis/V3FamilyRelationListView_apps_PRX";

// Interface
import { State as MainState } from "../../ManageInterface";

export type State = {
  loadingBabyRelationship?: boolean;
  babyRelationFilter?: { hn: string; limit: number; offset: number };
  familyOptions?: any[];
  relationPatient?: any;
  relationProfiles?: any[];
  selectedBabySegment?: any;
  babySegmentList?: any;
  babyList?: any;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedDivision"
>;

export const StateInitial: State = {
  loadingBabyRelationship: false,
  babyRelationFilter: { hn: "", limit: 10, offset: 0 },
  familyOptions: [],
  relationPatient: {},
  relationProfiles: [],
  selectedBabySegment: {},
  babySegmentList: {},
  babyList: {},
};

export type Event =
  // GET
  | { message: "handleGetOptions"; params: {} }
  | { message: "handleGetBabySegmentList" }
  | {
      message: "handleGetBabyPatientList";
      params: { keyword: string; limit: number; offset: number };
    }
  | { message: "handleGetProfileByUsername"; params: { index: number } }
  | { message: "handleGetFamilyRelation"; params: {} }
  // CHANGE
  | {
      message: "handleChangeBabyRelationFilter";
      params: { name: string; value: string };
    }
  | {
      message: "handleChangeRelationProfiles";
      params: { index: number; name: string; value: string };
    }
  | { message: "handleAddRelationProfiles"; params: {} }
  | { message: "handleRemoveRelationProfiles"; params: { index: number } }
  // SAVE
  | { message: "handleBabyCreatePatientSegment"; params: {} }
  | { message: "handleDeleteBabyPatientSegment"; params: {} }
  | { message: "handleCreateFamilyRelation"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetOptions: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const [response, error, network] = await ChoiceView.get({
    apiToken: controller.apiToken,
    params: { model: "PRX.UserAccessPatient", field: "family" },
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    familyOptions: CreateOptions(response?.items) || [],
  });
};

export const handleGetBabySegmentList: Handler = async (controller) => {
  controller.setState({ loadingBabyRelationship: true });

  const state = controller.getState();
  const [error, response] = await commonListPatientSegmentList(
    controller as any,
    {
      segment_type: SEGMENT_TYPE.CLINICAL,
      segment_group: state.selectedDivision?.division_code,
      result_contain: CHILD_RESULT,
      hn: state.babyRelationFilter?.hn,
      limit: state.babyRelationFilter?.limit,
      offset: state.babyRelationFilter?.offset,
    }
  );

  controller.setState({
    loadingBabyRelationship: false,
    errorMessage: error ? error : null,
    successMessage: null,
    babySegmentList: response,
  });
};

export const handleGetBabyPatientList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [error, response] = await commonListProxyPatientNoUser(
    controller as any,
    params // keyword, limit, offset
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    babyList: response,
  });
};

export const handleGetProfileByUsername: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  let result = [...(state.relationProfiles || [])];
  const [response, error, network] = await UserProfileListView.list({
    apiToken: Cookies.get("apiToken"),
    params: { username: result[params.index].username },
  });

  if (error) {
  } else if (response.total > 0) {
    result[params.index].profile = response.items[0].id;
    result[params.index].hn = response.items[0].hn;
    result[params.index].profile_name = response.items[0].fullname;
  } else {
    result[params.index].profile = null;
    result[params.index].hn = "";
    result[params.index].profile_name = "";
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    relationProfiles: result,
  });
};

export const handleGetFamilyRelation: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [response, error] = await V3FamilyRelationListView.list({
    apiToken: Cookies.get("apiToken"),
    params: { patient: params?.proxy_patient },
  });
  let patient_data: any = {};
  let profile_data: any[] = [];
  if (error) {
  } else {
    if (response?.total > 0) {
      patient_data = {
        ...response?.items[0]?.patient_data,
        proxy_patient: response?.items[0]?.patient_data?.patient,
        fullname: response?.items[0]?.patient_data?.patient_name,
        mom_name: response?.items[0]?.mom_name,
      };
      response?.items?.forEach((item: any) => {
        profile_data.push({
          ...item?.profile_data,
          profile: item?.profile_data?.id,
          relation: item?.relation,
          family: item?.family,
        });
      });
    } else {
      profile_data.push({ relation: "", family: "" });
      patient_data = { ...params };
    }
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    relationPatient: patient_data,
    relationProfiles: profile_data,
    selectedBabySegment: { ...params },
  });
};

// CHANGE
export const handleChangeBabyRelationFilter: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  await controller.setState({
    babyRelationFilter: {
      ...state.babyRelationFilter,
      [params.name]: params.value, // hn, limit, offset
    } as any,
  });

  if (["limit", "offset"].includes(params.name)) {
    handleGetBabySegmentList(controller);
  }
};

export const handleChangeRelationProfiles: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let result = [...(state.relationProfiles || [])];
  result[params.index][params.name] = params.value;
  controller.setState({ relationProfiles: result });
};

export const handleAddRelationProfiles: Handler = async (controller) => {
  const state = controller.getState();

  let result = [...(state.relationProfiles || [])];
  result.push({});
  controller.setState({ relationProfiles: result });
};

export const handleRemoveRelationProfiles: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let result = [...(state.relationProfiles || [])];
  result.splice(params.index, 1);
  controller.setState({ relationProfiles: result });
};

// SAVE
export const handleBabyCreatePatientSegment: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  if (state.selectedDivision?.profile?.feature !== CONFIG.CHILD_FEATURE) {
    controller.setState({
      errorMessage: `ต้องตั้งค่า Division feature เป็น ${CONFIG.CHILD_FEATURE}`,
      successMessage: null,
      loadingStatus: false,
    });
    return;
  }

  let successMessage: any = null;
  const [error, response] = await commonCreatePatientSegmentChild(
    controller as any,
    params
  );
  if (error) {
  } else {
    successMessage = "Create Patient Segment success.";
    handleGetBabySegmentList(controller);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: successMessage,
    babyList: {},
  });
};

export const handleDeleteBabyPatientSegment: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  if (params?.id) {
    const [error, response] = await commonDeletePatientSegment(
      controller as any,
      {
        id: params.id,
      }
    );
    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Delete Patient Segment success.";

      const state = controller.getState();
      await controller.setState({
        babyRelationFilter: {
          ...state.babyRelationFilter,
          limit: 10,
          offset: 0,
        } as any,
      });
      handleGetBabySegmentList(controller);
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
      selectedBabySegment: {},
    });
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "ID not found.",
      successMessage: null,
    });
  }
};

export const handleCreateFamilyRelation: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const data = {
    patient: state.relationPatient.proxy_patient,
    profile: [...(state.relationProfiles || [])],
  };

  let successMessage: any = null;
  let errorMessage: any = null;
  let checkData: any = true;

  for (let a = 0; a < (state.relationProfiles?.length || 0); a++) {
    if (state.relationProfiles?.[a]?.profile == null) {
      checkData = false;
    }
  }

  if (checkData) {
    const [response, error, network] = await V3FamilyRelationListView.create({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: data,
    });

    if (error) {
      errorMessage = "Please press enter on username.";
    } else {
      successMessage = "Save Family relation success.";

      handleGetBabySegmentList(controller);
      handleGetFamilyRelation(controller, state.selectedBabySegment);
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? errorMessage : null,
      successMessage: successMessage,
    });
  } else {
    errorMessage = "Please press enter on username.";
    controller.setState({
      loadingStatus: false,
      errorMessage: checkData ? null : errorMessage,
      successMessage: null,
    });
  }

  // controller.setState({
  //   errorMessage: checkData ? null : errorMessage,
  //   successMessage: successMessage,
  // });
};

// Utils
const CreateOptions = (items: any[]) => {
  const mapOption = (item: any, index: number) => ({
    key: index,
    text: item.label,
    value: item.id,
  });
  return items.map(mapOption);
};
