import React, { CSSProperties, useEffect, useState } from "react";
import moment from "moment";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import { Image, Dimmer, Loader } from "semantic-ui-react";
// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  convertToADDate,
  formatDate,
} from "../../MobClinicInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";

const styles = {
  DivHeader: {
    backgroundColor: "var(--theme-bg-color-dm, DFF5FC)",
    padding: "20px",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  HeaderColor: {
    fontSize: "27px",
    color: "#4F4F4F",
    fontWeight: "bold",
    paddingLeft: "15px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  } as CSSProperties,
  TextStatus: {
    paddingLeft: "40px",
    fontSize: "18px",
    color: "#000000",
  } as CSSProperties,
  DivIMG: {
    width: "163px",
    height: "111px",
    border: "1px solid #2ECCD7",
  } as CSSProperties,
};

type WebChildOutsideMedicineProps = {
  // function
  onEvent: any;
  // data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient?: any;
  outsideMedicines?: any;
};

const WebChildOutsideMedicineInitial: WebChildOutsideMedicineProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  outsideMedicines: {},
};

const WebChildOutsideMedicine: React.FC<WebChildOutsideMedicineProps> = (
  props
) => {
  const intl = useIntl();
  useEffect(() => {
    props.onEvent({ message: "handleGetOutsideMedicineDetail" });
  }, [props.selectedPatient]);

  console.log("selectedPatient", props.selectedPatient);

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="outsidemedicine.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/child/outsidemedicine.png"
        />
        <span style={styles.HeaderColor}>
          <FormattedMessage id="bplusClinicKey360" />
        </span>
      </div>

      <div className="sub-layout">
        {props.outsideMedicines?.items?.map((item: any) => (
          <div
            style={{ display: "flex", padding: "30px", alignItems: "center" }}
          >
            <div style={styles.DivIMG}>
              <Image
                height="111px"
                style={{ color: "var(--theme-icon-color-dm)" }}
                src={item?.data?.image || "images/detail/medicine.png"}
              />
            </div>
            <div style={styles.TextStatus}>
              <div style={{ paddingBottom: "5px" }}>
                {item?.data?.drug_name}
              </div>
              <div style={{ paddingBottom: "5px" }}>
                {item?.data?.administer}
              </div>
              <div style={{ paddingBottom: "5px" }}>
              {intl.formatMessage({id: "bplusClinicKey822"})}{" "}{item?.data?.recieveFrom}
              </div>
              <div>
                {intl.formatMessage({id: "bplusClinicKey821"})}{" "}
                {moment(item?.measure_date, `${BACKEND_DATE_FORMAT}`)
                  .locale("th")
                  .format("L")}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

WebChildOutsideMedicine.defaultProps = WebChildOutsideMedicineInitial;

export default React.memo(WebChildOutsideMedicine);
