import React, { Component, useEffect, useRef, useState, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Segment, Dimmer, Loader } from "semantic-ui-react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { FormattedMessage, injectIntl } from "react-intl";
import Cookies from "js-cookie";
import moment from "moment";

// UX
import ReceivingMedicineAddress from "bplus-lib/telephar/ReceivingMedicineAddress";

// Utils
import * as Util from "react-lib/utils/tsUtils";

interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  menuBar?: Component;
  controller: {
    getInvoice: ({
      apiToken,
      patientId,
      status,
    }: {
      apiToken: string;
      patientId?: string | number;
      status?: string;
    }) => [null | { items: any[] }, null | {}, null | {}];
    getInvoiceDetail: ({
      apiToken,
      invoiceId,
    }: {
      apiToken?: string;
      invoiceId?: string | number;
    }) => [null | Record<string, any>, null | {}, null | {}];
    postPaymentParameters: ({
      apiToken,
      invoiceId,
    }: {
      apiToken?: string;
      invoiceId?: string | number;
    }) => any;
    getInvoiceItemByItem: ({
      apiToken,
      invoiceId,
    }: {
      apiToken?: string;
      invoiceId?: string | number;
    }) => any;
  };
  addressController?: any;
  apiToken: string;
  intl: any;
  hospitalCode: string;
}

// Styles
const COLORS = {
  bg: "rgba(249, 251, 253, 1)",
};

const useStyles = makeStyles(() => ({}));

// -const MockData = {
//   id: 1,
//   total_price: "2,200",
//   date: "15/07/63",
//   time: "15 : 00",
// };

const PatientPaymentDetail = React.forwardRef((props: Props) => {
  const apiToken = Cookies.get("apiToken");

  const [invoiceDetail, setInvoiceDetail] = useState<
    {
      price?: string | number;
      edited?: string;
    } & { [key: string]: any }
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const [paymentRedirect, setPaymentRedirect] = useState<Record<string, any>>(
    {}
  );
  const [invoiceItem, setInvoiceItem] = useState<any[]>([]);
  const [shippingAddress, setShippingAddress] = useState<Record<
    string,
    any
  > | null>(null);
  const [organizationDetail, setOrganizationDetail] = useState<Record<
    string,
    any
  > | null>(null);

  // Ref
  const isMounted = useRef(true);

  useEffect(() => {
    if (props.match.params.id) {
      handleGetInvoiceDetail();
      handleGetInvoiceItem();
      handlePostPaymentParameters();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.match.params.id]);

  useEffect(() => {
    if (props.hospitalCode) {
      handleGetOrganizationDetail(props.hospitalCode);
    }
  }, [props.hospitalCode]);

  // Memo
  const pharmacyLocation = useMemo(() => {
    return organizationDetail?.default_drug_pickup_location || "-";
  }, [organizationDetail]);

  const handlePostPaymentParameters = async () => {
    setIsLoading(true);

    const [res] = await props.controller.postPaymentParameters({
      apiToken: props.apiToken ? props.apiToken : apiToken,
      invoiceId: props.match.params.id,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (res) {
        setPaymentRedirect(res);
      } else {
        setPaymentRedirect({});
      }
    }
  };

  const handleGetInvoiceDetail = async () => {
    setIsLoading(true);

    const [res] = await props.controller.getInvoiceDetail({
      apiToken: props.apiToken ? props.apiToken : apiToken,
      invoiceId: props.match.params.id,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (res) {
        setInvoiceDetail(res);

        handleGetShippingAddress(res.encounter);
      } else {
        setInvoiceDetail({});
      }
    }
  };

  const handleGetOrganizationDetail = async (code: string) => {
    setIsLoading(true);

    const res = await props.addressController?.prxManager?.getProxyOrganization(
      {
        apiToken: props.apiToken ? props.apiToken : apiToken,
        code,
      }
    );

    if (isMounted.current) {
      setIsLoading(false);

      if (res) {
        setOrganizationDetail(res[0]?.items?.[0] || null);
      } else {
        setOrganizationDetail({});
      }
    }
  };

  const handleGetInvoiceItem = async () => {
    setIsLoading(true);

    const [res] = await props.controller.getInvoiceItemByItem({
      apiToken: props.apiToken ? props.apiToken : apiToken,
      invoiceId: props.match.params.id,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (res) {
        setInvoiceItem(res.items);
      } else {
        setInvoiceItem([]);
      }
    }
  };

  const handleGetShippingAddress = async (encounterId: number) => {
    if (props.addressController) {
      const [res] = await props.addressController.getShippingAddress({
        apiToken: props.apiToken ? props.apiToken : apiToken,
        encounterId,
      });

      if (isMounted.current) {
        setShippingAddress(res?.items?.[0] || null);
      }
    }
  };

  const date = invoiceDetail.edited
    ? Util.convertToBEDate({
        date: moment(invoiceDetail.edited).format("DD/MM/YYYY"),
      })
    : "";
  const time = invoiceDetail.edited
    ? moment(invoiceDetail.edited).format("HH:mm")
    : "";

  const genPaymentForm = () => {
    return Object.keys(paymentRedirect).map((key) => {
      if (key === "endpoint" || key === "method") {
        return null;
      }
      return (
        <input
          key={key}
          type="hidden"
          name={key}
          value={paymentRedirect[key] ? paymentRedirect[key] : ""}
        />
      );
    });
  };

  const createInvoiceItem = () => {
    return invoiceItem.map((item) => {
      return (
        <tr
          key={item.id}
          style={{ display: "flex", paddingTop: "5px", paddingBottom: "5px" }}
        >
          <td style={{ flexGrow: 1 }}>{`${item.pricing_name}`}</td>
          {/*<td style={{whiteSpace: "nowrap", marginLeft: "5px", marginRight: "10px"}}>{`${item.pricing_quantity} ${item.pricing_unit}`}</td>*/}
          <td style={{ whiteSpace: "nowrap" }}>{`${Util.formatComma(
            item.price
          )} Baht`}</td>
        </tr>
      );
    });
  };

  console.log(invoiceDetail, "invoiceDetail", shippingAddress);

  return (
    <div
      className="patient-payment-detail"
      style={{ backgroundColor: COLORS.bg }}
    >
      {props.menuBar}
      <Dimmer.Dimmable>
        <Segment>
          <div className="text-content">
            {props.intl.formatMessage({ id: "payment.total_balance" })}
          </div>
          <h1 className="text-content price">{`${
            invoiceDetail.price
              ? Util.formatComma(invoiceDetail.price.toString())
              : ""
          } ${props.intl.formatMessage({ id: "payment.baht" })}`}</h1>
          <div className="date-time-wrapper">
            <div className="text-content">
              {props.intl.formatMessage({ id: "common.date" })} <br />{" "}
              <h4>{date}</h4>
            </div>
            <div className="text-content">
              {props.intl.formatMessage({ id: "common.time" })} <br />{" "}
              <h4>{time}</h4>
            </div>
          </div>
        </Segment>
        <Segment>
          <table style={{ lineHeight: 1.5, width: "100%" }}>
            {createInvoiceItem()}
            <tr
              style={{
                borderTop: "1px solid gray",
                display: "flex",
                paddingTop: "5px",
              }}
            >
              <td style={{ flexGrow: 1 }}>
                {props.intl.formatMessage({ id: "common.total" })}
              </td>
              <td>
                {Util.formatComma((invoiceDetail.price || "")?.toString())}{" "}
                {props.intl.formatMessage({ id: "payment.baht" })}
              </td>
            </tr>
          </table>
        </Segment>

        {shippingAddress && (
          <ReceivingMedicineAddress
            shippingAddress={shippingAddress}
            pharmacyLocation={pharmacyLocation}
          />
        )}

        {/* <Segment>
        <a>Download เอกสาร</a>
      </Segment> */}
        <Dimmer active={isLoading} inverted>
          <Loader>
            <FormattedMessage id="bplusClinicKey298" />
          </Loader>
        </Dimmer>
      </Dimmer.Dimmable>

      <form
        method={paymentRedirect["method"]}
        action={paymentRedirect["endpoint"]}
      >
        {genPaymentForm()}
        {invoiceDetail.status_name !== "CANCELED" && invoiceDetail.price && (
          <div className={`button-submit-bottom`}>
            <MuiButton
              variant="contained"
              color="primary"
              type="submit"
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                borderRadius: 0,
                padding: "0.75rem 0 2.35rem",
              }}
            >
              {invoiceDetail.status_name === "PAID"
                ? `${props.intl.formatMessage({ id: "payment.view_receipt" })}`
                : `ดำเนินการชำระเงิน`}
            </MuiButton>
          </div>
          // <Button
          //   type="submit"
          //   className="pay-btn"
          //   fluid
          //   content={
          //     invoiceDetail.status_name === "PAID"
          //       ? `${props.intl.formatMessage({ id: "payment.view_receipt" })}`
          //       : `${props.intl.formatMessage({ id: "payment.pay" })}`
          //   }
          //   disabled={isLoading}
          // />
        )}
      </form>
    </div>
  );
});

export default React.memo(
  injectIntl(PatientPaymentDetail, { forwardRef: true })
);
