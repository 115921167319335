import React, { useEffect, useState, CSSProperties } from "react";
import moment from "moment";
import { Button, Dropdown, Grid, Input } from "semantic-ui-react";
import { beToAd, adToBe } from "../../../react-lib/utils";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

// UI
import { DateTextBox } from "../../../react-lib/apps/common";
import PureReactTable from "../../../react-lib/component-js/common/PureReactTable";
import * as MedicationConst from "./WebDMMedication";
import { Loader, Dimmer } from "semantic-ui-react";

const styles = {
  flexVerticalAlignCenter: {
    alignSelf: "center",
  } as CSSProperties,
  centerReplacement: {
    alignSelf: "center",
    textAlign: "center",
  } as CSSProperties,
};

type WebDMMedicationTrackcareProps = {
  // Options
  medDivisionTKOptions?: any[];
  medDoctorTKOptions?: any[];
  // data
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medicationTrackcare: any;
  medicationTrackcareFilter: any[];
  selectedPatient: any;
  loadingMedication: boolean;
  // function
  onEvent?: any;
  onSearchDivision?: any;
  onSearchDoctor?: any;
};

const WebDMMedicationTrackcareInitial: WebDMMedicationTrackcareProps = {
  // Options
  medDivisionTKOptions: [],
  medDoctorTKOptions: [],
  // data
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medicationTrackcare: {},
  medicationTrackcareFilter: [],
  selectedPatient: {},
  loadingMedication: false,
  // function\
  onEvent: () => {},
  onSearchDivision: () => {},
  onSearchDoctor: () => {},
};

const WebDMMedicationTrackcare: React.FC<WebDMMedicationTrackcareProps> = (
  props
) => {
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [filterBy, setFilterBy] = useState<any>({
    start: "",
    end: "",
    drugName: "",
    clinic: "",
    doctor: "",
  });

  const patientColumn = [
    {
      Header: "",
      width: 100,
      Cell: (row: any) => {
        if (row?.original?.active) {
          return (
            <Button
              fluid
              color="orange"
              onClick={() => {
                handleChangeActive(row, false);
              }}
            >
              <FormattedMessage id="bplusClinicKey420" />
            </Button>
          );
        } else {
          return (
            <Button
              fluid
              disabled={row?.original?.is_expired || false}
              color="green"
              onClick={() => {
                handleChangeActive(row, true);
              }}
            >
              <FormattedMessage id="bplusClinicKey39" />
            </Button>
          );
        }
      },
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      width: 100,
      style: styles.centerReplacement,
    },
    {
      Header: "Drug name",
      width: 400,
      style: styles.flexVerticalAlignCenter,
      Cell: (row: any) => (
        <div>
          {row.original.name}
          {row.original.note.medication}
        </div>
      ),
    },
    {
      Header: "Clinic",
      accessor: "clinic",
      width: 250,
      style: styles.centerReplacement,
    },
    {
      Header: "Doctor",
      accessor: "doctor",
      width: 250,
      style: styles.centerReplacement,
    },
  ];

  useEffect(() => {
    props.onEvent({ message: "getAllTrackCareDrug", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    setCurrentItems(props.medicationTrackcareFilter);
  }, [props.medicationTrackcareFilter]);

  // search
  const handleChangeStartDate = (date: string) => {
    setFilterBy({ ...filterBy, start: date });
  };

  const handleChangeEndDate = (date: string) => {
    setFilterBy({ ...filterBy, end: date });
  };

  const handleChangeFilter = (event: any, data: any) => {
    setFilterBy({ ...filterBy, [data.name]: data.value });
  };

  const handleSearch = () => {
    props.onEvent({
      message: "filterMedicationTrackCare",
      params: {
        ...filterBy,
        start: filterBy.start
          ? beToAd(filterBy.start)?.format("YYYY-MM-DD")
          : "",
        end: filterBy.end ? beToAd(filterBy.end)?.format("YYYY-MM-DD") : "",
        showType: "medicationTrackcareFilter",
      },
    });
  };
  // action
  const handleChangeActive = (row: any, active: boolean) => {
    props.onEvent({
      message: "updateTrackcareMedication",
      params: {
        action: active ? "ADD_TRACKCARE" : "REMOVE_TRACKCARE",
        medId: row.original.id,
      },
    });
    // let items = [...currentItems];
    // items[row.index] = { ...items[row.index], active: active };
    // setCurrentItems(items);
  };

  const handleRemoveAll = () => {
    props.onEvent({
      message: "updateTrackcareMedication",
      params: { action: "REMOVE_ALL" },
    });
    // let items = [...currentItems];
    // items.forEach((item: any) => {
    //   item.active = false;
    // });
    // setCurrentItems(items);
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  return (
    <div className="sub-layout dm">
      <Dimmer active={props.loadingMedication} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <Grid verticalAlign="middle" style={{ paddingBottom: "35px" }}>
        <Grid.Row>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8} style={{ textAlign: "right" }}>
            <Button color="orange" size="large" onClick={handleRemoveAll}>
              <FormattedMessage id="bplusClinicKey421" />
            </Button>
            <Button
              color="blue"
              size="large"
              onClick={() => {
                props.onEvent({
                  message: "saveMedicationTrackCare",
                  params: { page: "trakcareList" },
                });
              }}
            >
              <FormattedMessage id="bplusClinicKey437" />
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={7}></Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey165" />
          </Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey114" />
          </Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey160" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={2}>
            <FormattedMessage id="bplusClinicKey356" />
          </Grid.Column>
          <Grid.Column width={2} style={MedicationConst.styles.noPadding}>
            <DateTextBox
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              maxDate={adToBe(moment())}
              value={filterBy?.start}
              onChange={handleChangeStartDate}
            />
          </Grid.Column>
          <Grid.Column width={1} style={{ textAlign: "right" }}>
            <FormattedMessage id="bplusClinicKey489" />
          </Grid.Column>
          <Grid.Column width={2} style={MedicationConst.styles.noPadding}>
            <DateTextBox
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              maxDate={adToBe(moment())}
              value={filterBy?.end}
              onChange={handleChangeEndDate}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Input
              name="drugName"
              text={filterBy?.drugName}
              onChange={handleChangeFilter}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Dropdown
              selection
              search
              name="clinic"
              loading={props.loadingDivisionOptions}
              options={props.medDivisionTKOptions}
              value={filterBy?.clinic}
              onSearchChange={(_event: any, data: any) => {
                if (data.searchQuery.length > 2) {
                  props.onSearchDivision("TK", data.searchQuery);
                }
              }}
              onChange={handleChangeFilter}
            />
          </Grid.Column>{" "}
          <Grid.Column width={3}>
            <Dropdown
              selection
              search
              name="doctor"
              loading={props.loadingDoctorOptions}
              options={props.medDoctorTKOptions}
              value={filterBy?.doctor}
              onSearchChange={(_event: any, data: any) => {
                if (data.searchQuery.length > 2) {
                  props.onSearchDoctor('TK',data.searchQuery);
                }
              }}
              onChange={handleChangeFilter}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={MedicationConst.styles.noPadding}>
          <Grid.Column floated="left" width={14}></Grid.Column>
          <Grid.Column
            width={2}
            style={{ ...MedicationConst.styles.noPadding, textAlign: "right" }}
          >
            <Button color="green" size="large" onClick={handleSearch}>
              <FormattedMessage id="bplusClinicKey440" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <PureReactTable
        // @ts-ignore
        className="weight-table-custom web-mom"
        // selectedRow={props.selectedPatient}
        data={currentItems}
        columns={patientColumn}
        showPagination={false}
        pageSize={50}
        // onSelect={handleSelectedPatient}
        manual
        style={{ height: "500px" }}
        getTheadThProps={handleGetTheadThProps}
      />
    </div>
  );
};

WebDMMedicationTrackcare.defaultProps = WebDMMedicationTrackcareInitial;

export default React.memo(WebDMMedicationTrackcare);
