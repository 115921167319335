import React, { useEffect, useState, useMemo, CSSProperties } from "react";

import { FormattedMessage } from "react-intl";
import {
  Dropdown,
  Grid,
  Image,
  Table,
  Search,
  Dimmer,
  Loader,
  Menu,
  Icon,
} from "semantic-ui-react";

import "../../css/WebClinic.scss";
import moment from "moment";
import HospitalDropDown from "./HospitalDropDown";
import { Bar } from "react-chartjs-2";
import { prepareDataDraw } from "../clinic/DMBloodPressure";
// import { Table } from '../../react-lib/frameworks/Table';
// UI
import PureReactTable from "../../react-lib/component-js/common/PureReactTable";

//type
import { MarketingAnalyticsType } from "../post/MarketingAnalyticsInterface";

type MarketingAnalyticsPersonalProps = {
  // function
  onEvent: any;
  handleSearchPatient?: (type: any, value: any, offset: any, limit: any) => any;
  handleSelectHospital?: any;
  // data
  organizationList?: any[];
  selectedOrganizationCode?: any;
  loadingStatus?: boolean;
  loadingMarketingPatient?: boolean;
  loadingPatientAnalytics?: boolean;
  marketingAnalytics?: MarketingAnalyticsType;
  analyticPatientList?: any;
  patientAnalyticsDetail?: any;
  segmentAnalytics?: any;
};

type UserPreviewProps = {
  patientAnalyticsDetail?: any;
  loadingPatientAnalytics?: any;
  marketingAnalytics?: any;
  selectUser?: any;
};

type BarChartProps = {
  mostUsed: any;
};

const ICONS = {
  time: "/images/expansion/icon-time.png",
  tag: "/images/expansion/icon-tag.png",
};

const COLORS = {
  primary: "var(--primary-theme-color)",
  super_ligtblue: "#CCDAED",
  gary: "#C8CBD9",
  black: "black",
  purple: "#422F8ADE",
  blue: "#0072BC",
  gary_text: "#797878",
};

const styles = {
  title: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    color: COLORS.primary,
    fontSize: "18px",
    textAlign: "center",
    padding: "10px 0",
  } as CSSProperties,
  title_section: {
    fontSize: "14px",
    color: COLORS.primary,
    marginLeft: "8px",
  } as CSSProperties,
  title_normal: {
    display: "flex",
    justifyContent: "end",
    fontSize: "14px",
    color: COLORS.primary,
  } as CSSProperties,
  set_center: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  tag_items: {
    display: "flex",
    gap: "5px",
    flexWrap: "wrap",
  } as CSSProperties,
  header_table: {
    background: COLORS.blue,
    color: "white",
    margin: "0",
    fontSize: "16px",
    fontWeight: "bold",
  } as CSSProperties,
  tag_item: {
    display: "flex",
    alignItems: "center",
    background: COLORS.super_ligtblue,
    alignContent: "center",
    padding: "2px 10px",
    color: COLORS.primary,
    borderRadius: "5px",
    fontSize: "14px",
  } as CSSProperties,
  section_margin: {
    display: "flex",
    margin: "15px 0 5px",
  } as CSSProperties,
};

const LIST_SORT = [
  { key: "hn", text: "HN", value: "hn" },
  { key: "gender", text: "Gender", value: "gender" },
  { key: "age", text: "Age", value: "age" },
  { key: "lastLogin", text: "Last login", value: "lastLogin" },
];

const MarketingAnalyticsPersonal: React.FC<MarketingAnalyticsPersonalProps> = (
  props: any
) => {
  const [sortBy, setSortBy] = useState<string>("hn");
  const [keyWordSearch, setKeyWordSearch] = useState<string>("");
  const [selectUser, setSelectUser] = useState<any>({});
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [directionTable, setDirectionTable] = useState<any>(null);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [limit, setLimit] = useState<any>(20);
  const [offset, setOffset] = useState<any>(0);
  const [keyWord, setKeyWord] = useState<any>("");
  const [rememberTotal, setRememberTotal] = useState<any>(0);
  const [resultSearch, setResultSearch] = useState<any>({
    type: "",
    value: "",
  });
  const [skipIndex, setSkipIndex] = useState(0);
  const dateFormateYYYYMMDD = "YYYYMMDD"
  const dateFormateDDMMYYYY = "DD/MM/YYYY"

  const handleSearchData = (search: any) => {
    setKeyWordSearch(search);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        handleCheckSearch(search);
        setKeyWord(search);
      }, 600)
    );
  };

  const handleSelectUser = (item: any) => {
    props.onEvent({
      message: "GetPatientAnalytics",
      params: { patientId: item?.proxy_patient || 0 },
    });
    setSelectUser(item);
  };

  const handleCheckSearch = (search: any) => {
    //fun param  handleSearchPatient(type: any, value: any, offset: any, limit: any) => any;
    if (search !== "") {
      // if (isNaN(Number(search))) {
      //   if (search.search("-") !== -1) {
      //     const dataTableCloneFilter = dataTable.filter(
      //       (item: any) => `${item.hn}`.search(search.toLowerCase()) !== -1
      //     );
      //     if (dataTableCloneFilter.length === 0) {
      //       props.handleSearchPatient("hn", search, 0, limit);
      //       setResultSearch({ type: "hn", value: search });
      //     }
      //   } else {
      //     const dataTableCloneFilter = dataTable.filter(
      //       (item: any) =>
      //         `${item.fullname}`.search(search.toLowerCase()) !== -1
      //     );
      //     if (dataTableCloneFilter.length === 0) {
      //       props.handleSearchPatient("name", search, 0, limit);
      //       setResultSearch({ type: "name", value: search });
      //     }
      //   }
      // } else {
      //   // check จาก table ก่อนไปดึงจากหลังบ้าน //
      //   const dataTableCloneFilter = dataTable.filter(
      //     (item: any) =>
      //       `${item.citizen_passport}`.search(search.toLowerCase()) !== -1
      //   );
      //   if (dataTableCloneFilter.length === 0) {
      //     props.handleSearchPatient("cid", search, 0, limit);
      //     setResultSearch({ type: "cid", value: search });
      //   }
      // }

      if (search.search("-") !== -1) {
        const dataTableCloneFilter = dataTable.filter(
          (item: any) => `${item.hn}`.search(search.toLowerCase()) !== -1
        );
        if (dataTableCloneFilter.length === 0) {
          props.handleSearchPatient("hn", search, 0, limit);
          setResultSearch({ type: "hn", value: search });
        }
      } 
    } else {
      props.handleSearchPatient("", "", "", "");
      setResultSearch({ type: "", value: search });
    }
  };

  const table = useMemo(() => {
    const dataTableClone = dataTable;
    if (directionTable) {
      switch (sortBy) {
        case "hn": {
          dataTableClone.sort((a: any, b: any) =>
            directionTable === "ascending"
              ? a.hn > b.hn
                ? 1
                : -1
              : a.hn < b.hn
              ? 1
              : -1
          );
          break;
        }
        case "age": {
          dataTableClone.sort((a: any, b: any) =>
            directionTable === "ascending"
              ? a.age > b.age
                ? 1
                : -1
              : a.age < b.age
              ? 1
              : -1
          );
          break;
        }
        case "gender": {
          dataTableClone.sort((a: any, b: any) =>
            directionTable === "ascending"
              ? a.gender_name > b.gender_name
                ? 1
                : -1
              : a.gender_name < b.gender_name
              ? 1
              : -1
          );
          break;
        }
        case "lastLogin": {
          dataTableClone.sort((a: any, b: any) =>
            directionTable === "ascending"
              ? moment(a.lastLogin,dateFormateDDMMYYYY).format(dateFormateYYYYMMDD) > moment(b.lastLogin,dateFormateDDMMYYYY).format(dateFormateYYYYMMDD)
                ? 1
                : -1
              : moment(a.lastLogin,dateFormateDDMMYYYY).format(dateFormateYYYYMMDD) < moment(b.lastLogin,dateFormateDDMMYYYY).format(dateFormateYYYYMMDD)
              ? 1
              : -1
          );
          break;
        }
        default: {
          break;
        }
      }
    }

    let dataTableCloneFilter: any = {};
    dataTableCloneFilter = dataTableClone;

    if (keyWord !== "") {
      if (isNaN(Number(keyWord))) {
        if (keyWord.search("-") !== -1) {
          dataTableCloneFilter = dataTable.filter(
            (item: any) => `${item.hn}`.search(keyWord.toLowerCase()) !== -1
          );
        } else {
          dataTableCloneFilter = dataTable.filter(
            (item: any) =>
              `${item.fullname}`.search(keyWord.toLowerCase()) !== -1
          );
        }
      } else {
        dataTableCloneFilter = dataTable.filter(
          (item: any) =>
            `${item.citizen_passport}`.search(keyWord.toLowerCase()) !== -1
        );
      }
    }

    return [...dataTableCloneFilter];
  }, [sortBy, directionTable, dataTable, keyWord]);

  const pageTotal = useMemo(() => {
    const dataTableClone = props.analyticPatientList;
    const rowTotal = dataTableClone?.total / limit;
    const pageArray = Array.from(
      { length: rowTotal < 1 ? 1 : Math.ceil(rowTotal) },
      (_, i) => i + 1
    );
    return pageArray;
  }, [props.analyticPatientList]);

  const barArray = useMemo(() => {
    let sliceArray = (list: any[]) => list.slice(skipIndex, 10 + skipIndex);
    return pageTotal.length < 11 ? pageTotal : sliceArray(pageTotal);
  }, [pageTotal, skipIndex]);

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 10);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 10);
    }
  };

  useEffect(() => {
    if (props.analyticPatientList?.items?.length > 0) {
      const userData = props.analyticPatientList.items;
      const editedUserData = userData.map((item: any) => {
        return {
          ...item,
          age: item.full_age.split(" ")?.[0] || item.full_age,
          lastLogin: moment(item.edited_at).format(dateFormateDDMMYYYY),
        };
      });

      if (rememberTotal !== props.analyticPatientList.total) {
        setRememberTotal(props.analyticPatientList.total);
        setOffset(0);
        setSkipIndex(0);
      }

      setDataTable(editedUserData);
      handleSelectUser(userData[0]);
    } else {
      setDataTable([]);
      handleSelectUser({});
    }
  }, [props.analyticPatientList]);

  useEffect(() => {
    setKeyWordSearch("");
    setResultSearch({ type: "", value: "" });
  }, [props.segmentAnalytics]);

  const handleSortTable = (type: any) => {
    setDirectionTable(
      type !== sortBy
        ? "ascending"
        : directionTable === "ascending"
        ? "descending"
        : "ascending"
    );
    setSortBy(type);
  };

  const handleSelectedPage = (item: number) => {
    if (offset !== item) {
      setOffset(item);
      props.handleSearchPatient(
        resultSearch.type !== "" ? resultSearch.type : "select",
        resultSearch.value,
        item * limit,
        limit
      );
    }
  };

  // const patientColumn = useMemo(
  //   () => [
  //     { Header: "ID Card No.", accessor: "citizen_passport", minWidth: 170 },
  //     { Header: "HN", accessor: "hn", minWidth: 120 },
  //     { Header: "Name", accessor: "fullname", minWidth: 220 },
  //     { Header: "Age", accessor: "age", minWidth: 80 },
  //     { Header: "Gender", accessor: "gender_name", minWidth: 80 },
  //     { Header: "Last Login", accessor: "lastLogin", minWidth: 100 },
  //   ],
  //   []
  // );

  return (
    <div style={{ position: "relative" }}>
      <Dimmer active={props.loadingMarketingPatient} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HospitalDropDown
            organizationList={props.organizationList}
            selectedOrganizationCode={props.selectedOrganizationCode}
            handleSelectHospital={props.handleSelectHospital}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 425px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 20px 0",
            }}
          >
            {/********************* Search Bar ********************/}

            <div className="analytics-search-personal">
              <Search
                input={{ icon: "search", iconPosition: "left" }}
                className="search-segment"
                onSearchChange={(e, { value }) => {
                  handleSearchData(value);
                }}
                placeholder="Search HN"
                showNoResults={false}
                value={keyWordSearch}
              />
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingRight: "10px",
                }}
              >
                Sort By
              </div>
              <div className="button-dropdown-light">
                <Dropdown
                  options={LIST_SORT}
                  value={sortBy}
                  icon={"chevron down"}
                  onChange={(e, value) => handleSortTable(value.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 425px",
          height: "calc(100vh - 200px)",
          overflowY: "hidden",
          position: "relative",
        }}
      >
        {/************************ Main Table  ********************************/}

        <div className="table-personal">
          <Table sortable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell>ID Card No.</Table.HeaderCell> */}
                <Table.HeaderCell
                  sorted={sortBy === "hn" ? directionTable : null}
                  onClick={() => handleSortTable("hn")}
                >
                  HN
                </Table.HeaderCell>
                {/* <Table.HeaderCell colSpan="2">Name</Table.HeaderCell> */}
                <Table.HeaderCell
                  sorted={sortBy === "age" ? directionTable : null}
                  onClick={() => handleSortTable("age")}
                >
                  Age
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortBy === "gender" ? directionTable : null}
                  onClick={() => handleSortTable("gender")}
                >
                  Gender
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortBy === "lastLogin" ? directionTable : null}
                  onClick={() => handleSortTable("lastLogin")}
                >
                  Last Login
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {table &&
                table.map((item: any, index: any) => (
                  <Table.Row
                    key={index}
                    onClick={() => handleSelectUser(item)}
                    style={{
                      background: item.hn === selectUser.hn ? "#CCDAEDE5" : "",
                    }}
                  >
                    {/* <Table.Cell>{item.citizen_passport || "-"}</Table.Cell> */}
                    <Table.Cell>{item.hn || "-"}</Table.Cell>
                    {/* <Table.Cell colSpan="2">{item.fullname || "-"}</Table.Cell> */}
                    <Table.Cell>{item.age || "-"}</Table.Cell>
                    <Table.Cell>{item.gender_name || "-"}</Table.Cell>
                    <Table.Cell>{item.lastLogin}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Menu floated="right" pagination>
                    <Menu.Item
                      as="a"
                      icon
                      onClick={() => handleMoveGraph("left")}
                      style={{ display: Boolean(!skipIndex) ? "none" : "" }}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    {barArray &&
                      barArray.map((item: any) => (
                        <Menu.Item
                          as="a"
                          onClick={() => handleSelectedPage(item - 1)}
                          style={{
                            color: offset === item - 1 ? "white" : "",
                            background: offset === item - 1 ? COLORS.blue : "",
                          }}
                        >
                          {item}
                        </Menu.Item>
                      ))}
                    <Menu.Item
                      as="a"
                      icon
                      onClick={() => handleMoveGraph("right")}
                      style={{
                        display:
                          pageTotal.slice(11 + skipIndex).length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>

        {/* <div className="table-personal" style={{ height: "calc(100vh - 320px)"}}>
        <PureReactTable
          // @ts-ignore
          selectedRow={{}}
          onSelect={handleSelectUser}
          data={table}
          columns={patientColumn}
          showPagination={true}
          pageSize={limit}
          pages={Math.ceil(
            props.analyticPatientList?.total /
              limit
          )}
          page={
            offset /
            limit
          }
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          loading={props.loadingMarketingPatient}
          manual
        />
        </div> */}

        <div>
          <UserPreview
            patientAnalyticsDetail={props.patientAnalyticsDetail}
            loadingPatientAnalytics={props.loadingPatientAnalytics}
            marketingAnalytics={props.marketingAnalytics}
            selectUser={selectUser}
          />
        </div>
      </div>
    </div>
  );
};

const UserPreview: React.FunctionComponent<UserPreviewProps> = (props) => {
  const [dataUser, setDataUser] = useState<any>({});

  useEffect(() => {
    if (props.patientAnalyticsDetail != null) {
      setDataUser(props.patientAnalyticsDetail);
    } else {
      setDataUser({});
    }
  }, [props.patientAnalyticsDetail, props.selectUser]);

  return (
    <div className="PostPreview">
      <Dimmer active={props.loadingPatientAnalytics} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <div
        className="PostHeader-mobilePreview"
        style={{
          display: "flex",
          flexDirection: "column",
          top: "0",
          height: "50px",
          background: COLORS.primary,
        }}
      >
        <div> {props.selectUser.fullname} </div>
        <div>
          {`last login ${moment(props.selectUser.edited_at).format(
            "DD/MM/YYYY HH:mm"
          )} `}
        </div>
      </div>
      <div className="PostBox-main">
        <div
          className="PostBox-mobilePreview"
          style={{
            height: "100%",
          }}
        >
          <div className="PostBox-background">
            {/********************* Info User ********************/}

            <Grid>
              <Grid.Row style={{ padding: "10px 15px 10px 10px" }}>
                <Grid.Column width={2} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.click_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Clicks"}</div>
                </Grid.Column>
                <Grid.Column width={2} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.like_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Like"}</div>
                </Grid.Column>
                <Grid.Column width={3} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.share_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Shares"}</div>
                </Grid.Column>
                <Grid.Column width={2} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.save_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Saved"}</div>
                </Grid.Column>
                <Grid.Column width={4} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.appointment_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Appointment"}</div>
                </Grid.Column>
                <Grid.Column width={3} style={{ padding: "0px" }}>
                  <div style={styles.title_normal}>
                    {dataUser.purchase_counts || 0}
                  </div>
                  <div style={styles.title_normal}>{"Purchase"}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          {/************************ BarChart  ********************************/}

          <div style={styles.section_margin}>
            <Image src={ICONS.time} style={{ width: "17px", height: "17px" }} />
            <div style={styles.title_section}>{"Most Used"}</div>
          </div>
          <div className="PostBox-background">
            <BarChart mostUsed={dataUser.most_used} />
          </div>
          <div style={styles.section_margin}>
            <Image src={ICONS.tag} style={{ width: "17px", height: "17px" }} />
            <div style={styles.title_section}>{"Most Visit tag"}</div>
          </div>

          {/******************************* Tag  ********************************/}

          <div className="PostBox-background">
            <div style={styles.tag_items}>
              {dataUser.most_tag &&
                dataUser.most_tag.map((item: any) => (
                  <div style={styles.tag_item}>{item}</div>
                ))}
            </div>
          </div>
          <div style={styles.section_margin}>
            <Image src={ICONS.tag} style={{ width: "17px", height: "17px" }} />
            <div style={styles.title_section}>{"Most Search"}</div>
          </div>
          <div className="PostBox-background">
            <div style={styles.tag_items}>
              {/* {!tag &&
                tag.map((item: any) => (
                  <div style={styles.tag_item}>{item}</div>
                ))} */}
            </div>
          </div>
          <div style={styles.section_margin}>
            <Image src={ICONS.tag} style={{ width: "17px", height: "17px" }} />
            <div style={styles.title_section}>{"Purchase history"}</div>
          </div>
          <div className="PostBox-background">
            <div style={styles.tag_items}>
              {/* {!tag &&
                tag.map((item: any) => (
                  <div style={styles.tag_item}>{item}</div>
                ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// ---------------------- BarChart ------------------------
const BarChart: React.FunctionComponent<BarChartProps> = (props) => {
  const [maxDataSet, setMaxDataSet] = useState<number>(60);
  const [minDataSet, setMinDataSet] = useState<number>(0);
  const [summaryTime, setSummaryTime] = useState<string>("");

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          padding: "30",
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          min: minDataSet,
          max: maxDataSet,
          gridLines: {
            display: true,
            drawTicks: false,
          },
          ticks: {
            userCallback: function (label: any, index: any, labels: any) {
              return `${label.toFixed(0)} m`;
            },
            padding: 5,
            beginAtZero: true,
            stepSize: (maxDataSet < 1? 2 : maxDataSet) /2,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  const barLeague = [
    {
      color: "#7F7FD5",
      name: "Appointment",
    },
    {
      color: "#CCDAED",
      name: "Post & Promotion",
    },
  ];

  const plugins = useMemo(() => {
    const top = 0;
    const radius = 15;
    const allowIndex = [0, 1];
    const allowStack = [2];
    const hideFillText = false;
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          const ctx = chart.ctx;
          const x = chart.chartArea.left;
          const topY = 0;
          const bottomY = chart.chartArea.bottom;
          const rightY = chart.chartArea.right;

          // draw line
          if (ctx) {
            ctx.save();
            //  bottom line
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#C4C4C4";
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, bottomY);
            ctx.lineTo(rightY, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#C4C4C4";
            ctx.stroke();
            ctx.restore();
          }
        },

        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          prepareDataDraw({
            chart,
            allowIndex,
            allowStack,
          });
        },
      },
    ];
  }, []);

  const chartData = useMemo(() => {
    let labels: any[] = [];
    let dataSetAppointment: any[] = [];
    let dataSetPost: any[] = [];
    const checkMax: any[] = [];
    let summaryTime = 0;
    if (props?.mostUsed != null) {
      const mostUsed = Object.entries(props?.mostUsed || {});
      mostUsed.forEach(([key, value], index: any) => {
        const valueItem: any = value;
        dataSetAppointment.push(valueItem.appointment || 0);
        dataSetPost.push(valueItem.postandpromotion || 0);
        checkMax.push(valueItem.appointment + valueItem.postandpromotion || 0);
        labels.push(index+1);
        summaryTime += valueItem.appointment + valueItem.postandpromotion;
      });
    } else {
      labels = [1, 2, 3, 4];
      dataSetAppointment = [0, 0, 0, 0];
      dataSetPost = [0, 0, 0, 0];
    }

    const maxValue = Math.max(...checkMax);
    setMaxDataSet(maxValue);

    const nowMonth = moment().locale("en").format("MMMM YYYY");
    const summary = `${Math.floor(summaryTime / 60)}`.split(".");
    setSummaryTime(`${nowMonth} ( ${summary[0]}h ${summary[0]}m )`);

    const datasets = [
      {
        label: "Post",
        backgroundColor: "#CCDAED",
        stack: "Stack 0",
        data: dataSetPost,
      },
      {
        label: "Appointment",
        backgroundColor: "#7F7FD5",
        stack: "Stack 0",
        data: dataSetAppointment,
      },
    ];

    return {
      labels: labels,
      datasets: datasets,
    };
  }, [props.mostUsed]);

  return (
    <div>
      <div
        style={{
          ...styles.title_normal,
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <div>{summaryTime}</div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "65%", position: "relative" }}>
          <Bar
            options={options}
            data={chartData}
            plugins={plugins}
            redraw={true}
            width={280}
            height={150}
          />
          <div
            style={{
              position: "absolute",
              fontSize: "12px",
              color: COLORS.gary_text,
              bottom: "20px",
              right: "-35px",
            }}
          >
            Week
          </div>
        </div>
        <div style={{ width: "35%", position: "relative" }}>
          <div style={{ position: "absolute" }}>
            {barLeague &&
              barLeague.map((item: any) => (
                <div className="BarLabel-BarChart" style={{ border: "0px" }}>
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: "10px",
                      height: "10px",
                    }}
                  ></div>
                  <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                    {item.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MarketingAnalyticsPersonal);
