import React, { useEffect, MutableRefObject, useRef, useState } from "react";
import { Icon, Divider, Button, Image } from "semantic-ui-react";
import {
  makeStyles,
  Avatar,
  Button as MuiButton,
  Radio,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Dialog, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

// UI
import PullToRefreshtest from "./PulltoRefresh";

// Interface
import { USE_LOCAL_STORAGE } from "./SelectHospitalInterface";

import { useIntl } from "react-intl";

type SelectHospitalProps = {
  onEvent?: (e: any) => any;
  hospitalFilterOptions?: any[];
  // selectedHospitalFilter?: any;
  textSearch?: string;
  loadingSearch?: boolean;
  hospitalList?: any[];
  firstLoadingHospital?: boolean;
  history: any;
  selectedHospital?: any;
  className?: string;
  closeIcon?: boolean;
  onClose?: (selectedValue?: any) => any;
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "var(--blue-bdms-color)",
  light_blue: "var(--primary-bg-color-light)",
  medium_blue: "var(--location-topbar-theme)",
  yellow: "var(--warning)",
  dark_grey: "var(--secondary-font-color)",
  orange: "var(--orange)",
  background: "var(--primary-bg-color)",
  gray: "var(--super-dark-gray)",
};

const ICON = {
  location: "/images/Feed/location-outline.png",
  bplus: "/images/expansion/logo_48.png",
};

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: "8px",
    color: COLOR.blue,
    fontWeight: "bold",
    width: "60px",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    "& .visible.menu": {
      left: "-13px",
      top: "calc(100% + 7px)",
      background: COLOR.background,
      "& .active.selected.item": {
        color: COLOR.blue + " !important",
      },
      "& .selected.item": {
        color: COLOR.dark_grey + " !important",
      },
    },
    "& i": {
      position: "absolute !important",
      right: "-15px !important",
    },
  },
  box_filter: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 5px",
    alignItems: "center",
  },
  title: {
    color: COLOR.gray,
    margin: "15px 0px -5px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  box_list: {
    display: "grid",
    gridTemplateColumns: "40px auto 40px",
    alignItems: "center",
    padding: "10px 10px 14px 10px",
    borderBottom: `1px solid #E9E9E9`,
    fontSize: "16px",
    "& img": {
      padding: "5px",
    },
    width: "100%",
  },
  close_icon: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: "-3px",
    top: "7px",
  },
  screen: {
    background: "white",
    height: "100vh",
    overflow: "auto",
  },
  backdrop_root: {
    zIndex: "0 !important" as any,
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "&.--zIndex": {
      zIndex: "1300 !important" as any,
    },
  },
  not_found: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 150px)",
    textAlign: "center",
    position: "fixed",
    width: "100%",
    "& i": {
      fontSize: "8.5rem",
      margin: 0,
      height: "auto",
      "@media (max-width: 345px)": {
        fontSize: "5rem",
      },
    },
    "@media (max-height: 700px)": {
      height: "calc(100vh - 110px)",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const SelectHospital: React.FunctionComponent<SelectHospitalProps> = (
  props
) => {
  const [selectedValue, setSelectedValue] = useState<any>("");
  const intl = useIntl();
  const classes = useStyles();

  const screenRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!props.onEvent) {
      return setSelectedValue(props.selectedHospital);
    }

    const getSelectedNativeHospital = async () => {
      let code = await globalThis.mobile.storedHospitalCode;
      let name = await globalThis.mobile.storedHospitalName;
      console.log(" code", code, "name", name);
      setSelectedValue({ code, name });
    };

    const getSelectAndroidNativeHospital = async () => {
      let setSelectHospital = await globalThis.mobile.storedPreferredHospital();
      let selectHospital = JSON.parse(setSelectHospital || "{}");
      setSelectedValue(selectHospital);
    };

    const getSelectStorageHospital = async () => {
      let setSelectHospital = localStorage.getItem("storedPreferredHospital");
      let selectHospital = JSON.parse(setSelectHospital || "{}");
      setSelectedValue(selectHospital);
    };

    if (globalThis.mobile.saveiOSSelectedHospital) {
      // iOS
      getSelectedNativeHospital();
    } else if (globalThis.mobile.storedPreferredHospital) {
      // Android
      getSelectAndroidNativeHospital();
    } else if (USE_LOCAL_STORAGE) {
      getSelectStorageHospital();
    } else {
      // previous
      setSelectedValue(props.selectedHospital);
      props.onEvent({ message: "GetListHospital", params: { apiv3: true } });
    }
  }, []);

  const handleChangeRadio = (item: any) => {
    setSelectedValue(item);
  };

  const handleReloadHospital = () => {
    props.onEvent?.({
      message: "GetListHospital",
      params: { sendToNative: true },
    });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        // @ts-ignore
        TransitionComponent={Transition}
        TransitionProps={props.history?.action === "PUSH" ? {} : { timeout: 0 }}
        className={`${classes.backdrop_root} ${props.className}`}
      >
        <div ref={screenRef} className={classes.screen}>
          <PullToRefreshtest
            onRefresh={() => handleReloadHospital()}
            isPullable={props.firstLoadingHospital ? true : false}
          >
            {props.closeIcon && (
              <div className={classes.close_icon}>
                <MuiButton
                  color="primary"
                  variant="text"
                  // className={classes.button_close}
                  onClick={props.onClose}
                >
                  <CloseIcon
                    style={{
                      color: COLOR.blue,
                      fontSize: "30px",
                    }}
                  />
                </MuiButton>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "40px 0 10px 0",
              }}
            >
              <div style={{ width: "40%", margin: " 20px 0px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: COLOR.primary,
                  }}
                >
                  {" "}
                  Select Hospital{" "}
                </div>
                <Divider
                  style={{
                    background: "red",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                  variant="middle"
                />
              </div>
            </div>

            <div style={{ padding: "0 15px 15px" }}>
              {/* <SearchBox
              rightIcon={
                <img
                  style={{ width: "13px", height: "auto" }}
                  src={ICON.location}
                  alt="location"
                />
              }
              onSearch={(value) =>
                props.onEvent({
                  message: "GetListHospital",
                  params: { name: value },
                })
              }
              textSearch={props.textSearch}
              isLoadingSearch={props.loadingSearch}
              hideSearchList={true}
              placeholder="Hospital"
            /> */}

              {/* Filter */}
              {/* <div className={classes.box_filter}>
              <p className={classes.title}>View mode</p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "15px",
                }}
              >
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 14H0L6 20V0H4V14ZM10 3V20H12V6H16L10 0V3Z"
                    fill="#0147A3"
                  />
                </svg>
                <Dropdown
                  value={props.selectedHospitalFilter}
                  options={props.hospitalFilterOptions}
                  className={classes.dropdown}
                  onChange={(e: any, v: DropdownProps) =>
                    props.onEvent({
                      message: "HandleChangeHospitalFilter",
                      params: { value: v.value },
                    })
                  }
                />
              </div>
            </div> */}
            </div>
            <div style={{ height: "calc(100vh - 250px)", overflow: "auto" }}>
              {(!props?.firstLoadingHospital
                ? new Array(5).fill("")
                : props.hospitalList || []
              ).map((item: any, index: number) => (
                <MuiButton
                  key={"hospital-" + index}
                  variant="text"
                  color="primary"
                  style={{
                    textAlign: "left",
                    textTransform: "unset",
                    width: "100%",
                    justifyContent: "flex-start",
                    paddingBottom: 0,
                  }}
                  onClick={() => {
                    handleChangeRadio(item);
                  }}
                  disabled={!props?.firstLoadingHospital}
                >
                  <div key={"hospital-" + index} className={classes.box_list}>
                    {props.firstLoadingHospital ? (
                      <>
                        <div>
                          <div
                            style={{
                              borderRadius: "50px",
                              width: "25px",
                            }}
                          >
                            <Image
                              src={ICON.bplus}
                              style={{
                                width: "25px",
                                padding: "5px",
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ fontWeight: "bold", color: COLOR.blue }}>
                          {item.name}
                        </div>
                        <div style={{ marginTop: "-3px" }}>
                          <Radio
                            checked={selectedValue?.code === item.code}
                            value={item.code}
                            name="radio-buttons"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Skeleton
                          animation="pulse"
                          variant="circle"
                          width={27}
                          height={27}
                          style={{}}
                        />
                        <Skeleton
                          animation="pulse"
                          height={18}
                          style={{}}
                          width="100%"
                        />
                      </>
                    )}
                  </div>
                </MuiButton>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "30px",
                width: "100%",
              }}
            >
              <Button
                style={{
                  width: "90%",
                  background: COLOR.primary,
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "15px 0",
                }}
                onClick={() => {
                  props.onEvent?.({
                    message: "HandleSelectedHospital",
                    params: { data: selectedValue },
                  });
                  props.onClose?.(selectedValue);
                }}
              >
                {intl.formatMessage({ id: "common.confirm" })}
              </Button>
            </div>

            {props.firstLoadingHospital && !props.hospitalList?.[0] && (
              <div className={classes.not_found}>
                <div style={{ color: "#b0b0b0" }}>
                  <Icon name="hospital outline" />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginTop: "30px",
                    }}
                  >
                    {intl.formatMessage({ id: "bplusClinicKey645" })}
                  </div>
                </div>
              </div>
            )}
          </PullToRefreshtest>
        </div>
      </Dialog>
    </>
  );
};

SelectHospital.defaultProps = {
  onEvent: () => {},
  hospitalFilterOptions: [],
  // selectedHospitalFilter: null,
  textSearch: "",
  loadingSearch: false,
  hospitalList: [],
  onClose: () => {},
  firstLoadingHospital: false,
  history: {},
  selectedHospital: null,
} as SelectHospitalProps;

export default React.memo(SelectHospital);
