import React, { useMemo, useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Doughnut as PieDonut } from "react-chartjs-2";
import { Grid, Progress, Modal, Button } from "semantic-ui-react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

type DMFoodMainProps = {
  nutrientRequirement: any;
  firstDayInWeekMealInfo: any;
  todayMealInfo: any;
  onEvent: any;
};

const useStyles = makeStyles((theme) => ({
  frame: {
    border: "0.5px solid #0072BC",
    boxSizing: "border-box",
    borderRadius: "2px",
    width: "360px",
    height: "510px",
  },

  CaloriesBudget: {
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#747474",
    textAlign: "center",
  },

  ProgressBoxLabel: {
    flex: 1,
    display: "flex",
    padding: "5px 0px 5px 0px",
  },

  ProgressLabelLeft: {
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "14px",
    color: "#747474",
    textAlign: "left",
    flex: 1,
  },

  ProgressLabelRight: {
    fontSize: "13px",
    lineHeight: "14px",
    color: "#747474",
    textAlign: "right",
    flex: 1,
  },

  Calories: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#0072BC",
  },

  c423: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#2399E5",
    position: "absolute",
    top: "305px",
    left: "71px",
  },

  Left: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#747474",
    position: "absolute",
    top: "325px",
    left: "71px",
  },

  c1207: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#747474",
    position: "absolute",
    top: "345px",
    left: "71px",
  },

  m: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#747474",
    position: "absolute",
    top: "3px",
  },

  Remaining: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#4F4F4F",
  },

  Remainingframe: {
    border: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    height: "23px",
  },

  RemainingframeL: {
    borderLeft: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    height: "23px",
  },

  RemainingframeR: {
    borderRight: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    height: "23px",
  },

  RemainingframeBL: {
    borderLeft: "0.2px solid #C4C4C4",
    borderBottom: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    height: "23px",
  },

  RemainingframeBR: {
    borderRight: "0.2px solid #C4C4C4",
    borderBottom: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    height: "23px",
  },

  PFC: {
    fontWeight: "normal",
    fontSize: "13px",
    color: "#747474",
  },

  PFCnumber: {
    fontWeight: "normal",
    fontSize: "12px",
    display: "flex",
    color: "#4F4F4F",
    paddingTop: "5px",
    paddingLeft: "20px",
  },

  Over: {
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    color: "#EF8318",
    paddingLeft: "5px",
  },

  Ontarget: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#219653",
    paddingLeft: "3px",
  },

  N: {
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#747474",
    paddingLeft: "5px",
  },

  RemainingframeNoTop: {
    borderLeft: "0.2px solid #C4C4C4",
    borderRight: "0.2px solid #C4C4C4",
    borderBottom: "0.2px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "2px",
    textAlign: "left",
    padding: "5px",
  },

  RatioTable: {
    textAlign: "center",
    fontSize: "12px",
  },
  box_center_piedonut: {
    position: "absolute",
    width: "100%",
    top: 0,
    marginTop: "5px",
    fontSize: "11px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
}));

// const dataPieDonut = {
//   datasets: [
//     {
//       data: [423, 1207],
//       backgroundColor: ["#2399E5", "#DFF5FC"],
//     },
//   ],

// };

export const calculatePercent = (part: number = 0, whole: number = 0) => {
  return (part / whole) * 100;
};

const DMFoodMain: React.FunctionComponent<DMFoodMainProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [pieData, setPieData] = useState<any>({ datasets: [] });
  const [modInfoFat, setModInfoFat] = useState<boolean>(false);

  const plugins = useMemo(() => {
    return [
      {
        beforeDraw: function (chart: any) {},
      },
    ];
  }, []);

  const progressData = useMemo(() => {
    const {
      breakfast = [],
      lunch = [],
      dinner = [],
      snack = [],
    } = props.todayMealInfo;
    const concat = [...breakfast, ...lunch, ...dinner, ...snack];
    const sum = concat.reduce(
      (result, item) => {
        result.protein += item.protein_grams * item.qty || 0;
        result.fat += item.fat_grams * item.qty || 0;
        result.carbohydrate += item.cabohydrate_grams * item.qty || 0;
        result.sodium += item.sodium_milligram * item.qty || 0;
        result.sugar += item.sugar_grams * item.qty || 0;
        result.sat_fat += item.sat_fat_milligram * item.qty || 0;
        result.calories += item.energy_cal * item.qty || 0;

        return result;
      },
      {
        protein: 0,
        fat: 0,
        carbohydrate: 0,
        sodium: 0,
        sugar: 0,
        sat_fat: 0,
        calories: 0,
      }
    );
    sum.protein_percent = (
      (sum.protein / (sum.protein + sum.fat + sum.carbohydrate)) * 100 || 0
    ).toFixed(2);
    sum.fat_percent = (
      (sum.fat / (sum.protein + sum.fat + sum.carbohydrate)) * 100 || 0.0
    ).toFixed(2);
    sum.carbohydrate_percent = (
      (sum.carbohydrate / (sum.protein + sum.fat + sum.carbohydrate)) * 100 ||
      0.0
    ).toFixed(2);

    if (
      props.firstDayInWeekMealInfo?.id &&
      props.firstDayInWeekMealInfo?.show_reccommend_fat_info &&
      props.nutrientRequirement?.fat?.percent &&
      props.nutrientRequirement?.fat?.percent > 30
    ) {
      setModInfoFat(true);
    }

    return sum;
  }, [props.todayMealInfo]);

  useEffect(() => {
    if (
      props.firstDayInWeekMealInfo?.id &&
      props.firstDayInWeekMealInfo?.show_reccommend_fat_info &&
      props.nutrientRequirement?.fat?.percent &&
      props.nutrientRequirement?.fat?.percent > 30
    ) {
      setModInfoFat(true);
    }

    console.log("Yes");
  }, [props.firstDayInWeekMealInfo, progressData]);

  useEffect(() => {
    if (
      props.nutrientRequirement?.calories_budget &&
      typeof progressData?.calories === "number"
    ) {
      // calculate as percentage
      let caloriesNow = progressData.calories;
      let caloriesBudget = props.nutrientRequirement.calories_budget;
      let percentBudget = 0;
      let percentForNow = 0;
      if (progressData.calories >= props.nutrientRequirement.calories_budget) {
        percentBudget = 0;
        percentForNow = 100;
      } else {
        percentForNow = Math.floor((caloriesNow * 100) / caloriesBudget);
        percentBudget = 100 - percentForNow;
      }

      setPieData({
        datasets: [
          {
            data: [percentForNow, percentBudget],
            backgroundColor: ["#2399E5", "#DFF5FC"],
            // borderColor: "#2399E5"
          },
        ],
      });
    } else {
      setPieData({
        datasets: [
          {
            data: [0, 100],
            backgroundColor: ["#2399E5", "#DFF5FC"],
            // borderColor: "#2399E5"
          },
        ],
      });
    }
  }, [props.nutrientRequirement, progressData]);

  const displayResult = (value: number) => {
    return !value ? "0" : value.toFixed(2);
  };
  // console.log("props.nutrientRequirement",props.nutrientRequirement)
  // console.log("props.nutrientRequirement?.carbohydrate",props.nutrientRequirement?.carbohydrate)
  // console.log("props.nutrientRequirement?.carbohydrate?.percent",props.nutrientRequirement?.carbohydrate?.percent)
  // console.log("pieData: ", pieData, props.nutrientRequirement.calories_budget, progressData.calories)
  return (
    <>
      <Grid style={{ display: "inline-block", width: "100%", marginLeft: 0 }}>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            <div className={classes.CaloriesBudget}>
              <FormattedMessage id="bplusClinicKey102" />
            </div>
            <div className={classes.Calories}>
              {props.nutrientRequirement?.calories_budget != null &&
               props.nutrientRequirement?.calories_budget > 0
                ? `${props.nutrientRequirement?.calories_budget?.toFixed(0)}`
                : ""}
            </div>
            <div style={{ position: "relative" }}>
              <PieDonut data={pieData} width={120} plugins={plugins} />
              <div className={classes.box_center_piedonut}>
                <div>
                  {props.nutrientRequirement?.calories_budget
                    ? `${intl.formatMessage({ id: "bplusClinicKey688" })}`
                    : ""}
                </div>
                <div style={{ fontWeight: "bold", fontSize: "11px" }}>
                  {props.nutrientRequirement?.calories_budget
                    ? (
                        props.nutrientRequirement?.calories_budget -
                        progressData.calories
                      ).toFixed(0)
                    : ""}
                </div>
                <div style={{ color: "#2399E5" }}>
                  {displayResult(progressData.calories)}
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: "left",
                paddingLeft: "2px",
                marginTop: "-6px",
                display: "flex",
                alignItems: "baseline",
                position: "relative",
              }}
            >
              <LightIcon />
              <div
                className={classes.Ontarget}
                style={{
                  padding: "4px 2px 0px 0px",
                  backgroundColor: "#f3f3f3",
                  textAlign: "center",
                  height: "21.2px",
                  position: "absolute",
                  top: "3px",
                  left: "35px",
                  right: "-33px",
                }}
              >
                {props.nutrientRequirement?.calories_budget -
                  progressData.calories <
                0
                  ? `${intl.formatMessage({ id: "bplusClinicKey859" })}`
                  : `${intl.formatMessage({ id: "bplusClinicKey352" })}`}
                {/* <FormattedMessage id="bplusClinicKey352" /> */}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage id="bplusClinicKey402" />
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 1 }}
                  >
                    {displayResult(progressData.protein)}/
                    {props.nutrientRequirement?.protein?.gram || 0} g
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(
                    progressData.protein,
                    props.nutrientRequirement?.protein?.gram || 0
                  )}
                  style={{ marginBottom: "5px" }}
                  color="yellow"
                  size="small"
                  disabled
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage id="bplusClinicKey196" />
                  </div>{" "}
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 1 }}
                  >
                    {displayResult(progressData.fat)}/
                    {props.nutrientRequirement?.fat?.gram || 0} g
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(
                    progressData.fat,
                    props.nutrientRequirement?.fat?.gram || 0
                  )}
                  style={{ marginBottom: "5px" }}
                  size="small"
                  color="green"
                  disabled
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage id="bplusClinicKey108" />
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 1 }}
                  >
                    {displayResult(progressData.carbohydrate)}/
                    {props.nutrientRequirement?.carbohydrate?.gram || 0} g
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(
                    progressData.carbohydrate,
                    props.nutrientRequirement?.carbohydrate?.gram
                  )}
                  style={{ marginBottom: "5px" }}
                  size="small"
                  color="pink"
                  disabled
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "11.5px",
                }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage id="bplusClinicKey407" />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: "0",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          width={`${progressData?.protein_percent || 0}%`}
                          className={classes.RatioTable}
                          style={{
                            ...(progressData?.protein_percent
                              ? { backgroundColor: "rgba(255, 245, 177)" }
                              : { border: "1px solid #fbbd08" }),
                          }}
                        >
                          {progressData?.protein_percent || 0}%
                        </td>
                        <td
                          width={`${progressData?.fat_percent || 0}%`}
                          className={classes.RatioTable}
                          style={{
                            ...(progressData?.fat_percent
                              ? { backgroundColor: "rgba(178, 231, 190)" }
                              : { border: "1px solid #21ba45" }),
                          }}
                        >
                          {progressData?.fat_percent || 0}%
                        </td>
                        <td
                          width={`${progressData?.carbohydrate_percent || 0}%`}
                          className={classes.RatioTable}
                          style={{
                            ...(progressData?.carbohydrate_percent
                              ? { backgroundColor: "rgba(252, 165, 195)" }
                              : { border: "1px solid #e03997" }),
                          }}
                        >
                          {progressData?.carbohydrate_percent || 0}%
                        </td>
                        {/* <td width={`${props.nutrientRequirement?.protein?.percent || 0}%`} className={classes.RatioTable} style={{ ...(props.nutrientRequirement?.protein?.percent) ? { backgroundColor: "rgba(255, 245, 177)" } : { border: "1px solid #fbbd08" } }}>
                          {props.nutrientRequirement?.protein?.percent || 0}%
                        </td>
                        <td width={`${props.nutrientRequirement?.fat?.percent || 0}%`} className={classes.RatioTable} style={{ ...(props.nutrientRequirement?.fat?.percent) ? { backgroundColor: "rgba(178, 231, 190)" } : { border: "1px solid #21ba45" } }}>
                          {props.nutrientRequirement?.fat?.percent || 0}%
                        </td>
                        <td width={`${props.nutrientRequirement?.carbohydrate?.percent || 0}%`} className={classes.RatioTable} style={{ ...(props.nutrientRequirement?.carbohydrate?.percent) ? { backgroundColor: "rgba(252, 165, 195)" } : { border: "1px solid #e03997" } }}>
                          {props.nutrientRequirement?.carbohydrate?.percent || 0}%
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        {/*  */}
        <Grid.Row
          columns={3}
          style={{ paddingLeft: "4px", paddingRight: "4px" }}
        >
          <Grid.Column style={{ padding: "0 0.2rem" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 2, fontSize: "10px" }}
                  >
                    <FormattedMessage id="bplusClinicKey461" />
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 5, fontSize: "9px" }}
                  >
                    {displayResult(progressData.sodium)}/
                    {props.nutrientRequirement?.first_time ? "0 mg" : "2300 mg"}
                    {/* 2300 mg */}
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(progressData.sodium, 2300)}
                  style={{ marginBottom: "5px" }}
                  color="orange"
                  size="small"
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column style={{ padding: "0 0.2rem" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 2, fontSize: "10px" }}
                  >
                    <FormattedMessage id="bplusClinicKey475" />
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 5, fontSize: "9px" }}
                  >
                    {displayResult(progressData.sugar / 4)}
                    {props.nutrientRequirement?.first_time
                      ? `/0 ${intl.formatMessage({ id: "bplusClinicKey901" })}`
                      : `${intl.formatMessage({ id: "bplusClinicKey15" })}`}
                    {/* /6 teaspon */}
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(progressData.sugar / 4, 6)}
                  style={{ marginBottom: "5px" }}
                  color="teal"
                  size="small"
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column style={{ padding: "0 0.2rem" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <div className={classes.ProgressBoxLabel}>
                  <div
                    className={classes.ProgressLabelLeft}
                    style={{ flex: 2, fontSize: "10px" }}
                  >
                    <FormattedMessage id="bplusClinicKey436" />
                  </div>
                  <div
                    className={classes.ProgressLabelRight}
                    style={{ flex: 2, fontSize: "9px" }}
                  >
                    {displayResult(progressData.sat_fat)}/
                    {props.nutrientRequirement?.first_time
                      ? "0 g"
                      : props.nutrientRequirement?.sat_fat?.gram + "g"}
                    {/* {props.nutrientRequirement?.sat_fat?.gram} g */}
                  </div>
                </div>
                <Progress
                  percent={calculatePercent(progressData.sat_fat, 140)}
                  style={{ marginBottom: "5px" }}
                  color="violet"
                  size="small"
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal open={modInfoFat} closeOnDimmerClick={false}>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#289ae0", fontSize: "16px" }}>
              {intl.formatMessage({ id: "bplusClinicKey811" })}{" "}
            </span>
            <span
              style={{
                color: "#289ae0",
                fontSize: "16px",
                paddingBottom: "15px",
              }}
            >
              {intl.formatMessage({ id: "bplusClinicKey809" })}
            </span>
            <Button
              style={{ width: "60px" }}
              color="blue"
              content="OK"
              onClick={() => {
                props.onEvent({
                  message: "handleGetConfirmInfo",
                  params: { fat_info: false },
                });
                setModInfoFat(false);
              }}
            />
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

const LightIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
    <path
      d="M11.5 5.75029C11.4986 6.54192 11.7156 7.31859 12.1269 7.99495C12.5383 8.6713 13.1282 9.22113 13.8318 9.58402C14.5353 9.9469 15.3253 10.1088 16.1149 10.0519C16.9045 9.99494 17.6631 9.72143 18.3073 9.26138L20.5462 11.5003L21.5625 10.484L19.3236 8.24507C19.7167 7.6921 19.9736 7.05416 20.0736 6.38314C20.1736 5.71211 20.1138 5.02697 19.899 4.38342C19.6843 3.73988 19.3207 3.15612 18.8377 2.67962C18.3548 2.20312 17.7662 1.84735 17.1199 1.64123C16.4735 1.43512 15.7876 1.3845 15.118 1.49347C14.4484 1.60244 13.8139 1.86794 13.2663 2.26837C12.7186 2.6688 12.2732 3.19284 11.9663 3.79788C11.6594 4.40292 11.4996 5.07186 11.5 5.75029ZM12.9375 5.75029C12.9375 5.18167 13.1061 4.62581 13.422 4.15302C13.7379 3.68023 14.187 3.31174 14.7123 3.09413C15.2376 2.87653 15.8157 2.8196 16.3734 2.93053C16.9311 3.04146 17.4434 3.31528 17.8454 3.71736C18.2475 4.11943 18.5213 4.63171 18.6323 5.1894C18.7432 5.7471 18.6863 6.32517 18.4687 6.8505C18.2511 7.37584 17.8826 7.82485 17.4098 8.14076C16.937 8.45667 16.3811 8.62529 15.8125 8.62529C15.0503 8.62434 14.3196 8.32113 13.7806 7.78217C13.2417 7.24321 12.9385 6.51249 12.9375 5.75029Z"
      fill="#0072BC"
    />
    <path d="M7.90625 17.25H15.0938V18.6875H7.90625V17.25Z" fill="#0072BC" />
    <path d="M9.34375 20.125H13.6562V21.5625H9.34375V20.125Z" fill="#0072BC" />
    <path
      d="M7.77362 13.0383C7.10048 12.5249 6.56388 11.8539 6.21106 11.0843C5.85824 10.3148 5.70002 9.47028 5.75034 8.6252C5.75238 7.35171 6.17687 6.11488 6.95729 5.10853C7.7377 4.10219 8.82994 3.38319 10.0628 3.06423L9.7854 1.65332C8.22581 2.03767 6.83972 2.9329 5.84794 4.19639C4.85616 5.45989 4.31576 7.01895 4.31284 8.6252C4.26411 9.66938 4.46417 10.7102 4.89654 11.6619C5.32891 12.6136 5.98121 13.449 6.79971 14.0992C7.52709 14.7712 7.90659 15.1522 7.90659 15.8127H9.34409C9.34409 14.4902 8.54628 13.7528 7.77362 13.0383Z"
      fill="#0072BC"
    />
    <path
      d="M16.5672 11.5C16.1977 12.0774 15.7442 12.5965 15.2217 13.0403C14.4519 13.7533 13.6562 14.49 13.6562 15.8125H15.0938C15.0938 15.1512 15.4718 14.7703 16.197 14.0976C16.8756 13.52 17.4514 12.8315 17.8997 12.0613L16.5672 11.5Z"
      fill="#0072BC"
    />
  </svg>
);

DMFoodMain.defaultProps = {
  nutrientRequirement: {},
  todayMealInfo: {},
  onEvent: null,
} as DMFoodMainProps;

export default React.memo(DMFoodMain);
