import React, { CSSProperties, useEffect, forwardRef, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Dialog, Slide } from "@material-ui/core";
import { Grid, Header, Icon, Image, Modal } from "semantic-ui-react";

import "./MainLabResultCSS.scss";
import CardDoctorDetail from "./CardDoctorDetail";
import Avatar from "@mui/material/Avatar";

// type
import { VisitDateItemsType } from "bplus-lib/MobLABInterface";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  flexcenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10px 0px",
  } as CSSProperties,
  card: {
    width: "100%",
    height: "120px",
    borderRadius: "10px",
    padding: "15px",
  } as CSSProperties,
  card_doctor: {
    borderRadius: "10px",
    padding: "15px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: "white",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16_noellipsis: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
};

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

type DoctorProps = {
  onEvent: any;
  textSearch: string;
  visitDateItems?: VisitDateItemsType[];
  selectedHospital?: any;
};
const Doctor: React.FC<DoctorProps> = (props) => {
  const [groupDoctor, setGroupDoctor] = useState<any>(null);
  const [openCard, setOpenCard] = useState<boolean>(false);
  const [doctorData, setDoctorData] = useState<any | undefined>("");

  useEffect(() => {
    if (props.visitDateItems) {
      const multiDoctor = handleSetMultiDoctor(props.visitDateItems);
      const groupDoctor = multiDoctor.reduce((group: any, item: any) => {
        const { doctor } = item;
        group[doctor] = group[doctor] ?? [];
        group[doctor].push(item);
        return group;
      }, {});
      const groupArrays = Object.keys(groupDoctor).map((doctor) => {
        const doctorItem = groupDoctor[doctor];
        const division_name = doctorItem[0].division_name;
        const full_name = doctorItem[0].doctor_details?.full_name;
        const hospital_name = doctorItem[0].hospital_name;
        const image = doctorItem[0].doctor_details?.image;
        const started = doctorItem[0].started;
        return {
          doctor,
          division_name,
          full_name,
          hospital_name,
          image,
          started,
          lab: groupDoctor[doctor],
        };
      });
      console.log("saika groupDoctor ", groupArrays);
      setGroupDoctor(groupArrays[0] ? groupArrays : null);
    } else {
      setGroupDoctor(null);
    }
  }, [props.visitDateItems]);

  const handleSetMultiDoctor = (data: VisitDateItemsType[]) => {
    // console.log(
    //   "saika check SetMultiDoctor ",
    //   data
    // );
    let lastYearMultiDoctor: any[] = [];
    data.forEach((visitItems: VisitDateItemsType ) => {
      const consultDoctors = visitItems.consult_doctors;
      if (
        (consultDoctors || []).filter(
          (item: any) => item?.doctor?.code === visitItems?.doctor_details?.code
        ).length < 1
      ) {
        consultDoctors?.push({
          doctor: visitItems.doctor_details,
          division_name: visitItems.division_name,
          arrived_dt: visitItems.started,
          main: true,
        });
      }
      (consultDoctors || []).sort((a: any, b: any) => (a.main ? -1 : 1));
      const multiDoctor = (consultDoctors || []).map((doctors: any) => {
        let labOrder: any[] = (visitItems?.lab_order || []).map((labItem: any) => {
          const childrenLabCheck = labItem.children
            .map((childLab: any) => {
              if (
                childLab.history_results.some(
                  (item: any) => item.doctor_code === doctors?.doctor?.code
                )
              ) {
                return childLab;
              } else if (
                childLab.history_results.some(
                  (item: any) => item.doctor_code == null
                ) &&
                doctors?.main
              ) {
                return childLab;
              }
            })
            .filter((item: any) => item != undefined);
          return { ...labItem, children: childrenLabCheck };
        });
        // console.log(
        //   "saika check labOrder ",
        //   labOrder.filter((item: any) => item?.children?.length > 0)
        // );

        if (visitItems?.consult_doctors && visitItems?.consult_doctors) {
          labOrder = (visitItems?.lab_order || [])
        } 

        return {
          ...visitItems,
          doctor: doctors?.doctor?.id,
          doctor_details: doctors?.doctor,
          lab_order: labOrder?.filter((item: any) => item?.children?.length > 0),
          started: doctors?.arrived_dt,
        };
      });
      // console.log(
      //   "saika check multiDoctor ",
      //   multiDoctor
      // );
      multiDoctor.forEach((item: any) => lastYearMultiDoctor.push(item));
    });
    // console.log("saika check lastYearMultiDoctor ", lastYearMultiDoctor);
    return lastYearMultiDoctor;
  };

  const handleOpenCard = (item: any) => {
    setDoctorData(item);
    setOpenCard(true);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  return (
    <div style={{ padding: "10px" }}>
      {groupDoctor == null && (
        <Header
          as="h3"
          color="grey"
          style={{ textAlign: "center", marginTop: "15px" }}
        >
          <FormattedMessage id="common.no_data_labresult" />
        </Header>
      )}

      <div>
        {groupDoctor ? (
          groupDoctor
            .filter(
              (item: any) =>
                item.full_name
                  ?.toLowerCase()
                  .search(props.textSearch.toLowerCase()) !== -1
            )
            .map((item: any, index: any) => (
              <div
                style={styles.card_doctor}
                onClick={() => handleOpenCard(item)}
              >
                <Grid style={{ margin: "0px" }}>
                  <Grid.Column
                    width={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0",
                    }}
                  >
                    <Avatar
                      src={item?.image || "images/Feed/doctor.png"}
                      style={{ width: "60px", height: "60px" }}
                    />
                  </Grid.Column>
                  <Grid.Column width={13} style={{ padding: "0 15px" }}>
                    {/* full name */}
                    <Grid.Row>
                      <div
                        style={{
                          ...styles.text_bluebdms_16,
                          margin: "10px 0",
                        }}
                      >
                        {item?.full_name || ""}
                      </div>
                    </Grid.Row>
                    {/* hospital name */}
                    <Grid.Row>
                      <div style={styles.text_DarkGray_14}>
                        {item.hospital_name ||
                          props.selectedHospital?.name ||
                          ""}
                      </div>
                    </Grid.Row>
                    {/* division name  */}
                    <Grid.Row>
                      <div style={styles.text_DarkGray_14}>
                        {item.division_name}
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>
            ))
        ) : (
          <></>
        )}
      </div>

      <Dialog
        fullScreen
        open={openCard}
        onClose={() => setOpenCard(false)}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <div style={{ width: "100%" }}>
          <CardDoctorDetail
            onEvent={props.onEvent}
            onClose={handleCloseCard}
            doctorSelected={doctorData}
            selectedHospital={props.selectedHospital}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(Doctor);
