import React, { CSSProperties } from 'react';
import { Grid } from 'semantic-ui-react';
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const styles = {
    noPadding: {
        padding: "0px",
     } as CSSProperties,
}

type WebChildUserManagementProps = {
    onEvent?: any;
    webSub?: string;
    userManagerSub?: string;
}

const WebChildUserManagementInitial = {
    onEvent: () => {},
    webSub: "",
    userManagerSub: ""
}

const WebChildUserManagement: React.FC<WebChildUserManagementProps> = (
    props
  ) => {
      return <>
      <Grid>
        <Grid.Column width={3} style={styles.noPadding}><FormattedMessage id="bplusClinicKey374" /></Grid.Column>
        <Grid.Column width={13} style={{ padding: "15px 0px" }}></Grid.Column>
        </Grid>
      </>
  }

WebChildUserManagement.defaultProps = WebChildUserManagementInitial

export default React.memo(WebChildUserManagement);
