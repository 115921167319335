import WasmController from "react-lib/frameworks/WasmController";
import firebase from "firebase/compat/app";
import moment from "moment";
import axios from "axios";
import CONFIG from "config/config";
import firebaseConfig from "../../_manager/firebase-config";

// APIs
import PatientFeedView from "issara-sdk/apis/PatientFeedView_apps_PHR";

// Interface
import {
  PostData,
  SaveDataField,
  EngagementData,
} from "../post/PostExInterface";
import { PromotionData } from "../post/PromotionExInterface";
import { State as MainState, MenuBarType } from "../MobFeedInterface";
import {
  GetAvailableDoctor,
  HandleSelectedCardItem,
} from "../appointment/MakeAppointmentInterface";
import { query, collection, where, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";

export type State = Partial<{
  // feedContentList: ContentData[];
  preferredContentList: ContentData[];
  allFeedContentList: ContentData[];
  // feedContentListId: any[];
  // allFeedContentListId: any[];
  favoriteContentList: ContentData[];
  savedContentList: ContentData[];
  marketingContentDetail: ContentData;
  firstLoadingFeed: boolean;
  firstLoadingFavorite: boolean;
  firstLoadingSaved: boolean;
  shareto: string;
  sharefrom: string;
  shareContentTo: string;
  followedHospital: {
    ids: string[];
    post: any[];
    promotion: any[];
  };
}>;

type Picked = Pick<
  MainState,
  | "myProfileDetail"
  | "selectedMenuBar"
  | "loadingMainFeed"
  | "apiToken"
  | "selectedHospital"
>;

type ContentData = PostData & PromotionData;

export const StateInitial: Required<State> = {
  // feedContentList: [],
  preferredContentList: [],
  allFeedContentList: [],
  favoriteContentList: [],
  savedContentList: [],
  marketingContentDetail: {} as any,
  firstLoadingFeed: false,
  firstLoadingFavorite: false,
  firstLoadingSaved: false,
  shareto: "",
  sharefrom: "",
  shareContentTo: "",
  followedHospital: {
    ids: [],
    post: [],
    promotion: [],
  },
};

const POST_DIVISION = "PostDivision";
const PROMOTION_DIVISION = "PromotionDivision";
const POST_PATIENT_ACTIVITY = "PostPatientActivity";
const PROMOTION_PATIENT_ACTIVITY = "PromotionPatientActivity";
const POST_ENGAGEMENT = "PostEngagement";
const PROMOTION_ENGAGEMENT = "PromotionEngagement";

export type Event =
  // GET
  | { message: "GetListFeedContent"; params: { patient: number } }
  | {
      message: "GetListFavoriteOrSaved";
      params: { patient: number; type: "favorite" | "saved" };
    }
  | {
      message: "GetListMarketingContent";
      params: { id: string; contentType: "post" | "promotion" };
    }
  | {
      message: "HandleMakeAppointmentContent";
      params: {
        history: any;
        content: any;
      };
    }
  // | { message: "HandleSearchFeedContent"; params: { search: string } }
  // UPDATE
  | {
      message: "HandleActionFeedContent";
      params: {
        id: string | null;
        active?: boolean;
        type: "like" | "click" | "save" | "follow" | "share" | "reach";
        isView?: boolean;
        content_type?: PostData["type"];
        onSuccess?: () => any;
        callback?: () => any;
      };
    }
  | { message: "HandleSaveReach"; params: { data: any } }
  // SET
  | { message: "HandleSetShareContentTo"; params: { value: string } }
  | { message: "HandleSetMarketingContentDetail"; params: { data: any } }
  | { message: "HandleShareContent"; params: { data: any } }
  | { message: "shareContent"; params: { contentUrl: string } }
  | { message: "HandleVisibleConsent"; params: { index?: number } };

export type Data = {};

export const DataInitial = {};

export const LIMIT_FEED = 3; // can't below OFFSET FEED
export const OFFSET_FEED = 2;

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

type Params<M extends Event["message"]> = Extract<
  Event,
  { message: M }
>["params"];

/* ------------------------- GET ------------------------ */
// const GetMarketing: Handler = async (
//   controller,
//   params: { collection: string; patient: number; collection_get: string }
// ) => {
//   try {
//     const result = await controller.db
//       .collection(params.collection)
//       .where("active", "==", true)
//       // .where("manage_segment_patient", "array-contains", params.patient)
//       .get();
//     const list = result.docs.map((doc: any) => ({
//       id: doc.id,
//       ...doc.data(),
//     })) as any[];
//     const promiseArray = list.map((item) => {
//       return controller.db
//         .collection(params.collection_get)
//         .where("id", "==", item.id)
//         .get();
//     });
//     const response = await Promise.all(promiseArray);
//     const mergeList = response.map((item, index) => {
//       const data = item.docs[0]?.data?.() || {};
//       return {
//         ...list[index],
//         likes: data.likes || [],
//         clicks: data.clicks || [],
//         saved: data.saved || [],
//         shares: data.shares || [],
//         follows: data.follows || [],
//       };
//     });
//     return [mergeList, null];
//   } catch (e) {
//     console.log("Error getting clinic content from firebase");
//     return [null, (e as Error).toString()];
//   }
// };

const GetPatientActivity: Handler = async (controller, params) => {
  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  let result: any = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/feed/dbjson/post_patient_activity_129620.json')
    .child(
      params.collection === POST_PATIENT_ACTIVITY
        ? `/PatientActivity/post_patient_activity_${params.patient}.json`
        : `/PatientActivity/promotion_patient_activity_${params.patient}.json`
    )
    .getDownloadURL()
    .then((url) => {
      controller.analytics.logEvent("read_firebase_storage", {
        document:
          params.collection === POST_PATIENT_ACTIVITY
            ? "post_patient_activity"
            : "promotion_patient_activity",
        patient: params.patient,
        value: 1,
      });

      return axios.get(url).catch((error) => error);
    })
    .catch((error) => {
      console.warn("get PatientActivity error: ", error);
    });

  // ไม่มี file (สร้างไฟล์ ?)

  console.log(
    "1. params.collection: ",
    params.collection,
    "1. result: ",
    result
  );

  if (result?.data) {
    let data = result.data;

    data = {
      ...data,
      id: data.patient,
    };

    return [data, null];
  } else {
    return [null, "error"];
  }

  try {
    const result = await controller.db
      .collection(params.collection)
      .where("patient", "==", params.patient)
      .limit(1)
      .get();
    const list = result.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    })) as any[];

    return [list?.[0] || {}, null];
  } catch (error) {
    return [null, error];
  }
};

const GetListContent: Handler = async (
  controller,
  params: {
    collection: string;
    collection_division: string;
    collection_engagement: string;
    patient: number;
    key: string;
  }
) => {
  console.log("GetListContent: ", params);
  try {
    const [patientActivity, error] = await GetPatientActivity(
      controller,
      params
    );

    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json");
    }
    let config = firebaseConfig.find(
      (item: any) => item.projectId === firebase_project
    );

    console.log("patientActivity: ", patientActivity);
    const items: string[] = (patientActivity?.[params.key] || [])
      .map((item: any) => (typeof item === "string" ? item : item?.feed_id))
      .filter(Boolean);

    console.log("GetListContent items: ", items);

    const promiseContent = items.map((id) => {
      // DIGITAL OCEAN
      return axios.get(
        `${CONFIG.DO_END_POINT_URL}/${params.collection_division}/${id}.json`
      );
    });

    console.log("GetListContent promiseContent: ", promiseContent);
    const promiseEngagement = items.map((id) => {
      console.log("GetListContent promiseEngagement id: ", id);
      return (
        controller.storage
          .refFromURL(`${config?.gsBucketName}`)
          // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
          .child(
            params.collection_engagement === POST_ENGAGEMENT
              ? `/ContentEngagement/post_engagement_${id}.json`
              : `/ContentEngagement/promotion_engagement_${id}.json`
          )
          .getDownloadURL()
          .then((url) => {
            controller.analytics.logEvent("read_firebase_storage", {
              document:
                params.collection_engagement === POST_ENGAGEMENT
                  ? "post_engagement"
                  : "promotion_engagement",
              content: id,
              value: 1,
            });

            return axios.get(url);
          })
          .catch((error) => {
            console.warn("get ContentEngagement error: ", error);
          })
      );

      // return controller.db
      //   .collection(params.collection_engagement)
      //   .where("id", "==", id)
      //   .get();
    });

    console.log("promiseContent: ", promiseContent);
    console.log("promiseEngagement: ", promiseEngagement);

    const response = await Promise.all([
      ...promiseContent,
      ...promiseEngagement,
    ]);

    console.log("GetListContent response: ", response);
    const contentRes = response.slice(0, response.length / 2);
    const engagementRes: any[] = response.slice(
      response.length / 2,
      response.length
    );

    console.log("GetListContent engagementRes: ", engagementRes);
    console.log("GetListContent contentRes: ", contentRes);
    const mergeList = contentRes
      .map((doc: any, index) => {
        const data: any = engagementRes[index]?.hasOwnProperty("docs")
          ? engagementRes[index].docs[0]?.data?.() || {}
          : engagementRes[index]?.data || {};
        const detail = doc.data;
        console.log("data: ", data);
        console.log("detail: ", detail);
        return typeof doc.data === "undefined"
          ? null
          : {
              id: doc.id,
              ...detail,
              likes: data.likes || [],
              clicks: data.clicks || [],
              saved: data.saved || [],
              shares: data.shares || [],
              // follows: data.follows || [],
              reach: data.reach || [],
              engagement_id: engagementRes[index]?.hasOwnProperty("docs")
                ? engagementRes[index].docs[0]?.id
                : engagementRes[index]?.data?.id,
              content_id: engagementRes[index]?.data?.id,
            };
      })
      .filter(Boolean);
    console.log("mergeList: ", mergeList);
    return [mergeList, null];
  } catch (e) {
    console.log("Error getting GetListContent from firebase", e);
    return [null, (e as Error).toString()];
  }
};

const GetFeedFromStorage: Handler = async (controller, params) => {
  console.log("GetFeedFromStorage: ");
  let url: string = "";

  const { apiToken } = controller.getState();

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error(
      "GetFeedFromStorage Not config FIREBASE_PROJECT in base.json"
    );
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  // console.log(" GetListFeedContent GetFeedFromStorage ", params.patient )

  // let postPatientActivity = null;
  // let promotionPatientActivity = null;
  let patientFeed = null;

  // Backend
  try {
    if (apiToken) {
      patientFeed = PatientFeedView.get({ apiToken });
    } else {
      patientFeed = PatientFeedView.get({});
    }
  } catch (error) {
    console.warn("GetFeedFromStorage error", error);
    patientFeed = Promise.reject([null, "error"]);
  }

  let feedResult = await patientFeed.catch((error) => error);
  console.log("feedResult: ", feedResult);

  if (feedResult?.[1] || feedResult?.[0]?.length === 0) {
    console.warn("Fallback to storage patientFeed");

    // Fallback
    try {
      if (params.patient) {
        patientFeed = controller.storage
          .refFromURL(`${config?.gsBucketName}`)
          // .child(`/feed/${params.patient}.json`)
          .child(`/feed_minimize/${params.patient}.json`)
          .getDownloadURL()
          .then((url) => {
            controller.analytics.logEvent("read_firebase_storage", {
              document: "feed_minimize",
              patient: params.patient,
              value: 1,
              userId: controller.cookies.get("userId") || "anonymous",
            });

            return axios.get(url);
          });
      } else {
        patientFeed = controller.storage
          .refFromURL(`${config?.gsBucketName}`)
          .child(`/feed_minimize/guest.json`)
          .getDownloadURL()
          .then((url) => {
            controller.analytics.logEvent("read_firebase_storage", {
              document: "feed_minimize",
              patient: 0,
              value: 1,
              userId: controller.cookies.get("userId") || "anonymous",
            });

            return axios.get(url);
          });
      }

      feedResult = await patientFeed.catch((error) => error);
    } catch (error) {
      console.log("GetFeedFromStorage errro", error);
      return [null, error];
    }
  }

  let feed = [];
  if (Array.isArray(feedResult) && feedResult[0]?.length > 0) {
    console.log("Get From backend feedResult: ", feedResult);
    feed = feedResult[0];
  } else if (feedResult?.data) {
    console.log("Get From storagefeedResult:  ", feedResult);
    feed = feedResult?.data;
  } else {
    console.warn("failed from  no patientFeed", feedResult);
    return [null, patientFeed];
  }

  console.log("GetFeedFromStorage feed: ", feed);

  if (feed) {
    console.log("feed: ", feed);
    return [feed, null];
  } else {
    return [null, "Error"];
  }
};

export const GetContentFromID: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log("GetContentFromID: params?.index ", params?.index);

  console.log(
    "GetContentFromID state?.allFeedContentList: ",
    state?.allFeedContentList
  );

  const unLoadContent = state?.allFeedContentList
    ?.filter((item: any) => item.visible)
    ?.filter((item: any) => item.type === "post" || item.type === "promotion") // ถ้ามีแสดงว่า ยังไม่ได้ โหลด ถ้าโหลดแล้วจะเป้น article ใดๆ
    ?.map((item: any) => ({ ...item }));

  console.log("GetContentFromID unLoadContent: ", unLoadContent);

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error(
      "GetFeedFromStorage Not config FIREBASE_PROJECT in base.json"
    );
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  let contentDivisionArray = (unLoadContent || [])
    .filter((item: any) => item.visible)
    .map((item: { id: string; type: string }) => {
      return controller.storage
        .refFromURL(`${config?.gsBucketName}`)
        .child(
          item.type === "post"
            ? `/PostDivision/${item.id}.json`
            : `/PromotionDivision/${item.id}.json`
        )
        .getDownloadURL()
        .then((url) => {
          console.log(" Content URL: ", url);

          controller.analytics.logEvent("read_firebase_storage", {
            document:
              item.type === "post" ? "post_division" : "promotion_division",
            content: item.id,
            value: 1,
          });

          return axios
            .get(url)
            .then((div: any) => {
              // console.log("GetFeedFromStorage div: ", div.data)
              return { ...div?.data, id: item.id };
            })
            .catch((error: any) => {
              console.log("error download content_id.json ", error);
              return Promise.reject(error);
            });
        })
        .catch((error: any) => {
          console.log("Cannot getDownloadURL from firebase storage error: ", error);
          return Promise.reject(error);
        });
    });

  console.log("GetContentFromID contentDivisionArray: ", contentDivisionArray);

  const engagmentArray = (unLoadContent || [])
    .filter((item: any) => item.visible)
    .map((item: any) => {
      return (
        controller.storage
          .refFromURL(`${config?.gsBucketName}`)
          // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
          .child(
            item.type === "post"
              ? `/ContentEngagement/post_engagement_${item.id}.json`
              : `/ContentEngagement/promotion_engagement_${item.id}.json`
          )
          .getDownloadURL()
          .then((url) => {
            // console.log(" Engagement id: ", item.id, " URL: ", url )
            controller.analytics.logEvent("read_firebase_storage", {
              document:
                item.type === "post"
                  ? "post_engagement"
                  : "promotion_engagement",
              content: item.id,
              value: 1,
            });

            return axios
              .get(url)
              .then((eng) => {
                // console.log("GetContentFromId eng: ", eng);
                return { ...eng.data };
              })
              .catch((error) => {
                // console.log("GetContentFromId error", error);
              });
          })
          .catch((error: any) => {
            console.log("error: ", error.code);
            switch (error.code) {
              case "storage/object-not-found":
                console.log("engagement storage/object-not-found ");
                return Promise.reject({
                  code: "storage/object-not-found",
                  id: item.id,
                });
                break;
              case "storage/unauthorized":
                console.log("engagement storage/unauthorized ");
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                console.log("engagement storage/canceled ");
                // User canceled the upload
                break;

              case "storage/unknown":
                console.log("engagement storage/unknown ");
                // Unknown error occurred, inspect the server response
                break;
            }
          })
      );
    });

  console.log("GetContentFromID engagmentArray: ", engagmentArray);

  try {
    let content = await Promise.allSettled(contentDivisionArray);

    // create when
    // ได้ Content (active: true/false)
    const contentList = content
      ?.filter((item) => {
        return item.status === "fulfilled";
      })
      ?.map((item: any) => item.value);

    // console.log("GetContentFromID contentList: ", contentList);

    // console.log("GetFeedFromStorage engagmentArray: ", engagmentArray);
    let engagementList = await Promise.allSettled(engagmentArray);
    // console.log('1 GetContentFromID engagementList: ', engagementList);
    engagementList = engagementList?.filter((item) => {
      // console.log("!!!! check engagement item:", item);
      return item.status === "fulfilled";
    });

    engagementList = engagementList?.map((item: any) =>
      item.status === "fulfilled"
        ? item.value
        : item.status === "rejected" &&
          item.reason.code === "storage/object-not-found"
        ? { id: item.reason.id, active: false }
        : undefined
    );

    // กรณี ที่ ไม่สามารถโหลด engagment ได้ ให้ เตรียม id, active ใน engagment (เป็น active คนละตัวกับ contentList )

    // console.log("GetFeedFromStorage engagementList: ", engagementList);

    let mergeList = engagementList?.map((data: any, index: any) => {
      // console.log("GetFeedFromStorage engagementList data: ", data);
      let con = contentList.find((con: any) => con?.id === data?.id);
      // console.log("con: ", con);
      return {
        ...con,
        likes: data.likes || [],
        clicks: data.clicks || [],
        saved: data.saved || [],
        shares: data.shares || [],
        follows: data.follows || [],
        reach: data.reach || [],
        engagement_id: data?.id,
        content_id: data?.id,
        visible: index < LIMIT_FEED,
      };
    });

    console.log("GetContentFromID  filter mergeList: ", mergeList);
    console.log(
      "GetContentFromID state.allFeedContentList: ",
      state.allFeedContentList
    );
    // console.log("GetContentFromID prepare updateFeedList from above");
    state = controller.getState();
    let followedHospital: any[] = state.followedHospital?.ids || [];

    // เตรียม update FeedList
    let updateFeedList = (state.allFeedContentList || [])?.map((item: any) => {
      let content = mergeList?.find((merge: any) => merge.id === item.id);
      if (item.visible && !item.active) {
        // console.log("updateFeedList GetContentFromID content: ", content);
        if (content) {
          delete item.type;
        }
        let a = {
          ...item,
          ...content,
          visible: true,
          fake_follow: content
            ? followedHospital.includes(content?.hospital?.code?.toString())
            : false,
        };
        console.log("GetContentFromID a: ", a);
        return a;
      } else {
        // console.log("GetContentFromID ELSE item.visible: ", item.visible);
        // console.log("GetContentFromID ELSE item.active: ", item.active);
        return { ...item };
      }
    });

    // กรณี content ที่ได้มา เป็น active =  false (ใน issue 60590) ต้องกรองออกจาก mergeList ตัวนี้ ไปเลย
    // ถ้าไม่มี content_id คือยังไม่ได้ โหลด content แต่ ถ้า มี content_id แล้ว เช็ค active flag
    updateFeedList = updateFeedList?.filter((item: any) => {
      return (item.content_id && item.active == true) || !item.content_id;
    });

    console.log(
      "GetContentFromID Finish params?.index : ",
      params?.index,
      " allFeedContentList / updateFeedList: ",
      updateFeedList
    );
    controller.setState({
      // feedContentList: updateFeedList,
      allFeedContentList: [...updateFeedList],
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const GetListFeedContent: Handler = async (
  controller,
  params: Params<"GetListFeedContent">
) => {
  console.log("GetListFeedContent: ");
  var hospital = {
    ids: [] as any[],
    post: [],
    promotion: [],
  };
  const start1 = performance.now();

  GetFollowedHospital(controller, params).then((res: any) => {
    // const state = controller.getState();
    console.log("GetListFeedContent !!!! 2 !!!! hosptial:   ", hospital);
    hospital = res;
    controller.setState({ followedHospital: hospital }); // ไปคิด ตอน lazy load
    // console.log("GetListFeedContent !!!! 2 !!!! state.feedContentList: ", state.feedContentList)
    // if (!!state.feedContentList?.length) {
    //   HandleSetFeedContentList(controller, {
    //     items: state.feedContentList,
    //     hospital,
    //   });
    // }
  });

  const [res, error] = await GetFeedFromStorage(controller, params);
  console.log("GetListFeedContent !!!! 1 !!!! res: ", res);
  const itemsId: any[] = res || [];
  const items = itemsId?.map((item: any, idx: any) => ({
    ...item,
    visible: idx < LIMIT_FEED,
  }));

  console.log("GetListFeedContent params: ", params);
  console.log("GetListFeedContent res: ", res);
  console.log("GetListFeedContent error: ", error);
  // const end1 = performance.now();
  // const timeTaken1 = end1 - start1;
  // console.log("timeTaken end1", timeTaken1 / 1000);

  // let items: (PostData & PromotionData)[] = res || [];

  if (params?.patient && error) {
    // if (false) {
    // fetch gest fall back
    console.log("GetListFeedContent fallback ");
    const [resGuest, error] = await GetFeedFromStorage(controller, {
      params: { ...params, patient: undefined },
    });
    const itemsGuestId: any[] = resGuest || [];
    const itemsGuest = itemsGuestId.map((item: any, idx: any) => ({
      ...item,
      visible: idx < LIMIT_FEED,
    }));
    controller.setState({
      // feedContentList: itemsGuest,
      allFeedContentList: [...itemsGuest],
      // feedContentListId: itemsGuestId,
      // allFeedContentListId: [...itemsGuestId],
      firstLoadingFeed: true,
      followedHospital: hospital,
    });
  } else {
    HandleSetFeedContentList(controller, { items, hospital });
    controller.setState({
      // feedContentList: items,
      allFeedContentList: [...items],
      // feedContentListId: itemsId,
      // allFeedContentListId: [...itemsId],
      firstLoadingFeed: true,
    });
  }

  GetContentFromID(controller, params);
};

// const end2 = performance.now();
// const timeTaken2 = end2 - start1;

// console.log("timeTaken end2", timeTaken2 / 1000);
// };

const HandleSetFeedContentList: Handler = (controller, params) => {
  let selectedHospital = controller.getState()?.selectedHospital;
  console.log("HandleSetFeedContentList params: ", params);
  console.log("HandleSetFeedContentList selectedHospital: ", selectedHospital);

  // แสดง status follow hospital หน้า feed
  const items = params.items.map((item: any, index: number) => ({
    ...item,
    fake_follow: params.hospital?.ids?.includes(item.hospital?.code),
    visible: index < LIMIT_FEED,
  }));

  controller.setState({
    // feedContentList: items,
    allFeedContentList: [...items],
    preferredContentList: [...items]?.filter(
      (item: any) => item.hospital?.code == selectedHospital?.code
    ),
    followedHospital: params.hospital,
  });
};

const GetFollowedHospital: Handler = async (controller, params) => {
  if (!params.patient) {
    return [];
  }

  const getPost = GetPatientActivity(controller, {
    patient: params.patient,
    collection: POST_PATIENT_ACTIVITY,
  });
  const getPromotion = GetPatientActivity(controller, {
    patient: params.patient,
    collection: PROMOTION_PATIENT_ACTIVITY,
  });

  let [[postRes], [promotionRes]] = await Promise.all([getPost, getPromotion]);

  const mapHospital = (list: any[]) => {
    return (list || []).filter((item: any) => item?.hospital);
  };

  const followedPost = mapHospital(postRes?.follows);
  console.log("GetFollowedHospital followedPost: ", followedPost);
  const followedPromotion = mapHospital(promotionRes?.follows);
  console.log("GetFollowedHospital followedPromotion: ", followedPromotion);

  const hospitalPost = followedPost.map((item) => item.hospital);
  console.log("GetFollowedHospital hospitalPost: ", hospitalPost);
  const hospitalPromotion = followedPromotion.map((item) => item.hospital);
  console.log("GetFollowedHospital hospitalPromotion: ", hospitalPromotion);

  const hospitalList = Array.from(
    new Set([...hospitalPost, ...hospitalPromotion])
  );

  console.log("GetFollowedHospital hospitalList: ", hospitalList);
  return {
    ids: hospitalList,
    post: followedPost,
    promotion: followedPromotion,
  };
};

const GetListFavorite: Handler = async (
  controller,
  params: Params<"GetListFavoriteOrSaved">
) => {
  console.log("GetListFavorite: ");
  const state = controller.getState();

  if (params.patient === undefined) {
    controller.setState({
      favoriteContentList: [],
      firstLoadingFavorite: true,
    });
    return;
  }

  const ids = state.followedHospital?.ids || [];
  const filterFeed = state.allFeedContentList || [];
  // .filter((item) => ids.includes(item.hospital?.code || ""))
  // .map((item) => ({ ...item, fake_follow: true }));

  console.log("GetListFavorite filterFeed: ", filterFeed);
  controller.setState({
    favoriteContentList: filterFeed,
    firstLoadingFavorite: true,
  });
};

export const GetListSaved: Handler = async (controller, params) => {
  console.log("GetListSaved: ", params);

  const state = controller.getState();

  const getPost = GetListContent(controller, {
    collection: POST_PATIENT_ACTIVITY,
    collection_division: POST_DIVISION,
    collection_engagement: POST_ENGAGEMENT,
    patient: params.patient,
    key: "saved",
  });
  const getPromotion = GetListContent(controller, {
    collection: PROMOTION_PATIENT_ACTIVITY,
    collection_division: PROMOTION_DIVISION,
    collection_engagement: PROMOTION_ENGAGEMENT,
    patient: params.patient,
    key: "saved",
  });

  let [[postRes], [promotionRes]] = await Promise.all([getPost, getPromotion]);

  console.log(
    " ---------- !!!!!!!!! ---------- ---------- !!!!!!!!! ---------- ---------- !!!!!!!!! ----------  "
  );
  const ids = state.followedHospital?.ids || [];
  const concat: ContentData[] = [
    ...(postRes || []),
    ...(promotionRes || []),
  ].reduce(
    (result, item) => [
      ...result,
      ...(item.hospital?.code
        ? [
            {
              ...item,
              fake_follow: ids.includes(item.hospital?.code),
              visible: result.length < LIMIT_FEED,
            },
          ]
        : []),
    ],
    []
  );

  let concatList = [...concat];
  console.log("GetListSaved concatList: ", concatList);

  controller.setState({
    savedContentList: concatList,
    firstLoadingSaved: true,
  });
};

export const GetListFavoriteOrSaved: Handler = async (
  controller,
  params: Params<"GetListFavoriteOrSaved">
) => {
  if (params.type === "favorite") {
    GetListFavorite(controller, params);
  }
  if (params.type === "saved") {
    GetListSaved(controller, params);
  }
};

const FeedMergeEngagement: Handler = async (controller, params) => {
  const { collection, data } = params;

  // const q = await controller.db
  //   .collection(collection)
  //   .where("id", "==", data.id)
  //   .get();

  const q = query(collection(controller.db, collection), where("id", "==", data.id));
  const querySnapshot = await getDocs(q);

  const engagement = querySnapshot?.docs?.[0].data();

  return {
    ...data,
    likes: engagement.likes || [],
    clicks: engagement.clicks || [],
    saved: engagement.saved || [],
    shares: engagement.shares || [],
    follows: engagement.follows || [],
    engagement_id: querySnapshot?.docs?.[0].id,
  };
};

export const GetListMarketingContent: Handler = async (controller, params) => {
  try {
    let hostShareURL = "";
    if (CONFIG.SHARE_CONTENT_URL === undefined) {
      console.error("Missing SHARE_CONTENT_URL .. ");
      return;
    } else {
      hostShareURL = CONFIG.SHARE_CONTENT_URL;
    }

    const url = `${CONFIG.SHARE_CONTENT_URL}/api/content/get/${params.contentType}/${params.id}/`;
    // normal fetch

    axios
      .get(url)
      .then(function (response) {
        console.log("response", response);
        if (response?.data) {
          HandleSetMarketingContentDetail(controller, { data: response?.data });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
    // .then(function () {
    //   // always executed
    // });

    // const getPost = controller.db
    //   .collection(POST_DIVISION)
    //   .doc(params.id)
    //   .get();
    // const getPromotion = controller.db
    //   .collection(PROMOTION_DIVISION)
    //   .doc(params.id)
    //   .get();

    // const [postRes, promotionRes] = await Promise.all([getPost, getPromotion]);

    // if (typeof postRes.data() !== "undefined") {
    //   data = await FeedMergeEngagement(controller, {
    //     collection: POST_ENGAGEMENT,
    //     data: {
    //       id: postRes.id,
    //       ...postRes.data(),
    //     },
    //   });
    // } else if (typeof promotionRes.data() !== "undefined") {
    //   data = await FeedMergeEngagement(controller, {
    //     collection: PROMOTION_ENGAGEMENT,
    //     data: {
    //       id: promotionRes.id,
    //       ...promotionRes.data(),
    //     },
    //   });
    // }

    // console.log(data);

    /// Fast API
  } catch (error) {}
};

export const HandleMakeAppointmentContent: Handler = async (
  controller,
  params: Params<"HandleMakeAppointmentContent">
) => {
  controller.setState({ loadingMainFeed: true });
  const { id, doctor, hospital } = params.content;

  controller.analytics.logEvent("action_feed_content", {
    doctor_code: doctor?.code || "",
    document_id: id,
    hospital_code: hospital?.code,
    content_type: "post", // hard code เพราะ เป็น Post เท่านั้นที่จะนัดหมายได้
    type: "appointment",
  });

  const name = doctor?.full_name
    ?.replace(/^.*?\./g, "")
    ?.match(/^.*?(?=\s)/g)?.[0];

  if (name && hospital?.code) {
    const [res, error] = await GetAvailableDoctor(controller as any, {
      name,
      hospital: hospital?.code,
    });

    const findDoctor = (res?.items || []).find(
      (item: any) => item.code === doctor.code
    );

    if (!!findDoctor) {
      params.history.location.state = { contentIdToDate: id };
      HandleSelectedCardItem(controller as any, {
        data: findDoctor,
        history: params.history,
      });
      globalThis.history.go(0);
      return;
    }
  }

  let pathname = `/select-specialty/?app=MobAppointment`;
  if (hospital?.code) {
    pathname = `/select-specialty/?app=MobAppointment&hospital=${hospital.code}`;
  }

  controller.setState({ loadingMainFeed: false });

  params.history.push({ pathname, state: { contentIdToSpecialty: id } });
  globalThis.history.go(0);
};

export const HandleSearchFeedContent: Handler = (controller, params) => {
  // const { allFeedContentList, feedContentList } = controller.getState();
  // const { search } = params;
  // const feedList = (allFeedContentList || []).map((item) => {
  //   const findContent = feedContentList?.find((acc) => acc.id === item.id);
  //   return !!findContent ? findContent : item;
  // });
  // // หัวข้อโพสต์, โรงพยาบาลเจ้าของโพสต์, tag, คลินิก
  // const filter = feedList.filter((item) => {
  //   return (
  //     item.title.includes(search) ||
  //     item.hospital?.name?.includes(search) ||
  //     item.hospital?.name_en?.includes(search) ||
  //     item.tags.includes(search) ||
  //     item.clinic?.name?.includes(search) ||
  //     item.clinic?.name_en?.includes(search)
  //   );
  // });
  // controller.setState({ feedContentList: filter });
};

/* ------------------------- UPDATE ------------------------ */
export const HandleActionFeedContent: Handler = async (
  controller,
  params: Params<"HandleActionFeedContent">
) => {
  const {
    myProfileDetail,
    allFeedContentList,
    selectedMenuBar,
    favoriteContentList,
    preferredContentList,
    savedContentList,
    shareContentTo,
    marketingContentDetail,
    apiToken,
  } = controller.getState();

  console.log("HandleActionFeedContent params: ", params);

  let analyticsLog = {
    content_type: params?.content_type != null ? "post" : "promotion",
    document_id: params?.id,
    type: params.type,
    patient:
      myProfileDetail?.patient !== undefined
        ? myProfileDetail?.patient
        : "anonymous",
    share_content_to: params.type === "share" ? shareContentTo : "",
    user_id: controller.cookies.get("userId") || "anonymous",
    value: 1,
  };
  console.log("analyticsLog: ", analyticsLog);
  controller.analytics.logEvent("action_feed_content", analyticsLog);

  if (!controller.cookies.get("userId")) {
    // No have userId not commu with Storage
    console.log("anonymous use just send ananlytics not hit our storage");
    return;
  }

  // if (params?.type === "reach" || params?.type === "click" || params?.type === "share") {
  //   return ;
  // }

  if (!apiToken) {
    return;
  }
  console.log(
    "HandleActionFeedContent allFeedContentList: ",
    allFeedContentList
  );

  const { age, gender_value, patient } = myProfileDetail || {};

  // let feed = {
  //   feed: {
  //     list: [...(allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  //   preferred: {
  //     list: [...(allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  //   favorite: {
  //     list: [...(allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  //   saved: ,
  // }[selectedMenuBar || "feed"];

  let feed = {
    list: [...(allFeedContentList || [])],
    field: "allFeedContentList",
  };
  console.log("selectedMenuBar: ", selectedMenuBar);

  const index = feed.list.findIndex((item) => item.id === params.id);

  const isFullContent = /\/content\/\w+/g.test(window.location.pathname);

  if (index === -1 && !params.isView) {
    console.warn("should not see me !! params: ", params);
    console.warn("feed.list: ", feed.list);
    console.warn("isFullContent: ", isFullContent);
    return;
  }

  const content = isFullContent ? marketingContentDetail : feed.list[index];
  // console.log("HandleActionFeedContent index: ", index);
  // console.log("HandleActionFeedContent feed.list[index]: ", feed.list[index]);
  // console.log("HandleActionFeedContent marketingContentDetail: ", marketingContentDetail);
  // console.log("HandleActionFeedContent isFullContent: ", isFullContent);
  console.log("HandleActionFeedContent content: ", content);

  // const q = await controller.db
  //   .collection(!params.content_type ? PROMOTION_ENGAGEMENT : POST_ENGAGEMENT)
  //   .doc(content?.engagement_id || "")
  //   .get();

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  const q = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)

    // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
    .child(
      params.content_type
        ? `/ContentEngagement/post_engagement_${content?.content_id}.json`
        : `/ContentEngagement/promotion_engagement_${content?.content_id}.json`
    )
    .getDownloadURL()
    .then((url) => {
      controller.analytics.logEvent("read_firebase_storage", {
        document: params.content_type
          ? "post_engagement_"
          : "promotion_engagement_",
        id: content?.content_id,
        value: 1,
      });

      return axios.get(url);
    })
    .catch((error) => {
      console.warn("get Content Engagement error: ", error);
    });

  const data = { ...(q?.data || {}) } as EngagementData;

  const key = {
    like: "likes",
    click: "clicks",
    save: "saved",
    follow: "follows",
    share: "shares",
    reach: "reach",
  }[params.type] as SaveDataField;

  let items: PostData["likes"] = data[key] || [];
  const toDate = new Date().toISOString();
  let saveData: any = {
    patient,
    date: toDate,
    age,
    gender: gender_value,
  };
  let actionFirebase = {
    type: "arrayUnion",
    data: saveData,
  };

  switch (params.type) {
    case "save":
    case "follow":
    case "like":
      // active ตัวนี่คือ สถานะ ของ ปุ่ม (สีฟ้า/เทา)
      if (params.active) {
        const index = items.findIndex(
          (item) => item.patient === myProfileDetail?.patient
        );

        if (index !== -1) {
          actionFirebase = {
            type: "arrayRemove",
            data: items[index],
          };
          items.splice(index, 1);
        }
      } else {
        items.push(saveData);
      }
      break;
    case "click":
    case "reach":
      items.push(saveData);
      break;
    case "share":
      saveData.share_to = shareContentTo;
      actionFirebase.data.share_to = shareContentTo;
      items.push(saveData);
      break;

    default:
      break;
  }

  data[key] = items;
  console.log("Check data[key] items: ", items);
  console.log("Check data: ", data);

  if (feed.list?.[index]) {
    if (key === "follows") {
      // update follows hospital
      feed.list = HandleActionFollow(controller, {
        feedType: !params.content_type ? "promotion" : "post",
        list: feed.list,
        content,
        active: params.active,
        menuBar: selectedMenuBar,
      });
    } else {
      feed.list[index] = {
        ...feed.list[index],
        [key]: items,
      };
    }

    console.log("feed.list : ", feed.list);
    console.log("feed.field: ", feed.field);

    if (["likes", "saved", "follows"]?.includes(key)) {
      controller.setState({ [feed.field]: feed.list }); /// ทำแค่ list ปัจจุบัน
    }
  }

  if (params.isView) {
    controller.setState({
      marketingContentDetail: { ...(content || {}), ...data } as any,
    });
  }

  if (params.type === "share") {
    controller.setState({ shareContentTo: "", shareto: "" });
  }

  // console.log('followedHospital: ', followedHospital);
  const { followedHospital } = controller.getState();
  // Update
  updatePatientActivity(controller, {
    ...params,
    key,
    feedDetail: { ...(content || {}), ...data },
    toDate,
  });

  if (key !== "follows") {
    updateContentEngagement(controller, {
      ...params,
      key,
      actionFirebase,
      engagementId: content?.engagement_id,
      content_id: content?.content_id,
    });
  }
};

const HandleActionFollow: Handler = (
  controller,
  params: {
    list: ContentData[];
    content: ContentData;
    active: boolean;
    menuBar: MenuBarType;
    feedType: "post" | "promotion";
  }
) => {
  console.log("HandleActionFollow params: ", params);
  const { followedHospital, allFeedContentList } = controller.getState();
  console.log("HandleActionFollow allFeedContentList: ", allFeedContentList);
  console.log("HandleActionFollow followedHospital: ", followedHospital);

  let items = params.list;

  console.log("HandleActionFollow items: ", items);
  console.log("HandleActionFollow params.menuBar: ", params.menuBar);

  // if (params.menuBar === "favorite" && followedHospital) {
  if (false) {
    // หน้า favorite ( followed ) // remove อย่างเดียว
    // อยู่ Tab favorite + followedHosptial arrays
    const code = params.content.hospital.code; // ดึง hospital ออกจาก content

    const removeFollowed = {
      [params.feedType]: [
        {
          hospital: code,
        },
      ],
    };

    //  ไม่ส่ง actionFirebase // ไม่ อัพเดท state
    const followed = HandleRemoveFollowedHospital(controller, {
      feedType: params.feedType,
      removeFollowed,
      actionFirebase: {},
      hospital: code,
      returnOnly: true,
    });

    console.log("HandleActionFollow update followed: ", followed);

    controller.setState({ followedHospital: followed });

    items = (allFeedContentList || [])
      .filter((item) => followed.ids.includes(item.hospital?.code || ""))
      .map((item, index) => ({
        ...item,
        fake_follow: true, // ผู้ใช้อาจไม่ได้กด ที่ content นั้น แต่ ถือว่า follow เพราะ มัน follow ในแกนโรงพยาบาล
        visible: index < LIMIT_FEED,
      }));
  } else {
    // อยู่ใน หน้าอื่นๆ ที่ไม่ใช่ followed  // อาจจะ ADD / REMOVE

    const followed = HandleUpdateFollowedHospital(controller, {
      feedType: params.feedType,
      hospitalCode: params.content.hospital.code,
      content: params.content,
    });
    console.log("HandleActionFollow update followed: ", followed);

    const code = params.content.hospital.code;
    // ทำสี ให้ถุกต้องใน Tab นั้น
    items = params.list.map((item) => {
      console.log("followed.ids", followed.ids);
      console.log("item.hospital.code", item?.hospital?.code);
      console.log("params.active", params.active);
      console.log("params", params);
      return {
        ...item,
        fake_follow: item?.hospital
          ? (followed.ids || [])?.includes(item?.hospital?.code)
          : false,
      };
    });

    // ปรับ state ด้วย

    controller.setState({ followedHospital: followed });
  }

  return items;
};

const updatePatientActivity: Handler = async (
  controller,
  params: Params<"HandleActionFeedContent"> & {
    key: SaveDataField;
    feedDetail: PostData & PromotionData;
    toDate: any;
  }
) => {
  const { myProfileDetail, followedHospital } = controller.getState();
  const { patient } = myProfileDetail || {};
  const { id, ...data } = params.feedDetail;

  console.log("updatePatientActivity params:", params);
  if (!myProfileDetail?.patient) {
    return;
  }

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  console.log(" updatePatientActivity");
  let result: any = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/feed/dbjson/post_patient_activity_129620.json')
    .child(
      params.content_type
        ? `/PatientActivity/post_patient_activity_${myProfileDetail?.patient}.json`
        : `/PatientActivity/promotion_patient_activity_${myProfileDetail?.patient}.json`
    )
    .getDownloadURL()
    .then(
      (url) => {
        controller.analytics.logEvent("read_firebase_storage", {
          document: params.content_type
            ? "post_patient_activity"
            : "promotion_patient_activity",
          patient: myProfileDetail?.patient,
          value: 1,
        });

        return axios.get(url).catch((error) => error);
      },
      (error) => {
        console.log(" error", error);
      }
    )
    .catch((error) => {
      console.warn("get Content Engagement error: ", error);
    });

  console.log("2 result: ", result);

  // let q = result.data

  // const q = await controller.db
  //   .collection(
  //     !params.content_type ? PROMOTION_PATIENT_ACTIVITY : POST_PATIENT_ACTIVITY
  //   )
  //   .where("patient", "==", myProfileDetail?.patient || "")
  //   .get();

  let saveData: any = {
    feed_id: params.id,
    date: params.toDate,
    ...(params.feedDetail?.hospital && {
      hospital: params.feedDetail?.hospital,
    }),

    // TODO: !
  };
  let actionFirebase = {
    type: "arrayUnion" as "arrayUnion" | "arrayRemove",
    data: saveData,
  };
  const removeFollowed = {
    post: [] as any[],
    promotion: [] as any[],
  };
  const feedType = !params.content_type ? "promotion" : "post";
  const oppositeType = feedType === "post" ? "promotion" : "post";

  if (result?.data) {
    console.log(" result.data", result?.data);
    let items = (result.data[params.key] || []) as (
      | string
      | null
      | { feed_id: string; hospital: string | null }
    )[];

    switch (params.type) {
      case "follow":
        const filterHospital = (items?: any[]) => {
          return (items || []).filter(
            (item) =>
              typeof item !== "string" && item?.hospital === data.hospital?.code
          );
        };

        const index = items.findIndex(
          (item) => typeof item !== "string" && item?.feed_id === params.id
        );
        if (index !== -1) {
          actionFirebase = {
            type: "arrayRemove",
            data: items[index],
          };
        }

        const { followedHospital } = controller.getState();
        // เพื่อลบการติดตามทั้งหมด
        removeFollowed.post = filterHospital(followedHospital?.post);
        removeFollowed.promotion = filterHospital(followedHospital?.promotion);
        console.log("updatePatientActivity data: ", data);
        // เพื่อบันทึกการติดตาม
        if (data.hospital?.code && !params.active) {
          actionFirebase.data = {
            date: params.toDate,
            feed_id: params.id,
            hospital: data.hospital?.code,
          };
        }
        break;
      case "save":
      case "like":
        if (params.active) {
          const index = items.findIndex((item) =>
            typeof item === "string"
              ? item === params.id
              : item?.feed_id === params.id
          );

          if (index !== -1) {
            actionFirebase = {
              type: "arrayRemove",
              data: items[index],
            };
          }
        } else {
        }
        break;
      case "click":
      case "reach":
      case "share":
        break;

      default:
        break;
    }

    // const Fieldvalue = firebase.firestore.FieldValue;

    // console.log(actionFirebase, result.data.id);
    // console.log("params.key", params.key);

    let json: any = result.data;
    console.log("removeFollowed: ", removeFollowed);
    console.log("Before Add json: ", json);
    console.log("params.key: ", params.key);
    console.log("actionFirebase: ", actionFirebase);
    console.log(
      "actionFirebase?.data?.feed_id: ",
      actionFirebase?.data?.feed_id
    );

    json = {
      ...json,
      [params.key]:
        actionFirebase.type === "arrayUnion"
          ? (json[params.key] || [])?.find(
              (item: any) => item.feed_id === actionFirebase?.data?.feed_id
            )
            ? json[params.key] || []
            : [...(json[params.key] || []), actionFirebase.data]
          : (json[params.key] || [])?.filter(
              (item: any) => item?.feed_id !== actionFirebase?.data?.feed_id
            ),
    };

    console.log("After Add json: ", json);

    const jsonString = JSON.stringify(json);
    const blob = new Blob([jsonString], { type: "application/json" });
    await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      // .child('/feed/dbjson/post_patient_activity_129620.json')
      .child(
        params.content_type
          ? `/PatientActivity/post_patient_activity_${myProfileDetail?.patient}.json`
          : `/PatientActivity/promotion_patient_activity_${myProfileDetail?.patient}.json`
      )
      .put(blob)
      .then(() => {
        controller.analytics.logEvent("write_firebase_storage", {
          document: params.content_type
            ? "post_patient_activity"
            : "promotion_patient_activity",
          patient: myProfileDetail?.patient,
          value: 1,
        });

        console.log(" Finish upload to storage");
      });

    console.log(" --- ");
    // await docRef.update({
    //   [params.key]:
    //     actionFirebase.type === "arrayUnion"
    //       ? Fieldvalue.arrayUnion(actionFirebase.data)
    //       : Fieldvalue.arrayRemove(actionFirebase.data),
    // });

    params.onSuccess?.();

    // if (params.type === "follow") {
    //   console.log("Follow !!!  HandleRemoveFollowedHospital ")
    //   console.log('Follow !!!  HandleRemoveFollowedHospital feedType: ', feedType);
    //   console.log('Follow !!!  HandleRemoveFollowedHospital removeFollowed: ', removeFollowed);
    //   console.log('Follow !!!  HandleRemoveFollowedHospital actionFirebase: ', actionFirebase);
    //   console.log('Follow !!!  HandleRemoveFollowedHospital hospital: ', data.hospital?.code);
    //   HandleRemoveFollowedHospital(controller, {
    //     feedType,
    //     removeFollowed,
    //     actionFirebase,
    //     hospital: data.hospital?.code,
    //     onSuccess: params.onSuccess,
    //   });
    // } else {
    //   params.onSuccess?.();
    // }
  } else {
    if (params.key === "follows" && !data.hospital?.code) {
      params.onSuccess?.();
      return;
    } else if (params.key === "follows") {
      saveData = { feed_id: saveData, hospital: data.hospital.code };
    }

    let newJson = {
      patient,
      [params.key]: !params.active ? [saveData] : [],
    };

    const jsonString = JSON.stringify(newJson);
    console.log("jsonString: ", jsonString);

    console.log("  config?.gsBucketName: ", config?.gsBucketName);

    console.log("params.content_type: ", params.content_type);
    const blob = new Blob([jsonString], { type: "application/json" });
    await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      // .child('/feed/dbjson/post_patient_activity_129620.json')
      .child(
        params.content_type
          ? `/PatientActivity/post_patient_activity_${patient}.json`
          : `/PatientActivity/promotion_patient_activity_${patient}.json`
      )
      .put(blob)
      .then(
        () => {
          controller.analytics.logEvent("write_firebase_storage", {
            document: params.content_type
              ? "post_patient_activity"
              : "promotion_patient_activity",
            patient: patient,
            value: 1,
          });

          console.log(" Finish upload to storage");
        },
        (error) => {
          console.log(" Error", error);
        }
      );
    params.onSuccess?.();

    return;
    await controller.db
      .collection(
        !params.content_type
          ? PROMOTION_PATIENT_ACTIVITY
          : POST_PATIENT_ACTIVITY
      )
      .add({
        patient,
        [params.key]: !params.active ? [saveData] : [],
      });

    params.onSuccess?.();
  }
};

export const updateContentEngagement: Handler = async (
  controller,
  params: Params<"HandleActionFeedContent"> & {
    key: "likes" | "clicks" | "saved" | "follows" | "shares";
    engagementId?: string;
    content_id: string;
    actionFirebase: { type: string; data: any };
  }
) => {
  const { actionFirebase } = params;
  console.log("updateContentEngagement params: ", params);

  if (!actionFirebase?.data?.patient) {
    return;
  }

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  let json = null;
  let data: any = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
    .child(
      params.content_type
        ? `/ContentEngagement/post_engagement_${params.content_id}.json`
        : `/ContentEngagement/promotion_engagement_${params.content_id}.json`
    )
    .getDownloadURL()
    .then((url) => {
      controller.analytics.logEvent("read_firebase_storage", {
        document: params.content_type
          ? "post_engagement"
          : "promotion_engagement",
        content: params.content_id,
        value: 1,
      });

      return axios.get(url);
    })
    .catch((error) => {
      switch (error.code) {
        case "storage/object-not-found":
          // File doesn't exist
          console.log("New Create Files ");
          json = { id: `${params.content_id}` };
          break;
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;

        // ...

        case "storage/unknown":
          // Unknown error occurred, inspect the server response
          break;
      }
      console.warn(error);
    });

  // const docRef = controller.db
  //   .collection(!params.content_type ? PROMOTION_ENGAGEMENT : POST_ENGAGEMENT)
  //   .doc(params.engagementId);

  if (data?.data) {
    json = data?.data;
  } else {
    // new file
    // json already prepare
  }

  console.log("Engagement json: ", json);

  if (json) {
    // success
    console.log("UpdateContent actionFirebase", actionFirebase.data);
    // const Fieldvalue = firebase.firestore.FieldValue;
    json = {
      ...json,
      [params.key]:
        actionFirebase.type === "arrayUnion"
          ? (json[params.key] || [])?.find(
              (item: any) => item.patient === actionFirebase.data.patient
            )
            ? json[params.key] || []
            : [...(json[params.key] || []), actionFirebase.data]
          : (json[params.key] || [])?.filter(
              (item: any) => item.patient !== actionFirebase.data.patient
            ),
    };

    const jsonString = JSON.stringify(json);
    console.log("Prepare for save jsonString: ", jsonString);
    const blob = new Blob([jsonString], { type: "application/json" });
    await controller.storage
      .refFromURL(`${config?.gsBucketName}`)
      // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
      .child(
        params.content_type
          ? `/ContentEngagement/post_engagement_${params.content_id}.json`
          : `/ContentEngagement/promotion_engagement_${params.content_id}.json`
      )
      .put(blob)
      .then(
        () => {
          controller.analytics.logEvent("write_firebase_storage", {
            document: params.content_type
              ? "post_engagement"
              : "promotion_engagement",
            content: params.content_id,
            value: 1,
          });
          let msg = params.content_type
            ? `/ContentEngagement/post_engagement_${params.content_id}.json`
            : `/ContentEngagement/promotion_engagement_${params.content_id}.json`;

          console.log(" Finish upload to storage :", msg);
        },
        (error) => {
          console.error(" Can't upload");
        }
      );
  } else {
    console.error(" Should not see me !! ");
  }

  // docRef.update({
  //   [params.key]:
  //     actionFirebase.type === "arrayUnion"
  //       ? Fieldvalue.arrayUnion(actionFirebase.data)
  //       : Fieldvalue.arrayRemove(actionFirebase.data),
  // });
};

const HandleRemoveFollowed: Handler = async (controller, params) => {
  if (!params.removeList.length) {
    return;
  }

  const { myProfileDetail } = controller.getState();

  const collectionName =
    params.type === "promotion"
      ? PROMOTION_PATIENT_ACTIVITY
      : POST_PATIENT_ACTIVITY;


  const q = query(collection(controller.db, collectionName), where("patient", "==", myProfileDetail?.patient || ""));
  const querySnapshot = await getDocs(q);

  // const q = await controller.db
  //   .collection(collectionName)
  //   .where("patient", "==", myProfileDetail?.patient || "")
  //   .get();

  if (!!querySnapshot?.docs.length) {

    const docRef = doc(controller.db, collectionName, querySnapshot?.docs[0].id as any);

    // const docRef = controller.db
    //   .collection(collectionName)
    //   .doc(querySnapshot?.docs[0].id as any);

    const Fieldvalue = firebase.firestore.FieldValue;

    await updateDoc(docRef, {
      follows: Fieldvalue.arrayRemove(...params.removeList),
    });

    // docRef.update({
    //   follows: Fieldvalue.arrayRemove(...params.removeList),
    // });
  }
};

const HandleUpdateFollowedHospital: Handler = (controller, params) => {
  const { followedHospital } = controller.getState();
  const { hospitalCode, feedType, content } = params;

  console.log(
    "HandleUpdateFollowedHospital followedHospital: ",
    followedHospital
  );
  console.log("HandleUpdateFollowedHospital hospitalCode: ", hospitalCode);

  const hospitalCodeString = hospitalCode?.toString();

  let updateFollow: State["followedHospital"] = {
    ids: [...(followedHospital?.ids || [])],
    post: [...(followedHospital?.post || [])],
    promotion: [...(followedHospital?.promotion || [])],
  };

  if (followedHospital?.ids?.includes(hospitalCode?.toString())) {
    // remove

    updateFollow.ids = Array.from(
      new Set(updateFollow?.ids?.filter((id) => id !== hospitalCodeString))
    );
    updateFollow.post = updateFollow?.post?.filter(
      (item) => item.hospital !== hospitalCodeString
    );
    updateFollow.promotion = updateFollow?.promotion?.filter(
      (item) => item.hospital !== hospitalCodeString
    );
    console.log("HandleUpdateFollowedHospital updateFollow: ", updateFollow);

    return updateFollow;
  } else {
    // add
    updateFollow.ids = [...updateFollow.ids, hospitalCodeString];
    if (feedType === "post") {
      updateFollow.post = [
        ...(updateFollow.post || []),
        {
          date: new Date().toISOString(),
          feed_id: content.id,
          hospital: hospitalCodeString,
        },
      ];
    } else {
      updateFollow.promotion = [
        ...(updateFollow.promotion || []),
        {
          date: new Date().toISOString(),
          feed_id: content.id,
          hospital: hospitalCodeString,
        },
      ];
    }
    console.log("HandleUpdateFollowedHospital updateFollow: ", updateFollow);
    return updateFollow;
  }
};

const HandleRemoveFollowedHospital: Handler = (controller, params) => {
  const { followedHospital } = controller.getState();
  const { removeFollowed, actionFirebase, hospital, feedType } = params;

  console.log("HandleRemoveFollowedHospital feedType: ", feedType);
  console.log("HandleRemoveFollowedHospital actionFirebase: ", actionFirebase);
  console.log(
    "HandleRemoveFollowedHospital followedHospital: ",
    followedHospital
  );
  console.log("HandleRemoveFollowedHospital removeFollowed: ", removeFollowed);

  if (followedHospital) {
    // filter ถ้าเจอ ซ้ำ กันเอามา remove
    const filterHospital = (key: "ids" | "post" | "promotion") => {
      const removeIds = [
        ...(removeFollowed.post || []),
        ...(removeFollowed.promotion || []),
      ].map((item) => item.hospital);

      console.log(
        "HandleRemoveFollowedHospital filterHospital removeIds: ",
        removeIds
      );

      let a = followedHospital[key].filter((value) =>
        typeof value === "string"
          ? !removeIds.includes(value)
          : !removeIds.includes(value.hospital)
      );
      console.log("HandleRemoveFollowedHospital a: ", a);

      return a;
    };

    const followed = {
      ids: filterHospital("ids"),
      post: filterHospital("post"),
      promotion: filterHospital("promotion"),
    };
    console.log("HandleRemoveFollowedHospital followed: ", followed);

    if (actionFirebase?.data?.hospital) {
      // delete ที่ firebase ถ้าส่ง actionFirebase
      followed.ids = [...followed.ids, hospital];
      (followed as any)[feedType] = [
        ...(followed as any)[feedType],
        {
          ...actionFirebase.data,
          date: firebase.firestore.Timestamp.fromDate(
            new Date(actionFirebase.data.date)
          ),
        },
      ];
    }

    if (!params.returnOnly) {
      const state = controller.getState();
      console.log("HandleRemoveFollowedHospital update state !! ");
      controller.setState(
        {
          followedHospital: { ...followed },
          allFeedContentList: state.allFeedContentList?.map((item: any) => ({
            ...item,
            fake_follow: followed?.ids?.includes(
              item.hospital?.code?.toString()
            ),
          })),
          // feedContentList: state.feedContentList?.map((item: any) => ({
          //   ...item,
          //   fake_follow: followed?.ids?.includes(
          //     item.hospital?.code?.toString()
          //   ),
          // })),
          // savedContentList: state.savedContentList?.map((item: any) => ({
          //   ...item,
          //   fake_follow: followed?.ids?.includes(
          //     item.hospital?.code?.toString()
          //   ),
          // })),
          // preferredContentList: state.preferredContentList?.map(
          //   (item: any) => ({
          //     ...item,
          //     fake_follow: followed?.ids?.includes(
          //       item.hospital?.code?.toString()
          //     ),
          //   })
          // ),
        },
        () => params.onSuccess?.()
      );
    }

    return followed;
  }

  return followedHospital;
};

export const HandleSaveReach: Handler = async (controller, params) => {
  console.error("Should not see me !!! HandleSaveReach");

  const collectionName = !params.content_type
    ? PROMOTION_ENGAGEMENT
    : POST_ENGAGEMENT;

  // const q = query(collection(db, "cities"), where("capital", "==", true));

  const q = query(collection(controller.db, collectionName), where("id", "==", params.id));
  const querySnapshot = await getDocs(q);

  // const q = await controller.db
  //   .collection(collectionName)
  //   .where("id", "==", params.id)
  //   .get();
  params.data.date = moment(params.data.date).toDate();

  if (querySnapshot?.docs[0]?.id) {

    const docRef = doc(controller.db, collectionName, querySnapshot?.docs[0]?.id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        reach: firebase.firestore.FieldValue.arrayUnion(params.data),
      });
    // const docRef = controller.db.collection(collectionName).doc(querySnapshot?.docs[0]?.id);
    // docRef.update({
    //   reach: firebase.firestore.FieldValue.arrayUnion(params.data),
    // });

  } else {

    const docRef = await addDoc(collection(controller.db, collectionName), {
      id: params.id,
      reach: [params.data],
    });
    console.log("Document written with ID: ", docRef.id);

    // await controller.db.collection(collectionName).add({
    //   id: params.id,
    //   reach: [params.data],
    // });

  }
};

/* ------------------------- SET ------------------------ */
export const HandleSetMarketingContentDetail: Handler = (
  controller,
  params
) => {
  controller.setState({ marketingContentDetail: params.data });
};

export const HandleSetShareContentTo: Handler = (controller, params) => {
  controller.setState({ shareContentTo: params.value });
};

export const HandleShareContent: Handler = (
  controller,
  params: Params<"HandleShareContent">
) => {
  const data = params.data;
  let url: string = "";

  if (data.type === "url") {
    url = `${data?.url}`;
  } else if (data.type === "video") {
    url = `${data?.youtube_url}`;
  } else {
    // To FastAPI
    if (data?.type) {
      // Post
      url = `${CONFIG.SHARE_CONTENT_URL}/content/post/${data.id}`;
    } else {
      // Promotion
      url = `${CONFIG.SHARE_CONTENT_URL}/content/promotion/${data.id}`;
    }
    // url = `${window.origin}/content/${data.id}/?app=MobFeed`;
  }

  controller.handleEvent({
    message: "shareContent",
    params: {
      contentUrl: url,
    },
  });
};

export const HandleVisibleConsent: Handler = (controller, params) => {
  const state = controller.getState();
  const limit = LIMIT_FEED + params.index + 1;
  console.log("1 HandleVisibleConsent limit: ", limit);
  console.log("1 HandleVisibleConsent params: ", params);

  let feed = {
    list: [...(state.allFeedContentList || [])],
    field: "allFeedContentList",
  };
  // ,
  //   preferred: {
  //     list: [...(state.allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  //   favorite: {
  //     list: [...(state.allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  //   saved: {
  //     list: [...(state.allFeedContentList || [])],
  //     field: "allFeedContentList",
  //   },
  // }[state.selectedMenuBar || "feed"];

  let debug = feed.list?.map((item: any) => item.visible);
  console.log("1 HandleVisibleConsent debug: ", debug);
  let debug2 = feed.list?.map((item: any) => item.active);
  console.log("1 HandleVisibleConsent debug2: ", debug2);
  if (feed.list?.[limit]?.visible) {
    console.log(
      "1 HandleVisibleConsent ignore !!! params.index : ",
      params.index,
      " limit: ",
      limit
    );
    return;
  }
  console.log(
    "1 HandleVisibleConsent process !!! params.index : ",
    params.index,
    " limit: ",
    limit
  );

  const needUpdate = feed.list?.find((item: any, index: any) => {
    if (index <= limit && item.visible !== true) {
      return true;
    } else {
      return false;
    }
  });
  console.log("HandleVisibleConsent needUpdate: ", needUpdate);

  feed.list = feed.list.map((item, index) => ({
    ...item,
    visible: index <= limit,
  }));

  console.log("HandleVisibleConsent feed.list: ", feed.list);
  // feedId.list = feed.list.map((item, index) => ({
  //   ...item,
  //   visible: index <= limit,
  // }));

  controller.setState({ [feed.field]: feed.list }, () => {
    console.log("HandleVisibleConsent feed ", feed);
    console.log("HandleVisibleConsent feed.list: ", feed.list);
    console.log("HandleVisibleConsent feed.field: ", feed.field);
    // console.log("HandleVisibleConsent feedId ", feedId)
    // console.log('HandleVisibleConsent feedId.field: ', feedId.list);
    console.log(
      "1 HandleVisibleConsent GetContentFromID params: ",
      params,
      " limit: ",
      limit
    );
    if (needUpdate) {
      GetContentFromID(controller, params);
    }
  });
};

const ClinicContentDivision = [
  {
    description:
      "การล็อกดาวน์ในช่วงโควิด-19 ที่หลายคนต้องอยู่แต่บ้านแทบไม่ได้ออกไปไหน ไม่ได้ขยับ Work From Home ทั้งวัน สั่งอาหารเดลิเวอรีทั้งอาหารหวาน มัน เค็มมารับประทาน ทำให้เกิดความเสี่ยงโรคเบาหวาน ความดันโลหิตสูง อ้วนลงพุง ไขมันพอกตับได้ แม้จะผ่อนคลายล็อกดาวน์ก็ต้องไม่คลายความฟิตเพื่อสุขภาพที่ดี",
    banners:
      "https://cdn.bangkokhospital.com/2021/10/IHL-คลายล็อกไม่คลายฟิต-OG-th-600x315.jpg",
    title: "คลายล็อกไม่คลายฟิต",
    hospital: "Bangkok Hospital",
    type: "article",
  },
  {
    image_url:
      "https://cdn.bangkokhospital.com/2021/10/IHL-คลายล็อกไม่คลายฟิต-OG-th-600x315.jpg",
    title: "คลายล็อกไม่คลายฟิต",
    hospital: "Bangkok Hospital",
    type: "url",
  },
  {
    short_description:
      "โรงพยาบาลกรุงเทพ สำนักงานใหญ่ให้บริการฉีดวัคซีน โควิด-19 สำหรับชาวฝรั่งเศส",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F644fa28b-d487-4192-8ff1-8950a6dfb025.png?alt=media&token=684e386b-9846-4c19-8af8-8f5daccea09c",
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F0fffc71e-9462-46c3-96a2-c1f242ccb646.png?alt=media&token=4a2dacb9-d9ee-4f7d-952d-d42dfd47c64b",
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F0fffc71e-9462-46c3-96a2-c1f242ccb646.png?alt=media&token=4a2dacb9-d9ee-4f7d-952d-d42dfd47c64b",
    ],
    title: "ต่อมไขมันที่เปลือกตาอุดตัน หนึ่งในตัวการโรคตาแห้ง",
    hospital: "Bangkok Hospital",
    type: "picture",
  },
  {
    short_description:
      "โรงพยาบาลกรุงเทพ สำนักงานใหญ่ให้บริการฉีดวัคซีน โควิด-19 สำหรับชาวฝรั่งเศส",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F644fa28b-d487-4192-8ff1-8950a6dfb025.png?alt=media&token=684e386b-9846-4c19-8af8-8f5daccea09c",
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F0fffc71e-9462-46c3-96a2-c1f242ccb646.png?alt=media&token=4a2dacb9-d9ee-4f7d-952d-d42dfd47c64b",
    ],
    title: "ต่อมไขมันที่เปลือกตาอุดตัน หนึ่งในตัวการโรคตาแห้ง",
    hospital: "Bangkok Hospital",
    type: "picture",
  },
  {
    short_description:
      "โรงพยาบาลกรุงเทพ สำนักงานใหญ่ให้บริการฉีดวัคซีน โควิด-19 สำหรับชาวฝรั่งเศส",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/mybplus-promotion/o/images%2F644fa28b-d487-4192-8ff1-8950a6dfb025.png?alt=media&token=684e386b-9846-4c19-8af8-8f5daccea09c",
    ],
    title: "ต่อมไขมันที่เปลือกตาอุดตัน หนึ่งในตัวการโรคตาแห้ง",
    hospital: "Bangkok Hospital",
    type: "picture",
  },
  {
    short_description:
      "โรงพยาบาลกรุงเทพ สำนักงานใหญ่ให้บริการฉีดวัคซีน โควิด-19 สำหรับชาวฝรั่งเศส",
    youtube_url: "https://www.youtube.com/watch?v=oOAdlI5NAsk",
    title: "ต่อมไขมันที่เปลือกตาอุดตัน หนึ่งในตัวการโรคตาแห้ง",
    hospital: "Bangkok Hospital",
    type: "video",
  },
  {
    description: "จบทุกความกังวลด้วยแพ็กเกจคลอด “เหมาจ่าย” Kiss my baby",
    banners: "/images/expansion/Kiss_my_baby.png",
    title: "แพ็กเกจคลอด “เหมาจ่าย” Kiss my baby",
    hospital: "Bangkok Hospital Phuket",
  },
];
