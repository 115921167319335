import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
// CSS
import {
  Dimmer,
  Dropdown,
  Grid,
  Loader,
  Image,
  Segment,
  Header,
} from "semantic-ui-react";

import { Bar } from "react-chartjs-2";
import moment from "moment";
import ReactTable from "react-table-6";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import {
  HeaderChart,
  FooterChartCustom,
  spliceChartData,
} from "bplus-lib/clinic/DMEmotionRestChart";
import { drawStarChart, displayNoteChart } from "../../clinic/DMBloodPressure";
import { columnsBMI, BMI_STATUS } from "../../clinic/DMWeight";

import {
  BACKEND_DATE_FORMAT,
  BE_DATE_FORMAT,
  checkWeightStatus,
} from "./../../MobClinicInterface";

const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  },
  boxTable: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "55px",
  },
};

type WebDMWeightProps = {
  // function
  onEvent: any;
  // data
  selectedPatient?: any;
  hospitalWeightList: any;
  weightChartList: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
};

const WebDMWeightInitial: WebDMWeightProps = {
  // function
  onEvent: () => null,
  // data
  selectedPatient: {},
  hospitalWeightList: {},
  weightChartList: {},
};

type MonitorType<T> = {
  hospital: T;
  self: T;
};

const WebDMWeight: React.FC<WebDMWeightProps> = (props) => {
  const [duration, setDuration] = useState<MonitorType<string>>({
    hospital: "7_days",
    self: "7_days",
  });
  const [startDate, setStartDate] = useState<MonitorType<moment.Moment>>({
    self: moment().add("-7", "days"),
    hospital: moment().add("-7", "days"),
  });
  const [endDate, setEndDate] = useState<MonitorType<moment.Moment>>({
    self: moment(),
    hospital: moment(),
  });

  const [hospitalWeight, setHospitalWeight] = useState<any>({
    data: { labels: [], datasets: [] },
    options: {},
  });
  const [selfWeight, setSelfWeight] = useState<any>({
    data: { labels: [], datasets: [] },
    options: {},
  });
  const [hospitalIsSeeAll, setHospitalIsSeeAll] = useState<boolean>(false);
  const [hospitalSkipIndex, setHospitalSkipIndex] = useState<number>(0);
  const [selfIsSeeAll, setSelfIsSeeAll] = useState<boolean>(false);
  const [selfSkipIndex, setSelfSkipIndex] = useState<number>(0);

  const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>;

  const labelLimit = 7;

  useEffect(() => {
    props.onEvent({
      message: "GetWeightChart",
      params: {
        start_measure: startDate.self.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.self.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.selectedPatient, startDate.self]);

  useEffect(() => {
    props.onEvent({
      message: "GetHospitalWeightChart",
      params: {
        start_date: startDate.hospital.format(BACKEND_DATE_FORMAT),
        end_date: endDate.hospital.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.selectedPatient, startDate.hospital]);

  useEffect(() => {
    const durationArr: any[] = duration.hospital.split("_");
    setStartDate({
      ...startDate,
      hospital: moment().add(`-${durationArr[0]}`, durationArr[1]),
    });
    setEndDate({ ...endDate, hospital: moment() });
  }, [duration.hospital]);

  useEffect(() => {
    const durationArr: any[] = duration.self.split("_");
    setStartDate({
      ...startDate,
      self: moment().add(`-${durationArr[0]}`, durationArr[1]),
    });
    setEndDate({ ...endDate, self: moment() });
  }, [duration.self]);

  useEffect(() => {
    let labels: any[] = [];
    let datas: any[] = [];
    if (props.hospitalWeightList.length > 0) {
      props.hospitalWeightList?.forEach((item: any) => {
        labels.push(
          moment(item.measurement_date, "DD/MM/YYYY").format("MMM DD,YYYY")
        );
        datas.push(item.result);
      });
    }
    setHospitalWeight({
      data: {
        labels: labels,
        datasets: [{ label: "BMI", data: datas, backgroundColor: "#00299c" }],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.floor(Math.max(...datas) + 10),
                min: Math.ceil(Math.min(...datas) - 10),
              },
            },
          ],
          xAxes: [
            {
              maxBarThickness: 70,
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });
  }, [props.hospitalWeightList]);

  useEffect(() => {
    let labels: any[] = [];
    let datas: any[] = [];
    const realData: any[] = [];
    if (props.weightChartList) {
      props.weightChartList?.items?.forEach((item: any) => {
        labels.push(
          moment(item.created_at, BACKEND_DATE_FORMAT).format("MMM DD,YYYY")
        );
        datas.push(item.data.bmi);
        realData.push(item);
      });
    }
    setSelfWeight({
      data: {
        labels: labels,
        datasets: [
          { label: "BMI", data: datas, backgroundColor: "#ff6ec2", realData },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              if (tooltipItem.value) {
                let bmi = +tooltipItem.value;
                const weightStatus = checkWeightStatus(bmi);

                return `BMI: ${weightStatus.bmi} ${weightStatus.status}`;
              }
              return tooltipItem.value;
            },
            labelColor: function (tooltipItem: any, data: any) {
              if (tooltipItem.datasetIndex === 0) {
                if (tooltipItem.value) {
                  let bmi = +tooltipItem.value;
                  console.log("BMI set: ", bmi);

                  return {
                    borderColor: checkWeightStatus(bmi).color,
                    backgroundColor: checkWeightStatus(bmi).color,
                  };
                }
                return {};
              }
            },
            labelTextColor: function (tooltipItem: any, data: any) {
              if (tooltipItem.datasetIndex === 0) {
                if (tooltipItem.value) {
                  let bmi = +tooltipItem.value;
                  console.log("BMI set: ", bmi);

                  return checkWeightStatus(bmi).color;
                }
                return {};
              }
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.floor(Math.max(...datas) + 10),
                min: Math.ceil(Math.min(...datas) - 10),
              },
            },
          ],
          xAxes: [
            {
              maxBarThickness: 70,
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });
  }, [props.weightChartList]);

  const hospitalDurationOptions = useMemo(
    () => [
      { key: 1, value: "5_days", text: "5 days" },
      { key: 2, value: "7_days", text: "7 days" },
      { key: 3, value: "10_days", text: "10 days" },
      { key: 4, value: "3_years", text: "All" },
    ],
    []
  );

  const durationOptions = useMemo(
    () => [
      { key: 1, value: "5_days", text: "5 days" },
      { key: 2, value: "7_days", text: "7 days" },
      { key: 3, value: "10_days", text: "10 days" },
    ],
    []
  );

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;
        },
        afterDatasetsDraw: (chart: any) => {
          drawStarChart({
            chart,
            allowIndex: [0],
            noteField: "realData.data",
          });
        },
        beforeEvent: (chart: any, e: any) => {
          displayNoteChart({
            chart,
            event: e,
            allowIndex: [0],
            noteField: "realData.data",
            tooltipRef: tooltipRef.current,
          });
        },
      },
    ];
  }, []);

  const subSelfChartData = useMemo(() => {
    return spliceChartData({
      data: selfWeight.data,
      index: selfSkipIndex,
      limit: labelLimit,
    });
  }, [selfWeight.data, selfSkipIndex]);

  const subHospitalChartData = useMemo(() => {
    return spliceChartData({
      data: hospitalWeight.data,
      index: hospitalSkipIndex,
      limit: labelLimit,
    });
  }, [hospitalWeight.data, hospitalSkipIndex]);

  const handleChangeSelfDuration = (event: any, data: any) => {
    setDuration({ ...duration, self: data.value });
  };

  const handleChangeHospitalDuration = (event: any, data: any) => {
    setDuration({ ...duration, hospital: data.value });
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleChangeDateRange = (
    event: any,
    data: any,
    field: "self" | "hospital"
  ) => {
    let fromdate: any = null;
    let todate: any = null;

    const durationArr: any[] = duration[field].split("_");
    if (data.name === "backward") {
      const targetDate: any = moment(startDate[field], BE_DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${durationArr[0]}`, durationArr[1]);
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate[field], BE_DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(durationArr[0], durationArr[1]);
    }
    setStartDate({ ...startDate, [field]: fromdate });
    setEndDate({ ...endDate, [field]: todate });
  };

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <Image
          style={{
            color: "var(--theme-icon-color-dm)",
            height: "32px",
          }}
          src="/images/detail/weight.png"
        />{" "}
        <span>
          <FormattedMessage id="weight.weight" />
        </span>
      </div>

      <Grid
        divided
        columns={2}
        relaxed="very"
        className="sub-layout dm"
        style={{ paddingLeft: 0, paddingRight: "15px" }}
      >
        <Grid.Column>
          <Header as="h2" style={{ ...styles.headerMonitor, marginTop: 0 }}>
            <FormattedMessage id="bplusClinicKey252" />
          </Header>
          <HeaderChart
            yAxesLabelLeft="BMI"
            startDate={startDate.hospital}
            endDate={endDate.hospital}
            durationOptions={hospitalDurationOptions}
            selectedDuration={duration.hospital}
            onChangeDateRange={(event: any, data: any) => {
              handleChangeDateRange(event, data, "hospital");
            }}
            onChangeDuration={handleChangeHospitalDuration}
            labelStyle={{ paddingLeft: "10px" }}
            legendStyle={{ gridTemplateColumns: "unset" }}
          />
          <Bar
            height={150}
            data={hospitalIsSeeAll ? hospitalWeight.data : subHospitalChartData}
            options={hospitalWeight.options}
          />
          <FooterChartCustom
            chartData={hospitalWeight.data}
            startDate={startDate.hospital}
            onChangeIsSeeAll={(see) => setHospitalIsSeeAll(see)}
            onChangeSkipIndex={(index) => setHospitalSkipIndex(index)}
            labelLimit={labelLimit}
          />
        </Grid.Column>

        <Grid.Column>
          <Header as="h2" style={styles.headerMonitor}>
            <FormattedMessage id="bplusClinicKey446" />
          </Header>
          <HeaderChart
            yAxesLabelLeft="BMI"
            durationOptions={durationOptions}
            selectedDuration={duration.self}
            onChangeDuration={handleChangeSelfDuration}
            onChangeDateRange={(event: any, data: any) => {
              handleChangeDateRange(event, data, "self");
            }}
            startDate={startDate.self}
            endDate={endDate.self}
            labelStyle={{ paddingLeft: "10px" }}
            legendStyle={{ gridTemplateColumns: "unset" }}
            legendItem={{
              type: "rect",
              items: [
                {
                  name: "Patient’s note",
                  color: "#EE1C24",
                  type: "star" as "star",
                },
              ],
            }}
          />
          <div style={{ position: "relative" }}>
            <Bar
              height={150}
              data={selfIsSeeAll ? selfWeight.data : subSelfChartData}
              options={selfWeight.options}
              plugins={plugins}
            />
            <div ref={tooltipRef} className="tooltip-blood-pressure"></div>
          </div>

          <FooterChartCustom
            chartData={selfWeight.data}
            startDate={startDate.self}
            onChangeIsSeeAll={(see) => setSelfIsSeeAll(see)}
            onChangeSkipIndex={(index) => setSelfSkipIndex(index)}
            labelLimit={labelLimit}
          />
        </Grid.Column>

        <div style={styles.boxTable}>
          <ReactTable
            className="weight-table-custom"
            style={{ width: "30rem" }}
            columns={columnsBMI}
            defaultPageSize={4}
            // style={{ height: "135px" }}
            showPagination={false}
            data={BMI_STATUS}
            resizable={false}
            getTheadThProps={handleGetTheadThProps}
          />
        </div>
      </Grid>
    </Segment>
  );
};

WebDMWeight.defaultProps = WebDMWeightInitial;

export default React.memo(WebDMWeight);
