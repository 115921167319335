export default class PaymentController {
  constructor(props) {
    this.bilManager = props.bilManager;
  }

  getInvoice = async ({
    apiToken,
    patientId,
    status,
    divisionId,
    isTelemed
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoice({
      apiToken,
      patientId,
      status,
      divisionId,
      isTelemed
    });
    return [data, error];
  };

  getPendingInvoice = async ({
    apiToken,
    isTelemed
  } = {}) => {
    const [data, error] = await this.bilManager.getPendingInvoice({
      apiToken,
      isTelemed
    });
    return [data, error];
  };

  getInvoiceDetail = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoiceDetail({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  postPaymentParameters = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.postPaymentParameters({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  getInvoiceItemByItem = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoiceItemByItem({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  getReceipt = async ({
    apiToken,
    patientId,
    divisionId,
    fromDate,
    isTelemed
  } = {}) => {
    const [data, error, network] = await this.bilManager.getReceipt({
      apiToken,
      patientId,
      divisionId,
      fromDate,
      isTelemed
    });
    return [data, error, network];
  }

  getCompleteInvoice = async ({
    apiToken,
    fromDate,
    isTelemed, 
    hospital
  } = {}) => {
    const [data, error, network] = await this.bilManager.getCompleteInvoice({
      apiToken,
      fromDate,
      isTelemed,
      hospital
    });
    return [data, error, network];
  }
}