import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// apis
import V3CheckMedCertFileView from "../../issara-sdk/apis/V3CheckMedCertFileView_apps_PRX";

export type State = {
  medicalCertificateList?: any[];
};

export const StateInitial: State = {
  medicalCertificateList: [],
};

export type Event = {
  message: "handleGetMedicalCertificateList";
  params: { hospital: any };
};

export type Data = {};

type Handler = (
  controller: WasmHandler<State, Event, Data>,
  params?: any
) => any;

export const handleGetMedicalCertificateList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const [response, error, network] = await V3CheckMedCertFileView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { covid: false, hospital: params.hospital },
  });

  if (error) {
    /// issue 51678

    console.log( "network" , network.response.status)
    if (network.response.status === 404) {
      controller.setState({
        loadingStatus: false,
        errorMessage: null,
        successMessage: null,
        medicalCertificateList: [],
      });
    } else {
      controller.setState({
        loadingStatus: false,
        errorMessage: error,
        successMessage: null,
        medicalCertificateList: [],
      });
    }
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      medicalCertificateList: response,
    });
  }
};
