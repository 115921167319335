import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
} from "../MobClinicInterface";

export type State = {
  contractionCountFilterDate?: moment.Moment;
  contractionFilterDuration?: string;
  contractionCountDetail?: any;
  contractionCountHistory?: any;
};

export const StateInitial = {
  contractionCountFilterDate: moment(),
  contractionFilterDuration: "7",
  contractionCountDetail: {},
  contractionCountHistory: {},
};

export type Event =
  // GET
  | { message: "handleGetContractionCountDetail"; params: {} }
  | {
      message: "handleGetContractionCountHistory";
      params: { start_measure: string; end_measure: string };
    }
  // CHANGE
  | {
      message: "handleChangeContractionCountFilterDate";
      params: { date: moment.Moment };
    }
  | {
      message: "handleChangeContractionCountFilterDuration";
      params: { duration: string };
    }
  // SAVE
  | { message: "handleSaveContractionCount"; params: {} }
  | { message: "handleUpdateEndContractionCount"; params: { index: number } }
  | { message: "handleDeleteContractionCount"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetContractionCountDetail: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.CONTRACTION_COUNT,
    data_type: DATA_TYPE.CONTRACTION_COUNT,
    start_measure:
      state.contractionCountFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    contractionCountDetail: response,
  });
};

export const handleGetContractionCountHistory: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.CONTRACTION_COUNT,
    data_type: DATA_TYPE.CONTRACTION_COUNT,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    contractionCountHistory: response,
  });
};

// CHANGE
export const handleChangeContractionCountFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({
    contractionCountFilterDate: moment(params.date, "DD/MM/YYYY"),
  });
};

export const handleChangeContractionCountFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ contractionFilterDuration: params.duration });
};

// SAVE
export const handleSaveContractionCount: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const state = controller.getState();

    data = {
      group: GROUP.CONTRACTION_COUNT,
      data_type: DATA_TYPE.CONTRACTION_COUNT,
      measure_date: state.contractionCountFilterDate?.format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: { ...params.data },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save contraction count success.";
    handleGetContractionCountDetail(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleUpdateEndContractionCount: Handler = (
  controller,
  params
) => {
  const state = controller.getState();

  // Todo: Remove It
  let result = { ...state.contractionCountDetail };
  const end: string = moment().format("HH:mm:ss");

  if (end < result.items[params.index].start) {
    controller.setState({
      contractionCountDetail: { ...state.contractionCountDetail },
      errorMessage:
        "Can't update end time. End time should be greater than Start time",
      successMessage: null,
    });
  } else {
    result.items[params.index].end = end;
    controller.setState({
      contractionCountDetail: result,
      errorMessage: null,
      successMessage: "Update end time success.",
    });
  }

  // Todo: call save
};

export const handleDeleteContractionCount: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete contraction count success.";
    }

    handleGetContractionCountDetail(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
