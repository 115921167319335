import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Input, Typography } from "@mui/material";
import { Button, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";

import { adToBe } from "../../react-lib/utils";
import * as KYCVerifyConst from "./KYCVerify";
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

type KYCFormProps = {
  onEvent: any;
  patientIdentification: any;
  headerText: string;
};

const KYCFormInitial: KYCFormProps = {
  onEvent: () => null,
  patientIdentification: {},
  headerText: "",
};

const KYCForm: React.FC<KYCFormProps> = (props) => {
  const intl = useIntl();
  const [identData, setIdentData] = useState<any>({});
  const [modInfo, setModInfo] = useState<any>({
    open: false,
    titleName: "",
    imageName: "",
  });

  useEffect(() => {
    setIdentData({
      first_name: props.patientIdentification?.first_name,
      last_name: props.patientIdentification?.last_name,
      cid: props.patientIdentification?.cid,
      dob: props.patientIdentification?.dob,
      phone_no: props.patientIdentification?.phone_no,
      email: props.patientIdentification?.email,
      idcard: props.patientIdentification?.idcard,
      face: props.patientIdentification?.face,
    });
  }, [props.patientIdentification]);

  // Modal
  const handleOpenModInfo = (name: string) => {
    let titleName: string = "";
    if (name === "idcard") {
      titleName = "ID Card";
    } else {
      titleName = "Photo";
    }
    setModInfo({ open: true, titleName: titleName, imageName: name });
  };

  const handleCloseModInfo = () => {
    setModInfo({ open: false, titleName: "", imageSrc: null });
  };

  // Change
  const handleChange = (event: any) => {
    setIdentData({ ...identData, [event.target.name]: event.target.value });
  };

  const handleChangeDate = (date: string) => {
    var dateSplit = date.split('/');
    if (dateSplit.length < 3) {
      console.log("saika ;) date error",date)
    } else {
      const year = parseInt(dateSplit[2]) - 543;
      const newDate = year + '-' +dateSplit[1]+'-'+dateSplit[0];
      setIdentData({ ...identData, dob: newDate });
    }
  };

  // Save
  const handleConfirm = () => {
    props.onEvent({
      message: "handleConfirmPatientIdentification",
      params: identData,
    });
  };

  const handleCancel = () => {
    let newUrl  = globalThis.window.location.origin + "/registration-incomplete?app=MobFeed"
    console.log("newUrl", newUrl)
    globalThis.window.location.href = newUrl
  }

  return (
    <>
      <Header
        as="h4"
        textAlign="center"
        style={KYCVerifyConst.styles.textHeader}
      >
        {props.headerText}
      </Header>

      <Segment style={KYCVerifyConst.styles.bgDetail}>
        <Header as="h4" style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey69" />
        </Header>
      </Segment>

      <Segment
        style={{ padding: "10px" }}
        onClick={() => {
          handleOpenModInfo("idcard");
        }}
      >
        <Grid
          verticalAlign="middle"
          style={{ color: "var(--blue-bdms-color)" }}
        >
          <Grid.Column width={2}>
            <Icon name="address card outline" size="big" />
          </Grid.Column>
          <Grid.Column width={11}><FormattedMessage id="bplusClinicKey260" /></Grid.Column>
          <Grid.Column width={2}>
            <Icon name="eye" size="large" />
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment
        style={{ padding: "10px" }}
        onClick={() => {
          handleOpenModInfo("face");
        }}
      >
        <Grid
          verticalAlign="middle"
          style={{ color: "var(--blue-bdms-color)" }}
        >
          <Grid.Column width={2}>
            <Icon name="mobile alternate" size="big" />
          </Grid.Column>
          <Grid.Column width={11}><FormattedMessage id="bplusClinicKey376" /></Grid.Column>
          <Grid.Column width={2}>
            <Icon name="eye" size="large" />
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment
        style={{ ...KYCVerifyConst.styles.bgDetail, marginBottom: "10px" }}
      >
        <Header as="h4" style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey546" />
        </Header>
      </Segment>

      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey202" /> <label style={KYCVerifyConst.styles.requireField}>*</label>
        </Typography>
        <Input
          fullWidth
          // readOnly
          name="first_name"
          value={identData?.first_name || ""}
          onChange={handleChange}
        />
      </Box>
      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey287" /> <label style={KYCVerifyConst.styles.requireField}>*</label>
        </Typography>
        <Input
          fullWidth
          // readOnly
          name="last_name"
          value={identData?.last_name || ""}
          onChange={handleChange}
        />
      </Box>
      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          {intl.formatMessage({id: "bplusClinicKey259"})}{" "}
          <label style={KYCVerifyConst.styles.requireField}>*</label>
        </Typography>
        <Input fullWidth readOnly name="idno" value={identData?.cid || ""} />
      </Box>
      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          Date of Birth{" "}
          <label style={KYCVerifyConst.styles.requireField}>*</label>
        </Typography>
        <DateTextBox
          // disabled={true}
          style={{ width: "100%" }}
          inputStyle={{ width: "100%" }}
          maxDate={adToBe(moment())}
          value={identData?.dob || ""}
          onChange={handleChangeDate}
        />
      </Box>

      <Segment
        style={{ ...KYCVerifyConst.styles.bgDetail, marginBottom: "10px" }}
      >
        <Header as="h4" style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey122" />
        </Header>
      </Segment>
      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey324" />
        </Typography>
        <Input
          fullWidth
          readOnly
          name="phone_no"
          value={identData?.phone_no || ""}
        />
      </Box>
      <Box style={{ padding: "10px 0px" }}>
        <Typography style={{ color: "var(--text-dark-grey)" }}>
          <FormattedMessage id="bplusClinicKey179" />
        </Typography>
        <Input
          fullWidth
          // readOnly
          name="email"
          value={identData?.email || ""}
          onChange={handleChange}
        />
      </Box>

      <Grid style={{ padding: "40px 20px" }}>
        <Grid.Row centered columns={2} style={{ padding: "0px" }}>
          <Grid.Column>
            <Button fluid color="blue" onClick={handleConfirm}>
              <FormattedMessage id="bplusClinicKey119" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2}>
          <Grid.Column>
            <Button fluid inverted color="blue" onClick={handleCancel}>
              <FormattedMessage id="bplusClinicKey105" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ModInfo
        open={modInfo?.open}
        titleColor="blue"
        titleName={modInfo?.titleName}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <Image
          src={identData[modInfo.imageName]}
          style={{ minHeight: "200px", minWidth: "200px" }}
        />
      </ModInfo>
    </>
  );
};

KYCForm.defaultProps = KYCFormInitial;

export default React.memo(KYCForm);
