import React, { CSSProperties } from "react";
import { Dimmer, Grid, Header, Icon, Loader, Segment } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
// UI
import SnackMessage from "../../clinic/SnackMessage";
import DMPersonalLabChart from "../../clinic/DMPersonalLabChart";
import DMPersonalScoreChart from "../../clinic/DMPersonalScoreChart";

const styles = {
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  },
};

type WebDMOverallProps = {
  onEvent: any;
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  scoreSummary: any[];
  labSummary: any;
};

const WebDMOverallInitial: WebDMOverallProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  scoreSummary: [],
  labSummary: {},
};

const WebDMOverall: React.FC<WebDMOverallProps> = (props) => {
  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div className="box-header div-header">
        <Icon.Group style={{ color: "var(--theme-icon-color-dm)" }}>
          <Icon name="user" />
          <Icon
            corner="bottom right"
            name="signal"
            rotated="counterclockwise"
          />
        </Icon.Group>
        <span><FormattedMessage id="bplusClinicKey363" /></span>
      </div>

      <Grid divided columns={2} relaxed="very" className="sub-layout dm">
        <Grid.Column>
          <Header as="h2" style={styles.headerMonitor}>
            <FormattedMessage id="bplusClinicKey252" />
          </Header>
          <DMPersonalLabChart
            // function
            onEvent={props.onEvent}
            // data
            selectedPatient={props.selectedPatient}
            labSummary={props.labSummary}
            hideFilter={true}
          />
        </Grid.Column>

        <Grid.Column>
          <Header as="h2" style={styles.headerMonitor}>
            <FormattedMessage id="bplusClinicKey446" />
          </Header>
          <DMPersonalScoreChart
            // function
            onEvent={props.onEvent}
            // data
            selectedPatient={props.selectedPatient}
            scoreSummary={props.scoreSummary}
            hideFilter={false}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

WebDMOverall.defaultProps = WebDMOverallInitial;

export default React.memo(WebDMOverall);
