import WasmController from "react-lib/frameworks/WasmController";
import axios from "axios";
import moment from "moment";
import Cookies from 'js-cookie';

import V3PinnedSpecialties_apps_PRX from "../../issara-sdk/apis/V3PinnedSpecialties_apps_PRX"

export type State = {
  departmentOptionList?: any[],
  appointmentFilter?: {
    department: number | string,
    availability: number | string,
    fee: number | string,
    experience: number | string,
    gender: number | string,
    rate: number | string
  },
  tmpAppointmentFilter?: any;
  firstLoadingFilterAppointment?: boolean;
};

export const StateInitial: State = {
  departmentOptionList: [],
  appointmentFilter: {
    department: "",
    availability: "",
    fee: "",
    experience: "",
    gender: "",
    rate: ""
  },
  tmpAppointmentFilter: {},
  firstLoadingFilterAppointment: false
};

export type Event =
  | { message: "GetListDepartment"; params: {} }
  | { message: "HandleClickButtonFilterAppointment"; params: { type: keyof Required<State>["appointmentFilter"], value: string | number } }
  | { message: "HandleSetValueFilterAppointment"; params: { type: "CLEAR" | "CLOSE" | "SAVE", callback: any } }

export type Data =
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetListDepartment: Handler = async (controller, params) => {
  const appointmentFilter = controller.getState().appointmentFilter || {}
  controller.setState({ tmpAppointmentFilter: JSON.parse(JSON.stringify(appointmentFilter)) })

  const [res, error] = await V3PinnedSpecialties_apps_PRX.list({
    apiToken: controller.apiToken || Cookies.get("apiToken")
  })

  controller.setState({
    departmentOptionList: res?.items || [],
    firstLoadingFilterAppointment: true
  })
};

export const HandleClickButtonFilterAppointment: Handler = (controller, params) => {
  const { appointmentFilter } = controller.getState()
  const cloneFilter = { ...appointmentFilter }
  const { type, value }: { type: keyof Required<State>["appointmentFilter"], value: string | number } = params

  if (cloneFilter?.[type] === value) {
    cloneFilter[type] = ""
  } else {
    cloneFilter[type] = value
  }

  controller.setState({ appointmentFilter: cloneFilter as any })
}

export const HandleSetValueFilterAppointment: Handler = (controller, params) => {

  const { tmpAppointmentFilter, appointmentFilter } = controller.getState()
  switch (params.type) {
    case "CLEAR":
      const clearData = {
        department: "",
        availability: "",
        fee: "",
        experience: "",
        gender: "",
        rate: ""
      }
      controller.setState({ appointmentFilter: clearData })
      params.callback(clearData)
      break;

    case "CLOSE":
      const closeData = { ...(tmpAppointmentFilter || {}) }
      console.log(closeData)
      controller.setState({ appointmentFilter: closeData })
      params.callback(closeData)
      break;

    case "SAVE":
      params.callback(appointmentFilter)
      break;
    default:
      break;
  }
}