import React, { useState, useRef, useEffect, CSSProperties } from "react";
import {
  Menu,
  Icon,
  List,
  Search,
  Image,
  Header,
  Input,
  Loader,
  Dimmer,
  Button,
  Label,
} from "semantic-ui-react";
import personImg from "react-lib/apps/common/person.png";
import CONFIG from "config/config";
import Cookies from "js-cookie";
import "./TeleDesktopCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
};

const styles = {
  fixSize: {
    width: "35px",
  } as CSSProperties,
};

type ChartListDesktopProps = {
  onEvent: (e: any) => any;
  apiToken?: any;
  chatController: any;
  userId?: any;
  channelList?: any;
  loadChannelList: () => any;
  unfollowChannelList: any;
  followDivisionProfile: (item: any) => any;
  isLoading: boolean;
  menuSelected: any;
  onChannelSelected: (
    id: any,
    division: any,
    channel_Name: any,
    icon: any
  ) => any;
  keyWordSearch?: any;
  setProp: any;
};

export const ChartListDesktop = React.forwardRef(
  (props: ChartListDesktopProps, ref) => {
    const isMounted = useRef(true);
    const inputRef = useRef();
    const [menuSelect, setMenuSelect] = useState<string>("");
    const [isload, setIsLoad] = useState<boolean>(false);
    const [chatList, setChatList] = useState<any[]>([]);
    const [isLoadUnfollow, setIsLoadUnfollow] = useState<boolean>(false);

    useEffect(() => {
      if (props.chatController && props.apiToken && props.userId) {
        props.loadChannelList();
      }
    }, [props.chatController, props.apiToken, props.userId]);

    useEffect(() => {
      setMenuSelect(props.menuSelected);
      if (props.channelList?.length !== 0) {
        setChatList(props.channelList);
      } else {
        setChatList([]);
      }
    }, [props.menuSelected, props.channelList]);

    useEffect(() => {
      if (props.channelList?.length === 0) {
        setIsLoad(props.isLoading);
      } else {
        setIsLoad(false);
      }
    }, [props.isLoading]);

    useEffect(() => {
      if (isLoadUnfollow) {
        setIsLoadUnfollow(false);
      }
    }, [props.unfollowChannelList]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const handleChannelSelected = (item: any, index: any) => () => {
      if (item.unread_message_count !== 0) {
        var tmpList = [...chatList];
        tmpList[index].unread_message_count = 0;
        setChatList(tmpList);
      }

      var iconName = "/logo512.png";
      if (
        item.division_feature === CONFIG.DM_FEATURE ||
        item.division_feature === CONFIG.MOM_FEATURE ||
        item.division_feature === CONFIG.CHILD_FEATURE
      ) {
        iconName = getIcon(item.division_feature);
      }
      const name = item.division_name || item.name;
      setMenuSelect(item.id);
      props.onChannelSelected(item.id, item.division, name, iconName);
    };

    const getIcon = (feature: any) => {
      let iconName = "/logo512.png";

      // console.log(" getIcon: ", feature);

      switch (feature) {
        case CONFIG.DM_FEATURE:
          iconName = "/dm.png";
          break;
        case CONFIG.MOM_FEATURE:
          iconName = "/mom.png";
          break;
        case CONFIG.CHILD_FEATURE:
          iconName = "/child.png";
          break;
        default:
          break;
      }
      return iconName;
    };

    const createChannelList = () => {
      if (!props.userId && !Cookies.get("userId")) {
        return (
          <div className="no-user-id">
            <h2>Error: No User Id</h2>
          </div>
        );
      }
      return (
        <>
          {chatList
            .filter(
              (item: any) =>
                item.division_name
                  .toLocaleLowerCase()
                  .search(props.keyWordSearch.toLocaleLowerCase()) !== -1
            )
            .map((item: any, index: any) => {
              let iconName = "";
              let isFeature = false;
              let unread_noti = "none";
              // TODO: change to feature
              // console.log(" getIcon Map ", item)
              if (
                item.division_feature === CONFIG.DM_FEATURE ||
                item.division_feature === CONFIG.MOM_FEATURE ||
                item.division_feature === CONFIG.CHILD_FEATURE
              ) {
                isFeature = true;
                iconName = getIcon(item.division_feature);
              }

              return (
                <Menu.Item
                  key={index}
                  onClick={handleChannelSelected(item, index)}
                  active={menuSelect === item.id}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#F4F6F7",
                        borderRadius: "20px",
                        padding: "5px",
                        ...styles.fixSize,
                      }}
                    >
                      <div style={{ display: "flex", ...styles.fixSize }}>
                        <div
                          style={{
                            background: "red",
                            borderRadius: "10px",
                            padding: "5px",
                            width: "4px",
                            height: "4px",
                            position: "absolute",
                            zIndex: "2",
                            left: "35px",
                            display:
                              item.unread_message_count === 0 ? "none" : "",
                          }}
                        />

                        <Image
                          src={
                            iconName ? iconName : "/logo512.png"
                          }
                          style={{ width: "25px" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ paddingLeft: "10px" }}>
                        {item.division_name || item.name}
                      </div>
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
        </>
      );
    };

    const createunfollowChannelList = () => {
      return (
        <>
          {props.unfollowChannelList
            .filter(
              (item: any) =>
                item.division_name
                  .toLocaleLowerCase()
                  .search(props.keyWordSearch.toLocaleLowerCase()) !== -1
            )
            .map((item: any, index: any) => {
              let iconName = "";
              let isFeature = false;
              // TODO: change to feature
              // console.log(" getIcon Map ", item)
              // console.log("saika ;) item ",item)
              // console.log("saika ;) item.division_feature ",item.division_feature," : " ,CONFIG.DM_FEATURE)
              if (
                item.feature === CONFIG.DM_FEATURE ||
                item.feature === CONFIG.MOM_FEATURE ||
                item.feature === CONFIG.CHILD_FEATURE
              ) {
                isFeature = true;
                iconName = getIcon(item.feature);
              }

              return (
                <Menu.Item
                  key={index}
                  onClick={() => console.log("can't load chart  unfollow ")}
                  active={menuSelect === item.id}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#F4F6F7",
                        borderRadius: "20px",
                        padding: "5px",
                      }}
                    >
                      <Image
                        src={iconName ? iconName : "/logo512.png"}
                        style={{ width: "25px" }}
                      />
                    </div>
                    <div>
                      <div style={{ paddingLeft: "10px" }}>
                        {item.division_name || item.name}
                      </div>
                    </div>
                    <div>
                      <List.Content
                        verticalAlign={"middle"}
                        floated={"right"}
                        style={{ paddingRight: "7px", marginLeft: "5px" }}
                      >
                        <Label
                          data-testid="msg-noti"
                          circular
                          color={"blue"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsLoadUnfollow(true);
                            props.followDivisionProfile(item.id);
                          }}
                        >
                          + follow
                        </Label>
                      </List.Content>
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
        </>
      );
    };

    console.log("Chats props:", props);

    return (
      <div className="chat-page" style={{ overflowY: "auto" }}>
        <Dimmer.Dimmable>
          <Dimmer
            data-testid="chat-list-loader"
            active={isload || isLoadUnfollow}
            inverted
          >
            <Loader inverted>Loading...</Loader>
          </Dimmer>
          <div>
            <Menu.Menu className="chat-list">{createChannelList()}</Menu.Menu>
            <Menu.Menu className="chat-list">
              {createunfollowChannelList()}
            </Menu.Menu>
          </div>
        </Dimmer.Dimmable>
      </div>
    );
  }
);

ChartListDesktop.defaultProps = {
  noApiToken: () => {},
  onChannelSelected: () => {},
  searchChannelList: () => {},
  channelList: [],
};

export default React.memo(ChartListDesktop);
