import React, { CSSProperties, useEffect, useState, forwardRef } from "react";
import moment from "moment";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  Card,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Button,
} from "semantic-ui-react";

import SnackMessage from "../clinic/SnackMessage";
import LabResultChart from "./LabResultChart";
import { Dialog, Slide } from "@material-ui/core";

// type
import { LabOrderType } from "bplus-lib/MobHistoryinterface";
import { ChildLabResult } from "bplus-lib/MobLABInterface";

const styles = {
  card: {
    width: "100%",
    fontSize: "15px",
    boxShadow: "0px 0px 8px 1px rgba(131, 133, 135, 0.2)",
    lineHeight: "1.5em",
    borderRadius: "10px",
  } as CSSProperties,
  noMargin: {
    margin: "0px",
  } as CSSProperties,
};

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

type LabProfileProps = {
  onEvent: any;
  setProp: any;
  loadingStatus?: boolean;
  pdfDownloadStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedHospital?: any;
  labItems?: LabOrderType[];
  childLabItems?: ChildLabResult[];
  textSearch: string;
  selectedParent?: any;
} & WrappedComponentProps;

const LabProfileInitial: Omit<LabProfileProps, "intl"> = {
  onEvent: () => null,
  setProp: () => null,
  loadingStatus: false,
  pdfDownloadStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedHospital: null,
  labItems: [],
  selectedParent: {},
  childLabItems: [],
  textSearch: "",
};

const LabProfile = (props: LabProfileProps) => {
  const [showResult, setShowResult] = useState<boolean>(false);
  const [childLabLoading, setChildLabLoading] = useState<boolean>(false);

  const handleGetChildLabResult = (item: any) => {
    setShowResult(true);
    setChildLabLoading(true);
    props.onEvent({
      message: "handleGetChildLabResult",
      params: {
        code: item?.code,
        parentName: item?.name,
        hospitalCode: props.selectedHospital?.code,
      },
    });
  };

  const closeSnackMessage = () => {
    props.onEvent({ message: "closeSnackMessage" });
  };

  useEffect(() => {
    if (props.childLabItems && props.childLabItems?.length > 0) {
      setShowResult(true);
      setChildLabLoading(false);
    }
    // else {
    //   setShowResult(false);
    // }
  }, [props.childLabItems]);

  console.log("props", props);
  return (
    <>
      <div style={{ padding: "15px", marginBottom: "20px" }}>
        <Dimmer active={props.loadingStatus} inverted>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>

        <SnackMessage
          onEvent={props.onEvent}
          onClose={closeSnackMessage}
          error={props.errorMessage}
          success={props.successMessage}
        />

        {((props.labItems || []).length < 1 ||
          (props.labItems?.filter((item) => item.id).length || 0) === 0) && (
          <Header as="h3" color="grey" style={{ textAlign: "center" }}>
            {props.intl.formatMessage({ id: "common.no_data_labresult" })}
          </Header>
        )}

        {props.labItems
          ?.filter(
            (item: LabOrderType) =>
              item?.name
                ?.toLowerCase()
                .search(props.textSearch.toLowerCase()) !== -1
          )
          .map((item: any, index: number) => {
            if (item?.id) {
              return (
                <Card key={index} style={styles.card}>
                  <Card.Content>
                    <Grid.Row
                      key={index}
                      onClick={() => {
                        handleGetChildLabResult(item);
                      }}
                    >
                      <Grid verticalAlign="middle">
                        <Grid.Column width={4}>
                          <Image src="/images/lab/lab_icon.png" />
                        </Grid.Column>
                        <Grid.Column width={12} style={{ paddingLeft: "0px" }}>
                          <Grid>
                            <Grid.Column width={12}>
                              <Header
                                as="h3"
                                style={{
                                  ...styles.noMargin,
                                  color: "var(--primary-theme-color)",
                                }}
                              >
                                {item.name}
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <button
                                onClick={() => {
                                  handleGetChildLabResult(item);
                                }}
                                style={{ border: "none", background: "none" }}
                              >
                                <Icon.Group size="large">
                                  <Icon name="angle right" color="blue" />
                                </Icon.Group>
                              </button>
                            </Grid.Column>
                          </Grid>
                          <Header as="h5" color="grey" style={styles.noMargin}>
                            {props.selectedHospital.name || "-"}
                          </Header>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                  </Card.Content>
                </Card>
              );
            } else {
              return;
            }
          })}
      </div>
      <Dialog
        fullScreen
        open={showResult}
        onClose={() => {
          setShowResult(false);
          props.setProp("childLabItems", []);
          props.setProp("selectedParent", {});
        }}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Dimmer active={childLabLoading} inverted>
            <Loader inverted>
              <FormattedMessage id="bplusClinicKey297" />
            </Loader>
          </Dimmer>
          <LabResultChart
            onEvent={props.onEvent}
            title={props.selectedParent?.title}
            selectedHospital={props.selectedHospital}
            onBackPress={() => {
              setShowResult(false);
              props.setProp("childLabItems", []);
              props.setProp("selectedParent", {});
            }}
            childLabResult={props.childLabItems}
            selectedParent={props.selectedParent}
            pdfDownloadStatus={props.pdfDownloadStatus}
          ></LabResultChart>
        </div>
      </Dialog>
    </>
  );
};

LabProfile.defaultProps = LabProfileInitial;

export default React.memo(injectIntl(LabProfile));
