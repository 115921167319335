import React from "react";
// CSS
import { Dimmer, Loader } from "semantic-ui-react";

import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Menu from '@mui/material/Menu';
// Icon
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import { FormattedMessage } from "react-intl";
import moment from "moment";

// Interface
import { Event } from "../ManageInterface";

type PostListProps = {
  onEvent: (e: Event) => any;
  list: Record<string, any>[];
  isSegment?: Boolean;
  titleKey?: string;
  dateKey?: string;
  activeIndex?: number;
  activeId?: string | number | null;
  disabled?: boolean;
  loadingStatus?: boolean;
  showFilter?: boolean;
  searchAddPlaceholder?: string;
  searchPlaceholder?: string;
  filterActive?: string;
  hideAddButton?: boolean;
  // Callback
  onClickItem?: (item: any, index: number) => any;
  onAdd?: (e: any) => any;
  onSearch?: (search: string) => any;
  onFilterActive?: (select: string) => any;
};

const COLORS = {
  background_card: "rgba(204,218,237,0.75)",
  primary: "var(--text-light-blue)",
  light_blue: "#0072BC",
  dark_grey: "#797878",
  red: "#0A7800",
};

const filterType = [
  { id: "all" , name: "All"},
  { id: "active" , name: "Active"},
  { id: "inactive" , name: "Inactive"},
]

const BE_DATE_FORMAT = "DD/MM/YYYY";
const BE_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

const useStyles = makeStyles(() => ({
  post_item: {
    marginTop: "25px",
    height: "calc(100vh - 170px)",
    overflowY: "auto",
    "& > div": {
      color: COLORS.dark_grey,
      borderTop: `0.5px solid ${COLORS.primary}`,
      padding: "5px 3px",
      cursor: "pointer",
    },
    "& > div:last-child": {
      borderBottom: `0.5px solid ${COLORS.primary}`,
    },
    "& .title-name": {
      fontSize: "16px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .text-activet": {
      color: COLORS.red,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  },
  dropsdown: {
    "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded": {
      borderRadius: "10px",
      marginTop: "5px",
      border: "2px solid #CCDAED",
    },
    "& .MuiList-root.MuiMenu-list li": {
      fontSize: "14px",
      fontWeight: "bold",
    }
  },
}));

export const Search = styled("div")(() => ({
  position: "relative",
  borderRadius: "10px",
  backgroundColor: COLORS.background_card,
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: "100%",
  margin: "0 5px",
  "& .MuiButton-root.MuiButton-contained": {
    position: "absolute",
    minWidth: 0,
    maxWidth: "40px",
    right: 0,
    borderRadius: "0 10px 10px 0",
    height: "100%",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    color: "white",
    marginLeft: "-7px",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    paddingRight: "45px",
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
}));

/* TODO --------------------- PostItem --------------------- */
const PostList: React.FC<PostListProps> = React.memo<PostListProps>((props) => {
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={props.searchAddPlaceholder}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              props.onSearch?.(e.target.value);
            }}
          />
          {!props.hideAddButton &&
            <MuiButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={props.onAdd}
          ></MuiButton>
          }
        </Search>
      </div>

      {props.showFilter && (
        <div
         className="PostList_Filter"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
              <Select
                MenuProps={{ className: classes.dropsdown}}
                value={props.filterActive || "all"}
                onChange={(e: any) => {
                  props.onFilterActive?.(e.target.value);
                }}
              >
                {filterType.map((item: any, index: number) => (
                  <MenuItem key={"menu-item" + index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
        </div>
      )}

      <div className={classes.post_item}>
        {props.list.map((item, index) => (
          <div
            key={"item-" + index}
            style={{
              ...((typeof props.activeIndex !== "undefined"
                ? index === props.activeIndex
                : typeof props.activeId !== "undefined"
                ? item.id === props.activeId
                : false) &&
                !props.disabled && {
                  background:
                    "linear-gradient(89.71deg, #CCDAED -0.4%, rgba(204, 218, 237, 0) 68.47%)",
                  borderLeft: `4px solid ${COLORS.primary}`,
                  color: COLORS.primary,
                }),
              padding: !props.dateKey ? "10px 5px" : "",
            }}
            onClick={() => {
              props.onClickItem?.(item, index);
            }}
          >
            {props.isSegment ? (
              <div className="title-name">{item[props.titleKey || ""]}</div>
            ) : props.dateKey === "posting_date" ? ( 
              <div>
                <div className="title-name">{item[props.titleKey || ""]}</div>
                <div>{`Edit by : ${item["editor"] !== undefined? item["editor"] : ""}`}</div>
                <div>{`Edit date : ${moment(item["edit_date" || ""]).format(BE_DATE_TIME_FORMAT)}`}</div>
                <div>{`Posting Date : ${item[props.dateKey]? moment(item[props.dateKey || ""]).format(BE_DATE_FORMAT):""}`}</div>
                <div className="text-activet" style={{color :item["active" || ""] ? "" : "red"}}>{item["active" || ""] ? "Active" : "Inactive" }</div>
              </div>
            ) : ( 
              <div>
                <div className="title-name">{item[props.titleKey || ""]}</div>
                <div>{`Edit by : ${item["editor"] !== undefined? item["editor"] : ""}`}</div>
                <div>{`Edit date : ${moment(item["edit_date" || ""]).format(BE_DATE_TIME_FORMAT)}`}</div>
                <div>{item["available_start_date"]||item["available_end_date"] ? `Available at : ${item["available_start_date"] ? moment(item["available_start_date" || ""]).format(BE_DATE_FORMAT) : " "} - ${item["available_end_date"] ? moment(item["available_end_date" || ""]).format(BE_DATE_FORMAT) :" "}` : "Available at :"}</div>
                <div className="text-activet" style={{color :item["active" || ""] ? "" : "red" }}>{item["active" || ""] ? "Active" : "Inactive" }</div>
              </div>
            ) }
           </div>
        ))}
      </div>
    </div>
  );
});

PostList.defaultProps = {
  onEvent: () => null,
  list: [],
  searchAddPlaceholder: "Search...",
  searchPlaceholder: "Search...",
} as PostListProps;

export default PostList;
