import React from "react";
// Mui
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import { FormattedMessage } from "react-intl";

// Types
type SubUnderVerificationProps = {};

// Images
const IMAGES = {
  loading: "/images/register/loading.png",
};

// Styles
const waitingStyles = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  textAlign: "center",
  position: "relative",
  "& > div": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    "& img": {
      width: "12rem",
    },
    "& .title": {
      margin: "2.25rem 0 1.15rem",
      fontWeight: "bold",
      fontSize: "1.375rem",
      "& .loading": {
        animation: "dot-loading 1s steps(4) infinite",
        clipPath: "inset(0 3ch 0 0)",
        display: "inline-block",
        fontFamily: "monospace",
      },
    },
  },
} as CSSProperties;

const useStyles = makeStyles(() => ({
  screen: {
    padding: "20px",
    "&:before": {
      zIndex: 0,
    },
  },
  waiting: waitingStyles,
}));

const SubUnderVerification = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.screen} bg-gradient-container`}>
      <div className={classes.waiting}>
        <div>
          <img src={IMAGES.loading} alt="loading" />
          <div className="title">
            <FormattedMessage id="bplusClinicKey1053" />
            <span className="loading">...</span>
          </div>
          <div>{<FormattedMessage id="bplusClinicKey1055" />}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SubUnderVerification);
