import React, { useCallback, useEffect, useMemo, useState } from "react";

// DatePicker
// import DateAdapter from "@mui/lab/AdapterMoment";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";

import PreferredList from "./PreferredList";
import PreferredTab from "./PreferredTab";
import PreferredPreviewHome from "./PreferredPreviewHome";
import PreferredPreviewAppointment from "./PreferredPreviewAppointment";
import PreferredEditor from "./PreferredEditor";
import { PREFERRED_MODE } from "./Preferred";

type ServiceItemDJProps = {
  active: boolean;
  icon: string;
  iconURL: string;
  seq: number;
  display: string;
  type: string;
};

type ServiceItemFBProps = {
  active: boolean;
  iconPathFromFirebase: string;

  // previews
  previewIconPathURL: string; // use for previews
  previewIconName: string; // use for previews name

  // input
  savedIconDocIdFirebase: string; // use for local file data
  iconNameFromLocalFile: string; // use for local filename

  iconExternalURL: string; // use in URL image

  sequence: number;
  service: string;
  emergency_no: number;
  title_en: string;
  title_th: string;
  type: string;
  mode: string;
  medProgramList: any[];
  subspecialtiesList: any[];
};

type PreferredManageProp = {
  controller: any;
  selectedOrganizationCode: string;
  onEvent: any;
  servicesOfHospitalFB?: ServiceItemFBProps[];
  servicesOfHospitalDJ?: ServiceItemDJProps[];
  organizationList: any[];
  preferredLoading: any;
  loadingStatus: boolean;
  mode: string;
  typeOptions: any[];
  serviceOptions: any[];
  medProgramList?: any[];
  subspecialtiesList?: any[];
};

const ICONS = {
  settings: "/images/expansion/settings.png",
};
const CARD_PREFERRED_MANAGE_HOME = "PreferredManageHome";
const CARD_PREFERRED_MANAGE_APPOINTMENT = "PreferredManageAppointment";

const PreferredManage = (props: PreferredManageProp) => {
  const [activePreferredListIndex, setActivePreferredListIndex] = useState<
    number | null
  >(0);
  const [serviceItem, setServiceItem] = useState<
    Partial<ServiceItemFBProps | ServiceItemDJProps> | {}
  >({});
  const [hospitalName, setHosiptalName] = useState<string>("");
  const [filterServicesOfHospitalFB, setFilterServicesOfHospitalFB] = useState<
    any[]
  >([]);
  const [filterServicesOfHospitalDJ, setFilterServicesOfHospitalDJ] = useState<
    any[]
  >([]);
  const [searchText, setSearchText] = useState("");

  console.log("activePreferredListIndex: ", activePreferredListIndex);
  const serviceItemInitial: Partial<ServiceItemFBProps | ServiceItemDJProps> =
    useMemo(() => {
      if (props.mode === PREFERRED_MODE.HOME) {
        return {
          active: false,
          iconPathFromFirebase: "",
          previewIconPathURL: "",
          previewIconName: "",
          savedIconDocIdFirebase: "",
          iconExternalURL: "",
          iconNameFromLocalFile: "",
          sequence: 1,
          title_en: "",
          service: "",
          emergency_no: 1724,
          title_th: "",
          type: "Other service",
        };
      } else {
        return {
          active: false,
          iconURL: "",
          seq: 1,
          display: "รายการใหม่",
          display_en: "New Menu",
          type: "med_program",
        };
      }
    }, [props.mode]);

  // iframe communicated
  useEffect(() => {
    const onMessage = (e: any) => {
      // console.log("onMessage", e)
      if (
        e?.data &&
        typeof e?.data === "string" &&
        e?.data?.includes("MobFeedSimulator")
      ) {
        let a = e?.data?.split(" ");
        let id = a?.[1];

        let idx = props.servicesOfHospitalFB?.findIndex(
          (item: any) => item.id === id
        );
        if (idx !== -1 && idx !== undefined) {
          setActivePreferredListIndex(idx);
        }
      } else if (
        e?.data &&
        typeof e?.data === "string" &&
        e?.data?.includes("MobAppointmentSimulator")
      ) {
        // console.log("MobAppointmentSimulator e?.data :", e?.data);
        let a = e?.data?.split(" ");
        let id = a?.[1];

        // console.log('props.servicesOfHospitalDJ: ', props.servicesOfHospitalDJ);
        let idx = props.servicesOfHospitalDJ?.findIndex(
          (item: any) => item.id == id
        );
        // console.log("MobAppointmentSimulator idx: ", idx);
        if (idx !== -1 && idx !== undefined) {
          setActivePreferredListIndex(idx);
        }
      }
    };

    window.onmessage = onMessage;
  }, [props.servicesOfHospitalDJ, props.servicesOfHospitalFB]);

  console.log("props.controller.auth?.currentUser?.uid", props.controller?.auth?.currentUser?.uid)
  console.log('props.selectedOrganizationCode: ', props.selectedOrganizationCode);
  console.log('props.mode: ', props.mode);
  useEffect(() => {
    console.log("useEffect props.controller.auth?.currentUser?.uid", props.controller?.auth?.currentUser?.uid)
    console.log('useEffect props.selectedOrganizationCode: ', props.selectedOrganizationCode);
    console.log('useEffect props.mode: ', props.mode);
    if (props.selectedOrganizationCode && props.controller?.auth?.currentUser?.uid) {

      // get Firebase
      if (props.mode === PREFERRED_MODE.HOME) {
        props.onEvent({ message: "GetServiceFromFirestore", params: {} });
      } else {
        props.onEvent({ message: "GetServicePreferred", params: {} });
      }
    }
  }, [props.selectedOrganizationCode, props.mode,  props.controller?.auth?.currentUser?.uid]);
  
  useEffect(() => {
    if (props.mode === PREFERRED_MODE.HOME) {
      // FB
      if ((props.servicesOfHospitalFB || []).length > 0) {
        if (
          (props.servicesOfHospitalFB || []).length - 1 >=
          (activePreferredListIndex || 0)
        ) {
          // เลือก serviceItems
          setServiceItem(
            (props.servicesOfHospitalFB || [])?.[activePreferredListIndex || 0]
          );
        } else {
          // ไม่พบ ใน list
          setActivePreferredListIndex(0);
          setServiceItem((props.servicesOfHospitalFB || [])?.[0]);
        }
      } else {
        // ไม่มี list
        setActivePreferredListIndex(0);
        setServiceItem({});
      }
    } else if (props.mode === PREFERRED_MODE.APPOINTMENT) {
      // DJ
      if ((props.servicesOfHospitalDJ || []).length > 0) {
        if (
          (props.servicesOfHospitalDJ || []).length - 1 >=
          (activePreferredListIndex || 0)
        ) {
          // เลือก serviceItems
          setServiceItem(
            (props.servicesOfHospitalDJ || [])?.[activePreferredListIndex || 0]
          );
        } else {
          // ไม่พบ ใน list
          setActivePreferredListIndex(0);
          setServiceItem((props.servicesOfHospitalDJ || [])?.[0]);
        }
      } else {
        // ไม่มี list
        setActivePreferredListIndex(0);
        setServiceItem({});
      }
    }
  }, [
    props.servicesOfHospitalFB,
    props.servicesOfHospitalDJ,
    activePreferredListIndex,
    searchText,
    props.mode,
  ]);

  useEffect(() => {
    if (props.selectedOrganizationCode && props.organizationList?.length > 0) {
      let hosp = props.organizationList?.find(
        (item: any) => item.code === props.selectedOrganizationCode
      );
      setHosiptalName(`${hosp?.name} (${hosp?.name_en})`);
    }
  }, [props.organizationList, props.selectedOrganizationCode]);

  useEffect(() => {
    setFilterServicesOfHospitalFB(
      (props.servicesOfHospitalFB || []).filter((item: any) =>
        item.title_en?.includes(searchText)
      )
    );
  }, [props.servicesOfHospitalFB, searchText]);

  useEffect(() => {
    setFilterServicesOfHospitalDJ(
      (props.servicesOfHospitalDJ || []).filter((item: any) =>
        item.display?.includes(searchText)
      )
    );
  }, [props.servicesOfHospitalDJ, searchText]);

  const handleOnChangeServiceItem = (data: any) => {
    console.log("handleOnChangeServiceItem data: ", data);
    let sI = { ...serviceItem, ...data };
    console.log("handleOnChangeServiceItem sI: ", sI);
    setServiceItem(sI);
  };

  const handleOnSave = () => {
    if (props.mode === PREFERRED_MODE.HOME) {
      props.onEvent({
        message: "SaveServiceItemToFirebase",
        params: { serviceItem, card: CARD_PREFERRED_MANAGE_HOME },
      });
    } else {
      props.onEvent({
        message: "SaveServicePreferred",
        params: { serviceItem, card: CARD_PREFERRED_MANAGE_APPOINTMENT },
      });
    }
  };

  const handleOnDeleteIcon = () => {
    if (props.mode === PREFERRED_MODE.HOME) {
      props.onEvent({
        message: "DeleteIconFromFirebase",
        params: { serviceItem, card: CARD_PREFERRED_MANAGE_HOME },
      });
    } else {
      props.onEvent({
        message: "DeleteServicePreferredIcon",
        params: { serviceItem, card: CARD_PREFERRED_MANAGE_APPOINTMENT },
      });
    }
  };

  // console.log("PreferredManage !!! ", props.servicesOfHospitalFB)
  // console.log("filterServicesOfHospital", filterServicesOfHospital)
  // <LocalizationProvider dateAdapter={DateAdapter}>
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "320px 1fr 375px",
          height: "calc(100vh - 47px)",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            gridColumn: 1,
            paddingTop: "10px",
            borderRight: "1px solid rgb(238, 238, 238)",
          }}
        >
          <PreferredList
            // function

            onEvent={props.onEvent}
            onDeleteClickItem={(item: any) => {
              console.log("onDeleteClickItem item ", item);
              if (props.mode === PREFERRED_MODE.HOME) {
                props.onEvent({
                  message: "deleteServiceToFirebase",
                  params: item,
                });
              } else {
                props.onEvent({
                  message: "DeleteServicePreferred",
                  params: item,
                });
              }
            }}
            onClickItem={(item: any) => {
              // console.log("item: ", item);

              let idx = 0;
              if (props.mode === PREFERRED_MODE.HOME) {
                idx = (props.servicesOfHospitalFB || []).findIndex(
                  (soh: any) => soh.id === item.id
                );
              } else {
                idx = (props.servicesOfHospitalDJ || []).findIndex(
                  (soh: any) => soh.id === item.id
                );
              }

              console.log("idx: ", idx);
              setActivePreferredListIndex(idx);
            }}
            onSearch={(search: string) => {
              setSearchText(search);
            }}
            onAdd={() => {
              if (props.mode === PREFERRED_MODE.HOME) {
                props.onEvent({
                  message: "addNewServiceToFirebase",
                  params: { ...serviceItemInitial, mode: props.mode },
                });
                setActivePreferredListIndex(0);
              } else {
                props.onEvent({
                  message: "AddNewServicePreferred",
                  params: { ...serviceItemInitial },
                });
                setActivePreferredListIndex(0);
              }
            }}
            // data
            loadingStatus={props.loadingStatus}
            mode={props.mode}
            listFB={filterServicesOfHospitalFB}
            listDJ={filterServicesOfHospitalDJ}
            activeIndex={activePreferredListIndex}
            byPrefix=""
            titleKey={
              props.mode === PREFERRED_MODE.HOME ? "title_en" : "display"
            }
            disabledAddNew={
              props.mode === PREFERRED_MODE.HOME
                ? props.servicesOfHospitalFB?.[0]?.title_en === ""
                : props.servicesOfHospitalDJ?.[0]?.display === ""
            }
          />
        </div>
        <div className="PostEditor">
          <PreferredTab
            initialActive={0}
            disableMenu={props.loadingStatus}
            activeSegment={0}
            list={[{ title: "Apppointment" }]}
            onClickTab={(_item: any, index: number) => {}}
            panes={[
              {
                key: "settings",
                image: ICONS.settings,
                render: () => (
                  <PreferredEditor
                    showTransferList={props.mode === PREFERRED_MODE.APPOINTMENT}
                    selectedServices={serviceItem}
                    onChange={handleOnChangeServiceItem}
                    hospitalDivisionName={hospitalName}
                    onSave={handleOnSave}
                    onDeleteIcon={handleOnDeleteIcon}
                    typeOptions={props.typeOptions}
                    serviceOptions={
                      props.mode === PREFERRED_MODE.HOME
                        ? props.serviceOptions
                        : []
                    }
                    preferredLoading={
                      props.preferredLoading?.[CARD_PREFERRED_MANAGE_HOME]
                    }
                    mode={props.mode}
                    medProgramList={props.medProgramList}
                    subspecialtiesList={props.subspecialtiesList}
                  />
                ),
              },
            ]}
          />
        </div>

        <div style={{ gridColumn: 3 }}>
          {props.mode === PREFERRED_MODE.HOME &&
          props.selectedOrganizationCode ? (
            <PreferredPreviewHome
              selectedOrganizationCode={props.selectedOrganizationCode}
              servicesOfHospitalFB={props.servicesOfHospitalFB}
            />
          ) : null}
          {props.mode === PREFERRED_MODE.APPOINTMENT &&
          props.selectedOrganizationCode ? (
            <PreferredPreviewAppointment
              organizationList={props.organizationList}
              selectedOrganizationCode={props.selectedOrganizationCode}
              servicesOfHospitalDJ={props.servicesOfHospitalDJ}
            />
          ) : null}
        </div>
      </div>
    </>
    // </LocalizationProvider>
  );
};

export default PreferredManage;
