import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  State as MainState
} from "../MobClinicInterface";

export type State = {
  weightFilterStartDate?: moment.Moment;
  weightFilterEndDate?: moment.Moment;
  obstreticRecord?: any;
  weightPrePregnancy?: any;
  weightGainList?: any;
  weightGainDetail?: any;
};

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage">

export const StateInitial = {
  weightFilterStartDate: moment().clone().add(-1, "years"),
  weightFilterEndDate: moment(),
  obstreticRecord: {},
  weightPrePregnancy: {},
  weightGainList: {},
  weightGainDetail: {},
};

export type Event =
  // GET
  | { message: "handleGetObstreticRecord", params: {} }
  | { message: "handleGetPrePregnancyWeight", params: {} }
  | { message: "handleGetPregnancyWeight", params: {} }
  | {
    message: "handleGetWebPregnancyWeight";
    params: { startDate: moment.Moment; endDate: moment.Moment };
  }
  // CHANGE
  | {
    message: "handleChangeFilterDate";
    params: { startDate: moment.Moment; endDate: moment.Moment };
  }
  //   SAVE
  | {
    message: "handleAddWeightGain";
    params: { measure_date: string; data: any };
  }
  | { message: "handleAddPrePregnantWeight"; params: { weight: string } }
  | { message: "handleRemoveWeightGain"; params: {} };

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetObstreticRecord: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.OBSTRETIC_RECORD,
    data_type: DATA_TYPE.OBSTRETIC_RECORD,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    obstreticRecord: response,
  });
};

export const handleGetPrePregnancyWeight: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.PREGNANCY_WEIGHT,
    data_type: DATA_TYPE.PRE_PREGNANCY_WEIGHT,
  };
  const [error, response] = await commonGetPatientDataLatest(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    weightPrePregnancy: response,
  });
};

export const handleGetPregnancyWeight: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const tmpParams: any = {
    group: GROUP.PREGNANCY_WEIGHT,
    data_type: DATA_TYPE.PREGNANCY_WEIGHT,
    start_measure: state.weightFilterStartDate?.format(BACKEND_DATE_FORMAT),
    end_measure: state.weightFilterEndDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    weightGainList: response,
  });
};

export const handleGetWebPregnancyWeight: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  const tmpParams: any = {
    group: GROUP.PREGNANCY_WEIGHT,
    data_type: DATA_TYPE.PREGNANCY_WEIGHT,
    start_measure: params.startDate.format(BACKEND_DATE_FORMAT),
    end_measure: params.endDate.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    weightGainList: response,
  });
};

// CHANGE
export const handleChangeFilterDate: Handler = async (controller, params) => {
  controller.setState({
    weightFilterStartDate: params.startDate,
    weightFilterEndDate: params.endDate,
  });
};

// SAVE
export const handleAddWeightGain: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller as any, data);
  } else {
    data = {
      group: GROUP.PREGNANCY_WEIGHT,
      data_type: DATA_TYPE.PREGNANCY_WEIGHT,
      measure_date: params.measure_date,
      data: { ...params.data },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save weight gain success.";
    handleGetPregnancyWeight(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleAddPrePregnantWeight: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const data = {
    group: GROUP.PREGNANCY_WEIGHT,
    data_type: DATA_TYPE.PRE_PREGNANCY_WEIGHT,
    measure_date: moment().format(
      `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
    ),
    data: { ...params },
  };
  const [error, response] = await commonCreatePatientData(controller as any, data);

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save weight gain success.";
    handleGetPrePregnancyWeight(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleRemoveWeightGain: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete weight gain success.";
    }

    handleGetPregnancyWeight(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
