import React from "react";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
// import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

type MobFeedSkeletonProps = {};

export const COLOR = {
  opacity: "rgba(133,133,133,0.025)",
};

const useStyles = makeStyles((theme) => ({
  list_horizontal: {
    width: "100%",
    padding: "5px 5px 0",
    display: "flex",
    alignItems: "center",
    height: "37px",
    background: COLOR.opacity,
    borderRadius: "15px",
    marginTop: "15px",
  },
  box_collection_menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: COLOR.opacity,
    borderRadius: "10px",
    width: "100%",
    height: "99px",
  },
  collection_menu_inner: {
    width: "100%",
    padding: "5px 5px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box_service: {
    width: "100%",
    padding: "5px 5px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box_card: {
    background: COLOR.opacity,
    borderRadius: "20px",
    width: "100%",
    height: "180px",
    padding: "20px 15px",
    display: "grid",
    gridTemplateColumns: "50px auto",
  },
}));

export const FixedMenuSkeleton: React.FunctionComponent<any> = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.box_collection_menu}
      style={{ background: props.background }}
    >
      {new Array(3).fill("").map((_: any, index: number) => (
        <div
          key={"skeleton-" + index}
          className={classes.collection_menu_inner}
        >
          <Skeleton
            animation="pulse"
            variant="circular"
            width={60}
            height={56}
          />
          <Skeleton
            animation="pulse"
            height={18}
            width="70%"
            style={{ marginTop: "8px" }}
          />
        </div>
      ))}
    </div>
  );
};

const MobFeedSkeleton: React.FunctionComponent<MobFeedSkeletonProps> = (
  props
) => {
  const classes = useStyles();

  if (typeof globalThis !== "undefined" ? globalThis.location.pathname !== "/" : window.location.pathname !== "/") {
    return (
      <div>
        <div>
          {/* Error เมื่อไม่มี IntlProvider */}
          {/* <FormattedMessage id="bplusClinicKey298" /> */}
          Loading...
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        background:
          "linear-gradient(0deg, rgba(1, 71, 163, 0.2) -7.92%, rgba(1, 71, 163, 0) 100%), #FFFFFF",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding: "15px" }}>
        <Skeleton
          animation="pulse"
          variant="rectangular"
          width={55}
          height={55}
        />
        <Skeleton
          animation="pulse"
          height={30}
          style={{ marginLeft: "15px" }}
          width="45%"
        />
      </div>

      <div style={{ padding: "15px" }}>
        <FixedMenuSkeleton />

        {new Array(2).fill("").map((_: any, index: number) => (
          <div key={"skeleton-" + index} className={classes.list_horizontal}>
            <Skeleton
              animation="pulse"
              variant="rectangular"
              width={"28%"}
              height={21}
              style={{ marginRight: "20px" }}
            />
            <Skeleton animation="pulse" height={18} width="50%" />
          </div>
        ))}

        <Skeleton
          animation="pulse"
          height={22}
          width="40%"
          style={{ marginTop: "30px" }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
          }}
        >
          {new Array(5).fill("").map((_: any, index: number) => (
            <div key={"skeleton-" + index} className={classes.box_service}>
              <Skeleton
                animation="pulse"
                variant="circular"
                width={45}
                height={45}
              />
              <Skeleton
                animation="pulse"
                height={18}
                width="90%"
                style={{ marginTop: "5px" }}
              />
            </div>
          ))}
        </div>

        <Skeleton
          animation="pulse"
          height={22}
          width="40%"
          style={{ margin: "15px 0" }}
        />

        <div className={classes.box_card}>
          <div>
            <Skeleton
              animation="pulse"
              variant="rectangular"
              width={55}
              height={55}
              style={{ marginTop: "5px" }}
            />
          </div>
          <div style={{ marginLeft: "15px" }}>
            {[100, 50, 70].map((width: number, index: number) => (
              <Skeleton
                key={"skeleton-" + index}
                animation="pulse"
                height={18}
                width={`${width}%`}
                style={{ marginTop: "5px" }}
              />
            ))}
          </div>
        </div>

        {[100, 50, 70].map((width: number, index: number) => (
          <Skeleton
            key={"skeleton-" + index}
            animation="pulse"
            height={30}
            width={`${width}%`}
            style={{ marginTop: "8px" }}
          />
        ))}
      </div>
    </div>
  );
};

MobFeedSkeleton.defaultProps = {} as MobFeedSkeletonProps;

export default MobFeedSkeleton;
