import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Button as ButtonMui } from "@material-ui/core";
import MobCouponChoice from "./MobCouponChoice";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  combineQuestion,
  answerInjectDate,
  answervaccineBrand,
  filterVaccineExtraAnswer,
  isTextInside,
  questionCovidText,
} from "./MobCouponEvaluate";
import { Modal } from "semantic-ui-react";
import moment from "moment";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

import DateRangeTwoToneIcon from "@material-ui/icons/DateRangeTwoTone";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  progress: {
    width: "50%",
    marginBottom: "8px",
  },
  numberQuestion: {
    marginBottom: "8px",
  },
  paper: {
    padding: "10px 30px 30px 30px",
    width: "90%",
  },
  question: {
    margin: "20px 20px 20px 0px",
    fontWeight: "bolder",
  },
  choice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  nextButton: {
    justifyContent: "space-between",
    display: "flex",
  },
  caution: {
    display: "flex",
    backgroundColor: "#0147A3",
    marginTop: "22px",
    padding: "14px",
    justifyContent: "space-between",
  },
  cautionImg: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cautionText: {
    color: "#FFFFFF",
    flex: "3",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  buttonDarkblue: {
    color: "#FFFFFF",
    backgroundColor: "#0147A3",
  },
  resultEvaluate: {
    display: "flex",
    backgroundColor: "white",
    margin: "22px 0px",
    padding: "14px",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  resultImg: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resultText: {
    flex: "3",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "10px",
  },
}));

const MobCouponQuestion = (props: any) => {
  const [textMessage, setTextMessage] = useState("");
  const [language, setLanguage] = useState("");
  const [suggestMessage, setSuggestMessage] = useState("");
  const classes = useStyles();

  let questionList: any = [];
  let choiceList: any = [];

  // console.log(" props.history.location.state.extra", props.history.location.state.extra)

  useEffect(() => {
    if (props.countLevel === 1) {
      if (props?.answer["0"]?.answer === "English") {
        setLanguage("EN");
      } else {
        setLanguage("TH");
      }
    }
  }, [props.countLevel]);

  // get relate Level of FormTree
  if ("[object Object]" === Object.prototype.toString.call(props?.answer)) {
    for (const [key, value] of Object.entries(props?.answer)) {
      /////-------------------------------------------------------------
      ///// questionList QUESTION
      /////-------------------------------------------------------------
      if (
        value?.level === props?.countLevel &&
        value?.extra_finished &&
        value?.selected
      ) {
        // Normal Question
        questionList.push(value);
      } else if (
        value?.level === props?.countLevel &&
        !value?.extra_finished &&
        value?.selected &&
        value?.extra_intro
      ) {
        // Extra Question
        questionList.push(value);
      }

      /////-------------------------------------------------------------
      ///// choiceList CHOICE
      /////-------------------------------------------------------------
      if (value?.level === props?.countLevel + 1) {
        // Normal Choice
        choiceList.push(value);
      } else if (
        value?.level === props?.countLevel &&
        // !value?.extra_finished &&
        value?.selected &&
        value?.extra_intro
      ) {
        // Extra Choice (extra_items) same as Extra Question
        choiceList.push(value);
      }
    }
  }

  console.log("props?.answer", props?.answer);
  console.log("questionList", questionList);
  console.log("choiceList: ", choiceList);

  // Choice List of related Question
  let currentQuestion = (questionList?.length > 0 && questionList[0]) || {};
  console.log("currentQuestion: ", currentQuestion);

  let currentchoice = choiceList.filter((item: any) => {
    return item.parent_id === currentQuestion?.id;
  });

  if (currentchoice?.length === 0) {
    currentchoice = choiceList.filter((item: any) => {
      if (item.extra_intro && item.extra_items?.length > 0 && item.selected) {
        // No need to find parent
        return item;
      } else {
        // Find normal Choice
        return item.parent_id === currentQuestion?.id;
      }
    });
  }

  // Compute progress percent
  let progressLevel;
  if (props.max > 0) {
    progressLevel = ((props?.countLevel + 1) / props.max) * 100;
  }

  console.log("currentQuestion: ", currentQuestion);
  console.log("currentchoice: ", currentchoice);

  // IsExtra
  const isExtra =
    currentchoice?.length === 1 &&
    currentchoice[0].selected &&
    currentchoice[0].extra_items &&
    currentchoice[0].extra_items?.length > 0;

  /***************************************************************************************************************/
  // Is Finish Question
  // ดูได้จากจำนวนเข็ม + ลงวันที่ครบ
  // บวกกับ type end
  /***************************************************************************************************************/

  // ดูได้จากจำนวนเข็ม + ลงวันที่ครบ
  let isFinish = false;
  let completeOnInjectDate = false;
  if (!isNaN(props.injectCovidCount) && props.injectCovidCount > 0 && isExtra) {
    let answerList = filterVaccineExtraAnswer(
      currentchoice[0].extra_items,
      props.subCountLevel
    );
    // console.log('answerList: ', answerList);
    // ลิมิตตัว จำนวนคำถามตาม จำนวนคำตอบที่ว่าฉีดไปกี่เข็ม (คูณ 2 เพราะว่า คำถามมีเพิ่มมา ยี่ห้อกับวันที่)
    answerList = answerList.slice(0, props.injectCovidCount * 2);
    // console.log("Check Finish ?? answerList: ", answerList);
    if (
      answerList?.length > 0 &&
      answerList.filter((item) => !item.value)?.length === 0
    ) {
      completeOnInjectDate = true;
    }
  }
  // console.log('completeOnInjectDate: ', completeOnInjectDate);
  // console.log("currentQuestion?.type", currentQuestion?.type);
  // บวกกับ type end + ไม่มี extra_intro และ
  if (
    currentQuestion?.type === "end" &&
    !("extra_intro" in currentQuestion) &&
    !(
      "extra_items" in currentQuestion &&
      currentQuestion["extra_items"].length > 0
    )
  ) {
    // console.log("Finish on normal choice")
    isFinish = true;

    /// TODO: Check Exceed Render
    if (currentQuestion?.text && currentQuestion?.text?.length > 0) {
      if (textMessage !== currentQuestion?.text) {
        setTextMessage(currentQuestion?.text);
        props.setModInjectionCancel({
          open: true,
          text: currentQuestion?.text,
          buttonName: language === "EN" ? "Main menu" : "กลับสู่หน้าหลัก",
        });
      }
    }
  }

  // console.log("props.injectCovidCount", props.injectCovidCount);
  // console.log("props.subCountLevel", props.subCountLevel);
  if (isNaN(props.injectCovidCount) || completeOnInjectDate) {
    isFinish = true;
  }

  // console.log("isFinish", isFinish);

  /***************************************************************************************************************
  ตรวจว่าผุ้ใช้เลือก หัวข้อหรือยัง
***************************************************************************************************************/
  let userChoose = false;
  if (!isExtra) {
    // Normal
    userChoose =
      currentchoice?.filter((item: any) => item.selected)?.length > 0;
  } else {
    // Extra แบบอื่นๆ เว้น ลงวันที่
    let currentExtra = currentQuestion?.extra_items?.[props.subCountLevel];

    // ดูว่า คำถามปัจจุบันถึงจุดที่ถามเกี่ยวกับ วันที่ของการฉีดหรือไม่ โดยใน find ถ้า > 0 แสดงว่าใช่
    let currentLabelIsContainDose =
      [...answerInjectDate, ...answervaccineBrand].find((item: any) =>
        currentExtra?.label?.includes(item)
      ) || false;

    // console.log("currentLabelIsContainDose", currentLabelIsContainDose);
    if (currentExtra?.value && !currentLabelIsContainDose) {
      userChoose = true;
    } else if (!!currentLabelIsContainDose) {
      userChoose = completeOnInjectDate;
    }
  }

  /***************************************************************************************************************
  เตรียมคำสำหรับ ผลการประเมิน 
***************************************************************************************************************/

  
  useEffect(() => {
    if (props.displaySuggest) {
      if (props.displaySuggest?.canInjectDate === null) {
        // ขึ้นแจ้ง ให้รอ Gen 2
        if (language === "EN") {
          setSuggestMessage(
            "Based on your history, it is recommended to wait for the second generation vaccine.You can still transfer this E-coupon to someone else to use within 30/04/2022"
          );
        } else {
          setSuggestMessage(
            "แนะนำให้ท่านรอวัคซีน Generation ที่ 2 ทั้งนี้ท่านยังสามารถมอบ E-coupon นี้ให้ผู้อื่นใช้ ภายใน 30/04/2022"
          );
        }
      } else if (props.displaySuggest?.doseCount >= 1) {
        // ขึ้นเข็มแรกสำหรับคุณ ไม่มีแจ้งวัน / เข็มสอง เข็มสาม สำหรับคุณ พร้อมบอกวัน  /

        let firstEnText = (date) => `This booking is for your 1st dose.\nBased on your history, your next vaccination is from\n${date} onwards.`
        let firstThText = (date) => `การนัดหมายนี้สำหรับเข็มแรกของคุณ\nจากประวัติของท่านสามารถรับวัคซีนได้ตั้งแต่\n${date} เป็นต้นไป`
        let nextDoseEnText = (doseCount,date) => { 
          let order = doseCount === 2 ? "nd" : doseCount === 3 ? "rd" : "th"
          return `This booking is for your ${doseCount}${order} dose.\nBased on your history, your next vaccination is from\n${date} onwards.`
        }
        let nextDoseThText = (doseCount,date) => `การนัดหมายนี้สำหรับวัคซีนเข็มที่ ${doseCount}\nจากประวัติของท่านสามารถรับวัคซีนได้ตั้งแต่\n${date} เป็นต้นไป`
        // let warningFouthEnText = () => `\n\nIn the case of moderna vaccination is not in accordance with the hospital guideline.\nI hereby confirm to receive the Moderna vaccine as my determination.`
        // let warningFouthThText = () => `\n\nกรณีการรับวัคซีนทางเลือกโมเดอร์น่า ไม่ตรงตามแนวทางที่ รพ. กำหนดนั้น ข้าพเจ้ายืนยันขอรับวัคซีนทางเลือกโมเดอร์น่า ตามวัตถุประสงค์ของข้าพเจ้า`


        if (
          props.displaySuggest?.doseCount === 1 &&
          moment.isMoment(props.displaySuggest?.canInjectDate)
        ) {
          // find diff from today for check positive value then
          let delayFromToday = props.displaySuggest?.canInjectDate
            ?.startOf("day")
            .diff(moment().startOf("day"), "days");
          if (delayFromToday > 3) {
            // ได้ฉีดเข็มแรก มากกว่า 3 วัน 
            if (language === "EN") {
              let date = props.displaySuggest?.canInjectDate
                ?.locale("en")
                .format("DD MMM YYYY");
              setSuggestMessage(
                firstEnText(date)
              );
            } else {
              let date = props.displaySuggest?.canInjectDate
                ?.locale("th")
                .format("DD MMM YYYY");
              setSuggestMessage(
                firstThText(date)
              );
            }
          } else {
            // ได้วันนี้ เลย ซึ่งไม่น่าจะเกิดขึ้นแล้ว เพราะ + 3 วัน ถูกนำไป คำนวน ใน EvalulateAppointmentDate แล้ว 
            if (language === "EN") {
              setSuggestMessage("This booking is for your 1st dose.");
            } else {
              setSuggestMessage("การนัดหมายนี้สำหรับเข็มแรกของคุณ");
            }
            console.log("Should not see me !")
          }
        } else if (props.displaySuggest?.doseCount >= 2 &&
          moment.isMoment(props.displaySuggest?.canInjectDate)
        ) {
          let doseCount = props.displaySuggest?.doseCount 
      
          if (props.displaySuggest?.suggestionInfo === "") {
            // แนะนำวัน ตามปกติ
            if (language === "EN") {
              let date = props.displaySuggest?.canInjectDate
                .locale("en")
                .format("DD MMM YYYY");
              setSuggestMessage(
                nextDoseEnText(doseCount, date));
            } else {
              let date = props.displaySuggest?.canInjectDate
                .locale("th")
                .format("DD MMM YYYY");
              setSuggestMessage(
                nextDoseThText(doseCount,date));
            }
          } else {  
            // คนไข้ ต้องการฉีด ไม่รอ Gen 2 และ รพ. อนุญาต
            setSuggestMessage(props.displaySuggest?.suggestionInfo)
          }
        }
      }
    }
  }, [props.displaySuggest]);

  /***************************************************************************************************************
  ตรวจว่า เลือก ยังไม่เคยติด Covid ให้ข้าม 2 level (hardcode)
***************************************************************************************************************/

  console.log("Level", props.countLevel, props.subCountLevel);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.numberQuestion}>
        {language === "EN" ? "No. " : "ข้อที่ "} {props.countLevel + props.subCountLevel + 1}
        </div>
        {/* <LinearProgress
          variant="determinate"
          className={classes.progress}
          value={progressLevel}
        /> */}
        <Paper elevation={3} className={classes.paper}>
          {!isExtra ? (
            <div className={classes.question}>
              {currentQuestion?.extra_intro
                ? currentQuestion?.extra_intro
                : currentQuestion?.text
                ? currentQuestion?.text
                : null}
            </div>
          ) : null}

          <div className={classes.choice}>
            <MobCouponChoice
              choice={currentchoice}
              currentQuestion={currentQuestion}
              selectedChoice={props.selectedChoice}
              selectedExtra={props.selectedExtra}
              subCountLevel={props.subCountLevel}
              injectCovidCount={props.injectCovidCount}
              language={language}
            />
          </div>
          <div className={classes.nextButton}>
            {!props.isEvaluated && (
              <ButtonMui
                className={classes.buttonDarkblue}
                color="primary"
                variant="contained"
                style={{ display: props.countLevel === 0 ? "none" : "" }}
                onClick={() => {
                  if (isExtra && props.subCountLevel !== 0) {
                    // Extra Item

                    props.backExtraQuestion({ id: currentchoice[0]?.id });

                    // Need Clear for Auto Next
                  } else {
                    // Normal Selected
                    props.backQuestion();
                  }
                }}
              >
                <FormattedMessage id="bplusClinicKey79" />
              </ButtonMui>
            )}
            {isFinish && !props.isEvaluated ? (
              <ButtonMui
                color="primary"
                className={classes.buttonDarkblue}
                variant="contained"
                disabled={!userChoose}
                onClick={() => {
                  if (isFinish) {
                    // Last Question
                    // console.log(" currentchoice", currentchoice);
                    // console.log(" currentQuestion", currentQuestion);
                    // alert("submit API to Backend");

                    props.finishEvaluate(currentQuestion?.id, language);
                  } else if (isExtra) {
                    // Extra Item
                    props.nextExtraQuestion();
                  } else {
                    // Normal Selected
                    props.nextQuestion();
                  }
                }}
              >
                {isFinish ? "Finish" : "Next"}
              </ButtonMui>
            ) : null}
          </div>
        </Paper>
      </div>
      {!!props.displaySuggest && (
        <div className={classes.resultEvaluate}>
          <div className={classes.resultImg}>
            {/* <img
              src="/images/calendar_exclamation.png"
              alt="mea.icon"
              height="50px"
            /> */}
            <DateRangeTwoToneIcon />
          </div>

          <div className={classes.resultText}>
            {suggestMessage.split("\n").map((item) => (
              <>
                {" "}
                {item} <br />{" "}
              </>
            ))}
          </div>
          <ButtonMui
            color="primary"
            className={classes.buttonDarkblue}
            style={{
              display: props.displaySuggest?.suggestDate === null ? "none" : "",
            }}
            variant="contained"
            onClick={() => {
              props.onToAppointment(props.displaySuggest?.suggestDate);
            }}
          >
            {language === "EN" ? "Make Appointment" : "ทำนัดหมาย"}
          </ButtonMui>
        </div>
      )}
      {(props.countLevel === 3 ||
        (currentchoice?.length === 1 &&
          isTextInside(
            currentchoice[0].extra_items[props.subCountLevel].label,
            questionCovidText
          ))) && (
        <div className={classes.caution}>
          <div className={classes.cautionImg}>
            <img
              src="/images/calendar_exclamation.png"
              alt="mea.icon"
              height="50px"
            />
          </div>
          <div className={classes.cautionText}>
            {language === "EN"
              ? props.countLevel === 3
                ? "It is recommended to inject after 12 weeks of gestation onwards."
                : "For patients who have had COVID-19, it is recommended to inject 30 days after the first date of knowing COVID-19 infection."
              : props.countLevel === 3
              ? "กรณีตั้งครรภ์ แนะนำให้ฉีดเมื่อหลังอายุครรภ์ 12 สัปดาห์เป็นต้นไป"
              : "สำหรับผู้ป่วยที่เคยเป็น COVID-19 แนะนำให้ฉีดวัคซีนหลังจากวันที่ตรวจพบการติดเชื้อ COVID-19 อย่างน้อย 30 วัน"}
          </div>
        </div>
      )}
    </>
  );
};

export default MobCouponQuestion;
