import WasmController from 'react-lib/frameworks/WasmController';
import * as LoginI from './login/LoginInterface';
import * as TagI from 'react-lib/frameworks/TagI';
import * as ContentI from "./content/ContentInterface";

export type State = 
  {
  } &
  LoginI.State &
  TagI.State &
  ContentI.State

export const StateInitial: State = 
  {
    ...LoginI.StateInitial,
    ...TagI.StateInitial,
    ...ContentI.StateInitial
  }

export type Event = 
  { message: "DidMount", params: {} } |
  LoginI.Event |
  TagI.Event |
  ContentI.Event

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

  export const DidMount: Handler = async (controller, params) => {
    const onFirebaseAuthStateChanged = (user: any) => {
      if (user) {
        // Check if logged in
        console.log("user: ", user.uid);
        controller.setState({ loggedin: true });
      } else {
        console.log("no user");
      }
    };
    controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
    const apiToken = controller.cookies.get("apiToken") || ""
    if (apiToken !== "") {
      controller.apiToken = apiToken;
      controller.setState({ loggedin: true})
    }
  }
