import React, { useMemo, useState, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import { useHistory, useLocation } from "react-router";

// UX
import CardRadioList from "./CardRadioList";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// telephar

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type SelectCheckupHospitalProps = {
  onEvent: (e: Event) => any;
} & Pick<State, "hospitalList">;

export type CHLocationType = {
  storedState?: {
    hospital?: Record<string, any>;
  };
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  very_light_blue: "rgba(1, 71, 163, 0.03)",
};

export const titleStyles = {
  fontSize: "1.125rem",
  fontWeight: "bold",
  color: COLORS.grey,
  marginBottom: "1rem",
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "16px",
    display: "flex",
    minHeight: "calc(100vh - 50px)",
    flexDirection: "column",
    "&:before": {
      top: "-50px",
      minHeight: "100vh",
    },
  },
  title: titleStyles,
}));

const SelectCheckupHospital = (props: SelectCheckupHospitalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<CHLocationType>();

  const [selectedHospital, setSelectedHospital] = useState<any>(null);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  // Memo
  const hospitalItems = useMemo(() => {
    return (props.hospitalList || []).map((item) => {
      return {...item,id: item.code}
    })
  }, [props.hospitalList]);

  // Effect
  useEffect(() => {
    if (locState?.storedState) {
      setSelectedHospital(locState?.storedState.hospital?.code);
    }
  }, [locState.storedState]);

  // Handler
  const handleConfirmHospital = () => {
    const hospital = hospitalItems.find(
      (item) => item.code === selectedHospital
    );

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: URLS.SELECT_CHECKUP_TYPE,
        state: {
          hospital,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        setTitle={"เลือกโรงพยาบาล"}
        whiteTheme={true}
        bgGradient={true}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      <div className={`${classes.screen} bg-gradient-container`}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>
            เลือกโรงพยาบาลที่ต้องการตรวจสุขภาพ
          </div>

          <CardRadioList
            items={hospitalItems as any[]}
            selected={selectedHospital}
            // callback
            onSelect={setSelectedHospital}
          />
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!selectedHospital}
              // callback
              onClick={handleConfirmHospital}
            >
              ถัดไป
            </MuiButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SelectCheckupHospital);
