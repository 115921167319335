import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
} from "../MobClinicInterface";

export type State = {
  emotionFilterDate?: moment.Moment;
  emotionDuration?: string;
  emotionRecordList?: any;
  restRecord?: any;
  emotionRestHistoryList?: any[];
};

export const StateInitial = {
  emotionFilterDate: moment(),
  emotionDuration: "7",
  emotionRecordList: {},
  restRecord: {},
  emotionRestHistoryList: [],
};

export type Event =
  // GET
  | { message: "handleGetEmotion", params: {} }
  | { message: "handleGetRest", params: {} }
  | {
    message: "handleGetEmotionRestHistory";
    params: { start_measure: string; end_measure: string };
  }
  // CHANGE
  | {
    message: "handleChangeEmotionFilterDate";
    params: { date: moment.Moment };
  }
  | {
    message: "handleChangeEmotionRestFilterDuration";
    params: { duration: string };
  }
  // SAVE
  | { message: "handleSaveEmotion"; params: {} }
  | { message: "handleSaveRest"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetEmotion: Handler = async (controller) => {
  console.log("handleGetEmotion")
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  console.log('state: ', state);

  const tmpParams: any = {
    group: GROUP.EMOTION,
    data_type: DATA_TYPE.EMOTION,
    start_measure: state.emotionFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  console.log('tmpParams: ', tmpParams);
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  console.log("response", response)
  console.log("error", error)
  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    emotionRecordList: response,
  });
};

export const handleGetRest: Handler = async (controller) => {
  console.log("handleGetRest")
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const startMeasure: string = state.emotionFilterDate
    ?.clone()
    ?.add(-1, "days")
    .format(BACKEND_DATE_FORMAT);
  const endMeasure: string =
    state.emotionFilterDate?.format(BACKEND_DATE_FORMAT);

  // get
  const tmpParams: any = {
    group: GROUP.EMOTION,
    data_type: DATA_TYPE.REST,
    start_measure: startMeasure,
    end_measure: endMeasure,
  };
  console.log('tmpParams: ', tmpParams);
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  console.log("response", response)
  console.log("error", error)

  // set
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      restRecord: {},
    });
  } else {
    let yesterdayData: any = {};
    let todayData: any = {};
    response?.items.forEach((item: any) => {
      const itemDate: string = moment(
        item?.measure_date,
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ).format(BACKEND_DATE_FORMAT);
      if (itemDate === startMeasure) {
        yesterdayData = item;
      } else if (itemDate === endMeasure) {
        todayData = item;
      }
    });

    let result: any = {};
    if (yesterdayData.hasOwnProperty("id") && yesterdayData?.data?.hours) {
      result = todayData;
    } else if (!yesterdayData.hasOwnProperty("id")) {
      result = todayData;
    } else {
      result = yesterdayData;
    }
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      restRecord: result,
    });
  }
};

export const handleGetEmotionRestHistory: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let promiseArr: any[] = [];

  // Emotion
  const emotionParams: any = {
    group: GROUP.EMOTION,
    data_type: DATA_TYPE.EMOTION,
    ...params, // params: start_measure, end_measure
  };
  promiseArr.push(commonListPatientDataList(controller, emotionParams));

  // Rest
  const restParams: any = {
    group: GROUP.EMOTION,
    data_type: DATA_TYPE.REST,
    ...params, // params: start_measure, end_measure
  };
  promiseArr.push(commonListPatientDataList(controller, restParams));

  // Get
  const failResponse = await Promise.all(promiseArr);

  let errorMessage: any[] = [];
  let result: any[] = [];
  failResponse.forEach((resp: any) => {
    if (resp[0]) {
      errorMessage.push(resp[0]);
    } else {
      resp[1].items.forEach((item: any) => {
        const measureDate: moment.Moment = moment(item?.measure_date);
        const index: number = result.findIndex(
          (x) =>
            moment(x.measure_date).format(BACKEND_DATE_FORMAT) ===
            measureDate.format(BACKEND_DATE_FORMAT)
        );

        if (item.data_type === DATA_TYPE.EMOTION) {
          if (index === -1) {
            result.push({
              measure_date: measureDate,
              feeling: item?.data?.feeling,
              stress: item?.data?.stress,
            });
          } else {
            result[index].feeling = item?.data?.feeling;
            result[index].stress = item?.data?.stress;
          }
        } else if (item.data_type === DATA_TYPE.REST) {
          if (index === -1) {
            result.push({ measure_date: measureDate, sleep: item?.data });
          } else {
            result[index].sleep = item?.data;
          }
        }
      });
    }
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage?.[0] ? errorMessage : null,
    successMessage: null,
    emotionRestHistoryList: result,
  });
};

// CHANGE
export const handleChangeEmotionFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ emotionFilterDate: moment(params.date, "DD/MM/YYYY") });
};

export const handleChangeEmotionRestFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ emotionDuration: params.duration });
};

// SAVE
export const handleSaveEmotion: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const state = controller.getState();

    data = {
      group: GROUP.EMOTION,
      data_type: DATA_TYPE.EMOTION,
      measure_date: state.emotionFilterDate?.format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save emotion success.";
    handleGetEmotion(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const handleSaveRest: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const state = controller.getState();

    data = {
      group: GROUP.EMOTION,
      data_type: DATA_TYPE.REST,
      measure_date: `${state.emotionFilterDate?.format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`,
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save rest success.";
    handleGetRest(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
