import React, { useEffect, useState } from "react";
// Mui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { FormattedMessage } from "react-intl";

import { useStyles, COLORS, styles, MedAllergyType } from "./AllergyDetail";
import { contains } from "./CardWarningTab";

// Types
type CardAllergyTabProps = {
  // data
  data: MedAllergyType[];
  expanded?: string;
  // callback
  onToggle: (panel: string) => any;
};
// Constants
export const ALLERGY_ASSESSMENT = {
  CERTAIN: "Certain",
  PROBABLE: "Probable",
  POSSIBLE: "Possible",
  ALLERGY_HISTORY: "Allergy history",
  SIDE_EFFECT: "Side effect",
  PHARMACOGNETIC_TESTING: "pharmacogenetic",
} as const;

const ALLERGY_COLOR = {
  [ALLERGY_ASSESSMENT.CERTAIN]: "#EE1C24",
  [ALLERGY_ASSESSMENT.PROBABLE]: "#F39C12",
  [ALLERGY_ASSESSMENT.POSSIBLE]: "#F4D03F",
  [ALLERGY_ASSESSMENT.ALLERGY_HISTORY]: "#F9E79F",
  [ALLERGY_ASSESSMENT.SIDE_EFFECT]: "#82B9E7A8",
  [ALLERGY_ASSESSMENT.PHARMACOGNETIC_TESTING]: "#7879F1",
};

const ALLERGY_ORDER = {
  [ALLERGY_ASSESSMENT.CERTAIN]: 0,
  [ALLERGY_ASSESSMENT.PROBABLE]: 1,
  [ALLERGY_ASSESSMENT.POSSIBLE]: 2,
  [ALLERGY_ASSESSMENT.ALLERGY_HISTORY]: 3,
  [ALLERGY_ASSESSMENT.SIDE_EFFECT]: 4,
  [ALLERGY_ASSESSMENT.PHARMACOGNETIC_TESTING]: 5,
};

const CardAllergyTab: React.FC<CardAllergyTabProps> = (props) => {
  const classes = useStyles();
  const [allergyList, setAllergyList] = useState<any[]>([]);

  const handleToggle = (panel: any) => (_event: any, newExpanded: boolean) => {
    props.onToggle(newExpanded ? panel : "");
  };

  useEffect(() => {
    const list = props.data.map((item) => {
      const order = Object.entries(ALLERGY_ORDER).find(([key]) => {
        if (item.probability != null) {
          return item.probability.toLowerCase().includes(key.toLowerCase());
        } else {
          return item.type.toLowerCase().includes(key.toLowerCase());
        }
      })?.[1];

      return { ...item, order };
    });

    setAllergyList(list.sort((a, b) => Number(a?.order) - Number(b?.order)));
  }, [props.data]);

  return (
    <>
      {allergyList.map((item: any, index: any) => (
        <Accordion
          key={"allergy-accordion-" + index}
          className={classes.accordion}
          style={{
            borderLeft: `15px solid ${contains(
              ALLERGY_COLOR,
              item.probability ? item.probability : item.type
            )}`,
          }}
          expanded={props.expanded === `panel-${index}`}
          onChange={handleToggle(`panel-${index}`)}
        >
          <AccordionSummary
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
              }}
            >
              <div style={{ padding: "12px 10px 18px 18px" }}>
                <div
                  style={{
                    color: contains(
                      [ALLERGY_ASSESSMENT.CERTAIN],
                      item.probability ? item.probability : item.type
                    )
                      ? ALLERGY_COLOR[ALLERGY_ASSESSMENT.CERTAIN]
                      : COLORS.blue_bdms,
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  {/* แสดง TradeName - หากไม่มี GenericDrug และมี TradeName */}
                  {!item.name_generic && !!item.name_trade
                    ? item.name_trade
                    : item.name_generic}
                  {/* แสดง (TradeName) หลัง GenericDrug - หากมี GenericDrug และมี TradeName */}
                  {!!item.name_trade && !!item.name_generic
                    ? ` (${item.name_trade})`
                    : ""}
                </div>

                {props.expanded !== `panel-${index}` && (
                  <div
                    style={{
                      color: COLORS.blue_bdms,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormattedMessage id={"allery_seemore"} />
                  </div>
                )}

                {item.type
                  .toLowerCase()
                  .includes(
                    ALLERGY_ASSESSMENT.PHARMACOGNETIC_TESTING.toLowerCase()
                  ) ? (
                  <>
                    {props.expanded === `panel-${index}` && (
                      <div
                        style={{ color: COLORS.blue_bdms, margin: "10px 0" }}
                      >
                        {"Pharmacognetic Testing"}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div style={styles.divider}></div>
                    {props.expanded !== `panel-${index}` ? (
                      <div style={{ color: "#797878" }}>
                        {item.probability ? item.probability : item.testing}
                      </div>
                    ) : (
                      <div
                        style={{ color: COLORS.blue_bdms, marginTop: "12px" }}
                      >
                        <FormattedMessage id={"allery_adverse_reaction"} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </AccordionSummary>

          {/* Full card detail  */}
          <AccordionDetails>
            {item.type
              .toLowerCase()
              .includes(
                ALLERGY_ASSESSMENT.PHARMACOGNETIC_TESTING.toLowerCase()
              ) ? (
              <div style={{ padding: "0 0 0 2px", marginTop: "-15px" }}>
                <div
                  style={{
                    backgroundColor: "rgba(233, 233, 233, 0.44)",
                    borderRadius: "5px",
                    padding: "0px 10px",
                    textIndent: "15px",
                  }}
                >
                  {item.comment ? `${item.comment} ` : ""}
                  {item.testing}
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr",
                }}
              >
                <div style={{ padding: "0 0 0 2px", marginTop: "-15px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(233, 233, 233, 0.44)",
                      borderRadius: "5px",
                      padding: "10px 10px 15px",
                      textIndent: "15px",
                    }}
                  >
                    {item.comment ? `${item.comment} ` : ""}
                    {item.testing}
                  </div>
                  <div style={{ color: COLORS.blue_bdms, margin: "10px 0" }}>
                    <FormattedMessage id={"allery_probabillity"} />
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(233, 233, 233, 0.44)",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {item.probability}
                  </div>
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

// MOCK_DATA
const MOCK_ALLERGY = [
  { q01: "Allopurinol", q05: "Certain" },
  { q01: "Enoxaparin", q05: "Probable" },
  { q01: "Celecoxib (Celebrex®)", q05: "Possible" },
  { q01: "Simvastatin", q05: "Allergy history" },
  { q01: "Amoxicillin", q05: "Side effect" },
  { q01: "Amoxicillin", q05: "Pharmacognetic Testing" },
].map((item) => ({
  HN: "01-17-051095",
  EN: "O19-22-827160",
  questionnaireCode: "01ALL2",
  q02: "-",
  q03: "Detail",
  q04: "Allergy",
  q06: "comment",
  q07: "ผลการตรวจทางเภสัชพันธุศาสตร์ สำหรับยา Allopurinol    (ยีน HLA-B* 58:01) เป็น Positive  มีความเสี่ยงสูงมากต่อการแพ้ยา ชนิดรุนแรงแบบ Steven-Johnson Syndrome (SJS) และ Toxic Epidermal Necrolysis (TEN)  หลีกเลี่ยงการใช้ยา Allopurinol ในผู้ป่วยรายนี้",
  q08: "-",
  bucode: "BHQ",
  ...item,
}));

export default React.memo(CardAllergyTab);
