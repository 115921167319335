import React, { useEffect } from "react";
import moment from "moment";

// CSS
import { Tab } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core";

// Interface
import { State, Event } from "../ManageInterface";

// UI
import WebChildGrowthChart from "./detail/WebChildGrowthChart";
import WebChildVaccination from "./detail/WebChildVaccination";
import WebChildOutsideMedicine from "./detail/WebChildOutsideMedicine";
import WebChildDevelopment from "./detail/WebChildDevelopment";
import WebPatientPanel from "./WebPatientPanel";

type ChildDetailProps = {
  // function
  onEvent: (e: Event) => any;
  setProp: any;
} & Pick<
  State,
  // common
  | "loadingStatus"
  | "errorMessage"
  | "successMessage"
  | "selectedPatient"
  | "dmSelectedTab"
  // Options
  | "routeOptions"
  | "unitOptions"
  | "frequencyOptions"
  | "methodOptions"
  | "administrationTimeOptions"
  // WebPatientPanel
  | "scoreSummary"
  | "labSummary"
  // OutsideMedicines
  | "outsideMedicines"
  | "profileHealth"
  // Development
  | "developmentHistory"
  | "developmentLastEdited"
  | "developmentAgeRange"
  // Growth
  | "growthHistoryList"
  // Overall Progress
  | "childOverallProgress"
  // Vaccination
  | "ageRangeVaccineChild"
  | "essentialVaccineList"
  | "otherVaccineList"
  | "lastUpdateVaccineChild"
>;

const ChildDetailInitial: ChildDetailProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  // common
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  dmSelectedTab: null,
  // Options
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // WebPatientPanel
  scoreSummary: [],
  labSummary: {},
  // WebChildOutsideMedicines
  outsideMedicines: {},
  profileHealth: [],
  // WebChildDevelopment
  developmentHistory: [],
  developmentLastEdited: null,
  // WebChildGrowthChart
  growthHistoryList: {},
  // Overall Progress
  childOverallProgress: [],
  // Vaccination
  ageRangeVaccineChild: [],
  essentialVaccineList: [],
  otherVaccineList: [],
  lastUpdateVaccineChild: "",
};

const useStyles = makeStyles((theme) => ({
  tab_bar: {
    "& .ui.bottom.attached.segment.active.tab": {
      padding: 0,
    },
  },
}));

const ChildDetail: React.FC<ChildDetailProps> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.setProp("dmSelectedTab", 0);
  }, [props.selectedPatient]);

  const handleChangeTab = (event: any, data: any) => {
    props.setProp("dmSelectedTab", data.activeIndex);

    if (data.panes[data.activeIndex].menuItem.toLowerCase() === "weight") {
      props.onEvent({
        message: "handleGetDMTabWeight",
        params: {
          start: moment().add(-1, "w").format("DD/MM/YYYY"),
          end: moment().format("DD/MM/YYYY"),
        },
      });
    }
  };

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div style={{ width: "300px" }}>
        <WebPatientPanel
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // common data
          selectedPatient={props.selectedPatient}
          dmSelectedTab={props.dmSelectedTab}
          // loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          scoreSummary={props.scoreSummary}
          labSummary={props.labSummary}
          profileHealth={props.profileHealth}
          childOverallProgress={props.childOverallProgress}
          hidePersonalScoreChart={true}
          hidePersonalLabChart={true}
          hideMomOverall={true}
          hideChildOverall={false}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Tab
          className={classes.tab_bar}
          menu={{
            vertical: true,
            color: "blue",
            pointing: true,
            tabular: true,
          }}
          grid={{ paneWidth: 14, tabWidth: 2 }}
          defaultActiveIndex={props.dmSelectedTab || 0}
          onTabChange={handleChangeTab}
          panes={[
            {
              menuItem: "Growth chart",
              render: () => (
                <Tab.Pane>
                  <WebChildGrowthChart
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    growthHistoryList={props.growthHistoryList}
                    selectedPatient={props.selectedPatient}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Vaccination",
              render: () => (
                <Tab.Pane>
                  <WebChildVaccination
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    ageRangeVaccineChild={props.ageRangeVaccineChild}
                    essentialVaccineList={props.essentialVaccineList}
                    otherVaccineList={props.otherVaccineList}
                    lastUpdateVaccineChild={props.lastUpdateVaccineChild}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Outside Med",
              render: () => (
                <Tab.Pane>
                  <WebChildOutsideMedicine
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    outsideMedicines={props.outsideMedicines}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Development",
              render: () => (
                <Tab.Pane>
                  <WebChildDevelopment
                    // function
                    onEvent={props.onEvent}
                    // data
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    developmentHistory={props.developmentHistory}
                    developmentLastEdited={props.developmentLastEdited}
                    developmentAgeRange={props.developmentAgeRange}
                    loadingStatus={props.loadingStatus}
                  />
                </Tab.Pane>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

ChildDetail.defaultProps = ChildDetailInitial;

export default React.memo(ChildDetail);
