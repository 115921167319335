import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Button as ButtonMui } from "@material-ui/core";
import MobCouponChoiceExtra from "./MobCouponChoiceExtra";

const MobCouponChoice = (props: any) => {
  const [selectedChoice, setSelectedchoice] = useState(null);
  const useStyles = makeStyles(() => ({
    paper: {
      padding: "10px",
      margin: "10px",
      width: "100%",
    },
    label: {
      color: "#0147A3",
      width: "100%",
    },
    radioGroup: {
      width: "100%",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (props?.currentQuestion?.answer && props?.choice?.length === 0) {
      /// find real answer
      console.error("should not see me");
      setSelectedchoice(props?.currentQuestion?.answer);
    } else if (props?.currentQuestion?.answer && props?.choice?.length > 0) {
      /// find real answer เพราะว่าเวลา โชว์​ใช้ label เวลาเก็บ ลง tree form ใช้ condition ถ้า สองตัวไม่เท่ากัน
      /// จะใช้คำตอบจาก key answer ใน Question ไม่ได้
      let condition = props?.currentQuestion?.answer;
      let findAnswer = props.choice?.filter(
        (item: any) => item.condition === condition
      );
      if (findAnswer?.length > 0) {
        setSelectedchoice(findAnswer[0].label);
      }
    }
  }, [props?.currentQuestion, props?.choice]);

  // currentAnswer If have
  // if (props?.currentQuestion?.answer) {
  //   // Add Current answer if have

  // }
  console.log("props.choice", props.choice);

  return (
    <>
      <RadioGroup
        aria-label="gender"
        defaultValue="female"
        name="radio-buttons-group"
        className={classes.radioGroup}
      >
        {props.choice?.length === 1 &&
        props.choice[0].selected &&
        props.choice[0].extra_items &&
        props.choice[0].extra_items?.length > 0 ? (
          <>
            <MobCouponChoiceExtra
              extraItems={props.choice[0].extra_items}
              selectedExtra={(data: any) => {
                props.selectedExtra({ ...data, id: props.choice[0].id });
              }}
              subCountLevel={props.subCountLevel}
              injectCovidCount={props.injectCovidCount}
              language={props.language}
            />
          </>
        ) : props.choice ? (
          props.choice.map((item: any) => (
            <Paper key={item.label} className={classes.paper} elevation={2}>
              <FormControlLabel
                className={classes.label}
                value={item.label}
                control={
                  <Radio
                    onClick={(e) => {
                      //console.log(" Checked value", e.target.value);
                      //console.log("check", {  d: props?.currentQuestion?.id,  choice: e.target.value });
                      props.selectedChoice({ choiceId: item?.id });
                      setSelectedchoice(e.target.value);
                    }}
                    checked={selectedChoice === item.label}
                  />
                }
                label={item.condition}
              />
            </Paper>
          ))
        ) : null}
      </RadioGroup>
    </>
  );
};

export default MobCouponChoice;
