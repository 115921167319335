import React, { useEffect, useState } from "react";
import { Grid, Header } from "semantic-ui-react";
import { Radar } from "react-chartjs-2";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import * as ChildOverallConst from "./ChildOverall";
import "../../css/WebClinic.scss";

type ChildOverallProgressProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  childOverallProgress?: any[];
};

const ChildOverallProgressInitial: ChildOverallProgressProps = {
  // function
  onEvent: () => {},
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  childOverallProgress: [],
};

const ChildOverallProgress: React.FC<ChildOverallProgressProps> = (props) => {
  const intl = useIntl();
  const [data, setData] = useState<any>({});

  const options = {
    pointBorderColor: "rgba(157, 230, 235, 0.6)",
    legend: {
      display: false,
    },
    scale: {
      angleLines: false,
      display: true,
      gridLines: {
        color: "#A3A3A3",
      },
      ticks: {
        grid: 0,
        display: false,
        minRotation: 0,
        min: 0,
        max: 100,
        stepSize: 25,
      },
      pointLabels: {
        fontFamily: "Roboto",
        fontSize: "16",
        color: "#000000",
      },
    },
  };
  
  useEffect(() => {
    props.onEvent({ message: "handleGetChildOverallProgress", params: {intl} });
  }, [props.selectedPatient]);

  useEffect(() => {
    let labels: any[] = [];
    let datas: any[] = [];
    props.childOverallProgress?.forEach((item: any) => {
      labels.push(item.name);
      datas.push(item.score);
    });

    const datasets = {
      labels: labels,
      datasets: [
        {
          data: datas,
          backgroundColor: "rgba(157, 230, 235, 0.6)",
          borderColor: "rgba(157, 230, 235, 0.6)",
          borderWidth: 1,
          radius: 0,
        },
      ],
    };
    setData(datasets);
  }, [props.childOverallProgress]);

  return (
    <div style={{ padding: "20px", paddingTop: 0, marginTop: "-45px" }}>
      <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
        <FormattedMessage id="bplusClinicKey364" />
      </Header>

      <Radar data={data} options={options} />

      <Grid verticalAlign="middle" style={{ paddingTop: "50px" }}>
        <Grid.Row
          style={{
            background: "var(--theme-bg-color-dm)",
            margin: "0px 10px",
            borderRadius: "10px"
          }}
        >
          <Grid.Column width={7}>
            <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey519" />
            </Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey468" />
            </Header>
          </Grid.Column>
        </Grid.Row>
        
        {props.childOverallProgress?.map((item: any, index: number) => {
          let style = {
            color: "var(--text-grey)",
            padding: "5px",
            fontSize: "17px",
          }
          if (index === 0) {
            style = {
              color: "var(--text-grey)",
              padding: "10px 5px 5px 5px",
              fontSize: "17px",   
            }
          } 
          return (
            <Grid.Row
              key={index}
              style={style}
            >
              <Grid.Column width={7}>{item.name}</Grid.Column>
              <Grid.Column width={9}>
                {
                  item.value === "น้ำหนักน้อยกว่าเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey912" })}` :
                  item.value === "น้ำหนักค่อนข้างน้อย" ? `${intl.formatMessage({ id: "bplusClinicKey911" })}` :
                  item.value === "น้ำหนักตามเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey910" })}` :
                  item.value === "น้ำหนักค่อนข้างมาก" ? `${intl.formatMessage({ id: "bplusClinicKey913" })}` :
                  item.value === "น้ำหนักมากกว่าเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey914" })}` :

                  item.value === "ส่วนสูงต่ำกว่าเกณฑ์มาก" ? `${intl.formatMessage({ id: "bplusClinicKey916" })}` :
                  item.value === "ส่วนสูงต่ำกว่าเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey915" })}` :
                  item.value === "ส่วนสูงตามเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey919" })}` :
                  item.value === "ค่อนข้างสูง" ? `${intl.formatMessage({ id: "bplusClinicKey917" })}` :
                  item.value === "สูง" ? `${intl.formatMessage({ id: "bplusClinicKey918" })}` :

                  item.value === "ครบตามกำหนด" ? `${intl.formatMessage({ id: "bplusClinicKey921" })}` :
                  item.value === "ควรรับวัคซีนให้ครบตามเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey922" })}` :

                  item.value === "ดีเยี่ยม" ? `${intl.formatMessage({ id: "bplusClinicKey874" })}` :
                  item.value === "ดี" ? `${intl.formatMessage({ id: "bplusClinicKey875" })}` :
                  item.value === "พอใช้" ? `${intl.formatMessage({ id: "bplusClinicKey890" })}` :
                  
                  item.value === "ตามเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey920" })}` :
                  item.value === "ควรปรึกษาแพทย์" ? `${intl.formatMessage({ id: "bplusClinicKey381" })}` : item.value
                }
              </Grid.Column>
            </Grid.Row>
          );
        })}
     
       
      </Grid>
    </div>
  );
};

ChildOverallProgress.defaultProps = ChildOverallProgressInitial;

export default React.memo(ChildOverallProgress);
