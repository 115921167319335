import React, { useState, useEffect, CSSProperties } from "react";
import { Image, Search } from "semantic-ui-react";

// UI
import SelectHospital from "../appointment/SelectHospital";

// Types
type HospitalSearchBoxProps = {
  hospitalList: any[];
  // config
  margin?: CSSProperties["margin"];
  width?: CSSProperties["width"];
  // callback
  onSearchChange: (value: string) => any;
};

const HospitalSearchBox = (props: HospitalSearchBoxProps) => {
  const [openModHospital, setOpenModHospital] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    props.onSearchChange(searchText);
  }, [searchText]);

  const handleOpenModal = () => {
    setOpenModHospital(true);
  };

  const handleSelectHospital = (hospital: any) => {
    if (hospital?.name) {
      setSearchText(hospital.name);
    }
    setOpenModHospital(false);
  };

  const handleSearchChange = (_e: any, v: any) => {
    setSearchText(v.value);
  };

  return (
    <>
      <div
        style={{
          margin: props.margin ?? "15px 0 10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="medication-search"
          style={{ width: props.width ?? "80%" }}
        >
          <Search
            input={{ icon: "search", iconPosition: "left" }}
            onSearchChange={handleSearchChange}
            placeholder="Search Hospital"
            showNoResults={false}
            value={searchText}
          />
        </div>
        <div
          style={{ marginLeft: "10px", display: "flex" }}
          // onClick={handleSortdata}
        >
          <Image
            style={{ width: "35px", height: "35px" }}
            src="/images/History/sort-icon.png"
            onClick={handleOpenModal}
          />
        </div>
      </div>

      {openModHospital && (
        <SelectHospital
          // onEvent={this.controller.handleEvent}
          hospitalList={props.hospitalList}
          onClose={handleSelectHospital}
          className="--zIndex"
          firstLoadingHospital={true}
          history={{ action: "PUSH" }}
          selectedHospital={{ code: searchText }}
          closeIcon={true}
        />
      )}
    </>
  );
};

export default React.memo(HospitalSearchBox);
