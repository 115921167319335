import React, { Suspense } from "react";
// import MobFeed from "./MobFeed";

const MobAppointment = React.lazy(() => import("bplus-lib/MobAppointment"));

const MobAppointmentSimulator = () => {

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <MobAppointment 
          simulator={true} />
      </Suspense>{" "}
    </>
  );
};

export default MobAppointmentSimulator;
