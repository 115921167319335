import React, { useState, useEffect } from "react"
import TabPath from "react-lib/frameworks/TabPath";
import { Button } from "semantic-ui-react";
import Classify from "./Classify";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const Content = (props: any) => {

  const [oldLink, setOldLink] = useState<string>("");

  useEffect(() => {
    // props.onEvent({
    //   message: "GetFetchedContent",
    //   params: {}
    // });
    // props.onEvent({
    //   message: "GetUniqueContent",
    //   params: {}
    // });
    props.onEvent({
      message: "GetDomainList",
      params: {}
    });
    props.onEvent({
      message: "GetTagList",
      params: {}
    });
  }, []);

  return(
    <div 
      style={{ height: "94vh" }}>
      <TabPath
        level={2}
        tabWidth="100px"
        history={props.history}
        data={[
          {
            key: "classify",
            name: "Classify",
            render: () => (
              <Classify
                contentList={props.contentList}
                selectedContentIndex={props.selectedContentIndex}
                tagList={props.tagList}
                onEvent={props.onEvent}
                setProp={props.setProp}
                domainList={props.domainList}
                domainData={props.domainData}
                contentDomain={props.contentDomain}
              />
            )
          },
          { 
            key: "terms",
            name: "Terms",
            render: () => (
              <div style={{ height: "94vh"}}>
                <TabPath
                  tabWidth="100px"
                  onTabChange={(index: number) => {
                    console.log("tabChange")
                    props.setProp("termList", []);
                  }}
                  data={props.contentUnique?.map((url: string, index: number) => (
                    {
                      key: index,
                      name: index,
                      render: () => (
                        <div>
                          <div><a href={url} target="_blank">{url}</a></div>
                          <Button
                            color="green"
                            onClick={(e: any) => {
                              props.onEvent({
                                message: "GetLinkTerm",
                                params: { url }
                              });
                            }}>
                            <FormattedMessage id="bplusClinicKey214" />
                          </Button>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {props.termList?.map((term: string, term_index: number) => (
                              <div 
                                key={term_index}
                                style={{ 
                                  padding: "2px", 
                                  margin: "2px",
                                  border: "solid black 1px",
                                  cursor: "pointer"
                                }}
                                onClick={(e: any) => {
                                  props.onEvent({
                                    message: "AddSkipTerm",
                                    params: { 
                                      url: url,
                                      term: term
                                    }
                                  })
                                }}>
                                {term}
                              </div>
                            )) || []}
                          </div>
                        </div>
                      )
                    }
                  )) || []}
                />
              </div>
            )
          },
          {
            key: "relevance",
            name: "Relevance",
            render: () => (
              <div style={{ height: "94vh"}}>
                <TabPath
                  padding="0px"
                  onTabChange={(index: number) => {
                    setOldLink(props.contentSummary?.summary?.[index] || "");
                  }}
                  data={props.contentSummary?.summary?.map((item: any, index: number) => (
                    {
                      key: index,
                      name: (
                        <div 
                          style={{ width: "100%", borderBottom: "solid #eeeeee 1px", padding: "5px"}}>
                          <div style={{ wordWrap: "break-word" }}>
                            {item.domain}
                          </div>
                          <div style={{ wordWrap: "break-word" }}>
                            {`(${item.url_count} links)`}
                          </div>
                        </div>),
                      render: () => (
                        <div style={{ height: "100%", overflowY: "scroll" }}>
                          <div 
                            style={{ 
                              display: "grid",
                              gridTemplateColumns: "500px 50%",
                              justifyContent: "space-between"
                            }}>
                            <div 
                              style={{ gridColumn: 1 }}>
                              <a href={item.url_list[0]} target="_blank" >
                                <div style={{ wordWrap: "break-word"}}>
                                  {item.url_list[0]}
                                </div>
                              </a>
                            </div>
                            <div style={{ gridColumn: 2 }}>
                              <Button 
                                color="green"
                                onClick={(e: any) => {
                                  setOldLink(item.url_list[0]);
                                  props.onEvent({
                                    message: "ContentOk",
                                    params: { url: item.url_list[0], ok: true }
                                  })
                                }}>
                                <FormattedMessage id="bplusClinicKey346" />
                              </Button>
                              <Button 
                                color="red"
                                onClick={(e: any) => {
                                  setOldLink(item.url_list[0]);
                                  props.onEvent({
                                    message: "ContentOk",
                                    params: { url: item.url_list[0], ok: false }
                                  })
                                }}>
                                <FormattedMessage id="bplusClinicKey341" />
                              </Button>
                            </div>
                          </div>
                          <iframe 
                            width="100%" 
                            height="100%" 
                            hidden={item.url_list[0]===oldLink}
                            src={item.url_list[0] === oldLink ? "" : item.url_list[0]}>
                          </iframe>
                        </div>
                      )
                    }
                  )) || []}
                />
              </div>
            )
          },
        ]}
      />      
    </div>
  )
}

export default Content;
