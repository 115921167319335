import React, { useState } from "react";
import { Grid, Header, Button, Input } from "semantic-ui-react";
import { Alert } from "@mui/material";
import { useIntl } from "react-intl";

export const LoginForm = (props: any) => {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onEvent({
          message: "LoginIssara",
          params: {
            username: username,
            password: password,
            comname: "",
            device_type: "webpush",
          },
        });
      }}
    >
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Grid.Row>
            {props.showAlertLoginError ? (
               <div style={{ padding: "0px 0px 10px 0px" }}>
               <Alert severity="error">
                 {intl.formatMessage({ id: "login_failed" })}
               </Alert>
             </div>
            ) :null}
          </Grid.Row>
          <img src="/logo512.png" style={{ height: "200px" }} />
          <Header as="h3" color="teal" textAlign="center">
            <div>
              {props?.appConfig?.title}{" "}
              {intl.formatMessage({ id: "bplusClinicKey299" })}
            </div>
          </Header>
          <div style={{ padding: "5px" }}>
            <Input
              fluid
              placeholder="Username"
              onChange={(e: any, v: any) => {
                setUsername(v.value);
              }}
            />
          </div>
          <div style={{ padding: "5px" }}>
            <Input
              fluid
              placeholder="Password"
              type="password"
              onChange={(e: any, v: any) => {
                setPassword(v.value);
              }}
            />
          </div>
          <Button type="submit" color="blue" fluid>
            ​​{intl.formatMessage({ id: "bplusClinicKey299" })}
          </Button>
        </Grid.Column>
      </Grid>
    </form>
  );
};

export default LoginForm;
