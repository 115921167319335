import React, { useState } from "react";
import { Button, Label } from "semantic-ui-react";

import { FormattedMessage, useIntl } from "react-intl";
import { History } from "history";

// UI
import PhoneNumberInput from "./PhoneNumberInput";

// Styles
import "./RegisterCSS.scss";

// Types
import { registerInfo } from "./TypeModal";

// Types
type LoginWithSMSProps = {
  onEvent: (e: any) => any;
  // data
  history: History;
  registerInfo?: registerInfo;
  loading?: boolean;
};

const LoginWithSMS = (props: LoginWithSMSProps) => {
  const intl = useIntl();

  const [username, setUsername] = useState<string>("");

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};
    if (filed === "username" && props.registerInfo?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleHistoryGoTo = (action: string) => {
    if (!props.loading) {
      switch (action) {
        case "login":
          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: { key: "username", value: username },
          });

          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: {
              key: "typeUsername",
              value: "phoneNumber",
            },
          });

          handleClearError("username");

          props.onEvent({
            message: "HandleRequestOTP",
            params: { history: props.history, smsAuth: true, intl },
          });
          break;
        case "register":
          props.history.push("/register/?app=MobRegister");
          break;
        case "forgot-password":
          props.history.push("/register/forgot-password/?app=MobRegister");
          break;
      }
    }
  };

  return (
    <>
      <div className="title-text-blue" style={{ marginBottom: "2rem" }}>
        {<FormattedMessage id="bplusClinicKey958" />}
      </div>
      <div className={"content-filed-input-phone"}>
        <Label>
          <FormattedMessage id="profile.phone_no" />
        </Label>

        <div onClick={() => handleClearError("username")}>
          <PhoneNumberInput
            error={props.registerInfo?.error?.username || props.registerInfo?.error?.message}
            value={username || ""}
            onChange={(e, data) => {
              setUsername(data.value as string);
            }}
          />
        </div>

        <div
          className={
            props.registerInfo?.error?.username ||
            props.registerInfo?.error?.message
              ? "error"
              : "normal"
          }
          {...(props.registerInfo?.error?.username && { className: "error" })}
        >
          {props.registerInfo?.error?.username}
        </div>

        <div className="div-center-bottom" style={{ marginTop: "4.5rem" }}>
          <div className="bplus-buttom">
            <Button
              loading={props.loading}
              onClick={() => handleHistoryGoTo("login")}
            >
              {<FormattedMessage id="bplusClinicKey959" />}
            </Button>
          </div>
          <div className="text-backtologin">
            <div></div>
            <div onClick={() => props.history.goBack()}>
            {<FormattedMessage id="bplusClinicKey1021" />}
            </div>
          </div>
        </div>
      </div>

      <div
        className="div-center-bottom"
        style={{ flex: 1, alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            color: "#797878",
            whiteSpace: "pre-line",
          }}
        >
          {<FormattedMessage id="bplusClinicKey980" />}
          {
            <div
              className="highlight"
              onClick={() => handleHistoryGoTo("register")}
            >
              {<FormattedMessage id="bplusClinicKey957" />}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default React.memo(LoginWithSMS);
