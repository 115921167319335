import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Menu, Tab, Icon, Dimmer, Loader, Modal } from "semantic-ui-react";
import SwipeableViews from "react-swipeable-views";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

// UI
import MobHead from "../MobHead";
import PatientPanel from "./PatientPanel";
import { AppBar } from "@material-ui/core";
import DMFootMain from "./DMFootMain";
import moment from "moment";
import SnackMessage from "./SnackMessage";
import { HeaderChart } from "./DMEmotionRestChart";

import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  FOOT_STATUS,
  formatDateByLanguage
} from "../MobClinicInterface";

export const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  borderImg: {
    width: "74px",
    height: "74px",
    border: "solid 1px #2399E5",
    margin: "auto",
    // padding: "10px",
    backgroundColor: "white",
    color: "#A3A3A3",
  } as CSSProperties,
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  MainflexColumn: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  } as CSSProperties,
  MainflexColumnWithBackground: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e7fafb",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  } as CSSProperties,
  flexRow: {
    display: "flex",
    flexDirection: "row",
    padding: "3px",
  } as CSSProperties,
};

// ---------- Tab Detail
type TabDetailProps = {
  onEvent?: any;
  selectedPatient?: any;
  footFilterDate?: moment.Moment;
  footDetail?: any;
  openFootConfirm?: boolean;
};

const TabDetail: React.FC<TabDetailProps> = (props) => {
  return (
    <>
      {/* ----- PatientPanel ----- */}
      <PatientPanel patientInfo={props.selectedPatient} haveBackground={true} />

      <div>
        <DMFootMain
          onEvent={props.onEvent}
          selectedPatient={props.selectedPatient}
          footFilterDate={props.footFilterDate}
          footDetail={props.footDetail}
          openFootConfirm={props.openFootConfirm}
        />
      </div>
    </>
  );
};

export type FootDetailKey = keyof typeof footDetailWordMap;

export const footDetailWordMap = {
  normal_foot: "สีผิวปกติ ไม่มีบาดแผล",
  paralyze_foot: "รู้สึกชา/ แสบร้อนที่เท้า",
  dry_skin_foot: "ผิวแห้ง ส้นเท้าแตก",
  wound_foot: "มีบาดแผล",
  dark_skin_foot: "สีผิวที่เท้าคล้ำ",
  pale_skin_foot: "สีผิวที่เท้าซีด",
  red_skin_foot: "สีผิวที่เท้าแดง",
  cut_toenail_foot: "เคยถูกตัดนิ้วเท้า",
  mycosis_foot: "มีการติดเชื้อรา",
  infected_foot: "มีเนื้อตาย/ ผิวที่เท้าเป็นสีดำ",
  abnormal_foot: "รูปร่างเท้า ผิดปกติ",
};

// ---------- Tab History
type TabHistoryProps = {
  onEvent?: any;
  selectedPatient?: any;
  footFilterDuration?: string;
  footHistory?: any;
};

const TabHistory: React.FC<TabHistoryProps> = (props) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [viewImage, setViewImage] = useState<string>("");

  const durationOptions = useMemo(
    () => [
      {
        key: "4",
        value: "4",
        text: `5 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "6",
        value: "6",
        text: `7 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "9",
        value: "9",
        text: `10 ${intl.formatMessage({ id: "profile.days" })}`,
      },
    ],
    []
  );

  useEffect(() => {
    setStartDate(moment().add(`-${props.footFilterDuration}`, "days"));
    setEndDate(moment());
  }, [props.footFilterDuration]);

  useEffect(() => {
    props.onEvent({
      message: "handleGetFootHistoryByDuration",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [startDate]);

  const handleChangeDuration = (_e: any, { value }: any) => {
    props.onEvent({
      message: "handleChangeFootFilterDuration",
      params: { duration: value },
    });
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, "DD/MM/YYYY");
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${props.footFilterDuration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, "DD/MM/YYYY");
      fromdate = targetDate.clone();
      todate = targetDate.add(props.footFilterDuration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleSetFootDetailMap = (footDetail: any) => {
    // sent left or right detail to convert to text
    let footDetailKeys = Object.keys(footDetail);
    let footDetailText = [];
    for (const foot of footDetailKeys) {
      if (footDetail[foot]) {
        footDetailText.push(
          footDetailWordMap[foot as FootDetailKey] === "สีผิวปกติ ไม่มีบาดแผล" ? `${intl.formatMessage({ id: "bplusClinicKey729" })} ${intl.formatMessage({ id: "bplusClinicKey730" })}` :
          footDetailWordMap[foot as FootDetailKey] === "รู้สึกชา/ แสบร้อนที่เท้า" ? `${intl.formatMessage({ id: "bplusClinicKey731" })} ${intl.formatMessage({ id: "bplusClinicKey732" })}` :
          footDetailWordMap[foot as FootDetailKey] === "ผิวแห้ง ส้นเท้าแตก" ? `${intl.formatMessage({ id: "bplusClinicKey733" })} ${intl.formatMessage({ id: "bplusClinicKey734" })}` :
          footDetailWordMap[foot as FootDetailKey] === "มีบาดแผล" ? `${intl.formatMessage({ id: "bplusClinicKey735" })}` :
          footDetailWordMap[foot as FootDetailKey] === "สีผิวที่เท้าคล้ำ" ? `${intl.formatMessage({ id: "bplusClinicKey736" })}` :
          footDetailWordMap[foot as FootDetailKey] === "สีผิวที่เท้าซีด" ? `${intl.formatMessage({ id: "bplusClinicKey737" })}` :
          footDetailWordMap[foot as FootDetailKey] === "สีผิวที่เท้าแดง" ? `${intl.formatMessage({ id: "bplusClinicKey738" })}` :
          footDetailWordMap[foot as FootDetailKey] === "เคยถูกตัดนิ้วเท้า" ? `${intl.formatMessage({ id: "bplusClinicKey739" })}` :
          footDetailWordMap[foot as FootDetailKey] === "มีการติดเชื้อรา" ? `${intl.formatMessage({ id: "bplusClinicKey740" })}` :
          footDetailWordMap[foot as FootDetailKey] === "มีเนื้อตาย/ ผิวที่เท้าเป็นสีดำ" ? `${intl.formatMessage({ id: "bplusClinicKey741" })} ${intl.formatMessage({ id: "bplusClinicKey742" })}` :
          footDetailWordMap[foot as FootDetailKey] === "รูปร่างเท้า ผิดปกติ" ? `${intl.formatMessage({ id: "bplusClinicKey743" })}` : ""
        );
      }
    }
    console.log("footDetailText",footDetailText)
    return footDetailText.length > 0 ? footDetailText.join(", ") : "";
  };

  return (
    <>
      <HeaderChart
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={props.footFilterDuration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        // labelStyle={{ padding: "10px 30px 5px 18px" }}
      />
      <div style={{ ...styles.flexColumn, padding: "5px" }}>
        <div
          style={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            borderTop: "solid rgb(0, 114, 188)",
            borderBottom: "solid rgb(0, 114, 188)",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="mea.icon" height="25px" src="/images/detail/foot.png" />
            <span
              style={{ fontSize: "15px", fontWeight: "bold", padding: "2px" }}
            >
              {intl.formatMessage({ id: "bplusClinicKey626" })}
            </span>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="mea.icon" height="25px" src="/images/detail/foot.png" />
            <span
              style={{ fontSize: "15px", fontWeight: "bold", padding: "2px" }}
            >
              {intl.formatMessage({ id: "bplusClinicKey624" })}
            </span>
          </div>
        </div>
        {props.footHistory?.items?.map((item: any, index: number) => {
          const lastData: any = item?.data[item?.data?.length - 1];
          return (
            <div
              key={index}
              style={
                index % 2 === 0
                  ? styles.MainflexColumnWithBackground
                  : styles.MainflexColumn
              }
            >
              <div style={{ ...styles.flexRow, alignItems: "baseline" }}>
                <div style={{ flex: "1" }}>
                  <Icon
                    name="calendar alternate outline"
                    style={{ color: "#0072BC" }}
                  />
                  {formatDateByLanguage(moment(
                    lastData?.time,
                    `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                  ).format("DD/MM/YYYY"),"DD MMMM YYYY")}
                </div>
                <div style={{ flex: "1" }}>
                  {lastData?.status === FOOT_STATUS.HIGH && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        name="user md"
                        color={lastData?.color}
                        style={{ fontSize: "22px" }}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: lastData?.color,
                        }}
                      >
                        <FormattedMessage id="bplusClinicKey381" />
                      </span>
                    </div>
                  )}
                  {[FOOT_STATUS.MEDIUM, FOOT_STATUS.LOW].includes(
                    lastData?.status
                  ) && (
                    <div style={{ display: "flex" }}>
                      <Icon
                        name={
                          lastData?.status === FOOT_STATUS.LOW
                            ? "thumbs up"
                            : "thumbs down"
                        }
                        color={lastData?.color}
                        style={{ fontSize: "22px" }}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color:
                            lastData?.color === "yellow"
                              ? "#fbbd08"
                              : lastData?.color,
                        }}
                      >
                        {lastData?.status === FOOT_STATUS.MEDIUM && "Dry"}
                        {lastData?.status === FOOT_STATUS.LOW && "Normal"}
                      </span>
                    </div>
                  )}
                  {lastData?.status === "" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: lastData?.color,
                          textAlign: "center",
                        }}
                      >
                        {intl.formatMessage({ id: "bplusClinicKey483" })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div style={styles.flexRow}>
                <div
                  style={{
                    flex: "1",
                    padding: "0px 10px",
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#4F4F4F",
                  }}
                >
                  {
                    handleSetFootDetailMap(lastData?.leftFoot)
                  }
                </div>
                <div
                  style={{
                    flex: "1",
                    padding: "0px 10px",
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#4F4F4F",
                  }}
                >
                  {handleSetFootDetailMap(lastData?.rightFoot)}
                </div>
              </div>
              <div style={styles.flexRow}>
                <div style={{ ...styles.borderImg, textAlign: "center" }}>
                  {lastData?.leftFoot?.image?.url ? (
                    <img
                      src={lastData.leftFoot.image.url}
                      style={{ height: "inherit", width: "100%" }}
                      onClick={() => {
                        setOpenModal(true);
                        setViewImage(lastData.leftFoot.image.url);
                      }}
                    />
                  ) : (
                    "No photo"
                  )}
                </div>
                <div style={{ ...styles.borderImg, textAlign: "center" }}>
                  {lastData?.rightFoot?.image?.url ? (
                    <img
                      src={lastData.rightFoot.image.url}
                      style={{ height: "inherit", width: "100%" }}
                      onClick={() => {
                        setOpenModal(true);
                        setViewImage(lastData.rightFoot.image.url);
                      }}
                    />
                  ) : (
                    "No photo"
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={openModal}
        dimmer={true}
        onClose={() => {
          setOpenModal(false);
        }}
        closeIcon
      >
        <Modal.Content>
          <div>
            <img src={viewImage} style={{ width: "100%" }} />
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

// ---------- FootDM
type FootDMProps = {
  onEvent: any;
  onSetBackToApp: any;
  loadingStatus?: boolean;
  successMessage?: string | null;
  errorMessage?: string | null;
  selectedPatient?: any;
  openFootConfirm: boolean;
  footFilterDate: moment.Moment;
  footFilterDuration: string;
  footDetail: any;
  footHistory: any;
  footDMHistory?: any;
};

const FootDMInitial: FootDMProps = {
  onEvent: () => null,
  onSetBackToApp: () => null,
  loadingStatus: false,
  successMessage: null,
  errorMessage: null,
  selectedPatient: {},
  openFootConfirm: false,
  footFilterDate: moment(),
  footFilterDuration: "6",
  footDetail: {},
  footHistory: {},
};

const DMFoot: React.FC<FootDMProps> = (props) => {
  const intl = useIntl();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    if (tabIndex === 0) {
      props.onEvent({
        message: "handleGetFootDetailByDate",
        params: { openConfirm: false },
      });
    } else if (tabIndex === 1) {
      props.onEvent({
        message: "handleGetFootHistoryByDuration",
        params: {
          start_measure: moment()
            .add(`-${props.footFilterDuration}`, "days")
            .format(BACKEND_DATE_FORMAT),
          end_measure: moment().format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, tabIndex]);

  const handleTabChange = (event: object, data: any) => {
    setTabIndex(data.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <AppBar position="fixed" color="default" style={{ marginTop: "0px" }}>
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "foot.foot_examination" })}
        />
      </AppBar>

      <Tab
        className="weight-tab-custom dm"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={tabIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
      >
        <TabDetail
          onEvent={props.onEvent}
          selectedPatient={props.selectedPatient}
          footFilterDate={props.footFilterDate}
          footDetail={props.footDetail}
          openFootConfirm={props.openFootConfirm}
        />
        <TabHistory
          onEvent={props.onEvent}
          selectedPatient={props.selectedPatient}
          footFilterDuration={props.footFilterDuration}
          footHistory={props.footHistory}
        />

        <SnackMessage
          onEvent={props.onEvent}
          error={props.errorMessage}
          success={props.successMessage}
        />
      </SwipeableViews>
    </>
  );
};

DMFoot.defaultProps = FootDMInitial;

export default React.memo(DMFoot);
