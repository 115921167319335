import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
// Mui
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";

// UI
import SubUnderVerification from "./SubUnderVerification";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Styles
import { stepeKYC } from "./CardKYCStep";

// Types
type IDCardVerifyProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  rotateType?:
    | "Portrait"
    | "LandscapeLeft"
    | "LandscapeRight"
    | "PortraitUpsideDown";
  type: "ID_CARD" | "PASSPORT";
  history: History;
  // CommonInterface
  loadingStatus?: Record<string, any>;
  errorMessage?: Record<string, any>;
};

// Images
const IMAGES = {
  scan_id_card: "/images/register/scan-id-card.png",
  frame_id_card: "/images/register/frame-id-card.png",
  frame_passport: "/images/register/frame-passport.png",
  frame_id_card_back: "/images/register/frame-id-card-back.png",
  dismiss: "/images/register/dismiss.png",
  lock_shield: "/images/register/lock-shield.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  red: "rgb(218, 0, 0)",
  light_blue: "rgb(204 218 237)",
  grey: "#2c2c2c",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 1s",
  },
};

const useStyles = makeStyles((theme) => ({
  description: {
    padding: "15px 20px",
    display: "grid",
    gridTemplateColumns: "auto 1fr",

    "&.ID_CARD": {
      marginBottom: "3.5rem",
      transition: `transform 0.5s`,
    },
    "&.PASSPORT": {
      marginBottom: "1.75rem",
      transition: `transform 0.5s`,
    },
    "& .icon": {
      backgroundColor: COLORS.light_blue,
      padding: "13px 10px 6px",
      borderRadius: "8px",
      width: "fit-content",
      height: "fit-content",
      "& img": {
        width: "55px",
      },
    },
    "& > div:nth-child(2)": {
      paddingLeft: "1.5rem",
      lineHeight: 1.55,
    },
  },
  shutter_button: {
    backgroundColor: COLORS.blue,
    width: "70px",
    height: "70px",
    borderRadius: "500rem",
    marginTop: "1rem",
    ...styles.center,
    "& > div": {
      border: "1px solid white",
      width: "67.5%",
      height: "67.5%",
      borderRadius: "500rem",
    },
  },
  loading: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  id_card: {
    position: "relative",
    marginTop: "1.55rem",
    "& > div:nth-child(1)": {
      ...styles.center,
      position: "absolute",
      height: "100%",
      "& .ID_CARD": {
        width: "90%",
        zIndex: 1000,
      },
      "& .PASSPORT": {
        width: "62.5%",
        zIndex: 1000,
      },
    },
  },
  ...stepeKYC,
}));

// Const

const FRAME_CARD = {
  ID_CARD_front: IMAGES.frame_id_card,
  ID_CARD_back: IMAGES.frame_id_card_back,
  PASSPORT_front: IMAGES.frame_passport,
} as any;

const ID_CARD_WIDTH = window.innerWidth;
const PASSPORT_WIDTH = window.innerWidth;

const ID_CARD_HEIGHT = ID_CARD_WIDTH * 0.63;
const PASSPORT_HEIGHT = PASSPORT_WIDTH * 1.055;

const ID_CARD_VERIFY = "IDCardVerify";

const IDCardVerify = (props: IDCardVerifyProps) => {
  const classes = useStyles();
  const intl = useIntl();

  // open
  const [openCamera, setOpenCamera] = useState<boolean>(true);
  // Mod
  const [side, setSide] = useState<"front" | "back">("front");

  const [idCardImage, setIdCardImage] = useState<{
    front?: string;
    back?: string;
  }>({});
  const [videoConstraints, setVideoConstraints] = useState<any>(null);

  const webcamRef = useRef<any>(null);

  // Memo Effect
  const isMobileDevice = useMemo(() => {
    // try {
    //   return (navigator as any).userAgentData.mobile;
    // } catch (error) {
    //   return isMobile;
    // }
    return true;
  }, []);

  useEffect(() => {
    props.onEvent({
      message: "HandleCreateTaskIdCard",
      params: { history: props.history },
    });

    props.onEvent({
      message: "HandleGetStatuseKYC",
      params: { card: ID_CARD_VERIFY },
    });

    if (globalThis.mobile?.checkRotate) {
      globalThis.mobile?.checkRotate()
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      await setVideoConstraints({
        ...handleCheckSizeVideo(props.type, props.rotateType),
        facingMode: "environment",
        frameRate: { min: 30, ideal: 30, max: 60 },
      });
    };

    init();

    return () => {
      webcamRef && webcamRef.current?.stopAndCleanup();
    };
  }, [props.type, props.rotateType]);

  const documents = useMemo(() => {
    return {
      ID_CARD: {
        description: <FormattedMessage id="bplusClinicKey1040" />,
        title: {
          front: <FormattedMessage id="bplusClinicKey1038" />,
          back: <FormattedMessage id="bplusClinicKey1039" />,
        },
      },
      PASSPORT: {
        description: intl.formatMessage({ id: "bplusClinicKey1120" }),
        title: {
          front: <FormattedMessage id="bplusClinicKey1036" />,
          back: "",
        },
      },
    };
  }, []);

  const title = useMemo(() => {
    return documents[props.type].title[side];
  }, [side, props.type]);

  const description = useMemo(() => {
    return documents[props.type].description;
  }, [side, props.type]);

  const idCardBase64 = useMemo(() => {
    const regex = /data:image\/jpeg;base64,/g;

    return {
      front: idCardImage.front?.replace(regex, ""),
      back: idCardImage.back?.replace(regex, ""),
    };
  }, [idCardImage]);

  const errorMessage = useMemo(() => {
    return props.errorMessage?.[ID_CARD_VERIFY];
  }, [props.errorMessage]);

  const handleTakePhoto = () => {
    setIdCardImage({
      ...idCardImage,
      [side]: webcamRef.current?.getScreenshot?.(),
    });

    setOpenCamera(false);
  };

  const handleRetake = () => {
    setOpenCamera(true);
    setIdCardImage({
      ...idCardImage,
      [side]: "",
    });
  };

  const handleUsePhoto = async () => {
    // -if (side === "front" && props.type === "ID_CARD") {
    //   setSide("back");
    //   setOpenCamera(true);
    //   return;
    // }
    webcamRef.current?.stopAndCleanup();
    if (idCardImage) {
      props.onEvent({
        message: "HandleThaiIdOCR",
        params: {
          card: ID_CARD_VERIFY,
          base64: idCardBase64,
          history: props.history,
          type: props.type,
          dataUrl: idCardImage,
          side,
        },
      });
    }
  };

  const handleCloseModError = () => {
    const { [ID_CARD_VERIFY]: key, ...error } = props.errorMessage || {};

    props.setProp(`errorMessage`, { ...error });
  };

  const handleReconfirm = () => {
    setOpenCamera(true);
    setSide("front");
    setIdCardImage({});

    handleCloseModError();
  };

  const handleCheckRotate = (rotateType?: string) => {
    console.log("saika rotateType", rotateType);
    switch (rotateType) {
      case `Portrait`:
        return "rotate(0deg)";
      case `PortraitUpsideDown`:
        return "rotate(180deg)";
      case `LandscapeLeft`:
        return "rotate(-90deg)";
      case `LandscapeRight`:
        return "rotate(90deg)";
      default:
        return "rotate(0deg)";
    }
  };

  const handleCheckSize = useCallback((
    type: string,
    rotateType?: string,
    isWidth?: boolean
  ) => {
    const typeIDCard = "ID_CARD";
    if (type !== typeIDCard) {
      return isWidth ? PASSPORT_WIDTH : PASSPORT_HEIGHT;
    }

    switch (rotateType) {
      case `Portrait`:
        return isWidth ? ID_CARD_WIDTH : ID_CARD_HEIGHT;
      case `PortraitUpsideDown`:
        return isWidth ? ID_CARD_WIDTH : ID_CARD_HEIGHT;
      case `LandscapeLeft`:
        return isWidth ? ID_CARD_HEIGHT : ID_CARD_WIDTH;
      case `LandscapeRight`:
        return isWidth ? ID_CARD_HEIGHT : ID_CARD_WIDTH;
      default:
        return isWidth ? ID_CARD_WIDTH : ID_CARD_HEIGHT;
    }
  },[])

  const handleCheckSizeVideo = (type: string, rotateType?: string) => {
    const typeIDCard = "ID_CARD";
    if (type !== typeIDCard) {
      if (
        [`Portrait`, `PortraitUpsideDown`].includes(rotateType || "Portrait")
      ) {
        return { width: { ideal: 758 }, height: { ideal: 720 } };
      } else {
        return { width: { ideal: 720 }, height: { ideal: 758 } };
      }
    } else {
      if (
        [`Portrait`, `PortraitUpsideDown`].includes(rotateType || "Portrait")
      ) {
        return {
          width: { ideal: 720 },
          height: { ideal: 1180 },
          // aspectRatio: 0.6666666667,
        };
      } else {
        return {
          width: { ideal: 1180 },
          height: { ideal: 720 },
          // aspectRatio: 0.6666666667,
        };
      }
    }
  };

  const handleGotoFeed = () => {
    props.onEvent({
      message: "HandleCanceleKYC",
      params: { history: props.history },
    });
  };

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <div className={classes.step_ekyc}>
        <div style={styles.center}>
          <div className="number">{1}</div>
          <div className="title">{title}</div>
        </div>
        <div style={styles.center}>
          <div className="divider">
            <div className="step-active"></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <div className={classes.id_card}>
        <div>
          {!idCardImage[side] && (
            <img
              className={props.type}
              src={FRAME_CARD[`${props.type}_${side}`]}
              alt="frame-card"
            />
          )}
        </div>
        <div
          style={{
            ...styles.center,
            marginTop: isMobileDevice || idCardImage[side] ? "" : "-15px",
            transform: handleCheckRotate(props.rotateType),
            ...([`LandscapeLeft`, `LandscapeRight`].includes(props.rotateType || "Portrait") && {overflowX:"hidden" , margin: "-5rem 0"})
          }}
        >
          {openCamera && videoConstraints && !idCardImage[side] ? (
            <Webcam
              id="idcard-module"
              ref={webcamRef}
              audio={false}
              imageSmoothing={true}
              screenshotFormat="image/jpeg"
              width={handleCheckSize(props.type, props.rotateType, true)}
              height={handleCheckSize(props.type, props.rotateType, false)}
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              forceScreenshotSourceSize={true}
              autoPlay={true}
              playsInline={true}
              muted={true}
            />
          ) : (
            <img
              src={idCardImage[side]}
              alt="id card"
              style={{
                height: handleCheckSize(props.type, props.rotateType, false),
              }}
            />
          )}
        </div>
      </div>

      <div
        className={`${classes.description} ${props.type}`}
        style={{
          marginTop: props.type === "PASSPORT" && idCardBase64 ? "0.75rem" : "",
        }}
      >
        <div>
          <div className="icon">
            <img src={IMAGES.scan_id_card} alt="scan id card" />
          </div>
        </div>
        <div style={{ whiteSpace: "pre-line" }}>
          <div>{description}</div>
        </div>
      </div>

      {openCamera ? (
        <div style={styles.center}>
          <div
            aria-hidden="true"
            className={classes.shutter_button}
            onClick={handleTakePhoto}
          >
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className="button-submit-bottom" style={{ marginTop: "2rem" }}>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleUsePhoto}
            >
              <FormattedMessage id="bplusClinicKey1117" />
            </MuiButton>
          </div>

          <div
            aria-hidden="true"
            style={{
              textAlign: "center",
              color: COLORS.blue,
              marginBottom: "1rem",
            }}
            onClick={handleRetake}
          >
            <FormattedMessage id="bplusClinicKey1118" />
          </div>
        </>
      )}

      {props.loadingStatus?.[ID_CARD_VERIFY] && (
        <div className={classes.loading}>
          <SubUnderVerification />
        </div>
      )}

      <DialogConfirm
        open={
          typeof errorMessage === "string" && errorMessage?.includes("failed")
        }
        title={<FormattedMessage id="bplusClinicKey1034" />}
        description={`${intl.formatMessage({
          id: "bplusClinicKey1213",
        })}`}
        img={IMAGES.dismiss}
        imgStyle={{ width: "4rem", margin: "-1rem 0px 1.5rem" }}
        denyBasic={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1082" />}
        // callback
        onApprove={handleReconfirm}
        onDeny={handleCloseModError}
      />

      <DialogConfirm
        open={errorMessage === "exceeded"}
        title={
          <div style={{ whiteSpace: "pre-line" }}>
            <FormattedMessage id="bplusClinicKey1079" />
          </div>
        }
        description={intl.formatMessage({ id: "bplusClinicKey1115" })}
        img={IMAGES.lock_shield}
        imgStyle={{ width: "5rem", margin: "-1.5rem 0px 0.75rem" }}
        hideDeny={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1078" />}
        // callback
        onApprove={handleGotoFeed}
      />
    </div>
  );
};

export default React.memo(IDCardVerify);
