import React, { createRef } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Grid,
  Button,
  TextArea,
  Form,
  Dimmer,
  Loader,
  Segment,
  Icon,
} from "semantic-ui-react";

// const SnackMessage = React.lazy(()=> import('../../../../HIS/common/SnackMessage').catch(()=> ({default: ()=> null })))

import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { useIntl } from "react-intl";

/**
 * A modal display the settings of shift on the given division.
 */
export default class ModConfirmCNMI extends React.Component<any, any> {
  static propTypes = {
    textContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]),
    content: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]),
    titleName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]),
    size: PropTypes.string,
    approveButtonText: PropTypes.string,
    denyButtonText: PropTypes.string,
    approveButtonColor: PropTypes.string,
    approveButtonLoading: PropTypes.bool,
    approveButtonStyle: PropTypes.object,
    approveButton: PropTypes.element,
    denyButton: PropTypes.element,
    denyButtonColor: PropTypes.string,
    denyButtonStyle: PropTypes.object,
    disabledApproveButton: PropTypes.bool,
    disabledDenyButton: PropTypes.bool,
    buttonClassName: PropTypes.string,
    loading: PropTypes.bool,
    openModal: PropTypes.bool,
    onApprove: PropTypes.func,
    onDeny: PropTypes.func,
    basic: PropTypes.bool,
    modalStyle: PropTypes.object,
    closeIcon: PropTypes.bool,
    // loading: PropTypes.bool,
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: PropTypes.func,
    autoFocusConfirm: PropTypes.bool,
  };

  static defaultProps = {
    textContent: null,
    content: null,
    backgroundColor: null,
    titleName: "แจ้งเตือน!!!",
    size: "mini",
    approveButtonText: "ใช่",
    denyButtonText: "ไม่",
    approveButtonColor: "green",
    approveButtonLoading: false,
    approveButtonStyle: {},
    approveButton: null,
    denyButton: null,
    denyButtonColor: "red",
    denyButtonStyle: {},
    disabledApproveButton: false,
    disabledDenyButton: false,
    buttonClassName: "centered five wide",
    loading: false,
    openModal: false,
    basic: true,
    modalStyle: {},
    // loading: false,
    testId: "",
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: () => {},
    autoFocusConfirm: false,
  };

  buttonRef: any;

  constructor(props: any) {
    super(props);

    this.buttonRef = createRef<Button>();
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.buttonRef?.current && this.props.autoFocusConfirm) {
      this.buttonRef.current.focus();
    }
  };

  render() {
    const {
      textContent,
      content,
      titleName,
      size,
      basic,
      titleColor,
      approveButtonText,
      denyButtonText,
      approveButtonColor,
      approveButtonLoading,
      approveButtonStyle,
      approveButton,
      denyButton,
      denyButtonColor,
      denyButtonStyle,
      disabledApproveButton,
      disabledDenyButton,
      buttonClassName,
      loading,
      openModal,
      backgroundColor,
      testId,
      className,
      modalStyle,
      // TextArea
      textNote,
      setTextNote,
      showTextNote,
      // SnackMessage
      onEvent,
      setProp,
      cardName,
      submitError,
      submitSuccess,
      // ErrorMessage,
      errorMessageText,
    } = this.props;

    let headerColor = titleColor;

    if (!titleColor && !backgroundColor) {
      headerColor = "blue";
    }

    return (
      <Modal
        open={openModal}
        size={size}
        testid={"modConfirm" + testId}
        closeOnDimmerClick={true}
        onClose={this.props.onCloseWithDimmerClick}
        className={className}
        style={modalStyle}
      >
        <Segment
          className={"inverted " + headerColor}
          style={{
            ...styles.header,
            backgroundColor: backgroundColor,
            ...this.props.customStyleHeader,
          }}
        >
          {titleName}
          {this.props.closeIcon && (
            <Icon
              name="close"
              link={true}
              onClick={this.props.onCloseWithDimmerClick}
            />
          )}
        </Segment>
        <Segment
          basic
          loading={this.props.loading}
          style={{ ...styles.content, ...this.props.customStyleBasic }}
        >
          {textContent}
          {content}
          {this.props.oneBr ? (
            <br />
          ) : (
            <>
              {" "}
              <br /> <br />{" "}
            </>
          )}
          {showTextNote && (
            <Form style={{ marginBottom: "20px" }}>
              {" "}
              <div>
                {" "}
                <TextArea
                  onChange={setTextNote}
                  value={textNote}
                  style={{ maxHeight: "100px", minHeight: "100px" }}
                />{" "}
              </div>{" "}
            </Form>
          )}
          {/* {submitError && SnackMessage && (
            <React.Suspense fallback={'loading...'}>
              <SnackMessage
              onEvent={onEvent}
              onClose={() => {
                setProp(`errorMessage.${cardName}`, null);
              }}
              error={submitError}
              success={submitSuccess}
            />
            </React.Suspense> 
          )} */}
          {errorMessageText && <ErrorMessage error={errorMessageText} />}
          <Grid>
            <Grid.Column
              style={{
                display: !approveButtonText ? "none" : null,
                ...this.props.customGridButton,
              }}
              className={buttonClassName}
            >
              {!approveButton ? (
                <Button
                  ref={this.buttonRef}
                  basic={basic}
                  fluid
                  color={approveButtonColor}
                  onClick={this.props.onApprove}
                  loading={approveButtonLoading}
                  style={approveButtonStyle}
                  disabled={disabledApproveButton}
                >
                  {approveButtonText}
                </Button>
              ) : (
                approveButton
              )}
            </Grid.Column>
            <Grid.Column
              style={{
                display: !denyButtonText ? "none" : null,
                ...this.props.customGridButton,
              }}
              className={buttonClassName}
            >
              {!denyButton ? (
                <Button
                  basic={basic}
                  fluid
                  color={denyButtonColor}
                  onClick={this.props.onDeny}
                  style={denyButtonStyle}
                  disabled={disabledDenyButton}
                >
                  {denyButtonText}
                </Button>
              ) : (
                denyButton
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>
    );
  }
}

const styles = {
  header: {
    borderRadius: 0,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    lineHeight: "1.5em",
  },
};
