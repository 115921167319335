import React, {
  ChangeEvent,
  CSSProperties,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";

// CSS
import { Card, Modal, Icon, Dimmer, Loader, Button } from "semantic-ui-react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// ICON
import CloseIcon from "@material-ui/icons/Close";

// Library
import { browserName, browserVersion } from "react-device-detect";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

// Interface
import { Event as MobEvent } from "../MobCouponInterface";

// UI
import { QRCodeImage, NotFound } from "./MyCoupon";
import SnackMessage from "../clinic/SnackMessage";
import ErrorMessage  from "react-lib/apps/common/ErrorMessage";
import ModInfo  from "react-lib/apps/common/ModInfo";
import MobHeadApp from "../appointment/MobHeadApp";
import ModalChooseMakeAppointment from "./ModalChooseMakeAppointment";
import ModRetryTimeout from "../appointment/ModRetryTimeout";

// Style
import "./MyCoupon.scss";

type AddCouponProps = {
  onEvent: (e: MobEvent) => any;
  successMessage: any;
  errorMessage: any;
  loadingSearchCoupon?: boolean;
  language?: "th-TH" | "en-US";
  patientDetail?: any;
  userId?: string | number;
  showTopbar?: boolean;
  loadingMainCoupon?: boolean;
  stackRememberScroll?: any[];
} & WrappedComponentProps;

type ModCouponError = {
  open: boolean;
  type: "NOT_FOUND" | "USED";
  error: any;
};

// Constant
const hardCodeDiagRuleEvaluate = 20;
const params = new URLSearchParams(window.location.search);
const app = params.get("app");
const path = "/add-coupon";

export const COLOR = {
  blue: "#18a0fb",
  medium_blue: "#0b4ea6",
  dark_blue: "#0147A3",
  opacity: "rgba(133,133,133,0.025)",
};

const useStyles = makeStyles((theme) => ({
  underline: {
    "&:after": {
      borderColor: COLOR.dark_blue,
    },
  },
  button: {
    width: "75%",
    margin: "15px 0",
    fontSize: "16px",
    color: "white",
    backgroundColor: COLOR.dark_blue,
    "&:hover": {
      backgroundColor: COLOR.dark_blue,
    },
    borderRadius: "10px",
    padding: "10px 0",
  },
}));

const styles = {
  label: {
    color: COLOR.dark_blue,
    fontSize: "18px",
    marginBottom: "4px",
  } as CSSProperties,
};

const AddCoupon: React.FunctionComponent<AddCouponProps> = (props) => {
  let history = useHistory();
  const classes = useStyles();

  // const [couponImageSrc, setCouponImageSrc] = useState<string>("");
  const [orderNo, setOrderNo] = useState<string>("");
  const [couponId, setCouponId] = useState<string>("");
  const [fileImage, setFileImage] = useState<any>(null);
  const [message, setMessage] = useState<any>({ error: null, success: null });
  const [inputError, setInputError] = useState<any>({
    orderNo: false,
    couponId: false,
  });
  const [addCouponBy, setAddCouponBy] = useState<"CODE" | "IMAGE">("CODE");
  const [openModCouponError, setOpenModCouponError] = useState<ModCouponError>({
    open: false,
    type: "NOT_FOUND",
    error: null,
  });
  const [locationKeys, setLocationKeys] = useState<string[]>([]);
  const [openModInfo, setOpenModInfo] = useState<any>({});
  const [openModChooseAppointment, setOpenModChooseAppointment] = useState<{
    open?: boolean;
    data?: any;
  }>({});
  const [isFetchError, setIsFetchError] = useState<boolean>(false);

  // Ref
  const inputFile = useRef<HTMLInputElement>(null);
  const diagFormId = useRef<any>();

  // Effect
  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }

        if (openModCouponError.open) {
          setOpenModCouponError({
            open: false,
            type: "NOT_FOUND",
            error: null,
          });
        }
      }
    });
  }, [locationKeys, openModCouponError]);

  useEffect(() => {
    if (!props.userId) {
      return;
    }
    if (!Object.keys(props?.patientDetail || {})?.[0]) {
      props.onEvent({
        message: "handleGetPatientDetail",
        params: { userId: props.userId },
      });
    }
  }, [props.userId]);

  // const handleClearImage = useCallback(() => {
  //   if (inputFile?.current) {
  //     inputFile.current.value = ""
  //   }
  //   setFileImage(null);
  //   setCouponImageSrc("")
  // }, [])

  const handleButtonClick = async () => {
    setIsFetchError(false);

    if (!orderNo || !couponId) {
      setInputError({ orderNo: !!!orderNo, couponId: !!!couponId });
      return;
    } else {
      setInputError({ orderNo: false, couponId: false });
    }
    setAddCouponBy("CODE");
    getCouponDetail({ orderNo, couponId, hospital: 1 });
  };

  const getCouponDetail = async (params: {
    orderNo: string;
    couponId: string;
    hospital: number;
  }) => {
    diagFormId.current = null;

    const [res, error] = await props.onEvent({
      message: "getCouponDetail",
      params,
    });

    if (error && !res?.is_coupon_check) {
      setIsFetchError(true);
      return;
    }

    if (res?.is_coupon_check) {
      setOpenModInfo({ open: true, error });
      return;
    }

    if (res?.error?.code === "data_not_found") {
      history.push(`${path}?app=${app}&coupon_error=true`);
      setOpenModCouponError({
        open: true,
        type: "NOT_FOUND",
        error: res?.error,
      });
    } else {
      if (res?.data?.coupon_status === "not use" && res?.data?.book_status) {
        const { product_code } = res.data;
        const response = await props.onEvent({
          message: "handleGetEvaluate",
          params: { productCode: product_code },
        });

        const findModerna = response?.medProgram?.items?.find(
          (item: any) => item.booking_service_code === "24A2767"
        );

        diagFormId.current = response?.diagFormId;

        if (findModerna) {
          setOpenModChooseAppointment({
            open: true,
            data: res.data,
          });
        } else {
          goToPageEvaluate(res.data);
        }

        return;
      } else {
        history.push(`${path}?app=${app}&coupon_error=true`);
        setOpenModCouponError({ open: true, type: "USED", error: res?.error });
      }
    }
  };

  const goToPageEvaluate = (data: any, page: string = "evaluate") => {
    const { product_code, coupon_id, product_name, site, expired_date } = data;
    history.push({
      pathname: `/${page}/?app=${"MobCoupon"}`,
      // pathname: `/?app=${"MobAppointment"}&product_code=${product_code}`,
      state: {
        product_code: product_code,
        product_name: product_name,
        coupon_number: coupon_id,
        patientDetail: props.patientDetail,
        coupon_expired_date: expired_date,
        coupon_code: `${coupon_id}${site}`,
        diag_form: diagFormId.current,
        add_coupon: true,
      },
    });
  };

  const imageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) {
      return;
    }
    console.log("e.target.files[0]", e.target.files[0]);
    let file = URL.createObjectURL(e.target.files[0]);
    setFileImage(e.target.files[0]);

    // setOrderNo("1454")
    // setCouponId("245648754")
    var img = new Image();
    img.onload = () => {
      if (inputFile.current) {
        inputFile.current.value = "";
      }
      const data = "1-1";
      const [order_no, coupon_id] = data.split("-");
      setAddCouponBy("IMAGE");
      getCouponDetail({ orderNo: order_no, couponId: coupon_id, hospital: 1 });
    };
    img.src = file;
    // setCouponImageSrc(file);
    // setMessage({ error: null, success: "เพิ่มคูปองสำเร็จ" })
  };

  const handleCloseSnackbar = () => {
    setMessage({ error: null, success: null });
  };

  const handleCloseModError = () => {
    history.goBack();
    setOpenModCouponError({ open: false, type: "NOT_FOUND", error: null });
  };

  const handleOpenFile = () => {
    inputFile.current?.click();
  };

  const handleClickMyConpon = () => {
    if (!props.stackRememberScroll?.[0]) {
      const ecoupon = props.showTopbar ? "ecoupon" : "";
      history.push(`/${ecoupon}?app=MobCoupon`);
    } else {
      history.goBack();
    }
  };

  const handleCloseModInfo = () => {
    setOpenModInfo({});
  };

  const handleModalSelect = (select: "self" | "others") => {
    if (select === "self") {
      goToPageEvaluate(openModChooseAppointment.data);
    } else if (select === "others") {
      goToPageEvaluate(openModChooseAppointment.data, "new-patient");
    }

    setOpenModChooseAppointment({});
  };

  console.log(openModInfo);
  console.log("browserName", browserName);
  return (
    <>
      {/* <div style={{ width: "100%", height: "80px", backgroundColor: "#18a0fb", position: "sticky", zIndex: 99, top: 0 }}></div> */}

      {!openModCouponError.open && (
        <Dimmer
          active={props.loadingSearchCoupon || props.loadingMainCoupon}
          inverted
        >
          <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
        </Dimmer>
      )}
      {props.showTopbar && (
        <MobHeadApp title={"E-coupon"} leftIconClick={() => history.goBack()} />
      )}
      <div className="my-coupon" style={{ padding: "15px 12px" }}>
        <Card
          className="card-frame"
          style={{ padding: "15px 15px 20px", borderRadius: "15px" }}
        >
          <label style={styles.label}>
            <FormattedMessage id="e_coupon.order_no" />
          </label>
          <TextField
            error={inputError.orderNo}
            placeholder=""
            label=""
            // className={classes.underline}
            inputProps={{ style: { fontSize: 21, color: "rgba(0,0,0,.87)" } }}
            value={orderNo}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setOrderNo(e.target.value)
            }
            {...(browserName.includes("Safari") && { focused: true })}
            // focused={browserName.includes("Safari")}
          />
          <label style={{ ...styles.label, marginTop: "15px" }}>
            <FormattedMessage id="e_coupon.coupon_id" />
          </label>
          <TextField
            label=""
            error={inputError.couponId}
            placeholder=""
            // className={classes.underline}
            inputProps={{ style: { fontSize: 21, color: "rgba(0,0,0,.87)" } }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCouponId(e.target.value)
            }
            value={couponId}
            {...(browserName.includes("Safari") && { focused: true })}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px 0",
            }}
          >
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={imageChange}
            />
            <MuiButton
              className={classes.button}
              variant="contained"
              onClick={handleButtonClick}
            >
              <FormattedMessage id="e_coupon.add_coupon" />
            </MuiButton>
            {/* <div style={{ color: "#9F9F9F", fontSize: "16px", marginBottom: "15px" }}>OR SCAN QR</div> */}

            {/* <div onClick={handleOpenFile}>
              <QRCodeImage style={{ height: "90px", width: "90px" }} />
            </div> */}

            <div
              style={{
                color: "#9F9F9F",
                fontSize: "16px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <FormattedMessage id="common.or" />
            </div>
            <MuiButton
              className={classes.button}
              variant="contained"
              onClick={handleClickMyConpon}
            >
              <FormattedMessage id="e_coupon.my_coupon" />
            </MuiButton>
          </div>
        </Card>
        <Modal
          basic
          open={openModCouponError.open}
          className="my-coupon"
          style={{ borderRadius: "20px", width: "87.5%", overflow: "hidden" }}
        >
          <Card
            className="card-frame"
            style={{
              padding: "25px",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(80vh)",
            }}
          >
            <Dimmer active={props.loadingSearchCoupon} inverted>
              <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
            </Dimmer>
            <div style={{ position: "absolute", top: "10px", right: "10px" }}>
              <CloseIcon
                style={{ color: COLOR.dark_blue, fontSize: "30px" }}
                onClick={handleCloseModError}
              />
            </div>
            <NotFound
              type={
                openModCouponError.type === "NOT_FOUND"
                  ? "not_found"
                  : "has_used"
              }
              style={{ height: "auto" }}
              content={() => (
                <>
                  <div
                    style={{
                      color: "#393939",
                      fontSize: "22px",
                      textAlign: "center",
                      margin: "30px 0 25px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {openModCouponError.type === "NOT_FOUND" ? (
                      <FormattedMessage id="e_coupon.coupon_not_found" />
                    ) : (
                      openModCouponError.error[
                        props.language === "th-TH" ? "message_th" : "message_en"
                      ]?.replace(/\.$/, "")
                    )}
                  </div>
                  <div
                    style={{
                      color: "#393939",
                      fontSize: "18px",
                      textAlign: "center",
                      margin: "0 0 20px",
                      lineHeight: "28px",
                    }}
                  >
                    {openModCouponError.type === "NOT_FOUND" ? (
                      <FormattedMessage id="e_coupon.sorry_can_not_found_coupon" />
                    ) : (
                      <div style={{ lineHeight: "27px" }}>
                        <FormattedMessage id="e_coupon.sorry" />
                        ,&nbsp;
                        <text>
                          {
                            openModCouponError.error[
                              props.language === "th-TH"
                                ? "message_th"
                                : "message_en"
                            ]
                          }
                        </text>
                        &nbsp;
                        <FormattedMessage id="e_coupon.please_change_coupon" />
                      </div>
                    )}
                  </div>
                </>
              )}
            />
            <MuiButton
              className={classes.button}
              variant="contained"
              onClick={() => {
                if (addCouponBy === "CODE") {
                  // handleButtonClick()
                  handleCloseModError();
                } else {
                  handleOpenFile();
                }
              }}
              style={{ marginBottom: 0 }}
            >
              <FormattedMessage id="common.try_again" />
            </MuiButton>
            <div></div>
          </Card>
        </Modal>
        {/* <SnackMessage
          onEvent={props.onEvent}
          error={openModInfo?.error}
          success={null}
          onClose={handleCloseModInfo}
        /> */}
        <ModInfo
          open={openModInfo?.open}
          className="mod-error-custom"
          titleColor={"red"}
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          <ErrorMessage error={openModInfo?.error} />
        </ModInfo>
        <ModRetryTimeout
          open={isFetchError}
          language={props.language}
          onClick={handleButtonClick}
          onCloseIconClick={() => setIsFetchError(false)}
          closeIcon={true}
        />

        <ModalChooseMakeAppointment
          open={openModChooseAppointment.open || false}
          onClose={() => setOpenModChooseAppointment({})}
          onSelect={handleModalSelect}
        />
      </div>
    </>
  );
};

AddCoupon.defaultProps = {
  onEvent: () => null,
  successMessage: null,
  errorMessage: null,
  loadingSearchCoupon: false,
  patientDetail: {},
  showTopbar: false,
  loadingMainCoupon: false,
  stackRememberScroll: [],
} as Omit<AddCouponProps, "intl">;

export default React.memo(injectIntl(AddCoupon));
