import React, { useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import { makeStyles, Button as MuiButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

import config from "../../config/config";

type ModRetryTimeoutProps = {
  open?: boolean;
  language?: "th-TH" | "en-US";
  hideButtonRetry?: boolean;
  autoHide?: boolean;
  closeIcon?: boolean;
  onClick?: () => any;
  onTimeout?: () => any;
  onCloseIconClick?: () => any;
} & WrappedComponentProps;

const CLASSIC_BLUE = "var(--blue-bdms-color)";

const useStyles = makeStyles((theme) => ({
  text_timeout: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    right: "6px",
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "-2px",
  },
  mod_confirm_content: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  button: {
    width: "75%",
    margin: "15px 0",
    fontSize: "18px",
    fontWeight: "bold",
    color: "white",
    backgroundColor: CLASSIC_BLUE,
    "&:hover": {
      backgroundColor: CLASSIC_BLUE,
    },
    borderRadius: "10px",
    padding: "10px 0",
    textTransform: "unset",
    height: "47.2px",
  },
  text_countdown: {
    width: "100%",
    textAlign: "center",
    fontSize: "18px",
    margin: "10px 0 20px",
  },
  btn_submit: {
    width: "238px",
    color: "white",
    backgroundColor: CLASSIC_BLUE,
    borderRadius: "10px",
    fontWeight: "bold",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  close_icon: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: "8px",
    top: "8px",
  },
}));

export const formatTimer = (duration: any, language?: "th-TH" | "en-US") => {
  const convertLang = {
    "en-US": {
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
    },
    "th-TH": {
      hours: "ชั่วโมง",
      minutes: "นาที",
      seconds: "วินาที",
    },
  }[language || "en-US"];

  const padStart = (num: number) => {
    return num.toString();
  };

  const hours: number = duration.hours();
  const minutes: number = duration.minutes();
  const seconds: number = duration.seconds();

  return `${hours ? `${padStart(hours)} ${convertLang.hours} ` : ""}${
    minutes || hours ? `${padStart(minutes)} ${convertLang.minutes} ` : ""
  }${`${padStart(seconds)} ${convertLang.seconds}`}`;
};

const ModRetryTimeout: React.FunctionComponent<ModRetryTimeoutProps> = (
  props
) => {
  const classes = useStyles();

  const [openModRetry, setOpenModRetry] = useState<boolean>(false);
  const [countdownTimer, setCountdownTimer] = useState<string>("");

  useEffect(() => {
    if (!props.open) {
      return;
    }

    setOpenModRetry(true);

    const diffTime = config.RETRY_TIMEOUT;
    let duration: any = moment.duration(diffTime * 1000, "milliseconds");
    const interval = 1000;

    handleSetCoutdownTimer(duration);

    var timeInterval = setInterval(() => {
      duration = moment.duration(duration - interval, "milliseconds");

      if (duration._milliseconds < 0) {
        setCountdownTimer("");
        if (props.autoHide) {
          setOpenModRetry(false);
        }

        props.onTimeout?.();
        clearInterval(timeInterval);
      } else {
        handleSetCoutdownTimer(duration);
      }
    }, interval);
  }, [props.open]);

  const handleSetCoutdownTimer = (duration: any) => {
    const timer = formatTimer(duration, props.language);
    setCountdownTimer(timer ? `(${timer})` : "");
  };

  const handleClick = () => {
    props.onClick?.();
    setCountdownTimer(" ");
    setOpenModRetry(false);
  };

  return (
    <div>
      <Modal
        open={openModRetry}
        closeOnDimmerClick
        // onClose={props.onClose}
        style={{ borderRadius: "20px", width: "87.5%" }}
      >
        {!countdownTimer && props.closeIcon && (
          <div className={classes.close_icon}>
            <CloseIcon
              style={{ color: CLASSIC_BLUE, fontSize: "30px" }}
              onClick={() => {
                setOpenModRetry(false);
                props.onCloseIconClick?.();
              }}
            />
          </div>
        )}
        <div
          style={
            props.hideButtonRetry
              ? { padding: "35px 25px" }
              : { padding: "45px 25px 40px" }
          }
        >
          <div
            className={classes.mod_confirm_content}
            style={{
              color: CLASSIC_BLUE,
              paddingBottom: "8px",
            }}
          >
            “การทำนัดหมายของท่านยังไม่เสร็จสมบูรณ์
            เนื่องจากมีการเข้าใช้งานระบบเป็นจำนวนมาก กรุณารอสักครู่
            และลองใหม่อีกครั้ง”
          </div>
          <div
            className={classes.mod_confirm_content}
            style={{
              color: CLASSIC_BLUE,
              paddingBottom: "8px",
            }}
          >
            “Your appointment has not been successfully made due to the load
            demand on the system. Please wait a moment and try again.”
          </div>

          <div
            className={classes.text_countdown}
            style={props.hideButtonRetry ? { marginBottom: 0 } : {}}
          >
            {countdownTimer}
          </div>

          {!props.hideButtonRetry && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MuiButton
                className={classes.btn_submit}
                style={{
                  padding: "8px 45px",
                  width: "auto",
                  opacity: countdownTimer ? "0.45" : "",
                }}
                onClick={handleClick}
                disabled={!!countdownTimer}
              >
                <FormattedMessage id="common.retry" />
              </MuiButton>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

ModRetryTimeout.defaultProps = {
  open: false,
  hideButtonRetry: false,
  autoHide: false,
  closeIcon: false,
  onClick: () => {},
  onTimeout: () => {},
  onCloseIconClick: () => {},
} as Omit<ModRetryTimeoutProps, "intl">;

export default React.memo(injectIntl(ModRetryTimeout));
