import React, { useEffect } from "react";
import TabPath from "react-lib/frameworks/TabPath";
import { Button } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

const ContentEdit = (props: any) => {

  useEffect(() => {
    props.onEvent({
      message: "GetContent",
      params: {
        content: props.item
      }
    });
  }, [props.item])
  
  
  return(
    <div 
      style={{ width: "100%", height: "94vh" }}>
      <div style={{ height: "9vh", wordWrap: "break-word" }}>
        <Button size="mini" color="green"
          onClick={(e: any) => {
            props.onEvent({
              message: "ContentOk",
              params: { content: props.item, ok: true }
            })
          }}>
          <FormattedMessage id="bplusClinicKey346" />
        </Button>
        <Button size="mini" color="red"
          onClick={(e: any) => {
            props.onEvent({
              message: "ContentOk",
              params: { content: props.item, ok: false }
            })
          }}>
          <FormattedMessage id="bplusClinicKey341" />
        </Button>
        <a href={props.item.url} target="_blank">
          {decodeURI(props.item.url).substring(0, 100)}
        </a>
      </div>
      <div 
        style={{ 
          height: "85vh", 
          display: "grid",
          gridTemplateColumns: "50% 50%"
        }}>
        <div 
          style={{ 
            overflow: "scroll", 
            padding: "5px",
            border: "solid #cccccc 1px"
          }}>
          {props.item?.elements?.filter((element: any) => !element.key.includes("img:"))
            .map((element: any, index: number) => (
            <div key={index} style={{ borderBottom: "solid #cccccc 1px", cursor: "pointer" }}>
              {element.data} ({element.key.split(":")[0]})
            </div>
            
          ))}
        </div>
        <div 
          style={{ 
            overflow: "scroll", 
            padding: "5px",
            border: "solid #cccccc 1px"
          }}>
          {props.item?.elements?.filter((element: any) => element.key.includes("img:"))
            .map((element: any, index: number) => (
            <div key={index}>
              <div>{decodeURIComponent(element.data)}</div>
              <img 
                style={{ width: "300px" }}
                src={element.data.includes("data:image") ? 
                      decodeURIComponent(element.data):
                      element.data.includes("http") ? 
                      element.data: 
                      "http://" + props.item.domain + element.data}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContentEdit
