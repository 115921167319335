import React, {
  KeyboardEvent,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
  ReactElement,
} from "react";
import { DropdownProps } from "semantic-ui-react";

import makeStyles from "@mui/styles/makeStyles";

// @ts-ignore
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";

// UX
import BottomSheetOption from "./BottomSheetOption";

// Types
type PhoneNumberInputProps = {
  value?: string | number;
  label?: string | ReactElement;
  // required?: boolean;
  // disabled?: boolean;
  error?: string;
  name?: string;
  // callback
  onChange?: (
    event: React.SyntheticEvent<HTMLElement, Event> | null,
    data: DropdownProps
  ) => void;
};

// Styles

const useStyles = makeStyles(() => ({
  phone_input: {
    marginBottom: "1rem",
    "& input.form-control": {
      padding: "1.5rem 1rem 1.5rem 4.5rem !important",
      borderRadius: "8px !important",
      background: "transparent !important",
      // outline: "none !important",
      "&:focus": {
        borderColor: "rgba(34,36,38,.15) !important",
      },
    },
    "& div.flag-dropdown": {
      background: "transparent !important",
      border: "none !important",
      WebkitTapHighlightColor: "transparent",
      "& .selected-flag": {
        paddingLeft: "1.25rem !important",
        background: "transparent !important",
        "& .flag": {
          transform: "scale(1.35) !important",
          "& .arrow": {
            transform: "scale(1.15) !important",
            marginLeft: "0.15rem !important",
          },
        },
      },
    },
    "&.react-tel-input .country-list": {
      display: "none",
    },
  },
  phone_input_error: {
    marginBottom: "1rem",
    "& input.form-control": {
      padding: "1.5rem 1rem 1.5rem 4.5rem !important",
      borderRadius: "8px !important",
      background: "transparent !important",
      border: "1px solid red",
      // outline: "none !important",
      "&:focus": {
        borderColor: "rgba(34,36,38,.15) !important",
      },
    },
    "& div.flag-dropdown": {
      background: "transparent !important",
      border: "none !important",
      WebkitTapHighlightColor: "transparent",
      "& .selected-flag": {
        paddingLeft: "1.25rem !important",
        background: "transparent !important",
        "& .flag": {
          transform: "scale(1.35) !important",
          "& .arrow": {
            transform: "scale(1.15) !important",
            marginLeft: "0.15rem !important",
          },
        },
      },
    },
    "&.react-tel-input .country-list": {
      display: "none",
    },
    "& > input " :{
      border: "1px solid red"
    }
  },
  label: {
    display: "block",
    margin: "0 0 0.28571429rem 0",
    fontWeight: "bold",
    textTransform: "none",
    color: "#393939",
    fontSize: "14px",
  },
}));

const PhoneNumberInput = (props: PhoneNumberInputProps, ref: Ref<any>) => {
  const classes = useStyles();

  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const phoneInputRef = useRef<any>(null);
  const isFocusRef = useRef(false);

  useImperativeHandle(ref, () => phoneInputRef.current);

  // Callback Effect
  const handleDropdownChange = useCallback(
    (mutationList) => {
      mutationList.forEach((mutation: MutationRecord) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const className = (mutation.target as any).className;

          setOpen(className?.includes("open"));
        }
      });
    },
    [phoneInputRef.current]
  );

  const handleObserveDropdown = useCallback(() => {
    const dropdownRef = phoneInputRef.current.dropdownContainerRef;

    if (dropdownRef) {
      const options = {
        attributes: true,
      };

      const observer = new MutationObserver(handleDropdownChange);
      observer.observe(dropdownRef, options);
    }
  }, [phoneInputRef.current]);

  // Memo Effect
  const value = useMemo(() => {
    const countryCode = phoneInputRef.current?.state?.selectedCountry?.dialCode;

    return !props.value ? countryCode : props.value;
  }, [props.value, phoneInputRef.current]);

  const autoFormat = useMemo(() => {
    const digitsOnly = (value || "").toString().replace(/\D/g, "");
    const countryCodes = {
      "66": 2, // th
      "855": 3, //kh
    };

    return Object.keys(countryCodes).some((code) =>
      digitsOnly.startsWith(code)
    );
  }, [value]);

  // Effect
  useEffect(() => {
    if (phoneInputRef.current) {
      if (autoFormat) {
        if (isFocusRef.current) {
          phoneInputRef.current.numberInputRef.focus();
        }

        handleObserveDropdown();
      }
    }
  }, [autoFormat, phoneInputRef.current]);

  useEffect(() => {
    if (phoneInputRef.current) {
      const onlyCountries = phoneInputRef.current.state.onlyCountries.map(
        (country: any) => ({
          key: country.iso2,
          text: country.name,
          value: country.dialCode,
          country,
        })
      );

      setOptions(onlyCountries);

      handleObserveDropdown();
    }
  }, [autoFormat, phoneInputRef.current]);

  // Handler
  const handleCheckPhoneNumber = (
    phoneNumber: string,
    country: CountryData
  ) => {
    if (phoneNumber.length < 11 + country.dialCode.length) {
      let phoneNo = phoneNumber;
      if (
        phoneNumber.replace(`${country.dialCode}`, "").startsWith("0") ||
        phoneNumber.startsWith("0")
      ) {
        phoneNo = phoneNumber.replace(`${country.dialCode}`, "");
        phoneNo = phoneNo.replace(/^0/, "");
      }

      if (
        !`${phoneNo}`.includes(`${country.dialCode}`) &&
        phoneNumber.length < `${country.dialCode}`.length
      ) {
        phoneNo = `${country.dialCode}`;
      } else if (!`${phoneNo}`.includes(`${country.dialCode}`)) {
        phoneNo =
          `${country.dialCode}` + phoneNo.replace(`${country.dialCode}`, "");
      }

      const value = phoneNo === country.dialCode ? "" : phoneNo;

      const newData = {
        value: value,
        key: country.dialCode,
        text: value,
        name: props.name,
        country,
        phoneNumber,
      };

      props.onChange?.(null, newData);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const elm = event.target as HTMLInputElement;
    if (
      event.key === "Backspace" &&
      elm.value === `+${phoneInputRef.current.state.selectedCountry.dialCode}`
    ) {
      event.preventDefault();
    }
  };

  const handleFlagItemClick = (e: any, option: any) => {
    phoneInputRef.current.handleFlagItemClick(option.country);
  };

  return (
    <>
      {!!props.label && <label className={classes.label}>{props.label}</label>}
      <PhoneInput
        key={`phone-input-${autoFormat}`}
        containerClass={props.error? classes.phone_input_error: classes.phone_input}
        // @ts-ignore
        ref={phoneInputRef}
        disableCountryCode={!autoFormat}
        autoFormat={autoFormat}
        country={"th"}
        onlyCountries={["th", "kh"]}
        defaultMask=".. ... ...."
        value={value?.toString()}
        // styles
        containerStyle={{ marginBottom: props.label ? "" : 0 }}
        onKeyDown={handleKeyDown}
        onChange={handleCheckPhoneNumber}
        onFocus={() => (isFocusRef.current = true)}
        onBlur={() => (isFocusRef.current = false)}
      />

      <BottomSheetOption
        open={open}
        label="Country code"
        type="phone-number"
        options={options}
        // callback
        onChange={handleFlagItemClick}
      />
    </>
  );
};

const ForwardedPhoneNumberInput = forwardRef<any, PhoneNumberInputProps>(
  PhoneNumberInput
);

export default React.memo(ForwardedPhoneNumberInput);
