import React, { useEffect } from "react";
import { Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';

import SnackMessage from "./SnackMessage";

import "../../css/WebClinic.scss";

type MomProfileHealthOverallProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  profileHealth?: any[];
  tableWidth?: string;
};

const MomProfileHealthOverallInitial: MomProfileHealthOverallProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  profileHealth: [],
  tableWidth: "100%",
};

const MomProfileHealthOverall: React.FC<MomProfileHealthOverallProps> = (
  props
) => {
  const intl = useIntl();
  useEffect(() => {
    props.onEvent({ message: "handleGetProfileHealth", params: {intl} });
  }, [props.selectedPatient]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid
        verticalAlign="middle"
        style={{ width: props.tableWidth || "100%" }}
      >
        <Grid.Column width={16}>
          <Header as="h4" style={{ color: "var(--text-dark-blue)" }}>
            <FormattedMessage id="bplusClinicKey364" />
          </Header>
        </Grid.Column>

        <Grid.Row
          style={{
            background: "var(--theme-bg-color-dm)",
            margin: "0px 10px",
          }}
        >
          <Grid.Column width={7}>
            <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey519" />
            </Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey468" />
            </Header>
          </Grid.Column>
        </Grid.Row>
        {props.profileHealth?.map((item: any, index: number) => {
          return (
            <Grid.Row
              key={index}
              style={{ color: "var(--text-grey)", padding: "5px" }}
            >
              <Grid.Column width={7}>{item.name}</Grid.Column>
              <Grid.Column width={9}>
                {
                  item.value === "น้อยกว่าเกณฑ์มาตรฐาน" ? `${intl.formatMessage({ id: "bplusClinicKey912" })}` :
                  item.value === "มากกว่าเกณฑ์มาตรฐาน" ? `${intl.formatMessage({ id: "bplusClinicKey914" })}` :
                  item.value === "เหมาะสมตามเกณฑ์" ? `${intl.formatMessage({ id: "bplusClinicKey869" })}` :
                  item.value === "อยู่ในเกณฑ์ที่เหมาะสม" ? `${intl.formatMessage({ id: "bplusClinicKey868" })}` :

                  item.value === "หัวนมปกติ" ? `${intl.formatMessage({ id: "bplusClinicKey777" })}` :
                  item.value === "หัวนมสั้น" ? `${intl.formatMessage({ id: "bplusClinicKey778" })}` :
                  item.value === "หัวนมบอด" ? `${intl.formatMessage({ id: "bplusClinicKey779" })}` :
                  item.value === "หัวนมบุ๋ม" ? `${intl.formatMessage({ id: "bplusClinicKey780" })}` :
                  
                  item.value === "สมควรลดกิจกรรม" ? `${intl.formatMessage({ id: "bplusClinicKey909" })}` :
                  item.value === "ควรรีบปรึกษาแพทย์" ? `${intl.formatMessage({ id: "bplusClinicKey381" })}` : item.value
                }
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
};

MomProfileHealthOverall.defaultProps = MomProfileHealthOverallInitial;

export default React.memo(MomProfileHealthOverall);
