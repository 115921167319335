import React, { CSSProperties, ReactElement } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// Types
type ItemNotFoundProps = {
  img: string | ReactElement;
  imgSize?: string;
  title?: string;
  subTitle?: string | ReactElement;
  // style
  style?: CSSProperties;
};

// Styles
const COLORS = {
  light_grey: "rgba(121, 120, 120, 1)",
};

const useStyles = makeStyles(() => ({
  not_found: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100% - 8rem)",
    color: COLORS.light_grey,
    textAlign: "center",
    "& img": {
      width: "10rem",
    },
    "& span": {
      fontWeight: "bold",
    },
    "& .bold": {
      fontWeight: "bold",
      fontSize: "1.25rem",
      marginBottom: "0.5rem",
    },
  },
}));

const ItemNotFound = (props: ItemNotFoundProps) => {
  const classes = useStyles();

  return (
    <div className={classes.not_found} style={props.style}>
      {typeof props.img === "string" ? (
        <img
          src={props.img}
          style={{
            width: props.imgSize || "",
            marginBottom: props.title ? "1.5rem" : "1rem",
          }}
        />
      ) : (
        props.img
      )}
      <div>
        {props.title && <div className="bold">{props.title}</div>}
        {typeof props.subTitle === "string"
          ? props.subTitle
              ?.split("\n")
              .map((text) => <div key={text}>{text}</div>)
          : props.subTitle}
      </div>
    </div>
  );
};

export default React.memo(ItemNotFound);
