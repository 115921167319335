import React, {
  CSSProperties,
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";
import { Bar, Line } from "react-chartjs-2";
import { Dimmer, Dropdown, Icon, Loader, Segment } from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import moment from "moment";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BE_DATE_FORMAT,
  GLUCOSE_MEAL,
} from "../../MobClinicInterface";
import { Event } from "../../ManageInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import {
  HeaderChart,
  labelNewLine,
  FooterChartCustom,
  spliceChartData,
} from "../../clinic/DMEmotionRestChart";
import { drawStarChart, displayNoteChart } from "../../clinic/DMBloodPressure";

// Utils
import { dateToStringWithoutTimeBE } from "../../../react-lib/utils/dateUtils";

type GlucoseProps = {
  onEvent: (e: Event) => any;
  glucoseData?: any;
  bgChartList?: any;
  bgBarfpg?: any;
  bgBarhba1c?: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
};

const GlucoseInitial = {
  onEvent: () => {},
  labSummary: {},
  glucoseData: {
    HbA1c: [
      {
        updated_at: "2021-02-01",
        value: 1.6,
      },
      {
        updated_at: "2021-02-02",
        value: 2.5,
      },
      {
        updated_at: "2021-02-03",
        value: 1.5,
      },
      {
        updated_at: "2021-02-04",
        value: 2.2,
      },
      {
        updated_at: "2021-02-05",
        value: 1.4,
      },
      {
        updated_at: "2021-02-06",
        value: 1.8,
      },
      {
        updated_at: "2021-02-07",
        value: 1.9,
      },
    ],
    FPG: [
      {
        updated_at: "2021-02-01",
        value: 160,
      },
      {
        updated_at: "2021-02-02",
        value: 250,
      },
      {
        updated_at: "2021-02-03",
        value: 150,
      },
      {
        updated_at: "2021-02-04",
        value: 220,
      },
      {
        updated_at: "2021-02-05",
        value: 140,
      },
      {
        updated_at: "2021-02-06",
        value: 180,
      },
      {
        updated_at: "2021-02-07",
        value: 190,
      },
    ],
    SMBG: [
      {
        updated_at: "2021-02-01",
        value: 110,
        meal: "before",
      },
      {
        updated_at: "2021-02-01",
        value: 170,
        meal: "after",
      },
      {
        updated_at: "2021-02-02",
        value: 180,
        meal: "before",
      },
      {
        updated_at: "2021-02-02",
        value: 250,
        meal: "after",
      },
      {
        updated_at: "2021-02-03",
        value: 150,
        meal: "before",
      },
      {
        updated_at: "2021-02-03",
        value: 200,
        meal: "after",
      },
      {
        updated_at: "2021-02-04",
        value: 160,
        meal: "before",
      },
      {
        updated_at: "2021-02-04",
        value: 220,
        meal: "after",
      },
      {
        updated_at: "2021-02-05",
        value: 140,
        meal: "before",
      },
      {
        updated_at: "2021-02-05",
        value: 200,
        meal: "after",
      },
      {
        updated_at: "2021-02-06",
        value: 140,
        meal: "before",
      },
      //   {
      //     updated_at: "2021-02-06",
      //     value: 180,
      //     meal: "after",
      //   },
      {
        updated_at: "2021-02-07",
        value: 130,
        meal: "before",
      },
      {
        updated_at: "2021-02-07",
        value: 190,
        meal: "after",
      },
    ],
  },
};

const styles = {
  barChart: {
    flex: "1",
    padding: "15px",
  } as CSSProperties,
  headerBarChart: {
    fontSize: "26px",
    color: "#000000",
    padding: "10px",
  } as CSSProperties,
  durationFont: {
    fontSize: "15px",
    color: "#2D9BE2",
    paddingRight: "15px",
  } as CSSProperties,
  durationDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  } as CSSProperties,
  durationDropdown: {
    minWidth: "100px",
  } as CSSProperties,
};

const Glucose: React.FC<GlucoseProps> = (props) => {
  useEffect(() => {
    props.onEvent({
      message: "GetBloodGlucoseBar",
      params: {
        start_measure: moment().add("-7", "days").format(BACKEND_DATE_FORMAT),
        end_measure: moment().format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.glucoseData]);

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <Icon
          name="tint"
          style={{ color: "var(--theme-icon-color-dm)", marginBottom: "-6px" }}
        />
        <span>
          <FormattedMessage id="bplusClinicKey215" />
        </span>
      </div>

      <div className="sub-layout dm">
        <div style={{ display: "flex", flexDirection: "row", height: "45%" }}>
          <div style={styles.barChart}>
            <span style={styles.headerBarChart}>
              <FormattedMessage id="bplusClinicKey232" />
            </span>
            <BarGlucose
              label="HbA1c"
              data={props.bgBarhba1c}
              onEvent={props.onEvent}
              stepSizeChart={2}
              labCode="02C880"
            />
          </div>
          <div style={styles.barChart}>
            <span style={styles.headerBarChart}>
              <FormattedMessage id="bplusClinicKey195" />
            </span>
            <BarGlucose
              label="FPG"
              data={props.bgBarfpg}
              onEvent={props.onEvent}
              stepSizeChart={20}
              labCode="02C040"
            />
          </div>
        </div>
        <div style={{ height: "55%", padding: "15px" }}>
          <span style={styles.headerBarChart}>
            <FormattedMessage id="bplusClinicKey447" />
          </span>
          <LineGlucose data={props.bgChartList} onEvent={props.onEvent} />
        </div>
      </div>
    </Segment>
  );
};

type ChartProps = {
  data?: any;
  onEvent: (e: Event) => any;
  label?: string;
  stepSizeChart?: number;
  labCode: "02C880" | "02C040";
};

type LineProps = {} & Omit<ChartProps, "labCode">;

const durationOptions = [
  { key: 1, value: "4", text: "5 days" },
  { key: 2, value: "6", text: "7 days" },
  { key: 3, value: "9", text: "10 days" },
];

const barDurationOPtions = [{ key: "all", value: "all", text: "All" }];
const BarGlucose: React.FC<ChartProps> = (props) => {
  const [view, setView] = useState<any>("all");
  const [bar, setBar] = useState<any>({ data: {}, options: {} });

  //   useEffect(() => {
  //     (Chart as any).pluginService.register({
  //       afterDraw: (chart: any) => {
  //         var ctx = chart.chart.ctx;
  //         ctx.save();
  //         ctx.font = "bold 14px";
  //         ctx.fillStyle = "gray";
  //         var xAxe = chart.scales["x-axis-0"];
  //         var yAxe = chart.scales["y-axis-0"];
  //         let labelText = props.label === "FPG" ? "md/dl" : "%HbA1c"
  //         if (yAxe !== undefined && xAxe !== undefined) {
  //           console.log("Chart Data", xAxe, yAxe);
  //           ctx.textAlign = "left";
  //           ctx.fillText(labelText, xAxe.left, yAxe.top);
  //           ctx.fillText("Date", xAxe.right, yAxe.bottom);

  //           ctx.textAlign = "right";
  //           // ctx.restore();
  //         }
  //       },
  //     });
  //   }, []);

  useEffect(() => {
    let labels: any = [];
    let data = [];
    if (props.data?.length > 0) {
      // set x Axis (Date)
      let mapDate = props.data.map((item: any) => item.updated_at.split(" "));
      for (const date of mapDate) {
        if (!labels.includes(date)) {
          labels.push(date);
        }
      }

      // set y Axis (Data)
      data = props.data.map((item: any) => item.value);
    }

    setBar({
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: props.label === "FPG" ? "#1621A8" : "#A0F297",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontSize: 16,
                fontWeight: "bold",
              },
              maxBarThickness: 70,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
                beginAtZero: true,
                stepSize: props.stepSizeChart,
              },
              scaleLabel: {
                display: true,
                labelString: props.label === "FPG" ? "md/dl" : "%HbA1c",
                fontSize: 16,
                fontWeight: "bold",
              },
            },
          ],
        },
        maintainAspectRatio: false,
      },
    });
  }, [props.data]);

  const handleChangeView = (event: any, data: any) => {
    setView(data.value);
    props.onEvent({
      message: "GetBloodGlucoseByLabCode",
      params: {
        start_measure: moment()
          .add(`-${data.value}`, "d")
          .format(BACKEND_DATE_FORMAT),
        end_measure: moment().format(BACKEND_DATE_FORMAT),
        labCode: props.labCode,
      },
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <div style={styles.durationDiv}>
        <span style={styles.durationFont}>
          <FormattedMessage id="bplusClinicKey169" />
        </span>
        <Dropdown
          style={styles.durationDropdown}
          selection
          options={barDurationOPtions}
          value={view}
          onChange={handleChangeView}
        />
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <Bar data={bar.data} options={bar.options} />
      </div>
    </div>
  );
};

const LineGlucose: React.FC<LineProps> = (props) => {
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [view, setView] = useState<any>("6");
  const [line, setLine] = useState<any>({ data: {}, options: {} });
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>;

  const labelLimit = 15;

  useEffect(() => {
    let labels: any[] = [];
    // let beforeData: any[] = [];
    // let afterData: any[] = [];
    let realBefore: any[] = [];
    let realAfter: any[] = [];

    let max = 250;
    if (props.data.length > 0) {
      console.log(props.data);
      [labels, realAfter, realBefore] = props.data.reduce(
        (result: [string[], any[], any[]], item: any) => {
          const date = dateToStringWithoutTimeBE(item.measure_date);
          const filter = result[0].filter((value) => value.includes(date));
          const label = `${date} (${filter.length + 1})`;
          if (Number.isInteger(+item.value)) {
            result[0].push(label);
            result[item.meal === GLUCOSE_MEAL.AFTER ? 1 : 2].push({
              ...item,
              label: label,
            });
          }
          return result;
        },
        [[], [], []]
      );
      // set x Axis (Date)
      // let mapDate = props.data.map(
      //   (item: any) => item.updated_at.split(" ")[0]
      // );
      // console.log(props.data);
      // for (const date of mapDate) {
      //   if (!labels.includes(date)) {
      //     labels.push(date);
      //     // set y Axis (Data)
      //     let filterDateData = props.data.filter((item: any) =>
      //       item.updated_at.includes(date)
      //     );
      //     if (filterDateData.length > 0) {
      //       let filterBeforeMeal = filterDateData.filter(
      //         (item: any) => item.meal === "before"
      //       );
      //       let filterAfterMeal = filterDateData.filter(
      //         (item: any) => item.meal === "after"
      //       );
      //       if (filterBeforeMeal.length === 0) {
      //         beforeData.push(null);
      //         rawBefore.push(null);
      //         // console.log("push", filterBeforeMeal, beforeData);
      //       } else {
      //         beforeData = beforeData.concat(
      //           filterBeforeMeal.map((item: any) => item.value)
      //         );
      //         rawBefore = [...rawBefore, ...filterBeforeMeal];
      //       }
      //       if (filterAfterMeal.length === 0) {
      //         afterData.push(null);
      //         rawAfter.push(null);
      //       } else {
      //         afterData = afterData.concat(
      //           filterAfterMeal.map((item: any) => item.value)
      //         );
      //         rawAfter = [...rawAfter, ...filterAfterMeal];
      //       }
      //     }
      //   }
      // }
      // if (afterData.length > 0) {
      //   max = afterData.reduce((a, b) => {
      //     return Math.max(a, b);
      //   }, 0);
      //   max = Math.ceil(max / 100) * 100;
      // } else if (beforeData.length > 0) {
      //   max = beforeData.reduce((a, b) => {
      //     return Math.max(a, b);
      //   }, 0);
      //   max = Math.ceil(max / 100) * 100;
      // }
      max =
        Math.max(...[...realAfter, ...realBefore].map((item) => +item.value)) +
        10;
    }

    setLine({
      data: {
        labels: labels,
        datasets: [
          {
            label: "Before meal",
            data: realBefore.map((item) => ({ x: item.label, y: item.value })),
            fill: false,
            borderColor: "#2399E5",
            // backgroundColor: "#2399E5",
            spanGaps: true,
            realData: realBefore,
            pointRadius: 4,
            borderWidth: 1,
          },
          {
            label: "After meal",
            data: realAfter.map((item) => ({ x: item.label, y: item.value })),
            fill: false,
            borderColor: "#60BB2E",
            // backgroundColor: "#60BB2E",
            spanGaps: true,
            realData: realAfter,
            pointRadius: 4,
            borderWidth: 1,
          },
        ],
      },
      options: {
        // responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem: any[], data: any) => {
              const target = getTargetData(tooltipItem[0], data);

              return `${dateToStringWithoutTimeBE(
                target.measure_date
              )} ${moment(target.measure_date).format("HH:mm")}`;
            },
            label: (tooltipItem: any, data: any) => {
              const target = getTargetData(tooltipItem, data);

              return [`- ${target.value}, ${target.meal} meal`];
            },
            labelColor(tooltipItem: any, chart: any) {
              const borderColor =
                chart.chart.config.data.datasets[tooltipItem.datasetIndex]
                  .borderColor;

              return {
                borderColor: borderColor,
                backgroundColor: borderColor,
              };
            },
            afterLabel: (tooltipItem: any, data: any) => {
              const target = getTargetData(tooltipItem, data);
              return [`- ${target.status}`];
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontSize: 16,
                fontWeight: "bold",
              },
              ticks: {
                callback: labelNewLine,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
                max: max,
                beginZero: true,
              },
              scaleLabel: {
                display: true,
                // labelString: "mg/dL",
                fontSize: 16,
                fontWeight: "bold",
              },
            },
          ],
        },
        maintainAspectRatio: false,
      },
    });
  }, [props.data]);

  useEffect(() => {
    props.onEvent({
      message: "GetBloodGlucoseChart",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [startDate]);

  const subChartData = useMemo(() => {
    return spliceChartData({
      data: line.data,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [line.data, skipIndex]);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {},
        afterDatasetsDraw: (chart: any) => {
          drawStarChart({
            chart,
            allowIndex: [0, 1],
            noteField: "realData",
          });
        },
        beforeEvent: (chart: any, event: any) => {
          displayNoteChart({
            chart,
            event,
            allowIndex: [0, 1],
            noteField: "realData",
            tooltipRef: tooltipRef.current,
          });
        },
      },
    ];
  }, []);

  const getTargetData = (tooltipItem: any, data: any) => {
    return data.datasets[tooltipItem.datasetIndex].realData[tooltipItem.index];
  };

  const handleChangeView = (event: any, data: any) => {
    setView(data.value);
    setStartDate(moment().add(`-${data.value}`, "days"));
    setEndDate(moment());
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, BE_DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${view}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, BE_DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(view, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  return (
    <div style={{ height: "100%" }}>
      <HeaderChart
        yAxesLabelLeft="mg/dL"
        yAxesLabelRight=""
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={view}
        onChangeDuration={handleChangeView}
        labelStyle={{ padding: "10px 8px 5px 8px" }}
        legendStyle={{ gridTemplateColumns: "unset" }}
        onChangeDateRange={handleChangeDateRange}
        // hideDateRange={true}
        legendItem={{
          type: "line",
          items: [
            {
              name: "Before meal",
              color: "#2399E5",
            },
            {
              name: "After meal",
              color: "#60BB2E",
            },
            {
              name: "Patient’s note",
              color: "#EE1C24",
              type: "star" as "star",
              style: { marginLeft: "17px" },
            },
          ],
        }}
      />
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Line
          data={isSeeAll ? line.data : subChartData}
          options={line.options}
          plugins={plugins}
          height={120}
        />
        <div ref={tooltipRef} className="tooltip-blood-pressure"></div>
      </div>

      <FooterChartCustom
        chartData={line.data}
        startDate={startDate}
        onChangeIsSeeAll={(see) => setIsSeeAll(see)}
        onChangeSkipIndex={(index) => setSkipIndex(index)}
        labelLimit={labelLimit}
        style={{ marginLeft: "27.5px" }}
      />
    </div>
  );
};

Glucose.defaultProps = GlucoseInitial;

export default React.memo(Glucose);
