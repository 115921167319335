// Autogenerated by utils/python/genmain.py
import React, { useState } from "react";
import TabPath from "react-lib/frameworks/TabPath";
import MarketingSegment from "../post/MarketingSegment";
import { Post } from "../post/Post";
import PostEx from "../post/PostEx";
import PromotionEX from "../post/PromotionEX";

import ClinicContent from "./ClinicContent";
import MenuIcon from "./MenuIcon";
// ui
import PatientDashBoard from "./PatientDashboard";
import PatientDetail from "./PatientDetail";
import UserManagementDM from "./UserManagementDM";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import { DM_RESULT, DM_SUB_RESULT } from "../ManageInterface";

type MainDMProps = {
  // function
  onEvent: any;
  setProp: any;
  // common data
  loadingStatus?: boolean;
  loadingWebFoot?: boolean;
  loadingPatientSelection?: boolean;
  loadingPatientSegment?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  selectedDivision?: any;
  clinic?: string;
  onSearch?: boolean;
  showSegment?: boolean;
  // Options
  medDivisionTKOptions?: any[];
  medDivisionCROptions?: any[];
  medDoctorTKOptions?: any[];
  medDoctorCROptions?: any[];
  routeOptions: any;
  unitOptions: any;
  frequencyOptions: any;
  methodOptions: any;
  administrationTimeOptions: any;
  // data PatientDashBoard
  patientSegmentList?: any;
  // data WebPatientPanel
  scoreSummary?: any;
  labSummary?: any;
  profileHealth?: any[];
  // data PatientDetail
  showPatientDM?: boolean;
  leftMenuSelected?: string;
  dmSelectedTab?: number | null;
  // weight
  patientWeightList?: any;
  hospitalWeightList?: any;
  weightData?: any;
  weightChartList?: any;
  // emotion rest
  emotionRestHistoryList?: any[];
  // exercise
  exerciseChartList?: any[];
  exerciseSettingData?: any;
  // medication
  loadingMedication?: boolean;
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medicationCurrentList?: any;
  medicationCurrentListFilter: any[];
  medicationTrackcare?: any;
  medicationShowFilter?: any;
  medicationTrackcareFilter?: any;
  drugDataTabHistory?: any;
  medicationCurrentPatientDrug?: any;
  // blood
  patientData?: any;
  BloodPresusreData?: any;
  bpChartList?: any;
  // glucose
  glucoseData?: any;
  bgChartList?: any;
  bgBarfpg?: any;
  bgBarhba1c?: any;
  // foot
  footHistory?: any;
  footData?: any;
  footEclinicalData?: any;
  // food
  foodHistory?: any[];
  // vaccine
  vaccineDataTabDetail?: any;
  vaccineSeriesList?: any;
  // Usermanagement Nutrition
  loadingStatusAditionalMenu?: boolean;
  nutritionAditionalMenuList?: any;
  selectedNutritionAditionalMenu?: any;
  nutritionNutritionistMenuList?: any;
  selectedNutritionistMenu?: any;
  nutritionistUMFilter?: any;
  nutritionUMFilter?: any;
  consumptionUnitList?: any[];
  foodTypeList?: any[];
  openModNutrition?: any;
  patientSearch?: any;
  // Usermanager Patient Selection
  filterKeyword?: string;
  dmMomSegmentList?: any;
  dmMomPatientList?: any;
  // User manager vaccine
  vaccineUMDetail?: any;
  vaccineUMList?: any[];
  orderItemVaccineUM?: any[];
  selectedDivisionId?: any;
  // Segmentation
  loadingMarketingSegment?: boolean;
  loadingMarketingPatient?: boolean;
  filterMarketingPatient?: any;
  marketingSegmentList?: any;
  marketingPatientList?: any;
  marketingSegmentAgeGender?: any;
  marketingSegmentTopPost?: any[];
  marketingSegmentTopPromotion?: any[];
  marketingCreatePatient?: any;
  tabPathActiveIndex?: number;
};

const MainDMInitial: MainDMProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  // common data
  loadingStatus: false,
  loadingWebFoot: false,
  loadingPatientSelection: false,
  loadingPatientSegment: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  selectedDivision: {},
  clinic: "",
  onSearch: false,
  // Options
  medDivisionTKOptions: [],
  medDivisionCROptions: [],
  medDoctorTKOptions: [],
  medDoctorCROptions: [],
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // data PatientDashBoard
  patientSegmentList: {},
  // data WebPatientPanel
  scoreSummary: {},
  labSummary: {},
  profileHealth: [],
  // data PatientDetail
  showPatientDM: false,
  leftMenuSelected: "patient_segment",
  dmSelectedTab: 0,
  // weight
  patientWeightList: {},
  hospitalWeightList: {},
  weightData: {},
  weightChartList: {},
  // emotion rest
  emotionRestHistoryList: [],
  // medication
  loadingMedication: false,
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medicationCurrentList: {},
  medicationCurrentListFilter: [],
  medicationTrackcare: {},
  medicationShowFilter: {},
  medicationTrackcareFilter: [],
  drugDataTabHistory: {},
  medicationCurrentPatientDrug: {},
  // blood
  patientData: {},
  BloodPresusreData: {},
  bpChartList: {},
  // glucose
  glucoseData: {},
  bgChartList: {},
  bgBarfpg: {},
  bgBarhba1c: {},
  // foot
  footHistory: {},
  footData: {},
  footEclinicalData: {},
  // food
  foodHistory: [],
  // vaccine
  vaccineDataTabDetail: {},
  vaccineSeriesList: [],
  // Usermanagement Nutrition
  loadingStatusAditionalMenu: false,
  nutritionAditionalMenuList: {},
  selectedNutritionAditionalMenu: null,
  nutritionNutritionistMenuList: {},
  selectedNutritionistMenu: null,
  nutritionistUMFilter: {},
  nutritionUMFilter: {},
  consumptionUnitList: [],
  foodTypeList: [],
  openModNutrition: {},
  patientSearch: {},
  // Usermanager Patient Selection
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
  // User manager vaccine
  vaccineUMDetail: {},
  vaccineUMList: [],
  orderItemVaccineUM: [],
  // Segmentation
  loadingMarketingSegment: false,
  loadingMarketingPatient: false,
  filterMarketingPatient: {},
  marketingSegmentList: {},
  marketingPatientList: {},
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  marketingCreatePatient: null,
};

const DM_LEFT_MENU = [
  {
    result: DM_RESULT.DM,
    subResults: [
      DM_SUB_RESULT.NO_COMPLICATION,
      DM_SUB_RESULT.RISK_TO_CVD,
      DM_SUB_RESULT.UNCLASSIFIED,
    ],
  },
  {
    result: DM_RESULT.PRE_DM,
    subResults: [DM_SUB_RESULT.NO_COMPLICATION, DM_SUB_RESULT.RISK_TO_CVD],
  },
  {
    result: DM_RESULT.DM_SURVEILLANCE,
    subResults: [DM_SUB_RESULT.NO_COMPLICATION, DM_SUB_RESULT.RISK_TO_CVD],
  },
];

const MainDM: React.FC<MainDMProps> = (props: any) => {
  const handleTabChange = (index: number) => {
    if (index === 0 || index === 1) {
      props.setProp("leftMenuSelected", "patient_segment");
    } else if (index === 5) {
      props.setProp("leftMenuSelected", "user_management");
    }
  };

  return (
    <div className="MainDm-tabPath" style={{ height: "100%" }}>
      {/* 94vh */}
      <TabPath
        selectedTabIndex={0}
        level={1}
        history={props.history}
        tabWidth={props.showPatientDM ? "0" : "5.7vw"}
        onTabChange={handleTabChange}
        data={[
          {
            name: (
              <MenuIcon
                label="Diabetes Pathway"
                icon="/images/expansion/blood.png"
              />
            ),
            key: "dmpathway",
            render: () => (
              <div style={{ height: "100%" }}>
                {props.showPatientDM ? (
                  <PatientDetail
                    // function
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // Options
                    medDivisionTKOptions={props.medDivisionTKOptions}
                    medDivisionCROptions={props.medDivisionCROptions}
                    medDoctorTKOptions={props.medDoctorTKOptions}
                    medDoctorCROptions={props.medDoctorCROptions}
                    routeOptions={props.routeOptions}
                    unitOptions={props.unitOptions}
                    frequencyOptions={props.frequencyOptions}
                    methodOptions={props.methodOptions}
                    administrationTimeOptions={props.administrationTimeOptions}
                    // data
                    loadingStatus={props.loadingStatus}
                    loadingWebFoot={props.loadingWebFoot}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    dmSelectedTab={props.dmSelectedTab}
                    // overall
                    scoreSummary={props.scoreSummary}
                    labSummary={props.labSummary}
                    profileHealth={props.profileHealth}
                    // weight
                    patientWeightList={props.patientWeightList}
                    hospitalWeightList={props.hospitalWeightList}
                    weightData={props.weightData}
                    weightChartList={props.weightChartList}
                    // EmotionRest
                    emotionRestHistoryList={props.emotionRestHistoryList}
                    // Exercise
                    exerciseChartList={props.exerciseChartList}
                    exerciseSettingData={props.exerciseSettingData}
                    // medication
                    loadingMedication={props.loadingMedication}
                    loadingDivisionOptions={props.loadingDivisionOptions}
                    loadingDoctorOptions={props.loadingDoctorOptions}
                    medicationCurrentList={props.medicationCurrentList}
                    medicationCurrentListFilter={
                      props.medicationCurrentListFilter
                    }
                    medicationTrackcare={props.medicationTrackcare}
                    medicationShowFilter={props.medicationShowFilter}
                    // blood
                    patientData={props.patientData}
                    BloodPresusreData={props.BloodPresusreData}
                    bpChartList={props.bpChartList}
                    bpFilterDuration={props.bpFilterDuration}
                    // glucose
                    glucoseData={props.glucoseData}
                    bgChartList={props.bgChartList}
                    bgBarfpg={props.bgBarfpg}
                    bgBarhba1c={props.bgBarhba1c}
                    // foot
                    footHistory={props.footHistory}
                    footData={props.footData}
                    footEclinicalData={props.footEclinicalData}
                    medicationTrackcareFilter={props.medicationTrackcareFilter}
                    drugDataTabHistory={props.drugDataTabHistory}
                    // food
                    foodHistory={props.foodHistory}
                    loadingFood={props.loadingFood}
                    // vaccine
                    vaccineDataTabDetail={props.vaccineDataTabDetail}
                    vaccineSeriesList={props.vaccineSeriesList}
                  />
                ) : (
                  <PatientDashBoard
                    // function
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // data
                    loadingStatus={props.loadingStatus}
                    loadingPatientSegment={props.loadingPatientSegment}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    patientSegmentList={props.patientSegmentList}
                    selectedPatient={props.selectedPatient}
                    selectedDivision={props.selectedDivision}
                    leftMenuSelected={props.leftMenuSelected}
                    dmSelectedTab={props.dmSelectedTab}
                    onSearch={props.onSearch}
                    // config
                    leftMenu={DM_LEFT_MENU}
                    // WebPatientPanel
                    scoreSummary={props.scoreSummary}
                    labSummary={props.labSummary}
                    profileHealth={props.profileHealth}
                    hidePersonalScoreChart={false}
                    hidePersonalLabChart={false}
                    hideMomOverall={true}
                    hideChildOverall={true}
                  />
                )}
              </div>
            ),
          },
          {
            name: (
              <MenuIcon
                label="Clinical Content"
                icon="/images/expansion/youtube.png"
                underline={true}
              />
            ),
            key: "Content",
            render: () => (
              <ClinicContent
                onEvent={props.onEvent}
                setProp={props.setProp}
                setPropSeparate={props.setPropSeparate}
                clinicContent={props.clinicContent}
                clinicContentIndex={props.clinicContentIndex}
                clinicalSegment={props.clinicalSegment}
                divisionPatient={props.divisionPatient}
                divisionHasUser={props.divisionHasUser}
                selectedDivisionId={props.selectedDivisionId}
                selectedDivision={props.selectedDivision}
                clinic={props.clinic}
              />
            ),
          },
          {
            name: (
              <MenuIcon
                label="Segmentation"
                icon="/images/expansion/carbon-user-activity.png"
              />
            ),
            key: "segmentation",
            render: () => (
              <div style={{ height: "100%" }}>
                <MarketingSegment
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // data
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  loadingStatus={props.loadingStatus}
                  loadingMarketingSegment={props.loadingMarketingSegment}
                  loadingMarketingPatient={props.loadingMarketingPatient}
                  selectedDivision={props.selectedDivision}
                  filterMarketingPatient={props.filterMarketingPatient}
                  marketingSegmentList={props.marketingSegmentList}
                  marketingPatientList={props.marketingPatientList}
                  marketingSegmentAgeGender={props.marketingSegmentAgeGender}
                  marketingSegmentTopPost={props.marketingSegmentTopPost}
                  marketingSegmentTopPromotion={
                    props.marketingSegmentTopPromotion
                  }
                  marketingCreatePatient={props.marketingCreatePatient}
                  showSegment={props.showSegment}
                />
              </div>
            ),
          },

          // {
          //   name: (
          //     <MenuIcon
          //       label="Post"
          //       icon="/images/expansion/carbon-user-activity.png"
          //     />
          //   ),
          //   key: "post",
          //   render: () => (
          //     <Post
          //       // function
          //       onEvent={props.onEvent}
          //       setProp={props.setProp}
          //       // data
          //       tagList={props.tagList}
          //       postList={props.postList}
          //       selectedPostIndex={props.selectedPostIndex}
          //     />
          //   ),
          // },
          {
            name: (
              <MenuIcon
                label="Promotion"
                icon="/images/expansion/fontisto_shopping-sale.png"
              />
            ),
            key: "promotion",
            render: () => (
              <div>
                <PromotionEX
                  onEvent={props.onEvent}
                  marketingPromotionList={props.marketingPromotionList}
                  marketingPromotionIndex={props.marketingPromotionIndex}
                  clinicalSegment={props.clinicalSegment}
                  loadingMainPromotion={props.loadingMainPromotion}
                  marketingSegmentList={props.marketingSegmentList}
                  marketingPromotionId={props.marketingPromotionId}
                  divisionHasUser={props.divisionHasUser}
                  marketingPromotionChart={props.marketingPromotionChart}
                  selectedDivisionId={props.selectedDivisionId}
                />
                {/* <FormattedMessage id="bplusClinicKey401" /> */}
              </div>
            ),
          },
          {
            name: (
              <MenuIcon label="Post" icon="/images/expansion/mdi_post.png" />
            ),
            key: "post_ex",
            render: () => (
              <PostEx
                // function
                onEvent={props.onEvent}
                marketingPostList={props.marketingPostList}
                marketingPostIndex={props.marketingPostIndex}
                clinicalSegment={props.clinicalSegment}
                loadingMainPost={props.loadingMainPost}
                marketingSegmentList={props.marketingSegmentList}
                marketingPostId={props.marketingPostId}
                divisionHasUser={props.divisionHasUser}
                marketingPostChart={props.marketingPostChart}
                selectedDivisionId={props.selectedDivisionId}
              />
            ),
          },
          {
            name: (
              <MenuIcon
                label="User Management"
                icon="/images/expansion/user-plus.png"
              />
            ),
            key: "User Management",
            render: () => (
              <UserManagementDM
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // data
                loadingStatus={props.loadingStatus}
                loadingPatientSelection={props.loadingPatientSelection}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                patientSegmentList={props.patientSegmentList}
                selectedDivision={props.selectedDivision}
                selectedPatient={props.selectedPatient}
                clinic={props.clinic}
                dmSelectedTab={props.dmSelectedTab}
                // nutrition
                loadingStatusAditionalMenu={props.loadingStatusAditionalMenu}
                nutritionAditionalMenuList={props.nutritionAditionalMenuList}
                selectedNutritionAditionalMenu={
                  props.selectedNutritionAditionalMenu
                }
                nutritionNutritionistMenuList={
                  props.nutritionNutritionistMenuList
                }
                selectedNutritionNutritionistMenu={
                  props.selectedNutritionNutritionistMenu
                }
                nutritionistUMFilter={props.nutritionistUMFilter}
                nutritionUMFilter={props.nutritionUMFilter}
                consumptionUnitList={props.consumptionUnitList}
                foodTypeList={props.foodTypeList}
                openModNutrition={props.openModNutrition}
                patientSearch={props.patientSearch}
                // Usermanager Patient Selection
                filterKeyword={props.filterKeyword}
                dmMomSegmentList={props.dmMomSegmentList}
                dmMomPatientList={props.dmMomPatientList}
                vaccineUMDetail={props.vaccineUMDetail}
                vaccineUMList={props.vaccineUMList}
                orderItemVaccineUM={props.orderItemVaccineUM}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

MainDM.defaultProps = MainDMInitial;

export default React.memo(MainDM);
