import axios from "axios";
import { WasmHandler } from "react-lib/frameworks/WasmController";
import config from "../../config/config";
import PatientSegmentList from "issara-sdk/apis/PatientSegmentList_apps_PHR";
import { FlightTakeoffSharp } from "@material-ui/icons";
import moment from "moment";
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";

const FAST_API = `${config.FAST_API}`;

export type ContentData = {
  id?: string;
  name: string;
  link: string;
  title: string;
  image: string;
  description: string;
  active: boolean;
  effect_date: moment.Moment | null;
};

export type State = {
  clinic?: string;
  clinicContent?: any[];
  clinicContentIndex?: number;
  clinicalSegment?: any[];
  // Division to operate on (user can has many "DivisionHasUser")
  divisionHasUser?: any[];
  organizationList?: any[];
  groupedOrganizationList?: any[];
  selectedOrganizationCode?: string;
  selectedOrganizationId?: string;
  medProgramList?: any[];
  subspecialtiesList?: any[];
  servicesOfHospitalFB?: any[];
  servicesOfHospitalDJ?: any[];
  preferredLoading?: any;
  hospitalDivision?: any[];
  selectedDivisionId?: number | null;
  divisionPatient?: any[];
};

export const StateInitial = {
  clinicContent: [],
  clinicContentIndex: null,
  clinicalSegment: [],
  organizationList: [],
  groupedOrganizationList: [],
  selectedOrganizationCode: "",
  selectedOrganizationId: "",
  servicesOfHospitalFB: [],
  servicesOfHospitalDJ: [],
  medProgramList: [],
  subspecialtiesList: [],
  preferredLoading: {},
  divisionHasUser: [],
  hospitalDivision: [],
  selectedDivisionId: null,
  divisionPatient: [],
};

export type Event =
  | { message: "GetClinicContent"; params: { clinic: string } }
  | { message: "SaveClinicContent"; params: { content: any; callback: any } }
  | { message: "DeleteClinicContent"; params: { content: any; callback: any } }
  | { message: "NewClinicContent"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State, Event, Data>;

export const GetClinicalSegment: Handler = async (controller, params) => {
  console.log(" GetClinicalSegment", params)
  try {
    const result1 = await axios.get(
      `${FAST_API}/api/segment/get-clinical-segment/`,
      {
        params: {
          segment_group: params.clinic,
        },
        headers: {
          Authorization: `Token ${controller.apiToken}`,
        },
      }
    );
    controller.setState({
      clinicalSegment: result1.data.items,
    });
  } catch (e) {
    console.log("Error getting clinical segments from fastapi");
    console.log((e as Error).toString());
  }
}

export const GetDivisionHasUser: Handler = async (controller, params) => {
  console.log(" GetDivisionHasUser collection and fast api")
  // let { clinic, selectedDivisionId } = controller.getState();

  // let segmentGroupList: any[] = []
  // if (clinic === "dm") {
  //   segmentGroupList = ["dm"]
  // } else if (clinic = "mom") {
  //   segmentGroupList = ["mom", "child"]
  // }

  // let clinicDivision: any[] = []
  // let divisionHasUser: any[] = []

  // try {
  //   for (const segment of segmentGroupList) {
  //     const clinicDiv = await controller.db
  //       .collection("ClinicDivision")
  //       .doc(segment)
  //       .get();
  //     clinicDivision.push({ segment, divisions: clinicDiv.data()?.divisions })
  //   }

  //   const result = await axios.get(
  //     `${FAST_API}/api/login/get-division-has-user`,
  //     {
  //       headers: {
  //         Authorization: `Token ${controller.apiToken}`,
  //       },
  //     }
  //   );

  //   divisionHasUser = result.data.map((item: any) => {
  //     // console.log(" GetClinicContent item:", item)
  //     // console.log(" GetClinicContent sumOfDivision:", sumOfDivision)
  //     let element = clinicDivision.find(clincDiv => clincDiv.divisions.includes(item.division_id))
  //     if (element) {
  //       return { ...item, segment: element.segment }
  //     } else {
  //       return { ...item }
  //     }
  //   }).filter((item: any) => item.segment)

  //   controller.setState({
  //     divisionHasUser: divisionHasUser,
  //     selectedDivisionId: divisionHasUser.length > 0 ? divisionHasUser[0].division_id : null
  //   });

  //   if (!selectedDivisionId) {
  //     selectedDivisionId = divisionHasUser.length > 0 ? divisionHasUser[0].division_id : null
  //   }
  // } catch (e) {
  //   console.log("Error getting division has user");
  //   console.log((e as Error).toString());
  // }

  // return { selectedDivisionId, divisionHasUser }
}

export const GetClinicContent: Handler = async (controller, params) => {

  console.log("GetClinicContent params: ", params)
  let { selectedDivisionId } = controller.getState();

  let divisionHasUser: any[] = []

  // const { selectedDivisionId: divisionId } = await GetDivisionHasUser(controller, params)

  // if (divisionId) {
  //   selectedDivisionId = divisionId
  // }

  try {

    const querySnapshot = await getDocs(collection(controller.db, "ClinicContentDivision"));
    
    // const result = await controller.db
    //   .collection("ClinicContentDivision")
    //   .get();

    if (querySnapshot?.docs.length > 0) {
      controller.setState({
        clinicContent: querySnapshot?.docs
          .map((doc: any) => ({ id: doc.id, ...doc.data() }))
          .map((item: any) => ({
            ...item,
            effect_date: item.effect_date ? item.effect_date?.toDate() : null,
          })),
        clinicContentIndex: 0,
      });
    }
  } catch (e) {
    console.log("Error getting clinic content from firebase");
    console.log((e as Error).toString());
  }

  console.log("GetClinicalSegment")
  await GetClinicalSegment(controller, params)

  let segment = divisionHasUser.find((item: any) => item.division_id === selectedDivisionId)?.segment
  if (selectedDivisionId && segment) {
    try {
      const result = await axios.get(
        `${FAST_API}/api/segment/division-patient/`,
        {
          params: {
            division_id: selectedDivisionId,
            segment_group: segment || "dm",
          },
          headers: {
            Authorization: `Token ${controller.apiToken}`,
          },
        }
      );
      console.log(result.data);
      controller.setState({
        divisionPatient: result.data,
      });
    } catch (e) {
      console.log("Error getting division patients");
      console.log((e as Error).toString());
    }
  }
};

export const DeleteClinicContent: Handler = async (controller, params) => {

  var content: any = {}
  if (params.id) {
    content = controller.getState().clinicContent?.find((item: any) => item.id === params.id)
  } else {
    content = controller.getState().clinicContent?.[params.index];
  }

  let { id } = content;
  if (id) {

    await deleteDoc(doc(controller.db, "ClinicContentDivision", id));
    console.log("Document successfully deleted!");
    if (params.callback) {
      GetClinicContent(controller, { params: { clinic: params.clinic } })
      params.callback();
    }

    // await controller.db
    //   .collection("ClinicContentDivision")
    //   .doc(id)
    //   .delete()
    //   .then(() => {
    //     console.log("Document successfully deleted!");
    //     if (params.callback) {
    //       GetClinicContent(controller, { params: { clinic: params.clinic } })
    //       params.callback();
    //     }
    //   })
    //   .catch((error) => {
    //     if (params.callback) {
    //       params.callback();
    //     }
    //     console.error("Error removing document: ", error);
    //   });
  } else {
    /// delete new Content 

    let clinicContentList = controller.getState().clinicContent
    clinicContentList?.splice(params.index, 1);
    controller.setState({
      clinicContent: [...(clinicContentList || [])],
      clinicContentIndex: 0,
    });
  }
};

export const GetContentGenPreview:Handler=async(controller, params)=>{
  return  axios.get(`${FAST_API}/api/content/gen-preview/`, {
    params: { url: params.link },
    headers: {
      Authorization: `Token ${controller.apiToken}`,
    },
  });
}

export const SaveClinicContent: Handler = async (controller, params) => {
  try {
    var content: any = {}
    if (params.id) {
      content = controller.getState().clinicContent?.find((item: any) => item.id === params.id)
    } else {
      content = controller.getState().clinicContent?.[params.index];
    }

    if (params.changeActive) {
      content.active = !content.active
    }

    let { id, ...data } = content;
    if (content.link) {
      const result = await GetContentGenPreview(controller, { link: content.link })
      console.log(result.data);
      if (result.data.clinicContentId) {
        data.clinicContentId = result.data.clinicContentId;
        await setDoc(doc(controller.db, "ClinicContent", data.clinicContentId), {
          ur: content.link 
        });

        // controller.db
        //   .collection("ClinicContent")
        //   .doc(data.clinicContentId)
        //   .set({ ur: content.link });
      }

      for (const item of result.data.items) {
        if (item.tag === "title") {
          console.log(item);
          if (data.title === "") {
            data.title = item.data;
          }
        } else if (item.tag === "img") {
          console.log(item);
          if (data.image === "") {
            data.image = item.data;
          }
        } else if (item.tag === "meta") {
          console.log(item);
          if (item.property === "og:title") {
            data.title = item.content;
          } else if (item.property === "og:image") {
            data.image = item.content;
          } else if (item.property === "og:description") {
            data.description = item.content;
          }
        }
      }
    }

    // console.log("SaveClinicContent data", data)
    // console.log('SaveClinicContent id', id)
    // console.log("SaveClinicContent controller.getState().selectedDivisionId", controller.getState().selectedDivisionId);

    let newId: any
    if (id) {
      // Update 
      newId = id
      // await controller.db.collection("ClinicContentDivision").doc(id).set(data);
      await setDoc(doc(controller.db, "ClinicContentDivision", id), data);

    } else {
      // New 
      // console.log("SaveClinicContent nohave id data: ", data)
      data.divisionId = controller.getState().selectedDivisionId;
      delete data.unsaved
      // console.log("new data.divisionId", data.divisionId);

      const docRef = await addDoc(collection(controller.db, "ClinicContentDivision"), data);
      newId = docRef?.id

      // newId = (
      //   await controller.db.collection("ClinicContentDivision").add(data)
      // ).id;
      // console.log("SaveClinicContent id", id);
    }

    let clinicContent = Object.assign(
      [] as ContentData[],
      controller.getState().clinicContent || []
    );

    let targetId = id ? newId : undefined
    let editId = clinicContent.findIndex((item: any) => item.id === targetId)
    console.log('clinicContent: ', clinicContent, editId, newId, data);

    clinicContent[editId] = { id: newId, ...data };
    if (params.callback) {
      params.callback();
    }
    controller.setState({
      clinicContent: [...clinicContent],
    });
  } catch (e) {
    console.error("some error has occured");
    console.log((e as Error).toString());
  }
};

export const NewClinicContent: Handler = async (controller, params) => {
  let clinicContent = controller.getState().clinicContent || [];
  clinicContent = [
    {
      name: "คอนเทนท์ใหม่",
      link: "",
      title: "",
      image: "",
      description: "",
      active: false,
      effect_date: null,
      unsaved: true,
    },
  ].concat(clinicContent);
  controller.setState({
    clinicContent: [...clinicContent],
    clinicContentIndex: 0,
  });
};
