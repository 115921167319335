import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
} from "../MobClinicInterface";
import {
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  State as MainState,
} from "../MobClinicInterface";

export type State = {
  bpFilterDate?: moment.Moment;
  bpFilterDuration?: string;
  bpRecordList?: any;
  bpSettingData?: any;
  bpChartList?: any;
  bgBarhba1c?: any;
  bgBarfpg?: any;
  setSys?: string;
  setDia?: string;
  setPulse?: string;
  inputBpDetail?: {
    systolic?: string | number;
    diastolic?: string | number;
    pulse?: string | number;
    note?: string;
  };
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "successMessage"
>;

export const StateInitial: State = {
  bpFilterDate: moment(),
  bpFilterDuration: "6",
  bpRecordList: {},
  bpSettingData: {},
  bpChartList: {},
  inputBpDetail: {
    systolic: "",
    diastolic: "",
    pulse: "",
    note: ""
  }
};

export type Event =
  // GET
  | { message: "GetBloodPressureRecordByStart" }
  | { message: "GetBloodPressureSetting" }
  | {
    message: "GetBloodPressureChart";
    params: { start_measure: string; end_measure: string };
  }
  // CHANGE
  | { message: "handleChangeBpFilterDate"; params: { date: moment.Moment } }
  | {
    message: "handleChangeBpFilterDuration";
    params: { duration: string };
  }
  | {
    message: "HandleSetInputBpDetail";
    params: State["inputBpDetail"]
  }
  // SAVE
  | {
    message: "SaveTodayBloodPressureRecord";
    params: { measure_date: string; data: any };
  }
  | { message: "SaveBloodPressureSetting"; params: {} }
  // DELETE
  | { message: "RemoveBloodPressureRecord"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const GetBloodPressureRecordByStart: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const tmpParams: any = {
    group: GROUP.BLOOD_PRESSURE,
    data_type: DATA_TYPE.BLOOD_PRESSURE,
    start_measure: state.bpFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bpRecordList: response,
  });
};

export const GetBloodPressureSetting: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.BLOOD_PRESSURE,
    data_type: DATA_TYPE.SETTING,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bpSettingData: response,
  });
};

export const GetBloodPressureChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.BLOOD_PRESSURE,
    data_type: DATA_TYPE.BLOOD_PRESSURE,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bpChartList: response,
  });
};

// CHANGE
export const handleChangeBpFilterDate: Handler = (controller, params) => {
  controller.setState({ 
    bpFilterDate: moment(params.date, "DD/MM/YYYY") ,
    inputBpDetail: {
      systolic: "",
      diastolic: "",
      pulse: "",
      note: ""
    }
  });
};

export const handleChangeBpFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ bpFilterDuration: params.duration });
};

export const HandleSetInputBpDetail: Handler = (controller, params) => {
  const { inputBpDetail } = controller.getState();

  controller.setState({
    inputBpDetail: {
      ...inputBpDetail,
      ...params,
    },
    setSys: "",
    setDia: "",
    setPulse: "",
  });
};

// SAVE
export const SaveTodayBloodPressureRecord: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const data: any = {
    group: GROUP.BLOOD_PRESSURE,
    data_type: DATA_TYPE.BLOOD_PRESSURE,
    measure_date: params.measure_date,
    data: { ...params.data },
  };
  const [error, response] = await commonCreatePatientData(
    controller as any,
    data
  );
  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add blood pressure success.";
    GetBloodPressureRecordByStart(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
    inputBpDetail: {
      systolic: "",
      diastolic: "",
      pulse: "",
      note: ""
    }
  });
};

export const SaveBloodPressureSetting: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let data = {};
  let error = {};
  let response = {};

  if (params?.id) {
    data = { ...params };
    [error, response] = await commonUpdatePatientData(controller as any, data);
  } else {
    data = {
      group: GROUP.BLOOD_PRESSURE,
      data_type: DATA_TYPE.SETTING,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...params.data,
      },
    };
    [error, response] = await commonCreatePatientData(controller as any, data);
  }

  console.log("globalThis.iosNative", globalThis.mobile);
  if (globalThis.iosNative?.setiOSLocalAlarm) {
    // IOS
    console.log("call globalThis.iosNative.setiOSLocalAlarm");
    globalThis.iosNative?.setiOSLocalAlarm(data);
  }

  if (globalThis.MobNative) {
    // Android 
    console.log("call globalThis.MobNative.setLocalAlarm")
    console.log(data)
    globalThis.MobNative.setLocalAlarm(JSON.stringify(data))
  }

  console.log("SaveBloodPressureSetting data", data);
  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save settings success.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

// DELETE
export const RemoveBloodPressureRecord: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete blood pressure success.";
    }

    GetBloodPressureRecordByStart(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
