import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Dropdown, Form, Grid, Header, Icon } from "semantic-ui-react";
import { Radar } from "react-chartjs-2";

import { BACKEND_DATE_FORMAT } from "../MobClinicInterface";
import * as DMPersonalScoreConst from "./DMPersonalScore";
import { HeaderChart } from "./DMEmotionRestChart";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

import "../../css/WebClinic.scss";

// ---------- DMPersonalLabChart
type DMPersonalLabChartProps = {
  onEvent: any;
  selectedPatient: any;
  labSummary: any;
  hideFilter: boolean;
};

const DMPersonalLabChartInitial: DMPersonalLabChartProps = {
  onEvent: () => null,
  selectedPatient: {},
  labSummary: {},
  hideFilter: false,
};

const DMPersonalLabChart: React.FC<DMPersonalLabChartProps> = (props) => {
  const intl = useIntl();
  const [selectedLabDuration, setSelectedLabDuration] = useState<string>("w");
  const [labStartDate, setLabStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [labEndDate, setLabEndDate] = useState<moment.Moment>(moment());
  const [labDataSummary, setLabDataSummary] = useState<any>({});
  const durationLabOptions = useMemo(
    () => [
      {
        key: 1,
        value: "w",
        text: `${intl.formatMessage({ id: "bplusClinicKey763" })}`,
      },
      {
        key: 2,
        value: "M",
        text: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      },
      {
        key: 3,
        value: "y",
        text: `${intl.formatMessage({ id: "bplusClinicKey590" })}`,
      },
    ],
    []
  );

  const options = {
    scale: {
      ticks: { beginAtZero: true },
    },
  };

  useEffect(() => {
    let labels: any[] = [];
    let datas: any[] = [];

    props.labSummary?.items?.forEach((item: any) => {
      labels.push(
        item?.label === "น้ำหนัก (WT)" ? `${intl.formatMessage({ id: "bplusClinicKey748" })}` :
        item?.label === "ความดันโลหิต (BP)" ? `${intl.formatMessage({ id: "bplusClinicKey749" })}` : item?.label
      );
      datas.push(item.score);
    });

    const datasets = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: Array(datas.length).fill(100),
          borderColor: "#4a06f0",
          borderWidth: 1,
          fill: false,
        },
        {
          label: "lab",
          data: datas,
          borderColor: "#2399E5",
          borderWidth: 1,
        },
      ],
    };
    setLabDataSummary(datasets);
  }, [props.labSummary]);

  useEffect(() => {
    props.onEvent({
      message: "handleGetLabSummary",
      params: {
        start_date: labStartDate.format(BACKEND_DATE_FORMAT),
        end_date: labEndDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.selectedPatient, labStartDate, labEndDate]);

  // Lab
  const handleChangeDurationLab = (event: any, data: any) => {
    setSelectedLabDuration(data.value);
    const start = moment().add("-1", data.value);
    if (data.value === "w") {
      start.add(1, "days");
    }
    setLabStartDate(start);
    setLabEndDate(moment());
  };

  const handleChangeLabDateRange = (event: any, data: any) => {
    setLabStartDate(data.fromDate);
    setLabEndDate(data.toDate);
  };

  return (
    <>
      {!props.hideFilter && (
        <>
          <HeaderChart
            startDate={labStartDate}
            endDate={labEndDate}
            durationOptions={durationLabOptions}
            selectedDuration={selectedLabDuration}
            onChangeDuration={handleChangeDurationLab}
            onChangeDateRange={handleChangeLabDateRange}
            // labelStyle={{ padding: "10px 30px 5px 18px" }}
          />
        </>
      )}

      <Radar height={200} data={labDataSummary} options={options} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "50px",
        }}
      >
        <Grid style={DMPersonalScoreConst.styles.gridLab}>
          <Grid.Row columns={2} style={{ fontWeight: "bold" }}>
            <Grid.Column width={9} style={{ padding: "0px 0px 0px 10px" }}>
              <Header as="h3" dividing>
                <FormattedMessage id="bplusClinicKey519" />
              </Header>
            </Grid.Column>
            <Grid.Column
              width={7}
              style={DMPersonalScoreConst.styles.noPadding}
            >
              <Header as="h3" dividing style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey468" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          {props.labSummary?.items?.map((item: any, index: number) => {
            return (
              <Grid.Row
                key={index}
                columns={2}
                style={{
                  ...DMPersonalScoreConst.styles.noPadding,
                  color: item?.color,
                }}
              >
                <Grid.Column width={9}>
                  {
                    item?.label === "น้ำหนัก (WT)" ? `${intl.formatMessage({ id: "bplusClinicKey748" })}` :
                    item?.label === "ความดันโลหิต (BP)" ? `${intl.formatMessage({ id: "bplusClinicKey749" })}` : item?.label
                  }
                </Grid.Column>
                <Grid.Column width={7}>
                  {
                    item?.status === "Underweight" ? `${intl.formatMessage({ id: "bmi.underweight" })}` :
                    item?.status === "Normal/ Healthy weight" ? `${intl.formatMessage({ id: "bplusClinicKey825" })}` :
                    item?.status === "Overweight" ? `${intl.formatMessage({ id: "bmi.overweight" })}` :
                    item?.status === "Obese" ? `${intl.formatMessage({ id: "bplusClinicKey347" })}` :

                    item?.status === "Low" ? `${intl.formatMessage({ id: "bplusClinicKey759" })}` :
                    item?.status === "Normal" ? `${intl.formatMessage({ id: "bplusClinicKey832" })}` : 
                    item?.status === "High" ? `${intl.formatMessage({ id: "bplusClinicKey758" })}` : item?.status 
                  }
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

DMPersonalLabChart.defaultProps = DMPersonalLabChartInitial;

export default React.memo(DMPersonalLabChart);
