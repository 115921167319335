import React, { useState, useMemo, useEffect } from "react";
import "./MedicationCSS.scss";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Divider, Loader, Tab, TabProps, Dimmer } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import Skeleton from "@mui/material/Skeleton";

//component
import MyMedication from "./MyMedication";
import Reminder from "./Reminder";
import CardAllergyWarning from "../history/CardAllergyWarning";
import TabMenu from "../history/TabMenu";
import MyCurrentMedication from "./MyCurrentMedication";
import CONFIG from "config/config";
import moment from "moment";

// Use styles
const COLOR = {
  primary: "var(--primary-theme-color)",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  red: "#DA0000",
  darkgray: "#9F9F9F",
  font_white: "white",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  boxtabshospital: {
    width: "90%",
    background: "#F5F5F5",
    display: "flex",
    marginBottom: "20px",
    borderRadius: "5px",
  } as CSSProperties,
  buttonhospital: {
    width: "50%",
    borderRadius: "5px",
    background: "#F5F5F5",
    margin: "0px",
    padding: "7px",
  } as CSSProperties,
  message: {
    paddingLeft: "1.75rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2 /* number of lines to show */,
    lineClamp: 2,
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
};

type MainMedicationProps = {
  onEvent: (e: any) => any;
  medicationItem: any;
  hospitalList?: any;
  loadingMedication?: boolean;
  selectedHospital: any;
  loadingHospital?: boolean;
  firstLoadingAllergy?: boolean;
  getReminderData: any;
  medAllergyList?: any[];
  history: any;
  language?: "en-US" | "th-TH";
  currentMedication?: any[];
  loadingUpdateCurrent: boolean;
  isCurrentMed: boolean;
};

const MainMedication: React.FunctionComponent<MainMedicationProps> = (
  props
) => {
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  const [preferred, setPreferred] = useState<string>("Preferred");

  useEffect(() => {
    moment.locale(
      navigator.languages[0].toLowerCase().includes("th") ? "th" : "en"
    );
    const hospital = props.selectedHospital;

    if (hospital) {
      props.onEvent({
        message: "handleGetMedication",
        params: { hospital: hospital.code },
      });
      if (CONFIG.ENABLE_ALLERGY) {
          props.onEvent({
            message: "GetMedAllergy",
            params: { hospital: hospital.code, name: hospital.name },
          });
      }
    }
  }, [props.selectedHospital]);

  const panes = useMemo(
    () => [
      {
        menuItem: "My Medication",
      },
      {
        menuItem: "Reminder",
      },
    ],
    []
  );

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleChangePreferred = (preferred: string) => {
    setPreferred(preferred);
    if (preferred === "All") {
      props.onEvent({
        message: "handleGetMedication",
        params: { hospital: "all" },
      });
    } else {
      props.onEvent({
        message: "handleGetMedication",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  };

  return (
    <div style={{ ...styles.parentbox }}>
      <Dimmer
        inverted
        active={props.loadingUpdateCurrent}
        style={{ height: "100%", position: "fixed", zIndex: 3000 }}
      >
        <Loader>Loading</Loader>
      </Dimmer>
      <div style={{ margin: " 20px 0px" }}>
        <div style={styles.title}>
          <FormattedMessage id={"bplusClinicKey929"} />
        </div>
        <Divider
          style={{
            background: "red",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          variant="middle"
        />
      </div>

      {CONFIG.ENABLE_ALLERGY && (
        <>
          {(!props.firstLoadingAllergy) ? (
            <div
              style={{ position: "relative", width: "100%", padding: "0 15px" }}
            >
              <Skeleton
                animation="pulse"
                height={"7.5rem"}
                width={"100%"}
                style={{
                  margin: "-20px 0 0",
                  backgroundColor: "rgba(0, 0, 0, 0.035)",
                }}
              />
            </div>
          ) : (
            <CardAllergyWarning
              allergyList={props.medAllergyList}
              history={props.history}
              // config
              detailField={["detail", "name_generic"]}
              href={`/Allergy-card/?app=MobMedication`}
            />
          )}
        </>
      )}

      <TabMenu
        tab={preferred}
        leftTitle="Preferred"
        rightTitle="All"
        // config
        paddingStyle="0 15px"
        marginStyle="0 0 15px"
        rightDisabled={props.loadingMedication}
        leftDisabled={props.loadingMedication}
        // callback
        onChange={handleChangePreferred}
      />

      <div style={{ width: "100%", maxHeight: "100vh" }}>
        <Tab
          className="medication-tab-custom"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={viewIndex}
          onTabChange={handleTabChange}
        />

        <SwipeableViews
          index={+viewIndex}
          onChangeIndex={handleChangeIndex}
          animateHeight={viewIndex === 1 ? true : false}
        >
          <div
            style={{ paddingBottom: "55px", minHeight: "calc(100vh - 320px)" }}
          >
            {props.isCurrentMed ? (
              <MyCurrentMedication
                onEvent={props.onEvent}
                medicationItem={props.medicationItem || []}
                loadingMedication={false}
                medicationAll={preferred}
                selectedHospital={props.selectedHospital}
                hospitalList={props.hospitalList}
                currentMedication={props.currentMedication}
              />
            ) : (
              <MyMedication
                onEvent={props.onEvent}
                medicationItem={props.medicationItem || []}
                loadingMedication={false}
                medicationAll={preferred}
                selectedHospital={props.selectedHospital}
                hospitalList={props.hospitalList}
              />
            )}
          </div>
          <div style={{ paddingBottom: "55px" }}>
            <Reminder
              onEvent={props.onEvent}
              getReminderData={props.getReminderData}
              language={props.language}
            />
          </div>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default React.memo(MainMedication);

const medicationItem = [
  {
    name: "Augmentin 1 g Tablet",
    medID: "A1G",
    image: "https://bhqmdw.bdms.co.th/TEST/HMSPIC/picture/MTBAUGM1000O.jpg",
    dose: "",
    count: "x 1 เม็ด",
    dosage: "",
    dosage_image: "",
    unit: "เม็ด",
    unit_image: "",
    route_image:
      "https://penguin-staging.sgp1.cdn.digitaloceanspaces.com/media/Tablet.png",
    hospital_name: "",
    extra: {
      dispense_type: "HOSP",
    },
    encounter_number: "O01-22-0001053",
    is_save_drug: true,
    order_date: "2022-11-30",
    note: {
      medication:
        "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง หลังอาหาร เช้า เย็น  ",
      side_effect: "",
      remark_en: "",
      label:
        "(ทานยาติดต่อกันทุกวันจนยาหมด),(ถ้ายังไม่ทาน-อย่าแกะเม็ดยาออกจากแผง)ยาฆ่าเชื้อ",
    },
    duplicate: true,
  },
  {
    name: "Paracetamol 500 mg (Bakamol) Tablet $$ //",
    medID: "PA500",
    image: "https://bhqmdw.bdms.co.th/TEST/HMSPIC/picture/MTBAUGM1000O.jpg",
    dose: "",
    count: "x 1 เม็ด",
    dosage: "",
    dosage_image: "",
    unit: "เม็ด",
    unit_image: "",
    route_image:
      "https://penguin-staging.sgp1.cdn.digitaloceanspaces.com/media/Tablet.png",
    hospital_name: "",
    extra: {
      dispense_type: "HOSP",
    },
    encounter_number: "O01-22-0001053",
    is_save_drug: true,
    order_date: "2022-11-30",
    note: {
      medication:
        "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง หลังอาหาร เช้า เย็น  ",
      side_effect: "",
      remark_en: "",
      label:
        "(ทานยาติดต่อกันทุกวันจนยาหมด),(ถ้ายังไม่ทาน-อย่าแกะเม็ดยาออกจากแผง)ยาฆ่าเชื้อ",
    },
    duplicate: false,
  },
  {
    name: "Augmentin 1 g Tablet",
    medID: "A1G",
    image: "https://bhqmdw.bdms.co.th/TEST/HMSPIC/picture/MTBAUGM1000O.jpg",
    dose: "",
    count: "x 1 เม็ด",
    dosage: "",
    dosage_image: "",
    unit: "เม็ด",
    unit_image: "",
    route_image:
      "https://penguin-staging.sgp1.cdn.digitaloceanspaces.com/media/Tablet.png",
    hospital_name: "",
    extra: {
      dispense_type: "HOSP",
    },
    encounter_number: "O01-22-0001053",
    is_save_drug: true,
    order_date: "2022-11-30",
    note: {
      medication:
        "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง หลังอาหาร เช้า เย็น  ",
      side_effect: "",
      remark_en: "",
      label:
        "(ทานยาติดต่อกันทุกวันจนยาหมด),(ถ้ายังไม่ทาน-อย่าแกะเม็ดยาออกจากแผง)ยาฆ่าเชื้อ",
    },
    duplicate: false,
  },
  {
    name: "Paracetamol 500 mg (Bakamol) Tablet $$ //",
    medID: "PA500",
    image: "https://bhqmdw.bdms.co.th/TEST/HMSPIC/picture/MTBAUGM1000O.jpg",
    dose: "",
    count: "x 1 เม็ด",
    dosage: "",
    dosage_image: "",
    unit: "เม็ด",
    unit_image: "",
    route_image:
      "https://penguin-staging.sgp1.cdn.digitaloceanspaces.com/media/Tablet.png",
    hospital_name: "",
    extra: {
      dispense_type: "HOSP",
    },
    encounter_number: "O01-22-0001053",
    is_save_drug: true,
    order_date: "2022-11-30",
    note: {
      medication:
        "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง หลังอาหาร เช้า เย็น  ",
      side_effect: "",
      remark_en: "",
      label:
        "(ทานยาติดต่อกันทุกวันจนยาหมด),(ถ้ายังไม่ทาน-อย่าแกะเม็ดยาออกจากแผง)ยาฆ่าเชื้อ",
    },
    duplicate: false,
  },
  {
    name: "Paracetamol 100 mg",
    medID: "PA1000",
    image: "https://bhqmdw.bdms.co.th/TEST/HMSPIC/picture/MTBAUGM1000O.jpg",
    dose: "",
    count: "x 1 เม็ด",
    dosage: "",
    dosage_image: "",
    unit: "เม็ด",
    unit_image: "",
    route_image:
      "https://penguin-staging.sgp1.cdn.digitaloceanspaces.com/media/Tablet.png",
    hospital_name: "",
    extra: {
      dispense_type: "HOSP",
    },
    encounter_number: "O01-22-0001053",
    is_save_drug: true,
    order_date: "2022-11-30",
    note: {
      medication:
        "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง หลังอาหาร เช้า เย็น  ",
      side_effect: "",
      remark_en: "",
      label:
        "(ทานยาติดต่อกันทุกวันจนยาหมด),(ถ้ายังไม่ทาน-อย่าแกะเม็ดยาออกจากแผง)ยาฆ่าเชื้อ",
    },
    duplicate: false,
  },
];
