import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import StaffDirectVitalSignView from "../../../issara-sdk/apis/StaffDirectVitalSignView_apps_PRX";

// Interface
import { State as MainState } from "../../ManageInterface"

export type State = {
  hospitalWeightList?: any;
}

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export const StateInitial: State = {
  hospitalWeightList: {},
};

export type Event =
  // GET
  {
    message: "GetHospitalWeightChart";
    params: { start_measure: string; end_measure: string };
  };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

// GET
export const GetHospitalWeightChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const [response, error, network] = await StaffDirectVitalSignView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      ...params, // start_date, end_date
    },
  });

  let result: any[] = [];
  if (error) {
  } else {
    response?.vital_sign_values?.forEach((item: any) => {
      if (item.code === "BMI") {
        result = item.values;
      }
    });
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    hospitalWeightList: result,
  });
};
