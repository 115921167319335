import WasmController from "react-lib/frameworks/WasmController";
// Interface
import * as ManageI from "./ManageInterface";
import * as PatientDetailI from "./manage/PatientDetailInterface";
import * as FirebaseI from "react-lib/frameworks/FirebaseInterface";
import * as Python from "react-lib/frameworks/Python";
import * as PostI from "./post/PostI";
import * as LoginI from "./login/LoginInterface";
import * as TagI from "react-lib/frameworks/TagI";
// DM Interface
import * as DMEmotionRestI from "./clinic/DMEmotionRestInterface";
import * as DMExerciseI from "./clinic/DMExerciseInterface";
import * as DMFoodI from "./clinic/DMFoodInterface";
import * as DMDrugI from "./clinic/DMDrugInterface";
import * as DMPersonalScoreI from "./clinic/DMPersonalScoreInterface";
import * as DMWeightI from "./clinic/DMWeightInterface";
import * as DMVaccineI from "./clinic/DMVaccineInterface";

// Web DM interface
import * as WebDMWeightI from "./manage/detail/WebDMWeightInterface";
import * as WebDMBloodPressureI from "./manage/detail/WebDMBloodPressureInterface";
import * as WebDMGlucoseI from "./manage/detail/WebDMGlucoseInterface";
import * as WebDMFootI from "./manage/detail/WebDMFootDetailInterface";
import * as WebDMMedicationI from "./manage/detail/WebDMMedicationInterface";
import * as WebDMVaccineI from "./manage/detail/WebDMVaccineInterface";

import * as MarketingSegmentI from "./post/MarketingSegmentInterface";

// User Management Interface
import * as WebDMUMNutritionI from "./manage/detail/WebDMUMNutritionInterface";
import * as WebUMBabyRelationshipI from "./manage/detail/WebUMBabyRelationshipInterface";
import * as WebUMPatientSelectionI from "./manage/detail/WebUMPatientSelectionInterface";
import * as WebUMVaccineI from "./manage/detail/WebUMVaccineInterface";
import * as WebUMDevelopmentI from "./manage/detail/WebUMDevelopmentInterface";

// MOM Interface
import * as MomProfileHealthI from "./clinic/MomProfileHealthInterface";
import * as MomKickCountI from "./clinic/MomKickCountInterface";
import * as MomContractionCountI from "./clinic/MomContractionCountInterface";
import * as WebMomObstreticRecordI from "./manage/detail/WebMomObstreticRecordInterface";
import * as MomWeightGainI from "./clinic/MomWeightGainInterface";
import * as WebMomUltrasoundI from "./manage/detail/WebMomUltrasoundInterface";
import * as WebMomBreastFeedingI from "./manage/detail/WebMomBreastFeedingInterface";
import * as WebMomPrenatalAndANCRecordInterfaceI from "./manage/detail/WebMomPrenatalAndANCRecordInterface";

// Child Interface
import * as ChildOverallI from "./clinic/ChildOverallInterface";
import * as ChildDevelopmentI from "./clinic/ChildDevelopmentInterface";
import * as WebChildOutsideMedicineI from "./manage/detail/WebChildOutsideMedicineInterface";
import * as WebChildDevelopmentI from "./manage/detail/WebChildDevelopmentInterface";
import * as WebChildGrowthChartI from "./manage/detail/WebChildGrowthChartInterface";
import * as WebChildVaccinationI from "./manage/detail/WebChildVaccinationInterface";

// ClinicContent Interface
import * as ClinicContentI from "./manage/ClinicContentInterface";

// MarketingPromotion Interface
import * as PromotionExI from "./post/PromotionExInterface";
import * as PostExI from "./post/PostExInterface";

// Analytics Interface
import * as MarketingAnalyticsI from "./post/MarketingAnalyticsInterface";

export default class ManageController extends WasmController<
  ManageI.State,
  ManageI.Event,
  ManageI.Data
> {
  setPropSeparate: any;

  constructor(
    getState: () => ManageI.State,
    setState: (state: ManageI.State) => void,
    window: any
  ) {
    super(getState, setState, window, ManageI.DataInitial);
  }

  handleEvent = async (e: ManageI.Event): Promise<any> => {
    console.log(e);
    switch (e.message) {
      // Login logout
      case "DidMount":
        ManageI.DidMount(this, e.params);
        return;

      case "LoggedIn":
        ManageI.LoggedIn(this, e.params);
        return;

      case "LoginIssara":
        LoginI.LoginIssara(this, e.params);
        return;

      case "FirebaseSetupStateChangeHandler":
        FirebaseI.SetupStateChangeHandler(this, e.params);
        return;

      case "FirebaseCustomLoginWithUsername":
        FirebaseI.CustomLoginWithUsername(this, e.params);
        return;

      case "Logout":
      case "FirebaseLogout":
        LoginI.Logout(this, e.params);
        return;

      // ----- DM
      // Manage
      case "handleClearMessage":
        ManageI.handleClearMessage(this);
        return;
      case "handleGetPatientSegmentList":
        ManageI.handleGetPatientSegmentList(this, e.params);
        return;
      case "handleSelectedPatient":
        ManageI.handleSelectedPatient(this, e.params);
        return;

      // Get PatientInfo
      case "GetPatientClinicalList":
        ManageI.GetPatientClinicalList(this, e.params);
        return;
      case "SetPatientIndex":
        ManageI.SetPatientIndex(this, e.params);
        return;
      case "handleSearchPatient":
        return ManageI.handleSearchPatient(this, e.params);
      case "handleSelectPatientSearch":
        return ManageI.handleSelectPatientSearch(this, e.params);
      case "handleSelectedDivisionId":
        return ManageI.handleSelectedDivisionId(this, e.params);

      // Preferred
      case "PreferedDidMount":
        return ManageI.PreferedDidMount(this, e.params);
      case "handleSelectedOrganization":
        return ManageI.handleSelectedOrganization(this, e.params);
      case "GetServiceFromFirestore":
        return ManageI.GetServiceFromFirestore(this, e.params);
      case "DeleteIconFromFirebase":
        return ManageI.DeleteIconFromFirebase(this, e.params);
      case "SaveServiceItemToFirebase":
        return ManageI.SaveServiceItemToFirebase(this, e.params);
      case "addNewServiceToFirebase":
        return ManageI.addNewServiceToFirebase(this, e.params);
      case "deleteServiceToFirebase":
        return ManageI.deleteServiceToFirebase(this, e.params);

      case "GetServicePreferred":
        return ManageI.GetServicePreferred(this, e.params);
      case "SaveServicePreferred":
        return ManageI.SaveServicePreferred(this, e.params);
      case "DeleteServicePreferredIcon":
        return ManageI.DeleteServicePreferredIcon(this, e.params);
      case "AddNewServicePreferred":
        return ManageI.AddNewServicePreferred(this, e.params);
      case "DeleteServicePreferred":
        return ManageI.DeleteServicePreferred(this, e.params);

  

      // PatientDetailI
      case "handleGetDMTabWeight":
        PatientDetailI.handleGetDMTabWeight(this, e.params);
        return;
      case "handleAddMedicationCurrent":
        PatientDetailI.handleAddMedicationCurrent(this, e.params);
        return;
      case "handleDeleteMedicationCurrent":
        PatientDetailI.handleDeleteMedicationCurrent(this, e.params);
        return;

      // DMPersonalScore
      case "handleGetLatestWeight":
        DMPersonalScoreI.handleGetLatestWeight(this);
        return;
      case "handleGetScoreSummary":
        DMPersonalScoreI.handleGetScoreSummary(this, e.params);
        return;
      case "handleGetLabSummary":
        ManageI.handleGetLabSummary(this, e.params);
        return;

      // DMEmotionRest
      case "handleGetEmotionRestHistory":
        DMEmotionRestI.handleGetEmotionRestHistory(this, e.params);
        return;

      // DMExercise
      case "GetExerciseChart":
        DMExerciseI.GetExerciseChart(this, e.params);
        return;
      case "GetExerciseSetting":
        DMExerciseI.GetExerciseSetting(this, e.params);
        return;
      // DMFood
      case "handleGetHistoryFood":
        DMFoodI.handleGetHistoryFood(this, e.params);
        return;

      // DMDrug
      case "handleGetDrugHistory":
        DMDrugI.handleGetDrugHistory(this, e.params);
        return;
      case "getAllTrackCareDrug":
        DMDrugI.getAllTrackCareDrug(this, e.params);
        return;
      case "updateTrackcareMedication":
        DMDrugI.updateTrackcareMedication(this, e.params);
        return;
      case "filterMedicationTrackCare":
        DMDrugI.filterMedicationTrackCare(this, e.params);
        return;
      case "saveMedicationTrackCare":
        DMDrugI.saveMedicationTrackCare(this, e.params);
        return;
      case "getCurrentDrugList":
        DMDrugI.getCurrentDrugList(this, e.params);
        return;

      // MomProfileHealthI
      case "handleGetProfileHealth":
        MomProfileHealthI.handleGetProfileHealth(this, e.params);
        return;
      case "handleGetProfileObstretic":
        MomProfileHealthI.handleGetProfileObstretic(this);
        return;
      case "handleSaveProfileObstretic":
        MomProfileHealthI.handleSaveProfileObstretic(this, e.params);
        return;

      // MomKickCount
      case "handleGetKickCountHistory":
        MomKickCountI.handleGetKickCountHistory(this, e.params);
        return;
      case "handleChangeKickCountFilterDuration":
        MomKickCountI.handleChangeKickCountFilterDuration(this, e.params);
        return;

      // MomContractionCount
      case "handleGetContractionCountHistory":
        MomContractionCountI.handleGetContractionCountHistory(this, e.params);
        return;
      case "handleChangeContractionCountFilterDuration":
        MomContractionCountI.handleChangeContractionCountFilterDuration(
          this,
          e.params
        );
        return;

      // Python
      case "LoadPython":
        Python.LoadPython(this, e.params);
        return;
      case "CallPython":
        Python.CallPython(this, e.params);
        return;

      // Post
      case "AddPost":
        return;
      case "DeleteSelectedPost":
        return;
      case "GetPost":
        PostI.GetPost(this, e.params);
        return;
      case "GetPreview":
        PostI.GetPreview(this, e.params);
        return;
      case "SaveSelectedPost":
        PostI.SaveSelectedPost(this, e.params);
        return;

      // Tag
      case "AddTagSet":
        TagI.AddTagSet(this, e.params);
        return;
      case "AddTagToTagSet":
        TagI.AddTagToTagSet(this, e.params);
        return;
      case "RemoveTagFromTagSet":
        TagI.RemoveTagFromTagSet(this, e.params);
        return;
      case "GetTagList":
        TagI.GetTagList(this, e.params);
        return;
      case "AddTag":
        TagI.AddTag(this, e.params);
        return;
      case "SaveTag":
        TagI.SaveTag(this, e.params);
        return;
      case "DeleteTag":
        TagI.DeleteTag(this, e.params);
        return;

      // Weight DM
      case "GetWeightChart":
        DMWeightI.GetWeightChart(this, e.params);
        return;
      case "GetHospitalWeightChart":
        WebDMWeightI.GetHospitalWeightChart(this, e.params);
        return;

      case "handleGetObstreticRecord":
        MomWeightGainI.handleGetObstreticRecord(this);
        return;
      case "handleGetPrePregnancyWeight":
        MomWeightGainI.handleGetPrePregnancyWeight(this);
        return;
      case "handleGetPregnancyWeight":
        MomWeightGainI.handleGetPregnancyWeight(this);
        return;
      case "handleGetWebPregnancyWeight":
        MomWeightGainI.handleGetWebPregnancyWeight(this, e.params);
        return;

      // BloodPressureln DM
      case "GetBloodPressureChart":
        WebDMBloodPressureI.GetBloodPressureChart(this, e.params);
        return;

      case "handleChangeBpFilterDuration":
        WebDMBloodPressureI.handleChangeBpFilterDuration(this, e.params);
        return;

      // Glucose DM
      case "GetBloodGlucoseChart":
        WebDMGlucoseI.GetBloodGlucoseChart(this, e.params);
        return;
      case "GetBloodGlucoseBar":
        WebDMGlucoseI.GetBloodGlucoseBar(this, e.params);
        return;
      case "GetBloodGlucose":
        WebDMGlucoseI.GetBloodGlucose(this, e.params);
        return;
      case "GetBloodGlucoseByLabCode":
        WebDMGlucoseI.GetBloodGlucoseByLabCode(this, e.params);
        return;

      // FootI DM
      case "handleGetFootHistoryByDuration":
        WebDMFootI.handleGetFootHistoryByDuration(this, e.params);
        return;
      case "handleGetFootDetailByDate":
        WebDMFootI.handleGetFootDetailByDate(this, e.params);
        return;

      // WebDMMedicationI
      case "handleGetMedicationDivisionOptions":
        WebDMMedicationI.handleGetMedicationDivisionOptions(this, e.params);
        return;
      case "handleGetMedicationDoctorOptions":
        WebDMMedicationI.handleGetMedicationDoctorOptions(this, e.params);
        return;

      // WebDM Vaccine
      case "HandleSaveVaccineDetail":
        WebDMVaccineI.HandleSaveVaccineDetail(this, e.params);
        return;
      case "handleGetVaccineSummary":
        DMVaccineI.handleGetVaccineSummary(this, e.params);
        return;

      // Marketing Segment DashBoard
      case "handleGetMarketingSegmentList":
        MarketingSegmentI.handleGetMarketingSegmentList(this);
        return;
      case "handleGetMarketingPatientList":
        MarketingSegmentI.handleGetMarketingPatientList(this);
        return;
      case "handleGetMarketingSummary":
        MarketingSegmentI.handleGetMarketingSummary(this);
        return;
      case "handleGetTopPost":
        MarketingSegmentI.handleGetTopPost(this);
        return;
      case "handleGetTopPromotion":
        MarketingSegmentI.handleGetTopPromotion(this);
        return;
      case "handleSearchMarketingPatient":
        MarketingSegmentI.handleSearchMarketingPatient(this, e.params);
        return;
      case "handleSetMarketingPatientParams":
        MarketingSegmentI.handleSetMarketingPatientParams(this, e.params);
        return;
      case "handleCreateMarketingSegmentPatient":
        MarketingSegmentI.handleCreateMarketingSegmentPatient(this, e.params);
        return;
      case "handleEditMarketingSegmentName":
        MarketingSegmentI.handleEditMarketingSegmentName(this, e.params);
        return;
      case "handleDeleteMarketingSegment":
        MarketingSegmentI.handleDeleteMarketingSegment(this, e.params);
        return;
      case "handleDeleteMarketingPatient":
        MarketingSegmentI.handleDeleteMarketingPatient(this, e.params);
        return;

      // Nutrition DM UM
      case "GetNutritionAditionalMenuList":
        WebDMUMNutritionI.GetNutritionAditionalMenuList(this);
        return;
      case "HandleSelectedNutritionAditionalMenu":
        WebDMUMNutritionI.HandleSelectedNutritionAditionalMenu(this, e.params);
        return;
      case "HandleChangeValueSelectedNutritionAditionalMenu":
        WebDMUMNutritionI.HandleChangeValueSelectedNutritionAditionalMenu(
          this,
          e.params
        );
        return;
      case "GetNutritionNutritionistMenuList":
        WebDMUMNutritionI.GetNutritionNutritionistMenuList(this);
        return;
      case "HandleSelectedNutritionNutritionistMenu":
        WebDMUMNutritionI.HandleSelectedNutritionNutritionistMenu(
          this,
          e.params
        );
        return;
      case "HandleChangeValueSelectedNutritionNutritionistMenu":
        WebDMUMNutritionI.HandleChangeValueSelectedNutritionNutritionistMenu(
          this,
          e.params
        );
        return;
      case "HandleChangeNutritionUMFilter":
        WebDMUMNutritionI.HandleChangeNutritionUMFilter(this, e.params);
        return;
      case "GetNutritionMaster":
        WebDMUMNutritionI.GetNutritionMaster(this);
        return;
      case "SaveNutritionAditionalMenu":
        WebDMUMNutritionI.SaveNutritionAditionalMenu(this, e.params);
        return;
      case "UpdateNutritionAditionalMenu":
        WebDMUMNutritionI.UpdateNutritionAditionalMenu(this, e.params);
        return;
      case "DeleteNutritionMenu":
        WebDMUMNutritionI.DeleteNutritionMenu(this, e.params);
        return;
      case "HandleSetOpenModNutrition":
        WebDMUMNutritionI.HandleSetOpenModNutrition(this, e.params);
        return;
      case "HandleSetNutritionistUMFilter":
        WebDMUMNutritionI.HandleSetNutritionistUMFilter(this, e.params);
        return;

      // WebUMBabyRelationship
      case "handleGetOptions":
        WebUMBabyRelationshipI.handleGetOptions(this);
        return;
      case "handleGetBabySegmentList":
        WebUMBabyRelationshipI.handleGetBabySegmentList(this);
        return;
      case "handleGetBabyPatientList":
        WebUMBabyRelationshipI.handleGetBabyPatientList(this, e.params);
        return;
      case "handleGetProfileByUsername":
        WebUMBabyRelationshipI.handleGetProfileByUsername(this, e.params);
        return;
      case "handleGetFamilyRelation":
        WebUMBabyRelationshipI.handleGetFamilyRelation(this, e.params);
        return;
      case "handleChangeBabyRelationFilter":
        WebUMBabyRelationshipI.handleChangeBabyRelationFilter(this, e.params);
        return;
      case "handleChangeRelationProfiles":
        WebUMBabyRelationshipI.handleChangeRelationProfiles(this, e.params);
        return;
      case "handleAddRelationProfiles":
        WebUMBabyRelationshipI.handleAddRelationProfiles(this);
        return;
      case "handleRemoveRelationProfiles":
        WebUMBabyRelationshipI.handleRemoveRelationProfiles(this, e.params);
        return;
      case "handleBabyCreatePatientSegment":
        WebUMBabyRelationshipI.handleBabyCreatePatientSegment(this, e.params);
        return;
      case "handleDeleteBabyPatientSegment":
        WebUMBabyRelationshipI.handleDeleteBabyPatientSegment(this, e.params);
        return;
      case "handleCreateFamilyRelation":
        WebUMBabyRelationshipI.handleCreateFamilyRelation(this);
        return;

      // WebUMPatientSelection
      case "handleGetDmMomSegmentList":
        WebUMPatientSelectionI.handleGetDmMomSegmentList(this, e.params);
        return;
      case "handleGetProxyPatientList":
        WebUMPatientSelectionI.handleGetProxyPatientList(this, e.params);
        return;
      case "handleChangeFilterKeyword":
        WebUMPatientSelectionI.handleChangeFilterKeyword(this, e.params);
        return;
      case "handleCreatePatientSegment":
        WebUMPatientSelectionI.handleCreatePatientSegment(this, e.params);
        return;
      case "handleDeletePatientSegment":
        WebUMPatientSelectionI.handleDeletePatientSegment(this, e.params);
        return;

      // WebUMVaccine
      case "GetVaccineUMList":
        WebUMVaccineI.GetVaccineUMList(this, e.params);
        return;
      case "HandleSetVaccineDMDetail":
        WebUMVaccineI.HandleSetVaccineDMDetail(this, e.params);
        return;
      case "HandleSearchOrderItemVaccineUM":
        WebUMVaccineI.HandleSearchOrderItemVaccineUM(this, e.params);
        return;
      case "HandleClearInputVaccineUM":
        WebUMVaccineI.HandleClearInputVaccineUM(this, e.params);
        return;
      case "HandleSearchVaccineUM":
        WebUMVaccineI.HandleSearchVaccineUM(this, e.params);
        return;
      case "HandleSaveVaccineUM":
        WebUMVaccineI.HandleSaveVaccineUM(this, e.params);
        return;
      case "HandleSelectedVaccineUM":
        WebUMVaccineI.HandleSelectedVaccineUM(this, e.params);
        return;
      case "HandleDeleteVaccineUM":
        WebUMVaccineI.HandleDeleteVaccineUM(this, e.params);
        return;
      case "HandleDeleteVaccineUM":
        WebUMVaccineI.HandleDeleteVaccineUM(this, e.params);
        return;
      case "GetChoiceAgeRange":
        WebUMVaccineI.GetChoiceAgeRange(this, e.params);
        return;
      case "HandleChangeAgeRangeDetail":
        WebUMVaccineI.HandleChangeAgeRangeDetail(this, e.params);
        return;
      case "HandleSaveAgeRange":
        WebUMVaccineI.HandleSaveAgeRange(this, e.params);
        return;
      case "GetListAgeRange":
        WebUMVaccineI.GetListAgeRange(this, e.params);
        return;
      case "HandleSelectedAgeRange":
        WebUMVaccineI.HandleSelectedAgeRange(this, e.params);
        return;
      case "HandleDeleteAgeRange":
        WebUMVaccineI.HandleDeleteAgeRange(this, e.params);
        return;
      case "GetChoiceVaccineSeries":
        WebUMVaccineI.GetChoiceVaccineSeries(this, e.params);
        return;

      // WebUMDevelopment
      case "GetChoiceDevelopment":
        WebUMDevelopmentI.GetChoiceDevelopment(this, e.params);
        return;
      case "HandleChangeDevelopmentUMDetail":
        WebUMDevelopmentI.HandleChangeDevelopmentUMDetail(this, e.params);
        return;
      case "GetListDevelopment":
        WebUMDevelopmentI.GetListDevelopment(this, e.params);
        return;
      case "HandleSelectedDevelopmentUM":
        WebUMDevelopmentI.HandleSelectedDevelopmentUM(this, e.params);
        return;
      case "HandleSaveDevelopmentUM":
        WebUMDevelopmentI.HandleSaveDevelopmentUM(this, e.params);
        return;
      case "HandleDeleteDevelopmentUM":
        WebUMDevelopmentI.HandleDeleteDevelopmentUM(this, e.params);
        return;
      case "HandleSearchDevelopmentUM":
        WebUMDevelopmentI.HandleSearchDevelopmentUM(this, e.params);
        return;

      // WebMomObstreticRecord
      case "HandleSetObstreticHistoryList":
        WebMomObstreticRecordI.HandleSetObstreticHistoryList(this, e.params);
        return;
      case "HandleAddBabyObstreticRecord":
        WebMomObstreticRecordI.HandleAddBabyObstreticRecord(this, e.params);
        return;
      case "HandleSetActiveIndexBaby":
        WebMomObstreticRecordI.HandleSetActiveIndexBaby(this, e.params);
        return;
      case "HandleClickButtonMDPOther":
        WebMomObstreticRecordI.HandleClickButtonMDPOther(this, e.params);
        return;
      case "HandleClickButtonMedicationDuringLabourOther":
        WebMomObstreticRecordI.HandleClickButtonMedicationDuringLabourOther(
          this,
          e.params
        );
        return;
      case "HandleClickButtonMedicationPregnancyOther":
        WebMomObstreticRecordI.HandleClickButtonMedicationPregnancyOther(
          this,
          e.params
        );
        return;
      case "HandleClickButtonMedicationToBabyOther":
        WebMomObstreticRecordI.HandleClickButtonMedicationToBabyOther(
          this,
          e.params
        );
        return;
      case "HandleClickButtonCordBloodOther":
        WebMomObstreticRecordI.HandleClickButtonCordBloodOther(this, e.params);
        return;
      case "HandleSetactiveIndexObstetricHistory":
        WebMomObstreticRecordI.HandleSetactiveIndexObstetricHistory(
          this,
          e.params
        );
        return;
      case "HandleAddObstreticHistoryList":
        WebMomObstreticRecordI.HandleAddObstreticHistoryList(this, e.params);
        return;
      case "GetObstetricHistoryList":
        WebMomObstreticRecordI.GetObstetricHistoryList(this, e.params);
        return;
      case "SaveObstreticRecord":
        WebMomObstreticRecordI.SaveObstreticRecord(this, e.params);
        return;
      case "ObstreticGetPatientByHn":
        WebMomObstreticRecordI.ObstreticGetPatientByHn(this, e.params);
        return;
      case "HandleCreateObstreticRecordPDF":
        WebMomObstreticRecordI.HandleCreateObstreticRecordPDF(this, e.params);
        return;
      case "HandleSetOpenModConfirmCreate":
        WebMomObstreticRecordI.HandleSetOpenModConfirmCreate(this, e.params);
        return;

      // WebMomUltrasound
      case "HandleSetUltrasoundUploadDetail":
        WebMomUltrasoundI.HandleSetUltrasoundUploadDetail(this, e.params);
        return;
      case "HandleConfirmUploadUltrasound":
        WebMomUltrasoundI.HandleConfirmUploadUltrasound(this, e.params);
        return;
      case "HandleClearUltrasoundUploadDetail":
        WebMomUltrasoundI.HandleClearUltrasoundUploadDetail(this, e.params);
        return;
      case "HandleSelectedUltrasoundImage":
        WebMomUltrasoundI.HandleSelectedUltrasoundImage(this, e.params);
        return;
      case "HandleDeleteUltrasoundList":
        WebMomUltrasoundI.HandleDeleteUltrasoundList(this, e.params);
        return;
      case "GetUltrasoundList":
        WebMomUltrasoundI.GetUltrasoundList(this, e.params);
        return;

      // WebMomBreastFeeding
      case "GetBreastfeedingList":
        WebMomBreastFeedingI.GetBreastfeedingList(this, e.params);
        return;

      // WebMomPrenatalAndANCRecord
      case "GetPrenatalAndANCRecordList":
        WebMomPrenatalAndANCRecordInterfaceI.GetPrenatalAndANCRecordList(
          this,
          e.params
        );
        return;
      case "SavePrenatalAndANCRecordList":
        WebMomPrenatalAndANCRecordInterfaceI.SavePrenatalAndANCRecordList(
          this,
          e.params
        );
        return;
      case "HandleChangePrenatalAndANCRecordFilter":
        WebMomPrenatalAndANCRecordInterfaceI.HandleChangePrenatalAndANCRecordFilter(
          this,
          e.params
        );
        return;
      case "RemovePrenatalAndANCRecordList":
        WebMomPrenatalAndANCRecordInterfaceI.RemovePrenatalAndANCRecordList(
          this,
          e.params
        );
        return;

      // WebChildOutsideMedicine
      case "handleGetOutsideMedicineDetail":
        WebChildOutsideMedicineI.handleGetOutsideMedicineDetail(this, e.params);
        return;

      // WebChildDevelopment
      case "HandleGetDevelopmentList":
        WebChildDevelopmentI.HandleGetDevelopmentList(this, e.params);
        return;
      case "HandleGetDevelopmenAgeRange":
        ChildDevelopmentI.HandleGetDevelopmenAgeRange(this);
        return;
      case "HandleGetDevelopmenHistory":
        ChildDevelopmentI.HandleGetDevelopmenHistory(this);
        return;
      case "HandleCreateUpdateDevelopment":
        ChildDevelopmentI.HandleCreateUpdateDevelopment(this, e.params);
        return;
      case "HandleUpdateAgeRangeOptions":
        WebChildDevelopmentI.HandleUpdateAgeRangeOptions(this, e.params);
        return;

      // WebChildGrowthChart
      case "getGrowthHistoryList":
        WebChildGrowthChartI.getGrowthHistoryList(this, e.params);
        return;
      case "GetAgeRangeVaccineChild":
        WebChildVaccinationI.GetAgeRangeVaccineChild(this, e.params);
        return;
      case "HandleCreateVaccinePDF":
        WebChildVaccinationI.HandleCreateVaccinePDF(this, e.params);
        return;
      case "HandleSaveVaccineChildDetail":
        WebChildVaccinationI.HandleSaveVaccineChildDetail(this, e.params);
        return;

      // ChildOverall
      case "handleGetChildOverallProgress":
        ChildOverallI.handleGetChildOverallProgress(this, e.params);
        return;

      // ClinicContent
      case "GetClinicContent":
        ClinicContentI.GetClinicContent(this, e.params);
        return;

      // MarketingPromotion
      case "GetListMarketingPromotion":
        PromotionExI.GetListMarketingPromotion(this, e.params);
        return;
      case "HandleSetMarketingPromotionIndex":
        PromotionExI.HandleSetMarketingPromotionIndex(this, e.params);
        return;
      case "HandleChangeMarketingPromotion":
        PromotionExI.HandleChangeMarketingPromotion(this, e.params);
        return;
      // case "HandleGetHospitalOptions":
      //   return PromotionExI.HandleGetHospitalOptions(this, e.params);
      case "HandleAddNewMarketingPromotion":
        PromotionExI.HandleAddNewMarketingPromotion(this, e.params);
        return;
      case "SaveMarketingPromotion":
        PromotionExI.SaveMarketingPromotion(this, e.params);
        return;
      case "GetMarketingSegmentByResult":
        return PromotionExI.GetMarketingSegmentByResult(this, e.params);
      case "HandleSearchMarketingPromotion":
        PromotionExI.HandleSearchMarketingPromotion(this, e.params);
        return;
      case "HandleGetPromotionChart":
        PromotionExI.HandleGetPromotionChart(this, e.params);
        return;

      // MarketingPost
      case "GetListMarketingPost":
        PostExI.GetListMarketingPost(this, e.params);
        return;
      case "HandleSetMarketingPostIndex":
        PostExI.HandleSetMarketingPostIndex(this, e.params);
        return;
      case "HandleChangeMarketingPost":
        PostExI.HandleChangeMarketingPost(this, e.params);
        return;
      case "HandleAddNewMarketingPost":
        PostExI.HandleAddNewMarketingPost(this, e.params);
        return;
      case "SaveMarketingPost":
        PostExI.SaveMarketingPost(this, e.params);
        return;
      case "HandleGetDoctorOptions":
        return PostExI.HandleGetDoctorOptions(this, e.params);
      case "HandleGetClinicOptions":
        return PostExI.HandleGetClinicOptions(this, e.params);
      case "HandleGetPostChart":
        PostExI.HandleGetPostChart(this, e.params);
        return;
      case "HandleSubmitUrl":
        PostExI.HandleSubmitUrl(this, e.params);
        return;

      case "SaveClinicContent":
        ClinicContentI.SaveClinicContent(this, e.params);
        return;

      case "DeleteClinicContent":
        ClinicContentI.DeleteClinicContent(this, e.params);
        return;

      case "NewClinicContent":
        ClinicContentI.NewClinicContent(this, e.params);
        return;

      // MarketingAnalytics
      case "GetMarketingAnalytics":
        MarketingAnalyticsI.GetMarketingAnalytics(this, e.params);
        return;

      case "handleGetMarketingSegmentStorage":
        MarketingAnalyticsI.handleGetMarketingSegmentStorage(this, e.params);
        return;

      case "GetPatientAnalytics":
        MarketingAnalyticsI.GetPatientAnalytics(this, e.params);
        return;

      case "handleGetMarketingPatientListByHospital":
        MarketingAnalyticsI.handleGetMarketingPatientListByHospital(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
