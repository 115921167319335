import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Grid,
  Image,
  Loader,
} from "semantic-ui-react";
import { Calendar } from "@natscale/react-calendar";
import "./calendarTekephar.scss";
import { Skeleton } from "@mui/material";

import "@natscale/react-calendar/dist/main.css";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Controller
import QueueController from "../react-lib/apps/QUE/QueueController";

// Manager
import QUEManager from "../react-lib/apis/manager/QUEManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";

type PostponeTelepharProps = {
  calendarList: any;
  selectedDate: any;
  loadingSkeleton: boolean;
  apiToken: any;
  gotoChatChannel: any;
  dataStateLocation: any;
  onSetChannelName: any;
  // Controller
  queueController: QueueController & {
    queManager: QUEManager;
    prxManager: PRXManager;
  };
};

const ICON = {
  pin: "/images/telephar/pin_location_white.png",
  calendarWhite: "/images/telephar/icon_calendar_white.png",
  calendarBlue: "/images/telephar/icon_calendar_blue.png",
  information: "/images/telephar/icon_information.png",
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  white: "white",
  lighterBlue: "#EBF1F8",
  lightBlue: "#A3D2F8",
  normalGrey: "#393939",
  lighter: "#DBE5F3",
};

const arrayMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const beFormatDate = "YYYY-MM-DD";

const styles = {
  buttonConfirmBox: {
    margin: "30px 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  buttonConfirm: {
    display: "flex",
    justifyContent: "center",
    background: COLOR.primary,
    color: "white",
    width: "70%",
    padding: "14px 0",
    fontWeight: "bold",
    fontSize: "18px",
    borderRadius: "8px",
  } as CSSProperties,
  boxWarning: {
    margin: "10px",
    background: COLOR.lighterBlue,
    border: `1px solid ${COLOR.lightBlue}`,
    borderRadius: "5px",
  } as CSSProperties,
  cardAppointment: {
    margin: "10px",
    padding: "10px",
    background: COLOR.primary,
    borderRadius: "5px",
  } as CSSProperties,
  setCenter: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  buttonSection: {
    display: "flex",
    padding: "5px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
  } as CSSProperties,
};

const PostponeTelephar = (props: PostponeTelepharProps) => {
  const intl = useIntl();
  const [selectedSlot, setSelectedSlot] = useState<any>("day");
  const [timeList, setTimeList] = useState<any[]>([]);
  const [active, setActive] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [dateSelected, setDateSelected] = useState<any>(null);
  const [selectedDSB, setSelectedDSB] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialViewDate = useRef<any>(null);
  const yearMonthList = useRef<string[]>([]);

  useEffect(() => {
    props.onSetChannelName({ name: props?.dataStateLocation?.chatName || "" });
    gatData();
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const gatData = async () => {
    setIsLoading(true);
    const [response, error] =
      await props.queueController.loadDivisionServiceBlockSummaryView({
        apiToken: props.apiToken,
        divisions: props.dataStateLocation?.division,
        provider: props.dataStateLocation?.provider,
      });

    if (error) {
      setErrorMessage(error)
      setActive([]);
      setTimeList([]);
    } else {
      const DSBClone = response?.items || [];
      const dateAllList: any[] = [];
      const timeAllList: any[] = [];
      DSBClone.forEach((items: any) => {
        // console.log("saika items.start_datetime_iso ",items.start_datetime_iso)
        const date = moment(items.start_datetime_iso).format(beFormatDate);
        const dateNum = moment(items.end_datetime_iso).format("YYYYMMDD");
        const time = `${moment(items.start_datetime_iso).format("HH:mm")}`;
        if (dateNum >= moment().format("YYYYMMDD")) {
          if (!dateAllList.some((item: any) => moment(item).format(beFormatDate) === date)
          ) {
            const _availableDate = new Date(date);
            _availableDate.setHours(0, 0, 0, 0);
            dateAllList.push(_availableDate);
          }
          timeAllList.push({
            time: time,
            dsbID: items.id,
            keyCheck: dateNum,
          });
        }
      });
      let start_date = moment();
      const firstMonth = dateAllList?.[0] || start_date
      initialViewDate.current = new Date(moment(firstMonth).format(beFormatDate))
      yearMonthList.current = [moment(firstMonth).format("YYYY-MM")];
      setActive(dateAllList);
      setTimeList(timeAllList);
    } 
    setIsLoading(false);
  };

  const templateColumns = useMemo(() => {
    let size = Math.floor((dimensions.width - 30) / 100);
    size = size >= 10 ? size - 1 : size;
    const percent = 100 / size;
    return new Array(size).fill(`${percent}%`).join(" ");
  }, [dimensions]);

  const onDateChange = (value: any) => {
    setDateSelected(moment(value));
    setSelectedDSB("");
  };

  const timeActiveList = useMemo(() => {
    // console.log("saika timeList ", timeList);
    if (timeList) {
      const timeActive = (timeList || []).filter((item: any) => {
        return item.keyCheck === dateSelected.format("YYYYMMDD");
      });

      // console.log("saika timeActive ", timeActive);
      const dayTime = timeActive.filter(
        (item: any) => moment(item.time, "HH:mm").format("HH") < "13"
      );
      const nightTime = timeActive.filter(
        (item: any) => moment(item.time, "HH:mm").format("HH") >= "13"
      );

      // console.log("saika dayTime ", dayTime);
      // console.log("saika nightTime ", nightTime);
      if (dayTime.length > 0) {
        setSelectedSlot("day");
      } else {
        setSelectedSlot("night");
      }
      return ({ day: dayTime || [], night: nightTime || [] });
    } else {
      return ({});
    }
  },[dateSelected]);

  const getYearMonthCalendar = () => {
    const headerDate = document.querySelectorAll(
      ".rc_header_label.rc_header_label-days-of-month span"
    );
    const [elmMonth, elmYear] = Array.from(headerDate);
    let getYearMonth: string = "";
    if (elmMonth && elmYear) {
      getYearMonth = `${elmYear.textContent}-${(
        arrayMonth.indexOf(elmMonth.textContent || "") + 1
      )
        .toString()
        .padStart(2, "0")}`;
    }
    return getYearMonth;
  };

  const setSelectedDateManual = () => {
    if (dateSelected) {
      const yearMonth = getYearMonthCalendar();
      const nextYearMonth = moment(`${yearMonth}-01`)
        .add(1, "months")
        .format("YYYY-MM");
      const selectDate = moment(dateSelected).format(beFormatDate);

      // console.log('saika yearMonth ',yearMonth)

      for (const elm of Array.from(
        document.querySelectorAll(".rc .rc_body-cell")
      )) {
        const btn = elm.querySelector("button");

        if (elm.className.includes("rc_selected")) {
          elm.classList.remove("rc_selected", "rc_disabled");
        }
        if (
          elm.className.includes("rc_active") &&
          `${yearMonth}-${btn?.textContent?.padStart(2, "0")}` === selectDate &&
          !elm.className.includes("rc_highlight") && yearMonth === moment(dateSelected).format("YYYY-MM")
        ) {
          elm.classList.add("rc_selected");
        } else if (
          (elm.className.includes("rc_lr") ||
            elm.className.includes("rc_lc")) &&
          `${nextYearMonth}-${btn?.textContent?.padStart(2, "0")}` ===
            selectDate &&
          !elm.className.includes("rc_active")
        ) {
          elm.classList.add("rc_selected", "rc_disabled");
        }
      }
    }
  };

  const handleIsDisabled = (date: Date) => {
    setTimeout(() => {
      const yearMonth = getYearMonthCalendar();
      if (
        !yearMonthList.current.includes(yearMonth) &&
        yearMonth > yearMonthList.current[0]
      ) {
        yearMonthList.current = [...yearMonthList.current, yearMonth];
      }

      if (yearMonthList.current.includes(yearMonth)) {
        setSelectedDateManual();
      }
    });

    return !active.find(
      (item: Date) => item.toISOString() === date.toISOString()
    );
  };

  const handleSelectTime = (DSB: any) => {
    setSelectedDSB(DSB);
  };

  const handleUpdateAppointment = async () => {
    if (selectedDSB !== "") {
      const [resProvider, errorProvider] =
        await props.queueController.updatePatientAppointmentTelephar({
          apiToken: props.apiToken,
          pk: props.dataStateLocation?.appointmentId,
          divisionServiceBlock: selectedDSB,
          status: "3",
        });
      if (errorProvider ) {
        setErrorMessage(errorProvider)
      } else {
        props.gotoChatChannel(props.dataStateLocation.channelId);
      }
    }
  };

  return (
    <div
      style={{
        margin: "10px",
        position: "relative",
      }}
      className="main-postpone"
    >
      <SnackMessage
        onEvent={{}}
        onClose={() => {
          setErrorMessage(null)
        }}
        error={errorMessage != null? errorMessage : null }
        success={null}
      />
      <div style={styles.boxWarning}>
        <Grid style={{ margin: "0px" }}>
          <Grid.Row style={{ padding: "1rem" }}>
            <Grid.Column
              width={2}
              style={{
                ...styles.setCenter,
                alignItems: "center",
                padding: "0px",
              }}
            >
              <Image
                src={ICON.information}
                style={{ width: "24px", height: "24px" }}
              />
            </Grid.Column>
            <Grid.Column width={14} style={{ padding: "0px" }}>
              <div
                style={{ fontSize: "14px", color: "#008BF5", whiteSpace: "pre-line",marginLeft: "5px"}}
              >{`กรุณานัดหมายภายใน 24 ชม.\nเพื่อประสิทธิภาพที่ดีในการรักษาของท่าน`}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: COLOR.normalGrey,
            margin: "10px 0",
          }}
        >
          เลือกวัน
        </div>
        <div></div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Dimmer active={isLoading} inverted style={{zIndex:"9"}}>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>
        {useMemo(
          () => (
            <Calendar
              className="calendar-custom"
              value={dateSelected}
              onChange={onDateChange}
              disablePast={true}
              // disableToday={true}
              // disableFuture={true}
              isDisabled={handleIsDisabled}
              startOfWeek={0}
              size={dimensions.width}
              initialViewDate={initialViewDate.current || active?.[0]}
            />
          ),
          [dateSelected, dimensions, active]
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: COLOR.normalGrey,
            margin: "10px 0",
          }}
        >
          เลือกเวลา
        </div>
        <div></div>
      </div>
      <div
        style={{
          display: "grid",
          padding: "10px",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "30px",
          background: COLOR.lighter,
        }}
      >
        <div
          style={{
            ...styles.buttonSection,
            background: selectedSlot === "day" ? COLOR.primary : "",
            color: selectedSlot === "day" ? COLOR.white : COLOR.primary,
            fontWeight: selectedSlot === "day" ? "bold" : "",
          }}
          onClick={() => {
            setSelectedSlot("day");
          }}
        >
          ช่วงเช้า
        </div>
        <div
          style={{
            ...styles.buttonSection,
            background: selectedSlot === "night" ? COLOR.primary : "",
            color: selectedSlot === "night" ? COLOR.white : COLOR.primary,
            fontWeight: selectedSlot === "night" ? "bold" : "",
          }}
          onClick={() => {
            setSelectedSlot("night");
          }}
        >
          ช่วงเย็น
        </div>
      </div>

      <div
        style={{
          padding: "13px 10px 10px 10px",
          minHeight: "20vh",
        }}
      >
        <div>
          {(timeActiveList.day != null && timeActiveList.night != null ) ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: `${templateColumns}`,
                rowGap: "12px",
              }}
            >
              {(selectedSlot === "day"
                ? timeActiveList?.day || []
                : timeActiveList?.night || []
              )?.map((item: any, index: number) => (
                <div
                  key={"slot-" + index}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="button-slot"
                    style={{
                      fontSize: "14px",
                      padding: "12px 30px",
                      boxShadow: `0px 4px 8px rgba(44, 44, 44, 0.08)`,
                      borderRadius: "16px",
                      color:
                        selectedDSB === item.dsbID ? "white" : COLOR.normalGrey,
                      background:
                        selectedDSB === item.dsbID ? COLOR.primary : "white",
                      // selectedSlot?.start_time
                      //   ? { background: COLOR.blue, color: "white" }
                      //   : props.loadingSkeleton
                      //   ? { background: COLOR.opacity, border: 0 }
                      //   : {}),
                    }}
                    onClick={() => handleSelectTime(item.dsbID)}
                    disabled={props.loadingSkeleton}
                  >
                    {item.time}
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ background: "#f6f7f7", padding: "13px" }}>
                {intl.formatMessage({ id: "bplusClinicKey336" })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div style={styles.buttonConfirmBox}>
        <Button
          style={styles.buttonConfirm}
          disabled={selectedDSB ? false : true}
          onClick={() => handleUpdateAppointment()}
        >
          ยืนยันการนัดหมาย
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PostponeTelephar);
