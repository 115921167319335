import React, { useState, useEffect, forwardRef } from "react";
import {
  Divider,
  Input,
  Label,
  InputProps,
  Button,
  Image,
  Icon,
} from "semantic-ui-react";

import { FormattedMessage, useIntl } from "react-intl";

// UI
import PhoneNumberInput from "./PhoneNumberInput";

// Styles
import "./RegisterCSS.scss";

// Types
import { registerInfo, LastLoginType } from "./TypeModal";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

const Icons = {
  user: "/images/Appointment/user-icon.png",
  faceID: "/images/register/icon-faceid.png",
  touchID: "/images/register/icon-touchid.png",
  pinLock: "/images/register/icon-pinlock.png",
};

type WelcomebackProps = {
  onEvent: (e: any) => any;
  history: any;
  loading?: boolean;
  registerInfo?: registerInfo;
  lastLogin?: LastLoginType;
  apiToken?: any;
};

const Welcomeback: React.FunctionComponent<WelcomebackProps> = (props) => {
  const intl = useIntl();

  useEffect(() => {
    if (globalThis.mobile.getLastLogin) {
      globalThis.mobile.getLastLogin();
    }
  }, []);

  const checkBiometric = (type: string) => {
    switch (type) {
      case "faceID":
        return Icons.faceID;
      case "touchID":
        return Icons.touchID;
      default:
        return Icons.pinLock;
    }
  };

  const handleHistoryGoTo = (action: string) => {
    if (!props.loading) {
      switch (action) {
        case "login":
          if (props.lastLogin?.typeLogin !== "sms") {
            props.onEvent({
              message: "HandleSetValueRegisterInfo",
              params: {
                key: "username",
                value: props.lastLogin?.username || "",
              },
            });

            props.onEvent({
              message: "HandleSetValueRegisterInfo",
              params: {
                key: "typeUsername",
                value: props.lastLogin?.typeLogin,
              },
            });
          }

          props.history.push("/login/?app=MobRegister");
          break;
        case "sigout":
          props.onEvent({
            message: "HandleLogOut",
            params: { history: props.history },
          });
          break;
        case "sms":
          if (props.lastLogin?.typeLogin === "sms") {
            props.onEvent({
              message: "HandleSetValueRegisterInfo",
              params: {
                key: "username",
                value: props.lastLogin?.username || "",
              },
            });

            props.onEvent({
              message: "HandleSetValueRegisterInfo",
              params: {
                key: "typeUsername",
                value: "phoneNumber",
              },
            });

            props.onEvent({
              message: "HandleRequestOTP",
              params: { history: props.history, smsAuth: true, intl },
            });
          } else {
            props.history.push("/login/sms/?app=MobRegister");
          }
          break;
        case "localAuthen":
          if (globalThis.mobile.localAuthen) {
            globalThis.mobile.localAuthen();
          } else if (props.apiToken) {
            props.onEvent({ message: "HandleLogOut", params: props.history });
          } else {
            props.history.push("/login/?app=MobRegister");
          }
          break;
      }
    }
  };

  const normalizeInput = (value: string) => {
    // console.log("saika ~ value:", value);
    if (!value) return value;
    // +xx xxx xxx xxx
    if (value.length > 10) {
      const normalizeNumber = value.slice(value.length - 9, value.length);
      const normalizeCountryCode = value.slice(0, value.length - 9);
      //(+x) xx xxx xxxx
      return `(+${normalizeCountryCode})  ${normalizeNumber.slice(
        0,
        normalizeNumber.length - 7
      )} ${normalizeNumber.slice(
        normalizeNumber.length - 7,
        normalizeNumber.length - 4
      )} ${normalizeNumber.slice(
        normalizeNumber.length - 4,
        normalizeNumber.length
      )}`;
    } else {
      return `${value}`;
    }
  };

  return (
    <>
      <div>
        <div className="title-text-blue">
          {<FormattedMessage id="bplusClinicKey958" />}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
              width: "75%",
            }}
          >
            <Image
              src={props.lastLogin?.image ? props.lastLogin?.image : Icons.user}
              size="tiny"
              circular
            />
          </div>
          {props.lastLogin?.username && props.lastLogin?.typeLogin == "sms" && (
            <div
              className="text-blue-bold"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {normalizeInput(props.lastLogin?.username)}
            </div>
          )}

          <div style={{ padding: "10px", width: "75%" }}>
            <div className="bplus-buttom">
              <Button
                style={{ width: "100%" }}
                loading={props.loading}
                onClick={() =>
                  handleHistoryGoTo(
                    props.lastLogin?.typeLogin === "sms" ? "sms" : "login"
                  )
                }
              >
                {props.lastLogin?.typeLogin === "sms" ? (
                  <FormattedMessage id="bplusClinicKey1022" />
                ) : (
                  <FormattedMessage id="bplusClinicKey958" />
                )}
              </Button>
            </div>
            <div className="bplus-buttom">
              <div
                style={{
                  background: "transparent",
                  color: COLOR.primary,
                }}
                onClick={() =>
                  handleHistoryGoTo(
                    props.lastLogin?.typeLogin === "sms" ? "login" : "sms"
                  )
                }
              >
                {props.lastLogin?.typeLogin === "sms" ? (
                  <FormattedMessage id="bplusClinicKey1021" />
                ) : (
                  <FormattedMessage id="bplusClinicKey982" />
                )}
              </div>
            </div>
          </div>
          <Divider
            horizontal
            inverted
            style={{ color: "#A2BFC9", fontSize: "14px", width: "75%" }}
          >
            <FormattedMessage id="bplusClinicKey1020" />
          </Divider>
          <div
            className="title-text-blue"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "normal",
              flexDirection: "column",
              alignItems: "center",
              width: "75%",
            }}
            onClick={() => handleHistoryGoTo("localAuthen")}
          >
            <Image
              src={checkBiometric(props.lastLogin?.localTypeAuthen || "")}
              style={{
                width: "50px",
              }}
            />
            <div
              className="text-blue"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {props.lastLogin?.localTypeAuthen === "faceID" ? (
                <FormattedMessage id="bplusClinicKey1017" />
              ) : props.lastLogin?.localTypeAuthen === "touchID" ? (
                <FormattedMessage id="bplusClinicKey1018" />
              ) : (
                <FormattedMessage id="bplusClinicKey1019" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="div-center-bottom"
        style={{ color: "", flex: "1", alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            color: "#797878",
            whiteSpace: "pre-line",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {<FormattedMessage id="bplusClinicKey1015" />}
          {
            <div
              className="highlight"
              onClick={() => handleHistoryGoTo("sigout")}
            >
              {<FormattedMessage id="bplusClinicKey1016" />}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default React.memo(Welcomeback);
