import React, { useEffect } from "react";
import TabPath from "react-lib/frameworks/TabPath";
import ContentEdit from "./ContentEdit"

const Domain = (props: any) => {

  useEffect(() => {
    props.onEvent({
      message: "GetDomainContent",
      params: {
        domain: props.domain
      }
    });
  }, [props.domain])

  let countList = props.domainData?.items?.reduce((acc: any, item: any) => {
    // console.log(item.url)
    // console.log(props.contentDomain?.[item.url]);
    if (!Object.keys(props.contentDomain).includes(item.url)) {
      acc.new += 1
    } else if (props.contentDomain?.[item.url] === true) {
      acc.ok += 1
    }
    return acc
  }, { new: 0, ok: 0 })
  
  return(
    props.domainData?.items?.length > 0 ?
    <TabPath
      padding="0px"
      topTab={
        <div>{`New: ${countList.new}, Ok: ${countList.ok}, Total: ${props.domainData?.items.length}`}</div> 
      }
      data={props.domainData?.items
            ?.sort((a: any, b: any) => {
              let aFlag = props.contentDomain?.[a.url];
              let bFlag = props.contentDomain?.[b.url];
              if (aFlag === true && bFlag === false)
                return -1
              else if (aFlag === false && bFlag === true)
                return 1
              else if (typeof aFlag === "undefined" && typeof bFlag !== "undefined")
                return -1
              else if (typeof aFlag !== "undefined" && typeof bFlag === "undefined")
                return 1
              else if (a.url < b.url)
                return -1
              else if (a.url > b.url)
                return 1
              else
                return -1
            })
            .map((item: any, index: number) => (
        {
          key: index,
          name: <div 
                  style={{ 
                    borderBottom: "solid #cccccc 1px",
                    padding: "5px",
                    wordWrap: "break-word",
                    color: props.contentDomain?.[item.url] === false ? 
                           "red": 
                           props.contentDomain?.[item.url] === true ? 
                           "green":
                           "black"
                  }}>
                  {decodeURI(item.url).substring(0, 100)}
                </div>,
          render: () => 
            <ContentEdit 
              item={item}
              onEvent={props.onEvent}
            />
        }
      ))}
    />: <></>
  )
}

export default Domain