import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {IntlProvider, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Menu,
  Segment,
  Tab,
  TextArea,
} from "semantic-ui-react";
import Slider from "@mui/material/Slider";
import { AppBar } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  EMOTION_TYPE,
  convertToADDate,
  formatDate,
} from "bplus-lib/MobClinicInterface";

// UI
import { DateTextBox } from "../../react-lib/apps/common/";
import MobHead from "../MobHead";
import PatientPanel from "./PatientPanel";
import SnackMessage from "./SnackMessage";
import DMEmotionRestChart, { DMDatePicker } from "./DMEmotionRestChart";

import "../../css/WebClinic.scss";

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  lightBlueBG: {
    background: "var(--text-light-blue-20)",
  } as CSSProperties,
  selectedFeeling: {
    border: "2px solid var(--theme-icon-color-dm)",
    padding: "5px",
  } as CSSProperties,
};

export const DATE_FORMAT = "DD/MM/YYYY";

// ---------- Tab Detail
type TabDetailProps = {
  // function
  onEvent: any;
  // data
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  emotionFilterDate: moment.Moment;
  emotionRecordList: any;
  restRecord: any;
};

const TabDetail: React.FC<TabDetailProps> = (props) => {
  const intl = useIntl();
  const [feeling, setFeeling] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [stress, setStress] = useState<number>(0);
  const [rest, setRest] = useState<any>({});

  useEffect(() => {
    props.onEvent({ message: "handleGetEmotion" });
    props.onEvent({ message: "handleGetRest" });
  }, [props.emotionFilterDate]);

  useEffect(() => {
    if (props.emotionRecordList?.total > 0) {
      const emotionData =
        props.emotionRecordList?.items[props.emotionRecordList.total - 1];

      setStress(emotionData?.data?.stress);

      if (emotionData?.data?.feeling?.length > 0) {
        const feeling: any =
          emotionData.data.feeling[emotionData.data.feeling.length - 1];
        setFeeling(feeling.value);
        setNote(feeling?.note);
      } else {
        setFeeling("");
        setNote("");
      }
    } else {
      setFeeling("");
      setNote("");
      setStress(0);
    }
  }, [props.emotionRecordList]);

  useEffect(() => {
    if (props.restRecord.hasOwnProperty("id")) {
      setRest(props.restRecord);
    } else {
      setRest({ id: null, data: { sleep: "", wake_up: "", hours: "" } });
    }
  }, [props.restRecord]);

  // change date
  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: any = props.emotionFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeEmotionFilterDate",
      params: { date: targetDate },
    });
  };

  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleChangeEmotionFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  const handleSaveEmotionRest = () => {
    handleSaveEmotion();
    handleSaveRest();
  };

  // ----- Emotion
  // Feeling
  const handleChangeFeeling = (event: any) => {
    setFeeling(event.target.name);
  };
  // Note
  const handleChangeStressNote = (event: object, { value }: any) => {
    setNote(value);
  };
  // Stress
  const handleChangeStress = (event: object, value: number) => {
    // Todo: Slider ต้องลาก ถ้าคลิกจุดเลย จะ bug
    setStress(value);
  };
  // Save
  const handleSaveEmotion = () => {
    let emotionData: any = {};
    if (props.emotionRecordList?.total > 0) {
      emotionData = {
        ...props.emotionRecordList?.items[props.emotionRecordList.total - 1],
      };
    } else {
      emotionData = { data: { feeling: [] } };
    }

    let label: string = "";
    if (feeling === EMOTION_TYPE.VERY_GOOD.value) {
      label = EMOTION_TYPE.VERY_GOOD.label;
    } else if (feeling === EMOTION_TYPE.GOOD.value) {
      label = EMOTION_TYPE.GOOD.label;
    } else if (feeling === EMOTION_TYPE.NORMAL.value) {
      label = EMOTION_TYPE.NORMAL.label;
    } else if (feeling === EMOTION_TYPE.BAD.value) {
      label = EMOTION_TYPE.BAD.label;
    } else if (feeling === EMOTION_TYPE.VERY_BAD.value) {
      label = EMOTION_TYPE.VERY_BAD.label;
    }

    emotionData.data.feeling.push({
      time: moment().format("HH:mm:ss"),
      value: feeling,
      label: label,
      note: note,
    });

    emotionData.data.stress = stress;

    props.onEvent({
      message: "handleSaveEmotion",
      params: emotionData,
    });
  };

  // ----- Rest
  // Sleep
  const handleChangeSleepHour = (event: object, { value }: any) => {
    let result = { ...rest };
    result.data.hours = value;
    setRest(result);
  };
  const handleSleep = () => {
    let result = { ...rest };
    result.data.sleep = moment().format(
      `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
    );
    setRest(result);
  };
  const handleWakeUp = () => {
    const sleepTime = moment(
      rest?.data?.sleep,
      `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
    );
    const wakeUpTime = moment();
    const diffTime = wakeUpTime.diff(sleepTime);
    const duration = moment.duration(diffTime);

    let result = { ...rest };
    result.data.wake_up = wakeUpTime.format(
      `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
    );
    result.data.hours = `${duration.hours()}.${duration.minutes()}`;
    setRest(result);
  };

  // Save
  const handleSaveRest = () => {
    props.onEvent({ message: "handleSaveRest", params: rest });
  };

  return (
    <>
      {/* ----- PatientPanel ----- */}
      <PatientPanel
        patientInfo={props.selectedPatient}
        haveBackground={false}
      />

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <DMDatePicker
        date={props.emotionFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
      />

      {/* ----- Feeling ----- */}
      <div style={styles.lightBlueBG}>
        <Header as="h4" style={{ textAlign: "center", padding: "10px" }}>
          <FormattedMessage id="bplusClinicKey256" />
        </Header>
      </div>
      <Grid style={{ padding: "10px 25px" }}>
        <Grid.Row columns={5}>
          {(Object.keys(EMOTION_TYPE) as (keyof typeof EMOTION_TYPE)[]).map(
            (key) => {
              const icon = EMOTION_TYPE[key].label
                .replace(" ", "_")
                .toLowerCase();
              return (
                <Grid.Column
                  style={
                    feeling === EMOTION_TYPE[key].value
                      ? styles.selectedFeeling
                      : { padding: "5px" }
                  }
                >
                  <Image
                    src={`/images/detail/em_${icon}.png`}
                    name={EMOTION_TYPE[key].value}
                    onClick={handleChangeFeeling}
                  />
                  <Header
                    as="h6"
                    style={{ textAlign: "center", marginTop: "0px" }}
                  >
                    {
                      EMOTION_TYPE[key].label === "Very happy" ? `${intl.formatMessage({ id: "bplusClinicKey725" })}` :
                      EMOTION_TYPE[key].label === "Happy" ? `${intl.formatMessage({ id: "bplusClinicKey726" })}` :
                      EMOTION_TYPE[key].label === "Normal" ? `${intl.formatMessage({ id: "bplusClinicKey832" })}` :
                      EMOTION_TYPE[key].label === "Bad" ? `${intl.formatMessage({ id: "bplusClinicKey727" })}` :
                      EMOTION_TYPE[key].label === "Very bad" ? `${intl.formatMessage({ id: "bplusClinicKey728" })}` : ""
                    }
                  </Header>
                </Grid.Column>
              );
            }
          )}
        </Grid.Row>
      </Grid>
      <Header
        as="h4"
        color="brown"
        style={{ textAlign: "center", marginTop: "0px" }}
      >
        <FormattedMessage id="bplusClinicKey541" />
        <Icon name="heart" size="tiny" color="red" />?
      </Header>
      <Form style={{ padding: "0px 20px 20px" }}>
        <TextArea
          rows="3"
          value={note || ""}
          onChange={handleChangeStressNote}
        />
      </Form>

      {/* ----- Stress ----- */}
      <div style={styles.lightBlueBG}>
        <Header as="h4" style={{ textAlign: "center", padding: "10px" }}>
          <FormattedMessage id="bplusClinicKey438" />
        </Header>
      </div>
      <div style={{ padding: "40px 20px 0px" }}>
        <Slider
          defaultValue={stress}
          value={stress}
          min={0}
          step={10}
          max={100}
          scale={(x) => x ** 10}
          marks={[
            { value: 0, label: "0 %" },
            { value: 50, label: "50 %" },
            { value: 100, label: "100 %" },
          ]}
          valueLabelDisplay="on"
          valueLabelFormat={`${stress}%`}
          onChange={handleChangeStress}
        />
      </div>
      
      {/* ----- Rest  ----- */}
      <div style={styles.lightBlueBG}>
        <Header as="h4" style={{ textAlign: "center", padding: "10px" }}>
          <FormattedMessage id="bplusClinicKey422" />
        </Header>
      </div>

      <Grid verticalAlign="middle" style={{ margin: "10px 15px" }}>
        <Grid.Column width={5} style={{ padding: "0px" }}>
          <Segment
            circular
            disabled={!!rest?.data?.hours}
            style={{ border: "5px solid rgba(28, 153, 243, 0.4)" }}
          >
            {rest?.data?.sleep ? (
              <Icon
                name="sun"
                size="big"
                color="yellow"
                disabled={!!rest?.data?.hours}
                onClick={handleWakeUp}
              />
            ) : (
              <Icon.Group size="big" onClick={handleSleep}>
                <Icon name="moon" color="blue" />
                <Icon corner="top right" name="star" color="blue" />
              </Icon.Group>
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column width={4} style={{ padding: "0px" }}>
          <Header as="h4" color="grey" textAlign="center">
            <FormattedMessage id="bplusClinicKey459" />
          </Header>
        </Grid.Column>
        <Grid.Column width={4} style={{ padding: "0px" }}>
          <Input
            type="number"
            style={{ width: "90px" }}
            value={rest?.data?.hours}
            onChange={handleChangeSleepHour}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Header as="h4" color="grey">
            <FormattedMessage id="bplusClinicKey255" />
          </Header>
        </Grid.Column>
      </Grid>

      <Grid style={{ paddingBottom: "20px" }}>
        <Grid.Row centered columns={2}>
          <Grid.Column width={5}>
            <Button
              fluid
              color="blue"
              content={intl.formatMessage({ id: "common.save" })}
              onClick={handleSaveEmotionRest}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

// ---------- DMEmotionRest
type DMEmotionRestProps = {
  // function
  onEvent: any;
  onSetBackToApp: any;
  // data
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  emotionFilterDate: moment.Moment;
  emotionDuration: string;
  emotionRecordList: any;
  restRecord: any;
  emotionRestHistoryList: any[];
};

const DMEmotionRestInitial: DMEmotionRestProps = {
  // function
  onEvent: () => null,
  onSetBackToApp: () => null,
  // data
  loadingStatus: false,
  errorMessage: {},
  successMessage: {},
  selectedPatient: {},
  emotionFilterDate: moment(),
  emotionDuration: "7",
  emotionRecordList: {},
  restRecord: {},
  emotionRestHistoryList: [],
};

const DMEmotionRest: React.FC<DMEmotionRestProps> = (props) => {
  const intl = useIntl();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    if (tabIndex === 0) {
      props.onEvent({ message: "handleGetEmotion" });
      props.onEvent({ message: "handleGetRest" });
    } else if (tabIndex === 1) {
      props.onEvent({
        message: "handleGetEmotionRestHistory",
        params: {
          start_measure: moment()
            .add(`-${props.emotionDuration}`, "days")
            .format(BACKEND_DATE_FORMAT),
          end_measure: moment().format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, tabIndex]);

  const handleTabChange = (event: object, data: any) => {
    setTabIndex(data.activeIndex);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  return (
    <>
      <AppBar position="fixed" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "emotion_rest.emotion_rest" })}
        />
      </AppBar>

      <Tab
        className="weight-tab-custom dm"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={tabIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
      >
        <TabDetail
          // funtion
          onEvent={props.onEvent}
          // data
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          emotionFilterDate={props.emotionFilterDate}
          emotionRecordList={props.emotionRecordList}
          restRecord={props.restRecord}
        />
        <DMEmotionRestChart
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          height={200}
          emotionRestHistoryList={props.emotionRestHistoryList}
          starRadius={3.5}
          starTop={2}
        />
      </SwipeableViews>
    </>
  );
};

DMEmotionRest.defaultProps = DMEmotionRestInitial;

export default React.memo(DMEmotionRest);
