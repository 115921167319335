import React, { useState, useEffect, useMemo } from "react";
// MUI
import Radio from "@mui/material/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { Skeleton } from "@material-ui/lab";

import { makeStyles, Button as MuiButton } from "@material-ui/core";

import { useIntl } from "react-intl";
import { History } from "history";

// Interface
import { USE_LOCAL_STORAGE } from "./SelectHospitalInterface";
import { State } from "bplus-lib/MobSmartRegisterInterface";

// Types
type SelectInitialHospitalProps = {
  onEvent?: (e: any) => any;
  hospitalList?: any[];
  firstLoadingHospital?: boolean;
  history: History<{ hospitalsMatch?: any[] }>;
  selectedHospital?: any;
  buttonLoading?: boolean;
  onClose?: (selectedValue?: any) => any;
} & Pick< State,"safeAreaTop">;

// Images
const IMAGES = {
  bg: "/images/register/bg-gradient.png",
  logo: "/images/expansion/logo_192.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  half_grey: "#E9E9E9",
};

const bgStyles = {
  position: "relative",
  minHeight: "100vh",
  "&:before": {
    backgroundImage: `url(${IMAGES.bg})`,
    backgroundPosition: "50% 0",
    backgroundSize: "cover",
    backgroundColor: "white",
    content: "' '",
    display: "block",
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    opacity: 0.5,
    zIndex: -1,
  },
} as any;

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "20px 0",
    ...bgStyles,
  },
  header: {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: COLORS.blue,
    padding: "2rem 20px 1.25rem",
  },
  card: {
    boxShadow: "none !important",
    borderBottom: `1px solid ${COLORS.half_grey}`,
    // margin: "0 -20px",
    padding: "4px 0px",
    backgroundColor: "transparent !important",
    "& .MuiCardContent-root": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      fontWeight: "bold",
      "& div:nth-child(2)": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
}));

const SelectInitialHospital = (props: SelectInitialHospitalProps) => {
  const intl = useIntl();
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<any>("");

  useEffect(() => {
    if (!props.onEvent) {
      return setSelectedValue(props.selectedHospital);
    }

    props.onEvent({
      message: "GetListHospital",
      params: { sendToNative: true, apiv3: true },
    });
  }, []);

  const hospitalItems = useMemo(() => {
    let list = props.hospitalList || [];
    const hospitals = props.history.location.state?.hospitalsMatch;
    const hospitalsCode = hospitals?.map((item) => item.organization.code);

    // เมื่อ some match มากกว่า 1 ให้เลือกโรงพยาบาลได้เฉพาะที่ match; ModGettingStarted
    if (hospitalsCode) {
      list = list.filter((item) => hospitalsCode.includes(item.code));
    }

    return list;
  }, [props.hospitalList, props.history.location.state?.hospitalsMatch]);

  const handleChangeRadio = (item: any) => {
    setSelectedValue(item);
  };

  return (
    <div className={classes.screen} style={{...(props.safeAreaTop && {paddingTop: `${props.safeAreaTop}px`})}}>
      <div className={classes.header}>ระบุโรงพยาบาลเริ่มต้น</div>
      <div style={{ marginBottom: "1.25rem", padding: "0 20px" }}>
        กรุณาระบุโรงพยาบาลเริ่มต้นเพื่อการเข้าถึงข้อมูลสุขภาพ ที่รวดเร็ว
      </div>

      <div style={{ height: "calc(100vh - 250px)", overflow: "auto" }}>
        {(!props?.firstLoadingHospital
          ? new Array(5).fill("")
          : hospitalItems
        ).map((item: any, index: number) => (
          <CardItem
            key={item.name}
            data={item}
            checked={selectedValue?.code === item.code}
            firstLoadingHospital={props.firstLoadingHospital}
            // callback
            onSelect={() => {
              handleChangeRadio(item);
            }}
          />
        ))}
      </div>

      <div className="button-submit-bottom">
        <MuiButton
          variant="contained"
          color="primary"
          disabled={props.buttonLoading}
          onClick={() => {
            props.onEvent?.({
              message: "HandleSelectedHospital",
              params: { data: selectedValue },
            });
            props.onClose?.(selectedValue);
          }}
        >
          {intl.formatMessage({ id: "common.confirm" })}
          {props.buttonLoading && (
            <CircularProgress
              style={{
                width: "25px",
                height: "25px",
                marginLeft: "0.5rem",
              }}
            />
          )}
        </MuiButton>
      </div>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                        CardItem                        */

/* ------------------------------------------------------ */

// Types
type CardItemType = {
  data: any;
  disabled?: boolean;
  checked?: boolean;
  // config
  firstLoadingHospital?: boolean;
  // callback
  onSelect?: (data: any) => any;
};

const CardItem = (props: CardItemType) => {
  const classes = useStyles();

  const handleClick = () => {
    props.onSelect?.(props.data);
  };

  return (
    <Card
      key={props.data.name}
      className={`${classes.card}${props.disabled ? " --grey" : ""}`}
      onClick={handleClick}
    >
      <CardActionArea disabled={props.disabled}>
        <CardContent>
          {props.firstLoadingHospital ? (
            <>
              <div>
                <Radio
                  className={`smart-radio-button${
                    props.disabled ? " --grey" : ""
                  }`}
                  checked={props.checked}
                  name="radio-buttons"
                />
              </div>
              <div style={{ margin: "0.15rem 0 0 0.25rem" }}>
                {props.data.name}
                <div style={{ flex: 1 }}></div>
                <img
                  src={IMAGES.logo}
                  alt="logo bplus"
                  style={{ width: "24px" }}
                />
              </div>
            </>
          ) : (
            <>
              <div></div>
              <div>
                <Skeleton
                  animation="pulse"
                  height={18}
                  style={{}}
                  width="60%"
                />
                <div style={{ flex: 1 }}></div>
                <Skeleton
                  animation="pulse"
                  variant="circle"
                  width={27}
                  height={27}
                  style={{}}
                />
              </div>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(SelectInitialHospital);
