import { USER } from "./url";
import BaseService from "../react-lib/apis/services/BaseService";

class UserService extends BaseService {
    getUserProfile({headers, params } = {}) {
        return this.client.get(USER.USER_PROFILE, { headers, params } )
          .then(this.handleResponse)
          .catch(this.throwErrorMessage);
    }

    getMyProfile({headers, params } = {}) {
        return this.client.get(USER.USER_MY_PROFILE, { headers, params } )
          .then(this.handleResponse)
          .catch(this.throwErrorMessage);
    }

    patchUserProfile({headers, data} = {}) {
        return this.client.patch(USER.USER_PROFILE, data, {headers})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }
}

export default UserService;