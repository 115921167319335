import React, { useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  Message,
  Grid,
  Header,
  Icon,
  Button,
  Modal,
  Checkbox,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import {
  DrugDMBar,
  SubHistory,
  DURATION_OPTIONS,
} from "../../clinic/DMDrugTabHistory";
import moment from "moment";
import { HeaderChart } from "../../clinic/DMEmotionRestChart";
import { DATE_FORMAT } from "../../clinic/DMEmotionRest";

type WebDMMedicationComplianceProps = {
  drugDataTabHistory: any;
  loadingMedication: boolean;
  medicationShowFilter: any;
  selectedPatient: any;
  onEvent: any;
};

const WebDMMedicationComplianceInitial: WebDMMedicationComplianceProps = {
  drugDataTabHistory: {},
  loadingMedication: false,
  medicationShowFilter: {},
  selectedPatient: {},
  onEvent: () => {},
};

const WebDMMedicationCompliance: React.FC<WebDMMedicationComplianceProps> = (
  props
) => {
  const [view, setView] = useState<any>("7");
  const [selectedData, setSelectedData] = useState<any>({});
  const [openModSelect, setOpenModSelect] = useState<boolean>(false);
  const [dataChecked, setDataChecked] = useState<any>({});
  // const [selectedDate, setSelectedDate] = useState<string>(
  //   moment().locale("en").format("D MMMM YYYY")
  // );

  const [startDate, setStartDate] = useState(moment().add(-6, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [modSelect, setModSelect] = useState<boolean | undefined>(true);

  useEffect(() => {
    props.onEvent({
      message: "handleGetDrugHistory",
      params: {
        date: endDate.locale("en").format("D MMMM YYYY"),
        duration: `${view}_DAY`,
      },
    });
  }, [props.selectedPatient]);

  useEffect(() => {
    if (
      Object.keys(props.medicationShowFilter).length !==
      Object.keys(dataChecked).length
    )
      setDataChecked(props.medicationShowFilter);
  }, [props.medicationShowFilter]);

  // const handleChangeDate = (date) => {
  //   let dateConverter = date.split("/");
  //   let year = +dateConverter[2] - 543;
  //   let dateAD = moment(
  //     `${dateConverter[0]}/${dateConverter[1]}/${year}`,
  //     "DD/MM/YYYY"
  //   )
  //     .locale("en")
  //     .format("D MMMM YYYY");
  //   props.onEvent({
  //     message: "handleGetDrugHistory",
  //     params: { date: dateAD, duration: view },
  //   });
  //   setSelectedDate(dateAD);
  // };

  // const handleGetHistoryDate = (action: "back" | "next" | null = null) => {
  //   let date = moment(selectedDate);
  //   let date_string = "";
  //   if (action === "back") {
  //     date_string = moment(date)
  //       .add(-1, "days")
  //       .locale("en")
  //       .format("D MMMM YYYY");
  //     props.onEvent({
  //       message: "handleGetDrugHistory",
  //       params: { date: date_string, duration: view },
  //     });
  //     setSelectedDate(date_string);
  //   }
  //   if (action === "next") {
  //     date_string = moment(date)
  //       .add(1, "days")
  //       .locale("en")
  //       .format("D MMMM YYYY");
  //     props.onEvent({
  //       message: "handleGetDrugHistory",
  //       params: { date: date_string, duration: view },
  //     });
  //     setSelectedDate(date_string);
  //   }
  //   console.log("DateString:", date_string);
  // };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${view - 1}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(view - 1, "days");
    }

    props.onEvent({
      message: "handleGetDrugHistory",
      params: {
        date: todate.locale("en").format("D MMMM YYYY"),
        duration: `${view}_DAY`,
      },
    });

    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    let stDate = endDate.clone().add(-value + 1, "days");

    props.onEvent({
      message: "handleGetDrugHistory",
      params: {
        date: endDate.locale("en").format("D MMMM YYYY"),
        duration: `${value}_DAY`,
      },
    });

    setStartDate(stDate);
    setView(value);
  };

  // const handleChangeDuration = (event: any, data: any) => {
  //   setView(data.value);
  //   props.onEvent({
  //     message: "handleGetDrugHistory",
  //     params: { date: selectedData, duration: `${value}_day` },
  //   });
  // };

  const handleChangeSelectAll = (checked?: boolean) => {
    let drugChecked = { ...dataChecked };
    Object.keys(dataChecked).forEach((item) => {
      drugChecked[item] = checked;
    });
    setDataChecked(drugChecked);
    setModSelect(checked);
  };

  const handleChangeDrugCheckbox = (name: string, checked?: boolean) => {
    let drugChecked = { ...dataChecked };
    drugChecked[name] = checked;
    setDataChecked(drugChecked);
  };

  // console.log("medicationShowFilter:", props.medicationShowFilter, dataChecked);
  console.log("WebDMMedicationCompliance: ", selectedData);

  return (
    <div className="sub-layout dm">
      <Dimmer active={props.loadingMedication} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      {/* <div style={MedicationConst.styles.durationDiv}>
        <span style={MedicationConst.styles.durationFont}>Duration</span>
        <Dropdown
          style={MedicationConst.styles.durationDropdown}
          selection
          options={durationOptions}
          value={view}
          onChange={handleChangeDuration}
        />
      </div> */}
      <HeaderChart
        yAxesLabelLeft="%compliance"
        yAxesLabelRight=""
        startDate={startDate}
        endDate={endDate}
        durationOptions={DURATION_OPTIONS}
        selectedDuration={view}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        legendItem={{
          type: "rect",
          items: [
            {
              name: "Morning",
              color: "#97F2E0",
            },
            {
              name: "Afternoon",
              color: "#F9DD8D",
            },
            {
              name: "Evening",
              color: "#EE9191",
            },
            {
              name: "Bedtime",
              color: "#BDBDBD",
            },
            {
              name: "Other",
              color: "#FF7BCA",
            },
            {
              name: "No taken",
              color: "",
              image: "/images/exercise/marker-alert-outline.png",
            },
          ],
        }}
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          content="Select Medicine"
          color="green"
          onClick={() => {
            setOpenModSelect(true);
          }}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon
          name="backward"
          style={{ color: DrugDMConst.COLOR_CODE.lightGrey }}
          onClick={() => {
            handleGetHistoryDate("back");
          }}
        />
        <DateTextBox value={selectedDate} onChange={handleChangeDate} />
        <Icon
          name="forward"
          style={{ color: DrugDMConst.COLOR_CODE.lightGrey }}
          onClick={() => {
            handleGetHistoryDate("next");
          }}
        />
      </div> */}
      <DrugDMBar
        drugDataTabHistory={props.drugDataTabHistory}
        setSelectedData={setSelectedData}
        height={130}
        onEvent={props.onEvent}
        hideFooterChart={true}
        startDate={startDate}
        endDate={endDate}
      />

      {selectedData && (
        <>
          <Grid verticalAlign="middle" style={{ padding: "20px" }}>
            {/* Date */}
            <Grid.Row>
              <Grid.Column width={1}>
                <Icon
                  name="calendar alternate outline"
                  size="large"
                  style={{ color: "var(--text-dark-blue)" }}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as="h4" style={{ color: "var(--text-grey)" }}>
                  {moment(selectedData.date, "DD/MM/YYYY")
                    .locale("en")
                    .format("DD MMMM YYYY")}
                </Header>
              </Grid.Column>
            </Grid.Row>

            {/* Morning */}
            <SubHistory
              title="Morning"
              compliance={selectedData?.morning?.compliance}
              items={selectedData?.morning?.items}
              showMoreInfo={true}
            />

            {/* Afternoon */}
            <SubHistory
              title="Afternoon"
              compliance={selectedData?.afternoon?.compliance}
              items={selectedData?.afternoon?.items}
              showMoreInfo={true}
            />

            {/* Evening */}
            <SubHistory
              title="Evening"
              compliance={selectedData?.evening?.compliance}
              items={selectedData?.evening?.items}
              showMoreInfo={true}
            />

            {/* Bedtime */}
            <SubHistory
              title="Bedtime"
              compliance={selectedData?.bedtime?.compliance}
              items={selectedData?.bedtime?.items}
              showMoreInfo={true}
            />

            {/* Others */}
            <SubHistory
              title="Others"
              compliance={selectedData?.others?.compliance}
              items={selectedData?.others?.items}
              showMoreInfo={true}
            />

            <Grid.Row verticalAlign="top">
              <Grid.Column width={1}>
                <Icon
                  name="comment alternate outline"
                  size="large"
                  style={{ color: "orange" }}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as="h4" style={{ color: "var(--text-grey)" }}>
                  <FormattedMessage id="bplusClinicKey454" />
                </Header>
              </Grid.Column>
              <Grid.Column width={9}>
                <label style={{ color: "var(--text-grey)" }}>
                  {selectedData?.sideEffectNote || ""}
                </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {/* <Message
            color={props.drugDataTabHistory?.summary?.color}
            style={{ margin: "0px 20px 35px 20px" }}
          >
            <Message.Header>
              <Icon name="warning sign" size="large" />
              {props.drugDataTabHistory?.summary?.title}
            </Message.Header>
            {props.drugDataTabHistory?.summary?.detail}
          </Message> */}
          <Message
            // color={props.drugDataTabHistory?.summary?.color}
            color={"red"}
            style={{ margin: "0px 20px 35px 20px" }}
          >
            <Message.Header
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Icon name="warning sign" size="large" />
              {"Diabetic patient should"}
              {/* {props.drugDataTabHistory?.summary?.title} */}
            </Message.Header>
            <div style={{ textAlign: "center" }}>
              {"continuously take drug & observe yourself everyday."}
            </div>
            {/* {props.drugDataTabHistory?.summary?.detail} */}
          </Message>
        </>
      )}
      <Modal
        open={openModSelect}
        onClose={() => {
          setOpenModSelect(false);
        }}
        size="mini"
      >
        <div
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <Checkbox
            label="Select all"
            checked={modSelect}
            name="selectAll"
            style={{ padding: "5px" }}
            onChange={(e, { checked }) => {
              handleChangeSelectAll(checked);
            }}
          />
          {Object.keys(dataChecked).map((item) => {
            return (
              <Checkbox
                label={item}
                name={item}
                checked={dataChecked[item]}
                style={{ padding: "5px" }}
                onChange={(e, { checked }) => {
                  handleChangeDrugCheckbox(item, checked);
                }}
              />
            );
          })}
          <Button
            content="Show %compliance"
            color="green"
            style={{ padding: "5px" }}
            onClick={() => {
              props.onEvent({
                message: "handleGetDrugHistory",
                params: {
                  date: endDate.locale("en").format("D MMMM YYYY"),
                  duration: `${view}_DAY`,
                  drugShow: dataChecked,
                },
              });
              setOpenModSelect(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

WebDMMedicationCompliance.defaultProps = WebDMMedicationComplianceInitial;

export default React.memo(WebDMMedicationCompliance);
