import React, { ReactElement } from "react";
// MUI
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

type PaymentAlertMessageProps = {
  loading?: boolean;
  children: ReactElement | string;
  type?: "info-yellow" | "info-blue" | "contact" | "info-red";
  img?: string;
  // config
  textOnly?: boolean;
  // styles
  style?: CSSProperties;
};

// Images
const IMAGES = {
  medication: "/images/telephar/service-medication.png",
  information: "/images/payment/information.png",
  warning: "/images/payment/warning.png",
  error: "/images/Appointment/error.png",
};

// Styles
const COLORS = {
  blue: "var(--primary-theme-color)",
  half_light_grey: "rgba(233, 233, 233, 0.5)",
  light_grey: "rgba(196, 196, 196, 1)",
  grey: "rgba(57, 57, 57, 1)",
  border_info: "rgba(0, 139, 245, 1)",
  yellow: "rgba(243, 156, 18, 1)",
  border_yellow: "rgba(255, 238, 185, 1)",
  bg_contact: "rgba(1, 71, 163, 0.05)",
  light_yellow: " #FFF8E3",
  font_contact: "rgba(121, 120, 120, 1)",
  bg: "#ECF4FF",
  light_blue: "rgba(0, 139, 245, 1)",
  border_danger: "rgba(255, 173, 173, 1)",
  light_red: "rgba(255, 231, 231, 1)",
  red: "rgba(218, 0, 0, 1)",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const messageStyles = {
  display: "grid",
  padding: "16px",
  gridTemplateColumns: "auto 1fr",
  backgroundColor: COLORS.half_light_grey,
  borderRadius: "8px",
  alignItems: "center",
  "& > div:nth-child(1)": {
    ...styles.center,
    "& img": {
      width: "32px",
      marginRight: "14px",
    },
  },
  "& > div:nth-child(2)": {
    lineHeight: 1.5,
    color: COLORS.blue,
  },
};

const informationStyles = {
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  color: COLORS.light_blue,
  padding: "16px",
  borderRadius: "8px !important",
  backgroundColor: `${COLORS.bg}`,
  alignItems: "center",
  lineHeight: 1.5,
  marginBottom: 0,
  border: `1px solid ${COLORS.border_info}`,
  textAlign: "left",
  "& img": {
    width: "24px",
    marginTop: "0.5rem",
    marginRight: "1rem",
  },
  "&.info-yellow": {
    border: `1px solid ${COLORS.border_yellow}`,
    color: COLORS.yellow,
    background: `${COLORS.light_yellow} !important`,
  },
  "&.info-red": {
    border: `1px solid ${COLORS.border_danger}`,
    color: COLORS.red,
    background: `${COLORS.light_red} !important`,
  },
} as CSSProperties;

const useStyles = makeStyles(() => ({
  message: messageStyles,
  information: informationStyles,
  contact: {
    backgroundColor: `${COLORS.bg_contact} !important`,
    border: "0 !important",
    marginBottom: "0 !important",
    "& img": {
      width: "56px !important",
    },
    "& > div:nth-child(2)": {
      color: `${COLORS.font_contact} !important`,
    },
  },
  text_only: {
    border: "none",
    color: COLORS.grey,
  },
}));

const ICONS = {
  "info-yellow": IMAGES.warning,
  "info-red": IMAGES.error,
  "info-blue": IMAGES.information,
  contact: "",
};

const PaymentAlertMessage = (props: PaymentAlertMessageProps) => {
  const classes = useStyles();

  return (
    <>
      {props.type?.includes("info") ? (
        <div
          className={`${classes.information} ${props.type || ""} ${
            props.textOnly ? classes.text_only : ""
          }`}
          style={props.style}
        >
          <div>{!props.textOnly && <img src={ICONS[props.type || ""]} />}</div>
          <div>{props.children}</div>
        </div>
      ) : (
        <div
          className={`${classes.message} ${
            props.type === "contact" ? classes.contact : ""
          }`}
          style={props.style}
        >
          {props.loading ? (
            <>
              <div>
                <Skeleton
                  animation="pulse"
                  variant="rounded"
                  width={"32px"}
                  height={"32px"}
                  style={{ marginRight: "14px" }}
                />
              </div>
              <div>
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={"80%"}
                  height={16}
                />
                <Skeleton
                  animation="pulse"
                  variant="text"
                  width={"30%"}
                  height={16}
                  style={{ marginTop: "0.25rem" }}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <img
                  src={props.img ? props.img : IMAGES.medication}
                  alt="medication"
                />
              </div>
              <div>{props.children}</div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default React.memo(PaymentAlertMessage);
