import React from 'react'
import PreferredEditorHome from "./PreferredEditorHome";
import PreferredEditorAppointment from "./PreferredEditorAppointment";

const PreferredEditor = (props: any) => {
  return (
    props.mode === "HOME" ? <PreferredEditorHome {...props} /> : <PreferredEditorAppointment {...props} />
  )
}

export default PreferredEditor