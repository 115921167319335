import React, { ReactElement, useMemo } from "react";
// MUI
import Skeleton from "@mui/material/Skeleton";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Types
type ChipListItemProps = {
  className?: string;
  items: ItemType[];
  selected?: string | number | (number | string)[];
  minWidth: number;
  loading?: boolean;
  // config
  rightIcon?: boolean;
  highlight?: boolean;
  // callback
  onSelect?: (selected: ItemType) => any;
};

type ItemType = {
  id: number | string;
  value: number | string | number[];
  unit?: string;
  unit_code?: string;
  icon?: string | ReactElement;
  icon_active?: string | ReactElement;
};

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  light_blue_01: "rgba(1, 71, 163, 0.1)",
  bg: "#ECF4FF",
  highlight: "rgba(204, 218, 237, 1)",
  opacity: "rgba(133,133,133,0.03)",
  grey: "rgba(57, 57, 57, 1)",
};

const boxChipStyles = {
  gap: "11px",
  display: "grid",
  "& > div": {
    width: "100%",
    position: "relative",
  },
  "& div.highlight.active + div.highlight.active": {
    "&:before": {
      content: '""',
      width: "100%",
      height: "100%",
      background: COLORS.highlight,
      left: "-55%",
      position: "absolute",
    },
    "&.left:before": {
      display: "none",
    },
  },
  "& button": {
    border: `1px solid ${COLORS.light_blue_01}`,
    borderRadius: "8px",
    padding: ".4375rem 1rem",
    display: "flex",
    alignItems: "center",
    fontFamily: "inherit",
    justifyContent: "center",
    width: "100%",
    fontSize: ".9375rem",
    color: COLORS.grey,
    "& img": {
      width: "20px",
    },
  },
  "& .active": {
    "& button": {
      border: `1px solid ${COLORS.primary}`,
      backgroundColor: COLORS.bg,
      color: COLORS.primary,
      fontWeight: "bold",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  box_chip: boxChipStyles,
}));

const ChipListItem = (props: ChipListItemProps) => {
  const classes = useStyles();

  const maxItemsPerRow = useMemo(() => {
    const padding = 32;
    const gap = 11 + 5.5;

    return Math.floor((window.innerWidth - padding) / (props.minWidth + gap));
  }, [props.minWidth]);

  const items = useMemo(() => {
    const skeletons = Array(8)
      .fill("")
      .map((_, index) => ({ id: index + 1 } as any));

    return props.loading ? skeletons : props.items;
  }, [props.loading, props.items]);

  // Handler
  const handleSelect = (selected: ItemType) => () => {
    props.onSelect?.(selected);
  };

  return (
    <div
      className={`${classes.box_chip} ${props.className || ""}`}
      style={{
        gridTemplateColumns: `repeat(${maxItemsPerRow}, minmax(${props.minWidth}px, 1fr))`,
      }}
    >
      {items.map((item, index, self) => (
        <RenderContent
          key={"content-" + item.id}
          className={`${index % maxItemsPerRow === 0 ? "left" : ""} ${
            props.highlight ? "highlight" : ""
          }`}
          active={
            Array.isArray(props.selected)
              ? props.selected.includes(item.id)
              : item.id === props.selected
          }
          value={item.value}
          icon={item.icon}
          iconActive={item.icon_active}
          unit={item.unit}
          zIndex={self.length - index}
          loading={props.loading}
          // style
          justifyContentStyle={props.rightIcon ? "space-between" : ""}
          // callback
          onClick={handleSelect(item)}
        />
      ))}
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      RenderContent                     */

/* ------------------------------------------------------ */
type RenderContentProps = {
  className?: string;
  active: boolean;
  justifyContentStyle: string;
  value: ItemType["value"];
  icon: ItemType["icon"];
  iconActive: ItemType["icon_active"];
  unit: ItemType["unit"];
  zIndex: number;
  loading?: boolean;
  // callback
  onClick: () => any;
};

const RenderContent = (props: RenderContentProps) => {
  const valueContent = useMemo(() => {
    return Array.isArray(props.value)
      ? `${props.value[0]} - ${props.value[1]}`
      : props.value;
  }, []);

  const iconElement = useMemo(() => {
    const activeIcon =
      props.active && props.iconActive ? props.iconActive : props.icon;
    let type = "text";

    if (
      typeof props.icon === "string" &&
      typeof props.iconActive === "string"
    ) {
      type = "active";
    } else if (typeof props.icon === "string") {
      type = "img";
    } else if (props.icon) {
      type = "icon";
    }

    return {
      text: props.unit,
      active: <img src={activeIcon as string} />,
      image: <img src={props.icon as string} />,
      icon: props.iconActive || props.icon,
    }[type];
  }, []);

  return (
    <div
      className={`${props.active ? "active" : ""} ${props.className || ""}`}
      style={{ zIndex: props.zIndex }}
    >
      <MuiButton
        style={
          props.loading
            ? { background: COLORS.opacity, border: 0, boxShadow: "none" }
            : {
                justifyContent: props.justifyContentStyle,
              }
        }
        variant="text"
        onClick={props.onClick}
      >
        {props.loading ? (
          <Skeleton animation="pulse" width={"100%"} height={18} />
        ) : (
          <>
            {valueContent} {iconElement}
          </>
        )}
      </MuiButton>
    </div>
  );
};

export default React.memo(ChipListItem);
