import React from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import CloseIcon from "@mui/icons-material/Close";

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "20px 20px 0",
  },
  header: {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: COLORS.blue,
    padding: "1rem 0 0.25rem",
    marginBottom: "-0.5rem",
  },
}));

const HeaderConsentForm2 = (props: any) => {
  const classes = useStyles();

  const handleCloseConsent = () => {
    console.log("saika handleCloseConsent ");
    if (!props.notDisable) {
      if (typeof window.iosNative !== "undefined") {
        window.iosNative?.disableFeature?.("phr");
        window.iosNative?.dismissWebView?.();
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative?.disableFeature?.("phr");
        window.MobNative?.dismissWebView?.();
      } else if (typeof window.mobile !== "undefined") {
        window.mobile?.disableFeature?.("phr");
        window.mobile?.dismissWebView?.();
      }
    } else {
      props.onGoBack?.()
    }
  };

  return (
    <div className={classes.screen}>
      <div className="close-icon-right">
        <MuiButton
          color="primary"
          variant="text"
          className="--small"
          style={{ margin: 0 }}
          onClick={() => handleCloseConsent()}
        >
          <CloseIcon />
        </MuiButton>
      </div>
      <div className={classes.header}>{props.title}</div>
    </div>
  );
};

export default React.memo(HeaderConsentForm2);
