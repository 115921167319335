import React, { useEffect, useState } from "react";
import moment from "moment";
import { Grid, Progress } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

// Interface
import { BACKEND_DATE_FORMAT, calculateDelivery, formatDateByLanguage } from "../MobClinicInterface";

import "../../css/WebClinic.scss";

const DATE_FORMAT = "DD/MM/YYYY";

type MomProfileHealthPregnancyProps = {
  profileObstretic?: any;
};

const MomProfileHealthPregnancyInitial: MomProfileHealthPregnancyProps = {
  profileObstretic: {},
};

const MomProfileHealthPregnancy: React.FC<MomProfileHealthPregnancyProps> = (
  props
) => {
  const [deliveryDetail, setDeliveryDetail] = useState<any>({});

  useEffect(() => {
    if (props.profileObstretic?.hasOwnProperty("id")) {
      const deliveryResult = calculateDelivery(
        props.profileObstretic?.data?.by_ultrasound,
        moment(),
        moment(props.profileObstretic?.data?.lmp_date, BACKEND_DATE_FORMAT),
        moment(props.profileObstretic?.data?.edc_date, BACKEND_DATE_FORMAT)
      );
      setDeliveryDetail(deliveryResult);
    }
  }, [props.profileObstretic]);
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px 0px",
      }}
    >
      <Grid style={{ width: "90%", border: "1px solid #A2D7E8" }}>
        <Grid.Row
          style={{
            color: "var(--text-dark-blue)",
            fontWeight: "bold",
            paddingBottom: "0px",
          }}
        >
          <Grid.Column width={10}><FormattedMessage id="bplusClinicKey210" /></Grid.Column>
          <Grid.Column width={6} style={{ textAlign: "right" }}>
            <FormattedMessage id="bplusClinicKey83" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{ color: "var(--text-grey)", padding: "0px 0px 10px" }}
        >
          <Grid.Column width={10}>
            {deliveryDetail?.ga_weeks} <FormattedMessage id="bplusClinicKey776"/>  &nbsp;
            {deliveryDetail?.ga_days} <FormattedMessage id="profile.days" />
          </Grid.Column>
          <Grid.Column width={6} style={{ textAlign: "right" }}>
            {deliveryDetail?.bb_weeks} <FormattedMessage id="bplusClinicKey776"/> &nbsp;
            {deliveryDetail?.bb_days} <FormattedMessage id="profile.days" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={16} style={{ padding: "0px 15px 10px" }}>
          <Progress
            size="tiny"
            percent={(deliveryDetail?.ga_total_day * 100) / 280}
            color="blue"
            style={{ margin: "0px" }}
          />
        </Grid.Column>

        <Grid.Row
          style={{ color: "var(--text-grey)", padding: "0px 0px 15px" }}
        >
          <Grid.Column width={10}>
            {deliveryDetail?.ga_total_day} <FormattedMessage id="bplusClinicKey562" />
            {deliveryDetail?.trimester?.value} <FormattedMessage id="bplusClinicKey588" />
          </Grid.Column>
          <Grid.Column width={6} style={{ textAlign: "right" }}>
            <div>{deliveryDetail?.bb_total_day} <FormattedMessage id="bplusClinicKey561" /></div>
            <div>EDD: &nbsp; 
              {formatDateByLanguage(
                moment(deliveryDetail?.bb_edd)?.format(
                  DATE_FORMAT
                ),
                "DD MMMM YYYY"
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

MomProfileHealthPregnancy.defaultProps = MomProfileHealthPregnancyInitial;

export default React.memo(MomProfileHealthPregnancy);
