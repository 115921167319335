import WasmController from "../../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// APIs
import V3TopServicesListView from "../../issara-sdk/apis/V3TopServicesListView_apps_PRX";
import V3AvailableDoctorListView from "../../issara-sdk/apis/V3AvailableDoctorListView_apps_PRX";
import V3SearchProgramSpecialtyListView from "../../issara-sdk/apis/V3SearchProgramSpecialtyListView_apps_PRX";
import V3PinnedSpecialties from "../../issara-sdk/apis/V3PinnedSpecialties_apps_PRX";
import V3PatientHospital from "../../issara-sdk/apis/V3PatientHospital_apps_PRX";
import V3MedProgramsDetailView from "../../issara-sdk/apis/V3MedProgramsDetailView_apps_PRX";
import CheckRequireDiagRuleView from "../../issara-sdk/apis/CheckRequireDiagRuleView_apps_PRX";

// interface
import { GetHospitalConsentList } from "../MobSelectHospitalInterface";
import {
  SelectedInitialHospital,
  HandleSelectedHospital as SelectedHospital,
} from "./SelectHospitalInterface";

export type State = {
  loadingMakeAppointment?: boolean;
  firstLoadingMakeAppointment?: boolean;
  firstLoadingProgramSpecialty?: boolean;
  firstLoadingAvailableDoctor?: boolean;
  firstLoadingTopService?: boolean;
  firstLoadingHospital?: boolean;
  loadingSearch?: boolean;
  textSearch?: string;
  loadingSearchHostipalFilter?: boolean;
  textSearchHostipalFilter?: string;
  // Data
  topServiceList?: any[];
  availableDoctorList?: any[];
  programSpecialtyList?: any[];
  hospitalList?: any[];
  hospitalConsentList?: any[];
  appointmentSearchList?: {
    doctor: {
      data: any[];
      nextPage: number;
      endOfItem: boolean;
    };
    specialty: {
      data: any[];
      nextPage: number;
      endOfItem: boolean;
    };
    department: {
      data: any[];
      nextPage: number;
      endOfItem: boolean;
    };
  };
  patientDetail?: any;
  nextPageAvailableDoctor?: number;
  endOfAvailableDoctor?: boolean;
  nextPageProgramSpecialty?: number;
  endOfProgramSpecialty?: boolean;

  selectedTopService?: any;
  hospitalFilterOptions?: any[];
  // selectedHospitalFilter?: any;
  selectedHospital?: any;
  // Open
  openModSearchDoctor?: boolean;
  menuTabMakeAppointment?: "doctor" | "specialty" | "";
  tmpMakeAppointmentData?: {
    textSearch?: string;
    appointmentFilter?: {
      department: number | string;
      availability: number | string;
      fee: number | string;
      experience: number | string;
      gender: number | string;
      rate: number | string;
    };
    menuTabMakeAppointment?: "doctor" | "specialty";
    availableDoctorList?: any[];
    programSpecialtyList?: any[];
    nextPageAvailableDoctor?: number;
    endOfAvailableDoctor?: boolean;
    nextPageProgramSpecialty?: number;
    endOfProgramSpecialty?: boolean;
    selectedTopService?: any;
    selectedHospital?: any;
    firstLoadingProgramSpecialty?: boolean;
  };
  viewIndexSearchDoctor?: number;
  isSelectTelemed?: boolean;
  appointmentFooterFilter?: {
    doctor: {
      online: boolean;
      onsite: boolean;
      sortby: string;
    };
    specialty: {
      online: boolean;
      onsite: boolean;
      sortby: string;
    };
  };
  language?: "en-US" | "th-TH";
  // config_PRX_ENABLE_ECOUPON?: boolean;
  // loadingConfigEnableEcouponSuccess?: boolean;
  // prepareMedProgramPushHistory?: any;
};

export const StateInitial: State = {
  loadingMakeAppointment: false,
  firstLoadingMakeAppointment: false,
  firstLoadingProgramSpecialty: false,
  firstLoadingAvailableDoctor: true,
  firstLoadingTopService: false,
  firstLoadingHospital: false,
  loadingSearch: false,
  textSearch: "",
  loadingSearchHostipalFilter: false,
  textSearchHostipalFilter: "",
  nextPageAvailableDoctor: 0,
  endOfAvailableDoctor: false,
  nextPageProgramSpecialty: 0,
  endOfProgramSpecialty: false,

  // Data
  topServiceList: [],
  availableDoctorList: [],
  programSpecialtyList: [],
  hospitalList: [],
  appointmentSearchList: {
    doctor: {
      data: [],
      nextPage: 0,
      endOfItem: false,
    },
    specialty: {
      data: [],
      nextPage: 0,
      endOfItem: false,
    },
    department: {
      data: [],
      nextPage: 0,
      endOfItem: false,
    },
  },
  patientDetail: {},
  selectedTopService: null,
  hospitalFilterOptions: [
    { key: 1, value: "near_by", text: "Near by" },
    { key: 2, value: "pass_visit", text: "Pass visit" },
  ],
  // selectedHospitalFilter: "pass_visit",
  selectedHospital: null,
  // Open
  openModSearchDoctor: false,
  menuTabMakeAppointment: "",
  tmpMakeAppointmentData: {},
  viewIndexSearchDoctor: 0,
  isSelectTelemed: false,
  appointmentFooterFilter: {
    doctor: {
      online: true,
      onsite: true,
      sortby: "availability",
    },
    specialty: {
      online: true,
      onsite: true,
      sortby: "availability",
    },
  },
  // prepareMedProgramPushHistory: null
};

export type Event =
  | { message: "GetTopServiceList"; params: {} }
  | {
      message: "GetListMakeAppointment";
      params: {
        page: number;
        telemed?: boolean;
        onsite?: boolean;
        history?: any;
        medProgram?: string;
      };
    }
  | { message: "HandleGetTopService"; params: { loading?: boolean; code?: string; simulator?: boolean; } }
  | { message: "GetListProgramSpecialty"; params: { [key: string]: string } }
  | {
      message: "GetListAvailableDoctor";
      params: {
        gender?: any;
        page?: number;
        isConcat?: boolean;
        specialty?: number;
        hospital?: number;
        telemed?: boolean;
        onsite?: boolean;
        score?: number;
      };
    }
  | { message: "HandleSelectTopService"; params: { data: any; history: any } }
  | {
      message: "HandleSearchAvailableDoctor";
      params: {
        name: string;
        page?: number;
        isConcat?: boolean;
        type?: "doctor" | "special" | "department";
        limit?: number;
        emptyClear?: boolean;
        hospital?: string;
      };
    }
  | { message: "SetOpenModSearchDoctor"; params: { open: boolean } }
  // | { message: "HandleChangeHospitalFilter"; params: { value: any } }
  | { message: "GetListHospital"; params: {} }
  | { message: "HandleSelectedHospital"; params: { data: any } }
  | {
      message: "HandleSetMenuTabMakeAppointment";
      params: { value: "doctor" | "specialty" };
    }
  | { message: "HandleSetViewIndexSearchDoctor"; params: { value: any } }
  | { message: "HandleSelectedCardItem"; params: { data: any; history: any } }
  | { message: "HandleCloseTeleConsult"; params: { data: any; history: any } }
  | { message: "HandleEvent"; params: { name: any } }
  | {
      message: "HandleSetAppointmentFooterFilter";
      params: { key: any; type: any; value: any };
    };

export type Data = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const limit = 20;

export const GetTopService: Handler = async (controller, params) => {
  let state = controller.getState();

  console.log("GetTopService state: ", state);
  const { isSelectTelemed, selectedHospital } = controller.getState();
  let lang = undefined;
  if (globalThis.window.mobile.iOSLang) {
    lang = globalThis.window.navigator?.language;
  }

  console.log("GetTopService selectedHospital?.code: ", selectedHospital?.code);
  console.log("GetTopService params?.code: ", params?.code);

  const [res, error, network] = await V3TopServicesListView.list({
    apiToken: Cookies.get("apiToken"),
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
    params: {
      hospital: params?.code ? params?.code : selectedHospital?.code,
    },
  });

  return [res, error, network];
};

export const GetAvailableDoctor: Handler = async (controller, params) => {
  const { isSelectTelemed } = controller.getState();
  let lang = undefined;
  if (globalThis.window.mobile.iOSLang) {
    lang = globalThis.window.navigator?.language;
  }

  const [res, error, network] = await V3AvailableDoctorListView.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      limit: params.limit ? params.limit : limit,
      offset: limit * ((params.page || 1) - 1),
      gender: params.gender,
      name: params.name,
      specialty: params.specialty,
      subspecialty: params.subspecialty,
      hospital: params.hospital,
      telemed: params.telemed ? params.telemed : isSelectTelemed,
      onsite: params.onsite,
      score: params.score,
    },
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [res, error, network];
};

export const GetProgramSpecialty: Handler = async (controller, params) => {
  const { isSelectTelemed, selectedHospital } = controller.getState();

  let hospitalCode = selectedHospital?.code;
  let lang = undefined;
  if (globalThis.window.mobile.iOSLang) {
    lang = globalThis.window.navigator?.language;
  }

  const [res, error, network] = await V3SearchProgramSpecialtyListView.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      limit: params.limit ? params.limit : limit,
      offset: limit * ((params.page || 1) - 1),
      search: params.name,
      med_program: params.med_program,
      subspecialty: params.subspecialty,
      telemed: params.telemed ? params.telemed : isSelectTelemed,
      specialty: params.specialty,
      onsite: params.onsite,
      ...(hospitalCode && { hospital: hospitalCode }),
    },
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [res, error, network];
};

export const GetDepartment: Handler = async (controller, params) => {
  let lang = undefined;
  if (globalThis.window.mobile.iOSLang) {
    lang = globalThis.window.navigator?.language;
  }

  const [res, error, network] = await V3PinnedSpecialties.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      limit: params.limit ? params.limit : limit,
      offset: limit * ((params.page || 1) - 1),
    },
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [res, error, network];
};

export const GetHospital: Handler = async (controller, params) => {
  let lang = window.navigator?.language;;
  if (window.mobile?.iOSLang) {
    lang = window.mobile.iOSLang;
  }
  const [res, error, network] = await V3PatientHospital.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      search: params.name,
    },
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [res, error, network];
};

// export const GetConfigPrxEnableEcoupon: Handler = async (
//   controller,
//   params
// ) => {
//   // controller.setState({ loadingConfigEnableEcouponSuccess: false })
//   // const [res, error] = await UserPermissionView.post({
//   //   apiToken: Cookies.get("apiToken"),
//   //   data: { config_PRX_ENABLE_ECOUPON: "" },
//   // });

//   // controller.setState({
//   //   // config_PRX_ENABLE_ECOUPON: true, //res?.config_PRX_ENABLE_ECOUPON,
//   //   loadingConfigEnableEcouponSuccess: true,
//   // });

//   return true; //res?.config_PRX_ENABLE_ECOUPON
// };

export const GetListMakeAppointment: Handler = async (controller, params) => {
  const pathName = globalThis.location.pathname;
  const { tmpMakeAppointmentData } = controller.getState();

  HandleGetHospitalConsent(controller, params);

  // initial select hospital
  const [hospitalDetail] = await Promise.all([
    HandleSelectHospitalInitial(controller, params),
    // HandleGetTopService(controller, {}),
  ]);

  const isTelemed =
    pathName?.includes("/tele-consult") &&
    !Object.keys(tmpMakeAppointmentData || {})?.[0];

  if (isTelemed) {
    return;
  }

  console.log("hospitalDetail: ", hospitalDetail);
  HandleGetAvailableDoctor(controller, {
    ...params,
    hospital: hospitalDetail?.code,
  });
};

export const HandleGetTopService: Handler = async (controller, params) => {
  console.log("HandleGetTopService: params", params);

  if(params.loading){
    controller.setState({ firstLoadingTopService: false })
  }

  var temp: any[] = [[null, null], {}];
  if ((params?.simulator && params?.code) || !params?.simulator) {
    temp = await Promise.all([
      GetTopService(controller, params),
      // GetConfigPrxEnableEcoupon(
      //   controller as any,
      //   params
      // )
      {},
    ]);
  }

  // console.log("temp: ", temp);
  const [[resTopService, errTopService], configPrxEnableEcoupon] = temp;
  // console.log("configPrxEnableEcoupon: ", configPrxEnableEcoupon);

  HandleSelectMedProgramAuto(controller, { resTopService, ...params });

  const teleConsult = {
    active: true,
    display: "Tele-consult",
    icon: null,
    icon_url: "/images/Appointment/telemed.png",
    id: "telemed",
    is_telemed: true,
  };

  const ecoupon = {
    active: true,
    display: "E-coupon",
    icon: null,
    icon_url: "/images/Appointment/e-coupon.png",
    id: "ecoupon",
    is_ecoupon: true,
  };

  const topService = [teleConsult, ecoupon, ...(resTopService?.items || [])];

  if (!configPrxEnableEcoupon) {
    topService.splice(1, 1);
  }

  controller.setState({
    topServiceList: topService,
    firstLoadingTopService: true,
  });
};

const HandleGetAvailableDoctor: Handler = async (controller, params) => {
  const [doctorRes, doctorErr, doctorNet] = await GetAvailableDoctor(
    controller,
    params
  );

  if (doctorErr?.hospital && !params.refresh) {
    // initial select hospital
    const hospitalDetail = await HandleSelectHospitalInitial(controller, {
      reset: true,
    });

    return HandleGetAvailableDoctor(controller, {
      ...params,
      hospital: hospitalDetail?.code,
      refresh: true,
    });
  }

  controller.setState({
    availableDoctorList: doctorRes?.items || [],
    firstLoadingMakeAppointment: true,
    nextPageAvailableDoctor: params.page,
    firstLoadingAvailableDoctor: true,
    loadingMakeAppointment: false,
  });
};

export const HandleGetHospitalConsent: Handler = async (controller, params) => {
  const [res] = await GetHospitalConsentList(controller as any, params);

  controller.setState({
    hospitalConsentList: res,
  });

  return res;
};

const HandleSelectMedProgramAuto: Handler = (controller, params) => {
  const { menuTabMakeAppointment } = controller.getState();
  const { resTopService } = params;

  if (params.medProgram && !menuTabMakeAppointment) {
    const findService = (resTopService?.items || []).find((item: any) =>
      item.med_programs.includes(+params.medProgram)
    );
    HandleSelectTopService(controller, {
      data: findService,
      history: params.history,
    });
    controller.setState({ menuTabMakeAppointment: "specialty" });
  } else if (!menuTabMakeAppointment) {
    controller.setState({ menuTabMakeAppointment: "doctor" });
  }
};

export const HandleSelectHospitalInitial: Handler = async (
  controller,
  params
) => {
  const { language } = controller.getState();
  const [selectedHospital] = await SelectedInitialHospital(controller as any, {
    reset: params.reset,
  });
  
  console.log('selectedHospital: ', selectedHospital);
  controller.setState({
    firstLoadingHospital: true,
  });

  if (!selectedHospital) {
    controller.setState({
      selectedHospital: selectedHospital || {
        code: "1",
        name:
          language === "th-TH"
            ? "โรงพยาบาลกรุงเทพ สำนักงานใหญ่"
            : "Bangkok Hospital Headquarters",
      },
    });
  }

  return selectedHospital;
};

export const GetListProgramSpecialty: Handler = async (controller, params) => {
  const { programSpecialtyList, menuTabMakeAppointment } =
    controller.getState();
  const { isConcat, page, isTopService, history, isLoading } = params;

  if (isLoading) {
    controller.setState({ firstLoadingProgramSpecialty: false });
  }

  const [res, error] = await GetProgramSpecialty(controller, params);

  let list: any[] = isConcat
    ? [...(programSpecialtyList || []), ...(res?.items || [])]
    : res?.items || [];
  const end = !res?.items?.[0] && page > 1 ? true : false;

  if (isTopService) {
    if (list.length === 1) {
      await HandleSelectedCardItem(controller, { data: list[0], history });
      list = programSpecialtyList || [];
    } else {
      controller.setState({
        firstLoadingProgramSpecialty: true,
        menuTabMakeAppointment: "specialty",
        selectedTopService: params.topServiceData,
      });
    }

    controller.setState({ loadingMakeAppointment: false });
  } else {
    controller.setState({ firstLoadingProgramSpecialty: true });
  }

  controller.setState({
    programSpecialtyList: list,
    nextPageProgramSpecialty: page,
    endOfProgramSpecialty: end,
  });
};

export const GetListAvailableDoctor: Handler = async (controller, params) => {
  const { availableDoctorList, nextPageAvailableDoctor } =
    controller.getState();
  const { isConcat, page } = params;
  if (!isConcat) {
    controller.setState({ firstLoadingAvailableDoctor: false });
  }
  const [res, error] = await GetAvailableDoctor(controller, params);

  const list = isConcat
    ? [...(availableDoctorList || []), ...(res?.items || [])]
    : res?.items || [];
  const end = !res?.items?.[0] && page > 1 ? true : false;

  controller.setState({
    availableDoctorList: list,
    nextPageAvailableDoctor: page,
    endOfAvailableDoctor: end,
    firstLoadingAvailableDoctor: true,
  });
};

export const HandleSelectTopService: Handler = (controller, params) => {
  const state = controller.getState();
  const { selectedTopService, appointmentFooterFilter } = state;
  const { data, history } = params;
  const { online: telemed, onsite } = appointmentFooterFilter?.specialty || {};
  // E-coupon
  if (data.is_ecoupon) {
    controller.setState({ loadingMakeAppointment: true });
    globalThis.location.href = `/ecoupon?app=MobCoupon`;
    return;
  }

  // controller.setState({ firstLoadingProgramSpecialty: false });

  if (selectedTopService?.id === data?.id) {
    controller.setState({ selectedTopService: null });

    GetListProgramSpecialty(controller, { telemed, onsite });
    return;
  }

  let paramsUrl: any = { page: 1, history };
  if (data?.type) {
    const type: "subspecialty" | "med_program" = data.type;
    paramsUrl[type] =
      data[
        type === "med_program" ? "med_programs" : "subspecialties"
      ].toString();
  }

  if (selectedTopService?.id !== data?.id && !data?.is_telemed) {
    paramsUrl.isTopService = true;
    paramsUrl.topServiceData = data;
    controller.setState({ loadingMakeAppointment: true });
    // GetListProgramSpecialty(controller, paramsUrl)
    GetListProgramSpecialty(controller, { ...paramsUrl, telemed, onsite });
  }

  if (data?.is_telemed) {
    const {
      textSearch,
      appointmentFilter,
      menuTabMakeAppointment,
      availableDoctorList,
      programSpecialtyList,
      nextPageAvailableDoctor,
      endOfAvailableDoctor,
      nextPageProgramSpecialty,
      endOfProgramSpecialty,
      selectedTopService,
      selectedHospital,
      firstLoadingProgramSpecialty,
    }: any = state;

    controller.setState(
      {
        tmpMakeAppointmentData: {
          textSearch,
          appointmentFilter,
          menuTabMakeAppointment,
          availableDoctorList,
          programSpecialtyList,
          nextPageAvailableDoctor,
          endOfAvailableDoctor,
          nextPageProgramSpecialty,
          endOfProgramSpecialty,
          selectedTopService,
          selectedHospital,
          firstLoadingProgramSpecialty,
        },
        textSearch: "",
        appointmentFilter: {},
        menuTabMakeAppointment: "doctor",
        availableDoctorList: [],
        programSpecialtyList: [],
        nextPageAvailableDoctor: 0,
        endOfAvailableDoctor: false,
        nextPageProgramSpecialty: 0,
        endOfProgramSpecialty: false,
        firstLoadingAvailableDoctor: false,
        firstLoadingProgramSpecialty: false,
        isSelectTelemed: true,
      },
      () => GetListMakeAppointment(controller, { page: 1 })
    );
    history.push("/tele-consult/?app=MobAppointment");
  }
};

export const HandleSearchAvailableDoctor: Handler = async (
  controller,
  params
) => {
  if (params.emptyClear && !params.name) {
    controller.setState({
      loadingSearch: false,
      textSearch: params.name,
      appointmentSearchList: {
        doctor: {
          data: [],
          nextPage: 0,
          endOfItem: false,
        },
        specialty: {
          data: [],
          nextPage: 0,
          endOfItem: false,
        },
        department: {
          data: [],
          nextPage: 0,
          endOfItem: false,
        },
      },
    });
    return;
  }

  controller.setState({ loadingSearch: true, textSearch: params.name });

  const { appointmentSearchList, selectedHospital } = controller.getState();
  const { isConcat, page, type } = params;
  let searchList: any = {};

  const getData = {
    doctor: () =>
      GetAvailableDoctor(controller, {
        ...params,
        hospital: selectedHospital?.code,
      }),
    specialty: () => GetProgramSpecialty(controller, params),
    department: () => GetDepartment(controller, params),
  };

  if (isConcat) {
    const [res, error] = await (getData as any)[type]();
    const end = !res?.items?.[0] && page > 1 ? true : false;

    searchList = {
      ...appointmentSearchList,
      [type]: {
        data: [
          ...((appointmentSearchList as any)?.[type]?.data || []),
          ...(res.items || []),
        ],
        nextPage: page,
        endOfItem: end,
      },
    };
  } else {
    const response = await Promise.all([getData.doctor(), getData.specialty()]);

    searchList = {
      doctor: {
        data: response[0]?.[0]?.items || [],
        nextPage: 1,
        endOfItem: false,
      },
      specialty: {
        data: response[1]?.[0]?.items || [],
        nextPage: 1,
        endOfItem: false,
      },
      // department: { data: response[2]?.[0]?.items || [], nextPage: 1, endOfItem: false }
    };
  }
  controller.setState({
    loadingSearch: false,
    appointmentSearchList: { ...searchList },
  });
};

export const SetOpenModSearchDoctor: Handler = (controller, params) => {
  controller.setState({ openModSearchDoctor: params.open });
};

// export const HandleChangeHospitalFilter: Handler = (controller, params) => {
//   // controller.setState({ selectedHospitalFilter: params.value });
// };

export const GetListHospital: Handler = async (controller, params) => {
  controller.setState({ loadingSearchHostipalFilter: true });
  console.log("GetListHospital");
  const [res, error] = await GetHospital(controller, params);

  console.log("GetListHospital");
  controller.setState({
    hospitalList: res?.items || [],
    firstLoadingHospital: true,
    textSearchHostipalFilter: params.name,
    loadingSearchHostipalFilter: false,
  });
};

export const HandleSelectedHospital: Handler = (controller, params) => {
  const data = JSON.parse(JSON.stringify(params.data)); // e.g. {code: "1", name: "Bangkok Hospital Headquarters"}

  SelectedHospital(controller as any, { data: params.data });

  const { appointmentFilter, selectedHospital, appointmentFooterFilter }: any =
    controller.getState();
  const { gender, department, rate } = appointmentFilter;
  const { online: telemed, onsite } = appointmentFooterFilter?.doctor || {};

  // if (data?.code !== selectedHospital?.code) {
  GetListAvailableDoctor(controller, {
    gender,
    page: 1,
    specialty: department,
    hospital: +data.code,
    telemed,
    onsite,
    score: rate,
  });
  // }
};

export const HandleSetMenuTabMakeAppointment: Handler = (
  controller,
  params
) => {
  controller.setState({ menuTabMakeAppointment: params.value });
};

export const HandleSelectedCardItem: Handler = async (controller, params) => {
  const { patientDetail, selectedHospital, language } = controller.getState();
  const { data, history } = params;
  const app = "MobAppointment";

  const hospitalDetail = selectedHospital
    ? { ...selectedHospital, id: +selectedHospital.code }
    : {
        code: "1",
        id: 1,
        name:
          language === "th-TH"
            ? "โรงพยาบาลกรุงเทพ สำนักงานใหญ่"
            : "Bangkok Hospital Headquarters",
      };

  if (data?.is_doctor) {
    history.push({
      pathname: `/select-date/?app=${app}`,
      state: {
        patientDetail: patientDetail,
        doctorDetail: data,
        appointment_type: "specialty",
        hospitalDetail,
        is_telemed: data.is_telemed,
        is_onsite: data.is_onsite,
        showTopbar: true,
        subspecialty: data.subspecialty?.map((item: any) => item.id)?.join(","),
        ...history.location.state,
      },
    });
  } else if (data.type === "med_program") {
    controller.setState({ loadingMakeAppointment: true });
    const { selectedHospital, patientDetail } = controller.getState();
    const pushHistory = await goToCheckupData({
      medProgramId: data?.id,
      history: params.history,
      patientDetail,
      selectedHospital,
    });

    params.history.push(pushHistory);

    controller.setState({ loadingMakeAppointment: false });
  } else if (data.type === "subspecialty") {
    history.push(
      await {
        pathname: `/select-doctor/${data.id}/?app=${app}`,
        state: {
          service: data.service_id,
          [data.type]: data.id,
        },
      }
    );
  }
};

export const goToCheckupData = async (
  params: {
    medProgramId?: number;
    selectedHospital?: any;
    patientDetail?: any;
    history?: any;
  } = {}
) => {
  const { medProgramId, selectedHospital, patientDetail } = params;
  const [res, error] = await V3MedProgramsDetailView.retrieve({
    apiToken: Cookies.get("apiToken"),
    pk: medProgramId,
  });

  const hospitalDetail = selectedHospital
    ? { ...selectedHospital, id: +selectedHospital.code }
    : null;
  let pushHistory = {
    pathname: "",
    state: {} as any,
  };

  const pushState: any = {
    patientDetail,
    hospitalDetail,
    service: res.service,
    place: res.place,
    doctor_code: res.doctor_code,
    med_program: res.id,
    doctor: res.doctor,
    division: res.division,
    booking_service_code: res.booking_service_code,
    appointment_type: "check_up",
    check_up_image: res.image || res.image_url,
    product_name: res.name,
    is_telemed: res.is_telemed,
    showTopbar: true,
  };

  if (res?.diag_rule) {
    const [resCheck, errorCheck] = await CheckRequireDiagRuleView.get({
      apiToken: Cookies.get("apiToken"),
      params: {
        division: res.division,
        diag_rule: res?.diag_rule,
      },
    } as any);

    if (!errorCheck && !resCheck?.finished) {
      pushState.is_consent_form = true;
      pushHistory = {
        pathname: `/form/${
          resCheck.publish_diag_rule
        }/?app=${"MobAppointment"}&locCode=${res.location_code}`,
        state: pushState,
      };
    } else {
      pushHistory = {
        pathname: `/select-date/${res.location_code}/?app=${"MobAppointment"}`,
        state: pushState,
      };
    }
  } else {
    pushHistory = {
      pathname: `/select-date/${res.location_code}/?app=${"MobAppointment"}`,
      state: pushState,
    };
  }

  return pushHistory;
};

export const HandleSetViewIndexSearchDoctor: Handler = (controller, params) => {
  controller.setState({ viewIndexSearchDoctor: params.value });
};

export const HandleCloseTeleConsult: Handler = (controller, params) => {
  const { tmpMakeAppointmentData: tmp }: any = controller.getState();
  controller.setState({
    tmpMakeAppointmentData: {},
    textSearch: tmp.textSearch,
    appointmentFilter: tmp.appointmentFilter,
    menuTabMakeAppointment: tmp.menuTabMakeAppointment,
    availableDoctorList: tmp.availableDoctorList,
    programSpecialtyList: tmp.programSpecialtyList,
    nextPageAvailableDoctor: tmp.nextPageAvailableDoctor,
    endOfAvailableDoctor: tmp.endOfAvailableDoctor,
    nextPageProgramSpecialty: tmp.nextPageProgramSpecialty,
    endOfProgramSpecialty: tmp.endOfProgramSpecialty,
    selectedTopService: tmp.selectedTopService,
    firstLoadingAvailableDoctor: true,
    selectedHospital: tmp.selectedHospital,
    firstLoadingProgramSpecialty: tmp.firstLoadingProgramSpecialty,
    isSelectTelemed: false,
  });
  params.history.goBack();
};

export const HandleSetAppointmentFooterFilter: Handler = (
  controller,
  params
) => {
  const { appointmentFooterFilter } = controller.getState();
  const { key, type, value } = params;
  const cloneFilter = { ...(appointmentFooterFilter || {}) };

  if (cloneFilter) {
    (cloneFilter as any)[key][type] = value;
  }

  controller.setState({ appointmentFooterFilter: cloneFilter as any });
};
