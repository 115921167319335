import React from "react";
import { Icon, Input } from "semantic-ui-react";

export const FeedHead = (props) => (
  <div style={{ width: "100%", display: "flex", alignItems: "stretch" }}>
    <div
      style={{
        border: "solid #dddddd 1px",
        display: "flex",
        alignItems: "center",
      }}
      onClick={(e) => {
        props.onEvent({
          message: "ScanPHR",
          params: {},
        });
      }}
    >
      <Icon name="call" style={{ minWidth: "25px", margin: 0 }} />
    </div>
    <div
      style={{
        border: "solid #dddddd 1px",
        display: "flex",
        alignItems: "center",
      }}
      onClick={(e) => {
        props.history.push("/nearby/?app=Mob");
      }}
    >
      <Icon
        name="map marker alternate"
        style={{ minWidth: "25px", margin: 0 }}
      />
    </div>
    <Input icon="search" style={{ flex: 1 }} />
  </div>
);

export default FeedHead;
