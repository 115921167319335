import WasmController from "../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// Interface
import * as MobHistoryI from "./MobHistoryinterface";
import * as MobLABI from "./MobLABInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";

// Api
import V3MedicationView from "../issara-sdk/apis/V3MedicationView_apps_PRX";
import V3MedicationFullView from "../issara-sdk/apis/V3MedicationFullView_apps_PRX";
import CurrentMedication from "../issara-sdk/apis/CurrentMedication_apps_PHR";

// type
import { ExtraType } from "./MobHistoryinterface";

export type State = {
  loggedin?: boolean;
  platform?: string;
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  language?: "en-US" | "th-TH";
  // message
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  pdfDownloadStatus?: boolean;

  medicationItem?: medicationType[];
  loadingMedication?: boolean;
  loadingHospital?: boolean;
  getReminderData?: any;
  allergyList?: any[];
  loadingUpdateCurrent?: boolean;
  currentMedication?: any[];
} & MobHistoryI.State &
  SelectHospitalI.State;

// const language = ;  // language without region code
export const StateInitial: State = {
  userId: "",
  loggedin: false,
  platform: "",
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",

  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  pdfDownloadStatus: false,

  loadingMedication: true,
  loadingHospital: true,
  getReminderData: null,
  allergyList: [],
  medicationItem: [],
  loadingUpdateCurrent: false,
  currentMedication: [],
  ...MobHistoryI.StateInitial,
  ...SelectHospitalI.StateInitial,
};

export type medicationType = {
  id?: string;
  name?: string;
  image?: string;
  unit_image?: string;
  route?: number;
  route_description?: string;
  route_description_th?: string;
  route_display_seq?: number;
  route_tc?: string;
  route_tc_th?: string;
  route_image?: string;
  hospital_name?: string;
  hospital_code?: string;
  history?: historyType[];
  med_detail?: string;
  med_used_for?: string;
  med_need_before?: string;
  how_to_use_med?: string;
  miss_dose?: string;
  med_side_effect?: string;
  med_emer_warning?: string;
  med_store?: string;
  indication?: string;
  encounter_number?: any;
  prescription_code?: string;
  pi_my_med_url?: string;
  media_link?: string;
};

export type historyType = {
  doctor?: string;
  doctor_image?: string;
  encounter?: string;
  order_date?: string;
  count?: string;
  unit?: string;
  dosage?: string;
  dose?: string;
  dosage_image?: string;
  extra?: ExtraType;
  note?: Record<string, string>;
  state?: string;
  qty?: string;
  exp?: string;
  ph_qty_ord?: string;
};

export type Event =
  | { message: "GetLoginInfo"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "HandleSetAlarm"; params: { rm: any; key: any } }
  | {
      message: "handleGetMedication";
      params: { hospital: any };
    }
  | {
      message: "handleUpdateCurrentMed";
      params: { encounter: any; drug_id: any; state: any };
    }
  | { message: "handleOpenLink"; params: { url: any } }
  | { message: "HandleAllergy"; params: {} }
  | MobHistoryI.Event
  | MobLABI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  console.log("MobLAB DidMount: ");

  await SelectHospitalI.SelectedInitialHospital(controller, params);

  controller.setState({ loadingHospital: false });
};

export const DidUpdate: Handler = async (controller, params) => {};

export const HandleSetAlarm: Handler = async (controller, params) => {
  if (globalThis.mobile.setiOSReminder) {
    const rmFilter = params.rm.filter(
      (item: any) => item.idAlarm === params.key
    );

    console.log("chen ", rmFilter);

    const data = {
      idAlarm: rmFilter[0].idAlarm,
      isAlarm: rmFilter[0].isAlarm,
      time: rmFilter[0].timeFull,
      index: params.key,
    };
    globalThis.mobile.setiOSReminder(data);
  } else if (globalThis.mobile.setReminder) {
    console.log("chen setReminder");
    globalThis.mobile.setReminder(JSON.stringify(params.rm), params.key);
  }
};

export const handleGetMedication: Handler = async (controller, params) => {
  controller.setState({ loadingMedication: true });
  const { currentmed } = controller.getState();

  const [res, error] = await V3MedicationFullView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
  });

  console.log("saika res ", res, " error :", error);

  const [resCurrent, errorCurrent] = await CurrentMedication.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
  });

  if (error || errorCurrent) {
    controller.setState({
      medicationItem: [],
      loadingMedication: false,
      currentMedication: [],
    });
  } else {
    controller.setState({
      medicationItem: res?.items || null,
      loadingMedication: false,
      currentMedication: resCurrent || [],
    });
  }
};

export const handleUpdateCurrentMed: Handler = async (controller, params) => {
  controller.setState({
    loadingUpdateCurrent: true,
  });
  const [resCurrent, errorCurrent] = await CurrentMedication.post({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
    },
    data: params,
  });

  controller.setState({
    currentMedication: resCurrent,
    loadingUpdateCurrent: false,
  });
};

export const handleOpenLink: Handler = async (controller, params) => {
  if (params.url !== "") {
    if (globalThis.mobile.openLink) {
      globalThis.mobile.openLink(params.url, params.type);
    } else {
      window.open(params.url, "_blank");
    }
  }
};
