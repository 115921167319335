import WasmController from "../react-lib/frameworks/WasmController";
import * as FeedInterface from "./native/FeedInterface";
import * as MobKYCI from "./MobKYCInterface";
import * as KYCVerifyI from "./kyc/KYCVerifyInterface";

console.log(" MobKYCController !!! init ");
if (typeof globalThis !== "undefined" && typeof globalThis.mobile === "undefined") {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}  

export default class MobKYCController extends WasmController<
  MobKYCI.State,
  MobKYCI.Event,
  MobKYCI.Data
> {
  constructor(
    getState: () => MobKYCI.State,
    setState: (state: MobKYCI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobKYCI.DataInitial);
  }

  handleEvent = async (e: MobKYCI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // ----- Setup
      case "DidMount":
        MobKYCI.DidMount(this, e.params);
        return;
      case "DidUpdate":
        MobKYCI.DidUpdate(this, e.params);
        return;
      case "handleSetMessage":
        MobKYCI.handleSetMessage(this, e.params);
        return;

      // Mobile
      case "GetLoginInfo":
        globalThis.mobile?.getLoginInfo();
        return;
      case "handleMobileEvent":
        globalThis.mobile.handleEvent(e.params.name);
        return;

      // ----- KYCVerifyI
      case "handleGetPatientIdentification":
        KYCVerifyI.handleGetPatientIdentification(this);
        return;
      case "handleChangePhoto":
        KYCVerifyI.handleChangePhoto(this, e.params);
        return;
      case "handleConfirmPatientIdentification":
        KYCVerifyI.handleConfirmPatientIdentification(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
