import WasmController from "../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";

// Interface
import * as KYCVerifyI from "./kyc/KYCVerifyInterface";

export type State = {
  language?: "en-US" | "th-TH";
  intl?: IntlShape;
  userId?: string | number;
  loggedin?: boolean;
  apiToken?: string;
  // message
  errorMessage?: any;
  successMessage?: any;
} & KYCVerifyI.State;

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  userId: "",
  loggedin: false,
  apiToken: "",
  // message
  errorMessage: null,
  successMessage: null,
  ...KYCVerifyI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "GetLoginInfo"; params: {} }
  | {
      message: "handleSetMessage";
      params: { errorMessage: any; successMessage: any };
    }
  | KYCVerifyI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const DidUpdate: Handler = async (controller, params) => {};

export const handleSetMessage: Handler = async (controller, params) => {
  controller.setState({
    errorMessage: params.errorMessage || null,
    successMessage: params.successMessage || null,
  });
};
