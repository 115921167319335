import WasmController from "../../../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// APIs
import NutritionalMenuList from "../../../issara-sdk/apis/NutritionalMenuList_apps_PHRM";
import ChoiceView from "../../../issara-sdk/apis/ChoiceView_core";
import ClinicalTermList from "../../../issara-sdk/apis/ClinicalTermList_core";
import NutritionalMenuDetail from "../../../issara-sdk/apis/NutritionalMenuDetail_apps_PHR";
import NutritionalMenuDetailM from "../../../issara-sdk/apis/NutritionalMenuDetail_apps_PHRM";
import NutritionalMenuToMasterDetail from "../../../issara-sdk/apis/NutritionalMenuToMasterDetail_apps_PHR";

// Interface
import { BACKEND_DATE_FORMAT } from "bplus-lib/MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  // Data
  loadingStatusAditionalMenu?: boolean;
  nutritionAditionalMenuList?: any;
  nutritionNutritionistMenuList?: any;
  selectedNutritionAditionalMenu?: Record<string, any> | null;
  selectedNutritionNutritionistMenu?: Record<string, any> | null;
  nutritionUMFilter?: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    limit: number;
    offset: number;
  };
  foodTypeList?: any[];
  consumptionUnitList?: any[];
  openModNutrition?: {
    color?: "yellow" | "red";
    open?: boolean;
    type?: "edit" | "delete";
    prepareData?: any;
  };
  // nutritionist filter
  nutritionistUMFilter?: {
    limit: number;
    offset: number;
    name: string;
    type: string | null;
  };
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "patientSearch"
>;

export const StateInitial: State = {
  // Data
  loadingStatusAditionalMenu: false,
  nutritionAditionalMenuList: {},
  nutritionNutritionistMenuList: {},
  selectedNutritionAditionalMenu: null,
  selectedNutritionNutritionistMenu: null,
  nutritionUMFilter: {
    startDate: null,
    endDate: null,
    limit: 10,
    offset: 0,
  },
  foodTypeList: [],
  consumptionUnitList: [],
  // nutritionist filter
  nutritionistUMFilter: {
    limit: 10,
    offset: 0,
    name: "",
    type: null,
  },
};

export type Event =
  // GET
  | { message: "GetNutritionAditionalMenuList"; params: {} }
  | { message: "GetNutritionNutritionistMenuList" }
  | { message: "GetNutritionMaster"; params: {} }
  // SAVE
  | {
      message: "SaveNutritionAditionalMenu";
      params: { addToMasterMenu: any[]; removeFromMasterMenu: any[] };
    }
  | {
      message: "UpdateNutritionAditionalMenu";
      params: {
        id: number;
        data: any;
        type: "additional" | "nutritionist";
        successMessage?: string;
      };
    }
  | { message: "DeleteNutritionMenu"; params: { id: number; type: string } }
  // Setter
  | {
      message: "HandleChangeValueSelectedNutritionAditionalMenu";
      params: { key: string; value: any };
    }
  | {
      message: "HandleSelectedNutritionAditionalMenu";
      params: { data: any };
    }
  | {
      message: "HandleChangeValueSelectedNutritionNutritionistMenu";
      params: { key: string; value: any };
    }
  | {
      message: "HandleSelectedNutritionNutritionistMenu";
      params: { data: any; allowEdit?: boolean };
    }
  | {
      message: "HandleChangeNutritionUMFilter";
      params: { key: string; value: any };
    }
  | {
      message: "HandleSetOpenModNutrition";
      params: {
        color?: "yellow" | "red";
        open?: boolean;
        type?: "edit" | "delete";
        prepareData?: any;
      };
    }
  | {
      message: "HandleSetNutritionistUMFilter";
      params: { params: any[] };
    };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

/* ANCHOR ------------------------- Get ------------------------ */
export const GetNutritionAditionalMenuList: Handler = async (controller) => {
  controller.setState({ loadingStatusAditionalMenu: true });

  const { nutritionUMFilter, patientSearch } = controller.getState();

  let urlParams: any = {
    limit: nutritionUMFilter?.limit,
    offset: nutritionUMFilter?.offset,
  };
  if (nutritionUMFilter?.startDate) {
    urlParams.start_created =
      nutritionUMFilter?.startDate?.format(BACKEND_DATE_FORMAT);
  }
  if (nutritionUMFilter?.endDate) {
    urlParams.end_created =
      nutritionUMFilter?.endDate?.format(BACKEND_DATE_FORMAT);
  }
  if (patientSearch?.patient) {
    urlParams.patient = patientSearch.patient;
  }

  const [res, error] = await NutritionalMenuList.list({
    apiToken: controller.apiToken,
    params: {
      by_nutritionist: "False",
      ...urlParams,
    },
  });

  controller.setState({
    loadingStatusAditionalMenu: false,
    nutritionAditionalMenuList: res,
    selectedNutritionAditionalMenu: null,
  });
};

export const GetNutritionNutritionistMenuList: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const [res, error] = await NutritionalMenuList.list({
    apiToken: controller.apiToken,
    params: {
      master_menu: "True",
      approved: "True",
      by_nutritionist: "True",
      name: state.nutritionistUMFilter?.name,
      type: state.nutritionistUMFilter?.type,
      limit: state.nutritionistUMFilter?.limit,
      offset: state.nutritionistUMFilter?.offset,
    },
  });
  controller.setState({
    nutritionNutritionistMenuList: res || {},
    selectedNutritionNutritionistMenu: null,
    loadingStatus: false,
  });
};

export const GetNutritionMaster: Handler = async (controller) => {
  const [resType, errType] = await ChoiceView.get({
    apiToken: controller.apiToken,
    params: {
      model: "PHR.NutritionalMenu",
      field: "type",
      name_as_id: true,
    },
  });
  const [resUnit, errUnit] = await ClinicalTermList.list({
    apiToken: controller.apiToken,
    params: { type: "fd_consumption_unit" },
  });

  controller.setState({
    consumptionUnitList: resUnit?.items,
    foodTypeList: resType?.items,
  });
};

/* ANCHOR ------------------------ Save ------------------------ */
export const SaveNutritionAditionalMenu: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let promiseArray: any[] = [];
  promiseArray = (params.addToMasterMenu || [])?.map((id: number) => {
    return NutritionalMenuToMasterDetail.update({
      pk: id,
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        master_menu: "True",
      },
    });
  });
  promiseArray = (params.removeFromMasterMenu || [])?.map((id: number) => {
    return NutritionalMenuToMasterDetail.update({
      pk: id,
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        master_menu: "False",
      },
    });
  });

  await Promise.all(promiseArray);

  GetNutritionAditionalMenuList(controller);
  controller.setState({
    loadingStatus: false,
    errorMessage: null,
    successMessage: "Update master menu success.",
  } as any);
};

export const UpdateNutritionAditionalMenu: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  if (Object.keys(params?.data || {})?.[0] && !params?.id) {
    CreateNutritionalMenu(controller, params);
    return;
  }

  let cloneData: any = {};
  for (let key in params.data) {
    if (params.data[key] !== null) {
      cloneData[key] = params.data[key];
    }
  }

  delete cloneData.image;

  if (cloneData.file) {
    cloneData.image = cloneData.file;
    delete cloneData.file;
  }

  const [res, error] = await NutritionalMenuDetailM.update({
    pk: params.id,
    apiToken: controller.apiToken,
    data: cloneData,
  });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    controller.setState({ loadingStatus: false } as any);
    errorMessage = error;
  } else {
    if (params.type === "additional") {
      successMessage = "Update additional menu success.";
      GetNutritionAditionalMenuList(controller);
    } else if (params.type === "nutritionist") {
      successMessage = params.successMessage || "Update menu success.";
      GetNutritionNutritionistMenuList(controller);
    }
  }

  controller.setState({
    errorMessage,
    successMessage,
    loadingStatus: false
  } as any);
};

export const DeleteNutritionMenu: Handler = async (controller, params) => {
  if (!params?.id) {
    controller.setState({ errorMessage: "ID not found." } as any);
    return;
  }

  controller.setState({ loadingStatus: true } as any);

  const [res, error, network] = await NutritionalMenuDetailM.delete({
    apiToken: controller.apiToken,
    pk: params.id,
  });

  if (error) {
    controller.setState({ loadingStatus: false, errorMessage: error } as any);
  } else {
    let successMessage: any = null;
    if (params.type === "additional") {
      successMessage = "Delete additional menu success.";
      GetNutritionAditionalMenuList(controller);
    } else if (params.type === "nutritionist") {
      successMessage = "Delete menu success.";
      GetNutritionNutritionistMenuList(controller);
    }
    controller.setState({ successMessage } as any);
  }
};

/* ANCHOR ----------------------- Create ----------------------- */
export const CreateNutritionalMenu: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const cloneData = { ...params.data };
  cloneData.name_en = "";
  delete cloneData.image;

  if (cloneData.file) {
    cloneData.image = cloneData.file;
    delete cloneData.file;
  }

  const [res, error] = await NutritionalMenuList.create({
    apiToken: controller.apiToken,
    data: { ...cloneData, active: true },
  });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    controller.setState({ loadingStatus: false } as any);
    errorMessage = error;
  } else {
    successMessage = params.successMessage || "Add menu success.";
    GetNutritionNutritionistMenuList(controller);
  }

  controller.setState({
    errorMessage,
    successMessage,
  } as any);
};

/* ANCHOR ---------------------- Setter --------------------- */
export const HandleChangeValueSelectedNutritionAditionalMenu: Handler = (
  controller,
  params
) => {
  const { selectedNutritionAditionalMenu } = controller.getState();
  const { key, value } = params;
  const cloneData = { ...selectedNutritionAditionalMenu };
  if (cloneData) {
    cloneData[key] = value;
    if (key === "file") {
      cloneData.image = value ? URL.createObjectURL(value) : value;
    }
  }

  controller.setState({ selectedNutritionAditionalMenu: cloneData });
};

export const HandleSelectedNutritionAditionalMenu: Handler = (
  controller,
  params
) => {
  controller.setState({ selectedNutritionAditionalMenu: params.data });
};

export const HandleChangeValueSelectedNutritionNutritionistMenu: Handler = (
  controller,
  params
) => {
  const { selectedNutritionNutritionistMenu } = controller.getState();
  const { key, value } = params;
  const cloneData = { ...selectedNutritionNutritionistMenu };
  if (cloneData) {
    cloneData[key] = value;
    if (key === "file") {
      cloneData.image = value ? URL.createObjectURL(value) : value;
    }
  }

  controller.setState({ selectedNutritionNutritionistMenu: cloneData });
};

export const HandleSelectedNutritionNutritionistMenu: Handler = (
  controller,
  params
) => {
  const { selectedNutritionNutritionistMenu: menu } = controller.getState();

  if (Object.keys(menu || {})?.[0] && !menu?.id && !params.allowEdit) {
    HandleSetOpenModNutrition(controller, {
      open: true,
      type: "edit",
      color: "yellow",
      prepareData: params.data,
    });
    return;
  }
  controller.setState({ selectedNutritionNutritionistMenu: params.data });
};

export const HandleChangeNutritionUMFilter: Handler = async (
  controller,
  params
) => {
  const { nutritionUMFilter } = controller.getState();
  const { key, value } = params;
  const cloneData: any = { ...nutritionUMFilter };
  if (cloneData) {
    cloneData[key] = value;
  }
  await controller.setState({
    nutritionUMFilter: cloneData,
  });
  GetNutritionAditionalMenuList(controller);
};

export const HandleSetOpenModNutrition: Handler = (controller, params) => {
  controller.setState({ openModNutrition: params });
};

export const HandleSetNutritionistUMFilter: Handler = (controller, params) => {
  const state = controller.getState();
  let result: any = { ...state.nutritionistUMFilter };
  params.params?.forEach((item: any) => {
    result[item.name] = item.value;
  });
  controller.setState({ nutritionistUMFilter: result });
};
