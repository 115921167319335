import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

import {
  BACKEND_DATE_FORMAT,
  GROUP,
  DATA_TYPE,
  commonListPatientDataList,
} from "../MobClinicInterface";

export type State = {
  ultrasoundList?: any;
};
export const StateInitial = {
  ultrasoundList: {},
};

export type Event = { message: "handleGetUltrasoundList" };

type Handler = WasmHandler<State, Event>;

export const handleGetUltrasoundList: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.ULTRASOUND,
    data_type: DATA_TYPE.ULTRASOUND,
    start_measure: moment().add(-40, "weeks").format(BACKEND_DATE_FORMAT),
    end_measure: moment().format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  if (response?.items?.[0]) {
    response.items = response.items.sort((a: any, b: any) => ("" + b.measure_date).localeCompare(a.measure_date));
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    ultrasoundList: response,
  });
};
