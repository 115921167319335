import React, { useEffect, useState } from "react";

// DatePicker
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// UI
import MarketingSegmentDashboard from "./MarketingSegmentDashboard";
import MarketingSegmentStatistic from "./MarketingSegmentStatistic";
import PostList from "./PostList";
import PostTab from "./PostTab";

import "../../css/WebClinic.scss";

type MarketingSegmentProps = {
  // function
  onEvent: any;
  setProp: any;
  // data
  errorMessage?: any;
  successMessage?: any;
  loadingStatus?: any;
  loadingMarketingSegment?: boolean;
  loadingMarketingPatient?: boolean;
  selectedDivision?: any;
  filterMarketingPatient?: any;
  marketingSegmentList?: any;
  marketingPatientList?: any;
  marketingSegmentAgeGender?: any;
  marketingSegmentTopPost?: any[];
  marketingSegmentTopPromotion?: any[];
  marketingCreatePatient?: any;
  showSegment?: boolean;
};

const MarketingSegmentInitial: MarketingSegmentProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  // data
  errorMessage: null,
  successMessage: null,
  loadingStatus: false,
  loadingMarketingSegment: false,
  loadingMarketingPatient: false,
  selectedDivision: {},
  filterMarketingPatient: {},
  marketingSegmentList: {},
  marketingPatientList: {},
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  marketingCreatePatient: null,
  showSegment: false,
};

const MarketingSegment: React.FC<MarketingSegmentProps> = (props: any) => {
  const [activeSegment, setActiveSegment] = useState<number | undefined>(
    undefined
  );
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [modeAddSegment, setModeAddSegment] = useState<boolean>(false);
  const [searchSegment, setSearchSegment] = useState<string>("");
  const [segmentList, setSegmentList] = useState<any[]>([]);
  const [panes, setPanes] = useState<any[]>([]);

  useEffect(() => {
    props.onEvent({ message: "handleGetMarketingSegmentList" });
  }, []); // [props.selectedDivision]);

  useEffect(() => {
    if (props.filterMarketingPatient?.result) {
      props.onEvent({ message: "handleGetMarketingPatientList" });
    }
  }, [props.selectedDivision, props.filterMarketingPatient, props.showSegment]);

  useEffect(() => {
    const filter = props.marketingSegmentList?.items?.filter((item: any) =>
      item.result?.toLowerCase().includes(searchSegment.toLowerCase())
    );
    setSegmentList(filter);

    const includeFilter = filter?.filter((item: any) =>
      item.result?.includes(props.filterMarketingPatient?.result)
    );
    if (searchSegment && includeFilter.length === 0) {
      props.onEvent({
        message: "handleSetMarketingPatientParams",
        params: {
          ...filter?.[0],
          offset: 0,
        },
      });
    }
  }, [props.marketingSegmentList, searchSegment]);

  useEffect(() => {
    if (segmentList?.length > 0) {
      if (!props.filterMarketingPatient.result) {
        props.onEvent({
          message: "handleSetMarketingPatientParams",
          params: {
            ...segmentList?.[0],
          },
        });
        setActiveSegment(0);
      }
    } else {
      props.onEvent({
        message: "handleSetMarketingPatientParams",
        params: { result: null, add_manually: true },
      });
      setActiveSegment(undefined);
    }
  }, [segmentList]);

  useEffect(() => {
    if (activeSegment !== null) {
      var current = segmentList?.[activeSegment || 0]?.result;
    }

    if (current !== props.filterMarketingPatient.result) {
      segmentList?.forEach((item: any, index) => {
        if (item.result === props.filterMarketingPatient.result) {
          setActiveSegment(index);
        }
      });
    }
  }, [props.filterMarketingPatient.result, segmentList, activeSegment]);

  useEffect(() => {
    if (modeAddSegment) {
      setPanes([
        {
          key: "dashboard",
          image: "/images/expansion/crowd.png",
          render: () => (
            <MarketingSegmentDashboard
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              onSetModeAddSegment={(mode: boolean) => setModeAddSegment(mode)}
              // data
              loadingStatus={props.loadingStatus}
              loadingMarketingPatient={props.loadingMarketingPatient}
              filterMarketingPatient={props.filterMarketingPatient}
              marketingPatientList={{}}
              marketingCreatePatient={props.marketingCreatePatient}
              modeAddSegment={modeAddSegment}
              isMyTab={false}
            />
          ),
        },
      ]);
    } else if (segmentList?.[activeSegment || 0]?.add_manually) {
      setPanes([
        {
          key: "dashboard",
          image: "/images/expansion/crowd.png",
          render: () => (
            <MarketingSegmentDashboard
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              onSetModeAddSegment={(mode: boolean) => setModeAddSegment(mode)}
              // data
              loadingStatus={props.loadingStatus}
              loadingMarketingPatient={props.loadingMarketingPatient}
              filterMarketingPatient={props.filterMarketingPatient}
              marketingPatientList={props.marketingPatientList}
              marketingCreatePatient={props.marketingCreatePatient}
              modeAddSegment={modeAddSegment}
              isMyTab={!modeAddSegment && activeMenu === 0}
            />
          ),
        },
        {
          key: "chart",
          image: "/images/expansion/carbon_analytics.png",
          render: () => (
            <MarketingSegmentStatistic
              // function
              onEvent={props.onEvent}
              // data
              loadingStatus={props.loadingStatus}
              marketingPatientList={props.marketingPatientList}
              filterMarketingPatient={props.filterMarketingPatient}
              marketingSegmentAgeGender={props.marketingSegmentAgeGender}
              marketingSegmentTopPost={props.marketingSegmentTopPost}
              marketingSegmentTopPromotion={props.marketingSegmentTopPromotion}
              isMyTab={activeMenu === 1}
            />
          ),
        },
      ]);
    } else {
      setPanes([
        {
          key: "chart",
          image: "/images/expansion/carbon_analytics.png",
          render: () => (
            <MarketingSegmentStatistic
              // function
              onEvent={props.onEvent}
              // data
              loadingStatus={props.loadingStatus}
              marketingPatientList={props.marketingPatientList}
              filterMarketingPatient={props.filterMarketingPatient}
              marketingSegmentAgeGender={props.marketingSegmentAgeGender}
              marketingSegmentTopPost={props.marketingSegmentTopPost}
              marketingSegmentTopPromotion={props.marketingSegmentTopPromotion}
              isMyTab={activeMenu === 0}
            />
          ),
        },
      ]);
    }
  }, [
    segmentList,
    activeSegment,
    props.marketingSegmentAgeGender,
    modeAddSegment,
  ]);

  useEffect(() => {
    props.onEvent({ message: "handleGetMarketingSummary" });
  }, [props.marketingPatientList]);

  const handleAddSegment = () => {
    setModeAddSegment(true);
    setActiveSegment(undefined);
    setActiveMenu(0);
  };

  const handleChangeSegment = (item: any, index: number) => {
    props.setProp("marketingSegmentAgeGender", {});
    props.onEvent({
      message: "handleSetMarketingPatientParams",
      params: {
        ...item,
        offset: 0,
      },
    });
    setModeAddSegment(false);
    setActiveMenu(0);
    setActiveSegment(index);
  };

  const handleSearchSegmentResult = (search: string) => {
    setSearchSegment(search);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "320px 1fr",
        }}
      >
        <div
          style={{
            gridColumn: 1,
            paddingTop: "10px",
            borderRight: "1px solid rgb(238, 238, 238)",
          }}
        >
          <PostList
            // function
            onEvent={props.onEvent}
            onClickItem={handleChangeSegment}
            onSearch={handleSearchSegmentResult}
            onAdd={handleAddSegment}
            // data
            loadingStatus={props.loadingMarketingSegment}
            list={segmentList}
            activeIndex={activeSegment}
            isSegment= {true}
            titleKey="result"
          />
        </div>
        <div className="SegmentEditor">
          <PostTab
            initialActive={activeMenu}
            disableMenu={
              props.loadingStatus ||
              props.loadingMarketingSegment ||
              props.loadingMarketingPatient
            }
            activeSegment={activeSegment}
            list={segmentList}
            onClickTab={(_item: any, index: number) => setActiveMenu(index)}
            panes={panes}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

MarketingSegment.defaultProps = MarketingSegmentInitial;

export default React.memo(MarketingSegment);
