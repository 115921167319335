import React, { createRef, Ref, Component } from "react";
import { withRouter } from "react-router-dom";

import * as WebI from "./WebClinicInterface";

import "../css/WebClinic.scss";
import WebClinicController from "./WebClinicController";
import SetProp from "../react-lib/frameworks/SetProp";
import WebMomChildTopBar from "./clinic/WebMomChildTopBar";
// UI
import WebMomChildClassification from "./clinic/WebMomChildClassification";
import WebMomChildTopBarLocation from "./clinic/WebMomChildTopBarLocation";
import WebMomChildUserManagement from "./clinic/WebChildUserManagement";

class WebClinic extends Component<any, WebI.State> {
  controller: WebClinicController;

  constructor(props: any) {
    super(props);
    this.state = WebI.StateInitial;
    this.controller = new WebClinicController(
      () => {
        return this.state;
      },
      (state: {}, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    globalThis.setState = this.setState.bind(this);
    this.controller.setProp = SetProp(this, "");
  }

  render() {
    return (
      <div>
        <WebMomChildTopBar
          setProp={this.controller.setProp}
          webSub={this.state.webSub}
          onEvent={this.controller.handleEvent}
        />
        <WebMomChildTopBarLocation
          setProp={this.controller.setProp}
          webSub={this.state.webSub}
          onEvent={this.controller.handleEvent}
        />
        {this.state.webSub === "patient" && (
          <div>
            <WebMomChildClassification
              onEvent={this.controller.handleEvent}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
              patientList={this.state.patientList}
              selectedPatient={this.state.selectedPatient}
            />
          </div>
        )}
        {this.state.webSub === "userManagement" && (
          <div>
            <WebMomChildUserManagement
              onEvent={this.controller.handleEvent}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
              patientList={this.state.patientList}
              selectedPatient={this.state.selectedPatient}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(WebClinic);
