import WasmController from "react-lib/frameworks/WasmController";
import axios from "axios";
import moment, { Moment } from "moment";
import Cookies from "js-cookie";
import RealtimeLocationScheduleView from "issara-sdk/apis/RealtimeLocationScheduleView_apps_PRXM";
import V3DoctorScheduleView from "issara-sdk/apis/V3DoctorScheduleView_apps_PRX";

export type State = {
  selectedDate?: Moment | null;
  calendarList?: {
    active: Date[];
    inactive: Date[];
  };
  timeslotList?: timeSlotsType[];
  isLoadingCalendar?: boolean;
  isLoadingNextMonth?: boolean;
  isFetchCalendarError?: boolean;
  menuTabSelectDate?: "online" | "on-site";
};

export const StateInitial: State = {
  selectedDate: null, // moment().add(1, "days"),
  calendarList: {
    active: [],
    inactive: [],
  },
  timeslotList: [],
  isLoadingCalendar: false,
  isLoadingNextMonth: false,
  isFetchCalendarError: false,
};


type calendarType = {
  date: string;
  available: number;
};

export type timeSlotsType = {
  schedule_id: number;
  slot_id: string;
  location: string;
  online: false;
  raw_start_time: string;
  raw_end_time: string;
  raw_date: string;
  capacity: number;
  status: string;
  start_time: string;
  end_time: string;
  available: number;
  doctor: {
    doctor_code: string;
    old_doctor_code: string;
    doctor_name: string;
    location_code: string;
    location_name: string;
  };
};

export type Event =
  | { message: "handleSelectDate"; params: { value: any } }
  | {
      message: "GetCalendars";
      params: {
        date_range: { start_date: string; end_date: string }[];
        loc_code?: string;
        doctor_code?: string;
        is_telemed?: any;
        hospital?: any;
      };
    }
  | {
      message: "handleCalendarNextMonth";
      params: {
        date_range: { start_date: string; end_date: string }[];
        loc_code?: string;
        doctor_code?: string;
        is_telemed?: any;
        hospital?: any;
      };
    }
  | { message: "handleGoBackSelectDate"; params: { history: any } }
  | { message: "HandleSetMenuTabSelectDate"; params: { value: any } }
  | { message: "HandleSetIsFetchCalendarError"; params: { value: boolean } };

export type Data = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const handleSelectDate: Handler = async (controller, params) => {
  controller.setState({ selectedDate: params.value });
};

const GetRealtimeLocationSchedule: Handler = async (controller, params) => {
  const { date_range, loc_code, doctor_code, is_telemed, hospital } = params;

  const promiseArray = date_range.map(({ start_date, end_date }: Record<string,string>) => {
    console.log("saika start_date  ", start_date)
    const paramsUrl: any = { start_date, end_date };
    if (loc_code) {
      paramsUrl.loc_code = loc_code;
    }
    if (doctor_code) {
      paramsUrl.doctor_code = doctor_code;
    }
    if (is_telemed) {
      paramsUrl.telemed = is_telemed;
    }
    if (hospital) {
      paramsUrl.hospital = hospital;
    }
    const api = loc_code ? RealtimeLocationScheduleView : V3DoctorScheduleView;

    return api.get({
      params: paramsUrl,
      apiToken: controller.apiToken || Cookies.get("apiToken"),
    });
  });

  const response = await Promise.all(promiseArray);

  const calendarList: calendarType[] = response.map(
    (item: any) => item?.[0]?.calendar || []
  );
  const timeslotList: timeSlotsType[] = response.map(
    (item: any) => item?.[0]?.time_slots || []
  );

  const isError = response.some((item: any) => item[1]);

  return {
    calendarList: calendarList.flat(),
    timeslotList: timeslotList.flat(),
    isError,
  };
};

export const GetCalendars: Handler = async (controller, params) => {
  console.log("GetCalendars " + params);

  controller.setState({
    isLoadingNextMonth: true,
    isLoadingCalendar: true,
    isFetchCalendarError: false,
  });
  const { calendarList, timeslotList, isError } =
    await GetRealtimeLocationSchedule(controller, params);

  const isRealtime = timeslotList?.[0]?.online || false

  let highlights: any = {
    active: [],
    inactive: [],
  };
  const startDate = params.date_range[0].start_date;
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const filterTime = (date: moment.Moment) => {
    console.log(date.format("YYYY-MM-DD HH:mm"));
    return (timeslotList || []).filter(
      (item: any) =>
        date.diff(moment(`${item.raw_date} ${item.start_time}`)) <= 0
    );
  };

  const hours = isRealtime ? 4 : 24

  let filterItemSlot = filterTime(moment().add(hours, "hours"))

  let groupSlot: any[] = []
   filterItemSlot.forEach((items:any)=> {
    if (!groupSlot.includes(items.raw_date)) {
      groupSlot.push(items.raw_date)
    }
  })

  const calendarListFilter = calendarList.filter((item:any) => groupSlot.includes(item.date))

  for (let item of calendarListFilter) {
    const _availableDate = new Date(item.date);
    _availableDate.setHours(0, 0, 0, 0);
    if (item.available === 0 ) {
      highlights.inactive.push(_availableDate);
    } else {
      highlights.active.push(_availableDate);
    }
  }

  const checkActiveMonth = highlights.active?.find(
    (date: Date) => date.getMonth() + 1 === +moment(startDate).format("M")
  );

  if (checkActiveMonth || !calendarList?.[0] || !highlights.active?.[0]) {
    controller.setState({
      isLoadingNextMonth: false,
      isLoadingCalendar: false,
    });
    if (isError) {
      controller.setState({ isFetchCalendarError: true });
    }
  } else {
    controller.setState({ isLoadingCalendar: false });
  }
  // console.log(dateList)
  console.log(highlights, "calendarListx");
  controller.setState({
    calendarList: calendarList?.[0] ? highlights : { active: [], inactive: [] },
    timeslotList: timeslotList?.[0] ? timeslotList : [],
  });
};

export const handleCalendarNextMonth: Handler = async (controller, params) => {
  controller.setState({ isLoadingNextMonth: true });

  const {
    calendarList: calendars,
    timeslotList: timeslots,
    isError,
  } = await GetRealtimeLocationSchedule(controller, params);
  const { calendarList, timeslotList } = controller.getState();

  if (calendarList && calendars[0]) {
    const startDate = params.date_range[0].start_date;
    const endDate = params.date_range[params.date_range.length - 1].end_date;
    const date = moment(startDate);

    for (let item of calendars) {
      const _availableDate = new Date(item.date);
      _availableDate.setHours(0, 0, 0, 0);

      if (item.available === 0) {
        calendarList.inactive.push(_availableDate);
      } else {
        calendarList.active.push(_availableDate);
      }
    }
    // while (date.format("YYYY-MM-DD") !== endDate) {
    //   const formatDate = date.format("YYYY-MM-DD");
    //   const _availableDate = new Date(formatDate);
    //   _availableDate.setHours(0, 0, 0, 0);

    //   if (!!!calendars.find((item: any) => item.date === formatDate)) {
    //     calendarList.inactive.push(_availableDate);
    //   } else {
    //     calendarList.active.push(_availableDate);
    //   }
    //   date.add(1, "days");
    // }
  }
  if (isError) {
    controller.setState({ isFetchCalendarError: true });
  }
  controller.setState({ isLoadingNextMonth: false });
  console.log(calendarList, "calendarListxx");
  controller.setState({
    calendarList: calendarList,
    timeslotList: [...(timeslotList || []), ...timeslots],
  });

  return true;
};

export const handleGoBackSelectDate: Handler = (controller, params) => {
  controller.setState({
    calendarList: { active: [], inactive: [] },
    timeslotList: [],
    menuTabSelectDate: undefined,
  });

  const stateHistory = params.history?.location?.state;

  if (stateHistory?.mob === "MobFeed") {
    if (stateHistory?.is_consent_form) {
      params.history.go(-2);
    } else {
      params.history.go(-1);
    }
    setTimeout(() => {
      params.history.go(0);
    }, 10);
  } else if (stateHistory?.dubai_tele_consult) {
    params.history.go(-1);
    setTimeout(() => {
      params.history.go(0);
    }, 10);
  } else if (stateHistory?.is_consent_form) {
    params.history.go(-2);
  } else if (stateHistory.contentIdToDate) {
    params.history.push(
      `/content/${stateHistory.contentIdToDate}/?app=MobFeed`
    );
    params.history.go(0);
  } else {
    params.history.goBack();
  }
};

export const HandleSetMenuTabSelectDate: Handler = (controller, params) => {
  controller.setState({ menuTabSelectDate: params.value });
};

export const HandleSetIsFetchCalendarError: Handler = (controller, params) => {
  controller.setState({ isFetchCalendarError: params.value });
};