import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";
// CSS
import { Dropdown, Form, Grid, Header, Icon, Message } from "semantic-ui-react";

import moment from "moment";
import { Bar } from "react-chartjs-2";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

// UI
import * as DrugDMConst from "./DMDrug";
import { DateTextBox } from "../../react-lib/apps/common";
import {
  HeaderChart,
  FooterChart,
  spliceChartData,
} from "./DMEmotionRestChart";

// Interface
import { BACKEND_DATE_FORMAT, BE_DATE_FORMAT, formatDateByLanguage } from "../MobClinicInterface";

import { DATE_FORMAT } from "./DMExercise";

// ---------- SubHistory
type SubHistoryProps = {
  title: string;
  compliance: string;
  items: any[];
  showMoreInfo?: boolean;
};

export const DURATION_OPTIONS = [
  { key: "5", value: "5", text: "5 days" },
  { key: "7", value: "7", text: "7 days" },
  { key: "10", value: "10", text: "10 days" },
];

export const SubHistory: React.FC<SubHistoryProps> = (props) => {
  const intl = useIntl();
  return (
    <>
      <Grid.Row verticalAlign="bottom">
        <Grid.Column width={1}>
          <Icon
            name="clock outline"
            size="large"
            style={{ color: DrugDMConst.COLOR_CODE.cyan }}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
            {props.title}
          </Header>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.cyan }}>
            {props.compliance
              ? `${intl.formatMessage({ id: "bplusClinicKey716" })} ${
                  props.compliance
                }%`
              : "Result -"}
          </Header>
        </Grid.Column>
      </Grid.Row>

      {props.items?.map((item: any, index: number) => {
        let color = DrugDMConst.COLOR_CODE.darkGrey;
        if (item.status === "hold") {
          color = "orange";
        } else if (item.status === "") {
          color = "red";
        }
        return (
          <Grid.Row
            key={index}
            verticalAlign="bottom"
            style={{ ...DrugDMConst.styles.noPadding, color: color }}
          >
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column
              width={props.showMoreInfo ? 5 : 7}
              style={DrugDMConst.styles.noPadding}
            >
              {item.drug_name}
            </Grid.Column>
            <Grid.Column width={1} style={DrugDMConst.styles.noPadding}>
              {item.dose}
            </Grid.Column>
            <Grid.Column width={2} style={DrugDMConst.styles.noPadding}>
              {item.unit}
            </Grid.Column>
            {props.showMoreInfo && (
              <Grid.Column width={1} style={DrugDMConst.styles.noPadding}>
                {item.status === "hold" ? (
                  <Icon name="pause circle" color="yellow" />
                ) : item.status === "confirm" ? (
                  <Icon name="check circle" color="green" />
                ) : item.status === "" ? (
                  <Icon name="minus circle" color="red" />
                ) : (
                  <></>
                )}
              </Grid.Column>
            )}
            <Grid.Column
              width={props.showMoreInfo ? 2 : 4}
              style={{ ...DrugDMConst.styles.noPadding, paddingLeft: "10px" }}
            >
              {item.status === "hold" ? (
                <label>{item.status}</label>
              ) : item.status === "confirm" &&
                props.showMoreInfo &&
                props.title !== "Others" ? (
                <label>
                  <Icon name="clock" color="blue" />
                  <span
                    style={{
                      color: item.over_time ? "red" : "rgb(101, 101, 101)",
                    }}
                  >
                    {item.confirmTime}
                  </span>
                </label>
              ) : item.status === "" ? (
                <label>
                  <FormattedMessage id="bplusClinicKey592" />
                </label>
              ) : (
                <></>
              )}
            </Grid.Column>
            {props.showMoreInfo && (
              <Grid.Column width={2} style={DrugDMConst.styles.noPadding}>
                <label>{item.status === "hold" ? item.holdReason : ""}</label>
              </Grid.Column>
            )}
          </Grid.Row>
        );
      })}
    </>
  );
};

// ---------- DrugDMBar

type DrugDMBarProps = {
  setSelectedData?: any;
  height?: number;
  hideFooterChart?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
} & DMDrugTabHistoryProps;

export const DrugDMBar: React.FC<DrugDMBarProps> = (props) => {
  const labelLimit = 4;
const intl = useIntl();
  const [history, setHistory] = useState<any>({ labels: [], datasets: [] });
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const pinRef = useRef() as MutableRefObject<HTMLImageElement>;

  const allDrugHistory = useMemo(() => {
    let historyList: any[] = Array.isArray(props.drugDataTabHistory?.items)
      ? props.drugDataTabHistory?.items
      : [];
    const list: any[] = [];

    let tmpDate = props.startDate?.clone()?.format(BE_DATE_FORMAT);
    const lastDate = props.endDate
      ?.clone()
      ?.add(1, "days")
      .format(BE_DATE_FORMAT);

    while (tmpDate !== lastDate) {
      const findItem = historyList.find((item) => item.date === tmpDate);
      const object = {
        compliance: "",
        items: [""],
      };

      const defaultItem = {
        date: tmpDate,
        morning: object,
        afternoon: object,
        evening: object,
        bedtime: object,
        others: {
          compliance: "",
          items: [],
        },
      };

      list.push(findItem || defaultItem);

      tmpDate = moment(tmpDate, BE_DATE_FORMAT)
        .clone()
        .add(1, "days")
        .format(BE_DATE_FORMAT);
    }

    return list;
  }, [props.drugDataTabHistory?.items, props.startDate, props.endDate]);

  useEffect(() => {
    let labels: any[] = [];
    let mornings: any[] = [];
    let afternoons: any[] = [];
    let evenings: any[] = [];
    let bedtimes: any[] = [];
    let others: any[] = [];
    let realMorningData: any[] = [];
    let realAfternoonData: any[] = [];
    let realEveningData: any[] = [];
    let realBedtimeData: any[] = [];
    let realOtherData: any[] = [];
    if (allDrugHistory.length > 0 && props.startDate && props.endDate) {
      console.log(allDrugHistory);

      allDrugHistory?.forEach((item: any) => {
        labels.push(
          formatDateByLanguage(moment(item.date, "DD/MM/YYYY").format(DATE_FORMAT),"DD MMM,YYYY")
        );
        mornings.push(item.morning.compliance);
        afternoons.push(item.afternoon.compliance);
        evenings.push(item.evening.compliance);
        bedtimes.push(item.bedtime.compliance);
        others.push(item.others.compliance);
        realMorningData.push(item.morning);
        realAfternoonData.push(item.afternoon);
        realEveningData.push(item.evening);
        realBedtimeData.push(item.bedtime);
        realOtherData.push(item.others);
      });

      props.setSelectedData(allDrugHistory.slice(-1)[0]);
    }

    console.log(props.drugDataTabHistory?.items);
    const datasets = [
      {
        label: `${intl.formatMessage({ id: "bplusClinicKey329" })}`,
        data: mornings,
        realData: realMorningData,
        backgroundColor: "#97F2E0",
      },
      {
        label: `${intl.formatMessage({ id: "bplusClinicKey708" })}`,
        data: afternoons,
        realData: realAfternoonData,
        backgroundColor: "#F9DD8D",
      },
      {
        label: `${intl.formatMessage({ id: "bplusClinicKey190" })}`,
        data: evenings,
        realData: realEveningData,
        backgroundColor: "#EE9191",
      },
      {
        label: `${intl.formatMessage({ id: "bplusClinicKey81" })}`,
        data: bedtimes,
        realData: realBedtimeData,
        backgroundColor: "#BDBDBD",
      },
      {
        label: `${intl.formatMessage({ id: "bplusClinicKey358" })}`,
        data: others,
        realData: realOtherData,
        backgroundColor: "#FF7BCA",
      },
    ];

    setHistory({ labels: labels, datasets: datasets });
  }, [props.drugDataTabHistory]);

  useEffect(() => {
    setSkipIndex(0);
  }, [props.startDate, isSeeAll]);

  useEffect(() => {
    if (props.hideFooterChart) {
      setIsSeeAll(true);
    } else {
      setIsSeeAll(false);
    }
  }, [props.hideFooterChart]);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;
        },
        afterDatasetsDraw: (chart: any) => {
          for (const [i, dataset] of chart.config.data.datasets.entries()) {
            const meta = chart.getDatasetMeta(i);

            for (const [index, bar] of meta.data.entries()) {
              const ctx = chart.ctx;
              var data = dataset.realData[index];

              if (!Number(data?.compliance) && data?.items?.length) {
                const width = 20;
                const height = 20;

                if (pinRef.current && ctx) {
                  ctx.drawImage(
                    pinRef.current,
                    bar._model.x - width / 2,
                    bar._model.y - width - 3,
                    width,
                    height
                  );
                }
              }
            }
          }
        },
      },
    ];
  }, []);

  const subChartData = useMemo(() => {
    if (props.hideFooterChart) {
      return history;
    }
    return spliceChartData({
      data: history,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [history, skipIndex]);

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const options = {
    onClick: (event: any, element: any) => {
      if (element.length > 0) {
        // console.log("index movig:", props.drugDataTabHistory?.items[element[0]._index + skipIndex])
        props.setSelectedData(allDrugHistory[element[0]._index + skipIndex]);
      }
    },
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
            stepSize: 20,
            fontColor: "#b1b3b2",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#404040",
            fontStyle: "bold",
          },
        },
      ],
    },
  };

  return (
    <>
      <Bar
        height={props.height}
        data={isSeeAll ? history : subChartData}
        options={options}
        plugins={plugins}
      />
      <img
        ref={pinRef}
        src="/images/exercise/marker-alert-outline.png"
        alt="pin.icon"
        style={{ width: 0, height: 0 }}
      />
      {!props.hideFooterChart && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            history.labels?.slice(labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
        />
      )}
    </>
  );
};

// ---------- DrugDMTabHistory
type DMDrugTabHistoryProps = {
  onEvent: any;
  drugDataTabHistory: {
    summary: { title: string; detail: string; color: any };
    sideEffectNote: string;
    items: [
      {
        date: string;
        morning: {
          compliance: number | null;
          items: [
            { drug_name: string; dose: string; unit: string; status: string }
          ];
        };
        afternoon: {
          compliance: number | null;
          items: [
            { drug_name: string; dose: string; unit: string; status: string }
          ];
        };
        evening: {
          compliance: number | null;
          items: [
            { drug_name: string; dose: string; unit: string; status: string }
          ];
        };
        bedtime: {
          compliance: number | null;
          items: [
            { drug_name: string; dose: string; unit: string; status: string }
          ];
        };
        others: {
          compliance: number | null;
          items: [
            { drug_name: string; dose: string; unit: string; status: string }
          ];
        };
      }
    ];
  };
  selectedPatient?: any;
};

const DMDrugTabHistoryInitial: DMDrugTabHistoryProps = {
  onEvent: () => null,
  drugDataTabHistory: {
    summary: { title: "", detail: "", color: "" },
    sideEffectNote: "",
    items: [
      {
        date: "",
        morning: {
          compliance: null,
          items: [{ drug_name: "", dose: "", unit: "", status: "" }],
        },
        afternoon: {
          compliance: null,
          items: [{ drug_name: "", dose: "", unit: "", status: "" }],
        },
        evening: {
          compliance: null,
          items: [{ drug_name: "", dose: "", unit: "", status: "" }],
        },
        bedtime: {
          compliance: null,
          items: [{ drug_name: "", dose: "", unit: "", status: "" }],
        },
        others: {
          compliance: null,
          items: [{ drug_name: "", dose: "", unit: "", status: "" }],
        },
      },
    ],
  },
};

const DMDrugTabHistory: React.FC<DMDrugTabHistoryProps> = (props) => {
  const intl = useIntl();
  const labelLimit = 7;

  const [selectedDuration, setSelectedDuration] = useState<string>("7");
  const [selectedData, setSelectedData] = useState<any>({});
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  // const durationOptions = useMemo(
  //   () => [
  //     { key: "5", value: "5", text: "5 days" },
  //     { key: "7", value: "7", text: "7 days" },
  //     { key: "10", value: "10", text: "10 days" },
  //   ],
  //   []
  // );

  // useEffect(() => {
  //   props.onEvent({
  //     message: "handleGetDrugHistory",
  //     params: {
  //       date: startDate,
  //       duration: `${selectedDuration}_day`.toUpperCase(),
  //     },
  //   });
  // }, [props.selectedPatient, startDate, endDate]);

  useEffect(() => {
    setStartDate(moment().add(`-${+selectedDuration - 1}`, "days"));
    setEndDate(moment());
  }, [selectedDuration]);

  useEffect(() => {
    setSkipIndex(0);
  }, [startDate]);

  // useEffect(() => {
  //   let labels: any[] = [];
  //   let mornings: any[] = [];
  //   let afternoons: any[] = [];
  //   let evenings: any[] = [];
  //   let bedtimes: any[] = [];
  //   let others: any[] = [];
  //   if (props.drugDataTabHistory) {
  //     props.drugDataTabHistory?.items?.forEach((item: any) => {
  //       labels.push(
  //         moment(item.date, "DD/MM/YYYY").locale("en").format("MMM DD,YYYY")
  //       );
  //       mornings.push(item.morning.compliance);
  //       afternoons.push(item.afternoon.compliance);
  //       evenings.push(item.evening.compliance);
  //       bedtimes.push(item.bedtime.compliance);
  //       others.push(item.others.compliance);
  //     });

  //     setSelectedData(
  //       props.drugDataTabHistory?.items[
  //         props.drugDataTabHistory?.items.length - 1
  //       ]
  //     );
  //   }
  //   const datasets = [
  //     { label: "Morning", data: mornings, backgroundColor: "#97F2E0" },
  //     { label: "Afternoon", data: afternoons, backgroundColor: "#F9DD8D" },
  //     { label: "Evening", data: evenings, backgroundColor: "#EE9191" },
  //     { label: "Bedtime", data: bedtimes, backgroundColor: "#BDBDBD" },
  //     { label: "Other", data: others, backgroundColor: "#FF7BCA" },
  //   ];

  //   setHistory({ labels: labels, datasets: datasets });
  // }, [props.drugDataTabHistory]);

  // const options = {
  //   onClick: (event: any, element: any) => {
  //     if (element.length > 0) {
  //       setSelectedData(props.drugDataTabHistory?.items[element[0]._index]);
  //     }
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           max: 100,
  //           min: 0,
  //         },
  //       },
  //     ],
  //   },
  // };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, "DD/MM/YYYY");
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${+selectedDuration - 1}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, "DD/MM/YYYY");
      fromdate = targetDate.clone();
      todate = targetDate.add(+selectedDuration - 1, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
    props.onEvent({
      message: "handleGetDrugHistory",
      params: {
        date: todate,
        duration: `${selectedDuration}_day`.toUpperCase(),
      },
    });
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    setSelectedDuration(value);
    props.onEvent({
      message: "handleGetDrugHistory",
      params: { date: endDate, duration: `${value}_day`.toUpperCase() },
    });
  };

  const durationOptions = useMemo(
    () => [
      { key: "5", value: "5", text: `5 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "7", value: "7", text: `7 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "10", value: "10", text: `10 ${intl.formatMessage({ id: "profile.days" })}` },
    ],
    []
  );

  return (
    <>
      <HeaderChart
        yAxesLabelLeft="%compliance"
        yAxesLabelRight=""
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={selectedDuration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        labelStyle={{ padding: "10px 8px 5px 8px" }}
        legendItem={{
          type: "rect",
          items: [
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey329" })}`,
              color: "#97F2E0",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey708" })}`,
              color: "#F9DD8D",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey358" })}`,
              color: "#FF7BCA",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey190" })}`,
              color: "#EE9191",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey81" })}`,
              color: "#BDBDBD",
            },
            {
              name: `${intl.formatMessage({ id: "bplusClinicKey843" })}`,
              color: "",
              image: "/images/exercise/marker-alert-outline.png",
            },
          ],
        }}
      />

      <DrugDMBar
        height={200}
        drugDataTabHistory={props.drugDataTabHistory}
        onEvent={props.onEvent}
        setSelectedData={setSelectedData}
        startDate={startDate}
        endDate={endDate}
      />

      {selectedData && (
        <>
          <Grid verticalAlign="middle" style={{ padding: "20px" }}>
            {/* Date */}
            <Grid.Row>
              <Grid.Column width={1}>
                <Icon
                  name="calendar alternate outline"
                  size="large"
                  style={{ color: DrugDMConst.COLOR_CODE.cyan }}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header
                  as="h4"
                  style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}
                >
                  {formatDateByLanguage(moment(selectedData.date, "DD/MM/YYYY").format(DATE_FORMAT),"DD MMMM YYYY")}
                </Header>
              </Grid.Column>
            </Grid.Row>
            
            {/* Morning */}
            <SubHistory
              title={`${intl.formatMessage({ id: "bplusClinicKey329" })}`}
              compliance={selectedData?.morning?.compliance}
              items={selectedData?.morning?.items}
            />

            {/* Afternoon */}
            <SubHistory
              title={`${intl.formatMessage({ id: "bplusClinicKey708" })}`}
              compliance={selectedData?.afternoon?.compliance}
              items={selectedData?.afternoon?.items}
            />

            {/* Evening */}
            <SubHistory
              title={`${intl.formatMessage({ id: "bplusClinicKey190" })}`}
              compliance={selectedData?.evening?.compliance}
              items={selectedData?.evening?.items}
            />

            {/* Bedtime */}
            <SubHistory
              title={`${intl.formatMessage({ id: "bplusClinicKey81" })}`}
              compliance={selectedData?.bedtime?.compliance}
              items={selectedData?.bedtime?.items}
            />

            {/* Others */}
            <SubHistory
              title={`${intl.formatMessage({ id: "bplusClinicKey358" })}`}
              compliance={selectedData?.others?.compliance}
              items={selectedData?.others?.items}
            />

            <Grid.Row verticalAlign="top">
              <Grid.Column width={1}>
                <Icon
                  name="comment alternate outline"
                  size="large"
                  style={{ color: "orange" }}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Header
                  as="h4"
                  style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}
                >
                  <FormattedMessage id="bplusClinicKey454" />
                </Header>
              </Grid.Column>
              <Grid.Column width={9}>
                <label style={{ color: DrugDMConst.COLOR_CODE.darkGrey }}>
                  {selectedData?.sideEffectNote}
                </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Message
            // color={props.drugDataTabHistory?.summary?.color}
            color={"red"}
            style={{ margin: "0px 20px 35px 20px" }}
          >
            <Message.Header style={{ display: "flex" }}>
              <Icon name="warning sign" size="large" />
              {intl.formatMessage({ id: "bplusClinicKey827" })}
              {/* {props.drugDataTabHistory?.summary?.title} */}
            </Message.Header>
            <div style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "bplusClinicKey828" })}
            </div>
            {/* {props.drugDataTabHistory?.summary?.detail} */}
          </Message>
        </>
      )}
    </>
  );
};

DMDrugTabHistory.defaultProps = DMDrugTabHistoryInitial;

export default React.memo(DMDrugTabHistory);
