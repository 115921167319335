import React, { useEffect, useState } from "react";

// DatePicker
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// UI
import MarketingAnalyticsPersonal from "./MarketingAnalyticsPersonal";
import MarketingAnalyticsOverall from "./MarketingAnalyticsOverall";
import PostList from "./PostList";
import PostTab from "./PostTab";

import {MarketingAnalyticsType} from "../post/MarketingAnalyticsInterface";
import "../../css/WebClinic.scss";
import { Dimmer, Loader } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

type MarketingAnalyticsProps = {
  // function
  onEvent: any;
  setProp: any;
  // data
  organizationList?: any[];
  selectedOrganizationCode?: any;
  errorMessage?: any;
  successMessage?: any;
  loadingStatus?: boolean;
  loadingMarketingAnalytics?: boolean;
  loadingAnalyticsSegment?: boolean;
  loadingMarketingPatient?: boolean;
  loadingPatientAnalytics?: boolean;
  marketingAnalytics?: MarketingAnalyticsType;
  marketingAnalyticsSegment?: string[];
  analyticPatientList?: any;
  patientAnalyticsDetail?: any;
  showSegment?: boolean;
};

const MarketingAnalytics: React.FC<MarketingAnalyticsProps> = (props: any) => {
  const [activeSegment, setActiveSegment] = useState<number>(0);
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [segmentSelected, setSegmentSelected] = useState<any>({});
  const [selectHospital, setSelectHospital] = useState<number>(0);
  const [segmentList, setSegmentList] = useState<any[]>([]);
  const [panes, setPanes] = useState<any[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [keyWord, setKeyWord] = useState<any>("");

  useEffect(() => {
    props.onEvent({ message: "handleGetMarketingSegmentStorage" });
  }, []); // [props.selectedDivision]);

  useEffect(() => {
    handleChangeSegment(segmentSelected, activeSegment);
  }, [selectHospital]);

  useEffect(() => {
    let marketingAnalyticsSegment = (props.marketingAnalyticsSegment || [])
    if (!props.showSegment) {
      marketingAnalyticsSegment = (marketingAnalyticsSegment || []).filter((item:any)=>  item.add_manually !== false)
    }
    const segmentList = (marketingAnalyticsSegment || []).map((item: any, index: any) => {
      return { id: index, result: item.result, nameJS: `${item.result}.json` }
    });

    const filter = segmentList?.filter((item: any) =>
      item.result?.toLowerCase().includes(keyWord.toLowerCase())
    );

    setSelectHospital(props.selectedOrganizationCode);
    setSegmentList(filter);
    if (filter[0] != null) {
      setSegmentSelected(filter[0]);
      handleChangeSegment(filter[0], 0);
    }
  }, [props.marketingAnalyticsSegment,props.showSegment, keyWord]);

  const handleChangeSegment = (item: any, index: number) => {
    props.onEvent({
      message: "GetMarketingAnalytics",
      params: { name: item.nameJS },
    });

    const organization = props.organizationList?.find((items: any) => {
      if (
        items.code ===
        (selectHospital !== 0 ? selectHospital : props.selectedOrganizationCode)
      ) {
        return items.id;
      }
    })?.id;

    if (item.result != null && organization !== 0) {
      props.onEvent({
        message: "handleGetMarketingPatientListByHospital",
        params: {
          result: item.result,
          offset: 0,
          limit: 20,
          organization: organization,
        },
      });
    }

    setSegmentSelected(item);
    setActiveMenu(0);
    setActiveSegment(index);
  };

  const handleSearchPatient = (
    type: any,
    value: any,
    offset: any,
    limit: any
  ) => {
    // console.log("saika handleSearchPatient type : ", type , " value : " , value , " offset : " ,offset, " limit : ",limit);

    const organization = props.organizationList?.find((items: any) => {
      if (
        items.code ===
        (selectHospital !== 0 ? selectHospital : props.selectedOrganizationCode)
      ) {
        return items.id;
      }
    })?.id;

    let param = {};
    switch (type) {
      case "hn": {
        param = {
          result: segmentSelected.result,
          organization: organization,
          hn: value,
          offset: offset,
          limit: limit,
        };
        break;
      }
      case "name": {
        param = {
          result: segmentSelected.result,
          organization: organization,
          full_name: value,
          offset: offset,
          limit: limit,
        };
        break;
      }
      case "cid": {
        param = {
          result: segmentSelected.result,
          organization: organization,
          cid: value,
          offset: offset,
          limit: limit,
        };
        break;
      }
      case "select": {
        param = {
          result: segmentSelected.result,
          organization: organization,
          offset: offset,
          limit: limit,
        };
        break;
      }
      default: {
        param = {
          result: segmentSelected.result,
          organization: organization,
          offset: 0,
          limit: 20,
        };
      }
    }

    props.onEvent({
      message: "handleGetMarketingPatientListByHospital",
      params: param,
    });
  };

  useEffect(() => {
    setPanes([
      {
        key: "personal",
        image: "/images/expansion/crowd.png",
        render: () => (
          <MarketingAnalyticsPersonal
            onEvent={props.onEvent}
            organizationList={props.organizationList}
            marketingAnalytics={props.marketingAnalytics}
            selectedOrganizationCode={
              selectHospital !== 0
                ? selectHospital
                : props.selectedOrganizationCode
            }
            patientAnalyticsDetail={props.patientAnalyticsDetail}
            loadingPatientAnalytics={props.loadingPatientAnalytics}
            handleSelectHospital={handleSelectHospital}
            analyticPatientList={props.analyticPatientList}
            loadingMarketingPatient={props.loadingMarketingPatient}
            handleSearchPatient={handleSearchPatient}
            segmentAnalytics={segmentSelected}
          />
        ),
      },
      {
        key: "overall",
        image: "/images/expansion/carbon_analytics.png",
        render: () => (
          <MarketingAnalyticsOverall
            onEvent={props.onEvent}
            marketingAnalytics={props.marketingAnalytics}
            organizationList={props.organizationList}
            selectedOrganizationCode={
              selectHospital !== 0
                ? selectHospital
                : props.selectedOrganizationCode
            }
            handleSelectHospital={handleSelectHospital}
          />
        ),
      },
    ]);
  }, [
    segmentList,
    activeSegment,
    selectHospital,
    props.marketingAnalytics,
    props.patientAnalyticsDetail,
    props.analyticPatientList,
    props.loadingMarketingPatient,
    props.selectedOrganizationCode,
  ]);

  const handleSearchSegmentResult = (search: string) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeyWord(search);
      }, 800)
    );
  };

  const handleSelectHospital = (code: number) => {
    setSelectHospital(code);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "320px 1fr",
        }}
        className="analytics"
      >
        <div
          style={{
            gridColumn: 1,
            paddingTop: "10px",
            borderRight: "1px solid rgb(238, 238, 238)",
          }}
        >
          <PostList
            // function
            onEvent={props.onEvent}
            onClickItem={handleChangeSegment}
            onSearch={handleSearchSegmentResult}
            // data
            loadingStatus={props.loadingAnalyticsSegment}
            list={segmentList}
            activeIndex={activeSegment}
            hideAddButton={true}
            isSegment={true}
            titleKey="result"
          />
        </div>
        <div className="SegmentEditor">
          <PostTab
            initialActive={activeMenu}
            disableMenu={props.loadingMarketingAnalytics || false}
            smallPadding={true}
            activeSegment={activeSegment}
            list={segmentList}
            onClickTab={(_item: any, index: number) => setActiveMenu(index)}
            panes={panes}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default React.memo(MarketingAnalytics);
