import React, { useEffect } from 'react'
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';
import { Input, Icon, Button } from 'semantic-ui-react'
import { Bar } from 'react-chartjs-2'
import TextareaAutosize from 'react-textarea-autosize'
import FeedHead from '../clinic/FeedHead'
import MobHead from "../clinic/MobHead";
import TabBar from "../manage/TabBar";
import PostTagEditor from "../content/PostTagEditor";


export const Post = (props: any) => {

  const intl = useIntl();
  useEffect(() => {
      props.onEvent({ message: "GetPost", params: {} });
      props.onEvent({ message: "GetTagList", params: {}});
  }, [])
  
  const selectedPost = Number.isInteger(props.selectedPostIndex) ? 
      props.postList[props.selectedPostIndex] : null;

  console.log(props.selectedPostIndex);
  console.log(selectedPost);

  let link = null
  if (selectedPost) {
    const lines = selectedPost.title.split("\n")
    for (const line of lines) {
      try {
        const url = new URL(line)
        const valid = url.href.includes(".com") || 
                      url.href.includes(".org") ||
                      url.href.includes(".co.th") ||
                      url.href.includes(".ac.th") ||
                      url.href.includes(".or.th") ||
                      url.href.includes(".company") ||
                      url.href.includes(".center")
        if (valid) {
          if (!selectedPost.preview || url.href !== selectedPost.preview.url) {
            props.onEvent({ message: "GetPreview", params: { url: url.href }})
          }
          break
        }
      } catch (_) {}
    }
  }

  return(
    <div style={{ height: "100%", display: "grid", gridTemplateColumns: "350px 1fr 400px"}}>

      <div style={{ gridColumn: 1, borderRight: "solid #cccccc 0.1px" }}>
        <div style={{ display: "flex", alignItems: "center", borderBottom: "solid #dddddd 0.1px" }}>
          <Input style={{ flex: 1 }} />
          <Icon 
            name="plus" 
            style={{ minWidth: "25px" }} 
            color="green" 
            onClick={(e) => {
              props.onEvent({ message: "AddPost", params: {} })
            }}
          />
        </div>
        {props.postList?.map((post: any, index: number) => (
        <PostItem 
          key={index}
          highlighted={props.selectedPostIndex === index}
          post={post}
          onClick={(e: any) => {
            console.log(index);
            props.setProp("selectedPostIndex", index)
            props.setProp("preview", null)
          }}
        />
        ))}
      </div>

      <div style={{ gridColumn: 2, display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button 
            size="mini"
            color="green"
            onClick={(e) => {
              props.onEvent({ message: "SaveSelectedPost", params: {} })
            }}>
            {intl.formatMessage({id: "bplusClinicKey623"})}
          </Button>
          <Button 
            size="mini"
            color="red"
            onClick={(e) => { props.onEvent({ message: "DeleteSelectedPost", params: {} })}}>
             {intl.formatMessage({id: "bplusClinicKey614"})}
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "5px" }}>
          <div style={{ minWidth: "100px" }}><FormattedMessage id="bplusClinicKey443" /> </div>
          <TextareaAutosize
            style={{ flex: 1, border: "solid #dddddd 0.1px" }}
            value={(selectedPost?.segments || []).join(", ")}
            onChange={(e)=> {
              props.setProp(
                `postList.${props.selectedPostIndex}.segments`, 
                e.target.value.split(",").map(item => item.trim()));
            }}
            onBlur={(e) => { 
              props.onEvent({ message: "SaveSelectedPost", params: {} })
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "5px" }}>
          <div style={{ minWidth: "100px", display: "flex" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div><FormattedMessage id="bplusClinicKey481" /> </div>
              <div
                style={{ 
                  cursor: "pointer", 
                  display: "flex", 
                  borderRadius: "1rem", 
                  padding: "0 0.3rem 0 0.3rem", 
                  background: "lightgreen" 
                }}
                onClick={(e: any) => {
                  props.onEvent({
                    message: "AddTagSet",
                    params: { dataType: "post" }
                  })
                }}>
                +
              </div>
            </div>  
          </div>
          <div style={{ flex: 1 }}>
            <PostTagEditor 
              tagList={props.tagList}
              selectedPost={selectedPost} 
              setProp={props.setProp}
              onEvent={props.onEvent}
            />
          </div>
        </div>
        {/* <Dashboard data={props.data} onEvent={props.onEvent} /> */}
        <StatChart
          selectedPost={selectedPost}
        />
      </div>

      <div style={{ gridColumn: 3 }}>
        {selectedPost && 
        <div 
          style={{ 
            margin: "25px", height: "650px", border: "solid black 1px", 
            display: "flex", flexDirection: "column" 
          }}>
          <MobHead />
          <FeedHead />
          <div 
            style={{ 
              flex: 1, display: "flex", flexDirection: "column"
            }}>
            <Preview
              post={selectedPost}
              postIndex={props.selectedPostIndex}
              setProp={props.setProp}
              onEvent={props.onEvent}
            />
            <div style={{ flex: 1 }}></div>
          </div>
          <TabBar history={[]}/>
        </div>}
      </div>
      
    </div>
  )
}

export const Preview = (props: any) => (
  <div style={{ display: "flex", flexDirection: "column", borderBottom: "solid #aaaaaa 0.5px", padding:"3px"}}>
    <div style={{ display: "flex", marginBottom: "4px" }}>
      <img src={props.post?.oaInfo?.icon} style={{ width: "40px"}} />
      <div style={{ fontWeight: "bold" }}>
        <div>{props.post?.oaInfo?.name}</div>
        <div>{props.post?.created?.toDate()?.toLocaleDateString('th-TH')}</div>
      </div>
    </div>
    <TextareaAutosize
      style={{ border: "none", width: "100%" }}
      value={props.post.title}
      onChange={(e) => { 
        console.log("change")
        props.setProp(`postList.${props.postIndex}.title`, e.target.value);
      }}
      onBlur={(e) => { 
        props.onEvent({ message: "SaveSelectedPost", params: {} })
      }}
    />
    {props.post.preview ?
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <img style={{ maxWidth: "40%", width: "auto", height: "auto", objectFit: "contain" }} src={props.post.preview?.image} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ marginLeft: "5px", fontWeight: "bold" }}>{props.post.preview?.title}</div>
        <div style={{ marginLeft: "5px" }}>{props.post.preview?.description}</div>
      </div>
    </div>:
    <></>}
  </div>
)

const StatChart = (props: any) => (
  <div style={{ width: "100%", height: "50vh"}}>
    {props.selectedPost?.stats &&
    <Bar
      height={20}
      width={50}
      options={{ 
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0
            }
          }]
        }
      }}
      data={{
        labels: props.selectedPost?.stats
          ?.sort((a: any, b: any) => a.date_joined < b.date_joined ? -1 : 1)
          .map((row: any) => row.date_joined),
        datasets: [
          {
            label: "count",
            data: props.selectedPost?.stats?.map((row: any) => row.count),
            backgroundColor: "green"
          }
        ]
      }}
    />}
  </div>
)

export const PostItem = (props) => {

  const lines = props.post?.title.split("\n")
  const title = lines.reduce((acc, cur) => (
    cur.includes("https") || cur.includes("http") ? acc : acc.concat(cur)
  ))

  return(
  <div 
    style={{ 
      cursor: "pointer",
      padding: "5px",
      backgroundColor: props.highlighted ? "#d8f3fa" : "white",
      borderBottom: "solid #cccccc 0.1px",
      overflow: "hidden"
    }}
    onClick={props.onClick}>    
    <div>[{props.post?.created?.toDate()?.toLocaleDateString('th-TH')}] {title}</div>
  </div>)
}
