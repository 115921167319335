import moment from "moment";
import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Divider, Grid, Icon, Image } from "semantic-ui-react";

type ConfirmTelepharProps = {
  gotoPostponeTelephar: any;
  dataStateLocation: any;
  handleGoBack: any;
  onSetChannelName: any;
};

const ICON = {
  pin: "/images/telephar/pin_location_white.png",
  calendarWhite: "/images/telephar/icon_calendar_white.png",
  calendarBlue: "/images/telephar/icon_calendar_blue.png",
  information: "/images/telephar/icon_information.png",
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  white: "white",
  lighterBlue: "#EBF1F8",
  lightBlue: "#A3D2F8",
  normalGrey: "#393939",
  lighter: "#DBE5F3",
};

const styles = {
  buttonConfirmBox: {
    margin: "30px 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "1px",
  } as CSSProperties,
  buttonConfirm: {
    display: "flex",
    justifyContent: "center",
    background: COLOR.primary,
    color: "white",
    width: "70%",
    padding: "14px 0",
    fontWeight: "bold",
    fontSize: "18px",
    borderRadius: "8px",
  } as CSSProperties,
  boxWarning: {
    margin: "10px",
    background: COLOR.lighterBlue,
    border: `1px solid ${COLOR.lightBlue}`,
    borderRadius: "8px",
  } as CSSProperties,
  cardAppointment: {
    margin: "10px 0",
    padding: "16px",
    background: COLOR.primary,
    borderRadius: "8px",
  } as CSSProperties,
  setCenter: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  textWhite: {
    fontSize: "12px",
    color: "white",
  } as CSSProperties,
};

const ConfirmTelephar = (props: ConfirmTelepharProps) => {
  const [test, setTest] = useState<any>({});
  useEffect(() => {
    props.onSetChannelName({ name: props?.dataStateLocation?.chatName || "" });
  }, []);

  return (
    <div
      style={{
        margin: "10px",
        position: "relative",
        height: "calc(100vh - 60px)",
      }}
    >
      <div style={styles.boxWarning}>
        <Grid style={{ margin: "0px" }}>
          <Grid.Row style={{ padding: "1rem" }}>
            <Grid.Column
              width={2}
              style={{
                ...styles.setCenter,
                alignItems: "center",
                padding: "0px",
              }}
            >
              <Image
                src={ICON.information}
                style={{ width: "24px", height: "24px" }}
              />
            </Grid.Column>
            <Grid.Column width={14} style={{ padding: "0px" }}>
              <div
                style={{ fontSize: "14px", color: "#008BF5", whiteSpace: "pre-line",marginLeft: "5px" }}
              >{`กรุณานัดหมายภายใน 24 ชม.\nเพื่อประสิทธิภาพที่ดีในการรักษาของท่าน`}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: COLOR.normalGrey,
          }}
        >
          วันและเวลานัดหมาย
        </div>
        <div
          style={{ display: "flex" }}
          onClick={() => props.gotoPostponeTelephar(props.dataStateLocation)}
        >
          <div
            style={{
              ...styles.setCenter,
              alignItems: "center",
              paddingRight: "5px",
            }}
          >
            <Image src={ICON.calendarBlue} style={{ width: "16px" }} />
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: COLOR.primary,
              textDecorationLine: "underline",
            }}
          >
            แก้ไข
          </div>
        </div>
      </div>
      <div>
        <div style={styles.cardAppointment}>
          <div style={{ ...styles.textWhite, fontWeight: "bold" }}>
            ช่วงเวลาที่เร็วที่สุด
          </div>
          <div
            style={{
              ...styles.cardAppointment,
              margin: "10px 0",
              padding: "0 7px",
              background: "#3875bf",
            }}
          >
            <Grid
              style={{
                margin: "0px",
                borderRadius: "2px",
              }}
            >
              <Grid.Row>
                <Grid.Column
                  width={8}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "0px",
                    borderRight: "1px solid white"
                  }}
                >
                  <div style={styles.setCenter} >
                    <div
                      style={{
                        ...styles.setCenter,
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <Image
                        src={ICON.calendarWhite}
                        style={{ width: "16px", marginRight: "5px" }}
                      />
                    </div>
                    <div style={{ ...styles.textWhite, fontSize: "14px" }}>
                      {props.dataStateLocation.date}
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  width={8}
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "white",
                    padding: "0px",
                  }}
                >
                  <div style={styles.setCenter}>
                    <Icon name="clock outline" />
                    <div style={{ ...styles.textWhite, fontSize: "14px" }}>
                      {props.dataStateLocation.time}
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ display: "flex" }}>
            <Image
              src={ICON.pin}
              style={{ width: "16px", marginRight: "5px" }}
            />
            <div style={styles.textWhite}>{`รพ. กรุงเทพ สำนักงานใหญ่`}</div>
          </div>
        </div>
      </div>
      <div style={styles.buttonConfirmBox}>
        <div style={styles.buttonConfirm} onClick={() => props.handleGoBack()}>
          ยืนยันการนัดหมาย
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConfirmTelephar);
