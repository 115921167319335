import React, { useState, useMemo, useEffect, forwardRef } from "react";
import "./MainLabResultCSS.scss";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  Divider,
  Icon,
  Button,
  Loader,
  Tab,
  TabProps,
  Dimmer,
  Image,
  Input,
  InputProps,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import { Skeleton } from "@material-ui/lab";
import { Dialog, Slide } from "@material-ui/core";
import {
  makeStyles,
  Avatar,
  Button as MuiButton,
  Checkbox,
} from "@material-ui/core";

//component
import LabProfile from "./LabProfile";
import VisitDate from "./VisitDate";
import Doctor from "./Doctor";
import moment from "moment";

// type
import { LabOrderType } from "bplus-lib/MobHistoryinterface";
import { VisitDateItemsType, ChildLabResult } from "bplus-lib/MobLABInterface";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  blue: " #2399E5",
  gray: " #393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0px 20px",
    color: COLOR.primary,
  } as CSSProperties,
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  divsearch: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    borderRadius: "20px",
    marginBottom: "18px",
  } as CSSProperties,
  box_list: {
    display: "grid",
    gridTemplateColumns: "40px auto 40px",
    alignItems: "center",
    padding: "10px 10px 14px 10px",
    borderBottom: `1px solid #E9E9E9`,
    fontSize: "16px",
    "& img": {
      padding: "5px",
    },
    width: "100%",
  },
  button_close: {
    borderRadius: "5px",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#ffffff",
  } as CSSProperties,
};

type MainLabResultProps = {
  onEvent: (e: any) => any;
  setProp: (e: any) => any;
  labItems?: LabOrderType[];
  selectedParent?: any;
  childLabItems?: ChildLabResult[];
  loadingLabResult?: boolean;
  pdfDownloadStatus?: boolean;
  selectedHospital?: any;
  loadingHospital?: boolean;
  loadingLabByEn?: boolean;
  getLabParent?: any;
  visitDateItems?: VisitDateItemsType[];
};

const TransitionUp = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const ICON = {
  location: "/images/Feed/location-outline.png",
  bplus: "/logo512.png",
};

const MainLabResult: React.FunctionComponent<MainLabResultProps> = (props) => {
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  const [height, setHeight] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [textSearch, setTextSearch] = useState("");
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>([]);

  useEffect(() => {
    moment.locale(
      navigator.languages[0].toLowerCase().includes("th") ? "th" : "en"
    );
    console.log("chen props.selectedHospital", props.selectedHospital);
    if (props.selectedHospital) {
      props.onEvent({
        message: "handleGetLabParent",
        params: { hospital: props.selectedHospital?.code },
      });

      props.onEvent({
        message: "HandleLabResultByEncounter",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  }, [props.selectedHospital]);

  useEffect(() => {
    setSelectedFilter(props.labItems);
  }, [props.labItems]);

  useEffect(() => {
    if (height) {
      setHeight(false);
      setTimeout(() => setHeight(true), 1000);
    } else {
      setHeight(true);
    }
  }, [
    props.visitDateItems,
    props.childLabItems,
    textSearch,
    props.selectedHospital,
  ]);

  const panes = useMemo(
    () => [
      {
        menuItem: "Visit Date",
      },
      {
        menuItem: "Lab Profile",
      },
      {
        menuItem: "Doctor",
      },
    ],
    []
  );

  const handleSearch = (e: any, v: InputProps) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setTextSearch(v.value);
      }, 800)
    );
  };

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  return (
    <div style={{ ...styles.parentbox }}>
      {props.loadingLabResult ||
      props.loadingHospital ||
      props.loadingLabByEn ? (
        // Skeleton MainLabResult

        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <div style={{ width: "50%" }}>
              {" "}
              <Skeleton height={40} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "90%" }}>
              {" "}
              <Skeleton height={150} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              {" "}
              <Skeleton height={40} />
            </div>
          </div>
          {new Array(3).fill("").map((item: any) => (
            <div
              key={item}
              style={{
                borderRadius: "15px",
                height: "140px",
                width: "90%",
                margin: "0px",
              }}
            >
              <Skeleton height={150} />
            </div>
          ))}
        </>
      ) : (
        // MainLabResult

        <>
          <div style={{ margin: " 20px 0px" }}>
            <div style={styles.title}>
              <FormattedMessage id="bplusClinicKey937" />
            </div>
            <Divider
              style={{
                background: "red",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              variant="middle"
            />
          </div>

          <div style={styles.divsearch}>
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Input
                icon={
                  <Icon
                    name="search"
                    style={{
                      color: COLOR.gray,
                    }}
                  />
                }
                placeholder={
                  viewIndex === 0
                    ? "Clinic/Department"
                    : viewIndex === 1
                    ? "Lab name"
                    : "Search doctor"
                }
                iconPosition="left"
                style={{ width: "100%" }}
                onChange={handleSearch}
              />
              {viewIndex === 1 ? (
                <Image
                  src="/images/lab/lab_options.png"
                  style={{ marginLeft: "auto", width: "39px" }}
                  onClick={() => {
                    setShowFilterDialog(true);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>

          <div style={{ width: "100%", maxHeight: "100vh" }}>
            <Tab
              className="lab-tab-custom"
              menu={{ secondary: true, pointing: true }}
              panes={panes}
              activeIndex={viewIndex}
              onTabChange={handleTabChange}
            />

            <SwipeableViews
              index={+viewIndex}
              onChangeIndex={handleChangeIndex}
              animateHeight={height}
            >
              <div style={{ paddingBottom: "35px" }}>
                <VisitDate
                  onEvent={props.onEvent}
                  textSearch={textSearch}
                  visitDateItems={props.visitDateItems}
                  selectedHospital={props.selectedHospital}
                  pdfDownloadStatus={props.pdfDownloadStatus}
                />
              </div>
              <div style={{ paddingBottom: "35px" }}>
                <LabProfile
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  selectedHospital={props.selectedHospital}
                  labItems={selectedFilter}
                  selectedParent={props.selectedParent}
                  childLabItems={props.childLabItems}
                  textSearch={textSearch}
                  pdfDownloadStatus={props.pdfDownloadStatus}
                />
              </div>
              <div style={{ paddingBottom: "35px" }}>
                <Doctor
                  onEvent={props.onEvent}
                  textSearch={textSearch}
                  visitDateItems={props.visitDateItems}
                  selectedHospital={props.selectedHospital}
                />
              </div>
            </SwipeableViews>
          </div>
        </>
      )}

      <Dialog
        fullScreen
        open={showFilterDialog}
        // @ts-ignore
        TransitionComponent={TransitionUp}
        // className={classes.backdrop_root}
      >
        <div style={{ background: "white", height: "100vh", overflow: "auto" }}>
          <div style={{ position: "absolute", right: "10px", top: "10px" }}>
            <Button
              icon
              size="big"
              onClick={() => {
                setShowFilterDialog(false);
              }}
              style={styles.button_close}
            >
              <Icon name="close" />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "40px 0 10px 0",
            }}
          >
            <div style={{ width: "40%", margin: " 20px 0px" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: COLOR.primary,
                }}
              >
                {" "}
                <FormattedMessage id="bplusClinicKey942" />{" "}
              </div>
              <Divider
                style={{
                  background: "red",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
                variant="middle"
              />
            </div>
          </div>

          <div style={{ height: "calc(100vh - 250px)", overflow: "auto" }}>
            {props.labItems?.map((item: any, index: number) => (
              <MuiButton
                key={"hospital-" + index}
                variant="text"
                color="primary"
                style={{
                  textAlign: "left",
                  textTransform: "unset",
                  width: "100%",
                  justifyContent: "flex-start",
                  paddingBottom: 0,
                }}
                onClick={() => {
                  let tmp = [...selectedFilter];
                  const objWithIdIndex = tmp.findIndex(
                    (it: any) => it.id === item.id
                  );
                  if (objWithIdIndex > -1) {
                    tmp.splice(objWithIdIndex, 1);
                  } else {
                    tmp.push(item);
                  }
                  setSelectedFilter(tmp);
                }}
                // disabled={!props?.firstLoadingHospital}
              >
                <div key={"parent-" + index} style={styles.box_list}>
                  {true ? (
                    <>
                      <div
                        style={{
                          width: "27px",
                          height: "27px",
                        }}
                      ></div>
                      <div style={{ fontWeight: "bold", color: COLOR.primary }}>
                        {item.name}
                      </div>
                      <div style={{ marginTop: "-3px" }}>
                        <Checkbox
                          checked={selectedFilter.some(
                            (it: any) => it.id === item.id
                          )}
                          size="medium"
                          color="primary"
                          value={item.id}
                          name="radio-buttons"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        animation="pulse"
                        variant="circle"
                        width={27}
                        height={27}
                        style={{}}
                      />
                      <Skeleton
                        animation="pulse"
                        height={18}
                        style={{}}
                        width="100%"
                      />
                    </>
                  )}
                </div>
              </MuiButton>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: "30px",
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "40%",
                background: "#ffffff",
                color: COLOR.primary,
                border: "2px solid",
                borderColor: COLOR.primary,
                borderRadius: "20px",
              }}
              onClick={() => {
                setSelectedFilter([]);
              }}
            >
              <FormattedMessage id="bplusClinicKey113" />
            </Button>
            <Button
              style={{
                width: "40%",
                background: COLOR.primary,
                color: "#ffffff",
                borderRadius: "20px",
              }}
              onClick={() => {
                setShowFilterDialog(false);
              }}
            >
              <FormattedMessage id="bplusClinicKey67" />
            </Button>
          </div>

          {props.firstLoadingHospital && !props.hospitalList?.[0] && (
            <div className={classes.not_found}>
              <div style={{ color: "#b0b0b0" }}>
                <Icon name="hospital outline" />
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "30px",
                  }}
                >
                  {intl.formatMessage({ id: "bplusClinicKey645" })}
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(MainLabResult);
