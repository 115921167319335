import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";

import { FormattedMessage } from "react-intl";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import {
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Loader,
  Input,
  Image,
  Search,
  Divider,
} from "semantic-ui-react";
import { MenuItem, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import { DateTextBox } from "../../react-lib/apps/common/";

import "../../css/WebClinic.scss";
import moment, { min, Moment } from "moment";
import HospitalDropDown from "./HospitalDropDown";
import { Padding } from "@mui/icons-material";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FooterChart } from "bplus-lib/clinic/DMEmotionRestChart";
import { prepareDataDraw } from "../clinic/DMBloodPressure";
import { Event } from "bplus-lib/ManageInterface";
import DateRangePicker from "@mui/lab/DateRangePicker";
import DayPicker, {
  DateUtils,
  Modifier,
  RangeModifier,
} from "react-day-picker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

//type
import {
  MarketingAnalyticsType,
  UserRelevantLifetimeType,
} from "../post/MarketingAnalyticsInterface";

type MarketingAnalyticsOverallProps = {
  // function
  onEvent: (e: Event) => void;
  // data
  organizationList?: any[];
  selectedOrganizationCode?: number;
  loadingMarketingAnalytics?: boolean;
  marketingAnalytics?: MarketingAnalyticsType;
  handleSelectHospital: (code: number) => void;
};

type LegendLabel = {
  name: string;
  value: number;
  colorLegend: string;
};

type commonType = {
  name?: string;
  items?: engagementSummary;
  color?: string;
  labels?: string;
  datasets?: string;
};

type TotalEngagementProps = {
  infoSummary?: infoSummary;
  userData: UserRelevantLifetimeType[];
};

type MostUsersListProps = {
  infoSummary?: infoSummary;
  userData: UserRelevantLifetimeType[];
  isTopUser?: boolean;
};

type ChartProps = {
  lineDataset?: graphDataType[];
  rangDataSet?: graphDataType[];
  onFilterMonth?: Function;
  sortByMenu?: string;
  rangeDate?: {
    startDate?: string;
    endDate?: string;
  };
  selectMonth?: string;
};

const ICONS = {
  Share: "/images/expansion/icon_share_blue.png",
  Appointment: "/images/expansion/icon_appointment.png",
  User: "/images/icon_user_blue.png",
  Like: "/images/expansion/icon_like_blue.png",
  Cart: "/images/expansion/icon_cart_blue.png",
  Menu: "/images/expansion/icon_menu.png",
};

const COLORS = {
  primary: "var(--primary-theme-color)",
  blue_light: "#EAF0F8",
  gary: "#C8CBD9",
  black: "black",
  purple: "#422F8ADE",
  text_gary: "#797878",
};

const styles = {
  title: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    color: COLORS.primary,
    fontSize: "18px",
    textAlign: "center",
    padding: "10px 0",
  } as CSSProperties,
  header_section: {
    fontSize: "16px",
    color: COLORS.black,
  } as CSSProperties,
  title_section: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLORS.black,
  } as CSSProperties,
  set_center: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  image_user: {
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
};

type infoSummary = {
  engagementCounts?: number;
  reachCounts?: number;
  viewCounts?: number;
  impressionCounts?: number;
  clickCounts?: number;
  likeCounts?: number;
  shareCounts?: number;
  appointmentCounts?: number;
};

type engagementSummary = {
  user: number;
  likeCounts: number;
  shareCounts: number;
  appointmentCounts: number;
  buyCounts: number;
  saveCounts: number;
};

type infoDataType = {
  icon: string;
  title: string;
  number: string;
  type: string;
};

type MostUserType = {
  name: string;
  like: number | string;
  share: number | string;
  appointment: number | string;
};

type graphDataType = {
  date: string;
  engagement: number;
  reach: number;
};

const options_PatientGroup = [
  { key: 99, text: "All", value: "All" },
  { key: 0, text: "Elderly", value: "Elderly" },
  { key: 1, text: "Adult", value: "Adult" },
  { key: 2, text: "Child", value: "Child" },
];

const options_GenderGroup = [
  { key: 99, text: "All", value: "All" },
  { key: 0, text: "Male", value: "Male" },
  { key: 1, text: "Female", value: "Female" },
  { key: 2, text: "Unknown", value: "Unknown" },
];

const shortenNumber = (number: number) => {
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + "K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
};

// ---------------------- MarketingAnalyticsOverall main tab  ------------------------
const MarketingAnalyticsOverall: React.FC<MarketingAnalyticsOverallProps> = (
  props: any
) => {
  const [sortByMenu, setSortByMenu] = useState<string>("post_relevant_daily");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [infoSummary, setInfoSummary] = useState<infoSummary>({});
  const [dataSetAll, setDataSetAll] = useState<graphDataType[]>([]);
  const [rangDataSet, setRangeDataSet] = useState<graphDataType[]>([]);
  const [monthDataSet, setMonthDataSet] = useState<graphDataType[]>([]);
  const [userData, setUserData] = useState<UserRelevantLifetimeType[]>([]);
  const [selectMonth, setSelectMonth] = useState<string>("");
  const [keyWordSearch, setKeyWordSearch] = useState<string>("");
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [modifiers, setModifiers] = useState<any>({
    start: undefined,
    end: undefined,
  });

  // ************** this useEffect setUp MainData ************** //
  useEffect(() => {
    let infoData: infoSummary = {
      engagementCounts: 0,
      reachCounts: 0,
      viewCounts: 0,
      impressionCounts: 0,
      clickCounts: 0,
      likeCounts: 0,
      shareCounts: 0,
      appointmentCounts: 0,
    };

    const graphData: graphDataType[] = [];
    if (props.marketingAnalytics != null) {
      const analytics = props.marketingAnalytics;
      console.log("saika ~ analytics:", analytics);

      // gat data object to array //

      let userRelevant: UserRelevantLifetimeType[] = Object.values(
        analytics.user_relevant_lifetime ? analytics.user_relevant_lifetime : {}
      );
      const analyticsArray: Record<string, any> = analytics;
      let analyticsLifetime = "post_engagement_lifetime";
      const analyticsData = Object.entries(analyticsArray).filter((item) => {
        if (sortByMenu.toLowerCase().includes(item[0].toLowerCase())) {
          if (item[0] === "post_engagement_lifetime") {
            analyticsLifetime = "post_engagement_lifetime";
          } else if (item[0] === "promotion_engagement_lifetime") {
            analyticsLifetime = "promotion_engagement_lifetime";
          } else if (item[0] === "appointment_engagement_lifetime") {
            analyticsLifetime = "appointment_engagement_lifetime";
          }
        }
        return sortByMenu.toLowerCase().includes(item[0].toLowerCase());
      })?.[0];

      const analyticsLifetimeData = Object.entries(analyticsArray).filter(
        (item) =>
          analyticsLifetime.toLowerCase().includes(item[0].toLowerCase())
      )?.[0];
      const dataLifetime = Object.entries(
        analyticsLifetimeData ? analyticsLifetimeData[1] : {}
      );
      let selectKeyPost = "";
      dataLifetime.forEach(([key, value]) => {
        // search for title find key firebase //
        const valueItem: any = value;
        if (
          keyWordSearch !== "" &&
          valueItem.title.toLowerCase().search(keyWordSearch.toLowerCase()) !==
            -1
        ) {
          selectKeyPost = key;
        }
      });

      // console.log("  selectKeyPost ", selectKeyPost);
      // console.log(" analyticsData ", analyticsData);
      let dataList = Object.entries(analyticsData ? analyticsData[1] : {});
      console.log("saika ~ dataList:", dataList)

      // set data graph && Summary //
      if (keyWordSearch) {
        dataList = dataList.filter(([postName, value]) =>
          postName === selectKeyPost
        );
      }
      console.log("saika ~ dataList:", dataList)

      dataList.forEach(([postName, value]) => {
        const valueItem: any = value;
        const dailyList = Object.entries(valueItem);
        dailyList.forEach(([keyDaily, valueDaily]) => {
          const valueDailyItem: any = valueDaily;
          infoData.engagementCounts += valueDailyItem.engagement_counts;
          infoData.reachCounts += valueDailyItem.reach_counts;
          infoData.viewCounts += valueDailyItem.view_counts;
          infoData.impressionCounts += valueDailyItem.impression_counts;
          infoData.clickCounts += valueDailyItem.click_counts;
          infoData.likeCounts += valueDailyItem.like_counts;
          infoData.shareCounts += valueDailyItem.share_counts;
          infoData.appointmentCounts += valueDailyItem.appointment_counts;
          const checkDate = graphData.findIndex(
            (item) => item.date === keyDaily
          );
          if (checkDate != -1) {
            const engagement =
              graphData[checkDate].engagement +
              valueDailyItem.engagement_counts;
            const reach =
              graphData[checkDate].reach + valueDailyItem.reach_counts;
            const graphDataDump = {
              date: keyDaily,
              engagement: engagement,
              reach: reach,
            };
            graphData[checkDate] = graphDataDump;
          } else {
            graphData.push({
              date: keyDaily,
              engagement: valueDailyItem.engagement_counts,
              reach: valueDailyItem.reach_counts,
            });
          }
        });
      });

      // set fist select Month //
      const dateNow = moment();
      const graphDataMonth = graphData.filter(
        (item: graphDataType) => moment(item.date).month() === dateNow.month()
      );

      // sort userRelevant //
      userRelevant.sort(
        (a: UserRelevantLifetimeType, b: UserRelevantLifetimeType) => {
          const aVal =
            (a.appointment_counts || 0) +
            (a.like_counts || 0) +
            (a.share_counts || 0);
          const bVal =
            (b.appointment_counts || 0) +
            (b.like_counts || 0) +
            (b.share_counts || 0);
          return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
        }
      );

      if (
        props.marketingAnalytics?.segment_name?.toLowerCase().search("female") >
        -1
      ) {
        userRelevant = userRelevant.filter((item: UserRelevantLifetimeType) =>
          ["F"].includes(item.gender)
        );
      } else if (
        props.marketingAnalytics?.segment_name?.toLowerCase().search("male") >
        -1
      ) {
        userRelevant = userRelevant.filter((item: UserRelevantLifetimeType) =>
          ["M"].includes(item.gender)
        );
      }

      setSelectMonth(dateNow.format("YYYYMMDD"));
      setDataSetAll(graphData);
      setRangeDataSet(graphData);
      setUserData(userRelevant);
      setMonthDataSet(graphDataMonth);
      setInfoSummary(infoData);
    }
  }, [props.marketingAnalytics, sortByMenu, keyWordSearch]);

  useEffect(() => {
    let DataRange: graphDataType[] = dataSetAll;
    if (startDate != "" && endDate != "") {
      DataRange = dataSetAll.filter((item: graphDataType) => {
        return item.date >= startDate && item.date <= endDate;
      });
    }
    const graphDataMonth = DataRange.filter(
      (item: graphDataType) =>
        moment(item.date).month() === moment(selectMonth).month()
    );
    setRangeDataSet(DataRange);
    setMonthDataSet(graphDataMonth);
  }, [selectMonth]);

  useEffect(() => {
    if (endDate != "") {
      setSelectMonth(moment(endDate).format("YYYYMMDD"));
    } else {
      setSelectMonth(moment().format("YYYYMMDD"));
    }
  }, [rangeDate]);

  const options_menu = [
    { key: "Post", text: "Post", value: "post_relevant_daily" },
    { key: "Promotion", text: "Promotion", value: "promotion_relevant_daily" },
    {
      key: "Appointment",
      text: "Appointment",
      value: "appointment_relevant_daily",
    },
  ];

  const trigger_menu = (
    <span style={{ ...styles.set_center, alignItems: "center" }}>
      <Image src={ICONS.Menu} style={{ width: "16px", height: "16px" }} />
      <label style={{ margin: "0 10px" }}>Menu</label>
      <Icon name={"caret down"} />
    </span>
  );

  const handleFilter = (date: string) => {
    setSelectMonth(date);
  };

  const handleSearchData = (keyWord?: string) => {
    setKeyWordSearch(keyWord || "");
  };

  const handleDayClick = (day: any) => {
    const range = DateUtils.addDayToRange(day, rangeDate);
    setRangeDate(range);
    setStartDate(
      range.from
        ? moment(range.from.toLocaleDateString(), "MM/DD/YYYY").format(
            "YYYYMMDD"
          )
        : ""
    );
    setEndDate(
      range.to
        ? moment(range.to.toLocaleDateString(), "MM/DD/YYYY").format("YYYYMMDD")
        : ""
    );
    setModifiers({ start: range.from, end: range.to });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HospitalDropDown
          organizationList={props.organizationList}
          selectedOrganizationCode={props.selectedOrganizationCode}
          handleSelectHospital={props.handleSelectHospital}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
        }}
      >
        <div className="analytics-search">
          <Search
            input={{ icon: "search", iconPosition: "left" }}
            className="search-segment"
            onSearchChange={(e, { value }) => {
              handleSearchData(value);
            }}
            placeholder="Search Tag, Post, Promotion, Product&Service"
            showNoResults={false}
            value={keyWordSearch}
          />
        </div>
        <div style={{ display: "flex", width: "50%", justifyContent: "right" }}>
          <div style={styles.set_center}>
            <div className="button-dropdown">
              <Dropdown
                trigger={trigger_menu}
                options={options_menu}
                icon={null}
                onChange={(e, data) => {
                  setSortByMenu(`${data.value}`);
                }}
              />
            </div>
            <div
              className="button-dropdown-range-date"
              style={{ position: "relative" }}
            >
              <div onClick={() => setOpenDate(!openDate)}>
                <span>
                  <Icon name={"calendar alternate"} />
                  <label style={{ margin: "0 10px" }}>Date</label>
                  <Icon name={"caret down"} />
                </span>
              </div>
              <div
                className="date-picker-range"
                style={{ display: openDate ? "" : "none" }}
              >
                <DayPicker
                  className="Selectable"
                  numberOfMonths={1}
                  selectedDays={[rangeDate]}
                  modifiers={modifiers}
                  onDayClick={(day: any) => {
                    handleDayClick(day);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "25px 0" }}>
        <Grid
          style={{
            margin: "0px",
            background: "#EAF0F8",
            borderRadius: "5px",
          }}
          columns={6}
          divided
        >
          <Grid.Row style={{ padding: "10px 0" }}>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(infoSummary.viewCounts || 0)}`}</div>
                <div>view</div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(
                  infoSummary.impressionCounts || 0
                )}`}</div>
                <div>Impression</div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(infoSummary.reachCounts || 0)}`}</div>
                <div>Reach</div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(
                  infoSummary.engagementCounts || 0
                )}`}</div>
                <div>Engagement</div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(infoSummary.shareCounts || 0)}`}</div>
                <div>Share</div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.title}>
                <div>{`${shortenNumber(0)}`}</div>
                <div>Purchase</div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <LineChart
        lineDataset={monthDataSet}
        rangDataSet={rangDataSet}
        onFilterMonth={handleFilter}
        sortByMenu={
          options_menu.find(
            (item: Record<string, string>) => item.value === sortByMenu
          )?.text
        }
        rangeDate={{ startDate: startDate, endDate: endDate }}
        selectMonth={selectMonth}
      />
      <div style={{ display: "flex", borderTop: `1px solid ${COLORS.gary}` }}>
        <div style={{ width: "50%", borderRight: `1px solid ${COLORS.gary}` }}>
          <TotalEngagement userData={userData} />
          <MostUsersList userData={userData} />
        </div>
        <div style={{ width: "50%", padding: "10px 0 0 25px" }}>
          <BarChart selectMonth={selectMonth} />
          <MostUsersList userData={userData} isTopUser={true} />
        </div>
      </div>
    </div>
  );
};

// ---------------------- Section LineChart ------------------------
const LineChart: React.FunctionComponent<ChartProps> = (props) => {
  const [lineData, setLineData] = useState<any>({});
  const [value, setValue] = React.useState<moment.Moment | null>(moment());
  const [OpenDate, setOpenDate] = useState<boolean>(false);
  const [skipIndex, setSkipIndex] = useState(0);
  const [maxDate, setMaxDate] = useState<moment.Moment | null>(moment());
  const [minDate, setMinDate] = useState<moment.Moment | null>(moment());
  const [maxDataSet, setMaxDataSet] = useState<number>(1);
  const [minDataSet, setMinDataSet] = useState<number>(0);
  const activePoint = useRef<any>(null);

  const labelLimit = 21;

  /*************** useEffect setFrom line dataset only *******************/
  useEffect(() => {
    const dataSet1: number[] = [];
    const dataSet2: number[] = [];
    let labels: string[] = [];
    const startDate = props.rangeDate?.startDate;
    const endDate = props.rangeDate?.endDate;
    const currentMonth = moment(props.selectMonth, "YYYYMMDD").format(
      "MM/YYYY"
    );
    // console.log(" selectMonth ", moment(props.selectMonth,"YYYYMMDD"));
    setValue(moment(props.selectMonth, "YYYYMMDD").locale("en"));
    const monthIndex = moment(currentMonth, "MM/YYYY").daysInMonth();
    // const monthIndex = moment().set("month", 1).daysInMonth();
    const datOfMonth = Array.from({ length: monthIndex }, (_, i) => i + 1);
    datOfMonth.forEach((days: any) => {
      const dataFilter = (props.lineDataset || []).filter(
        (item: graphDataType) => days == moment(item.date).format("DD")
      );
      labels.push(`${days}/${currentMonth}`);
      if (dataFilter.length === 0) {
        dataSet1.push(0);
        dataSet2.push(0);
      } else {
        dataSet1.push(dataFilter[0].engagement);
        dataSet2.push(dataFilter[0].reach);
      }
    });

    // check max & min DatePicker //
    const maxDataset = Math.max(...dataSet1, ...dataSet2);
    setMaxDataSet(maxDataset > 0 ? maxDataset : 1);
    setMinDataSet(Math.min(...dataSet1, ...dataSet2));

    const data = {
      labels,
      datasets: [
        {
          label: "Engagement",
          data: dataSet1,
          borderColor: "rgb(87, 71, 150)",
          fill: false,
          pointRadius: 0,
          pointHoverRadius: 7,
          pointHoverBackgroundColor: "rgb(87, 71, 150)",
          pointHoverBorderColor: "rgba(255, 255, 255, 1)",
          pointHoverBorderWidth: 4,
        },
        {
          label: "Reach",
          data: dataSet2,
          borderColor: "rgb(243, 156, 18)",
          fill: false,
          pointRadius: 0,
          pointHoverRadius: 7,
          pointHoverBackgroundColor: "rgb(243, 156, 18)",
          pointHoverBorderColor: "rgba(255, 255, 255, 1)",
          pointHoverBorderWidth: 4,
        },
      ],
    };
    const sortDate = (props.rangDataSet || []).sort(
      (a: any, b: any) => a.date - b.date
    );
    // console.log(" setMinDate ", (startDate != ""?  moment(startDate,"YYYY/MM/DD") : moment(sortDate[0]?.date) || currentMonth));
    // console.log(" setMaxDate ", (endDate != "" ? moment(endDate,"YYYY/MM/DD") : moment()));
    setMinDate(
      startDate != ""
        ? moment(startDate, "YYYY/MM/DD")
        : moment(sortDate[0]?.date) || currentMonth
    );
    setMaxDate(endDate != "" ? moment(endDate, "YYYY/MM/DD") : moment());
    setLineData(data);
    setSkipIndex(0);
  }, [
    props.lineDataset,
    props.rangDataSet,
    props.rangeDate,
    props.selectMonth,
  ]);

  const LineDataSetSlice = useMemo(() => {
    let sliceArray = (list: any[]) =>
      list.slice(skipIndex, labelLimit + skipIndex);
    const cloneData = { ...lineData };
    let data: any = [];
    const labels = sliceArray(cloneData.labels || []);
    let datasets = (cloneData?.datasets || []).map((item: any) => {
      data = sliceArray(item.data || []);
      return {
        ...item,
        data,
      };
    });
    return {
      labels,
      datasets,
    };
  }, [lineData, skipIndex]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "nearest",
      axis: "xy",
      intersect: false,
    },
    layout: {
      padding: {
        left: 20,
        right: 40,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            lineWidth: 0,
            offset: false,
          },
          ticks: {
            userCallback: function (label: any, index: any, labels: any) {
              return moment(label, "DD/MM/YYYY").format("D");
            },
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          min: minDataSet,
          max: maxDataSet,
          gridLines: {
            display: true,
            drawTicks: false,
          },
          ticks: {
            userCallback: function (label: any, index: any, labels: any) {
              return `${shortenNumber(label.toFixed(0))}`;
            },
            stepSize: maxDataSet === 1 ? maxDataSet / 3 : maxDataSet / 3,
            padding: 20,
            beginAtZero: true,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    hover: {
      mode: "nearest",
      axis: "xy",
      intersect: false,
    },
    tooltips: {
      mode: "nearest",
      axis: "xy",
      intersect: false,
      backgroundColor: "rgba(187, 176, 230, 0.95)",
      displayColors: false,
      callbacks: {
        title: function (tooltipItems: any) {
          let nameValue = "Engagement";
          if (tooltipItems.length > 1) {
            const value = `Engagement : ${shortenNumber(
              tooltipItems[0]?.value
            )}\nReach : ${shortenNumber(tooltipItems[0]?.value)}`;
            return value;
          } else {
            LineDataSetSlice.datasets.forEach((item: any) => {
              if (item?.label === "Reach") {
                if (
                  tooltipItems[0]?.yLabel === item.data[tooltipItems[0]?.index]
                ) {
                  nameValue = "Reach";
                  const value = `Reach : ${shortenNumber(
                    tooltipItems[0]?.value
                  )}`;
                  return value;
                }
              }
            });
            const value = `${nameValue} : ${shortenNumber(
              tooltipItems[0]?.value
            )}`;
            return value;
          }
        },
        label: function (tooltipItems: any) {
          const value = `Date : ${tooltipItems.label}`;
          return value;
        },
      },
    },
  };

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 10);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 10);
    }
  };

  const pluginsLineChart = useMemo(() => {
    return [
      {
        id: "plugins-linechart",
        afterDraw: (chart: any) => {
          if (chart.canvas) {
            chart.canvas.style.padding = `0 5px`;
          }
          createYaxisLine(chart);
        },
      },
    ];
  }, []);

  const createYaxisLine = (chart: any) => {
    const _meta = chart.getDatasetMeta(0);
    let active: any = activePoint.current;

    const modelArray: any[] = _meta.data.map((item: any) => [
      item._model.x,
      item._model.y,
    ]);

    const isCompare =
      activePoint.current?._modelArray === modelArray?.toString();

    const findPoint = _meta.data.find(
      (item: any) =>
        item._model.x === activePoint.current?._model?.x &&
        item._model.y === activePoint.current?._model?.y
    );

    if (!findPoint || !isCompare) {
      const lastPoint = _meta.data.slice(-1)[0];
      active = lastPoint;
    }

    if (chart.tooltip._active && chart.tooltip._active.length) {
      active = chart?.tooltip?._active?.[0];
    }

    if (
      !(
        active?._model?.x === activePoint.current?._model?.x &&
        active?._model?.y === activePoint.current?._model?.y
      ) ||
      !isCompare
    ) {
      activePoint.current = active;
    }

    if (activePoint.current) {
      const ctx = chart.ctx;
      const y_axis = activePoint.current._yScale;
      const _model = activePoint.current._model;
      const x = _model.x;
      const topY = y_axis.top;
      const bottomY = y_axis.bottom;
      // draw line
      if (ctx) {
        ctx.save();
        //  bottom line
        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(x, topY + _model.y);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = COLORS.purple;
        ctx.stroke();
        ctx.restore();
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ ...styles.set_center }}>
        <div style={{ ...styles.title_section, width: "30%" }}>
          {`${props.sortByMenu} Relevant`}
        </div>
        <div style={{ display: "flex", width: "70%" }}>
          <div className="Label-LineChart">
            <div style={{ backgroundColor: "#574796", borderRadius: "6px" }} />
            <label>Engagement</label>
          </div>
          <div className="Label-LineChart">
            <div style={{ backgroundColor: "#F39C12", borderRadius: "6px" }} />
            <label>Reach</label>
          </div>
          <div className="Line-DatePicker" onClick={() => setOpenDate(true)}>
            {/* {console.log("  value ", value)} */}
            <DatePicker
              views={["month", "year"]}
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              value={value}
              onChange={(newValue) => {
                props.onFilterMonth?.(moment(newValue).format("YYYYMMDD"));
                setValue(newValue);
              }}
              closeOnSelect={true}
              open={OpenDate}
              onYearChange={() => setOpenDate(false)}
              onClose={() => setOpenDate(false)}
              components={{
                OpenPickerIcon: CalendarMonthIcon,
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} helperText={null} />
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        <Line
          options={options}
          data={LineDataSetSlice}
          height={350}
          redraw={true}
          width={750}
          plugins={pluginsLineChart}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "10px",
          width: "100%",
        }}
      >
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            LineDataSetSlice.labels.slice(11 + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          iconNameRight={"caret right"}
          iconNameLeft={"caret left"}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          right: "15px",
          width: "100%",
          display: "flex",
          justifyContent: "end",
          color: COLORS.text_gary,
        }}
      >
        Date
      </div>
    </div>
  );
};

// ---------------------- Section BarChart ------------------------
const BarChart: React.FunctionComponent<ChartProps> = (props) => {
  const [maxDataSet, setMaxDataSet] = useState<number>(6500);
  const [minDataSet, setMinDataSet] = useState<number>(0);
  const [skipIndex, setSkipIndex] = useState(0);
  const [barData, setBarData] = useState<any>({});
  const [currentMonth, setCurrentMonth] = useState<string>("");

  const labelLimit = 11;

  useEffect(() => {
    setCurrentMonth(
      moment(props.selectMonth, "YYYYMMDD").locale("en")?.format("MMMM YYYY")
    );
  }, [props.selectMonth]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    layout: {
      padding: {
        left: 5,
        right: 40,
        top: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          padding: "30",
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          min: minDataSet,
          max: maxDataSet,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            userCallback: function (label: any, index: any, labels: any) {
              return `${shortenNumber(label.toFixed(0))}`;
            },
            padding: 10,
            stepSize: maxDataSet / 3,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    currentMonth: currentMonth,
  };

  const plugins = useMemo(() => {
    const top = 0;
    const radius = 15;
    const allowIndex = [0, 1, 2];
    const allowStack = [3];
    const hideFillText = false;
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          const currentMonth = chart.config.options.currentMonth;
          let ctx = chart.ctx;
          const halfHeight = 40;
          const halfWidth = chart.width / 2;

          ctx.save();
          ctx.textAlign = "center";
          ctx.font = "14px Roboto";
          ctx.fillStyle = "#797878";
          if (chart.data?.datasets) {
            ctx.fillText(currentMonth, halfWidth, halfHeight);
          }
          ctx.restore();
        },
        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          prepareDataDraw({
            chart,
            allowIndex,
            allowStack,
          });
        },
      },
    ];
  }, []);

  const BarDataSetSlice = useMemo(() => {
    let sliceArray = (list: any[]) =>
      list.slice(skipIndex, labelLimit + skipIndex);
    const cloneData = { ...barData };
    let data: any = [];
    const labels = sliceArray(cloneData.labels || []);
    let datasets = (cloneData?.datasets || []).map((item: any) => {
      data = sliceArray(item.data || []);
      return {
        ...item,
        data,
      };
    });
    return {
      labels,
      datasets,
    };
  }, [barData, skipIndex]);

  useEffect(() => {
    const monthIndex = moment(props.selectMonth).daysInMonth();
    const datOfMonth = Array.from({ length: monthIndex }, (_, i) => i + 1);
    const arrayDate: any[] = [];
    //data not use is mobData
    datOfMonth.forEach((days: any) => {
      arrayDate.push(0);
    });

    const datasets = [
      {
        label: "Child",
        backgroundColor: "#32CDCD",
        stack: "Stack 0",
        data: arrayDate,
      },
      {
        label: "Adult",
        backgroundColor: "#0072BC",
        stack: "Stack 0",
        data: arrayDate,
      },
      {
        label: "Elderly",
        backgroundColor: "#18A0FB",
        stack: "Stack 0",
        data: arrayDate,
      },
    ];

    setBarData({
      labels: datOfMonth,
      datasets: datasets,
    });
  }, [props.selectMonth]);

  const barLeague = [
    {
      color: "#18A0FB",
      name: "Elderly",
    },
    {
      color: "#0072BC",
      name: "Adult",
    },
    {
      color: "#32CDCD",
      name: "Child",
    },
  ];

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 10);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 10);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={styles.title_section}>Total Revenue</div>
        <div className="button-dropdown-light">
          <Dropdown
            options={options_PatientGroup}
            defaultValue={"Patient Group"}
            placeholder={"Patient Group"}
            icon={"chevron down"}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "80%", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              color: COLORS.text_gary,
            }}
          >
            Amount (THB)
          </div>

          <Bar
            options={options}
            data={BarDataSetSlice}
            height={250}
            width={550}
            plugins={plugins}
          />

          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
            }}
          >
            <FooterChart
              graphIndex={0}
              lastGraph={Boolean(
                BarDataSetSlice.labels.slice(12 + skipIndex)?.[0]
              )}
              onMoveGraph={handleMoveGraph}
              iconNameRight={"caret right"}
              iconNameLeft={"caret left"}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: "0",
              bottom: "23px",
              color: COLORS.text_gary,
            }}
          >
            Date
          </div>
        </div>
        <div style={{ width: "20%", marginTop: "20px" }}>
          <div>
            {barLeague &&
              barLeague.map((item: commonType) => (
                <div
                  key={`${item.name}`}
                  className="BarLabel-BarChart"
                  style={{ border: "0px", padding: "2px 2px 2px 6px" }}
                >
                  <div
                    style={{ backgroundColor: item.color, borderRadius: "0px" }}
                  ></div>
                  <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                    {item.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// ---------------------- Section TotalEngagement ------------------------
const TotalEngagement: React.FunctionComponent<TotalEngagementProps> = (
  props
) => {
  const divRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [activePatientInfo, setActivePatientInfo] = useState<infoDataType[]>(
    []
  );
  const [activeGenderInfo, setActiveGenderInfo] = useState<infoDataType[]>([]);
  const [doughnutLeague, setDoughnutLeague] = useState<
    Record<string, LegendLabel[]>
  >({});
  const [patientGroup, setPatientGroup] = useState<commonType[]>([]);
  const [genderGroup, setGenderGroup] = useState<commonType[]>([]);
  const [sortPatientGroup, setSortPatientGroup] = useState<string>("All");
  const [sortGenderGroup, setSortGenderGroup] = useState<string>("All");
  const [valuePatientSelect, setValuePatientSelect] = useState<number>(0);
  const [valueGenderSelect, setValueGenderSelect] = useState<number>(0);
  const [doughnutPatientData, setDoughnutPatientData] = useState<number[]>([]);
  const [doughnutGenderData, setDoughnutGenderData] = useState<number[]>([]);

  const activeType = ["Account", "Like", "Buy", "Share", "Appointment"];

  useEffect(() => {
    const initEngagement = {
      user: 0,
      likeCounts: 0,
      shareCounts: 0,
      appointmentCounts: 0,
      buyCounts: 0,
      saveCounts: 0,
    };
    let childType: engagementSummary = { ...initEngagement };
    let elderlyType: engagementSummary = { ...initEngagement };
    let adultType: engagementSummary = { ...initEngagement };
    let maleType: engagementSummary = { ...initEngagement };
    let femaleType: engagementSummary = { ...initEngagement };
    let unknownType: engagementSummary = { ...initEngagement };
    let allType: engagementSummary = { ...initEngagement };

    let patientGroup: commonType[] = [];
    let genderGroup: commonType[] = [];

    props.userData.forEach((item: UserRelevantLifetimeType) => {
      if (item.age_range === "CHILD") {
        childType = handleCountData(childType, item);
      } else if (item.age_range === "ELDERLY") {
        elderlyType = handleCountData(elderlyType, item);
      } else {
        adultType = handleCountData(adultType, item);
      }

      if (item.gender === "M") {
        maleType = handleCountData(maleType, item);
      } else if (item.gender === "F") {
        femaleType = handleCountData(femaleType, item);
      } else {
        unknownType = handleCountData(unknownType, item);
      }

      allType = handleCountData(allType, item);
    });

    patientGroup.push(
      { name: "All", items: allType },
      { name: "Elderly", items: elderlyType },
      { name: "Adult", items: adultType },
      { name: "Child", items: childType }
    );

    genderGroup.push(
      { name: "All", items: allType },
      { name: "Male", items: maleType },
      { name: "Female", items: femaleType },
      { name: "Unknown", items: unknownType }
    );

    const patientGroupLegend: LegendLabel[] = [];
    const genderGroupLegend: LegendLabel[] = [];
    const valueAllType = handleCalculateData(allType);
    patientGroup.forEach((item: commonType) => {
      switch (item.name) {
        case "Elderly": {
          const value = handleCalculateData(elderlyType);

          patientGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#18A0FB",
          });
          break;
        }
        case "Child": {
          const value = handleCalculateData(childType);

          patientGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#32CDCD",
          });
          break;
        }
        case "Adult": {
          const value = handleCalculateData(adultType);

          patientGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#0072BC",
          });
          break;
        }
        case "All": {
          break;
        }
        default:
          break;
      }
    });

    genderGroup.forEach((item: commonType) => {
      switch (item.name) {
        case "Male": {
          const value = handleCalculateData(maleType);

          genderGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#0148A2",
          });
          break;
        }
        case "Female": {
          const value = handleCalculateData(femaleType);

          genderGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#FF8DCA",
          });
          break;
        }
        case "Unknown": {
          const value = handleCalculateData(unknownType);
          genderGroupLegend.push({
            name: item.name,
            value: handleCalculatePercentage(value, valueAllType),
            colorLegend: "#A6A4EC",
          });
          break;
        }
        case "All": {
          break;
        }
        default:
          break;
      }
    });

    setDoughnutLeague({
      patientGroup: patientGroupLegend,
      genderGroup: genderGroupLegend,
    });
    setPatientGroup(patientGroup);
    setGenderGroup(genderGroup);
  }, [props.userData]);

  // ************** this useEffect set info beside DoughnutChart  ************** //

  useEffect(() => {
    const patientFilter = patientGroup.filter((item: commonType) => {
      return item.name === sortPatientGroup;
    })?.[0];

    const genderFilter = genderGroup.filter((item: commonType) => {
      return item.name === sortGenderGroup;
    })?.[0];

    const allFilter = [patientFilter, genderFilter];

    let activePatientList: infoDataType[] = [];
    let activeGenderList: infoDataType[] = [];

    allFilter.forEach((itemFilter: any, index: any) => {
      if (index === 0) {
        activePatientList = handleFilterInfo(itemFilter);
      } else {
        activeGenderList = handleFilterInfo(itemFilter);
      }
    });

    const indexPatient =
      options_PatientGroup.find((item: any) => item.text === sortPatientGroup)
        ?.key || 0;
    const indexGender =
      options_GenderGroup.find((item: any) => item.text === sortGenderGroup)
        ?.key || 0;

    setValuePatientSelect(
      sortPatientGroup === "All"
        ? doughnutPatientData.reduce((a, b) => (a = a + b), 0) > 100
          ? 100
          : doughnutPatientData.reduce((a, b) => (a = a + b), 0)
        : doughnutPatientData[indexPatient]
    );
    setActivePatientInfo(activePatientList);
    setValueGenderSelect(
      sortGenderGroup === "All"
        ? doughnutGenderData.reduce((a, b) => (a = a + b), 0) > 100
          ? 100
          : doughnutPatientData.reduce((a, b) => (a = a + b), 0)
        : doughnutGenderData[indexGender]
    );
    setActiveGenderInfo(activeGenderList);
  }, [patientGroup, genderGroup, sortPatientGroup, sortGenderGroup]);

  const handleCountData = (
    typeData: engagementSummary,
    item: UserRelevantLifetimeType
  ) => {
    typeData.user += 1;
    typeData.likeCounts += item.like_counts || 0;
    typeData.shareCounts += item.share_counts || 0;
    typeData.appointmentCounts += item.appointment_counts || 0;
    typeData.buyCounts += item.buy_counts || 0;
    typeData.saveCounts += item.save_counts || 0;
    return typeData;
  };

  const handleCalculateData = (typeData: engagementSummary) => {
    if (typeData.user <= 0) {
      return 0;
    }

    return (
      typeData.likeCounts +
      typeData.shareCounts +
      typeData.appointmentCounts +
      typeData.buyCounts +
      typeData.user
    );
  };

  const handleCalculatePercentage = (value: number, valueAllType: number) => {
    return (value * 100) / valueAllType;
  };

  const handleFilterInfo = (itemFilter: commonType) => {
    console.log(" handleFilterInfo itemFilter ", itemFilter);
    return activeType.map((item: string) => {
      if (item === "Account") {
        console.log(" handleFilterInfo Account ", itemFilter?.items?.user);
        return {
          icon: ICONS.User,
          title: item,
          number: `${shortenNumber(itemFilter?.items?.user || 0)}`,
          type: "account",
        };
      } else if (item === "Buy") {
        return {
          icon: ICONS.Cart,
          title: item,
          number: `${shortenNumber(itemFilter?.items?.buyCounts || 0)}`,
          type: "order",
        };
      } else {
        const number =
          item === "Like"
            ? itemFilter?.items?.likeCounts
            : item === "Share"
            ? itemFilter?.items?.shareCounts
            : itemFilter?.items?.appointmentCounts || 0;

        const icon =
          item === "Like"
            ? ICONS.Like
            : item === "Share"
            ? ICONS.Share
            : ICONS.Appointment;

        return {
          icon: icon,
          title: item,
          number: `${shortenNumber(number || 0)}`,
          type: "time",
        };
      }
    });
  };

  const doughnutPatientDataset = useMemo(() => {
    const dateSet: number[] = [];
    const labels: string[] = [];
    doughnutLeague?.patientGroup?.forEach((item: LegendLabel) => {
      labels.push(item.name);
      dateSet.push(item.value);
    });

    // const maxValue = Math.max(...dateSet);
    // const indexMaxData = dateSet.findIndex((item: number) => item === maxValue);
    // options_PatientGroup.forEach((item: any) => {
    //   if (item.key === indexMaxData) {
    //     setSortPatientGroup(item.value);
    //   }
    // });
    setSortPatientGroup("All");

    setDoughnutPatientData(dateSet);
    const data = {
      labels: labels,
      datasets: [
        {
          label: "# of Votes",
          data: dateSet,
          backgroundColor: [
            "rgba(24, 160, 251, 1)",
            "rgba(0, 114, 188, 1)",
            "rgba(50, 205, 205, 1)",
          ],
          hoverBorderColor: [
            "rgba(24, 160, 251, 0.3)",
            "rgba(0, 114, 188, 0.3)",
            "rgba(50, 205, 205, 0.3)",
          ],
          hoverBorderWidth: 6,
        },
      ],
    };
    return data;
  }, [doughnutLeague]);

  const doughnutGenderDataset = useMemo(() => {
    const dateSet: number[] = [];
    const labels: string[] = [];
    doughnutLeague?.genderGroup?.forEach((item: LegendLabel) => {
      labels.push(item.name);
      dateSet.push(item.value || 0);
    });

    // const maxValue = Math.max(...dateSet);
    // const indexMaxData = dateSet.findIndex((item: number) => item === maxValue);
    // options_GenderGroup.forEach((item: any) => {
    //   if (item.key === indexMaxData) {
    //     setSortGenderGroup(item.value);
    //   }
    // });
    setSortGenderGroup("All");

    setDoughnutGenderData(dateSet);
    const data = {
      labels: labels,
      datasets: [
        {
          label: "# of Votes",
          data: dateSet,
          backgroundColor: [
            "rgba(1, 72, 162, 1)",
            "rgba(255, 141, 202, 1)",
            "rgba(166, 164, 236, 1)",
          ],
          hoverBorderColor: [
            "rgba(1, 72, 162, 0.3)",
            "rgba(255, 141, 202, 0.3)",
            "rgba(166, 164, 236, 0.3)",
          ],
          hoverBorderWidth: 6,
        },
      ],
    };
    return data;
  }, [doughnutLeague]);

  const doughnutPlugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          const selectType = chart.config.options.selectType;
          const valueType = chart.config.options.valueType;
          let ctx = chart.ctx;
          const halfHeight = chart.height / 2;
          const halfWidth = chart.width / 2;

          ctx.save();
          ctx.textAlign = "center";
          ctx.font = "18px Roboto";
          ctx.fillStyle = "#0147A3";
          if (chart.data?.datasets) {
            ctx.fillText(
              `${(valueType || 0).toFixed(1)}%`,
              halfWidth,
              halfHeight - 5
            );
            ctx.fillText(selectType, halfWidth, halfHeight + 15);
          }
          ctx.restore();
        },
      },
    ];
  }, []);

  const doughnutPatientOption = {
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
    responsive: true,
    onClick: (e: any, activeEls: any) => {
      options_PatientGroup.forEach((item: any) => {
        if (item.key === activeEls[0]?._index) {
          setSortPatientGroup(item.value);
        }
      });
    },
    tooltips: {
      enabled: false,
    },
    selectType: sortPatientGroup,
    valueType: valuePatientSelect,
  };

  const doughnutGenderOption = {
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
    responsive: true,
    onClick: (e: any, activeEls: any) => {
      options_GenderGroup.forEach((item: any) => {
        if (item.key === activeEls[0]?._index) {
          setSortGenderGroup(item.value);
        }
      });
    },
    tooltips: {
      enabled: false,
    },
    selectType: sortGenderGroup,
    valueType: valueGenderSelect,
  };

  return (
    <div
      key={"TotalEngagement"}
      style={{ margin: "0px 10px 10px 0px", padding: "10px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={styles.title_section}>Total Engagement</div>
        <div className="button-dropdown-light">
          <Dropdown
            options={options_PatientGroup}
            placeholder="Patient Group"
            icon={"chevron down"}
            value={sortPatientGroup}
            onChange={(e, data) => {
              setSortPatientGroup(`${data.value}`);
            }}
          />
        </div>
      </div>

      {/********************** DoughnutLeague  ************************/}

      <div style={{ display: "flex", margin: "20px 10px" }}>
        {doughnutLeague?.patientGroup &&
          doughnutLeague?.patientGroup.map((item: LegendLabel) => (
            <div
              key={`Label-${item.name}`}
              className="PostLabel-doughnutChart"
              style={{ border: "0px", padding: "2px 5px 2px 6px" }}
            >
              <div
                style={{
                  backgroundColor: item.colorLegend,
                  borderRadius: "6px",
                }}
              ></div>
              <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                {item.name}
              </label>
              <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                {`${item.value | 0}%`}
              </label>
            </div>
          ))}
      </div>

      {/********************** Doughnut Chart ************************/}

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <div ref={divRef} style={{ position: "relative", marginTop: "10px" }}>
            <div>
              <Doughnut
                data={doughnutPatientDataset}
                options={doughnutPatientOption}
                plugins={doughnutPlugins}
              />
            </div>
          </div>
        </div>

        {/********************** info beside Doughnut Chart ************************/}

        <div
          style={{ width: "50%", padding: "30px 0 10px", ...styles.set_center }}
        >
          <Grid>
            {activePatientInfo &&
              activePatientInfo.map((item: infoDataType) => (
                <Grid.Row
                  style={{ padding: "0 0 5px 0" }}
                  key={`Info-${item.title}`}
                >
                  <Grid.Column width={3}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <Image src={item.icon} />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={6} style={{ paddingLeft: "5px" }}>
                    {item.title}
                  </Grid.Column>
                  <Grid.Column width={4}>{item.number}</Grid.Column>
                  <Grid.Column width={3} style={{ paddingLeft: "0px" }}>
                    {item.type}
                  </Grid.Column>
                </Grid.Row>
              ))}
          </Grid>
        </div>
      </div>

      {/********************** DoughnutLeague  ************************/}
      <div
        style={{
          display: "flex",
          margin: "20px 10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {doughnutLeague?.genderGroup &&
            doughnutLeague?.genderGroup.map((item: LegendLabel) => (
              <div
                key={`Label-${item.name}`}
                className="PostLabel-doughnutChart"
                style={{ border: "0px", padding: "2px 0px 2px 6px" }}
              >
                <div
                  style={{
                    backgroundColor: item.colorLegend,
                    borderRadius: "6px",
                  }}
                ></div>
                <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                  {item.name}
                </label>
                <label style={{ margin: "0 12px 0 8px", fontSize: "12px" }}>
                  {`${item.value | 0}%`}
                </label>
              </div>
            ))}
        </div>
        <div className="button-dropdown-light">
          <Dropdown
            options={options_GenderGroup}
            placeholder="Gender"
            icon={"chevron down"}
            value={sortGenderGroup}
            onChange={(e, data) => {
              setSortGenderGroup(`${data.value}`);
            }}
          />
        </div>
      </div>

      {/********************** Doughnut Chart ************************/}

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <div ref={divRef} style={{ position: "relative", marginTop: "10px" }}>
            <div>
              <Doughnut
                data={doughnutGenderDataset}
                options={doughnutGenderOption}
                plugins={doughnutPlugins}
              />
            </div>
          </div>
        </div>

        {/********************** info beside Doughnut Chart ************************/}

        <div
          style={{ width: "50%", padding: "30px 0 10px", ...styles.set_center }}
        >
          <Grid>
            {activeGenderInfo &&
              activeGenderInfo.map((item: infoDataType) => (
                <Grid.Row
                  style={{ padding: "0 0 5px 0" }}
                  key={`Info-${item.title}`}
                >
                  <Grid.Column width={3}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <Image src={item.icon} />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={6} style={{ paddingLeft: "5px" }}>
                    {item.title}
                  </Grid.Column>
                  <Grid.Column width={4}>{item.number}</Grid.Column>
                  <Grid.Column width={3} style={{ paddingLeft: "0px" }}>
                    {item.type}
                  </Grid.Column>
                </Grid.Row>
              ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

// ---------------------- Section MostUsersList ------------------------
const MostUsersList: React.FunctionComponent<MostUsersListProps> = (props) => {
  const [mostUser, setMostUser] = useState<MostUserType[]>([]);

  // ************** this useEffect find top 5 all User ************** //
  useEffect(() => {
    const topFive: MostUserType[] = [];
    const dataUser = props.userData;
    const topNumber = 5;
    if (props.userData?.length > 0) {
      if (props.isTopUser) {
        // TODO: not data
      } else {
        for (let i = 0; i < topNumber; i++) {
          topFive.push({
            name: dataUser[i]?.patient_name || "",
            like: dataUser[i]?.like_counts || "",
            share: dataUser[i]?.share_counts || "",
            appointment: dataUser[i]?.appointment_counts || "",
          });
        }
      }
      setMostUser(topFive);
    } else {
      setMostUser([]);
    }
  }, [props.userData]);

  return (
    <div
      key={`MostUsersList-${
        props.isTopUser ? "Top Users Purchase" : "Most Users Engagement"
      }`}
      style={{ margin: "10px" }}
    >
      <div style={styles.header_section}>
        {props.isTopUser ? "Top Users Purchase" : "Most Users Engagement"}
      </div>
      <div style={{ padding: "10px" }}>
        {/********************** Header Top Users ************************/}

        <Grid>
          {props.isTopUser ? (
            <Grid.Row style={{ padding: "5px 0" }}>
              <Grid.Column width={7} />
              <Grid.Column width={3} style={styles.set_center}>
                Program
              </Grid.Column>
              <Grid.Column width={3} style={styles.set_center}>
                Quatity
              </Grid.Column>
              <Grid.Column width={3} style={styles.set_center}>
                Amount
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row style={{ padding: "5px 0" }}>
              <Grid.Column width={7} />
              <Grid.Column width={3} style={styles.set_center}>
                Like
              </Grid.Column>
              <Grid.Column width={3} style={styles.set_center}>
                Share
              </Grid.Column>
              <Grid.Column width={3} style={styles.set_center}>
                Appointment
              </Grid.Column>
            </Grid.Row>
          )}

          {/********************** List User TOP 5 ************************/}

          <Divider style={{ margin: "0px", width: "100%" }} />
          {mostUser &&
            mostUser.map((item: MostUserType, index: number) => (
              <div
                style={{ width: "100%", padding: "0" }}
                key={`User-${index}`}
              >
                <Grid style={{ margin: "0" }}>
                  <Grid.Row style={{ padding: "5px" }}>
                    <Grid.Column width={3} style={styles.set_center}>
                      <div style={styles.image_user}>
                        <Image src={"images/Appointment/user-icon.png"} />
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      style={{
                        ...styles.set_center,
                        padding: "0px",
                        justifyContent: "left",
                      }}
                    >
                      {item.name}
                    </Grid.Column>
                    <Grid.Column width={3} style={styles.set_center}>
                      {item.like || 0}
                    </Grid.Column>
                    <Grid.Column width={3} style={styles.set_center}>
                      {item.share || 0}
                    </Grid.Column>
                    <Grid.Column width={3} style={styles.set_center}>
                      {item.appointment || 0}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider style={{ margin: "0px" }} />
              </div>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(MarketingAnalyticsOverall);
