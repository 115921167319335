import React, { ReactElement } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// UX
import CardListItem from "./CardListItem";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";

// Types
type BottomSheetListItemProps = {
  className?: string;
  open: boolean;
  title: string;
  options: { id: number; name: string; image?: string }[];
  rightIcon?: ReactElement;
  // config
  centerTitle?: boolean;
  // callback
  onChange: (state: boolean) => any;
  onSelect?: (selected: Record<string, any>) => any;
};

// Styles
const COLORS = {
  font: "rgba(57, 57, 57, 1)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    maxHeight: "75vh",
    overflow: "auto",
    margin: "0 -20px",
    padding: "16px",
    color: COLORS.font,
  },
}));

const BottomSheetListItem = (props: BottomSheetListItemProps) => {
  const classes = useStyles();

  // Handler
  const handleChange = (state: boolean) => {
    props.onChange?.(state);
  };

  const handleSelect = (selected: Record<string, any>) => {
    props.onSelect?.(selected);
  };

  return (
    <DialogReadMore
      className={props.className}
      open={props.open}
      title={props.title}
      centerTitle={props.centerTitle}
      onChange={handleChange}
    >
      <div className={classes.screen}>
        <CardListItem
          items={props.options}
          rightIcon={props.rightIcon}
          onSelect={handleSelect}
        />
      </div>
    </DialogReadMore>
  );
};

export default React.memo(BottomSheetListItem);
