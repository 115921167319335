import React, { useState, useMemo, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";

import { useHistory, useLocation } from "react-router";

// UX
import { CHLocationType, titleStyles } from "./SelectCheckupHospital";
import CardRadioList from "./CardRadioList";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// telephar

// Interface
import { State, Event } from "../MobSmartAppointmentInterface";

// Types
type SelectCheckupTypeProps = {
  onEvent: (e: Event) => any;
} & Pick<State, "medProgramLists" | "loadingStatus">;

export type CTLocationType = CHLocationType &
  CHLocationType["storedState"] & {
    centergroup?: Record<string, any>;
    storedState?: {
      checkup?: Record<string, any>;
    };
  };

// Styles
const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "16px",
    display: "flex",
    minHeight: "calc(100vh - 50px)",
    flexDirection: "column",
    // "& .centered": centeredStyles,
    "&:before": {
      top: "-50px",
      minHeight: "100vh",
    },
  },
  title: titleStyles,
}));

const BUTTON_ACTIONS = {
  list: "_LIST",
  confirm: "_CONFIRM",
};

const SELECT_CHECKUP_TYPE = "SelectCheckupType";
const ACTION_LIST = `${SELECT_CHECKUP_TYPE}${BUTTON_ACTIONS.list}`;
const ACTION_CONFIRM = `${SELECT_CHECKUP_TYPE}${BUTTON_ACTIONS.confirm}`;

const SelectCheckupType = (props: SelectCheckupTypeProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<CTLocationType>();

  const [selectedType, setSelectedType] = useState<any>(null);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  // Effect
  useEffect(() => {
    if (locState?.storedState) {
      setSelectedType(locState?.storedState.checkup?.id);
    }
  }, [locState.storedState]);

  useEffect(() => {
    const refresh = locState.hospital?.code;

    if (refresh) {
      props.onEvent({
        message: "GetListMedProgram",
        params: {
          hospital: locState.hospital?.code,
          med_program: locState.centergroup?.med_programs?.toString(),
          card: ACTION_LIST,
        },
      });
    }
  }, [locState.hospital]);

  // Memo
  const isLoading = useMemo(() => {
    return props.loadingStatus?.[ACTION_LIST] || !locState.hospital?.code;
  }, [props.loadingStatus, locState.hospital]);

  // Handler
  const handleConfirmHospital = () => {
    const checkup = props.medProgramLists?.find(
      (item) => item.id === selectedType
    );

    if (checkup) {
      props.onEvent({
        message: "HandleConfirmCheckupType",
        params: {
          history,
          checkup,
          card: ACTION_CONFIRM,
        },
      });
    }
  };

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        setTitle={"ประเภทการตรวจสุขภาพ"}
        whiteTheme={true}
        bgGradient={true}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      <div className={`${classes.screen} bg-gradient-container`}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>เลือกประเภทการตรวจสุขภาพ</div>

          <CardRadioList
            items={(props.medProgramLists || []) as any[]}
            selected={selectedType}
            loading={isLoading}
            // callback
            onSelect={setSelectedType}
          />
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!selectedType || props.loadingStatus?.[ACTION_CONFIRM]}
              // callback
              onClick={handleConfirmHospital}
            >
              ถัดไป
              {props.loadingStatus?.[ACTION_CONFIRM] && (
                <CircularProgress
                  style={{
                    marginLeft: "0.5rem",
                  }}
                  size={20}
                />
              )}
            </MuiButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SelectCheckupType);
