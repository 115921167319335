import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
  commonCreateUpdatePatientDocumentFile,
  commonDeletePatientDocumentFile,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonUpdatePatientData,
  calculateChildDevelopmentSkillV2,
  DATA_TYPE,
  GROUP,
} from "../MobClinicInterface";

// APIs
import AgeRangeList from "../../issara-sdk/apis/AgeRangeList_apps_PHR";
import DevelopmentList from "../../issara-sdk/apis/DevelopmentList_apps_PHR";

export type State = {
  developmentAgeRangeOptions?: any[];
  developmentAgeRange?: any[];
  developmentDetail?: any;
  developmentHistory?: any[];
  developmentLastEdited?: moment.Moment | null;
};

export const StateInitial = {
  developmentAgeRangeOptions: [],
  developmentAgeRange: [],
  developmentDetail: null,
  developmentHistory: [],
  developmentLastEdited: null,
};

export type Event =
  // GET
  | { message: "HandleGetDevelopmenAgeRange" }
  | {
      message: "HandleGetDevelopmenDetail";
      params: { age_range: number | null };
    }
  | { message: "HandleGetDevelopmenHistory" }
  // SAVE
  | { message: "HandleCreateUpdateDevelopment"; params: {} }
  | {
      message: "HandleCreateUpdateImage";
      params: { developmentId: number; index: number; file: any };
    }
  | {
      message: "HandleDeleteImage";
      params: { developmentId: number; index: number };
    };

type Handler = WasmHandler<State, Event>;

// GET
export const HandleGetDevelopmenAgeRange: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  const [response, error, network] = await AgeRangeList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { type: "DEVELOPMENT" },
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    developmentAgeRangeOptions: CreateOptions(response?.items) || [],
    developmentAgeRange: response?.items || [],
  });
};

export const HandleGetDevelopmenDetail: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  // ----- Skill
  const [respSkill, errSkill, netwSkill] = await DevelopmentList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { ...params }, // age_range
  });
  if (errSkill) {
    controller.setState({
      loadingStatus: false,
      errorMessage: errSkill,
      successMessage: null,
      developmentDetail: null,
    });
    return;
  } else {
    if (respSkill.total === 0) {
      controller.setState({
        loadingStatus: false,
        errorMessage: null,
        successMessage: null,
        developmentDetail: null,
      });
      return;
    }
  }

  // ----- Patient Data
  const [errData, respData] = await getDevelopmentPatientData(
    controller,
    params?.age_range
  );

  // ----- Set Data
  const skillItems: any[] = setSkillData(respSkill?.items);
  const patientSkillList: any[] = setPatientData(respData, skillItems);
  let result: any = { ...respData };
  result.data = {
    age_range: respSkill?.items[0]?.age_range,
    age_range_name: respSkill?.items[0]?.age_range_name,
    age_range_display_seq: respSkill?.items[0]?.age_range_display_seq,
    items: [...patientSkillList],
  };

  controller.setState({
    loadingStatus: false,
    errorMessage: null,
    successMessage: null,
    developmentDetail: result,
  });
};

export const HandleGetDevelopmenHistory: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any[] = [];

  // ----- Skill
  const [respSkill, errSkill, netwSkill] = await DevelopmentList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
  });
  if (errSkill) {
    controller.setState({
      loadingStatus: false,
      errorMessage: errSkill,
      successMessage: null,
      developmentDetail: null,
    });
    return;
  } else {
    if (respSkill.total === 0) {
      controller.setState({
        loadingStatus: false,
        errorMessage: null,
        successMessage: null,
        developmentDetail: null,
      });
      return;
    }
  }

  // ----- Patient Data
  const [errData, respData] = await commonListPatientDataList(controller, {
    group: GROUP.DEVELOPMENT,
  });

  // ----- Set Data
  let skillResult = respSkill?.items?.reduce((r: any, a: any) => {
    r[`${a.age_range_display_seq}_${a.age_range}`] = [
      ...(r[`${a.age_range_display_seq}_${a.age_range}`] || []),
      a,
    ];
    return r;
  }, {});

  let group = respData?.items?.reduce((r: any, a: any) => {
    r[`${a.data.age_range_display_seq}_${a.data.age_range}`] = [
      ...(r[`${a.data.age_range_display_seq}_${a.data.age_range}`] || []),
      a,
    ];
    return r;
  }, {});

  let skillItems: any[] = [];
  let editedList: any[] = [];
  let result: any[] = [];
  Object.keys(skillResult).forEach((key: string) => {
    skillItems = setSkillData(skillResult[key]);

    let patientSkillList = skillItems;
    let tmpResult: any = {};

    if (group?.hasOwnProperty(key) && group[key]?.length > 0) {
      patientSkillList = setPatientData(group[key][0], skillItems);

      editedList.push(
        moment(
          group[key][0]?.edited_at,
          `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
        )
      );
      tmpResult = { ...group[key][0] };
    }
    tmpResult.data = {
      age_range: skillResult[key][0]?.age_range,
      age_range_name: skillResult[key][0]?.age_range_name,
      age_range_display_seq: skillResult[key][0]?.age_range_display_seq,
      items: [...patientSkillList],
    };
    result.push(tmpResult);
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage.length > 0 ? errorMessage : null,
    successMessage: null,
    developmentHistory: result,
    developmentLastEdited: moment.max(...editedList),
  });
};

// SAVE
export const HandleCreateUpdateDevelopment: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let error: any = null;
  let response: any = null;

  // Update
  if (params?.id) {
    [error, response] = await commonUpdatePatientData(controller, {
      ...params,
    });
  }
  // Create
  else {
    const data = {
      group: GROUP.DEVELOPMENT,
      data_type: `${DATA_TYPE.DEVELOPMENT}${params?.data?.age_range}`,
      measure_date: moment().format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: { ...params.data },
    };
    [error, response] = await commonCreatePatientData(controller, data);
  }

  if (error) {
  } else {
    const [errData, respData] = await getDevelopmentPatientData(
      controller,
      params?.data?.age_range
    );

    if (params.getHistory) {
      HandleGetDevelopmenHistory(controller);
    }
    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: error ? null : "Save success.",
      developmentDetail: respData,
    });
  }
};

export const HandleCreateUpdateImage: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  let patientData: any = { ...state.developmentDetail };

  let image = await previewFile(params?.file);

  const [imgErr, imgRes] = await commonCreateUpdatePatientDocumentFile(
    controller,
    {
      phr: patientData?.id,
      image: image,
      id: patientData?.image_id || null,
    }
  );
  if (imgErr) {
    controller.setState({
      loadingStatus: false,
      errorMessage: imgErr,
      successMessage: null,
    });
    return;
  } else {
    patientData?.data?.items?.forEach((item: any) => {
      if (item.developmentId === params.developmentId) {
        item.data.method[params?.index].image = imgRes.url;
        item.data.method[params?.index].image_id = imgRes.id;
      }
    });
    patientData.getHistory = false;
    await HandleCreateUpdateDevelopment(controller, patientData);
  }
};

export const HandleDeleteImage: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  let patientData: any = { ...state.developmentDetail };

  let imageId: number | null = null;
  patientData?.data?.items?.forEach((item: any) => {
    if (item.developmentId === params.developmentId) {
      imageId = item.data.method[params?.index].image_id;

      item.data.method[params?.index].image = null;
      item.data.method[params?.index].image_id = null;
    }
  });

  const [imgErr, imgRes] = await commonDeletePatientDocumentFile(controller, {
    id: imageId,
  });
  if (imgErr) {
    controller.setState({
      loadingStatus: false,
      errorMessage: imgErr,
      successMessage: null,
    });
    return;
  } else {
    patientData.getHistory = false;
    await HandleCreateUpdateDevelopment(controller, patientData);
  }
};

// utility
const getDevelopmentPatientData = async (controller: any, ageRange: number) => {
  const [errData, respData] = await commonGetPatientDataLatest(controller, {
    group: GROUP.DEVELOPMENT,
    data_type: `${DATA_TYPE.DEVELOPMENT}${ageRange}`,
  });
  return [errData, respData];
};

const previewFile = async (file: Blob) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

const CreateOptions = (items: any[]) => {
  const mapOption = (item: any, index: number) => ({
    key: index,
    text: item.name,
    value: item.id,
  });
  return items.map(mapOption);
};

const setSkillData = (skillItems: any) => {
  let items: any[] = [];
  skillItems?.map((item: any) => {
    return items.push({
      developmentId: item?.id,
      skill: item?.skill,
      skill_label: item?.skill_label,
      data: { ...item?.data },
    });
  });
  return items;
};

const setPatientData = (patientData: any, skillItems: any[]) => {
  if (patientData) {
    skillItems?.forEach((sElem: any) => {
      let tmpItems = sElem.data.method.map((sItem: any) => {
        patientData?.data?.items?.map((dElem: any) => {
          let temData = dElem.data.method.find((dItem: any) => {
            return dItem.name === sItem.name;
          });

          if (temData) {
            if (temData.hasOwnProperty("checked")) {
              sItem.checked = temData.checked;
            }
            if (temData.hasOwnProperty("date")) {
              sItem.date = temData.date;
            }
            if (temData.hasOwnProperty("image")) {
              sItem.image = temData.image;
            }
            if (temData.hasOwnProperty("image_id")) {
              sItem.image_id = temData.image_id;
            }
          }
          return temData;
        });
        return sItem;
      });
      sElem.skill_score = calculateChildDevelopmentSkillV2(sElem?.data?.method);
      sElem.data.method = tmpItems;
    });
  }
  return skillItems;
};
