import WasmController from "../../../react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import PatientDocumentDataList from "../../../issara-sdk/apis/PatientDocumentDataList_apps_PHRM";

// Types
import PatientDataSerializer from "../../../issara-sdk/types/PatientDataSerializer_apps_PHR";

// Constant
import {
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonDeletePatientData,
  commonCreatePatientData,
  commonUpdatePatientData,
  commonCreatePatientDocumentFile,
  commonUpdatePatientDocumentFile,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  prenatalAndANCRecordList?: Record<string, any>[];
  prenatalAndANCRecordFilter?: any;
  allPrenatalAndANCRecordList?: any[];
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus"
>;

export const StateInitial: State = {
  prenatalAndANCRecordList: [],
  prenatalAndANCRecordFilter: [],
  allPrenatalAndANCRecordList: [],
};

export type Event =
  // GET
  | {
      message: "GetPrenatalAndANCRecordList";
      params: {};
    }
  // Save
  | {
      message: "SavePrenatalAndANCRecordList";
      params: { measure_date: string; data: any };
    }
  // Change
  | {
      message: "HandleChangePrenatalAndANCRecordFilter";
      params: { key: string; value: any };
    }
  //DELETE
  | {
      message: "RemovePrenatalAndANCRecordList";
      params: {};
    };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

// GET
export const GetPrenatalAndANCRecordList: Handler = async (controller) => {
  controller.setState({ loadingStatus: true } as any);

  const tmpParams: any = {
    group: GROUP.PRENATAL_AND_ANC_RECORD,
    data_type: DATA_TYPE.PRENATAL_AND_ANC_RECORD,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );
  controller.setState({
    prenatalAndANCRecordList: response.items,
    loadingStatus: false,
    allPrenatalAndANCRecordList: [...response.items],
    prenatalAndANCRecordUploadDetail: response,
  } as any);
};

export const SavePrenatalAndANCRecordList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  let fileErr: any = null;
  let fileRes: any = null;
  let deletErr: any = null;
  let deletRes: any = null;

  let errorMessage: any = null;
  let successMessage: any = null;

  const tmpParams: any = {
    group: GROUP.PRENATAL_AND_ANC_RECORD,
    data_type: DATA_TYPE.PRENATAL_AND_ANC_RECORD,
    measure_date: params.measure_date,
    data: { ...params.data },
  };

  const [error, response] = await commonCreatePatientData(
    controller as any,
    tmpParams
  );

  let id = response.id;
  console.log("response", response.id);

  if (response.id) {
    const [fileRes, fileErr, network] = await PatientDocumentDataList.create({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        phr: id,
        content_file: params.data.uploadFile,
        active: true,
      } as PatientDataSerializer,
    });

    console.log("fileRes", fileRes.content_file);
    let tmpData = {
      group: GROUP.PRENATAL_AND_ANC_RECORD,
      data_type: DATA_TYPE.PRENATAL_AND_ANC_RECORD,
      measure_date: params.measure_date,
      data: {
        ...params.data,
        uploadFile: fileRes.content_file,
      },
    };

    const [error, response] = await commonCreatePatientData(
      controller as any,
      tmpData
    );

    const [deletErr, deletRes] = await commonDeletePatientData(
      controller as any,
      {
        id: id,
      }
    );
  }

  // const [response, error, network] = await PatientDocumentDataList.create({
  //   apiToken: controller.apiToken || Cookies.get("apiToken"),
  //   phr: response.id,
  //   data: { content_file: params.data.uploadFile, active: true },
  // });

  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Add Prenatal And ANCRecord success.";
    GetPrenatalAndANCRecordList(controller);
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  } as any);
};

//Change
export const HandleChangePrenatalAndANCRecordFilter: Handler = async (
  controller,
  params
) => {
  const {
    prenatalAndANCRecordFilter,
    prenatalAndANCRecordList,
    allPrenatalAndANCRecordList,
  } = controller.getState();
  const cloneDate = { ...prenatalAndANCRecordFilter };
  let cloneArray = [...(prenatalAndANCRecordList || [])];
  if (cloneDate) {
    cloneDate[params.key] = params.value;
  }
  console.log("cloneDate", cloneDate);
  cloneArray = (allPrenatalAndANCRecordList || [])?.filter((item: any) => {
    const conpareStartDate = cloneDate?.startDate
      ? new Date(moment(item.measure_date).format("YYYY-MM-DD")) >=
        new Date(cloneDate?.startDate)
      : true;
    const conpareEndDate = cloneDate?.endDate
      ? new Date(moment(item.measure_date).format("YYYY-MM-DD")) <=
        new Date(cloneDate?.endDate)
      : true;
    const conpareFileType = cloneDate?.fileType
      ? item.data.fileType == cloneDate?.fileType
      : true;

    if (
      conpareEndDate == true &&
      conpareStartDate == true &&
      conpareFileType == true
    ) {
      return conpareStartDate && conpareStartDate;
    }
  });

  controller.setState({
    prenatalAndANCRecordFilter: cloneDate,
    prenatalAndANCRecordList: cloneArray,
  });
};

// DELETE
export const RemovePrenatalAndANCRecordList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete Prenatal And ANCRecord success.";
    }

    GetPrenatalAndANCRecordList(controller);
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
