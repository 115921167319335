import React, { CSSProperties, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Divider, Image } from "semantic-ui-react";

import CoreManager from "../react-lib/apis/manager/CoreManager";

const ICONS = {
  background: "/images/backgroundConcent.png",
  noAvailable: "/images/no-available-telemed.png",
};

// Use styles
const COLOR = {
  primary: "var(--primary-theme-color)",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  red: "#DA0000",
  darkgray: "#9F9F9F",
  font_white: "white",
};

const styles = {
  backgroundContent: {
    backgroundImage: "url(" + process.env.PUBLIC_URL + ICONS.background + ")",
    height: "100%",
    display: "flex",
    overflowY: "auto",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "28px",
    fontWeight: "bold",
    margin: "15px 5px",
    whiteSpace: "pre-line",
    lineHeight: "30px",
    color: COLOR.primary,
  } as CSSProperties,
  cardDetails: {
    background: "#FFFFFF7D",
    borderRadius: "20px",
    padding: "0px 15px 15px 15px",
    display: "flex",
    marginBottom: "20px",
    flexDirection: "column",
  } as CSSProperties,
  textDetails: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0px 0px 40px",
    lineHeight: "30px",
    whiteSpace: "pre-line",
  } as CSSProperties,
  button: {
    borderRadius: "20px",
    border: `2px solid ${COLOR.primary}`,
    color: COLOR.primary,
    margin: "30px 0 50px",
    padding: "15px 25px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "20px",
  } as CSSProperties,
};

type organizationType = {
  id: number;
  code: string;
  name: string;
  name_en: string;
};

type NoAvailableTelemedProps = {
  gotoMainChat: Function;
  apiToken: any;
  controller: CoreManager;
  hospitalCode: string;
  name?: string;
  showBadge?: (badge: number) => any;
};

const NoAvailableTelemed = (props: NoAvailableTelemedProps) => {
  // -const handleBackToMain = () => {
  //   if (typeof window.iosNative !== "undefined") {
  //     window.iosNative.goToMainScreen();
  //     return;
  //   }
  //   if (typeof window.MobNative !== "undefined") {
  //     window.MobNative.goToMainScreen();
  //     return;
  //   }
  // };

  useEffect(()=>{
    props.showBadge?.(0)
  },[])

  return (
    <div style={styles.backgroundContent}>
      <div style={{ width: "100%", height: "100%", margin: "0 30px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ margin: " 20px" }}>
            <div style={styles.title}>
              <FormattedMessage id={"bplusClinicKey955"} />
            </div>
            <Divider
              style={{
                background: "red",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              variant="middle"
            />
          </div>
        </div>
        <div style={styles.cardDetails}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image style={{ width: "330px" }} src={ICONS.noAvailable} />
          </div>
          <div style={styles.textDetails}>
            <FormattedMessage id={"bplusClinicKey956"} />
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={styles.button} onClick={() => handleBackToMain()}>
              <FormattedMessage id={"common.back_to_home"} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

NoAvailableTelemed.displayName = "NoAvailableTelemed";
export default React.memo(NoAvailableTelemed);
