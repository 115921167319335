import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Dimmer,
  Dropdown,
  Form,
  Header,
  Icon,
  Loader,
} from "semantic-ui-react";
import { Bar } from "react-chartjs-2";

import ReactTable from "react-table-6";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

// UI
import SnackMessage from "./SnackMessage";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  formatDateByLanguage,
} from "bplus-lib/MobClinicInterface";

import { DATE_FORMAT, styles } from "./MomKickCount";
import "../../css/WebClinic.scss";

// ---------- MomKickCountChart
type MomKickCountChartProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  kickCountFilterDuration?: string;
  kickCountHistory?: any;
  // config
  useWebMomTable?: boolean;
  height?: number;
  isMyTab?: boolean;
  hideWebMomKickCount?: boolean;
};

const MomKickCountChartInitial: MomKickCountChartProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  kickCountFilterDuration: "6",
  kickCountHistory: {},
  height: 200,
  isMyTab: false,
  hideWebMomKickCount: false,
};

const MomKickCountChart: React.FC<MomKickCountChartProps> = (props) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [history, setHistory] = useState<any>({
    data: { labels: [], datasets: [] },
    options: {},
  });

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({
        message: "handleGetKickCountHistory",
        params: {
          start_measure: startDate.format(BACKEND_DATE_FORMAT),
          end_measure: endDate.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, props.isMyTab, startDate, endDate]);

  useEffect(() => {
    let labels: any[] = [];
    let data: any[] = [];
    if (props.kickCountHistory) {
      props.kickCountHistory?.items?.forEach((item: any) => {
        labels.push(
          formatDateByLanguage(moment(
            item.measure_date,
            `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
          ).format("DD/MM/YYYY"),"MMM DD,YYYY")
        );
        data.push(item?.data?.total);
      });
    }

    setHistory({
      data: {
        labels: labels,
        datasets: [
          { label: "Kick count", data: data, backgroundColor: "#2399E5" },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.max(...data) + 3,
                min: 0,
              },
            },
          ],
          xAxes: [{ maxBarThickness: 70 }],
        },
      },
    });
  }, [props.kickCountHistory]);

  const durationOptions = useMemo(
    () => [
      { key: "5", value: "4", text: `5 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "7", value: "6", text: `7 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "10", value: "9", text: `10 ${intl.formatMessage({ id: "profile.days" })}` },
    ],
    []
  );

  const columnsRecord = useMemo(
    () => [
      {
        Header: <FormattedMessage id="common.date" />,
        minWidth: 80,
        accessor: "measure_date",
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                color: "var(--text-dark-blue)",
                fontWeight: "bold",
                ...styles.textCenter,
              }}
            >
              {formatDateByLanguage(moment(
                row?.measure_date,
                `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
              ).format("DD/MM/YYYY"),"ddd, DD MMM YYYY")}
            </div>
          );
        },
      },
      {
        Header: <FormattedMessage id="bplusClinicKey496" />,
        minWidth: 75,
        accessor: "data",
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                color: "black",
                fontWeight: "bold",
                ...styles.textCenter,
              }}
            >
              {row?.data?.total}
            </div>
          );
        },
      },
    ],
    []
  );

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${props.kickCountFilterDuration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(props.kickCountFilterDuration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };
  console.log("props.kickCountFilterDuration",props.kickCountFilterDuration)

  const handleChangeDuration = (event: any, { value }: any) => {
    setStartDate(moment().add(`-${value}`, "days"));
    setEndDate(moment());
    props.onEvent({
      message: "handleChangeKickCountFilterDuration",
      params: { duration: value },
    });
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "12px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  return (
    <>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Form size="small" style={{ marginTop: "20px", padding: "0 15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Header
            as="h4"
            style={{ margin: "0 8px 0 0", color: "var(--text-grey)" }}
          >
            <FormattedMessage id="bplusClinicKey169" />
          </Header>
          <Dropdown
            selection
            search
            compact
            style={{ width: "160px" }}
            value={props.kickCountFilterDuration}
            options={durationOptions}
            onChange={handleChangeDuration}
          />
        </div>
      </Form>

      <div style={{ display: "flex", padding: "20px 0px" }}>
        <div style={{ flex: 1 }}></div>
        <div
          style={{ ...styles.textCenter, flex: 6, color: "var(--text-grey)" }}
        >
          <Icon name="backward" onClick={handleChangeDateRange} />
          <label style={{ padding: "0px 10px" }}>
            {`${formatDateByLanguage(startDate?.format(DATE_FORMAT),"MMM DD, YYYY")} - 
              ${formatDateByLanguage(endDate?.format(DATE_FORMAT),"MMM DD, YYYY")}`}
          </label>
          <Icon
            name="forward"
            disabled={
              endDate.format(DATE_FORMAT) === moment().format(DATE_FORMAT)
            }
            onClick={handleChangeDateRange}
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </div>

      <div style={{ padding: "0 15px" }}>
        {!props.hideWebMomKickCount ? (
          <div style={styles.textKickCount}>{intl.formatMessage({id: "bplusClinicKey768"})}</div>
        ) : (
          <div style={styles.textKickCount}>{intl.formatMessage({id: "bplusClinicKey819"})}</div>
        )}
        <Bar
          height={props.height}
          data={history.data}
          options={history.options}
        />
        <div style={styles.textDate}><FormattedMessage id="common.date" /></div>
      </div>

      <div style={{ padding: "0 15px" }}>
        <ReactTable
          className={`weight-table-custom${
            props.useWebMomTable ? " web-mom" : ""
          }`}
          data={props.kickCountHistory?.items}
          columns={columnsRecord}
          pageSize={(props.kickCountHistory?.items || [])?.length}
          style={{ marginTop: "20px", height: "250px" }}
          showPagination={false}
          resizable={false}
          getTheadThProps={handleGetTheadThProps}
        />
      </div>
    </>
  );
};

MomKickCountChart.defaultProps = MomKickCountChartInitial;

export default React.memo(MomKickCountChart);
