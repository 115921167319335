import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

import {
  BACKEND_DATE_FORMAT,
  GROUP,
  DATA_TYPE,
  commonListPatientDataList,
} from "../MobClinicInterface";

export type State = {
  antenatalHistoryList?: any[];
};
export const StateInitial = {
  antenatalHistoryList: [],
};

export type Event = { message: "handleGetAntenatalHistoryList"; params: {} };

type Handler = WasmHandler<State, Event>;

export const handleGetAntenatalHistoryList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.PRENATAL_AND_ANC_RECORD,
    data_type: DATA_TYPE.PRENATAL_AND_ANC_RECORD,
    start_measure: moment().add(-40, "weeks").format(BACKEND_DATE_FORMAT),
    end_measure: moment().format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  let group = response?.items?.reduce((r: any, a: any) => {
    r[a.data.fileType] = [...(r[a.data.fileType] || []), a];
    return r;
  }, {});

  let result = Object.keys(group).map((key: string) => {
    if (group[key].length === 1) {
      return {
        ...group[key][0],
        firstUpload: group[key][0]?.measure_date,
        lastUpdate: group[key][0]?.measure_date,
      };
    } else {
      const lastIndex: number = group[key].length - 1;
      return {
        ...group[key][lastIndex],
        firstUpload: group[key][0]?.measure_date,
        lastUpdate: group[key][lastIndex]?.measure_date,
      };
    }
  });

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    antenatalHistoryList: result,
  });
};
