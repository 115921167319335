import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Grid, Header } from "semantic-ui-react";
import { Radar } from "react-chartjs-2";

import { BACKEND_DATE_FORMAT } from "../MobClinicInterface";
import * as DMPersonalScoreConst from "./DMPersonalScore";
import { HeaderChart } from "./DMEmotionRestChart";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

// Styles
import "../../css/WebClinic.scss";

// ---------- DMPersonalScoreChart
type DMPersonalScoreChartProps = {
  onEvent: any;
  selectedPatient: any;
  scoreSummary: any[];
  hideFilter: boolean;
};

const DMPersonalScoreChartInitial: DMPersonalScoreChartProps = {
  onEvent: () => null,
  selectedPatient: {},
  scoreSummary: [],
  hideFilter: false,
};

const DMPersonalScoreChart: React.FC<DMPersonalScoreChartProps> = (props) => {
  const intl = useIntl();
  const [selectedScoreDuration, setSelectedScoreDuration] =
    useState<string>("w");
  const [scoreStartDate, setScoreStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [scoreEndDate, setScoreEndDate] = useState<moment.Moment>(moment());
  const [scoreDataSummary, setScoreDataSummary] = useState<any>({});
  const durationPatientOptions = useMemo(
    () => [
      {
        key: 1,
        value: "w",
        text: `${intl.formatMessage({ id: "bplusClinicKey763" })}`,
      },
      {
        key: 2,
        value: "M",
        text: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      },
    ],
    []
  );

  const options = {
    scale: {
      ticks: { beginAtZero: true },
    },
  };

  useEffect(() => {
    let labels: any[] = [];
    let datas: any[] = [];

    props.scoreSummary?.forEach((item: any) => {
      labels.push(item.code);
      datas.push(item.score);
    });

    const datasets = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: Array(datas.length).fill(100),
          borderColor: "#4a06f0",
          borderWidth: 1,
          fill: false,
        },
        {
          label: "data",
          data: datas,
          borderColor: "#2399E5",
          borderWidth: 1,
          fill: false,
        },
      ],
    };
    setScoreDataSummary(datasets);
  }, [props.scoreSummary]);

  useEffect(() => {
    props.onEvent({
      message: "handleGetScoreSummary",
      params: {
        start_measure: scoreStartDate.format(BACKEND_DATE_FORMAT),
        end_measure: scoreEndDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [props.selectedPatient, scoreStartDate, scoreEndDate]);

  // Score
  const handleChangeDurationPatient = (event: any, data: any) => {
    setSelectedScoreDuration(data.value);
    const start = moment().add("-1", data.value);
    if (data.value === "w") {
      start.add(1, "days");
    }
    setScoreStartDate(start);
    setScoreEndDate(moment());
  };

  const handleChangeScoreDateRange = (event: any, data: any) => {
    setScoreStartDate(data.fromDate);
    setScoreEndDate(data.toDate);
  };

  return (
    <>
      {!props.hideFilter && (
        <div style={{ marginTop: "10px" }}>
          <HeaderChart
            startDate={scoreStartDate}
            endDate={scoreEndDate}
            durationOptions={durationPatientOptions}
            selectedDuration={selectedScoreDuration}
            onChangeDuration={handleChangeDurationPatient}
            onChangeDateRange={handleChangeScoreDateRange}
            // labelStyle={{ padding: "10px 30px 5px 18px" }}
          />
        </div>
      )}

      <Radar height={200} data={scoreDataSummary} options={options} />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Grid style={DMPersonalScoreConst.styles.gridLab}>
          <Grid.Row columns={2} style={{ fontWeight: "bold" }}>
            <Grid.Column width={9} style={{ padding: "0px 0px 0px 10px" }}>
              <Header as="h3" dividing>
                <FormattedMessage id="bplusClinicKey519" />
              </Header>
            </Grid.Column>
            <Grid.Column
              width={7}
              style={DMPersonalScoreConst.styles.noPadding}
            >
              <Header as="h3" dividing style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey468" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          {props.scoreSummary?.map((item: any, index: number) => {
            return (
              <Grid.Row
                key={index}
                columns={2}
                style={{
                  ...DMPersonalScoreConst.styles.noPadding,
                  color: item?.color,
                }}
              >
                <Grid.Column width={9}>
                  {item?.label === "น้ำหนัก (WT)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey748" })}`
                    : item?.label === "ความดันโลหิต (BP)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey749" })}`
                    : item?.label === "ระดับน้าตาลปลายนิ้ว (SMBG)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey750" })}`
                    : item?.label === "การออกกำลังกาย (EX)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey751" })}`
                    : item?.label === "การรับประทานอาหาร (NU)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey752" })}`
                    : item?.label === "อารมณ์/ ความรู้สึก (EM)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey753" })}`
                    : item?.label === "การพักผ่อน (RE)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey754" })}`
                    : item?.label === "ความเครียด (ST)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey755" })}`
                    : item?.label === "สุขภาพเท้า (FT)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey756" })}`
                    : item?.label === "การรับประทานยา (MD)"
                    ? `${intl.formatMessage({ id: "bplusClinicKey757" })}`
                    : ""}
                </Grid.Column>
                <Grid.Column width={7}>
                  {
                    item?.status === "Underweight" ? `${intl.formatMessage({ id: "bplusClinicKey502" })}` :
                    item?.status === "Normal/ Healthy weight" ? `${intl.formatMessage({ id: "bplusClinicKey825" })}` :
                    item?.status === "Overweight" ? `${intl.formatMessage({ id: "bplusClinicKey365" })}` :
                    item?.status === "Obese" ? `${intl.formatMessage({ id: "bplusClinicKey347" })}` :

                    item?.status === "Normal" ? `${intl.formatMessage({ id: "bplusClinicKey832" })}` : 
                    item?.status === "High blood pressure" ? `${intl.formatMessage({ id: "bplusClinicKey871" })}` :
                    
                    item?.status === "Poorly controlled" ? `${intl.formatMessage({ id: "bplusClinicKey873" })}` :
                    item?.status === "Well controlled" ? `${intl.formatMessage({ id: "bplusClinicKey872" })}` :
                    
                    item?.status === "Very good" ? `${intl.formatMessage({ id: "bplusClinicKey874" })}` :
                    item?.status === "Good" ? `${intl.formatMessage({ id: "bplusClinicKey875" })}` :
                    item?.status === "Poor" ? `${intl.formatMessage({ id: "bplusClinicKey876" })}` :
                    item?.status === "Very poor" ? `${intl.formatMessage({ id: "bplusClinicKey877" })}` :
                    item?.status === "Perfect" ? `${intl.formatMessage({ id: "bplusClinicKey879" })}` :

                    item?.status === "Severe stress" ? `${intl.formatMessage({ id: "bplusClinicKey880" })}` :
                    item?.status === "High stress" ? `${intl.formatMessage({ id: "bplusClinicKey881" })}` :
                    item?.status === "Moderate stress" ? `${intl.formatMessage({ id: "bplusClinicKey882" })}` :
                    item?.status === "Mild stress" ? `${intl.formatMessage({ id: "bplusClinicKey883" })}` :
                    item?.status === "No stress" ? `${intl.formatMessage({ id: "bplusClinicKey884" })}` :
                    
                    item?.status === "Excellent caring" ? `${intl.formatMessage({ id: "bplusClinicKey885" })}` :
                    item?.status === "Good caring" ? `${intl.formatMessage({ id: "bplusClinicKey886" })}` :
                    item?.status === "Fairly good caring" ? `${intl.formatMessage({ id: "bplusClinicKey887" })}` :
                    item?.status === "Fair caring" ? `${intl.formatMessage({ id: "bplusClinicKey888" })}` :
                    item?.status === "Poor caring" ? `${intl.formatMessage({ id: "bplusClinicKey889" })}` :
                    item?.status === "Fair" ? `${intl.formatMessage({ id: "bplusClinicKey890" })}` :
                    
                    item?.status === "Very happy" ? `${intl.formatMessage({ id: "bplusClinicKey725" })}` :
                    item?.status === "Happy" ? `${intl.formatMessage({ id: "bplusClinicKey726" })}` :
                    item?.status === "Bad" ? `${intl.formatMessage({ id: "bplusClinicKey727" })}` :
                    item?.status === "Very bad" ? `${intl.formatMessage({ id: "bplusClinicKey728" })}` :item?.status 
                  }
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

DMPersonalScoreChart.defaultProps = DMPersonalScoreChartInitial;

export default React.memo(DMPersonalScoreChart);
