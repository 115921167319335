import React, { ReactElement } from "react";
// MUI
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { SxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// UX
import { DeliveryType } from "./CardDeliveryType";

// Interface
import { MedicineReceivingOptionType } from "../telephar/Types";

// Types
type RadioCardProps = {
  option: OptionType;
  active: boolean;
  title: ReactElement | string;
  // config
  grey?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  centered?: boolean;
  // styles
  sx?: SxProps;
  // callback
  onSelect?: (option: OptionType) => any;
  // Element
  children?: ReactElement | null;
  rightElement?: ReactElement | null;
  onClickSeeDetail?: Function;
  showDetail?: boolean;
};

type OptionType = MedicineReceivingOptionType | DeliveryType | string | number;

// Styles
const COLORS = {
  blue: "var(--primary-theme-color)",
  very_light_blue: "rgba(204, 218, 237, 1)",
  light_grey: "rgba(196, 196, 196, 1)",
  bg: "linear-gradient(0deg, #F0EFEF, #F0EFEF),linear-gradient(0deg, #F7F7F7, #F7F7F7)",
  border: "rgba(240, 239, 239, 1)",
  grey: "rgba(57, 57, 57, 1)",
  readonly: "rgba(164, 164, 164, 1)",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const cardStyles = {
  borderRadius: "8px !important",
  boxShadow: "none !important",
  border: `1px solid ${COLORS.very_light_blue} !important`,
  backgroundColor: "white !important",
  width: "100%",
  marginBottom: "1rem",
  "& .MuiCardActionArea-focusHighlight": {
    backgroundColor: "white",
  },
  "& .MuiCardContent-root": {
    width: "100%",
    "& .header": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      fontWeight: "bold",
      "& > div:nth-child(2)": {
        marginTop: "0.1rem",
      },
    },
  },
  "&.--grey": {
    // backgroundColor: COLORS.very_light_grey,
    "& *": {
      color: `${COLORS.light_grey} !important`,
    },
    "& .btn-pin, & .MuiButton-root": {
      // filter: "grayscale(1) opacity(0.25)",
    },
  },
  "&.active": {
    border: `1px solid ${COLORS.blue} !important`,
  },
  "&.readonly": {
    background: COLORS.bg,
    color: COLORS.readonly,
    "&.MuiCard-root": {
      border: `1px solid ${COLORS.border} !important`,
    },
  },
};

const boxAddressStyles = {
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  marginTop: "1.25rem",
  lineHeight: 1.45,
  color: "rgba(121, 120, 120, 1)",
  "& .btn-pin": {
    backgroundColor: COLORS.very_light_blue,
    borderRadius: "500rem",
    width: "3.125rem",
    height: "3.125rem",
    ...styles.center,
    "& img": {
      width: "1.5rem",
    },
    marginRight: "1.25rem",
  },
  "& .header": {
    color: COLORS.blue,
    fontSize: "1.2rem",
    fontWeight: "bold",
    margin: "0.15rem 0 0.45rem",
  },
  "& .address": {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    fontWeight: 500,
    color: COLORS.grey,
    "& svg": {
      fontSize: "2.25rem",
      color: COLORS.blue,
    },
  },
  "& .divider": {
    borderBottom: `1px solid ${COLORS.light_grey}`,
    margin: "0.5rem 0",
  },
};

const centeredStyles = {
  "& .MuiCardContent-root": {
    alignItems: "center",
    padding: "12px 16px 14px",
    "& .header": {
      alignItems: "center",
      fontWeight: "normal",
      "& > div:nth-child(2)": {
        marginTop: "-0.1rem",
      },
    },
    "& .MuiRadio-root": {
      margin: "0 0.25rem 0 -0.5rem !important",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    margin: "1rem 0 1rem",
  },
  card: {
    ...cardStyles,
    "& .box-address": boxAddressStyles,
    "&.centered": centeredStyles,
  },
}));

export const RadioCard = (props: RadioCardProps) => {
  const classes = useStyles();

  const handleSelect = () => {
    props.onSelect?.(props.option);
  };

  return (
    <Card
      className={`${classes.card}${
        props.active && !props.disabled ? " active" : ""
      }${props.grey ? " --grey" : ""}${props.readonly ? " readonly" : ""}${
        props.centered ? " centered" : ""
      }`}
      sx={props.sx}
    >
      <CardActionArea disabled={props.disabled} onClick={handleSelect}>
        <CardContent
          style={
            props.rightElement
              ? { display: "grid", gridTemplateColumns: "1fr auto" }
              : {}
          }
        >
          <div>
            <div className="header">
              <div>
                {!props.readonly && (
                  <Radio
                    className={`smart-radio-button${
                      props.grey ? " --grey" : ""
                    }`}
                    name="radio-buttons"
                    checked={props.active}
                    style={{ margin: "0 0.75rem 0 0", padding: 0 }}
                  />
                )}
              </div>
              <div className="label">{props.title}</div>
            </div>
            {props.children}
          </div>
          {props.showDetail && (
            <div
              style={{
                textDecorationLine: "underline",
                color: COLORS.blue,
                textAlign: "right",
              }}
              onClick={() => props.onClickSeeDetail?.()}
            >
              {"รายละเอียดเพิ่มเติม"}
            </div>)
          }
          {props.rightElement && <div>{props.rightElement}</div>}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(RadioCard);
