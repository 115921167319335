import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SetProp, { SetPropSeparate } from "react-lib/frameworks/SetProp";
import Cookies from "js-cookie";
import { defaultAppConfig, DMAppConfig, MOMAppConfig } from "./appConfig";

import ManageController from "./ManageController";
import * as ManageI from "./ManageInterface";
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";
import { IntlProvider, FormattedMessage } from "react-intl";

// ui
import Main from "./manage/Main";
import MainDM from "./manage/MainDM";
import MainMarketing from "./manage/MainMarketing"; 
// import MainPD from "./manage/MainPD";
import MainMom from "./manage/MainMom";
import LoginForm from "./login/LoginForm";
import TopBarClinic from "./manage/TopBarClinic";
import SnackMessage from "./clinic/SnackMessage";
import Preferred from "./manage/Preferred";

// Styles
import "../css/WebClinic.scss";
class Manage extends React.Component<any, ManageI.State> {
  controller: ManageController;
  app: string;
  appConfig: any;
  patientId: string;

  constructor(props: any) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.app = params.get("clinic") || "";
    switch (this.app) {
      case "dm":
        this.appConfig = DMAppConfig;
        break;
      case "mom":
        this.appConfig = MOMAppConfig;
        break;
      default:
        this.appConfig = defaultAppConfig;
    }

    this.patientId = params.get("patientId") || "";

    this.state = {
      ...ManageI.StateInitial,
      loggedin: !!Cookies.get("apiToken") 
    };
    this.setState = this.setState.bind(this);
    this.controller = new ManageController(
      () => {
        return this.state;
      },
      (state: {}, callback?: any) => {
        this.setState(state, callback);
      },
      window
    );
    this.controller.setProp = SetProp(this, "");
    this.controller.setPropSeparate = SetPropSeparate(this, "");
    this.controller.cookies = Cookies;
  }

  componentDidMount = () => {
    console.log('componentDidMount: call DidMount card 1Manage.tsx');
    console.log('componentDidMount this.state.loggedin: ', this.state.loggedin);
    this.controller.handleEvent({ message: "DidMount", params: {card: "2Manage.tsx "} });
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    // Debug
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val &&
        console.debug(`componentDidUpdate Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val &&
          console.debug(`componentDidUpdate State '${key}' changed`)
      );
    }


    if ((prevState.loggedin !== this.state.loggedin) && this.state.loggedin) {
      console.log('componentDidUpdate this.state.loggedin: ', this.state.loggedin);
      console.log('componentDidUpdate prevState.loggedin: ', prevState.loggedin);
      let apiToken = this.controller.cookies.get("apiToken") || "";
      if (apiToken) {
        console.log("componentDidUpdate: DidMount card 2Manage.tsx")
        this.controller.handleEvent({ message: "LoggedIn", params: {card: "2Manage.tsx "} });
      }
    }
  };

  handleSearchPatient = ({ searchText = "" }) => {
    return this.controller.handleEvent({
      message: "handleSearchPatient",
      params: {
        search: searchText,
      },
    });
  };

  render() {
    // console.log("Manage this.appConfig", this);
    // console.log("setup !!! Mange.tsx selectedDivision:  ", this.state.selectedDivision);
    return !this.state.loggedin ? (
      <IntlProvider
        locale="th-TH"
        messages={
          globalThis.window.navigator.language?.toUpperCase()?.includes("TH")
            ? thMessage
            : enMessage
        }
      >
        <LoginForm
          appConfig={this.appConfig}
          onEvent={this.controller.handleEvent}
          showAlertLoginError={this.state.showAlertLoginError}
        />
      </IntlProvider>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <IntlProvider
          locale="th-TH"
          messages={
            globalThis.window.navigator.language?.toUpperCase()?.includes("TH")
              ? thMessage
              : enMessage
          }
        >
          <TopBarClinic
            appConfig={this.appConfig}
            onEvent={this.controller.handleEvent}
            setProp={this.controller.setProp}
            history={this.props.history}
            clinic={this.state.clinic}
            dmTab={this.state.dmSelectedTab}
            patientId={this.state.patientId}
            onGetSearchOptions={this.handleSearchPatient}
            divisionHasUser={this.state.divisionHasUser}
            selectedDivisionId={this.state.selectedDivisionId}
            /// Perferred
            organizationList={this.state.organizationList}
            groupedOrganizationList={this.state.groupedOrganizationList}
            selectedOrganizationCode={this.state.selectedOrganizationCode}
          />
          <SnackMessage
            onEvent={this.controller.handleEvent}
            error={this.state.errorMessage}
            success={this.state.successMessage}
          />
          <div style={{ flex: 1, overflowY: "scroll" }}>
            {this.state.clinic === "dm" ? (
              <MainDM
                // common function
                bgBarfpg={this.state.bgBarfpg}
                bgBarhba1c={this.state.bgBarhba1c}
                bgChartList={this.state.bgChartList}
                bpChartList={this.state.bpChartList}
                onEvent={this.controller.handleEvent}
                setProp={this.controller.setProp}
                onSearch={this.state.onSearch}
                // common data
                loadingStatus={this.state.loadingStatus}
                loadingWebFoot={this.state.loadingWebFoot}
                loadingPatientSelection={this.state.loadingPatientSelection}
                loadingPatientSegment={this.state.loadingPatientSegment}
                errorMessage={this.state.errorMessage}
                successMessage={this.state.successMessage}
                selectedPatient={this.state.selectedPatient}
                selectedDivision={this.state.selectedDivision}
                clinic={this.state.clinic}
                tabPathActiveIndex={this.state.tabPathActiveIndex}
                showSegment={this.state.showSegment}
                // Options
                medDivisionTKOptions={this.state.medDivisionTKOptions}
                medDivisionCROptions={this.state.medDivisionCROptions}
                medDoctorTKOptions={this.state.medDoctorTKOptions}
                medDoctorCROptions={this.state.medDoctorCROptions}
                routeOptions={this.state.routeOptions}
                unitOptions={this.state.unitOptions}
                frequencyOptions={this.state.frequencyOptions}
                methodOptions={this.state.methodOptions}
                administrationTimeOptions={this.state.administrationTimeOptions}
                // data PatientDashBoard
                patientSegmentList={this.state.patientSegmentList}
                // data PersonalScore
                scoreSummary={this.state.scoreSummary}
                labSummary={this.state.labSummary}
                profileHealth={this.state.profileHealth}
                // foot
                footHistory={this.state.footHistory}
                footEclinicalData={this.state.footEclinicalData}
                // data PatientDetail
                leftMenuSelected={this.state.leftMenuSelected}
                dmSelectedTab={this.state.dmSelectedTab}
                showPatientDM={this.state.showPatientDM}
                // weight
                patientWeightList={this.state.patientWeightList}
                hospitalWeightList={this.state.hospitalWeightList}
                weightData={this.state.weightData}
                weightChartList={this.state.weightChartList}
                // EmotionRest
                emotionRestHistoryList={this.state.emotionRestHistoryList}
                // Exercise
                exerciseChartList={this.state.exerciseChartList}
                exerciseSettingData={this.state.exerciseSettingData}
                // medication
                loadingMedication={this.state.loadingMedication}
                loadingDivisionOptions={this.state.loadingDivisionOptions}
                loadingDoctorOptions={this.state.loadingDoctorOptions}
                medicationCurrentList={this.state.medicationCurrentList}
                medicationCurrentListFilter={
                  this.state.medicationCurrentListFilter
                }
                drugDataTabHistory={this.state.drugDataTabHistory}
                medicationShowFilter={this.state.medicationShowFilter}
                medicationTrackcare={this.state.medicationTrackcare}
                medicationTrackcareFilter={this.state.medicationTrackcareFilter}
                // blood
                bpFilterDuration={this.state.bpFilterDuration}
                // food
                foodHistory={this.state.foodHistory}
                loadingFood={this.state.loadingFood}
                // vaccine
                vaccineDataTabDetail={this.state.vaccineDataTabDetail}
                vaccineSeriesList={this.state.vaccineSeriesList}
                // User manager Nutrition
                loadingStatusAditionalMenu={
                  this.state.loadingStatusAditionalMenu
                }
                nutritionAditionalMenuList={
                  this.state.nutritionAditionalMenuList
                }
                selectedNutritionAditionalMenu={
                  this.state.selectedNutritionAditionalMenu
                }
                nutritionNutritionistMenuList={
                  this.state.nutritionNutritionistMenuList
                }
                selectedNutritionNutritionistMenu={
                  this.state.selectedNutritionNutritionistMenu
                }
                nutritionistUMFilter={this.state.nutritionistUMFilter}
                nutritionUMFilter={this.state.nutritionUMFilter}
                consumptionUnitList={this.state.consumptionUnitList}
                foodTypeList={this.state.foodTypeList}
                openModNutrition={this.state.openModNutrition}
                patientSearch={this.state.patientSearch}
                // User manager Patient Selection
                filterKeyword={this.state.filterKeyword}
                dmMomSegmentList={this.state.dmMomSegmentList}
                dmMomPatientList={this.state.dmMomPatientList}
                // User manager vaccine
                vaccineUMDetail={this.state.vaccineUMDetail}
                vaccineUMList={this.state.vaccineUMList}
                orderItemVaccineUM={this.state.orderItemVaccineUM}
                // Segmentation
                loadingMarketingSegment={this.state.loadingMarketingSegment}
                loadingMarketingPatient={this.state.loadingMarketingPatient}
                filterMarketingPatient={this.state.filterMarketingPatient}
                marketingSegmentList={this.state.marketingSegmentList}
                marketingPatientList={this.state.marketingPatientList}
                marketingSegmentAgeGender={this.state.marketingSegmentAgeGender}
                marketingSegmentTopPost={this.state.marketingSegmentTopPost}
                marketingSegmentTopPromotion={
                  this.state.marketingSegmentTopPromotion
                }
                marketingCreatePatient={this.state.marketingCreatePatient}
                // Post
                tagList={this.state.tagList}
                postList={this.state.postList}
                selectedPostIndex={this.state.selectedPostIndex}
                // ClinicContent
                setPropSeparate={this.controller.setPropSeparate}
                clinicContent={this.state.clinicContent}
                clinicContentIndex={this.state.clinicContentIndex}
                clinicalSegment={this.state.clinicalSegment}
                divisionPatient={this.state.divisionPatient}
                divisionHasUser={this.state.divisionHasUser}
                selectedDivisionId={this.state.selectedDivisionId}
                // MarketingPromotion
                marketingPromotionList={this.state.marketingPromotionList}
                marketingPromotionIndex={this.state.marketingPromotionIndex}
                loadingMainPromotion={this.state.loadingMainPromotion}
                marketingPromotionId={this.state.marketingPromotionId}
                marketingPromotionChart={this.state.marketingPromotionChart}
                // MarketingPost
                marketingPostList={this.state.marketingPostList}
                marketingPostIndex={this.state.marketingPostIndex}
                loadingMainPost={this.state.loadingMainPost}
                marketingPostId={this.state.marketingPostId}
                marketingPostChart={this.state.marketingPostChart}
              />
            ) : this.state.clinic === "marketing" ? (
              <MainMarketing
                // common function
                bgBarfpg={this.state.bgBarfpg}
                bgBarhba1c={this.state.bgBarhba1c}
                bgChartList={this.state.bgChartList}
                bpChartList={this.state.bpChartList}
                onEvent={this.controller.handleEvent}
                setProp={this.controller.setProp}
                onSearch={this.state.onSearch}
                organizationList={this.state.organizationList}
                selectedOrganizationCode={this.state.selectedOrganizationCode}
                // common data
                loadingStatus={this.state.loadingStatus}
                loadingWebFoot={this.state.loadingWebFoot}
                loadingPatientSelection={this.state.loadingPatientSelection}
                loadingPatientSegment={this.state.loadingPatientSegment}
                errorMessage={this.state.errorMessage}
                successMessage={this.state.successMessage}
                selectedPatient={this.state.selectedPatient}
                selectedDivision={this.state.selectedDivision}
                clinic={this.state.clinic}
                tabPathActiveIndex={this.state.tabPathActiveIndex}
                showSegment={this.state.showSegment}
                // Options
                medDivisionTKOptions={this.state.medDivisionTKOptions}
                medDivisionCROptions={this.state.medDivisionCROptions}
                medDoctorTKOptions={this.state.medDoctorTKOptions}
                medDoctorCROptions={this.state.medDoctorCROptions}
                routeOptions={this.state.routeOptions}
                unitOptions={this.state.unitOptions}
                frequencyOptions={this.state.frequencyOptions}
                methodOptions={this.state.methodOptions}
                administrationTimeOptions={this.state.administrationTimeOptions}
                // User manager Nutrition
                loadingStatusAditionalMenu={
                  this.state.loadingStatusAditionalMenu
                }
                nutritionAditionalMenuList={
                  this.state.nutritionAditionalMenuList
                }
                selectedNutritionAditionalMenu={
                  this.state.selectedNutritionAditionalMenu
                }
                nutritionNutritionistMenuList={
                  this.state.nutritionNutritionistMenuList
                }
                selectedNutritionNutritionistMenu={
                  this.state.selectedNutritionNutritionistMenu
                }
                nutritionistUMFilter={this.state.nutritionistUMFilter}
                nutritionUMFilter={this.state.nutritionUMFilter}
                consumptionUnitList={this.state.consumptionUnitList}
                foodTypeList={this.state.foodTypeList}
                openModNutrition={this.state.openModNutrition}
                patientSearch={this.state.patientSearch}
                // User manager Patient Selection
                filterKeyword={this.state.filterKeyword}
                dmMomSegmentList={this.state.dmMomSegmentList}
                dmMomPatientList={this.state.dmMomPatientList}
                // User manager vaccine
                vaccineUMDetail={this.state.vaccineUMDetail}
                vaccineUMList={this.state.vaccineUMList}
                orderItemVaccineUM={this.state.orderItemVaccineUM}
                // Segmentation
                loadingMarketingSegment={this.state.loadingMarketingSegment}
                loadingMarketingPatient={this.state.loadingMarketingPatient}
                filterMarketingPatient={this.state.filterMarketingPatient}
                marketingSegmentList={this.state.marketingSegmentList}
                marketingPatientList={this.state.marketingPatientList}
                marketingSegmentAgeGender={this.state.marketingSegmentAgeGender}
                marketingSegmentTopPost={this.state.marketingSegmentTopPost}
                marketingSegmentTopPromotion={
                  this.state.marketingSegmentTopPromotion
                }
                marketingCreatePatient={this.state.marketingCreatePatient}
                // Post
                tagList={this.state.tagList}
                postList={this.state.postList}
                selectedPostIndex={this.state.selectedPostIndex}
                // ClinicContent
                setPropSeparate={this.controller.setPropSeparate}
                clinicContent={this.state.clinicContent}
                clinicContentIndex={this.state.clinicContentIndex}
                clinicalSegment={this.state.clinicalSegment}
                divisionPatient={this.state.divisionPatient}
                divisionHasUser={this.state.divisionHasUser}
                selectedDivisionId={this.state.selectedDivisionId}
                // MarketingPromotion
                marketingPromotionList={this.state.marketingPromotionList}
                marketingPromotionIndex={this.state.marketingPromotionIndex}
                loadingMainPromotion={this.state.loadingMainPromotion}
                marketingPromotionId={this.state.marketingPromotionId}
                marketingPromotionChart={this.state.marketingPromotionChart}
                // MarketingPost
                marketingPostList={this.state.marketingPostList}
                marketingPostIndex={this.state.marketingPostIndex}
                loadingMainPost={this.state.loadingMainPost}
                marketingPostId={this.state.marketingPostId}
                marketingPostChart={this.state.marketingPostChart}
                // MarketingAnalytic
                marketingAnalytics={this.state.marketingAnalytics}
                marketingAnalyticsSegment={this.state.marketingAnalyticsSegment}
                loadingMarketingAnalytics={this.state.loadingMarketingAnalytics}
                loadingAnalyticsSegment={this.state.loadingAnalyticsSegment}
                patientAnalyticsDetail={this.state.patientAnalyticsDetail}
                loadingPatientAnalytics={this.state.loadingPatientAnalytics}
                analyticPatientList={this.state.analyticPatientList}
              />
            ) : this.state.clinic === "mom" ? (
              <MainMom
                // common function
                onEvent={this.controller.handleEvent}
                setProp={this.controller.setProp}
                history={this.props.history}
                // common data
                loadingStatus={this.state.loadingStatus}
                loadingPatientSelection={this.state.loadingPatientSelection}
                loadingBabyRelationship={this.state.loadingBabyRelationship}
                loadingPatientSegment={this.state.loadingPatientSegment}
                errorMessage={this.state.errorMessage}
                successMessage={this.state.successMessage}
                selectedPatient={this.state.selectedPatient}
                selectedDivision={this.state.selectedDivision}
                clinic={this.state.clinic}
                tabPathActiveIndex={this.state.tabPathActiveIndex}
                showSegment={this.state.showSegment}
                // Options
                routeOptions={this.state.routeOptions}
                unitOptions={this.state.unitOptions}
                frequencyOptions={this.state.frequencyOptions}
                methodOptions={this.state.methodOptions}
                administrationTimeOptions={this.state.administrationTimeOptions}
                // data PatientDashBoard
                patientSegmentList={this.state.patientSegmentList}
                // data PersonalScore
                scoreSummary={this.state.scoreSummary}
                labSummary={this.state.labSummary}
                // data PatientDetail
                leftMenuSelected={this.state.leftMenuSelected}
                dmSelectedTab={this.state.dmSelectedTab}
                showPatientDM={this.state.showPatientDM}
                menuName={this.state.menuName}
                // WebMomOverall
                profileHealth={this.state.profileHealth}
                profileObstretic={this.state.profileObstretic}
                // Weight gain
                weightGainList={this.state.weightGainList}
                weightGainDetail={this.state.weightGainDetail}
                obstreticRecord={this.state.obstreticRecord}
                weightPrePregnancy={this.state.weightPrePregnancy}
                weightFilterStartDate={this.state.weightFilterStartDate}
                weightFilterEndDate={this.state.weightFilterEndDate}
                // kickcount
                kickCountHistory={this.state.kickCountHistory}
                kickCountFilterDuration={this.state.kickCountFilterDuration}
                // Contractioncount
                contractionCountHistory={this.state.contractionCountHistory}
                contractionFilterDuration={this.state.contractionFilterDuration}
                // Breast Feeding
                breastfeedingList={this.state.breastfeedingList}
                // Obstretic record
                obstetricHistoryList={this.state.obstetricHistoryList}
                obstetricChildList={this.state.obstetricChildList}
                activeIndexBaby={this.state.activeIndexBaby}
                activeIndexObstetricHistory={
                  this.state.activeIndexObstetricHistory
                }
                openModConfirmCreate={this.state.openModConfirmCreate}
                // Ultrasound
                ultrasoundList={this.state.ultrasoundList}
                ultrasoundUploadDetail={this.state.ultrasoundUploadDetail}
                selectedUltrasoundImage={this.state.selectedUltrasoundImage}
                // Segmentation
                loadingMarketingSegment={this.state.loadingMarketingSegment}
                loadingMarketingPatient={this.state.loadingMarketingPatient}
                filterMarketingPatient={this.state.filterMarketingPatient}
                marketingSegmentList={this.state.marketingSegmentList}
                marketingPatientList={this.state.marketingPatientList}
                marketingSegmentAgeGender={this.state.marketingSegmentAgeGender}
                marketingSegmentTopPost={this.state.marketingSegmentTopPost}
                marketingSegmentTopPromotion={
                  this.state.marketingSegmentTopPromotion
                }
                marketingCreatePatient={this.state.marketingCreatePatient}
                // Post
                tagList={this.state.tagList}
                postList={this.state.postList}
                selectedPostIndex={this.state.selectedPostIndex}
                // PrenatalAndANCRecord
                prenatalAndANCRecordList={this.state.prenatalAndANCRecordList}
                prenatalAndANCRecordFilter={
                  this.state.prenatalAndANCRecordFilter
                }
                // OutsideMedicines
                outsideMedicines={this.state.outsideMedicines}
                // Development
                developmentHistory={this.state.developmentHistory}
                developmentLastEdited={this.state.developmentLastEdited}
                developmentAgeRange={this.state.developmentAgeRange}
                // Growth
                growthHistoryList={this.state.growthHistoryList}
                // Overall Progress
                childOverallProgress={this.state.childOverallProgress}
                // Vaccination
                ageRangeVaccineChild={this.state.ageRangeVaccineChild}
                essentialVaccineList={this.state.essentialVaccineList}
                otherVaccineList={this.state.otherVaccineList}
                lastUpdateVaccineChild={this.state.lastUpdateVaccineChild}
                // User manager Patient Selection
                filterKeyword={this.state.filterKeyword}
                dmMomSegmentList={this.state.dmMomSegmentList}
                dmMomPatientList={this.state.dmMomPatientList}
                // User manager WebUMBabyRelationship
                babyRelationFilter={this.state.babyRelationFilter}
                familyOptions={this.state.familyOptions}
                relationPatient={this.state.relationPatient}
                relationProfiles={this.state.relationProfiles}
                selectedBabySegment={this.state.selectedBabySegment}
                babySegmentList={this.state.babySegmentList}
                babyList={this.state.babyList}
                // User manager vaccine
                vaccineUMDetail={this.state.vaccineUMDetail}
                vaccineUMList={this.state.vaccineUMList}
                vaccineSeriesChoice={this.state.vaccineSeriesChoice}
                orderItemVaccineUM={this.state.orderItemVaccineUM}
                ageRangeChoice={this.state.ageRangeChoice}
                ageRangeDetail={this.state.ageRangeDetail}
                ageRangeList={this.state.ageRangeList}
                // User manager development
                developmentChoice={this.state.developmentChoice}
                developmentUMDetail={this.state.developmentUMDetail}
                developmentUMList={this.state.developmentUMList}
                // ClinicContent
                setPropSeparate={this.controller.setPropSeparate}
                clinicContent={this.state.clinicContent}
                clinicContentIndex={this.state.clinicContentIndex}
                clinicalSegment={this.state.clinicalSegment}
                divisionPatient={this.state.divisionPatient}
                divisionHasUser={this.state.divisionHasUser}
                selectedDivisionId={this.state.selectedDivisionId}
              />
            ) : this.state.clinic === "preferred" ? (
              <Preferred
                onEvent={this.controller.handleEvent}
                setProp={this.controller.setProp}
                controller={this.controller}
                selectedOrganizationCode={this.state.selectedOrganizationCode}
                servicesOfHospitalFB={this.state.servicesOfHospitalFB}
                servicesOfHospitalDJ={this.state.servicesOfHospitalDJ}
                organizationList={this.state.organizationList}
                preferredLoading={this.state.preferredLoading}
                medProgramList={this.state.medProgramList}
                subspecialtiesList={this.state.subspecialtiesList}
              />
            ) : (
              <Main
                hideTab={this.state.hideTab}
                onEvent={this.controller.handleEvent}
                history={this.props.history}
                contentSummary={this.state.contentSummary}
                contentOk={this.state.contentOk}
                contentUnique={this.state.contentUnique}
                contentList={this.state.contentList}
                selectedContentIndex={this.state.selectedContentIndex}
                termList={this.state.termList}
                tagList={this.state.tagList}
                selectedTagIndex={this.state.selectedTagIndex}
                postList={this.state.postList}
                selectedPostIndex={this.state.selectedPostIndex}
                patientClinicalList={this.state.patientClinicalList}
                patientMarketingList={this.state.patientMarketingList}
                patientSegmentIndex={this.state.patientSegmentIndex}
                patientIndex={this.state.patientIndex}
                patientId={this.state.patientId}
                statDict={this.state.statDict}
                medicationList={this.state.medicationList}
                footList={this.state.footList}
                patientSegmentData={this.state.patientSegmentData}
                openPatientDetail={() => {
                  this.props.history.push("/patientDetail/?clinic=pd");
                }}
                setPatientIndex={(index: number) => {
                  this.controller.handleEvent({
                    message: "SetPatientIndex",
                    params: { index: index },
                  });
                }}
                setProp={this.controller.setProp}
              />
            )}
          </div>
        </IntlProvider>
      </div>
    );
  }
}

export default withRouter(Manage);
