import React, { CSSProperties, useMemo, useState } from "react";
import { Accordion, Grid, Menu, Table } from "semantic-ui-react";

// UI
import WebUMPatientSelection from "./detail/WebUMPatientSelection";
import WebUMVaccine from "./detail/WebUMVaccine";
import WebDMUMNutrition from "./detail/WebDMUMNutrition";

export const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
};

type UserManagementDMProps = {
  // function
  onEvent: any;
  setProp: any;
  // data
  loadingStatus?: boolean;
  loadingPatientSelection?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedDivision?: any;
  selectedPatient: any;
  clinic?: string;
  patientSegmentList: any;
  dmSelectedTab?: null | number;
  // data WebPatientPanel
  scoreSummary?: any;
  labSummary?: any;
  profileHealth?: any[];
  // Usermanager Patient Selection
  filterKeyword?: string;
  dmMomSegmentList?: any;
  dmMomPatientList?: any;
  // Usermanager Nutrition
  loadingStatusAditionalMenu?: boolean;
  nutritionAditionalMenuList?: any;
  selectedNutritionAditionalMenu?: any;
  nutritionNutritionistMenuList?: any;
  selectedNutritionNutritionistMenu?: any;
  nutritionistUMFilter?: any;
  nutritionUMFilter?: any;
  consumptionUnitList?: any[];
  foodTypeList?: any[];
  openModNutrition?: any;
  patientSearch?: any;
  // User manager vaccine
  vaccineUMDetail?: any;
  vaccineUMList?: any[];
  orderItemVaccineUM?: any[];
  // config
  hideLeftMenu?: boolean;
};

const UserManagementDMInitial: UserManagementDMProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  // data
  loadingStatus: false,
  loadingPatientSelection: false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  selectedPatient: {},
  clinic: "",
  patientSegmentList: {},
  dmSelectedTab: null,
  // WebPatientPanel
  scoreSummary: {},
  labSummary: {},
  profileHealth: [],
  // Usermanager Patient Selection
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
  // Usermanager Nutrition
  loadingStatusAditionalMenu: false,
  nutritionAditionalMenuList: {},
  selectedNutritionAditionalMenu: null,
  nutritionNutritionistMenuList: {},
  selectedNutritionNutritionistMenu: null,
  nutritionistUMFilter: {},
  nutritionUMFilter: {},
  consumptionUnitList: [],
  foodTypeList: [],
  openModNutrition: {},
  patientSearch: {},
  // User manager vaccine
  vaccineUMDetail: {},
  vaccineUMList: [],
  orderItemVaccineUM: [],
  // config
  hideLeftMenu: false,
};

const UserManagementDM: React.FC<UserManagementDMProps> = (props: any) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const leftMenu = useMemo(
    () => [
      {
        result: "Patient Selection",
      },
      {
        result: "Vaccine",
      },
      {
        result: "Nutrition",
      },
    ],
    []
  );

  return (
    <>
      {/* <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      /> */}

      <Grid style={{ margin: "-10px", height: "100%" }}>
        {!props.hideLeftMenu && (
          <Grid.Column
            width={3}
            style={{
              padding: "10px 0px 0px 10px",
              borderRight: "0.1px solid rgb(204, 204, 204)",
            }}
          >
            <Accordion as={Menu} vertical fluid>
              {leftMenu?.map((item: any, index: number) => {
                return (
                  <Menu.Item
                    key={index}
                    style={{ padding: "0px 0px", fontWeight: "bold" }}
                  >
                    <Accordion.Content
                      active={true}
                      content={
                        <Table selectable style={{ border: "none" }}>
                          <Table.Body>
                            <Table.Row active={activeIndex === index}>
                              <Table.Cell
                                style={{
                                  color: "var(--text-dark-blue)",
                                  padding: "10px 30px",
                                }}
                                onClick={() => {
                                  setActiveIndex(index);
                                }}
                              >
                                {item.result}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      }
                    />
                  </Menu.Item>
                );
              })}
            </Accordion>
          </Grid.Column>
        )}

        <Grid.Column
          width={!props.hideLeftMenu ? 13 : 13}
          style={{ padding: "10px 0px", background: "#DBEFFD" }}
        >
          {activeIndex === 0 ? (
            <WebUMPatientSelection
              // function
              onEvent={props.onEvent}
              // data
              loadingPatientSelection={props.loadingPatientSelection}
              selectedDivision={props.selectedDivision}
              clinic={props.clinic}
              filterKeyword={props.filterKeyword}
              dmMomSegmentList={props.dmMomSegmentList}
              dmMomPatientList={props.dmMomPatientList}
            />
          ) : activeIndex === 1 ? (
            <WebUMVaccine
              onEvent={props.onEvent}
              vaccineUMDetail={props.vaccineUMDetail}
              vaccineUMList={props.vaccineUMList}
              orderItemVaccineUM={props.orderItemVaccineUM}
              hideTabAgeRange={true}
              loadingStatus={props.loadingStatus}
              patientType="DM"
            />
          ) : activeIndex === 2 ? (
            <WebDMUMNutrition
              onEvent={props.onEvent}
              setProp={props.setProp}
              loadingStatus={props.loadingStatus}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              loadingStatusAditionalMenu={props.loadingStatusAditionalMenu}
              selectedPatient={props.selectedPatient}
              nutritionAditionalMenuList={props.nutritionAditionalMenuList}
              selectedNutritionAditionalMenu={
                props.selectedNutritionAditionalMenu
              }
              nutritionNutritionistMenuList={
                props.nutritionNutritionistMenuList
              }
              selectedNutritionNutritionistMenu={
                props.selectedNutritionNutritionistMenu
              }
              nutritionistUMFilter={props.nutritionistUMFilter}
              nutritionUMFilter={props.nutritionUMFilter}
              consumptionUnitList={props.consumptionUnitList}
              foodTypeList={props.foodTypeList}
              openModNutrition={props.openModNutrition}
              patientSearch={props.patientSearch}
            />
          ) : null}
        </Grid.Column>
      </Grid>
    </>
  );
};

UserManagementDM.defaultProps = UserManagementDMInitial;
export default React.memo(UserManagementDM);
