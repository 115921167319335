import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
  checkBloodPressure,
  commonListPatientDataList,
} from "./../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

import { formatUTCtoMoment } from "../../../react-lib/utils/dateUtils";
import StaffDirectVitalSignView from "../../../issara-sdk/apis/StaffDirectVitalSignView_apps_PRX";

export type State = {
  bpFilterDate?: moment.Moment;
  bpFilterDuration?: string;
  bpRecordList?: any;
  bpSettingData?: any;
  bpChartList?: any;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export const StateInitial: State = {
  bpFilterDate: moment(),
  bpFilterDuration: "6",
  bpRecordList: {},
  bpSettingData: {},
  bpChartList: {},
};

export type Event =
  // GET
  | {
      message: "GetBloodPressureChart";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "handleChangeBpFilterDuration";
      params: { duration: string };
    };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const GetBloodPressureChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  const tmpParams: any = {
    group: GROUP.BLOOD_PRESSURE,
    data_type: DATA_TYPE.BLOOD_PRESSURE,
    ...params,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );
  console.log("print", tmpParams);
  // formatUTCtoMoment(measure_date).format("YYYY-MM-DD HH")

  const [res, err, net] = await StaffDirectVitalSignView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: controller.getState().selectedPatient?.proxy_patient,
      start_date: params.start_measure, // start_date, end_date
      end_date: params.end_measure,
    },
  });

  console.log("res lab result: ", res, net);

  let vitalSignDirectData: any[] = [];
  if (res.vital_sign_values && res.vital_sign_values.length > 0) {
    let diastolic =
      res.vital_sign_values.find((item: any) => item.code === "BP_DIASTOLIC")
        ?.values || [];
    let systolic =
      res.vital_sign_values.find((item: any) => item.code === "BP_SYSTOLIC")
        ?.values || [];
    let pulse =
      res.vital_sign_values.find((item: any) => item.code === "PULSE")
        ?.values || [];

    let dateData = [
      // @ts-ignore
      ...new Set(
        diastolic
          .map(
            (item: any) =>
              `${moment(item.measurement_date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}T${item.measurement_time}Z`
          )
          .concat(
            systolic.map(
              (item: any) =>
                `${moment(item.measurement_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}T${item.measurement_time}Z`
            )
          )
          .concat(
            pulse.map(
              (item: any) =>
                `${moment(item.measurement_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}T${item.measurement_time}Z`
            )
          )
      ),
    ];

    dateData.forEach((date) => {
      // if (
      //   formatUTCtoMoment(date).isSameOrAfter(
      //     moment(params.start_measure, BACKEND_DATE_FORMAT)
      //   ) &&
      //   formatUTCtoMoment(date).isSameOrBefore(
      //     moment(params.end_measure, BACKEND_DATE_FORMAT)
      //   )
      // ) {
      let hosSys =
        systolic.find(
          (i: any) =>
            `${moment(i.measurement_date, "DD/MM/YYYY").format("YYYY-MM-DD")}T${
              i.measurement_time
            }Z` === date
        )?.result || null;
      let hosDia =
        diastolic.find(
          (i: any) =>
            `${moment(i.measurement_date, "DD/MM/YYYY").format("YYYY-MM-DD")}T${
              i.measurement_time
            }Z` === date
        )?.result || null;

      let result: { status: string; pass: boolean | null; color: string } = {
        status: "",
        pass: null,
        color: "black",
      };
      if (hosSys && hosDia) {
        result = checkBloodPressure(hosSys, hosDia);
      }

      vitalSignDirectData.push({
        measure_date: formatUTCtoMoment(date).format(BACKEND_DATE_FORMAT),
        time:
          systolic.find(
            (i: any) =>
              `${moment(i.measurement_date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )}T${i.measurement_time}Z` === date
          )?.measurement_time || "",
        data: {
          hospital_systolic: hosSys,
          hospital_diastolic: hosDia,
          hospital_pulse:
            pulse.find(
              (i: any) =>
                `${moment(i.measurement_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}T${i.measurement_time}Z` === date
            )?.result || null,
          systolic: null,
          diastolic: null,
          pulse: null,
          note: "",
          ...result,
          trakcare: true,
        },
      });
      // }
    });
  }

  let bloodpressureResponse: any = {
    items: [],
  };

  if (vitalSignDirectData.length > 0) {
    bloodpressureResponse["items"] = [...vitalSignDirectData];
  }

  if (response.items && response.items.length > 0) {
    response.items.forEach((item: any) => {
      let dateItem = formatUTCtoMoment(item.measure_date).format(
        BACKEND_DATE_FORMAT
      );
      let timeItem = formatUTCtoMoment(item.measure_date).format(
        BACKEND_TIME_FORMAT
      );
      let findItem = bloodpressureResponse["items"].find(
        (i: any) => i.measure_date === dateItem && timeItem === i.time
      );

      if (findItem) {
        bloodpressureResponse["items"].data = { ...item.data };
      } else {
        bloodpressureResponse["items"].push({
          id: item.id,
          measure_date: dateItem,
          time: timeItem,
          data: {
            ...item.data,
            hospital_systolic: null,
            hospital_diastolic: null,
            hospital_pulse: null,
          },
        });
      }
    });
  }

  // bloodpressureResponse["items"] = response.items.map((item) => {
  //   return {
  //     id: item.id,
  //     measure_date: formatUTCtoMoment(item.measure_date).format(
  //       BACKEND_DATE_FORMAT
  //     ),
  //     time: formatUTCtoMoment(item.measure_date).format(BACKEND_TIME_FORMAT),
  //     data: { ...item.data },
  //   };
  // });

  // sort data
  // let tmpData = [...bloodpressureResponse["items"]]
  let realData: any[] = [];
  let start = moment(params.start_measure, BACKEND_DATE_FORMAT);
  let end = moment(params.end_measure, BACKEND_DATE_FORMAT);
  let running = start.clone();

  while (running.isSameOrAfter(start) && running.isSameOrBefore(end)) {
    let runningDate = running.clone().format(BACKEND_DATE_FORMAT);
    let filterData = [...bloodpressureResponse["items"]].filter(
      (item) => item.measure_date === runningDate
    );
    if (filterData.length > 0) {
      realData = realData.concat(filterData);
    }
    running.add(1, "days");
  }

  bloodpressureResponse["items"] = [...realData];

  console.log("bloodpressureResponse: ", bloodpressureResponse["items"]);

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bpChartList: bloodpressureResponse,
  });
};

export const handleChangeBpFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ bpFilterDuration: params.duration });
};
