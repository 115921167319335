import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Icon } from "semantic-ui-react";
import CONFIG from "../config/config";
import { useStateCallback } from "../react-lib/utils/hooksUtils";

var globPostMessage: Record<number, any[]> = {};
export const Main = (props: any) => {
  const history = useHistory();

  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [visible, setVisible] = useState(true);
  const [videoCallStartPip, setVideoCallStartPip] = useState(false);
  const [postMessage, setPostMessage] = useStateCallback({});
  const [textMessage, setTextMessage] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    setVisible(props.openVideoCallModal);
  }, [props.openVideoCallModal]);

  const sendMessage = ({ contentType, content } = {}) => {
    // console.log("saika ;) message ----> ",contentType)
    return new Promise((resolve, reject) => {
      let txtContent = content ? content : textMessage;
      console.log("txtContent: ", txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if ( 
        !props.incomingDetail
      ) {
        return;
      }
      let chatChannelId = props.incomingDetail.channelId;
      let id = new Date().toISOString();
      let msg = postMessage[chatChannelId]
        ? [...postMessage[chatChannelId]]
        : [];
      // let postMsg = {
      //   ...postMessage,
      //   [chatChannelId]: [
      //     ...msg,
      //     {
      //       id: id,
      //       author: {
      //         id: props.userId
      //       },
      //       content_file: txtContent,
      //       content: txtContent,
      //       content_type: contentType ? contentType : "text",
      //       sendFail: false
      //     }
      //   ]
      // };
      if (!globPostMessage[chatChannelId]) {
        globPostMessage[chatChannelId] = [];
      }
      globPostMessage[chatChannelId].push({
        id: id,
        author: {
          id: props.userId,
        },
        content_file: txtContent,
        content: txtContent,
        content_type: contentType ? contentType : "text",
        sendFail: false,
      });

      setTextMessage("");

      var response, error, network;
      const length = globPostMessage[chatChannelId]?.length - 1;
      console.log("globPostMessage", globPostMessage);
      console.log("postMessage", postMessage);
      // console.log("saika ;) props.apitoken ",props.apitoken," chatChannelId ",props.incomingDetail.channelId," divisionId ",props.incomingDetail.divisionId)
      setPostMessage({ ...globPostMessage }, async () => {
        [response, error, network] =
          await props.controller.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.incomingDetail.channelId,
            apiToken:  props.apitoken,
            divisionId: props.incomingDetail.divisionId
          });
        // console.log("saika ;) response -> main",response)
        if (isMounted.current) {
          if (response) {
            if (globPostMessage[chatChannelId]?.[length]) {
              globPostMessage[chatChannelId][length].message_id = response.id;
            }
            // Request next question
            if (props.chatBotLogic) {
              await props.chatBotLogic(textMessage);
              // getLastMessage();
            }
            // await getLastMessage();
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            globPostMessage[chatChannelId].splice(index, 1);
            // newObj[chatChannelId] = newArr;
            setPostMessage(globPostMessage);
          } else {
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            if (index !== -1) {
              globPostMessage[chatChannelId][index].sendFail = true;
              // newObj[chatChannelId] = newArr;
            }
            setPostMessage(globPostMessage);
          }
        }
        resolve(response);
      });
    });
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("MainVideoCall handle close video call");
    setTimeout(() => {
      sendMessage({
        contentType: "end_call",
        content: "", // `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
      });
    }, 1000);
    props.setProp("openVideoCallModal", false);
    setVisible(false);
    setVideoCallStartPip(false);
    setIframeHeight(1);
    setIframeWidth(1);
  };

  const handleIframeResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
      setVisible(true);
    }, 500);
  };

  const handleIframeMessage = (msg: any) => {
    console.log(msg);
    try {
      let jsonMsg = JSON.parse(msg.data);
      if (jsonMsg.message === "end_call") {
        handleCloseVideoCall({ sendEndMessage: false });
      } else if (jsonMsg.message === "start_pip") {
        setTimeout(() => {
          setIframeHeight(1);
          setIframeWidth(1);
          setVideoCallStartPip(true);
          // setVisible(false);
        }, 500);
        window.removeEventListener("resize", handleIframeResize);
      } else if (jsonMsg.message === "stop_pip") {
        window.addEventListener("resize", handleIframeResize);
        setVideoCallStartPip(false);
        handleIframeResize();
      }
    } catch (e) {
      // console.log("ignore this message");
    }
  };

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleIframeResize);
    window.addEventListener("message", handleIframeMessage);
    if (visible) handleIframeResize();
  };

  return (
    <div>
      {props.openVideoCallModal && (
        <Dimmer
          inverted
          active={visible}
          size="fullscreen"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            width: videoCallStartPip ? "0px" : "100%",
            height: videoCallStartPip ? "0px" : "100%",
          }}
          className={{}}
        >
          <iframe
            frameBorder={0}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera; *;"
            src={`${props.videoCallRoom}`}
            width={videoCallStartPip ? "1px" : iframeWidth}
            height={videoCallStartPip ? "1px" : iframeHeight}
          />
        </Dimmer>
      )}
    </div>
  );
};
