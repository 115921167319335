import React, { useState, useRef, useEffect } from "react";
import {
  Menu,
  Icon,
  List,
  Label,
  Image,
  Header,
  Input,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import "./App.css";
import personImg from "react-lib/apps/common/person.png";
import OrganizationListView from "issara-sdk/apis/OrganizationListView_users";
import CONFIG from "../config/config";
import { FormattedMessage } from "react-intl";
import { Skeleton } from "@material-ui/lab";
import MobileMenuBar from "react-lib/apps/IsHealth/Common/MobileMenuBar";

export const ChatList = React.forwardRef((props, ref) => {
  const isMounted = useRef(true);
  const inputRef = useRef();
  const apiToken = Cookies.get("apiToken");
  const cookiesUserId = Cookies.get("userId");
  const [loadCheckTelemed, setLoadCheckTelemed] = useState(false)
  const handleChannelSelected = (item) => () => {
    console.log(" handleChannelSelected item", item);

    // console.log("CONFIG.DM_BPLUS_URL", CONFIG.DM_BPLUS_URL)
    // console.log('redirectDMRoom: ', redirectDMRoom);
    // console.log(" handleChannelSelected", redirectDMRoom[0])
    // console.log('redirectMomRoom: ', redirectMomRoom);

    let feature = item.division_feature;
    // TODO: change to feature
    if (
      feature == CONFIG.DM_FEATURE ||
      feature == CONFIG.MOM_FEATURE ||
      item.division_code.includes(";Child")
    ) {
      props.onFeatureChannelSelected(item);
      return;
    }

    // เตรียมไว้ เมื่อมี PentaChannel ประกอบการ follow เป็นช่อง ที่ Chat ไม่ได้ แต่ จะไปเปิด URL แทน
    // if (CONFIG.DM_BPLUS_URL && redirectDMRoom?.length > 0 && item.id) {
    //   if (redirectDMRoom.includes(Number(item.id))) {
    //     let path = CONFIG.DM_BPLUS_URL + item.id + "/?app=Mob";
    //     console.log("pathDM: ", path);
    //     props.onRedirectedChannelSelected(path);
    //     return;
    //   }
    // }

    // if (CONFIG.MOM_BPLUS_URL && redirectMomRoom?.length > 0 && item.id) {
    //   if (redirectMomRoom.includes(Number(item.id))) {
    //     let path = CONFIG.MOMNCHILD_URL + item.id + "/?app=Mob";
    //     console.log("pathMOM: ", path);
    //     props.onRedirectedChannelSelected(path);
    //     return;
    //   }
    // }

    // if (CONFIG.CHILD_BPLUS_URL && redirectChildRoom?.length > 0 && item.id) {
    //   if (redirectChildRoom.includes(Number(item.id))) {
    //     let path = CONFIG.MOMNCHILD_URL + item.id + "/?app=Mob";
    //     console.log("pathChild: ", path);
    //     props.onRedirectedChannelSelected(path);
    //     return;
    //   }
    // }

    let path = "/Chat/" + item.id + "/";
    if (item.chat_channel_id) {
      path = path + item.chat_channel_id + "/";
    }
    props.onChannelSelected(path);
  };

  const getIcon = (feature) => {
    let iconName = "/dm.png";

    // console.log(" getIcon: ", feature);

    switch (feature) {
      case CONFIG.DM_FEATURE:
        iconName = "/dm.png";
        break;
      case CONFIG.MOM_FEATURE:
        iconName = "/mom.png";
        break;
      case CONFIG.CHILD_FEATURE:
        iconName = "/child.png";
        break;
      default:
        break;
    }
    return iconName;
  };

  const handleChannelFollow = (name) => () => {
    let dummy = { division_profile_feature: name };

    props.onFeatureChannelSelected(dummy);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      props.searchChannelList();
    }
  };

  const createChannelList = () => {
    if (!props.userId && !cookiesUserId) {
      return (
        <div className="no-user-id">
          <h2>Error: No User Id</h2>
        </div>
      );
    }
    console.log(" props.channelList", props.channelList);
    console.log("props.unfollowChannelList", props.unfollowChannelList);

    return (
      <>
        {props.channelList?.length > 0 ? (
          <span style={{ fontWeight: "bold", margin: "5px", color: "#828282" }}>
            My Center
          </span>
        ) : null}
        {props.channelList.map((item, index) => {
          let iconName = "";
          let isFeature = false;
          // TODO: change to feature
          // console.log(" getIcon Map ", item)
          if (
            item.division_feature === CONFIG.DM_FEATURE ||
            item.division_feature === CONFIG.MOM_FEATURE ||
            item.division_feature === CONFIG.CHILD_FEATURE
          ) {
            isFeature = true;
            iconName = getIcon(item.division_feature);
          }

          return (
            <List.Item
              key={index}
              className="chat-list-item"
              onClick={handleChannelSelected(item)}
            >
              <Image
                fluid
                src={
                  iconName
                    ? process.env.PUBLIC_URL + iconName
                    : item.image
                    ? item.image
                    : personImg
                }
                circular
                onError={(i) => (i.target.src = personImg)}
              />
              <List.Content verticalAlign={"middle"}>
                <Header size={"small"}>
                  {item.division_name || item.name}
                </Header>
              </List.Content>
              {!isFeature && item.unread_message_count !== 0 && (
                <List.Content verticalAlign={"middle"} floated={"right"}>
                  <Label data-testid="msg-noti" circular color={"red"}>
                    {item.unread_message_count}
                  </Label>
                </List.Content>
              )}
              {/* {isFeature && (
                <List.Content verticalAlign={"middle"} floated={"right"}>
                  <Label
                    data-testid="msg-noti"
                    circular
                    color="violet"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.unFollowDivisionProfile(item.division_profile_id);
                    }}
                  >
                    unfollow
                  </Label>
                </List.Content>
              )} */}
            </List.Item>
          );
        })}
      </>
    );
  };

  const createUnFollowList = () => {
    return (
      <>
        {props.unfollowChannelList?.length > 0 ? (
          <span style={{ fontWeight: "bold", margin: "5px", color: "#828282" }}>
            Recommend
          </span>
        ) : null}
        {props.unfollowChannelList.map((item, index) => {
          console.log(" getIcon unfollow", item);
          let iconName = getIcon(item.feature);

          return (
            <List.Item
              className="chat-list-item"
              // onClick={handleChannelFollow(item.division_name)}
            >
              <div
                style={{
                  widht: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    fluid
                    src={process.env.PUBLIC_URL + iconName}
                    circular
                    // onError={(i) => (i.target.src = personImg)}
                  />
                  <List.Content
                    verticalAlign={"middle"}
                    style={{ paddingLeft: "7px" }}
                  >
                    <Header size={"small"}>{item.division_name}</Header>
                  </List.Content>
                </div>
                <div>
                  <List.Content
                    verticalAlign={"middle"}
                    floated={"right"}
                    style={{ paddingRight: "7px" }}
                  >
                    <Label
                      data-testid="msg-noti"
                      circular
                      color={"blue"}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.followDivisionProfile(item.id);
                      }}
                    >
                      + follow
                    </Label>
                  </List.Content>
                </div>
              </div>
            </List.Item>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (
      props.controller &&
      (apiToken || props.apiToken) &&
      (props.userId || cookiesUserId)
    ) {
      props.loadChannelList();
    }
  }, [props.controller, apiToken, props.apiToken, props.userId, cookiesUserId]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (typeof window.iosNative?.setHospitalCode !== "undefined") {
      window.iosNative.setHospitalCode();
    } else if (typeof window.MobNative?.setHospitalCode !== "undefined") {
      window.MobNative.setHospitalCode();
    } 
  }, []);

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }
  }, [props.apiToken, apiToken]);

  useEffect(() => {
    console.log("saika ~ props.hospitalCode:", props.hospitalCode)
    if (props.hospitalCode) {
      setLoadCheckTelemed(true);
      handleGatOrganization();
    }
  }, [props.hospitalCode]);

  const handleGatOrganization = async () => {
    const [res, error] = await OrganizationListView.list({
      apiToken: props.apiToken,
    });

    if (res) {
      const organizationSelected = (res.items || []).filter(
        (item) => item.code === props.hospitalCode
      );
      if (organizationSelected?.[0]?.id !== null) {
        handleCheckAvailable(organizationSelected?.[0]?.id);
      } else {
        props.gotoNoAvailable()
      }
    } else if (error) {
      props.gotoNoAvailable()
    }
  };

  const handleCheckAvailable = async (organization) => {
    let param = {
      apiToken: props.apiToken,
      organization: organization || -1,
      isTelemed: true,
    };

    const [res, error] = await props.coreManager.getDivision(param);

    if (res) {
      if (res.total > 0) {
        setLoadCheckTelemed(false);
      } else {
        props.gotoNoAvailable()
      }
    } else if (error) {
      props.gotoNoAvailable()
    }
  };


  console.log("Chats props:", props);

  return (
    <div className="chat-page">
      {props.isLoading || loadCheckTelemed ? (
        <div style={{ width: "100%", height: "100%", background: "white" }}>
          <div
            style={{
              display: "flex",
              padding: "10px 20px",
              justifyContent: "center",
            }}
          >
            <Skeleton animation="pulse" height={60} width="100%" />
          </div>

          {new Array(2).fill("").map(() => (
            <>
              <div
                style={{
                  display: "flex",
                  padding: "10px 50px 10px 20px",
                }}
              >
                <Skeleton animation="pulse" height={30} width="40%" />
              </div>
              {new Array(4).fill("").map(() => (
                <div
                  style={{
                    display: "flex",
                    padding: "10px 20px",
                    justifyContent: "center",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    variant="circle"
                    width={40}
                    height={40}
                    style={{ padding: " 10px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      padding: "10px 20px",
                      width: "100%",
                    }}
                  >
                    <Skeleton
                      animation="pulse"
                      height={20}
                      style={{}}
                      width="70%"
                    />
                  </div>
                </div>
              ))}
            </>
          ))}
          </div>) : (
        <Dimmer.Dimmable>
          <Dimmer
            data-testid="chat-list-loader"
            active={props.isLoading}
            inverted
          >
            <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
          </Dimmer>
          <Menu secondary className="chat-list-search">
            <Menu.Item>
              <Input
                ref={inputRef}
                fluid
                icon={"search"}
                loading={props.isLoading}
                iconPosition={"left"}
                placeholder="Search..."
                onChange={props.onSearchChange}
                value={props.chatSearchValue}
                onKeyDown={handleInputKeyPress}
              />
            </Menu.Item>
          </Menu>
          <List
            celled
            selection
            verticalAlign={"middle"}
            style={{ paddingBottom: "10px" }}
          >
            {createChannelList()}
            {createUnFollowList()}
            {/* {createDebugChannelList()} */}
          </List>
        </Dimmer.Dimmable>)}
    </div>
  );
});

ChatList.defaultProps = {
  noApiToken: () => {},
  onChannelSelected: () => {},
  searchChannelList: () => {},
  channelList: [],
};
