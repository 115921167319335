import React, {
  useMemo,
  CSSProperties,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  MutableRefObject,
} from "react";
import moment from "moment";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {formatDateByLanguage, } from "../MobClinicInterface";
// CSS
import {
  AppBar,
  Button as MuiButton,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {
  Dimmer,
  Dropdown,
  DropdownProps,
  Icon,
  Loader,
  Menu,
  Tab,
  TabProps,
} from "semantic-ui-react";

import CancelIcon from "@material-ui/icons/Cancel";
import SwipeableViews from "react-swipeable-views";
import { Bar } from "react-chartjs-2";
import { COLOR } from "./ChildDevelopment";

// Common
import MobHead from "../MobHead";
import SnackMessage from "./SnackMessage";
import { DateTextBox } from "react-lib/apps/common";

// Interface
import {
  BACKEND_DATE_FORMAT,
  Event as MobEvent,
  convertToADDate,
  calculateChildDevelopmentTotalV2,
  formatDate,
  DEVELOPMENT_SKILL,
} from "../MobClinicInterface";

// UI
import PatientPanel from "./PatientPanel";
import { HeaderChart } from "./DMEmotionRestChart";

type BarChartProps = {
  developmentHistory?: any[];
  developmentAgeRange?: any[];
  height?: number;
  selectedPatient?: any;
};

const defaultOptions = {
  legend: {
    display: false,
  },
  tooltips: {},
  // animation: false,
  hover: { mode: null },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          // fontSize: 1,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          max: 100,
          stepSize: 25,
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
};

const ChildDevelopmentBarChart: React.FunctionComponent<BarChartProps> = (
  props
) => {
  const intl = useIntl();
  const [skipIndex, setSkipIndex] = useState<number>(0);
  const labelLimit = 3;
  const stepSize = 3;

  useEffect(() => {
    const monthList = (props.developmentAgeRange || []).map((item: any) => {
      const fromMonth = item.from_year * 12 + item.from_month;
      const toMonth = (item.to_year ?? 0) * 12 + (item.to_month ?? 0);
      return {
        month: Math.max(fromMonth, toMonth),
        name: item.name,
      };
    });

    const a = moment();
    const b = moment(props.selectedPatient?.birthdate);
    const months = a.diff(b, "months");

    const length = Math.ceil(monthList.length / 3);

    const findIndex = new Array(length)
      .fill("")
      .findIndex((_: any, index: number) => {
        const size = index * stepSize;
        const range = monthList.slice(size, labelLimit + size);
        return months >= range[0].month && months <= range[2].month;
      });

    const index = findIndex === -1 ? length - 1 : findIndex;

    setSkipIndex(index);
  }, [props.selectedPatient, props.developmentAgeRange]);

  // Chart options
  const options = useMemo(() => {
    const defaults = defaultOptions;

    defaults.tooltips = {
      // mode: "single",
      callbacks: {
        // title: (tooltipItem: any[], data: any) => {
        //   return data["labels"][tooltipItem[0]["index"]];
        // },
        // label: (tooltipItem: any, data: any) => {
        //   props.onClickLegends?.(tooltipItem, data);
        //   const type: keyof ExerciseTypeDetail =
        //     data["datasets"][tooltipItem.datasetIndex].rawData[
        //       tooltipItem.index
        //     ].type;
        //   // return [`- 19/02/2564, ${EXERCISE_TYPE_DETAIL[type].text}, 08:30`]
        //   return [`${EXERCISE_TYPE_DETAIL[type].text}`];
        // },
      },
    };
    return defaults;
  }, []);

  const chartData = useMemo(() => {
    let labelList: any[] = [];
    let dataSkills: any = {
      [DEVELOPMENT_SKILL.LANGUAGE.key]: [],
      [DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key]: [],
      [DEVELOPMENT_SKILL.FINE_MOTOR.key]: [],
      [DEVELOPMENT_SKILL.GROSS_MOTOR.key]: [],
    };
    let dataTotal: any[] = [];

    props.developmentHistory?.forEach((history: any) => {
      console.log("history?.data?.age_range_name",history?.data?.age_range_name)
      labelList.push(
        history?.data?.age_range_name === "2 month"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "4 month"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "6 month"
            ? `6 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "9 month"
            ? `9 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "12 month"
            ? `12 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name  === "15 month"
            ? `15 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "18 month"
            ? `18 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "2 year"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "2.5 year"
            ? `2.5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "3 year"
            ? `3 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "4 year"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "5 year"
            ? `5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : "",
        );
      let dataLang: number = 0;
      let dataEmo: number = 0;
      let dataFine: number = 0;
      let dataGross: number = 0;
      history?.data?.items?.forEach((item: any) => {
        if (item?.skill === DEVELOPMENT_SKILL.LANGUAGE.key) {
          dataLang = item?.skill_score;
        } else if (item?.skill === DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key) {
          dataEmo = item?.skill_score;
        } else if (item?.skill === DEVELOPMENT_SKILL.FINE_MOTOR.key) {
          dataFine = item?.skill_score;
        } else if (item?.skill === DEVELOPMENT_SKILL.GROSS_MOTOR.key) {
          dataGross = item?.skill_score;
        }
      });
      dataSkills[DEVELOPMENT_SKILL.LANGUAGE.key].push(dataLang);
      dataSkills[DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key].push(dataEmo);
      dataSkills[DEVELOPMENT_SKILL.FINE_MOTOR.key].push(dataFine);
      dataSkills[DEVELOPMENT_SKILL.GROSS_MOTOR.key].push(dataGross);
      dataTotal.push(calculateChildDevelopmentTotalV2(history?.data?.items));
    });
    // setLabelList(labelList);

    return {
      labels: labelList,
      datasets: [
        {
          label: DEVELOPMENT_SKILL.LANGUAGE.label === "การสื่อสาร" ? `${intl.formatMessage({ id: "bplusClinicKey799" })}` : '',
          data: dataSkills[DEVELOPMENT_SKILL.LANGUAGE.key],
          backgroundColor: DEVELOPMENT_SKILL.LANGUAGE.color,
          barThickness: 18,
        },
        {
          label: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.label === "อารมณ์ และสังคม" ? `${intl.formatMessage({ id: "bplusClinicKey800" })}` : '',
          data: dataSkills[DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.key],
          backgroundColor: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.color,
          barThickness: 18,
        },
        {
          label: DEVELOPMENT_SKILL.FINE_MOTOR.label === "กล้ามเนื้อมัดเล็ก" ? `${intl.formatMessage({ id: "bplusClinicKey801" })}` : '',
          data: dataSkills[DEVELOPMENT_SKILL.FINE_MOTOR.key],
          backgroundColor: DEVELOPMENT_SKILL.FINE_MOTOR.color,
          barThickness: 18,
        },
        {
          label: DEVELOPMENT_SKILL.GROSS_MOTOR.label === "กล้ามเนื้อมัดใหญ่" ? `${intl.formatMessage({ id: "bplusClinicKey802" })}` : '',
          data: dataSkills[DEVELOPMENT_SKILL.GROSS_MOTOR.key],
          backgroundColor: DEVELOPMENT_SKILL.GROSS_MOTOR.color,
          barThickness: 18,
        },
        {
          label: `${intl.formatMessage({ id: "bplusClinicKey803" })}`,
          data: dataTotal,
          backgroundColor: COLOR.blue,
          barThickness: 18,
        },
      ],
    };
  }, [props.developmentHistory]);

  const subChartData = useMemo(() => {
    const cloneData = { ...chartData };
    const size = skipIndex * stepSize;

    let sliceArray = (list: any[]) => list.slice(size, labelLimit + size);

    let datasets = (cloneData?.datasets || []).map((item: any) => ({
      ...item,
      data: sliceArray(item.data || []),
    }));

    return {
      ...cloneData,
      labels: sliceArray(cloneData.labels || []),
      datasets,
    };
  }, [chartData, skipIndex]);

  const lastGraph = useMemo(() => {
    const size = skipIndex * stepSize;
    return (
      typeof chartData?.labels?.slice(labelLimit + size)?.[0] === "undefined"
    );
  }, [chartData, skipIndex]);

  const handleClick = (type: "next" | "prev") => {
    // let cloneArray = [...labelList];
    if (type === "next") {
      setSkipIndex(skipIndex + 1);
    } else {
      setSkipIndex(skipIndex - 1);
    }
  };

  return (
    <>
      <HeaderChart
        hideDateRange
        legendStyle={{
          gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
        }}
        legendItem={{
          type: "rect",
          items: [
            {
              name: DEVELOPMENT_SKILL.LANGUAGE.label === "การสื่อสาร" ? `${intl.formatMessage({ id: "bplusClinicKey799" })}` : '',
              color: DEVELOPMENT_SKILL.LANGUAGE.color,
            },
            {
              name: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.label === "อารมณ์ และสังคม" ? `${intl.formatMessage({ id: "bplusClinicKey800" })}` : '',
              color: DEVELOPMENT_SKILL.EMOTIONAL_SOCIAL.color,
            },
            {
              name: DEVELOPMENT_SKILL.FINE_MOTOR.label === "กล้ามเนื้อมัดเล็ก" ? `${intl.formatMessage({ id: "bplusClinicKey801" })}` : '',
              color: DEVELOPMENT_SKILL.FINE_MOTOR.color,
            },
            {
              name: DEVELOPMENT_SKILL.GROSS_MOTOR.label === "กล้ามเนื้อมัดใหญ่" ? `${intl.formatMessage({ id: "bplusClinicKey802" })}` : '',
              color: DEVELOPMENT_SKILL.GROSS_MOTOR.color,
            },
            { name: `${intl.formatMessage({ id: "bplusClinicKey803" })}`, color: COLOR.blue },
          ],
        }}
      />
      <div style={{ marginLeft: "-30px" }}>
        <Bar data={subChartData} options={options} height={props.height} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "-5px 0 30px",
        }}
      >
        <div>
          <MuiButton
            variant="outlined"
            style={{ padding: "8px", borderRadius: "4px 0 0 4px" }}
            onClick={() => handleClick("prev")}
            disabled={!skipIndex}
          >
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.21094 3.30273L5.66797 4.61523V6.03906L0.763672 3.85352V2.72266L5.66797 0.542969V1.9668L2.21094 3.30273ZM8.31641 3.30273L11.7734 4.61523V6.03906L6.86914 3.85352V2.72266L11.7734 0.542969V1.9668L8.31641 3.30273Z"
                fill="#A3A3A3"
              />
            </svg>
          </MuiButton>
        </div>
        <div>
          <MuiButton
            variant="outlined"
            style={{ padding: "8px", borderRadius: "0 4px 4px 0" }}
            onClick={() => handleClick("next")}
            disabled={lastGraph}
          >
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.625 3.27344L0.00390625 1.94336V0.542969L5.06641 2.72266V3.85352L0.00390625 6.0332V4.63281L3.625 3.27344ZM9.87109 3.27344L6.25 1.94336V0.542969L11.3125 2.72266V3.85352L6.25 6.0332V4.63281L9.87109 3.27344Z"
                fill="#A3A3A3"
              />
            </svg>
          </MuiButton>
        </div>
      </div>
    </>
  );
};

export default ChildDevelopmentBarChart;
