import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import {
  Accordion,
  Dimmer,
  Grid,
  Loader,
  Menu,
  Table,
} from "semantic-ui-react";
import PureReactTable from "../../react-lib/component-js/common/PureReactTable";
// UI
import WebPatientPanel from "./WebPatientPanel";
import SnackMessage from "../clinic/SnackMessage";
import { formatUTCtoMoment } from "react-lib/utils/dateUtils";

// Interface
import { SEGMENT_TYPE } from "../ManageInterface";

export const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
};

const DATE_FORMAT = "DD-MM-YYYY";

type PatientDashBoardProps = {
  // function
  onEvent: any;
  setProp: any;
  // data
  loadingStatus?: boolean;
  loadingPatientSegment?:boolean;
  errorMessage: any;
  successMessage: any;
  selectedDivision?: any;
  selectedPatient: any;
  patientSegmentList: any;
  leftMenuSelected?: string;
  dmSelectedTab?: null | number;
  onSearch?: boolean,
  // data WebPatientPanel
  scoreSummary?: any;
  labSummary?: any;
  profileHealth?: any[];
  hidePersonalScoreChart?: boolean;
  hidePersonalLabChart?: boolean;
  hideMomOverall?: boolean;
  hideChildOverall?: boolean;
  menuName?: string;
  childOverallProgress?: any[];
  // config
  hideLeftMenu?: boolean;
  leftMenu?: any[] | null;
};

const PatientDashBoardInitial: PatientDashBoardProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  // data
  loadingStatus: false,
  loadingPatientSegment:false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  selectedPatient: {},
  patientSegmentList: {},
  leftMenuSelected: "patient_segment",
  dmSelectedTab: null,
  onSearch: false,
  // WebPatientPanel
  scoreSummary: {},
  labSummary: {},
  profileHealth: [],
  hidePersonalScoreChart: false,
  hidePersonalLabChart: false,
  hideMomOverall: false,
  hideChildOverall: false,
  menuName: "",
  childOverallProgress: [],
  // config
  hideLeftMenu: false,
  leftMenu: null,
};

const PatientDashBoard: React.FC<PatientDashBoardProps> = (props: any) => {
  const [activeResult, setActiveResult] = useState<string>(
    props.leftMenu?.[0]?.result || ""
  );
  const [activeSubResult, setActiveSubResult] = useState<string>(
    props.leftMenu?.[0]?.subResults[0] || ""
  );
  // Pagination
  const [limit, setLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(0);

  const patientColumn = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 150 },
      { Header: "ชื่อ - นามสกุล", accessor: "fullname", width: 300 },
      { 
        Header: "Birthdate", 
        accessor: "birthdate", 
        width: 140,
        Cell: ({ row }: any = {}) => {
          if (row?.birthdate) {
            return <>{formatUTCtoMoment(row?.birthdate).format(DATE_FORMAT)}</>;
          }
        },  
      },
      { 
        Header: "Last visit date", 
        accessor: "last_visit_date", 
        width: 140,
        Cell: ({ row }: any = {}) => {
          if (row?.last_visit_date) {
            return (
              <>{formatUTCtoMoment(row?.last_visit_date).format(DATE_FORMAT)}</>
            );
          }
        }, 
      },
      {
        Header: "Appointment date",
        accessor: "appointment_date",
        minWidth: 140,
        Cell: ({ row }: any = {}) => {
          if (row?.appointment_date) {
            const appointmentData = row?.appointment_date.split(',')
            var dataText = "";
            for (var i = 0; appointmentData.length > i; i++){
              dataText =
                dataText +
                `${formatUTCtoMoment(appointmentData[i]).format(
                  `${DATE_FORMAT} HH:mm`
                )} , `;
            }
            return (
              <>{dataText}</>
            );
          }
        }, 
      },
    ],
    []
  );

  useEffect(() => {
    if(props.onSearch === true) {
      setActiveSubResult("");
    }
  }, [props.onSearch]);

  useEffect(() => {
    props.setProp("menuName", activeResult);
    if (activeSubResult !== ""){
      if (
        props.leftMenuSelected === "patient_segment" &&
        props.selectedDivision?.division_code !== undefined
      ) {
        props.onEvent({
          message: "handleGetPatientSegmentList",
          params: {
            segment_type: SEGMENT_TYPE.CLINICAL,
            result: `${activeResult} ${activeSubResult}`,
            limit: limit,
            offset: page * limit,
          },
        });
      }
    }
  }, [page, limit, activeResult, activeSubResult, props.selectedDivision]);

  const handleSetActive = (result: string, subResult: string) => {
    setActiveResult(result);
    setActiveSubResult(subResult);
  };

  const handleSelectedPatient = (row: any) => {
    props.onEvent({ message: "handleSelectedPatient", params: row });
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    setPage(pageNo);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setLimit(pageSize);
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid style={{ margin: "-10px" }}>
        {!props.hideLeftMenu && (
          <Grid.Column width={3} style={{ padding: "10px 0px 0px 10px" }}>
            <Accordion as={Menu} vertical fluid>
              {props.leftMenu?.map((item: any, index: number) => {
                return (
                  <Menu.Item
                    key={index}
                    style={{ padding: "10px 0px", fontWeight: "bold" }}
                  >
                    <Accordion.Title
                      active={true}
                      content={item?.result}
                      style={{ padding: "10px" }}
                    />
                    <Accordion.Content
                      active={true}
                      content={
                        <Table selectable style={{ border: "none" }}>
                          <Table.Body>
                            {item.subResults.map(
                              (subResult: string, iindex: number) => {
                                return (
                                  <Table.Row
                                    key={iindex}
                                    active={
                                      `${activeResult} ${activeSubResult}` ===
                                      `${item.result} ${subResult}`
                                    }
                                  >
                                    <Table.Cell
                                      style={{
                                        color: "var(--text-dark-blue)",
                                        padding: "10px 30px",
                                      }}
                                      onClick={() => {
                                        handleSetActive(item.result, subResult);
                                      }}
                                    >
                                      {subResult}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      }
                    />
                  </Menu.Item>
                );
              })}
            </Accordion>
          </Grid.Column>
        )}
        <Grid.Column
          width={!props.hideLeftMenu ? 10 : 13}
          style={{ padding: "10px 0px" }}
        >
          <PureReactTable
            // @ts-ignore
            className="queue-table-custom"
            selectedRow={props.selectedPatient}
            onSelect={handleSelectedPatient}
            data={props.patientSegmentList?.items}
            columns={patientColumn}
            showPagination={true}
            pageSize={limit}
            pages={Math.ceil(props.patientSegmentList?.total / limit)}
            page={page}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            loading={props.loadingPatientSegment}
            manual
          />
        </Grid.Column>
        {props.selectedPatient.hasOwnProperty("proxy_patient") && (
          <Grid.Column width={3} style={{ padding: "0px 10px 0px 0px" }}>
            <WebPatientPanel
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // common data
              loadingStatus={props.loadingStatus}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              selectedPatient={props.selectedPatient}
              dmSelectedTab={props.dmSelectedTab}
              // data
              scoreSummary={props.scoreSummary}
              labSummary={props.labSummary}
              profileHealth={props.profileHealth}
              childOverallProgress={props.childOverallProgress}
              hidePersonalScoreChart={props.hidePersonalScoreChart}
              hidePersonalLabChart={props.hidePersonalLabChart}
              hideMomOverall={props.hideMomOverall}
              hideChildOverall={props.hideChildOverall}
            />
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

PatientDashBoard.defaultProps = PatientDashBoardInitial;

export default React.memo(PatientDashBoard);
