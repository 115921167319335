
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
import ProxyPatientSerializer from '../types/ProxyPatientSerializer_apps_PRX'


const HOST = `${config.API_HOST}`
/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
const ProxyPatientDetail:
  {
    retrieve: ({ pk, params, apiToken, extra }:
      {
        pk: any,
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    update: ({ pk, params, data, apiToken, extra }:
      {
        pk: any,
        params?: any,
        data?: ProxyPatientSerializer,
        apiToken?: any,
        extra?: any,
      }) => any,
    patch: ({ pk, params, data, apiToken, extra }:
      {
        pk: any,
        params?: any,
        data?: ProxyPatientSerializer,
        apiToken?: any,
        extra?: any,
      }) => any,
  } =
{
  retrieve: async ({ pk, params, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/PRX/proxy-patient/${pk}/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/PRX/proxy-patient/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/PRX/proxy-patient/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default ProxyPatientDetail

