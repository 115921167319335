import React, { useEffect, useState, CSSProperties } from "react";
import { Dimmer, Grid, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
// UI
import SnackMessage from "../../clinic/SnackMessage";
import MomWeightGain from "../../clinic/MomWeightGain";
import moment from "moment";

// Interface
import { BACKEND_DATE_FORMAT } from "../../MobClinicInterface";
import { HeaderChart } from "bplus-lib/clinic/DMEmotionRestChart";

const DATE_FORMAT = "DD/MM/YYYY";

const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  } as CSSProperties,
  divDetail: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 20px",
  } as CSSProperties,
};

type WebMomWeightGainProps = {
  onEvent: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  weightGainDetail?: any;
  weightGainList?: any[];
  profileObstretic?: any;
  obstreticRecord?: any;
  weightPrePregnancy?: any;
  weightFilterStartDate?: any;
  weightFilterEndDate?: any;
};

const WebMomWeightGainInitial: WebMomWeightGainProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  weightGainDetail: {},
  weightGainList: [],
  profileObstretic: {},
};

const durationOptions = [
  { key: 1, value: "1", text: "40 weeks" },
  { key: 2, value: "2", text: "1st trimester" },
  { key: 3, value: "3", text: "2nd trimester" },
  { key: 4, value: "4", text: "3th trimester" },
];

const WebMomWeightGain: React.FC<WebMomWeightGainProps> = (props) => {
  const [duration, setDuration] = useState("1");
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  // console.log("profileObstretic?: any;",props.profileObstretic)
  // console.log("profileObstretic?: any;",props.profileObstretic.data.lmp_date)

  useEffect(() => {
    if (props.profileObstretic?.data?.lmp_date) {
      handleChangeDuration(null, { value: "1" });
    } else {
      setStartDate(moment(new Date()));
      setEndDate(moment(new Date()).add("40", "w"));
    }
  }, [props.profileObstretic]);

  useEffect(() => {
    if (startDate && endDate) {
      props.onEvent({
        message: "handleGetWebPregnancyWeight",
        params: {
          startDate: startDate.format(BACKEND_DATE_FORMAT),
          endDate: endDate.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    props.onEvent({ message: "handleGetObstreticRecord" });
    props.onEvent({ message: "handleGetPrePregnancyWeight" });
  }, [props.selectedPatient]);

  const handleChangeDuration = (_e: any, { value }: any) => {
    setDuration(value);
    if (value == "1") {
      let start = moment(props.profileObstretic.data.lmp_date);
      let end = moment(props.profileObstretic.data.lmp_date).add("40", "w");
      setStartDate(start);
      setEndDate(end);
    } else if (value == "2") {
      let start = moment(props.profileObstretic.data.lmp_date);
      let end = moment(props.profileObstretic.data.lmp_date).add("14", "w");
      setStartDate(start);
      setEndDate(end);
    } else if (value == "3") {
      let start = moment(props.profileObstretic.data.lmp_date).add("14", "w");
      let end = moment(props.profileObstretic.data.lmp_date).add("28", "w");
      setStartDate(start);
      setEndDate(end);
    } else if (value == "4") {
      let start = moment(props.profileObstretic.data.lmp_date).add("28", "w");
      let end = moment(props.profileObstretic.data.lmp_date).add("40", "w");
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleChangeStepDateRecord = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(-1, "w");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(1, "w");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="weightgain.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/mom/weightgain.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey537" />
        </span>
      </div>
      <div className="sub-layout">
        <HeaderChart
          startDate={startDate || moment()}
          endDate={endDate || moment()}
          durationOptions={durationOptions}
          selectedDuration={duration}
          onChangeDuration={handleChangeDuration}
          onChangeDateRange={handleChangeStepDateRecord}
          dropdownStyle={{ width: "130px" }}
          // labelStyle={{ padding: "10px 30px 5px 18px" }}
        />
        <MomWeightGain
          hidePatientPanel={true}
          hideFilter={true}
          hideAppbar={true}
          hideWeightStatus={true}
          onEvent={props.onEvent}
          weightGainDetail={props.weightGainDetail}
          weightGainList={props.weightGainList}
          obstreticRecord={props.obstreticRecord}
          weightPrePregnancy={props.weightPrePregnancy}
          useWebMomTable={true}
          isWeb={true}
        />
      </div>
    </div>
  );
};

WebMomWeightGain.defaultProps = WebMomWeightGainInitial;

export default React.memo(WebMomWeightGain);
