import React, { CSSProperties, useEffect, forwardRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import icon_collapse from "./image/icon_collapse.png";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Dialog, Slide } from "@material-ui/core";
import { Grid, Icon, Image, Divider, Button } from "semantic-ui-react";
import Avatar from "@mui/material/Avatar";
import CardLabDetail from "./CardLabDetail";

import "./MainLabResultCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  ligtblue: "#E3EFFFCC",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  button_back: {
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "20px",
    background: COLOR.font_white,
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    color: COLOR.primary,
    margin: "0px 20px",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16: {
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  card_lab: {
    borderRadius: "15px",
    padding: "15px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: "white",
  } as CSSProperties,
};

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

type CardDoctorDetailProps = {
  onEvent: any;
  onClose?: () => any;
  doctorSelected?: any;
  selectedHospital?: any;
} & WrappedComponentProps;

const CardDoctorDetailInitial: Omit<CardDoctorDetailProps, "intl"> = {
  onEvent: () => null,
  onClose: () => null,
  doctorSelected: null,
  selectedHospital: null,
};

const CardDoctorDetail: React.FC<CardDoctorDetailProps> = (props) => {
  const [doctorInfo, setDoctorInfo] = useState<any>({
    fullname: "",
    image: "",
  });

  const [thirtyDays, setThirtyDays] = useState<any | undefined>(undefined);
  const [lastYears, setLastYears] = useState<any | undefined>(undefined);
  const [openCard, setOpenCard] = useState<boolean>(false);
  const [labData, setLabData] = useState<any | undefined>("");

  useEffect(() => {
    if (props.doctorSelected !== null) {
      console.log("saika props.doctorSelected -> ", props.doctorSelected);
      const info = props.doctorSelected;
      setDoctorInfo({ fullname: info.full_name, image: info.image });
      const newThirtyDay: any[] = [];
      const newLastYear: any[] = [];
      props.doctorSelected?.lab?.forEach((item: any) => {
        let toDate = moment();
        const lastYear = toDate
          .clone()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        const thirtyDay = toDate
          .clone()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        const date = moment(item.started).format("YYYY-MM-DD");
        if (moment(date).isAfter(thirtyDay)) {
          newThirtyDay.push({ ...item });
        } else {
          newLastYear.push({ ...item });
        }
      });

      setThirtyDays(newThirtyDay);
      setLastYears(newLastYear);
    } else {
      setLastYears(null);
      setThirtyDays(null);
      setDoctorInfo(null);
    }
  }, [props.doctorSelected]);

  const handleOpenCard = (item: any) => {
    setLabData(item);
    setOpenCard(true);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  return (
    <div>
      <div style={{ position: "absolute" }}>
        <Button
          icon
          size="large"
          onClick={props.onClose}
          style={styles.button_back}
        >
          <Icon name="angle left" />
        </Button>
      </div>
      <div style={{ ...styles.parentbox }}>
        <div style={{ margin: " 45px 0 10px" }}>
          <div style={{ ...styles.title }}>
            <FormattedMessage id="bplusClinicKey937" />
          </div>
          <Divider
            style={{ background: "red", marginTop: "5px", marginBottom: "5px" }}
            variant="middle"
          />
        </div>
      </div>

      {/* Doctor detail */}
      <div style={{ background: COLOR.ligtblue }}>
        <Grid style={{ margin: "0" }}>
          <Grid.Column width={3} style={{ padding: "5px 0 5px 2rem" }}>
            <Avatar
              src={doctorInfo.image}
              style={{ width: "60px", height: "60px" }}
            />
          </Grid.Column>
          <Grid.Column
            width={13}
            style={{
              padding: "5px 1rem",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ ...styles.text_bluebdms_16, margin: "0px 20px" }}>
              {doctorInfo.fullname || ""}
            </div>
          </Grid.Column>
        </Grid>
      </div>
      {thirtyDays?.length > 0 ? (
        <>
          <div style={{ ...styles.text_DarkGray_14, margin: "10px 20px" }}>
            {"Last 30 day"}
          </div>
          {thirtyDays ? (
            thirtyDays.map((item: any, index: any) => (
              <div
                style={styles.card_lab}
                key={index}
                onClick={() => handleOpenCard(item)}
              >
                <Grid style={{ margin: "0px" }}>
                  <Grid.Column width={16} style={{ padding: "0 15px" }}>
                    {/* date time */}
                    <Grid.Row>
                      <div
                        style={{
                          ...styles.text_bluebdms_16,
                          marginBottom: "15px",
                        }}
                      >
                        {moment(item.started).format("DD MMM YYYY, HH:mm") ||
                          "-"}
                      </div>
                    </Grid.Row>
                    {/* hospital name */}
                    <Grid.Row>
                      <div
                        style={{
                          ...styles.text_DarkGray_14,
                          marginBottom: "5px",
                        }}
                      >
                        {item.hospital_name ||
                          props.selectedHospital?.name ||
                          ""}
                      </div>
                    </Grid.Row>
                    {/* division name  */}
                    <Grid.Row>
                      <div style={styles.text_DarkGray_14}>
                        {item.division_name}
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}
      {lastYears?.length > 0 ? (
        <>
          <div style={{ ...styles.text_DarkGray_14, margin: "10px 20px" }}>
            {"Last Year"}
          </div>
          {lastYears ? (
            lastYears.map((item: any, index: any) => (
              <div
                style={styles.card_lab}
                key={index}
                onClick={() => handleOpenCard(item)}
              >
                <Grid style={{ margin: "0px" }}>
                  <Grid.Column width={16} style={{ padding: "0 15px" }}>
                    {/* date time */}
                    <Grid.Row>
                      <div
                        style={{
                          ...styles.text_bluebdms_16,
                          marginBottom: "15px",
                        }}
                      >
                        {moment(item.started).format("DD MMM YYYY, HH:mm") ||
                          "-"}
                      </div>
                    </Grid.Row>
                    {/* hospital name */}
                    <Grid.Row>
                      <div
                        style={{
                          ...styles.text_DarkGray_14,
                          marginBottom: "5px",
                        }}
                      >
                        {item.hospital_name ||
                          props.selectedHospital?.name ||
                          ""}
                      </div>
                    </Grid.Row>
                    {/* division name  */}
                    <Grid.Row>
                      <div style={styles.text_DarkGray_14}>
                        {item.division_name}
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}
      <Dialog
        fullScreen
        open={openCard}
        onClose={() => setOpenCard(false)}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <div style={{ width: "100%" }}>
          <CardLabDetail
            onEvent={props.onEvent}
            onClose={handleCloseCard}
            labData={labData}
            selectedHospital={props.selectedHospital}
          />
        </div>
      </Dialog>
    </div>
  );
};

CardDoctorDetail.defaultProps = CardDoctorDetailInitial;

export default React.memo(injectIntl(CardDoctorDetail));
