import WasmController from "../react-lib/frameworks/WasmController";
import * as MobMedicationI from "./MobMedicationInterface";
import * as FeedInterface from "./native/FeedInterface";
import *  as MobHistoryI from "./MobHistoryinterface"
import *  as MobLABI from "./MobLABInterface"
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";

console.log(" MobMedicationController !!! init ");
if (typeof globalThis !== "undefined" && typeof globalThis.mobile === "undefined") {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobMedicationController extends WasmController<
  MobMedicationI.State,
  MobMedicationI.Event,
  MobMedicationI.Data
> {
  constructor(
    getState: () => MobMedicationI.State,
    setState: (state: MobMedicationI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobMedicationI.DataInitial);
  }

  handleEvent = async (e: MobMedicationI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      case "HandleSetOpenBurger":
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative":
        globalThis.mobile.setBackToNative();
        return;

      case "HandleSetAlarm":
        MobMedicationI.HandleSetAlarm(this, e.params);
        return;

      // Setup
      case "DidMount":
        MobMedicationI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobMedicationI.DidUpdate(this, e.params);
        return;

      case "handleGetMedication":
        MobMedicationI.handleGetMedication(this, e.params);
        return;

      case "GetListHospital":
        SelectHospitalI.GetListHospital(this, e.params);
        return;

      case "HandleSelectedHospital":
        SelectHospitalI.HandleSelectedHospital(this, e.params);
        return;

      case "GetMedAllergy":
        MobHistoryI.GetMedAllergy(this, e.params);
        return;

      case "HandleChangeAllergyTab":
        MobHistoryI.HandleChangeAllergyTab(this, e.params);
        return;

      case "handleDownloadLabResult":
        MobLABI.handleDownloadLabResult(this, e.params);
        return;

      case "handleOpenLink":
        MobMedicationI.handleOpenLink(this, e.params);
        return;

      case "handleUpdateCurrentMed":
        MobMedicationI.handleUpdateCurrentMed(this,e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
