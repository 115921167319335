import WasmController from "../react-lib/frameworks/WasmController";
import * as TeleDesktop from "./TeleDesktopInterface";
import * as FeedInterface from "./native/FeedInterface";

console.log(" TeleDesktopController !!! init ");
if (
  typeof globalThis !== "undefined" &&
  typeof globalThis.mobile === "undefined"
) {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobMedicationController extends WasmController<
  TeleDesktop.State,
  TeleDesktop.Event,
  TeleDesktop.Data
> {
  constructor(
    getState: () => TeleDesktop.State,
    setState: (state: TeleDesktop.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, TeleDesktop.DataInitial);
  }

  handleEvent = async (e: TeleDesktop.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      // Setup
      case "DidMount":
        TeleDesktop.DidMount(this, e.params);
        return;

      case "DidUpdate":
        TeleDesktop.DidUpdate(this, e.params);
        return;

      case "logout":
        TeleDesktop.logout(this);
        return;

      case "HandleGetPatient":
        TeleDesktop.HandleGetPatient(this);
        return;

      case "HandleGetOAuthToken":
        TeleDesktop.HandleGetOAuthToken(this, e.params);
        return;
      
      case "HandleDownloadReceipt": 
        TeleDesktop.HandleDownloadReceipt(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
