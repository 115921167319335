import React, {
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  ChangeEvent,
  useCallback,
  useImperativeHandle,
  Suspense,
} from "react";
// CSS
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import { Button as MuiButton } from "@mui/material";
// Selected
// Input
import LoadingButton from "@mui/lab/LoadingButton";
// DatePicker
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

// Interface
import { Event, State } from "../ManageInterface";
import { PostData, ContentFileType } from "./PostExInterface";

// UI
import PostSortable from "./PostSortable";

import { PromotionData } from "./PromotionExInterface";

const SunEditor = React.lazy(() => import('suneditor-react'));

type PostExProps = {
  // callback
  onEvent?: (e: Event) => any;
} & Pick<
  State,
  | "marketingPostList"
  | "marketingPostIndex"
  | "clinicalSegment"
  | "loadingMainPost"
  | "marketingSegmentList"
  | "marketingPostId"
  | "divisionHasUser"
  | "marketingPostChart"
>;

type PostEditorProps = {
  // data
  detail: PostData | null;
  // callback
  onChange?: (data: Partial<PostData>) => any;
  onSave: (params: {
    id: any;
    callback?: (params: { content_files: ContentFileType[] }) => any;
  }) => any;
} & Pick<
  PostExProps,
  "onEvent" | "clinicalSegment" | "marketingSegmentList" | "divisionHasUser"
>;

type FormByTypeProps = {
  type: "article" | "url" | "picture" | "video" | "promotion";
  detail: PostData | PromotionData | null ;
  componentLabelError?: any;
  errorField?: any;
  handleDelError?: (type:string) => any;
} 
& Pick<PostEditorProps,  "onChange" | "onEvent">


type FormByTypeRef = {
  setContentFile: (data: ContentFileType[]) => void;
};

const useStyles = makeStyles((theme) => ({
  banners: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "25px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .MuiButton-root.MuiButton-contained": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));
/* TODO --------------------- FormByType -------------------- */
const PostFormByType = React.forwardRef<any, FormByTypeProps>((props, ref) => {
  const [imageUrl, setImageUrl] = useState<string | null>("");
  const [youtubeUrl, setYoutubeUrl] = useState<string | null>("");
  const [contentFile, setContentFile] = useState<ContentFileType[]>([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const bannersRef = useRef() as MutableRefObject<HTMLInputElement>;
  const sunEditorRef = useRef() as MutableRefObject<any>;

  const classes = useStyles();

  useImperativeHandle<FormByTypeRef, FormByTypeRef>(ref, () => ({
    setContentFile: (data) => setContentFile(data),
  }));

  useEffect(() => {
    setContentFile([]);
  }, [props.detail?.id]);

  useEffect(() => {
    setImageUrl(props.detail?.url || null);
  }, [props.detail?.url]);

  useEffect(() => {
    setYoutubeUrl(props.detail?.youtube_url || null);
  }, [props.detail?.youtube_url]);

  useEffect(() => {
    props.onChange?.({
      extra_field: {
        ...(props.detail?.extra_field || {}),
        content_files: contentFile,
      },
    });
  }, [contentFile]);

  const handleChange = useCallback((content: string) => {
    let contentCheck = content
    if (content === "<div><br></div>") {
      contentCheck = ""
    }
    props.onChange?.({ description: contentCheck });
  }, []);

  const handleChangeBanners = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      props.onChange?.({
        extra_field: {
          ...(props.detail?.extra_field || {}),
          banners_file: file,
          banners: file.name,
        },
      });
    }
  };

  const handleClickBrowse = () => {
    props.handleDelError?.("banners")
    bannersRef.current?.click?.();
  };

  const handleImageUpload = (
    targetImgElement: HTMLImageElement,
    index: number,
    state: string,
    imageInfo: any,
    remainingFilesCount: number
  ) => {
    setContentFile((content) =>
      handleSetContentFile(content, index, state, imageInfo)
    );
  };

  const handleSetContentFile = (
    content: any[],
    index: number,
    state: string,
    imageInfo: any
  ) => {
    const findFile = (content || []).find(
      (item) => item?.index === index && item?.src === imageInfo?.src
    );

    if (!!findFile) {
      return content;
    }

    if (
      ["delete", "update"].includes(state) &&
      sunEditorRef?.current?.editor?.getContents?.()
    ) {
      handleChange(sunEditorRef.current.editor.getContents());
    }

    const data: any = {
      index,
      name: imageInfo?.name,
      src: imageInfo?.src,
      status: state,
    };

    if (imageInfo?.src.includes("/9j/4AAQ")) {
      data.file = dataURLtoFile(imageInfo.src, "image.jpg");
    } else if (imageInfo?.src.includes("iVBORw0KGg")) {
      data.file = dataURLtoFile(imageInfo.src, "image.png");
    }

    return [...content, data];
  };

  // Utils
  const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = globalThis.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmitUrl = () => {
    setIsLoadingSubmit(true);
    props.onEvent?.({
      message: "HandleSubmitUrl",
      params: {
        link: imageUrl,
        title: props.detail?.title,
        callback: () => {
          setIsLoadingSubmit(false);
        },
      },
    });
  };

  return (
    <>
      {props.type === "article" && (
        <>
          <div className={classes.banners}>
            <TextField
              id="banners-required"
              {...(props.errorField?.banners) ? {error:true} : {required:true}}
              label={props.errorField?.banners ? props.componentLabelError("Banners (350 x 450 px)") : "Banners (350 x 450 px)"}
              // label="Banners (350 x 450 px)"
              value={
                props.detail?.extra_field?.banners ||
                props.detail?.banners ||
                ""
              }
              InputProps={{
                disabled: true,
                style:{
                  ...(props.errorField?.banners && {border: "1px solid red"})
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "65%" }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              className="PostButton-browse"
              onClick={handleClickBrowse}
            >
              Browse
            </MuiButton>
          </div>

          <input
            ref={bannersRef}
            type="file"
            accept="image/*"
            id="file"
            onChange={handleChangeBanners}
            hidden
          />

          {/* <TextField
            required
            id="description-required"
            label="Description"
            value={props.detail?.description}
            onChange={(e: any) => {
              props.onChange?.({ description: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
          /> */}
          <div className="ArticleDesc-containerEditor">
            <label className="EditorLabel">{props.errorField?.description ?props.componentLabelError("Description") : "Description *"}</label>
            <Suspense fallback={<div>Loading...</div>}>
              <SunEditor
                ref={sunEditorRef}
                autoFocus={false}
                // plugin=""
                key={"sun-editor" + props.detail?.id}
                setDefaultStyle= {`font-size: 1.125rem; color: rgb(61, 61, 61); margin-top: 10px; ${props.errorField?.description && "border: 1px solid red;"}`}
                onImageUpload={handleImageUpload}
                setContents={props.detail?.description || ""}
                onChange={handleChange}
                setOptions={{
                  buttonList: [
                    ["formatBlock"],
                    ["blockquote"],
                    ["bold", "underline", "italic", "strike"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list"],
                    ["outdent", "indent"],
                    ["horizontalRule", "image"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  minHeight: "200px",
                  showPathLabel: false,
                }}
              />
            </Suspense>
          </div>
        </>
      )}

      {props.type === "url" && (
        <div className={classes.banners}>
          <TextField
            {...(props.errorField?.url) ? {error:true} : {required:true}}
            id="url-required"
            label={props.errorField?.url ?props.componentLabelError("Url") : "Url"}
            disabled={isLoadingSubmit}
            value={imageUrl}
            onChange={(e: any) => {
              setImageUrl(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "90%" }}
          />
          <LoadingButton
            className="PostButton-browse"
            color="primary"
            loading={isLoadingSubmit}
            // loadingPosition="start"
            variant={isLoadingSubmit ? "outlined" : "contained"}
            onClick={handleSubmitUrl}
          >
            Submit
          </LoadingButton>
        </div>
      )}

      {props.type === "picture" && (
        <>
          <TextField
            {...(props.errorField?.short_description) ? {error:true} : {required:true}}
            label={props.errorField?.short_description ?props.componentLabelError("Short Description") : "Short Description"}
            id="short-description-required"
            value={props.detail?.short_description}
            onChange={(e: any) => {
              props.handleDelError?.("short_description")
              props.onChange?.({ short_description: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <PostSortable
            key={"sortable-" + props.detail?.id}
            detail={props.detail}
            onChange={props.onChange}
          />
        </>
      )}

      {props.type === "video" && (
        <>
          <div className={classes.banners}>
            <TextField
              {...(props.errorField?.youtube_url) ? {error:true} : {required:true}}
              label={props.errorField?.youtube_url ?props.componentLabelError("Youtube url") : "Youtube url"}
              id="youtube-url-required"
              value={youtubeUrl}
              onChange={(e: any) => {
                props.handleDelError?.("youtube_url")
                setYoutubeUrl(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "90%" }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              className="PostButton-browse"
              onClick={() => {
                props.onChange?.({ youtube_url: youtubeUrl });
              }}
            >
              Submit
            </MuiButton>
          </div>

          <TextField
            {...(props.errorField?.short_description) ? {error:true} : {required:true}}
            label={props.errorField?.short_description ?props.componentLabelError("Short Description") : "Short Description"}
            id="short-description-required"
            value={props.detail?.short_description}
            onChange={(e: any) => {
              props.handleDelError?.("short_description")
              props.onChange?.({ short_description: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={3}
          />
        </>
      )}

      {props.type === "promotion" && (
        <>
          <div className="ArticleDesc-containerEditor">
          <label className="EditorLabel">{props.errorField?.description ?props.componentLabelError("Description") : "Description *"}</label>
  
            <Suspense fallback={<div>Loading...</div>}>
              <SunEditor
                ref={sunEditorRef}
                autoFocus={false}
                // plugin=""
                key={"sun-editor" + props.detail?.id}
                setDefaultStyle= {`font-size: 1.125rem; color: rgb(61, 61, 61); margin-top: 10px; ${props.errorField?.description && "border: 1px solid red;"}`}
                onImageUpload={handleImageUpload}
                setContents={props.detail?.description || ""}
                onChange={handleChange}
                setOptions={{
                  buttonList: [
                    ["formatBlock"],
                    ["blockquote"],
                    ["bold", "underline", "italic", "strike"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list"],
                    ["outdent", "indent"],
                    ["horizontalRule", "image"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  minHeight: "200px",
                  showPathLabel: false,
                  imageUploadSizeLimit: 500000
                }}
              />
            </Suspense>
          </div>
        </>
      )}
    </>
  );
});

export default PostFormByType;
