import WasmController from "../react-lib/frameworks/WasmController";
import * as MobLABI from "./MobLABInterface";
import * as FeedInterface from "./native/FeedInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";

if (
  typeof globalThis !== "undefined" &&
  typeof globalThis.mobile === "undefined"
) {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobMedicationController extends WasmController<
  MobLABI.State,
  MobLABI.Event,
  MobLABI.Data
> {
  constructor(
    getState: () => MobLABI.State,
    setState: (state: MobLABI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobLABI.DataInitial);
  }

  handleEvent = async (e: MobLABI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      case "HandleSetOpenBurger":
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative":
        globalThis.mobile.setBackToNative();
        return;

      // Setup
      case "DidMount":
        MobLABI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobLABI.DidUpdate(this, e.params);
        return;

      case "handleGetLabParent":
        MobLABI.handleGetLabParent(this, e.params);
        return;
      
      case "HandleLabResultByEncounter":
        MobLABI.HandleLabResultByEncounter(this,e.params);
        return

      case "handleGetChildLabResult":
        MobLABI.handleGetChildLabResult(this, e.params);
        return;

      case "handleDownloadLabResult":
        MobLABI.handleDownloadLabResult(this, e.params);
        // if (globalThis.mobile?.downloadMedCert) {
        //   globalThis.mobile.downloadMedCert(e.params.url);
        // }
        return;
        
      case "GetListHospital":
        SelectHospitalI.GetListHospital(this, e.params);
        return;

      case "HandleSelectedHospital":
        SelectHospitalI.HandleSelectedHospital(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
