import UserService from "./UserService";
import { to, setStateAsync, formatDateToYYYYMMDD } from "../react-lib/utils";

export default class UserManager {
    getUserProfile = async ({apiToken}) => {
        let headers = {}
        let params = {};
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const userService = new UserService();
        const [error, response] = await to(userService.getUserProfile({ headers, params }));
        return [response, error];
    }

    getMyProfile = async ({apiToken}) => {
        let headers = {}
        let params = {};
        if (apiToken) {
            headers.Authorization = `Token ${apiToken.apiToken}`;
        }
        const userService = new UserService();
        const [error, response] = await to(userService.getMyProfile({ headers, params }));
        return [response, error];
    }

    updateUserProfile = async ({apiToken, firstName, lastName, dob, phone}) => {
        let headers = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        let data = {
            first_name: firstName,
            last_name: lastName,
            phone_no: phone,
        }
        if (dob) {
            data.dob = formatDateToYYYYMMDD(dob);
        }
        const userService = new UserService();
        const [error, response] = await to(userService.patchUserProfile({headers, data }));
        return [response, error];
    }
}