import React, { useMemo, useState, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { useLocation, useHistory } from "react-router";

// UX
import DoctorBanner from "./DoctorBanner";
import CardRadioList from "./CardRadioList";
import { DLLocationType } from "./CardDoctorList";

// Types
import { AppointmentDoctorType } from "./Types";

// Interface
import { Event } from "../MobSmartAppointmentInterface";

// Types
type SelectClinicProps = {
  onEvent: (e: Event) => any;
};

type SCLocationType = {
  appointmentLocation?: "online" | "on-site";
  doctor: AppointmentDoctorType;
  storedState?: {
    division?: Record<string, any>;
  };
} & DLLocationType;

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  bg: "rgba(249, 249, 249, 1)",
  very_light_blue: "rgba(1, 71, 163, 0.03)",
  primary: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    "& .button-submit-bottom": {
      position: "fixed",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, -50%)",
      paddingBottom: "0.75rem",
      width: "100%",
    },
  },
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: COLORS.grey,
    margin: "0.25rem 0 1rem",
  },
  description: {
    display: "flex",
    backgroundColor: COLORS.bg,
    padding: "10px 16px",
    borderRadius: "8px",
    minHeight: "46px",
    color: COLORS.grey,
    alignItems: "center",
  },
}));

const SelectClinic = (props: SelectClinicProps) => {
  const classes = useStyles();
  const location = useLocation<SCLocationType>();
  const history = useHistory();

  const [selectedClinicId, setSelectedClinicId] = useState<any>(null);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const specialtyName = useMemo(() => {
    return locState.doctor?.specialties || "";
  }, [locState.doctor]);

  const divisions = useMemo((): any[] => {
    return (locState.doctor?.divisions || []).map((division) => ({
      id: division.division_id,
      name: division.division_name,
      price: division.doctor_fee,
      is_telemed: !!division.is_telemed,
      division_code: division.division_code,
      division_type: division.division_type
    })).filter((item:any)=> locState.appointmentLocation === "online" ? item.is_telemed : true )

  }, [locState.doctor]);

  // Effect
  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      setSelectedClinicId(storedState.division?.id);
    }
  }, [locState.storedState]);

  // Handler
  const handleConfirm = () => {
    const division = divisions.find((item) => item.id === selectedClinicId);

    props.onEvent({
      message: "HandleDoctorAppointmentAction",
      params: {
        history,
        doctor: locState.doctor || {},
        division,
        storedState: { division },
      },
    });
  };

  return (
    <DoctorBanner
      titleName={locState.doctor?.full_name}
      hospitalName={locState.doctor?.hospital_name}
      headerImage={locState.doctor?.image}
      specialtyName={specialtyName}
      appLoc={locState.appointmentLocation}
    >
      <div className={classes.screen}>
        <div className={classes.title}>เลือกศูนย์และคลินิก</div>

        <CardRadioList
          items={divisions}
          selected={selectedClinicId}
          appointmentType={locState.appointmentLocation}
          // callback
          onSelect={setSelectedClinicId}
        />

        {locState.appointmentLocation === "on-site" && (
          <div className={classes.description}>
            <div>**ค่าแพทย์ขึ้นอยู่กับโรคและคำวินิจฉัยของแพทย์</div>
          </div>
        )}

        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            disabled={!selectedClinicId}
            onClick={handleConfirm}
          >
            ถัดไป
          </MuiButton>
        </div>
      </div>
    </DoctorBanner>
  );
};

export default React.memo(SelectClinic);
