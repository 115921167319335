import React, { ReactElement, useMemo } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// UX
import CardCenterGroupContent from "./CardCenterGroupContent";

// Interface
import { CenterGroupType } from "./Types";
import ItemNotFound from "./ItemNotFound";

export type CardCenterGroupListProps = {
  items: CenterGroupType[];
  selected?: number;
  loading?: boolean;
  noResultsMessage?: ReactElement;
  // config
  hideShadow?: boolean;
  // callback
  onSelect?: (selected: Record<string, any>) => any;
};

// Images
const IMAGES = {
  notfound: "/images/Appointment/no-information-search.png",
};

// Styles
const useStyles = makeStyles((theme) => ({
  group_card: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "0.85rem",
  },
}));

const CardCenterGroupList = (props: CardCenterGroupListProps) => {
  const classes = useStyles();

  // Memo
  const items = useMemo(() => {
    const skeletons = Array(8)
      .fill("")
      .map((_, index) => ({ id: index } as any));

    return props.loading ? skeletons : props.items;
  }, [props.loading, props.items]);

  if (props.loading) {
    return (
      <div className={classes.group_card}>
        {items.map((item) => (
          <CardCenterGroupContent
            key={"card-center-group-" + item.id}
            loading={true}
            hideShadow={true}
          />
        ))}
      </div>
    );
  } else if (items.length) {
    return (
      <div className={classes.group_card}>
        {items.map((item) => (
          <CardCenterGroupContent
            key={"card-center-group-" + item.id}
            data={item}
            active={item.id === props.selected}
            loading={props.loading}
            // config
            hideShadow={props.hideShadow}
            // callback
            onSelect={props.onSelect}
          />
        ))}
      </div>
    );
  } else {
    return props.noResultsMessage ? (
      <ItemNotFound
        img={IMAGES.notfound}
        imgSize="6rem"
        subTitle={props.noResultsMessage}
        style={{ height: "calc(50vh)" }}
      />
    ) : null;
  }
};

export default React.memo(CardCenterGroupList);
