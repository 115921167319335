import React, {
  CSSProperties,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
//css
import { Grid, Image, Dimmer, Loader } from "semantic-ui-react";
import { AppBar } from "@material-ui/core";

import { FormattedMessage } from "react-intl";
import moment from "moment";

// Interface
import { PostData } from "../post/PostExInterface";
import { PromotionData } from "../post/PromotionExInterface";
import { Event } from "../MobFeedInterface";

// UI
import MobHeadApp from "../appointment/MobHeadApp";
import {
  getStatusActive,
  ActionButton,
  OnClickType,
  loadPlayer,
  getYoutubeSrc,
  OnClickParams,
} from "./MobContentDetail";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

type MobContentFullProps = {
  onEvent?: (e: Event) => any;
  // data
  marketingContent: PostData & PromotionData;
  myProfileDetail?: any;
  language?: "en-US" | "th-TH";
  match?: {
    isExact: boolean;
    params: { id?: string };
    path: string;
    url: string;
  };
  loadingMainFeed?: boolean;
  shareContentTo?: string;
  apiToken?: string;
  // callback
  onClose: () => any;
  onButtonClick?: OnClickType;
  // config
  isPreview?: boolean;
  isTransition?: boolean;
  contentType: "post" | "promotion";
  firebaseIsAnonymous?: boolean;
};

type CardArticleProps = {
  image: string | null;
  title: string;
  description: string;
};

const IMAGES = {
  ribbon: "/images/expansion/Save.png",
  my_bplus: "/images/expansion/logo_48.png",
  like: "/images/expansion/Like.png",
  follow: "/images/expansion/Follow.png",
  share: "/images/expansion/Share.png",
  like_active: "/images/expansion/like-active.png",
  ribbon_active: "/images/expansion/save-outline-active.png",
  follow_active: "/images/expansion/follow-active.png",
  time: "/images/Feed/time-icon.png",
  price_tag: "/images/Feed/pricetag-outline.png",
};

const styles = {
  imgDescriptionFull: {
    width: "100%",
  } as CSSProperties,
  imgPromotion: {
    width: "100%",
    height: "100%",
  } as CSSProperties,
  gridColumnTopLikeFull: {
    // height: "35px",
    position: "fixed",
    background: "#FFFFFF",
    borderTop: "1px solid #E9E9E9",
    borderBottom: "1px solid #E9E9E9",
    textAlign: "center",
  } as CSSProperties,
  textHeaderHospital: {
    color: "#0147A3",
    fontWeight: 700,
    fontSize: "18px",
    padding: "10px 0px 0px 10px",
  } as CSSProperties,
  textHeaderFull: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "18px",
    textIndent: "1.5rem",
  } as CSSProperties,
  textMakeAppointment: {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "18px",
  } as CSSProperties,
  textContent: {
    color: "#9F9F9F",
    fontSize: "14px",
    padding: "7px 2px",
  } as CSSProperties,
  textContentFull: {
    color: "#3d3d3d",
    fontSize: "1.125rem",
    whiteSpace: "break-spaces",
    lineHeight: "26px",
  } as CSSProperties,
  imagIcon: {
    width: "1.4375rem",
    height: "1.4375rem",
    display: "inline",
    marginRight: "8px",
    paddingBottom: "2px",
  } as CSSProperties,
  imagIconSave: {
    width: "1.0313rem",
    height: "1.4375rem",
    display: "inline",
    marginRight: "8px",
    paddingBottom: "2px",
  } as CSSProperties,
  buttonBack: {
    width: "38px",
    height: "35px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    border: "0px",
    alignItems: "center",
  } as CSSProperties,
  buttonMakeAppointment: {
    width: "325px",
    height: "54px",
    background: "#0147A3",
    borderRadius: "10px",
    border: "0px",
    alignItems: "center",
  } as CSSProperties,
  priceLabel: {
    color: "var(--blue-bdms-color)",
    fontWeight: "bold",
    marginLeft: "0.75rem",
  },
};

/* TODO ------------------- MobContentFull ------------------- */
const MobContentFull: React.FC<MobContentFullProps> = (props) => {
  const [, setLoadScreenSuccess] = useState<boolean>(false);
  const [isShareMode, setIsShareMode] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);

  const appBarRef = useRef() as MutableRefObject<HTMLDivElement>;
  const iframeRef = useRef() as MutableRefObject<any>;
  const playerRef = useRef() as MutableRefObject<any>;
  const prevPlayerRef = useRef() as MutableRefObject<any>;

  const history = useHistory();

  const prevPlayerState = useMemo(() => {
    return (props.marketingContent as any).playerState;
  }, [props.marketingContent]);

  useEffect(() => {
    prevPlayerRef.current = (props.marketingContent as any).player;

    if (
      iframeRef.current &&
      prevPlayerRef.current &&
      props.marketingContent?.id
    ) {
      loadPlayer({
        id: props.marketingContent?.id,
        onYouTubePlayerAPIReady: handleYouTubePlayer,
      });
    }
  }, []);

  useEffect(() => {
    if (props.match?.params?.id) {
      props.onEvent?.({
        message: "GetListMarketingContent",
        params: { id: props.match?.params?.id, contentType: props.contentType },
      });
    }
  }, [props.match?.params?.id]);

  useEffect(() => {
    if (props.shareContentTo && isShareMode && props.marketingContent?.id) {
      setIsShareMode(false);

      handleButtonClick({
        id: props.marketingContent.id || null,
        active: false,
        type: "share",
        content_type: props.marketingContent.type,
        onSuccess: () => {},
      });
    }
  }, [props.shareContentTo]);

  const youtubeSrc = useMemo(() => {
    return getYoutubeSrc(
      props.marketingContent?.youtube_url || "",
      prevPlayerState === 5
    );
  }, [props.marketingContent?.youtube_url]);

  const priceDetail = useMemo(() => {
    const data = props.marketingContent;

    const getDate = (key: keyof (PostData & PromotionData)) => {
      return !data[key] || data[key] === "NaT" ? "" : data[key];
    };

    const startDate = getDate("available_start_date");
    const endDate = getDate("available_end_date");

    return {
      available_start_date: startDate,
      available_end_date: endDate,
      hideDate: !startDate && !endDate,
      showDateHyphen: startDate && endDate,
      minprice: data.minprice,
      maxprice: data.maxprice,
      hidePrice: !data.minprice && !data.maxprice,
      showPriceHyphen: data.minprice && data.maxprice,
    };
  }, [props.marketingContent]);

  const isPriceEmpty = useMemo(() => {
    return (
      !priceDetail.available_start_date &&
      !priceDetail.available_end_date &&
      !priceDetail.minprice &&
      !priceDetail.maxprice
    );
  }, [priceDetail]);

  const statusActive = useCallback(() => {
    return getStatusActive(
      props.marketingContent,
      props.myProfileDetail?.patient
    );
  }, [props.marketingContent, props.myProfileDetail?.patient]);

  const handleYouTubePlayer = async (id: string) => {
    let player = new YT.Player(`iframe-full-${id}`, {
      events: {
        // call this function when player is ready to use
        onReady: () => handleReady(player),
        onStateChange: () => {
          if (player?.getPlayerState?.() === -1 && prevPlayerState === 5) {
            player.seekTo(0);
          }
        },
      },
    });
  };

  const handleReady = (player: any) => {
    playerRef.current = player;

    console.log(prevPlayerRef);
    const currentTime = prevPlayerRef.current?.getCurrentTime?.();

    if (currentTime) {
      player.seekTo(currentTime);
    }

    if (prevPlayerState === 5) {
      handlePauseVideo(player, -1);
    }

    if (![1, 5].includes(prevPlayerState)) {
      handlePauseVideo(player, 1);
    }
  };

  const handlePauseVideo = (player: any, status: number) => {
    var playerInterVal = setInterval(() => {
      if (player?.getPlayerState?.() === status) {
        player.pauseVideo?.();
        clearInterval(playerInterVal);
      }
    });
  };

  const handleMakeAppointment = () => {
    if (!props.isPreview) {
      props.onEvent?.({
        message: "HandleMakeAppointmentContent",
        params: {
          history,
          content: props.marketingContent,
        },
      });
    }
  };

  const handleBuy = () => {
    if (!props.isPreview) {
      history.push(`/?app=IH`);
      globalThis.history.go();
    }
  };

  const handleClose = () => {
    if (prevPlayerRef?.current) {
      const currentTime = playerRef.current?.getCurrentTime?.();
      const prev = prevPlayerRef.current;

      if (currentTime) {
        prev.seekTo(currentTime);
      }

      if (playerRef.current.getPlayerState() === 1) {
        prev.playVideo();

        if (Math.abs(prev.getCurrentTime() - currentTime) >= 5) {
          var playerInterVal = setInterval(() => {
            if (prev?.getPlayerState?.() === 1) {
              prev.seekTo(currentTime);
              clearInterval(playerInterVal);
            }
          });
        }
      }
    }

    props.onClose?.();
  };

  const handleButtonClick = (params: OnClickParams) => {
    if (params.type === "share" && params.id && !props.shareContentTo) {
      setIsShareMode(true);

      return props.onEvent?.({
        message: "HandleShareContent",
        params: { data: props.marketingContent },
      });
    }

    props.onButtonClick?.(params);
  };

  return (
    <>
      <AppBar
        ref={appBarRef}
        position={props.isPreview ? "relative" : "fixed"}
        style={{ backgroundColor: "white" }}
        elevation={0.0}
      >
        <MobHeadApp
          title={
            props.marketingContent.clinic?.[
              props.language?.includes("th") ? "name" : "name_en"
            ] || ""
          }
          leftIconClick={handleClose}
          subTitle={
            props.marketingContent.hospital?.[
              props.language?.includes("th") ? "name" : "name_en"
            ] || ""
          }
          subTitleStyle={{
            fontSize: "1.2188rem",
            color: "var(--blue-bdms-color)",
            width: "105%",
            marginLeft: "-1%",
          }}
          barStyle={{
            alignItems: props.marketingContent.clinic?.name
              ? "baseline"
              : "center",
          }}
          titleStyle={{
            color: "#6a93ca",
            fontSize: "1.2188rem",
            width: "105%",
            marginLeft: "-1%",
          }}
        />
      </AppBar>

      <div
        ref={(instance) => {
          if (instance) {
            setLoadScreenSuccess(true);
          }
        }}
        style={{
          overflowX: "hidden", // Safari in Mobile
          backgroundColor: "white",
          ...(!props.isPreview && {
            height: `calc(100vh - ${appBarRef.current?.offsetHeight || 0}px)`,
            marginTop: `${appBarRef.current?.offsetHeight || 0}px`,
          }),
        }}
      >
        { !props.firebaseIsAnonymous ?
            <div
            style={{
              margin: props.isPreview
                ? ""
                : isPriceEmpty
                ? "0 0 60px 0"
                : "0px 0 37px 0",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Grid
              columns="equal"
              divided
              inverted
              padded
              style={{
                ...styles.gridColumnTopLikeFull,
                position: props.isPreview ? "relative" : "fixed",
              }}
            >
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column style={{ padding: "0px" }}>
                  <ActionButton
                    id={props.marketingContent.id || ""}
                    active={statusActive().like}
                    onClick={handleButtonClick}
                    type="like"
                    style={styles.imagIcon}
                    textStyle={styles.textContent}
                    label="Like"
                    icon={IMAGES.like}
                    iconActive={IMAGES.like_active}
                    contentType={props.marketingContent.type}
                    disabled={!props.apiToken}
                  />
                </Grid.Column>
                <Grid.Column style={{ padding: "0px" }}>
                  <ActionButton
                    id={props.marketingContent.id || ""}
                    active={statusActive().follow}
                    onClick={handleButtonClick}
                    type="follow"
                    style={{ ...styles.imagIcon, padding: "2px 0 0 0" }}
                    textStyle={styles.textContent}
                    label="Follow"
                    icon={IMAGES.follow}
                    iconActive={IMAGES.follow_active}
                    contentType={props.marketingContent.type}
                    disabled={!props.apiToken}
                  />
                </Grid.Column>
                <Grid.Column style={{ padding: "0px" }}>
                  <ActionButton
                    id={props.marketingContent.id || ""}
                    active={true}
                    onClick={handleButtonClick}
                    type="share"
                    style={styles.imagIcon}
                    textStyle={styles.textContent}
                    label="Share"
                    icon={IMAGES.share}
                    iconActive={IMAGES.share}
                    contentType={props.marketingContent.type}
                    // disabled={!props.apiToken}
                  />
                </Grid.Column>
                <Grid.Column style={{ padding: "0px" }}>
                  <ActionButton
                    id={props.marketingContent.id || ""}
                    active={statusActive().saved}
                    onClick={handleButtonClick}
                    type="save"
                    style={styles.imagIconSave}
                    textStyle={styles.textContent}
                    label="Save"
                    icon={IMAGES.ribbon}
                    iconActive={IMAGES.ribbon_active}
                    contentType={props.marketingContent.type}
                    disabled={!props.apiToken}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div> : null
        }
    

        {props.marketingContent?.type === "article" ? (
          <CardArticle
            image={props.marketingContent.banners}
            title={props.marketingContent.title}
            description={props.marketingContent.description}
          />
        ) : props.marketingContent?.type === "picture" ? (
          <div>
            <Grid.Column style={{ margin: "20px 20px" }}>
              <div style={styles.textContentFull}>
                {props.marketingContent.short_description}
              </div>
            </Grid.Column>
            {props.marketingContent.images?.map((item: any, index: number) => {
              return (
                <Grid.Column
                  key={"item-image" + index}
                  style={{
                    margin: "10px 20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={item}
                    size="medium"
                    style={styles.imgDescriptionFull}
                  />
                </Grid.Column>
              );
            })}
            {/* <Image src={props.marketingContent.image} size='medium' style={styles.imgDescriptionFull} /> */}
          </div>
        ) : props.marketingContent?.type === "video" ? (
          <div>
            <Grid.Column style={{ margin: "20px 25px 0px 25px" }}>
              <div style={styles.textContentFull}>
                {props.marketingContent.short_description}
              </div>
            </Grid.Column>
            <Grid.Column
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <iframe
                ref={iframeRef}
                id={"iframe-full-" + props.marketingContent.id || ""}
                title={`youtube-${props.marketingContent.id}`}
                width="100%"
                height="200"
                src={youtubeSrc}
                allowFullScreen
                frameBorder={0}
                allow={prevPlayerState === 5 ? "" : "autoplay"}
              ></iframe>
            </Grid.Column>
          </div>
        ) : props.marketingContent?.type === "url" ? (
          <CardArticle
            image={props.marketingContent.banners}
            title={props.marketingContent.title}
            description={props.marketingContent.description}
          />
        ) : props.marketingContent?.type === undefined ? (
          <div>
            {!isPriceEmpty && <DisplayPromotionPrice data={priceDetail} />}
            <Grid.Column style={{ margin: "20px 25px 20px 25px" }}>
              {/* <div style={styles.textContentFull}>
                {props.marketingContent.description}
              </div> */}
              <div
                style={styles.textContentFull}
                className="ArticleDesc-container"
                dangerouslySetInnerHTML={{
                  __html: props.marketingContent.description || "",
                }}
              ></div>
            </Grid.Column>
            <Grid.Column>
              <Image
                src={props.marketingContent.banners}
                size="medium"
                style={styles.imgPromotion}
              />
            </Grid.Column>
          </div>
        ) : null}
        <div style={{ paddingTop: "80px" }}></div>
      </div>

      <div
        className="ContentButton-footer"
        style={{
          left: "50%",
          position: "fixed",
          bottom: "-10px",
          transform: "translate(-50%, -50%)",
        }}
      >
        {props.marketingContent.id &&
        props.marketingContent?.type === undefined ? (
          <button style={styles.buttonMakeAppointment} onClick={handleBuy}>
            <div style={styles.textMakeAppointment}>Contact Us</div>
          </button>
        ) : props.marketingContent?.is_appointment ? (
          // && !isMobile ? (
          <button
            style={styles.buttonMakeAppointment}
            onClick={handleMakeAppointment}
          >
            <div style={styles.textMakeAppointment}>Make Appointment</div>
          </button>
        ) : null}
      </div>

      <Dimmer
        active={props.loadingMainFeed}
        inverted
        style={{ height: "100vh" }}
      >
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
    </>
  );
};

const CardArticle: React.FunctionComponent<CardArticleProps> = (props) => {
  return (
    <div>
      {props.image && (
        <Grid.Column
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image
            src={props.image}
            size="medium"
            style={{ ...styles.imgDescriptionFull, borderRadius: "8px" }}
          />
        </Grid.Column>
      )}
      <Grid.Column style={{ margin: "20px" }}>
        <div style={styles.textHeaderFull}>{props.title}</div>
      </Grid.Column>
      <Grid.Column style={{ margin: "20px 20px" }}>
        <div
          style={styles.textContentFull}
          className="ArticleDesc-container"
          dangerouslySetInnerHTML={{
            __html: props.description || "",
          }}
        ></div>
      </Grid.Column>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                DisplayPromotionPrice                */

/* ------------------------------------------------------ */
type DisplayPromotionPriceProps = {
  data: {
    available_start_date: any;
    available_end_date: any;
    hideDate: boolean;
    showDateHyphen: any;
    minprice: string | null;
    maxprice: string | null;
    hidePrice: boolean;
    showPriceHyphen: string | null;
  };
};
const DisplayPromotionPrice = (props: DisplayPromotionPriceProps) => {
  return (
    <div style={{ padding: "15px 25px", backgroundColor: "#E7EDF6" }}>
      {!props.data.hideDate && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: props.data.hidePrice ? "" : "0.5rem",
          }}
        >
          <img src={IMAGES.time} style={{ width: "16px" }} />
          <label style={styles.priceLabel}>
            {props.data.available_start_date &&
              formatDate(moment(props.data.available_start_date))}{" "}
            {props.data.showDateHyphen ? "-" : ""}{" "}
            {props.data.available_end_date &&
              formatDate(moment(props.data.available_end_date))}
          </label>
        </div>
      )}
      {!props.data.hidePrice && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={IMAGES.price_tag} style={{ width: "16px" }} />
          <label style={styles.priceLabel}>
            {props.data.minprice
              ? Number(props.data.minprice).toLocaleString("en-US")
              : ""}{" "}
            {props.data.showPriceHyphen ? "-" : ""}{" "}
            {props.data.maxprice
              ? Number(props.data.maxprice).toLocaleString("en-US")
              : ""}
          </label>
        </div>
      )}
    </div>
  );
};

export default MobContentFull;
