import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "semantic-ui-react";

type HospitalDropDownProps = {
  organizationList?: any[];
  selectedOrganizationCode?: any;
  handleSelectHospital: any;
};

const HospitalDropDown: React.FC<HospitalDropDownProps> = (props: any) => {

  const COLORS = {
    primary: "var(--primary-theme-color)",
  };

  return (
    <div style={{ display: "flex", marginBottom: "30px", }}>
        <div
          style={{
            borderRadius: "20px",
            border: `1px solid ${COLORS.primary}` ,
            padding: "5px",
          }}
        >
          <Image src={"/logo512.png"} style={{ width: "25px" }} />
        </div>
        <div className="hospital-dropdown" style={{ display: "flex", justifyContent: "center" }}>
          <Dropdown options={props.organizationList
                  ?.map((organ: any, index: number) => ({
                    key: organ.id,
                    value: organ.code,
                    text: `${organ.name} (${organ.name_en})`,
                  }))}
                  defaultValue={props.selectedOrganizationCode} 
                  value={props.selectedOrganizationCode}
                  icon={"chevron down"}
                  onChange={(event,value)=> props.handleSelectHospital(value.value)}
                  />
        </div>
    </div>
  );
};

export default React.memo(HospitalDropDown);
