import React, { useEffect, useState } from "react";
import { Button, Dimmer, Grid, Header, Loader, Radio } from "semantic-ui-react";
import moment from "moment";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
// COMMMON
import { DateTextBox } from "../../../react-lib/apps/common";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import MomProfileHealthOverall from "../../clinic/MomProfileHealthOverall";
import MomProfileHealthPregnancy from "../../clinic/MomProfileHealthPregnancy";

// Interface
import {
  BACKEND_DATE_FORMAT,
  convertToADDate,
  formatDate,
  calculateDelivery,
} from "../../MobClinicInterface";

const DATE_FORMAT = "DD/MM/YYYY";

type WebMomOverallProps = {
  // function
  onEvent: any;
  // data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  profileObstretic?: any;
  profileHealth?: any[];
  profileDetail?: any;
};

const WebMomOverallInitial: WebMomOverallProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  profileObstretic: {},
  profileHealth: [],
  profileDetail: {},
};

const WebMomOverall: React.FC<WebMomOverallProps> = (props) => {
  const [obstreticRecord, setObstreticRecord] = useState<any>({
    data: {
      by_lmp_date: false,
      by_ultrasound: true,
      edc_date: null,
      lmp_date: null,
    },
  });

  useEffect(() => {
    props.onEvent({ message: "handleGetProfileObstretic" });
  }, [props.selectedPatient]);

  useEffect(() => {
    if (props.profileObstretic?.hasOwnProperty("id")) {
      setObstreticRecord(props.profileObstretic);
    } else {
      setObstreticRecord({
        data: {
          by_lmp_date: false,
          by_ultrasound: true,
          edc_date: null,
          lmp_date: null,
        },
      });
    }
  }, [props.profileObstretic]);

  const handleCaculateDeliveryResult = (result) => {
    let edc_date: moment.Moment | null = result.data.edc_date
      ? moment(result.data.edc_date, BACKEND_DATE_FORMAT).clone()
      : null;
    if (result.data.by_ultrasound && !edc_date) {
      edc_date = moment();
    }
    const deliveryResult = calculateDelivery(
      result.data.by_ultrasound,
      moment(),
      moment(result.data.lmp_date, BACKEND_DATE_FORMAT),
      edc_date
    );
    // console.log('deliveryResult: ', deliveryResult);
    // console.log('deliveryResult.edd_Date: ', deliveryResult.edd_date);
    result.data.edc_date = deliveryResult.edd_date.format(BACKEND_DATE_FORMAT);
    result.data.start_pregnancy_date =
      deliveryResult.start_pregnancy_date.format(BACKEND_DATE_FORMAT);
    result.data.ga_days = deliveryResult.ga_days;
    result.data.ga_weeks = deliveryResult.ga_weeks;
    result.data.trimester = deliveryResult.trimester;

    console.log('handleCaculateDeliveryResult result: ', result);
    setObstreticRecord(result);
  }

  const handleChangeDate = (name: string, date: string) => {
    // console.log('name: ', name);
    // console.log('date: ', date);
    let result = { ...obstreticRecord };
    // console.log('result: ', result);
    // console.log("convertToADDate(date)", convertToADDate(date))
    result.data[name] = moment(convertToADDate(date), DATE_FORMAT).format(
      BACKEND_DATE_FORMAT
      );
    // console.log('result.data[name]: ', result.data[name]);
    // console.log('result: ', result);
    handleCaculateDeliveryResult(result)
  };

  const handleChangeRadio = (event: any, data: any) => {
    let result = { ...obstreticRecord };
    result.data.by_ultrasound = false;
    result.data.by_lmp_date = false;
    result.data[data.value] = true;

    handleCaculateDeliveryResult(result)
  };

  const handleSaveProfileObstretic = () => {
    props.onEvent({
      message: "handleSaveProfileObstretic",
      params: { ...obstreticRecord },
    });
  };

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid verticalAlign="middle" className="box-header">
        <Grid.Column width={14}>
          <div className="div-header">
            <img
              alt="overall.icon"
              height="36px"
              style={{ color: "var(--theme-icon-color-dm)" }}
              src="/images/mom/overall.png"
            />
            <span><FormattedMessage id="bplusClinicKey363" /></span>
          </div>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            fluid
            style={{
              background: "var(--text-dark-blue)",
              color: "white",
            }}
            onClick={handleSaveProfileObstretic}
          >
            <FormattedMessage id="bplusClinicKey437" />
          </Button>
        </Grid.Column>
      </Grid>

      <div className="sub-layout">
        <Grid verticalAlign="middle" style={{ margin: "0px", padding: "10px" }}>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h4" style={{ color: "var(--text-light-blue)" }}>
                <FormattedMessage id="bplusClinicKey290" />
              </Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateTextBox
                value={
                  obstreticRecord?.data?.lmp_date
                    ? formatDate(
                        moment(
                          obstreticRecord.data.lmp_date,
                          BACKEND_DATE_FORMAT
                        ).format(DATE_FORMAT)
                      )
                    : ""
                }
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                onChange={(date: string) => {
                  handleChangeDate("lmp_date", date);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h4" style={{ color: "var(--text-light-blue)" }}>
                <FormattedMessage id="bplusClinicKey189" />
              </Header>
            </Grid.Column>
            <Grid.Column width={2}>
              <Radio
                label="By U/S"
                name="gaCalculate"
                value="by_ultrasound"
                checked={obstreticRecord?.data?.by_ultrasound}
                onChange={handleChangeRadio}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Radio
                label="By LMP"
                name="gaCalculate"
                value="by_lmp_date"
                checked={obstreticRecord?.data?.by_lmp_date}
                onChange={handleChangeRadio}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <DateTextBox
                value={
                  obstreticRecord?.data?.edc_date
                    ? formatDate(
                        moment(
                          obstreticRecord.data.edc_date,
                          BACKEND_DATE_FORMAT
                        ).format(DATE_FORMAT)
                      )
                    : ""
                }
                disabled={obstreticRecord?.data?.by_lmp_date}
                onChange={(date: string) => {
                  handleChangeDate("edc_date", date);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <MomProfileHealthOverall
          onEvent={props.onEvent}
          errorMessage={props.errorMessage}
          // loadingStatus={props.loadingStatus}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          profileHealth={props.profileHealth}
          tableWidth="50%"
        />

        <MomProfileHealthPregnancy profileObstretic={obstreticRecord} />
      </div>
    </div>
  );
};

WebMomOverall.defaultProps = WebMomOverallInitial;

export default React.memo(WebMomOverall);
