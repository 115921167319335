import React, { useState, useEffect, useCallback, CSSProperties } from "react";
import { Segment, Button, Dimmer, Loader, Modal } from "semantic-ui-react";
// MUI
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import MuiButton from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import makeStyles from "@mui/styles/makeStyles";

import Cookies from "js-cookie";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";

const styles = {
  button: {
    textAlign: "left",
    textTransform: "unset",
    width: "100%",
    justifyContent: "flex-start",
    paddingBottom: 0,
  } as CSSProperties,
  button_content: {
    display: "grid",
    gridTemplateColumns: "40px 40px auto 10px",
    alignItems: "center",
    padding: "8px 8px 12px",
    borderBottom: `1px solid #E9E9E9`,
    fontSize: "16px",
    width: "100%",
  } as CSSProperties,
};

const COLOR = {
  blue: "var(--blue-bdms-color)",
  light_blue: "var(--primary-bg-color-light)",
  medium_blue: "var(--location-topbar-theme)",
  yellow: "var(--warning)",
  dark_grey: "var(--secondary-font-color)",
  orange: "var(--orange)",
  background: "var(--primary-bg-color)",
  gray: "var(--super-dark-gray)",
};

const ICON = {
  location: "/images/Feed/consent-hospital.png",
  check: "/images/Feed/consent-hospital-check.png",
  bplus: "/logo512.png",
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    textTransform: "unset",
    backgroundColor: COLOR.blue,
    "&:hover": {
      backgroundColor: COLOR.blue,
    },
    borderRadius: "15px",
    padding: "8px 0",
    boxShadow: "none",
    margin: "5px",
  },
  checkbox: {
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
}));

const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
)
  ? "th-TH"
  : "en-US";

const ConsentFormByHospitalList = (props: any) => {
  const [myDiagForm, setMyDiagForm] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [consentList, setConsentList] = useState<any[]>([]);
  const [checkedHospital, setCheckedHospital] = useState<any[]>([]);
  const [answer, setAnswer] = useState<Record<string, any>>({});
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const history = useHistory();
  const classes = useStyles();
  const apiToken = Cookies.get("apiToken");

  useEffect(() => {
    if (apiToken) {
      getProxyPatientHasDivision();
    }
  }, [apiToken]);

  useEffect(() => {
    const checkBackToApp = async () => {
      //   -if (typeof window.iosNative !== "undefined") {
      //     try {
      //       const backToApp = await window.iosNative.backToApp;
      //       if (backToApp === "true") {
      //         props.onSetBackToApp(true);
      //       }
      //     } catch (e) {
      //     }
      //   } else if (typeof window.MobNative !== "undefined") {
      //     const backToApp = await window.MobNative.backToApp();
      //     if (backToApp) {
      //       props.onSetBackToApp(true);
      //     }
      //   }
      props.onSetBackToApp?.(true);
    };

    checkBackToApp();
  }, [window.iosNative, window.MobNative]);

  const handleCheckedHospital = useCallback(
    (diagRule: number) => {
      const isChecked = checkedHospital.includes(diagRule);

      if (!isChecked) {
        setCheckedHospital([...checkedHospital, diagRule]);
      } else {
        setCheckedHospital(checkedHospital.filter((id) => id !== diagRule));
      }
    },
    [checkedHospital]
  );

  const handleButtonClick = (id: number) => {
    history.push(`/consent-hospital-form/${id}/`);
  };

  const handleClick = (id: number) => (e: any) => {
    if (props.hideDialog) {
      handleButtonClick(id);
    } else {
      handleCheckedHospital(id);
    }
  };

  const getProxyPatientHasDivision = async () => {
    setFirstLoading(true);

    const params = new URLSearchParams(window.location.search);
    const diagFormId = params.get("diag_form");

    const promiseArr = [
      props.controller.getListConsents({
        apiToken: apiToken,
      }),
      props.controller.getV3PatientGetHn({
        apiToken: apiToken,
      }),
      props.controller.getMyDiagForm({
        apiToken: apiToken,
      }),
      SmartUserState.retrieve({
        apiToken: apiToken,
      }),
    ];

    if (diagFormId) {
      promiseArr.push(
        props.controller.getDiagFormDetail({
          diagFormId,
          apiToken: apiToken,
        })
      );
    }

    const [[consents], [hospital], [MyDiagForm], [kycStatus], diagForm] =
      await Promise.all(promiseArr);

    const key = "organization_code";

    const arrayUniqueByKey = Array.from(
      new Map((consents || []).map((item: any) => [item[key], item])).values()
    );

    const list: any[] = arrayUniqueByKey.filter((item: any) =>
      (hospital || []).find(
        (acc: any) =>
          acc.code === item.organization_code && item.name !== "ConsentFormBDMS"
      )
    );

    const listFinished: any[] = arrayUniqueByKey.filter((item: any) =>
      (hospital || []).find((acc: any) => acc.code === item.organization_code)
    );

    const isConsentFalse = list.filter((item: any) =>
      (kycStatus.hospital_profiles || []).find(
        (kyc: any) =>
          kyc.hospital_code === item.organization_code && !kyc.is_consent
      )
    );

    if (isConsentFalse.length === 0 && !props.hideDialog) {
      globalThis.location.href = "/select-hospital/?app=MobRegister";
    }

    const finished = listFinished.filter((item) => item.diag_form_finished);

    setMyDiagForm(MyDiagForm.items);
    setData(props.hideDialog ? finished : isConsentFalse);
    setConsentList(consents || []);
    setAnswer(JSON.parse(diagForm?.[0]?.answers || "{}"));
    setFirstLoading(false);
  };

  // -const handleNextPage = () => {
  //   // setOpenModal(true);
  //   console.log("diagConfirm");
  //   if (typeof window.iosNative !== "undefined") {
  //     return window.iosNative.diagConfirm();
  //   } else if (typeof window.MobNative !== "undefined") {
  //     return window.MobNative.diagConfirm();
  //   } else {
  //     history.push("/");
  //   }
  // };

  const handleModalButtonClick = async (e: any) => {
    const { textContent } = e.target;
    if (textContent === "ไม่ใช่" || textContent === "No") {
      setOpenModal(false);
    } else if (textContent === "ใช่" || textContent === "Yes") {
      setOpenModal(false);
      props.onCheckType?.("diagUnConfirm");
    }
  };

  const handleSelectAll = () => {
    setCheckedHospital(data.map((item) => item.published_diag_rule));
  };

  const handleConfirm = async () => {
    // -const headquarterDiagRuleId: number = consentList.find(
    //   (item) => item.organization_code === "1"
    // )?.published_diag_rule;
    const bdmsDiagRuleId: number = consentList.find((item) => {
      if (!props.hideDialog) {
        return item.name === "ConsentFormBDMS";
      } else {
        return false;
      }
    })?.published_diag_rule;

    const ids = Array.from(new Set([...checkedHospital, bdmsDiagRuleId]));

    setIsLoading(true);

    let promiseArr: any[] = [];
    ids.forEach((id) => {
      let diagFormIdList: any[] = [];
      myDiagForm.forEach((item) => {
        if (item.published_diag_rule === id) {
          diagFormIdList.push(item.id);
        }
      });

      if (diagFormIdList.length > 0) {
        diagFormIdList.forEach((item) => {
          promiseArr.push(
            props.controller.updateDiagForm({
              apiToken: props.apiToken ? props.apiToken : apiToken,
              diagFormId: item,
              answers: JSON.stringify(answer),
              finished: true,
              triageLevel: undefined,
              text: "",
            })
          );
        });
      } else {
        promiseArr.push(
          props.controller.createDiagForm(
            props.apiToken ? props.apiToken : apiToken,
            props.patientData.patient, //patientId
            id, //DiagFormId
            JSON.stringify(answer),
            undefined,
            true
          )
        );
      }
    });

    const response = await Promise.all(promiseArr);
    const success = response.every((res) => res[0]?.id);

    if (success) {
      setIsLoading(false);
      globalThis.location.href = "/select-hospital/?app=MobRegister";
    } else {
      setIsLoading(false);
    }
  };

  console.log("ConsentHospitalForm");

  return (
    <div className="consent-form">
      <Modal open={openModal} className="mod-consent-form">
        <div
          style={{ fontSize: "16px", textAlign: "center", color: COLOR.blue }}
        >
          ท่านไม่ต้องการทำแบบแสดงความยินยอมของทุกโรงพยาบาล
        </div>
        <br />
        <div className="btn-group">
          <Button
            content={props.intl.formatMessage({ id: "e_consent_Yes" })}
            onClick={handleModalButtonClick}
            fluid
          />
          <Button
            content={props.intl.formatMessage({ id: "e_consent_No" })}
            onClick={handleModalButtonClick}
            fluid
          />
        </div>
      </Modal>
      {props.menuBar}
      <Dimmer.Dimmable style={{ marginBottom: "125px" }}>
        <Dimmer active={isLoading} inverted style={{ position: "fixed" }}>
          <Loader />
        </Dimmer>

        <div
          style={{
            textAlign: "center",
            color: COLOR.blue,
            paddingBottom: "10px",
          }}
        >
          {/* #{LANGUAGE === "en-US"
            ? "Please select hospital"
            : "กรุณาเลือกโรงพยาบาล"} */}
          {LANGUAGE === "en-US"
            ? "Please select at least 1 hospital"
            : "กรุณาเลือกอย่างน้อย 1 โรงพยาบาล"}
        </div>

        {!firstLoading ? (
          data.map((item, index) => (
            <MuiButton
              key={"list-" + index}
              variant="text"
              color="primary"
              style={styles.button}
              className="consent-form-muibutton"
              onClick={handleClick(item.published_diag_rule)}
            >
              <div
                style={{
                  ...styles.button_content,
                  gridTemplateColumns: props.hideDialog
                    ? "40px auto 10px"
                    : "40px 40px auto 10px",
                }}
              >
                {!props.hideDialog && (
                  <div>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={<Checkbox defaultChecked />}
                      label=""
                      checked={checkedHospital.includes(
                        item.published_diag_rule
                      )}
                      onClick={handleClick(item.published_diag_rule)}
                    />
                  </div>
                )}
                <div>
                  <Avatar
                    src={ICON.bplus}
                    style={{
                      padding: "10%",
                      width: "27px",
                      height: "27px",
                      border: `1px solid ${COLOR.medium_blue}`,
                    }}
                  />
                </div>
                <div style={{ color: COLOR.blue }}>
                  {LANGUAGE === "en-US"
                    ? item?.organization_name_en
                    : item?.organization_name}
                </div>
                <div style={{ marginTop: "10px", paddingRight: "20px" }}>
                  {/* <img
                  style={{ width: "20px", height: "auto" }}
                  src={item?.diag_form_finished === true ? ICON.check : ICON.location}
                  alt="location"
                /> */}
                </div>
              </div>
            </MuiButton>
          ))
        ) : (
          <ListSkeleton hideDialog={props.hideDialog} />
        )}

        {/* <Button
          fluid
          content={LANGUAGE === "en-US" ? "Skip" : "ข้าม"}
          onClick={handleNextPage}
        />
        <br />
        <br /> */}
        {!props.hideDialog ? (
          <FooterButtonConfirm
            language={LANGUAGE}
            // config
            allowConfirm={checkedHospital.length}
            // callback
            onSelectAll={handleSelectAll}
            onConfirm={handleConfirm}
          />
        ) : (
          ""
        )}
      </Dimmer.Dimmable>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                  FooterButtonConfirm;                  */

/* ------------------------------------------------------ */
const FooterButtonConfirm = (props: any) => {
  const classes = useStyles();

  return (
    <Segment
      style={{
        padding: "15px 10px",
        border: 0,
        backgroundColor: "white",
      }}
    >
      <div
        className="button-submit-bottom"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          margin: 0,
        }}
      >
        <MuiButton
          variant="contained"
          color="primary"
          style={{ width: "100%", maxHeight: "46.2px" }}
          onClick={props.onSelectAll}
        >
          {props.language === "en-US" ? "Select all" : "เลือกทั้งหมด"}
        </MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          style={{ width: "100%", maxHeight: "46.2px" }}
          onClick={props.onConfirm}
          disabled={!props.allowConfirm}
        >
          {props.language === "en-US" ? "Confirm" : "ยืนยัน"}
        </MuiButton>
      </div>
    </Segment>
  );
};

/* ------------------------------------------------------ */

/*                    HospitalSkeleton;                   */

/* ------------------------------------------------------ */
const ListSkeleton = (props: any) => {
  return (
    <>
      {[75, 100, 60, 85, 70, 99].map((width, index) => (
        <MuiButton
          key={"skeleton-" + width}
          variant="text"
          color="primary"
          style={styles.button}
          className="consent-form-muibutton"
          // onClick={() => handleButtonClick(item?.published_diag_rule)}
          disabled={true}
        >
          <div
            style={{
              ...styles.button_content,
              // padding: "18px 8px 18px",
              borderBottom: "1px solid rgba(233,233,233,0.75)",
              gridTemplateColumns: props.hideDialog
                ? "40px auto 10px"
                : "40px 40px auto 10px",
            }}
          >
            {!props.hideDialog && (
              <div>
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width={25}
                  height={25}
                  style={{}}
                />
              </div>
            )}
            <div>
              <Skeleton
                animation="pulse"
                variant="circular"
                width={27}
                height={27}
                style={{}}
              />
            </div>
            <div>
              <Skeleton
                animation="pulse"
                height={18}
                style={{}}
                width={`${width}%`}
              />
            </div>
            <div style={{ marginTop: "10px", paddingRight: "20px" }}></div>
          </div>
        </MuiButton>
      ))}
    </>
  );
};

ConsentFormByHospitalList.defaultProps = {
  onFormLanguageChange: () => {},
};

export default React.memo(injectIntl(ConsentFormByHospitalList));
