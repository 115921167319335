import React, { useMemo, useCallback } from "react";
import { Input, InputProps } from "semantic-ui-react";
// MUI
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import MuiButton from "@mui/material/Button";
import { CSSProperties } from "@mui/styles";

// UX
// telephar
import RadioCard from "bplus-lib/telephar/RadioCard";

// Types
type CardRadioListProps = {
  items: ItemType[];
  selected?: number | string;
  loading?: boolean;
  note?: string; // EXTRA INPUT
  member?: number; // FAMILY MEMBER
  appointmentType?: "on-site" | "online";
  // callback
  onSelect?: (selected?: number | string) => any;
  onNoteChange?: (value: any) => any; // EXTRA INPUT
  onMemberSelect?: (selected?: number) => any; // FAMILY MEMBER
};

type ItemType = {
  [key: string]: any;
  id: number;
  name: string;
  has_free_text?: boolean; // แสดงระบุเพิ่มเติม
  families?: any[]; // แสดงสมาชิกในครอบครัว
  first_consultation?: boolean; // แสดงพบแพทย์ครั้งแรก
  doctor_fee?: number; // แสดงค่าแพทย์ด้านขวารายการ
  hospital_logo?: string; // แสดงโลโก้โรงพยาบาล
};

// Images
const IMAGES = {
  logo: "/images/expansion/logo_192.png",
  person: "/images/Appointment/person.png",
};

// Styles
const COLORS = {
  very_light_blue: "rgba(1, 71, 163, 0.03)",
  blue_005: "rgba(1, 71, 163, 0.05)",
  grey: "rgba(57, 57, 57, 1)",
  light_blue: "rgba(204, 218, 237, 1)",
  primary: "var(--blue-bdms-color)",
  blue: "rgba(0, 53, 122, 1)",
};

const screenStyles = {
  "& .centered": {
    marginBottom: "0.75rem",
    "& .MuiCardContent-root": {
      padding: "22.5px 16px",
      backgroundColor: "white",
      "& .MuiRadio-root": {
        margin: "0 0.75rem 0 0rem !important",
      },
    },
    "&.active .MuiCardContent-root": {
      backgroundColor: COLORS.very_light_blue,
    },
  },
} as CSSProperties;

const boxCardStyles = {
  margin: "0.5rem 0 -0.75rem",
  "& > button": {
    width: "100%",
    boxShadow: "none",
    justifyContent: "flex-start",
    backgroundColor: "white",
    padding: "7px 14px",
    borderRadius: "8px",
    marginTop: "0.35rem",
    marginBottom: "0.5rem",
    "& img": {
      width: "34px",
      borderRadois: "500rem",
      marginRight: "0.75rem",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& > label": {
      color: COLORS.grey,
    },
    "&.active": {
      backgroundColor: COLORS.light_blue,
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  screen: screenStyles,
  skeleton: {
    display: "flex",
    alignItems: "center",
    height: "66px",
    "& > span:nth-child(1)": {
      marginRight: "1rem",
    },
    paddingLeft: "1rem",
  },
  note: {
    color: COLORS.blue,
    backgroundColor: COLORS.blue_005,
    borderRadius: "8px",
    padding: "15px 16px",
    marginBottom: "1rem",
  },
  box_card: boxCardStyles,
  price: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: COLORS.primary,
    marginTop: "0.2rem",
  },
}));

const CardRadioList = (props: CardRadioListProps) => {
  const classes = useStyles();

  // Memo
  const items = useMemo((): ItemType[] => {
    const skeletons = Array(3)
      .fill("")
      .map((_, index) => ({ id: index } as any));

    return props.loading ? skeletons : props.items || [];
  }, [props.loading, props.items]);

  const selectedData = useMemo(() => {
    return items.find((item) => item.id === props.selected);
  }, [items, props.selected]);

  // callback
  const renderRightElement = useCallback((item: any) => {
    if (item.price && props.appointmentType !== "on-site") {
      return (
        <div className={classes.price}>{item.price && <>฿ {item.price}</>}</div>
      );
    } else if (item.hospital_logo) {
      return <img src={IMAGES.logo} style={{ width: "24px" }} />;
    } else {
      return null;
    }
  }, []);

  // Handler
  const handleChangeInput = (e: any, data: InputProps) => {
    props.onNoteChange?.(data.value);
  };

  const handleSelectMember = (selected: any) => () => {
    props.onMemberSelect?.(selected);
  };

  return (
    <div className={classes.screen}>
      {items.map((item) => (
        <>
          {props.loading ? (
            <CardSkeleton key={"card-skeleton" + item.id} />
          ) : (
            <>
              <RadioCard
                key={"card-item-" + item.id}
                option={item.id}
                active={props.selected === item.id}
                title={item.name}
                //  config
                centered={true}
                // callback
                onSelect={props.onSelect}
                // Element
                rightElement={renderRightElement(item)}
              >
                <>
                  {/* NOTE INPUT */}
                  {item.has_free_text && props.selected === item.id && (
                    <Input
                      value={props.note || ""}
                      placeholder="ระบุรูปแบบการนัดหมาย"
                      fluid={true}
                      onChange={handleChangeInput}
                    />
                  )}

                  {/* FAMILY MEMBER */}
                  {item.id === props.selected && !!item.families?.length && (
                    <div className={classes.box_card}>
                      {item.families.map((acc: any) => (
                        <MuiButton
                          key={"family-" + acc.patient}
                          className={
                            acc.patient === props.member ? "active" : ""
                          }
                          variant="contained"
                          onClick={handleSelectMember(acc.patient)}
                        >
                          <img
                            src={acc.image || acc.image_url || IMAGES.person}
                            onError={(e: any) => (e.target.src = IMAGES.person)}
                          />
                          <label>{acc.patient_name}</label>
                        </MuiButton>
                      ))}
                    </div>
                  )}
                </>
              </RadioCard>

              {selectedData?.first_consultation &&
                item.first_consultation &&
                props.appointmentType === "on-site" && (
                  <div className={classes.note}>
                    การพบแพทย์ครั้งแรก แบบใช้บริการที่โรงพยาบาล ใช้เวลาประมาณ 30
                    นาที ในการพบแพทย์
                  </div>
                )}
            </>
          )}
        </>
      ))}
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      CardSkeleton                      */

/* ------------------------------------------------------ */

const CardSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.skeleton}>
      <Skeleton
        animation="pulse"
        variant="circle"
        width={27}
        height={27}
        style={{}}
      />
      <Skeleton animation="pulse" height={18} style={{}} width="60%" />
    </div>
  );
};

export default React.memo(CardRadioList);
