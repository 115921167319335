import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
// Interface
import {
  BACKEND_DATE_FORMAT,
  GROUP,
  DATA_TYPE,
  LAB_CODE,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

import { commonListPatientDataList } from "../../MobClinicInterface";
import { formatUTCtoMoment } from "../../../react-lib/utils/dateUtils";

// APIs
import StaffDirectDMChildLabResultView from "../../../issara-sdk/apis/StaffDirectDMChildLabResultView_apps_PRX";

export type State = {
  bgFilterDate?: moment.Moment;
  bgFilterDuration?: string;
  bgRecordList?: any;
  bgSettingData?: any;
  bgChartList?: any;
  dataGlucose?: any;
  bgBarfpg?: any;
  bgBarhba1c?: any;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "selectedPatient"
>;

export const StateInitial: State = {
  bgFilterDate: moment(),
  bgFilterDuration: "6",
  bgRecordList: {},
  bgSettingData: {},
  bgChartList: {},
  dataGlucose: {},
  bgBarfpg: {},
  bgBarhba1c: {},
};

export type Event =
  // GET
  | {
      message: "GetBloodGlucoseChart";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "GetBloodGlucoseBar";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "GetBloodGlucose";
      params: { start_measure: string; end_measure: string };
    }
  | {
      message: "GetBloodGlucoseByLabCode";
      params: {
        start_measure: string;
        end_measure: string;
        labCode: "02C880" | "02C040";
      };
    };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET

export const GetBloodGlucose: Handler = async (controller, params) => {
  let getChart = await GetBloodGlucoseChart(controller, params);
  let getBar = await GetBloodGlucoseBar(controller, params);
};

export const GetBloodGlucoseChart: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.BLOOD_GLUCOSE,
    data_type: DATA_TYPE.BLOOD_GLUCOSE,
    ...params,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  let glucoseResponse: any = {};
  glucoseResponse = response.items.map((item: any) => {
    return {
      ...item.data,
      updated_at: formatUTCtoMoment(item.measure_date).format(
        BACKEND_DATE_FORMAT
      ),
      measure_date: item.measure_date,
      created_at: item.created_at,
      value: item.data.value,
      meal: item.data.meal,
    };
  });
  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    bgChartList: glucoseResponse,
  });

  return true;
};

export const GetBloodGlucoseBar: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const [respFasting, errorFasting, networkFasting] =
    await StaffDirectDMChildLabResultView.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: {
        patient: state.selectedPatient?.proxy_patient,
        parent_lab: "02C040",
        start_measure: moment(params.start_measure, BACKEND_DATE_FORMAT)
          .add(-4, "years")
          .format(BACKEND_DATE_FORMAT),
        end_measure: params.end_measure,
        // ...params, // start_measure, end_measure
      },
    });

  const [respHba1c, errorHba1c, networkHba1c] =
    await StaffDirectDMChildLabResultView.get({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: {
        patient: state.selectedPatient?.proxy_patient,
        parent_lab: "02C880",
        start_measure: moment(params.start_measure, BACKEND_DATE_FORMAT)
          .add(-4, "years")
          .format(BACKEND_DATE_FORMAT),
        end_measure: params.end_measure,
        // ...params, // start_measure, end_measure
      },
    });

  let fpgData: any = {};
  let hba1cData: any = {};
  if (errorFasting || errorHba1c) {
    controller.setState({
      loadingStatus: false,
      errorMessage: errorFasting || errorHba1c,
      successMessage: null,
      bgBarhba1c: null,
      bgBarfpg: null,
    });
  } else {
    respFasting?.forEach((fasting: any) => {
      fpgData = fasting?.lab_results?.map((item: any) => {
        return {
          updated_at: formatUTCtoMoment(item?.reported_datetime).format(
            BACKEND_DATE_FORMAT
          ),
          value: item?.value,
        };
      });
    });
    respHba1c?.forEach((hba1c: any) => {
      hba1cData = hba1c?.lab_results?.map((item: any) => {
        return {
          updated_at: formatUTCtoMoment(item?.reported_datetime).format(
            BACKEND_DATE_FORMAT
          ),
          value: item?.value,
        };
      });
    });
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      bgBarhba1c: hba1cData,
      bgBarfpg: fpgData,
    });
  }
  return true;
};

export const GetBloodGlucoseByLabCode: Handler = async (
  controller,
  params: Extract<Event, { message: "GetBloodGlucoseByLabCode" }>["params"]
) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  const [resp, error, network] = await StaffDirectDMChildLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      parent_lab: params.labCode,
      start_measure: moment(params.start_measure, BACKEND_DATE_FORMAT)
        .add(-4, "years")
        .format(BACKEND_DATE_FORMAT),
      end_measure: params.end_measure,
      // ...params, // start_measure, end_measure
    },
  });
  console.log(
    "start_measure: ",
    params.start_measure,
    moment(params.start_measure, BACKEND_DATE_FORMAT)
      .add(-4, "years")
      .format(BACKEND_DATE_FORMAT)
  );
  let data: any = {};
  const labCode = {
    "02C880": "bgBarhba1c",
    "02C040": "bgBarfpg",
  };
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      [labCode[params.labCode]]: null,
    });
  } else {
    resp?.forEach((acc: any) => {
      data = acc?.lab_results?.map((item: any) => {
        return {
          updated_at: formatUTCtoMoment(item?.reported_datetime).format(
            BACKEND_DATE_FORMAT
          ),
          value: item?.value,
        };
      });
    });
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      [labCode[params.labCode]]: data,
    });
  }
  return true;
};
