import React, { createRef, useEffect, useState } from "react";
// CSS
import { Dimmer, Loader } from "semantic-ui-react";

import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
// Icon
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { FormattedMessage } from "react-intl";
import moment from "moment";

import { PREFERRED_MODE } from "./Preferred";

// Interface
import { Event } from "../ManageInterface";
import { Mode } from "@mui/icons-material";

// type PostListProps = {
//   onEvent: (e: Event) => any;
//   list: Record<string, any>[];
//   byPrefix?: string;
//   titleKey?: string;
//   dateKey?: string;
//   activeIndex?: number;
//   activeId?: string | number | null;
//   disabled?: boolean;
//   loadingStatus?: boolean;
//   showSearchClinic?: boolean;
//   searchAddPlaceholder?: string;
//   searchPlaceholder?: string;
//   // Callback
//   onClickItem?: (item: any, index: number) => any;
//   onAdd?: (e: any) => any;
//   onSearch?: (search: string) => any;
//   onSecondSearch?: (search: string) => any;
// };

const COLORS = {
  background_card: "rgba(204,218,237,0.75)",
  primary: "var(--text-light-blue)",
  light_blue: "#0072BC",
  dark_grey: "#797878",
};

const BE_DATE_FORMAT = "DD/MM/YYYY";

const useStyles = makeStyles(() => ({
  post_item: {
    marginTop: "25px",
    height: "calc(100vh - 134px)",
    overflowY: "auto",
    "& > div": {
      color: COLORS.dark_grey,
      borderTop: `0.5px solid ${COLORS.primary}`,
      padding: "5px 3px",
      cursor: "pointer",
    },
    "& > div:last-child": {
      borderBottom: `0.5px solid ${COLORS.primary}`,
    },
    "& .title-name": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export const Search = styled("div")(() => ({
  position: "relative",
  borderRadius: "10px",
  backgroundColor: COLORS.background_card,
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: "100%",
  margin: "0 5px",
  "& .MuiButton-root.MuiButton-contained": {
    position: "absolute",
    minWidth: 0,
    maxWidth: "40px",
    right: 0,
    borderRadius: "0 10px 10px 0",
    height: "100%",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    color: "white",
    marginLeft: "-7px",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    paddingRight: "45px",
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
}));

const PreferredList = (props: any) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [lock, setLock] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("useEffect props.activeIndex: ", props.activeIndex);

    if (lock) {
      setLock(false);
      return;
    }

    if (props.listFB?.length > 0) {
      let id = props.listFB?.[props.activeIndex]?.id;
      console.log("useEffect id: ", id);
      refs[id]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [props.activeIndex]);

  const refs = props.listFB.reduce((acc: any, value: any) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    setOpen(false);
  }, [props.listFB, props.listDJ]);

  return (
    <div style={{ position: "relative" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={props.searchAddPlaceholder}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              props.onSearch?.(e.target.value);
            }}
          />
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            disabled={props.disabledAddNew}
            onClick={props.onAdd}
          ></MuiButton>
        </Search>
      </div>

      {props.showSearchClinic && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={props.searchPlaceholder}
              inputProps={{
                "aria-label": "search",
                style: { paddingRight: "14px" },
              }}
              onChange={(e) => {
                props.onSecondSearch?.(e.target.value);
              }}
            />
          </Search>
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.mode === PREFERRED_MODE.HOME
              ? `Menu name: ${props.listFB?.[props.activeIndex]?.title_en}`
              : `Menu name: ${props.listDJ?.[props.activeIndex]?.display}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancle</Button>
          <Button
            onClick={() => {
              if (props.mode === PREFERRED_MODE.HOME) {
                props.onDeleteClickItem?.(props.listFB?.[props.activeIndex]);
              } else {
                props.onDeleteClickItem?.(props.listDJ?.[props.activeIndex]);
              }
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.post_item}>
        {props.mode === PREFERRED_MODE.HOME
          ? props.listFB?.map((item: any, index: any) => {
              /// Firebase

              return (
                <div
                  key={"item-" + item.id}
                  ref={refs[item.id]}
                  style={{
                    ...((typeof props.activeIndex !== "undefined"
                      ? index === props.activeIndex
                      : typeof props.activeId !== "undefined"
                      ? item.id === props.activeId
                      : false) &&
                      !props.disabled && {
                        background:
                          "linear-gradient(89.71deg, #CCDAED -0.4%, rgba(204, 218, 237, 0) 68.47%)",
                        borderLeft: `4px solid ${COLORS.primary}`,
                        color: COLORS.primary,
                      }),
                    padding: !props.dateKey ? "10px 5px" : "",
                  }}
                  onClick={() => {
                    setLock(true);
                    props.onClickItem?.(item, index);
                  }}
                >
                  {!props.byPrefix && props.dateKey && (
                    <div>
                      {moment(item[props.dateKey]).format(BE_DATE_FORMAT)}
                    </div>
                  )}
                  {props.byPrefix ? (
                    <div>Promotion {index + 1}</div>
                  ) : (
                    <div
                      className="title-name"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={{ marginBottom: "5px" }}>
                          {item[props.titleKey || ""]}
                        </div>
                        <div>
                          {item?.active ? (
                            <CheckIcon color="primary" fontSize="small" />
                          ) : (
                            <CloseIcon color="primary" fontSize="small" />
                          )}{" "}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            fontWeight: "lighter",
                            fontSize: "smaller",
                            display: "flex",
                            marginBottom: "5px",
                          }}
                        >
                          <span
                            style={
                              item?.type === "Main service"
                                ? { color: "purple" }
                                : item?.type === "Secondary service"
                                ? { color: "green" }
                                : item?.type
                                ? { color: "brown" }
                                : {}
                            }
                          >
                            {` ${item?.type} , seq: ${item?.sequence}`}
                          </span>
                        </div>

                        <div
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {index === props.activeIndex ? (
                            <DeleteIcon
                              onClick={() => {
                                handleClickOpen();
                                // props.onDeleteClickItem?.(item, index);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          : props.listDJ?.map((item: any, index: any) => {
              /// Django

              return (
                <div
                  key={"item-" + item.id}
                  ref={refs[item.id]}
                  style={{
                    ...((typeof props.activeIndex !== "undefined"
                      ? index === props.activeIndex
                      : typeof props.activeId !== "undefined"
                      ? item.id === props.activeId
                      : false) &&
                      !props.disabled && {
                        background:
                          "linear-gradient(89.71deg, #CCDAED -0.4%, rgba(204, 218, 237, 0) 68.47%)",
                        borderLeft: `4px solid ${COLORS.primary}`,
                        color: COLORS.primary,
                      }),
                    padding: !props.dateKey ? "10px 5px" : "",
                  }}
                  onClick={() => {
                    setLock(true);
                    props.onClickItem?.(item, index);
                  }}
                >
                  {!props.byPrefix && props.dateKey && (
                    <div>
                      {moment(item[props.dateKey]).format(BE_DATE_FORMAT)}
                    </div>
                  )}
                  {props.byPrefix ? (
                    <div>Promotion {index + 1}</div>
                  ) : (
                    <div
                      className="title-name"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={{ marginBottom: "5px" }}>
                          {item[props.titleKey || ""]}
                        </div>
                        <div>
                          {item?.active ? (
                            <CheckIcon color="primary" fontSize="small" />
                          ) : (
                            <CloseIcon color="primary" fontSize="small" />
                          )}{" "}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            fontWeight: "lighter",
                            fontSize: "smaller",
                            display: "flex",
                            marginBottom: "5px",
                          }}
                        >
                          <span
                            style={
                              item?.type === "Main service"
                                ? { color: "purple" }
                                : item?.type === "Secondary service"
                                ? { color: "green" }
                                : item?.type
                                ? { color: "brown" }
                                : {}
                            }
                          >
                            {` ${item?.type} , seq: ${item?.seq}`}
                          </span>
                        </div>

                        <div
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {index === props.activeIndex ? (
                            <DeleteIcon
                              onClick={() => {
                                handleClickOpen();
                                // props.onDeleteClickItem?.(item, index);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default PreferredList;
