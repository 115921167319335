import React, {
  memo,
  useState,
  useCallback,
  CSSProperties,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";

import { registerInfo } from "bplus-lib/register/TypeModal";
import { FormattedMessage, useIntl } from "react-intl";

type InputOTPProps = {
  setProp: any;
  length: number;
  onChangeOTP: (otp: string) => any;
  refCode: string;
  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  onKeyEnter: Function;

  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
  minuteStart?: number;
  secondsStart?: number;
  registerInfo?: registerInfo;
};

const InputOTP = (props: InputOTPProps) => {
  const intl = useIntl();
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    minuteStart,
    secondsStart,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    setMinutes(props.minuteStart || 0);
    setSeconds(props.secondsStart || 0);
  }, [props.refCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(seconds);

          const registerInfo = props.registerInfo || {};

          const error = registerInfo.error || {};
          error.message = intl.formatMessage({
            id: "bplusClinicKey1131",
          });
          registerInfo.error = error;

          props.setProp("registerInfo", { ...registerInfo });
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [minutes, seconds]);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log("log ~ e.currentTarget.value:", e.currentTarget.value);
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;
      console.log("log ~ pressedKey:", pressedKey);
      switch (pressedKey) {
        case "Enter": {
          props.onKeyEnter();
          break;
        }
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }
          console.log("log ~ pressedKey:", pressedKey);
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedDataCheck = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput);

      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        onChangeOTP(pastedDataCheck);
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <div>
      <label
        className="otp-ref-code"
        style={{
          fontSize: "14px",
        }}
      >
        {`ref. code : ${props.refCode ? props.refCode : "-"}`}
      </label>

      <div
        style={{ ...props.style, display: "flex", justifyContent: "center" }}
        className={props.className}
      >
        {Array(length)
          .fill("")
          .map((_, index) => (
            <SingleInput
              key={`SingleInput-${index}`}
              type={"number"}
              focus={activeInput === index}
              value={otpValues && otpValues[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              style={inputStyle}
              className={inputClassName}
              disabled={disabled}
              inputMode="numeric"
            />
          ))}
      </div>
      <div
        className="div-center"
        style={{
          margin: "10px 0px 30px",
          color: "red",
          display: props.registerInfo?.error?.message ? "" : "none",
        }}
      >
        {props.registerInfo?.error?.message === "incorrect" ? <FormattedMessage id="bplusClinicKey1130" /> : props.registerInfo?.error?.message}
      </div>

      <label
        className="otp-timeout"
        style={{
          fontSize: "20px",
        }}
      >
        {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
      </label>
    </div>
  );
};

type SingleOTPInputProps = {
  focus?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SingleInput = (props: SingleOTPInputProps) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus]);

  return <input ref={inputRef} {...rest} />;
};

export default React.memo(InputOTP);
