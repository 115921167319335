import React, { CSSProperties, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import icon_collapse from "./image/icon_collapse.png";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  Grid,
  Icon,
  Image,
  Divider,
  Button,
  Header,
  Modal,
} from "semantic-ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  lightBlueBDMS: "#0072BC",
  lightBlue: "lightBlue",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    color: COLOR.primary,
    margin: "0px 20px",
  } as CSSProperties,
  button_back: {
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: COLOR.font_white,
  } as CSSProperties,
  text_lightBlue: {
    margin: "10px 0 0 0 ",
    fontSize: "14px",
    color: COLOR.lightBlueBDMS,
  } as CSSProperties,
  text_lightBlueBDMS_bold: {
    margin: "10px 0 0 0 ",
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.lightBlueBDMS,
  } as CSSProperties,
  text_bluebdms_bold: {
    margin: "0 0 0 10px ",
    fontSize: "14px",
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    color: COLOR.BlueBDMS,
  } as CSSProperties,
  text_bluebdms_bold_20: {
    margin: "0",
    fontSize: "20px",
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    color: COLOR.BlueBDMS,
  } as CSSProperties,
  text_bluebdms_20: {
    margin: "15px 0 0 0 ",
    fontSize: "20px",
    alignItems: "center",
    display: "flex",
    color: COLOR.BlueBDMS,
  } as CSSProperties,
  divIcon: {
    display: "flex",
    margin: "25px 0px",
    alignItems: "center",
  } as CSSProperties,
  divImage: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0 0 0",
  } as CSSProperties,
};

type DrugDetailProps = {
  onClose: Function;
  medicationItem: any;
  selectedHospital: any;
  onEvent: (e: any) => any;
};

const DrugDetail: React.FC<DrugDetailProps> = (props) => {
  const [openYoutube, setOpenYoutube] = useState<boolean>(false);
  const [accordion, setAccordion] = useState<boolean>(false);

  const handleChange = () => {
    setAccordion(!accordion);
  };

  const handleOpenLink = (url:string, type: String) => {
    props.onEvent({
      message: "handleOpenLink",
      params: { url: url ,type: type},
    });
  };

  const handleDownloadFile = () => {
    // const url = "apis/PRX/v3/lab_report_file/?en="+latestVisitNumber+"&hospital="+props.selectedHospital?.code;
    console.log("globalThis", globalThis);
    console.log("----- typeof globalThis.mobile", typeof globalThis.mobile);

    // props.onEvent({
    //   message: "handleDownloadLabResult",
    //   params: { url: url, filename: `.pdf` },
    // });

    // handleCloseModConfirm();
  };

  return (
    <div style={{ padding: "10px 10px 30px 10px" }}>
      {/* props.medicationItem.nodata field ที่ยังไม่ได้แอดเข้ามาแต่ต้องมี  */}
      <div style={{ position: "absolute" }}>
        <Button
          icon
          size="large"
          onClick={props.onClose}
          style={styles.button_back}
        >
          <Icon name="angle left" />
        </Button>
      </div>
      <div style={{ ...styles.parentbox }}>
        <div style={{ margin: " 25px 0 10px" }}>
          <div style={{ ...styles.title, margin: "0px 10px" }}>
            {"Drug Detail "}
            {/* <FormattedMessage id="bplusClinicKey937" /> */}
          </div>
          <Divider
            style={{ background: "red", marginTop: "5px", marginBottom: "5px" }}
            variant="middle"
          />
        </div>
      </div>
      <div style={{ padding: "10px 10px 10px 30px" }}>
        <div style={styles.text_bluebdms_bold_20}>
          {props.medicationItem?.name || "-"}
        </div>
        <div style={styles.text_bluebdms_20}>
          {props.medicationItem?.nodata || "-"}
        </div>
        <div
          style={{ ...styles.divImage, minWidth: "100px", minHeight: "100px" }}
        >
          <div style={{ ...styles.divImage, margin: "0px" }}>
            <Image
              src={
                props.medicationItem?.image ||
                "/images/drug/icon_no_medication_lightblue.png"
              }
            />
          </div>
        </div>
        <div style={styles.divIcon}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={() => handleOpenLink(props.medicationItem?.media_link,"video")}
          >
            <div style={{ width: "48.75px" }}>
              <Image src="/images/icon_youtube.png" />
            </div>
            <div style={styles.text_bluebdms_bold}>How to use</div>
          </div>
          <div
            style={{ width: "100%", display: "flex" }}
            onClick={() => handleOpenLink(props.medicationItem?.pi_my_med_url,"link")}
          >
            <div style={{ width: "33px", marginLeft: "20px" }}>
              <Image src="/images/icon_document.png" />
            </div>
            <div style={styles.text_bluebdms_bold}>Drug Fact</div>
          </div>
        </div>
        <div style={styles.text_lightBlueBDMS_bold}>Indication : </div>
        <div style={styles.text_lightBlue}>
          {props.medicationItem?.indication || "-"}
        </div>
        <div style={styles.text_lightBlueBDMS_bold}>Side effect : </div>
        <div style={styles.text_lightBlue}>
          {props.medicationItem?.med_side_effect || "-"}
        </div>
        <div style={styles.text_lightBlueBDMS_bold}> Precaution : </div>
        <div style={styles.text_lightBlue}>
          {props.medicationItem?.med_need_before || "-"}
        </div>
        <div style={styles.text_lightBlueBDMS_bold}>Storage :</div>
        <div style={styles.text_lightBlue}>
          {props.medicationItem?.med_store || "-"}
        </div>
        <div style={styles.text_lightBlueBDMS_bold}>Description : </div>
        <div style={styles.text_lightBlue}>
          {props.medicationItem?.med_detail || "-"}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DrugDetail);
