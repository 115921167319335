import React from "react";

// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

// Ux
import { CardCenterGroupListProps } from "./CardCenterGroupList";

// Interface
import { CenterGroupType } from "./Types";

type CardCenterGroupContentProps = {
  data?: CenterGroupType;
  active?: boolean;
} & Pick<CardCenterGroupListProps, "onSelect" | "hideShadow" | "loading">;

// Styles
const COLORS = {
  shadow: "0px 4px 8px 0px rgba(44, 44, 44, 0.08)",
  very_light_blue: "rgba(1, 71, 163, 0.05)",
  grey: "rgba(57, 57, 57, 1)",
  border: "rgba(233, 233, 233, 1)",
  primary: "var(--blue-bdms-color)",
  bg: "#ECF4FF",
};

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const cardStyles = {
  "&.MuiPaper-root": {
    boxShadow: COLORS.shadow,
    height: "9.625rem",
    borderRadius: "8px",
    "& button": {
      height: "100%",
    },
    "& .MuiCardContent-root": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "14px 12px 12px",
    },
    "& .box-image": {
      background: COLORS.very_light_blue,
      borderRadius: "8px",
      width: "2.8438rem",
      height: "2.8438rem",
      ...styles.center,
      "& img": {
        width: "2.0313rem",
        height: "2.0313rem",
      },
    },
    "& .title": {
      fontWeight: "bold",
      color: COLORS.grey,
      fontSize: ".8438rem",
      margin: 0,
    },
  },
  "&.active": {
    "&.MuiPaper-root": {
      border: `1px solid ${COLORS.primary} !important`,
      backgroundColor: COLORS.bg,
      color: COLORS.primary,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  card: cardStyles,
}));

const CardCenterGroupContent = (props: CardCenterGroupContentProps) => {
  const classes = useStyles();

  const handleSelect = () => {
    props.onSelect?.(props.data as any);
  };

  return (
    <Card
      className={`${classes.card}${props.active ? " active" : ""}`}
      style={
        props.hideShadow
          ? {
              boxShadow: "none",
              border: props.loading ? "none" : `1px solid ${COLORS.border}`,
            }
          : {}
      }
      onClick={handleSelect}
    >
      <CardActionArea>
        <CardContent>
          <div className="box-image">
            {props.loading ? (
              <Skeleton
                animation="pulse"
                variant="rounded"
                width={40}
                height={40}
              />
            ) : (
              <img src={props.data?.image} />
            )}
          </div>
          {props.loading ? (
            <div>
              <Skeleton
                animation="pulse"
                variant="text"
                width={"75%"}
                height={16}
              />
              <Skeleton
                animation="pulse"
                variant="text"
                width={"100%"}
                height={16}
              />
            </div>
          ) : (
            <div className="title">{props.data?.name}</div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardCenterGroupContent);
