import React, { CSSProperties, useEffect, forwardRef, useState } from "react";
import moment from "moment";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Grid, Header, Icon, Image, Modal } from "semantic-ui-react";

import "./MainLabResultCSS.scss";
import CardDetail from "./CardLabDetail";

import Avatar from "@mui/material/Avatar";
import { Dialog, Slide } from "@material-ui/core";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  flexcenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10px 0px",
  } as CSSProperties,
  card: {
    width: "100%",
    height: "120px",
    borderRadius: "10px",
    padding: "15px",
  } as CSSProperties,
  card_doctor: {
    borderRadius: "15px",
    padding: "15px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: "white",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_DarkGray_14_2_line: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16_noellipsis: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
};

type VisitDateProps = {
  onEvent: any;
  textSearch: string;
  visitDateItems?: any;
  selectedHospital?: any;
  pdfDownloadStatus?: boolean;
};

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const VisitDate: React.FC<VisitDateProps> = (props) => {
  const [thirtyDays, setThirtyDays] = useState<any | undefined>(undefined);
  const [lastYears, setLastYears] = useState<any | undefined>(undefined);
  const [openCard, setOpenCard] = useState<boolean>(false);
  const [labData, setLabData] = useState<any | undefined>("");

  const mobvisit = [
    {
      name: "Dr. PAT PHATRAPAKDEE",
      date: "07 Jun. 2022, 10:30",
      hospital: "Bangkok Hospital Headquaters ",
      depatment: "Diabetes and Endocrine Center",
    },
  ];

  useEffect(() => {
    if (props.visitDateItems) {
      const newThirtyDay: any[] = [];
      const newLastYear: any[] = [];
      props.visitDateItems.forEach((item: any) => {
        let toDate = moment();
        const lastYear = toDate
          .clone()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        const thirtyDay = toDate
          .clone()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        const date = moment(item.started).format("YYYY-MM-DD");
        if (moment(date).isAfter(thirtyDay)) {
          newThirtyDay.push({ ...item });
        } else if (moment(date).isAfter(lastYear)) {
          newLastYear.push({ ...item });
        } else {
          newLastYear.push({ ...item });
        }
      });

      if (newThirtyDay.length > 0) {
        setThirtyDays(handleSetMultiDoctor(newThirtyDay) || undefined);
      } else {
        setThirtyDays(undefined);
      }

      if (newLastYear.length > 0) {
        setLastYears(handleSetMultiDoctor(newLastYear) || undefined);
      } else {
        setLastYears(undefined);
      }
    }
  }, [props.visitDateItems]);

  const handleSetMultiDoctor = (data: any) => {
    // console.log(
    //   "saika check SetMultiDoctor ",
    //   data
    // );
    let lastYearMultiDoctor: any[] = [];
    data.forEach((visitItems: any) => {
      const consultDoctors = visitItems.consult_doctors;
      if (
        consultDoctors.filter(
          (item: any) => item?.doctor?.code === visitItems?.doctor_details?.code
        ).length < 1
      ) {
        consultDoctors.push({
          doctor: visitItems.doctor_details,
          division_name: visitItems.division_name,
          arrived_dt: visitItems.started,
          main: true,
        });
      }
      consultDoctors.sort((a: any, b: any) => (a.main ? -1 : 1));
      const multiDoctor = consultDoctors.map((doctors: any) => {
        let labOrder: any[] = (visitItems?.lab_order || []).map((labItem: any) => {
          const childrenLabCheck = (labItem.children || [])
            .map((childLab: any) => {
              if (
                childLab?.history_results.some(
                  (item: any) => item?.doctor_code === doctors?.doctor?.code
                )
              ) {
                return childLab;
              } else if (
                childLab.history_results.some(
                  (item: any) => item.doctor_code == null
                ) &&
                doctors?.main
              ) {
                return childLab;
              }
            })
            .filter((item: any) => item != undefined);
          return { ...labItem, children: childrenLabCheck };
        });

        if (visitItems?.consult_doctors && visitItems?.consult_doctors) {
          labOrder = (visitItems?.lab_order || [])
        } 

        return {
          ...visitItems,
          doctor: doctors?.doctor?.id,
          doctor_details: doctors?.doctor,
          lab_order: labOrder.filter((item: any) => item?.children?.length > 0),
          started: doctors?.arrived_dt,
        };
      });
      // console.log(
      //   "saika check multiDoctor ",
      //   multiDoctor
      // );
      multiDoctor.forEach((item: any) => lastYearMultiDoctor.push(item));
    });
    // console.log("saika check lastYearMultiDoctor ", lastYearMultiDoctor);
    return lastYearMultiDoctor.sort((a:any,b:any )=> a.started < b.started ? 1 : -1);
  };

  const handleOpenCard = (item: any) => {
    console.log("saika item : ", item);
    setLabData(item);
    setOpenCard(true);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  return (
    <div style={{ padding: "10px" }}>
      {thirtyDays == null && lastYears == null && (
        <Header
          as="h3"
          color="grey"
          style={{ textAlign: "center", marginTop: "15px" }}
        >
          <FormattedMessage id="common.no_data_labresult" />
        </Header>
      )}

      {thirtyDays ? (
        <>
          <div style={{ ...styles.text_DarkGray_14, marginLeft: "20px" }}>
            {"Last 30 day"}
          </div>
          {thirtyDays ? (
            thirtyDays
              .filter(
                (item: any) =>
                  item.division_name
                    ?.toLowerCase()
                    .search(props.textSearch.toLowerCase()) !== -1
              )
              .map((item: any, index: any) => (
                <div
                  style={styles.card_doctor}
                  key={index}
                  onClick={() => handleOpenCard(item)}
                >
                  <Grid style={{ margin: "0px" }}>
                    <Grid.Column
                      width={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                      }}
                    >
                      <Avatar
                        src={
                          item?.doctor_details?.image ||
                          "images/Feed/doctor.png"
                        }
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Grid.Column>
                    <Grid.Column width={13} style={{ padding: "0 15px" }}>
                      {/* date time */}
                      <Grid.Row>
                        <div style={styles.text_bluebdms_16}>
                          {moment(item.started).format("DD MMM YYYY, HH:mm") ||
                            "-"}
                        </div>
                      </Grid.Row>
                      {/* full name */}
                      <Grid.Row>
                        <div
                          style={{
                            ...styles.text_DarkGray_14,
                            margin: "10px 0",
                          }}
                        >
                          {item?.doctor_details?.full_name || ""}
                        </div>
                      </Grid.Row>
                      {/* hospital name */}
                      <Grid.Row>
                        <div style={styles.text_DarkGray_14}>
                          {item.hospital_name ||
                            props.selectedHospital?.name ||
                            ""}
                        </div>
                      </Grid.Row>
                      {/* division name  */}
                      <Grid.Row>
                        <div style={styles.text_DarkGray_14}>
                          {item.division_name}
                        </div>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>
              ))
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}

      {lastYears ? (
        <>
          <div style={{ ...styles.text_DarkGray_14, marginLeft: "20px" }}>
            {"Last Year"}
          </div>
          {lastYears ? (
            lastYears
              .filter(
                (item: any) =>
                  item.division_name
                    ?.toLowerCase()
                    .search(props.textSearch.toLowerCase()) !== -1
              )
              .map((item: any, index: any) => (
                <div
                  style={styles.card_doctor}
                  key={index}
                  onClick={() => handleOpenCard(item)}
                >
                  <Grid style={{ margin: "0px" }}>
                    <Grid.Column
                      width={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                      }}
                    >
                      <Avatar
                        src={
                          item?.doctor_details?.image ||
                          "images/Feed/doctor.png"
                        }
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Grid.Column>
                    <Grid.Column width={13} style={{ padding: "0 15px" }}>
                      {/* date time */}
                      <Grid.Row>
                        <div style={styles.text_bluebdms_16}>
                          {moment(item.started).format("DD MMM YYYY, HH:mm") ||
                            "-"}
                        </div>
                      </Grid.Row>
                      {/* full name */}
                      <Grid.Row>
                        <div
                          style={{
                            ...styles.text_DarkGray_14_2_line,
                            margin: "10px 0",
                          }}
                        >
                          {item?.doctor_details?.full_name || ""}
                        </div>
                      </Grid.Row>
                      {/* hospital name */}
                      <Grid.Row>
                        <div style={styles.text_DarkGray_14}>
                          {item.hospital_name ||
                            props.selectedHospital?.name ||
                            ""}
                        </div>
                      </Grid.Row>
                      {/* division name  */}
                      <Grid.Row>
                        <div style={styles.text_DarkGray_14}>
                          {item.division_name}
                        </div>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>
              ))
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}

      <Dialog
        fullScreen
        open={openCard}
        onClose={() => setOpenCard(false)}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <div style={{ width: "100%" }}>
          <CardDetail
            onEvent={props.onEvent}
            onClose={handleCloseCard}
            labData={labData}
            selectedHospital={props.selectedHospital}
            pdfDownloadStatus={props.pdfDownloadStatus}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(VisitDate);
