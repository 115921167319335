import { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import React, { useState, useMemo, useEffect, useRef, Ref } from "react";
import { FormattedMessage } from "react-intl";
import {
  Image,
  Grid,
  Button,
  Dimmer,
  Loader,
  Sidebar,
  Search,
  Icon,
} from "semantic-ui-react";
import { convertToBEDate } from "react-lib/utils/tsUtils";
import { formatDate } from "react-lib/utils";

//component
import ChatList from "./ChatListDesktop";
import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import DiagForm from "patient-lib/DiagForm";
import CONFIG from "config/config";
// Use styles
import "./TeleDesktopCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  gary: "#797878",
  lightGary: "#D9D9D9",
};

const styles = {
  title_logo: {
    fontSize: "20px",
    color: COLOR.primary,
    alignItems: "center",
    fontWeight: "bold",
    display: "flex",
    marginLeft: "20px",
  } as CSSProperties,
  payment_titlebar: {
    background: COLOR.blue,
    padding: "5px 0px",
    color: "white",
    margin: "10px 0px",
  } as CSSProperties,
  button_pay: {
    padding: "10px",
    color: "white",
    background: COLOR.blue,
  } as CSSProperties,
};

type ChatTeleDeskTopProps = {
  chatListRef: Ref<unknown> | undefined;
  chatBoxRef: Ref<unknown> | undefined;
  onEvent: (e: any) => any;
  apiToken?: any;
  chatController: any;
  diagFormController: any;
  userId?: any;
  channelList?: any;
  loadChannelList: () => any;
  followDivisionProfile: (item: any) => any;
  unfollowChannelList: any;
  isLoading?: any;
  handleNavigationMessage?: any;
  patientInfo?: any;
  setProp: any;
  focusSelectMenu?: any;
  handleSetFocusSelect: () => any;
  lockChatList: boolean;
  handleSelectChat: (
    id: any,
    division: any,
    channel_Name: any,
    icon: any
  ) => any;
  chatListSelect?: any;
  selectChat: selectChatType;
  isDiag: {
    id: number;
    isOpen: boolean;
  };
  onCloseDiagForm: Function;
};

type selectChatType = {
  id: string;
  division: string;
  channel_Name: string;
  icon: string;
};

const ChatTeleDeskTop = (props: ChatTeleDeskTopProps) => {
  const [searchBox, setSearchBox] = useState<string>("");
  const [keyWord, setkeyWord] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  const handleSearchChat = (value: any) => {
    setSearchBox(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setkeyWord(value);
      }, 800)
    );
  };

  return (
    <Sidebar.Pusher>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: props.lockChatList ? "300px auto" : "1fr 1.5fr",
          height: "100vh",
          width: "100%",
          overflowY: "hidden",
        }}
      >
        {/*----------------- Search & logo --------------------- */}

        <div
          style={{
            gridColumn: 1,
            overflowY: "hidden",
          }}
        >
          <div style={{ display: "flex", margin: "10px" }}>
            <Image src="/logo512.png" style={{ width: "35px" }} />
            <div style={styles.title_logo}>My B+</div>
          </div>
          <div className="chat_search">
            <Search
              input={{ icon: "search", iconPosition: "right" }}
              onSearchChange={(e, { value }) => {
                handleSearchChat(value);
              }}
              placeholder="Search..."
              showNoResults={false}
              value={searchBox}
            />
          </div>

          {/*----------------- Chat list --------------------- */}

          <ChatList
            ref={props.chatListRef}
            apiToken={props.apiToken}
            userId={props.userId}
            chatController={props.chatController}
            // noApiToken={this.handleGoToLogin}
            isLoading={props.isLoading}
            loadChannelList={props.loadChannelList}
            channelList={props.channelList}
            unfollowChannelList={props.unfollowChannelList}
            followDivisionProfile={props.followDivisionProfile}
            onChannelSelected={props.handleSelectChat}
            keyWordSearch={keyWord}
            menuSelected={props.chatListSelect}
            setProp={props.setProp}
            onEvent={function (e: any) {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
        <div
          style={{
            gridColumn: 2,
            overflowY: "hidden",
          }}
        >
          {/*----------------- ChatBox --------------------- */}

          <>
            <div
              style={{
                display: "flex",
                margin: "10px",
                justifyContent: "space-between",
              }}
            >
              <div style={styles.title_logo}>
                {props.selectChat.channel_Name}
              </div>
              <div onClick={() => props.onCloseDiagForm()}>
                {props.isDiag.isOpen && props.isDiag.id != 0 && (
                  <Icon name={"close"} />
                )}
              </div>
            </div>
            {props.isDiag.isOpen && props.isDiag.id != 0 ? (
              <div
                style={{
                  height: "calc(100% - 42px)",
                  overflowY: "auto",
                }}
              >
                <DiagForm
                  {...props}
                  match={{
                    params: { id: props.isDiag.id},
                  }}
                  noApiToken={props.onCloseDiagForm}
                  apiToken={props.apiToken}
                  controller={props.diagFormController}
                  onFinished={props.onCloseDiagForm}
                />
              </div>
            ) : (
              <ChatBox
                acceptContentHTML={true}
                selectedPatient={props.patientInfo}
                // {...props}
                match={{
                  params: { chatChannelId: props.selectChat.id },
                }}
                ref={props.chatBoxRef}
                controller={props.chatController}
                division={props.selectChat.division}
                apiToken={props.apiToken}
                userId={props.userId}
                useNormalTextAreaKey={false}
                onSetBackToApp={(value: any) => {
                  console.log(" Value", value);
                }}
                icon={props.selectChat.icon || "/logo512.png"}
                onNavigationMessage={props.handleNavigationMessage}
                isTeleDesktop={true}
                // onOpenVideoCall={}
                downloadFile={CONFIG.ENABLE_CHATBOX_DOWNLOAD_FILE}
              />
            )}
          </>
        </div>
      </div>
    </Sidebar.Pusher>
  );
};

export default React.memo(ChatTeleDeskTop);
