import React, { CSSProperties } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

import SnackMessage from "../../clinic/SnackMessage";

import DMEmotionRestChart from "../../clinic/DMEmotionRestChart";

const styles = {};

type WebDMEmotionRestProps = {
  onEvent: any;
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  emotionRestHistoryList: any[];
};

const WebDMEmotionRestInitial: WebDMEmotionRestProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  emotionRestHistoryList: [],
};

const WebDMEmotionRest: React.FC<WebDMEmotionRestProps> = (props) => {
  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/detail/emotion.png"
        />
        <span><FormattedMessage id="bplusClinicKey180" /></span>
      </div>

      <div className="sub-layout dm">
        <DMEmotionRestChart
          onEvent={props.onEvent}
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          height={100}
          emotionRestHistoryList={props.emotionRestHistoryList}
          hideFooterChart={true}
          showPatientNote={true}
        />
      </div>
    </Segment>
  );
};

WebDMEmotionRest.defaultProps = WebDMEmotionRestInitial;

export default React.memo(WebDMEmotionRest);
