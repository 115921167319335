import React, { CSSProperties, useEffect, useState, useMemo } from "react";
import {
  Dimmer,
  Icon,
  Loader,
  Form,
  Image,
  Button,
  Dropdown,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import ReactTable from "react-table-6";
import moment from "moment";

// Interface
import { BACKEND_DATE_FORMAT } from "../../MobClinicInterface";
import { Event, State } from "../../ManageInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import { HeaderChart } from "bplus-lib/clinic/DMEmotionRestChart";

// Constant
import { durationOptions } from "../../clinic/DMExerciseChart";

const DATE_FORMAT = "DD/MM/YYYY";

const COLOR_CODE = {
  cyan: "#DFF5FC",
  darkBlue: "#0072BC",
  blue: "var(--text-light-blue)",
  darkGrey: "#4F4F4F",
  medium_blue: "var(--bg-medium-blue)",
};

const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  } as CSSProperties,
  divDetail: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 20px",
  } as CSSProperties,
  textCenter: {
    textAlign: "center",
  } as CSSProperties,
  flexRowCenter: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  unSelectedCircle: {
    width: "100px",
    height: "100px",
    borderRadius: "75px",
    border: `0.2px solid ${COLOR_CODE.blue}`,
  } as CSSProperties,
};

type WebMomBreastFeedingProps = {
  onEvent: (e: Event) => any;
  breastFeedingFilterDate?: moment.Moment;
} & Pick<
  State,
  | "breastfeedingList"
  | "loadingStatus"
  | "errorMessage"
  | "successMessage"
  | "selectedPatient"
>;

const WebMomBreastFeedingInitial: WebMomBreastFeedingProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  breastFeedingFilterDate: moment(),
  breastfeedingList: [],
};

const WebMomBreastFeeding: React.FC<WebMomBreastFeedingProps> = (props) => {
  const [duration, setDuration] = useState("w");
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-1", "w")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    props.onEvent({
      message: "GetBreastfeedingList",
      params: {
        start_measure: startDate.format(BACKEND_DATE_FORMAT),
        end_measure: endDate.format(BACKEND_DATE_FORMAT),
      },
    });
  }, [startDate, endDate]);

  const columnsRecord = useMemo(
    () => [
      {
        Header: "Date",
        minWidth: 80,
        accessor: "measure_date",
        Cell: ({ row }: any = {}) => {
          return (
            <div
              style={{
                ...styles.textCenter,
                color: COLOR_CODE.medium_blue,
                fontWeight: "bold",
              }}
            >
              {moment(row.measure_date).format("ddd, DD MMM YYYY")}
            </div>
          );
        },
      },
      {
        Header: "Time",
        minWidth: 75,
        accessor: "measure_time",
        Cell: ({ row }: any = {}) => {
          return (
            <div style={{ ...styles.textCenter, fontWeight: "bold" }}>
              {moment(row.measure_date).format("HH:mm:ss")}
            </div>
          );
        },
      },
      {
        Header: "Gestational Age",
        minWidth: 75,
        accessor: "ga",
        Cell: (row: any) => {
          return (
            <div style={{ ...styles.textCenter, fontWeight: "bold" }}>
              {row?.original?.ga}
            </div>
          );
        },
      },
      {
        Header: "Breast Feeding",
        minWidth: 75,
        accessor: "data",
        Cell: ({ row }: any = {}) => {
          return (
            <div style={styles.textCenter}>
              <div style={styles.flexRowCenter}>
                <Image
                  src={`/images/breastfeeding/breast_feeding_${row.data.key}.jpg`}
                  style={styles.unSelectedCircle}
                />
              </div>
              <Button
                color="blue"
                size="tiny"
                style={{ borderRadius: "10px", marginTop: "5px" }}
              >
                {row.data.text}
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleChangeStepDateRecord = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(-1, duration);
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(1, duration);
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    setDuration(value);
    setStartDate(moment().add("-1", value));
    setEndDate(moment());
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="breastfeeding.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/mom/breastfeeding.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey97" />
        </span>
      </div>
      <div className="sub-layout">
        <HeaderChart
          startDate={startDate}
          endDate={endDate}
          durationOptions={durationOptions}
          selectedDuration={duration}
          onChangeDuration={handleChangeDuration}
          onChangeDateRange={handleChangeStepDateRecord}
          // labelStyle={{ padding: "10px 30px 5px 18px" }}
        />

        <ReactTable
          className="weight-table-custom web-mom"
          data={props.breastfeedingList}
          columns={columnsRecord}
          pageSize={
            (props.breastfeedingList?.length || 0) >= 4
              ? props.breastfeedingList?.length
              : 4
          }
          style={{ marginTop: "20px", height: "425px" }}
          showPagination={false}
          resizable={false}
          getTheadThProps={handleGetTheadThProps}
        />
      </div>
    </div>
  );
};

WebMomBreastFeeding.defaultProps = WebMomBreastFeedingInitial;

export default React.memo(WebMomBreastFeeding);
