import React, {
  useState,
  useRef,
  useEffect,
  memo,
  useMemo,
  MutableRefObject,
} from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
// MUI
import MuiRadio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useHistory } from "react-router-dom";
import ModInfo from "react-lib/apps/common/ModInfo";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// config
import CONFIG from "../config/config";
import Cookies from "js-cookie";

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  light_grey: "rgba(196, 196, 196, 1)",
  very_light_grey: "rgba(121, 120, 120, 0.15)",
};

export const cardStyles = {
  borderRadius: "8px !important",
  boxShadow: "none !important",
  border: `1px solid ${COLORS.very_light_grey} !important`,
  backgroundColor: "transparent !important",
  width: "100%",
  margin: "1rem 0",
  "& .MuiCardActionArea-focusHighlight": {
    backgroundColor: "white",
  },
  "& .MuiCardContent-root": {
    padding: "14px 16px",
    "& > .header": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
    },
  },
  "&.active": {
    border: `1px solid ${COLORS.blue} !important`,
  },
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    textTransform: "unset",
    backgroundColor: COLORS.blue,
    "&:hover": {
      backgroundColor: COLORS.blue,
    },
    borderRadius: "15px",
    padding: "8px 0",
    boxShadow: "none",
    margin: "5px",
  },
  checkbox: {
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
  card: cardStyles,
  checkbox_primary: {
    color: COLORS.blue,
    marginTop: "0 !important",
    marginLeft: "17px !important",
    width: "max-content",
    "& .MuiCheckbox-root": {
      marginTop: "-2px",
      transform: "scale(1.2)",
      "&.Mui-checked .MuiSvgIcon-root": {
        color: COLORS.blue,
      },
    },
  },
}));

const LANGUAGE = {
  TH: "th-TH",
  EN_US: "en-US",
};

const IMAGES = {
  cancel_hn: "/images/telephar/cancel-hn.png",
  consent: "/images/register/consent.png",
};

const OPTIONS_LABEL = {
  consent: ["ยอมรับ", "ยินยอม", "consent", "รับทราบ", "acknowledged"],
  refuse: ["ไม่ยินยอม", "Refuse", "ไม่ยอมรับ", "refuse", "acknowledged"],
};

const ConsentForm = (props: any) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState<Record<string, any>>({});
  const [updateConsent, setUpdateConsent] = useState<number>(0);
  const [diagRuleContent, setDiagRuleContent] = useState<Record<string, any>>(
    {}
  );
  const [showModal, setShowModal] = useState<{
    index?: number;
    label?: string;
    modal?: boolean;
    telemed?: boolean;
    choice?: string;
    options?: any[];
  }>({});
  const [openModal, setOpenModal] = useState(false);
  const [modError, setModError] = useState<any>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // -const [modalDetail, setModalDetail] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [formLanguage, setFormLanguage] = useState<any>(null);
  const [currentForm, setCurrentForm] = useState<Record<string, any>>({});
  const [division, setDivision] = useState<Record<string, any>>({});
  const [diagFormId, setDiagFormId] = useState(null);
  const [editAnswer, setEditAnswer] = useState<Record<string, any>>({});
  const [userLang, setUserLang] = useState<string | null>(null);
  const [formLangText, setFormLangText] = useState("th-TH");
  // -const [isBottom, setIsBottom] = useState<boolean>(false);

  const history = useHistory();
  const apiToken = Cookies.get("apiToken");
  const cookiesFormLang = Cookies.get("formLang");

  const isMounted = useRef(true);
  const consentFormRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isBottomRef = useRef<boolean>(false);
  const isAgreeAllRef = useRef<boolean>(false);

  useEffect(() => {
    handleSetDefaultLang();
    if (props.match.params.page || props.match.params.page !== 1) {
      history.replace({
        pathname: "/consent-form/" + props.match.params.id + "/",
        state: history.location.state,
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const bodyRef = consentFormRef.current?.closest("body") as HTMLBodyElement;

    if (!bodyRef) {
      return;
    }

    const handleScroll = () => {
      const isBottom =
        window.scrollY + bodyRef.offsetHeight + 15 >= bodyRef.scrollHeight;

      if (isBottom && !isBottomRef.current) {
        isBottomRef.current = true;
        // -setIsBottom(true);
      }
    };

    handleScroll();

    bodyRef.onscroll = handleScroll;
    return () => {
      bodyRef.onscroll = null;
      isBottomRef.current = false;
      // -setIsBottom(false);
    };
  }, [props.match.params.page]);

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }
  }, [props.apiToken, apiToken]);

  useEffect(() => {
    if (props.match?.params?.id && (props.apiToken || apiToken)) {
      getPublishDiagRuleDetail({ publishDiagRuleId: props.match.params.id });
    }

    if (props.apiToken || apiToken) {
      getProxyPatientHasDivision();
    }
  }, [props.match.params.id, props.apiToken, apiToken]);

  useEffect(() => {
    if (diagFormId) {
      getDiagFormDetail();
    }
  }, [diagFormId]);

  useEffect(() => {
    if (division.id) {
      getPublishDiagRuleCheck();
    }
  }, [division.id]);

  useEffect(() => {
    setShowModal({});
  }, [props.match.params.page]);

  useEffect(() => {
    if (cookiesFormLang) {
      return setFormLanguage(cookiesFormLang);
    }
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        (item) => item.parent_id === diagRuleContent[0].id
      );
      if (obj) {
        setFormLanguage(obj.id);
      }
    } else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [diagRuleContent, cookiesFormLang]);

  useEffect(() => {
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        (item) => item.id == formLanguage
      );

      if (obj) {
        setCurrentForm(obj);
        props.onFormLanguageChange(obj.label);

        let language = "th-TH";

        if (
          obj.label &&
          typeof obj.label === "string" &&
          ["en", "en-us", "eng", "english"].includes(obj.label.toLowerCase())
        ) {
          language = "en-US";
        }
        setFormLangText(language);
      }
    } else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [formLanguage, diagRuleContent]);

  useEffect(() => {
    let firstAnswer = editAnswer?.[0] ? editAnswer[0].answer : null;
    let obj = Object.values(editAnswer).find(
      (item) => item.label === firstAnswer
    );

    if (currentForm?.extra_items && (!obj || currentForm.id != obj.id)) {
      let defaultAnswer: Record<string, any> = {};
      currentForm.extra_items.forEach((item: any, index: number) => {
        // item.type === "select" && item.default
        if (item.type) {
          defaultAnswer[item.label] = {
            value: item.default,
            type: item.type,
          };
        }
      });

      setAnswer(defaultAnswer);
      console.log("saika ~ defaultAnswer:", defaultAnswer);
    } else {
      handleSetEditAnswer();
    }
  }, [currentForm, editAnswer]);

  useEffect(() => {
    if (Object.keys(editAnswer).length === 0) {
      if (userLang === "th-TH") {
        let obj = Object.values(diagRuleContent).find(
          (item) => item.label === "ไทย"
        );
        if (obj) {
          return setFormLanguage(obj.id);
        }
      } else if (userLang === "en-US") {
        let obj = Object.values(diagRuleContent).find(
          (item) => item.label.toLowerCase() === "English".toLowerCase()
        );
        if (obj) {
          return setFormLanguage(obj.id);
        }
      }
    } else {
      let obj = Object.values(diagRuleContent).find(
        (item) => editAnswer[0] && item.label === editAnswer[0].answer
      );
      if (obj) {
        setFormLanguage(obj.id);
      }
    }
  }, [diagRuleContent, userLang, editAnswer]);

  useEffect(() => {
    if (isAgreeAllRef.current) {
      isAgreeAllRef.current = false;
      handleFinishForm();
    }
  }, [answer]);

  useEffect(() => {
    if (showModal.index || showModal.index === 0) {
      // -let arrAfterItem = currentForm?.extra_items.slice(showModal.index + 1);
      // -let modalObj = arrAfterItem.find((item: any) => item.type === "Modal");

      // -setModalDetail(modalObj);
      setOpenModal(true);
    }
  }, [showModal.index]);

  const displaySelect = useMemo(() => {
    const page = props.match.params.page;
    if ((!page || page == 1) && Object.keys(diagRuleContent).length > 1) {
      let choices = Object.values(diagRuleContent).filter(
        (item) => item.parent_id === diagRuleContent[0].id
      );
      return choices
        .map((item, index) => {
          return item.label;
        })
        .filter((item) => Array.isArray(item));
    }

    if (!currentForm?.extra_items) {
      return [];
    }

    return currentForm.extra_items
      .filter((item: any) => {
        if (Object.keys(diagRuleContent).length > 1) {
          return item.page == (page ? parseInt(page) - 1 : 0);
        } else {
          return item.page == (page ? parseInt(page) : 1);
        }
      })
      .map((item: any, index: number) => {
        if (item.type === "Modal") {
          return null;
        }
        return (
          item.type === "Select" &&
          item.options?.map((optionItem: any, optionIndex: number) => {
            return item.label;
          })
        );
      })
      .filter((item: any) => Array.isArray(item));
  }, [currentForm, props.match, diagRuleContent]);

  const formattedAnswer = useMemo(() => {
    let formatAns = { ...diagRuleContent };
    const consent = currentForm || {};
    const answers: any[] = [];
    const choices: any[] = [];

    if (!formatAns[0] || !formatAns[consent.id]) {
      return { bylabel: answers, bychoice: choices };
    }

    for (let ansItem of Object.keys(answer || {})) {
      const value = answer[ansItem]?.value;

      if (value) {
        answers.push(ansItem);
        choices.push(value);
      }
    }

    return {
      bylabel: answers,
      bychoice: choices,
    };
  }, [diagRuleContent, currentForm, answer]);

  const formatAns = useMemo(() => {
    return formattedAnswer.bylabel;
  }, [formattedAnswer]);

  const allowNextPage = useMemo(() => {
    return displaySelect.every((item: any) => formatAns.includes(item[0]));
  }, [displaySelect, formatAns]);

  const isAllConsent = useMemo(() => {
    return (
      !!allowNextPage &&
      formattedAnswer.bychoice.every((value) =>
        OPTIONS_LABEL.consent.includes(value)
      )
    );
  }, [formattedAnswer, allowNextPage]);

  const isEndPage = useMemo(() => {
    const currentPage = props.match.params.page
      ? parseInt(props.match.params.page)
      : 1;

    let totalPage = 0;

    if (diagRuleContent[0]) {
      if (typeof diagRuleContent[0].total_page === "undefined") {
        totalPage = 2;
      } else {
        totalPage = diagRuleContent[0].total_page;
      }
    }

    return currentPage === totalPage && totalPage === 2;
  }, [props.match.params.page, diagRuleContent]);

  const handleSetDefaultLang = () => {
    if (navigator && !cookiesFormLang) {
      let userLang = navigator.languages[0];

      if (userLang.toLowerCase().includes("en")) {
        userLang = LANGUAGE.EN_US;
      } else if (userLang.toLowerCase().includes("th")) {
        userLang = LANGUAGE.TH;
      } else {
        userLang = LANGUAGE.EN_US;
      }
      if (userLang) {
        setUserLang(userLang);
      }
    }
  };

  const handleSetEditAnswer = () => {
    if (Object.keys(editAnswer).length === 0) {
      setAnswer(editAnswer);
      return;
    }

    const firstAnswer = editAnswer[0]?.answer;
    const editAnswerValues = Object.values(editAnswer);
    const obj =
      editAnswerValues.find((item) => item.label === firstAnswer) ||
      editAnswerValues.find((item) => item.selected);

    if (!obj) {
      setAnswer(editAnswer);
      return;
    }

    setFormLanguage(obj.id);
    Cookies.set("formLang", obj.id);

    const filterByType = (item: any) => item.type === "Select";
    const items = (currentForm?.extra_items || []).filter(filterByType);
    const extraItems = obj.extra_items.filter(filterByType);

    const newObj: Record<string, any> = {};
    for (let i = 0; i < extraItems.length; i++) {
      const currentLabel = items?.[i]?.label;
      const label = extraItems[i]?.label || currentLabel;

      if (label && !props.modalOnRefuse) {
        newObj[label] = {
          value: extraItems[i]?.value,
          type: extraItems[i]?.type,
        };
      }
    }
    setAnswer(newObj);
  };

  const getProxyPatientHasDivision = async () => {
    setIsLoading(true);

    const [response] = await props.controller.getProxyPatientHasDivision({
      apiToken: apiToken || props.apiToken,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (response) {
        if (response.items.length > 0 && response.items[0].division) {
          setDivision(response.items[0].division);
        }
      }
    }
  };

  const getPublishDiagRuleCheck = async () => {
    setIsLoading(true);

    const [response] = await props.controller.getPublishDiagRuleCheck({
      apiToken: apiToken || props.apiToken,
      divisionId: division.id,
      code: "CS",
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (response) {
        if (response.diag_form) {
          setDiagFormId(response.diag_form);
        }
      }
    }
  };

  const getPublishDiagRuleDetail = async (params: any) => {
    setIsLoading(true);

    const [response, error] = await props.controller.getPublishDiagRuleDetail({
      publishDiagRuleId: props.match.params.id,
      apiToken: apiToken || props.apiToken,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (response?.content) {
        let json = JSON.parse(response.content);

        setDiagRuleContent(json);
      } else {
        setDiagRuleContent({});

        if (error.detail) {
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.showToast(error.detail);
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.showToast(error.detail);
          }
        }
      }
    }
  };

  const getDiagFormDetail = async () => {
    setIsLoading(true);

    const [response] = await props.controller.getDiagFormDetail({
      diagFormId,
      apiToken: apiToken || props.apiToken,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (response?.answers) {
        setUpdateConsent(diagFormId || 0)
        let ans = JSON.parse(response.answers);
        setEditAnswer(ans);
      }
    }
  };

  const createFirstPageChoices = () => {
    let choices = Object.values(diagRuleContent).filter(
      (item) => item.parent_id === diagRuleContent[0].id
    );
    return (
      <div className="content-item">
        <div>{diagRuleContent[0].label}</div>
        <div
          className="answer-options"
          style={{ textAlign: "left", marginTop: "1.5rem" }}
        >
          {choices.map((item, index) => {
            return (
              <div key={item.label}>
                <div>
                  <Card
                    className={`${classes.card} ${
                      formLanguage == item.id ? " active" : ""
                    }`}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      setFormLanguage(item.id);
                      Cookies.remove("formLang");
                      Cookies.set("formLang", item.id);
                    }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <div className="header">
                          <div>
                            <MuiRadio
                              className="smart-radio-button"
                              name="radio-buttons"
                              checked={formLanguage == item.id}
                            />
                          </div>
                          <div>{item.label}</div>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* #<Radio
                    label={item.label}
                    name={item.label}
                    value={item.id}
                    checked={formLanguage == item.id}
                    onChange={(e, { checked, value }) => {
                      setFormLanguage(value);
                      Cookies.remove("formLang");
                      Cookies.set("formLang", value);
                    }}
                  /> */}
                </div>
                {/* <br /> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const handleSelect = ({ index, item, value, options }: any) => {
    const ignore =
      !item.showModalIfNotOk && !!item.label?.match(/^<.*?>2.3 /g)?.length;

    const matching =
      item.label?.search(/^<.*?>1\. /g) >= 0 ||
      item.label?.search(/^<.*?>2\.1 /g) >= 0 ||
      item.label?.search(/^<.*?>2\.2 /g) >= 0;

    if (
      (item.showModalIfNotOk || (props.modalOnRefuse && !ignore) || matching) &&
      (value === "ไม่ยินยอม" ||
        value === "Refuse" ||
        value === "ไม่ยอมรับ" ||
        value === "refuse")
    ) {
      setAnswer({
        ...answer,
        [item.label]: {
          value,
          type: item.type,
        },
        options,
      });
      setShowModal({
        index,
        label: item.label,
        modal: props.modalOnRefuse,
        telemed: !item.showModalIfNotOk && props.modalOnRefuse,
        choice: /^<.*?>(\d\.\d?)/g.exec(item.label)?.[1] || "",
        options,
      });
    } else {
      setShowModal({});
    }

    setAnswer({
      ...answer,
      [item.label]: {
        value,
        type: item.type,
      },
    });
  };

  const createContent = () => {
    const allOptions = getAllAnswerOptions();

    if (allOptions?.firstPage) {
      return createFirstPageChoices(); // For handle select language
    }

    if (allOptions === null) {
      return;
    }

    return allOptions.map((item: any, index: number) => {
      if (item.type === "Modal") {
        return <React.Fragment key={`${item.label}_${item.type}`} />;
      }

      return (
        <div key={item.label} className="content-item">
          <div dangerouslySetInnerHTML={{ __html: item.label }} />
          <div
            className="answer-options"
            style={{
              marginTop:
                item.type === "Select" && !!item.options?.length ? "1rem" : "",
            }}
          >
            {item.type === "Select" &&
              item.options?.map((optionItem: any, optionIndex: number) => {
                const checked =
                  !!answer[item.label]?.value &&
                  answer[item.label]?.value === optionItem;

                return (
                  <span key={`${item.label}_${item.type}_${optionItem}`}>
                    <label
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        if (!checked) {
                          handleSelect({
                            index,
                            item,
                            value: optionItem,
                            options: item.options,
                          });
                        }
                      }}
                    >
                      <MuiRadio
                        className="smart-radio-button"
                        name="radio-buttons"
                        checked={checked}
                        style={{ marginTop: "-8px" }}
                      />
                      <label>{optionItem}</label>
                    </label>
                    {/* #<Radio
                      label={optionItem}
                      name={item.label}
                      value={optionItem}
                      checked={
                        !!answer[item.label]?.value &&
                        answer[item.label]?.value === optionItem
                      }
                      onChange={(e, { checked, value }) => {
                        handleSelect({ index, item, value });
                      }}
                    /> */}
                    &ensp;
                  </span>
                );
              })}
          </div>
          <br />
        </div>
      );
    });
  };

  const getAllAnswerOptions = () => {
    if (
      (!props.match.params.page || props.match.params.page == 1) &&
      Object.keys(diagRuleContent).length > 1
    ) {
      return { firstPage: true };
    }

    if (!currentForm?.extra_items) {
      return null;
    }

    return currentForm.extra_items.filter((item: any) => {
      if (Object.keys(diagRuleContent).length > 1) {
        return (
          item.page ==
          (props.match.params.page ? parseInt(props.match.params.page) - 1 : 0)
        );
      } else {
        return (
          item.page ==
          (props.match.params.page ? parseInt(props.match.params.page) : 1)
        );
      }
    });
  };

  const handleNextPage = () => {
    goToNextPage();
  };

  const goToNextPage = () => {
    let nextPage =
      (props.match.params.page ? parseInt(props.match.params.page) : 1) + 1;
    if (nextPage <= (diagRuleContent[0].total_page ?? 2)) {
      history.push({
        pathname: `/consent-form/${props.match.params.id}/${nextPage}`,
        state: history.location.state,
      });
    } else {
      handleFinishForm();
    }
  };

  const createUpdateDiagForm = async (finished: boolean) => {
    setIsLoading(true);

    let formatAns = JSON.parse(JSON.stringify(diagRuleContent));
    const extraItems = diagRuleContent[currentForm.id].extra_items.filter(
      (item: any) => item.type === "Select"
    );

    formatAns[0].answer = currentForm.label;
    formatAns[currentForm.id].selected = true;
    formatAns[currentForm.id].extra_items = [];
    formatAns[currentForm.id].extra_finished = true;

    for (let item of extraItems) {
      formatAns[currentForm.id].extra_items.push({
        // label: ansItem,
        value: answer[item.label]?.value || "",
        type: item?.type,
      });
    }

    let answer2_2 = "";
    let disableFeature = "";
    if (formatAns[currentForm.id].extra_items[3] != null) {
      answer2_2 = formatAns[currentForm.id].extra_items[3].value;
    }
    if (OPTIONS_LABEL.refuse.includes(answer2_2)) {
      disableFeature = "phr";
    }


    let [res, err]: [any, any] = [null, null];
    if (updateConsent !== 0) {
      [res, err] = await props.controller.updateDiagForm({
        apiToken: props.apiToken ? props.apiToken : apiToken,
        diagFormId: updateConsent,
        answers: JSON.stringify(formatAns), 
        finished: finished,
        triageLevel: undefined,
        text: formatAns[currentForm.id].text}
      )
    } else {
      [res, err] = await props.controller.createDiagForm(
        props.apiToken ? props.apiToken : apiToken,
        props.patientData.patient, //patientId
        Number(props.match.params.id), //diagFormId
        JSON.stringify(formatAns), //answer
        undefined, // triageLevel
        finished,
        formatAns[currentForm.id].text // text
      );
    }

    if (res?.id) {
      if (typeof window.iosNative !== "undefined") {
        window.iosNative?.disableFeature(disableFeature);
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative?.disableFeature(disableFeature);
      } else if (typeof window.mobile !== "undefined") {
        window.mobile?.disableFeature?.(disableFeature);
      }
      // -res.id == formId != diagFormId
      // -let params = {
      //   apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      //   diagFormId: res.id,
      //   answers: JSON.stringify(formatAns),
      //   text: formatAns[currentForm.id].text,
      //   finished,
      // };
      // -const [response, error] = await props.controller.updateDiagForm(params);
      setIsLoading(false);

      return res;
    }

    setIsLoading(false);

    return { error: err };
  };

  const handleFinishForm = async ({ finished } = { finished: true }) => {
    const response = await createUpdateDiagForm(true);

    if (!response?.error) {
      // for debug purpose

      if (CONFIG.E_CONSENT_ENABLE) {
        history.push({
          pathname: `/consent-hospital-form`,
          state: history.location.state,
          search: `?diag_form=${response.id}`,
        });
      } else if (typeof window.iosNative !== "undefined" && finished) {
        window.iosNative.diagConfirm();
      } else if (typeof window.MobNative !== "undefined" && finished) {
        window.MobNative.diagConfirm();
      } else if (typeof window.mobile !== "undefined") {
        window.mobile.diagConfirm();
      } else if (finished) {
        history.push("/");
      }
    } else {
      setModError(response?.error);
    }
  };

  // -const handleModalButtonClick = async (e: any) => {
  //   const { textContent } = e.target;
  //   if (textContent === "ไม่ใช่" || textContent === "No") {
  //     const cloneAnswer = { ...answer };
  //     delete cloneAnswer[showModal?.label || ""];
  //     setShowModal({});
  //     setOpenModal(false);
  //     setAnswer({ ...cloneAnswer });
  //   } else if (textContent === "ใช่" || textContent === "Yes") {
  //     // handleCheckAnswer()
  //     await createUpdateDiagForm(false);
  //     setOpenModal(false);
  //     props.onCheckType?.("diagUnConfirm");
  //     // let modalAnswer = { ...answer };
  //     // modalAnswer[modalDetail.label] = e.target.textContent;
  //     // setAnswer(modalAnswer);
  //     // goToNextPage();
  //   }
  // };

  // -const handleCheckAnswer = () => {
  //   // ถ้าผู้ป่วยไม่ยินยอมในข้อในข้อที่ 2.2 จะถือว่า ไม่ยินยอม ทุกข้อที่ยังไม่ได้ได้ตอบ
  //   if (!allowNextPage) {
  //     const allOptions = getAllAnswerOptions();
  //     const cloneAnswer = { ...answer };
  //     if (Array.isArray(allOptions)) {
  //       // @ts-ignore
  //       for (const [i, item] of allOptions.entries()) {
  //         if (item.type === "Select" && item.options?.length) {
  //           for (const optionItem of item.options) {
  //             if (OPTIONS_LABEL.refuse.includes(optionItem.toLowerCase())) {
  //               if (cloneAnswer[item.label].value === undefined) {
  //                 cloneAnswer[item.label] = {
  //                   value: optionItem,
  //                   type: item.type,
  //                 };
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // isAgreeAllRef.current = true;
  //     setAnswer(cloneAnswer);
  //   }
  // };

  const handleAgreeAll = () => {
    const allOptions = getAllAnswerOptions();
    const cloneAnswer = { ...answer };

    if (Array.isArray(allOptions)) {
      for (const item of allOptions) {
        if (item.type === "Select") {
          item.options?.map((optionItem: any, optionIndex: number) => {
            if (
              OPTIONS_LABEL[allowNextPage ? "refuse" : "consent"].includes(
                optionItem.toLowerCase()
              )
            ) {
              cloneAnswer[item.label] = {
                value: allowNextPage ? "" : optionItem,
                type: item.type,
              };
            }
          });
        }
      }
    }

    // -isAgreeAllRef.current = true;
    setAnswer(cloneAnswer);
  };

  const handleApprove = () => {
    const cloneAnswer = { ...answer };
    cloneAnswer[showModal?.label || ""] = {
      type: "Select",
      value: showModal.options?.[0] || "",
    };

    handleDeny();
    setAnswer({ ...cloneAnswer });
  };

  const handleDeny = async () => {
    setShowModal({});
    setOpenModal(false);
  };

  const handleCloseModError = () => {
    setModError(null);
  };

  return (
    <div ref={consentFormRef} className="consent-form --hospital">
      <ModInfo
        open={openSuccessModal}
        type="success"
        duration={500}
        onClose={() => {
          setOpenSuccessModal(false);
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.diagConfirm();
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.diagConfirm();
          } else {
            history.push("/");
          }
        }}
      />

      {/* #<Modal open={openModal} className="mod-consent-form">
        <div dangerouslySetInnerHTML={{ __html: modalDetail?.label }} />
        <br />
        <div className="btn-group">
          {modalDetail?.options &&
            modalDetail?.options.map((optionItem: any, index: number) => {
              return (
                <Button
                  key={index}
                  content={optionItem}
                  fluid
                  onClick={handleModalButtonClick}
                  loading={isLoading}
                />
              );
            })}
        </div>
      </Modal> */}
      {props.menuBar}
      <Dimmer.Dimmable>
        {/* <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer> */}
        <div className={isEndPage ? "content-no-padding" : "content"}>
          {createContent()}
        </div>
        {isEndPage ? (
          // isBottom && (
          <div className="contentSegment-no-fixed ">
            <FooterButtonConfirm
              formLangText={formLangText}
              isAllConsent={isAllConsent}
              isLoading={isLoading}
              // config
              allowNextPage={allowNextPage}
              // callback
              onAgreeAll={handleAgreeAll}
              onConfirm={handleFinishForm}
            />
          </div>
        ) : (
          // )
          <FooterButton
            // data
            page={props.match.params.page}
            formLangText={formLangText}
            loading={isLoading}
            diagRuleContent={diagRuleContent}
            // config
            allowNextPage={allowNextPage}
            // callback
            onNextPage={handleNextPage}
          />
        )}
      </Dimmer.Dimmable>

      <DialogConfirm
        open={openModal}
        title={"การยินยอมให้เปิดเผยข้อมูล"}
        description={
          <div style={{ whiteSpace: "pre-line", marginTop: "0.5rem" }}>
            {["1.", "2.1"].includes(showModal.choice || "")
              ? "หากโรงพยาบาลไม่ได้รับความยินยอมจาก\nท่านในข้อดังกล่าว ท่านจะไม่สามารถ \nนัดหมายแพทย์ ได้"
              : "หากโรงพยาบาลไม่ได้รับความยินยอมจาก\nท่านในข้อดังกล่าว ท่านจะไม่สามารถ\nนัดหมายแพทย์ หรือดู PHR ได้"}
          </div>
        }
        loading={isLoading}
        img={IMAGES.consent}
        imgSize="150px"
        denyBasic={true}
        approveText={"ยินยอม"}
        denyText={"ไม่ยินยอม"}
        // callback
        onApprove={handleApprove}
        onDeny={handleDeny}
      />

      <DialogConfirm
        open={!!modError}
        title={"ขออภัยค่ะ"}
        approveText="ตกลง"
        img={IMAGES.cancel_hn}
        imgStyle={{ margin: "-1rem 0px -0.5rem" }}
        // config
        hideDeny={true}
        onApprove={handleCloseModError}
      >
        <ErrorMessage
          error={{
            detail: "Invalid token.",
          }}
          style={{ marginTop: "0.5rem", width: "100%" }}
        />
      </DialogConfirm>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                  FooterButtonConfirm;                  */

/* ------------------------------------------------------ */
const FooterButtonConfirm = (props: any) => {
  const classes = useStyles();

  return (
    <Segment
      style={{
        padding: "15px 10px",
        border: 0,
        backgroundColor: "white",
      }}
    >
      {/* <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          margin: 0,
        }}
      >
        <MuiButton
          className={classes.button}
          variant="contained"
          onClick={props.onAgreeAll}
        >
          {props.formLangText === "en-US" ? "Agree all" : "ยินยอมทั้งหมด"}
        </MuiButton>
        <MuiButton
          className={classes.button}
          variant="contained"
          onClick={props.onConfirm}
          disabled={!props.allowNextPage}
        >
          {props.formLangText === "en-US" ? "Confirm" : "ยืนยัน"}
        </MuiButton>
      </div> */}
      <FormGroup className={classes.checkbox_primary}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.isAllConsent}
              onClick={props.onAgreeAll}
            />
          }
          label="ยินยอมทั้งหมด"
        />
      </FormGroup>
      <div
        className="button-submit-bottom"
        style={{ padding: 0, marginTop: "-0.5rem" }}
      >
        <MuiButton
          variant="contained"
          color="primary"
          style={{ width: "100%", maxHeight: "46.2px" }}
          disabled={!props.allowNextPage}
          onClick={props.onConfirm}
        >
          ยืนยัน
        </MuiButton>
      </div>
    </Segment>
  );
};

/* ------------------------------------------------------ */

/*                      FooterButton;                     */

/* ------------------------------------------------------ */
const FooterButton = (props: any) => {
  // -const totalPage = useMemo(() => {
  //   return props.diagRuleContent[0]
  //     ? typeof props.diagRuleContent[0].total_page === "undefined"
  //       ? 2
  //       : props.diagRuleContent[0].total_page
  //     : 0;
  // }, [props.diagRuleContent]);

  return (
    <Segment style={{ backgroundColor: "transparent" }}>
      {/* <Button
        fluid
        content={
          props.diagRuleContent[0] && totalPage == props.page
            ? props.formLangText === "en-US"
              ? "Done"
              : "เสร็จสิ้น"
            : props.formLangText === "en-US"
            ? "Next"
            : "ต่อไป"
        }
        onClick={props.onNextPage}
        loading={props.isLoading}
        disabled={props.isLoading || !props.allowNextPage}
      /> */}
      <div className="button-submit-bottom" style={{ marginBottom: "3rem" }}>
        <MuiButton
          variant="contained"
          color="primary"
          disabled={!!props.loading}
          style={{ maxHeight: "46.2px" }}
          onClick={props.onNextPage}
        >
          ถัดไป
          <Loader
            size="small"
            active={!!props.loading}
            inline={true}
            style={{ marginLeft: "0.5rem" }}
          ></Loader>
        </MuiButton>
      </div>
      {/* <div>
        <span>{`${props.page ? parseInt(props.page) : 1}/${totalPage}`}</span>
      </div> */}
    </Segment>
  );
};

ConsentForm.defaultProps = {
  onFormLanguageChange: () => {},
};

export default memo(ConsentForm);
