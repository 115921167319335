import moment, { Moment } from "moment";

export function formatDateToStringBe(date: any) {
  return formatDate(date);
}

export function formatDate(date: any) {
  if (moment.isMoment(date)) {
    date = date.toDate();
  }
  if (date instanceof Date && !isNaN(date.valueOf())) {
    const day = `${date.getDate()}`.padStart(2, "0");
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const year = `${date.getFullYear() + 543}`;
    return `${day}/${month}/${year}`;
  }
  return "";
}

type PDReturnType<T extends boolean | undefined> = T extends boolean
  ? T extends false
    ? Date
    : Moment
  : Date;



export function parseDate<S extends string, T extends boolean | undefined = undefined>(
  str: S,
  toMoment?: T
): PDReturnType<T> | undefined {
  if (typeof str !== "string") {
    return;
  }

  const split = str.split("/");

  if (split.length !== 3) {
    return;
  }

  const year = parseInt(split[2], 10) - 543;
  const month = parseInt(split[1], 10) - 1;
  const day = parseInt(split[0], 10);

  if (
    isNaN(year) ||
    String(year).length > 4 ||
    isNaN(month) ||
    isNaN(day) ||
    day <= 0 ||
    day > 31 ||
    month < 0 ||
    month >= 12
  ) {
    return;
  }

  // When using this function with DayPickerInput, the second argument will be a format not toMoment
  if (toMoment === true) {
    return moment(`${year}-${month + 1}-${day}`, "YYYY-MM-DD") as PDReturnType<T>;
  }

  // Handle the case where toMoment is false or undefined
  return new Date(year, month, day) as PDReturnType<T>;
}

// Data yyyy-mm-dd (AD) to dd/mm/yyyy (BE)
// export function formatADtoBEString(dateString) {
//   if (typeof dateString !== 'string') {
//     return;
//   }

//   const split = dateString.split('-');
//   if (split.length !== 3) {
//     return;
//   }

//   const year = parseInt(split[0], 10) + 543;
//   // const month = parseInt(split[1], 10) ;
//   // const day = parseInt(split[2], 10);
//   return split[2] + "/" + split[1] + "/" + year.toString()
// }

// Data yyyy-mm-dd (AD) to dd/mm/yyyy (BE) Default
export function formatADtoBEString(dateString: any, spliter = "-") {
  if (typeof dateString !== "string") {
    return;
  }

  const split = dateString.split(spliter);
  if (split.length !== 3) {
    return;
  }

  const year = parseInt(split[0], 10) + 543;
  // const month = parseInt(split[1], 10) ;
  // const day = parseInt(split[2], 10);
  return split[2] + "/" + split[1] + "/" + year.toString();
}

/**
 * format date string dd/mm/yyyy (BE) to yyyy<separator>mm<separator>dd (AD)
 * @param {string} dateString - date in dd/mm/yyyy
 * @param {string} separator - separator (default: '-')
 * @param {boolean} fromBE - convert from BE to AD when `true`,
 *                           `false` if do not convert (default: true)
 * @return {any} - return string of formatted date if dateString is a valid date string
 *                 else return dateString without do anything
 */
export function formatDateToYYYYMMDD(dateString: any, separator?: any, fromBE?: any) {
  separator = separator || "-";
  fromBE = fromBE != null ? fromBE : true;

  if (typeof dateString !== "string") {
    return dateString;
  }

  const dateSplitted = dateString.split("/");
  if (dateSplitted.length !== 3) {
    return dateString;
  }

  let year;
  if (fromBE) {
    year = String(parseInt(dateSplitted[2], 10) - 543);
  } else {
    year = String(parseInt(dateSplitted[2], 10));
  }

  const month = String(parseInt(dateSplitted[1], 10)).padStart(2, "0");
  const day = String(parseInt(dateSplitted[0], 10)).padStart(2, "0");

  return year + separator + month + separator + day;
}

// Data "2020-06-22T17:17:32Z" to dd/mm/yyyy hh:mm (BE)
export function formatUTCtoBEString(utcDT: any, timeFormat = "HH:mm") {
  const dateObj = new Date(utcDT);
  const mm = moment(dateObj);
  const dateBe = formatADtoBEString(mm.format("YYYY-MM-DD"));
  const timeBe = mm.format(timeFormat);

  return [dateBe, timeBe];
}

// Data "2020-06-22T17:17:32Z" to Moment
export function formatUTCtoMoment(utcDT: any) {
  const dateObj = new Date(utcDT);
  return moment(dateObj);
}

export function formatISOtoDDMMMYYYY(isoDate: string) {
  // console.log('isoDate: ', isoDate);
  const dateObj = new Date(isoDate);
  // console.log('dateObj: ', dateObj);
  let month = dateObj.toLocaleString("default", { month: "short" });
  // console.log('month: ', month);
  let ddmmmyyyy = dateObj.getDate() + "/" + month + "/" + dateObj.getFullYear();
  // console.log('ddmmmyyyy: ', ddmmmyyyy);
  return ddmmmyyyy;
}

export const isValidDate = (str: any) =>
  /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(str);

export function formatDatetime(value: any, squareBrackets = false) {
  if (value) {
    let datetime = moment(value);
    let day = datetime.format("DD");
    let month = datetime.format("MM");
    let year = parseInt(datetime.format("YYYY")) + 543;
    let time = datetime.format("HH:mm");

    time = squareBrackets ? `[${time}]` : time;

    return `${day}/${month}/${year} ${time}`;
  } else {
    return "";
  }
}

// Date Obj => string DD/MM/YYYY เช่น 23/08/2566
export function dateToStringWithoutTimeBE(value: any) {
  // console.log("dateToStringWithoutTimeBE value" ,value)
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY")) + 543;
    // console.log("dateToStringWithoutTimeBE", day, "/", month, "/", year)
    return day + "/" + month + "/" + year;
  } else {
    return "";
  }
}

// Date Obj => string DD/MM/YYYY เช่น 23/08/2023
export function dateToStringWithoutTimeAD(value: any): string {
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY"));
    return day + "/" + month + "/" + year;
  } else {
    return "";
  }
}

// Data dd/mm/yyyy (BE) to moment
export function beToAd(str: string) {
  if (str && typeof str === "string") {
    const tokens = str.split("/");
    const date = `${parseInt(tokens[2]) - 543}-${tokens[1]}-${tokens[0]}`;
    return moment(date);
  } else {
    return;
  }
}

// Check is Today in dd/mm/yyyy (BE) string
export const isTodayBEString = (str: string) => {
  if (str?.length !== 10) {
    console.warn("not in format BE String");
    return false;
  }
  const tokens = str.split("/");
  let inputDate = new Date(parseInt(tokens[2]) - 543, parseInt(tokens[1]) - 1, parseInt(tokens[0]));

  let today = new Date();

  if (inputDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};

// Data dd/mm/yyyy (BE) to dd/mm/yyyy (AD) or undefined
export function beStringToAdString(beString: string): string | undefined {
  if (beString && typeof beString === "string") {
    const tokens = beString.split("/");
    const dateAD = `${tokens[0]}/${tokens[1]}/${parseInt(tokens[2]) - 543}`;
    return dateAD;
  }
  return undefined;
}

// Data dd/mm/yyyy (BE) to Object Date Javascirpt
export function beStringToDateObject(beString: string): Date {
  if (beString && typeof beString === "string") {
    const tokens = beString.split("/");
    return new Date(parseInt(tokens[2]) - 543, parseInt(tokens[1]) - 1, parseInt(tokens[0]));
  }
  return new Date();
}

// Data dd/mm/yyyy (BE) to Moment
export function beStringToMoment(beString: string): Moment {
  let date = new Date()
  if (beString && typeof beString === "string") {
    const tokens = beString.split("/");
    date = new Date(parseInt(tokens[2]) - 543, parseInt(tokens[1]) - 1, parseInt(tokens[0]));
  }
  return moment(date)
}

// Data dd/mm/yyyy-HH:mm (BE) to Object Date
export function beStringDateTimeToDateObject(beDateTimeString: string): Date {
  if (beDateTimeString && typeof beDateTimeString === "string") {
    const dateTime = beDateTimeString.split("-");
    const date = dateTime[0].split("/");
    const time = dateTime[1].split(":");
    if (date && time) {
      return new Date(
        parseInt(date[2]) - 543,
        parseInt(date[1]) - 1,
        parseInt(date[0]),
        parseInt(time[0]),
        parseInt(time[1]),
        0
      );
    }
  }
  return new Date();
}

// Data dd/mm/yyyy (BE) to Object Date
export function beStringDateToDateObject(beDateTimeString: string): Date {
  if (beDateTimeString && typeof beDateTimeString === "string") {
    const date = beDateTimeString.split("/");
    if (date && date?.length === 3) {
      return new Date(parseInt(date[2]) - 543, parseInt(date[1]) - 1, parseInt(date[0]), 0);
    }
  }
  return new Date();
}

// Data yyyy-mm-dd/HH:mm (AD) to Object Date
export function YYYYMMDDadStringDateTimeToDateObject(adateTimeString: string): Date {
  if (adateTimeString && typeof adateTimeString === "string") {
    const dateTime = adateTimeString.split("/");
    console.log("dateTime: ", dateTime);
    const date = dateTime[0].split("-");
    console.log("date: ", date);
    const time = dateTime[1].split(":");
    console.log("time: ", time);
    if (date && time) {
      return new Date(
        parseInt(date[0]),
        parseInt(date[1]) - 1,
        parseInt(date[2]),
        parseInt(time[0]),
        parseInt(time[1]),
        0
      );
    }
  }
  return new Date();
}

// Object Date -> DD/MM/YYYY HH:mm (AD)
export function dateToStringAD(date: any) {
  const mm = moment(date);
  const dateAd = mm.format("DD/MM/YYYY HH:mm");
  return dateAd;
}

// Object Date -> YYYY-MM-DD HH:mm (AD)
export function dateToStringADDash(date: any) {
  const mm = moment(date);
  const dateAd = mm.format("YYYY-MM-DD HH:mm");
  return dateAd;
}

// Object Date -> DD/MM/YYYY HH:mm (BE)
export function dateToStringBE(date: any) {
  const mm = moment(date);
  const year = Number(mm.format("YYYY")) + 543;
  const dateBe1 = mm.format("DD/MM/");
  const dateBe2 = mm.format("HH:mm");
  return dateBe1 + year + " " + dateBe2;
}

// Moment -> DD/MM/YYYY HH:mm (BE)
export function momentToStringBE(mm: Moment) {
  const year = Number(mm.format("YYYY")) + 543;
  const dateBe1 = mm.format("DD/MM/");
  const dateBe2 = mm.format("HH:mm");
  return dateBe1 + year + " " + dateBe2;
}

// "22-06-2567 09:00"(BE)  -> DD-MM-YYYY hh:mm(AD)
export function timeStrBEToStringAD(timeStr: string) {
  const [date, ...timeParts] = timeStr.split(" ");
  const [day, month, yearBE] = date.split("-");

  if (!(timeParts.length > 0 && day && month && yearBE)) {
    return "";
  }

  const yearAD = Number(yearBE) - 543;
  const dateAD = [day, month, yearAD].join("-");

  return yearAD > 0 ? `${dateAD} ${timeParts.join(" ")}` : "";
}

// only return string today "2020-06-22"
export function today_string_ad() {
  const dateObj = new Date();
  const mm = moment(dateObj);
  const dateBe = mm.format("YYYY-MM-DD");
  return dateBe;
}

// 20/01/2000 -> 20/01/2543
export function adToBe(str: string, format = "DD/MM/YYYY"): string {
  //! Use convertToBEDatetime instead
  if (str) {
    const momentDate = moment(str, format);
    return `${momentDate.format("DD/MM/")}${parseInt(momentDate.format("YYYY")) + 543}`;
  } else {
    return "";
  }
}

// Date object -> HH:mm (Time)
export function formatTimeCurrent(value: any) {
  if (value) {
    var datetime = moment(value);
    var time = datetime.format("HH:mm");
    return time;
  } else {
    return "";
  }
}

export function adToBeWithSetFormat(
  dateAd: string,
  adFormat: string = "YYYY-MM-DD",
  beFormat: string = "DD/MM/YYYY",
  locale: string = "en"
): string {
  if (dateAd) {
    const momentDate = moment(dateAd, adFormat);
    // find year be format
    let yearFormat = "YYYY";
    if (beFormat.includes("YYYY")) {
      yearFormat = "YYYY";
    } else if (beFormat.includes("YY")) {
      yearFormat = "YY";
    }

    // set year value
    let yearAD = momentDate.format("YYYY");
    let yearBE = (parseInt(yearAD) + 543).toString();
    if (yearFormat == "YY") {
      yearBE = yearBE.slice(2, 4);
    }
    let yearSetFormat = beFormat.replace(yearFormat, yearBE);
    return momentDate.locale(locale).format(yearSetFormat);
  } else {
    return "";
  }
}

// For report raksthai
// YYYY-MM-DD HH:mm -> DD/MM/YYYY HH:mm (AD) หรือ DD/MM/YYYY HH:mm (BE)
export const convertLastUpdate = (input: string = "", isAd: boolean = false): string => {
  if (!input) {
    return "";
  }

  let date = moment(input, "YYYY-MM-DD HH:mm");
  let convertDate = "";
  if (isAd) {
    //
    convertDate = dateToStringAD(date);
  } else {
    convertDate = dateToStringBE(date);
  }

  return convertDate;
};

// Date Time ISO String or DD/MM/YYYY to DD/MM/YYYY (BE)
export const convertISOtoBEIfISO = (strDate: string) => {
  console.log("convertISOtoBEIfISO strDate: ", strDate);
  if (strDate && strDate?.length > 10 && moment(strDate, moment.ISO_8601).isValid()) {
    return dateToStringWithoutTimeBE(new Date(strDate));
  } else {
    return strDate;
  }
};

// Date ISO Date to HH:mm
export function formatISOtoHHmm(isoDate: string) {
  // console.log('isoDate: ', isoDate);
  const dateObj = new Date(isoDate);
  // console.log('dateObj: ', dateObj);
  let hours = dateObj.getHours().toString().padStart(2, "0");
  // console.log('hours', hours);
  let minutes = dateObj.getMinutes().toString().padStart(2, "0");
  // console.log('minutes', minutes);
  let hhmm = hours + ":" + minutes;
  // console.log('hhmm: ', hhmm);
  return hhmm;
}

// moment (AD) -> format Local (+7) mode (BE) // 2566-02-04T10:35:24-08:00
export function momentADToLocalFormatBE( dateMoment: Moment)  {
  let beString = dateMoment?.format()
  let [yearAD, ...rest] = beString.split("-")
  let yearBE = Number(yearAD) + 543
  let prepare = [yearBE, ...rest]
  return prepare.join("-")
}

// momentToYYYYMMDD(moment(), "YYYY-MM-DD"))
//  moment -> 2566-05-01
export function momentToYYYYMMDD( dateMoment: Moment, formatString: string = "YYYY-MM-DD" ) {
  let beString = dateMoment?.format(formatString)
  let [yearAD, ...rest] = beString.split("-")
  let yearBE = Number(yearAD) + 543
  let prepare = [yearBE, ...rest]
  return prepare.join("-")
}


export function formatISOtoBEDate(value: string) {
  if (value) {
    var datetime = moment(value);
    var day = datetime.format("DD");
    var month = datetime.format("MM");
    var year = parseInt(datetime.format("YYYY")) + 543;
    let monthText = MONTH_OPTION.find((items: any) => items?.number === month)?.text

    return `${day} ${monthText} ${year}`
  } else {
    return "";
  }
}

export const MONTH_OPTION = [
  { value: 1, key: 1, text: "มกราคม", number: "01" },
  { value: 2, key: 2, text: "กุมภาพันธ์", number: "02" },
  { value: 3, key: 3, text: "มีนาคม", number: "03" },
  { value: 4, key: 4, text: "เมษายน", number: "04" },
  { value: 5, key: 5, text: "พฤษภาคม", number: "05" },
  { value: 6, key: 6, text: "มิถุนายน", number: "06" },
  { value: 7, key: 7, text: "กรกฎาคม", number: "07" },
  { value: 8, key: 8, text: "สิงหาคม", number: "08" },
  { value: 9, key: 9, text: "กันยายน", number: "09" },
  { value: 10, key: 10, text: "ตุลาคม", number: "10" },
  { value: 11, key: 11, text: "พฤศจิกายน", number: "11" },
  { value: 12, key: 12, text: "ธันวาคม", number: "12" },
];
