import React, {
  useState,
  useEffect,
  CSSProperties,
  useRef,
  MutableRefObject,
  ReactChild,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dimmer,
  Header,
  Form,
  Checkbox,
  Input,
  Button,
  Divider,
  Loader,
} from "semantic-ui-react";

//Common
import SnackMessage from "./SnackMessage";
import TimeFreeTextBox24 from "../../react-lib/apps/common/TimeFreeTextBox24";

// Event
import { Event as MobEvent, LANGUAGE } from "../MobClinicInterface";

import { COLOR } from "./DMWeight";

type DMWeightSettingProps = {
  onEvent: (e: MobEvent) => any;
  loadingStatus?: boolean;
  successMessage?: any;
  errorMessage?: any;
  weightSettingData?: any;
  selectedPatient?: any;
};

type DMWeightCameraScanProps = {
  onEvent: (e: MobEvent) => any;
  loadingStatus?: boolean;
  successMessage?: any;
  errorMessage?: any;
};

type TextLabelProps = {
  label: string | ReactChild;
};

const TextLabel = (props: TextLabelProps) => {
  return (
    <>
      <label
        style={{
          fontWeight: "bold",
          paddingTop: "13px",
          paddingBottom: "13px",
          color: COLOR.grey,
        }}
      >
        {props.label}
      </label>
    </>
  );
};

export const DMWeightCameraScan = React.memo(
  (props: DMWeightCameraScanProps) => {
    const handleScanWeight = () => {
      props.onEvent({ message: "HandleEvent", params: { name: "scanWeight" } });
    };

    return (
      <div
        style={{
          height: "calc(100vh - 53px)",
          overflow: "auto",
          paddingTop: "42px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Header
            as="h3"
            style={{
              color: COLOR.medium_blue,
              margin: "30px 0 40px",
              fontSize: "20px",
            }}
          >
            <FormattedMessage id="weight.weight_recording" />
          </Header>
        </div>

        <div style={{ display: "grid", justifyContent: "center" }}>
          {/*Line center */}
          <div style={styles.redLine}></div>

          <div
            style={{
              width: "240px",
              height: "240px",
              border: `1px solid ${COLOR.blue}`,
              borderRadius: "5px",
            }}
          ></div>
        </div>

        <div style={{ textAlign: "center" }}>
          <Header
            as="h3"
            style={{
              color: COLOR.medium_blue,
              margin: "35px 0 40px",
              fontWeight: "normal",
              fontSize: "20px",
            }}
          >
            <FormattedMessage id="weight.center_photo" />
          </Header>
          {/* <Icon name="camera" color="blue" style={{ fontSize: "40px" }} /> */}
          <svg
            onClick={handleScanWeight}
            width="35"
            height="31"
            viewBox="0 0 35 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.2188 7.75V6.76692C33.3962 7.04871 33.5 7.38512 33.5 7.75V27.6786C33.5 28.0435 33.3962 28.3799 33.2188 28.6616V27.6786V7.75ZM31.7188 5.92857C32.09 5.92857 32.4391 6.0466 32.7281 6.25H31.7188H24.4722L23.2003 2.80225L22.8384 1.82143H21.793H13.2139H12.2036C12.4955 1.61587 12.8466 1.5 13.2139 1.5H22.1689C22.6755 1.5 23.1343 1.81427 23.3165 2.31337L23.3165 2.31338L23.3196 2.3218L24.2971 4.95126L24.6605 5.92857H25.7031H31.7188ZM11.5514 2.67228C11.6756 2.33647 11.8895 2.05681 12.1575 1.85496L11.8073 2.80034L10.5293 6.25H3.28125H2.27191C2.56094 6.0466 2.90997 5.92857 3.28125 5.92857H9.29688H10.339L10.7026 4.95198L11.5503 2.67542L11.5514 2.67228ZM1.78125 6.76692V7.75V27.6786V28.6616C1.60382 28.3799 1.5 28.0435 1.5 27.6786V7.75C1.5 7.38512 1.60382 7.04871 1.78125 6.76692ZM3.28125 29.5C2.90997 29.5 2.56094 29.382 2.27191 29.1786H3.28125H31.7188H32.7281C32.4391 29.382 32.09 29.5 31.7188 29.5H3.28125ZM17.5 24.5179C13.8202 24.5179 10.7969 21.4839 10.7969 17.7143C10.7969 13.9446 13.8202 10.9107 17.5 10.9107C21.1798 10.9107 24.2031 13.9446 24.2031 17.7143C24.2031 21.4839 21.1798 24.5179 17.5 24.5179ZM17.5 11.2321C13.9405 11.2321 11.0781 14.156 11.0781 17.7143C11.0781 21.2725 13.9405 24.1964 17.5 24.1964C21.0595 24.1964 23.9219 21.2725 23.9219 17.7143C23.9219 14.156 21.0595 11.2321 17.5 11.2321Z"
              fill="#0072BC"
              stroke="#0072BC"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
    );
  }
);

const initialHeight = window.innerHeight;

export const DMWeightSetting = React.memo((props: DMWeightSettingProps) => {
  const intl = useIntl();
  const [settingData, setSettingData] = useState<any>({
    id: null,
    data: { isReminder: false, remindBefore: "", times: [""] },
  });

  const formRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.onEvent({ message: "GetWeightSetting", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    setSettingData(props.weightSettingData);
  }, [props.weightSettingData]);

  const formHeight = Math.floor(
    (formRef.current?.offsetHeight / initialHeight) * 100
  );

  const ordinalNumber = (number: number) => {
    const splitNumber = number.toString().split("");
    const last = splitNumber[splitNumber.length - 1];
    const ordinal = ["st", "nd", "rd"];
    const string = ordinal[+last];
    const time = 
    LANGUAGE === "th-TH" ? `ครั้งที่ ${number + 1}` : `${number + 1}${string || "th"} time`;
    return time;
  };

  const handleChangeSetting = (event: any, data: any) => {
    let result = { ...settingData };
    if (data.type === "checkbox") {
      result.data[data.name] = data.checked;
      setSettingData({ ...settingData, ...result });
    } else {
      result.data[data.name] = data.value;
      setSettingData({ ...settingData, ...result });
    }
  };

  const SaveWeightSetting = () => {
    props.onEvent({ message: "SaveWeightSetting", params: { ...settingData } });
  };

  return (
    <div
      style={{
        height: "calc(100vh - 53px)",
        overflow: "auto",
        paddingTop: "42px",
      }}
    >
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div ref={formRef} className="form-ref">
        <Form style={{ padding: "0px 15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextLabel label={<FormattedMessage id="weight.time_reminder" />} />
            <Checkbox
              toggle
              style={{ transform: "scale(0.7)", marginLeft: "25px" }}
              name="isReminder"
              checked={settingData?.data?.isReminder}
              onChange={handleChangeSetting}
            />
          </div>
          <div>
            <TextLabel
              label={<FormattedMessage id="weight.next_time_reminder" />}
            />
          </div>
        </Form>
        <Divider />

        <Form style={{ padding: "0px 15px" }}>
          <TextLabel label={intl.formatMessage({ id: "bplusClinicKey670" })} />
          {settingData?.data?.times?.map((data: any, index: number) => (
            <div
              key={"time-setting" + index}
              style={{ display: "grid", gridTemplateColumns: "75% 25%" }}
            >
              <div style={styles.flexBaseline}>
                <label style={styles.labelTimeSetting}>
                  {ordinalNumber(index)}
                </label>
                <TimeFreeTextBox24
                  style={{
                    display: "flex",
                    alignItems: "left",
                    margin: "0 15px",
                    transform: "scale(0.8)",
                  }}
                  className="time-custom-react-picker padding-input"
                  value={data}
                  onChange={(time: string) => {
                    let result: any[] = [...settingData?.data?.times];
                    result[index] = time;
                    handleChangeSetting({}, { name: "times", value: result });
                  }}
                  autoFocus={false}
                  checkTimeOverflow={true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  color="red"
                  icon="minus"
                  circular
                  size="mini"
                  style={{ transform: "scale(0.6)", marginTop: "-1px" }}
                  onClick={(event: any) => {
                    let result: any[] = [...settingData?.data?.times];
                    result.splice(index, 1);

                    handleChangeSetting(event, {
                      name: "times",
                      value: result,
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </Form>
        <Divider />

        <Form style={{ padding: "0px 15px 35px" }}>
          <div style={styles.flexBaseline}>
            <TextLabel label={intl.formatMessage({ id: "bplusClinicKey419" })} />
            <Input
              style={{ transform: "scale(0.8)" }}
              name="remindBefore"
              value={settingData?.data?.remindBefore}
              onChange={handleChangeSetting}
            />
            <label style={{ color: COLOR.light_grey }}>
              <FormattedMessage id="bplusClinicKey323" />
            </label>
          </div>
        </Form>
      </div>

      <div
        className="footer-button-settings"
        style={
          Number.isNaN(formHeight)
            ? { display: "none" }
            : formHeight >=100
            ? {}
            : { position: "fixed", top: `${initialHeight - 190}px` }
        }
      >
        <Button
          fluid
          content={intl.formatMessage({ id: "bplusClinicKey671" })}
          icon="plus"
          color="blue"
          labelPosition="left"
          basic
          onClick={(event: any) => {
            let result: any[] = [...settingData?.data?.times];
            result.push("");
            handleChangeSetting(event, { name: "times", value: result });
          }}
        />
        <Button
          fluid
          content={intl.formatMessage({ id: "common.save" })}
          icon="save outline"
          color="green"
          labelPosition="left"
          basic
          onClick={SaveWeightSetting}
          disabled={!settingData?.data?.times.every((time: string) => time)}
        />
      </div>
    </div>
  );
});

const styles = {
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    margin: "5px 0",
  } as CSSProperties,
  labelTimeSetting: {
    color: "#299be6",
    fontWeight: "bold",
    width: "70px",
  } as CSSProperties,
  redLine: {
    position: "absolute",
    width: "1px",
    height: "275px",
    background: "red",
    left: "50%",
    marginTop: "-15px",
  } as CSSProperties,
};
