import React, {
  useMemo,
  useState,
  useEffect,
  CSSProperties,
  useRef,
  TouchEvent,
} from "react";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  WrappedComponentProps,
  useIntl,
} from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Form,
  Icon,
  Header,
  Input,
  Button,
  Tab,
  Menu,
  TabProps,
  InputOnChangeData,
  SemanticICONS,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { AppBar, Slide } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

// Library
import moment from "moment";
import ReactTable from "react-table-6";
import { Line } from "react-chartjs-2";

// Interface
import {
  Event as MobEvent,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  convertToADDate,
  formatDateByLanguage,
} from "../MobClinicInterface";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// UI
import PatientPanel from "./PatientPanel";
import { DMWeightCameraScan, DMWeightSetting } from "./DMWeightDialog";
import MobHead from "../MobHead";
import SnackMessage from "./SnackMessage";
import {
  HeaderChart,
  FooterChart,
  spliceChartData,
  labelNewLine,
  DMDatePicker,
} from "./DMEmotionRestChart";

// Utils
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";

// Styles
import "./DMWeight.scss";

type DMWeightProps = {
  // funtion
  onEvent: (e: MobEvent) => any;
  onSetBackToApp?: () => void;
  // data
  successMessage: any;
  errorMessage: any;
  loadingStatus?: boolean;

  selectedPatient?: any;
  weightFilterDate?: moment.Moment;
  weightFilterDuration?: string;
  weightRecordList: any;
  weightSettingData: any;
  weightChartList: any;
  inputWeightDetail?: Partial<{
    weight: string | number;
    note: string;
  }>;
} & WrappedComponentProps;

const DMWeightInitial: Omit<DMWeightProps, "intl"> = {
  // funtion
  onEvent: () => null,
  onSetBackToApp: () => null,
  // data
  successMessage: null,
  errorMessage: null,
  loadingStatus: false,

  selectedPatient: {},
  weightFilterDate: moment(),
  weightFilterDuration: "6",
  weightRecordList: {},
  weightSettingData: {},
  weightChartList: {},
};

type DetailsTabProps = {
  //Callback
  onSettingClick: () => any;
  onCameraClick: () => any;
} & DMWeightProps &
  WrappedComponentProps;

type HistoryTabProps = {
  // function
  onEvent: (e: MobEvent) => any;
  onHoverChart?: (e: TouchEvent<HTMLDivElement>, type: "start" | "end") => any;
  // data
  weightFilterDuration?: string;
  selectedPatient?: any;
  weightChartList?: any;
  isMyTab?: boolean;
  loadingStatus?: boolean;
};

type LinePointProps = {
  data: any;
  // Callback
  onClickLegends?: (tooltip: any, data: any) => void;
};

type IconLabelProps = {
  icon: SemanticICONS | undefined;
  label: string;
};

type LegendDetail = {
  id: number | null;
  date: string;
  time: string;
  note: string;
  weight: string;
  bmi: string;
  bmiStatus: {
    color?: string;
    text?: string;
  };
  utc_date?: string;
};

export const COLOR = {
  yellow: "#ffd00d",
  green: "#4aa418",
  orange: "#ff9a15",
  red: "#ef2b33",
  light_grey: " #A3A3A3",
  grey: "#747474",
  medium_grey: "#656565",
  blue: "#239cf3",
  medium_blue: "#2083C1",
};

const BMI_DETAIL = {
  UNDERWEIGHT: {
    text: "Underweight",
    color: "#EFB810",
    pointColor: "#EFB810",
  },
  NORMAL: {
    text: "Normal/ Healthy Weight",
    color: COLOR.green,
    pointColor: COLOR.medium_grey,
  },
  OVERWEIGHT: {
    text: "Overweight",
    color: COLOR.orange,
    pointColor: COLOR.orange,
  },
  OBESE: {
    text: "Obese",
    color: COLOR.red,
    pointColor: COLOR.red,
  },
};

export const BMI_STATUS = [
  {
    bmi: "Below 18.5",
    status: BMI_DETAIL.UNDERWEIGHT.text,
    color: BMI_DETAIL.UNDERWEIGHT.color,
  },
  {
    bmi: "18.5 - 22.9",
    status: BMI_DETAIL.NORMAL.text,
    color: BMI_DETAIL.NORMAL.color,
  },
  {
    bmi: "23.0 - 24.9",
    status: BMI_DETAIL.OVERWEIGHT.text,
    color: BMI_DETAIL.OVERWEIGHT.color,
  },
  {
    bmi: "25.0 and Above",
    status: BMI_DETAIL.OBESE.text,
    color: BMI_DETAIL.OBESE.color,
  },
];

const defaultOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  interaction: {
    mode: "x",
  },
  tooltips: {
    enabled: false,
    mode: "x",
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontSize: 10,
          fontColor: "#404040",
          fontStyle: "bold",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          stepSize: 4,
          beginAtZero: true,
          fontColor: "#b1b3b2",
          fontSize: 10,
        },
      },
    ],
  },
};

const DATE_FORMAT = "DD/MM/YYYY";

const IconLabel = (props: IconLabelProps) => {
  return (
    <>
      <Icon name={props.icon} style={{ color: COLOR.blue }} />
      <label style={{ color: COLOR.medium_grey, margin: "0 15px 0 5px" }}>
        {props.label}
      </label>
    </>
  );
};

const LinePoint = React.memo<LinePointProps>((props) => {
  const lineRef = useRef<any>(null);
  // const [activePoint, setActivePoint] = useState<any>(null);
  const activePoint = useRef<any>(null);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-linechart",
        afterDraw: (chart: any) => {
          if (chart.canvas) {
            chart.canvas.style.padding = `0 5px`;
          }

          createYaxisLine(chart);

          if (typeof chart.config.options.lineAt !== "undefined") {
            var lineAt = chart.config.options.lineAt;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            // I'm not good at maths
            // So I couldn't find a way to make it work ...
            // ... without having the `min` property set to 0
            if (yAxe.min !== 0) return;

            if (ctxPlugin) {
              ctxPlugin.strokeStyle = "red";
              ctxPlugin.lineWidth = 1;
              ctxPlugin.beginPath();
              lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
              lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
              ctxPlugin.moveTo(xAxe.left, lineAt);
              ctxPlugin.lineTo(xAxe.right, lineAt);
              ctxPlugin.stroke();
            }
          }

          // Get the object that determines the region to highlight.
          var yHighlightRange = chart.config.data.yHighlightRange;

          // If the object exists.
          if (yHighlightRange !== undefined) {
            var ctx = chart.chart.ctx;

            var yRangeBegin = yHighlightRange.begin;
            var yRangeEnd = yHighlightRange.end;

            var xaxis = chart.scales["x-axis-0"];
            var yaxis = chart.scales["y-axis-0"];

            var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
            var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

            if (ctx) {
              ctx.save();
              // The fill style of the rectangle we are about to fill.

              ctx.fillStyle = "rgba(98, 246, 168, 0.1)";
              // console.log(ctx)
              // ctx.globalCompositeOperation = "lighter";
              // ctx.globalAlpha = 0.5
              // Fill the rectangle that represents the highlight region. The parameters are the closest-to-starting-point pixel's x-coordinate,
              // the closest-to-starting-point pixel's y-coordinate, the width of the rectangle in pixels, and the height of the rectangle in pixels, respectively.
              ctx.fillRect(
                xaxis.left,
                Math.min(yRangeBeginPixel, yRangeEndPixel),
                xaxis.right - xaxis.left,
                Math.max(yRangeBeginPixel, yRangeEndPixel) -
                  Math.min(yRangeBeginPixel, yRangeEndPixel)
              );

              ctx.restore();
            }
          }
        },
        afterDatasetsDraw: function (chart: any, number: number) {},
        beforeEvent: (chart: any, e: any) => {
          // console.log(chart, e, chart.getDatasetMeta(0));
        },
      },
    ];
  }, []);

  const createYaxisLine = (chart: any) => {
    const _meta = chart.getDatasetMeta(0);
    let active: any = activePoint.current;

    const modelArray: any[] = _meta.data.map((item: any) => [
      item._model.x,
      item._model.y,
    ]);

    const isCompare =
      activePoint.current?._modelArray === modelArray?.toString();

    const findPoint = _meta.data.find(
      (item: any) =>
        item._model.x === activePoint.current?._model?.x &&
        item._model.y === activePoint.current?._model?.y
    );

    if (!findPoint || !isCompare) {
      const lastPoint = _meta.data.slice(-1)[0];
      active = lastPoint;
    }

    if (chart.tooltip._active && chart.tooltip._active.length) {
      active = chart?.tooltip?._active?.[0];
    }

    if (
      !(
        active?._model?.x === activePoint.current?._model?.x &&
        active?._model?.y === activePoint.current?._model?.y
      ) ||
      !isCompare
    ) {
      activePoint.current = active;
      if (active) {
        activePoint.current._modelArray = modelArray?.toString();
      }
      props.onClickLegends?.(active, active?._chart?.config?.data);
    }

    if (activePoint.current) {
      const ctx = chart.ctx;
      const y_axis = activePoint.current._yScale;
      const _model = activePoint.current._model;
      const x = _model.x;
      const topY = y_axis.top;
      const bottomY = y_axis.bottom;
      // draw line
      if (ctx) {
        ctx.save();
        //  bottom line
        ctx.beginPath();
        ctx.moveTo(x, topY + _model.y);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#c9caca";
        ctx.stroke();
        // top line
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, topY + _model.y - 5 * 2 - _model.borderWidth / 2);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#c9caca";
        ctx.stroke();
        ctx.restore();
      }
    }
  };

  const chartData: any = useMemo(() => {
    return {
      labels: props.data.labels,
      datasets: props.data.datasets,
      yHighlightRange: {
        begin: 18.5,
        end: 22.9,
      },
    };
  }, [props.data]);

  const options = useMemo(() => {
    const defaults: any = JSON.parse(JSON.stringify(defaultOptions));
    const data = props.data?.datasets?.[0]?.data || [0];
    const max = Math.max(...data);

    defaults.scales.yAxes[0].ticks.max = max > 31 ? Math.floor(max) + 3 : 34;
    defaults.scales.xAxes[0].ticks.callback = labelNewLine;

    // defaults.animation = false;
    // defaults.tooltips = {
    // mode: "single",
    // callbacks: {
    //   title: (tooltipItem: any[], data: any) => {
    //     return data["labels"][tooltipItem[0]["index"]];
    //   },
    //   label: (tooltipItem: any, data: any) => {
    //     // props.onClickLegends?.(tooltipItem, data);
    //     return ["19/02/2564"];
    //     // return ["- 19/02/2564, 08:30, Before after"];
    //   },
    //   // afterLabel: (tooltipItem: any, data: any) => {
    //   //   const bmi = data["datasets"][0]["data"][tooltipItem["index"]];
    //   //   return [`- 56.5, ${bmi}`, "- Overweight"];
    //   // },
    //   // afterBody: () => {
    //   //   return [];
    //   // },
    //   // afterLabelTextColor: () => {
    //   //   return "#543453";
    //   // },
    //   // bodyFontColor: "red",
    //   // displayColors: false
    // },
    // };
    // defaults.onHover = (e: any) => {
    //   console.log(e, lineRef.current.chartInstance.getDatasetMeta(0));
    // };

    return {
      ...defaults,
      lineAt: 25,
    };
  }, [props.data]);

  const handleGetElementAtEvent = (chart: any) => {
    if (!chart?.[0]) {
      return;
    }
    const active = chart[0];
    props.onClickLegends?.(active, active?._chart?.config?.data);
  };

  return (
    <div style={{ marginLeft: "-20px" }}>
      <Line
        ref={(instance) => {
          if (instance) {
            lineRef.current = instance;
          }
        }}
        data={chartData}
        options={options}
        height={200}
        plugins={plugins}
        getElementAtEvent={handleGetElementAtEvent}
      />
    </div>
  );
});

const formatDetail = (data: any) => {
  if (!data) {
    return {
      id: null,
      note: "-",
      weight: "-",
      bmi: "-",
      date: "-",
      time: "-",
      bmiStatus: { text: "", color: "" },
    };
  }
  const date = formatUTCtoMoment(data.measure_date);
  return {
    id: data.id,
    note: data.data?.note,
    weight: data.data?.value,
    bmi: data.data?.bmi,
    date: date.format("DD MMMM YYYY"),
    time: date.format("HH:mm"),
    bmiStatus: { text: data.data?.status, color: data.data?.color },
  };
};

const HistoryTab = React.memo((props: HistoryTabProps) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [detail, setDetail] = useState<LegendDetail>({
    id: null as number | null,
    date: "",
    time: "",
    note: "",
    weight: "",
    bmi: "",
    bmiStatus: {
      color: "",
      text: "",
    },
  });
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const labelLimit = 7;

  useEffect(() => {
    setStartDate(moment().add(`-${props.weightFilterDuration}`, "days"));
    setEndDate(moment());
  }, [props.weightFilterDuration]);

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({
        message: "GetWeightChart",
        params: {
          start_measure: startDate.format(BACKEND_DATE_FORMAT),
          end_measure: endDate.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, props.isMyTab, startDate]);

  useEffect(() => {
    setSkipIndex(0);
  }, [startDate]);

  useEffect(() => {
    setSkipIndex(0);
  }, [isSeeAll]);

  const durationOptions = useMemo(
    () => [
      {
        key: "4",
        value: "4",
        text: `5 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "6",
        value: "6",
        text: `7 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "9",
        value: "9",
        text: `10 ${intl.formatMessage({ id: "profile.days" })}`,
      },
    ],
    []
  );

  const chartData = useMemo(() => {
    const labels = [];
    let sortList: any[] = [];

    if (props.weightChartList?.items) {
      sortList = props.weightChartList?.items
        ?.sort((a: any, b: any) =>
          a?.measure_date.localeCompare(b?.measure_date)
        )
        .filter((item: any) => !Number.isNaN(+item?.data?.bmi));

      const hashDate = new Map();
      for (const item of sortList) {
        const date = formatDateByLanguage(moment(
          item.measure_date,
          `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
        ).format(DATE_FORMAT),"MMM DD");
        if (!hashDate.has(date)) {
          hashDate.set(date, 1);
          labels.push(`${date} (1)`);
        } else {
          const length = hashDate.get(date);
          hashDate.set(date, length + 1);
          labels.push(`${date} (${length + 1})`);
        }
      }
    }

    const datasets = [
      {
        label: "",
        fill: "start",
        lineTension: 0.5,
        borderWidth: 1,
        showLine: false,
        pointBackgroundColor: (context: any) => {
          const index = context.dataIndex;
          const raw = context.dataset.realData[index];
          return raw?.data?.color;
        },
        pointBorderColor: (context: any) => {
          const index = context.dataIndex;
          const raw = context.dataset.realData[index];
          return raw?.id === (detail?.id || defaultDetail?.id)
            ? "rgba(0,0,0,.2)"
            : "";
        },
        pointBorderWidth: (context: any) => {
          const index = context.dataIndex;
          return context.dataset.realData[index]?.id ===
            (detail?.id || defaultDetail?.id)
            ? 5
            : 1;
        },
        pointRadius: (context: any) => {
          const index = context.dataIndex;
          return context.dataset.realData[index]?.id ===
            (detail?.id || defaultDetail?.id)
            ? 5
            : 4;
        },
        data: sortList.map((item) => item.data?.bmi),
        realData: sortList,
      },
    ];

    return {
      labels,
      datasets,
    };
  }, [props.weightChartList]);

  const subChartData = useMemo(() => {
    return spliceChartData({
      data: chartData,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [chartData, skipIndex]);

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    props.onEvent({
      message: "handleChangeWeightFilterDuration",
      params: { duration: value },
    });
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;
    const duration = props.weightFilterDuration;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${duration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(duration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleClickLegends = (tooltipItem: any, data: any) => {
    const target: LegendDetail =
      data?.["datasets"]?.[tooltipItem?._datasetIndex]?.realData?.[
        tooltipItem?._index
      ];
    setDetail(formatDetail(target));
  };

  const defaultDetail = useMemo(() => {
    const data: LegendDetail[] = chartData.datasets?.[0]?.realData;
    // console.log(chartData);

    return formatDetail(data?.[data.length - 1]);
  }, [chartData]);

  const columnsBMI = useMemo(
    () => [
      {
        Header: (
          <div style={{ textAlign: "left", paddingLeft: "5px" }}>
            <FormattedMessage id="profile.bmi" />{" "}
            <label style={{ fontWeight: "normal", fontStyle: "italic" }}>
              <FormattedMessage id="bplusClinicKey8" />
            </label>
          </div>
        ),
        accessor: " ",
        width: 150,
        Cell: (row: { original: { bmi: string } }) => (
          <div style={styles.bmiLeftBold}>
            {
              row.original?.bmi === "Below 18.5" ? `${intl.formatMessage({ id: "bplusClinicKey85" })}` :
              row.original?.bmi === "25.0 and Above" ? `${intl.formatMessage({ id: "bplusClinicKey836" })}` : row.original?.bmi
            }
          </div>
        ),
      },
      {
        Header: (
          <div style={{}}>
            <FormattedMessage id="bplusClinicKey468" />
          </div>
        ),
        accessor: " ",
        minWidth: 80,
        Cell: (row: { original: { color: any; status: string } }) => (
          <div style={{ color: row.original.color, ...styles.bmiLeftBold }}>
            {row.original?.status === "Underweight"
              ? `${intl.formatMessage({ id: "bmi.underweight" })}`
              : row.original?.status === "Normal/ Healthy Weight"
              ? `${intl.formatMessage({ id: "bplusClinicKey825" })}`
              : row.original?.status === "Overweight"
              ? `${intl.formatMessage({ id: "bmi.overweight" })}`
              : row.original?.status === "Obese"
              ? `${intl.formatMessage({ id: "bplusClinicKey347" })}`
              : ""}
          </div>
        ),
      },
    ],
    [props.selectedPatient]
  );

  return (
    <div
      onTouchStart={(e) => {
        props.onHoverChart?.(e, "start");
      }}
      onTouchEnd={(e) => {
        props.onHoverChart?.(e, "end");
      }}
    >
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <HeaderChart
        yAxesLabelLeft="BMI"
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={props.weightFilterDuration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleChangeDateRange}
        // labelStyle={{ padding: "10px 30px 5px 18px" }}
      />

      <LinePoint
        data={isSeeAll ? chartData : subChartData}
        onClickLegends={handleClickLegends}
      />

      <FooterChart
        graphIndex={skipIndex}
        lastGraph={Boolean(
          chartData.labels?.slice(labelLimit + skipIndex)?.[0]
        )}
        onMoveGraph={handleMoveGraph}
        isSeeAll={isSeeAll}
        onClickSeeAll={handleClickSeeAll}
        hideMoveIcon={isSeeAll}
        style={{ margin: "-5px 0 20px" }}
      />

      <div style={{ marginTop: "10px", ...styles.flexBaseline }}>
        <IconLabel
          icon="calendar alternate outline"
          label={
            formatDateByLanguage(moment(detail.date, "DD MMMM YYYY").format(DATE_FORMAT),"DD MMMM YYYY") || 
            defaultDetail.date
          }
        />
        <IconLabel icon="time" label={detail.time || defaultDetail.time} />
        <IconLabel icon="edit" label={detail.note || defaultDetail.note} />
      </div>

      <div style={{ marginTop: "15px", ...styles.flexBaseline }}>
        <div style={{ display: "flex" }}>
          <label style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey540" />
          </label>
          <label style={{ width: "50px", textAlign: "center" }}>
            {detail.weight || defaultDetail.weight}
          </label>
          <label>{intl.formatMessage({ id: "bplusClinicKey572" })}</label>
        </div>
        <div style={{ display: "flex", marginLeft: "25px" }}>
          <label style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "bplusClinicKey74" })}
          </label>
          <label style={{ width: "50px", textAlign: "center" }}>
            {detail.bmi || defaultDetail.bmi}
          </label>
        </div>
      </div>

      <div style={{ textAlign: "center", margin: "20px 0 15px" }}>
        <Header
          as="h2"
          style={{
            color: detail.bmiStatus.color || defaultDetail.bmiStatus.color,
          }}
        >
          {detail.bmiStatus.text === "Underweight"
            ? `${intl.formatMessage({ id: "bmi.underweight" })}`
            : detail.bmiStatus.text === "Normal/ Healthy weight"
            ? `${intl.formatMessage({ id: "bplusClinicKey825" })}`
            : detail.bmiStatus.text === "Overweight"
            ? `${intl.formatMessage({ id: "bmi.overweight" })}`
            : detail.bmiStatus.text === "Obese"
            ? `${intl.formatMessage({ id: "bplusClinicKey347" })}`
            : ""}
          {/* {detail.bmiStatus.text || defaultDetail.bmiStatus.text} */}
        </Header>
      </div>

      <Form style={{ padding: "0 10px" }}>
        <ReactTable
          className="weight-table-custom"
          columns={columnsBMI}
          defaultPageSize={4}
          // style={{ height: "135px" }}
          showPagination={false}
          data={BMI_STATUS}
          resizable={false}
          getTheadThProps={handleGetTheadThProps}
        />
      </Form>
    </div>
  );
});

const DetailsTab = (props: DetailsTabProps) => {
  const intl = useIntl();
  // const [inputWeight, setInputWeight] = useState<string>("");
  // const [note, setNote] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);

  const columnsRecord = useMemo(
    () => [
      {
        Header: `${props.intl.formatMessage({ id: "common.time" })}`,
        width: 60,
        Cell: ({ original }: any) => (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {formatUTCtoMoment(original?.measure_date).format("HH:mm")}
          </div>
        ),
      },
      {
        Header: `${props.intl.formatMessage({ id: "weight.weight_unit" })}`,
        width: 70,
        Cell: ({ original }: any) => (
          <div style={{ textAlign: "center" }}>{original.data.value}</div>
        ),
      },
      {
        Header: `${props.intl.formatMessage({ id: "common.bmi" })}`,
        width: 80,
        Cell: ({ original }: any) => (
          <div style={{ textAlign: "center" }}>
            {Number.isNaN(+original.data.bmi)
              ? props.intl.formatMessage({ id: "bplusClinicKey664" })
              : original.data.bmi}
          </div>
        ),
      },
      {
        Header: `${props.intl.formatMessage({ id: "common.note_short" })}`,
        minWidth: 80,
        Cell: ({ original }: any) => (
          <div style={{ textAlign: "center" }}>{original.data.note}</div>
        ),
      },
      {
        Header: "",
        accessor: "_remove",
        width: 30,
        Cell: ({ original }: any) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              color="red"
              icon="minus"
              circular
              style={{ fontSize: "6px" }}
              onClick={() => {
                setSelectedItem(original);
                setOpenModConfirm(true);
              }}
            />
          </div>
        ),
      },
    ],
    [props.selectedPatient]
  );

  useEffect(() => {
    props.onEvent({ message: "GetWeightRecord", params: {} });
  }, [props.selectedPatient, props.weightFilterDate]);

  // ---------- GET ----------
  const handleGetRowProps = () => {
    return {
      style: {
        borderBottom: 0,
      },
    };
  };

  // ---------- CHANGE ----------
  const handleChangeWeight = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    props.onEvent({
      message: "HandleSetInputWeightDetail",
      params: { weight: data.value },
    });
  };

  const handleChangeNote = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    props.onEvent({
      message: "HandleSetInputWeightDetail",
      params: { note: data.value },
    });
  };

  const handleClearInput = () => {
    props.onEvent({
      message: "HandleSetInputWeightDetail",
      params: { weight: "", note: "" },
    });
  };

  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: any = props.weightFilterDate;
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    props.onEvent({
      message: "handleChangeWeightFilterDate",
      params: { date: targetDate },
    });
  };

  const handleChangeDateRecord = (date: string) => {
    props.onEvent({
      message: "handleChangeWeightFilterDate",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  // ---------- SAVE ----------
  const handleSaveWeightRecord = () => {
    props.onEvent({
      message: "SaveWeightRecord",
      params: {
        measure_date: `${props.weightFilterDate?.format(
          BACKEND_DATE_FORMAT
        )} ${moment().format(BACKEND_TIME_FORMAT)}`,
        data: {
          value: props.inputWeightDetail?.weight,
          note: props.inputWeightDetail?.note,
        },
      },
    });

    if (typeof window.iosNative !== "undefined") {
      window.iosNative.saveWeight(`${props.inputWeightDetail?.weight}`);
    } else if (typeof window.MobNative !== "undefined") {
      window.MobNative.saveWeight(`${props.inputWeightDetail?.weight}`);
    }

    handleClearInput();
  };

  const handleDeleteWeightRecord = () => {
    props.onEvent({ message: "DeleteWeightRecord", params: selectedItem });
    handleCloseModConfirm();
  };

  // ---------- MODAL ----------
  const handleCloseModConfirm = () => {
    setSelectedItem({});
    setOpenModConfirm(false);
  };

  return (
    <>
      <PatientPanel patientInfo={props.selectedPatient} />

      <DMDatePicker
        date={props.weightFilterDate}
        onChangeStepDateRecord={handleChangeStepDateRecord}
        onChangeDateRecord={handleChangeDateRecord}
      />

      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <Form style={{ padding: "0px 10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "60% 40%",
            marginBottom: "2em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "end",
              color: COLOR.medium_blue,
            }}
          >
            <Header
              as="h3"
              style={{ margin: "0 10px 0 0", color: COLOR.medium_blue }}
            >
              {props.intl.formatMessage({ id: "weight.weight_recording" })}
            </Header>
            <Icon
              name="camera retro"
              size="large"
              onClick={() => {
                props.onCameraClick?.();
              }}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <Icon
              name="cog"
              color="blue"
              size="large"
              onClick={() => {
                props.onSettingClick?.();
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            justifyContent: "center",
            padding: "0 5em",
            marginTop: "5px",
          }}
        >
          <Input
            ref={(instance: any) => {
              if (instance) {
                instance.inputRef.current.style.textAlign = "right";
              }
            }}
            label={{
              basic: true,
              content: `${intl.formatMessage({ id: "bplusClinicKey572" })}`,
            }}
            labelPosition="right"
            value={props.inputWeightDetail?.weight}
            type="number"
            style={{ marginRight: "43px" }}
            onChange={handleChangeWeight}
            placeholder={props.intl.formatMessage({
              id: "weight.enter_weight",
            })}
          />
          <div style={{ marginTop: "15px", display: "flex", width: "100%" }}>
            <Input
              icon="edit"
              iconPosition="left"
              fluid
              placeholder={`${props.intl.formatMessage({
                id: "common.note_short",
              })}...`}
              value={props.inputWeightDetail?.note}
              style={{ width: "100%" }}
              onChange={handleChangeNote}
            />
            <Button
              icon="save"
              color="blue"
              style={{ marginLeft: "5px", marginRight: "-43px" }}
              onClick={handleSaveWeightRecord}
              disabled={!props.inputWeightDetail?.weight}
            />
          </div>
        </div>

        <div style={{ marginTop: "3em" }}>
          <Header
            as="h3"
            style={{ marginRight: "10px", color: COLOR.medium_blue }}
          >
            {props.intl.formatMessage({ id: "weight.today_weight_rec" })}
          </Header>

          {props.weightRecordList?.items?.length > 0 ? (
            <ReactTable
              className="weight-table-custom"
              data={props.weightRecordList?.items}
              columns={columnsRecord}
              pageSize={
                props.weightRecordList?.items.length > 3
                  ? props.weightRecordList?.items.length
                  : 3
              }
              style={{ minHeight: "150px" }}
              showPagination={false}
              resizable={false}
              getTrGroupProps={(state: any, rowInfo: any) => {
                return handleGetRowProps();
              }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              {props.intl.formatMessage({ id: "common.no_data" })}
            </div>
          )}
        </div>

        <ModConfirm
          openModal={openModConfirm}
          onDeny={handleCloseModConfirm}
          onCloseWithDimmeClick={handleCloseModConfirm}
          onApprove={handleDeleteWeightRecord}
          titleColor="blue"
          titleName="ต้องการลบหรือไม่"
        />
      </Form>
    </>
  );
};

const DMWeight = (props: DMWeightProps) => {
  const intl = useIntl();
  let history = useHistory();

  const [viewIndex, setViewIndex] = useState<number | string>(0);
  //Open
  const [openScreenSetting, setOpenScreenSetting] = useState(false);
  const [locationKeys, setLocationKeys] = useState<string[]>([]);
  const [disabledSwipeable, setDisabledSwipeable] = useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "GetLatestHeight", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }

        if (openScreenSetting) {
          setOpenScreenSetting(false);
        }
      }
    });
  }, [locationKeys]);

  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="details" style={styles.tabs}>
            <FormattedMessage id="weight.detail" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="history" style={styles.tabs}>
            <FormattedMessage id="weight.history" />
          </Menu.Item>
        ),
      },
    ],
    []
  );

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleTabChange = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleSettingClick = () => {
    history.push(`${globalThis.location.search}&setting=true`);
    setOpenScreenSetting(true);
  };

  const handleCameraClick = () => {
    // props.onEvent({ message: "HandleEvent", params: { name: "scanWeight" } });
    console.log(window.MobNative, window.iosNative);
    if (typeof window.iosNative !== "undefined") {
      console.log("call window.iosNative.scanWeight");
      window.iosNative.scanWeight?.();
    } else if (typeof window.MobNative !== "undefined") {
      console.log("call window.MobNative.scanWeight");
      window.MobNative.scanWeight?.();
    }
  };

  const handleLeftIconClick = () => {
    if (openScreenSetting) {
      history.goBack();
    } else {
      props.onSetBackToApp?.();
    }
  };

  const handleHoverChart = (
    e: TouchEvent<HTMLDivElement> | MouseEvent,
    type: "start" | "end"
  ) => {
    if (type === "start" && (e.target as any).tagName === "CANVAS") {
      setDisabledSwipeable(true);
    } else if (type === "end") {
      setDisabledSwipeable(false);
    }
  };

  return (
    <div id="DMWeight">
      {/* Settings */}
      <Slide direction="left" in={openScreenSetting} mountOnEnter unmountOnExit>
        {
          <div
            id="Add Weight DM"
            style={{
              backgroundColor: "white",
              position: "fixed",
              top: 0,
              zIndex: 1101,
              width: "100%",
              height: "100%",
              right: "0",
            }}
          >
            <>
              {/* TODO: change Weight Settings to Weight */}
              <AppBar position="fixed" color="default">
                <MobHead
                  leftIcon="arrow left"
                  leftIconClick={handleLeftIconClick}
                  title={intl.formatMessage({ id: "bplusClinicKey669" })}
                />
              </AppBar>
              {openScreenSetting ? (
                <DMWeightSetting
                  onEvent={props.onEvent}
                  successMessage={props.successMessage}
                  errorMessage={props.errorMessage}
                  loadingStatus={props.loadingStatus}
                  selectedPatient={props.selectedPatient}
                  weightSettingData={props.weightSettingData}
                />
              ) : null}
            </>
          </div>
        }
      </Slide>

      <AppBar position="fixed" color="default">
        <MobHead
          leftIcon="arrow left"
          leftIconClick={props.onSetBackToApp}
          title={intl.formatMessage({ id: "weight.weight" })}
        />
      </AppBar>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Tab
        className="weight-tab-custom dm"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
      />
      <SwipeableViews
        index={+viewIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="dm-view"
        disabled={disabledSwipeable}
      >
        <div style={{ paddingBottom: "35px" }}>
          <DetailsTab
            // Callback
            onSettingClick={handleSettingClick}
            onCameraClick={handleCameraClick}
            {...props}
          />
          {/* <HistoryTab /> */}
        </div>
        <div style={{ paddingBottom: "35px" }}>
          <HistoryTab
            // function
            onEvent={props.onEvent}
            // data
            weightFilterDuration={props.weightFilterDuration}
            selectedPatient={props.selectedPatient}
            weightChartList={props.weightChartList}
            isMyTab={viewIndex === 1}
            onHoverChart={handleHoverChart}
          />
        </div>
      </SwipeableViews>
    </div>
  );
};

export const columnsBMI = [
  {
    Header: (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        <FormattedMessage id="profile.bmi" />{" "}
        <label style={{ fontWeight: "normal", fontStyle: "italic" }}>
          <FormattedMessage id="bplusClinicKey8" />
        </label>
      </div>
    ),
    accessor: " ",
    width: 150,
    Cell: (row: { original: { bmi: string } }) => (
      <div style={styles.bmiLeftBold}>{row.original?.bmi}</div>
    ),
  },
  {
    Header: (
      <div style={{}}>
        <FormattedMessage id="bplusClinicKey468" />
      </div>
    ),
    accessor: " ",
    minWidth: 80,
    Cell: (row: { original: { color: any; status: string } }) => (
      <div style={{ color: row.original.color, ...styles.bmiLeftBold }}>
        {row.original?.status}
      </div>
    ),
  },
];

const styles = {
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
  } as CSSProperties,
  bmiLeftBold: {
    fontWeight: "bold",
    textAlign: "left",
    paddingLeft: "5px",
  } as CSSProperties,
};

DMWeight.defaultProps = DMWeightInitial;

export default React.memo(injectIntl(DMWeight));
