import { WasmHandler } from "../../../react-lib/frameworks/WasmController";
import CONFIG from "../../../config/config";
// Interface
import {
  commonCreatePatientSegment,
  commonDeletePatientSegment,
  commonListPatientSegmentList,
  commonListProxyPatientNoUser,
  CLINIC,
  DM_RESULT,
  DM_SUB_RESULT,
  MOM_RESULT,
  MOM_SUB_RESULT,
  SEGMENT_TYPE,
} from "../../ManageInterface";
import { State as MainState } from "../../ManageInterface";

// Apis

export type State = {
  loadingPatientSelection?: boolean;
  filterKeyword?: string;
  dmMomSegmentList?: any;
  dmMomPatientList?: any;
};

type Picked = Pick<
  MainState,
  | "errorMessage"
  | "successMessage"
  | "loadingStatus"
  | "clinic"
  | "selectedDivision"
>;

export const StateInitial: State = {
  loadingPatientSelection: false,
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
};

export type Event =
  // GET
  | {
      message: "handleGetDmMomSegmentList";
      params: { result: string; limit: number; offset: number };
    }
  | {
      message: "handleGetProxyPatientList";
      params: { keyword: string; limit: number; offset: number };
    }
  // CHANGE
  | { message: "handleChangeFilterKeyword"; params: { keyword: string } }
  // SAVE
  | { message: "handleCreatePatientSegment"; params: {} }
  | { message: "handleDeletePatientSegment"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetDmMomSegmentList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingPatientSelection: true });

  const state = controller.getState();
  const [error, response] = await commonListPatientSegmentList(
    controller as any,
    {
      segment_type: SEGMENT_TYPE.CLINICAL,
      segment_group: state.selectedDivision?.division_code,
      keyword: state.filterKeyword,
      ...params, // result, limit, offset
    }
  );

  controller.setState({
    loadingPatientSelection: false,
    errorMessage: error ? error : null,
    successMessage: null,
    dmMomSegmentList: response,
  });
};

export const handleGetProxyPatientList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const [error, response] = await commonListProxyPatientNoUser(
    controller as any,
    params // keyword, limit, offset
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    dmMomPatientList: response,
  });
};

// CHANGE
export const handleChangeFilterKeyword: Handler = async (
  controller,
  params
) => {
  controller.setState({ filterKeyword: params.keyword });
};

// SAVE
export const handleCreatePatientSegment: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let result: string = "";
  if (state.clinic === CLINIC.DM) {
    if (state.selectedDivision?.profile?.feature !== CONFIG.DM_FEATURE) {
      controller.setState({
        errorMessage: `ต้องตั้งค่า Division feature เป็น ${CONFIG.DM_FEATURE}`,
        successMessage: null,
      });
      return;
    }
    result = `${DM_RESULT.DM} ${DM_SUB_RESULT.UNCLASSIFIED}`;
  } else if (state.clinic === CLINIC.MOM) {
    if (state.selectedDivision?.profile?.feature !== CONFIG.MOM_FEATURE) {
      controller.setState({
        errorMessage: `ต้องตั้งค่า Division feature เป็น ${CONFIG.MOM_FEATURE}`,
        successMessage: null,
      });
      return;
    }
    result = `${MOM_RESULT} ${MOM_SUB_RESULT.PREGNANCY_UNKNOW}`;
  } else {
    controller.setState({
      errorMessage: "Clinic ไม่ถูกต้อง ต้องเป็น dm หรือ mom",
      successMessage: null,
      dmMomPatientList: {},
    });
    return;
  }

  let data: any[] = [];
  params?.forEach((item: any) => {
    data.push({ patient: item.id, result: result });
  });

  controller.setState({ loadingStatus: true });

  let successMessage: any = null;
  const [error, response] = await commonCreatePatientSegment(
    controller as any,
    { patients: [...data] }
  );
  if (error) {
  } else {
    successMessage = "Create Patient Segment success.";

    if (state.clinic === CLINIC.DM) {
      handleGetDmMomSegmentList(controller, { result: DM_RESULT.DM });
    } else if (state.clinic === CLINIC.MOM) {
      handleGetDmMomSegmentList(controller, { result: MOM_RESULT });
    }
  }

  controller.setState({
    errorMessage: error ? error : null,
    successMessage: successMessage,
    dmMomPatientList: {},
  });
};

export const handleDeletePatientSegment: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  if (params?.id) {
    const [error, response] = await commonDeletePatientSegment(
      controller as any,
      {
        id: params.id,
      }
    );
    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Delete Patient Segment success.";
      const state = controller.getState();
      if (state.clinic === CLINIC.DM) {
        handleGetDmMomSegmentList(controller, { result: DM_RESULT.DM });
      } else if (state.clinic === CLINIC.MOM) {
        handleGetDmMomSegmentList(controller, { result: MOM_RESULT });
      }
    }

    controller.setState({
      errorMessage: error ? error : null,
      successMessage: successMessage,
    });
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "ID not found.",
      successMessage: null,
    });
  }
};
