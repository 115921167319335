import React, { useEffect, useState } from "react";
import TabPath from "react-lib/frameworks/TabPath";

import PreferredManage from "./PreferredManage";
// import PreferredManageAppointment from "./PreferredManageAppointment";
import MenuIcon from "./MenuIcon";
import CONFIG from "../../config/config";

export enum PREFERRED_MODE {
  HOME = "home",
  APPOINTMENT = "appointment",
}

const HOME_OPTION = ["Main service", "Secondary service", "Other service"];
const APPOINTMENT_OPTION = [ 
  {label: "Med Program", key: "med_program"}, 
  {label: "Subspecialty", key: "subspecialty"}, 
]

const ALL_SERVICE_OPTIONS = [
  "Appointment",
  "Vaccine",
  "Check up",
  "Personal Health Record",
  "save drug",
  "Navigation",
  "Package & Promotion",
  "Health tips",
  "Department",
  "Payment",
  "Consent",
  "Emergency",
  "Insurance",
   ...(CONFIG.DUBAI_TELE_CONSULT_ENABLE ? ["Dubai Tele-consult"] : [])
];

const Preferred = (props: any) => {

  const [homeFilter, setHomeFilter] = useState([])
  const [allServiceOption, setAllServiceOption] = useState<string[]>([])

  useEffect(() => {
    
    if (props.servicesOfHospitalFB) {
      let array = props.servicesOfHospitalFB?.filter(
        (item: any) => item.mode === PREFERRED_MODE.HOME
      )
      setHomeFilter(array);
    }
    
  }, [props.servicesOfHospitalFB])

  useEffect(() => {
    if (props.selectedOrganizationCode) {
      props.onEvent({message: "PreferedDidMount", params: {}})
      if (props.selectedOrganizationCode === "1") {
        let tmp = [...ALL_SERVICE_OPTIONS]
        tmp.push("DMClinic","MomClinic","ChildClinic")
        setAllServiceOption(tmp)
      } else {
        setAllServiceOption(ALL_SERVICE_OPTIONS)
      }
    }
  }, [props.selectedOrganizationCode])
  
  
  return (
    <div className="MainDm-tabPath" style={{ height: "100%" }}>
      {/* 94vh */}
      <TabPath
        selectedTabIndex={0}
        level={1}
        history={props.history}
        tabWidth={props.showPatientDM ? "0" : "5.7vw"}
        // onTabChange={handleTabChange}
        data={[
          {
            name: <MenuIcon label="Home" icon="/images/expansion/home.png" />,
            key: "home",
            render: () => (
              <div style={{ height: "100%" }}>
                <PreferredManage
                  controller={props.controller}
                  mode={PREFERRED_MODE.HOME}
                  selectedOrganizationCode={props.selectedOrganizationCode}
                  servicesOfHospitalFB={homeFilter}
                  servicesOfHospitalDJ={[]}
                  onEvent={props.onEvent}
                  organizationList={props.organizationList}
                  preferredLoading={props.preferredLoading}
                  loadingStatus={false}
                  typeOptions={HOME_OPTION}
                  serviceOptions={allServiceOption}
                />
              </div>
            ),
          },
          {
            name: (
              <MenuIcon
                label="Appointment"
                icon="/images/expansion/calendar.png"
              />
            ),
            key: "appointment",
            render: () => (
              <div style={{ height: "100%" }}>
                <PreferredManage
                  controller={props.controller}
                  mode={PREFERRED_MODE.APPOINTMENT}
                  selectedOrganizationCode={props.selectedOrganizationCode}
                  servicesOfHospitalFB={[]}
                  servicesOfHospitalDJ={props.servicesOfHospitalDJ}
                  onEvent={props.onEvent}
                  organizationList={props.organizationList}
                  preferredLoading={props.preferredLoading}
                  loadingStatus={false}
                  typeOptions={APPOINTMENT_OPTION}
                  serviceOptions={allServiceOption}
                  medProgramList={props.medProgramList}
                  subspecialtiesList={props.subspecialtiesList}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default React.memo(Preferred);
