import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import PureReactTable from "../../react-lib/component-js/common/PureReactTable";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

// UI
import SnackMessage from "./SnackMessage";
import WebPatientPanel from "../manage/WebPatientPanel";

export const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
};

// ---------- WebMomChildClassification
type WebMomChildClassificationProps = {
  onEvent: any;
  errorMessage: any;
  successMessage: any;
  patientList: { items: any[] };
  selectedPatient: any;
};

const WebMomChildClassificationInitial: WebMomChildClassificationProps = {
  onEvent: () => null,
  errorMessage: null,
  successMessage: null,
  patientList: { items: [] },
  selectedPatient: null,
};

const WebMomChildClassification: React.FC<WebMomChildClassificationProps> = (
  props
) => {
  const [patientSelectedRow, setPatientSelectedRow] = useState<any>(null);

  const patientColumn = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 170 },
      { Header: "ชื่อ - นามสกุล", accessor: "fullname", width: 350 },
      { Header: "Birthdate", accessor: "birthdate", width: 150 },
      { Header: "Last visit date", accessor: "last_visit_date", width: 150 },
      { Header: "Appointment date", accessor: "appointment_date", width: 150 },
    ],
    []
  );

  useEffect(() => {
    if (props.patientList?.items?.length > 0) {
      handleSelectedPatient(props.patientList.items[0]);
    }
  }, [props.patientList]);

  const handleSelectedPatient = (row: any) => {
    setPatientSelectedRow(row);
    props.onEvent({ message: "handleSeletedPatient", params: row });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Grid>
        <Grid.Column width={3} style={{ padding: "15px 10px" }}>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell><FormattedMessage id="bplusClinicKey392" /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><FormattedMessage id="bplusClinicKey394" /></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width={10} style={{ padding: "15px 0px" }}>
          <PureReactTable
            className="queue-table-custom"
            selectedRow={patientSelectedRow}
            data={props.patientList?.items}
            columns={patientColumn}
            showPagination={true}
            pageSize={25}
            onSelect={handleSelectedPatient}
            manual
          />
        </Grid.Column>
        <Grid.Column width={3} style={{ padding: "0px 15px 0px 0px" }}>
          <WebPatientPanel selectedPatient={props.selectedPatient} />
        </Grid.Column>
      </Grid>
    </>
  );
};

WebMomChildClassification.defaultProps = WebMomChildClassificationInitial;

export default React.memo(WebMomChildClassification);
