import React, { useEffect, useState, useMemo } from "react";

import { FormattedMessage } from "react-intl";

import { Dimmer, Header, Icon, Loader, Progress } from "semantic-ui-react";
import { Grid } from "@mui/material";
import { Bar, Doughnut } from "react-chartjs-2";

import "../../css/WebClinic.scss";
import moment from "moment";
import { prepareDataDraw } from "../clinic/DMBloodPressure";

type MarketingSegmentStatisticProps = {
  // function
  onEvent: any;
  // data
  loadingStatus?: boolean;
  filterMarketingPatient?: any;
  marketingPatientList?: any;
  marketingSegmentAgeGender?: any;
  marketingSegmentTopPost?: any[];
  marketingSegmentTopPromotion?: any[];
  isMyTab?: boolean;
};

const MarketingSegmentStatisticInitial: MarketingSegmentStatisticProps = {
  // funtion
  onEvent: () => null,
  // data
  loadingStatus: false,
  filterMarketingPatient: {},
  marketingPatientList: null,
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  isMyTab: false,
};

const WOMEN = "F";
const MEN = "M";

const MarketingSegmentStatistic: React.FC<MarketingSegmentStatisticProps> = (
  props: any
) => {
  const [barData, setBarData] = useState<any>({ labels: [], datasets: [] });
  const [detailLabel, setDetailLabel] = useState<any>({
    namesegment: "",
    datesegment: "",
  });
  const [doughnutData, setDoughnutData] = useState<any>({
    labels: [],
    datasets: [],
  });

  // useEffect(() => {
  //   if (props.filterMarketingPatient?.result && props.isMyTab) {
  //     props.onEvent({ message: "handleGetMarketingSummary" });
  //     props.onEvent({ message: "handleGetTopPost" });
  //     props.onEvent({ message: "handleGetTopPromotion" });
  //   }
  // }, [props.filterMarketingPatient, props.isMyTab]);

  const doughnutPlugins = useMemo(() => {
    const radius = 15;
    return [
      {
        id: "plugins-chart",
        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          chart.data.datasets.forEach((dataset: any, i: any) => {
            var totalvalue = 0;
            dataset.data.map((item: any) => {
              totalvalue = totalvalue + item;
            });
            chart
              .getDatasetMeta(i)
              .data.forEach((datapoint: any, index: any) => {
                const { x, y } = datapoint.tooltipPosition();
                ctx.fillStyle = "black";
                ctx.beginPath();
                ctx.font = `${radius}px Arial`;
                const halfheight = chart.height / 2;
                const halfwidth = chart.width / 2;
                const dataValue = chart.data.datasets[0].data[index];
                const sumValue: number = chart.data.datasets[0].data[index];
                const percent =
                  totalvalue != 0 ? (dataValue / totalvalue) * 100 : 0;
                // console.log('chen totalvalue: ',totalvalue);
                // console.log('chen dataValue: ',dataValue);
                // console.log('chen sumValue: ',sumValue);
                const positiony = y >= halfheight ? y + 10 : y - 15;
                const positionx = x >= halfwidth ? x + 20 : x - 15;
                const extraPositionx = x >= halfwidth ? 10 : -20;
                const textPosition = x >= halfwidth ? "left" : "right";
                ctx.textAlign = textPosition;
                ctx.textBaseline = "middle";
                ctx.fillStyle = "black";
                let precentText = "";
                if (
                  (0 < percent && percent < 1) ||
                  (99 < percent && percent < 100)
                ) {
                  precentText = `${percent.toFixed(1)}`;
                } else {
                  precentText = `${percent.toFixed(0)}`;
                }

                ctx.fillText(
                  percent != 0 ? `${precentText}%` : "",
                  positionx + extraPositionx,
                  positiony
                );
                ctx.textAlign = textPosition;
                ctx.font = `${radius - 3}px Arial`;
                ctx.fillStyle = "black";
                ctx.fillText(
                  sumValue != 0 ? `(${sumValue.toLocaleString()})` : "",
                  positionx + extraPositionx,
                  positiony + 13
                );
              });
          });
        },
      },
    ];
  }, []);

  const plugins = useMemo(() => {
    const top = 30;
    const radius = 15;
    const allowIndex = [0, 1];
    const allowStack = [0];
    const hideFillText = false;
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          const ctx = chart.ctx;
          const x = chart.chartArea.left;
          const topY = 0;
          const bottomY = chart.chartArea.bottom;
          const rightY = chart.chartArea.right;

          // draw line
          if (ctx) {
            ctx.save();
            //  bottom line
            ctx.beginPath();
            ctx.moveTo(x, bottomY);
            ctx.lineTo(rightY, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#C4C4C4";
            ctx.stroke();
            ctx.restore();

            ctx.beginPath();
            ctx.font = `${radius}px Arial`;
            ctx.fillStyle = "black";
            ctx.fillText("0%", x - (30 / 2 + 0.5), bottomY + (10 / 2 + 0.5));
          }
        },

        afterDatasetsDraw: (chart: any) => {
          const ctx = chart.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";

          const barDrawText = (bar: any) => {
            const ctx = chart.ctx;
            const y_axis = bar._yScale;
            const _model = bar._model;
            const centerX = _model.x;
            const topY = y_axis.top;
            const centerY = topY + _model.y - top;
            var Valuetext = "";

            if (
              bar._chart.config.data.datasets[bar._datasetIndex]?.data[
                bar._index
              ] !== 0
            ) {
              Valuetext = bar._chart.config.data.datasets[
                bar._datasetIndex
              ]?.data[bar._index]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            if (!hideFillText) {
              ctx.beginPath();
              ctx.font = `${radius}px serif`;
              ctx.fillStyle = "black";
              ctx.fillText(Valuetext, centerX, centerY + (radius / 2 + 0.5));
            }
          };

          const { barList } = prepareDataDraw({
            chart,
            allowIndex,
            allowStack,
          });
          for (const bar of barList) {
            barDrawText(bar);
          }
        },
      },
    ];
  }, []);

  useEffect(() => {
    let barLabels: any[] = [];
    let barWomen: any[] = [];
    let barMen: any[] = [];
    // Bar
    props.marketingSegmentAgeGender?.age_items?.forEach((item: any) => {
      barLabels.push(item?.age);
      let dataF: number = 0;
      let dataM: number = 0;
      item?.result?.forEach((result: any) => {
        if (result?.proxy_patient__gender === WOMEN) {
          dataF = result?.count_patient;
        } else if (result?.proxy_patient__gender === MEN) {
          dataM = result?.count_patient;
        }
      });
      barWomen.push(dataF);
      barMen.push(dataM);
    });
    setBarData({
      labels: barLabels,
      datasets: [
        { label: "Women", data: barWomen, backgroundColor: "#E15EB4" },
        { label: "Men", data: barMen, backgroundColor: "#3237AC" },
      ],
    });

    // Doughnut
    let doughnutWomen: number = 0;
    let doughnutMen: number = 0;
    props.marketingSegmentAgeGender?.gender_items?.forEach((item: any) => {
      if (item?.proxy_patient__gender === WOMEN) {
        doughnutWomen = item?.count_patient;
      } else if (item?.proxy_patient__gender === MEN) {
        doughnutMen = item?.count_patient;
      }
    });
    setDoughnutData({
      labels: ["Men", "Women"],
      datasets: [
        {
          label: "count",
          data: [doughnutMen, doughnutWomen],
          backgroundColor: ["#3237AC", "#E15EB4"],
          borderColor: ["#ffffff", "#ffffff"],
          borderWidth: 1,
        },
      ],
    });
    if (props.marketingPatientList?.items == null ) {
      setDetailLabel({ namesegment: "", dateSegment: "" });
    } else {
      const nameSegment = props.marketingPatientList?.items[0]?.result;
      const dateSegment = moment(
        props.marketingPatientList.items[0]?.edited_at
      ).format("DD/MM/YYYY HH:mm");
      setDetailLabel({ namesegment: nameSegment, dateSegment: dateSegment });
    }
  }, [
    props.marketingPatientList,
    props.marketingSegmentAgeGender,
    props.marketingSegmentAgeGender?.age_items,
    props.marketingSegmentAgeGender?.gender_items,
  ]);

  return (
    <div>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <Grid container spacing={1}>
        <Grid item lg={8} md={4} sm={2}>
          <Header as="h1" style={{ marginBottom: "0" }}>
            {detailLabel.namesegment ? detailLabel.namesegment : "Segmant"}
          </Header>
          <div
            style={{
              color: "var(--text-grey)",
              marginBlockStart: "8px",
              fontSize: "18px",
            }}
          >
            last updated:{" "}
            {moment().subtract(1, 'days').format("DD/MM/YYYY 23:59")}
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={5}>
          <Header as="h4" style={{ color: "var(--text-grey)" }}>
            Total members
          </Header>
          <Header as="h3">
            {props.marketingSegmentAgeGender?.total_patient?.[0]
              ?.count_patient || ""}
          </Header>
        </Grid>
        <Grid item lg={2} md={4} sm={5}>
          <Header as="h4" style={{ color: "var(--text-grey)" }}>
            Daily Active Users
          </Header>
          <Header as="h3">
            {props.marketingSegmentAgeGender?.active_patient?.[0]
              ?.count_patient || ""}
          </Header>
        </Grid>
      </Grid>

      {/* Chart */}
      <Grid container spacing={1}>
        <Grid item lg={8} md={4} sm={2}>
          <div
            style={{
              margin: "2rem 0 1rem",
            }}
          >
            <Header as="h5" style={{ color: "var(--text-grey)" }}>
              Age & gender
              <Icon name="info circle" size="mini" />
            </Header>
          </div>
        </Grid>
        <Grid item lg={4} md={8} sm={5}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "2rem 0 1rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#E15EB4",
                width: "20px",
                height: "20px",
              }}
            />
            <label style={{ margin: "0 30px 0 10px", fontWeight: "bold" }}>
              Women
            </label>
            <div
              style={{
                backgroundColor: "#3237AC",
                width: "20px",
                height: "20px",
              }}
            />
            <label style={{ margin: "0 20px 0 10px", fontWeight: "bold" }}>
              Men
            </label>
          </div>
        </Grid>
      </Grid>

      <Doughnut
        redraw={true}
        width={1200}
        options={{
          responsive: true,
          legend: { display: false },
          cutoutPercentage: 55,
          layout: {
            padding: 20,
          },
        }}
        data={doughnutData}
        plugins={doughnutPlugins}
      />
      <Bar
        redraw={true}
        width={900}
        options={{
          responsive: true,
          legend: { display: false },
          scales: {
            yAxes: [
              {
                display: false,
                gridLines: { display: false },
              },
            ],
            xAxes: [
              {
                gridLines: { display: false },
              },
            ],
          },
          layout: {
            padding: {
              left: 50,
              top: 20,
            },
          },
        }}
        data={barData}
        plugins={plugins}
      />

      {/* Top data */}
      <Grid container spacing={1} style={{ paddingTop: "20px" }}>
        <Grid item lg={6} md={6} sm={12}>
          <Header as="h4">Top Post</Header>
          {props.marketingSegmentTopPost?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                style={{ margin: "0px", paddingBottom: "10px", width: "90%" }}
              >
                {item.name}
                <Progress
                  progress
                  className="SegmentChart"
                  size="small"
                  percent={item.likes}
                  value={`${item.likes}%`}
                />
              </div>
            );
          })}
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <Header as="h4">Top Promotion</Header>
          {props.marketingSegmentTopPromotion?.map(
            (item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{ margin: "0px", paddingBottom: "10px", width: "90%" }}
                >
                  {item.name}
                  <Progress
                    progress
                    className="SegmentChart"
                    size="small"
                    percent={item.likes}
                    value={`${item.likes}%`}
                  />
                </div>
              );
            }
          )}
        </Grid>
      </Grid>
    </div>
  );
};

MarketingSegmentStatistic.defaultProps = MarketingSegmentStatisticInitial;

export default React.memo(MarketingSegmentStatistic);
