import React, {useState} from 'react'
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

function CameraTest() {
  const [source , setSource] = useState(undefined)

  const handleCapture = (target) => {
    console.log("target", target)
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        console.log("newUrl",newUrl)
        setSource(newUrl);
      }
    }
  }

  return (<>
    <h5><FormattedMessage id="bplusClinicKey107" /></h5>

    <img src={source} style={{height: "400px", width: "400px"
    }} />
    <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={(e) => {handleCapture(e.target) } }
      />
      <label htmlFor="icon-button-file">
        <FormattedMessage id="bplusClinicKey106" />
      </label>
      </>
  )
}

export default CameraTest;
