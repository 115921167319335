import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import { FormattedMessage } from "react-intl";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import ChildGrowthChart from "../../clinic/ChildGrowthChart";

const styles = {
  DivHeader: {
    backgroundColor: "var(--theme-bg-color-dm, DFF5FC)",
    padding: "20px",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  HeaderColor: {
    fontSize: "27px",
    color: "#4F4F4F",
    fontWeight: "bold",
    paddingLeft: "15px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  } as CSSProperties,
  ButtonOn: {
    color: "#2399E5",
    background: "#FFFFFF",
    border: "1px solid #2399E5",
    borderRadius: "4px",
  } as CSSProperties,
  ButtonOff: {
    color: "#FFFFFF",
    background: "#2399E5",
    border: "1px solid #2399E5",
    borderRadius: "4px",
  } as CSSProperties,
  TextTitle: {
    marginLeft: "3%",
    paddingBottom: "15px",
    fontWeight: "bold",
    color: "#1621A8",
  } as CSSProperties,
  TextTitleUnder: {
    color: "#1621A8",
    textAlign: "center",
    fontWeight: "bold",
  } as CSSProperties,
  TextLastUpdate: {
    color: "#656565",
    fontSize: "12px",
    paddingRight: "10px",
  } as CSSProperties,
  TextDate: {
    color: "#000000",
    fontSize: "12px",
  } as CSSProperties,
};

type WebChildGrowthChartProps = {
  // function
  onEvent: any;
  // data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  growthHistoryList?: any;
  selectedPatient?: any;
};

const WebChildGrowthChartInitial: WebChildGrowthChartProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  growthHistoryList: {},
  selectedPatient: {},
};

const WebChildGrowthChart: React.FC<WebChildGrowthChartProps> = (props) => {
  const [status, setStatus] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<any>("");
  const [genderMale, setGenderMale] = useState<boolean>(false);
  const [genderFemale, setGenderFemale] = useState<boolean>(false);
  const [menuTab, setMenuTab] = useState<
    "weight" | "height" | "weight_height" | "head"
  >("weight");

  useEffect(() => {
    props.onEvent({
      message: "getGrowthHistoryList",
      params: {},
    });
    console.log("get history");
    if (props.selectedPatient.gender_name == "Male") {
      setGenderMale(true);
    } else if (props.selectedPatient.gender_name == "Female") {
      setGenderFemale(true);
    }
  }, [props.selectedPatient]);

  useEffect(() => {
    console.log("growthHistory: ", props.growthHistoryList);
    if (
      props.growthHistoryList?.items &&
      props.growthHistoryList?.items.length > 0
    ) {
      let weightSetData = [];
      let heightSetData = [];
      for (const item of props.growthHistoryList.items) {
        weightSetData.push(item.weight);
        heightSetData.push(item.height);
      }

      let lastDate = moment(
        props.growthHistoryList.items.slice(-1)[0].selectedDate,
        "DD MMMM YYYY"
      )
        .locale("en")
        .format("DD MMM YYYY");
      setLastUpdate(lastDate);
      setStatus(true);
    }
  }, [props.growthHistoryList]);

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="growth.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/child/growth.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey221" />
        </span>
      </div>

      <div className="sub-layout">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{ paddingBottom: "20px", marginLeft: "5px" }}
              onClick={() => {
                setMenuTab("weight");
              }}
            >
              <Button
                style={
                  menuTab === "weight" ? styles.ButtonOn : styles.ButtonOff
                }
              >
                <FormattedMessage id="bplusClinicKey59" />
              </Button>
            </div>
            <div
              style={{ paddingBottom: "20px", marginLeft: "5px" }}
              onClick={() => {
                setMenuTab("height");
              }}
            >
              <Button
                style={
                  menuTab === "height" ? styles.ButtonOn : styles.ButtonOff
                }
              >
                <FormattedMessage id="bplusClinicKey58" />
              </Button>
            </div>
            <div
              style={{ paddingBottom: "20px", marginLeft: "5px" }}
              onClick={() => {
                setMenuTab("weight_height");
              }}
            >
              <Button
                style={
                  menuTab === "weight_height"
                    ? styles.ButtonOn
                    : styles.ButtonOff
                }
              >
                <FormattedMessage id="bplusClinicKey532" />
              </Button>
            </div>
            <div
              style={{ paddingBottom: "20px", marginLeft: "5px" }}
              onClick={() => {
                setMenuTab("head");
              }}
            >
              <Button
                style={menuTab === "head" ? styles.ButtonOn : styles.ButtonOff}
              >
                <FormattedMessage id="bplusClinicKey56" />
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: "10px", display: "flex" }}>
            <div style={styles.TextLastUpdate}>
              <FormattedMessage id="bplusClinicKey288" />
            </div>
            <div style={styles.TextDate}>{lastUpdate}</div>
          </div>
        </div>

        {status && (
          <ChildGrowthChart
            type={menuTab}
            selectedPatient={props.selectedPatient}
            growthHistoryList={props.growthHistoryList}
            gender={genderMale ? "male" : genderFemale ? "female" : ""}
            showBottomArrow={true}
          />
        )}
      </div>
    </div>
  );
};

WebChildGrowthChart.defaultProps = WebChildGrowthChartInitial;

export default React.memo(WebChildGrowthChart);
