import React from "react";
import { Icon } from "semantic-ui-react";

const TabBarStyle = {
  backgroundColor: "white",
  minHeight: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  width: "100%",
};

type TabBarProps = { history: string[] };

type TabItemProps = {
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  icon: any;
  title: any | undefined;
};

const TabItem = (props: TabItemProps) => (
  <div
    style={{
      flex: 1,
      textAlign: "center",
      fontSize: "0.7em",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
    onClick={props.onClick}
  >
    <Icon name={props.icon} style={{ marginBottom: "5px" }} />
    <div>{props.title}</div>
  </div>
);

export const TabBar = (props: TabBarProps) => (
  <div style={{ padding: "5px", borderTop: "solid #cccccc 1px" }}>
    <div style={TabBarStyle}>
      <TabItem
        title="Home"
        icon="home"
        onClick={(e) => {
          props.history.push("/?app=Mob");
        }}
      />
      <TabItem
        title="Visit / Plan"
        icon="calendar outline"
        onClick={(e) => {}}
      />
      {/* <TabItem title="Clinics" icon="medkit" 
          onClick={(e) => { props.history.push("/cliniclist/?app=Mob") }} 
        /> */}
      <TabItem
        title="More"
        icon="ellipsis horizontal"
        onClick={(e) => {
          props.history.push("/more/?app=Mob");
        }}
      />
    </div>
  </div>
);

export default TabBar;
