import React, { useEffect } from "react";
// CSS
import { Tab } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core";

import moment from "moment";

// UI
import WebDMOverall from "./detail/WebDMOverall";
import BloodPressure from "./detail/WebDMBloodPressure";
import WebDMWeight from "./detail/WebDMWeight";
import WebDMFood from "./detail/WebDMFood";
import WebDMExercise from "./detail/WebDMExercise";
import WebDMEmotionRest from "./detail/WebDMEmotionRest";
import WebDMMedication from "./detail/WebDMMedication";
import WebPatientPanel from "./WebPatientPanel";
import Glucose from "./detail/WebDMGlucose";
import WebDMVaccine from "./detail/WebDMVaccine";
import WebFootDetail from "./detail/WebDMFootDetail";

type PatientDetailProps = {
  // function
  onEvent: any;
  setProp: any;
  // common
  loadingStatus: boolean;
  loadingWebFoot: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  dmSelectedTab: number | null;
  // Options
  medDivisionTKOptions?: any[];
  medDivisionCROptions?: any[];
  medDoctorTKOptions?: any[];
  medDoctorCROptions?:any[];
  routeOptions: any;
  unitOptions: any;
  frequencyOptions: any;
  methodOptions: any;
  administrationTimeOptions: any;
  // WebPatientPanel
  scoreSummary?: any;
  labSummary?: any;
  profileHealth?: any[];
  // weight
  patientWeightList: any;
  hospitalWeightList: any;
  weightChartList: any;
  weightData: any;
  // emotion rest
  emotionRestHistoryList: any[];
  // exercise
  exerciseChartList: any[];
  exerciseSettingData?: any;
  // medicaion
  loadingMedication: boolean;
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medicationCurrentList: any;
  medicationCurrentListFilter: any[];
  medicationTrackcare: any;
  // blood
  patientData: any;
  BloodPresusreData: any;
  bpChartList: any;
  bpFilterDuration: string;
  // glucose
  glucoseData: any;
  bgChartList: any;
  bgBarfpg?: any;
  bgBarhba1c?: any;
  // foot
  footHistory?: any;
  footData?: any;
  footEclinicalData?: any;
  medicationTrackcareFilter: any[];
  drugDataTabHistory: any;
  medicationShowFilter: any;
  // food
  foodHistory: any[];
  loadingFood: boolean;
  //
  childOverallProgress?: any[];
  // vaccine
  vaccineDataTabHistory?: any[];
  vaccineDataTabDetail?: any;
  vaccineSeriesList?: any[];
};

const PatientDetailInitial: PatientDetailProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  // common
  loadingStatus: false,
  loadingWebFoot: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  dmSelectedTab: null,
  // Options
  medDivisionTKOptions: [],
  medDivisionCROptions: [],
  medDoctorTKOptions: [],
  medDoctorCROptions:[],
  routeOptions: {},
  unitOptions: {},
  frequencyOptions: {},
  methodOptions: {},
  administrationTimeOptions: {},
  // WebPatientPanel
  scoreSummary: {},
  labSummary: {},
  profileHealth: [],
  // weight
  patientWeightList: {},
  hospitalWeightList: {},
  weightData: {},
  weightChartList: {},
  // emotion
  emotionRestHistoryList: [],
  // exercise
  exerciseChartList: [],
  // medication
  loadingMedication: false,
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medicationCurrentList: {},
  medicationCurrentListFilter: [],
  medicationTrackcare: {},
  medicationShowFilter: {},
  // blood
  patientData: {},
  BloodPresusreData: {},
  bpFilterDuration: "",
  bpChartList: {},
  // glucose
  glucoseData: {},
  bgChartList: {},
  bgBarfpg: {},
  bgBarhba1c: {},
  // foot
  footHistory: {},
  footData: {},
  footEclinicalData: {},
  medicationTrackcareFilter: [],
  drugDataTabHistory: {},
  // Food
  foodHistory: [],
  loadingFood: false,
  //
  childOverallProgress: [],
  // vaccine
  vaccineDataTabHistory: [],
  vaccineDataTabDetail: {},
  vaccineSeriesList: [],
};

const useStyles = makeStyles((theme) => ({
  tab_bar: {
    "& .ui.bottom.attached.segment.active.tab": {
      padding: 0,
    },
    " & .ui.vertical.tabular.menu": {
      paddingRight: "0px",
    },
    "& .ui.vertical.tabular.menu": {
      width: "auto",
    },
    "& .ui.grid>.stretched.column:not(.row)": {
      paddingLeft: "0px",
    },
    "& .ui.grid>[class*='two wide'].column": {
      paddingRight: "0px",
    },
  },
}));

const PatientDetail: React.FC<PatientDetailProps> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.setProp("dmSelectedTab", 0);
  }, [props.selectedPatient]);

  const handleChangeTab = (event: any, data: any) => {
    props.setProp("dmSelectedTab", data.activeIndex);

    if (data.panes[data.activeIndex].menuItem.toLowerCase() === "weight") {
      props.onEvent({
        message: "handleGetDMTabWeight",
        params: {
          start: moment().add(-1, "w").format("DD/MM/YYYY"),
          end: moment().format("DD/MM/YYYY"),
        },
      });
    }
  };
  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div style={{ width: "300px" }}>
        <WebPatientPanel
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // common data
          // loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          dmSelectedTab={props.dmSelectedTab}
          // data
          scoreSummary={props.scoreSummary}
          labSummary={props.labSummary}
          profileHealth={props.profileHealth}
          childOverallProgress={props.childOverallProgress}
          hidePersonalScoreChart={false}
          hidePersonalLabChart={false}
          hideMomOverall={true}
          hideChildOverall={true}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Tab
          menu={{
            vertical: true,
            color: "blue",
            pointing: true,
            tabular: true,
          }}
          grid={{ paneWidth: 14, tabWidth: 2 }}
          defaultActiveIndex={props.dmSelectedTab || 0}
          onTabChange={handleChangeTab}
          className={classes.tab_bar}
          panes={[
            {
              menuItem: "Overall",
              render: () => (
                <Tab.Pane>
                  <WebDMOverall
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    scoreSummary={props.scoreSummary}
                    labSummary={props.labSummary}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Glucose",
              render: () => (
                <Tab.Pane>
                  <Glucose
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    glucoseData={props.glucoseData}
                    bgChartList={props.bgChartList}
                    bgBarfpg={props.bgBarfpg}
                    bgBarhba1c={props.bgBarhba1c}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Blood Pressure",
              render: () => (
                <Tab.Pane>
                  <BloodPressure
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    patientData={props.patientData}
                    BloodPresusreData={props.BloodPresusreData}
                    bpChartList={props.bpChartList}
                    bpFilterDuration={props.bpFilterDuration}
                    selectedPatient={props.selectedPatient}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Weight",
              render: () => (
                <Tab.Pane>
                  <WebDMWeight
                    // function
                    onEvent={props.onEvent}
                    // data
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    hospitalWeightList={props.hospitalWeightList}
                    weightChartList={props.weightChartList}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Nutrition",
              render: () => (
                <Tab.Pane>
                  <WebDMFood
                    onEvent={props.onEvent}
                    loadingFood={props.loadingFood}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    foodHistory={props.foodHistory}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Exercise",
              render: () => (
                <Tab.Pane>
                  <WebDMExercise
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    exerciseChartList={props.exerciseChartList}
                    exerciseSettingData={props.exerciseSettingData}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Emotion & Rest",
              render: () => (
                <Tab.Pane>
                  <WebDMEmotionRest
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    selectedPatient={props.selectedPatient}
                    emotionRestHistoryList={props.emotionRestHistoryList}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Foot",
              render: () => (
                <Tab.Pane>
                  <WebFootDetail
                    onEvent={props.onEvent}
                    loadingStatus={props.loadingStatus}
                    loadingWebFoot={props.loadingWebFoot}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    footHistory={props.footHistory}
                    footData={props.footData}
                    footEclinicalData={props.footEclinicalData}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Medication",
              render: () => (
                <Tab.Pane>
                  <WebDMMedication
                    // function
                    onEvent={props.onEvent}
                    // Options
                    medDivisionTKOptions={props.medDivisionTKOptions}
                    medDivisionCROptions={props.medDivisionCROptions}
                    medDoctorTKOptions={props.medDoctorTKOptions}
                    medDoctorCROptions={props.medDoctorCROptions}
                    routeOptions={props.routeOptions}
                    unitOptions={props.unitOptions}
                    frequencyOptions={props.frequencyOptions}
                    methodOptions={props.methodOptions}
                    administrationTimeOptions={props.administrationTimeOptions}
                    // data
                    selectedPatient={props.selectedPatient}
                    loadingMedication={props.loadingMedication}
                    loadingDivisionOptions={props.loadingDivisionOptions}
                    loadingDoctorOptions={props.loadingDoctorOptions}
                    medicationCurrentList={props.medicationCurrentList}
                    medicationCurrentListFilter={
                      props.medicationCurrentListFilter
                    }
                    medicationTrackcare={props.medicationTrackcare}
                    medicationTrackcareFilter={props.medicationTrackcareFilter}
                    drugDataTabHistory={props.drugDataTabHistory}
                    medicationShowFilter={props.medicationShowFilter}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Vaccine",
              render: () => (
                <Tab.Pane>
                  <WebDMVaccine
                    // function
                    onEvent={props.onEvent}
                    //  data
                    vaccineDataTabDetail={props.vaccineDataTabDetail}
                    vaccineSeriesList={props.vaccineSeriesList}
                    loadingStatus={props.loadingStatus}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                  />
                </Tab.Pane>
                // <Medication
                //   statDict={props.statDict}
                //   medicationList={props.medicationList}
                //   displayList={["HPC"]}
                //   vaccine={true}
                // />
              ),
            },
            // {
            //   menuItem: "Appointment",
            //   render: () => (
            //     <Tab.Pane>
            //       <Appointment />
            //     </Tab.Pane>
            //   ),
            // },
            // {
            //   menuItem: "Content",
            //   render: () => (
            //     <Tab.Pane>
            //       <Content />
            //     </Tab.Pane>
            //   ),
            // },
            // {
            //   menuItem: "Scoring",
            //   render: () => (
            //     <Tab.Pane>
            //       <Scoring />
            //     </Tab.Pane>
            //   ),
            // },
          ]}
        />
      </div>
    </div>
  );
};

PatientDetail.defaultProps = PatientDetailInitial;

export default React.memo(PatientDetail);
