import React, { CSSProperties, useEffect, useState, useMemo } from "react";
import moment from "moment";
// CSS
import { Button, Icon, Table, Dimmer, Loader } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// Interface
import { Event, State } from "../../ManageInterface";
import { BE_DATE_FORMAT, BACKEND_DATE_FORMAT } from "../../MobClinicInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import { ModAddVaccine } from "./WebDMVaccine";

const styles = {
  DivHeader: {
    backgroundColor: "var(--theme-bg-color-dm, DFF5FC)",
    padding: "12px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as CSSProperties,
  HeaderColor: {
    fontSize: "27px",
    color: "#4F4F4F",
    fontWeight: "bold",
    paddingLeft: "15px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  } as CSSProperties,
  TextLastUpdate: {
    color: "#656565",
    fontSize: "12px",
    paddingRight: "10px",
  } as CSSProperties,
  TextDate: {
    color: "#000000",
    fontSize: "12px",
  } as CSSProperties,
  TextEssentialVaccine: {
    textAlign: "center",
    background: "#2399E5",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "16px",
  } as CSSProperties,
  TextHeadCell: {
    textAlign: "center",
    background: "#2399E5",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: 0,
  } as CSSProperties,
  TextCellHeader: {
    borderWidth: "0px 1px 0px 1px",
    borderStyle: "none solid none solid",
    borderColor: "#FFFFFF #2399E5 #FFFFFF #2399E5",
    color: "#4F4F4F",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "5px 5px 5px 15px",
  } as CSSProperties,
  TextCell: {
    borderWidth: "0px 1px 0px 1px",
    borderStyle: "none solid none solid",
    borderColor: "#FFFFFF #C7F1FF #FFFFFF #C7F1FF",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "5px",
  } as CSSProperties,
  DivVaccineOn: {
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
  DivVaccineOff: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "22px",
  } as CSSProperties,
  TextVaccineOn: {
    background: "#2D9BE2",
    borderRadius: "5px",
    color: "#FFFFFF",
    width: "60px",
    height: "20px",
    textAlign: "center",
    alignItems: "center",
  } as CSSProperties,
  TextVaccineOff: {
    background: "#DFF5FC",
    borderRadius: "5px",
    color: "#FFFFFF",
    width: "60px",
    height: "20px",
    textAlign: "center",
    alignItems: "center",
  } as CSSProperties,
  TextVaccineDate: {
    textAlign: "center",
    paddingTop: "5px",
    color: "#2D9BE2",
  } as CSSProperties,
  DivDialogHeader: {
    justifyContent: "space-between",
    display: "flex",
    background: "#2399E5",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "20px",
  } as CSSProperties,
  DivDialogBG: {
    display: "flex",
    background: "#FFFFFF",
    padding: "20px",
    justifyContent: "center",
  } as CSSProperties,
  DivDialogInput: {
    display: "flex",
    marginLeft: "28%",
    paddingTop: "20px",
  } as CSSProperties,
  TextDialogInput: {
    color: "#0072BC",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "8px 10px 0px 0px",
  } as CSSProperties,
  TextButton: {
    background: "#2399E5",
    fontSize: "16px",
    color: "#FFFFFF",
    width: "100px",
  } as CSSProperties,
  TextMeasles: {
    color: "#0072BC",
    fontSize: "20px",
    fontWeight: "bold",
  } as CSSProperties,
  TextActive: {
    color: "#0072BC",
    fontSize: "16px",
    paddingLeft: "30px",
  } as CSSProperties,
  TextButtonPDF: {
    background: "#EF8318",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
  } as CSSProperties,
  TextButtonSave: {
    background: "#0072BB",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
  } as CSSProperties,
};

type WebChildVaccinationProps = {
  // function
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "loadingStatus"
  | "errorMessage"
  | "successMessage"
  | "ageRangeVaccineChild"
  | "essentialVaccineList"
  | "otherVaccineList"
  | "lastUpdateVaccineChild"
>;

type DisplayVaccineStatusProps = {
  parent: any;
  list: any[];
  onEdit: (item: any, detail: any, index: number) => any;
};

const LIMIT = 8;

const WebChildVaccinationInitial: WebChildVaccinationProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
};

const WebChildVaccination: React.FC<WebChildVaccinationProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<any>(null);

  useEffect(() => {
    props.onEvent({ message: "GetAgeRangeVaccineChild", params: {} });
  }, []);

  const tableHeader = useMemo(() => {
    const sliceArray = (props.ageRangeVaccineChild || []).slice(
      viewIndex * LIMIT,
      viewIndex * LIMIT + LIMIT
    );
    return ["Vaccine", ...sliceArray.map((item) => item.name)];
  }, [viewIndex, props.ageRangeVaccineChild]);

  const tableRow = useMemo(() => {
    const sliceArray = (list: any[]) =>
      (list || []).slice(viewIndex * LIMIT, viewIndex * LIMIT + LIMIT);
    return (props.essentialVaccineList || []).map((item) => ({
      ...item,
      items: sliceArray(item.items),
    }));
  }, [viewIndex, props.essentialVaccineList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEdit = (item: any, detail: any, index: number) => {
    const cloneDetail = { ...detail };
    if (!cloneDetail.date) {
      cloneDetail.active = true;
      cloneDetail.date = moment().format(BE_DATE_FORMAT);
    }

    setSelectedData({
      ...cloneDetail,
      name: item.name,
      id: detail.id,
      action: "edit",
      vaccine: item.id,
      index,
    });
  };

  const handleApprove = (detail: any) => {
    if (selectedData.action === "edit") {
      handleUpdateVaccine(detail);
    } else {
      handleAddVaccine(detail);
    }

    setSelectedData(null);
  };

  const handleAddVaccine = (detail: any) => {
    props.onEvent({
      message: "HandleSaveVaccineChildDetail",
      params: {
        id: null,
        proxy_patient: null,
        vaccine: selectedData.id,
        data: {
          ...selectedData.realData,
          OEORI_Date: detail.date
            ? moment(detail.data).format(BACKEND_DATE_FORMAT)
            : "",
          OEORI_PrescNo: detail.lot_no,
          Manual: true,
          By_Staff: true,
          DispenseTypeDesc: "Other hospital",
        },
      },
    });
  };

  const handleUpdateVaccine = (detail: any) => {
    props.onEvent({
      message: "HandleSaveVaccineChildDetail",
      params: {
        id: selectedData.id,
        proxy_patient: selectedData.proxy_patient,
        vaccine: selectedData.vaccine,
        data: {
          ...selectedData.realData,
          OEORI_Date: detail.date
            ? moment(detail.date, BE_DATE_FORMAT).format(BACKEND_DATE_FORMAT)
            : "",
          OEORI_PrescNo: detail.lot_no,
          By_Staff: true,
          DispenseTypeDesc:
            selectedData.data?.DispenseTypeDesc || "Other hospital",
        },
      },
    });
  };

  const handleAddExtraVaccine = (data: any) => {
    setSelectedData({ ...data, active: true, action: "add", date: "" });
  };

  console.log(props.otherVaccineList);

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div className="box-header">
        <div className="div-header" style={{ marginTop: 0 }}>
          <img
            alt="vaccine.icon"
            height="36px"
            style={{ color: "var(--theme-icon-color-dm)" }}
            src="/images/child/vaccine.png"
          />
          <span>
            <FormattedMessage id="bplusClinicKey513" />
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            right: "30px",
            top: "30%",
          }}
        >
          <Button 
            style={styles.TextButtonPDF}
            onClick={() => {
              props.onEvent({
                message: "HandleCreateVaccinePDF",
                params: {},
              });
            }}
          >
            <FormattedMessage id="bplusClinicKey135" />
          </Button>
          {/* <Button style={styles.TextButtonSave}>
            <FormattedMessage id="bplusClinicKey431" />
          </Button> */}
        </div>
      </div>

      <div className="sub-layout" style={{ paddingLeft: "20px" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ paddingTop: "10px", display: "flex" }}>
            <div style={styles.TextLastUpdate}>
              <FormattedMessage id="bplusClinicKey288" />
            </div>
            <div style={styles.TextDate}>
              {props.lastUpdateVaccineChild
                ? moment(props.lastUpdateVaccineChild).format(
                    "DD MMM YYYY, HH:mm"
                  )
                : "-"}
            </div>
          </div>
        </div>

        <div>
          <div
            style={{ display: "grid", gridTemplateColumns: "30px auto 15px" }}
          >
            <div
              onClick={() => setViewIndex(viewIndex - 1)}
              style={{
                alignItems: "center",
                width: "15px",
                display: "flex",
                opacity: viewIndex === 0 ? 0 : 1,
              }}
            >
              <Icon name="arrow circle left" color="blue" size="big" />
            </div>
            <Table celled singleLine style={{ border: "1px solid #2399E5" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan={9}
                    style={styles.TextEssentialVaccine}
                  >
                    <FormattedMessage id="bplusClinicKey185" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Header>
                <Table.Row>
                  {tableHeader.map((value, index) => (
                    <Table.HeaderCell
                      key={"header-" + index}
                      style={styles.TextHeadCell}
                    >
                      {value}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {tableRow.map((item, index) => (
                  <Table.Row key={"row-" + index}>
                    <Table.Cell
                      style={{
                        ...styles.TextCellHeader,
                        verticalAlign: "baseline",
                      }}
                    >
                      {item.abbreviation}
                    </Table.Cell>
                    <DisplayVaccineStatus
                      parent={item}
                      list={item.items}
                      onEdit={handleEdit}
                    />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <div
              onClick={() => setViewIndex(viewIndex + 1)}
              style={{
                alignItems: "center",
                width: "15px",
                display: "flex",
                opacity:
                  (props.ageRangeVaccineChild?.length || 0) >
                  viewIndex * LIMIT + LIMIT
                    ? 1
                    : 0,
              }}
            >
              <Icon name="arrow circle right" color="blue" size="big" />
            </div>
          </div>

          <div style={{ padding: "30px 35px 20px 20px" }}>
            <Table
              celled
              fixed
              singleLine
              style={{ border: "1px solid #2399E5" }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan={9}
                    style={styles.TextEssentialVaccine}
                  >
                    <FormattedMessage id="bplusClinicKey359" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(props.otherVaccineList || []).map((item, index) => (
                  <Table.Row key={"vaccine-other-" + index}>
                    <Table.Cell
                      style={{
                        ...styles.TextCellHeader,
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                        onClick={() => {
                          handleAddExtraVaccine(item);
                        }}
                      >
                        {item.abbreviation}
                        <Icon
                          name="plus circle"
                          color="blue"
                          onClick={handleClickOpen}
                        />
                      </div>
                    </Table.Cell>
                    <DisplayVaccineStatus
                      parent={item}
                      list={item.items}
                      onEdit={handleEdit}
                    />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>

      <ModAddVaccine
        open={!!selectedData}
        detail={selectedData}
        onClose={() => {
          setSelectedData(null);
        }}
        onApprove={handleApprove}
      />
    </div>
  );
};

/* TODO---------------- DisplayVaccineStatus; --------------- */
const DisplayVaccineStatus: React.FunctionComponent<
  DisplayVaccineStatusProps
> = (props) => {
  return (
    <>
      {props.list.map((acc: any, idx: number) =>
        acc?.date ? (
          <Table.Cell key={"cell" + idx} style={styles.TextCell}>
            <div style={styles.DivVaccineOn}>
              <Icon
                name="check circle"
                color="green"
                style={{ paddingTop: "1px" }}
              />
              <div
                style={{
                  ...styles.TextVaccineOn,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onEdit(props.parent, acc, idx);
                }}
              >
                {acc.abbreviation ?? acc.code}
              </div>
              {acc.data?.By_Patient ? (
                <Icon
                  name="user outline"
                  color="blue"
                  style={{
                    paddingTop: "1px",
                    paddingLeft: "5px",
                  }}
                />
              ) : (
                <div style={{ width: "14px" }}></div>
              )}
            </div>
            <div style={styles.TextVaccineDate}>{acc.date}</div>
          </Table.Cell>
        ) : acc?.abbreviation ?? acc?.code ? (
          <Table.Cell style={styles.TextCell}>
            <div style={{ ...styles.DivVaccineOff, paddingTop: "5px" }}>
              <div
                style={{
                  ...styles.TextVaccineOff,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.onEdit(props.parent, acc, idx);
                }}
              >
                {acc.abbreviation ?? acc.code}
              </div>
            </div>
          </Table.Cell>
        ) : (
          <Table.Cell style={styles.TextCell} />
        )
      )}
    </>
  );
};

WebChildVaccination.defaultProps = WebChildVaccinationInitial;

export default React.memo(WebChildVaccination);
