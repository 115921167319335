import WasmController from "../../../react-lib/frameworks/WasmController";
import moment from "moment";

// Constant
import {
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  commonCreatePatientData,
  commonDeletePatientData,
  calculateDelivery,
  BACKEND_DATE_FORMAT,
  commonCreatePatientDocumentFile,
  commonUpdatePatientData,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  ultrasoundList?: Record<string, any>[];
  ultrasoundUploadDetail?: {
    file?: File;
    image?: string | File;
    date?: string;
    ga_weeks?: number;
    ga_days?: number;
  };
  selectedUltrasoundImage?: any;
  profileObstretic?: any;
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus"
>;

export const StateInitial: State = {
  ultrasoundList: [],
  ultrasoundUploadDetail: {},
  selectedUltrasoundImage: null,
  profileObstretic: {},
};

export type Event =
  // Setter
  | {
      message: "HandleSetUltrasoundUploadDetail";
      params: {
        key: keyof Required<State>["ultrasoundUploadDetail"];
        value: any;
      };
    }
  | { message: "HandleSelectedUltrasoundImage"; params: { data: any } }
  // PUSH
  | {
      message: "HandleConfirmUploadUltrasound";
      params: { data: State["ultrasoundUploadDetail"]; measure_date: string };
    }
  // Clear
  | { message: "HandleClearUltrasoundUploadDetail"; params: {} }
  // Delete
  | { message: "HandleDeleteUltrasoundList"; params: { id: number } }
  // GET
  | { message: "GetUltrasoundList"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const HandleSetUltrasoundUploadDetail: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { key, value } = params;

  let cloneData: any = { ...state.ultrasoundUploadDetail };

  if (cloneData) {
    cloneData[key] = value;
    if (key === "file") {
      cloneData.image = value ? URL.createObjectURL(value) : value;
    } else if (key === "date") {
      const deliveryResult = calculateDelivery(
        state.profileObstretic?.data?.by_ultrasound,
        moment(value, BACKEND_DATE_FORMAT),
        moment(state.profileObstretic?.data?.lmp_date, BACKEND_DATE_FORMAT),
        moment(state.profileObstretic?.data?.edc_date, BACKEND_DATE_FORMAT)
      );

      cloneData.ga_weeks = deliveryResult?.ga_weeks;
      cloneData.ga_days = deliveryResult?.ga_days;
    }
  }

  controller.setState({ ultrasoundUploadDetail: cloneData });
};

export const GetUltrasoundList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [errorLatest, respLatest] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.OBSTRETIC_RECORD,
      data_type: DATA_TYPE.OBSTRETIC_RECORD,
    }
  );
  if (errorLatest) {
    controller.setState({ profileObstretic: {} });
  } else {
    controller.setState({ profileObstretic: respLatest });
  }

  const tmpParams: any = {
    group: GROUP.ULTRASOUND,
    data_type: DATA_TYPE.ULTRASOUND,
  };
  const [error, response] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  if (error) {
    controller.setState({
      ultrasoundList: [],
      loadingStatus: false,
    });
  } else {
    controller.setState({
      ultrasoundList: response?.items,
      loadingStatus: false,
    });
  }
};

export const HandleConfirmUploadUltrasound: Handler = async (
  controller,
  params
) => {
  // const { ultrasoundList } = controller.getState();

  // const list = [...(ultrasoundList || []), { ...params.data, id: Date.now() }];

  // controller.setState({ ultrasoundList: list });
  // HandleClearUltrasoundUploadDetail(controller, {});
  controller.setState({ loadingStatus: true });

  let ultrasoundImage = params.data.image;
  params.data.image = null;

  let tmpParams: any = {
    group: GROUP.ULTRASOUND,
    data_type: DATA_TYPE.ULTRASOUND,
    measure_date: params.measure_date,
    data: { ...params.data },
  };
  const [error, response] = await commonCreatePatientData(
    controller as any,
    tmpParams
  );

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    const [errorImage, responseImage] = await commonCreatePatientDocumentFile(
      controller as any,
      {
        phr: response.id,
        image: ultrasoundImage,
      }
    );
    if (errorImage) {
      errorMessage = errorImage;
    } else {
      tmpParams = {
        id: response.id,
        proxy_patient: response.proxy_patient,
        ...tmpParams,
        data: {
          ...tmpParams.data,
          image: responseImage.image,
          image_id: responseImage.id,
        },
      };
      const [errorUpdate, responseUpdate] = await commonUpdatePatientData(
        controller as any,
        tmpParams
      );
      if (errorUpdate) {
        errorMessage = errorUpdate;
      } else {
        successMessage = "Add Ultrasound success.";
        controller.setState({ ultrasoundUploadDetail: {} });
        GetUltrasoundList(controller);
      }
    }
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const HandleClearUltrasoundUploadDetail: Handler = (
  controller,
  params
) => {
  controller.setState({ ultrasoundUploadDetail: {} });
};

export const HandleSelectedUltrasoundImage: Handler = (controller, params) => {
  controller.setState({ selectedUltrasoundImage: params.data });
};

export const HandleDeleteUltrasoundList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  if (params?.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });
    if (error) {
      errorMessage = error;
    } else {
      successMessage = "Delete Ultrasound success.";
      GetUltrasoundList(controller);
      controller.setState({ selectedUltrasoundImage: null });
    }
  } else {
    errorMessage = "ID not found.";
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};
