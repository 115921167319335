
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data:  */
const V3PatientCouponCheckM:
  {
    get: ({ params, apiToken, extra }:
      {
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  } =
{
  get: async ({ params, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/PRX/v3/check_coupon_booking/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default V3PatientCouponCheckM

