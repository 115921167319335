
import moment from "moment";

import {
  questionPregnantWeekText,
  weekText,
  yesText,
  questionCovidText,
  questionVaccineCountText,
  isTextInside,
} from "./MobCouponEvaluate";

export const vaccineTable = [
  { idx: 0, name: "Sinovac" },
  { idx: 1, name: "Sinopharm" },
  { idx: 2, name: "AstraZeneca" },
  { idx: 3, name: "Pfizer" },
  { idx: 4, name: "Johnson & Johnson" },
  { idx: 5, name: "Moderna" },
];

/********************************************************************************************
 *
 *
 * {dose1: {date: moment(), idx: 0}, dose2: null, dose3:null, pregnentWeek: 0}
 * {dose1: {date: moment(), idx: 0}, dose2: null, dose3:null, pregnentWeek: 11}
 * {dose1: {date: moment(), idx: 0}, dose2: null, dose3:null, pregnentWeek: 12, covidDate: moment()}
 *
 *
 ********************************************************************************************/

export type InputEvaluate = {
  dose1?: { date: moment.Moment; idx: number };
  dose2?: { date: moment.Moment; idx: number };
  dose3?: { date: moment.Moment; idx: number };
  dose4?: { date: moment.Moment; idx: number };
  covidDetectDate?: { date: moment.Moment };
  pregnentWeek?: number;
};

export type SuggestInject = {
  doseCount: number;
  suggestDate?: moment.Moment | null;
  canInjectDate?: moment.Moment | null;
  infection_date?: string | null;
  suggestionInfo: string; 
};

const findIdxTable = (name: string) => {
  let idx = vaccineTable.find((item) => item.name === name?.trim())?.idx
  if (idx === undefined) {
    console.error("Unknown Vaccine Name", name)
  }
  return idx
};

export const readForm = (form: any): InputEvaluate => {
  let prepare: InputEvaluate = {};

  let pregnentWeek = null;
  let covidDetectDate = null;
  let vaccineCount = 0;
  let vaccineDetail: any = {};

  let lastQuestion: any;
  for (const [key, value] of Object.entries(form)) {
    if (
      value.extra_finished &&
      value.extra_intro &&
      value.extra_items?.length > 0
    ) {
      lastQuestion = value;
    }
  }


  console.log(" readform lastQuestion: ", lastQuestion);

  let covidIdx = 0;
  let injectCountIdx = 0;
  let vacineDetailStartIdx = 0;
  // Pregenent
  if (isTextInside(lastQuestion.condition, yesText)) {
    // ตอบ ใช่
    if (
      isTextInside(lastQuestion.extra_items[0].label, questionPregnantWeekText)
    ) {
      let [first, ...rest] = lastQuestion.extra_items[0].value
        ?.split(" ")
        .filter((item: any) => item);

      pregnentWeek = parseInt(first);
      if (!isNaN(pregnentWeek)) {
        // น้อยกว่า 12 สัปดาห์
        pregnentWeek = pregnentWeek;
      } else {
        // มากกว่า 12 สัปดาห์ จะ ทำเป็น number ไม่ได้
        pregnentWeek = null;
      }
      //console.log(" ตั้งครรค์ อายุ", pregnentWeek);
    }
    covidIdx = 1;
  }

  console.log("covidIdx", covidIdx);
  // Covid (idx 0 or 1 if pregnent)
  if (
    isTextInside(lastQuestion.extra_items[covidIdx].label, questionCovidText)
  ) {
    // ถามเรื่อง covide
    //console.log(" Ask covid");
    if (isTextInside(lastQuestion.extra_items[covidIdx].value, yesText)) {
      // ตอบ ใช่
      covidDetectDate = moment(lastQuestion.extra_items[covidIdx + 1].value);
    }

    injectCountIdx = covidIdx + 2;
  }

  console.log("injectCountIdx: ", injectCountIdx);

  // Vaccine Count
  if (
    isTextInside(
      lastQuestion.extra_items[injectCountIdx].label,
      questionVaccineCountText
    )
  ) {
    // ถาม จำนวนเข็ม
    let count = parseInt(
      lastQuestion.extra_items[injectCountIdx].value?.trim()
    );
    if (!isNaN(count)) {
      // มีจำนวนฉีด
      vaccineCount = count;
    }
    vacineDetailStartIdx = injectCountIdx + 1;
  }

  console.log("vacineDetailStartIdx", vacineDetailStartIdx);
  for (let index = 0; index < vaccineCount; index++) {
    // name, date
    let name = lastQuestion.extra_items[vacineDetailStartIdx].value;
    console.log("name: ", name);
    let date = moment(lastQuestion.extra_items[vacineDetailStartIdx + 1].value);
    //console.log("date: ", date);
    let key = "dose" + (index + 1);
    //console.log("key: ", key);

    vaccineDetail[key] = { date, idx: findIdxTable(name) };
    //console.log("vaccineDetail: ", vaccineDetail);
    vacineDetailStartIdx = vacineDetailStartIdx + 2;
  }
  //console.log("vaccineDetail: ", vaccineDetail);

  prepare = {
    ...vaccineDetail,
    ...(covidDetectDate && { covidDetectDate }),
    ...(pregnentWeek && { pregnentWeek }),
  };

  //console.log("preapre", prepare);
  return prepare;
};

/********************************************************************************************
 *
 *
 * evaluateNextAppointment
 * covid 30 วัน
 * อายุครร ต้องไปให้ถึง 12 สัปดาห์
 *
 * return moment เป็นวันที่ แนะนำในการเริ่มฉีด วัคซีน Moderna
 * หรือ
 * return null ต้องรอ Gen 2
 *
 * return { doseCount: 0,1,2,3, suggestDate: moment | null, }
 * {doseCount: 0, suggestDate: null} // รอ Gen 2
 * {doseCount: 1, suggestDate: moment.today} // นัดหมาย ได้เลย
 * {doseCount: 2, suggestDate: moment.dayใดๆ} // นัดหมาย ตามวันที่ แนะนำ
 * {doseCount: 3, suggestDate: moment.dayใดๆ} // นัดหมาย ตามวันที่ แนะนำ
 *
 ********************************************************************************************/

export const evaluateNextAppointment = (form: any, lang: string): SuggestInject => {
  let data: InputEvaluate = readForm(form);
  let suggest = getSuggestion(data, lang)
  return suggest 
}

export const getSuggestion = (data: InputEvaluate, lang: string) : SuggestInject => {

  let today = moment();
  console.log("Answer data: ", data);

  let allowNoGuideLine = false
  let delayPregnentDay = 0;
  let delayCovidDetectDay = 0;
  let delayTableMapDay = 0;

  let suggest: SuggestInject = { doseCount: 0, suggestDate: null, infection_date: null, suggestionInfo: "", canInjectDate: null };
  // Evaluate Table

  // No dose
  if (!data.dose3 && !data.dose2 && !data.dose1) {
    console.log(" ยังไม่ได้ฉีด ซักเข็ม");
    suggest.doseCount = 1;
    delayTableMapDay = 3; // ช่วย Appointment
    // return suggest
  }

  // One Dose
  if (!data.dose3 && !data.dose2 && data.dose1) {
    suggest.doseCount = 2;
    switch (data.dose1.idx) {
      case 0:
        delayTableMapDay = 28;
        break;
      case 1:
        delayTableMapDay = 28;
        break;
      case 2:
        delayTableMapDay = 28;
        break;
      case 3:
        // Pfizer
        delayTableMapDay = 0;
        break;
      case 4:
        delayTableMapDay = 28;
        break;
      case 5:
        delayTableMapDay = 28;
        break;
      default:
        break;
    }

    if (delayTableMapDay === 0) {
      // Wait Gen 2
      console.log(
        "รอ Gen 2 จาก ตาราง delayCovidDetectDay: ",
        delayCovidDetectDay,
        "delayPregnentDay: ",
        delayPregnentDay,
        "delayTableMapDay: ",
        delayTableMapDay
      );
      suggest.suggestDate = null;
      return suggest;
    }

    let momentAllow = moment(data.dose1.date);
    momentAllow.add(delayTableMapDay, "days");

    console.log("momentAllow: ", momentAllow.toString());
    delayTableMapDay = momentAllow
      .startOf("day")
      .diff(today.startOf("day"), "days");
  }

  // two Dose
  if (!data.dose3 && data.dose2 && data.dose1) {
    suggest.doseCount = 3;
    if (data?.dose1?.idx === 0 && data?.dose2?.idx === 0) {
      // 2 Sinovac
      delayTableMapDay = 28;
    } else if (data?.dose1?.idx === 1 && data.dose2?.idx === 1) {
      // 2 Sinopharm
      delayTableMapDay = 28;
    } else if (data.dose1?.idx === 2 && data.dose2?.idx === 2) {
      // 2 AstraZeneca
      delayTableMapDay = 84;
    } else if (data.dose1?.idx === 0 && data.dose2?.idx === 2) {
      // 1 Sinovac 2 AstraZeneca
      delayTableMapDay = 84;
    } else if (data?.dose1?.idx === 0 && data?.dose2?.idx === 5) {
      // 1 Sinovac 2 Moderna
      delayTableMapDay = 28;
    } else if (data?.dose1?.idx === 1 && data.dose2?.idx === 5) {
      // 1 Sinopharm 2 Moderna
      delayTableMapDay = 28;
    } else if (data?.dose1?.idx === 3 && data.dose2?.idx === 3) {
      // 1 Pfizer 2 Pfizer
      delayTableMapDay = 168;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 2 && data.dose2?.idx === 3) {
      // 1 AstraZeneca 2 Pfizer
      delayTableMapDay = 168;
      allowNoGuideLine = true 
    } else if (data?.dose1?.idx === 2 && data.dose2?.idx === 5){
      // 1 AstraZeneca 2 Moderna
      delayTableMapDay = 84;
    } else if (data?.dose1?.idx === 4 && data.dose2?.idx === 5){
      // 1 Johnson & Johnson 2 Moderna
      delayTableMapDay = 28;
    } else if (data?.dose1?.idx === 5 && data.dose2?.idx === 5){ 
      // 1 Moderna 2 Moderna
      delayTableMapDay = 28;
    }



    // else if (data?.dose1?.idx === 3 && data.dose2?.idx === 3) {
    //   // 1 Sinopharm 2 Moderna
    //   waitGen2 = true
    // }

    if (delayTableMapDay === 0) {
      // Wait Gen 2
      console.log(
        "รอ Gen 2 จาก ตาราง delayCovidDetectDay: ",
        delayCovidDetectDay,
        "delayPregnentDay: ",
        delayPregnentDay,
        "delayTableMapDay: ",
        delayTableMapDay
      );
      suggest.suggestDate = null;
      return suggest;
    }


    let momentAllow = moment(data.dose2.date);
    momentAllow.add(delayTableMapDay, "days");

    console.log("momentAllow: ", momentAllow.toString());
    delayTableMapDay = momentAllow
      .startOf("day")
      .diff(today.startOf("day"), "days");
  }

  // three Dose
  if (data.dose3 && data.dose2 && data.dose1 && !data.dose4) {
    suggest.doseCount = 4;
    if (data?.dose1?.idx === 0 && data.dose2?.idx === 0 && data.dose3?.idx === 1) {
      // 1 Sinovac 2 Sinovac 3 Sinopharm
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 0 && data.dose2?.idx === 0 && data.dose3?.idx === 2) {
      // 1 Sinovac 2 Sinovac 3 AstraZeneca
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 0 && data.dose2?.idx === 0 && data.dose3?.idx === 3) {
      // 1 Sinovac 2 Sinovac 3 Pfizer
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 0 && data.dose2?.idx === 0 && data.dose3?.idx === 5) {
      // 1 Sinovac 2 Sinovac 3 Moderna
      delayTableMapDay = 84;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 0 && data.dose2?.idx === 2 && data.dose3?.idx === 5) {
      // 1 Sinovac 2 AstraZeneca 3 Moderna
      delayTableMapDay = 84;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 0 && data.dose2?.idx === 5 && data.dose3?.idx === 5) {
      // 1 Sinovac 2 Moderna 3 Moderna
      delayTableMapDay = 28;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 1 && data.dose2?.idx === 1 && data.dose3?.idx === 5) {
      // 1 Sinopharm 2 Sinopharm 3 Moderna
      delayTableMapDay = 84;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 1 && data.dose2?.idx === 5 && data.dose3?.idx === 5) {
      // 1 Sinopharm 2 Moderna 3 Moderna
      delayTableMapDay = 28;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 2 && data.dose2?.idx === 2 && data.dose3?.idx === 3) {
      // 1 AstraZeneca 2 AstraZeneca 3 Pfizer
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 2 && data.dose2?.idx === 2 && data.dose3?.idx === 5) {
      // 1 AstraZeneca 2 AstraZeneca 3 Moderna
      delayTableMapDay = 84;
      // allowNoGuideLine = true
    } else if (data?.dose1?.idx === 2 && data.dose2?.idx === 3 && data.dose3?.idx === 5) {
      // 1 AstraZeneca 2 Pfizer 3 Moderna
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else if (data?.dose1?.idx === 3 && data.dose2?.idx === 3 && data.dose3?.idx === 5) {
      // 1 Pfizer 2 Pfizer 3 Moderna
      delayTableMapDay = 84;
      allowNoGuideLine = true
    } else {
      // ยี่ห้อ ใดๆ 3 เข็ม 
      delayTableMapDay = 84;
      allowNoGuideLine = true
    }


    if (delayTableMapDay === 0) {
      // Wait Gen 2
      console.log(
        "รอ Gen 2 จาก ตาราง delayCovidDetectDay: ",
        delayCovidDetectDay,
        "delayPregnentDay: ",
        delayPregnentDay,
        "delayTableMapDay: ",
        delayTableMapDay
      );
      suggest.suggestDate = null;
      return suggest;
    }

    let momentAllow = moment(data.dose3.date);
    momentAllow.add(delayTableMapDay, "days");

    console.log("momentAllow: ", momentAllow.toString());
    delayTableMapDay = momentAllow
      .startOf("day")
      .diff(today.startOf("day"), "days");
  }

  if (data.dose3 && data.dose2 && data.dose1 && data.dose4) { 
      // ยี่ห้อ ใดๆ 4 เข็ม 
      suggest.doseCount = 5;
      delayTableMapDay = 84;
      allowNoGuideLine = true

      let momentAllow = moment(data.dose4.date);
      momentAllow.add(delayTableMapDay, "days");

      console.log("momentAllow: ", momentAllow.toString());
      delayTableMapDay = momentAllow
        .startOf("day")
        .diff(today.startOf("day"), "days");
  }
  
  if (allowNoGuideLine) {
    if (lang === "EN") {
        suggest.suggestionInfo = "\nBased on your history, it is recommended to wait for the second generation vaccine.\n\nIn case that you require other vaccination schemes which differ from our guideline,\nplease click “Make Appointment” to confirm and consent form needs to be signed at the service point."
      } else {
        suggest.suggestionInfo = "\nแนะนำให้ท่านรอวัคซีน Generation ที่ 2\n\nกรณีผู้ที่มีความต้องการฉีดวัคซีนนอกเหนือจาก Guideline ที่ รพ. กำหนด กรุณา กด \"ทำนัดหมาย\" เพื่อยืนยันและเซ็นเอกสารยินยอมทางการแพทย์ ณ จุดรับบริการ"
      }
  }


  // Evaluate Pregment
  if (data.pregnentWeek && data.pregnentWeek < 12) {
    delayPregnentDay = (12 - data.pregnentWeek) * 7;
  }

  // Evaluate Covid Detect
  if (data.covidDetectDate && moment.isMoment(data.covidDetectDate)) {
    console.log(" Covide day: ", data.covidDetectDate.toString());
    let momentAllow = moment(data.covidDetectDate);
    momentAllow.add(30, "days");
    console.log("momentAllow: ", momentAllow.toString());
    delayCovidDetectDay = momentAllow
      .startOf("day")
      .diff(today.startOf("day"), "days");
    suggest.infection_date = moment(data.covidDetectDate).locale("en").format("DD/MM/YYYY")
  }

  
  // mock delay date will be zero after check and doesn't exist in any case
  let maxDelayPast = -99999
  // );
  if(data.covidDetectDate && delayCovidDetectDay > maxDelayPast){
    maxDelayPast = delayCovidDetectDay
  }

  if(delayTableMapDay && delayTableMapDay > maxDelayPast){
    maxDelayPast = delayTableMapDay
  }

  if(delayPregnentDay > 0 && delayPregnentDay > maxDelayPast){
    maxDelayPast = delayPregnentDay
  }

  if(maxDelayPast === -99999){
    maxDelayPast = 0
  }
  
  suggest.canInjectDate = moment().add(maxDelayPast, "days")
  console.log("suggest date: ", suggest, maxDelayPast)

  let maxDelay = Math.max(
    delayTableMapDay,
    delayPregnentDay,
    delayCovidDetectDay
  );

  if (maxDelay <= 3) {
    maxDelay = 3
  }
  // console.log("Date length:", maxDelay, delayCovidDetectDay, delayPregnentDay, delayTableMapDay)
  suggest.suggestDate = moment().add(maxDelay, "days");
  console.log(
    "predict: แนะนำวัน ",
    suggest,
    "delayCovidDetectDay: ",
    delayCovidDetectDay,
    "delayPregnentDay: ",
    delayPregnentDay,
    "delayTableMapDay: ",
    delayTableMapDay
  );
  return suggest;
};
