export default class ProfileController {
  constructor(props) {
    this.userManager = props.userManager;
    this.prxManager = props.prxManager;
  }

  loadProfile = async (apiToken) => {
    var params = { apiToken: apiToken };
    const [data, error] = await this.userManager.getUserProfile(params);
    return [data, error];
  };

  getMyProfile = async (apiToken) => {
    const [data, error] = await this.userManager.getMyProfile({ apiToken });
    return [data, error];
  };

  getPatient = async ({apiToken}) => {
    const [data, error] = await this.prxManager.getPatient({ apiToken });
    return [data, error]
  }
  /**
   * params = {apiToken, firstName, lastName, dob, phone}
   */
  updateProfile = async (params) => {
    const [data, error] = await this.userManager.updateUserProfile(params);
    return [data, error];
  };

  patchMyProfile = async (params) => { 
    const [data, error, network] = await this.userManager.patchMyProfile(params);
    return [data, error];
  }

  getChatChannel = async ({ apiToken, userId, isPatient } = {}) => {
    const [response, error, network] = await this.prxManager.getChatChannel({
      apiToken,
      userId,
      isPatient,
    });
    return [response, error, network];
  };

  getProxyPatientHasDivision = async ({ apiToken, params}) => {
    const [response, error, network] = await this.prxManager.getProxyPatientHasDivision({
      apiToken,
      params
    });
    return [response, error, network];
  }


  patchProxyPatientHasDivision = async ({ apiToken, params, hn, id}) => {
    const [response, error, network] = await this.prxManager.patchProxyPatientHasDivision({
      apiToken,
      params,
      hn,
      id,
    });
    return [response, error, network];
  }
}
