import { WasmHandler } from "react-lib/frameworks/WasmController";import { collection, getDocs, where } from "firebase/firestore";


export type State = {
  locationList?: any[];
};

export const StateInitial = {
  locationList: [],
};

export type Event = { message: "GetLocation"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State, Event>;

export const GetLocation: Handler = async (controller, params) => {

	const querySnapshot = await getDocs(collection(controller.db, "Location"));
	console.log("GetLocation " , querySnapshot?.docs.map((doc) => doc.data()));
	controller.setState({
		locationList: (querySnapshot?.docs || []).map((doc) => doc.data()),
	});

  // controller.db
  //   .collection("Location")
  //   .get()
  //   .then((res) => {
  //     console.log(res.docs.map((doc) => doc.data()));
  //     controller.setState({
  //       locationList: res.docs.map((doc) => doc.data()),
  //     });
  //   });
};
