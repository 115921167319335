import WasmController from "react-lib/frameworks/WasmController";
import axios from "axios";
import moment from "moment";

export type State = {
  selectedLocation?: selectedLocationType;
  loadingSkeletonLocation?: boolean;
};

export type selectedLocationType = {
  id?: number | null;
  img?: string;
  sub_title?: string;
  title?: string;
  image_url?: string;
  name?: string;
  place?: string;
}

export const StateInitial: State = {
  selectedLocation: { id: null, image_url: "", name: "", place: "" },
  loadingSkeletonLocation: false
};

export type Event =
  | {
    message: "handleSelectLocation";
    params: { id: Number; img: string; sub_title: string; title: string };
  }

export type Data =
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const handleSelectLocation: Handler = async (controller, params) => {
  controller.setState({
    selectedLocation: {
      id: params.id,
      image_url: params.image_url,
      name: params.name,
      place: params.place,
    },
  });
};

