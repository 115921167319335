import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Header,
  Input,
} from "semantic-ui-react";
import { adToBe, beToAd } from "../../../react-lib/utils";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
// UI
import { DateTextBox } from "../../../react-lib/apps/common";
import PureReactTable from "../../../react-lib/component-js/common/PureReactTable";
import SearchBox from "../../appointment/SearchBox";
import { styles as MedStyles } from "./WebDMMedication";
import { Dimmer, Loader } from "semantic-ui-react";

type MedicationCurrentProps = {
  // function
  onEvent: any;
  onSearchDivision: any;
  onSearchDoctor: any;
  // Options
  medDivisionCROptions?: any[];
  medDoctorCROptions?: any[];
  routeOptions: any[];
  unitOptions: any[];
  frequencyOptions: any[];
  methodOptions: any[];
  administrationTimeOptions: any[];
  // data
  loadingDivisionOptions?: boolean;
  loadingDoctorOptions?: boolean;
  medicationCurrentList: any;
  medicationCurrentListFilter: any[];
  loadingMedication: boolean;
  selectedPatient: any;
};

const MedicationCurrentInitial: MedicationCurrentProps = {
  // function
  onEvent: () => null,
  onSearchDivision: () => null,
  onSearchDoctor: () => null,
  // Options
  medDivisionCROptions: [],
  medDoctorCROptions: [],
  routeOptions: [],
  unitOptions: [],
  frequencyOptions: [],
  methodOptions: [],
  administrationTimeOptions: [],
  // data
  loadingDivisionOptions: false,
  loadingDoctorOptions: false,
  medicationCurrentList: {},
  medicationCurrentListFilter: [],
  loadingMedication: false,
  selectedPatient: {},
};

const MedicationCurrent: React.FC<MedicationCurrentProps> = (props) => {
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [filterBy, setFilterBy] = useState<any>({
    start: "",
    end: "",
    drugName: "",
    clinic: "",
    doctor: "",
  });
  const [drugManually, setDrugManually] = useState<any>({
    drug_name: "",
    route: "",
    dosage: "",
    unit: "",
    frequency: "",
    method: "",
    administrationTime: [],
  });
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  const patientColumn = [
    {
      Header: "",
      width: 50,
      Cell: (row: any) => {
        return (
          <Checkbox
            checked={!row.original.active}
            onChange={(event: any, { checked }) => {
              handleCheckBoxChange(row, "active", !checked);
            }}
          />
        );
      },
    },
    { Header: "Order Date", accessor: "order_date", width: 100 },
    {
      Header: "Drug name",
      width: 400,
      Cell: (row: any) => (
        <div>
          {row.original.name}
          {row.original?.note?.medication}
        </div>
      ),
    },
    { Header: "Clinic", accessor: "clinic", width: 250 },
    { Header: "Doctor", accessor: "doctor", width: 250 },
    {
      Header: "Is cal.",
      width: 50,
      Cell: (row: any) => {
        // console.log("is cal data: ", row.original.name, row.original.compliance )
        return (
          <Checkbox
            checked={row.original.compliance || false}
            disabled={row.original.extra?.is_prn || false}
            onChange={(event: any, { checked }) => {
              handleCheckBoxChange(row, "is_cal", checked);
            }}
          />
        );
      },
    },
  ];
console.log("props.medicationCurrentListFilter",props.medicationCurrentListFilter)
  useEffect(() => {
    props.onEvent({ message: "getCurrentDrugList", params: {} });
  }, [props.selectedPatient]);

  useEffect(() => {
    setCurrentItems(props.medicationCurrentListFilter);
    // console.log(props.medicationCurrentListFilter)
  }, [props.medicationCurrentListFilter]);

  // search
  const handleChangeStartDate = (date: string) => {
    setFilterBy({ ...filterBy, start: date });
  };

  const handleChangeEndDate = (date: string) => {
    setFilterBy({ ...filterBy, end: date });
  };

  const handleChangeFilter = (_event: any, data: any) => {
    setFilterBy({ ...filterBy, [data.name]: data.value });
  };

  // table action
  const handleCheckBoxChange = (row: any, name: string, checked?: boolean) => {
    // let items = [...currentItems];
    // items[row.index] = { ...items[row.index], [name]: checked };
    // setCurrentItems(items);
    if (name === "is_cal") {
      props.onEvent({
        message: "updateTrackcareMedication",
        params: {
          action: "ADD_COMPLIANCE",
          medId: row.original.id,
          isActive: checked,
        },
      });
    }
    if (name === "active") {
      props.onEvent({
        message: "updateTrackcareMedication",
        params: {
          action: "ACTIVE_CURRENT",
          medId: row.original.id,
          isActive: checked,
        },
      });
    }
  };

  const handleDeleteMedicationCurrent = () => {
    props.onEvent({
      message: "updateTrackcareMedication",
      params: {
        action: "DELETE_CURRENT",
      },
    });
  };

  const handleSaveMedicationCurrent = () => {
    props.onEvent({
      message: "saveMedicationTrackCare",
      params: {},
    });
  };

  const handleSearch = () => {
    props.onEvent({
      message: "filterMedicationTrackCare",
      params: {
        ...filterBy,
        doctor:
          props.medDoctorCROptions?.find(
            (item: any) => item.value === filterBy?.doctor
          )?.text || "",
        clinic:
          props.medDivisionCROptions?.find(
            (item: any) => item.value === filterBy?.clinic
          )?.text || "",
        start: filterBy.start
          ? beToAd(filterBy?.start)?.format("YYYY-MM-DD")
          : "",
        end: filterBy.end ? beToAd(filterBy.end)?.format("YYYY-MM-DD") : "",
        showType: "medicationCurrentListFilter",
      },
    });
  };
  // clinic;
  // drug manually action
  const handleChangeDrugManually = (event: any, data: any) => {
    console.log("handleChangeDrugManually:", data);
    let result: any = { ...drugManually, [data.name]: data.value };
    if (data.name === "frequency") {
      result.administrationTime = Array(data.value).fill("");
    }
    setDrugManually(result);
  };

  const handleAddMedicationCurrent = () => {
    let addManual = { ...drugManually };
    let doctor = props.medDoctorCROptions?.find(
      (item) => drugManually.doctor === item.value
    );
    if (doctor) {
      addManual["doctor"] = doctor.text;
    }

    let clinic = props.medDivisionCROptions?.find(
      (item) => drugManually.clinic === item.value
    );
    if (clinic) {
      addManual["clinic"] = clinic.text;
    }

    let unit = props.unitOptions.find(
      (item) => +drugManually.unit === +item.value
    );
    if (unit) {
      addManual["unit"] = unit.text;
    }

    let route = props.routeOptions.find(
      (item) => drugManually.route === item.value
    );
    if (route) {
      addManual["route"] = route.text;
    }

    let frequency = props.frequencyOptions.find(
      (item) => drugManually.frequency === item.value
    );
    if (frequency) {
      addManual["frequency"] = frequency.text;
    }

    let method = props.methodOptions.find(
      (item) => drugManually.method === item.value
    );
    if (method) {
      addManual["method"] = method.text;
    }

    if (addManual.administrationTime.length > 0) {
      addManual["administrationTime"] = addManual.administrationTime.map(
        (item: any, index: number) => {
          let additionTime = props.administrationTimeOptions.find(
            (time) => item === time.value
          );
          if (additionTime) {
            return additionTime.text;
          }
          return "";
        }
      );
    }
    // props.onEvent({
    //   message: "handleAddMedicationCurrent",
    //   params: drugManually,
    // });
    props.onEvent({
      message: "saveMedicationTrackCare",
      params: { manualDrug: addManual },
    });
    console.log("DrugManually:", addManual);
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  // console.log("That data:", props.medicationCurrentListFilter, drugManually, props.unitOptions)
  return (
    <div className="sub-layout dm">
      <Dimmer active={props.loadingMedication} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <Grid verticalAlign="middle" style={{ paddingBottom: "35px" }}>
        <Grid.Row>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8} style={{ textAlign: "right" }}>
            <FormattedMessage id="bplusClinicKey291" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8} style={{ textAlign: "right" }}>
            <Button
              color="red"
              size="large"
              onClick={handleDeleteMedicationCurrent}
            >
              <FormattedMessage id="bplusClinicKey153" />
            </Button>
            <Button
              color="blue"
              size="large"
              onClick={handleSaveMedicationCurrent}
            >
              <FormattedMessage id="bplusClinicKey431" />
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={7}></Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey165" />
          </Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey114" />
          </Grid.Column>
          <Grid.Column width={3}>
            <FormattedMessage id="bplusClinicKey160" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={2}>
            <FormattedMessage id="bplusClinicKey356" />
          </Grid.Column>
          <Grid.Column width={2} style={MedStyles.noPadding}>
            <DateTextBox
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              maxDate={adToBe(moment())}
              value={filterBy?.start}
              onChange={handleChangeStartDate}
            />
          </Grid.Column>
          <Grid.Column width={1} style={{ textAlign: "right" }}>
            <FormattedMessage id="bplusClinicKey489" />
          </Grid.Column>
          <Grid.Column width={2} style={MedStyles.noPadding}>
            <DateTextBox
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              maxDate={adToBe(moment())}
              value={filterBy?.end}
              onChange={handleChangeEndDate}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Input
              name="drugName"
              text={filterBy?.drugName}
              onChange={handleChangeFilter}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Dropdown
              selection
              search
              name="clinic"
              loading={props.loadingDivisionOptions}
              options={props.medDivisionCROptions}
              value={filterBy?.clinic}
              onSearchChange={(_event: any, data: any) => {
                if (data.searchQuery.length > 2) {
                  props.onSearchDivision("CR", data.searchQuery);
                }
              }}
              onChange={handleChangeFilter}
              clearable
            />
          </Grid.Column>{" "}
          <Grid.Column width={3}>
            <Dropdown
              selection
              search
              name="doctor"
              loading={props.loadingDoctorOptions}
              options={props.medDoctorCROptions}
              value={filterBy?.doctor}
              onSearchChange={(_event: any, data: any) => {
                if (data.searchQuery.length > 2) {
                  props.onSearchDoctor("CR", data.searchQuery);
                }
              }}
              onChange={handleChangeFilter}
              clearable
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={MedStyles.noPadding}>
          <Grid.Column floated="left" width={14}></Grid.Column>
          <Grid.Column
            width={2}
            style={{ ...MedStyles.noPadding, textAlign: "right" }}
          >
            <Button color="green" size="large" onClick={handleSearch}>
              <FormattedMessage id="bplusClinicKey440" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <PureReactTable
        // @ts-ignore
        className="weight-table-custom web-mom"
        // selectedRow={props.selectedPatient}
        data={currentItems}
        columns={patientColumn}
        showPagination={false}
        pageSize={currentItems?.length > 10 ? currentItems?.length : 10}
        // onSelect={handleSelectedPatient}
        manual
        style={{ height: "500px" }}
        getTheadThProps={handleGetTheadThProps}
      />

      <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
        <FormattedMessage id="bplusClinicKey42" />
      </Header>
      <Grid verticalAlign="middle" style={{ paddingBottom: "20px" }}>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={6}>
            <FormattedMessage id="bplusClinicKey165" />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey429" />
          </Grid.Column>
          <Grid.Column width={2}>
            <FormattedMessage id="bplusClinicKey162" />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey503" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: "0px" }}>
          <Grid.Column width={6}>
            <Input
              fluid
              name="drug_name"
              value={drugManually?.drug_name}
              onChange={handleChangeDrugManually}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              fluid
              selection
              search
              name="route"
              options={props.routeOptions}
              value={drugManually?.route}
              onChange={handleChangeDrugManually}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Input
              fluid
              name="dosage"
              text={drugManually?.dosage}
              onChange={handleChangeDrugManually}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              fluid
              selection
              search
              name="unit"
              options={props.unitOptions}
              value={drugManually?.unit}
              onChange={handleChangeDrugManually}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={8}>
            <FormattedMessage id="bplusClinicKey160" />
          </Grid.Column>
          <Grid.Column width={8}>
            <FormattedMessage id="bplusClinicKey114" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={8}>
          <Dropdown
            fluid
            selection
            search
            name="doctor"
            loading={props.loadingDoctorOptions}
            options={props.medDoctorCROptions}
            value={drugManually?.doctor}
            onSearchChange={(_event: any, data: any) => {
              if (data.searchQuery.length > 2) {
                props.onSearchDoctor("CR", data.searchQuery);
              }
            }}
            onChange={handleChangeDrugManually}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <Dropdown
            fluid
            selection
            search
            name="clinic"
            loading={props.loadingDivisionOptions}
            options={props.medDivisionCROptions}
            value={drugManually?.clinic}
            onSearchChange={(_event: any, data: any) => {
              if (data.searchQuery.length > 2) {
                props.onSearchDivision("CR", data.searchQuery);
              }
            }}
            onChange={handleChangeDrugManually}
          />
        </Grid.Column>

        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey205" />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormattedMessage id="bplusClinicKey51" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={4}>
          <Dropdown
            fluid
            selection
            search
            name="frequency"
            options={props.frequencyOptions}
            value={drugManually?.frequency}
            onChange={handleChangeDrugManually}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Dropdown
            fluid
            selection
            search
            name="method"
            options={props.methodOptions}
            value={drugManually?.method}
            onChange={handleChangeDrugManually}
          />
        </Grid.Column>
        {drugManually?.administrationTime.map((item: string, index: number) => {
          return (
            <Grid.Column width={2} key={index}>
              <Dropdown
                fluid
                selection
                search
                name="administrationTime"
                options={props.administrationTimeOptions}
                value={item}
                onChange={(event: any, data: any) => {
                  let result: any = { ...drugManually };
                  result.administrationTime[index] = data.value;
                  setDrugManually(result);
                }}
              />
            </Grid.Column>
          );
        })}

        <Grid.Row style={MedStyles.noPadding}>
          <Grid.Column floated="left" width={14}></Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "right" }}>
            <Button
              color="green"
              size="large"
              onClick={handleAddMedicationCurrent}
            >
              <FormattedMessage id="bplusClinicKey39" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

MedicationCurrent.defaultProps = MedicationCurrentInitial;

export default React.memo(MedicationCurrent);
