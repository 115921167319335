
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data:  */
const ProxyRequestedAppointmentM:
  {
    get: ({ params, apiToken, extra }:
      {
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    post: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any
      }) => any
  } =
{
  get: async ({ params, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(base.client.get(`${HOST}/proxy/api/requested_appointment/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/proxy/api/requested_appointment/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

}

export default ProxyRequestedAppointmentM

