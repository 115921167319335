import React, { useState, useEffect, CSSProperties } from "react";
import { Checkbox, Divider, Grid, Image } from "semantic-ui-react";
// Mui
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import moment from "moment";

// UI
import MobileTimePicker from "./MobileTimePicker";

// Types
type ReminderProp = {
  onEvent: (e: any) => any;
  getReminderData: any;
  language?: "en-US" | "th-TH";
};
type ReminderModel = {
  titleTime?: string | null;
  timeFull?: string | null;
  image?: string | null;
  isAlarm?: boolean;
  idAlarm?: number;
  stateBefore?: boolean;
  calendar?: any;
};

// Constant
const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 72,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    transform: "translateX(27px)",
    "&.Mui-checked": {
      transform: "translateX(46px)",
      color: "#ffffff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#44E744",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "white",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#F6F6F6" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const styles = {
  boxcard: {
    margin: "20px 15px 20px 15px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  } as CSSProperties,
  labalremider: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  labaltext: {
    fontSize: "16px",
    color: "#797878",
  } as CSSProperties,
  switchbutton: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0.5rem",
  } as CSSProperties,
  iconreminder: {
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  divider: {
    margin: "0px",
    borderTopColor: "rgba(131, 133, 135, 0.3)",
    borderBottom: "none",
  } as CSSProperties,
};

const IMAGES = {
  morning: "images/drug/icon_morning.png",
  lunch: "images/drug/icon_lunch.png",
  evening: "images/drug/icon_evening.png",
  beforeBedtime: "images/drug/icon_night.png",
};

const Reminder: React.FunctionComponent<ReminderProp> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState(moment());
  const [dialogIndex, setDialogIndex] = useState<number>(0);
  const [reminderData, setReminderData] = useState<ReminderModel[] | undefined>(
    []
  );

  useEffect(() => {
    globalThis.mobile?.reminderData?.();
    mockReminder();
  }, []);

  useEffect(() => {
    if (props.getReminderData) {
      const convent = props.getReminderData;
      console.log("chen reminderData123 convent: ", convent);
      const dataDetail = convent.map((item: any, index: any) => {
        console.log("chen item", item);
        if (item.titleTime === "Morning" || item.titleTime === "ตอนเช้า") {
          return { ...item, imageWeb: IMAGES.morning };
        } else if (item.titleTime === "Lunch" || item.titleTime === "กลางวัน") {
          return { ...item, imageWeb: IMAGES.lunch };
        } else if (
          item.titleTime === "Evening" ||
          item.titleTime === "ตอนเย็น"
        ) {
          return { ...item, imageWeb: IMAGES.evening };
        } else if (
          item.titleTime === "Before Bedtime" ||
          item.titleTime === "ก่อนนอน"
        ) {
          return { ...item, imageWeb: IMAGES.beforeBedtime };
        }
      });
      if (dataDetail && dataDetail.length > 0) {
        setReminderData(dataDetail);
      } else {
        mockReminder();
      }
    }
  }, [props.getReminderData]);

  const mockReminder = () => {
    const mockReminder: any[] = [];
    const mockjson = {
      titleTime: "Morning",
      timeFull: "08:00",
      image: 2,
      isAlarm: false,
      stateBefore: false,
      calendar: {},
    };

    mockReminder.push({
      ...mockjson,
      titleTime: "Morning",
      timeFull: "08:00",
      imageWeb: IMAGES.morning,
    });
    mockReminder.push({
      ...mockjson,
      titleTime: "Lunch",
      timeFull: "12:00",
      imageWeb: IMAGES.lunch,
    });
    mockReminder.push({
      ...mockjson,
      titleTime: "Evening",
      timeFull: "18:00",
      imageWeb: IMAGES.evening,
    });
    mockReminder.push({
      ...mockjson,
      titleTime: "Before Bedtime",
      timeFull: "21:00",
      imageWeb: IMAGES.beforeBedtime,
    });
    setReminderData(mockReminder);
  };

  const handleReminder = (indexKey: number) => {
    if (reminderData) {
      const dataDetail = reminderData.map((item: any, index: any) => {
        if (indexKey === index) {
          console.log("chen item", !item.isAlarm);
          return { ...item, isAlarm: !item.isAlarm };
        } else {
          return { ...item };
        }
      });
      setReminderData(dataDetail);
      props.onEvent({
        message: "HandleSetAlarm",
        params: { rm: dataDetail, key: indexKey },
      });
    }
  };

  const handleSelectTime = (indexKey: number) => {
    if (reminderData) {
      const time = reminderData[indexKey].timeFull || ""
      const format = `${moment().format("YYYY-MM-DD")} ${time}`;
      const dateTime = moment(format, "YYYY-MM-DD HH:mm");

      setDialogIndex(indexKey);
      setDate(dateTime);
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = (date: moment.Moment | null) => {
    setOpen(false);

    if (reminderData) {
      const dataDetail = reminderData.map((item: any, index: any) => {
        if (dialogIndex === index) {
          return {
            ...item,
            timeFull: moment(date).format("HH:mm"),
          };
        } else {
          return { ...item };
        }
      });
      setReminderData(dataDetail);
      props.onEvent({
        message: "HandleSetAlarm",
        params: { rm: dataDetail, key: dialogIndex },
      });
    }
  };

  return (
    <Grid columns={3} style={styles.boxcard}>
      {reminderData
        ? reminderData.map((item: any, index: any) => (
            <>
              <Grid.Row key={index} style={{ padding: "1rem 1.25rem" }}>
                <Grid.Column width={4} style={styles.iconreminder}>
                  <Image
                    src={item.imageWeb}
                    style={{ padding: "5px", marginTop: "2px" }}
                    disabled={!item.isAlarm}
                  />
                </Grid.Column>
                <Grid.Column style={styles.labalremider} width={8}>
                  <Grid.Row
                    style={{
                      ...styles.labaltext,
                      marginBottom: "3px",
                      color: item.isAlarm ? "#AAAAAA" : "#DCdCDC",
                      fontSize: "1.0rem",
                    }}
                  >
                    {item.titleTime}
                  </Grid.Row>
                  <Grid.Row
                    style={{
                      ...styles.labaltext,
                      color: item.isAlarm ? "rgba(0,0,0,1)" : "#808080",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSelectTime(index)}
                  >
                    {item.timeFull}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4} style={styles.switchbutton}>
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch defaultChecked />}
                      label=""
                      checked={item.isAlarm}
                      onClick={() => handleReminder(index)}
                    />
                  </FormGroup>
                </Grid.Column>
              </Grid.Row>

              {item.titleTime === "Before Bedtime" ? (
                ""
              ) : (
                <Divider style={styles.divider} />
              )}
            </>
          ))
        : ""}

      {/* Dialog select time */}
      <MobileTimePicker
        open={open}
        date={date}
        language={props.language}
        // callback
        onClose={handleCancel}
        onCancel={handleCancel}
        onOK={handleSave}
      />
    </Grid>
  );
};

(Reminder.defaultProps as ReminderProp) = {
  onEvent: () => null,
  getReminderData: null,
};

export default React.memo(Reminder);
