import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// APIs
import VaccineMasterItemList from "issara-sdk/apis/VaccineMasterItemList_apps_PHR";
import VaccineSeriesList from "issara-sdk/apis/VaccineSeriesList_apps_PHR";
import VaccineSeriesDetail from "issara-sdk/apis/VaccineSeriesDetail_apps_PHR";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import AgeRangeList from "issara-sdk/apis/AgeRangeList_apps_PHR";
import AgeRangeDetail from "issara-sdk/apis/AgeRangeDetail_apps_PHR";

// Types
import AgeRangeSerializer from "issara-sdk/types/AgeRangeSerializer_apps_PHR";

// Interface
import { State as MainState } from "../../ManageInterface";

export type State = {
  // Data
  vaccineUMDetail?: Partial<{
    id?: number;
    vaccine_type?: "ESSENTIAL" | "OTHERS" | "";
    age_range?: (number | null)[];
    name: string;
    add_manually: boolean;
    left: {
      items?: any[];
      checked?: number[];
    };
    right: {
      items?: any[];
      checked?: number[];
    };
    abbreviation: string;
    description: string;
    dose: string | number;
  }>;
  vaccineUMList?: Record<string, any>[];
  allVaccineUMList?: Record<string, any>[];
  orderItemVaccineUM?: Record<string, any>[];
  ageRangeChoice?: Record<string, any>[];
  vaccineSeriesChoice?: Record<string, any>[];
  ageRangeDetail?: Partial<{
    id?: number;
    name: string;
    sequence: number | string;
    to: {
      [key in DateKey]?: string;
    };
    start: {
      [key in DateKey]?: string;
    };
    startOptions: {
      [key in DateKey]: Record<string, any>[];
    };
    toOptions: {
      [key in DateKey]: Record<string, any>[];
    };
  }>;
  ageRangeList?: AgeRangeSerializer[];
};

type DateKey = "year" | "month" | "day";

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus"
>;

export const StateInitial: State = {
  // Data
  vaccineUMDetail: {
    add_manually: true,
  },
  vaccineUMList: [],
  vaccineSeriesChoice: [],
  allVaccineUMList: [],
  orderItemVaccineUM: [],
  ageRangeChoice: [],
  ageRangeDetail: {},
  ageRangeList: [],
};

type AgeRangeType = "VACCINE_CHILD" | "DEVELOPMENT";
type VaccineType = "DM" | "CHILD";

export type Event =
  // Setter
  | { message: "HandleSetVaccineDMDetail"; params: State["vaccineUMDetail"] }
  // Get
  | { message: "GetVaccineUMList"; params: { patientType: VaccineType } }
  | { message: "GetChoiceAgeRange"; params: {} }
  | { message: "GetChoiceVaccineSeries"; params: {} }
  | { message: "GetListAgeRange"; params: { patientType: AgeRangeType } }
  // Save
  | { message: "HandleSaveVaccineUM"; params: { patientType: VaccineType } }
  | { message: "HandleSaveAgeRange"; params: { patientType: AgeRangeType } }
  // Delete
  | {
    message: "HandleDeleteVaccineUM";
    params: { id: number | null; patientType: VaccineType };
  }
  | {
    message: "HandleDeleteAgeRange";
    params: { id: number | null; patientType: AgeRangeType };
  }
  // Handle
  | { message: "HandleSearchOrderItemVaccineUM"; params: { search: string } }
  | { message: "HandleClearInputVaccineUM"; params: {} }
  | { message: "HandleSearchVaccineUM"; params: { search: string } }
  | { message: "HandleSelectedVaccineUM"; params: { data: any } }
  | { message: "HandleChangeAgeRangeDetail"; params: State["ageRangeDetail"] }
  | { message: "HandleSelectedAgeRange"; params: { data: AgeRangeSerializer } };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

/* ------------------------- GET ------------------------ */
export const GetVaccineUMList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const getVaccineMasterItemList = VaccineMasterItemList.list({
    apiToken: Cookies.get("apiToken"),
  });

  const getVaccineSeriesList = VaccineSeriesList.list({
    apiToken: Cookies.get("apiToken"),
    params: { patient_type: params.patientType },
  });

  const [vaccineMaster, vaccineSeries] = await Promise.all([
    getVaccineMasterItemList,
    getVaccineSeriesList,
  ]);

  controller.setState({ loadingStatus: false });

  const masterItems = vaccineMaster?.[0]?.items || [];
  const seriesItems = vaccineSeries?.[0]?.items || [];
  const { vaccineSeriesChoice } = controller.getState();

  controller.setState({
    vaccineUMList: seriesItems,
    vaccineUMDetail: {
      vaccine_type:
        vaccineSeriesChoice?.find((item) => item.id === "ESSENTIAL")?.value ||
        "",
      left: {
        items: masterItems,
      },
      right: {
        items: [],
      },
      add_manually: true,
    },
    orderItemVaccineUM: [...masterItems],
    allVaccineUMList: [...seriesItems],
  });
};

export const GetListAgeRange: Handler = async (
  controller,
  params: Extract<Event, { message: "GetListAgeRange" }>["params"]
) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await AgeRangeList.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      type: params.patientType,
    },
  });

  const dateOptions = genDateOptions();

  controller.setState({
    ageRangeDetail: {
      startOptions: dateOptions,
      toOptions: dateOptions,
    },
  });

  if (error) {
    controller.setState({ loadingStatus: false, errorMessage: error });
  }

  controller.setState({ ageRangeList: res?.items || [], loadingStatus: false });
};

export const GetChoiceAgeRange: Handler = async (controller, params) => {
  const [res, error] = await ChoiceView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      model: "PHR.AgeRange",
      field: "type",
      name_as_id: true,
    },
  });

  controller.setState({
    ageRangeChoice: res?.items || [],
  });
};

export const GetChoiceVaccineSeries: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await ChoiceView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      model: "PHR.VaccineSeries",
      field: "vaccine_type",
      name_as_id: true,
    },
  });

  const items: any[] = res?.items || [];

  controller.setState({
    vaccineSeriesChoice: items,
    loadingStatus: true,
  });
};

/* ----------------------- SETTER ----------------------- */
export const HandleSetVaccineDMDetail: Handler = (controller, params) => {
  const { vaccineUMDetail } = controller.getState();

  controller.setState({
    vaccineUMDetail: {
      ...vaccineUMDetail,
      ...params,
    },
  });
};

/* ------------------------ SAVE ------------------------ */
export const HandleSaveVaccineUM: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleSaveVaccineUM" }>["params"]
) => {
  const { vaccineUMDetail: detail, vaccineSeriesChoice } =
    controller.getState();
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;

  const data: any = {
    name: detail?.name,
    patient_type: params.patientType,
    items: detail?.right?.items?.map((item) => item.id),
    qty: detail?.dose,
    abbreviation: detail?.abbreviation,
    description: detail?.description,
    add_manually: detail?.add_manually,
  };

  if (params.patientType === "CHILD") {
    const ageRange = (detail?.age_range || []).filter(Boolean);
    data.vaccine_type = detail?.vaccine_type || "";
    data.qty = data.vaccine_type === "OTHERS" ? detail?.dose || 0 : ageRange.length;
    data.age_range =
      data.vaccine_type === "OTHERS"
        ? (detail?.age_range || []).slice(0, data.qty)
        : ageRange;
  }

  let [res, error]: [any, any] = [null, null];

  if (detail?.id) {
    [res, error] = await VaccineSeriesDetail.update({
      pk: detail.id,
      apiToken: Cookies.get("apiToken"),
      data,
    });
  } else {
    [res, error] = await VaccineSeriesList.create({
      apiToken: Cookies.get("apiToken"),
      data,
    });
  }

  if (error) {
    errorMessage = error;
  } else {
    successMessage = `${detail?.id ? "Update" : "Create"} vaccine success.`;
    GetVaccineUMList(controller, params);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const HandleSaveAgeRange: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const { ageRangeDetail } = controller.getState();
  const { start, to } = ageRangeDetail || {};

  const data = {
    name: ageRangeDetail?.name,
    display_seq: ageRangeDetail?.sequence,
    type: params.patientType,
    from_year: parseInt(start?.year || "") || 0,
    from_month: parseInt(start?.month || "") || 0,
    from_day: parseInt(start?.day || "") || 0,
    to_year: parseInt(to?.year || "") ?? null,
    to_month: parseInt(to?.month || "") ?? null,
    to_day: parseInt(to?.day || "") ?? null,
  } as any;

  let [res, error]: [any, any] = [null, null];

  if (ageRangeDetail?.id) {
    [res, error] = await AgeRangeDetail.update({
      pk: ageRangeDetail.id,
      apiToken: Cookies.get("apiToken"),
      data,
    });
  } else {
    [res, error] = await AgeRangeList.create({
      apiToken: Cookies.get("apiToken"),
      data,
    });
  }

  if (error) {
    controller.setState({ errorMessage: error, loadingStatus: false });
    return;
  }

  GetListAgeRange(controller, params);
  controller.setState({
    successMessage: `${ageRangeDetail?.id ? "Update" : "Create"
      } age range success.`,
    loadingStatus: false,
  });
};

/* ----------------------- Delete ----------------------- */
export const HandleDeleteVaccineUM: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  const [res, error] = await VaccineSeriesDetail.update({
    pk: params.id,
    apiToken: Cookies.get("apiToken"),
    data: {
      patient_type: params.patientType,
      active: false,
    },
  });

  if (error) {
    errorMessage = error;
  } else {
    successMessage = `Delete vaccine success.`;
    GetVaccineUMList(controller, params);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

export const HandleDeleteAgeRange: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let errorMessage: any = null;
  let successMessage: any = null;
  const [res, error] = await AgeRangeDetail.update({
    pk: params.id,
    apiToken: Cookies.get("apiToken"),
    data: {
      type: params.patientType,
      active: false,
    } as any,
  });

  if (error) {
    errorMessage = error;
  } else {
    successMessage = `Delete age range success.`;
    GetListAgeRange(controller, params);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

/* ----------------------- Handler ---------------------- */
export const HandleSearchOrderItemVaccineUM: Handler = (controller, params) => {
  const { orderItemVaccineUM, vaccineUMDetail } = controller.getState();
  const { search } = params;

  const filter = (orderItemVaccineUM || []).filter((item) =>
    `${item.item_code} ${item.type_of_vaccine}`.toLowerCase().includes(search)
  );

  if (vaccineUMDetail?.left?.items) {
    vaccineUMDetail.left.items = filter;
  }

  controller.setState({
    vaccineUMDetail: {
      ...vaccineUMDetail,
    },
  });
};

export const HandleSearchVaccineUM: Handler = (
  controller,
  params: Extract<Event, { message: "HandleSearchVaccineUM" }>["params"]
) => {
  const { allVaccineUMList } = controller.getState();
  const { search } = params;

  const filter = (allVaccineUMList || []).filter((item) =>
    item.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  controller.setState({
    vaccineUMList: [...filter],
  });
};

export const HandleClearInputVaccineUM: Handler = (controller, params) => {
  const { orderItemVaccineUM, vaccineUMDetail } = controller.getState();
  controller.setState({
    vaccineUMDetail: {
      ...vaccineUMDetail,
      name: "",
      dose: "",
      abbreviation: "",
      add_manually: true,
      description: "",
      left: {
        items: orderItemVaccineUM,
      },
      right: {
        items: [],
      },
    },
  });
};

export const HandleSelectedVaccineUM: Handler = (controller, params) => {
  const { orderItemVaccineUM } = controller.getState();
  const { data } = params;
  controller.setState({
    vaccineUMDetail: {
      ...data,
      dose: data.qty,
      left: {
        items: orderItemVaccineUM?.filter(
          (item: any) => !data.items.includes(item.id)
        ),
      },
      right: {
        items: orderItemVaccineUM?.filter((item: any) =>
          data.items.includes(item.id)
        ),
      },
    },
  });
};

export const HandleChangeAgeRangeDetail: Handler = (
  controller,
  params: Extract<Event, { message: "HandleChangeAgeRangeDetail" }>["params"]
) => {
  const { ageRangeDetail } = controller.getState();
  const cloneDetail: any = { ...ageRangeDetail, ...params };
  const key = Object.keys(
    params || {}
  )?.[0] as keyof Required<State>["ageRangeDetail"];

  // if (["start", "to"].includes(key)) {
  //   const value = cloneDetail?.[key];
  //   let year: string = value?.year || "";
  //   let month: string = value?.month || "";
  //   let date: string = value?.day || "";
  //   let dateNumber = 0;

  //   if (month === "02") {
  //     dateNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  //   } else {
  //     dateNumber = moment(month, "MM").daysInMonth();
  //   }

  //   console.log(dateNumber);
  //   if (dateNumber && cloneDetail) {
  //     cloneDetail[key].day =
  //       +date > dateNumber ? dateNumber.toString().padStart(2, "0") : date;
  //     cloneDetail[`${key}Options`].day = CreateOptions(range(1, dateNumber));
  //   }
  // }

  controller.setState({ ageRangeDetail: cloneDetail });
};

export const HandleSelectedAgeRange: Handler = (
  controller,
  params: Extract<Event, { message: "HandleSelectedAgeRange" }>["params"]
) => {
  const { ageRangeDetail } = controller.getState();
  const { data } = params;

  controller.setState({
    ageRangeDetail: {
      ...(ageRangeDetail || {}),
      id: data.id,
      name: data.name,
      sequence: data.display_seq,
      start: {
        year: data.from_year?.toString(),
        month: data.from_month?.toString(),
        day: data.from_day?.toString(),
      },
      to: {
        year: data.to_year?.toString() || null,
        month: data.to_month?.toString() || null,
        day: data.to_day?.toString() || null,
      },
    },
  });
};

// Utils

export const CreateOptions = (items: any[] = [], idByValue = false) => {
  const mapOption = (item: string, index: number) => ({
    key: index,
    text: item,
    value: idByValue ? item : `${index + 1}`,
  });
  return items.map(mapOption);
};

export const range = (min: number, max: number) =>
  Array.from({ length: max - min + 1 }, (_, i) => `${min + i}`);

const getDayOptions = (month = "01", year = "1970") => {
  let dateNumber = 0;
  if (month === "02") {
    dateNumber = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  } else {
    dateNumber = moment(month, "MM").daysInMonth();
  }

  if (!dateNumber) {
    dateNumber = 31;
  }

  return CreateOptions(range(1, dateNumber));
};

const getMonthOptions = () => {
  const monthArray = new Array(12).fill("").map((_, index) =>
    moment()
      .startOf("month")
      .clone()
      .add(index - 1, "month")
      .format("MMMM")
  );

  return CreateOptions(monthArray);
};

const genDateOptions = () => {
  const today = moment();
  const momentTh = today.clone().locale("th");
  const currentYear = +momentTh.format("YYYY") + 543;

  const day = CreateOptions(range(0, 31), true); //getDayOptions();
  const month = CreateOptions(range(0, 12), true); //getMonthOptions();
  const year = CreateOptions(range(0, 100), true);

  return {
    day,
    month,
    year,
  };
};
