import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// Interface
import {
  HandleSelectHospitalInitial,
  GetListHospital as handleGetListHospital,
} from "../appointment/MakeAppointmentInterface";

// apis
import V3CheckCheckupFileView from "../../issara-sdk/apis/V3CheckCheckupFileView_apps_PRX";

export type State = {
  checkupList?: any[];
  hospitalList?: any[];
  firstLoadingHospital?: boolean;
  selectedHospital?: any;
  loadingSearchHostipalFilter?: boolean;
  textSearchHostipalFilter?: string;
};

export const StateInitial = {
  checkupList: [],
  hospitalList: [],
  firstLoadingHospital: false,
  selectedHospital: null,
  loadingSearchHostipalFilter: false,
  textSearchHostipalFilter: "",
};

export type Event =
  | { message: "handleGetCheckupList"; params: { hospital: any } }
  | { message: "GetListHospital"; params: {} }
  | { message: "HandleSelectedHospital"; params: {} };

type Handler = WasmHandler<State, Event>;

const GetCheckupList: Handler = async (controller, params) => {
  const [response, error, network] = await V3CheckCheckupFileView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { covid: false, hospital: params.code },
  });
  if (error) {
    if (network.response.status === 404) {
      controller.setState({
        loadingStatus: false,
        errorMessage: null,
        successMessage: null,
        checkupList: [],
      });
    } else {
      controller.setState({
        loadingStatus: false,
        errorMessage: error,
        successMessage: null,
        checkupList: [],
      });
    }
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      checkupList: response,
    });
  }
};

export const handleGetCheckupList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  GetCheckupList(controller, { code: params.hospital });
};

export const GetListHospital: Handler = (controller, params) => {
  handleGetListHospital(controller as any, params);
};

export const HandleSelectedHospital: Handler = (controller, params) => {
  controller.setState({ loadingStatus: true });

  const data = JSON.parse(JSON.stringify(params.data));

  controller.setState({ selectedHospital: data });

  GetCheckupList(controller, { code: data.code });
};
