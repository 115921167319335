import React, { useEffect } from "react";
import DMExerciseChart from "../../clinic/DMExerciseChart";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import SnackMessage from "../../clinic/SnackMessage";

type WebDMExerciseProps = {
  // function
  onEvent?: any;
  // data
  selectedPatient?: any;
  loadingStatus: boolean;
  errorMessage: any;
  successMessage: any;
  exerciseChartList?: any[];
  exerciseSettingData?: any;
};

const WebDMExerciseInitial: WebDMExerciseProps = {
  // function
  onEvent: () => {},
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  exerciseChartList: [],
};

const WebDMExercise: React.FC<WebDMExerciseProps> = (props) => {
  useEffect(() => {
    props.onEvent({ message: "GetExerciseSetting", params: {} });
  }, [props.selectedPatient]);

  console.log(props);

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/detail/exercise.png"
        />
        <span>
          <FormattedMessage id="exercise.exercise" />
        </span>
      </div>
      <div className="sub-layout dm">
        <DMExerciseChart
          // function
          onEvent={props.onEvent}
          // data
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          exerciseChartList={props.exerciseChartList}
          exerciseSettingData={props.exerciseSettingData}
          // config
          showCurrentBarDetail={false}
          hideFooterChart={true}
          height={130}
          isMyTab={true}
          showTooltipChart={true}
        />
      </div>
    </Segment>
  );
};

WebDMExercise.defaultProps = WebDMExerciseInitial;

export default WebDMExercise;
