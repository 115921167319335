import WasmController from "../../react-lib/frameworks/WasmController";
import axios from "axios";
import moment from "moment";
import Cookies from 'js-cookie';
import { GetAvailableDoctor } from "./MakeAppointmentInterface"

export type State = {
  specialtyOptions?: any[];
  selectedSpecialty?: any;
  doctorSpecialtyList?: any[];
  nextPageDoctorSpecialty?: number;
  endOfDoctorSpecialty?: boolean;
  firstLoadingDoctorSpecialty?: boolean;
  textSearchDoctorSpecialty?: string;
  loadingSearchDoctorSpecialty?: boolean;
};

export const StateInitial: State = {
  specialtyOptions: [
    { key: 1, value: "availability", text: "Availability" },
    { key: 2, value: "price_low_to_high", text: "Price low to high" },
    { key: 3, value: "price_high_to_low", text: "Price high to low" },
    { key: 4, value: "experience_year", text: "Years of experience" },
    { key: 5, value: "rating", text: "Rating" }
  ],
  selectedSpecialty: "availability",
  doctorSpecialtyList: [],
  nextPageDoctorSpecialty: 0,
  endOfDoctorSpecialty: false,
  firstLoadingDoctorSpecialty: false,
  textSearchDoctorSpecialty: "",
  loadingSearchDoctorSpecialty: false
};

export type Event =
  | { message: "HandleChangeSpecialty"; params: { value: any } }
  | { message: "GetListDoctorSpecialty"; params: { page?: number; isConcat?: boolean; name?: string; subspecialty?: any; isFirstLoad?: boolean; hospital?:number; } }

export type Data =
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const limit = 20
export const HandleChangeSpecialty: Handler = (controller, params) => {
  controller.setState({ selectedSpecialty: params.value })
}

export const GetListDoctorSpecialty: Handler = async (controller, params) => {
  const { doctorSpecialtyList, nextPageDoctorSpecialty } = controller.getState()
  const { isConcat, page, specialty, isFirstLoad } = params
  if (!isConcat) {
    controller.setState({ loadingSearchDoctorSpecialty: true, })
  }
  if (isFirstLoad) {
    controller.setState({ firstLoadingDoctorSpecialty: false, endOfDoctorSpecialty: false })
  }
  const [res, error] = await GetAvailableDoctor(controller as any, params)

  const list = isConcat ? [...(doctorSpecialtyList || []), ...(res?.items || [])] : (res?.items || [])
  const end = !res?.items?.[0] && page > 1 ? true : false

  controller.setState({
    doctorSpecialtyList: list,
    nextPageDoctorSpecialty: page,
    endOfDoctorSpecialty: end,
    firstLoadingDoctorSpecialty: true,
    loadingSearchDoctorSpecialty: false,
    textSearchDoctorSpecialty: params.name
  })
}