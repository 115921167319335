import WasmController from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";
import V3PatientHospital from "../issara-sdk/apis/V3PatientHospital_apps_PRX";
import ListConsentsView from "../issara-sdk/apis/ListConsentsView_apps_PRX";

export type State = {
  loggedin?: boolean;
  platform?: string;
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  language?: "en-US" | "th-TH";
  intl?: IntlShape;
  firstLoadingHospital?: boolean;
  textSearch?: string;
  loadingSearch?: boolean;
  hospitalList?: any[];
  hospitalConsentList?: any[];
};

export const StateInitial: State = {
  userId: "",
  loggedin: false,
  platform: "",

  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  firstLoadingHospital: false,
  textSearch: "",
  loadingSearch: false,
  hospitalList: [],
  hospitalConsentList: [],
};

export type Event =
  | { message: "GetLoginInfo"; params: {} }
  | { message: "HandleEvent"; params: {} }
  | { message: "SelectHospitalCode"; params: {} }
  | { message: "SelectHospitalArray"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "GetListHospital"; params: {} }
  | { message: "HandleSelectedHospital"; params: { data: any } }
  | { message: "GetHospitalConsentList"; params: {} }
  | { message: "HandleSelectedHospitalConsent"; params: {} };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
    // const result = await GetHospital(controller, params);
    console.log(" DidMount GetHospital ")
    const [res, error] = await GetHospital(controller, params);
    const [resConsent, errorConsent] = await GetHospitalConsentList(controller, params);

    console.log(" DidMount GetHospital ")
    controller.setState({
        hospitalList: res?.items || [],
        firstLoadingHospital: true,
        hospitalConsentList: resConsent || [],
        // textSearchHostipalFilter: params.name,
        // loadingSearchHostipalFilter: false,
    });
    console.log("res",res)
    console.log("resConsent",resConsent)
};

export const GetListHospital: Handler = async (controller, params) => {
//   controller.setState({ loadingSearchHostipalFilter: true });
  console.log("GetListHospital:", params)
  const [res, error] = await GetHospital(controller, params);
  console.log("GetListHospital:", params)
  controller.setState({
    hospitalList: res?.items || [],
    firstLoadingHospital: true,
    // textSearchHostipalFilter: params.name,
    // loadingSearchHostipalFilter: false,
  });

};

export const GetHospital: Handler = async (controller, params) => {

  let lang = window.navigator?.language;;
  if (window.mobile?.iOSLang) {
    lang = await window.mobile?.iOSLang;
  }
  
  const [res, error, network] = await V3PatientHospital.list({
    apiToken: Cookies.get("apiToken"),
    params: {
      search: params.name,
    },
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [res, error, network];
};

export const GetHospitalConsentList: Handler = async (controller, params) => {

  const [res, error, network] = await ListConsentsView.get({
    apiToken: Cookies.get("apiToken"),
  });

  return [res, error, network];
};

export const HandleSelectedHospitalConsent: Handler = async (controller, params) => {
  controller.handleEvent({ message: "SelectHospitalCode", params: {code: params?.code}})
  controller.handleEvent({ message: "SelectHospitalArray", params: {...params}})
}

export const HandleSelectedHospital: Handler = async (controller, params) => {
  controller.handleEvent({ message: "SelectHospitalCode", params: {code: params.data.code}})
  controller.handleEvent({ message: "SelectHospitalArray", params: {...params.data}})
}
