import React from "react";
import moment from "moment";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, Header, Icon, Label } from "semantic-ui-react";
// UI
import PatientPanel from "./PatientPanel";
import { DMDatePicker } from "./DMEmotionRestChart";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BE_DATE_FORMAT,
  convertToADDate,
} from "../MobClinicInterface";

import * as DrugDMConst from "./DMDrug";

// ---------- Tab History
type DMDrugTabSummaryProps = {
  onEvent: any;
  onShowDrugManually: any;
  onShowDrugDetail: any;
  selectedPatient: any;
  drugDataTabSummary: {
    date: string;
    items: [
      {
        drug_name: string;
        unit: string;
        morning: { meal: string; dose: number | null };
        afternoon: { meal: string; dose: number | null };
        evening: { meal: string; dose: number | null };
        bedtime: { meal: string; dose: number | null };
        others: { meal: string; dose: number | null };
      }
    ];
  };
};

const DATE_FORMAT = "DD/MM/YYYY";

const DMDrugTabSummaryInitial: DMDrugTabSummaryProps = {
  onEvent: () => null,
  onShowDrugManually: () => null,
  onShowDrugDetail: () => null,
  selectedPatient: {},
  drugDataTabSummary: {
    date: "",
    items: [
      {
        drug_name: "",
        unit: "",
        morning: { meal: "", dose: null },
        afternoon: { meal: "", dose: null },
        evening: { meal: "", dose: null },
        bedtime: { meal: "", dose: null },
        others: { meal: "", dose: null },
      },
    ],
  },
};

const DMDrugTabSummary: React.FC<DMDrugTabSummaryProps> = (props) => {
  const intl = useIntl();
  const handleGetDrugSummary = (event: object, data: any) => {
    let targetDate: moment.Moment = moment(
      props.drugDataTabSummary?.date,
      "DD/MM/YYYY"
    );
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
      props.onEvent({
        message: "handleGetDrugSummary",
        params: { date: targetDate.format(BE_DATE_FORMAT) },
      });
    } else {
      targetDate = targetDate.add(1, "days");
      props.onEvent({
        message: "handleGetDrugSummary",
        params: { date: targetDate.format(BE_DATE_FORMAT) },
      });
    }
  };

  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleGetDrugSummary",
      params: {
        date: convertToADDate(date), //moment(convertToADDate(date), DATE_FORMAT)
      },
    });
  };

  console.log("drug summary data:", props.drugDataTabSummary);
  return (
    <>
      <PatientPanel
        patientInfo={props.selectedPatient}
        haveBackground={false}
      />

      <DMDatePicker
        date={moment(props.drugDataTabSummary?.date, BE_DATE_FORMAT)}
        onChangeStepDateRecord={handleGetDrugSummary}
        onChangeDateRecord={handleChangeDateRecord}
      />

      <Grid
        verticalAlign="middle"
        divided="vertically"
        style={{ padding: "20px 10px 35px 10px" }}
      >
        <Grid.Column width={16}>
          <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.darkBlue }}>
            <FormattedMessage id="bplusClinicKey479" />
          </Header>
        </Grid.Column>

        <Grid.Column width={7}></Grid.Column>
        <Grid.Column width={5}>
          <Label
            circular
            empty
            color="blue"
            size="medium"
            style={{ marginRight: "10px" }}
          />
          <FormattedMessage id="bplusClinicKey82" />
        </Grid.Column>
        <Grid.Column width={4}>
          <Label
            circular
            empty
            color="red"
            size="medium"
            style={{ marginRight: "10px" }}
          />
          <FormattedMessage id="bplusClinicKey52" />
        </Grid.Column>

        <Grid.Column width={6}></Grid.Column>
        <Grid.Column
          width={2}
          style={{
            ...DrugDMConst.styles.noPadding,
            ...DrugDMConst.styles.textCenter,
          }}
        >
          {/* <Icon
            name="sun outline"
            color="yellow"
            size="large"
            style={{ margin: "0px" }}
          /> */}
          <img
            height="24px"
            width="30px"
            src="images/drug/icon_morning.png"
            style={{ margin: "0px" }}
          />
          <p style={{ fontSize: "10px" }}>
            <FormattedMessage id="bplusClinicKey329" />
          </p>
        </Grid.Column>
        <Grid.Column
          width={2}
          style={{
            ...DrugDMConst.styles.noPadding,
            ...DrugDMConst.styles.textCenter,
          }}
        >
          {/* <Icon
            name="sun"
            color="yellow"
            size="large"
            style={{ margin: "0px" }}
          /> */}
          <img
            height="24px"
            width="30px"
            src="images/drug/icon_lunch.png"
            style={{ margin: "0px" }}
          />
          <p style={{ fontSize: "10px" }}>
            <FormattedMessage id="bplusClinicKey339" />
          </p>
        </Grid.Column>
        <Grid.Column
          width={2}
          style={{
            ...DrugDMConst.styles.noPadding,
            ...DrugDMConst.styles.textCenter,
          }}
        >
          {/* <Icon
            name="cloudversify"
            color="yellow"
            size="large"
            style={{ margin: "0px" }}
          /> */}
          <img
            height="24px"
            width="30px"
            src="images/drug/icon_evening.png"
            style={{ margin: "0px" }}
          />
          <p style={{ fontSize: "10px" }}>
            <FormattedMessage id="bplusClinicKey190" />
          </p>
        </Grid.Column>
        <Grid.Column
          width={2}
          style={{
            ...DrugDMConst.styles.noPadding,
            ...DrugDMConst.styles.textCenter,
          }}
        >
          {/* <Icon
            name="bed"
            color="yellow"
            size="large"
            style={{ margin: "0px" }}
          /> */}
          <img
            height="24px"
            width="30px"
            src="images/drug/icon_night.png"
            style={{ margin: "0px" }}
          />
          <p style={{ fontSize: "10px" }}>
            <FormattedMessage id="bplusClinicKey81" />
          </p>
        </Grid.Column>
        <Grid.Column
          width={2}
          style={{
            ...DrugDMConst.styles.noPadding,
            ...DrugDMConst.styles.textCenter,
          }}
        >
          {/* <Icon
            name="warning sign"
            color="yellow"
            size="large"
            style={{ margin: "0px" }}
          /> */}
          <Icon.Group size="big">
            <Icon name="clock outline" color="yellow" />
            <Icon corner="top right" name="plus" color="yellow" />
          </Icon.Group>
          <p style={{ fontSize: "10px" }}>
            <FormattedMessage id="bplusClinicKey358" />
          </p>
        </Grid.Column>

        {props.drugDataTabSummary?.items?.map((item: any, index: number) => {
          return (
            <Grid.Row key={index} style={DrugDMConst.styles.noPadding}>
              <Grid.Column
                width={6}
                style={DrugDMConst.styles.drugNameSummary}
                onClick={() => {
                  props.onShowDrugDetail(item);
                }}
              >
                {item.drug_name}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{
                  ...DrugDMConst.styles.drugMealSummary,
                  backgroundColor: "#bdf7ee",
                }}
              >
                {item.morning?.dose && item.morning?.meal ? (
                  <Label
                    circular
                    color={item.morning?.meal === "before" ? "blue" : "red"}
                  >
                    {item.morning?.dose}
                  </Label>
                ) : (
                  <>{item.morning?.dose}</>
                )}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{
                  ...DrugDMConst.styles.drugMealSummary,
                  backgroundColor: "#fceac7",
                }}
              >
                {item.afternoon?.dose && item.afternoon?.meal ? (
                  <Label
                    circular
                    color={item.afternoon?.meal === "before" ? "blue" : "red"}
                  >
                    {item.afternoon?.dose}
                  </Label>
                ) : (
                  <>{item.afternoon?.dose}</>
                )}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{
                  ...DrugDMConst.styles.drugMealSummary,
                  backgroundColor: "#fcc3c5",
                }}
              >
                {item.evening?.dose && item.evening?.meal ? (
                  <Label
                    circular
                    color={item.evening?.meal === "before" ? "blue" : "red"}
                  >
                    {item.evening?.dose}
                  </Label>
                ) : (
                  <>{item.evening?.dose}</>
                )}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{
                  ...DrugDMConst.styles.drugMealSummary,
                  backgroundColor: "#dfdfdf",
                }}
              >
                {item.bedtime?.dose && item.bedtime?.meal ? (
                  <Label
                    circular
                    color={item.bedtime?.meal === "before" ? "blue" : "red"}
                  >
                    {item.bedtime?.dose}
                  </Label>
                ) : (
                  <>{item.bedtime?.dose}</>
                )}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{
                  ...DrugDMConst.styles.drugMealSummary,
                  backgroundColor: "#ffa4c4",
                }}
              >
                {item.others?.dose && item.others?.meal ? (
                  <Label
                    circular
                    color={item.others?.meal === "before" ? "blue" : "red"}
                  >
                    {item.others?.dose}
                  </Label>
                ) : (
                  <>{item.others?.dose}</>
                )}
              </Grid.Column>
            </Grid.Row>
          );
        })}

        {props.drugDataTabSummary?.items?.length === 0 && (
          <Grid.Column
            width={16}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              fontSize: "16px",
            }}
          >
            {intl.formatMessage({id: "bplusClinicKey640"})}
          </Grid.Column>
        )}

        <Grid.Column width={2}></Grid.Column>
        {/* <Grid.Column width={12}>
          <Button
            basic
            fluid
            color="blue"
            style={{ marginTop: "20px" }}
            onClick={props.onShowDrugManually}
          >
            <Icon name="plus" />
            <FormattedMessage id="bplusClinicKey42" />
          </Button>
        </Grid.Column> */}
        <Grid.Column width={2}></Grid.Column>
      </Grid>
    </>
  );
};

DMDrugTabSummary.defaultProps = DMDrugTabSummaryInitial;

export default React.memo(DMDrugTabSummary);
