import { WasmHandler } from "react-lib/frameworks/WasmController";

import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
} from "../MobClinicInterface";
import { formatUTCtoMoment } from "../../react-lib/utils/dateUtils";

export type State = {
  selectedPatient?: any;
  loadingGrowth?: boolean;
  childGrowDetail?: any;
  growthHistoryList?: any;
  successMessage?: any;
  errorMessage?: any;
};

export const StateInitial = {
  loadingGrowth: false,
  childGrowDetail: {},
  growthHistoryList: {},
};

export type Event =
  // Save
  | { message: "handleSaveGrowthDetail"; params: { weigth?: any; height?: any; head?: any; selectedDate?: any; } }
  //  Delete
  | { message: "handleDeleteGrowthHistory"; params: { id?: any } }
  // GET
  | { message: "handleGetGrowthDetail"; params: { selectedDate?: any } }
  | { message: "getGrowthHistoryList"; params: {} }
  | { message: "handleGetGrowthDetail"; params: { selectedDate: string; } }
  | { message: "getGrowthHistoryList"; params: {} }
  // Update
  | { message: "handleUpdateChildGrowth"; params: {} }

type Handler = WasmHandler<State, Event>;

export const handleGetGrowthDetail: Handler = async (controller, params) => {
  controller.setState({ loadingGrowth: true })
  const state = controller.getState()
  let errorMessage = null
  let childGrowResult = {
    weight: 0,
    height: 0,
    head: 0
  }

  let filterData = {
    group: GROUP.GROWTH,
    data_type: DATA_TYPE.GROWTH,
    start_measure: moment(params.selectedDate).format(BACKEND_DATE_FORMAT),
    end_measure: moment(params.selectedDate).format(BACKEND_DATE_FORMAT)
  }

  const [error, response] = await commonListPatientDataList(controller as any, filterData)

  if (error) {
    errorMessage = error
  } else {
    if (response?.items?.length > 0) {
      let latestData = response.items[response.items.length - 1]
      childGrowResult = {
        id: latestData.id,
        measure_date: latestData.measure_date,
        date: formatUTCtoMoment(latestData.measure_date).locale("en").format("D MMMM YYYY"),
        proxy_patient: latestData.proxy_patient,
        ...latestData.data
      }
    }
  }

  console.log("childGrowResult Patient data: ", state.selectedPatient)
  controller.setState({
    childGrowDetail: { ...childGrowResult },
    loadingGrowth: false,
    errorMessage
  })
}


export const getGrowthHistoryList: Handler = async (controller, params) => {
  controller.setState({ loadingGrowth: true })
  const state = controller.getState()
  let data = []
  let dateSetData: any[] = [];
  const filterData = {
    group: GROUP.GROWTH,
    data_type: DATA_TYPE.GROWTH
  }

  let errorMessage: any = null
  const [error, response] = await commonListPatientDataList(controller as any, filterData)

  if (error) {
    errorMessage = error
  } else {
    if (response?.items?.length > 0) {
      data = response.items.map((item: any) => {
        return {
          id: item.id,
          measure_date: item.measure_date,
          date: formatUTCtoMoment(item.measure_date).locale('en').format("D MMMM YYYY"),
          proxy_patient: item.proxy_patient,
          ...item.data,
          rawData: item
        }
      })
      console.log("data", data)
      console.log("data", state.selectedPatient)
    }
    if (state.selectedPatient?.birthdate) {
      let startDate: moment.Moment = moment(
        state.selectedPatient?.birthdate,
        BACKEND_DATE_FORMAT
      );
      for (let n = 0; n < data.length; n++) {
        let dataDate = moment(moment(data[n].date)).format(BACKEND_DATE_FORMAT);
        let today: moment.Moment = moment(dataDate, BACKEND_DATE_FORMAT);
        const diffDate = today.diff(startDate);
        const duration = moment.duration(diffDate);
        console.log("duration.years()", duration.years())
        if (duration.years() > 0) {
          const age = duration.months();
          const ageYear = duration.years();
          let ageSum = (ageYear * 12) + age
          dateSetData.push(ageSum)
        } else {
          const age = duration.months();
          dateSetData.push(age)
        }

      }
      // setAgeData(dateSetData)
      console.log("ageData", dateSetData)
    }
  }

  controller.setState({
    loadingGrowth: false,
    growthHistoryList: {
      items: [...data],
      dateSetData: dateSetData
    }
  })
}

export const handleSaveGrowthDetail: Handler = async (controller, params) => {
  controller.setState({ loadingGrowth: true })

  const state = controller.getState();
  let detail = { ...state.childGrowDetail, ...params };
  let errorMessage: any = ""
  let successMessage: any = ""

  if (state.childGrowDetail.id) {
    let growthData = {
      id: state.childGrowDetail.id,
      group: GROUP.GROWTH,
      data_type: DATA_TYPE.GROWTH,
      proxy_patient: state.childGrowDetail.proxy_patient,
      measure_date: state.childGrowDetail.measure_date,
      data: {
        ...detail,
      },
    };
    delete growthData.data["date"];
    delete growthData.data["proxy_patient"];
    delete growthData.data["measure_date"];
    delete growthData.data["id"];

    const [error, response] = await commonUpdatePatientData(
      controller as any,
      growthData
    );

    if (error) {
      errorMessage = error;
    } else {
      successMessage = `Save success.`;
    }
  } else {
    let growthData = {
      group: GROUP.GROWTH,
      data_type: DATA_TYPE.GROWTH,
      measure_date: moment(params.selectedDate).format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: {
        ...detail,
      },
    };

    delete growthData.data["date"];

    const [error, response] = await commonCreatePatientData(
      controller as any,
      growthData
    );
    if (error) {
      errorMessage = error;
    } else {
      detail = {
        ...detail,
        id: response.id,
        measure_date: response.measure_date,
        proxy_patient: response.proxy_patient,
      };
      successMessage = `Save success.`;
    }
  }
  controller.setState({
    loadingGrowth: false,
    successMessage: successMessage,
    errorMessage: errorMessage,
    childGrowDetail: { ...detail }
  })

  await getGrowthHistoryList(controller, params)
};

export const handleDeleteGrowthHistory: Handler = async (controller, params) => {
  controller.setState({ loadingGrowth: true })
  let errorMessage: any = null;
  let successMessage: any = null;

  if (params.id) {
    const [error, response] = await commonDeletePatientData(controller as any, {
      id: params.id,
    });

    if (error) {
      errorMessage = error
    } else {
      successMessage = "Delete data complete"
    }
  } else {
    errorMessage = "No select id"
  }

  controller.setState({
    errorMessage,
    successMessage
  })

  await getGrowthHistoryList(controller, params)
};

export const handleUpdateChildGrowth: Handler = async (controller, params) => {
  controller.setState({ loadingGrowth: true })

  let errorMessage: any = null;
  let successMessage: any = null;

  const [error, response] = await commonUpdatePatientData(controller as any, params.data);

  if (error) {
    errorMessage = error
  } else {
    successMessage = "Update data successs"
    getGrowthHistoryList(controller, {})
  }

  controller.setState({
    errorMessage,
    successMessage,
    // loadingGrowth: false
  })
}

export type AgeHeadDataRange = keyof (typeof ageHeadData)["boy"]

export const ageHeadData = {
  "boy": {
    0: { "less_than": 32.1, "more_than": 36.9 },
    1: { "less_than": 35.1, "more_than": 39.5 },
    2: { "less_than": 36.9, "more_than": 41.3 },
    3: { "less_than": 38.3, "more_than": 42.7 },
    4: { "less_than": 39.4, "more_than": 43.9 },
    5: { "less_than": 40.3, "more_than": 44.8 },
    6: { "less_than": 41.0, "more_than": 45.6 },
    7: { "less_than": 41.7, "more_than": 46.3 },
    8: { "less_than": 42.2, "more_than": 46.9 },
    9: { "less_than": 42.6, "more_than": 47.4 },
    10: { "less_than": 43.0, "more_than": 47.8 },
    11: { "less_than": 43.4, "more_than": 48.2 },
    12: { "less_than": 43.6, "more_than": 48.5 },
    13: { "less_than": 43.9, "more_than": 48.8 },
    14: { "less_than": 44.1, "more_than": 49.0 },
    15: { "less_than": 44.3, "more_than": 49.3 },
    16: { "less_than": 44.5, "more_than": 49.5 },
    17: { "less_than": 44.7, "more_than": 49.7 },
    18: { "less_than": 44.9, "more_than": 49.9 },
    19: { "less_than": 45.0, "more_than": 50.0 },
    20: { "less_than": 45.2, "more_than": 50.2 },
    21: { "less_than": 45.3, "more_than": 50.4 },
    22: { "less_than": 45.4, "more_than": 50.5 },
    23: { "less_than": 45.6, "more_than": 50.7 },
    24: { "less_than": 45.7, "more_than": 50.8 },
    25: { "less_than": 45.8, "more_than": 50.9 },
    26: { "less_than": 45.9, "more_than": 51.1 },
    27: { "less_than": 46.0, "more_than": 51.2 },
    28: { "less_than": 46.1, "more_than": 51.3 },
    29: { "less_than": 46.2, "more_than": 51.4 },
    30: { "less_than": 46.3, "more_than": 51.6 },
    31: { "less_than": 46.4, "more_than": 51.7 },
    32: { "less_than": 46.5, "more_than": 51.8 },
    33: { "less_than": 46.6, "more_than": 51.9 },
    34: { "less_than": 46.6, "more_than": 52.0 },
    35: { "less_than": 46.7, "more_than": 52.0 },
    36: { "less_than": 46.8, "more_than": 52.1 },
    37: { "less_than": 46.9, "more_than": 52.2 },
    38: { "less_than": 46.9, "more_than": 52.3 },
    39: { "less_than": 47.0, "more_than": 52.4 },
    40: { "less_than": 47.0, "more_than": 52.4 },
    41: { "less_than": 47.1, "more_than": 52.5 },
    42: { "less_than": 47.2, "more_than": 52.6 },
    43: { "less_than": 47.2, "more_than": 52.7 },
    44: { "less_than": 47.3, "more_than": 52.7 },
    45: { "less_than": 47.3, "more_than": 52.8 },
    46: { "less_than": 47.4, "more_than": 52.8 },
    47: { "less_than": 47.4, "more_than": 52.9 },
    48: { "less_than": 47.5, "more_than": 52.9 },
    49: { "less_than": 47.5, "more_than": 53.0 },
    50: { "less_than": 47.5, "more_than": 53.1 },
    51: { "less_than": 47.6, "more_than": 53.1 },
    52: { "less_than": 47.6, "more_than": 53.2 },
    53: { "less_than": 47.7, "more_than": 53.2 },
    54: { "less_than": 47.7, "more_than": 53.3 },
    55: { "less_than": 47.7, "more_than": 53.3 },
    56: { "less_than": 47.8, "more_than": 53.4 },
    57: { "less_than": 47.8, "more_than": 53.4 },
    58: { "less_than": 47.9, "more_than": 53.5 },
    59: { "less_than": 47.9, "more_than": 53.5 },
    60: { "less_than": 47.9, "more_than": 53.5 },
  },
  "girl": {
    0: { "less_than": 31.7, "more_than": 36.1 },
    1: { "less_than": 34.3, "more_than": 36.8 },
    2: { "less_than": 36.0, "more_than": 40.5 },
    3: { "less_than": 37.2, "more_than": 41.9 },
    4: { "less_than": 38.2, "more_than": 43.0 },
    5: { "less_than": 39.0, "more_than": 43.9 },
    6: { "less_than": 39.7, "more_than": 44.6 },
    7: { "less_than": 40.4, "more_than": 45.3 },
    8: { "less_than": 40.9, "more_than": 45.9 },
    9: { "less_than": 41.3, "more_than": 46.3 },
    10: { "less_than": 41.7, "more_than": 46.8 },
    11: { "less_than": 42.0, "more_than": 47.1 },
    12: { "less_than": 42.3, "more_than": 47.5 },
    13: { "less_than": 42.6, "more_than": 47.7 },
    14: { "less_than": 42.9, "more_than": 48.0 },
    15: { "less_than": 43.1, "more_than": 48.2 },
    16: { "less_than": 43.3, "more_than": 48.5 },
    17: { "less_than": 43.5, "more_than": 48.7 },
    18: { "less_than": 43.6, "more_than": 48.8 },
    19: { "less_than": 43.8, "more_than": 49.0 },
    20: { "less_than": 44.0, "more_than": 49.2 },
    21: { "less_than": 44.1, "more_than": 49.4 },
    22: { "less_than": 44.3, "more_than": 49.5 },
    23: { "less_than": 44.4, "more_than": 49.7 },
    24: { "less_than": 44.6, "more_than": 49.8 },
    25: { "less_than": 44.7, "more_than": 49.9 },
    26: { "less_than": 44.8, "more_than": 50.1 },
    27: { "less_than": 44.9, "more_than": 50.2 },
    28: { "less_than": 45.1, "more_than": 50.3 },
    29: { "less_than": 45.2, "more_than": 50.5 },
    30: { "less_than": 45.3, "more_than": 50.6 },
    31: { "less_than": 45.4, "more_than": 50.7 },
    32: { "less_than": 45.5, "more_than": 50.8 },
    33: { "less_than": 45.6, "more_than": 50.9 },
    34: { "less_than": 45.7, "more_than": 51.0 },
    35: { "less_than": 45.8, "more_than": 51.1 },
    36: { "less_than": 45.9, "more_than": 51.2 },
    37: { "less_than": 45.9, "more_than": 51.3 },
    38: { "less_than": 46.0, "more_than": 51.3 },
    39: { "less_than": 46.1, "more_than": 51.4 },
    40: { "less_than": 46.2, "more_than": 51.5 },
    41: { "less_than": 46.2, "more_than": 51.6 },
    42: { "less_than": 46.3, "more_than": 51.6 },
    43: { "less_than": 46.4, "more_than": 51.7 },
    44: { "less_than": 46.4, "more_than": 51.8 },
    45: { "less_than": 46.5, "more_than": 51.8 },
    46: { "less_than": 46.5, "more_than": 51.9 },
    47: { "less_than": 46.6, "more_than": 51.9 },
    48: { "less_than": 46.7, "more_than": 52.0 },
    49: { "less_than": 46.7, "more_than": 52.1 },
    50: { "less_than": 46.8, "more_than": 52.1 },
    51: { "less_than": 46.8, "more_than": 52.2 },
    52: { "less_than": 46.9, "more_than": 52.2 },
    53: { "less_than": 46.9, "more_than": 52.3 },
    54: { "less_than": 47.0, "more_than": 52.3 },
    55: { "less_than": 47.0, "more_than": 52.4 },
    56: { "less_than": 47.1, "more_than": 52.4 },
    57: { "less_than": 47.1, "more_than": 52.5 },
    58: { "less_than": 47.2, "more_than": 52.5 },
    59: { "less_than": 47.2, "more_than": 52.6 },
    60: { "less_than": 47.2, "more_than": 52.6 },
  }
}

// only compare with less than
export const ageHeightWeight = {
  "boy": {
    0: { height: [46.1, 47.0, 52.8, 53.8], weight: [2.5, 2.7, 4.2, 4.5] },
    1: { height: [50.8, 51.7, 57.7, 58.7], weight: [3.4, 3.6, 5.5, 5.9] },
    2: { height: [54.4, 55.4, 61.5, 62.5], weight: [4.3, 4.6, 6.8, 7.2] },
    3: { height: [57.3, 58.3, 64.6, 65.6], weight: [5.0, 5.3, 7.7, 8.1] },
    4: { height: [59.7, 60.8, 67.1, 68.1], weight: [5.6, 5.9, 8.4, 8.8] },
    5: { height: [61.7, 62.7, 69.2, 70.2], weight: [6.0, 6.3, 8.9, 9.4] },
    6: { height: [63.3, 64.4, 70.9, 72.0], weight: [6.4, 6.7, 9.4, 9.9] },
    7: { height: [64.8, 65.9, 72.5, 73.6], weight: [6.7, 7.0, 9.9, 10.4] },
    8: { height: [66.2, 67.3, 74.0, 75.1], weight: [6.9, 7.3, 10.2, 10.8] },
    9: { height: [67.5, 68.6, 75.4, 76.6], weight: [7.1, 7.6, 10.6, 11.1] },
    10: { height: [68.7, 69.8, 76.8, 78.0], weight: [7.4, 7.8, 10.9, 11.5] },
    11: { height: [69.9, 71.1, 78.1, 79.3], weight: [7.6, 8.0, 11.2, 11.8] },

    12: { height: [71.0, 72.2, 79.4, 80.6], weight: [7.7, 8.2, 11.5, 12.1] },
    13: { height: [72.1, 73.3, 80.7, 81.9], weight: [7.9, 8.4, 11.7, 12.4] },
    14: { height: [73.1, 74.3, 81.9, 83.1], weight: [8.1, 8.6, 12.0, 12.7] },
    15: { height: [74.1, 75.3, 83.0, 84.3], weight: [8.3, 8.7, 12.3, 12.9] },
    16: { height: [75.0, 76.3, 84.2, 85.5], weight: [8.4, 8.9, 12.5, 13.2] },
    17: { height: [76.0, 77.3, 85.3, 86.6], weight: [8.6, 9.1, 12.8, 13.5] },
    18: { height: [76.9, 78.2, 86.4, 87.8], weight: [8.8, 9.3, 13.0, 13.8] },
    19: { height: [77.7, 79.1, 87.5, 88.9], weight: [8.9, 9.4, 13.3, 14.0] },
    20: { height: [78.6, 80.0, 88.5, 89.9], weight: [9.1, 9.6, 13.5, 14.3] },
    21: { height: [79.4, 80.8, 89.5, 91.0], weight: [9.2, 9.8, 13.8, 14.6] },
    22: { height: [80.2, 81.6, 90.5, 92.0], weight: [9.4, 9.9, 14.0, 14.8] },
    23: { height: [81.0, 82.4, 91.5, 93.0], weight: [9.5, 10.1, 14.3, 15.1] },
    24: { height: [81.7, 83.2, 92.5, 94.0], weight: [9.7, 10.2, 14.5, 15.4] },

    25: { height: [81.7, 83.3, 92.7, 94.3], weight: [9.8, 10.4, 14.8, 15.6] },
    26: { height: [82.5, 84.0, 93.7, 95.3], weight: [10.0, 10.6, 15.0, 15.9] },
    27: { height: [83.1, 84.7, 94.5, 96.2], weight: [10.1, 10.7, 15.3, 16.2] },
    28: { height: [83.8, 85.5, 95.4, 97.1], weight: [10.2, 10.9, 15.5, 16.4] },
    29: { height: [84.5, 86.2, 96.3, 98.0], weight: [10.4, 11.0, 15.8, 16.7] },
    30: { height: [85.1, 86.8, 97.1, 98.8], weight: [10.5, 11.2, 16.0, 17.0] },
    31: { height: [85.7, 87.5, 97.9, 99.7], weight: [10.7, 11.3, 16.2, 17.2] },
    32: { height: [86.4, 88.1, 98.7, 100.5], weight: [10.8, 11.4, 16.4, 17.5] },
    33: { height: [86.9, 88.7, 99.5, 101.3], weight: [10.9, 11.6, 16.7, 17.7] },
    34: { height: [87.5, 89.3, 100.3, 102.1], weight: [11.0, 11.7, 16.9, 17.9] },
    35: { height: [88.1, 89.9, 101.0, 102.8], weight: [11.2, 11.8, 17.1, 18.2] },

    36: { height: [88.7, 90.5, 101.8, 103.6], weight: [11.3, 12.0, 17.3, 18.4] },
    37: { height: [89.2, 91.1, 102.5, 104.3], weight: [11.4, 12.1, 17.5, 18.7] },
    38: { height: [89.8, 91.7, 103.2, 105.1], weight: [11.5, 12.2, 17.8, 18.9] },
    39: { height: [90.3, 92.2, 103.9, 105.8], weight: [11.6, 12.4, 18.0, 19.1] },
    40: { height: [90.9, 92.8, 104.5, 106.5], weight: [11.8, 12.5, 18.2, 19.4] },
    41: { height: [91.4, 93.3, 105.2, 107.2], weight: [11.9, 12.6, 18.4, 19.6] },
    42: { height: [91.9, 93.9, 105.9, 107.9], weight: [12.0, 12.8, 18.6, 19.8] },
    43: { height: [92.4, 94.4, 106.5, 108.6], weight: [12.1, 12.9, 18.8, 20.1] },
    44: { height: [93.0, 95.0, 107.2, 109.2], weight: [12.2, 13.0, 19.1, 20.3] },
    45: { height: [93.5, 95.5, 107.8, 109.9], weight: [12.4, 13.1, 19.3, 20.6] },
    46: { height: [94.0, 96.0, 108.5, 110.5], weight: [12.5, 13.3, 19.5, 20.8] },
    47: { height: [94.4, 96.5, 109.1, 111.2], weight: [12.6, 13.4, 19.7, 21.0] },

    48: { height: [94.9, 97.0, 109.7, 111.8], weight: [12.7, 13.5, 19.9, 21.3] },
    49: { height: [95.4, 97.6, 110.3, 112.5], weight: [12.8, 13.7, 20.2, 21.5] },
    50: { height: [95.9, 98.0, 111.0, 113.1], weight: [12.9, 13.8, 20.4, 21.8] },
    51: { height: [96.4, 98.5, 111.5, 113.7], weight: [13.1, 13.9, 20.6, 22.0] },
    52: { height: [96.9, 99.1, 112.2, 114.3], weight: [13.2, 14.0, 20.8, 22.3] },
    53: { height: [97.4, 99.5, 112.8, 115.0], weight: [13.3, 14.2, 21.0, 22.5] },
    54: { height: [97.8, 100.1, 113.4, 115.6], weight: [13.4, 14.3, 21.3, 22.8] },
    55: { height: [98.3, 100.5, 114.0, 116.2], weight: [13.5, 14.4, 21.5, 23.0] },
    56: { height: [98.8, 101.0, 114.6, 116.8], weight: [13.6, 14.5, 21.7, 23.3] },
    57: { height: [99.3, 101.5, 115.2, 117.5], weight: [13.7, 14.6, 21.9, 23.5] },
    58: { height: [99.7, 102.0, 115.8, 118.1], weight: [13.8, 14.8, 22.2, 23.8] },
    59: { height: [100.2, 102.5, 116.4, 118.7], weight: [14.0, 14.9, 22.4, 24.0] },
    60: { height: [100.7, 103.0, 117.0, 119.3], weight: [14.1, 15.0, 22.6, 24.3] },
  },
  "girl": {
    0: { height: [45.4, 46.4, 52.0, 53.0], weight: [2.4, 2.6, 4.1, 4.3] },
    1: { height: [49.8, 50.7, 56.7, 57.7], weight: [3.2, 3.4, 5.2, 5.6] },
    2: { height: [53.0, 54.0, 60.2, 61.2], weight: [3.9, 4.2, 6.3, 6.7] },
    3: { height: [55.6, 56.6, 63.0, 64.1], weight: [4.5, 4.8, 7.2, 7.6] },
    4: { height: [57.8, 58.9, 65.5, 66.5], weight: [5.0, 5.3, 7.8, 8.3] },
    5: { height: [59.6, 60.7, 67.4, 68.6], weight: [5.4, 5.7, 8.4, 8.9] },
    6: { height: [61.2, 62.3, 69.2, 70.4], weight: [5.7, 6.1, 8.9, 9.4] },
    7: { height: [62.7, 63.8, 70.9, 72.0], weight: [6.0, 6.4, 9.3, 9.9] },
    8: { height: [64.0, 65.2, 72.4, 73.6], weight: [6.3, 6.6, 9.7, 10.3] },
    9: { height: [65.3, 66.5, 73.9, 75.1], weight: [6.5, 6.9, 10.0, 10.6] },
    10: { height: [66.5, 67.8, 75.3, 76.5], weight: [6.7, 7.1, 10.3, 11.0] },
    11: { height: [67.7, 69.0, 76.7, 77.9], weight: [6.9, 7.3, 10.6, 11.3] },

    12: { height: [68.9, 70.1, 78.0, 79.3], weight: [7.0, 7.4, 10.8, 11.6] },
    13: { height: [70.0, 71.3, 79.3, 80.6], weight: [7.2, 7.7, 11.2, 11.9] },
    14: { height: [71.0, 72.4, 80.5, 81.8], weight: [7.4, 7.8, 11.4, 12.2] },
    15: { height: [72.0, 73.4, 81.7, 83.1], weight: [7.6, 8.0, 11.7, 12.5] },
    16: { height: [73.0, 74.4, 82.9, 84.3], weight: [7.7, 8.2, 12.0, 12.7] },
    17: { height: [74.0, 75.4, 84.0, 85.5], weight: [7.9, 8.4, 12.2, 13.0] },
    18: { height: [74.9, 76.4, 85.2, 86.6], weight: [8.1, 8.5, 12.5, 13.3] },
    19: { height: [75.8, 77.3, 86.2, 87.7], weight: [8.2, 8.7, 12.7, 13.6] },
    20: { height: [76.7, 78.2, 87.3, 88.8], weight: [8.4, 8.9, 13.0, 13.8] },
    21: { height: [77.5, 79.1, 88.4, 89.9], weight: [8.6, 9.1, 13.2, 14.1] },
    22: { height: [78.4, 79.9, 89.4, 90.9], weight: [8.7, 9.2, 13.5, 14.4] },
    23: { height: [79.2, 80.8, 90.4, 92.0], weight: [8.9, 9.4, 13.7, 14.7] },
    24: { height: [80.0, 81.6, 91.3, 93.0], weight: [9.0, 9.6, 14.0, 14.9] },

    25: { height: [80.0, 81.7, 91.6, 93.2], weight: [9.2, 9.8, 14.3, 15.2] },
    26: { height: [80.8, 82.4, 92.5, 94.2], weight: [9.4, 9.9, 14.5, 15.5] },
    27: { height: [81.5, 83.2, 93.4, 95.1], weight: [9.5, 10.1, 14.8, 15.8] },
    28: { height: [82.2, 83.9, 94.3, 96.1], weight: [9.7, 10.3, 15.0, 16.1] },
    29: { height: [82.9, 84.7, 95.2, 97.0], weight: [9.8, 10.4, 15.3, 16.3] },
    30: { height: [83.6, 85.4, 96.1, 97.8], weight: [10.0, 10.6, 15.5, 16.6] },
    31: { height: [84.3, 86.1, 96.9, 98.7], weight: [10.1, 10.7, 15.8, 16.9] },
    32: { height: [84.9, 86.8, 97.7, 99.5], weight: [10.3, 10.9, 16.0, 17.2] },
    33: { height: [85.6, 87.4, 98.5, 100.3], weight: [10.4, 11.0, 16.3, 17.4] },
    34: { height: [86.2, 88.1, 99.3, 101.2], weight: [10.5, 11.2, 16.5, 17.7] },
    35: { height: [86.8, 88.7, 100.1, 102.0], weight: [10.7, 11.3, 16.8, 18.0] },

    36: { height: [87.4, 89.3, 100.9, 102.8], weight: [10.8, 11.5, 17.0, 18.2] },
    37: { height: [88.0, 90.0, 101.6, 103.5], weight: [10.9, 11.6, 17.3, 18.5] },
    38: { height: [88.6, 90.6, 102.3, 104.3], weight: [11.1, 11.8, 17.5, 18.8] },
    39: { height: [89.2, 91.2, 103.1, 105.1], weight: [11.2, 11.9, 17.8, 19.1] },
    40: { height: [89.8, 91.8, 103.8, 105.8], weight: [11.3, 12.0, 18.0, 19.3] },
    41: { height: [90.4, 92.3, 104.5, 106.5], weight: [11.5, 12.2, 18.3, 19.6] },
    42: { height: [90.9, 92.9, 105.2, 107.3], weight: [11.6, 12.3, 18.5, 19.9] },
    43: { height: [91.5, 93.5, 105.9, 108.0], weight: [11.7, 12.5, 18.8, 20.2] },
    44: { height: [92.0, 94.1, 106.6, 108.7], weight: [11.8, 12.6, 19.0, 20.5] },
    45: { height: [92.5, 94.6, 107.3, 109.4], weight: [12.0, 12.7, 19.3, 20.8] },
    46: { height: [93.1, 95.2, 108.0, 110.1], weight: [12.1, 12.9, 19.5, 21.0] },
    47: { height: [93.6, 95.7, 108.6, 110.8], weight: [12.2, 13.0, 19.8, 21.3] },

    48: { height: [94.1, 96.3, 109.3, 111.4], weight: [12.3, 13.1, 20.0, 21.6] },
    49: { height: [94.6, 96.8, 110.0, 112.1], weight: [12.4, 13.3, 20.3, 21.9] },
    50: { height: [95.1, 97.3, 110.6, 112.8], weight: [12.6, 13.4, 20.5, 22.2] },
    51: { height: [95.6, 97.8, 111.2, 113.4], weight: [12.7, 13.5, 20.8, 22.5] },
    52: { height: [96.1, 98.4, 111.8, 114.1], weight: [12.8, 13.7, 21.0, 22.7] },
    53: { height: [96.6, 98.9, 112.5, 114.7], weight: [12.9, 13.8, 21.3, 23.0] },
    54: { height: [97.1, 99.4, 113.1, 115.3], weight: [13.0, 13.9, 21.6, 23.3] },
    55: { height: [97.6, 99.9, 113.7, 116.0], weight: [13.2, 14.1, 21.8, 23.6] },
    56: { height: [98.1, 100.4, 114.3, 116.6], weight: [13.3, 14.2, 22.1, 23.9] },
    57: { height: [98.5, 100.8, 114.9, 117.2], weight: [13.4, 14.3, 22.3, 24.2] },
    58: { height: [99.0, 101.3, 115.5, 117.8], weight: [13.5, 14.5, 22.6, 24.5] },
    59: { height: [99.5, 101.8, 116.1, 118.4], weight: [13.6, 14.6, 22.8, 24.7] },
    60: { height: [99.9, 102.3, 116.7, 119.0], weight: [13.7, 14.7, 23.1, 25.0] },
  }
}

export const heightStatus = [
  { color: "#eb8427", status: "ส่วนสูงต่ำกว่าเกณฑ์มาก", score: 20 },
  { color: "#a2f39a", status: "ส่วนสูงต่ำกว่าเกณฑ์", score: 40 },
  { color: "#81c37b", status: "ส่วนสูงตามเกณฑ์", score: 100 },
  { color: "#44783f", status: "ค่อนข้างสูง", score: 60 },
  { color: "#ffffff", status: "สูง", score: 80 }
];

export const weightStatus = [
  { color: "#eb8427", status: "น้ำหนักน้อยกว่าเกณฑ์", score: 60 },
  { color: "#a2f39a", status: "น้ำหนักค่อนข้างน้อย", score: 80 },
  { color: "#81c37b", status: "น้ำหนักตามเกณฑ์", score: 100 },
  { color: "#44783f", status: "น้ำหนักค่อนข้างมาก", score: 40 },
  { color: "#ffffff", status: "น้ำหนักมากกว่าเกณฑ์", score: 20 }
];

export const headStatus = [
  { score: 100, status: "ตามเกณฑ์" },
  { score: 50, status: "ควรปรึกษาแพทย์" }
]