import React, { useState, useEffect } from "react";
// CSS
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// ICON
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import CircularProgress from "@mui/material/CircularProgress";

type PostSearchBoxProps = {
  // data
  label: string;
  initialValue?: string;
  textField: string;
  // callback
  onGetSearchOptions?: (params: { searchText: string }) => any;
  onSelectOption?: (item: any) => any;
};

/* TODO --------------------- SearchBox --------------------- */
const PostSearchBox: React.FC<PostSearchBoxProps> =
  React.memo<PostSearchBoxProps>((props) => {
    const [inputSearch, setInputSearch] = useState<any>(null);
    const [typingTimeout, setTypingTimeout] = useState<any>(null);
    const [options, setOptions] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
      if (props.initialValue) {
        setInputSearch(props.initialValue);
      }
    }, []);

    useEffect(() => {
      if (!inputSearch) {
        setOptions([]);
      }
    }, [inputSearch]);

    const handleSearch = (e: any) => {
      const value = e.target.value;
      setInputSearch(value);

      let search = options.find((items) => items?.[props.textField] === value);
      if (!search) {
        props.onSelectOption?.(null);
      }

      if (!value) {
        return;
      }

      if (typingTimeout) {
        clearTimeout(typingTimeout as any);
      }
      setTypingTimeout(
        setTimeout(() => {
          getDataList(value);
        }, 400)
      );
    };

    const getDataList = async (search: string, id: any = null) => {
      setIsLoading(true);
      const [res, error] = await props.onGetSearchOptions?.({
        searchText: search,
      });

      let shortOptions = (res.items || [])?.slice(0, 10);
      setOptions(shortOptions);
      setIsLoading(false);
    };

    const handleSelectItem = (item: any) => {
      props.onSelectOption?.(item);
      setInputSearch(item?.[props.textField] || null);
      setOptions([]);
    };

    return (
      <>
        <Autocomplete
          className="PostSearchBox-root"
          freeSolo
          id="free-solo"
          value={inputSearch}
          inputValue={inputSearch || ""}
          onChange={(event, newValue) => {
            handleSelectItem(newValue);
          }}
          popupIcon={false}
          disableClearable={!inputSearch || isLoading}
          filterSelectedOptions
          options={options}
          getOptionLabel={(option) => option?.[props.textField] || ""}
          renderInput={(params) => (
            <div style={{ position: "relative" }}>
              <TextField
                {...params}
                label={props.label}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleSearch}
              />
              {!inputSearch && !isLoading && (
                <IconButton
                  aria-label="toggle visibility"
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  style={{ position: "absolute", right: "2px", top: "14px" }}
                >
                  <SearchIcon />
                </IconButton>
              )}
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{
                    position: "absolute",
                    right: "0.76rem",
                    top: "22px",
                  }}
                />
              )}
            </div>
          )}
        />
      </>
    );
  });

PostSearchBox.defaultProps = {
  label: "",
  textField: "name",
} as PostSearchBoxProps;

export default PostSearchBox;
