import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";

import * as ClinicInterface from "./native/ClinicInterface";

// DM
import * as DMBloodPressureI from "./clinic/DMBloodPressureInterface";
import * as DMDrugI from "./clinic/DMDrugInterface";
import * as DMEmotionRestI from "./clinic/DMEmotionRestInterface";
import * as DMPersonalScoreI from "./clinic/DMPersonalScoreInterface";
import * as DMWeightI from "./clinic/DMWeightInterface";
import * as DMExerciseI from "./clinic/DMExerciseInterface";
import * as DMFootI from "./clinic/DMFootInterface";
import * as DMBloodGlucoseI from "./clinic/DMBloodGlucoseInterface";
import * as DMFoodI from "./clinic/DMFoodInterface";
import * as VaccineDMI from "./clinic/DMVaccineInterface";
// Mom
import * as MomBreastFeedingI from "./clinic/MomBreastFeedingInterface";
import * as MomProfileHealthI from "./clinic/MomProfileHealthInterface";
import * as MomKickCountI from "./clinic/MomKickCountInterface";
import * as MomContractionCountI from "./clinic/MomContractionCountInterface";
import * as MomUltrasoundI from "./clinic/MomUltrasoundInterface";
import * as MomAntenatalHistoryI from "./clinic/MomAntenatalHistoryInterface";
import * as MomWeightGainI from "./clinic/MomWeightGainInterface";
// Child
import * as ChildBirthRecordI from "./clinic/ChildBirthRecordInterface";
import * as ChildOutsideMedicineI from "./clinic/ChildOutsideMedicineInterface";
import * as ChildDevelopmentI from "./clinic/ChildDevelopmentInterface";
import * as ChildGrowthI from "./clinic/ChildGrowthInterface";
import * as ChildOverallI from "./clinic/ChildOverallInterface";
import * as ChildVaccineI from "./clinic/ChildVaccineInterface";

// Mom & Child
import * as MomChildLabResultI from "./clinic/MomChildLabResultInterface";
// Other
import * as LoginI from "./login/LoginInterface";
import * as FeedI from "./feed/FeedI";
import * as FirebaseI from "../react-lib/frameworks/FirebaseInterface";
import * as NearbyI from "./feed/NearbyI";
import * as PostI from "./post/PostI";

// APIs
import AgeRangeList from "issara-sdk/apis/AgeRangeList_apps_PHR";
import PatientDataDetail from "../issara-sdk/apis/PatientDataDetail_apps_PHR";
import PatientDataGroupList from "../issara-sdk/apis/PatientDataGroupList_apps_PHR";
import PatientDataLatest from "../issara-sdk/apis/PatientDataLatest_apps_PHR";
import PatientDataList from "../issara-sdk/apis/PatientDataList_apps_PHR";
import PatientDataDetailM from "../issara-sdk/apis/PatientDataDetail_apps_PHRM";
import PatientDocumentDataList from "../issara-sdk/apis/PatientDocumentDataList_apps_PHRM";
import PatientDocumentDataDetailM from "../issara-sdk/apis/PatientDocumentDataDetail_apps_PHRM";
import ProxyPatientHasDivisionFamilyList from "../issara-sdk/apis/ProxyPatientHasDivisionFamilyList_apps_PRX";
import ProxyPatientHasDivisionList from "../issara-sdk/apis/ProxyPatientHasDivisionList_apps_PRX";
import ProxyUserProfileAPI from "../issara-sdk/apis/ProxyUserProfileAPI_users";
import UserFamilyAPI from "../issara-sdk/apis/UserFamilyAPI_users";
import UserUpdateImageProfile from "../issara-sdk/apis/UserUpdateImageProfile_usersM";
import VaccineSeriesList from "issara-sdk/apis/VaccineSeriesList_apps_PHR";
import V3PatientInfoView from "../issara-sdk/apis/V3PatientInfoView_apps_PRX";
import UserFamily from "../issara-sdk/apis/V3UserFamily_apps_PRX";

// Serializer
import VaccineSeriesSerializer from "issara-sdk/types/VaccineSeriesSerializer_apps_PHR";
import AgeRangeSerializer from "issara-sdk/types/AgeRangeSerializer_apps_PHR";

// Utils
import { adToBe } from "react-lib/utils";
import {
  mapVaccineItems,
  sortVaccineByDate,
} from "./clinic/DMVaccineInterface";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";

const params = new URLSearchParams(window.location.search);

export type State = {
  language?: "en-US" | "th-TH";
  intl?: IntlShape;
  userId?: string | number;
  apiToken?: string;

  query_params?: string;
  patientId?: string;
  patientInfo?: any;
  patientDetail?: any;
  isAgreeConcent?: boolean;
  uid?: string;
  modeDM?: string;
  modeMom?: string;
  modeChild?: string;
  clinic?: string;
  message?: string;
  device_id?: string;
  subscription?: string;
  platform?: string;
  hospitalCode?: string;

  // message
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;

  // options
  hospitalOptions?: any;
  holdDrugReasonOptions?: any;
  mealOptions?: any;
  medTimeOptions?: any;
  unitOptions?: any;

  // common
  patientList?: any;
  selectedPatient?: any;

  //
} & LoginI.State &
  FirebaseI.State &
  FeedI.State &
  NearbyI.State &
  PostI.State &
  // DM
  DMDrugI.State &
  DMBloodGlucoseI.State &
  DMBloodPressureI.State &
  DMEmotionRestI.State &
  DMExerciseI.State &
  DMFootI.State &
  DMPersonalScoreI.State &
  DMWeightI.State &
  DMFoodI.State &
  VaccineDMI.State &
  // Mom
  MomProfileHealthI.State &
  MomBreastFeedingI.State &
  MomKickCountI.State &
  MomContractionCountI.State &
  MomUltrasoundI.State &
  MomAntenatalHistoryI.State &
  MomWeightGainI.State &
  // Child
  ChildBirthRecordI.State &
  ChildDevelopmentI.State &
  ChildOutsideMedicineI.State &
  ChildGrowthI.State &
  ChildOverallI.State &
  ChildVaccineI.State &
  // Mom & Child
  MomChildLabResultI.State;

export const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
)
  ? "th-TH"
  : "en-US";

export const StateInitial: State = {
  language: LANGUAGE,
  userId: "",
  apiToken: "",

  clinic: params.get("clinic") || "",
  message: "ทดสอบ",
  device_id: "",
  subscription: "",
  query_params: params.toString(),
  platform: "",
  patientId: "",
  patientInfo: {},
  patientDetail: {},
  isAgreeConcent: false,
  uid: "",
  modeDM: "",
  modeMom: "",
  modeChild: "",
  hospitalCode: "",

  // message
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,

  // options
  hospitalOptions: {
    items: [
      // Todo: mock
      { id: 1, name: "Bangkok hospital" },
      { id: 2, name: "Other hospital" },
      { id: 3, name: "Clinic" },
    ],
  },
  holdDrugReasonOptions: {
    items: [
      // Todo: mock
      { id: "ระดับน้ำตาลในเลือดต่ำ", name: "ระดับน้ำตาลในเลือดต่ำ" },
      { id: "เกิดอาการข้างเคียง", name: "เกิดอาการข้างเคียง" },
      { id: "ยาหมด", name: "ยาหมด" },
      { id: "แพทย์สั่งหยุดยา", name: "แพทย์สั่งหยุดยา" },
      { id: "รับประทานครบแล้ว", name: "รับประทานครบแล้ว" },
    ],
  },
  mealOptions: {
    items: [
      // Todo: mock
      { id: "Before meal", name: "Before meal" },
      { id: "After meal", name: "After meal" },
    ],
  },
  medTimeOptions: {
    items: [
      // Todo: mock
      { id: "Morning", name: "Morning" },
      { id: "Afternoon", name: "Afternoon" },
      { id: "Evening", name: "Evening" },
      { id: "Bedtime", name: "Bed time" },
    ],
  },
  unitOptions: {
    items: [
      // Todo: mock
      { id: "Tablet", name: "Tablet" },
      { id: "Unit", name: "Unit" },
      { id: "mg", name: "mg" },
    ],
  },

  // common
  patientList: {},
  selectedPatient: {},

  //
  ...LoginI.StateInitial,
  ...FirebaseI.StateInitial,
  ...NearbyI.StateInitial,
  // ...FirebaseI.StateInitial,
  ...FeedI.StateInitial,
  ...PostI.StateInitial,
  // DM
  ...DMBloodGlucoseI.StateInitial,
  ...DMBloodPressureI.StateInitial,
  ...DMEmotionRestI.StateInitial,
  ...DMExerciseI.StateInitial,
  ...DMFootI.StateInitial,
  ...DMPersonalScoreI.StateInitial,
  ...DMWeightI.StateInitial,
  ...DMDrugI.StateInitial,
  ...DMFoodI.StateInitial,
  ...VaccineDMI.StateInitial,
  // Mom
  ...MomProfileHealthI.StateInitial,
  ...MomBreastFeedingI.StateInitial,
  ...MomKickCountI.StateInitial,
  ...MomContractionCountI.StateInitial,
  ...MomUltrasoundI.StateInitial,
  ...MomAntenatalHistoryI.StateInitial,
  ...MomWeightGainI.StateInitial,
  // Child
  ...ChildBirthRecordI.StateInitial,
  ...ChildDevelopmentI.StateInitial,
  ...ChildOutsideMedicineI.StateInitial,
  ...ChildGrowthI.StateInitial,
  ...ChildOverallI.StateInitial,
  ...ChildVaccineI.StateInitial,
  // Mom & Child
  ...MomChildLabResultI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "LoginFirebase"; params: {} }
  | { message: "DidLogin"; params: {} }
  | { message: "GetLoginInfo"; params: {} }
  | { message: "handleGetUserFamily" }
  | { message: "SetModeDM"; params: {} }
  | { message: "SetModeMom"; params: {} }
  | { message: "SetModeChild"; params: {} }
  | { message: "SavePHR"; params: { measure: string; value: number } }
  | { message: "AgreeConcent"; params: {} }
  | {
    message: "SetTopBar";
    params: { title: string; leftIcon?: string; rightIcon?: string };
  }
  | { message: "handleClearMessage" }
  | { message: "FirebaseSetupLoginHandler"; params: {} }
  | { message: "FirebaseLoginWithUserID"; params: {} }
  | { message: "SavePHR"; params: { measure: string; value: number } }
  | { message: "AgreeConcent"; params: {} }
  | { message: "handleSetSelectedPatient"; params: {} }
  | { message: "handleSetImagePatient"; params: { file?: File } }
  | { message: "HandleEvent"; params: { name: string } }
  | ClinicInterface.Event
  // FirebaseI.Event |
  | FirebaseI.Event
  | NearbyI.Event
  | PostI.Event
  // DM
  | DMBloodGlucoseI.Event
  | DMBloodPressureI.Event
  | DMEmotionRestI.Event
  | DMExerciseI.Event
  | DMFootI.Event
  | DMWeightI.Event
  | DMDrugI.Event
  | DMPersonalScoreI.Event
  | DMFoodI.Event
  | VaccineDMI.Event
  // Mom
  | MomProfileHealthI.Event
  | MomBreastFeedingI.Event
  | MomKickCountI.Event
  | MomContractionCountI.Event
  | MomUltrasoundI.Event
  | MomAntenatalHistoryI.Event
  | MomWeightGainI.Event
  // Child
  | ChildBirthRecordI.Event
  | ChildDevelopmentI.Event
  | ChildOutsideMedicineI.Event
  | ChildGrowthI.Event
  | ChildOverallI.Event
  | ChildVaccineI.Event
  // Mom & Child
  | MomChildLabResultI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const BACKEND_TIME_FORMAT = "HH:mm:ss";
export const BE_DATE_FORMAT = "DD/MM/YYYY";

export const GROUP = {
  // DM
  BLOOD_GLUCOSE: "Blood Glucose",
  BLOOD_PRESSURE: "Blood Pressure",
  EMOTION: "Emotion",
  HEIGHT: "Height",
  WEIGHT: "Weight",
  EXERCISE: "Exercise",
  FOOT: "Foot",
  VACCINE: "Vaccine",
  VACCINE_DM: "VACCINE DM",
  VACCINE_CHILD: "VACCINE CHILD",
  DRUG: "Drug",
  FOOD: "Food",
  // MOM
  BREAST_FEEDING: "Breast Feeding",
  CONTRACTION_COUNT: "Contraction Count",
  KICK_COUNT: "Kick Count",
  OBSTRETIC_RECORD: "Obstretic Record",
  PREGNANCY_WEIGHT: "Pregnancy Weight",
  PRENATAL_AND_ANC_RECORD: "Prenatal and ANC record",
  ULTRASOUND: "Ultrasound",
  // CHILD
  GROWTH: "Growth",
  DEVELOPMENT: "Development",
  OUTSIDE_MEDICINE: "Outside Medicine",
};

export const DATA_TYPE = {
  // DM
  BLOOD_GLUCOSE: "blood_glucose",
  BLOOD_PRESSURE: "blood_pressure",
  EMOTION: "emotion",
  REST: "rest",
  HEIGHT: "height",
  WEIGHT: "weight",
  EXERCISE: "exercise",
  SETTING: "setting",
  FOOT: "foot",
  VACCINE: "vaccine",
  VACCINE_DM: "vaccine_dm",
  DRUG: "drug",
  DRUG_MANUAL: "drug_manual",
  DRUG_CURRENT: "drug_current",
  FOOD: "food",
  ADDITIONAL_FOOD: "additional_food",
  FAVORITE_FOOD: "favorite_food",
  WEEKLY_FOOD_INFO: "weekly_food_info", // for food menu
  // MOM
  BREAST_FEEDING: "breast_feeding",
  CONTRACTION_COUNT: "contraction_count",
  KICK_COUNT: "kick_count",
  OBSTRETIC_RECORD: "obstretic_record",
  CHILD_OBSTRETIC_RECORD: "child_obstretic_record",
  PRE_PREGNANCY_WEIGHT: "pre_pregnancy_weight",
  PREGNANCY_WEIGHT: "pregnancy_weight",
  PRENATAL_AND_ANC_RECORD: "prenatal_and_anc_record",
  ULTRASOUND: "ultrasound",
  // CHILD
  GROWTH: "growth",
  DEVELOPMENT: "development_", // development_<AgeRange_id>
  OUTSIDE_MEDICINE: "outside_medicine",
};

// ---------- DM
export const EXERCISE_TYPE = {
  AEROBIC: { text: "Cardiorespiratory Training", color: "#A0F297" },
  WEIGHT_RESISTANCE: { text: "Resistance Training", color: "#FCA5C4" },
  FLEXIBILITY: { text: "Flexibility and Balance Training", color: "#ACE8FC" },
};

export const GLUCOSE_MEAL = {
  BEFORE: "before",
  AFTER: "after",
};

export const EMOTION_TYPE = {
  VERY_GOOD: { label: "Very happy", value: "5" },
  GOOD: { label: "Happy", value: "4" },
  NORMAL: { label: "Normal", value: "3" },
  BAD: { label: "Bad", value: "2" },
  VERY_BAD: { label: "Very bad", value: "1" },
};

export type FootTypeKey = keyof typeof FOOT_TYPE;

export const FOOT_TYPE = {
  NORMAL: {
    key: "normal_foot",
    label1: "bplusClinicKey729",
    label2: "bplusClinicKey730",
  },
  PARALYZE: {
    key: "paralyze_foot",
    label1: "bplusClinicKey731",
    label2: "bplusClinicKey732",
  },
  DRY_SKIN: {
    key: "dry_skin_foot",
    label1: "bplusClinicKey733",
    label2: "bplusClinicKey734",
  },
  WOUND: { key: "wound_foot", label1: "bplusClinicKey735", label2: "" },
  DARK_SKIN: { key: "dark_skin_foot", label1: "bplusClinicKey736", label2: "" },
  PALE_SKIN: { key: "pale_skin_foot", label1: "bplusClinicKey737", label2: "" },
  RED_SKIN: { key: "red_skin_foot", label1: "bplusClinicKey738", label2: "" },
  CUT_TOENAIL: {
    key: "cut_toenail_foot",
    label1: "bplusClinicKey739",
    label2: "",
  },
  MYCOSIS: { key: "mycosis_foot", label1: "bplusClinicKey740", label2: "" },
  INFECTED: {
    key: "infected_foot",
    label1: "bplusClinicKey741",
    label2: "bplusClinicKey742",
  },
  ABNORMAL: { key: "abnormal_foot", label1: "bplusClinicKey743", label2: "" },
};

export const FOOT_STATUS = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
};

export const LAB_CODE = {
  FPG: { code: "C040", ideals: [70, 130] }, // Fasting plasma glucose (FPG)
  HbA1c: { code: "A010", ideals: [7] }, // Hemoglobin A1c (HbA1c)
  HDL: { code: "C061", ideals: [50] }, // HDL-Cholesterol (HDL)
  LDL: { code: "C650", ideals: [150] }, // LDL-Cholesterol (LDL)
};

export const FOOD_MEAL = {
  BREAKFAST: "breakfast",
  LUNCH: "lunch",
  DINNER: "dinner",
  SNACK: "snack",
};

export const MEDICATION_PERIOD = {
  MORNING: "morning",
  AFTERNOON: "afternoon",
  EVENING: "evening",
  BEDTIME: "bedtime",
  OTHERS: "others",
};

export const MEDICATION_STATUS = {
  NONE: "",
  HOLD: "hold",
  START: "start",
  DELETE: "delete",
  CONFIRM: "confirm",
};

export const MEDICATION_TIMES = {
  MORNING_START: "06:00:00",
  MORNING_END: "11:00:59",
  AFTERNOON_START: "11:01:00",
  AFTERNOON_END: "17:00:59",
  EVENING_START: "17:01:00",
  EVENING_END: "21:59:59",
  BEDTIME_START: "22:00:00",
  BEDTIME_END: "05:59:59",
};

// ---------- MOM
export const BREAST = {
  NORMAL: {
    key: "normal",
    text: "bplusClinicKey777",
    detail: "bplusClinicKey858",
  },
  SHORT: {
    key: "short",
    text: "bplusClinicKey778",
    detail: "bplusClinicKey857",
  },
  INVERSIONT: {
    key: "inversion",
    text: "bplusClinicKey779",
    detail: "bplusClinicKey855",
  },
  SINK: {
    key: "sink",
    text: "bplusClinicKey780",
    detail: "bplusClinicKey856",
  },
};

// ---------- CHILD
export const DEVELOPMENT_SKILL = {
  LANGUAGE: { key: "LANGUAGE", label: "การสื่อสาร", color: "#abd9fb" },
  EMOTIONAL_SOCIAL: {
    key: "EMOTIONAL_SOCIAL",
    label: "อารมณ์ และสังคม",
    color: "#edc039",
  },
  FINE_MOTOR: {
    key: "FINE_MOTOR",
    label: "กล้ามเนื้อมัดเล็ก",
    color: "#fcafd6",
  },
  GROSS_MOTOR: {
    key: "GROSS_MOTOR",
    label: "กล้ามเนื้อมัดใหญ่",
    color: "#219653",
  },
};

// ---------- FUNCTION
export const DidLogin: Handler = async (controller, params) => {
  if (params.user.email) {


    await setDoc(doc(controller.db, "Patient_core", params.user.email), {
      dm: true,
      dmcat: "critical",
      pd: true,
      name: params.user.providerData[0].displayName,
      pic: params.user.providerData[0].photoURL || null,
    });

    // controller.db
    //   .collection("Patient_core")
    //   .doc(params.user.email)
    //   .set({
    //     dm: true,
    //     dmcat: "critical",
    //     pd: true,
    //     name: params.user.providerData[0].displayName,
    //     pic: params.user.providerData[0].photoURL || null,
    //   });

  }
  GetPatientInfo(controller, {
    callback: () => {
      PostI.GetFeedContent(controller as any, params);
    },
  });
  FeedI.GetAppointment(controller as any, params);
  FeedI.GetTodayVisit(controller as any, params);
};

export const GetPatientInfo: Handler = async (controller, params) => {
  const patientId = controller.getState().patientId;
  console.log(patientId);
  if (patientId) {

    const docRef = doc(controller.db, "Patient_core", patientId);
    const docSnap = await getDoc(docRef);
    const patientInfo = docSnap?.data();
    controller.setState({ patientInfo: patientInfo }, params.callback);

    // controller.db
    //   .collection("Patient_core")
    //   .doc(patientId)
    //   .get()
    //   .then((res: any) => {
    //     const patientInfo = res.data();
    //     controller.setState({ patientInfo: patientInfo }, params.callback);
    //   });
  }
};

export const handleGetUserFamily: Handler = async (controller) => {
  controller.setState({ loadingStatus: true });

  let patientItems: any[] = [];
  let currentPatient: any = {};

  const state = controller.getState();

  const [pphdlResp, pphdlError, pphdlNetwork] =
    await ProxyPatientHasDivisionList.list({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: {},
    });

  let currentDivisionCode = "";

  // หา divisionCode ที่ถูกต้อง จาก pphd
  if (!pphdlError && pphdlResp?.total > 0 && pphdlResp?.items?.length > 0) {
    let clinic = state.clinic;
    console.log("clinic: ", clinic);
    let pphdList = pphdlResp.items;
    let pphd = pphdList.find((item: any) => {
      let code = item?.division?.code.toString().toLowerCase();
      let clinicCode = clinic?.toString().toLowerCase();
      if (code.includes(";" + clinicCode)) {
        return true;
      } else {
        return false;
      }
    });
    // ได้ divisionCode แล้ว เอาไปกรอง family ที่ ไม่มี pphd
    currentDivisionCode = pphd?.division?.code;
  }
  console.log("currentDivisionCode: ", currentDivisionCode);

  if (["dm", "child"].includes(state.clinic || "")) {
    const [familyResp, familyError, familyNetwork] = await UserFamilyAPI.list({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      params: { have_family: state.clinic === "child" ? true : false },
    });
    if (familyError) {
    } else {
      patientItems = familyResp?.results?.map((item: any) => ({
        proxy_patient: item?.patient,
        // episode
        pre_name: item?.pre_name,
        first_name: item?.first_name,
        last_name: item?.last_name,
        image: item?.image,
        image_url: item?.image_url,
        age: item?.age,
        // age_dict
        birthdate: item?.birthdate,
        gender: item?.gender,
        gender_name: item?.extra_data?.gender,
        // blood
        chat_channel: "",
      }));

      console.debug("handleGetUserFamily patientItems: ", patientItems);

      if (currentDivisionCode) {
        const [pphdFamily, pphdFamilyError, pphdFamilyNetwork] =
          await ProxyPatientHasDivisionFamilyList.list({
            apiToken: controller.apiToken || Cookies.get("apiToken"),
            params: { division_code: currentDivisionCode },
          });

        console.debug("handleGetUserFamily pphdFamily : ", pphdFamily);
        if (
          !pphdFamilyError &&
          pphdFamily?.total > 0 &&
          pphdFamily?.items?.length > 0
        ) {
          let pphdfList = pphdFamily?.items?.map(
            (item: any) => item.proxy_patient
          );
          patientItems = patientItems?.filter((item: any) =>
            pphdfList.includes(item.proxy_patient)
          );
          // เพิ่ม chat_channel ของญาติ
          patientItems = patientItems?.map((item: any) => ({
            ...item,
            chat_channel: pphdFamily?.items?.find(
              (i: any) => i.proxy_patient === item.proxy_patient
            )?.chat_channel,
          }));
          // console.log("handleGetUserFamily  patientItems: ", patientItems);
        } else {
          // ไม่มี pphd เลย
          patientItems = [];
        }
      }
    }
  }

  if (["dm", "mom"].includes(state.clinic || "")) {
    const [profileResp, profileError, profileNetwork] =
      await ProxyUserProfileAPI.get({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: {},
      });
    if (profileError) {
      controller.setState({
        loadingStatus: false,
        errorMessage: profileError,
        successMessage: null,
        patientList: { items: [] },
        selectedPatient: {},
      });
      return;
    } else {
      // console.log('handleGetUserFamily profileResp: ', profileResp);
      currentPatient = {
        proxy_patient: profileResp?.patient,
        // episode
        pre_name: profileResp?.pre_name,
        first_name: profileResp?.first_name,
        last_name: profileResp?.last_name,
        image: profileResp?.image,
        image_url: profileResp?.image_url,
        age: profileResp?.age,
        age_dict: profileResp?.age_dict,
        birthdate: profileResp?.birthdate,
        gender: profileResp?.gender_value,
        gender_name: profileResp?.gender,
        chat_channel: "",
        // blood
      };

      if (currentDivisionCode) {
        // const [pphdlResp, pphdlError, pphdlNetwork] =
        //   await ProxyPatientHasDivisionList.list({
        //     apiToken: controller.apiToken || Cookies.get("apiToken"),
        //     params: {},
        //   });

        if (
          !pphdlError &&
          pphdlResp?.total > 0 &&
          pphdlResp?.items?.length > 0
        ) {
          let myPphd = pphdlResp.items?.find(
            (item: any) => item.division.code === currentDivisionCode
          );
          // เพิ่ม Chatchannel ของ ตัวเอง
          currentPatient.chat_channel = myPphd.chat_channel;
        }
      }
      patientItems.unshift(currentPatient);
    }
  }

  console.log("Check Last patientItems: ", patientItems);
  let tmpSelectedPatient = {};
  if (Object.keys(currentPatient).length > 0) {
    tmpSelectedPatient = { ...currentPatient };
  } else {
    tmpSelectedPatient = { ...patientItems[0] };
  }

  const search = new URLSearchParams(window.location.search)
  const proxy_patient = search.get('proxy_patient')

  if (proxy_patient) {
    const findPatient = patientItems.find((item) => item.proxy_patient === +proxy_patient)
    if (!!findPatient) {
      tmpSelectedPatient = findPatient
    }
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: null,
    successMessage: null,
    patientList: { items: [...patientItems] },
    selectedPatient: tmpSelectedPatient,
  });

  handleSetSelectedPatient(controller, tmpSelectedPatient);
};

// common Patient Data
export const commonCreatePatientData: Handler = async (controller, params) => {
  console.log("commonCreatePatientData !!!! params: ", params);
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    return ["No patient.", null];
  }

  let data = {
    proxy_patient: state.selectedPatient.proxy_patient,
    episode: state.selectedPatient.episode,
    ...params,
  };
  data = commonCheck(state.selectedPatient, data);

  const [response, error, network] = await PatientDataList.create({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonUpdatePatientData: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("commonUpdatePatientData !!!! params: ", params);
  let data: any = { ...params };
  data = commonCheck(state.selectedPatient, data);
  delete data["documents"]
  const [response, error, network] = await PatientDataDetail.patch({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    pk: params.id,
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonDeletePatientData: Handler = async (controller, params) => {
  const [response, error, network] = await PatientDataDetailM.delete({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    pk: params.id,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonGetPatientDataLatest: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    return ["No patient.", null];
  }
  const [response, error, network] = await PatientDataLatest.retrieve({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      proxy_patient: state.selectedPatient.proxy_patient,
      ...params,
    },
  });
  if (error && error?.[0] !== "No Latest Data.") {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonListPatientDataList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    return ["", null];
    // return ["No patient.", null];
  }
  const [response, error, network] = await PatientDataList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      proxy_patient: state.selectedPatient.proxy_patient,
      ...params,
    },
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonListPatientDataGroupList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    return ["No patient.", null];
  }

  let data = {
    proxy_patient: state.selectedPatient.proxy_patient,
    episode: state.selectedPatient.episode,
    ...params,
  };

  const [response, error, network] = await PatientDataGroupList.post({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

// common Patient Document File
export const commonCreatePatientDocumentFile: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  let data = { ...params };
  const [response, error, network] = await PatientDocumentDataList.create({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: { ...data, active: true },
  });

  if (!state.selectedPatient.proxy_patient) {
    return ["No patient.", null];
  }

  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonUpdatePatientDocumentFile: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let data: any = { ...params };

  const [response, error, network] = await PatientDocumentDataDetailM.update({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    pk: params.id,
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonDeletePatientDocumentFile: Handler = async (
  controller,
  params
) => {
  const [response, error, network] = await PatientDocumentDataDetailM.delete({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    pk: params.id,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonCreateUpdatePatientDocumentFile: Handler = async (
  controller,
  params
) => {
  // update and create file for phr data
  // params id, phr_id, image:base64, content_file:-
  let error: any = null;
  let response: any = null;
  if (params?.id) {
    [error, response] = await commonUpdatePatientDocumentFile(controller, {
      id: params.id,
      phr: +params.phr,
      image: params.image || null,
      // content_file: params.content_file || null,
    });
  } else {
    [error, response] = await commonCreatePatientDocumentFile(controller, {
      phr: +params.phr,
      image: params.image,
      // content_file: params.content_file || null,
    });
  }

  if (error) {
    return [error, null];
  } else {
    return [null, { id: response.id, url: response.image }];
  }
};

// common Patient Info
export const commonGetPatientInfo: Handler = async (controller) => {
  const state = controller.getState();

  const [dsRespor, dsError, dsNetwork] =
  await UserFamily.list({
    apiToken: controller.apiToken || Cookies.get("apiToken")
  });

if (dsRespor !== null) {
      dsRespor.items.map((item:any) => {
        if (state.selectedPatient.proxy_patient) {
          if (item.patient.patient === state.selectedPatient.proxy_patient){
              controller.setState({ hospitalCode: item.hospital });
           }
      }
    })
}

  if (!state.selectedPatient.proxy_patient) {
    return ["", null];
    // return ["No patient.", null];
  }

  const [response, error, network] = await V3PatientInfoView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient.proxy_patient,
      hospital: state.hospitalCode? state.hospitalCode:1,
    },
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

// Set Vaccine Child
export const commonGetChildVaccine: Handler = async (controller) => {
  const getAgeRangeList = AgeRangeList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { type: "VACCINE_CHILD" },
  });

  const getVaccineSeriesList = VaccineSeriesList.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { patient_type: "CHILD" },
  });

  const getVaccinePatient = commonListPatientDataList(controller as any, {
    group: GROUP.VACCINE_CHILD,
  });

  const [resAgeRange, resVaccine, resVPatient] = await Promise.all([
    getAgeRangeList,
    getVaccineSeriesList,
    getVaccinePatient,
  ]);

  const ageRangeItems: AgeRangeSerializer[] = resAgeRange?.[0]?.items || [];
  const vaccineItems: (VaccineSeriesSerializer & { age_range: any[] })[] =
    resVaccine?.[0]?.items || [];
  const vPatientItems = resVPatient?.[1]?.items || [];

  const vaccineOther = vaccineItems.filter(
    (item) => item.vaccine_type === "OTHERS"
  );

  const vaccineEssential = vaccineItems.filter(
    (item) => item.vaccine_type === "ESSENTIAL"
  );

  const list = ageRangeItems.map((item) => ({
    age_range: item.id,
    name: item.name,
    to_day: item.to_day,
    to_month: item.to_month,
    to_year: item.to_year,
    items: vaccineEssential
      .map((vItem) =>
        vItem.age_range.map((id, index) => {
          const filterItem: any[] = vPatientItems
            .filter((acc: any) => acc.data_type === `vaccine_${vItem.id}`)
            .sort(sortVaccineByDate);

          return {
            vaccine_age_range: id,
            ...vItem,
            index,
            data: filterItem?.[index]?.data || null,
            vaccine_id: filterItem?.[index]?.id,
            measure_date: filterItem?.[index]?.measure_date,
            proxy_patient: filterItem?.[index]?.proxy_patient,
          };
        })
      )
      .flat()
      .map((vItem: any) => {
        return vItem.vaccine_age_range === item.id
          ? formatVaccineData(vItem)
          : {};
      })
      .filter((vItem) => vItem.vaccine),
  }));

  return [list.filter((item) => !!item.items.length), mapVaccineItems(vaccineOther, vPatientItems)];
};

export const formatVaccineData: Handler = (item: any, code = "") => {
  return {
    vaccine: item.id,
    name: item.name,
    abbreviation: code
      ? code
      : `${item.age_range.length > 1
        ? `${item.abbreviation}${item.index + 1}`
        : item.abbreviation
      }`,
    data: item.data,
    id: item.vaccine_id,
    measure_date: item.measure_date,
    proxy_patient: item.proxy_patient,
    code: item.abbreviation,
    description: item.description,
    vaccine_type: item.vaccine_type,
  };
};

//
export const SavePHR: Handler = async (controller, params) => {
  let user = controller.app.auth().currentUser.email;
  let mapping = {
    Lab: "CentralLabResult_apps_LAB",
    VitalSign: "VitalSignValue_apps_PTM",
    Nutrition: "Nutrition",
    Exercise: "Exercise",
    Emotion: "Emotion",
  };
  const type = params.type as keyof typeof mapping;

  if (user) {
    if (
      ["Lab", "VitalSign", "Nutrition", "Exercise", "Emotion"].includes(type)
    ) {
      let collectionName = mapping[type];

      const docRef = await addDoc(collection(controller.db, collectionName), {
        date: new Date(),
        patientId: user,
        fromPatient: true,
        ...params.data,
        // measure: params.measure,
        // value: params.value,
      });

      params?.callback(docRef);
      
      // controller.db
      //   .collection(collectionName)
      //   .add({
      //     date: new Date(),
      //     patientId: user,
      //     fromPatient: true,
      //     ...params.data,
      //     // measure: params.measure,
      //     // value: params.value,
      //   })
      //   .then((res) => {
      //     params?.callback(res);
      //   })
      //   .catch((res) => {
      //     params?.callback(res);
      //   });
    }
  }
};

export const AgreeConcent: Handler = (controller, params) => {
  controller.setState({ isAgreeConcent: true });
  console.log(" controller.history", controller.history);
  controller.history.push("/clinicdm");
};

export const SetTopBar: Handler = (controller, params) => {
  let title = params.title;
  let left = params.leftIcon;
  let right = params.rightIcon;
  console.log("SetTopBar title", title, left, right);
  controller.setState({
    mobTitle: title,
    ...(left && { leftIcon: left }),
    ...(right && { rightIcon: right }),
  });
};

// mode
export const SetModeDM: Handler = async (controller, params) => {
  controller.setState({ modeDM: params.mode });
};

export const SetModeMom: Handler = async (controller, params) => {
  controller.setState({ modeMom: params.mode });
};

export const SetModeChild: Handler = async (controller, params) => {
  controller.setState({ modeChild: params.mode });
};

// Firebase -- For prototyping -----------------------------------------------------
export const FirebaseSetupLoginHandler: Handler = (controller, params) => {
  // For prototyping -- set up callback for firebase login
  const onFirebaseAuthStateChanged = async (user: any) => {
    if (user) {
      console.log("FirebaseSetupLoginHandler user", user)
      // Check if logged in
      console.log("user: ", user.uid);
      controller.setState({ loggedin: true });
      // params.loginCallBack();
      // Debug with firebase -- create patient with uid
      if (user.uid) {

        await setDoc(doc(controller.db, "Patient_core", user.uid), {
          name: "test",
          pic: null,
        });

        // controller.db.collection("Patient_core").doc(user.uid).set({
        //   name: "test",
        //   pic: null,
        // });
      }
      GetPatientInfo(controller, {
        callback: () => {
          console.log(" FirebaseSetupLoginHandler callback GetPatientInfo ")
          // PostI.GetContent(controller, params)
        },
      });
    } else {
      console.log("no user");
    }
  };
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
};

export const LoginFirebase: Handler = (controller, params) => {
  controller.functions
    .httpsCallable("createTokenFromUser")("Mob")
    .then((result: any) => {
      controller.setState({ firebaseToken: result.data });
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .then(function (res: any) {
          console.log("login firebase");
          // GetAppointment(controller, {})
        })
        .catch(function (error: any) {
          console.log(error);
        });
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const FirebaseLoginWithUserID: Handler = (controller, params) => {
  controller.functions
    .httpsCallable("createTokenFromUser")(params.userId)
    .then((result: any) => {
      // console.log(" result", result)
      controller.setState({ firebaseToken: result.data.toString() });
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .then(function (res: any) {
          console.log("login firebase");
          // GetAppointment(controller, {})
        })
        .catch(function (error: any) {
          console.log(error);
        });
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const handleSetSelectedPatient: Handler = async (controller, params) => {
  const [error, response] = await commonGetPatientInfo(controller);
  if (error) {
    controller.setState({
      errorMessage: response,
      successMessage: null,
      selectedPatient: params,
    });
  } else {
    controller.setState({
      errorMessage: null,
      successMessage: null,
      selectedPatient: { ...params, ...response },
    });
  }
};

// for upload file image child
export const handleSetImagePatient: Handler = async (controller, params) => {
  const { selectedPatient } = controller.getState();
  let patientNow = { ...selectedPatient };

  // let image = null

  // remove when api set
  // if(params.file){
  //   patientNow['image'] = await FileToBase64(params.file)
  // }
  const [res, err, net] = await UserUpdateImageProfile.patch({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: {
      patient: selectedPatient.proxy_patient,
      image: params.file,
    },
  });

  if (err) {
    console.log("err: ", err);
    return;
  } else {
    console.log("res: ", res);
    patientNow["image"] = res.image;
  }

  // TO DO: sent api here and remove set image

  handleSetSelectedPatient(controller, patientNow);
};

// To be deprecated ---------------------------------------------------------------
export const DidMount: Handler = (controller, params) => {
  controller.app.auth().onAuthStateChanged((user: any) => {
    if (user) {
      // Check if logged in
      console.log("user: ", user.uid);
      params.loginCallBack();
      // GetAppointment(controller, {})
    } else {
      console.log("no user");
    }
  });
};

export const handleClearMessage: Handler = (controller) => {
  controller.setState({ errorMessage: null, successMessage: null });
};

// Date format
export const formatDate = (
  date: moment.MomentInput,
  dateFormat = "DD MMMM YYYY"
) => {
  return moment(date, "DD/MM/YYYY").clone().locale("en").format(dateFormat);
};

export const convertToADDate = (date: string) => {
  let dateConverter = date.split("/");
  let year = +dateConverter[2] - 543;
  let dateAD = `${dateConverter[0]}/${dateConverter[1]}/${year}`;
  return dateAD;
};

export const formatDateByLanguage = (
  date: moment.MomentInput,
  dateFormat = "DD MMMM YYYY"
) => {
  if (LANGUAGE.includes("th")) {
    date = adToBe(date);
  }
  return moment(date, "DD/MM/YYYY").clone().locale(LANGUAGE).format(dateFormat);
};

// -------------------- CALCULATION
const commonCheck = (selectedPatient: any, data: any) => {
  // DM
  if (data.group === GROUP.WEIGHT && data.data_type === DATA_TYPE.WEIGHT) {
    const wResult: any = checkWeight(data?.data?.height, data.data.value);
    data.data.bmi = wResult.bmi;
    data.data.status = wResult.status;
    data.data.pass = wResult.pass;
    data.data.color = wResult.color;
  } else if (
    data.group === GROUP.BLOOD_PRESSURE &&
    data.data_type === DATA_TYPE.BLOOD_PRESSURE
  ) {
    const bpResult: any = checkBloodPressure(
      data.data.systolic,
      data.data.diastolic
    );
    data.data.status = bpResult.status;
    data.data.pass = bpResult.pass;
    data.data.color = bpResult.color;
  } else if (
    data.group === GROUP.BLOOD_GLUCOSE &&
    data.data_type === DATA_TYPE.BLOOD_GLUCOSE
  ) {
    const bgResult: any = checkBloodGlucose(data.data.value, data.data.meal);
    data.data.status = bgResult.status;
    data.data.pass = bgResult.pass;
    data.data.color = bgResult.color;
  } else if (data.group === GROUP.FOOT && data.data_type === DATA_TYPE.FOOT) {
    const tmpLastData = data.data[data.data.length - 1];
    const footResult: any = checkFoot(
      tmpLastData?.leftFoot,
      tmpLastData?.rightFoot
    );
    tmpLastData.status = footResult.status;
    tmpLastData.color = footResult.color;
  } else if (data.group === GROUP.FOOD && data.data_type === DATA_TYPE.FOOD) {
    const foodResult: any = checkFood(data.data);
    data.data.percentageCalory = foodResult.percentageCalory;
    data.data.status = foodResult.status;
    data.data.score = foodResult.score;
    data.data.allCalories = foodResult.allCalories;
  }
  // MOM
  else if (
    data.group === GROUP.PREGNANCY_WEIGHT &&
    data.data_type === DATA_TYPE.PREGNANCY_WEIGHT
  ) {
    const weightGainResult = checkPregnancyWeightGain(
      data.data?.ga_weeks,
      data.data?.gain
    );
    data.data.status = weightGainResult.status;
    data.data.pass = weightGainResult.pass;
    data.data.color = weightGainResult.color;
    data.data.gainStatus = weightGainResult.gainStatus;
    data.data.minGain = weightGainResult.minGain;
    data.data.maxGain = weightGainResult.maxGain;
  } else if (
    data.group === GROUP.CONTRACTION_COUNT &&
    data.data_type === DATA_TYPE.CONTRACTION_COUNT
  ) {
    const contractionCountResult = checkContractionCount(data.data?.total);
    data.data.status = contractionCountResult.status;
    data.data.pass = contractionCountResult.pass;
  } else if (
    data.group === GROUP.KICK_COUNT &&
    data.data_type === DATA_TYPE.KICK_COUNT
  ) {
    const contractionCountResult = checkKickCount(data.data?.total);
    data.data.status = contractionCountResult.status;
    data.data.pass = contractionCountResult.pass;
  } else if (
    data.group === GROUP.GROWTH &&
    data.data_type === DATA_TYPE.GROWTH
  ) {
    const growthResult = checkGrowth(selectedPatient, data.data);
    data.data = { ...data.data, ...growthResult };
  }

  return data;
};

// DM
export const checkWeightStatus = (bmi: number) => {
  let status: string = "";
  let pass: boolean | null = null;
  let color: string = "black";
  if (bmi < 18.5) {
    status = "Underweight";
    pass = false;
    color = "#EFB810";
  } else if (18.5 <= bmi && bmi <= 22.9) {
    status = "Normal/ Healthy weight";
    pass = true;
    color = "green";
  } else if (23.0 <= bmi && bmi < 24.9) {
    status = "Overweight";
    pass = false;
    color = "orange";
  } else if (25.0 <= bmi) {
    status = "Obese";
    pass = false;
    color = "red";
  }
  return { bmi: bmi, status: status, pass: pass, color: color };
};

const checkWeight = (height: string, weight: string) => {
  const heightM = parseFloat(height) / 100.0;
  const bmi: any = (parseFloat(weight) / (heightM * heightM)).toFixed(1);
  return checkWeightStatus(bmi); // { bmi: bmi, status: status, pass: pass, color: color }
};

export const checkBloodPressure = (systolic: string, diastolic: string) => {
  let status: string = "";
  let pass: boolean | null = null;
  let color: string = "black";

  const systolicF = parseFloat(systolic);
  const diastolicF = parseFloat(diastolic);

  if (systolicF < 140 && diastolicF < 90) {
    status = "Normal";
    pass = true;
    color = "green";
  } else if (systolicF >= 140 || diastolicF >= 90) {
    status = "High blood pressure";
    pass = false;
    color = "red";
  }

  return { status: status, pass: pass, color: color };
};

const checkBloodGlucose = (value: string, meal: string) => {
  let status: string = "";
  let pass: boolean | null = null;
  let color: string = "black";

  const valueI = parseInt(value);
  if (meal === "before") {
    if (valueI < 130) {
      status = "Normal";
      pass = true;
      color = "green";
    } else {
      status = "High blood glucose";
      pass = false;
      color = "red";
    }
  } else {
    if (valueI < 180) {
      status = "Normal";
      pass = true;
      color = "green";
    } else {
      status = "High blood glucose";
      pass = false;
      color = "red";
    }
  }

  if (valueI < 70) {
    status = "Low blood glucose";
    pass = false;
    color = "red";
  }

  return { status: status, pass: pass, color: color };
};

export const checkFootStatus = (foot: any) => {
  if (
    foot[FOOT_TYPE.PARALYZE.key] ||
    foot[FOOT_TYPE.WOUND.key] ||
    foot[FOOT_TYPE.DARK_SKIN.key] ||
    foot[FOOT_TYPE.PALE_SKIN.key] ||
    foot[FOOT_TYPE.RED_SKIN.key] ||
    foot[FOOT_TYPE.INFECTED.key] ||
    foot[FOOT_TYPE.MYCOSIS.key] ||
    foot[FOOT_TYPE.CUT_TOENAIL.key] ||
    foot[FOOT_TYPE.ABNORMAL.key]
  ) {
    return FOOT_STATUS.HIGH;
  } else if (foot[FOOT_TYPE.DRY_SKIN.key]) {
    return FOOT_STATUS.MEDIUM;
  } else if (foot[FOOT_TYPE.NORMAL.key]) {
    return FOOT_STATUS.LOW;
  }
  return "";
};

const checkFoot = (leftFoot: any, rightFoot: any) => {
  let status: string = "";
  let color: string = "blue";

  const leftStatus = checkFootStatus(leftFoot);
  const rightStatus = checkFootStatus(rightFoot);
  if (leftStatus === FOOT_STATUS.HIGH || rightStatus === FOOT_STATUS.HIGH) {
    status = FOOT_STATUS.HIGH;
    color = "red";
  } else if (
    leftStatus === FOOT_STATUS.MEDIUM ||
    rightStatus === FOOT_STATUS.MEDIUM
  ) {
    status = FOOT_STATUS.MEDIUM;
    color = "yellow";
  } else if (
    leftStatus === FOOT_STATUS.LOW ||
    rightStatus === FOOT_STATUS.LOW
  ) {
    status = FOOT_STATUS.LOW;
    color = "green";
  }
  return { status: status, color: color };
};

export const checkFoodStatus = (value: any) => {
  let result: any = { status: "", score: 0, color: "black" };
  if (value > 79 && value < 101) {
    result.status = "Well controlled";
    result.score = 100;
  } else {
    result.status = "Poorly controlled";
    result.color = "red";
  }
  return result;
};

const checkFood = (food: any) => {
  let result: any = {};
  let allCalories = 0;
  food[FOOD_MEAL.BREAKFAST].forEach((item: any) => {
    allCalories = allCalories + item.energy_cal * item.qty;
  });
  food[FOOD_MEAL.LUNCH].forEach((item: any) => {
    allCalories = allCalories + item.energy_cal * item.qty;
  });
  food[FOOD_MEAL.DINNER].forEach((item: any) => {
    allCalories = allCalories + item.energy_cal * item.qty;
  });
  food[FOOD_MEAL.SNACK].forEach((item: any) => {
    allCalories = allCalories + item.energy_cal * item.qty;
  });

  result.allCalories = allCalories;
  result.percentageCalory = (allCalories / food.calories_budget) * 100;

  const foodStatus: any = checkFoodStatus(result.percentageCalory);
  result.status = foodStatus.status;
  result.score = foodStatus.score;

  return result;
};

export const calculateLabWeight = (items: any[]) => {
  // Math.round((bmi1 + bmi2 + ... + bmi length) / length)
  console.log("----- [LWT] calculateLabWeight");
  let result: number = 0;
  items?.forEach((item: any) => {
    console.log(`----- [LWT] ${result} + ${item}`);
    result = result + parseFloat(item);
  });
  const bmiAvg: number = Math.round(result / items?.length);
  console.log(
    `----- [LWT] AVG Math.round( ${result}/${items?.length} ) = ${bmiAvg}`
  );
  const wResult: any = checkWeightStatus(bmiAvg); // { bmi: bmi, status: status, pass: pass, color: color }
  if (bmiAvg === 18.5) {
    return { ...wResult, score: 100 };
  } else if (bmiAvg > 30) {
    return { ...wResult, score: 0 };
  } else {
    return { ...wResult, score: 100 - Math.abs(bmiAvg - 18.5) * 10 };
  }
};

export const calculateLabBloodPressureScore = (items: any[]) => {
  // Math.round((sys1 + sys2 + ... + sys length) / length)
  console.log("----- [LBP] calculateLabBloodPressureScore");
  let result: number = 0;
  items?.forEach((item: any) => {
    console.log(`----- [LBP] ${result} + ${item}`);
    result = result + parseFloat(item);
  });
  const bpAvg: number = Math.round(result / items?.length);
  console.log(
    `----- [LBP] AVG Math.round( ${result}/${items?.length} ) = ${bpAvg}`
  );
  if (bpAvg < 140) {
    return { score: 100, status: "Normal", color: "black" };
  } else {
    return { score: 0, status: "Elevated blood pressure", color: "red" };
  }
};

export const calculateNormalRange = (value: string) => {
  let tmpNormal: string = value.replace("(", "");
  tmpNormal = tmpNormal.replace(")", "");

  let min: number | null = null;
  let max: number | null = null;
  let sign: string | null = null;
  if (tmpNormal.search("<=") > -1) {
    const tmp = parseFloat(tmpNormal.replace("<=", ""));
    max = tmp;
    sign = "<=";
  } else if (tmpNormal.search(">=") > -1) {
    const tmp = parseFloat(tmpNormal.replace(">=", ""));
    min = tmp;
    sign = ">=";
  } else if (tmpNormal.search("<") > -1) {
    const tmp = parseFloat(tmpNormal.replace("<", ""));
    max = tmp;
    sign = "<";
  } else if (tmpNormal.search(">") > -1) {
    const tmp = parseFloat(tmpNormal.replace(">", ""));
    min = tmp;
    sign = ">";
  } else if (tmpNormal.search("-") > -1) {
    const tmp = tmpNormal.split("-");
    min = parseFloat(tmp[0]);
    max = parseFloat(tmp[1]);
  }
  return { min: min, max: max, sign: sign };
};

export const calculateDMLabStatus = (normal: string, value: number) => {
  let score: number = 0;
  let status: "High" | "Normal" | "Low" | "" = "";
  let color: string = "black";

  const tmpNormal: any = calculateNormalRange(normal);
  if (tmpNormal.min && tmpNormal.max) {
    if (tmpNormal.min <= value && value <= tmpNormal.max) {
      score = 100;
      status = "Normal";
      color = "black";
    } else if (tmpNormal.max > value) {
      score = 0;
      status = "Low";
      color = "red";
    } else if (tmpNormal.min < value) {
      score = 0;
      status = "High";
      color = "red";
    }
  } else if (["<=", "<"].includes(tmpNormal.sign)) {
    if (eval(`${value} ${tmpNormal.sign} ${tmpNormal.max}`)) {
      score = 100;
      status = "Normal";
      color = "black";
    } else {
      score = 0;
      status = "High";
      color = "red";
    }
  } else if ([">=", ">"].includes(tmpNormal.sign)) {
    if (eval(`${value} ${tmpNormal.sign} ${tmpNormal.min}`)) {
      score = 100;
      status = "Normal";
      color = "black";
    } else {
      score = 0;
      status = "Low";
      color = "red";
    }
  }
  return {
    score: score,
    status: status,
    color: color,
    normalRange: normal,
    valueAvg: value,
  };
};

export const calculateDMLabScore = (items: any) => {
  // Math.round((fpg1 + fpg2 + ... + fpg length) / length)
  console.log(`----- [${items.name})] calculateDMLabScore`);
  console.log("+++++", items);
  let result: number = 0;
  items?.children?.forEach((item: any) => {
    console.log(`----- [${items.name})] ${result} + ${item["RSL"]}`);
    result = result + Math.round(item["RSL"]);
  });
  const labAvg: number = Math.round(result / items.children.length);
  console.log(
    `----- [${items.name}] AVG Math.round( ${result}/${items.children.length} ) = ${labAvg}`
  );
  return calculateDMLabStatus(items.children[0].NML, labAvg);
};

// MOM
export const calculateDelivery = (
  byUltrasound: boolean,
  currentDate: moment.Moment,
  lmpDate: moment.Moment,
  eddDate: moment.Moment | null
) => {
  /**
   * LMP date: วันที่ประจำเดือนมาวันสุดท้าย
   * EDD date: Estimate date of delivery กำหนดคลอด
   * GA: Gestational Age อายุครรภ์
   */
  let calEddDate: moment.Moment | null = moment();
  let calStartPregnancyDate: moment.Moment | null = moment();
  let calGAday: number = 0;
  if (byUltrasound) {
    calEddDate = eddDate;
    calStartPregnancyDate = calEddDate?.clone().add(-279, "days") || null;
    calGAday = currentDate
      .clone()
      .add(1, "days")
      .diff(calStartPregnancyDate, "days");
  } else if (!byUltrasound) {
    calEddDate = lmpDate.clone().add(279, "days");
    calStartPregnancyDate = lmpDate;
    calGAday = currentDate.clone().add(1, "days").diff(lmpDate, "days");
  }
  let trimester: any = {};
  const ga_weeks: number = Math.trunc(calGAday / 7);
  if (0 <= ga_weeks && ga_weeks < 14) {
    trimester = { value: 1, label: "1st", text: "First trimester" };
  } else if (14 <= ga_weeks && ga_weeks < 28) {
    trimester = { value: 2, label: "2nd", text: "Second trimester" };
  } else if (28 <= ga_weeks && ga_weeks <= 40) {
    trimester = { value: 3, label: "3rd", text: "Third trimester" };
  }

  let calBBday = 280 - calGAday;
  // console.log("calEddDate", calEddDate.toString())
  return {
    start_pregnancy_date: calStartPregnancyDate,
    edd_date: calEddDate,
    ga_total_day: calGAday,
    ga_days: calGAday % 7,
    ga_weeks: Math.trunc(calGAday / 7),
    bb_total_day: calBBday,
    bb_days: calBBday % 7,
    bb_weeks: Math.trunc(calBBday / 7),
    bb_edd: moment(eddDate).locale("en").format("DD MMMM YYYY"),
    trimester: trimester,
  };
};

export const checkPregnancyWeightGain = (gaWeeks: number, gain: number) => {
  let minGain: number = 0;
  let maxGain: number = 0;
  if (0 <= gaWeeks && gaWeeks < 13) {
    minGain = 0.07 * gaWeeks;
    maxGain = 0.21 * gaWeeks;
  } else if (13 <= gaWeeks && gaWeeks <= 40) {
    minGain = 0.39 * gaWeeks - 4.13;
    maxGain = 0.49 * gaWeeks - 3.62;
  }

  let status: string = "";
  let pass: boolean | null = null;
  let color: string = "black";
  let gainStatus: string = "";
  if (gain < minGain) {
    status = "น้อยกว่าเกณฑ์มาตรฐาน";
    pass = false;
    color = "yellow";
    gainStatus = "Underweight";
  } else if (gain > maxGain) {
    status = "มากกว่าเกณฑ์มาตรฐาน";
    pass = false;
    color = "yellow";
    gainStatus = "Overweight";
  } else {
    status = "เหมาะสมตามเกณฑ์";
    pass = true;
    color = "green";
    gainStatus = "Normal";
  }
  return {
    status: status,
    pass: pass,
    color: color,
    gainStatus: gainStatus,
    minGain: minGain,
    maxGain: maxGain,
  };
};

const checkContractionCount = (count: number) => {
  let status: string = "";
  let pass: boolean = false;
  if (count < 10) {
    status = "อยู่ในเกณฑ์ที่เหมาะสม";
    pass = true;
  } else if (11 <= count && count <= 20) {
    status = "สมควรลดกิจกรรม";
    pass = false;
  } else if (count > 20) {
    status = "ควรรีบปรึกษาแพทย์";
    pass = false;
  }
  return { status: status, pass: pass };
};

const checkKickCount = (count: number) => {
  let status: string = "";
  let pass: boolean = false;
  if (count >= 10) {
    status = "อยู่ในเกณฑ์ที่เหมาะสม";
    pass = true;
  } else if (count < 10) {
    status = "ควรรีบปรึกษาแพทย์";
    pass = false;
  }
  return { status: status, pass: pass };
};

// CHILD
export const calculateChildDevelopmentTotalV2 = (data: any[]) => {
  const flatItems = data?.map((item: any) => item?.data?.method)?.flat() || [];
  const checkSum = flatItems.filter((item: any) => item?.checked)?.length;
  return +((checkSum / flatItems.length) * 100).toFixed(2);
};

export const calculateChildDevelopmentSkillV2 = (data: any[]) => {
  const checkSum = data.filter((item: any) => item?.checked)?.length;
  return +((checkSum / data.length) * 100).toFixed(2);
};

const checkGrowth = (selectedPatient: any, data: any) => {
  let result = {
    head_status: "",
    head_score: 0,
    height_status: "",
    height_color: "",
    height_score: 0,
    weight_status: "",
    weight_color: "",
    weight_score: 0,
  };

  let gender: "boy" | "girl" | "" = "";

  let month: ChildGrowthI.AgeHeadDataRange = 24;
  if (selectedPatient.birthdate) {
    month = Math.floor(
      moment().diff(
        moment(selectedPatient.birthdate, "YYYY-MM-DD"),
        "months",
        true
      )
    ) as ChildGrowthI.AgeHeadDataRange;
    console.log("birth day month: ", month);
  }

  if (selectedPatient.gender === "F") {
    gender = "girl";
  }
  if (selectedPatient.gender === "M") {
    gender = "boy";
  }

  if (month && month > 0 && month < 61 && gender) {
    let headData = ChildGrowthI.ageHeadData[gender][month];
    let heightWeight = ChildGrowthI.ageHeightWeight[gender][month];

    if (data.head) {
      if (+data.head > headData.less_than && +data.head < headData.more_than) {
        result.head_status = ChildGrowthI.headStatus[0].status;
        result.head_score = ChildGrowthI.headStatus[0].score;
      } else {
        result.head_status = ChildGrowthI.headStatus[1].status;
        result.head_score = ChildGrowthI.headStatus[1].score;
      }
    }

    if (data.height) {
      let h = 0;
      for (const height of heightWeight.height) {
        if (+data.height < height) {
          break;
        }
        h += 1;
      }
      result.height_status = ChildGrowthI.heightStatus[h].status;
      result.height_color = ChildGrowthI.heightStatus[h].color;
      result.height_score = ChildGrowthI.heightStatus[h].score;
    }

    if (data.weight) {
      let h = 0;
      for (const weight of heightWeight.weight) {
        if (+data.weight < weight) {
          break;
        }
        h += 1;
      }
      result.weight_status = ChildGrowthI.weightStatus[h].status;
      result.weight_color = ChildGrowthI.weightStatus[h].color;
      result.weight_score = ChildGrowthI.weightStatus[h].score;
    }
  }

  return result;
};

// Utility
export const FileToBase64 = async (file: Blob) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};
