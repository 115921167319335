import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

import { DMBloodPressureLine } from "../../clinic/DMBloodPressure";
import SnackMessage from "../../clinic/SnackMessage";

import moment from "moment";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  GLUCOSE_MEAL,
  Event as MobEvent,
} from "../../MobClinicInterface";

const styles = {};

type BloodPressureProps = {
  onEvent?: any;
  patientData?: any;
  bloodPresusreData?: any;
  bpChartList?: any;
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  BloodPresusreData?: any;
  bpFilterDuration?: string;
  selectedPatient?: any;
};

const BloodPressure: React.FC<BloodPressureProps> = (props) => {
  // useEffect(() => {
  //   props.onEvent({
  //     message: "GetBloodPressureChart",
  //     params: {
  //       start_measure: moment().add(-7, "days").format(BACKEND_DATE_FORMAT),
  //       end_measure: moment().format(BACKEND_DATE_FORMAT),
  //     },
  //   });
  // }, []);

  return (
    <Segment className="main-layout web-dm">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div className="box-header div-header">
        <img
          alt="mea.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/detail/bloodpressure.png"
        />
        <span>
          <FormattedMessage id="bplusClinicKey89" />
        </span>
      </div>

      <div className="sub-layout dm">
        <DMBloodPressureLine
          lineData={props.bpChartList}
          onEvent={props.onEvent}
          setDetail={() => {}}
          bpFilterDuration={props.bpFilterDuration}
          selectedPatient={props.selectedPatient}
          hideFooterChart={true}
          height={130}
          isMyTab={true}
          showTooltipChart={true}
        />
      </div>
    </Segment>
  );
};

export default React.memo(BloodPressure);
