import React, { CSSProperties, useEffect, useState } from "react";
import { Box, Step, Stepper, StepButton } from "@mui/material";
import { Header } from "semantic-ui-react";
import KYCForm from "./KYCForm";
import KYCPhoto from "./KYCPhoto";
import SnackMessage from "../clinic/SnackMessage";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';

export const styles = {
  textMain: {
    color: "var(--text-dark-blue)",
    textDecoration: "underline #eb000e",
  } as CSSProperties,
  textHeader: {
    marginTop: "20px",
    color: "var(--text-dark-grey)",
  } as CSSProperties,
  bgDetail: {
    backgroundColor: "#dce7f2",
    margin: "0px -20px",
    border: "none",
  } as CSSProperties,
  requireField: {
    color: "red",
  } as CSSProperties,
};

// ----- Main
type KYCVerifyProps = {
  onEvent: any;
  errorMessage: any;
  successMessage: any;
  patientIdentification: any;
};

const KYCVerifyInitial: KYCVerifyProps = {
  onEvent: () => null,
  errorMessage: null,
  successMessage: null,
  patientIdentification: {},
};

const KYCVerify: React.FC<KYCVerifyProps> = (props) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    props.onEvent({ message: "handleGetPatientIdentification" });
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <div style={{ padding: "30px 20px" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Header as="h2" textAlign="center" style={styles.textMain}>
        <FormattedMessage id="bplusClinicKey522" />
      </Header>

      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {Array(3)
            .fill("")
            .map((item: any, index: number) => {
              return (
                <Step key={index}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                  ></StepButton>
                </Step>
              );
            })}
        </Stepper>

        {activeStep === 0 && (
          <KYCPhoto
            videoConstraints={{
              width: 600,
              height: 800,
              facingMode: { exact: "environment" },
            }}
            onEvent={props.onEvent}
            onNext={handleNext}
            patientIdentification={props.patientIdentification}
            imageName="idcard"
            headerText="Taking a photo of your ID card"
          />
        )}
        {activeStep === 1 && (
          <KYCPhoto
            videoConstraints={{ width: 800, height: 600, facingMode: "user" }}
            onEvent={props.onEvent}
            onNext={handleNext}
            patientIdentification={props.patientIdentification}
            imageName="face"
            headerText="Take a picture of straight face"
          />
        )}
        {activeStep === 2 && (
          <KYCForm
            onEvent={props.onEvent}
            patientIdentification={props.patientIdentification}
            headerText="Check information"
          />
        )}
      </Box>
    </div>
  );
};

KYCVerify.defaultProps = KYCVerifyInitial;

export default React.memo(KYCVerify);
