import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

import {
  Dropdown,
  DropdownProps,
  Icon,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Button as MuiButton } from "@material-ui/core";
import ChildDevelopmentBarChart from "./ChildDevelopmentBarChart";
import { useStyles } from "./ChildDevelopment";

// Interface
import {
  BACKEND_DATE_FORMAT,
  Event as MobEvent,
  calculateChildDevelopmentTotalV2,
  DEVELOPMENT_SKILL,
} from "../MobClinicInterface";

type HistoryTabProps = {
  // function
  onEvent: (e: MobEvent) => any;
  onSetViewHeight: (height: number) => any;
  // data
  selectedPatient?: any;
  developmentHistory?: any[];
  developmentAgeRange?: any[];
  isMyTab?: boolean;
  isMobile?: boolean;
  height?: number;
  loadingStatus?: boolean;
  isLoader?: boolean;
};

type HistoryItemsType = {
  data: any;
  skill_label: string;
  skill_score: number;
  skill: keyof typeof DEVELOPMENT_SKILL;
};

const ChildDevelopmentHistoryTab: React.FunctionComponent<HistoryTabProps> = (
  props
) => {
  const intl = useIntl();
  const classes = useStyles();
  const [ageRange, setAgeRange] = useState<any>(null);
  const [totalAverage, setTotalAverage] = useState<number>(0);
  const [historyItems, setHistoryItems] = useState<any>({});

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({ message: "HandleGetDevelopmenHistory" });
    }
  }, [props.selectedPatient, props.isMyTab]);

  const ageRangeOptions = useMemo(() => {
    let items: any[] = [];
    props.developmentHistory?.forEach((history: any, index: number) => {
      const value: string = `${history?.data?.age_range_display_seq}_${history?.data?.age_range}`;
      if (index === 0) {
        setAgeRange(value);
      }
      items.push({
        key: index,
        text:
          history?.data?.age_range_name === "2 month"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "4 month"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "6 month"
            ? `6 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "9 month"
            ? `9 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "12 month"
            ? `12 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "15 month"
            ? `15 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "18 month"
            ? `18 ${intl.formatMessage({ id: "bplusClinicKey796" })}`
            : history?.data?.age_range_name === "2 year"
            ? `2 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "2.5 year"
            ? `2.5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "3 year"
            ? `3 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "4 year"
            ? `4 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : history?.data?.age_range_name === "5 year"
            ? `5 ${intl.formatMessage({ id: "bplusClinicKey590" })}`
            : "",
        value: value,
      });
    });

    return items;
  }, [props.developmentHistory]);

  useEffect(() => {
    if (props.developmentHistory && ageRange) {
      props.developmentHistory?.forEach((history: any) => {
        if (
          `${history?.data?.age_range_display_seq}_${history?.data?.age_range}` ===
          ageRange
        ) {
          const total = calculateChildDevelopmentTotalV2(history?.data?.items);
          setTotalAverage(total);
          setHistoryItems(history);
          return;
        }
      });
    }
  }, [props.developmentHistory, ageRange]);

  return (
    <div
      ref={(instance: any) => {
        if (instance) {
          props.onSetViewHeight(instance.offsetHeight);
        }
      }}
      style={{ padding: "15px" }}
    >
      {props.isLoader && (
        <Dimmer active={props.loadingStatus} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}

      <ChildDevelopmentBarChart
        developmentHistory={props.developmentHistory}
        height={props.height}
        selectedPatient={props.selectedPatient}
        developmentAgeRange={props.developmentAgeRange}
      />

      {/* Content */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Dropdown
          options={ageRangeOptions}
          value={ageRange}
          selection
          onChange={(e: any, v: DropdownProps) => setAgeRange(v.value)}
        />
      </div>
      {/* Age Range */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px 0",
        }}
      >
        <div style={{ color: "var(--text-dark-blue)", fontWeight: "bold" }}>
          {intl.formatMessage({ id: "bplusClinicKey60" })}{" "}
          {ageRangeOptions?.find((item: any) => item.value === ageRange)
            ?.text || ""}
        </div>
        <div style={{ color: "var(--text-dark-blue)", fontWeight: "bold" }}>
          <FormattedMessage id="bplusClinicKey495" /> {totalAverage}%
        </div>
      </div>
      {/* Summary */}
      <Grid>
        {historyItems?.data?.items?.map((item: any, index: number) => (
          <Grid.Column width={props.isMobile ? 16 : 8} key={index}>
            <div
              key={item.skill + "sub"}
              style={{ border: "1px solid var(--theme-bg-color-dm)" }}
            >
              <div className={classes.card_summary_header}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className={classes.badge}
                    style={{
                      backgroundColor: (DEVELOPMENT_SKILL as any)[item?.skill]
                        ?.color,
                    }}
                  ></div>
                  {
                    item?.skill_label === "การสื่อสาร" ? `${intl.formatMessage({ id: "bplusClinicKey799" })}` :
                    item?.skill_label === "อารมณ์ และสังคม" ? `${intl.formatMessage({ id: "bplusClinicKey800" })}` :
                    item?.skill_label === "กล้ามเนื้อมัดเล็ก" ? `${intl.formatMessage({ id: "bplusClinicKey801" })}` :
                    item?.skill_label === "กล้ามเนื้อมัดใหญ่" ? `${intl.formatMessage({ id: "bplusClinicKey802" })}` : ""
                  }
                </div>
                <div>{item?.skill_score} %</div>
              </div>
              <div style={{ padding: "13px 13px 2px 13px" }}>
                {item?.data?.method?.map((acc: any, idx: number) => {
                  return (
                    <div
                      key={"list-" + idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "13px",
                      }}
                    >
                      <div style={{ color: "var(--text-dark-blue)" }}>
                        {acc.name}
                      </div>
                      <div>
                        {acc?.checked ? (
                          <Icon name="check circle" color="green" />
                        ) : !acc?.checked && props.isMobile ? (
                          <Icon name="remove" color="red" />
                        ) : (
                          <MuiButton
                            className={classes.button_check}
                            variant="contained"
                            size="small"
                            onClick={() => {
                              let result: any = { ...historyItems };

                              result?.data?.items?.forEach((item: any) => {
                                item?.data?.method?.forEach((met: any) => {
                                  if (met?.name === acc?.name) {
                                    met.checked = true;
                                    met.date =
                                      moment().format(BACKEND_DATE_FORMAT);
                                    return;
                                  }
                                });
                              });
                              result.getHistory = true;

                              props.onEvent({
                                message: "HandleCreateUpdateDevelopment",
                                params: result,
                              });
                            }}
                          >
                            <FormattedMessage id="bplusClinicKey110" />
                          </MuiButton>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
};

export default ChildDevelopmentHistoryTab;
