import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

// Serializer
import VaccineSeriesSerializer from "issara-sdk/types/VaccineSeriesSerializer_apps_PHR";

// Interface
import {
  commonUpdatePatientData,
  commonListPatientDataList,
  DATA_TYPE,
  GROUP,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
} from "../../MobClinicInterface";
import { handleGetVaccineSummary } from "../../clinic/DMVaccineInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  vaccineDataTabDetail?: any;
}

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage">

export const StateInitial: State = {
  vaccineDataTabDetail: {},
};

export type Event =
  // Save
  {
    message: "HandleSaveVaccineDetail";
    params: {
      data: any;
      vaccine: string;
      id?: number | null;
      proxy_patient?: number | null;
      measure_date?: string | null;
    };
  };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const HandleSaveVaccineDetail: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleSaveVaccineDetail" }>["params"]
) => {
  controller.setState({ loadingStatus: true });

  const { data, id, vaccine, proxy_patient, measure_date } = params;
  let saveData: any = {
    id,
    data,
    proxy_patient: proxy_patient,
    measure_date: (data.OEORI_Date ? moment(data.OEORI_Date) : moment())
      .locale("en")
      .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
  };

  if (!id) {
    saveData = {
      ...saveData,
      group: GROUP.VACCINE_DM,
      data_type: `${DATA_TYPE.VACCINE}_${vaccine}`,
    };

    delete saveData.id;
    delete saveData.proxy_patient;
  }

  let [error, response]: [any, any] = [null, null];

  if (saveData.id) {
    [error, response] = await commonUpdatePatientData(
      controller as any,
      saveData
    );
  } else {
    [error, response] = await commonCreatePatientData(
      controller as any,
      saveData
    );
  }

  let errorMessage: any = null;
  let successMessage: any = null;
  if (error) {
    errorMessage = error;
  } else {
    successMessage = `Update vaccine data success.`;
    handleGetVaccineSummary(controller as any, {});
  }

  controller.setState({
    loadingStatus: false,
    errorMessage,
    successMessage,
  });
};
