
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import NutritionalMenuSerializer from '../types/NutritionalMenuSerializer_apps_PHR'


const HOST = `${config.API_HOST}`
/* ['generics.ListCreateAPIView'] */
/* params: 'patient', 'start_created', 'end_created', 'master_menu', 'approved' */
/* data:  */
const NutritionalMenuList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: NutritionalMenuSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/PHR/nutritional-menu/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
      base.client.defaults.headers["content-type"] = "multipart/form-data"
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(data || {})) {
      formData.append(key, value)
    }
    const result = await to(base.client.post(`${HOST}/apis/PHR/nutritional-menu/`,
      formData,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default NutritionalMenuList

