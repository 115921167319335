import React, { useState, useEffect, ReactElement, useCallback, useRef} from "react";

//css
import { Slide, Dialog } from "@material-ui/core";
import { Skeleton } from "@mui/material";

// interface
import { Event, State } from "../MobFeedInterface";
import { PostData } from "../post/PostExInterface";
import { PromotionData } from "../post/PromotionExInterface";

//ui
import MobContentFull from "./MobContentFull";
import MobContentDetail, { ActionType } from "./MobContentDetail";

import { LIMIT_FEED, OFFSET_FEED } from "./MobContentInterface";

type MobContentWithLazyProps = {
  onEvent: (e: Event) => any;
  onGetContent?: () => any;
  contentList?: (PostData & PromotionData)[];
  // contentListId?: any[];
  notfoundMessage?: string;
  notfoundIcon?: string | ReactElement;
  isSkeleton?: boolean;
  firebaseUID?: string;
  firebaseIsAnonymous?: boolean;
  reload?: boolean;
  followOnly?: boolean;
  preferredOnly?: boolean;
  saveOnly?: boolean;
  selectedHospital?: any;
} & Pick<
  State,
  | "myProfileDetail"
  | "language"
  | "loadingMainFeed"
  | "shareContentTo"
  | "apiToken"
  | "isLoadingApiToken"
>;

declare global {
  var YT: any;
  var onYouTubePlayerAPIReady: (e: any) => any;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const MobContentWithLazy: React.FC<MobContentWithLazyProps> = (props) => {
  // const [openModNoti, setOpenModNoti] = useState<boolean>(false);
  const [marketingContent, setMarketingContent] = useState<any>(null);
  const playerListRef = useRef([]);

  useEffect(() => {
    
    const handleGetContent = () => {
      console.log(" MobContentWithLazy useEffect Normal User Effect ");
      console.log(" GetListFeedContent !!! ");
      if (props.onGetContent) {
        props.onGetContent();
      } else {
        props.onEvent({
          message: "GetListFeedContent",
          params: { patient: props.myProfileDetail?.patient },
        });
      }
    };

    if (!props.myProfileDetail?.patient) {
      return;
    }

    console.log(
      "UseEffect props.myProfileDetail?.patient : ",
      props.myProfileDetail?.patient
    );

    if (props.firebaseUID && props.reload) {
      handleGetContent();
    }
  }, [props.myProfileDetail?.patient, props.firebaseUID]);

  useEffect(() => {
    const handleGetGuestContent = () => {
      console.log(" MobContentWithLazy useEffect Guest User Effect ");
      console.log(
        "check api :",
        props.apiToken,
        " patient : ",
        props.myProfileDetail?.patient
      );
      // Workround
      if (
        props.apiToken &&
        props.apiToken !== "null" &&
        !props.myProfileDetail?.patient
      ) {
        /// มี patient
        console.log(" Not Get Guest List ");
        return;
      }

      if (!props.apiToken || !props.myProfileDetail?.patient) {
        console.log(" GetListFeedContent 1 !!! check run");
        if (props.onGetContent) {
          props.onGetContent();
        } else {
          console.log(" GetListFeedContent 2 !!! check run");

          props.onEvent({
            message: "GetListFeedContent",
            params: { patient: props.myProfileDetail?.patient },
          });
        }
      }
    };

    if (props.firebaseUID && props.reload) {
      handleGetGuestContent();
    }
  }, [props.firebaseUID]);

  useEffect(() => {
    if (marketingContent?.id) {
      const content = props.contentList?.find(
        (item) => item.id === marketingContent?.id
      );
      console.log("update market");
      setMarketingContent(content);
    }
  }, [props.contentList]);

  const stopAllVideo = () => {
    playerListRef.current.map((item: any) => item.playerRef()).filter((item: any) => item)?.forEach((item: any) => item?.pauseVideo?.())
  }
  const handleSeeMoreClick = (item: any, player: any) => {

    console.log(" handleSeeMoreClick props: ", props)
    stopAllVideo()

    // ลดค่าใช้จ่าย
    props.onEvent({
      message: "HandleActionFeedContent",
      params: {
        id: item.id,
        type: "click",
        active: true,
        content_type: item.type,
        onSuccess: () => {},
      },
    });

    setMarketingContent({
      ...item,
      player,
      playerState: player?.getPlayerState?.(),
    });
    player?.pauseVideo?.();
  };

  const handleButtonClick = (data: {
    id: string | null;
    active: boolean;
    type: ActionType;
    content_type: PostData["type"];
    onSuccess: () => any;
  }) => {
    console.log("handleButtonClick: data: ", data);
    console.log("handleButtonClick: type: ", data?.type);

    // ลดค่าใช้จ่าย Reach Click
    props.onEvent({
      message: "HandleActionFeedContent",
      params: {
        id: data.id,
        active: data.active,
        type: data.type,
        content_type: data.content_type,
        onSuccess: data.onSuccess,
      },
    });
  };


  let visibles = props.contentList?.filter((item: any) => item.visible);
  console.debug("visibles ", visibles?.length);
  console.debug('MobContentWithLazy playerListRef: ',   playerListRef);
  return (
    <div>
      {props.contentList
        ?.filter((item: any) => item.visible)
        ?.map((item, index: number) => {
          const isNextVisible =
            index % LIMIT_FEED === LIMIT_FEED - OFFSET_FEED &&
            !(
              props.contentList?.[index + OFFSET_FEED]?.visible != null && props.contentList?.[index + OFFSET_FEED]?.visible
            )
           
          // console.log('handleOnCardScroll props.contentList?.[index + OFFSET_FEED]?.visible: ', props.contentList?.[index + OFFSET_FEED]?.visible);
          // console.log('handleOnCardScroll LIMIT_FEED - OFFSET_FEED: ', LIMIT_FEED - OFFSET_FEED);
          // console.log('handleOnCardScroll index % LIMIT_FEED: ', index % LIMIT_FEED);
          // console.log('handleOnCardScroll isNextVisible: ', isNextVisible);
          if (isNextVisible) {
            console.log(
              "handleOnCardScroll Signal from",
              index,
              "Checking... : ",
              index + OFFSET_FEED,
              "item.id: ",
              item.id
            );
          }
          // console.log('handleOnCardScroll isNextVisible: ', isNextVisible,"index", index);
          // console.log('MobContentWithLazy index: ', index, 'index % LIMIT_FEED', index % LIMIT_FEED , 'LIMIT_FEED - 2', LIMIT_FEED - 2 ,' isNextVisible: ', isNextVisible);

          return <MobContentDetail
              key={"content-detail-" + index}
              ref={(ref) => playerListRef.current[index] = ref}
              stopAllVideo={stopAllVideo}
              followOnly={props.followOnly}
              preferredOnly={props.preferredOnly}
              saveOnly={props.saveOnly}
              selectedHospital={props.selectedHospital}
              onEvent={props.onEvent}
              item={item}
              index={index}
              isNextVisible={isNextVisible ? index + OFFSET_FEED : 0}
              myProfileDetail={props.myProfileDetail}
              handleSeeMoreClick={handleSeeMoreClick}
              onButtonClick={handleButtonClick}
              shareContentTo={props.shareContentTo}
              apiToken={props.apiToken}
              firebaseIsAnonymous={props.firebaseIsAnonymous}
            />

          // return props.followOnly ? (
          //   <>
          //     {item.fake_follow && (
          //       <MobContentDetail
          //         key={"content-detail-" + index}
          //         onEvent={props.onEvent}
          //         item={item}
          //         index={index}
          //         isNextVisible={isNextVisible ? index + OFFSET_FEED : 0}
          //         myProfileDetail={props.myProfileDetail}
          //         handleSeeMoreClick={handleSeeMoreClick}
          //         onButtonClick={handleButtonClick}
          //         shareContentTo={props.shareContentTo}
          //         apiToken={props.apiToken}
          //         firebaseIsAnonymous={props.firebaseIsAnonymous}
          //       />
          //     )}
          //   </>
          // ) : (
          //   <MobContentDetail
          //     key={"content-detail-" + index}
          //     onEvent={props.onEvent}
          //     item={item}
          //     index={index}
          //     isNextVisible={isNextVisible ? index + OFFSET_FEED : 0}
          //     myProfileDetail={props.myProfileDetail}
          //     handleSeeMoreClick={handleSeeMoreClick}
          //     onButtonClick={handleButtonClick}
          //     shareContentTo={props.shareContentTo}
          //     apiToken={props.apiToken}
          //     firebaseIsAnonymous={props.firebaseIsAnonymous}
          //   />
          // );

          // ) : <MobContentSkeleton type="article" />
        })}

      {props.isSkeleton && (
        <>
          <MobContentSkeleton type="article" />
          <MobContentSkeleton type="image" />
        </>
      )}

      {!props.contentList?.length &&
        props.notfoundMessage &&
        !props.isSkeleton && (
          <div className="Item-notFound">
            <div>
              {typeof props.notfoundIcon === "string" ? (
                <img src={props.notfoundIcon} alt="notfound.icon" />
              ) : props.notfoundIcon ? (
                props.notfoundIcon
              ) : null}

              <div>{props.notfoundMessage}</div>
            </div>
          </div>
        )}

      {!!marketingContent && (
        <Dialog
          fullScreen
          open={!!marketingContent}
          // @ts-ignore
          TransitionComponent={Transition}
        >
          <MobContentFull
            onEvent={props.onEvent}
            marketingContent={marketingContent}
            myProfileDetail={props.myProfileDetail}
            onClose={() => setMarketingContent(null)}
            onButtonClick={handleButtonClick}
            isTransition={true}
            loadingMainFeed={props.loadingMainFeed}
            shareContentTo={props.shareContentTo}
            apiToken={props.apiToken}
            firebaseIsAnonymous={props.firebaseIsAnonymous}
          />
        </Dialog>
      )}
    </div>
  );
};

export const MobContentSkeleton: React.FunctionComponent<any> = (props) => {
  return (
    <div style={{ padding: "0 15px" }}>
      <div
        style={{
          backgroundColor: "var(--skeleton-bg-color)",
          width: "100%",
          borderRadius: "15px",
          padding: "15px 15px 5px 15px",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            animation="pulse"
            variant="circular"
            width={30}
            height={30}
          />
          <Skeleton
            animation="pulse"
            variant="rectangular"
            width="60%"
            height={20}
            style={{ marginLeft: "15px" }}
          />
        </div>
        {props.type === "article" && (
          <div
            style={{
              display: "grid",
              alignItems: "flex-start",
              margin: "15px 0",
              gridTemplateColumns: "35% 65%",
            }}
          >
            <Skeleton
              animation="pulse"
              variant="rectangular"
              width="100%"
              height={75}
              style={{ marginLeft: "5px" }}
            />
            <div style={{ display: "grid", marginLeft: "15px" }}>
              <Skeleton animation="pulse" width="90%" height={20} />
              <Skeleton animation="pulse" width="50%" height={20} />
            </div>
          </div>
        )}
        {props.type === "image" && (
          <>
            <Skeleton animation="pulse" width="30%" height={20} />
            <div
              style={{
                display: "grid",
                alignItems: "flex-start",
                margin: "5px 0 15px",
                gridTemplateColumns: "60% 40%",
              }}
            >
              <Skeleton
                animation="pulse"
                variant="rectangular"
                width="100%"
                height={90}
              />
              <div
                style={{ display: "grid", marginLeft: "15px", rowGap: "5px" }}
              >
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height={40}
                />
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height={40}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(MobContentWithLazy);
