import React, { useEffect } from "react";
import { HeaderStyle } from "../common";
import { Icon, Input, Dropdown } from "semantic-ui-react";
import { SearchBox } from "../../react-lib/apps/common";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

const dmTabMap: { [k: number]: string } = {
  0: "Overall",
  1: "Glucose",
  2: "Blood Pressure",
  3: "Weight",
  4: "Nutrition",
  5: "Exercise",
  6: "Emotion & Rest",
  7: "Foot",
  8: "Medication",
  9: "Vaccine",
  10: "Appoiment",
  11: "Content",
  12: "Scoring",
};

// TODO: props.patientId, this.state.patientId ตัวนี้เช็คด้วย ว่ายังใช่้อยู่หรือไม่
const TopBarClinic = (props: any) => {
  const [inputText, setInputText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  // useEffect(() => {
  //   props.onEvent({
  //     message: "handleSelectedDivisionId",
  //     params: { divisionId: props.selectedDivisionId },
  //   });

  // }, [props.selectedDivisionId])

  const handleKeyDown = async (e: any) => {
    if (e.key === "Enter") {
      setIsLoading(true);
      await props.onGetSearchOptions({ searchText: inputText });
      setInputText("");
      setIsLoading(false);
    }
  };

  return (
    <div style={{ ...HeaderStyle, ...props.style }}>
      {props.clinic === "dm" ? (
        <div style={{ display: "flex", flex: "2", alignItems: "center" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              props.setProp("selectedPatient", {});
              props.setProp("showPatientDM", false);
              props.setProp("dmSelectedTab", null);
              props.history.push("/?clinic=dm");
            }}
          >
            {props.appConfig.title}
          </div>
          {/* // TODO: ตัวนี้  */}
          {props.patientId !== null && (
            <div>
              &nbsp;- &nbsp;
              <Icon name="users" /> <FormattedMessage id="bplusClinicKey372" />
            </div>
          )}
          {props.dmTab !== null && (
            <div>&nbsp;•&nbsp;{dmTabMap[props.dmTab]}</div>
          )}
          {/* Selecting Division to Operate on */}
          {props.divisionHasUser.length > 0 && (
            <Dropdown
              style={{
                marginLeft: "10px",
                padding: "5px",
                border: "solid white 1px",
              }}
              className="DmDropdown-custom"
              options={props.divisionHasUser.map(
                (divisionItem: any, index: number) => ({
                  key: divisionItem.division_id,
                  value: divisionItem.division_id,
                  text: divisionItem.division_name,
                })
              )}
              value={props.selectedDivisionId}
              onChange={(e: any, v: any) => {
                // console.log("handleSelectedDivisionId divisionId:", v.value )
                props.onEvent({
                  message: "handleSelectedDivisionId",
                  params: { divisionId: v.value },
                });
                // props.setProp("selectedDivisionId", v.value);
              }}
            />
          )}
        </div>
      ) : props.clinic === "mom" ? (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e: any) => {
            props.setProp("selectedPatient", {});
            props.setProp("showPatientDM", false);
            props.setProp("dmSelectedTab", null);
            props.history.push("/?clinic=mom");
          }}
        >
          {props.appConfig.title}
          {props.patientId !== null && (
            <div>
              &nbsp;- &nbsp;
              <Icon name="users" /> <FormattedMessage id="bplusClinicKey372" />
            </div>
          )}
          {props.divisionHasUser.length > 0 && (
            <Dropdown
              style={{
                marginLeft: "10px",
                padding: "5px",
                border: "solid white 1px",
              }}
              options={props.divisionHasUser.map(
                (divisionItem: any, index: number) => ({
                  key: divisionItem.division_id,
                  value: divisionItem.division_id,
                  text: divisionItem.division_name,
                })
              )}
              value={props.selectedDivisionId}
              onChange={(e: any, v: any) => {
                // console.log("handleSelectedDivisionId divisionId:", v.value )
                props.onEvent({
                  message: "handleSelectedDivisionId",
                  params: { divisionId: v.value },
                });
              }}
            />
          )}
        </div>
      ) : props.clinic === "marketing" ? (
        <div style={{ display: "flex", flex: "2", alignItems: "center" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              props.history.push("/?clinic=marketing");
            }}
          >
            My B+ Marketing Management
            {props.patientId !== null && (
              <div>
                &nbsp;- &nbsp;
                <Icon name="users" />{" "}
                <FormattedMessage id="bplusClinicKey372" />
              </div>
            )}
            {props.divisionHasUser.length > 0 && (
              <Dropdown
                style={{
                  marginLeft: "10px",
                  padding: "5px",
                  border: "solid white 1px",
                }}
                options={props.divisionHasUser.map(
                  (divisionItem: any, index: number) => ({
                    key: divisionItem.division_id,
                    value: divisionItem.division_id,
                    text: divisionItem.division_name,
                  })
                )}
                value={props.selectedDivisionId}
                onChange={(e: any, v: any) => {
                  // console.log("handleSelectedDivisionId divisionId:", v.value )
                  props.onEvent({
                    message: "handleSelectedDivisionId",
                    params: { divisionId: v.value },
                  });
                }}
              />
            )}
          </div>
        </div>
      ) : props.clinic === "preferred" ? (
        <div style={{ display: "flex", flex: "2", alignItems: "center" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => {
              props.history.push("/?clinic=preferred");
            }}
          >
            My B+ Management
            {props.patientId !== null && (
              <div>
                &nbsp;- &nbsp;
                <Icon name="users" />{" "}
                <FormattedMessage id="bplusClinicKey372" />
              </div>
            )}
            {props.groupedOrganizationList?.length > 0 && (
              <Dropdown
                style={{
                  marginLeft: "10px",
                  padding: "5px",
                  border: "solid white 1px",
                }}
                options={props.groupedOrganizationList
                  ?.map((organ: any, index: number) => ({
                    key: organ.id,
                    value: organ.code,
                    text: `${organ.name} (${organ.name_en})`,
                  }))}
                value={props.selectedOrganizationCode}
                onChange={(e: any, v: any) => {
                  console.log("handleSelectedDivisionId divisionId:", v )

                  let id = v.options.find((item: any) => item.value === v.value)?.key
                  props.onEvent({
                    message: "handleSelectedOrganization",
                    params: { organizeCode: v.value, organizeId: id },
                  });
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e: any) => {
            props.history.push("/");
          }}
        >
          {props.appConfig.title}
        </div>
      )}
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flex: "1",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* {this.state.pythonLoaded ? 
      <Icon name="checkmark" style={{ marginRight: "10px" }} color="green" />:
      <Loader size="mini" active inline style={{ marginRight: "10px" }}/>
      } */}

        {!props.hideSearch && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "35px",
            }}
          >
            <Input
              icon="search"
              style={{ width: "330px", fontSize: ".78571429em" }}
              placeholder="ค้นหารายชื่อคนไข้"
              onChange={(e) => {
                setInputText(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              value={inputText}
              loading={isLoading}
            />

            {/* <SearchBox
            ref={props.patientRef}
            // defaultId={props.patientRef?.current?.getId()}
            // defaultText={props.patientRef?.current?.getText()}
            textField="patient_name"
            onGetSearchOptions={props.onGetSearchOptions}
            icon="search"
            inputStyle={{ width: "330px", fontSize: ".78571429em" }}
            placeholder="ค้นหารายชื่อคนไข้"
            onSelectOption={({ item }: any = {}) => {
              props.onEvent({
                message: "handleSelectPatientSearch",
                params: { data: item },
              });
            }}
            onClear={() => {
              props.onEvent({
                message: "handleSelectPatientSearch",
                params: { data: null },
              });
            }}
          /> */}
          </div>
        )}

        <div
          onClick={(e) => {
            props.onEvent({
              message: "Logout",
              params: {
                logoutCallback: (controller: any) => {
                  props.setProp("loggedin", false);
                  props.history.push("/");
                },
              },
            });
          }}
        >
          <FormattedMessage id="bplusClinicKey301" />
        </div>
      </div>
    </div>
  );
};

export default TopBarClinic;
