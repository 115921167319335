import React, { useState, useMemo, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { useLocation, useHistory } from "react-router";

// UX
import CardRadioList from "./CardRadioList";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// telephar

// Interface
import { Event } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type MakeAppointmentForProps = {
  onEvent: (e: Event) => any;
};

export type AFLocationType = {
  families?: any[];
  hospital?: Record<string, any> | null;
  storedState?: {
    appointmentFor?: Record<string, any>;
    member?: Record<string, any>;
  };
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  bg: "linear-gradient(0deg, rgba(1, 71, 163, 0.03), rgba(1, 71, 163, 0.03))",
  very_light_blue: "rgba(1, 71, 163, 0.03)",
  light_blue: "rgba(204, 218, 237, 1)",
};

const boxCardStyles = {
  margin: "0.5rem 0 -0.75rem",
  "& > button": {
    width: "100%",
    boxShadow: "none",
    justifyContent: "flex-start",
    backgroundColor: "white",
    padding: "7px 14px",
    borderRadius: "8px",
    marginTop: "0.35rem",
    marginBottom: "0.5rem",
    "& img": {
      width: "34px",
      borderRadois: "500rem",
      marginRight: "0.75rem",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& > label": {
      color: COLORS.grey,
    },
    "&.active": {
      backgroundColor: COLORS.light_blue,
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "10px 16px 16px",
    display: "flex",
    minHeight: "calc(100vh - 50px)",
    flexDirection: "column",
    "& .MuiPaper-root.active": {
      background: COLORS.bg,
    },
    "&:before": {
      top: "-50px",
      minHeight: "100vh",
    },
  },
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: COLORS.grey,
    margin: "0.75rem 0 1rem",
  },
  box_card: boxCardStyles,
}));

const MakeAppointmentFor = (props: MakeAppointmentForProps) => {
  const classes = useStyles();
  const location = useLocation<AFLocationType>();
  const history = useHistory();

  const [selectedFor, setSelectedFor] = useState<any>(null);
  const [selectedMemberId, setSelectedMemberId] = useState<any>(null);

  // Memo Effect
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      setSelectedFor(storedState.appointmentFor);
      setSelectedMemberId(storedState.member?.id);
    }
  }, [locState.storedState]);

  useEffect(() => {
    if (selectedFor === "myself") {
      setSelectedMemberId(null);
    }
  }, [selectedFor]);

  // Memo
  const families = useMemo(() => {
    return location.state?.families || [];
  }, [location.state]);

  const options = useMemo(() => {
    return [
      {
        id: "myself",
        name: "ตัวเอง",
      },
      {
        id: "family",
        name: "บุคคลในครอบครัว",
        families,
      },
    ];
  }, [families]);

  const allowConfirm = useMemo(() => {
    return (
      selectedFor === "myself" || (selectedFor === "family" && selectedMemberId)
    );
  }, [selectedFor, selectedMemberId]);

  // Handler
  const handleConfirm = () => {
    const family = families.find(
      (item: any) => item.patient === selectedMemberId
    );

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: URLS.APPOINTMENT_REASON,
        state: {
          appointmentFor: selectedFor,
          member: family || null,
        },
      },
    });
  };

  return (
    <div>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="ทำการนัดหมายแพยท์ให้กับ"
        whiteTheme={true}
        bgGradient={true}
        // cscreenonfig
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      <div className={`${classes.screen} bg-gradient-container`}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>ทำนัดหมายแพยท์ให้กับ</div>

          <CardRadioList
            items={options as any[]}
            selected={selectedFor}
            member={selectedMemberId}
            // callback
            onSelect={setSelectedFor}
            onMemberSelect={setSelectedMemberId}
          />
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!allowConfirm}
              // callback
              onClick={handleConfirm}
            >
              ถัดไป
            </MuiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MakeAppointmentFor);
