import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

const TabPath = (props: any) => {
  const intl = useIntl();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    if (props.selectedTabIndex) {
      setSelectedTabIndex(props.selectedTabIndex);
    } else if (props.history && props.history.location.pathname !== "/") {
      try {
        const keys = props.history.location.pathname.split("/");
        console.log(props.history.location.pathname);
        console.log(keys, keys.length, props.level)
        if (keys.length > props.level) {
          const key = keys[props.level]
          const index = props.data?.map((item: any) => item.key).indexOf(key);
          setSelectedTabIndex(index);
        }        
      } catch (e: any) {
        console.log(e);
      }
    }
  }, [])

  useEffect(() => {
    if (props.selectedTabIndex) {
      setSelectedTabIndex(props.selectedTabIndex);
    }
  }, [props.selectedTabIndex]);
  
  return(
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <div 
        style={{ 
          width: props.tabWidth || "250px",
          display: "flex", 
          flexDirection: "column",
          border: "solid #cccccc 1px",
          overflowY: "scroll" 
        }}>
        {(props?.topTab ? [props.topTab] : []).concat(
          props?.data.map((item: any, index: number) => (
            (!props.mobileScreen || showTab || (!showTab && selectedTabIndex === index)) &&
            <div 
              key={index}
              style={{ 
                backgroundColor: props.data[selectedTabIndex]?.key === item.key ? "#d6ecf3" : "white",
                padding: props.padding || "5px",
                cursor: "pointer"
              }}
              onClick={(e) => { 
                if (props.mobileScreen) {
                  setShowTab(!showTab);
                }
                setSelectedTabIndex(index);
                if (props.history && props.data) {
                  try {
                    let keys = props.history.location.pathname.split("/");
                    if (keys.length > props.level) {
                      keys[props.level] = props.data?.[index].key;
                      keys.splice(props.level + 1);
                      props.history.push(keys.join("/") + window.location.search);
                    } else if (keys.length == props.level) {
                      keys.push(props.data?.[index].key);
                      props.history.push(keys.join("/") + window.location.search);
                    }
                  } catch (e: any) {
                    console.log(e);
                  }
                }
                props?.onTabChange?.(index);
              }}>
              {item.name}
            </div>
          ))
        )}
      </div>
      <div style={{ flex: 1 }}>
        {props?.data[selectedTabIndex]?.render() || ""}
      </div>
    </div> 
  );
};

export default TabPath;