import WasmController from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";

// Interface
import * as MedicalCertificateI from "./phr/MedicalCertificateInterface";
import * as CheckupI from "./phr/CheckupInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface"

// APIs

export type State = {
  language?: "en-US" | "th-TH";
  intl?: IntlShape;
  userId?: string | number;
  loggedin?: boolean;
  apiToken?: string;
  // message
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  loadingHospital?: boolean;
  // selectedHospital?: any;
} & CheckupI.State &
  MedicalCertificateI.State &
  SelectHospitalI.State;


export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  userId: "",
  loggedin: false,
  // message
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  loadingHospital: true,
  // selectedHospital: null,

  ...CheckupI.StateInitial,
  ...MedicalCertificateI.StateInitial,
  ...SelectHospitalI.StateInitial
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "GetLoginInfo"; params: {} }
  | { message: "closeSnackMessage" }
  | { message: "HandleEvent"; params: { name: string } }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "handleMobileDownloadMedCert"; params: {} }
  | { message: "handleMobileDownloadCheckup"; params: {} }
  | CheckupI.Event
  | MedicalCertificateI.Event
  | SelectHospitalI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  console.log('MobPHR DidMount: ');

  SelectHospitalI.SelectedInitialHospital(controller, params);
};

export const DidUpdate: Handler = async (controller, params) => {};

export const closeSnackMessage: Handler = async (controller) => {
  controller.setState({ errorMessage: null, successMessage: null });
};
