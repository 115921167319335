// Autogenerated by utils/python/genmain.py
import React from "react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
import TabPath from "react-lib/frameworks/TabPath";
import PatientDashBoard from './PatientDashboard';
//import TagManager from '../content/TagManager';
//import Content from '../content/Content';
import { Post } from '../post/Post';
// import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';


const Main = (props: any) => {
  return(
    <div style={{ height:"94vh" }}>
      <TabPath
        level={1}
        history={props.history}
        tabWidth={props.hideTab ? "0" : "10vw"}
        data={[
          { name: "Segmentation", key: "segmentation", render: 
            () => (
              <div style={{ height: "100%"}} >
                <PatientDashBoard 
                  hideTab={props.hideTab}
                  onEvent={props.onEvent}
                  patientSegmentList={props.patientMarketingList}
                  patientSegmentIndex={props.patientSegmentIndex}
                  patientIndex={props.patientIndex}
                  patientId={props.patientId}
                  patientSegmentData={props.patientSegmentData}
                  statDict={props.statDict}
                  medicationList={props.medicationList}
                  footList={props.footList}
                  openPatientDetail={() => { props.history.push("/patientDetail/?app=DM")} }
                  setPatientIndex={(index: number) => {
                    props.onEvent({ 
                      message: "SetPatientIndex", 
                      params: { index: index }
                    })
                  }}
                  setProp={props.setProp}
                />
              </div>
            )
          },
          { name: "Post", key: "post", render: 
            () => (
              <Post
                onEvent={props.onEvent}
                tagList={props.tagList}
                postList={props.postList}
                setProp={props.setProp}
                selectedPostIndex={props.selectedPostIndex}
              />
            )
          },
          { name: "Promotion", key: "promotion", render: 
            () => (
              <div>
                <FormattedMessage id="bplusClinicKey401" />
              </div>
            )
          },
        ]}
      />
    </div>
  )
}

export default Main
