import React, { useEffect, useMemo } from "react";
// MUI
import Skeleton from "@mui/material/Skeleton";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Types
type TopServicesProps = {
  onEvent: (e: Event) => any;
  // data
  hospitalCode?: string;
  topServiceList?: any[];
  loading?: boolean;
  isRefresh: boolean;
  // config
  simulator?: boolean;
  hideTopService?: boolean;
  // callback
  onShowTopService?: (state: boolean) => any;
  onSelect?: (selected: Record<string, any>) => any;
} & Pick<State, "topServiceList">;

// Styles
const COLORS = {
  shadow: "0px 4px 8px 0px rgba(44, 44, 44, 0.08)",
};

const buttonOuterStyles = {
  minWidth: "80px",
  maxWidth: "80px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .title": {
    color: "white",
    marginTop: "8px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
    width: "100%",
    padding: "0 2px",
    textAlign: "center",
  },
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "0",
    marginTop: ".75rem",
    color: "white",
    paddingLeft: "8px",
  },
  box_group: {
    display: "inline-flex",
    overflow: "auto",
    width: "calc(100% + 30px)",
    margin: "0 -16px",
    padding: "13px 0 0 16px",
  },
  button_inner: {
    "&.MuiButton-root": {
      boxShadow: COLORS.shadow,
      borderRadius: "8px",
      backgroundColor: "white",
      minWidth: "50px",
      maxWidth: "50px",
      height: "50px",
      padding: 0,
      "& img": {
        width: "30px",
        height: "30px",
      },
    },
  },
  button_outer: buttonOuterStyles,
}));

// Const
export const TOP_SERVICES = "TopServices";

const TopServices = (props: TopServicesProps) => {
  const classes = useStyles();

  // Memo Effect
  const filterTopServices = useMemo(() => {
    const skeleton = new Array(5)
      .fill("")
      .map((_, index) => ({ id: index, skeleton: true }));

    return (props.loading ? skeleton : props.topServiceList || [])
      .filter(
        (item: any) =>
          item.type === "med_program" ||
          item.type === "subspecialty" ||
          item.skeleton ||
          (item.id === "telemed" && props.hospitalCode === "1") ||
          (item.id === "ecoupon" && props.hospitalCode === "1")
      )
      .sort((a: any, b: any) => a?.seq - b?.seq);
  }, [props.loading, props.topServiceList, props.hospitalCode]);

  const showTopService = useMemo(() => {
    return (
      !props.hideTopService &&
      !!filterTopServices.length &&
      !!props.hospitalCode
    );
  }, [props.hideTopService, filterTopServices.length, props.hospitalCode]);

  // Effect
  useEffect(() => {
    if (props.simulator) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      props.onEvent({
        message: "HandleGetTopService",
        params: { code: params.code, simulator: true },
      });
    } else if (props.hospitalCode && props.isRefresh) {
      props.onEvent({
        message: "HandleGetTopService",
        params: { card: TOP_SERVICES ,...(props.hospitalCode && {code: props.hospitalCode})},
      });
    }
  }, [props.hospitalCode]);

  useEffect(() => {
    const update = (e: any) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (e.data == "updatesimulator") {
        props.onEvent({
          message: "HandleGetTopService",
          params: { code: params.code, simulator: true },
        });
      }
    };

    window.addEventListener("message", update);

    return () => {
      window.removeEventListener("message", update);
    };
  }, []);

  useEffect(() => {
    props.onShowTopService?.(showTopService);
  }, [showTopService]);

  // Handler
  const handleImageError = (e: any) => {
    const path = window.location.origin + "/images/child/outsidemedicine.png";

    e.target.onerror = null;
    e.target.src = path;
  };

  const handleSelect = (item: any) => () => {
    props.onSelect?.(item);
  };

  return (
    <div>
      {showTopService && (
        <>
          {!props.loading ? (
            <>
              <div className={classes.title}>Top Services</div>
              <div className={classes.box_group}>
                {filterTopServices.map((item) => (
                  <div
                    aria-hidden="true"
                    key={"button-service" + item.id}
                    className={classes.button_outer}
                    onClick={handleSelect(item)}
                  >
                    <MuiButton
                      className={classes.button_inner}
                      variant="contained"
                    >
                      <img
                        onError={handleImageError}
                        src={item.icon_url || item.icon}
                      />
                    </MuiButton>
                    <div className="title">{item.display}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <CardSkeleton items={filterTopServices} />
          )}
        </>
      )}
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      CardSkeleton                      */

/* ------------------------------------------------------ */
const CardSkeleton = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>
        <Skeleton
          animation="pulse"
          height={14}
          style={{ marginBottom: 6 }}
          width={50}
        />
      </div>
      <div className={classes.box_group}>
        {props.items?.map((item: any, index: number) => (
          <div
            className={classes.button_outer}
            key={"button-skeleton" + item.id}
          >
            <Skeleton
              animation="pulse"
              variant="rectangular"
              width={40}
              height={40}
              style={{
                marginBottom: "12px",
                color: "red",
                padding: "9px 0 6px",
              }}
            />
            <Skeleton
              animation="pulse"
              height={14}
              style={{ marginBottom: 6 }}
              width={50}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default React.memo(TopServices);
