import React, { useState, useEffect, forwardRef } from "react";

import {
  Image,
  Button,
  Radio,
  Divider,
  Input,
  InputProps,
} from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// Styles
import "./RegisterCSS.scss";

// UI
import InputOTP from "./InputOTP";

// Type
import { registerInfo } from "./TypeModal";

const icons = {
  passport: "/images/register/create_password.png",
  verifyEmail: "/images/register/verify_otp.png",
  alert: "/images/register/alert_notmatch.png",
};

type VerifyOTPProps = {
  onEvent: (e: any) => any;
  setProp: any;
  registerInfo?: registerInfo;
  history: any;
  refCode?: string;
  loading?: boolean;
  uiMode?: "LOGIN" | "NEW_USER" | "MATCH" | "NOT_MATCH" | "FORGOT_PASS";
};

const VerifyOTP = (props: VerifyOTPProps) => {
  const [timeResendOTP, setTimeResendOTP] = useState<number>(30);
  const [stepOTP, setStepOTP] = useState<number>(0);
  const [selectPhone, setSelectPhone] = useState<string>("");
  const [optionsUser, setOptonsUser] = useState<any>([]);

  useEffect(() => {
    if (
      props.registerInfo?.username?.includes("-") ||
      checkValidEmail(props.registerInfo?.username || "").valid
    ) {
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: {
          key: "typeUsername",
          value: "email",
        },
      });
    }

    if (selectPhone === "" && props.uiMode !== "FORGOT_PASS") {
      setSelectPhone(props.registerInfo?.username || "-");
    }
  }, [props.registerInfo?.username]);

  useEffect(() => {
    setTimeResendOTP(30);
    setStepOTP(1);
  }, [props.refCode]);

  useEffect(() => {
    if (props.registerInfo?.options) {
      let arrayObject: { key: string; value: any }[] = [];
      Object.entries(props.registerInfo?.options || {}).forEach(
        ([key, value]) => {
          arrayObject.push({ key: key, value: value });
        }
      );
      console.log("bug ~ useEffect 1:");
      if (props.uiMode === "FORGOT_PASS" && arrayObject.length > 0) {
        if (arrayObject[0].value.includes("**")) {
          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: {
              key: "typeUsername",
              value: "email",
            },
          });
        } else {
          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: {
              key: "typeUsername",
              value: "phoneNumber",
            },
          });
        }
        console.log("bug ~ useEffect 2: arrayObject.length ",arrayObject.length);
        if (arrayObject.length > 1) {
          setSelectPhone(arrayObject[0].key);
          setOptonsUser(arrayObject);
        } else {
          console.log("bug ~ useEffect 3: run HandleSetValueRegisterInfo ");
          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: {
              key: "username",
              value: arrayObject[0].value,
            },
          });
          setSelectPhone(arrayObject[0].key);
        }
      } else {
        setOptonsUser(arrayObject);
      }
    }
  }, [props.registerInfo?.options]);

  useEffect(() => {
    console.log("bug ~ useEffect 3:");
    const timer = setTimeout(() => {
      if (timeResendOTP > 0) {
        setTimeResendOTP(timeResendOTP - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timeResendOTP]);

  const handleRequestToken = () => {
    if (!props.loading) {
      props.onEvent({
        message: "HandleRequestToken",
        params: { history: props.history },
      });
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleRequestOTP = () => {
    handleClearError("message");
    if (!props.loading) {
      props.onEvent({
        message: "HandleRequestOTP",
        params: { history: props.history, otherUsername: selectPhone ,...(props.registerInfo?.smsAuth && {smsAuth : props.registerInfo?.smsAuth})},
      });
    }
  };

  const normalizeInput = (value: string) => {
    // console.log("saika ~ value:", value);
    if (!value) return value;
    // +xx xxx xxx xxx
    if (value.length > 10) {
      const normalizeNumber = value.slice(value.length - 9, value.length);
      const normalizeCountryCode = value.slice(0, value.length - 9);
      //(+x) xx xxx xxxx
      return `(+${normalizeCountryCode})  ${normalizeNumber.slice(
        0,
        normalizeNumber.length - 7
      )} ${normalizeNumber.slice(
        normalizeNumber.length - 7,
        normalizeNumber.length - 4
      )} ${normalizeNumber.slice(
        normalizeNumber.length - 4,
        normalizeNumber.length
      )}`;
    } else {
      return `${value}`;
    }
  };

  const handleHidePhoneNumber = (phoneNum: string, hide: boolean) => {
    if (
      phoneNum.includes("-") ||
      checkValidEmail(phoneNum).valid ||
      props.registerInfo?.typeUsername === "email"
    ) {
      return phoneNum.replace("-", "@");
    } else {
      if (hide) {
        return phoneNum;
      } else {
        return normalizeInput(phoneNum || "") || "-";
      }
    }
  };

  const checkValidEmail = (
    email: string
  ): { valid: boolean; message?: any } => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return {
      valid: regex.test(email),
      message: regex.test(email) ? "" : "อีเมล ไม่ถูกต้อง",
    };
  };

  const handleSelectLabel = (state: string) => {
    switch (state) {
      case "NEW_USER":
        return <FormattedMessage id="bplusClinicKey1002" />;
      case "LOGIN":
        return <FormattedMessage id="bplusClinicKey1003" />;
      case "MATCH":
        return <FormattedMessage id="bplusClinicKey1004" />;
      case "NOT_MATCH":
        return <FormattedMessage id="bplusClinicKey1005" />;
      case "FORGOT_PASS":
        return props.registerInfo?.typeUsername === "email" ? (
          <FormattedMessage id="bplusClinicKey1000" />
        ) : (
          <FormattedMessage id="bplusClinicKey1001" />
        );
    }
  };

  const handleButtonLabel = (state: string) => {
    switch (state) {
      case "NOT_MATCH": {
        return <FormattedMessage id="bplusClinicKey966" />;
      }
      default: {
        return <FormattedMessage id="common.confirm" />;
      }
    }
  };

  const handleSelectPhoneNumber = (phone_num: string) => {
    setSelectPhone(phone_num);
  };

  const handleCheckFile = () => {
    let disable = false;
    if (
      props.registerInfo?.otpCode?.length !== 6 ||
      isNaN(+`${props.registerInfo?.otpCode}`)
    ) {
      disable = true;
    }
    return disable;
  };
  console.log("saika ~ props.registerInfo:", props.registerInfo);

  return (
    ////////////////////////////////////////////////////////////////
    //                       inputOtp                             //
    ////////////////////////////////////////////////////////////////
    <>
      {props.refCode ? (
        <>
          <div style={{ width: "100%" }}>
            <div className="title-text-blue">
              {<FormattedMessage id="bplusClinicKey986" />}
            </div>
            <div className="text-gray">
              {props.registerInfo?.typeUsername === "email" ? (
                <FormattedMessage id="bplusClinicKey988" />
              ) : (
                <FormattedMessage id="bplusClinicKey989" />
              )}
            </div>
            <div className="verify-number">
              {handleHidePhoneNumber(props.registerInfo?.username || "", false)}
            </div>
            <div className="div-center">
              <InputOTP
                setProp={props.setProp}
                length={6}
                refCode={props.refCode || ""}
                onChangeOTP={(otp: string) => {
                  props.onEvent({
                    message: "HandleSetValueRegisterInfo",
                    params: { key: "otpCode", value: otp },
                  });
                }}
                onKeyEnter={handleRequestToken}
                autoFocus
                inputClassName={"otpInput"}
                className={"otpContainer"}
                minuteStart={5}
                registerInfo={props.registerInfo}
                // secondsStart={0}
              />
            </div>
            <div className="div-center" style={{ margin: "10px 0px 30px" }}>
              <Image src={icons.passport} />
            </div>
          </div>
          <div className="div-center-bottom">
            <div
              className="div-center"
              style={{ margin: "5px 0px", flexDirection: "column" }}
            >
              <div
                className="div-center"
                style={{ margin: "5px 0px" }}
                onClick={() => {
                  if (timeResendOTP < 1) {
                    handleRequestOTP();
                  }
                }}
              >
                {/* `กรุณารอ  วินาที ก่อนขอรหัส OTP อีกครั้ง` */}
                {timeResendOTP > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      color: "#797878",
                      whiteSpace: "pre-line",
                      flexWrap: "wrap",
                      justifyContent: "center"
                    }}
                  >
                    {<FormattedMessage id="bplusClinicKey1058"/>}
                    {
                      <div className="highlight">{` ${timeResendOTP} `}{<FormattedMessage id="bplusClinicKey1059"/>}</div>
                    }
                    {<FormattedMessage id="bplusClinicKey1060"/>}
                  </div>
                ) : (
                  <FormattedMessage id="bplusClinicKey987" />
                )}
              </div>
              <div className="bplus-buttom">
                <Button
                  loading={props.loading}
                  disabled={handleCheckFile()}
                  onClick={handleRequestToken}
                >
                  {<FormattedMessage id="common.confirm" />}
                </Button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      ) : (
        ////////////////////////////////////////////////////////////////
        //                       VerifyOtp                            //
        ////////////////////////////////////////////////////////////////
        <>
          <div style={{ width: "100%" }}>
            <div className="title-text-blue">
              {props.uiMode === "FORGOT_PASS" ? (
                <FormattedMessage id="bplusClinicKey965" />
              ) : (
                <FormattedMessage id="bplusClinicKey985" />
              )}
            </div>
            {props.uiMode === "LOGIN" && (
              <div className="text-black-bold" style={{ margin: "5px 0px" }}>
                {props.registerInfo?.typeUsername === "email" ? (
                  <FormattedMessage id="bplusClinicKey990" />
                ) : (
                  <FormattedMessage id="bplusClinicKey991" />
                )}
              </div>
            )}
            {props.uiMode !== "FORGOT_PASS" && (
              <div className="text-gray">
                {handleSelectLabel(props.uiMode || "")}
              </div>
            )}
            {props.uiMode === "NOT_MATCH" ? (
              <div>
                <Divider />
                {optionsUser?.length > 0 ? (
                  <div>
                    <div
                      className="text-black-bold"
                      style={{ margin: "10px 0px" }}
                    >
                      {props.registerInfo?.typeUsername === "email" ? (
                        <FormattedMessage id="bplusClinicKey992" />
                      ) : (
                        <FormattedMessage id="bplusClinicKey993" />
                      )}
                    </div>
                    <div className="text-gray">
                      {props.registerInfo?.typeUsername === "email" ? (
                        <FormattedMessage id="bplusClinicKey994" />
                      ) : (
                        <FormattedMessage id="bplusClinicKey995" />
                      )}
                    </div>
                    {optionsUser.map((item: { key: string; value: any }) => (
                      <div
                        key={item.key}
                        className={`list-radio-phone${
                          selectPhone === item.key ? "-active" : ""
                        }`}
                        onClick={() => setSelectPhone(item.key)}
                      >
                        <Radio
                          name="radioGroup"
                          value={selectPhone}
                          checked={selectPhone === item.key}
                        />
                        <div>{handleHidePhoneNumber(item.value, true)}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="not-match-no-number">
                    <Image
                      src={icons.alert}
                      style={{ width: "20px", height: "20px" }}
                    />
                    {props.registerInfo?.typeUsername === "email" ? (
                      <FormattedMessage id="bplusClinicKey996" />
                    ) : (
                      <FormattedMessage id="bplusClinicKey997" />
                    )}
                  </div>
                )}
                <div className="text-gray">
                  {props.registerInfo?.typeUsername === "email" ? (
                    <FormattedMessage id="bplusClinicKey998" />
                  ) : (
                    <FormattedMessage id="bplusClinicKey999" />
                  )}
                </div>
                <div
                  className={`list-radio-phone${
                    selectPhone === props.registerInfo?.username
                      ? "-active"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectPhoneNumber(props.registerInfo?.username || "-")
                  }
                >
                  <Radio
                    name="radioGroup"
                    value={selectPhone}
                    checked={selectPhone === props.registerInfo?.username}
                  />
                  <div>
                    {handleHidePhoneNumber(
                      props.registerInfo?.username || "",
                      false
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {props.uiMode === "FORGOT_PASS" && (
                  <div className="info-forgot-verify">
                    <div className="text-gray">
                      {<FormattedMessage id="bplusClinicKey964" />}
                    </div>
                    <div className="text-black-bold">
                      {props.registerInfo?.valuecid || "-"}
                    </div>
                    <div className="text-gray">
                      {props.registerInfo?.typeUsername === "email" ? (
                        <FormattedMessage id="bplusClinicKey1000" />
                      ) : (
                        <FormattedMessage id="bplusClinicKey1001" />
                      )}
                    </div>
                  </div>
                )}
                <>
                  {(optionsUser?.length > 1 && props.uiMode === "FORGOT_PASS") ? (
                    <>
                      {optionsUser.map((item: { key: string; value: any }) => (
                        <div
                          key={item.key}
                          className={`verify-number${
                            selectPhone === item.key ? "-active" : ""
                          }`}
                          style={{ flexDirection: "row", margin: "10px 15px" }}
                          onClick={() => {
                            setSelectPhone(item.key);
                            props.onEvent({
                              message: "HandleSetValueRegisterInfo",
                              params: {
                                key: "username",
                                value: item.value,
                              },
                            });
                          }}
                        >
                          <Radio
                            name="radioGroup"
                            value={selectPhone}
                            checked={selectPhone === item.key}
                          />
                          <div style={{ margin: "0px 10px" }}>
                            {item.value}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      className="verify-number"
                      style={{
                        ...(props.uiMode === "FORGOT_PASS" && {
                          margin: "10px 15px 30px",
                        }),
                      }}
                    >
                      {props.uiMode === "FORGOT_PASS"?  props.registerInfo?.username : handleHidePhoneNumber(
                        props.registerInfo?.username || "-",
                        false
                      )}
                    </div>
                  )}
                </>
              </div>
            )}
            {["LOGIN", "MATCH", "FORGOT_PASS"].includes(props.uiMode || "") && (
              <div className="div-center" style={{ margin: "5px 0px" }}>
                <Image
                  src={
                    props.uiMode === "MATCH" &&
                    props.registerInfo?.typeUsername === "email"
                      ? icons.verifyEmail
                      : icons.passport
                  }
                />
              </div>
            )}
          </div>
          <div className="div-center-bottom">
            <div
              className={
                props.registerInfo?.error?.message ? "error" : "normal"
              }
            >
              {props.registerInfo?.error?.message &&
                `${props.registerInfo?.error?.message}`}
            </div>
            <div className="bplus-buttom">
              <Button loading={props.loading} onClick={handleRequestOTP}>
                {handleButtonLabel(props.uiMode || "")}
              </Button>
            </div>
            <div
              className="div-center"
              style={{ margin: "5px 0px" }}
              onClick={() => props.history.goBack()}
            >
              {<FormattedMessage id="common.cancel" />}
            </div>
          </div>
        </>
        // </div>
      )}
    </>
  );
};

export default React.memo(VerifyOTP);
