import React, {
  useMemo,
  useState,
  useRef,
  MutableRefObject,
  forwardRef,
  Ref,
  useImperativeHandle,
  useEffect,
  ReactElement,
  CSSProperties,
} from "react";
// MUI
import MuiButton from "@mui/material/Button";

import Cookies from "js-cookie";

// Types
type ButtonPaymentProps = {
  // Controller
  controller?: any;
  apiToken?: string;
  title?: string | ReactElement;
  invoiceId?: number;
  disabled?: boolean;
  // style
  style?: CSSProperties;
  // callback
  onClick?: () => any;
};

const ButtonPayment = (props: ButtonPaymentProps, ref: Ref<any>) => {
  const apiToken = Cookies.get("apiToken");

  const [paymentRedirect, setPaymentRedirect] = useState<Record<string, any>>(
    {}
  );

  const submitRef = useRef() as MutableRefObject<HTMLButtonElement>;

  useImperativeHandle(ref, () => ({
    onPayment: handlePostPaymentParameters,
  }));

  // Memo Effect
  const genPaymentForm = useMemo(() => {
    return Object.keys(paymentRedirect).map((key) => {
      if (key === "endpoint" || key === "method") {
        return null;
      }

      return (
        <input
          key={key}
          type="hidden"
          name={key}
          value={paymentRedirect[key] ? paymentRedirect[key] : ""}
        />
      );
    });
  }, [paymentRedirect]);

  // Effect
  useEffect(() => {
    if (genPaymentForm?.[2] && submitRef.current) {
      const form = submitRef.current?.parentElement as HTMLFormElement;

      form?.submit();
    }
  }, [genPaymentForm]);

  const handlePostPaymentParameters = async (id: number) => {
    if (props.controller) {
      const [res] = await props.controller.postPaymentParameters({
        apiToken: props.apiToken ? props.apiToken : apiToken,
        invoiceId: id,
      });

      if (res) {
        setPaymentRedirect(res);
      } else {
        setPaymentRedirect({});
      }
    }
  };

  const handleClick = () => {
    if (props.invoiceId) {
      handlePostPaymentParameters(props.invoiceId);
    } else {
      props.onClick?.();
    }
  };

  return (
    <>
      {props.title && (
        <div
          className="button-submit-bottom"
          style={{
            padding: "0.5rem 0 0.25rem 0",
            justifyContent: "flex-start",
          }}
        >
          <MuiButton
            variant="contained"
            color="primary"
            disabled={props.disabled}
            style={{
              width: "auto",
              minWidth: "100px",
              padding: "3px 9px",
              fontSize: "1rem",
              borderRadius: "8px",
              ...(props.style || {}),
            }}
            onClick={handleClick}
          >
            {props.title}
          </MuiButton>
        </div>
      )}

      {paymentRedirect["method"] && paymentRedirect["endpoint"] && (
        <form
          method={paymentRedirect["method"]}
          action={paymentRedirect["endpoint"]}
          style={{ display: "none" }}
        >
          {genPaymentForm}

          <button ref={submitRef} type="submit" />
        </form>
      )}
    </>
  );
};

const ForwardedPhoneNumberInput = forwardRef<any, ButtonPaymentProps>(
  ButtonPayment
);

export default React.memo(ForwardedPhoneNumberInput);
