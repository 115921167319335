import React, { CSSProperties, useMemo, useState } from "react";
import { Accordion, Grid, Menu, Table } from "semantic-ui-react";

// UI
import WebUMPatientSelection from "./detail/WebUMPatientSelection";
import WebUMBabyRelationship from "./detail/WebUMBabyRelationship";
import WebUMVaccine from "./detail/WebUMVaccine";
import WebUMDevelopment from "./detail/WebUMDevelopment";

export const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
};

type UserManagementMomProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  loadingPatientSelection?: boolean;
  loadingBabyRelationship?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedDivision?: any;
  clinic?: string;
  // Usermanager Patient Selection
  filterKeyword?: string;
  dmMomSegmentList?: any;
  dmMomPatientList?: any;
  // WebUMBabyRelationship
  babyRelationFilter?: any;
  familyOptions?: any[];
  relationPatient?: any;
  relationProfiles?: any[];
  selectedBabySegment?: any;
  babySegmentList?: any;
  babyList?: any;
  // User manager vaccine
  vaccineUMDetail?: any;
  vaccineUMList?: any[];
  vaccineSeriesChoice?: any[];
  orderItemVaccineUM?: any[];
  ageRangeChoice?: any[];
  ageRangeDetail?: any;
  ageRangeList?: any[];
  // User manager development
  developmentChoice?: any[];
  developmentUMDetail?: any;
  developmentUMList?: any[];
};

const UserManagementMomInitial: UserManagementMomProps = {
  // funtion
  onEvent: () => null,
  // data
  loadingStatus: false,
  loadingPatientSelection: false,
  loadingBabyRelationship: false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  clinic: "",
  // Usermanager Patient Selection
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
  // WebUMBabyRelationship
  babyRelationFilter: {},
  familyOptions: [],
  relationPatient: {},
  relationProfiles: [],
  selectedBabySegment: {},
  babySegmentList: {},
  babyList: {},
  // User manager vaccine
  vaccineUMDetail: {},
  vaccineUMList: [],
  vaccineSeriesChoice: [],
  orderItemVaccineUM: [],
  ageRangeChoice: [],
  ageRangeDetail: {},
  ageRangeList: [],
  // User manager development
  developmentChoice: [],
  developmentUMDetail: {},
  developmentUMList: [],
};

const UserManagementMom: React.FC<UserManagementMomProps> = (props: any) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const leftMenu = useMemo(
    () => [
      { result: "Patient Selection" },
      { result: "Baby Relationship" },
      { result: "Vaccine" },
      { result: "Development" },
    ],
    []
  );

  return (
    <>
      <Grid style={{ margin: "-10px", height: "100%" }}>
        <Grid.Column
          width={3}
          style={{
            padding: "10px 0px 0px 10px",
            borderRight: "0.1px solid rgb(204, 204, 204)",
          }}
        >
          <Accordion as={Menu} vertical fluid>
            {leftMenu?.map((item: any, index: number) => {
              return (
                <Menu.Item
                  key={index}
                  style={{ padding: "0px 0px", fontWeight: "bold" }}
                >
                  <Accordion.Content
                    active={true}
                    content={
                      <Table selectable style={{ border: "none" }}>
                        <Table.Body>
                          <Table.Row active={activeIndex === index}>
                            <Table.Cell
                              style={{
                                color: "var(--text-dark-blue)",
                                padding: "10px 30px",
                              }}
                              onClick={() => {
                                setActiveIndex(index);
                              }}
                            >
                              {item.result}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    }
                  />
                </Menu.Item>
              );
            })}
          </Accordion>
        </Grid.Column>

        <Grid.Column
          width={13}
          style={{ padding: "10px 0px", background: "#DBEFFD" }}
        >
          {activeIndex === 0 ? (
            <WebUMPatientSelection
              // function
              onEvent={props.onEvent}
              // data
              loadingPatientSelection={props.loadingPatientSelection}
              selectedDivision={props.selectedDivision}
              clinic={props.clinic}
              filterKeyword={props.filterKeyword}
              dmMomSegmentList={props.dmMomSegmentList}
              dmMomPatientList={props.dmMomPatientList}
            />
          ) : activeIndex === 1 ? (
            <WebUMBabyRelationship
              // function
              onEvent={props.onEvent}
              // data
              loadingStatus={props.loadingStatus}
              loadingBabyRelationship={props.loadingBabyRelationship}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              selectedDivision={props.selectedDivision}
              babyRelationFilter={props.babyRelationFilter}
              familyOptions={props.familyOptions}
              relationPatient={props.relationPatient}
              relationProfiles={props.relationProfiles}
              selectedBabySegment={props.selectedBabySegment}
              babySegmentList={props.babySegmentList}
              babyList={props.babyList}
            />
          ) : activeIndex === 2 ? (
            <WebUMVaccine
              onEvent={props.onEvent}
              vaccineUMDetail={props.vaccineUMDetail}
              vaccineUMList={props.vaccineUMList}
              vaccineSeriesChoice={props.vaccineSeriesChoice}
              orderItemVaccineUM={props.orderItemVaccineUM}
              patientType="CHILD"
              ageRangeChoice={props.ageRangeChoice}
              ageRangeDetail={props.ageRangeDetail}
              ageRangeList={props.ageRangeList}
              loadingStatus={props.loadingStatus}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
            />
          ) : activeIndex === 3 ? (
            <WebUMDevelopment
              onEvent={props.onEvent}
              // data
              ageRangeChoice={props.ageRangeChoice}
              ageRangeDetail={props.ageRangeDetail}
              ageRangeList={props.ageRangeList}
              // development
              developmentChoice={props.developmentChoice}
              developmentUMDetail={props.developmentUMDetail}
              developmentUMList={props.developmentUMList}
              loadingStatus={props.loadingStatus}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
            />
          ) : null}
        </Grid.Column>
      </Grid>
    </>
  );
};

UserManagementMom.defaultProps = UserManagementMomInitial;
export default React.memo(UserManagementMom);
