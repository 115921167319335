import React, { useState, useEffect, CSSProperties } from "react";
import {
  Dimmer,
  Header,
  Form,
  Checkbox,
  Input,
  Button,
  Divider,
  Grid,
  Loader,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber, useIntl } from "react-intl";

//Common
import SnackMessage from "./SnackMessage";
import TimeFreeTextBox24 from "../../react-lib/apps/common/TimeFreeTextBox24";

// Event
import { Event as MobEvent, LANGUAGE } from "../MobClinicInterface";

import "./DMBloodPressure.scss";

type DMBloodPressureSettingProps = {
  onEvent: (e: MobEvent) => any;
  loadingStatus?: boolean;
  successMessage?: any;
  errorMessage?: any;
  bpSettingData: any;
  selectedPatient: any;
};

type TextLabelProps = {
  style: CSSProperties;
  label: string;
};

const TextLabel = (props: TextLabelProps) => {
  return (
    <>
      <label
        style={{
          fontWeight: "bold",
          paddingTop: "13px",
          paddingBottom: "13px",
          color: "#747474",
        }}
      >
        {props.label}
      </label>
    </>
  );
};

export const DMBloodPressureCameraScan = React.memo(() => {
  return (
    <>
      <div style={{ textAlign: "center", paddingTop: "42px" }}>
        <Header
          as="h3"
          style={{ color: "#2083C1", margin: "30px 0 40px", fontSize: "20px" }}
        >
          <FormattedMessage id="bplusClinicKey94" />
        </Header>
      </div>
      <div style={{ display: "grid", justifyContent: "center" }}>
        <div
          style={{
            width: "240px",
            height: "240px",
            border: "1px solid rgb(32, 131, 193)",
            borderRadius: "5px",
          }}
        ></div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Header
          as="h3"
          style={{
            color: "#2083C1",
            margin: "30px 0 40px",
            fontWeight: "normal",
            fontSize: "20px",
          }}
        >
          <FormattedMessage id="weight.center_photo" />
        </Header>
        {/* <Icon name="camera" color="blue" style={{ fontSize: "40px" }} /> */}
        <svg width="35" height="31" viewBox="0 0 35 31" fill="none">
          <path
            d="M33.2188 7.75V6.76692C33.3962 7.04871 33.5 7.38512 33.5 7.75V27.6786C33.5 28.0435 33.3962 28.3799 33.2188 28.6616V27.6786V7.75ZM31.7188 5.92857C32.09 5.92857 32.4391 6.0466 32.7281 6.25H31.7188H24.4722L23.2003 2.80225L22.8384 1.82143H21.793H13.2139H12.2036C12.4955 1.61587 12.8466 1.5 13.2139 1.5H22.1689C22.6755 1.5 23.1343 1.81427 23.3165 2.31337L23.3165 2.31338L23.3196 2.3218L24.2971 4.95126L24.6605 5.92857H25.7031H31.7188ZM11.5514 2.67228C11.6756 2.33647 11.8895 2.05681 12.1575 1.85496L11.8073 2.80034L10.5293 6.25H3.28125H2.27191C2.56094 6.0466 2.90997 5.92857 3.28125 5.92857H9.29688H10.339L10.7026 4.95198L11.5503 2.67542L11.5514 2.67228ZM1.78125 6.76692V7.75V27.6786V28.6616C1.60382 28.3799 1.5 28.0435 1.5 27.6786V7.75C1.5 7.38512 1.60382 7.04871 1.78125 6.76692ZM3.28125 29.5C2.90997 29.5 2.56094 29.382 2.27191 29.1786H3.28125H31.7188H32.7281C32.4391 29.382 32.09 29.5 31.7188 29.5H3.28125ZM17.5 24.5179C13.8202 24.5179 10.7969 21.4839 10.7969 17.7143C10.7969 13.9446 13.8202 10.9107 17.5 10.9107C21.1798 10.9107 24.2031 13.9446 24.2031 17.7143C24.2031 21.4839 21.1798 24.5179 17.5 24.5179ZM17.5 11.2321C13.9405 11.2321 11.0781 14.156 11.0781 17.7143C11.0781 21.2725 13.9405 24.1964 17.5 24.1964C21.0595 24.1964 23.9219 21.2725 23.9219 17.7143C23.9219 14.156 21.0595 11.2321 17.5 11.2321Z"
            fill="#0072BC"
            stroke="#0072BC"
            strokeWidth="3"
          />
        </svg>
      </div>
    </>
  );
});

export const DMBloodPressureSetting = React.memo(
  (props: DMBloodPressureSettingProps) => {
    const intl = useIntl();
    const [settingData, setSettingData] = useState<any>({
      id: null,
      data: { isReminder: false, remindBefore: "", times: [""] },
    });

    useEffect(() => {
      props.onEvent({ message: "GetBloodPressureSetting" });
    }, [props.selectedPatient]);

    useEffect(() => {
      if (props.bpSettingData?.items && props.bpSettingData?.total > 0) {
        setSettingData(
          props.bpSettingData.items[props.bpSettingData.items.length - 1]
        );
      } else {
        setSettingData({
          id: null,
          data: { isReminder: false, remindBefore: "", times: [""] },
        });
      }
    }, [props.bpSettingData]);

    const handleChangeSetting = (event: any, data: any) => {
      let result = { ...settingData };
      if (data.type === "checkbox") {
        result.data[data.name] = data.checked;
        setSettingData({ ...settingData, ...result });
      } else {
        result.data[data.name] = data.value;
        setSettingData({ ...settingData, ...result });
      }
    };

    const SaveBloodPressureSetting = () => {
      props.onEvent({
        message: "SaveBloodPressureSetting",
        params: { ...settingData },
      });
    };

    const ordinalNumber = (number: number) => {
      const splitNumber = number.toString().split("");
      const last = splitNumber[splitNumber.length - 1];
      const ordinal = ["st", "nd", "rd"];
      const string = ordinal[+last];
      const time = 
      LANGUAGE === "th-TH" ? `ครั้งที่ ${number + 1}` : `${number + 1}${string || "th"} time`;
      return time;
    };

    return (
      <div
        style={{
          overflowY: "scroll",
          height: "100%",
          padding: "60px 0px 70px 0px",
        }}
      >
        <Dimmer active={props.loadingStatus} inverted>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>

        <SnackMessage
          onEvent={props.onEvent}
          error={props.errorMessage}
          success={props.successMessage}
        />

        <Form style={{ padding: "0px 10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextLabel
              style={styles.textStyle}
              label={`${intl.formatMessage({ id: "bplusClinicKey864" })}`}
            />
            <Checkbox
              toggle
              style={{ transform: "scale(0.7)" }}
              name="isReminder"
              checked={settingData?.data?.isReminder}
              onChange={handleChangeSetting}
            />
          </div>
        </Form>
        <Divider />

        <Form style={{ padding: "0px 15px" }}>
          <TextLabel
            style={styles.textStyle}
            label={`${intl.formatMessage({ id: "bplusClinicKey485" })}`}
          />
          {settingData?.data?.times?.map((data: any, index: number) => {
            return (
              <div
                key={"time-setting" + index}
                style={{ display: "grid", gridTemplateColumns: "75% 25%" }}
              >
                <div style={{ ...styles.flexBaseline, alignItems: "center" }}>
                  <label style={styles.labelTimeSetting}>
                    {ordinalNumber(index)}
                  </label>

                  <TimeFreeTextBox24
                    style={{
                      display: "flex",
                      alignItems: "left",
                      margin: "0 15px",
                      transform: "scale(0.8)",
                    }}
                    className="time-custom-react-picker padding-input"
                    value={data}
                    onChange={(time: string) => {
                      let result: any[] = [...settingData?.data?.times];
                      result[index] = time;
                      handleChangeSetting({}, { name: "times", value: result });
                    }}
                    autoFocus={false}
                    checkTimeOverflow={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="red"
                    icon="minus"
                    circular
                    style={{ fontSize: "6px" }}
                    onClick={(event: any) => {
                      let result: any[] = [...settingData?.data?.times];
                      result.splice(index, 1);

                      handleChangeSetting(event, {
                        name: "times",
                        value: result,
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </Form>
        <Divider />

        <Form style={{ padding: "0px 15px" }}>
          <div style={styles.flexBaseline}>
            <TextLabel
              style={styles.textStyle}
              label={`${intl.formatMessage({ id: "bplusClinicKey419" })}`}
            />
            <Input
              className="center-input"
              type="number"
              style={{ transform: "scale(0.8)" }}
              name="remindBefore"
              value={settingData?.data?.remindBefore}
              onChange={handleChangeSetting}
            />
            <label style={styles.textStyle}>
              <FormattedMessage id="bplusClinicKey323" />
            </label>
          </div>
        </Form>

        <Grid
          style={{
            position: "absolute",
            display: "flex",
            bottom: "65px",
            margin: "0px",
            width: "100%",
          }}
        >
          <Grid.Row centered columns={2}>
            <Grid.Column width={12}>
              <Button
                fluid
                basic
                color="blue"
                icon="plus"
                labelPosition="left"
                content={intl.formatMessage({ id: "bplusClinicKey679" })}
                onClick={(event: any) => {
                  let result: any[] = [...settingData?.data?.times];
                  result.push("");
                  handleChangeSetting(event, { name: "times", value: result });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={2}>
            <Grid.Column width={12}>
              <Button
                fluid
                basic
                color="green"
                icon="save outline"
                labelPosition="left"
                content={intl.formatMessage({ id: "common.save" })}
                onClick={SaveBloodPressureSetting}
                disabled={
                  !settingData?.data?.times.every((time: string) => time)
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
);

const styles = {
  flexBaseline: {
    display: "flex",
    alignItems: "baseline",
    margin: "5px 0",
  } as CSSProperties,
  labelTimeSetting: {
    color: "#299be6",
    fontWeight: "bold",
    width: "70px",
  } as CSSProperties,
  textStyle: {
    color: "#747474",
    fontWeight: "bold",
    fontSize: "12px",
  } as CSSProperties,
};
