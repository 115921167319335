import getPdfMake from "react-lib/appcon/common/pdfMake";
import moment from "moment";

export type RecordOfImmunization = {
  selectedPatient?: string;
  age_years?: string;
  age_month?: string;
  age_days?: string;
  essentialVaccineList?: string;
  otherVaccineList?: string;
  bloodType?: string;
};

export const RecordOfImmunizationInitail = {
  selectedPatient: "",
  age_years: "",
  age_month: "",
  age_days: "",
  essentialVaccineList: "",
  otherVaccineList: "",
  bloodType: "",
};

export const recordOfImmunization = async (props) => {
  let pdfMake = await getPdfMake();

  props = { ...RecordOfImmunizationInitail, ...props };
  // let make = await import("pdfmake/build/pdfmake");
  // let font = await import("../assets/fonts/pdfFonts")
  // let pdfMake = make.default
  // pdfMake.vfs = font.default
  // pdfMake.fonts = {
  //     THSarabunNew: {
  //         normal: 'THSarabunNew.ttf',
  //         bold: 'THSarabunNew-Bold.ttf',
  //         italics: 'THSarabunNew-Italic.ttf',
  //         bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //     },
  //     Roboto: {
  //         normal: 'Roboto-Regular.ttf',
  //         bold: 'Roboto-Medium.ttf',
  //         italics: 'Roboto-Italic.ttf',
  //         bolditalics: 'Roboto-MediumItalic.ttf'
  //     }
  // }
  // pdfMake.vfs = pdfFonts;
  // pdfMake.fonts = {
  //   THSarabunNew: {
  //     normal: "THSarabunNew.ttf",
  //     bold: "THSarabunNew-Bold.ttf",
  //     italics: "THSarabunNew-Italic.ttf",
  //     bolditalics: "THSarabunNew-BoldItalic.ttf",
  //   },
  //   Roboto: {
  //     normal: "Roboto-Regular.ttf",
  //     bold: "Roboto-Medium.ttf",
  //     italics: "Roboto-Italic.ttf",
  //     bolditalics: "Roboto-MediumItalic.ttf",
  //   },
  // };

  var origin = window?.location?.origin;

  // playground requires you to assign document definition to a variable called dd

    // หาหัวข้อ
    // var vaccineHeader = [];
    // vaccineHeader.push(
    //   {
    //     fillColor: '#FBC197',
    //     stack: [
    //         { text: props.essentialVaccineList?.[i]?.abbreviation === "BCG" ? 
    //         "วัคซีนวัณโรค (บีซีจี) \nTuberculosis (BCG)" : 
    //         props.essentialVaccineList?.[i]?.abbreviation === "HBV" ? 
    //         "วัคซีนไวรัสตับอักเสบบี \nHepatitis B Virus" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "HIB" ? 
    //         "วัคซีนเยื่อหุ้มสมองอักเสบฮิบ \nHIB Meningitis" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "DTaP" ? 
    //         "วัคซีนคอตีบ ไอกรน บาดทะยัก \nDiphtheria, Pertussis, Tetanus (DPT)" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "IPV1" ? 
    //         "วัคซีนโปลิโอ \nPolio" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "IPD" ? 
    //         "วัคซีนนิวโมคอคคัส (ไอพีดี) \nPneumococcal Conjugate Vaccines (IPD)" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "Rota" ? 
    //         "วัคซีนโรต้าไวรัส \nRotavirus" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "JE" ? 
    //         "วัคซีนไข้สมองอักเสบ \nJapanese B Encephalitis" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "MMR" ? 
    //         "วัคซีนหัด คางทูม หัดเยอรมัน \nMeasles, Mumps, Rubella (MMR)" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "VZ" ? 
    //         "วัคซีนอีสุกอีใส \nChickenpox" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "HAV" ? 
    //         "วัคซีนไวรัสตับอักเสบเอ \nHepatitis A Virus" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "HPV" ? 
    //         "วัคซีนเอชพีวี \nHuman Papilloma Virus (HPV)" :
    //         props.essentialVaccineList?.[i]?.abbreviation === "INFLU" ? 
    //         "วัคซีนไข้หวัดใหญ่ \nInfluenza Virus" :
    //         "", style: "fontHeaderTable" },
    //     ]
    //   },
    // )

//   {
//     text: props.essentialVaccineList?.[i]?.items?.[j]?.date,
//     style: "fieldValue",
//   },
//   {
//     text: props.essentialVaccineList?.[i]?.items?.[j]?.by_patient === true ? "Yes" : "",
//     style: "fieldValue",
//   },

  var docDefinition = {

    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 14,
    },

    pageMargins: [8, 135, 8, 10],
    //A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      fontHeader: {
        fontSize: 22,
        bold: true,
      },
      fontHeaderTable: {
        alignment: "center",
        fontSize: 13,
        bold: true,
      },
      fieldTable: {
        marginTop: 2,
        marginBottom: 2,
        fontSize: 13,
      },
      dateTable: {
        marginTop: 5,
        fontSize: 13,
        alignment: "center",
      },
      fieldKey: {
        bold: true,
        fontSize: 17,
      },
      fieldValue: {
        fontSize: 17,
      },
      iconUser: {
        alignment: "center",
      },
    },

    header: {
      margin: [15, 10, 15, 0],
      table: {
        widths: ['*'],
        body: [
          [
            {
              stack: [
                {
                  table: {
                    widths: [155,'*'],
                    body: [[
                      {
                        stack: [
                          {
                            image: "logobkhpt",
                            width: 155,
                            marginRight: 10,
                          },
                        ],
                        
                      },
                      {
                        table: {
                          widths: ['*'],
                          body: [[{
                            fillColor: '#92D4FF',
                            color: '#FFFFFF',
                            alignment: "left",
                            columns: [
                              {
                                text: "ตารางบันทึกการให้วัคซีน Record of Immunization",
                                style: "fontHeader",
                                alignment: "center",
                                margin: 1,
                              },
                            ]
                        }]]
                        },
                        layout: 'noBorders',
                        width: 270,
                        height: 35,
                        marginTop: 2,
                      },
                    ]]
                  },
                  layout: 'noBorders',
                },
                {
                  marginTop: 5,
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.selectedPatient?.hn, style: "fieldValue" }
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        { text: props.selectedPatient?.fullname, style: "fieldValue" }
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 115,
                      text: [
                        { text: "Sex: ", style: "fieldKey" },
                        { text: props.selectedPatient?.gender_name, style: "fieldValue" }
                      ],
                    },
                    {
                      width: 140,
                      text: [
                        { text: "Birthdate: ", style: "fieldKey" },
                        { text: moment(props.selectedPatient?.birthdate, [
                                "DD/MM/YYYY", "YYYY-MM-DD",]).format("DD/MM/YYYY"), style: "fieldValue" }
                      ],
                    },
                    {
                      width: "*",
                      text: [
                        { text: "Age: ", style: "fieldKey" },
                        { text: props.age_years + "years ", style: "fieldValue" },
                        { text: props.age_month + "month ", style: "fieldValue" },
                        { text: props.age_days + "days ", style: "fieldValue" },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Blood type: ", style: "fieldKey" },
                        { text: props.bloodType, style: "fieldValue" }
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        ]
      },
      layout: 'noBorders',
    },
    
    content: [
      {
        margin: [10, 0, 10, 0],
        table: {
            widths: [180,'*','*','*','*','*','*'],
            body: [
              [
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "\nรายการ", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "1", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "2", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "3", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "4", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "5", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "6", style: "fontHeaderTable" },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนวัณโรค (บีซีจี) \nTuberculosis (BCG)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[0]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[1]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[0]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[0]?.items?.[5]?.by_patient === true &&
                             props.essentialVaccineList?.[0]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนไวรัสตับอักเสบบี \nHepatitis B Virus", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[0]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[1]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[1]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[1]?.items?.[5]?.by_patient === true &&
                             props.essentialVaccineList?.[1]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนเยื่อหุ้มสมองอักเสบฮิบ \nHIB Meningitis", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[5]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[5]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[5]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[5]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[5]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[5]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[5]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[5]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[5]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    // { text: props.essentialVaccineList?.[5]?.items?.[3]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[5]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    // { text: props.essentialVaccineList?.[5]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[5]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    // { text: props.essentialVaccineList?.[5]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[5]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนคอตีบ ไอกรน บาดทะยัก \nDiphtheria, Pertussis, Tetanus (DPT)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[2]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[2]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[2]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[2]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[2]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[2]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[2]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[2]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[2]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[2]?.items?.[7]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[2]?.items?.[7]?.by_patient === true &&
                             props.essentialVaccineList?.[2]?.items?.[7]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[2]?.items?.[10]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[2]?.items?.[10]?.by_patient === true &&
                             props.essentialVaccineList?.[2]?.items?.[10]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[2]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[2]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนโปลิโอ \nPolio", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[3]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[3]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[3]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[3]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[3]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[3]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[3]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[3]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[3]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[3]?.items?.[3]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[3]?.items?.[3]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[3]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[3]?.items?.[4]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[3]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[3]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนนิวโมคอคคัส (ไอพีดี) \nPneumococcal Conjugate Vaccines (IPD)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[4]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[4]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[4]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[4]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[4]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[4]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[4]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[4]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[4]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[4]?.items?.[6]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[4]?.items?.[6]?.by_patient === true &&
                             props.essentialVaccineList?.[4]?.items?.[6]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[4]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[4]?.items?.[4]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[4]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[4]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนโรต้าไวรัส \nRotavirus", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[6]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[6]?.items?.[2]?.by_patient === true &&
                             props.essentialVaccineList?.[6]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[6]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[6]?.items?.[3]?.by_patient === true &&
                             props.essentialVaccineList?.[6]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[6]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[6]?.items?.[4]?.by_patient === true &&
                             props.essentialVaccineList?.[6]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[6]?.items?.[3]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[6]?.items?.[3]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[6]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[6]?.items?.[4]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[6]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[6]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนไข้สมองอักเสบ \nJapanese B Encephalitis", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[7]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[7]?.items?.[5]?.by_patient === true &&
                             props.essentialVaccineList?.[7]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[7]?.items?.[8]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[7]?.items?.[8]?.by_patient === true &&
                             props.essentialVaccineList?.[7]?.items?.[8]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[7]?.items?.[2]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[7]?.items?.[2]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[7]?.items?.[3]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[7]?.items?.[3]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[7]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[7]?.items?.[4]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[7]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[7]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนหัด คางทูม หัดเยอรมัน \nMeasles, Mumps, Rubella (MMR)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[8]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[8]?.items?.[5]?.by_patient === true &&
                             props.essentialVaccineList?.[8]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.essentialVaccineList?.[8]?.items?.[9]?.date, style: "dateTable" },
                    { image: props.essentialVaccineList?.[8]?.items?.[9]?.by_patient === true &&
                             props.essentialVaccineList?.[8]?.items?.[9]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[8]?.items?.[2]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[8]?.items?.[2]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[8]?.items?.[3]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[8]?.items?.[3]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[8]?.items?.[4]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[8]?.items?.[4]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: ""}
                    // { text: props.essentialVaccineList?.[8]?.items?.[5]?.date, style: "dateTable" },
                    // { image: props.essentialVaccineList?.[8]?.items?.[5]?.by_patient === true ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนอีสุกอีใส \nChickenpox", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[0]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[1]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[2]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[3]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[4]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[1]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[1]?.items?.[5]?.by_patient === true &&
                             props.otherVaccineList?.[1]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนไวรัสตับอักเสบเอ \nHepatitis A Virus", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[0]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[1]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[2]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[3]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[4]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[2]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[2]?.items?.[5]?.by_patient === true &&
                             props.otherVaccineList?.[2]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนเอชพีวี \nHuman Papilloma Virus (HPV)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[0]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[1]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[2]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[3]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[4]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[4]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[4]?.items?.[5]?.by_patient === true &&
                             props.otherVaccineList?.[4]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนไข้หวัดใหญ่ \nInfluenza Virus", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[0]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[1]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[2]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[3]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[4]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[0]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[0]?.items?.[5]?.by_patient === true &&
                             props.otherVaccineList?.[0]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              
            //   [ props.essentialVaccineList?.[i]?.items?.[j]?.date
            //     {
            //       columns: [
            //         { text: "Heart rate", style: "fieldTable" }
            //       ]
            //     }, { text: "" }, { text: "" }, { text: "" }
            //   ],
            ]
        },
        layout: {
            hLineColor: function (i, node) {
              return "#F7975B";
            },
            vLineColor: function (i, node) {
              return "#F7975B";
            },
        },
      },
      {
        margin: [15, 10, 15, 0],
        columns: [
            { image: "icon_user", style: "iconUser", width: 10, marginLeft: 20,},
            { text: "    หมายถึง ข้อมูลการได้รับวัคซีนชนิดดังกล่าว ถูกบันทึกผ่านทาง Mobile application โดยผู้ปกครอง", fontSize: 13, },
          ]
      },


      {
        margin: [10, 0, 10, 0],
        table: {
            widths: [180,'*','*','*','*','*','*'],
            body: [
              [
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "\nรายการ", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "1", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "2", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "3", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "4", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "5", style: "fontHeaderTable" },
                  ]
                },
                {
                  fillColor: '#FBC197',
                  stack: [
                    { text: "Data", style: "fontHeaderTable" },
                    { text: "ครั้งที่", style: "fontHeaderTable" },
                    { text: "6", style: "fontHeaderTable" },
                  ]
                },
              ],
              [
                {
                  fillColor: '#FCCFAE',
                  stack: [
                    { text: "วัคซีนคอตีบ ไอกรน บาดทะยัก กระตุ้นแบบผู้ใหญ่ \nTetanus, Diphtheria and Pertussis (Tdap)", style: "fieldTable" },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[0]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[0]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[1]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[1]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[2]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[2]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[3]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[3]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[4]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[4]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
                {
                  fillColor: '#FEE5D3',
                  stack: [
                    { text: props.otherVaccineList?.[3]?.items?.[5]?.date, style: "dateTable" },
                    { image: props.otherVaccineList?.[3]?.items?.[5]?.by_patient === true &&
                             props.otherVaccineList?.[3]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
                  ]
                },
              ],
              // [
              //   {
              //     fillColor: '#FCCFAE',
              //     stack: [
              //       { text: "ทดสอบวัคซีนชนิดอื่น \n", style: "fieldTable" },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[0]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[0]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[0]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[1]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[1]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[1]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[2]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[2]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[2]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[3]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[3]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[3]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[4]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[4]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[4]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              //   {
              //     fillColor: '#FEE5D3',
              //     stack: [
              //       { text: props.otherVaccineList?.[5]?.items?.[5]?.date, style: "dateTable" },
              //       { image: props.otherVaccineList?.[5]?.items?.[5]?.by_patient === true &&
              //                props.otherVaccineList?.[5]?.items?.[5]?.date !== "" ? "icon_user" : "falseIcon_user", style: "iconUser", width: 15, },
              //     ]
              //   },
              // ],
              
            //   [ props.essentialVaccineList?.[i]?.items?.[j]?.date
            //     {
            //       columns: [
            //         { text: "Heart rate", style: "fieldTable" }
            //       ]
            //     }, { text: "" }, { text: "" }, { text: "" }
            //   ],
            ]
        },
        layout: {
            hLineColor: function (i, node) {
              return "#F7975B";
            },
            vLineColor: function (i, node) {
              return "#F7975B";
            },
        },
        pageBreak: 'before'
      },
      {
        margin: [15, 10, 15, 0],
        columns: [
            { image: "icon_user", style: "iconUser", width: 10, marginLeft: 20,},
            { text: "    หมายถึง ข้อมูลการได้รับวัคซีนชนิดดังกล่าว ถูกบันทึกผ่านทาง Mobile application โดยผู้ปกครอง", fontSize: 13, },
          ]
      },
    ],

    

    images: {
      logobkhpt: origin + "/images/pdfForm/logobkhpt.png",
      icon_user: origin + "/images/pdfForm/vaccine_by_patient.png",
      falseIcon_user: origin + "/images/pdfForm/vaccine_dont_by_patient.png",
    },
  };

  var win = window.open("", "_blank");
  pdfMake.createPdf(docDefinition).open({}, win);

};