import React, { useMemo } from "react";
// Mui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { FormattedMessage } from "react-intl";

import { useStyles, COLORS, MedAllergyType } from "./AllergyDetail";
import { ALLERGY_ASSESSMENT } from "./CardAllergyTab";
import Cookies from "js-cookie";

// Types
type CardWarningTabProps = {
  // data
  data: MedAllergyType[];
  expanded?: string;
  // callback
  onToggle: (panel: string) => any;
  onOpen: () => any;
};
// Constants
export const WARNING_DRUG = {
  G_6_PD: "G-6-PD",
  STREPTOKINASE: "Streptokinase",
  BISPHOSPHONATE: "Bisphosphonate",
} as const;

const WARNING_COLOR = {
  [WARNING_DRUG.G_6_PD]: "#BB6BD9",
  OTHER: "rgba(241, 120, 182, 1)",
};

const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_H]/)[0]
)
  ? "th-TH"
  : "en-US";

export const contains = (
  obj: Record<string, any> | string[],
  detail: string
) => {
  if (Array.isArray(obj)) {
    return obj.find((value) =>
      detail.toLowerCase().includes(value.toLowerCase())
    );
  } else {
    return Object.entries(obj).find((item) =>
      detail.toLowerCase().includes(item[0].toLowerCase())
    )?.[1];
  }
};

const CardWarningTab: React.FC<CardWarningTabProps> = (props) => {
  const classes = useStyles();

  const handleToggle =
    (panel: any, drug: string) => (event: any, newExpanded: boolean) => {
      if (drug.includes(WARNING_DRUG.G_6_PD)) {
        props.onOpen();
      } else {
        props.onToggle(newExpanded ? panel : "");
      }
    };

  return (
    <>
      {props.data.map((item, index) => (
        <Accordion
          key={"allergy-accordion-" + index}
          className={classes.accordion}
          style={{
            borderLeft: `15px solid ${
              contains(WARNING_COLOR, item.detail) || WARNING_COLOR.OTHER
            }`,
            ...(props.expanded === `panel-${index}` ? { marginTop: 0 } : {}),
          }}
          expanded={props.expanded === `panel-${index}`}
          onChange={handleToggle(`panel-${index}`, item.detail)}
        >
          <AccordionSummary
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
              }}
            >
              <div
                style={{
                  padding: `12px 10px 12px 18px`,
                }}
              >
                <div
                  style={{
                    color: COLORS.blue_bdms,
                    fontWeight: "bold",
                  }}
                >
                  {item.detail || item.name_generic}
                </div>

                {props.expanded !== `panel-${index}` && (
                  <div
                    style={{
                      color: COLORS.blue_bdms,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormattedMessage id={"allery_seemore"} />
                  </div>
                )}

                {props.expanded !== `panel-${index}` ? null : (
                  <div style={{ marginTop: "12px", opacity: 0 }}>-</div>
                )}
              </div>
            </div>
          </AccordionSummary>

          {/* Full card detail  */}
          <AccordionDetails>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
              }}
            >
              <div
                style={{
                  padding: "5px 0 0 2px",
                  marginTop: "-55px",
                  paddingBottom:
                    props.expanded === `panel-${index}` ? "5px" : "",
                }}
              >
                <SubAccordionDetail item={item} />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

/* ------------------------------------------------------ */

/*                   SubAccordionDetail;                  */

/* ------------------------------------------------------ */
type SubAccordionDetailProps = {
  item: MedAllergyType;
};
const SubAccordionDetail = (props: SubAccordionDetailProps) => {
  const contents = useMemo(() => {
    const item = props.item;
    const isStrepBis = !!contains(
      [WARNING_DRUG.STREPTOKINASE, WARNING_DRUG.BISPHOSPHONATE],
      item.detail
    );

    const items = [{ title: "Note", content: item["comment"] }];

    if (isStrepBis) {
      items.push({
        title: "วันที่ใช้ยาล่าสุด",
        content: item["last_used"],
      });
    }

    const message = {
      [WARNING_DRUG.STREPTOKINASE]: {
        TH: "หลีกเลี่ยงการได้รับยา Streptokinase ซ้ำในช่วงระยะเวลา 1 ปี นับจากการได้รับยาครั้งล่าสุด เนื่องจากอาจก่อให้เกิด ปฏิกิริยาการแพ้ชนิดรุนแรงได้",
        EN: "Streptokinaseis contraindicated within 1 year after previous exposure because of the potential for serous allergic reaction",
      },
      [WARNING_DRUG.BISPHOSPHONATE]: {
        TH: "ผู้ป่วยท่านนี้ได้รับยา/เคยได้รับยาที่อาจต้องพิจารณางดยาก่อนทำหัตถการหรือการผ่าตัดบางชนิด",
        EN: "This patient is tracking/has received medicine that may be considered discontinuing prior to certain dental procedures or surgery",
      },
    };

    if (isStrepBis) {
      const content = contains(message, item.detail)
      items.push({
        title: "ข้อแจ้งเตือน",
        content: LANGUAGE === "en-US"? content?.EN : content?.TH
      });
    }

    items.push({
      title: ALLERGY_ASSESSMENT.PHARMACOGNETIC_TESTING,
      content: item["testing"],
    });

    return items;
  }, [props.item]);

  return (
    <>
      {contents.map(
        (acc) =>
          !!acc.content && (
            <>
              <div style={{ color: COLORS.blue_bdms, margin: "10px 0" }}>
                {acc.title}
              </div>
              <div
                style={{
                  backgroundColor: "rgba(233, 233, 233, 0.44)",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {acc.content}
              </div>
            </>
          )
      )}
    </>
  );
};

// MOCK DATA
const MOCK_WARNING = [
  { q03: "G-6-PD x12-", q05: "Certain" },
  {
    q03: "Streptokinase t12-",
    q05: "Probable",
    q06: "ได้รับยาจากโรงพยาบาลกรุงเทพสุราษฎร์",
    q08: "15/09/2564 10:00",
    q07: "",
  },
  { q03: "Clopidogrel b12-", q05: "Pharmacognetic Testing" },
].map((item) => ({
  HN: "01-17-051095",
  EN: "O19-22-827160",
  questionnaireCode: "01ALL2",
  q02: "-",
  q01: "Detail",
  q04: "Warning",
  q06: "comment",
  q07: "Ultrarapid metabolizer (UM) (CYP2C19 *1/*17, *17/*17) : ยา Clopidogrel ออกฤทธิ์ในการยับยั้ง Platelet ได้มากกว่าปกติ สามารถให้ยา Clopidogrel ในขนาดตาม Standard Dosing ได้",
  q08: "-",
  bucode: "BHQ",
  ...item,
}));

export default React.memo(CardWarningTab);
