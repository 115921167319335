import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
// CSS
import { Dimmer, Form, Header, Icon, Loader } from "semantic-ui-react";

// Library
import moment from "moment";
import { Bar } from "react-chartjs-2";
import PureReactTable from "../../react-lib/apps/common/PureReactTable";

// UI
import SnackMessage from "./SnackMessage";
import {
  HeaderChart,
  FooterChart,
  spliceChartData,
} from "./DMEmotionRestChart";
import {
  COLOR,
  DATE_FORMAT,
  EXERCISE_TYPE_DETAIL,
  ExerciseTypeDetail,
} from "./DMExercise";
import { tooltipCustom, tooltipClose } from "./DMBloodPressure";

// Interface
import { BACKEND_DATE_FORMAT, EXERCISE_TYPE } from "../MobClinicInterface";

// Styles
import "./DMWeight.scss";

// ------------------------------ other
type LegendDetail = {
  date: string;
  utc?: string;
  calorie: string | number;
  allCalories?: string | number | null;
  type?: keyof ExerciseTypeDetail;
  duration: string;
  id: number | null;
  time: string;
  exercise: string;
};

export const durationOptions = [
  { key: 1, value: "w", text: "week" },
  { key: 2, value: "M", text: "month" },
];

// ------------------------------ DMExerciseChart
type DMExerciseChartProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  height?: number;
  exerciseChartList: any;
  exerciseSettingData: any;
  showCurrentBarDetail: boolean;
  exerciseFilterDate?: moment.Moment;
  // config
  isMyTab?: boolean;
  hideFooterChart?: boolean;
  showTooltipChart?: boolean;
};

const DMExerciseChartInitial: DMExerciseChartProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  height: 200,
  exerciseChartList: {},
  exerciseSettingData: {},
  showCurrentBarDetail: true,
  isMyTab: false,
};

const defaultOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  interaction: {
    mode: "x",
  },
  tooltips: {
    enabled: false,
    mode: "x",
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
          minRotation: 0,
          fontSize: 10,
          fontColor: "#404040",
          fontStyle: "bold",
        },
        maxBarThickness: 70,
      },
    ],
    yAxes: [
      {
        id: "min",
        gridLines: {
          drawBorder: false,
        },
        stacked: true,
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          max: 180,
          stepSize: 30,
          beginAtZero: true,
          fontColor: "#b1b3b2",
        },
        scaleLabel: {
          display: true,
        },
      },
      {
        id: "calories",
        gridLines: {
          drawBorder: false,
        },
        stacked: true,
        position: "right",
        ticks: {
          display: true,
          minRotation: 0,
          min: 0,
          max: 1000,
          stepSize: 250,
          beginAtZero: true,
          fontColor: "#b1b3b2",
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
};

const formatDetail = (data: LegendDetail, tooltipValue: string = "") => {
  const { utc, calorie, type, duration, id, allCalories }: LegendDetail = data;
  const momentDate = moment(utc);
  const date = momentDate.format("DD MMMM YYYY");
  const time = momentDate.format("HH:mm");
  if (type === "CALORIES") {
    return {
      id,
      date,
      time,
      calorie: +tooltipValue,
      exercise: "",
      duration: "",
      utc,
      allCalories,
      type,
    };
  }

  return {
    id,
    date,
    time,
    calorie: Number.isNaN(calorie) ? 0 : calorie,
    allCalories: Number.isNaN(allCalories) ? null : allCalories,
    exercise: EXERCISE_TYPE_DETAIL?.[type || "AEROBIC"]?.text,
    duration,
    utc,
    type,
  };
};

export const DMExerciseChart: React.FC<DMExerciseChartProps> = (props) => {
  const labelLimit = 4;

  const [duration, setDuration] = useState("w");
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-6", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const [selectedBarDetail, setSelectedBarDetail] = useState<LegendDetail>(
    {} as any
  );
  const [allBarDetail, setAllBarDetail] = useState<any[]>([]);
  const [skipIndex, setSkipIndex] = useState(0);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>;
  const barRef = useRef() as any;
  const intl = useIntl();

  const durationOptions = [
    {
      key: 1,
      value: "w",
      text: `${intl.formatMessage({ id: "bplusClinicKey763" })}`,
    },
    {
      key: 2,
      value: "M",
      text: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
    },
  ];

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({
        message: "GetExerciseChart",
        params: {
          start_measure: startDate?.format(BACKEND_DATE_FORMAT),
          end_measure: endDate?.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, props.isMyTab, startDate, endDate]);

  useEffect(() => {
    let result: any = [];
    props.exerciseChartList?.forEach((item: any, index: number) => {
      const date = moment(item.date, BACKEND_DATE_FORMAT).format(DATE_FORMAT);
      let aerobic: number = 0;
      let weight_resist: number = 0;
      let flexibility: number = 0;
      if (item.type_name === EXERCISE_TYPE.AEROBIC.text) {
        aerobic = parseFloat(item.duration);
      } else if (item.type_name === EXERCISE_TYPE.WEIGHT_RESISTANCE.text) {
        weight_resist = parseFloat(item.duration);
      } else if (item.type_name === EXERCISE_TYPE.FLEXIBILITY.text) {
        flexibility = parseFloat(item.duration);
      }

      const filterDate = result.filter((rs: any) => rs.date === date);
      if (filterDate.length > 0) {
        filterDate[0].aerobic += aerobic;
        filterDate[0].weight_resist += weight_resist;
        filterDate[0].flexibility += flexibility;
        filterDate[0].calorie += parseFloat(item.calorie) || 0;
        filterDate[0].target_calories = item?.target_calories || 0;
      } else {
        result.push({
          id: index,
          date: date,
          aerobic: aerobic,
          weight_resist: weight_resist,
          flexibility: flexibility,
          calorie: parseFloat(item.calorie) || 0,
          target_calories: item?.target_calories || 0,
        });
      }
    });
    setAllBarDetail(result);
  }, [props.exerciseChartList]);

  useEffect(() => {
    setSkipIndex(0);
  }, [startDate]);

  useEffect(() => {
    if (barRef.current) {
      barRef.current.chartInstance.config.options.exerciseSettingData =
        props.exerciseSettingData;
    }
  }, [props.exerciseSettingData]);

  useEffect(() => {
    if (props.showTooltipChart) {
      return;
    }
    const data: LegendDetail[] = chartData.datasets.flatMap(
      (item) => item.realData
    ) as any;
    const filterCalories: LegendDetail[] = data.filter(
      (item) => item.type !== "CALORIES"
    ) as any;

    if (data?.[0]) {
      if (selectedBarDetail?.id !== undefined) {
        const date = moment(
          selectedBarDetail.utc?.split("T")?.[0] || ""
        )?.format("YYYY-MM-DD");
        const currentDate = props.exerciseFilterDate?.format("YYYY-MM-DD");
        if (date === currentDate) {
          const detail: any = data.find(
            (item) =>
              item.id === selectedBarDetail.id &&
              item.type === selectedBarDetail.type
          );

          const isNotCompare =
            detail.type === "CALORIES"
              ? detail?.allCalories !== selectedBarDetail?.allCalories
              : detail?.allCalories !== selectedBarDetail?.allCalories ||
                detail?.duration !== selectedBarDetail?.duration;

          if (
            !!detail &&
            selectedBarDetail?.id &&
            detail?.id === selectedBarDetail?.id &&
            isNotCompare
          ) {
            setSelectedBarDetail(formatDetail(detail, detail.value));
            return;
          }
        }
      } else {
        setSelectedBarDetail(formatDetail(filterCalories.slice(-1)[0]));
        return;
      }
    }

    if (!data?.[0] && selectedBarDetail?.id) {
      setSelectedBarDetail({} as any);
    }
  }, [selectedBarDetail, props.exerciseChartList, props.exerciseFilterDate]);

  const chartData = useMemo(() => {
    let labels: string[] =
      props.exerciseChartList
        ?.sort((a: any, b: any) => a.date.localeCompare(b.date))
        .map((item: any) => item.date) || [];

    labels = Array.from(new Set(labels));

    const realData = labels.map((date): { value: number; type: string }[] => {
      const filterDate = (props.exerciseChartList || []).filter(
        (item: any) => item.date === date
      );
      const calories = filterDate.reduce(
        (result: number, item: { calorie: string }) =>
          (result += parseFloat(item.calorie) || 0),
        0
      ) as number;
      const sumCalories = {
        id: filterDate[0]?.date,
        value: calories,
        type: "CALORIES",
        utc: moment(
          `${filterDate[0]?.date.replace(/\//g, "-")} ${filterDate[0]?.time}`
        ).toISOString(),
        allCalories: calories,
      };

      const summation = (type: keyof ExerciseTypeDetail) => {
        const duration = filterDate.reduce(
          (result: number, item: { type_name: string; duration: string }) =>
            (result +=
              item.type_name === EXERCISE_TYPE_DETAIL[type].text
                ? parseFloat(item.duration)
                : 0),
          0
        ) as number;

        const caloriesValue = filterDate.reduce(
          (result: number, item: { type_name: string; calorie: string }) =>
            (result +=
              item.type_name === EXERCISE_TYPE_DETAIL[type].text
                ? parseFloat(item.calorie) || 0
                : 0),
          0
        ) as number;

        return {
          id: filterDate[0]?.id,
          value: duration,
          type,
          utc: moment(
            `${filterDate[0]?.date.replace(/\//g, "-")} ${filterDate[0]?.time}`
          ).toISOString(),
          calorie: caloriesValue,
          allCalories: sumCalories.value,
          duration,
        };
      };

      let sumAerobic = summation("AEROBIC");
      let sumWeight = summation("WEIGHT");
      let sumFlex = summation("FLEXIBILITY");

      const sort = [sumAerobic, sumWeight, sumFlex].sort(
        (a, b) => a.value - b.value
      );
      // const cloneSort = JSON.parse(JSON.stringify(sort));
      // sort[1].value = cloneSort[1].value - cloneSort[0].value;
      // sort[2].value = cloneSort[2].value - cloneSort[1].value;

      return [...sort, sumCalories];
    });

    const dataArray = realData.map((list) =>
      list.map((item) => (isNaN(item.value) ? 0 : item.value))
    );

    const datasets = (
      Object.keys(EXERCISE_TYPE_DETAIL) as (keyof ExerciseTypeDetail)[]
    ).map((item, index) => ({
      label: EXERCISE_TYPE_DETAIL[item]?.text,
      yAxisID: item === "CALORIES" ? "calories" : "min",
      backgroundColor: (data: any) => {
        const type: keyof ExerciseTypeDetail =
          data.dataset.realData?.[data.dataIndex]?.type;
        return EXERCISE_TYPE_DETAIL[type]?.color;
      },
      borderColor: (context: any) => {
        const index = context.dataIndex;
        return context.dataset?.realData?.[index]?.id ===
          (selectedBarDetail?.id || defaultDetail?.id)
          ? "rgba(0,0,0,.2)"
          : "";
      },
      borderWidth: (context: any) => {
        const borderStyle = {
          top: 2,
          bottom: 0,
          right: 2,
          left: 2,
        };
        const index = context.dataIndex;
        const target = context.dataset?.realData?.[index];
        const detailId = selectedBarDetail?.id || defaultDetail?.id;
        const detailDuration =
          selectedBarDetail?.duration || defaultDetail?.duration || 0;

        if (target?.id === detailId && target?.duration !== detailDuration) {
          borderStyle.top = 0;
        }
        if (target?.id === detailId && target?.duration > detailDuration) {
          borderStyle.left = 0;
          borderStyle.right = 0;
        }
        return context.dataset?.realData?.[index]?.id === detailId
          ? borderStyle
          : 0;
      },
      stack: item === "CALORIES" ? "Stack 1" : "Stack 0",
      data: dataArray.map((value) => value[index]),
      realData: realData.map((value) => value[index]),
    }));
    return {
      labels:
        labels.map((date: any) =>
          moment(date, "YYYY/MM/DD").format("DD/MM/YY")
        ) || [],
      datasets: datasets,
    };
  }, [props.exerciseChartList, selectedBarDetail]);

  const options = useMemo(() => {
    const defaults: any = defaultOptions;

    const data = chartData?.datasets?.[3]?.data || [0];
    const mapDuration = (index: number) =>
      chartData?.datasets?.[index]?.realData?.map((item: any) => item.duration);
    const concatDuration = [
      ...mapDuration(0),
      ...mapDuration(1),
      ...mapDuration(2),
    ];

    if (defaults.scales.yAxes?.[0] && Number.isFinite(Math.max(...data))) {
      defaults.scales.yAxes[0].ticks.max =
        concatDuration.reduce(
          (result: number, item: number) => (result += item),
          0
        ) + 10;
      defaults.scales.yAxes[1].ticks.max = Math.max(...data) + 10;
    }
    return defaults;
  }, [chartData]);

  const defaultDetail = useMemo(() => {
    if (props.showTooltipChart) {
      return;
    }

    return {
      id: null,
      date: "-",
      time: "-",
      calorie: 0,
      exercise: "-",
      duration: "-",
      allCalories: 0,
    };
  }, [chartData, selectedBarDetail, props.exerciseChartList]);

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;
        },
        afterDatasetsDraw: (chart: any) => {},
        beforeEvent: (chart: any, e: any) => {
          if (chart.tooltip?._active?.[0] && props.showTooltipChart) {
            const active = chart.tooltip._active.filter((item: any) =>
              Number(
                item._chart.config.data.datasets[item._datasetIndex].data[
                  item._index
                ]
              )
            );

            const findIndex = active.findIndex(
              (_chart: any) => _chart?._model?.y <= e.y
            );
            const bar = active[findIndex];

            if (!bar) {
              return;
            }

            const center =
              Math.abs(
                bar._model?.y -
                  (active?.[findIndex - 1]?._model?.y || bar._yScale.bottom)
              ) / 2;
            // const bar = active.slice(active.length - 2, -1)?.[0] || active[0];
            const _model = bar._model;
            // const top = y_axis.top;
            const centerX = _model.x;
            const centerY = _model.y + center; // top + _model.y - top;
            const tooltip = tooltipRef.current;
            const data =
              bar._chart.config.data.datasets?.[bar._datasetIndex]?.realData?.[
                bar._index
              ];
            const type = (EXERCISE_TYPE_DETAIL as any)?.[data.type || "AEROBIC"]
              ?.text;
            const settingData = chart.config.options.exerciseSettingData;
            const datasets = chart.config.data.datasets;
            const duration = datasets
              .map((item: any) => item.realData?.[bar._index])
              .filter((item: any) => item.type !== "CALORIES")
              .reduce((result: number, item: any) => result + item.duration, 0);

            let innerHTML = "";
            const isExcellent = data.value >= settingData?.data?.calorie;

            if (data.type === "CALORIES") {
              innerHTML = `<div>
              <div>
              Calories: ${data.value}/${
                settingData?.data?.calorie || "-"
              } Cal<br/>
              Duration: ${duration} min<br/>
              </div>
              <div style="display: flex; justify-content: center">
                <img src="/images/exercise/${
                  isExcellent ? "excellent" : "fair"
                }-icon.png" alt="status.icon"
                style="width: 18px; margin: 5px 0 0; ${
                  isExcellent ? "" : "transform: scale(1,-1);"
                }"
                />
              </div>
            </div>
            `;
            } else {
              innerHTML = `<div>
              Exercise: ${type}<br/>
              Calories: ${data.calorie} Cal<br/>
              Duration: ${data.duration} min
              </div>`;
            }

            tooltipCustom({
              chart,
              innerHTML,
              tooltip,
              x: centerX,
              y: centerY,
              bar,
              textAlign: "unset",
            });
          } else {
            tooltipClose(chart, tooltipRef.current);
          }
        },
      },
    ];
  }, []);

  const exerciseColumn = useMemo(
    () => [
      { Header: "Date", accessor: "date", width: 150 },
      {
        Header: "Total time (min)",
        width: 150,
        Cell: (row: any) => {
          const total: number =
            parseFloat(row?.original?.aerobic) +
            parseFloat(row?.original?.weight_resist) +
            parseFloat(row?.original?.flexibility);
          return <div style={{ textAlign: "center" }}>{total}</div>;
        },
      },
      {
        Header: "Cardiorespiratory Training (min)",
        width: 150,
        Cell: (row: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              {row?.original?.aerobic ? row?.original?.aerobic : "-"}
            </div>
          );
        },
      },
      {
        Header: "Flexibility and Balance Training (min)",
        width: 150,
        Cell: (row: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              {row?.original?.flexibility ? row?.original?.flexibility : "-"}
            </div>
          );
        },
      },
      {
        Header: "Resistance Training (min)",
        width: 200,
        Cell: (row: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              {row?.original?.weight_resist
                ? row?.original?.weight_resist
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "Calories (Cal.)/target",
        width: 180,
        Cell: (row: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              {row?.original?.calorie ? row?.original?.calorie : "-"} /{" "}
              {row?.original?.target_calories}
            </div>
          );
        },
      },
    ],
    []
  );

  const subChartData = useMemo(() => {
    if (props.hideFooterChart) {
      return chartData;
    }
    return spliceChartData({
      data: chartData,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [chartData, skipIndex]);

  const RECOMMENDATION = [
    {
      type: intl.formatMessage({ id: "bplusClinicKey662" }),
      duration: intl.formatMessage({ id: "bplusClinicKey663" }),
      frequency: intl.formatMessage({ id: "bplusClinicKey205" }),
    },
    {
      type: EXERCISE_TYPE.AEROBIC.text,
      duration: intl.formatMessage({ id: "bplusClinicKey655" }),
      frequency: intl.formatMessage({ id: "bplusClinicKey656" }),
    },
    {
      type: EXERCISE_TYPE.WEIGHT_RESISTANCE.text,
      duration: intl.formatMessage({ id: "bplusClinicKey657" }),
      frequency: intl.formatMessage({ id: "bplusClinicKey658" }),
    },
    {
      type: EXERCISE_TYPE.FLEXIBILITY.text,
      duration: intl.formatMessage({ id: "bplusClinicKey659" }),
      frequency: intl.formatMessage({ id: "bplusClinicKey660" }),
    },
  ];

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleClickLegends = (tooltipItem: any, data: any) => {
    const target =
      data["datasets"][tooltipItem._datasetIndex].realData[tooltipItem._index];
    setSelectedBarDetail(formatDetail(target, target.value));
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    setDuration(value);
    const start = moment().add("-1", value);
    if (value === "w") {
      start.add(1, "days");
    }
    setStartDate(start);
    setEndDate(moment());
  };

  const handleGetDataByDate = (event: any, data: any) => {
    setStartDate(data.fromDate);
    setEndDate(data.toDate);
  };

  const handleGetElementAtEvent = (chart: any) => {
    if (!chart?.[0] || props.showTooltipChart) {
      return;
    }
    const active = chart[0];
    handleClickLegends?.(active, active?._chart?.config?.data);
  };

  return (
    <div style={{}}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <HeaderChart
        yAxesLabelLeft={intl.formatMessage({ id: "bplusClinicKey829" })}
        yAxesLabelRight={intl.formatMessage({ id: "bplusClinicKey830" })}
        startDate={startDate}
        endDate={endDate}
        durationOptions={durationOptions}
        selectedDuration={duration}
        onChangeDuration={handleChangeDuration}
        onChangeDateRange={handleGetDataByDate}
        labelStyle={{ padding: "10px 30px 5px 18px" }}
        legendStyle={{ gridTemplateColumns: "unset" }}
        legendItem={{
          type: "rect",
          items: [
            {
              name: EXERCISE_TYPE_DETAIL.WEIGHT.text,
              color: EXERCISE_TYPE_DETAIL.WEIGHT.color,
            },
            {
              name: EXERCISE_TYPE_DETAIL.FLEXIBILITY.text,
              color: EXERCISE_TYPE_DETAIL.FLEXIBILITY.color,
            },
            {
              name: EXERCISE_TYPE_DETAIL.AEROBIC.text,
              color: EXERCISE_TYPE_DETAIL.AEROBIC.color,
            },
            {
              name: EXERCISE_TYPE_DETAIL.CALORIES.text,
              color: EXERCISE_TYPE_DETAIL.CALORIES.color,
            },
          ],
        }}
      />

      <div style={{ width: "108%", marginLeft: "-4%", position: "relative" }}>
        <Bar
          ref={barRef}
          data={isSeeAll ? chartData : subChartData}
          options={options}
          height={props.height ?? 180}
          getElementAtEvent={handleGetElementAtEvent}
          plugins={plugins}
        />
        <div ref={tooltipRef} className="tooltip-blood-pressure"></div>
      </div>

      {!props.hideFooterChart && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            chartData?.labels?.slice(labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
          style={{ margin: "-5px 0 20px" }}
        />
      )}

      {props.showCurrentBarDetail && (
        <Form style={{ padding: "0 10px 0px" }}>
          <div style={{ marginTop: "10px", display: "flex" }}>
            <Icon
              name="calendar alternate outline"
              style={{ color: COLOR.blue }}
            />
            <label style={{ color: COLOR.medium_grey, margin: "0 15px 0 5px" }}>
              {selectedBarDetail?.date || defaultDetail?.date}
            </label>

            <Icon name="time" style={{ color: COLOR.blue }} />
            <label style={{ color: COLOR.medium_grey, margin: "0 15px 0 5px" }}>
              {selectedBarDetail?.time || defaultDetail?.time}
            </label>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "40% auto",
              padding: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "115px",
                  backgroundColor: COLOR.baby_blue,
                  color: COLOR.medium_grey,
                  textAlign: "center",
                  lineHeight: "25px",
                }}
              >
                <div style={{ fontSize: "15px" }}>
                  <FormattedMessage id="bplusClinicKey100" />
                </div>
                <div style={{ fontWeight: 800, fontSize: "20px" }}>
                  {`${
                    selectedBarDetail?.allCalories ||
                    selectedBarDetail?.calorie ||
                    defaultDetail?.allCalories ||
                    defaultDetail?.calorie
                  }/${props.exerciseSettingData?.data?.calorie || 0}`}
                </div>
                <div>
                  <FormattedMessage id="bplusClinicKey99" />
                </div>
              </div>
            </div>
            <div className="exercise-detail">
              {/* <div style={{ marginTop: "15px",  }}> */}
              <div>
                <label>
                  <FormattedMessage id="bplusClinicKey556" />
                </label>
                {selectedBarDetail?.calorie || defaultDetail?.calorie || "-"}
              </div>
              <div>
                <label>
                  <FormattedMessage id="bplusClinicKey564" />:
                </label>
                {selectedBarDetail?.exercise || defaultDetail?.exercise}
              </div>
              <div>
                <label>
                  <FormattedMessage id="bplusClinicKey174" />
                </label>
                {selectedBarDetail?.duration || defaultDetail?.duration}
              </div>
              {/* </div> */}
            </div>
          </div>

          <div
            style={{
              padding: "10px 10px 15px",
              textAlign: "center",
              transform:
                (selectedBarDetail?.allCalories ||
                  selectedBarDetail?.calorie ||
                  defaultDetail?.calorie ||
                  0) >= props.exerciseSettingData?.data?.calorie
                  ? ""
                  : "scale(1, -1)",
            }}
          >
            {selectedBarDetail?.allCalories ||
            selectedBarDetail?.calorie ||
            defaultDetail?.calorie ? (
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.92188 17.2754H1.82813C0.818467 17.2754 0 18.0939 0 19.1035L0 37.3848C0 38.3944 0.818467 39.2129 1.82813 39.2129H7.92188C8.93154 39.2129 9.75001 38.3944 9.75001 37.3848V19.1035C9.75001 18.0939 8.93154 17.2754 7.92188 17.2754ZM4.875 36.166C3.86534 36.166 3.04688 35.3475 3.04688 34.3379C3.04688 33.3282 3.86534 32.5098 4.875 32.5098C5.88466 32.5098 6.70313 33.3282 6.70313 34.3379C6.70313 35.3475 5.88466 36.166 4.875 36.166ZM29.25 6.41724C29.25 9.64815 27.2718 11.4604 26.7152 13.6191H34.4637C37.0076 13.6191 38.9881 15.7326 38.9999 18.0446C39.0063 19.4109 38.4252 20.8819 37.5192 21.792L37.5109 21.8004C38.2601 23.578 38.1383 26.0688 36.8019 27.8537C37.4631 29.8262 36.7966 32.2491 35.554 33.5481C35.8814 34.8885 35.7249 36.0294 35.0857 36.9478C33.531 39.1814 29.6778 39.2129 26.4194 39.2129L26.2027 39.2128C22.5246 39.2115 19.5143 37.8723 17.0956 36.7963C15.8801 36.2555 14.2908 35.5861 13.085 35.5639C12.5869 35.5548 12.1875 35.1483 12.1875 34.65V18.3668C12.1875 18.123 12.2852 17.8891 12.4585 17.7177C15.476 14.736 16.7735 11.5793 19.2467 9.10184C20.3744 7.97206 20.7845 6.26551 21.181 4.61517C21.5197 3.20591 22.2283 0.212891 23.7656 0.212891C25.5938 0.212891 29.25 0.822266 29.25 6.41724Z"
                  fill={
                    (selectedBarDetail?.allCalories ||
                      selectedBarDetail?.calorie ||
                      defaultDetail?.calorie ||
                      0) >= props.exerciseSettingData?.data?.calorie
                      ? COLOR.medium_green
                      : COLOR.yellow
                  }
                />
              </svg>
            ) : null}
          </div>

          {/* Table Recommendation for DM patients */}
          <div
            style={{
              overflow: "hidden",
              border: `1px solid ${COLOR.blue}`,
              borderRadius: "3px",
            }}
          >
            <Header
              as="h4"
              style={{ padding: "10px", paddingBottom: 0, marginBottom: "8px" }}
            >
              <FormattedMessage id="bplusClinicKey661" />
            </Header>
            <div style={{ fontWeight: "bold" }}>
              {RECOMMENDATION.map((item, index) => (
                <div
                  key={"recommend" + index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "40% 32% 28%",
                    padding: "5px 10px",
                    ...(index === 0
                      ? {
                          borderTop: "1px solid rgb(43, 152, 225)",
                          borderBottom: "1px solid rgb(43, 152, 225)",
                          backgroundColor: "rgba(28,153,243,0.37)",
                        }
                      : { color: COLOR.medium_grey }),
                  }}
                >
                  <div>{item.type}</div>
                  <div>{item.duration}</div>
                  <div>{item.frequency}</div>
                </div>
              ))}
            </div>
          </div>
        </Form>
      )}

      {!props.showCurrentBarDetail && (
        <>
          <Header as="h2" style={{ color: COLOR.medium_blue }}>
            <FormattedMessage id="bplusClinicKey193" />
          </Header>
          <PureReactTable
            className="weight-table-custom"
            selectedRow={props.selectedPatient}
            data={allBarDetail}
            columns={exerciseColumn}
            showPagination={false}
            pageSize={10}
            manual
          />
        </>
      )}
    </div>
  );
};

DMExerciseChart.defaultProps = DMExerciseChartInitial;

export default React.memo(DMExerciseChart);
