import React, { useState, useEffect } from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Button as MuiButton } from "@material-ui/core";

import { FormattedMessage } from "react-intl";

// Icon

// Types
type ModBookingUnSuccess2Props = {
  open: boolean;
  onClick: () => any;
};

// Images
const IMAGES = {
  online_appointment: "/images/Appointment/online-appointment.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
};

const ModBookingUnSuccess2 = (props: ModBookingUnSuccess2Props) => {
  const [openModUnSuccess, setOpenModUnSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!props.open) {
      return;
    }
    setOpenModUnSuccess(true);
  }, [props.open]);

  const handleClick = () => {
    props.onClick?.();
    setOpenModUnSuccess(false);
  };

  return (
    <div>
      <Dialog open={openModUnSuccess} className="smart-dialog-container">
        <DialogContent>
          <div className="content">
            <img
              src={IMAGES.online_appointment}
              alt="online appointment"
              style={{ width: "100px", margin: "-1rem 0 1rem" }}
            />
            <div
              className="title"
              style={{ marginBottom: "1.25rem", color: COLORS.blue }}
            >
              <FormattedMessage id="bplusClinicKey952" />
            </div>
          </div>

          <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
            <FormattedMessage id="bplusClinicKey953" />
          </div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              style={{ width: "87.5%" }}
              onClick={handleClick}
            >
              <FormattedMessage id="common.ok" />
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(ModBookingUnSuccess2);
