import React, { useState, useCallback, useEffect, useMemo } from "react";
// MUI
import Rating from "@mui/material/Rating";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import moment from "moment";

// UX
import BottomSheetSelectBox from "./BottomSheetSelectBox";

// Const.
import { DOCTOR_REVIEW_OPTIONS } from "./Constants";

// Images
const IMAGES = {
  sort: "/images/Appointment/sort-icon.png",
  outoftime: "/images/Appointment/out-of-time-opacity.png",
};

// Styles
const COLORS = {
  light_grey: "rgba(121, 120, 120, 1)",
  yellow: "rgba(219, 182, 35, 1)",
  border: "rgba(233, 233, 233, 1)",
  grey: "rgba(57, 57, 57, 1)",
};

const scoreStyles = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  "& > div": {
    display: "grid",
    justifyContent: "center",
    fontSize: "1.375rem",
    textAlign: "center",
    fontWeight: "bold",
    "& .title": {
      color: COLORS.light_grey,
      fontSize: "1rem",
      fontWeight: "normal",
      marginTop: "0.4rem",
    },
    "&:nth-child(1)": {
      borderRight: `1px solid ${COLORS.border}`,
    },
  },
  "& .star": {
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: COLORS.yellow,
      marginRight: "0.25rem",
    },
  },
  borderBottom: `1px solid ${COLORS.border}`,
  padding: "1.75rem 1rem 0.75rem",
};

const useStyles = makeStyles((theme) => ({
  score: scoreStyles,
  sort: {
    color: COLORS.grey,
    margin: "1rem 0 0.5rem",
    "&, & > div": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    "& img": {
      width: "14px",
      marginLeft: "0.5rem",
    },
  },
  box_card: {
    color: COLORS.grey,
    "& > div": {
      borderBottom: `1px solid ${COLORS.border}`,
      marginBottom: "1rem",
      paddingBottom: "1rem",
    },
    "& .score": {
      display: "flex",
      alignItems: "center",
      margin: "0.1rem 0 0.5rem",
    },
    "& svg": {
      color: COLORS.yellow,
    },
    "& .dot": {
      width: "3px",
      height: "3px",
      backgroundColor: COLORS.light_grey,
      margin: "0 5px",
    },
  },
  not_found: {
    marginTop: "calc(50vh - 16rem)",
    "& img": { width: "9.0625rem" },
    fontSize: "18px",
    fontWeight: "bold",
    color: COLORS.grey,
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type DoctorReviewProps = {
  review : any
}

const DoctorReview = (props: DoctorReviewProps) => {
  console.log("saika ~ props:", props)
  const classes = useStyles();

  const [openModSort, setOpenModSort] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<any>(false);

  //  callback
  const calculatePastTime = useCallback((date: string) => {
    const units = {
      years: "ปี",
      months: "เดือน",
      weeks: "สัปดาห์",
      days: "วัน",
      hours: "ชั่วโมง",
      minutes: "นาที",
    };

    const [unit] = Object.entries(units).flatMap(([key, value]: any) => {
      const diff = moment().diff(moment(date), key);

      return diff ? [{ diff, name: value }] : [];
    });

    return unit ? `${unit.diff} ${unit.name}ที่ผ่านมา` : "";
  }, []);

  const handleSortReview = (a: any, b: any) => {
    switch(selectedReason?.id || 1) {
      case 2:
        return a.score < b.score ? 1 : -1;
      case 3:
        return a.score > b.score ? 1 : -1;
      default:
        return a.updated < b.updated ? 1 : -1;
    }
        
  };

  const reviewList = useMemo(()=> {
    return  (props.review?.last_reviews || []).sort((a:any,b:any) => handleSortReview(a,b))
  },[props.review?.last_reviews,selectedReason])

  const ratingAll = useMemo(()=> {
    let rating: Number = 0;
    (props.review?.last_reviews || []).forEach((item: any) => {
      rating += item.score || 0
    });

    return  rating
  },[props.review])

  // Hanlder
  const handleOpenModSort = () => {
    setOpenModSort(true);
  };

  const handleCheckThaiLang = (name : string) => {
    const lastChar = name.charAt(name.length - 1)
    const thaiVowels1 = /[ำุูิืี]/;
    const thaiVowels2 = /[์]/

    if (thaiVowels2.test(lastChar)){
      if (thaiVowels1.test(name.charAt(name.length - 2))) {
        return name.slice(name.length-3, name.length)
      } else {
        return name.slice(name.length - 2, name.length)
      }
    } else if (thaiVowels1.test(lastChar)){
      return name.slice(name.length - 2, name.length)
    } else {
      return lastChar
    }
  }

  const handleSelectReason = (selected: Record<string, any>) => {
    setSelectedReason(selected);
    setOpenModSort(false);
  };

  const handleHideName = (name : string) => {
    if (name.length < 2) {
      return "**************"
    }
    const firstChar = name.charAt(0);
    const lastChar = handleCheckThaiLang(name)

    return `${firstChar}************${lastChar}`
  };

  return (
    <div>
      {reviewList?.length ? (
        <>
          <div className={classes.score}>
            <div>
              <div className="star">
                <StarRoundedIcon />
                <label>{props.review.avg_score || 0.0}</label>
              </div>
              <div className="title">{`${ratingAll || 0} เรตติ้ง`}</div>
            </div>
            <div>
              <div>{props.review.last_reviews.length}</div>
              <div className="title">รีวิว</div>
            </div>
          </div>
          <div className={classes.sort}>
            <div aria-hidden="true" onClick={handleOpenModSort}>
              <div>เรียงลำดับตาม</div>
              <img src={IMAGES.sort} />
            </div>
          </div>

          <div className={classes.box_card}>
            {reviewList.map((item : any) => (
              <div key={"card-" + item.id}>
                <div>{handleHideName(item.evaluator_name || "")}</div>
                <div className="score">
                  <Rating value={item.score} precision={1} readOnly={true} />
                  <div className="dot"></div>
                  <div>{calculatePastTime(item.created)}</div>
                </div>
                <div>{item.comment}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={classes.not_found}>
          <img src={IMAGES.outoftime} />
          <div>ยังไม่มีรีวิว</div>
        </div>
      )}

      <BottomSheetSelectBox
        open={openModSort}
        title="เรียงลำดับ"
        options={DOCTOR_REVIEW_OPTIONS}
        // config
        centered={true}
        // callback
        onChange={setOpenModSort}
        onSelect={handleSelectReason}
      />
    </div>
  );
};

const MOCK_DATA = [
  {
    id: 1,
    name: "ย************ศ",
    score: 5,
    created: moment().clone().add(-10, "minutes").toISOString(),
    comment: "แนะนำดีมากค่ะ",
  },
  {
    id: 2,
    name: "ย************ศ",
    score: 2,
    created: moment().clone().add(-2, "hours").toISOString(),
    comment: "แนะนำดีมากค่ะ",
  },
  {
    id: 3,
    name: "ล************",
    score: 3,
    created: moment().clone().add(-3, "days").toISOString(),
    comment: "คุณหมอให้คำแนะนำดีมากค่ะ หายกังวลเลย",
  },
  {
    id: 4,
    name: "ย************ศ",
    score: 4,
    created: moment().clone().add(-2, "weeks").toISOString(),
    comment: "แนะนำดีมากค่ะ",
  },
  {
    id: 5,
    name: "ส*********ย",
    score: 5,
    created: moment().clone().add(-5, "months").toISOString(),
    comment:
      "คุณหมอให้คำแนะนำแบบครบถ้วนและดีๆมากๆค่ะแก้ปัญหาได้ ตรงจุด ตอบทุกคำถามที่สงสัย คุณหมอติดต่อได้ง่ายด้วย ค่ะ คุณภาพอินเทอร์เน็ตดีค่ะ จ่ายยาให้เฉพาะที่จำเป็นค่ะ",
  },
  {
    id: 6,
    name: "ว*********ต",
    score: 5,
    created: moment().clone().add(-2, "years").toISOString(),
    comment: "หมอใจดีมากค่ะ",
  },
];

export default React.memo(DoctorReview);
