import React, {
  useState,
  useRef,
  MutableRefObject,
  DragEvent,
  ChangeEvent,
  useEffect,
  useMemo,
} from "react";
// CSS
import { Button as MuiButton } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// ICON
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";

// Interface
import { PostData } from "./PostExInterface";

type PostSortableProps = {
  // data
  detail: PostData | null;
  // callback
  onChange?: (data: Partial<PostData>) => any;
};

type ListType = { image: string; file?: File | null; active: boolean };

const PostSortable: React.FunctionComponent<PostSortableProps> = (props) => {
  const [list, setList] = useState<ListType[]>([]);

  const photoRef = useRef() as MutableRefObject<HTMLInputElement>;
  const isMounted = useRef(false);
  const dragSrcEl = useRef(null) as
    | MutableRefObject<HTMLLIElement>
    | MutableRefObject<null>;

  useEffect(() => {
    setList(
      (props.detail?.images || []).map((image) => ({ image, active: true }))
    );
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      props.onChange?.({
        extra_field: {
          ...(props.detail?.extra_field || {}),
          image_files: list,
        },
      });
    }
    isMounted.current = true;
  }, [list]);

  const firstIndex = useMemo(() => {
    return list.findIndex((item) => item.active);
  }, [list]);

  // Handle Sortable
  const handleDragStart = (e: DragEvent<HTMLLIElement>, index: number) => {
    const target = e.target as HTMLLIElement;

    target.style.opacity = "0.4";
    dragSrcEl.current = target;

    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDrop = (e: DragEvent<HTMLLIElement>, index: number) => {
    if (e.stopPropagation) {
      e.stopPropagation(); // Stops some browsers from redirecting.
    }

    let oldIndex: number = +e.dataTransfer.getData("text/plain");

    if (oldIndex !== index) {
      const cloneList = [...list];
      const currentData = cloneList[oldIndex];
      cloneList.splice(oldIndex, 1);
      cloneList.splice(index, 0, currentData);

      setList(cloneList);
      handleTransition(e);
    }

    if (dragSrcEl.current !== null) {
      dragSrcEl.current.style.opacity = "";
    }
    dragSrcEl.current = null;

    handleDragLeave(e as any);

    return false;
  };

  const handleTransition = (e: DragEvent<HTMLLIElement>) => {
    const elm: any = e.target;

    elm.style.transition = "transform 3s";
    elm.style.transform = "scale(0.8)";
    setTimeout(() => {
      elm.style.transition = "transform 3s";
      elm.style.transform = "";
    }, 300);
  };

  const handleDragOver = (e: DragEvent<HTMLLIElement>, index: number) => {
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }

    // handleDragEnter(e);
    e.dataTransfer.dropEffect = "move"; // See the section on the DataTransfer object.

    return false;
  };

  const handleDragEnter = (e: DragEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement;
    target.classList.add("over");
  };

  const handleDragLeave = (e: DragEvent<HTMLLIElement>) => {
    (e.target as HTMLLIElement).classList.remove("over"); // this / e.target is previous target element.
  };

  const handleClickArrowTop = (index: number) => {
    const reverse = list.slice(0, index).reverse();
    const findIndex = reverse.findIndex((item) => item.active);

    const nextIndex = reverse.length - findIndex - 1;
    switchList(index, nextIndex);
  };

  const handleClickArrowBottom = (index: number) => {
    const array = list.slice(index + 1);
    const findIndex = array.findIndex((item) => item.active);

    const nextIndex = findIndex + index + 1;
    switchList(index, nextIndex);
  };

  const switchList = (currentIndex: number, nextIndex: number) => {
    const cloneList = [...list];

    [cloneList[currentIndex], cloneList[nextIndex]] = [
      cloneList[nextIndex],
      cloneList[currentIndex],
    ];

    setList(cloneList);
  };

  const handleRemoveItem = (index: number) => {
    const filter = list.map((item, idx) =>
      idx === index ? { ...item, active: false } : item
    );

    setList(filter);
  };

  // ===> End

  const handleChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      setList([
        ...list,
        { image: URL.createObjectURL(file), file, active: true },
      ]);
    }
  };

  const handleClickAddPhoto = () => {
    photoRef.current?.click?.();
  };

  return (
    <>
      {!!list.length && (
        <>
          <Box className="PostSortableList-root">
            <List>
              <TransitionGroup>
                {list.map(
                  (item, index) =>
                    item.active && (
                      <Collapse key={"sort-item" + index}>
                        <ListItem
                          onDragStart={(e: DragEvent<HTMLLIElement>) => {
                            handleDragStart(e, index);
                          }}
                          onDragOver={(e: DragEvent<HTMLLIElement>) => {
                            handleDragOver(e, index);
                          }}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={(e) => {
                            handleDrop(e, index);
                          }}
                          draggable={true}
                        >
                          <div>
                            {index !== firstIndex && (
                              <MuiButton
                                variant="outlined"
                                className="PostButton-arrow top"
                                onClick={(e) => {
                                  handleClickArrowTop(index);
                                }}
                              >
                                <ArrowBackIosIcon />
                              </MuiButton>
                            )}
                            {index !== list.length - 1 && (
                              <MuiButton
                                variant="outlined"
                                className="PostButton-arrow bottom"
                                onClick={() => {
                                  handleClickArrowBottom(index);
                                }}
                              >
                                <ArrowForwardIosIcon />
                              </MuiButton>
                            )}
                          </div>
                          <div>
                            <div style={{ height: "41px" }}>
                              <img
                                src={item.image}
                                alt={`image-list-${index}.image`}
                                style={{
                                  height: "100%",
                                  width: "fit-content",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <MuiButton
                              variant="contained"
                              className="PostButton-remove"
                              onClick={() => {
                                handleRemoveItem(index);
                              }}
                            >
                              <RemoveIcon />
                            </MuiButton>
                          </div>
                        </ListItem>
                      </Collapse>
                    )
                )}
              </TransitionGroup>
            </List>
          </Box>
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<AddPhotoAlternateOutlinedIcon />}
            style={{ padding: "10px 0", marginTop: 0 }}
            onClick={handleClickAddPhoto}
          >
            Add photos
          </MuiButton>
        </>
      )}

      {!list.length && (
        <div className="PostPreview-banners" onClick={handleClickAddPhoto}>
          <>
            <AddPhotoAlternateOutlinedIcon />
            <label>Add photos</label>
          </>
        </div>
      )}

      <input
        ref={photoRef}
        type="file"
        accept="image/*"
        id="file"
        onChange={handleChangePhoto}
        hidden
      />
    </>
  );
};

export default React.memo(PostSortable);
