import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import moment from "moment";

// APIs
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import DevelopmentList from "issara-sdk/apis/DevelopmentList_apps_PHR";
import DevelopmentDetail from "issara-sdk/apis/DevelopmentDetail_apps_PHR";

// Types
import DevelopmentSerializer from "issara-sdk/types/DevelopmentSerializer_apps_PHR";

// Interface
import { State as MainState } from "../../ManageInterface";

export type State = {
  developmentChoice?: Record<string, any>[];
  developmentUMDetail?: Partial<{
    id?: number;
    age_range: number | null;
    skill: string;
    data: { description: string[] | null };
  }>;
  developmentUMList?: DevelopmentSerializer[];
  allDevelopmentUMList?: DevelopmentSerializer[];
};

type Picked = Pick<
  MainState,
  "errorMessage" | "successMessage" | "loadingStatus" | "ageRangeList"
>;

export const StateInitial: State = {
  developmentChoice: [],
  developmentUMDetail: {},
  developmentUMList: [],
  allDevelopmentUMList: [],
};

type AgeRangeType = "VACCINE_CHILD" | "DEVELOPMENT";
type VaccineType = "DM" | "CHILD";

export type Event =
  // GET
  | { message: "GetChoiceDevelopment"; params: {} }
  | { message: "GetListDevelopment"; params: {} }
  // CHANGE
  | {
    message: "HandleChangeDevelopmentUMDetail";
    params: State["developmentUMDetail"];
  }
  // SAVE
  | { message: "HandleSaveDevelopmentUM"; params: {} }
  // DELETE
  | { message: "HandleDeleteDevelopmentUM"; params: { id: number | null } }
  // Handle
  | { message: "HandleSelectedDevelopmentUM"; params: { data: any } }
  | { message: "HandleSearchDevelopmentUM"; params: { search: string } };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

/* ------------------------- GET ------------------------ */
export const GetListDevelopment: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  const [res, error] = await DevelopmentList.list({
    apiToken: Cookies.get("apiToken"),
  });

  controller.setState({
    developmentUMDetail: {},
    developmentUMList: res?.items || [],
    allDevelopmentUMList: [...(res?.items || [])],
    loadingStatus: false,
  });
};

export const GetChoiceDevelopment: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [res, error] = await ChoiceView.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      model: "PHR.Development",
      field: "skill",
      name_as_id: true,
    },
  });

  controller.setState({
    developmentChoice: res?.items,
    loadingStatus: false,
  });
};

/* ----------------------- CHANGE ----------------------- */
export const HandleChangeDevelopmentUMDetail: Handler = (
  controller,
  params
) => {
  const { developmentUMDetail } = controller.getState();

  controller.setState({
    developmentUMDetail: {
      ...developmentUMDetail,
      ...params,
    },
  });
};

/* ------------------------ SAVE ------------------------ */
export const HandleSaveDevelopmentUM: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const { developmentUMDetail } = controller.getState();

  const data = {
    skill: developmentUMDetail?.skill,
    age_range: developmentUMDetail?.age_range,
    data: {
      method: (developmentUMDetail?.data?.description || [])
        .filter(Boolean)
        .map((value) => ({ name: value })),
    },
  } as any;

  let [res, error]: [any, any] = [null, null];

  if (developmentUMDetail?.id) {
    [res, error] = await DevelopmentDetail.update({
      pk: developmentUMDetail.id,
      apiToken: Cookies.get("apiToken"),
      data,
    });
  } else {
    [res, error] = await DevelopmentList.create({
      apiToken: Cookies.get("apiToken"),
      data,
    });
  }

  if (error) {
    controller.setState({ errorMessage: error, loadingStatus: false });
    return;
  }

  GetListDevelopment(controller, params);
  controller.setState({
    successMessage: `${developmentUMDetail?.id ? "Update" : "Create"
      } development success.`,
    loadingStatus: false,
  });
};

/* ----------------------- Delete ----------------------- */
export const HandleDeleteDevelopmentUM: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });
  const { developmentUMList } = controller.getState();
  let errorMessage: any = null;
  let successMessage: any = null;
  const [res, error] = await DevelopmentDetail.update({
    pk: params.id,
    apiToken: Cookies.get("apiToken"),
    data: {
      ...(developmentUMList?.find((item) => item.id === params.id) || {}),
      active: false,
    },
  });

  if (error) {
    errorMessage = error;
  } else {
    successMessage = `Delete development success.`;
    GetListDevelopment(controller, params);
  }

  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage,
    successMessage: successMessage,
  });
};

/* ----------------------- HANDLE ----------------------- */

export const HandleSelectedDevelopmentUM: Handler = (controller, params) => {
  const { data } = params;
  controller.setState({
    developmentUMDetail: {
      ...data,
      data: {
        description: data.data?.method?.map((item: any) => item.name) || []
      }
    },
  });
};

export const HandleSearchDevelopmentUM: Handler = (
  controller,
  params: Extract<Event, { message: "HandleSearchDevelopmentUM" }>["params"]
) => {
  const { allDevelopmentUMList, developmentChoice, ageRangeList } =
    controller.getState();
  const { search } = params;

  const filter = (allDevelopmentUMList || []).filter((item) => {
    const skillName = (
      ageRangeList?.find((acc: any) => acc?.id === item?.age_range)?.name || ""
    ).toLowerCase();
    const ageRangeName = (
      developmentChoice?.find((acc: any) => acc?.id === item?.skill)?.value ||
      ""
    ).toLowerCase();

    const description = (
      item?.data?.description?.join(", ") || ""
    ).toLowerCase();
    const searchData = search.toLowerCase();

    return (
      skillName?.includes(searchData) ||
      ageRangeName?.includes(searchData) ||
      description?.includes(searchData)
    );
  });
  controller.setState({
    developmentUMList: [...filter],
  });
};
