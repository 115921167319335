import { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import React, { useState, useMemo, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Image, Grid, Button, Dimmer, Loader } from "semantic-ui-react";
import { convertToBEDate } from "react-lib/utils/tsUtils";
import { formatDate } from "react-lib/utils";
import CircularProgress from "@mui/material/CircularProgress";
//component

// Use styles
import "./TeleDesktopCSS.scss";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  gary: "#797878",
  lightGary: "#D9D9D9",
};

const styles = {
  profile_image: {
    width: "80px",
    padding: "5px",
    margin: "20px 10px",
    borderRadius: "10px",
    background: "white",
  } as CSSProperties,
  title_logo: {
    fontSize: "20px",
    color: COLOR.primary,
    alignItems: "center",
    fontWeight: "bold",
    display: "flex",
    marginLeft: "20px",
  } as CSSProperties,
  payment_titlebar: {
    background: COLOR.blue,
    padding: "5px 0px",
    color: "white",
    margin: "10px 0px",
  } as CSSProperties,
  button_pay: {
    padding: "10px 20px",
    color: "white",
    background: COLOR.blue,
  } as CSSProperties,
  paymentlist: {
    height: "calc(100vh - 160px)",
    overflowY: "scroll",
  } as CSSProperties,
  textlightGray: {
    fontSize: "16px",
    color: COLOR.gary,
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
};

const STATUS_KEY = {
  BILLED: "BILLED",
  PAID: "PAID",
  PENDING: "PENDING",
  CANCELED: "CANCELED",
};

type PaymentProps = {
  onEvent: (e: any) => any;
  mobpayment?: any;
  clickButton: (item: any) => any;
  paymentController?: any;
  apiToken: any;
  patientInfo: any;
  open: boolean;
  loadingStatusPayment?: Record<string, any>;
};

const Payment = (props: PaymentProps) => {
  const [paymentState, setPaymentState] = useState<string>(STATUS_KEY.PENDING);
  const [waitingInvoiceList, setWaitingInvoiceList] = useState([]);
  const [paidInvoiceList, setPaidInvoiceList] = useState([]);
  const [cancelInvoice, setCancelInvoice] = useState([]);
  const [noPatientId, setNoPatientId] = useState(false);
  const [isLoadingWaiting, setIsLoadingWaiting] = useState(false);
  const [isLoadingPaid, setIsLoadingPaid] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const isMounted = useRef(true);

  const handleChangPaymentState = (stete: string) => {
    setPaymentState(
      stete === STATUS_KEY.PENDING ? STATUS_KEY.PENDING : STATUS_KEY.PAID
    );
  };

  useEffect(() => {
    if (props.patientInfo?.patient) {
      setNoPatientId(false);
      handleGetWaitingInvoice();
      var interval = setInterval(() => {
        handleGetWaitingInvoice();
      }, 16000);
      handleGetPaidInvoice();
      handleGetCancelInvoice();
    } else {
      setNoPatientId(true);
    }
    return () => {
      clearInterval(interval);
    };
  }, [props.patientInfo]);

  const handleGetWaitingInvoice = async () => {
    setIsLoadingWaiting(true);
    if (!props.patientInfo.patient) {
      setIsLoadingWaiting(false);
      return;
    }
    const [res, err, net] = await props.paymentController.getInvoice({
      apiToken: props.apiToken,
      patientId: props.patientInfo.patient,
      status: STATUS_KEY.BILLED,
      divisionId: "",
    });

    // console.log("saika1 ;) handleGetWaitingInvoice -> ", res);
    if (isMounted.current) {
      // res have next & prev url, currently not use :: CHERRY 13/07/2020 15.33
      setIsLoadingWaiting(false);
      if (res) {
        setWaitingInvoiceList(res.items);
      } else {
        setWaitingInvoiceList([]);
      }
    }
  };

  const handleGetCancelInvoice = async () => {
    setIsLoadingCancel(true);
    if (!props.patientInfo.patient) {
      setIsLoadingCancel(false);
      return;
    }
    const [res, err, net] = await props.paymentController.getInvoice({
      apiToken: props.apiToken,
      patientId: props.patientInfo.patient,
      status: STATUS_KEY.CANCELED,
      divisionId: "",
    });

    // console.log("saika1 ;) handleGetCancelInvoice -> ", res);
    if (isMounted.current) {
      // res have next & prev url, currently not use :: CHERRY 13/07/2020 15.33
      setIsLoadingCancel(false);
      if (res) {
        setCancelInvoice(res.items);
      } else {
        setCancelInvoice([]);
      }
    }
  };

  const handleGetPaidInvoice = async () => {
    setIsLoadingPaid(true);
    if (!props.patientInfo.patient) {
      setNoPatientId(true);
      setIsLoadingPaid(false);
      return;
    }

    const date = new Date();

    const [res, err, net] = await props.paymentController.getReceipt({
      apiToken: props.apiToken,
      patientId: props.patientInfo.patient,
      divisionId: "",
      fromDate: formatDate(moment().subtract(7, "days")),
    });
    // console.log("saika1 ;) handleGetPaidInvoice ", res, " err ", err);

    if (isMounted.current) {
      setIsLoadingPaid(false);
      console.log({ res });
      if (res) {
        setPaidInvoiceList(res.items);
      } else {
        setPaidInvoiceList([]);
      }
    }
  };

  const handleViewReceipt = async (paymentID: number, receiptUrl : string) => {
    const linkcode = /linkcode=(.*)/g.exec(receiptUrl)?.[1] || "";
    console.log("saika ~ linkcode:", linkcode)
    props.onEvent({
      message: "HandleDownloadReceipt",
      params: {
        card: `payment_${paymentID}`,
        receiptId: paymentID,
        hospital: 1,
        linkcode: linkcode
      },
    });

    // setIsLoadingPaid(true);
    // const [res, err, network] =
    //   await props.paymentController.postPaymentParameters({
    //     apiToken: props.apiToken,
    //     invoiceId: paymentID,
    //   });

    // if (res) {
    //   setIsLoadingPaid(false);
    //   window.open(`${res.endpoint}/?linkcode=${res.linkcode}`);
    // }
  };

  return (
    <div>
      <div style={{ display: "flex", margin: "10px" }}>
        <Image src="/logo512.png" style={{ width: "35px" }} />
        <div style={styles.title_logo}>My B+</div>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          style={{
            ...styles.button_pay,
            margin: "0 20px",
            background:
              paymentState == STATUS_KEY.PENDING ? COLOR.blue : COLOR.lightGary,
          }}
          onClick={() => handleChangPaymentState(STATUS_KEY.PENDING)}
        >
          {"Pending payment"}
        </Button>
        <Button
          style={{
            ...styles.button_pay,
            background:
              paymentState == STATUS_KEY.PAID ? COLOR.blue : COLOR.lightGary,
          }}
          onClick={() => handleChangPaymentState(STATUS_KEY.PAID)}
        >
          {"Paid"}
        </Button>
      </div>
      {paymentState === STATUS_KEY.PENDING ? (
        <div style={{ padding: "10px 20px" }}>
          <Grid style={{ margin: "0px" }}>
            <Grid.Row style={styles.payment_titlebar}>
              <Grid.Column width={9}>{"วันที่เข้ารับการรักษา"}</Grid.Column>
              <Grid.Column width={4}>{"ยอดที่ต้องชำระ"}</Grid.Column>
              <Grid.Column width={3} />
            </Grid.Row>
          </Grid>
          <div style={styles.paymentlist}>
            <Grid style={{ margin: "0px" }}>
              {/*---------------------waitingInvoiceList-------------------*/}

              {waitingInvoiceList
                ? waitingInvoiceList.map((item: any, index: any) => (
                    <Grid.Row
                      style={{ margin: "10px 0", padding: "0px" }}
                      key={index}
                    >
                      <Grid.Column width={9}>
                        {convertToBEDate({
                          date: moment(item.edited).format("DD/MM/YYYY HH:mm"),
                          startFormat: "DD/MM/YYYY HH:mm",
                          endFormat: "DD/MM/YYYY HH:mm",
                        })}
                      </Grid.Column>
                      <Grid.Column width={4}>{item.price}</Grid.Column>
                      <Grid.Column width={3}>
                        <Button
                          style={{ ...styles.button_pay, width: "100%" }}
                          onClick={() => props.clickButton(item.id)}
                        >
                          {"ชำระ"}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  ))
                : ""}
              {noPatientId && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={styles.textlightGray}>
                    <FormattedMessage id="common.an_error_occurred" />
                  </div>
                  <div style={styles.textlightGray}>
                    <FormattedMessage id="payment.no_patient_id_error" />
                  </div>
                </div>
              )}
              <Dimmer.Dimmable style={{ width: "100%", margin: "20px" }}>
                <Dimmer active={isLoadingWaiting} inverted>
                  <Loader inverted>Loading...</Loader>
                </Dimmer>
              </Dimmer.Dimmable>
            </Grid>
          </div>
        </div>
      ) : (
        <div style={{ padding: "10px 20px" }}>
          <Grid style={{ margin: "0px" }}>
            <Grid.Row style={styles.payment_titlebar}>
              <Grid.Column width={9}>{"วันที่เข้ารับการรักษา"}</Grid.Column>
              <Grid.Column width={4}>{"ยอดที่ต้องชำระ"}</Grid.Column>
              <Grid.Column width={3} />
            </Grid.Row>
          </Grid>
          <div style={styles.paymentlist}>
            <Grid style={{ margin: "0px" }}>
              {/*-----------------paidInvoiceList & cancelInvoice-----------------*/}
              {paidInvoiceList && cancelInvoice
                ? [...paidInvoiceList, ...cancelInvoice].map(
                    (item: any, index: any) => (
                      <Grid.Row
                        style={{ margin: "10px 0", padding: "0px" }}
                        key={index}
                      >
                        <Grid.Column width={9}>
                          {convertToBEDate({
                            date: moment(item.edited).format(
                              "DD/MM/YYYY HH:mm"
                            ),
                            startFormat: "DD/MM/YYYY HH:mm",
                            endFormat: "DD/MM/YYYY HH:mm",
                          })}
                        </Grid.Column>
                        <Grid.Column width={4}>{item.price}</Grid.Column>
                        <Grid.Column width={3}>
                          {/* {console.log("saika item paid", item)} */}
                          {item.status_name === STATUS_KEY.PAID ? (
                            <div
                              style={{
                                ...styles.button_pay,
                                width: "100%",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                aria-hidden="true"
                                onClick={() => handleViewReceipt(item.id,item.receipt_url)}
                                style={{display: "flex", gap: "5px", justifyContent: "center"}}
                              >
                                <span>{"ดูใบเสร็จรับเงิน"}</span>
                                {props.loadingStatusPayment?.[
                                  `payment_${item.id}`
                                ] && (
                                  <CircularProgress
                                    style={{
                                      marginLeft: "0.5rem",
                                      color: "white"
                                    }}
                                    size={18}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <Button
                              style={{
                                ...styles.button_pay,
                                background: COLOR.lightGary,
                                width: "100%",
                              }}
                            >
                              {"ไม่ต้องชำระ"}
                            </Button>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    )
                  )
                : ""}
              {noPatientId && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={styles.textlightGray}>
                    <FormattedMessage id="common.an_error_occurred" />
                  </div>
                  <div style={styles.textlightGray}>
                    <FormattedMessage id="payment.no_patient_id_error" />
                  </div>
                </div>
              )}
              <Dimmer.Dimmable style={{ width: "100%", margin: "20px" }}>
                <Dimmer active={isLoadingPaid || isLoadingCancel} inverted>
                  <Loader inverted>Loading...</Loader>
                </Dimmer>
              </Dimmer.Dimmable>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Payment);
