import React, { useEffect, useMemo } from "react";
import MobHeadApp from "bplus-lib/feed/MobHeadApp";
import MobFeed from "bplus-lib/MobFeed";
import { HtmlIframeProps } from "semantic-ui-react";

const PreferredPreviewAppointment = (props: any) => {
  useEffect(() => {
    // setIdx(Math.random());
    console.log("props.servicesOfHospitalDJ", props.servicesOfHospitalDJ);
    let iframe = document.getElementById(
      "prefered-simulator"
    ) as HtmlIframeProps;
    iframe?.contentWindow?.postMessage("updatesimulator", "*");
    // console.log("iframe: ", iframe);
  }, [props.servicesOfHospitalDJ]);

  console.log(
    "PreferredPreviewAppointment  props.servicesOfHospitalDJ: ",
    props.servicesOfHospitalDJ
  );

  console.log(
    " PreferredPreviewAppointment props.selectedOrganizationCode: ",
    props.selectedOrganizationCode
  );

  let name = props.organizationList?.find(
    (item: any) => item.code == props.selectedOrganizationCode
  )?.name;
  let nameEn = props.organizationList?.find(
    (item: any) => item.code == props.selectedOrganizationCode
  )?.name_en;
  console.log(" PreferredPreviewAppointment name: ", name);

  console.log(" PreferredPreviewAppointment nameEn: ", nameEn);
  return (
    <div className="PreferredPreview">
      <div className="PreferredHeader-mobilePreview">Mobile Preview</div>
      <div className="PreferredBox-main">
        <div className="PreferredBox-mobilePreview">
          <iframe
            id="prefered-simulator"
            src={`/select-specialty/?app=MobAppointmentSimulator&name=${name}&name_en=${nameEn}&code=${props.selectedOrganizationCode}`}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferredPreviewAppointment;
