import React, { useState, useEffect, forwardRef } from "react";
import { Divider, Input, Label, InputProps, Button } from "semantic-ui-react";

import { FormattedMessage ,useIntl} from "react-intl";

// UI
import PhoneNumberInput from "./PhoneNumberInput";

// Styles
import "./RegisterCSS.scss";

// Types
import { registerInfo } from "./TypeModal";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

type NormalLoginProps = {
  onEvent: (e: any) => any;
  history: any;
  loading?: boolean;
  registerInfo?: registerInfo;
};

const NormalLogin: React.FunctionComponent<NormalLoginProps> = (props) => {
  const intl = useIntl();
  const [useEmail, setUseEmail] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");

  useEffect(() => {
    if (
      props.registerInfo?.typeUsername === "email" &&
      props.registerInfo?.username
    ) {
      setUseEmail(true);
      setUsername(props.registerInfo?.username.replace("-", "@") || "");
    } else if (
      props.registerInfo?.typeUsername === "phoneNumber" &&
      props.registerInfo?.username
    ) {
      setUsername(props.registerInfo?.username || "");
    }
  }, [props.registerInfo?.typeId, props.registerInfo?.typeUsername]);

  useEffect(() => {
    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "password", value: "" },
    });
  }, []);

  const handleHistoryGoTo = (action: string) => {
    handleClearError("username")
    handleClearError("password")
    if (!props.loading) {
      switch (action) {
        case "login":
          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: { key: "username", value: username },
          });

          props.onEvent({
            message: "HandleSetValueRegisterInfo",
            params: {
              key: "typeUsername",
              value: useEmail ? "email" : "phoneNumber",
            },
          });
          handleClearError("message");
          props.onEvent({
            message: "HandleLogin",
            params: { history: props.history },
          });
          break;
        case "register":
          props.history.push("/register/?app=MobRegister");
          break;
        case "forgot-password":
          props.history.push("/register/forgot-password/?app=MobRegister");
          break;
        case "sms":
          props.history.push("/login/sms/?app=MobRegister");
          break;
      }
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};
    if (filed === "password" && props.registerInfo?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }
    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  return (
    <>
      <div>
        <div className="title-text-blue">
          {<FormattedMessage id="bplusClinicKey958" />}
        </div>
        <div className="type-register" style={{ margin: "24px 0px 10px" }}>
          <div
            {...(!useEmail && { className: "active" })}
            onClick={() => setUseEmail(false)}
          >
            {<FormattedMessage id="profile.phone_no" />}
          </div>
          <div
            {...(useEmail && { className: "active" })}
            onClick={() => setUseEmail(true)}
          >
            {<FormattedMessage id="profile.email" />}
          </div>
        </div>

        <div
          className={
            useEmail ? "content-filed-input" : "content-filed-input-phone"
          }
        >
          <Label>
            {useEmail ? (
              <FormattedMessage id="profile.email" />
            ) : (
              <FormattedMessage id="profile.phone_no" />
            )}
          </Label>

          <div onClick={() => handleClearError("username")}>
            {useEmail ? (
              <Input
                {...((props.registerInfo?.error?.password ||
                  props.registerInfo?.error?.message || props.registerInfo?.error?.username) && {
                  className: "error-input",
                })}
                value={username || ""}
                onChange={(e: any, v: InputProps) => {
                  setUsername(v.value);
                }}
              ></Input>
            ) : (
              <PhoneNumberInput
                error={props.registerInfo?.error?.password ||
                  props.registerInfo?.error?.message || props.registerInfo?.error?.username}
                value={username || ""}
                onChange={(e, data) => {
                  setUsername(data.value as string);
                }}
              />
            )}
          </div>
        </div>

        <div className="content-filed-input">
          <Label>{<FormattedMessage id="bplusClinicKey981" />}</Label>
          <div onClick={() => handleClearError("password")}>
            <Input
              {...((props.registerInfo?.error?.password ||
                props.registerInfo?.error?.message || props.registerInfo?.error?.username) && {
                className: "error-input",
              })}
              value={props.registerInfo?.password}
              icon={{
                name: !props.registerInfo?.hidePassword ? "eye slash" : "eye",
                link: true,
                onClick: (e: any) => {
                  props.onEvent({
                    message: "HandleSetValueRegisterInfo",
                    params: {
                      key: "hidePassword",
                      value: !props.registerInfo?.hidePassword,
                    },
                  });
                },
              }}
              type={!props.registerInfo?.hidePassword ? "password" : "input"}
              onChange={(e: any, v: InputProps) => {
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "password", value: v.value },
                });
              }}
            />
          </div>
          <div
            {...((props.registerInfo?.error?.password ||
              props.registerInfo?.error?.message || props.registerInfo?.error?.username) && {
              className: "error",
            })}
          >
            {props.registerInfo?.error?.message
              ? useEmail ? `${intl.formatMessage({ id: "bplusClinicKey1124" })}` : `${intl.formatMessage({ id: "bplusClinicKey1123" })}`
              : useEmail ? `${intl.formatMessage({ id: "bplusClinicKey1122" })}` : `${intl.formatMessage({ id: "bplusClinicKey1121" })}`}
          </div>
        </div>

        <div
          className="highlight"
          onClick={() => handleHistoryGoTo("forgot-password")}
          style={{ justifyContent: "flex-end", margin: "10px" }}
        >
          {
            <>
              <FormattedMessage id="bplusClinicKey979" />?
            </>
          }
        </div>

        <div style={{ padding: "40px" }}>
          <div className="bplus-buttom">
            <Button
              style={{ width: "100%" }}
              loading={props.loading}
              onClick={() => handleHistoryGoTo("login")}
            >
              <FormattedMessage id="bplusClinicKey958" />
            </Button>
          </div>
          <Divider
            horizontal
            inverted
            style={{ color: "#A2BFC9", fontSize: "14px" }}
          >
            <FormattedMessage id="common.or" />
          </Divider>
          <div className="bplus-buttom">
            <div
              style={{
                background: "#0147a30d",
                color: COLOR.primary,
              }}
              onClick={() => handleHistoryGoTo("sms")}
            >
              {<FormattedMessage id="bplusClinicKey982" />}
            </div>
          </div>
        </div>
      </div>

      <div
        className="div-center-bottom"
        style={{ color: "", flex: "1", alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            color: "#797878",
            whiteSpace: "pre-line",
          }}
        >
          {<FormattedMessage id="bplusClinicKey980" />}
          {
            <div
              className="highlight"
              onClick={() => handleHistoryGoTo("register")}
            >
              {<FormattedMessage id="bplusClinicKey957" />}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default React.memo(NormalLogin);
