import React, {
  useState,
  useEffect,
  CSSProperties,
  useMemo,
  ReactChild,
} from "react";
import { useHistory } from "react-router-dom";

// CSS
import moment from "moment";
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import ReactTable from "react-table-6";
import { Line } from "react-chartjs-2";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";

import { AppBar } from "@material-ui/core";

// UI
import MobHead from "../MobHead";
import PatientPanel from "./PatientPanel";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { HeaderChart } from "./DMEmotionRestChart";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  calculateDelivery,
  convertToADDate,
  formatDate,
  checkPregnancyWeightGain,
  formatDateByLanguage,
} from "../../bplus-lib/MobClinicInterface";

// Utils
import { beToAd } from "react-lib/utils";

// Styles "../../css/WebClinic.scss";
import "./MomWeightGain.scss";

// TO DO: onClickLegend // change Item pregnancy

export const DATE_FORMAT = "DD/MM/YYYY";

const styles = {
  header: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#0072BC",
    textAlign: "center",
    padding: "15px 0px",
  } as CSSProperties,
  flexRow: {
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  flexWeightShow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  subHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "5px",
    color: "#4F4F4F",
  } as CSSProperties,
  showValue: {
    fontSize: "20px",
    color: "#2399E5",
    fontWeight: "bold",
    padding: "5px",
  } as CSSProperties,
  showDate: {
    color: "#747474",
  } as CSSProperties,
  modTitle: {
    width: "100%",
    padding: "15px",
    backgroundColor: "#2399E5",
    color: "#FFFFFF",
    fontSize: "20px",
  } as CSSProperties,
  modInput: {
    width: "60px",
  } as CSSProperties,
  modSubTitle: {
    color: "#0072BC",
    fontSize: "22px",
    padding: "5px",
  } as CSSProperties,
  modDivInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "5px 0px 15px 0px",
  } as CSSProperties,
  modDot: {
    fontSize: "30px",
    color: "#0072BC",
    padding: "5px",
  } as CSSProperties,
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as CSSProperties,
};

// ModPregnancy
type ModPregnancyProps = {
  open?: boolean;
  onClose?: any;
  onEvent?: any;
};

const ModPregnancy: React.FC<ModPregnancyProps> = (props) => {
  const intl = useIntl();
  const [kilogram, setKilogram] = useState<number>(0);
  const [gram, setGram] = useState<number>(0);

  useEffect(() => {
    setKilogram(0);
    setGram(0);
  }, [props.open]);

  const handleAddPrePregnantWeight = () => {
    const currentWeight = `${Math.trunc(kilogram)}.${gram
      .toString()
      .substring(0, 2)}`;
    props.onEvent({
      message: "handleAddPrePregnantWeight",
      params: { weight: currentWeight },
    });

    props.onClose();
  };

  return (
    <Modal closeIcon open={props.open} onClose={props.onClose} dimmer="burring">
      <Modal.Header
        style={{ background: "var(--text-light-blue)", color: "white" }}
      >
        <FormattedMessage id="bplusClinicKey538" />
      </Modal.Header>
      <Modal.Content
        scrolling
        style={{ textAlign: "center", overflow: "unset" }}
      >
        <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
          <FormattedMessage id="bplusClinicKey379" />
        </Header>
        <Header
          as="h3"
          style={{ color: "var(--text-dark-blue)", marginTop: "0px" }}
        >
          <FormattedMessage id="bplusClinicKey552" />
        </Header>
        <Grid centered verticalAlign="bottom" style={{ padding: "30px 10px" }}>
          <Grid.Column width={4}>
            <Input
              fluid
              type="number"
              size="big"
              value={kilogram}
              onChange={(e, { value }: any) => {
                setKilogram(value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
              .
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Input
              fluid
              type="number"
              size="big"
              value={gram}
              onChange={(e, { value }: any) => {
                setGram(value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey572" />
            </Header>
          </Grid.Column>
        </Grid>
        <Button
          content={intl.formatMessage({ id: "common.confirm" })}
          color="blue"
          onClick={handleAddPrePregnantWeight}
        />
      </Modal.Content>
    </Modal>
  );
};

// ModCurrent
type ModCurrentProps = {
  open?: boolean;
  onClose?: any;
  onEvent?: any;
  obstreticRecord?: any;
  weightPrePregnancy?: any;
  weightGainList?: any;
};

const ModCurrent: React.FC<ModCurrentProps> = (props) => {
  const intl = useIntl();
  const [kilogram, setKilogram] = useState<number>(0);
  const [gram, setGram] = useState<number>(0);
  const [saveDate, setSaveDate] = useState<moment.Moment>(moment());
  const [lastItem, setLastItem] = useState<any>({});

  useEffect(() => {
    setKilogram(0);
    setGram(0);
    setSaveDate(moment());
  }, [props.open]);

  useEffect(() => {
    setLastItem({});
    setKilogram(0);
    setGram(0);
    if (props.weightGainList?.total > 0) {
      props.weightGainList?.items?.forEach((item: any) => {
        const measureDate = moment(
          item?.measure_date,
          `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
        );
        if (measureDate.format(DATE_FORMAT) === saveDate.format(DATE_FORMAT)) {
          setLastItem(item);
          const weight = item?.data?.weight?.split(".");
          setKilogram(weight?.[0]);
          setGram(weight?.[1]);
        }
      });
    }
  }, [saveDate]);

  // change date
  const handleChangeDateRecord = (date: any) => {
    setSaveDate(moment(convertToADDate(date), DATE_FORMAT));
  };

  const handleChangeStepDateRecord = (event: object, data: any) => {
    let targetDate: moment.Moment = saveDate.clone();
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
    } else {
      targetDate = targetDate.add(1, "days");
    }
    setSaveDate(targetDate);
  };

  // save
  const handleOnConfirmCurrent = () => {
    // weight
    const currentWeight = `${Math.trunc(kilogram)}.${gram
      .toString()
      .substring(0, 2)}`;

    // ga
    const deliveryResult = calculateDelivery(
      props.obstreticRecord?.data?.by_ultrasound,
      saveDate,
      moment(props.obstreticRecord?.data?.lmp_date, BACKEND_DATE_FORMAT),
      moment(props.obstreticRecord?.data?.edc_date, BACKEND_DATE_FORMAT)
    );
    // json
    let weightGainData = { ...lastItem };
    if (Object.keys(weightGainData).length === 0) {
      weightGainData.measure_date = `${saveDate.format(
        BACKEND_DATE_FORMAT
      )} ${moment().format(BACKEND_TIME_FORMAT)}`;
    }
    weightGainData.data = {
      pre_weight: props.weightPrePregnancy?.data?.weight,
      weight: currentWeight,
      gain: (
        parseFloat(currentWeight) -
        parseFloat(props.weightPrePregnancy?.data?.weight)
      ).toFixed(2),
      ga_days: deliveryResult?.ga_days,
      ga_weeks: deliveryResult?.ga_weeks,
    };

    props.onEvent({ message: "handleAddWeightGain", params: weightGainData });
    props.onClose();
  };

  return (
    <Modal closeIcon open={props.open} onClose={props.onClose} dimmer="burring">
      <Modal.Header
        style={{ background: "var(--text-light-blue)", color: "white" }}
      >
        <FormattedMessage id="bplusClinicKey538" />
      </Modal.Header>
      <Modal.Content
        scrolling
        style={{ textAlign: "center", overflow: "unset" }}
      >
        <Header as="h3" style={{ color: "var(--text-dark-blue)" }}>
          <FormattedMessage id="bplusClinicKey379" />
        </Header>
        <Header
          as="h3"
          style={{ color: "var(--text-dark-blue)", marginTop: "0px" }}
        >
          <FormattedMessage id="bplusClinicKey549" />
        </Header>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <div
            style={{
              flex: 8,
              paddingTop: "10px",
              color: "var(--text-grey)",
            }}
          >
            <Icon name="backward" onClick={handleChangeStepDateRecord} />
            <DateTextBox
              style={{ width: "80%" }}
              value={formatDate(saveDate?.format(DATE_FORMAT))}
              maxDate={formatDate(moment().format(DATE_FORMAT))}
              onChange={handleChangeDateRecord}
            />
            <Icon
              name="forward"
              disabled={
                formatDate(saveDate.format(DATE_FORMAT)) ===
                formatDate(moment().format(DATE_FORMAT))
              }
              onClick={handleChangeStepDateRecord}
            />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>

        <Grid centered verticalAlign="bottom" style={{ padding: "30px 10px" }}>
          <Grid.Column width={4}>
            <Input
              fluid
              type="number"
              size="big"
              value={kilogram}
              onChange={(e, { value }: any) => {
                setKilogram(value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
              .
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Input
              fluid
              type="number"
              size="big"
              value={gram}
              onChange={(e, { value }: any) => {
                setGram(value);
              }}
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
              <FormattedMessage id="bplusClinicKey572" />
            </Header>
          </Grid.Column>
        </Grid>

        <Button
          content={intl.formatMessage({ id: "common.confirm" })}
          color="blue"
          onClick={handleOnConfirmCurrent}
        />
      </Modal.Content>
    </Modal>
  );
};

// WeightGainLineChart
type WeightGainLineChartProps = {
  dataSet?: any;
  gaWeeks?: number;
  isWeb?: boolean;
};

const WeightGainLineChart: React.FC<WeightGainLineChartProps> = (props) => {
  const intl = useIntl();
  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-linechart",
        beforeDraw: (chart: any) => {
          if (chart.canvas) {
            chart.canvas.style.padding = `0 5px`;
          }

          // if (typeof props.gaWeeks === "number" && props.isWeb) {
          //   console.log("ga week: ", props.gaWeeks);
          //   var textAt = props.gaWeeks;
          //   var ctxPlugin = chart.chart.ctx;
          //   var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
          //   var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

          //   let beginX = (textAt / xAxe.max) * xAxe.width + xAxe.left;
          //   ctxPlugin.lineWidth = 1;
          //   ctxPlugin.beginPath();
          //   ctxPlugin.moveTo(beginX - 5, yAxe.bottom + 30);
          //   ctxPlugin.lineTo(beginX , yAxe.bottom + 20);
          //   ctxPlugin.lineTo(beginX + 5 , yAxe.bottom + 30);
          //   ctxPlugin.closePath();
          //   ctxPlugin.strokeStyle = "#f87ed1";
          //   ctxPlugin.stroke();
          //   ctxPlugin.fillStyle = "#f87ed1";
          //   ctxPlugin.fill();
          //   ctxPlugin.font = 'bold 60px'
          //   ctxPlugin.fillText('Current weeks', beginX - 30, yAxe.bottom + 40);
          //   ctxPlugin.restore();
          // }

          if (typeof chart.config.options.lineOne !== "undefined") {
            var lineAt = chart.config.options.lineOne;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            // I'm not good at maths
            // So I couldn't find a way to make it work ...
            // ... without having the `min` property set to 0
            //   if (yAxe.min != 0) return;

            ctxPlugin.strokeStyle = "#4F4F4F";
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();
            lineAt = lineAt / xAxe.max;
            lineAt = lineAt * xAxe.width + xAxe.left;
            ctxPlugin.moveTo(lineAt, yAxe.bottom);
            ctxPlugin.lineTo(lineAt, yAxe.top);
            ctxPlugin.stroke();
          }
          if (typeof chart.config.options.lineTwo !== "undefined") {
            var lineAt = chart.config.options.lineTwo;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            // I'm not good at maths
            // So I couldn't find a way to make it work ...
            // ... without having the `min` property set to 0
            //   if (yAxe.min != 0) return;

            ctxPlugin.strokeStyle = "#4F4F4F";
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();
            lineAt = lineAt / xAxe.max;
            lineAt = lineAt * xAxe.width + xAxe.left;
            ctxPlugin.moveTo(lineAt, yAxe.bottom);
            ctxPlugin.lineTo(lineAt, yAxe.top);
            ctxPlugin.stroke();
          }

          if (typeof chart.config.options.standardWeightGain !== undefined) {
            var standardWeightHighLight =
              chart.config.options.standardWeightGain;
            var ctxPlugin = chart.chart.ctx;

            var xBegin = standardWeightHighLight?.xBegin;
            var xMid = standardWeightHighLight?.xMid;
            var xEnd = standardWeightHighLight?.xEnd;

            var yStartBegin = standardWeightHighLight?.yStartBegin;
            var yStartEnd = standardWeightHighLight?.yStartEnd;
            var yMidBegin = standardWeightHighLight?.yMidBegin;
            var yMidEnd = standardWeightHighLight?.yMidEnd;
            var yLastBegin = standardWeightHighLight?.yLastBegin;
            var yLastEnd = standardWeightHighLight?.yLastEnd;

            var xaxis = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yaxis = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            // var xBeginPixel = xaxis.getPixelForValue(xBegin)
            let xBeginPixel = (xBegin / xaxis.max) * xaxis.width + xaxis.left;
            // var xMidPixel = xaxis.getPixelForValue(xMid);
            let xMidPixel = (xMid / xaxis.max) * xaxis.width + xaxis.left;
            // var xEndPixel = xaxis.getPixelForValue(xEnd)
            let xEndPixel = (xEnd / xaxis.max) * xaxis.width + xaxis.left;

            var yStartBeginPixel = yaxis.getPixelForValue(yStartBegin);
            var yStartEndPixel = yaxis.getPixelForValue(yStartEnd);
            var yMidBeginPixel = yaxis.getPixelForValue(yMidBegin);
            var yMidEndPixel = yaxis.getPixelForValue(yMidEnd);
            var yLastBeginPixel = yaxis.getPixelForValue(yLastBegin);
            var yLastEndPixel = yaxis.getPixelForValue(yLastEnd);

            // The fill style of the rectangle we are about to fill.
            // console.log("Axis3:", xBeginPixel, xMidPixel, xEndPixel);

            // let region = new Path2D();
            ctxPlugin.strokeStyle = "#44A210";
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();
            ctxPlugin.moveTo(xBeginPixel, yStartBeginPixel);
            ctxPlugin.lineTo(xMidPixel, yMidBeginPixel);
            ctxPlugin.lineTo(xEndPixel, yLastBeginPixel);
            ctxPlugin.lineTo(xEndPixel, yLastEndPixel);
            ctxPlugin.lineTo(xMidPixel, yMidEndPixel);
            ctxPlugin.lineTo(xBeginPixel, yStartEndPixel);
            // region.closePath();

            // ctxPlugin.fill(ctxPlugin, 'evenodd');
            ctxPlugin.stroke();

            let region = new Path2D();
            region.moveTo(xBeginPixel, yStartBeginPixel);
            region.lineTo(xMidPixel, yMidBeginPixel);
            region.lineTo(xEndPixel, yLastBeginPixel);
            region.lineTo(xEndPixel, yLastEndPixel);
            region.lineTo(xMidPixel, yMidEndPixel);
            region.lineTo(xBeginPixel, yStartEndPixel);
            region.closePath();
            ctxPlugin.fillStyle = "rgba(220, 255, 216, 0.6)";
            ctxPlugin.fill(region, "evenodd");
          }

          // Get the object that determines the region to highlight.
          var xEarlyHighLight = chart.config.data.xEarlyHighLightRange;
          var xMiddleHighLight = chart.config.data.xMiddleHighLightRange;
          var xLastestHighLight = chart.config.data.xLastestHighLightRange;
          var standardWeightHighLight = chart.config.data.standardWeightGain;

          // If the object exists.
          if (xEarlyHighLight !== undefined) {
            var ctx = chart.chart.ctx;

            var xEarlyBegin = xEarlyHighLight.begin;
            var xEarlyEnd = xEarlyHighLight.end;

            var xaxis = chart.scales["x-axis-0"];
            var yaxis = chart.scales["y-axis-0"];

            var xEarlyBeginPixel = xaxis.getPixelForValue(xEarlyBegin);
            var xEarlyEndPixel = xaxis.getPixelForValue(xEarlyEnd);

            ctx.save();
            // The fill style of the rectangle we are about to fill.

            ctx.fillStyle = "rgba(233, 221, 252, 0.5)";
            ctx.fillRect(
              Math.min(xEarlyBeginPixel, xEarlyEndPixel),
              yaxis.top,
              Math.max(xEarlyBeginPixel, xEarlyEndPixel) -
                Math.min(xEarlyBeginPixel, xEarlyEndPixel),
              yaxis.bottom - yaxis.top
            );

            ctx.restore();
          }

          if (xMiddleHighLight !== undefined) {
            //   var ctx = chart.chart.ctx;

            var xMiddleBegin = xMiddleHighLight.begin;
            var xMiddleEnd = xMiddleHighLight.end;

            var xaxis = chart.scales["x-axis-0"];
            var yaxis = chart.scales["y-axis-0"];

            var xMiddleBeginPixel = xaxis.getPixelForValue(xMiddleBegin);
            var xMiddleEndPixel = xaxis.getPixelForValue(xMiddleEnd);

            ctx.save();
            // The fill style of the rectangle we are about to fill.

            ctx.fillStyle = "rgba(223, 245, 252, 0.5)";
            ctx.fillRect(
              Math.min(xMiddleBeginPixel, xMiddleEndPixel),
              yaxis.top,
              Math.max(xMiddleBeginPixel, xMiddleEndPixel) -
                Math.min(xMiddleBeginPixel, xMiddleEndPixel),
              yaxis.bottom - yaxis.top
            );

            ctx.restore();
          }

          if (xLastestHighLight !== undefined) {
            //   var ctx = chart.chart.ctx;

            var xLastestBegin = xLastestHighLight.begin;
            var xLastestEnd = xLastestHighLight.end;

            var xaxis = chart.scales["x-axis-0"];
            var yaxis = chart.scales["y-axis-0"];

            var xLastestBeginPixel = xaxis.getPixelForValue(xLastestBegin);
            var xLastestEndPixel = xaxis.getPixelForValue(xLastestEnd);

            ctx.save();
            // The fill style of the rectangle we are about to fill.

            ctx.fillStyle = "rgba(243, 246, 202, 0.5)";
            ctx.fillRect(
              Math.min(xLastestBeginPixel, xLastestEndPixel),
              yaxis.top,
              Math.max(xLastestBeginPixel, xLastestEndPixel) -
                Math.min(xLastestBeginPixel, xLastestEndPixel),
              yaxis.bottom - yaxis.top
            );

            ctx.restore();
          }
        },
        afterDraw: (chart: any) => {
          if (typeof props.gaWeeks === "number" && props.isWeb) {
            const gaWeeks = chart.config.options.gaWeeks;
            console.log("ga week: ", gaWeeks);
            var textAt = chart.config.options.gaWeeks;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

            let beginX = (textAt / xAxe.max) * xAxe.width + xAxe.left;
            ctxPlugin.lineWidth = 1;
            ctxPlugin.beginPath();
            ctxPlugin.moveTo(beginX - 5, yAxe.bottom + 30);
            ctxPlugin.lineTo(beginX, yAxe.bottom + 20);
            ctxPlugin.lineTo(beginX + 5, yAxe.bottom + 30);
            ctxPlugin.closePath();
            ctxPlugin.strokeStyle = "#f87ed1";
            ctxPlugin.stroke();
            ctxPlugin.fillStyle = "#f87ed1";
            ctxPlugin.fill();
            ctxPlugin.font = "bold 14px serif";
            ctxPlugin.fillText("Current weeks", beginX - 50, yAxe.bottom + 45);
            // ctxPlugin.restore();
          }
        },
      },
    ];
  }, []);

  console.log(props.gaWeeks);

  const chartData: any = useMemo(() => {
    let labels = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40,
    ];

    let dataSetWeek: any[] = [];
    let dataAddWeek: any[] = [];
    if (props.dataSet.length > 0) {
      dataSetWeek = [...props.dataSet].map((item) => {
        let gestational = item?.data?.ga_weeks;
        dataAddWeek.push({ ...item?.data, week: parseInt(gestational) });
        return parseInt(gestational);
      });
    }

    let dataGraph: (string | null)[] = [];
    let realData: any[] = [];
    for (const label of labels) {
      if (dataSetWeek.includes(label)) {
        let findData =
          dataAddWeek.filter((item) => item.week === label).slice(-1)?.[0] ||
          {};
        dataGraph.push(findData.gain);
        realData.push(findData);
      } else {
        dataGraph.push(null);
        realData.push(null);
      }
    }

    console.log(dataGraph);

    return {
      labels: labels,
      datasets: [
        {
          //   label: "First dataset",
          data: dataGraph,
          realData,
          fill: false,
          // backgroundColor: "rgba(75,192,192,0.2)",
          // borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgb(255,123,202,1)",
          borderColor: "rgb(255,123,202,1)",
          pointRadius: 5,
          type: "scatter",
        },
      ],
      xEarlyHighLightRange: {
        begin: 0,
        end: 14,
      },
      xMiddleHighLightRange: {
        begin: 14,
        end: 28,
      },
      xLastestHighLightRange: {
        begin: 28,
        end: 40,
      },
    };
  }, [props.dataSet]);

  const options: any = useMemo(() => {
    return {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem: any[], data: any) => {
            const tooltip = tooltipItem[0];
            const item =
              data.datasets[tooltip.datasetIndex].realData[tooltip.index];
            return [
              `${intl.formatMessage({ id: "bplusClinicKey551" })} = ${
                item.gain
              } ${intl.formatMessage({ id: "bplusClinicKey572" })}`,
              `${intl.formatMessage({ id: "bplusClinicKey138" })} ${
                item.weight
              } ${intl.formatMessage({ id: "bplusClinicKey572" })}`,
              " ",
              "Recommended gain",
              `${item.minGain.toFixed(2)} - ${item.maxGain.toFixed(
                2
              )} ${intl.formatMessage({ id: "bplusClinicKey572" })}`,
            ];
          },
          label: (tooltipItem: any, data: any) => {
            return null;
          },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              display: true,
              minRotation: 0,
              min: -6,
              max: 22,
              stepSize: 2,
              //   beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
            },
            ticks: {
              display: true,
              minRotation: 0,
              fontSize: 9,
            },
          },
        ],
      },
      lineOne: 14,
      lineTwo: 28,
      standardWeightGain: {
        xBegin: 0,
        xMid: 13,
        xEnd: 40,
        yStartBegin: 0,
        yStartEnd: 0,
        yMidBegin: 0.91,
        yMidEnd: 2.72,
        yLastBegin: 11.34,
        yLastEnd: 15.88,
      },
      gaWeeks: props.gaWeeks,
    };
  }, [props.dataSet, props.gaWeeks]);

  return (
    <div style={{ paddingTop: "20px" }}>
      <div style={{ ...styles.flexBetween, padding: "0 15px 0 20px" }}>
        <Header as="h5" style={{ color: "var(--text-dark-blue)", margin: 0 }}>
          <FormattedMessage id="bplusClinicKey535" />
        </Header>
        <div
          style={{
            padding: "0px",
            color: "var(--text-dark-grey)",
            display: "grid",
            rowGap: "5px",
          }}
        >
          <label>
            <Icon name="square" size="large" style={{ color: "#dcffd8" }} />
            <FormattedMessage id="bplusClinicKey414" />
          </label>
          <label>
            <Icon name="circle" size="large" style={{ color: "#ff7bca" }} />
            <FormattedMessage id="bplusClinicKey551" />
          </label>
        </div>
      </div>

      <div style={{ marginLeft: "-13px" }}>
        <Line
          data={chartData}
          options={options}
          height={200}
          plugins={plugins}
        />
      </div>

      <Header
        as="h5"
        style={{
          textAlign: "center",
          color: "var(--text-dark-blue)",
          margin: "0px",
        }}
      >
        <FormattedMessage id="bplusClinicKey212" />
      </Header>
    </div>
  );
};

// MomWeightGain
type MomWeightGainProps = {
  // function
  onEvent?: any;
  onSetBackToApp?: () => any;
  // Config
  hideWeightStatus?: boolean;
  hidePatientPanel?: boolean;
  hideFilter?: boolean;
  hideAppbar?: boolean;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  weightFilterStartDate?: moment.Moment;
  weightFilterEndDate?: moment.Moment;
  obstreticRecord?: any;
  weightPrePregnancy?: any;
  weightGainList?: any;
  weightGainDetail?: any;
  // config
  useWebMomTable?: boolean;
  isWeb?: boolean;
};

const WeightGainMonInitial: MomWeightGainProps = {
  // function
  onEvent: () => {},
  // Config
  hideWeightStatus: false,
  hidePatientPanel: false,
  hideFilter: false,
  hideAppbar: false,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  weightFilterStartDate: moment().add("-1", "weeks"),
  weightFilterEndDate: moment(),
  obstreticRecord: {},
  weightPrePregnancy: {},
  weightGainList: {},
  weightGainDetail: {},
  // config
  useWebMomTable: false,
  isWeb: false,
};

const MomWeightGain: React.FC<MomWeightGainProps> = (props) => {
  const intl = useIntl();
  let history = useHistory();

  const [selectedDuration, setSelectedDuration] = useState<
    "day" | "week" | "month" | "year"
  >("year");
  const [lastItem, setLastItem] = useState<any>({});
  const [openModPregnancy, setOpenModPregnancy] = useState(false);
  const [openModCurrent, setOpenModCurrent] = useState(false);
  const [modConfirm, setModConfirm] = useState({
    open: false,
    onApprove: () => {},
    onDeny: () => {
      setModConfirm({ ...modConfirm, open: false });
    },
    content: null as ReactChild | null,
  });

  const columnsWeight = [
    {
      Header: `${intl.formatMessage({ id: "common.date" })}`,
      accessor: "date",
      minWidth: 40,
      Cell: (row: any) => {
        return formatDateByLanguage(
          moment(
            row?.original?.measure_date,
            `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
          ).format(DATE_FORMAT)
        );
      },
    },
    {
      Header: `${intl.formatMessage({ id: "weight.weight_unit" })}`,
      accessor: "weight",
      minWidth: 30,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return row?.original?.data?.weight;
      },
    },
    {
      Header: `${intl.formatMessage({ id: "bplusClinicKey783" })}`,
      accessor: "gain",
      minWidth: 30,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return row?.original?.data?.gain;
      },
    },
    {
      Header: `${intl.formatMessage({ id: "bplusClinicKey212" })}`,
      accessor: "ga",
      minWidth: 40,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return `${row?.original?.data?.ga_weeks} ${intl.formatMessage({
          id: "bplusClinicKey763",
        })} ${row?.original?.data?.ga_days} ${intl.formatMessage({
          id: "profile.days",
        })}`;
      },
    },
    {
      accessor: "_remove",
      minWidth: 10,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return (
          <Icon
            name="minus circle"
            color="red"
            style={{ fontSize: "16px" }}
            onClick={() => {
              setModConfirm({
                ...modConfirm,
                open: true,
                onApprove: () => {
                  props.onEvent({
                    message: "handleRemoveWeightGain",
                    params: { ...row?.original },
                  });
                  setModConfirm({ ...modConfirm, open: false });
                },
                content: (
                  <div style={{ textAlign: "center" }}>
                    {`Do you need to remove weight gain data on ${moment(
                      row?.original?.measure_date,
                      `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                    ).format(DATE_FORMAT)}?`}
                  </div>
                ),
              });
            }}
          />
        );
      },
    },
  ];

  const columnsWebWeight = [
    {
      Header: `${intl.formatMessage({ id: "common.date" })}`,
      accessor: "date",
      minWidth: 40,
      Cell: (row: any) => {
        return formatDateByLanguage(
          moment(
            row?.original?.measure_date,
            `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
          ).format(DATE_FORMAT)
        );
      },
    },
    {
      Header: `${intl.formatMessage({ id: "bplusClinicKey533" })}`,
      accessor: "weight",
      minWidth: 30,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return row?.original?.data?.weight;
      },
    },
    {
      Header: `${intl.formatMessage({ id: "bplusClinicKey783" })}`,
      accessor: "gain",
      minWidth: 30,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return row?.original?.data?.gain;
      },
    },
    {
      Header: `${intl.formatMessage({ id: "bplusClinicKey212" })}`,
      accessor: "ga",
      minWidth: 40,
      style: { textAlign: "center" },
      Cell: (row: any) => {
        return `${row?.original?.data?.ga_weeks} ${intl.formatMessage({
          id: "bplusClinicKey763",
        })} ${row?.original?.data?.ga_days} ${intl.formatMessage({
          id: "profile.days",
        })}`;
      },
    },
  ];

  useEffect(() => {
    props.onEvent({ message: "handleGetObstreticRecord" });
    props.onEvent({ message: "handleGetPrePregnancyWeight" });
  }, [props.selectedPatient]);

  useEffect(() => {
    props.onEvent({ message: "handleGetPregnancyWeight" });
  }, [
    props.selectedPatient,
    props.weightFilterStartDate,
    props.weightFilterEndDate,
  ]);

  useEffect(() => {
    if (props.weightGainList?.total > 0) {
      const result: any = {
        ...props.weightGainList?.items[props.weightGainList.total - 1],
      };
      setLastItem(result);
    } else {
      setLastItem({});
    }
  }, [props.weightGainList]);

  const durationOptions = useMemo(
    () => [
      {
        key: 1,
        value: "day",
        text: `${intl.formatMessage({ id: "bplusClinicKey764" })}`,
      },
      {
        key: 2,
        value: "week",
        text: `${intl.formatMessage({ id: "bplusClinicKey763" })}`,
      },
      {
        key: 3,
        value: "month",
        text: `${intl.formatMessage({ id: "bplusClinicKey796" })}`,
      },
      {
        key: 4,
        value: "year",
        text: `${intl.formatMessage({ id: "bplusClinicKey590" })}`,
      },
    ],
    []
  );

  const weightGain = useMemo(() => {
    const deliveryResult = (date: moment.Moment) =>
      calculateDelivery(
        props.obstreticRecord?.data?.by_ultrasound,
        date,
        moment(props.obstreticRecord?.data?.lmp_date, BACKEND_DATE_FORMAT),
        moment(props.obstreticRecord?.data?.edc_date, BACKEND_DATE_FORMAT)
      );

    return (props.weightGainList?.items || []).map((item: any) => {
      const delivery = deliveryResult(moment(item.measure_date));
      return {
        ...item,
        data: {
          ...item.data,
          ga_days: delivery?.ga_days,
          ga_weeks: delivery?.ga_weeks,
        },
      };
    });
  }, [props.weightGainList?.items, props.obstreticRecord]);

  const deliveryGaWeeks = useMemo(() => {
    const deliveryResult = calculateDelivery(
      props.obstreticRecord?.data?.by_ultrasound,
      moment(),
      moment(props.obstreticRecord?.data?.lmp_date, BACKEND_DATE_FORMAT),
      moment(props.obstreticRecord?.data?.edc_date, BACKEND_DATE_FORMAT)
    );

    return deliveryResult.ga_weeks;
  }, [props.obstreticRecord]);

  // change date
  const handleChangeDuration = (_e: any, { value }: any) => {
    setSelectedDuration(value);
    const today = moment();

    // const startDate = {
    //   day: today,
    //   week: today.clone().startOf("week").add(1, "day"),
    //   month: today.clone().startOf("month"),
    //   year: today.clone().startOf("year"),
    // }[value as "day" | "week" | "month" | "year"];
    const startDate = {
      day: today,
      week: today.clone().add(-1, "weeks").add(1, "day"),
      month: today.clone().add(-1, "months"),
      year: today.clone().add(-1, "years"),
    }[value as "day" | "week" | "month" | "year"];

    props.onEvent({
      message: "handleChangeFilterDate",
      params: {
        startDate,
        endDate: today,
      },
    });
  };

  const getToDate = (date: moment.Moment) => {
    return moment()
      .format(BACKEND_DATE_FORMAT)
      .localeCompare(date.format(BACKEND_DATE_FORMAT)) === -1
      ? moment()
      : date;
  };

  const handleChangeDateRange = (event: any, data: any) => {
    // let fromdate: any = null;
    // let todate: any = null;
    // if (data.name === "backward") {
    //   const targetDate = moment(props.weightFilterStartDate, DATE_FORMAT);
    //   const to = targetDate.clone().add(-1, "day");

    //   switch (selectedDuration) {
    //     case "day":
    //       todate = to;
    //       fromdate = to;
    //       break;
    //     case "week":
    //       todate = to;
    //       fromdate = targetDate.clone().add(-7, "day");
    //       break;
    //     case "month":
    //     case "year":
    //       todate = to.clone().endOf(selectedDuration);
    //       fromdate = to.clone().startOf(selectedDuration);
    //       break;
    //     default:
    //       break;
    //   }
    // } else if (data.name === "forward") {
    //   const targetDate: any = moment(props.weightFilterEndDate, "weeks");
    //   fromdate = targetDate.clone().add(1, "day");

    //   switch (selectedDuration) {
    //     case "day":
    //       todate = targetDate.clone().add(1, selectedDuration);
    //       break;
    //     case "week":
    //       todate = getToDate(targetDate.clone().add(7, "day"));
    //       break;
    //     case "month":
    //     case "year":
    //       const to = targetDate.clone().add(1, "day").endOf(selectedDuration);

    //       todate = getToDate(to);
    //       fromdate = to.clone().startOf(selectedDuration);
    //       break;
    //     default:
    //       break;
    //   }
    // }
    props.onEvent({
      message: "handleChangeFilterDate",
      params: { startDate: data.fromDate, endDate: data.toDate },
    });
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "12px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleLeftIconClick = () => {
    props.onSetBackToApp?.();
  };

  const handleChangeDate = (date: string) => {
    let startDate: any = null;
    let endDate: any = null;
    const today = moment(beToAd(date));

    // switch (selectedDuration) {
    //   case "day":
    //     startDate = today;
    //     endDate = today;
    //     break;
    //   case "week":
    //     startDate = today.clone().startOf("week").add(1, "day");
    //     endDate = getToDate(today.clone().endOf("week").add(1, "day"));
    //     break;
    //   case "month":
    //   case "year":
    //     startDate = today.clone().startOf(selectedDuration);
    //     endDate = getToDate(today.clone().endOf(selectedDuration));
    //     break;
    //   default:
    //     break;
    // }
    switch (selectedDuration) {
      case "day":
        startDate = today;
        endDate = today;
        break;
      case "week":
        startDate = today.clone().add(-6, "days");
        endDate = today.clone();
        break;
      case "month":
      case "year":
        startDate = today.clone().add(-1, `${selectedDuration}s`);
        endDate = today.clone();
        break;
      default:
        break;
    }

    props.onEvent({
      message: "handleChangeFilterDate",
      params: {
        startDate,
        endDate,
      },
    });
  };

  return (
    <div style={styles.flexColumn}>
      {!props.hideAppbar && (
        <AppBar position="fixed" color="default">
          <MobHead
            // title={`${props.selectedPatient?.first_name} ${props.selectedPatient?.last_name}`}
            title={intl.formatMessage({ id: "bplusClinicKey766" })}
            leftIcon="arrow left"
            leftIconClick={handleLeftIconClick}
          />
        </AppBar>
      )}

      <Dimmer
        active={props.loadingStatus}
        inverted
        style={{ position: "fixed" }}
      >
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      {!props.hidePatientPanel && (
        <PatientPanel
          patientInfo={props.selectedPatient}
          useBackgroundGradient
          // titleFooter="Weight gain Chart"
        />
      )}

      <WeightGainLineChart
        gaWeeks={deliveryGaWeeks || 0}
        dataSet={weightGain}
        isWeb={props.isWeb}
      />

      {!props.hideWeightStatus && (
        <>
          <div
            style={{
              ...styles.flexRow,
              justifyContent: "space-evenly",
              padding: "10px",
            }}
          >
            <div style={styles.flexWeightShow}>
              <div style={styles.subHeader}>
                <FormattedMessage id="bplusClinicKey390" />
              </div>
              <div>
                <span style={styles.showValue}>
                  {props.weightPrePregnancy?.data?.weight}
                </span>
                <span>
                  <FormattedMessage id="bplusClinicKey572" />
                </span>
              </div>
              <div style={styles.showDate}>
                {formatDateByLanguage(
                  moment(
                    props.weightPrePregnancy?.measure_date,
                    `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                  ).format(DATE_FORMAT)
                )}
              </div>
            </div>
            <div style={styles.flexWeightShow}>
              <div style={styles.subHeader}>
                <FormattedMessage id="bplusClinicKey138" />
              </div>
              <div>
                <span style={styles.showValue}>
                  {lastItem?.data?.weight || "-"}
                </span>
                <span>
                  <FormattedMessage id="bplusClinicKey572" />
                </span>
              </div>
              <div style={styles.showDate}>
                {lastItem?.measure_date
                  ? formatDateByLanguage(
                      moment(
                        lastItem?.measure_date,
                        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                      ).format(DATE_FORMAT)
                    )
                  : ""}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {lastItem?.data?.pass ? (
                <Icon
                  name="thumbs up"
                  color={lastItem?.data?.color}
                  size="big"
                />
              ) : (
                <Icon
                  name="thumbs down"
                  color={lastItem?.data?.color}
                  size="big"
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "15px",
            }}
          >
            <Button
              color="blue"
              content={intl.formatMessage({ id: "bplusClinicKey765" })}
              size="big"
              onClick={() => {
                if (props.weightPrePregnancy?.data?.weight) {
                  setOpenModCurrent(true);
                } else {
                  setOpenModPregnancy(true);
                }
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Divider />
          </div>
        </>
      )}
      {!props.hideFilter && (
        <HeaderChart
          startDate={props.weightFilterStartDate}
          endDate={props.weightFilterEndDate}
          durationOptions={durationOptions}
          selectedDuration={selectedDuration}
          onChangeDuration={handleChangeDuration}
          onChangeDateRange={handleChangeDateRange}
          labelOptions={intl.formatMessage({ id: "bplusClinicKey453" })}
          dateRangeStyle={{ paddingBottom: "5px" }}
          formatType={selectedDuration === "week" ? "range" : selectedDuration}
          allowPicker={true}
          onChangeDateRecord={handleChangeDate}
          widthDatePicker="225px"
        />
      )}

      {!props.hideFilter ? (
        <div style={{ padding: "0 15px 15px" }}>
          <ReactTable
            className={`weight-table-custom${
              props.useWebMomTable ? " web-mom" : ""
            }`}
            style={{ width: "100%", height: "250px" }}
            columns={columnsWeight}
            data={weightGain}
            showPagination={false}
            defaultPageSize={1}
            pageSize={props.weightGainList?.items?.length || 1}
            showPageSizeOptions={false}
            noDataText={<div />}
            resizable={false}
            getTheadThProps={handleGetTheadThProps}
          />
        </div>
      ) : (
        <div style={{ padding: "0 15px 15px" }}>
          <ReactTable
            className={`weight-table-custom${
              props.useWebMomTable ? " web-mom" : ""
            }`}
            style={{ width: "100%", height: "250px" }}
            columns={columnsWebWeight}
            data={weightGain}
            showPagination={false}
            defaultPageSize={1}
            pageSize={props.weightGainList?.items?.length || 1}
            showPageSizeOptions={false}
            noDataText={<div />}
            resizable={false}
            getTheadThProps={handleGetTheadThProps}
          />
        </div>
      )}

      <ModPregnancy
        open={openModPregnancy}
        onEvent={props.onEvent}
        onClose={() => {
          setOpenModPregnancy(false);
        }}
      />

      <ModCurrent
        open={openModCurrent}
        onEvent={props.onEvent}
        onClose={() => {
          setOpenModCurrent(false);
        }}
        obstreticRecord={props.obstreticRecord}
        weightPrePregnancy={props.weightPrePregnancy}
        weightGainList={props.weightGainList}
      />

      <ModConfirm
        openModal={modConfirm.open}
        onApprove={modConfirm.onApprove}
        onDeny={modConfirm.onDeny}
        onCloseWithDimmeClick={modConfirm.onDeny}
        content={modConfirm.content}
        titleName="Information"
        approveButtonText="Yes"
        denyButtonText="No"
      />
    </div>
  );
};

MomWeightGain.defaultProps = WeightGainMonInitial;

export default React.memo(MomWeightGain);
