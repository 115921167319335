import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

// Interface
import {
  GROUP,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  DATA_TYPE,
  commonDeletePatientData,
  commonUpdatePatientData,
  commonCreatePatientData,
  commonGetChildVaccine,
  formatVaccineData,
  State as MainState,
  BE_DATE_FORMAT,
} from "../MobClinicInterface";
import { formatVaccineDMData, sortVaccineByDate } from "./DMVaccineInterface";

type ChildVaccineHistoryType = {
  abbreviation: string;
  name: string;
  items: {
    abbreviation?: string;
    name?: string;
    date?: string;
    by_patient?: boolean;
  }[];
};

type ChildVaccineType = {
  age_range: number;
  name: string;
  items: {
    vaccine?: number;
    name?: string;
    abbreviation?: string;
    date?: string;
    data?: any;
    id?: number;
    measure_date?: string;
    proxy_patient?: number;
  }[];
};

export type State = {
  childVaccineList?: ChildVaccineType[];
  childVaccineOtherList?: any[];
  selectedChildVaccine?: any;
  childVaccineHistory?: ChildVaccineHistoryType[];
  childVaccineOtherHistory?: ChildVaccineHistoryType[];
  // ageRangeList?: any[];
  // vaccineSeriesList?: any[];
  // vaccinePatientDataList?: any[];
  loadingMobChildVaccine?: boolean;
};

export const StateInitial: State = {
  childVaccineList: [],
  selectedChildVaccine: null,
  childVaccineOtherList: [],
  // vaccineSeriesList: [],
  // vaccinePatientDataList: [],
  loadingMobChildVaccine: false,
};

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "selectedPatient"
>;

export type Event =
  // GET Mobile
  // | { message: "getAgeRangeList"; params: {} }
  // | { message: "getVaccineSeriesList"; params: { patient_type: string } }
  // | { message: "getPatientDataDetail"; params: { proxy_patient?: number } }

  // GET
  | { message: "GetListChildVaccine"; params: {} }
  | { message: "GetListChildVaccineHistory"; params: {} }
  // SAVE
  | {
      message: "HandleCheckedChildVaccineDetail";
      params: {
        data: any;
        id: number;
        vaccine: number;
        action_type?: "add" | "";
        detail: any;
        index?: number;
        page?: "other_vaccine";
      };
    }
  // Handle
  | { message: "HandleSelectedChildVaccine"; params: { data: any } };

type Handler = WasmHandler<State & Picked, Event>;

// export const getAgeRangeList: Handler = async (controller, params) => {
// 	controller.setState({ loadingMobChildVaccine: true });
// 	console.log(" getAgeRangeList");
// 	const [res, error] = await AgeRangeList.list({
// 		apiToken: Cookies.get("apiToken"),
// 	});
// 	console.log("!!!!! getAgeRangeList rest", res);
// 	if (res) {
// 		controller.setState({
// 			loadingMobChildVaccine: false,
// 			ageRangeList: res.items ? res.items : [],
// 		});
// 	} else if (error) {
// 		controller.setState({ loadingMobChildVaccine: false, ageRangeList: [] });
// 	}
// };

// export const getVaccineSeriesList: Handler = async (controller, params) => {
// 	controller.setState({ loadingMobChildVaccine: true });
// 	console.log(" getVaccineSeriesList");
// 	const [res, error] = await VaccineSeriesList.list({
// 		apiToken: Cookies.get("apiToken"),
// 		params: {
// 			patient_type: params.patient_type,
// 		},
// 	});
// 	console.log("!!!!! getVaccineSeriesList rest", res);
// 	if (res) {
// 		controller.setState({
// 			loadingMobChildVaccine: false,
// 			vaccineSeriesList: res.items ? res.items : [],
// 		});
// 	} else if (error) {
// 		controller.setState({
// 			loadingMobChildVaccine: false,
// 			vaccineSeriesList: [],
// 		});
// 	}
// };

// export const getPatientDataDetail: Handler = async (controller, params) => {
// 	controller.setState({ loadingMobChildVaccine: true });
// 	console.log(" getPatientDataDetail");

// 	let overwrite_proxy_patient_params = { proxy_patient: params.proxy_patient };

// 	const [error, response] = await commonListPatientDataList(
// 		controller as any,
// 		overwrite_proxy_patient_params
// 	);

// 	console.log("!!!!! getPatientDataDetail response: ", response);
// 	if (response) {
// 		controller.setState({
// 			loadingMobChildVaccine: false,
// 			vaccinePatientDataList: response.items ? response.items : [],
// 		});
// 	} else if (error) {
// 		controller.setState({
// 			loadingMobChildVaccine: false,
// 			vaccinePatientDataList: [],
// 		});
// 	}
// }

/* ------------------------- GET ------------------------ */
export const GetListChildVaccine: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [essentialList, otherList] = await commonGetChildVaccine(
    controller as any
  );

  controller.setState({
    childVaccineList: essentialList,
    loadingStatus: false,
    childVaccineOtherList: otherList,
  });
};

export const GetListChildVaccineHistory: Handler = (controller, params) => {
  const { childVaccineList, childVaccineOtherList, selectedPatient } =
    controller.getState();

  const mapVaccineHistory = (acc: any) => {
    return {
      abbreviation: acc.abbreviation ?? acc.code,
      date: acc.data?.OEORI_Date
        ? moment(acc.data?.OEORI_Date).format(BE_DATE_FORMAT)
        : "",
      name: getAgeAtVaccination(
        acc.data?.OEORI_Date,
        selectedPatient.birthdate
      ),
      by_patient: acc.data?.By_Patient,
      lot_no: acc.data?.OEORI_PrescNo,
    };
  };

  const arrayFlat = (childVaccineList || [])
    .map((item) =>
      item.items.map((acc) => ({ ...acc, age_range_name: item.name }))
    )
    .flat();
  const key = "vaccine";
  // @ts-ignore
  const arrayUniqueByKey = [
    // @ts-ignore
    ...new Map(arrayFlat.map((item) => [item[key], item])).values(),
  ]
    .map((item) => ({
      name: item.description,
      abbreviation: item.code,
      items: arrayFlat
        .filter((acc) => acc.vaccine === item.vaccine)
        .map(mapVaccineHistory)
        .filter((acc) => acc.date),
    }))
    .filter((item) => item.items.length > 0);

  const other = (childVaccineOtherList || [])
    .map((item) => ({
      abbreviation: item.abbreviation,
      name: item.description,
      items: item.items.map(mapVaccineHistory).filter((acc: any) => acc.date),
    }))
    .filter((item) => item.items.length > 0);

  controller.setState({
    childVaccineHistory: arrayUniqueByKey,
    childVaccineOtherHistory: other,
  });
};

/* ------------------------ SAVE ------------------------ */
export const HandleCheckedChildVaccineDetail: Handler = async (
  controller,
  params: Extract<
    Event,
    { message: "HandleCheckedChildVaccineDetail" }
  >["params"]
) => {
  const { childVaccineOtherList } = controller.getState();
  const { detail: vaccineDetail } = params;

  controller.setState({ loadingStatus: true });

  let vaccineDetailIdx = vaccineDetail.items.findIndex(
    (item: any) => params.id === item.id
  );
  let detail =
    vaccineDetail.items.find((item: any) => params.id === item.id) || {};

  const data = {
    ...detail.data,
    ...params.data,
  };

  let saveData: any = {
    id: params.id,
    data,
    proxy_patient: detail.proxy_patient,
    measure_date: (data.OEORI_Date ? moment(data.OEORI_Date) : moment())
      .locale("en")
      .format(`${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`),
  };

  if (!saveData.id) {
    saveData = {
      ...saveData,
      group: GROUP.VACCINE_CHILD,
      data_type: `${DATA_TYPE.VACCINE}_${params.vaccine}`,
    };
    saveData.data.By_Patient = true;
    delete saveData.id;
    delete saveData.proxy_patient;
  }

  let [error, response]: [any, any] = [null, null];

  // หากเป็นวัคซีนอื่นๆ
  if (
    vaccineDetail.qty === 1 &&
    !saveData.data.OEORI_Date &&
    vaccineDetail.vaccine_type === "OTHERS"
  ) {
    [error, response] = await commonDeletePatientData(controller as any, {
      id: saveData.id,
    });
  } else if (saveData.id) {
    [error, response] = await commonUpdatePatientData(
      controller as any,
      saveData
    );
  } else {
    [error, response] = await commonCreatePatientData(
      controller as any,
      saveData
    );
  }

  if (error) {
    controller.setState({ loadingStatus: false });
    return;
  }

  // หากเป็นวัคซีนอื่นๆ
  if (vaccineDetail?.vaccine_type === "OTHERS") {
    if (params.action_type === "add") {
      vaccineDetail.items.push(formatVaccineDMData(response, ""));
    } else {
      // let idx = vaccineDetail.items.findIndex( (item:any) => params.id === item.id)
      vaccineDetail.items[vaccineDetailIdx] = formatVaccineDMData(response, "");
    }

    vaccineDetail.items = vaccineDetail.items
      .sort(sortVaccineByDate)
      .map((item: any, index: number) => ({
        ...item,
        code: `${vaccineDetail.abbreviation}${
          vaccineDetail.items.length > 1 ? index + 1 : ""
        }`,
      }));
  } else {
    vaccineDetail.items[vaccineDetailIdx] = formatVaccineData(
      {
        ...vaccineDetail,
        ...detail,
        ...response,
        vaccine_id: response.id,
        id: detail.vaccine,
      },
      detail.abbreviation
    );
  }

  controller.setState({ loadingStatus: false });
  if (params.page === "other_vaccine") {
    controller.setState({
      childVaccineOtherList: (childVaccineOtherList || []).map((item) =>
        item.id === vaccineDetail.id ? vaccineDetail : item
      ),
    });
  } else {
    controller.setState({ selectedChildVaccine: vaccineDetail });
  }
};

/* ---------------------- // HANDLE --------------------- */
export const HandleSelectedChildVaccine: Handler = (controller, params) => {
  console.log("HandleSelectedChildVaccine   params", params);
  controller.setState({ selectedChildVaccine: params.data });
};

// Utils
export const getAgeAtVaccination = (injectDate: string, birthdate: string) => {
  if (!birthdate || !injectDate) {
    return "-";
  }
  const a = moment(injectDate);
  const b = moment(birthdate);

  const years = a.diff(b, "year");
  b.add(years, "years");

  const months = a.diff(b, "months");
  b.add(months, "months");

  const days = a.diff(b, "days");
  const date = {
    year: years ? `${years} year ` : "",
    month: months ? `${months} month ` : "",
    day: days ? `${days} day ` : "",
  };

  let fullDate = `${date.year}${date.month}${date.day}`;

  return fullDate;
};
