import React, { CSSProperties, useEffect } from "react";
import moment from "moment";
import { Button, Dimmer, Loader } from "semantic-ui-react";

import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

// UI
import SnackMessage from "../../clinic/SnackMessage";
import ChildDevelopmentHistoryTab from "../../clinic/ChildDevelopmentHistoryTab";

const styles = {
  DivHeader: {
    backgroundColor: "var(--theme-bg-color-dm, #DFF5FC)",
    padding: "12px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as CSSProperties,
  HeaderColor: {
    fontSize: "27px",
    color: "#4F4F4F",
    fontWeight: "bold",
    paddingLeft: "15px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  } as CSSProperties,
  TextLastUpdate: {
    color: "#656565",
    fontSize: "12px",
    paddingRight: "10px",
  } as CSSProperties,
  TextDate: {
    color: "#000000",
    fontSize: "12px",
  } as CSSProperties,
  TextButtonSave: {
    background: "#0072BB",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
  } as CSSProperties,
  TextBarDetails: {
    padding: "2px",
    width: "150px",
  } as CSSProperties,
  DivBarDetails: {
    display: "flex",
    marginRight: "10px",
  } as CSSProperties,
  DivBlue: {
    width: "23px",
    height: "23px",
    marginRight: "5px",
    background: "#ABD9FB",
  } as CSSProperties,
  DivYellow: {
    width: "23px",
    height: "23px",
    marginRight: "5px",
    background: "#F2C94C",
  } as CSSProperties,
  DivPink: {
    width: "23px",
    height: "23px",
    marginRight: "5px",
    background: "#FCAFD6",
  } as CSSProperties,
  DivGreen: {
    width: "23px",
    height: "23px",
    marginRight: "5px",
    background: "#219653",
  } as CSSProperties,
  DivDarkBlue: {
    width: "23px",
    height: "23px",
    marginRight: "5px",
    background: "#0072BC",
  } as CSSProperties,
  DivDevelopmentStatusHeader: {
    display: "flex",
    justifyContent: "space-between",
    background: "#DFF5FC",
    color: "#0072BC",
    fontSize: "18px",
    padding: "15px",
  } as CSSProperties,
  DivDevelopmentStatus: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0072BC",
    fontSize: "18px",
    paddingTop: "10px",
  } as CSSProperties,
  TextAgeRange: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#0072BC",
    paddingLeft: "20px",
  } as CSSProperties,
  TextTotal: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#0072BC",
    paddingRight: "20px",
    textAlign: "right",
  } as CSSProperties,
  ButtonChack: {
    border: "1px solid #0072BC",
    background: "#FFFFFF",
    padding: "5px 10px",
    color: "#0072BC",
    fontSize: "12px",
  } as CSSProperties,
};

type WebChildDevelopmentProps = {
  // function
  onEvent: any;
  // data
  errorMessage: any;
  successMessage: any;
  selectedPatient?: any;
  developmentHistory?: any[];
  developmentLastEdited?: moment.Moment | null;
  loadingStatus?: boolean;
  developmentAgeRange?: any[];
};

const WebChildDevelopmentInitial: WebChildDevelopmentProps = {
  // function
  onEvent: () => null,
  // data
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  developmentHistory: [],
  developmentLastEdited: null,
};

const WebChildDevelopment: React.FC<WebChildDevelopmentProps> = (props) => {
  useEffect(() => {
    props.onEvent({ message: "HandleGetDevelopmenAgeRange" });
  }, []);

  const handleSetViewHeight = (height: number) => {};

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div className="box-header">
        <div className="div-header" style={{ marginTop: 0 }}>
          <img
            alt="development.icon"
            height="36px"
            style={{ color: "var(--theme-icon-color-dm)" }}
            src="/images/child/development.png"
          />
          <span style={styles.HeaderColor}>
            <FormattedMessage id="bplusClinicKey159" />
          </span>
        </div>
        <div style={{ position: "absolute", right: "30px", top: "30%" }}>
          <Button style={styles.TextButtonSave}>
            <FormattedMessage id="bplusClinicKey431" />
          </Button>
        </div>
      </div>

      <div className="sub-layout">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ paddingTop: "10px", display: "flex" }}>
            <div style={styles.TextLastUpdate}>
              <FormattedMessage id="bplusClinicKey288" />
            </div>
            <div style={styles.TextDate}>
              {props.developmentLastEdited?.format("DD MMM YYYY, HH:mm")}
            </div>
          </div>
        </div>

        <ChildDevelopmentHistoryTab
          // function
          onEvent={props.onEvent}
          onSetViewHeight={handleSetViewHeight}
          // data
          selectedPatient={props.selectedPatient}
          developmentHistory={props.developmentHistory}
          developmentAgeRange={props.developmentAgeRange}
          isMyTab={true}
          isMobile={false}
          height={100}
          loadingStatus={props.loadingStatus}
          isLoader={true}
        />
      </div>
    </div>
  );
};

WebChildDevelopment.defaultProps = WebChildDevelopmentInitial;

export default React.memo(WebChildDevelopment);
