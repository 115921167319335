import axios from "axios";
import WasmController from "react-lib/frameworks/WasmController";

export type PythonState = {
  pythonLoaded?: boolean,
  pythonResults?: any,
  pythonError?: any,
  PythonPreload?: any
}

export type PythonEvent = 
  { message: "LoadPython", params: {} } |
  { message: "CallPython", params: 
    { id: string, data?: any } }

export type PythonController = WasmController<PythonState, PythonEvent, {}>

type Handler = (controller: PythonController, params?: any) => any

export const LoadPython: Handler = async (controller) => {

  console.time("Python");
  if (typeof(Worker) !== "undefined") {
    if (typeof(controller.python) == "undefined") {
      controller.python = new Worker("/js/pythonworker0.20.0mod.js");
    }
  }
  
  controller.python.onerror = (e: any) => {
    console.log(`Error in pyodideWorker at ${e.filename}, Line: ${e.lineno}, ${e.message}`);
    controller.setState({pythonError: e});
  };
  
  controller.python.onmessage = (e: any) => {
    const {results, error, id} = e.data;
    if (results) {
      console.log(`id: ${id}\nreturn: ${JSON.stringify(results)}: `);
      if (id) {
        controller.setState({ [id]: JSON.parse(results), pythonLoaded: true });
      } else {
        controller.setState({ pythonResults: JSON.parse(results), pythonLoaded: true });
      }
      if (id === "python_preload") {
        console.log("python pre-loaded successfully");
        console.timeEnd("Python");
      }
    } else if (error) {
      console.log(`id: ${id}\nerror: ${JSON.stringify(error)}`);
      if (id) {
        controller.setState({ [id + "Error"]: error });
      } else {
        controller.setState({ pythonError: error });
      }
    }
  };

  CallPython(controller, {id: "python_preload", data: {a: 1}});
};

export const CallPython: Handler = async (controller, params) => {
  if (controller.python) {
    const python_script = (await axios.get(`/python/${params.id}.py`)).data;
    controller.python.postMessage({ 
      id: params.id,
      python: python_script,
      ...params.data
    });
  }
};
