import React, { ReactElement, useMemo } from "react";
// MUI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { TransitionProps } from "@mui/material/transitions";

// @ts-ignore
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";

// Icon
import CloseIcon from "@mui/icons-material/Close";

// Types
type DialogReadMoreProps = {
  className?: string;
  open: boolean;
  title?: string;
  children: ReactElement;
  // config
  centerTitle?: boolean;
  marginTop?: number;
  centered?: boolean;
  hideCloseIcon?: boolean;
  dialogStyle?: any;
  // callback
  onChange: (state: boolean) => any;
};

// Styles
const COLORS = {
  grey: "#2c2c2c",
  light_grey: "rgba(121, 120, 120, 1)",
  divider: "rgba(196, 196, 196, 0.5)",
};

const useStyles = makeStyles(() => ({
  card_description: {
    padding: "25px 20px 0",
    width: "100%",
    height: "100%",
    color: COLORS.light_grey,
    "& .title": {
      fontWeight: "bold",
      fontSize: "1.125rem",
      margin: "1rem 0",
      color: COLORS.grey,
      "&.center": {
        textAlign: "center",
        borderBottom: `1px solid ${COLORS.divider}`,
        margin: "-5px -20px 0",
        paddingBottom: "1.25rem",
      },
    },
  },
  dialog: { "& .MuiDialog-paper": { width: 0, padding: 0 } },
  centered: { "& .MuiDialog-paper": { padding: 0 } },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogReadMore = (props: DialogReadMoreProps) => {
  const classes = useStyles();

  const element = useMemo(
    () => (
      <CardDescription
        onChange={props.onChange}
        centerTitle={props.centerTitle}
        title={props.title}
        hideCloseIcon={props.hideCloseIcon}
      >
        {props.children}
      </CardDescription>
    ),
    [
      props.onChange,
      props.centerTitle,
      props.title,
      props.hideCloseIcon,
      props.children,
    ]
  );

  const handleClose = () => {
    props.onChange(false);
  };

  return (
    <Dialog
      open={props.open}
      className={`smart-dialog-container ${
        props.centered ? classes.centered : classes.dialog
      } ${props.className || ""}`}
      TransitionComponent={props.centered ? undefined : Transition}
      onClose={handleClose}
    >
      <DialogContent style = {{...props.dialogStyle}}>
        {!props.centered ? (
          <SwipeableBottomSheet
            open={props.open}
            onChange={props.onChange}
            overflowHeight={0}
            marginTop={props.marginTop ?? 100}
            shadowTip={false}
            topShadow={false}
            overlay={false}
            bodyStyle={{
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
          >
            {element}
          </SwipeableBottomSheet>
        ) : (
          <>{element}</>
        )}
      </DialogContent>
    </Dialog>
  );
};

/* ------------------------------------------------------ */

/*                    CardDescription;                    */

/* ------------------------------------------------------ */
type CardDescriptionProps = {} & Pick<
  DialogReadMoreProps,
  "onChange" | "centerTitle" | "title" | "children" | "hideCloseIcon"
>;

const CardDescription = (props: CardDescriptionProps) => {
  const classes = useStyles();

  const handleClose = () => {
    props.onChange(false);
  };

  return (
    <div className={classes.card_description}>
      {!props.hideCloseIcon && (
        <div
          className="close-icon-right"
          style={{ position: "absolute", top: 20, right: 20 }}
        >
          <MuiButton color="primary" variant="text" className="--small">
            <CloseIcon onClick={handleClose} />
          </MuiButton>
        </div>
      )}
      <div className={`title${props.centerTitle ? " center" : ""}`}>
        {props.title}
      </div>

      {props.children}
    </div>
  );
};

export default React.memo(DialogReadMore);
