import React, { useState, useEffect } from "react";
import { Grid, Image } from "semantic-ui-react";
// MUI
import MobileStepper from "@mui/material/MobileStepper";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import Cookies from "js-cookie";
import SwipeableViews from "react-swipeable-views";

// CSS
import "./HistoryCSS.scss";

// Types
type ImplantDetailProps = {
  implantList?: any[];
};

// Const
const LANGUAGE = ["th", "th-TH", "th-th"].includes(
  Cookies.get("language") || navigator?.language?.split(/[-_H]/)[0]
)
  ? "th-TH"
  : "en-US";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superdarkgray: "#393939",
  BlueBDMS: "#0147A3",
  darkgrayHos: "#797878",
};

const styles = {
  cradtital: {
    borderRadius: "10px",
    background: COLOR.BlueBDMS,
    margin: "15px",
    minHeight: "192px",
    overflow: "hidden",
  } as CSSProperties,
  text_white_16_Bold: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "2px 5px",
    color: COLOR.font_white,
  } as CSSProperties,
  text_white_16: {
    margin: "2px 5px",
    fontSize: "16px",
    color: COLOR.font_white,
    textOverflow: "ellipsis",
    overflow: "hidden",
  } as CSSProperties,
  text_bluebdms_18: {
    margin: "2px 5px",
    fontSize: "18px",
    color: COLOR.primary,
  } as CSSProperties,
  flex_start: {
    display: "flex",
    justifyContent: "stert",
  } as CSSProperties,
  text_superdarkgray_16: {
    margin: "2px 5px",
    fontSize: "16px",
    color: COLOR.superdarkgray,
  } as CSSProperties,
  hospital_label: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0 0 0",
    fontSize: "14px",
    color: COLOR.font_white,
  } as CSSProperties,
};

const useStyles = makeStyles((theme) => ({
  implant_detail: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    "& span": {
      paddingRight: "0.25rem",
    },
  },
}));

const ImplantDetail: React.FunctionComponent<ImplantDetailProps> = (props) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [maxImplant, setMaxImplant] = useState<number>(0);

  const [height, setHeight] = useState<boolean>(false);

  useEffect(() => {
    if (props.implantList && props.implantList?.length > 0) {
      setMaxImplant(props.implantList.length);
    }
  }, [props.implantList]);

  useEffect(() => {
    if (height) {
      setHeight(false);
      setTimeout(() => setHeight(true));
    } else {
      setHeight(true);
    }
  }, [props.implantList]);

  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  const checkLanguage = (text: any) => {
    if (text != null) {
      const textSplit = text?.split("/");
      if (LANGUAGE === "en-US") {
        return textSplit[0] || "";
      } else {
        return textSplit[1] || "";
      }
    }
  };

  return (
    <div>
      <div className="ImplantDetail">
        <div style={{ ...(maxImplant < 0 && { display: "none" }) }}>
          <MobileStepper
            variant="dots"
            steps={maxImplant}
            position="static"
            activeStep={activeStep}
            nextButton={""}
            backButton={""}
            sx={(theme) => ({
              justifyContent: "center",
              marginTop: "10px",
              "& .MuiMobileStepper-dot": {
                width: "30px",
                zIndex: "1",
                borderRadius: "10px",
              },
              "& .MuiMobileStepper-dotActive ": {
                width: "30px",
                zIndex: "1",
                borderRadius: "10px",
                backgroundColor: "var(--primary-theme-color)",
              },
            })}
          />
        </div>
        <div>
          <>
            <SwipeableViews
              index={activeStep}
              onChangeIndex={handleChangeStep}
              animateHeight={height}
              enableMouseEvents
            >
              {props.implantList
                ? props.implantList.map((item: any, index: any) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <div style={styles.cradtital}>
                          <div style={{ padding: "15px" }}>
                            <Grid style={{ margin: "0", lineHeight: 1.5 }}>
                              <Grid.Row>
                                <div style={styles.text_white_16_Bold}>
                                  {"Procedure"}
                                </div>
                              </Grid.Row>
                              <Grid.Row>
                                <div
                                  style={{
                                    ...styles.text_white_16,
                                    // minHeight: "60px",
                                  }}
                                >
                                  {item.procedurE_DESC}
                                </div>
                              </Grid.Row>
                              <Grid.Row style={{ marginTop: "10px" }}>
                                <Grid.Column width={4} style={{ padding: "0" }}>
                                  <div style={styles.text_white_16_Bold}>
                                    {"Date"}
                                  </div>
                                </Grid.Column>
                                <Grid.Column
                                  width={12}
                                  style={{ padding: "0" }}
                                >
                                  <div style={styles.text_white_16}>
                                    {moment(item.procedurE_Date)?.format(
                                      "DD/MM/YYYY"
                                    ) || item.procedurE_Date}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={4} style={{ padding: "0" }}>
                                  <div style={styles.text_white_16_Bold}>
                                    {"Physician"}
                                  </div>
                                </Grid.Column>
                                <Grid.Column
                                  width={12}
                                  style={{ padding: "0" }}
                                >
                                  <div style={styles.text_white_16}>
                                    {checkLanguage(item.carpDesc) || ""}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <div style={styles.hospital_label}>
                              {item.hospital}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {item.implant
                        ? item.implant.map(
                            (implantItem: any, index: number) => (
                              <div style={{ margin: "10px", lineHeight: 1.5 }}>
                                <Grid style={{ margin: "0" }}>
                                  <Grid.Row>
                                    <div style={styles.text_bluebdms_18}>
                                      {`Implant ${index + 1}`}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Name : "}
                                      </span>
                                      {implantItem.imP_DESC
                                        ? implantItem.imP_DESC
                                        : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Lot Number : "}
                                      </span>
                                      {implantItem.imP_LOT
                                        ? implantItem.imP_LOT
                                        : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Brand : "}
                                      </span>
                                      {implantItem.imP_BRAND
                                        ? implantItem.imP_BRAND
                                        : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"MRI1 : "}
                                      </span>
                                      {implantItem.mri ? implantItem.mri : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Remove Date : "}
                                      </span>
                                      {implantItem.removE_DATE
                                        ? implantItem.removE_DATE
                                        : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <div className={classes.implant_detail}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {"Remark1 : "}
                                      </span>
                                      {implantItem.remark
                                        ? implantItem.remark
                                        : "-"}
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row
                                    style={{
                                      display: "flex",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        ...(!implantItem.imP_base64 && {
                                          display: "none",
                                        }),
                                      }}
                                    >
                                      <Image
                                        src={`data:image/jpeg;base64,${implantItem.imP_base64}`}
                                      />
                                    </div>
                                  </Grid.Row>
                                </Grid>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  ))
                : ""}
            </SwipeableViews>
          </>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ImplantDetail);

const mobImpant = [
  {
    implant: "Upper Limb Prosthsesis",
    hospital: "Bangkok Hospital Siriroj",
    items: [
      {
        data: "Lens Tecnis Eyhance ICB00  15.0D SN: 8149351927 Johnson & Johnson Vision",
      },
      { data: "MiniHip™  (RIght) SN: 34568819230 Corin" },
    ],
  },
  {
    implant: "Cochlear implant",
    hospital: "Bangkok Hospital Headquarters",
    items: [{ data: "MiniHip™  (RIght) SN: 34568819230 Corin" }],
  },
  {
    implant: "Procedure: Phaccemulsification Intraaccular Lens Right Eye",
    hospital: "Bangkok Hospital Headquarters",
  },
];
