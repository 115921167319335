import { PRX } from "./url";
import BaseService from "../react-lib/apis/services/BaseService";

class DiagService extends BaseService {
    getDiagRuleList({headers, params } = {}) {
        return this.client.get(PRX.PUBLISHED_DIAG_RULE_LIST, { headers, params } )
          .then(this.handleResponse)
          .catch(this.throwErrorMessage);
    }

    getPatient({headers, params} = {}) {
        return this.client.get(PRX.PATIENT, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getMyDiagForm({headers } = {}) {
        return this.client.get(PRX.MY_DIAG_FORM, {headers })
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    postDiagForm({headers, data} = {}) {
        return this.client.post(PRX.DIAG_FORM, data, {headers})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    patchDiagFormId({diagFormId, headers, data} = {}) {
        const url = PRX.DIAG_FORM_ID({ id: diagFormId });
        return this.client.patch(url, data, {headers})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getTriageLevelWithDivision({division, headers, params} = {}) {
        const url = PRX.TRIAGE_LEVEL_DIVISION_ID({ division_id: division})
        return this.client.get(url, { headers, params })
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    postCreateEncounterFromDiagForm({headers, data} = {}) {
        return this.client.post(PRX.CREATE_ENCOUNTER_FROM_DIAG_FORM, data, {headers})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    /** Division Profile */
    getDivisionProfileWithID({divisionId, headers, params} = {}) {
        const url = PRX.DIVISION_ID_PROFILE({division_id: divisionId})
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getPublishDiagRuleDetail({ publishDiagRuleId, headers, params } = {}){
        const url = PRX.PUBLISHED_DIAG_RULE_DETAIL({publishDiagRuleId: publishDiagRuleId})
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getProxyPatientHasDivision({ headers, params } = {}){
        return this.client.get(PRX.PROXY_PATIENT_HAS_DIVISION, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getPublishDiagRuleCheck({ headers, params } = {}){
        return this.client.get(PRX.PUBLISH_DIAG_RULE_CHECK, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getDiagFormDetail({ headers, params, diagFormId } = {}){
        let url = PRX.DIAG_FORM_DETAIL({ diagFormId: diagFormId })
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getListConsents({ headers, params } = {}){
        let url = PRX.LIST_CONSENTS;
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getV3PatientHospital({ headers, params } = {}){
        let url = PRX.V3_PATIENT_HOSPITAL;
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }

    getV3PatientGetHn({ headers, params } = {}){
        let url = PRX.V3_PATIENT_GET_HN;
        return this.client.get(url, {headers, params})
            .then(this.handleResponse)
            .catch(this.throwErrorMessage);
    }
}

export default DiagService;