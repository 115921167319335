import moment from "moment";
import React, { CSSProperties, useEffect, useState } from "react";
import { adToBe } from "react-lib/utils/dateUtils";
import { Button, Divider, Grid, Icon, Image } from "semantic-ui-react";

type ChatPostponeProps = {
  contentType: any;
  contentPayload: any;
  channelId: any;
  chatName: any;
  division: any;
  onClickMessage: any;
};

const ChatPostpone = (props: ChatPostponeProps) => {
  const [dataMessage, setDataMessage] = useState<any>({});

  useEffect(() => {
    if (props.contentPayload != null) {
      let routePath = "/ConfirmTelephar/";
      const providerId = props.contentPayload?.provider_id || "";
      const startDate = adToBe(
        moment(props.contentPayload?.start_datetime).format("DD/MM/YYYY")
      );
      const date = moment(startDate, "DD/MM/YYYY").format("DD MMMM YYYY");
      const startDateTime = moment(props.contentPayload?.start_datetime).format(
        "HH:mm"
      );
      const endDateTime = moment(props.contentPayload?.end_datetime).format(
        "HH:mm"
      );
      const appointmentId = props.contentPayload?.patient_appointment_id || "";

      setDataMessage({
        providerId: providerId,
        date: date,
        channelId: props.channelId,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        appointmentId: appointmentId,
        chatName: props.chatName,
        division: props.division,
      });
    }
  }, []);

  const handleClickMessage = () => {
    const state = {
      date: dataMessage.date,
      time: `${dataMessage.startDateTime}-${dataMessage.endDateTime}`,
      channelId: dataMessage.channelId,
      chatName: dataMessage.chatName,
      division: dataMessage.division,
      provider: dataMessage.providerId,
      appointmentId: dataMessage.appointmentId,
    };
    props.onClickMessage(state);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ minWidth: "max-content" }}>
        {`นัดหมายเพื่อให้คำปรึกษายาของท่าน`}
      </div>
      <div
        style={{
          ...(!(props.contentPayload.status === 4) && { display: "none" }),
          color: "#F03A3A",
        }}
      >
        {`ถูกเลื่อนเป็น`}
      </div>
      <div
        style={{
          ...((props.contentPayload.status === 4)&& { color: "#F03A3A" }),
        }}
      >
        {`วันที่: ${dataMessage.date}`}
      </div>
      <div
        style={{
          ...((props.contentPayload.status === 4)&& { color: "#F03A3A" }),
        }}
      >
        {`เวลา: ${dataMessage.startDateTime}-${dataMessage.endDateTime}`}
      </div>
      <div style={{ marginTop: "25px", whiteSpace: "pre-line" }}>
        {`หากท่านไม่สะดวกในเวลาดังกล่าว\nกรุณาเลือก “เลื่อนนัดหมาย”\nเพื่อเปลี่ยนแปลงเวลานัดหมาย`}
      </div>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            background: "#0147A3",
            borderRadius: "10",
            color: "white",
          }}
          onClick={() => handleClickMessage()}
        >
          เลื่อนนัดหมาย
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ChatPostpone);
