import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
// UI
import SnackMessage from "../../clinic/SnackMessage";
import MomKickCountChart from "../../clinic/MomKickCountChart";

type WebMomKickCountProps = {
  onEvent: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
  kickCountHistory: any;
  kickCountFilterDuration: any;
};

const WebMomKickCountInitial: WebMomKickCountProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  kickCountHistory: {},
  kickCountFilterDuration: "7",
};

const WebMomKickCount: React.FC<WebMomKickCountProps> = (props) => {
  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div className="box-header div-header">
        <img
          alt="kickcount.icon"
          height="36px"
          style={{ color: "var(--theme-icon-color-dm)" }}
          src="/images/mom/kickcount.png"
        />
        <span><FormattedMessage id="bplusClinicKey77" /></span>
      </div>
      <div className="sub-layout">
        <MomKickCountChart
          onEvent={props.onEvent}
          // data
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedPatient={props.selectedPatient}
          kickCountFilterDuration={props.kickCountFilterDuration}
          kickCountHistory={props.kickCountHistory}
          useWebMomTable={true}
          height={100}
          isMyTab={true}
          hideWebMomKickCount={true}
        />
      </div>
    </div>
  );
};

WebMomKickCount.defaultProps = WebMomKickCountInitial;

export default React.memo(WebMomKickCount);
