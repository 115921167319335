
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Image,
  Modal,
  Grid,
  Button,
  Divider,
  Dimmer,
  Icon,
  Loader,
} from "semantic-ui-react";

//component

// Use styles
import "./TeleDesktopCSS.scss";
import { useStateCallback } from "react-lib/utils/hooksUtils";

const COLOR = {
  primary: "var(--primary-theme-color)",
  blue: "#0147A3",
  white: "#FFFFFF",
  lightGary: "#9D9C9C",
};

const styles = {
  cardCalling: {
    padding: "40px 20px",
    height: "100%",
  } as CSSProperties,
  calling_button: {
    background: "#F4F6F7",
    borderRadius: "90px",
    padding: "20px",
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  styleCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  } as CSSProperties,
  styleBetween: {
    padding: "10px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  } as CSSProperties,
  textTitle: {
    fontSize: "30px",
    color: COLOR.white,
    fontWeight: "bold",
    marginTop: "30px",
  } as CSSProperties,
  textNormal: {
    fontSize: "18px",
    color: COLOR.white,
    marginTop: "30px",
  } as CSSProperties,
};

type IncomingDetailProps = {
  open: boolean;
  handleOpenVideoCall: () => any;
  handleCloseVideoCall: () => any;
  incomingDetail: any;
  userId: any;
  controller: any;
  apitoken: any;
};

var globPostMessage: Record<number, any[]> = {};
const IncomingDetail = (props: IncomingDetailProps) => {
  const [open, setOpen] = useState<boolean | undefined>(false);
  const [postMessage, setPostMessage] = useStateCallback({});
  const [textMessage, setTextMessage] = useState("");
  const isMounted = useRef(true);

  const sendMessage = ({ contentType, content } = {}) => {
    // console.log("saika ;) message ----> ", contentType);
    return new Promise((resolve, reject) => {
      let txtContent = content ? content : textMessage;
      console.log("txtContent: ", txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (!props.incomingDetail) {
        return;
      }
      let chatChannelId = props.incomingDetail.channelId;
      let id = new Date().toISOString();
      let msg = postMessage[chatChannelId]
        ? [...postMessage[chatChannelId]]
        : [];
      // let postMsg = {
      //   ...postMessage,
      //   [chatChannelId]: [
      //     ...msg,
      //     {
      //       id: id,
      //       author: {
      //         id: props.userId
      //       },
      //       content_file: txtContent,
      //       content: txtContent,
      //       content_type: contentType ? contentType : "text",
      //       sendFail: false
      //     }
      //   ]
      // };
      if (!globPostMessage[chatChannelId]) {
        globPostMessage[chatChannelId] = [];
      }
      globPostMessage[chatChannelId].push({
        id: id,
        author: {
          id: props.userId,
        },
        content_file: txtContent,
        content: txtContent,
        content_type: contentType ? contentType : "text",
        sendFail: false,
      });

      setTextMessage("");

      var response, error, network;
      const length = globPostMessage[chatChannelId]?.length - 1;
      console.log("globPostMessage", globPostMessage);
      console.log("postMessage", postMessage);
      // console.log(
      //   "saika ;) props.apitoken ",
      //   props.apitoken,
      //   " chatChannelId ",
      //   props.incomingDetail.channelId,
      //   " divisionId ",
      //   props.incomingDetail.divisionId
      // );
      setPostMessage({ ...globPostMessage }, async () => {
        [response, error, network] =
          await props.controller.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.incomingDetail.channelId,
            apiToken: props.apitoken,
            divisionId: props.incomingDetail.divisionId,
          });

        if (isMounted.current) {
          if (response) {
            if (globPostMessage[chatChannelId]?.[length]) {
              globPostMessage[chatChannelId][length].message_id = response.id;
            }
            // Request next question
            if (props.chatBotLogic) {
              await props.chatBotLogic(textMessage);
              // getLastMessage();
            }
            // await getLastMessage();
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            globPostMessage[chatChannelId].splice(index, 1);
            // newObj[chatChannelId] = newArr;
            setPostMessage(globPostMessage);
          } else {
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            if (index !== -1) {
              globPostMessage[chatChannelId][index].sendFail = true;
              // newObj[chatChannelId] = newArr;
            }
            setPostMessage(globPostMessage);
          }
        }
        resolve(response);
      });
    });
  };

  const handleCloseVideoCall = () => {
    setTimeout(() => {
      sendMessage({
        contentType: "end_call",
        content: "", // `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
      });
    }, 1000);
    props.handleCloseVideoCall();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.open && (
        <audio id="myAudio" loop controls autoPlay>
          <source src="phone_ringing.mp3" type="audio/mpeg" />
        </audio>
      )}
      <Modal
        open={props.open}
        onClose={() => {}}
        style={{ width: "90%", height: "90%", background: "black" }}
      >
        <div style={styles.cardCalling}>
          <Grid style={{ margin: "0", height: "100%" }}>
            <Grid.Row
              style={{
                ...styles.styleCenter,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#F4F6F7",
                  borderRadius: "80px",
                  padding: "20px",
                  width: "150px",
                  height: "150px",
                }}
              >
                <Image
                  src={"/logo512.png"}
                  style={{ width: "120px" }}
                />
              </div>
              <div style={styles.textTitle}>
                {props.incomingDetail.channelName}
              </div>
              <div style={styles.textNormal}>Incoming . . .</div>
            </Grid.Row>
            <Grid.Row style={{ alignItems: "end" }}>
              <Grid.Column width={8} style={styles.styleCenter}>
                <div
                  style={styles.calling_button}
                  onClick={() => props.handleOpenVideoCall()}
                >
                  <Icon
                    rotated="clockwise"
                    name="phone"
                    color="green"
                    size="huge"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8} style={styles.styleCenter}>
                <div
                  style={styles.calling_button}
                  onClick={() => handleCloseVideoCall()}
                >
                  <Icon
                    name="phone"
                    color="red"
                    size="huge"
                    style={{ transform: "rotate(225deg)" }}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(IncomingDetail);
