import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Image,
  Form,
  Button,
  Input,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import personImg from "react-lib/apps/common/person.png";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as Util from "react-lib/utils";
import Cookies from "js-cookie";
import { FormattedMessage } from "react-intl";

const Profile = (props) => {
  const isMounted = useRef(true);
  const [profile, setProfile] = useState(null);
  const [birthday, setBirthday] = useState("");
  const [chatChannel, setChatChannel] = useState([]);
  const [selectedChatChannel, setSelectedChatChannel] = useState(null);
  const [hospitalNumber, setHospitalNumber] = useState("");
  const [lockHN, setLockHN] = useState(false);
  const [proxyId, setProxyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [saveError, setSaveError] = useState([]);
  const [showSaveState, setShowSaveState] = useState(false);
  // Donot update email
  const [email, setEmail] = useState("");
  // Ref for input
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const lineIdRef = useRef();
  const history = useHistory();
  const apiToken = Cookies.get("apiToken");

  const updateUI = (newProfile) => {
    if (newProfile) {
      let data = newProfile;
      setProfile(data);
      // Initial value
      firstNameRef.current.inputRef.current.value = data.first_name;
      lastNameRef.current.inputRef.current.value = data.last_name;
      let dob = "";
      if (data.dob) {
        dob = AD2BE(data.dob);
      }
      setBirthday(dob);
      phoneRef.current.inputRef.current.value = data.phone_no;
      setEmail(data.email);
      // Line ID
      // lineIdRef.current.inputRef.current.value = ""
    }
  };

  const chatChannelToDropdownOption = (data) => {
    let dataOption = data.map((item) => {
      return { key: item.id, text: item.name, value: item.name };
    });

    return dataOption ? dataOption : [];
  };

  const loadProfile = async () => {
    let apiToken = props.apiToken ? props.apiToken : apiToken;
    setProfileLoading(true);
    const [data, error] = await props.controller.loadProfile(apiToken);
    if (!isMounted.current) {
      return;
    }
    setProfileLoading(false);
    console.log("loadProfile", data);
    updateUI(data);
  };

  const loadChatChannel = async (userId) => {
    let apiToken = props.apiToken ? props.apiToken : apiToken;
    const [data, error, network] = await props.controller.getChatChannel({
      apiToken: apiToken,
      userId: userId,
      isPatient: true,
    });

    if (isMounted.current) {
      if (data) {
        let options = chatChannelToDropdownOption(data.items);
        setChatChannel(options);
      }
    }
  };

  const loadProxyHasDivision = async () => {
    let apiToken = props.apiToken ? props.apiToken : apiToken;
    setProfileLoading(true);
    const [data, error, network] =
      await props.controller.getProxyPatientHasDivision({ apiToken: apiToken });

    // console.log(' loadProxyHasDivision', data)
    if (isMounted.current) {
      setProfileLoading(false);
      if (data) {
        let items = data.items;

        // console.log('data loadProxyHasDivision', data)
        if (items && items.length > 0) {
          let prepare = items.find((item) => {
            return item.division.name === selectedChatChannel;
          });
          if (prepare) {
            console.log("loadProxyHasDivision prepare", prepare);
            setProxyId(prepare && prepare.id ? prepare.id : null);
            setHospitalNumber(prepare.hn);
            setLockHN(prepare.lock_hn);
          }
        }
      }
    }
  };

  const updateHospitalNumber = async () => {
    setIsLoading(true);
    const [response, error] =
      await props.controller.patchProxyPatientHasDivision({
        apiToken: props.apiToken ? props.apiToken : apiToken,
        id: proxyId,
        hn: hospitalNumber,
      });
    if (!isMounted.current) {
      return;
    }
    setIsLoading(false);
    return [response, error];
  };

  const updateProfile = async () => {
    setIsLoading(true);
    let params = { apiToken: props.apiToken ? props.apiToken : apiToken };
    let firstName = firstNameRef.current.inputRef.current.value;
    let lastName = lastNameRef.current.inputRef.current.value;
    let phone = phoneRef.current.inputRef.current.value;
    if (firstName && firstName !== (profile ? profile.first_name : "")) {
      params.firstName = firstName;
    }
    if (lastNameRef && lastName !== (profile ? profile.last_name : "")) {
      params.lastName = lastName;
    }
    if (birthday) {
      params.dob = birthday;
    }
    if (phone && phone !== (profile ? profile.phone_no : "")) {
      params.phone = phone;
    }
    // Call update
    const [data, error] = await props.controller.updateProfile(params);
    if (!isMounted.current) {
      return;
    }
    setIsLoading(false);
    updateUI(data);
    return [data, error];
  };

  const handleDateChange = (date) => {
    setBirthday(date);
  };

  const handleOnHNChange = (e, data) => {
    if (!lockHN) {
      setHospitalNumber(data.value);
    }
  };

  /** Convert Date */
  const AD2BE = (dateAD) => {
    const dateSplitted = dateAD.split("/");
    if (dateSplitted.length !== 3) {
      return dateAD;
    }

    let year = String(parseInt(dateSplitted[2], 10) + 543);
    let month = String(parseInt(dateSplitted[1], 10)).padStart(2, "0");
    let day = String(parseInt(dateSplitted[0], 10)).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const handleSelectHospitalList = (e, data) => {
    setSelectedChatChannel(data.value);
    loadProxyHasDivision();
  };

  /** Handle "Save" button */
  const handleSave = async () => {
    let err = [];
    let [res1, err1] = await updateProfile();
    let [res2, err2] = await updateHospitalNumber();
    if (err1) {
      err.push(err1);
    }
    if (err2) {
      err.push(err2);
    }
    setSaveError(err);
    setShowSaveState(true);
    setTimeout(() => {
      if (isMounted.current) {
        setShowSaveState(false);
        if (err.length === 0) {
          history.push("/");
        }
      }
    }, 3000);
    // const promA = updateProfile();
    // const promB = updateHospitalNumber();
    // let err = []
    // Promise.all([promA, promB ]).then((res) => {
    //   for(let item of res){
    //     if(item[1]){
    //       err.push(item[1])
    //     }
    //   }
    //   setSaveError(err)
    //   setShowSaveState(true)
    //   setTimeout(() => {
    //     if (isMounted.current) {
    //       setShowSaveState(false)
    //       if(err.length === 0){
    //         history.push("/")
    //       }
    //     }
    //   }, 3000)
    // });
  };

  /** Handle "Logout" button */
  const handleLogout = () => {
    if (window.FB) {
      window.FB.logout();
    }
    props.onLogout();
  };

  useEffect(() => {
    if (props.controller && (props.apiToken || apiToken)) {
      loadProfile();
    }
  }, [props.controller, props.apiToken, apiToken]);

  useEffect(() => {
    // console.log('profile dependenc', profile)
    if (profile && profile.user) {
      loadChatChannel(profile.user);
    }
  }, [profile]);

  useEffect(() => {
    if (chatChannel && Array.isArray(chatChannel) && chatChannel.length > 0) {
      setSelectedChatChannel(chatChannel[0].value);
    }
  }, [chatChannel]);

  useEffect(() => {
    loadProxyHasDivision();
  }, [selectedChatChannel]);

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const createSaveState = () => {
    if (!showSaveState) {
      return;
    }
    if (saveError.length > 0) {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold", color: "red" }}>
          <br />
          บันทึกไม่สำเร็จ
        </div>
      );
    } else {
      return (
        <div
          style={{ textAlign: "center", fontWeight: "bold", color: "green" }}
        >
          <br />
          บันทึกสำเร็จ
        </div>
      );
    }
  };

  console.log("props profile.js", props);

  return (
    <>
      <div className="profile">
        <Image
          className={"profile-image border"}
          fluid
          circular
          centered
          src={profile && profile.image_url ? profile.image_url : personImg}
        />
        <Segment basic className="profile-segment">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <p>
              <FormattedMessage id="profile.profile" />
            </p>
            <Form className="profile-segment-form">
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.first_name" />
                </label>
                <Input fluid ref={firstNameRef} />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.last_name" />
                </label>
                <Input fluid ref={lastNameRef} />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.day_month_year_birth" />
                </label>
                <DateTextBox
                  value={birthday}
                  onChange={handleDateChange}
                  keepFocus={false}
                  maxDate={Util.formatDate(moment())}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.tel_no" />
                </label>
                <Input fluid ref={phoneRef} />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.email" />
                </label>
                <Input fluid value={email} />
              </Form.Field>
              <Form.Field>
                <Form.Field required>
                  <label>
                    <FormattedMessage id="profile.hospital" />
                  </label>
                  <Dropdown
                    selection
                    fluid
                    placeholder="โปรดเลือกโรงพยาบาล เพื่อดูข้อมูล"
                    value={selectedChatChannel}
                    options={chatChannel}
                    onChange={handleSelectHospitalList}
                  />
                </Form.Field>
                <Form.Field>
                  <label>HN.</label>
                  <Input
                    fluid
                    readOnly={lockHN ? "1" : null}
                    value={hospitalNumber}
                    onChange={handleOnHNChange}
                  />
                </Form.Field>
              </Form.Field>

              {/* <Form.Field>
                          <label style={{color: COLOR.DARK_BLUE}}>ID : Line</label>
                          <Input fluid ref={lineIdRef} />
                      </Form.Field> */}
            </Form>
            <div className="save-div">
              <Button
                onClick={handleSave}
                loading={isLoading}
                disabled={isLoading || !selectedChatChannel}
              >
                <FormattedMessage id="common.save" />
              </Button>
            </div>
            {createSaveState()}
          </Dimmer.Dimmable>
        </Segment>

        {!props.hideLogout && (
          <div className="logout-div">
            <Button
              size={"large"}
              onClick={handleLogout}
              loading={isLoading}
              disabled={isLoading}
            >
              <FormattedMessage id="auth.logout" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

Profile.defaultProps = {
  controller: {},
  onLogout: () => {},
  noApiToken: () => {},
  hideLogout: false,
};

export default Profile;
