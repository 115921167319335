import WasmController, { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

// Constant
import {
  BACKEND_DATE_FORMAT, BACKEND_TIME_FORMAT,
  GROUP,
  DATA_TYPE,
  commonGetPatientDataLatest,
  commonListPatientDataList,
  calculateDelivery,
} from "../../MobClinicInterface";
import { State as MainState } from "../../ManageInterface";

export type State = {
  breastfeedingList?: Record<string, any>[];
}

type Picked = Pick<MainState, "loadingStatus" | "errorMessage" | "successMessage">

export const StateInitial: State = {
  breastfeedingList: [],
};

export type Event =
  // GET
  {
    message: "GetBreastfeedingList";
    params: { start_measure: string; end_measure: any };
  };

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

// GET
export const GetBreastfeedingList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const [errorLatest, respLatest] = await commonGetPatientDataLatest(
    controller as any,
    {
      group: GROUP.OBSTRETIC_RECORD,
      data_type: DATA_TYPE.OBSTRETIC_RECORD,
    }
  );

  const tmpParams: any = {
    group: GROUP.BREAST_FEEDING,
    data_type: DATA_TYPE.BREAST_FEEDING,
    ...params,
  };
  const [errorList, respList] = await commonListPatientDataList(
    controller as any,
    tmpParams
  );

  if (errorLatest || errorList) {
    controller.setState({
      loadingStatus: false,
      errorMessage: [errorLatest, errorList],
      successMessage: null,
      breastfeedingList: [],
    });
  } else {
    respList?.items?.forEach((item: any) => {
      const deliveryResult = calculateDelivery(
        respLatest?.data?.by_ultrasound,
        moment(
          item?.measure_date,
          `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
        ),
        moment(respLatest?.data?.lmp_date, BACKEND_DATE_FORMAT),
        moment(respLatest?.data?.edc_date, BACKEND_DATE_FORMAT)
      );
      item.ga = `${deliveryResult.ga_weeks} weeks ${deliveryResult.ga_days} days`
    });
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      breastfeedingList: respList?.items,
    });
  }
};
