import React, {
  ChangeEvent,
  CSSProperties,
  useState,
  useMemo,
  useEffect,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
// import Plots from './Plots'
import { Bar } from "react-chartjs-2";
import {
  Button,
  Icon,
  Dropdown,
  ButtonProps,
  Progress,
} from "semantic-ui-react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import { calculatePercent } from "./DMFoodMain";
import {
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react/dist/commonjs/generic";
import moment from "moment";
import { FOOD_MEAL, formatDateByLanguage } from "../MobClinicInterface";
import {
  FooterChart,
  HeaderChart,
  spliceChartData,
} from "./DMEmotionRestChart";
import { DATE_FORMAT } from "./DMEmotionRest";
import { useIntl } from "react-intl";

export type NutrientsType =
  | "CARBOHYDRATE"
  | "PROTEIN"
  | "FAT"
  | "SODIUM"
  | "SUGAR"
  | "SAT_FAT";

export type NutrientDetail = Partial<{
  carbohydrate: number;
  protein: number;
  fat: number;
  sodium: number;
  sugar: number;
  sat_fat: number;
}>;

type BarChartProps = {
  color?: string;
  graphType?: "food" | "energy";
  foodData?: any[];
  nutrient?: string;
  setMealInfo?: any;
  hideFooterChart?: boolean;
};

type HistoryProps = {
  foodHistory?: any[];
  selectedPatient?: any;
  onEvent?: any;
};

export const COLOR = {
  light_grey: "#F2F2F2",
  yellow: "#FEE8A9",
  green: "#B2E7BE",
  pink: "#F4BADB",
  orange: "#FACDB0",
  teal: "#A6E5E2",
  violet: "#C9B8EC",
  border_grey: "#D8D8D8",
  light_bue: "#DFF5FC",
  blue: "#2399E5",
};

type CaloriesMapKey = keyof typeof CALORIES_MAP;

const CALORIES_MAP = {
  CARBOHYDRATE: 4,
  PROTEIN: 4,
  FAT: 9,
  SODIUM: 0,
  SUGAR: 4, // per teaspoon
  SAT_FAT: 9,
};

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 90,
  },

  plot: {
    marginLeft: "10px",
    fontSize: "7px",
    textAlign: "center",
    color: "#747474",
  },

  plottext: {
    marginLeft: "7px",
    fontSize: "7px",
    textAlign: "center",
    color: "#747474",
  },

  plotselecttext: {
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#747474",
  },

  plotselect: {
    marginLeft: "7px",
    fontSize: "10px",
    lineHeight: "10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#A3A3A3",
  },

  caltext: {
    marginLeft: "10px",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "23px",
    textAlign: "center",
    color: "#2399E5",
  },

  descriptiontext: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#656565",
  },

  textcalories: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "47px",
    color: "#A2D7E8",
    width: "100%",
    textAlign: "center",
  },

  textcarbohydrate: {
    fontWeight: "bold",
    fontSize: "10px",
    color: "#2399E5",
  },

  textstatus: {
    fontWeight: "bold",
    fontSize: "10px",
    color: "#828282",
    marginRight: "20px",
  },

  textgram: {
    fontWeight: "bold",
    fontSize: "10px",
    color: "#828282",
    textAlign: "right",
  },

  hr: {
    border: "1px solid #E0E0E0",
    height: "0px",
    width: "90%",
    marginTop: "10px",
    alignItems: "center",
    color: "#E0E0E0",
    backgroundColor: "#E0E0E0",
  },

  size: {
    width: "43px",
    height: "43px",
    background: "#C4C4C4",
    borderRadius: "50px",
    marginLeft: "5px",
  },

  textshow: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#2D9BE2",
  },

  textshownumber: {
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#1621A8",
  },

  textlink: {
    fontWeight: "bold",
    fontSize: "18px",
    textAlign: "center",
    color: "#219653",
  },

  alignCenter: {
    textAlign: "center",
    padding: "2px",
  },
  ProgressBoxLabel: {
    flex: 1,
    display: "flex",
    padding: "5px 0px 5px 0px",
  },

  ProgressLabelLeft: {
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "14px",
    color: "#747474",
    textAlign: "left",
    flex: 1,
  },

  ProgressLabelRight: {
    fontSize: "13px",
    lineHeight: "14px",
    color: "#747474",
    textAlign: "right",
    flex: 1,
  },
  textCalUnit: {
    width: "100%",
    textAlign: "center",
    color: "#c4c4c4",
    fontSize: "24px",
    fontWeight: "bold",
    // marginTop: "5px",
    // marginLeft: "18px",
  },
}));

const styles = {
  btnWidth120px: {
    width: "115px",
    background: COLOR.light_grey,
    fontSize: "12px",
    border: `1px solid ${COLOR.border_grey}`,
    margin: "1px",
    textTransform: "capitalize",
  } as CSSProperties,
};

export const DURATION_OPTIONS = [
  {
    key: 0,
    text: "5 Days",
    value: "5_DAY",
  },
  {
    key: 1,
    text: "7 Days",
    value: "7_DAY",
  },
  {
    key: 2,
    text: "10 Days",
    value: "10_DAY",
  },
];

export type DateDurationKey = keyof typeof DATE_DURATION;

export const DATE_DURATION = {
  "5_DAY": -4,
  "7_DAY": -6,
  "10_DAY": -9,
};

export const NutrientStatusList = {
  carbohydrate: {
    whole: 70,
    name: "Carbohydrate",
    unit: "g",
    apiUnit: "gram",
    color: "pink",
  },
  protein: {
    whole: 50,
    name: "Protein",
    unit: "g",
    apiUnit: "gram",
    color: "yellow",
  },
  fat: {
    whole: 50,
    name: "Fat",
    unit: "g",
    apiUnit: "gram",
    color: "green",
  },
  sodium: {
    whole: 2300,
    name: "Sodium",
    unit: "mg",
    apiUnit: "",
    color: "orange",
  },
  sugar: {
    whole: 6,
    name: "Sugar",
    unit: "teaspoon",
    apiUnit: "",
    color: "teal",
  },
  sat_fat: {
    whole: 140,
    name: "Sat. Fat",
    unit: "g",
    apiUnit: "gram",
    color: "violet",
  },
};

export const NutrientsTypeList: { name: NutrientsType; color: string }[] = [
  { name: "CARBOHYDRATE", color: COLOR.pink },
  { name: "PROTEIN", color: COLOR.yellow },
  { name: "FAT", color: COLOR.green },
  { name: "SODIUM", color: COLOR.orange },
  { name: "SUGAR", color: COLOR.teal },
  { name: "SAT_FAT", color: COLOR.violet },
];

export const calculateWhole = (
  info: any,
  key: keyof typeof NutrientStatusList
) => {
  return (
    info?.nutrientionRequirement?.[key]?.[
      NutrientStatusList[key].apiUnit
    ]?.toFixed(2) || NutrientStatusList[key].whole
  );
};

const HeaderMeal = (props: { text: string }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "10px 0 15px 10px",
        alignItems: "center",
        fontWeight: "bold",
        width: "88px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: "13px" }}>{props.text}</div>
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99712 0.415663C6.02019 0.544178 6.46154 2.99964 6.46154 3.93753C6.46154 5.3676 5.65962 6.38752 4.47404 6.79768L4.84615 13.3082C4.86635 13.6828 4.55192 14 4.15385 14H2.30769C1.9125 14 1.59519 13.6855 1.61538 13.3082L1.9875 6.79768C0.799038 6.38752 0 5.36487 0 3.93753C0 2.99691 0.441346 0.544178 0.464423 0.415663C0.556731 -0.139414 1.77115 -0.147617 1.84615 0.445741V4.30667C1.88365 4.39964 2.28173 4.39417 2.30769 4.30667C2.34808 3.61487 2.53558 0.500428 2.53846 0.429335C2.63365 -0.139414 3.82788 -0.139414 3.92019 0.429335C3.92596 0.503163 4.11058 3.61487 4.15096 4.30667C4.17692 4.39417 4.57788 4.39964 4.6125 4.30667V0.445741C4.6875 -0.144882 5.90481 -0.139414 5.99712 0.415663ZM9.43558 8.22775L9.00288 13.2891C8.96827 13.6719 9.28846 14 9.69231 14H11.3077C11.6913 14 12 13.7074 12 13.3438V0.656287C12 0.295351 11.6913 3.90683e-05 11.3077 3.90683e-05C8.92789 3.90683e-05 4.92115 4.88089 9.43558 8.22775Z"
          fill="#1C99F3"
        />
      </svg>
    </div>
  );
};

export const BarChart: React.FunctionComponent<BarChartProps> = (props) => {
  const labelLimit = 7;
  // const [data, setData] = useState({});
  const [maxCalories, setMaxCalories] = useState(100); // divider by 100
  const [skipIndex, setSkipIndex] = useState(0);
  const [history, setHistory] = useState<any>({ labels: [], datasets: [] });
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);
  // useEffect(() => {
  //   setSkipIndex(0);
  // }, [startDate]);

  useEffect(() => {
    if (props.hideFooterChart) {
      setIsSeeAll(true);
    } else {
      setIsSeeAll(false);
    }
  }, [props.hideFooterChart]);

  useEffect(() => {
    setSkipIndex(0);
  }, [isSeeAll]);

  useEffect(() => {
    setMaxCalories(100);
    let count = 100;
    let dataForGraph = [];
    if (!props.foodData || props.foodData?.length === 0) {
      return;
    }
    if (props.graphType === "energy") {
      let energy =
        CALORIES_MAP[props.nutrient?.toUpperCase() as CaloriesMapKey];

      dataForGraph = props.foodData.map((item) => {
        let calculateEnergy = 0;
        if (props.nutrient === "sugar") {
          calculateEnergy = item[props.nutrient as CaloriesMapKey] / energy;
        } else {
          calculateEnergy = item[props.nutrient as CaloriesMapKey] * energy;
        }

        if (calculateEnergy > count) {
          if (calculateEnergy <= 1000) {
            count = Math.ceil(calculateEnergy / 100) * 100;
          } else {
            count = Math.ceil(calculateEnergy / 200) * 200;
          }
        }
        return calculateEnergy;
      });
    } else {
      dataForGraph =
        props.foodData?.map((item) => {
          if (item[props.nutrient || ""] > count) {
            if (item[props.nutrient || ""] <= 1000) {
              count = Math.ceil(item[props.nutrient || ""] / 100) * 100;
            } else {
              count = Math.ceil(item[props.nutrient || ""] / 200) * 200;
            }
          }
          return item[props.nutrient || ""];
        }) || [];
    }
    let labels =
      props.foodData?.map((item) =>
        formatDateByLanguage(
          moment(item.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
          "DD MMM YYYY"
        )
      ) || [];
    setHistory({
      labels: labels,
      datasets: [
        {
          label: props.nutrient,
          data: dataForGraph,
          // borderColor: Utils.CHART_COLORS.red,
          backgroundColor: props.color,
        },
      ],
    });
    setMaxCalories(count);
  }, [props.color, props.foodData]);

  const options = {
    plugins: {
      legend: false,
    },
    responsive: true,
    elements: {
      bar: {
        // backgroundColor: "blue",
        // borderColor: colorize(true),
        // borderWidth: 2
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: maxCalories,
            stepSize:
              Math.ceil(maxCalories / 100) >= 5
                ? Math.ceil(maxCalories / 100) > 10
                  ? 200
                  : 100
                : 20,
            beginAtZero: true,
            fontSize: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: true,
            minRotation: 0,
            fontSize: 10,
          },
          maxBarThickness: 16,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    onClick: function (event: any, element: any) {
      if (element?.length > 0) {
        const index = element[0]._index;
        // props.setDetail(realDatas[index]);
        console.log(
          "legend data:",
          props.foodData?.[index + skipIndex],
          index,
          skipIndex
        );
        props.setMealInfo(props.foodData?.[index + skipIndex]);
        console.log("click legend:", props.foodData?.[index + skipIndex]);
      }
    },
  };

  const subChartData = useMemo(() => {
    if (props.hideFooterChart) {
      return history;
    }
    return spliceChartData({
      data: history,
      index: skipIndex,
      limit: labelLimit,
    });
  }, [history, skipIndex]);

  const handleMoveGraph = (move: "left" | "right") => {
    if (move === "left") {
      setSkipIndex(skipIndex - 1);
    } else if (move === "right") {
      setSkipIndex(skipIndex + 1);
    }
  };

  const handleClickSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <>
      <Bar data={isSeeAll ? history : subChartData} options={options} />
      {!props.hideFooterChart && (
        <FooterChart
          graphIndex={skipIndex}
          lastGraph={Boolean(
            history.labels?.slice(labelLimit + skipIndex)?.[0]
          )}
          onMoveGraph={handleMoveGraph}
          isSeeAll={isSeeAll}
          onClickSeeAll={handleClickSeeAll}
          hideMoveIcon={isSeeAll}
        />
      )}
    </>
  );
};

const HistoryFood: React.FC<HistoryProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  const [nutrientsType, setNutrientsType] = useState<{
    name: NutrientsType;
    color: string;
  }>(NutrientsTypeList[0]);
  const [nutrientDetail, setNutrientDetail] = useState<NutrientDetail>({});
  const [duration, setDuration] = useState<DateDurationKey>("7_DAY");
  const [mealInfo, setMealInfo] = useState<any>(null);
  const [startDate, setStartDate] = useState(moment().add(-6, "days"));
  const [endDate, setEndDate] = useState(moment());

  // useEffect(() => {
  //   props.onEvent({ message: "handleGetHistoryFood", params: { duration } });
  // }, [props.selectedPatient]);

  useEffect(() => {
    // handleCheckDate()
    const foodData: any[] = props.foodHistory || [];
    let foodSelectedData: any = null
    for (let i = 0; i <= foodData?.length; i++) {
      // if (foodData?.[i]?.date === moment().format("YYYY-MM-DD")) {
      //   if (
      //     foodData?.[i]?.breakfast?.length ||
      //     foodData?.[i]?.lunch?.length ||
      //     foodData?.[i]?.dinner?.length ||
      //     foodData?.[i]?.snack?.length 
      //   ) {
      //     console.log("foodData?.[i]", foodData?.[i]);
      //     setMealInfo(foodData?.[i]);
      //   } else if (
      //     foodData?.[i]?.breakfast?.length === 0 &&
      //     foodData?.[i]?.lunch?.length === 0 &&
      //     foodData?.[i]?.dinner?.length === 0 &&
      //     foodData?.[i]?.snack?.length === 0
      //   ) {
      //     console.log("foodData?.[i]", foodData?.[i]);
      //     setMealInfo(null);
      //   }
      // }
      if (
        foodData?.[i]?.breakfast?.length ||
        foodData?.[i]?.lunch?.length ||
        foodData?.[i]?.dinner?.length ||
        foodData?.[i]?.snack?.length 
      ) {
        foodSelectedData = foodData?.[i]
      }
    }
    setMealInfo(foodSelectedData)
  }, [props.foodHistory]);

  const durationOptions = useMemo(
    () => [
      {
        key: "0",
        value: "5_DAY",
        text: `5 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "1",
        value: "7_DAY",
        text: `7 ${intl.formatMessage({ id: "profile.days" })}`,
      },
      {
        key: "2",
        value: "10_DAY",
        text: `10 ${intl.formatMessage({ id: "profile.days" })}`,
      },
    ],
    []
  );

  const handleChangeNutrientsType = (item: {
    name: NutrientsType;
    color: string;
  }) => {
    setNutrientsType(item);
  };

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    let dateRange = DATE_DURATION[duration];

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(dateRange, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(Math.abs(dateRange), "days");
    }

    props.onEvent({
      message: "handleGetHistoryFood",
      params: {
        selectedDate: todate.locale("en").format("D MMMM YYYY"),
        duration: duration,
      },
    });

    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleChangeDuration = (_e: any, { value }: any) => {
    let dateRange = DATE_DURATION[value as DateDurationKey];
    let stDate = endDate.clone().add(dateRange, "days");

    props.onEvent({
      message: "handleGetHistoryFood",
      params: {
        selectedDate: endDate.locale("en").format("D MMMM YYYY"),
        duration: value,
      },
    });
    console.log("handleChangeDuration: ", stDate, dateRange);
    setStartDate(stDate);
    setDuration(value);
  };

  // const handleChangeDuration = (value: any) => {
  //   props.onEvent({
  //     message: "handleGetHistoryFood",
  //     params: { duration: value },
  //   });
  //   setDuration(value);
  // };

  return (
    <div>
      <Grid item xs={12} container>
        <Grid item xs={12} container direction="row">
          {/* <Grid item xs={7} container>
            <div className={classes.plottext}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <rect width="12" height="12" fill="#FCA5C4" />
                </svg>
                <div style={{ paddingLeft: "2px" }}>Carbohydrate</div>
              </div>
              <div className={classes.plottext}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <rect width="12" height="12" fill="#F9DD8D" />
                </svg>
                <div style={{ paddingLeft: "2px" }}>Protein</div>
              </div>
              <div className={classes.plottext}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <rect width="12" height="12" fill="#A0F297" />
                </svg>
                <div style={{ paddingLeft: "2px" }}>Fat</div>
              </div>
          </Grid> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ paddingRight: "15px" }}>Duration: </div>
              <Dropdown
                options={DURATION_OPTIONS}
                selection
                value={duration}
                onChange={(e, { value }) => {
                  handleChangeDuration(value);
                }}
              />
            </div>
          </div> */}

          <Grid item xs={12} container style={{ padding: "5px" }}>
            {NutrientsTypeList.map((item: any, index: number) => (
              <Grid
                key={"type-" + index}
                item
                xs={4}
                className={classes.alignCenter}
              >
                <Button
                  key={"button-" + index}
                  onClick={() => handleChangeNutrientsType(item)}
                  name={item.name}
                  style={{
                    ...styles.btnWidth120px,
                    ...(item.name === nutrientsType.name
                      ? {
                          background: COLOR.light_bue,
                          border: `1px solid ${COLOR.blue}`,
                          color: COLOR.blue,
                        }
                      : {}),
                  }}
                >
                  {/* {item.name.replace(/_/g, " ").toLowerCase()} */}
                  {item.name === "CARBOHYDRATE"
                    ? `${intl.formatMessage({ id: "bplusClinicKey108" })}`
                    : item.name === "PROTEIN"
                    ? `${intl.formatMessage({ id: "bplusClinicKey402" })}`
                    : item.name === "FAT"
                    ? `${intl.formatMessage({ id: "bplusClinicKey196" })}`
                    : item.name === "SODIUM"
                    ? `${intl.formatMessage({ id: "bplusClinicKey461" })}`
                    : item.name === "SUGAR"
                    ? `${intl.formatMessage({ id: "bplusClinicKey475" })}`
                    : item.name === "SAT_FAT"
                    ? `${intl.formatMessage({ id: "bplusClinicKey831" })}`
                    : ""}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Grid style={{ width: "100%" }}>
            <HeaderChart
              yAxesLabelLeft=""
              yAxesLabelRight=""
              startDate={startDate}
              endDate={endDate}
              durationOptions={durationOptions}
              selectedDuration={duration}
              onChangeDuration={handleChangeDuration}
              onChangeDateRange={handleChangeDateRange}
              labelStyle={{ padding: "0px" }}
              legendItem={{
                type: "rect",
                items: [
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey108" })}`,
                    color: "#f4badb",
                  },
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey402" })}`,
                    color: "#fee8a9",
                  },
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey196" })}`,
                    color: "#b2e7be",
                  },
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey461" })}`,
                    color: "#facdb0",
                  },
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey475" })}`,
                    color: "#a6e5e2",
                  },
                  {
                    name: `${intl.formatMessage({ id: "bplusClinicKey436" })}`,
                    color: "#c8b7ec",
                  },
                ],
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div
            className={classes.caltext}
            style={{ display: "flex", margin: "15px 8px 3px" }}
          >
            <div style={{ padding: "0 3px", textTransform: "capitalize" }}>
              {nutrientsType.name.replace(/_/g, " ").toLowerCase()}{" "}
            </div>
            {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sodium"
              ? "(g)"
              : "(mg)"}
          </div>
          <div className={classes.plot}>
            {/* <Plots /> */}
            <BarChart
              color={nutrientsType.color}
              foodData={props.foodHistory}
              graphType="food"
              nutrient={nutrientsType.name.trim().toLowerCase()}
              setMealInfo={setMealInfo}
            />
          </div>
        </Grid>

        {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sodium" && (
          <Grid item xs={12}>
            <div
              className={classes.caltext}
              style={{ display: "flex", margin: "20px 8px 3px" }}
            >
              <div style={{ padding: "0 3px", textTransform: "capitalize" }}>
                {nutrientsType.name.replace(/_/g, " ").toLowerCase()}{" "}
              </div>
              {nutrientsType.name.replace(/_/g, " ").toLowerCase() !== "sugar"
                ? "(kcal)"
                : "(teaspoon)"}
            </div>
            <div className={classes.plot}>
              {/* <Plots /> */}
              <BarChart
                color={nutrientsType.color}
                foodData={props.foodHistory}
                graphType="energy"
                nutrient={nutrientsType.name.trim().toLowerCase()}
                setMealInfo={setMealInfo}
              />
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          container
          direction="row"
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={12} container>
            <div className={classes.descriptiontext} style={{ padding: "5px" }}>
              <Icon
                name="calendar alternate outline"
                style={{ color: "#1C99F3", marginLeft: "5px" }}
              />
              <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                {mealInfo?.date
                  ? formatDateByLanguage(
                      moment(mealInfo.date).format("DD/MM/YYYY"),
                      "DD MMMM YYYY"
                    )
                  : `${formatDateByLanguage(
                      moment().format("DD/MM/YYYY"),
                      "DD MMMM YYYY"
                    )}`}
              </span>
              {/* <Icon name="utensils" style={{ color: "#1C99F3", marginLeft: "5px" }} />
                <span style={{ paddingLeft: "5px" }}>Breakfast</span> */}
            </div>
          </Grid>

          <Grid item xs={12} container style={{ padding: "10px 5px 5px" }}>
            {(Object.keys(NutrientStatusList) as (keyof NutrientDetail)[])?.map(
              (key, index: number) => (
                <Grid
                  key={"status-" + index}
                  item
                  xs={4}
                  className={classes.alignCenter}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 3px",
                    }}
                  >
                    <div className={classes.ProgressBoxLabel}>
                      <div
                        className={classes.ProgressLabelLeft}
                        style={{ flex: 1, fontSize: "11px" }}
                      >
                        {
                          NutrientStatusList[key].name === "Carbohydrate" ? `${intl.formatMessage({ id: "bplusClinicKey108" })}` :
                          NutrientStatusList[key].name === "Protein" ? `${intl.formatMessage({ id: "bplusClinicKey402" })}` :
                          NutrientStatusList[key].name === "Fat" ? `${intl.formatMessage({ id: "bplusClinicKey196" })}` :
                          NutrientStatusList[key].name === "Sodium" ? `${intl.formatMessage({ id: "bplusClinicKey461" })}` :
                          NutrientStatusList[key].name === "Sugar" ? `${intl.formatMessage({ id: "bplusClinicKey475" })}` :
                          NutrientStatusList[key].name === "Sat. Fat" ? `${intl.formatMessage({ id: "bplusClinicKey436" })}` : ""
                        }
                      </div>
                    </div>
                    <div
                      className={classes.ProgressBoxLabel}
                      style={{ paddingTop: "0px" }}
                    >
                      <div
                        className={classes.ProgressLabelRight}
                        style={{ flex: 1, fontSize: "11px", paddingTop: "0px" }}
                      >
                        {key === "sugar" ? (
                          <>
                            {((mealInfo?.sugar?.toFixed(2) || 0) / 4).toFixed(
                              2
                            )}
                            /
                            {mealInfo?.nutrientionRequirement?.[key]?.[
                              NutrientStatusList[key].apiUnit
                            ]?.toFixed(2) || NutrientStatusList[key].whole}{" "}
                            {NutrientStatusList[key].unit}
                          </>
                        ) : (
                          <>
                            {mealInfo?.[key]?.toFixed(2) || 0}/
                            {mealInfo?.nutrientionRequirement?.[key]?.[
                              NutrientStatusList[key].apiUnit
                            ]?.toFixed(2) || NutrientStatusList[key].whole}{" "}
                            {NutrientStatusList[key].unit}
                          </>
                        )}
                      </div>
                    </div>
                    <Progress
                      percent={
                        key === "sugar"
                          ? calculatePercent(
                              +((mealInfo?.sugar?.toFixed(2) || 0) / 4).toFixed(
                                2
                              ),
                              calculateWhole(mealInfo, key)
                            ) || 0
                          : calculatePercent(
                              mealInfo?.[key]?.toFixed(2) || 0,
                              calculateWhole(mealInfo, key)
                            ) || 0
                      }
                      style={{ marginBottom: "5px" }}
                      color={NutrientStatusList[key].color as SemanticCOLORS}
                      size="small"
                      disabled
                    />
                  </div>
                </Grid>
              )
            )}
          </Grid>
          {mealInfo &&
          Object.keys(mealInfo)?.length > 0 &&
          mealInfo[FOOD_MEAL.BREAKFAST]?.length > 0 ? (
            <MealLabel
              titleText={`${intl.formatMessage({ id: "bplusClinicKey684" })}`}
              foodItem={mealInfo[FOOD_MEAL.BREAKFAST]}
              classes={classes}
            />
          ) : (
            ""
          )}

          {mealInfo &&
          Object.keys(mealInfo)?.length > 0 &&
          mealInfo[FOOD_MEAL.LUNCH]?.length > 0 ? (
            <MealLabel
              titleText={`${intl.formatMessage({ id: "bplusClinicKey685" })}`}
              foodItem={mealInfo[FOOD_MEAL.LUNCH]}
              classes={classes}
            />
          ) : (
            ""
          )}

          {mealInfo &&
          Object.keys(mealInfo)?.length > 0 &&
          mealInfo[FOOD_MEAL.DINNER]?.length > 0 ? (
            <MealLabel
              titleText={`${intl.formatMessage({ id: "bplusClinicKey686" })}`}
              foodItem={mealInfo[FOOD_MEAL.DINNER]}
              classes={classes}
            />
          ) : (
            ""
          )}

          {mealInfo &&
          Object.keys(mealInfo)?.length > 0 &&
          mealInfo[FOOD_MEAL.SNACK]?.length > 0 ? (
            <MealLabel
              titleText={`${intl.formatMessage({ id: "bplusClinicKey687" })}`}
              foodItem={mealInfo[FOOD_MEAL.SNACK]}
              classes={classes}
            />
          ) : (
            ""
          )}

          {mealInfo !== null ? (
            <Grid
              item
              xs={12}
              container
              direction="row"
              style={{ marginTop: "15px" }}
            >
              <Grid item xs={12} container style={{ marginTop: "20px" }}>
                <Grid
                  item
                  xs={12}
                  // Failed prop type: The prop `justifyContent` of `Grid` must be used on `container`.
                  justifyContent="center"
                  style={{ display: "flex" }}
                >
                  <span className={classes.textshow}>
                    <FormattedMessage id="bplusClinicKey544" />
                  </span>
                  <span
                    className={classes.textshownumber}
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    {mealInfo?.allCalories?.toFixed(2) || 0}/
                    {mealInfo?.calories_budget > 1? mealInfo?.calories_budget.toFixed(2) || 0 : (0).toFixed(2) }
                  </span>
                  <span className={classes.textshow}>
                    <FormattedMessage id="bplusClinicKey555" />
                  </span>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                style={{ paddingTop: "10px", paddingBottom: "55px" }}
              >
                <Grid
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ display: "flex" }}
                >
                  <Icon
                    name={
                      (+mealInfo?.allCalories || 0) >
                      (+mealInfo?.calories_budget || 0)
                        ? "thumbs down"
                        : "thumbs up"
                    }
                    color={
                      (+mealInfo?.allCalories || 0) >
                      (+mealInfo?.calories_budget || 0)
                        ? "red"
                        : "green"
                    }
                    size="big"
                  />
                  <span
                    className={classes.textlink}
                    style={{
                      padding: "10px",
                      color:
                        (+mealInfo?.allCalories || 0) >
                        (+mealInfo?.calories_budget || 0)
                          ? "red"
                          : "#219653",
                    }}
                  >
                    {(+mealInfo?.allCalories || 0) >
                    (+mealInfo?.calories_budget || 0)
                      ? `${intl.formatMessage({ id: "bplusClinicKey702" })} !`
                      : 
                      <div>
                        {`${intl.formatMessage({ id: "bplusClinicKey865" })}`} <br/>
                        {`${intl.formatMessage({ id: "bplusClinicKey866" })}`} <br/>
                        {`${intl.formatMessage({ id: "bplusClinicKey867" })}`}
                      </div>}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

type MealLabelProps = {
  classes: any;
  titleText: string;
  foodItem: any[];
};

type nutrientType = {
  calories: any;
  carb: any;
  sugar: any;
  fat: any;
  satFat: any;
  fiber: any;
  sodium: any;
  protein: any;
};

export const MealLabel: React.FC<MealLabelProps> = (props) => {
  const intl = useIntl();
  const [nutrietionInfo, setNutrientInfo] = useState<nutrientType>({
    calories: 0,
    carb: 0,
    sugar: 0,
    fat: 0,
    satFat: 0,
    fiber: 0,
    sodium: 0,
    protein: 0,
  });
  const [imageGalleries, setImageGalleries] = useState<any[]>([]);

  useEffect(() => {
    let result: nutrientType = {
      calories: 0,
      carb: 0,
      sugar: 0,
      fat: 0,
      satFat: 0,
      fiber: 0,
      sodium: 0,
      protein: 0,
    };
    let imageArr: any[] = [];
    if (props.foodItem?.length > 0) {
      props.foodItem.forEach((item) => {
        result.calories += item.energy_cal * item.qty || 0;
        result.carb += item.cabohydrate_grams * item.qty || 0;
        result.sugar += item.sugar_grams * item.qty || 0;
        result.fat += item.fat_grams * item.qty || 0;
        result.satFat += item.sat_fat_milligram * item.qty || 0;
        result.fiber += item.fiber_grams * item.qty || 0;
        result.sodium += item.sodium_milligram * item.qty || 0;
        result.protein += item.protein_grams * item.qty || 0;

        if (item.image) {
          imageArr.push(item.image);
        }
      });

      result = {
        calories: result.calories.toFixed(2),
        carb: result.carb.toFixed(2),
        sugar: result.sugar.toFixed(2),
        fat: result.fat.toFixed(2),
        satFat: result.satFat.toFixed(2),
        fiber: result.fiber.toFixed(2),
        sodium: result.sodium.toFixed(2),
        protein: result.protein.toFixed(2),
      };
    }
    console.log("ImageArr:", imageArr);
    setNutrientInfo(result);
    setImageGalleries(imageArr);
  }, [props.foodItem]);

  return (
    <>
      <HeaderMeal text={props.titleText} />

      <Grid
        item
        xs={12}
        container
        direction="row"
        style={{ paddingBottom: "10px" }}
      >
        <Grid
          item
          xs={3}
          container
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <div className={props.classes.textcalories}>
            {nutrietionInfo.calories}
          </div>
          <div className={props.classes.textCalUnit}>
            <FormattedMessage id="bplusClinicKey553" />
          </div>
        </Grid>

        <Grid
          item
          xs={5}
          container
          direction="row"
          style={{ paddingLeft: "15px" }}
        >
          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey109" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.carb}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey476" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.sugar}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey197" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.fat}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey435" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.satFat}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              {intl.formatMessage({ id: "bplusClinicKey573" })}
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey403" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.protein}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey199" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.fiber}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>

          <Grid item xs={6}>
            <div className={props.classes.textcarbohydrate}>
              <FormattedMessage id="bplusClinicKey462" />
            </div>
          </Grid>
          <Grid item xs={4}>
            <span className={props.classes.textstatus}>
              {nutrietionInfo.sodium}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className={props.classes.textgram}>
              <FormattedMessage id="bplusClinicKey569" />
            </span>
          </Grid>
        </Grid>

        <Grid
          item
          xs={4}
          container
          style={{ paddingLeft: "15px", paddingTop: "5px" }}
        >
          {imageGalleries.map((item) => {
            return (
              <Avatar
                alt="Remy Sharp"
                src={item}
                className={props.classes.size}
              />
            );
          })}
          {/* <Avatar
            alt="Remy Sharp"
            src="/images/BoiledChickenRice.jpg"
            className={props.classes.size}
          />
          <Avatar
            alt="Remy Sharp"
            src="/images/OrageJuice.jpg"
            className={props.classes.size}
          /> */}
        </Grid>
        <hr className={props.classes.hr} />
      </Grid>

      {/* <hr className={props.classes.hr} /> */}
    </>
  );
};

export default React.memo(HistoryFood);
