import { WasmHandler } from "react-lib/frameworks/WasmController";

import moment from "moment";
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonCreatePatientData,
  commonDeletePatientData,
  commonListPatientDataList,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
  State as MainState,
} from "../../MobClinicInterface";
import { formatUTCtoMoment } from "../../../react-lib/utils/dateUtils";

export type State = {
  loadingGrowth?: boolean;
  childGrowDetail?: any;
  growthHistoryList?: any;
  successMessage?: any;
  errorMessage?: any;
};

type Picked = Pick<MainState, "loadingStatus">;

export const StateInitial = {
  loadingGrowth: false,
  childGrowDetail: {},
  growthHistoryList: {},
};

export type Event = { message: "getGrowthHistoryList"; params: {} };

type Handler = WasmHandler<State & Picked, Event>;

export const getGrowthHistoryList: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  // const state = controller.getState()
  let data = [];
  const filterData = {
    group: GROUP.GROWTH,
    data_type: DATA_TYPE.GROWTH,
  };

  let errorMessage: any = null;
  const [error, response] = await commonListPatientDataList(
    controller as any,
    filterData
  );

  if (error) {
    errorMessage = error;
  } else {
    if (response?.items?.length > 0) {
      data = response.items.map((item: any) => {
        return {
          id: item.id,
          measure_date: item.measure_date,
          date: formatUTCtoMoment(item.measure_date)
            .locale("en")
            .format("D MMMM YYYY"),
          proxy_patient: item.proxy_patient,
          ...item.data,
        };
      });
    }
  }

  controller.setState({
    growthHistoryList: {
      items: [...data],
    },
    loadingStatus: false,
  });
};
