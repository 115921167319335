import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { WasmHandler } from 'react-lib/frameworks/WasmController'

export type State = 
  {
    oaId?: string,
    oaInfo?: any,
    clinic?: string,
    patientId?: string | null,
    patientInfo?: any,
    postList?: any[],
    selectedPostIndex?: number | null,
    preview?: {
      url: string,
      title: string, 
      image: string
    } | null
  }

export const StateInitial = 
  {
    // clinic: "dm",
    postList: [],
    selectedPostIndex: null,
    preview: null
  }

export type Event = 
  { message: "GetPost", params: {} }  |  
  { message: "GetPreview", params: { url: string } } |
  { message: "SaveSelectedPost", params: {} } |
  { message: "AddPost", params: {} } |
  { message: "DeleteSelectedPost", params: {} }

export type Data = {}

export const DataInitial = {}

type Handler = WasmHandler<State, Event>

export const GetPost: Handler = async (controller, params) => {
  console.log(controller.getState().clinic);


  const postRef = collection(controller.db, "Post");
  const q = query(postRef, where("oaId", "==", controller.getState().oaId));
  const querySnapshot = await getDocs(q);

  const postList = (querySnapshot?.docs || []).map(doc => ({id: doc.id, ...doc.data()}))
  console.log("GetPost: ", postList)
  postList.sort((a:any, b: any) => a.created < b.created ? 1 : -1)
  controller.setState({ 
    postList: postList,
    selectedPostIndex: postList.length > 0 ? 0 : null,
    preview: null,
  })

  // controller.db.collection("Post")
  //   .where("oaId", "==", controller.getState().oaId)
  //   .get()
  //   .then(res => {
  //     // GenerateFakeStat(controller, { docs: res.docs })
  //     const postList = res.docs.map(doc => ({id: doc.id, ...doc.data()}))
  //     console.log(postList)
  //     postList.sort((a:any, b: any) => a.created < b.created ? 1 : -1)
  //     controller.setState({ 
  //       postList: postList,
  //       selectedPostIndex: postList.length > 0 ? 0 : null,
  //       preview: null,
  //     })
  //   })

}

// const GenerateFakeStat: Handler = (controller, params) => {
//   for (const doc of params.docs) {
//     console.log(doc.data())
//     controller.db.collection("Post")
//       .doc(doc.id)
//       .set({
//         ...doc.data(),
//         stats: [
//           { 'date_joined': "2020-02-01", count: 10 + Math.random() * 10 },
//           { 'date_joined': "2020-02-02", count: 15 + Math.random() * 10 },
//           { 'date_joined': "2020-02-03", count: 8 + Math.random() * 10 },
//           { 'date_joined': "2020-02-04", count: 4 + Math.random() * 10 },
//           { 'date_joined': "2020-02-05", count: 18 + Math.random() * 10 },
//           { 'date_joined': "2020-02-06", count: 10 + Math.random() * 10 },
//           { 'date_joined': "2020-02-07", count: 11 + Math.random() * 10 },
//           { 'date_joined': "2020-02-08", count: 2 + Math.random() * 10 },
//           { 'date_joined': "2020-02-09", count: 19 + Math.random() * 10 },
//           { 'date_joined': "2020-02-10", count: 10 + Math.random() * 10 },
//         ]
//       })
//   }
// }

export const GetPreview: Handler = (controller, params) => {
  controller.functions.httpsCallable("getWebPreview")(params.url)
    .then((result: any) => {
      console.log(result.data)
      controller.setState({ preview: { url: params.url, ...result.data } })
      const selectedPostIndex = controller.getState().selectedPostIndex
      if (Number.isInteger(selectedPostIndex)) {
        controller.setProp(`postList.${selectedPostIndex}.preview`, 
          { 
            url: params.url, 
            ...result.data 
          },
          () => {
            SaveSelectedPost(controller, {})
          }
        )
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
}

export const SaveSelectedPost: Handler = async (controller, params) => {
  console.log(controller.getState().oaInfo)
  if (Number.isInteger(controller.getState().selectedPostIndex)) {
    const state = controller.getState()
    const selectedPost = state?.postList?.[state?.selectedPostIndex as number];
    const data = Object.assign(
      Object.keys(selectedPost).reduce((acc, cur) => (
        cur === "id" ? acc: { ...acc, [cur]: selectedPost[cur] }
      ), {}), 
      {
        oaId: state.oaId,
        oaInfo: state.oaInfo || null
      })
    console.log(controller.getState().selectedPostIndex);
    console.log(data);

    await setDoc(doc(controller.db, "Post", selectedPost.id), data);

    // controller.db.collection("Post")
    //   .doc(selectedPost.id)
    //   .set(data)
  }
}

export const AddPost: Handler = async (controller, params) => {


  const docRef = await addDoc(collection(controller.db, "Post"), {
    oaId: controller.getState().oaId,
    title: "New Post",
    created: new Date()
  });

  GetPost(controller, {})

  // controller.db.collection("Post")
  //   .add({
  //     oaId: controller.getState().oaId,
  //     title: "New Post",
  //     created: new Date()
  //   }).then(() => {
  //     GetPost(controller, {})
  //   })
}

export const DeleteSelectedPost: Handler = async (controller, params) => {
  if (Number.isInteger(controller.getState().selectedPostIndex)) {
    const state = controller.getState()
    const selectedPost = state?.postList?.[state?.selectedPostIndex || 0];

    await deleteDoc(doc(controller.db, "Post", selectedPost.id));
    GetPost(controller, {})

    // controller.db.collection("Post")
    //   .doc(selectedPost.id)
    //   .delete()
    //   .then(() => {
    //     GetPost(controller, {})
    //   })
  }
}

export const GetFeedContent: Handler = async (controller, params) => {
  const tags = controller.getState().patientInfo.tags
  console.log(tags)

  const postRef = collection(controller.db, "Post");
  const q = query(postRef, where("tags", "array-contains-any", tags));
  const querySnapshot = await getDocs(q);

  let postList = querySnapshot?.docs.map(doc => doc.data())
  postList.sort((a: any, b: any) => (a.created < b.created ? 1: -1 ))
  controller.setState({
    postList: postList
  })

  // controller.db.collection("Post")
  //   .where("tags", "array-contains-any", tags)
  //   .get()
  //   .then(res => {
  //     let postList = res.docs.map(doc => doc.data())
  //     postList.sort((a: any, b: any) => (a.created < b.created ? 1: -1 ))
  //     controller.setState({
  //       postList: postList
  //     })
  //   })
}
