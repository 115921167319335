import { WasmHandler } from "react-lib/frameworks/WasmController";
import { Handler } from "../MobFeedInterface";
import moment from "moment";

export type State = {
  patientList?: { items: any[] };
  selectedPatient?: {
    id: number | null;
    image: string;
    hn: string;
    fullname: string;
    gender_label: string;
    encounter: string;
    birthdate: string;
    pregnancy: string;
    overall_progress: [{ name: string; value: string }];
  };
};

export const StateInitial = {
  patientList: {
    items: [
      {
        id: 1,
        image: "/images/taveerattana.png",
        hn: "01-10000001",
        fullname: "Taylor Swift",
        gender_label: "Female",
        encounter: "13",
        birthdate: moment()
          .add(-30, "years")
          .add(2, "months")
          .add(5, "days")
          .format("DD/MM/YYYY"),
        last_visit_date: moment().add(-6, "days").format("DD/MM/YYYY"),
        appointment_date: moment().add(2, "days").format("DD/MM/YYYY"),
        pregnancy: "0-13 weeks",
        overall_progress: [
          { name: "Weight gain ", value: "เหมาะสมตามเกณฑ์" },
          { name: "Breast feeding", value: "หัวนมปกติ" },
          { name: "Contraction", value: "อยู่ในเกณฑ์ที่เหมาะสม" },
          { name: "Baby kick", value: "อยู่ในเกณฑ์ที่เหมาะสม" },
        ],
      },
      {
        id: 2,
        hn: "01-10000002",
        fullname: "นาง มาดี มีใจ",
        gender_label: "Female",
      },
      {
        id: 3,
        hn: "01-10000003",
        fullname: "นาง สุขฤดี โสภณ",
        gender_label: "Female",
      },
      {
        id: 4,
        hn: "01-10000004",
        fullname: "นางสาว สุชาดา สุขดี",
        gender_label: "Female",
      },
      {
        id: 5,
        hn: "01-10000005",
        fullname: "นาง ชูใจ สีชมพู",
        gender_label: "Female",
      },
    ],
  },
  selectedPatient: {},
};

export type Event =
  | { message: "handleGetPatientList"; params: { type: string } }
  | { message: "handleSeletedPatient"; params: {} };

type Handler = WasmHandler<State, Event>;

export const handleGetPatientList: Handler = (controller, params) => {
  const state = controller.getState();

  if (state.selectedPatient?.id) {
    // Todo: Get
  }
};

export const handleSeletedPatient: Handler = (controller, params) => {
  controller.setState({ selectedPatient: params });
};
