import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import { Bar } from "react-chartjs-2";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

// UI
import SnackMessage from "./SnackMessage";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  formatDateByLanguage,
} from "../MobClinicInterface";

import { DATE_FORMAT, styles } from "./MomContractionCount";

type MomContractionCountChartProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedPatient?: any;
  contractionFilterDuration?: string;
  contractionCountHistory?: any;
  height?: number;
  isMyTab?: boolean;
  hideWebMomContractionCount?: boolean;
  // swipeableActionRef?: any;
};

const MomContractionCountChartInitial: MomContractionCountChartProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  contractionFilterDuration: "7",
  contractionCountHistory: {},
  height: 200,
  isMyTab: false,
  hideWebMomContractionCount: false,
};

const MomContractionCountChart: React.FC<MomContractionCountChartProps> = (
  props
) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add("-7", "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [history, setHistory] = useState<any>({
    data: { labels: [], datasets: [] },
    options: {},
  });

  const durationOptions = useMemo(
    () => [
      { key: "5", value: "4", text: `5 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "7", value: "6", text: `7 ${intl.formatMessage({ id: "profile.days" })}` },
      { key: "10", value: "9", text: `10 ${intl.formatMessage({ id: "profile.days" })}` },
    ],
    []
  );

  // useEffect(() => {
  //   if (!props.loadingStatus && props.swipeableActionRef?.current?.updateHeight) {
  //     console.log(" props.swipeableActionRef?.current?.updateHeight() ")
  //     props.swipeableActionRef?.current?.updateHeight()
  //   }
  // }, [props.loadingStatus])
  

  useEffect(() => {
    setStartDate(moment().add(`-${props.contractionFilterDuration}`, "days"));
    setEndDate(moment());
  }, [props.contractionFilterDuration]);

  useEffect(() => {
    if (props.isMyTab) {
      props.onEvent({
        message: "handleGetContractionCountHistory",
        params: {
          start_measure: startDate.format(BACKEND_DATE_FORMAT),
          end_measure: endDate.format(BACKEND_DATE_FORMAT),
        },
      });
    }
  }, [props.selectedPatient, props.isMyTab, startDate]);

  useEffect(() => {
    let labels: any[] = [];
    let data: any[] = [];
    if (props.contractionCountHistory) {
      props.contractionCountHistory?.items?.forEach((item: any) => {
        labels.push(
          formatDateByLanguage(moment(
            item.measure_date,
            `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
          ).format(DATE_FORMAT), "MMM DD,YYYY")
        );
        data.push(item?.data?.total);
      });
    }

    setHistory({
      data: {
        labels: labels,
        datasets: [
          {
            label: "Contraction count",
            data: data,
            backgroundColor: "#2399E5",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: Math.max(...data) + 2,
                min: 0,
              },
            },
          ],
          xAxes: [{ maxBarThickness: 70 }],
        },
      },
    });
  }, [props.contractionCountHistory]);

  const handleChangeDateRange = (event: any, data: any) => {
    let fromdate: any = null;
    let todate: any = null;

    if (data.name === "backward") {
      const targetDate: any = moment(startDate, DATE_FORMAT);
      todate = targetDate.clone();
      fromdate = targetDate.add(`-${props.contractionFilterDuration}`, "days");
    } else if (data.name === "forward") {
      const targetDate: any = moment(endDate, DATE_FORMAT);
      fromdate = targetDate.clone();
      todate = targetDate.add(props.contractionFilterDuration, "days");
    }
    setStartDate(fromdate);
    setEndDate(todate);
  };

  const handleChangeDuration = (event: any, { value }: any) => {
    props.onEvent({
      message: "handleChangeContractionCountFilterDuration",
      params: { duration: value },
    });
  };

  return (
    <div style={{ marginBottom: "20px", marginTop: "15px" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <Form size="small" style={{ marginTop: "20px", padding: "0 15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Header
            as="h4"
            style={{ margin: "0 8px 0 0", color: "var(--text-grey)" }}
          >
            <FormattedMessage id="bplusClinicKey169" />
          </Header>
          <Dropdown
            selection
            search
            compact
            style={{ width: "160px" }}
            value={props.contractionFilterDuration}
            options={durationOptions}
            onChange={handleChangeDuration}
          />
        </div>
      </Form>

      <div style={{ display: "flex", padding: "20px 0px" }}>
        <div style={{ flex: 1 }}></div>
        <div
          style={{ ...styles.textCenter, flex: 6, color: "var(--text-grey)" }}
        >
          <Icon name="backward" onClick={handleChangeDateRange} />
          <label style={{ padding: "0px 10px" }}>
            {`
              ${formatDateByLanguage(moment(startDate).format(DATE_FORMAT),"MMM DD, YYYY")} - 
              ${formatDateByLanguage(moment(endDate).format(DATE_FORMAT),"MMM DD, YYYY")}
            `}
          </label>
          <Icon
            name="forward"
            disabled={
              endDate.format(DATE_FORMAT) === moment().format(DATE_FORMAT)
            }
            onClick={handleChangeDateRange}
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </div>

      <div style={{ padding: "0 15px" }}>
        {!props.hideWebMomContractionCount ? (
          <div style={styles.textKickCount}>{intl.formatMessage({id: "bplusClinicKey125"})}</div>
        ) : (
          <div style={styles.textKickCount}>{intl.formatMessage({id: "bplusClinicKey818"})}</div>
        )}
        <Bar
          height={props.height}
          data={history?.data}
          options={history?.options}
        />
        <div style={styles.textDate}><FormattedMessage id="common.date" /></div>
      </div>
      <div style={{ padding: "20px" }} />

      <div style={{ padding: "0 10px" }}>
        {props.contractionCountHistory?.items?.map(
          (item: any, iindex: number) => {
            return (
              <Segment
                key={iindex}
                style={{ margin: "10px", padding: "0px 0px 10px" }}
              >
                <Grid style={{ padding: "15px" }}>
                  <Grid.Row>
                    <Grid.Column width={9}>
                      <Header
                        as="h4"
                        style={{ color: "var(--text-dark-blue)" }}
                      >
                        {formatDateByLanguage(moment(
                          item?.measure_date,
                          `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                        ).format(DATE_FORMAT),"ddd, DD MMM YYYY")}
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={7} style={{ textAlign: "right" }}>
                      <Header
                        as="h4"
                        style={{ color: "var(--text-dark-blue)" }}
                      >
                        <FormattedMessage id="bplusClinicKey338" />{" "}
                        {item?.data?.total}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ ...styles.textCenter, padding: "5px" }}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as="h4"
                        style={{ color: "var(--text-dark-blue)" }}
                      >
                        <FormattedMessage id="bplusClinicKey467" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as="h4"
                        style={{ color: "var(--text-dark-blue)" }}
                      >
                        <FormattedMessage id="bplusClinicKey182" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as="h4"
                        style={{ color: "var(--text-dark-blue)" }}
                      >
                        <FormattedMessage id="bplusClinicKey375" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                  </Grid.Row>

                  {item?.data?.items.map((data: any, dindex: number) => {
                    return (
                      <Grid.Row
                        key={dindex}
                        style={{
                          ...styles.textCenter,
                          color: "var(--text-grey)",
                          fontWeight: "bold",
                          padding: "5px",
                        }}
                      >
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={4}>{data?.start_time}</Grid.Column>
                        <Grid.Column width={4}>
                          {data?.end_time || "-"}
                        </Grid.Column>
                        <Grid.Column width={4}>{data?.period}</Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                      </Grid.Row>
                    );
                  })}
                </Grid>
              </Segment>
            );
          }
        )}
      </div>
    </div>
  );
};

MomContractionCountChart.defaultProps = MomContractionCountChartInitial;

export default React.memo(MomContractionCountChart);
