import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";

import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
  commonListPatientDataList,
  commonCreatePatientData,
  commonUpdatePatientData,
  DATA_TYPE,
  GROUP,
  commonCreatePatientDocumentFile,
  commonUpdatePatientDocumentFile,
} from "../MobClinicInterface";

export type State = {
  openFootConfirm?: boolean;
  footFilterDate?: moment.Moment;
  footFilterDuration?: string;
  footDetail?: any;
  footHistory?: any;
};

export const StateInitial: State = {
  openFootConfirm: false,
  footFilterDate: moment(),
  footFilterDuration: "6",
  footDetail: {},
  footHistory: {},
};

export type Event =
  // GET
  | {
    message: "handleGetFootDetailByDate";
    params: { openConfirm: boolean };
  }
  | {
    message: "handleGetFootHistoryByDuration";
    params: { start_measure: string; end_measure: string };
  }
  // CHANGE
  | { message: "handleChangeFootFilterDate"; params: { date: moment.Moment } }
  | { message: "handleChangeFootFilterDuration"; params: { duration: string } }
  // SAVE
  | { message: "handleSaveFootDetail"; params: {} }
  // MODAL
  | { message: "handleCloseFootModal"; params: {} };

type Handler = WasmHandler<State, Event>;

// GET
export const handleGetFootDetailByDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();
  const tmpParams: any = {
    group: GROUP.FOOT,
    data_type: DATA_TYPE.FOOT,
    start_measure: state.footFilterDate?.format(BACKEND_DATE_FORMAT),
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    footDetail: response,
    openFootConfirm: params.openConfirm,
  });
};

export const handleGetFootHistoryByDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true });

  const tmpParams: any = {
    group: GROUP.FOOT,
    data_type: DATA_TYPE.FOOT,
    ...params, // params: start_measure, end_measure
  };
  const [error, response] = await commonListPatientDataList(
    controller,
    tmpParams
  );

  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    footHistory: response,
  });
};

// CHANGE
export const handleChangeFootFilterDate: Handler = async (
  controller,
  params
) => {
  controller.setState({ footFilterDate: moment(params.date, "DD/MM/YYYY") });
};

export const handleChangeFootFilterDuration: Handler = async (
  controller,
  params
) => {
  controller.setState({ footFilterDuration: params.duration });
};

// SAVE
export const handleSaveFootDetail: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  let tmpData: any[] = [];
  let leftFootimage = "";
  let rightFootimage = "";

  let imgErr: any = null;
  let imgRes: any = null;

  if (params?.leftFoot?.image?.data) {
    leftFootimage = params.leftFoot.image.data;
  }
  if (params?.rightFoot?.image?.data) {
    rightFootimage = params.rightFoot.image.data;
  }

  const state = controller.getState();
  if (state.footDetail?.items?.length > 0) {
    tmpData = [...state.footDetail?.items[0].data];
  }

  let tmpParams = { ...params }
  delete tmpParams['leftFoot']['image']['data']
  delete tmpParams['rightFoot']['image']['data']

  tmpData.push({ ...tmpParams });

  let error: any = {};
  let response: any = {};

  let errorMessage: any = [];
  let successMessage: any = null;
  if (state.footDetail?.items?.length > 0 && state.footDetail.items[0].id) {
    let data: any = { ...state.footDetail?.items[0] };
    data.data = [...tmpData];
    let latestIndex = data.data.length - 1

    if (leftFootimage) {
      [imgErr, imgRes] = await saveImageData(controller, {
        phr: state.footDetail.items[0].id,
        image: { id: params.leftFoot.image.id, data: leftFootimage },
      });
      if (imgErr) {
        errorMessage.push(imgErr);
      } else {
        data.data[latestIndex].leftFoot.image = { ...imgRes };
      }
    }

    if (rightFootimage) {
      const [imgErr, imgRes] = await saveImageData(controller, {
        phr: state.footDetail.items[0].id,
        image: { id: params.rightFoot.image.id, data: rightFootimage },
      });
      if (imgErr) {
        errorMessage.push(imgErr);
      } else {
        data.data[latestIndex].rightFoot.image = { ...imgRes };
      }
    }

    [error, response] = await commonUpdatePatientData(controller, data);
  } else {
    const data: any = {
      group: GROUP.FOOT,
      data_type: DATA_TYPE.FOOT,
      measure_date: state.footFilterDate?.format(
        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
      ),
      data: [...tmpData],
    };
    [error, response] = await commonCreatePatientData(controller, data);
    if (leftFootimage || rightFootimage) {
      let updateData: any = { ...response }
      let latestIndex = response.data.length - 1
      if (leftFootimage) {
        [imgErr, imgRes] = await saveImageData(controller, {
          phr: response.id,
          image: { id: params.leftFoot.image.id, data: leftFootimage },
        });
        if (imgErr) {
          errorMessage.push(imgErr);
        } else {
          updateData.data[latestIndex].leftFoot.image = { ...imgRes };
        }
      }

      if (rightFootimage) {
        const [imgErr, imgRes] = await saveImageData(controller, {
          phr: response.id,
          image: { id: params.rightFoot.image.id, data: rightFootimage },
        });
        if (imgErr) {
          errorMessage.push(imgErr);
        } else {
          updateData.data[latestIndex].rightFoot.image = { ...imgRes };
        }
      }

      [error, response] = await commonUpdatePatientData(controller, updateData);
    }
  }

  if (error) {
    errorMessage = error;
  } else {
    successMessage = "Save foot success.";
    handleGetFootDetailByDate(controller, { openConfirm: true });
  }
  controller.setState({
    loadingStatus: false,
    errorMessage: errorMessage.length > 0 ? errorMessage : null,
    successMessage: successMessage,
  });
};

// MODAL
export const handleCloseFootModal: Handler = (controller) => {
  controller.setState({
    openFootConfirm: false,
  });
};



// ------------------------------ utility

const saveImageData: Handler = async (controller, params) => {
  let error: any = null;
  let response: any = null;
  if (params.image?.id) {
    [error, response] = await commonUpdatePatientDocumentFile(controller, {
      id: params.image.id,
      phr: params.phr,
      image: params.image.data,
    });
  } else {
    [error, response] = await commonCreatePatientDocumentFile(controller, {
      phr: params.phr,
      image: params.image.data,
    });
  }

  if (error) {
    return [error, null];
  } else {
    return [null, { id: response.id, url: response.image }];
  }
};
