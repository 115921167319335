import React, { useEffect, useMemo, useRef, useState } from "react";
import MobHeadApp from "bplus-lib/feed/MobHeadApp";
import MobFeed from "bplus-lib/MobFeed";
import { HtmlIframeProps } from "semantic-ui-react";

const PreferredPreviewHome = (props: any) => {

  useEffect(() => {
    // setIdx(Math.random());
    console.log("props.servicesOfHospitalFB", props.servicesOfHospitalFB)
    let iframe = document.getElementById("prefered-simulator") as HtmlIframeProps
    iframe?.contentWindow?.postMessage('updatesimulator', '*');
    // console.log('iframe: ', iframe);
  }, [props.servicesOfHospitalFB]);

  console.log(
    "PreferredPreviewHome  props.servicesOfHospitalFB: ",
    props.servicesOfHospitalFB
  );

  return (
    <div className="PreferredPreview">
      <div className="PreferredHeader-mobilePreview">Mobile Preview</div>
      <div className="PreferredBox-main">
        <div className="PreferredBox-mobilePreview">
          <iframe
            id="prefered-simulator"
 
            src={`/?app=MobFeedSimulator&code=${props.selectedOrganizationCode}`}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferredPreviewHome;
