import { WasmHandler } from "react-lib/frameworks/WasmController";
import * as WebMomChildClassificationI from "./clinic/WebMomChildClassificationInterface";

export type State = {
  webSub?: string;
  patientSub?: string,
  userManagerSub?: string,
  user?: any;
  errorMessage?: any;
  successMessage?: any;
} & WebMomChildClassificationI.State;

export const StateInitial = {
  webSub: "",
  patientSub: "",
  userManagerSub: "",
  user: {},
  errorMessage: null,
  successMessage: null,
  ...WebMomChildClassificationI.StateInitial,
};

export type Event =
  | { message: ""; params: {} }
  | WebMomChildClassificationI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State, Event>;
